import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'

class FiltrosInformesProductividadExtracciones extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeProductividadExtracciones({})
    this.props.actions.comboVerraco()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboIndex()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboOperario()
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboCliente()
  }

  obtenerInformeProductividadExtracciones(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    values.volumen = this.props.filtrosProductividadExtracciones.volumen
    values.concentracionTotal = this.props.filtrosProductividadExtracciones.concentracionTotal
    values.concentracionUtil = this.props.filtrosProductividadExtracciones.concentracionUtil
    values.fa = this.props.filtrosProductividadExtracciones.fa
    values.dosisReales = this.props.filtrosProductividadExtracciones.dosisReales
    values.motilidad = this.props.filtrosProductividadExtracciones.motilidad
    values.contaminacion = this.props.filtrosProductividadExtracciones.contaminacion
    this.props.actions.obtenerInformeProductividadExtracciones(values)
  }

  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesProductividadExtracciones !== prevProps.datosPdfInformesProductividadExtracciones) {
      if (this.props.datosPdfInformesProductividadExtracciones.pdf && this.props.datosPdfInformesProductividadExtracciones.pdf !== null){
        let pdf = this.props.datosPdfInformesProductividadExtracciones.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.PRODUCTIVIDAD_MACHOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesProductividadExtracciones(){
    this.props.actions.imprimirPdfInformesProductividadExtracciones(this.props.filtrosProductividadExtracciones)
  }

  crearCsvInformeProductividadExtracciones(){
    this.props.actions.crearCsvInformeProductividadExtracciones(this.props.filtrosProductividadExtracciones)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosProductividadExtracciones
    values[value] = null
    this.props.actions.obtenerInformeProductividadExtracciones(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosProductividadExtracciones,
    combos: {
      comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria
    }
  } = this.props

  let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="intervencionSanitaria"
                name="intervencionSanitaria"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA')}
                component={InputSelect}
                options={comboTipoIntervencionSanitaria}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.GRUPO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeProductividadExtracciones.bind(this))}
            />
            <div className="clearfix"></div>
            <div style={{borderTop: '1px solid rgb(238, 238, 238)', marginTop: '10px', marginBottom: '10px'}}></div>
            
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    filtrosProductividadExtracciones.raza && filtrosProductividadExtracciones.raza !== null ? (
                      <span className="badge-dark">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.RAZA') + ': ' + filtrosProductividadExtracciones.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    filtrosProductividadExtracciones.lineaGenetica && filtrosProductividadExtracciones.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.LINEA_GENETICA') + ': ' + filtrosProductividadExtracciones.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    (filtrosProductividadExtracciones.fechaEntrada && filtrosProductividadExtracciones.fechaSalida && filtrosProductividadExtracciones.fechaEntrada !== null && filtrosProductividadExtracciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosProductividadExtracciones.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosProductividadExtracciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosProductividadExtracciones.fechaEntrada && filtrosProductividadExtracciones.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosProductividadExtracciones.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosProductividadExtracciones.fechaSalida && filtrosProductividadExtracciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosProductividadExtracciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    filtrosProductividadExtracciones.estado && filtrosProductividadExtracciones.estado !== null ? (
                      <span className="badge-dark">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.ESTADO') + ': ' + filtrosProductividadExtracciones.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    filtrosProductividadExtracciones.intervencionSanitaria && filtrosProductividadExtracciones.intervencionSanitaria !== null ? (
                      <span className="badge-dark">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA') + ': ' + filtrosProductividadExtracciones.intervencionSanitaria.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('intervencionSanitaria')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosProductividadExtracciones).length > 0 && (
                    filtrosProductividadExtracciones.grupoVerraco && filtrosProductividadExtracciones.grupoVerraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.GRUPO') + ': ' + filtrosProductividadExtracciones.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeProductividadExtracciones()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesProductividadExtracciones()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesProductividadExtracciones',
})(FiltrosInformesProductividadExtracciones))

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputCheckBox from '../comun/form/InputCheckBox'
import Button from '../comun/button/Button'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './Planing.scss'
import FiltrosModalPlaningPage from '../../containers/planing/FiltrosModalPlaningPage'

class ModalVerracoPlaning extends Component {
   constructor () {
    super()
    this.state = {
      classErrorVerracos: false,
      imputSelected: false
    }
  }

  componentDidMount () {
    this.props.actions.asignarVerracos({list: this.props.listVerracosAsignados})
  }

  anadirVerracosPlaning (values) {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'verraco': row.verraco,
          'nombreRaza': row.nombreRaza,
          'nombreLinea': row.nombreLinea,
          'diasUltimaExtraccion': row.diasUltimaExtraccion,
          'espermautil': row.espermautil,
          'xDosis': row.xDosis,
          'Ubicacion': row.Ubicacion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.anadirVerracosPlaning(list)
    this.props.change('seleccionar-todo-modal-verraco', false)
  }

  cerrarModalSeleccionarVerracoPlaning () {
    this.props.actions.cerrarModalSeleccionarVerracoPlaning()
  }

  render () {
  const {
    t, handleSubmit, showModalVerracoPlaning, abrirModalSeleccionarVerracoPlaning, listVerracos, checkedVerracos, numeroRegistrosPorPagina, paginaActual
  } = this.props

    // TABLA VERRACO
    listVerracos.forEach(
      (row, idRow) => row.idElemento = idRow + 1
    )
    const tableVerraco = {
      id: 'tableVerracoPlaning',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: false,
      noBlockOptions: false,
      paginar: false,
      columns: [
        {id: 'verraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'diasUltimaExtraccion', name: t('PLANING.MODAL.TABLE.DIAS_SIN_EXTRAER')},
        {id: 'espermautil', name: t('PLANING.MODAL.TABLE.ESPERMA_UTIL'), type: 'numero'},
        {id: 'xDosis', name: t('PLANING.MODAL.TABLE.X_DOSIS'), type: 'numero'},
        {id: 'Ubicacion', name: t('PLANING.MODAL.TABLE.UBICACION')}
      ],
      rows: listVerracos,
      initialValues: {}
    }

    tableVerraco.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerraco.initialValues['check' + row.idElemento] = checkedVerracos
        }
      }
    )

  return (
      <Modal show={showModalVerracoPlaning} onHide={() => this.cerrarModalSeleccionarVerracoPlaning()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {t('PLANING.MODAL.TABLE.TITLE')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix"></div>
            <FiltrosModalPlaningPage />
            <SimpleTablePage {...tableVerraco}></SimpleTablePage>
            <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirVerracosPlaning.bind(this))}>{t('PLANING.MODAL.ACEPTAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalSeleccionarVerracoPlaning()}>{t('ALMACEN.PRODUCTO.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVerracoPlaning',
})(ModalVerracoPlaning))

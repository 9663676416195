import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTipoAlimentacion from '../../../components/planSanitario/tipoAlimentacion/ModalTipoAlimentacion'
import {submitNuevoTipoAlimentacion, recuperarDatosModalTipoAlimentacion, editarTipoAlimentacion, guardarYnuevoTipoAlimentacion} from '../../../actions/planSanitario/tipoAlimentacion/tipoAlimentacion'
import {closeModal} from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.tipoAlimentacion,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevoTipoAlimentacion,
      recuperarDatosModalTipoAlimentacion,
      editarTipoAlimentacion,
      guardarYnuevoTipoAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTipoAlimentacion))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import {openMensajeAlerta} from '../../actions/common'
import {comboProductosActivosTrazabilidad, comboLotesProductoEspecificoActivoTrazabilidadSuccess} from '../../actions/combos/combos'
import ajusteStockActionTypes from '../../constants/actions/ajusteStock/ajusteStock'
import {
  fetchAjusteStockSuccess, openModalAjusteStockSuccess, closeModalAjusteStockSuccess, crearCsvAjusteStockSuccess, imprimirPdfAjusteStockSuccess
} from '../../actions/ajusteStock/ajusteStock'
import getAjusteStockListService from '../../services/ajusteStock/getAjusteStockList'
import addAjusteStockService from '../../services/ajusteStock/addAjusteStock'
import getCrearCsvService from '../../services/ajusteStock/getCrearCsv'
import getImprimirPdfAjusteStockService from '../../services/ajusteStock/getImprimirPdfAjusteStock'

import { yesNoModal } from '../modal/yesNoModal'

export function * fetchAjusteStock ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let list = []
    if (filtros && Object.keys(filtros).length > 0) {
      list = yield call(getAjusteStockListService, filtros, auth.token)
    } else {
      list = yield call(getAjusteStockListService, {}, auth.token)
    }
    yield put(fetchAjusteStockSuccess(list, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.FETCH_AJUSTE_STOCK, fetchAjusteStock)
}

export function * openModalAjusteStock () {
  try {
    yield put(comboProductosActivosTrazabilidad())
    yield put(comboLotesProductoEspecificoActivoTrazabilidadSuccess([]))

    const valores = {activo: true}
    yield put(initialize('ModalAjusteStock', valores))
    yield put(openModalAjusteStockSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.OPEN_MODAL_AJUSTE_STOCK, openModalAjusteStock)
}

export function * closeModalAjusteStock () {
  try {
    yield put(closeModalAjusteStockSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.CLOSE_MODAL_AJUSTE_STOCK, closeModalAjusteStock)
}

export function * guardarAjusteStock ({values, guardarYNuevo}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionAjusteStock = yield call(addAjusteStockService, values, auth.token)
    if (insercionAjusteStock === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    if (guardarYNuevo) {
      const valores = {activo: true}
      yield put(initialize('ModalAjusteStock', valores))
    } else {
      yield put(closeModalAjusteStockSuccess())
    }

    const filtros = yield select(state => state.ajusteStock.filtros)
    const list = yield call(getAjusteStockListService, filtros, auth.token)
    yield put(fetchAjusteStockSuccess(list, filtros))

    /*
    let valoresFiltros = yield select(state => state.ajusteStock.filtros)
    yield put(initialize('FiltrosAjusteStock', valoresFiltros))
    yield put(comboProductosActivos())
    yield put(comboLotesActivos())
    */
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.GUARDAR_AJUSTE_STOCK, guardarAjusteStock)
}

export function * crearCsvAjusteStock ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelAjusteStock = yield call(getCrearCsvService, list, auth.token)
    yield put(crearCsvAjusteStockSuccess(datosExcelAjusteStock))
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelAjusteStock.excel)
    let name = i18n.t('MENU.ALMACEN.AJUSTE_STOCK') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.CREATE_CSV_AJUSTE_STOCK, crearCsvAjusteStock)
}

export function * imprimirPdfAjusteStock ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfAjusteStock = yield call(getImprimirPdfAjusteStockService, list, auth.token)
    yield put(imprimirPdfAjusteStockSuccess(datosPdfAjusteStock))
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAjusteStock.pdf)
    let name = i18n.t('MENU.ALMACEN.AJUSTE_STOCK') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfAjusteStock () {
  yield takeLatest(ajusteStockActionTypes.IMPRIMIR_PDF_AJUSTE_STOCK, imprimirPdfAjusteStock)
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../../setting'
import DuplicarVerraco from '../../../components/fichaVerraco/duplicarVerraco/DuplicarVerraco'
import {comboLineaGeneticaActiva, comboRazaActiva, comboRaza, comboOperarioPredeterminado, comboSalaExtraccionActivo, comboCliente, comboUbicacion, comboTipoDosis, comboDireccionesClienteSinPredeterminado} from '../../../actions/combos/combos'
import { fetchEditarVerraco, submitEditarVerraco, fetchHistoricoEstadosVerraco, fetchHistoricoGrupoVerraco } from '../../../actions/fichaVerraco/editarVerraco/editarVerraco'
import { asignarCodRaza, recuperarDatosModalEvolucion, deleteEvolucion, duplicarDatosModalEvolucion, getAlimentacion, abrirModalCambioEstadoVerraco,
      recuperarDatosModalAlimentacion, deleteAlimentacion, duplicarDatosModalAlimentacion, getIndex, recuperarDatosModalIndex, deleteIndex, restartVerraco,
      duplicarDatosModalIndex, abrirModalUbicacion, submitNuevoVerraco, guardarYnuevoVerraco, fetchDuplicarVerraco, abrirModalVerracoCambioEstado } from '../../../actions/fichaVerraco/fichaVerraco'
import { uploadFile, quitarDirectorioTemporal, eliminaImagen, subirArchivos, eliminarArchvio } from '../../../actions/comun/comun'
import {ubicacionenesVacias} from '../../../actions/movimientoVerraco/movimientoVerraco'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: state.combos,
    fichaVerraco: {...state.fichaVerraco},
    formEditarVerraco: state.form.editarVerraco,
    movimientoVerraco: {...state.movimientoVerraco},
    cambioEstadoVerraco: state.cambioEstadoVerraco,
    urlRecursos: setting.default.urlRecursos,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboLineaGeneticaActiva,
      comboRazaActiva,
      comboRaza,
      comboOperarioPredeterminado,
      comboSalaExtraccionActivo,
      comboCliente,
      comboUbicacion,
      comboTipoDosis,
      recuperarDatosModalEvolucion,
      deleteEvolucion,
      duplicarDatosModalEvolucion,
      getAlimentacion,
      recuperarDatosModalAlimentacion,
      deleteAlimentacion,
      duplicarDatosModalAlimentacion,
      getIndex,
      recuperarDatosModalIndex,
      deleteIndex,
      duplicarDatosModalIndex,
      uploadFile,
      quitarDirectorioTemporal,
      eliminaImagen,
      subirArchivos,
      eliminarArchvio,
      fetchEditarVerraco,
      submitEditarVerraco,
      abrirModalUbicacion,
      submitNuevoVerraco,
      ubicacionenesVacias,
      abrirModalCambioEstadoVerraco,
      guardarYnuevoVerraco,
      fetchHistoricoEstadosVerraco,
      fetchHistoricoGrupoVerraco,
      fetchDuplicarVerraco,
      restartVerraco,
      abrirModalVerracoCambioEstado,
      comboDireccionesClienteSinPredeterminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DuplicarVerraco))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Launch, Print, FilterList, Refresh, Delete } from '@material-ui/icons'
import {date_formatter, date_parser, dateTimeZones} from '../../util/formatFunctions'

class FiltrosDilucion extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      quitarFiltroFechas: false
    }
    this.crearCsvDilucion = this.crearCsvDilucion.bind(this)
  }

  componentDidMount () {
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_DILUCION.${this.props.combos.comboDiluido[1].label}`)
    this.props.initialize({
      //estado: {...this.props.combos.comboDiluido[1], label: estadoLabel},
      fechaEntrada: fechaActual
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosDilucion()
    }
    if (this.props.datosExcelDilucion !== prevProps.datosExcelDilucion) {
      if (this.props.datosExcelDilucion.excel && this.props.datosExcelDilucion.excel !== null){
        let pdf = this.props.datosExcelDilucion.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.DILUCION') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfDilucion !== prevProps.datosPdfDilucion) {
      if (this.props.datosPdfDilucion.pdf && this.props.datosPdfDilucion.pdf !== null){
        let pdf = this.props.datosPdfDilucion.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.DILUCION') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosDilucion()
          }
        } else {
          this.props.change('seleccionar-todo', false)
          this.setState({ imputSelected: false })
          this.props.actions.deSeleccionarTodosDilucion()
        }
      }
    }
    if (this.props.filtros !== prevProps.filtros) {    
      this.setState({quitarFiltroFechas: false})
    }
  }

  fetchFiltrarDilucion(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.fetchFiltrarDilucion(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }


  crearCsvDilucion(){
    const filtros = {...this.props.filtros,estado:{value:true,label:"diluido"}};
    this.props.actions.crearCsvDilucion(filtros);
  }

  imprimirPdfDilucion () {
    const filtros = {...this.props.filtros,estado:{value:true,label:"diluido"}};
    this.props.actions.imprimirPdfDilucion(filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    if (value == 'fechaEntrada' || value == 'fechaSalida') {        
      let ultimoMes = new Date()      
      ultimoMes.setMonth(ultimoMes.getMonth() - 1)
      values['fechaEntrada'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['fechaSalida'] = null
      values['desde'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['hasta'] = null     
      this.props.actions.fetchFiltrarDilucion(values)
      this.props.change('fechaEntrada', ultimoMes)  
      this.props.change('fechaSalida', null)
      this.props.change('desde', ultimoMes)  
      this.props.change('hasta', null)     
      this.setState({quitarFiltroFechas: true})
    } else {
      values[value] = null
      if (value === 'fechaEntrada') {
        values.desde = null
      }
      if (value === 'fechaSalida') {
        values.hasta = null
      }
      this.props.actions.fetchFiltrarDilucion(values)
      this.props.change(value, null)
    }
    this.setState({fitrosSeleccionados: values})
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosDilucion()
    } else {
      this.props.actions.deSeleccionarTodosDilucion()
    }
  }

  deleteSeleccionDilucion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idDilucion': row.idDilucion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionDilucion(list)
  }

  render () {
  const {
    t, handleSubmit, filtros
  } = this.props
  const tKey = 'EXTRACCIONES.DILUCION.'

  let comboDiluido = this.props.combos.comboDiluido
  comboDiluido = comboDiluido.map((dilucion) => {
    return {...dilucion, label: t(`COMUN.COMBOS.ESTADO_DILUCION.${dilucion.label}`)}
  })

  let date1
  let date2
  let partes1
  let partes2
  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
          {/*
          <Field
            colSm={2}
            id="estado"
            name="estado"
            controlLabel={t('EXTRACCIONES.DILUCION.FILTROS.ESTADO_DILUCION')}
            component={InputSelect}
            options={comboDiluido}
            valueKey="value"
            labelKey="label"
            customClass='select-estado-dilucion'
            onInputChange={handleSubmit(this.fetchFiltrarDilucion.bind(this))}
          />*/}
          <Fields
            names={['fechaEntrada', 'fechaSalida']}
            component={InputRangeDatePickerDynamicNames}
            colSm={4}
            nameFrom="fechaEntrada"
            nameTo="fechaSalida"
            controlLabel={t('EXTRACCIONES.DILUCION.FILTROS.DESDE_HASTA')}
            onInputChange={handleSubmit(this.fetchFiltrarDilucion.bind(this))}
          />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estado ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ESTADO_DILUCION') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
                {(this.state.quitarFiltroFechas === true && <span> {t('ANALISIS_EXTRACCIONES.FILTROS.AVISO_FILTROS_CERRADOS')}</span>)}
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {/*
              <div className="custom-check-w" key="checkbox_key">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>
              */}
                <div id="block-options" key="blockOptions_key" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionDilucion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvDilucion()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfDilucion()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosDilucion',
  initialValues: {
    fechaEntrada: new Date()
  }
})(FiltrosDilucion))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalHistoricoExtracciones from '../../components/fichaVerraco/ModalHistoricoExtracciones'
import {cerrarModalHistoricoExtracciones} from '../../actions/fichaVerraco/editarVerraco/editarVerraco'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalHistoricoExtracciones
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalHistoricoExtracciones))
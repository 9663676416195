import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalDuplicarPlaning from '../../components/planing/ModalDuplicarPlaning'
import {cerrarDuplicarPlaning, enviarFechaDuplicarPlaning, submitDuplicarPlaning} from '../../actions/planing/planing'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos,
    formDuplicarPlaning: state.form.ModalDuplicarPlaning
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDuplicarPlaning,
      enviarFechaDuplicarPlaning,
      submitDuplicarPlaning
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDuplicarPlaning))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalPrevisionPedidos from '../../components/pedidosVenta/ModalPrevisionPedidos'
import {cerrarModalPrevisionPedidos, anadirPrevisionPedidos} from '../../actions/pedidosVenta/pedidosVenta'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({      
      cerrarModalPrevisionPedidos,
      anadirPrevisionPedidos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPrevisionPedidos))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosTrazabilidadMaterial from '../../components/trazabilidadMaterial/FiltrosTrazabilidadMaterial'
import {
  seleccionarTodosTrazabilidadMaterial, deSeleccionarTodosTrazabilidadMaterial, deleteSeleccionTrazabilidadMaterial
} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import { openModalMaterialDisponible } from '../../actions/trazabilidadMaterial/materialDisponible'

const mapStateToProps = (state) => {
  return {
    ...state.trazabilidadMaterial,
    listadoTrazabilidadMaterialTable: state.form.listadoTrazabilidadMaterialTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModalMaterialDisponible,
      seleccionarTodosTrazabilidadMaterial,
      deSeleccionarTodosTrazabilidadMaterial,
      deleteSeleccionTrazabilidadMaterial
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosTrazabilidadMaterial))
import actionTypes from '../../../constants/actions/planSanitario/controlCalidad/controlMorfologico'

export function initialState () {
  return {
    mainList: [],
    filtros: {},
    checkedFiltros: false,
    esApartadoPrincipal: false,
    list: [],
    conservacion: null,
    codigo: '',
    idAnalisis: null,
    idPool: null,
    showModalListado: false,
    checkedFiltrosListado: false,
    modalFormControlMorfologico: {
      data: {},
      showModalFormControlMorfologico: false
    },

    checkedInicio: false,
    filtrosInicio: [],
    listVerracos: [],
    listVerracosAsignados: [],
    checkedVerracosAnadidos: false,
    showModalInicio: false,
    data: {},
    tipoAnalisis: null
  }
}

export function fetchSuccess (state, {mainList, filtros}) {
  return {
    ...state,
    mainList,
    filtros,
    esApartadoPrincipal: true
  }
}

export function seleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: true
  }
}

export function deSeleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: false
  }
}

export function openModalListadoSuccess (state, {list, conservacion, codigo, idAnalisis, idPool}) {
  return {
    ...state,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool,
    showModalListado: true
  }
}

export function closeModalListadoSuccess (state) {
  return {
    ...initialState(),
    mainList: state.mainList,
    filtros: state.filtros,
    checkedFiltros: state.checkedFiltros,
    esApartadoPrincipal: state.esApartadoPrincipal
  }
}

export function seleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: true
  }
}

export function deSeleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: false
  }
}

export function openModalFormControlMorfologicoSuccess (state, {data}) {
  return {
    ...state,
    modalFormControlMorfologico: {
      ...state.modalFormControlMorfologico,
      data,
      showModalFormControlMorfologico: true
    }
  }
}

export function guardarmodalFormControlMorfologicoSuccess (state) {
  return {
    ...state,
    modalFormControlMorfologico: {
      ...state.modalFormControlMorfologico,
      showModalFormControlMorfologico: false
    }
  }
}

export function closemodalFormControlMorfologicoSuccess (state) {
  return {
    ...state,
    modalFormControlMorfologico: {
      ...state.modalFormControlMorfologico,
      showModalFormControlMorfologico: false
    }
  }
}

export function seleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: true
  }
}

export function deSeleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: false
  }
}

export function abrirModalInicio (state) {
  return {
    ...state,
    showModalInicio: true
  }
}

export function closeModalInicio (state) {
  return {
    ...state,
    showModalInicio: false,
    checkedInicio: false
  }
}

export function anadirVerracosModalInicioSuccess (state, {listVerracosAsignados}) {
  return {
    ...state,
    listVerracosAsignados: listVerracosAsignados
  }
}

export function fetchFiltrarModalInicioSuccess (state, {listVerracos,filtros}) {  
  return {
    ...state,
    listVerracos: listVerracos,    
    filtrosInicio: filtros
  }
}

export function guardarResultadoSistemaAnalisisControlMorfologico (state, {data, tipoAnalisis}) {
  return {
    ...state,
    data: data,
    tipoAnalisis: tipoAnalisis
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_CONTROL_MORFOLOGICO_SUCCESS:
      return fetchSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CONTROL_MORFOLOGICO:
      return seleccionarTodos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CONTROL_MORFOLOGICO:
      return deSeleccionarTodos(state, action)
    case actionTypes.OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS:
      return openModalListadoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS:
      return closeModalListadoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO:
      return seleccionarTodosModal(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO:
      return deSeleccionarTodosModal(state, action)
    case actionTypes.OPEN_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS:
      return openModalFormControlMorfologicoSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS:
      return guardarmodalFormControlMorfologicoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS:
      return closemodalFormControlMorfologicoSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_MODAL_INICIO_SUCCESS:
      return fetchFiltrarModalInicioSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO:
      return seleccionarTodosVerracosInicio(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO:
      return deSeleccionarTodosVerracosInicio(state, action)
    case actionTypes.ABRIR_MODAL_INICIO:
      return abrirModalInicio(state, action)
    case actionTypes.CLOSE_MODAL_INICIO:
      return closeModalInicio(state, action)
    case actionTypes.GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO:
      return guardarResultadoSistemaAnalisisControlMorfologico(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { comprobarEntorno } from '../../actions/auth/auth'
import PedidosVenta from '../../components/pedidosVenta/PedidosVenta'
import { fetchFiltrarPedidosVenta, recuperarDatosModalPedidosVenta, duplicarDatosModalPedidosVenta, onDeletePedidosVenta } from '../../actions/pedidosVenta/pedidosVenta'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    isCloud: state.auth.isCloud,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comprobarEntorno,
      fetchFiltrarPedidosVenta,
      recuperarDatosModalPedidosVenta,
      duplicarDatosModalPedidosVenta,
      onDeletePedidosVenta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PedidosVenta))
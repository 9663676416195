import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { comboCliente, comboRuta, comboRaza, comboLineaGenetica, comboTipoDosis, comboLineaGeneticaGranja, comboTipoDosisGranja, comboNivelGenetico } from '../../actions/combos/combos'
import FiltrosAsignacionPedidos from '../../components/asignacionPedidos/FiltrosAsignacionPedidos'
import { fetchLineasAlbaranes, deleteSeleccionDosisEnvasadas } from '../../actions/asignacionPedidos/asignacionPedidos'
import { obtenerInformeStockDosis } from '../../actions/informes/informes'
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    asignacionPedidos: state.asignacionPedidos,
    formSimpleTable: state.form.simpleTable,
    listDosis:state.informes.listInformeStockDosis,
    combos: {      
      comboNumerosAlbaran: state.combos.comboNumerosAlbaran,
      comboRaza: state.combos.comboRaza,
      comboCliente: state.combos.comboCliente,
      comboLineaGenetica: state.combos.comboLineaGenetica,      
      comboRuta: state.combos.comboRuta,
      comboTipoDosis: state.combos.comboTipoDosis,
      comboNivelGenetico: state.combos.comboNivelGenetico
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({    
      obtenerInformeStockDosis,  
      fetchLineasAlbaranes,
      comboCliente,
      comboRuta,
      comboTipoDosis,
      comboLineaGeneticaGranja, 
      comboTipoDosisGranja,
      comboRaza,
      comboLineaGenetica,
      comboNivelGenetico,
      deleteSeleccionDosisEnvasadas
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAsignacionPedidos))
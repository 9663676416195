import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import ModalNuevoPoolAsignacion from '../../components/asignacionPoolVerraco/ModalNuevoPoolAsignacion';
import { guardarModalPoolAnalisisExtraccion } from '../../actions/analisisExtracciones/analisisExtracciones'
import { withTranslation } from 'react-i18next'
import {closeModal, createNewPool, obtenerNumeroPool} from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: {
      comboTipoDosis: state.combos.comboTipoDosis
    }, 
    showModal: state.asignacionAnalisisPool.showModal,
    extraccion: state.asignacionAnalisisPool.extraccion,
    modalNuevoPoolAsignacion: getFormValues('modalNuevoPoolAsignacion')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      createNewPool,
      obtenerNumeroPool,
      guardarModalPoolAnalisisExtraccion
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalNuevoPoolAsignacion)))
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import { fetchControlSemen, deleteControlSemen, abrirModalInicio, descargarArchivoControlSemen, recuperarDatosModalControlSemen, guardarDetalleControlSemen, subirArchivosInforme  } from '../../../actions/planSanitario/controlSemen/controlSemen'
import ControlSemen from '../../../components/planSanitario/controlSemen/ControlSemen'

export function mapStateToProps (state) {
  return {
    ...state.controlSemen,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlSemen,
      deleteControlSemen,
      yesNoModal,
      abrirModalInicio,
      descargarArchivoControlSemen,
      recuperarDatosModalControlSemen,
      guardarDetalleControlSemen,
      subirArchivosInforme
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlSemen))
import actionTypes from '../../constants/actions/instalacion/instalacion'

export function initialState () {
  return {
    list: [],
    checkedFiltrosInstalacion: false,
    filtros:{},
    datosExcelInstalacion: [],
    datosPdfInstalacion: [],
    data:[],
    dataDuplicar: []
  }
}

export function fetchInstalacionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalInstalacionSuccess (state, {data}) {
  return {
    ...state,
    data,
    dataDuplicar: []
  }
}

export function duplicarDatosModalInstalacionSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar,
    data: []
  }
}

export function cambiarEstadoInstalacionSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteInstalacionSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosInstalacion (state) {
  return {
    ...state,
    checkedFiltrosInstalacion: true
  }
}

export function deSeleccionarTodosInstalacion (state) {
  return {
    ...state,
    checkedFiltrosInstalacion: false
  }
}

export function fetchFiltrarInstalacionSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function crearCsvInstalacionSuccess (state, {datosExcelInstalacion}) {
  return {
    ...state,
    datosExcelInstalacion
  }
}

export function imprimirPdfInstalacionSuccess (state, {datosPdfInstalacion}) {
  return {
    ...state,
    datosPdfInstalacion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_INSTALACION_SUCCESS:
      return fetchInstalacionSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_INSTALACION_SUCCESS:
      return recuperarDatosModalInstalacionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_INSTALACION_SUCCESS:
      return duplicarDatosModalInstalacionSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_INSTALACION_SUCCESS:
      return cambiarEstadoInstalacionSuccess(state, action)
    case actionTypes.ON_DELETE_INSTALACION_SUCCESS:
      return onDeleteInstalacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_INSTALACION:
      return seleccionarTodosInstalacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_INSTALACION:
      return deSeleccionarTodosInstalacion(state, action)
    case actionTypes.FETCH_FILTRAR_INSTALACION_SUCCESS:
      return fetchFiltrarInstalacionSuccess(state, action)
    case actionTypes.CREATE_CSV_INSTALACION_SUCCESS:
      return crearCsvInstalacionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INSTALACION_SUCCESS:
      return imprimirPdfInstalacionSuccess(state, action)
    default:
      return state
  }
}
export default {
  FETCH_USUARIOS: 'FETCH_USUARIOS',
  FETCH_USUARIOS_SUCCESS: 'FETCH_USUARIOS_SUCCESS',
  ABRIR_MODAL_USUARIOS: 'ABRIR_MODAL_USUARIOS',
  CERRAR_MODAL_USUARIOS: 'CERRAR_MODAL_USUARIOS',
  FILTRAR_USUARIOS_SUCCESS: 'FILTRAR_USUARIOS_SUCCESS',
  UPLOAD_FILE_USUARIOS: 'UPLOAD_FILE_USUARIOS',
  FETCH_CENTROS_USUARIOS: 'FETCH_CENTROS_USUARIOS',
  FETCH_CENTROS_USUARIOS_SUCCESS: 'FETCH_CENTROS_USUARIOS_SUCCESS',
  FETCH_USUARIO_GRUPO_USUARIOS: 'FETCH_USUARIO_GRUPO_USUARIOS',
  FETCH_USUARIO_GRUPO_USUARIOS_SUCCESS: 'FETCH_USUARIO_GRUPO_USUARIOS_SUCCESS',
  ABRIR_MODAL_LIST_CENTRO: 'ABRIR_MODAL_LIST_CENTRO',
  CERRAR_MODAL_LIST_CENTRO: 'CERRAR_MODAL_LIST_CENTRO',
  ABRIR_MODAL_GRUPOS_USUARIOS: 'ABRIR_MODAL_GRUPOS_USUARIOS',
  CERRAR_MODAL_GRUPOS_USUARIOS: 'CERRAR_MODAL_GRUPOS_USUARIOS',
  SELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO: 'SELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO',
  DESELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO: 'DESELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO',
  ANADIR_GRUPO_USUARIO: 'ANADIR_GRUPO_USUARIO',
  ELIMINAR_GRUPO_USUARIOS_ANADIDOS: 'ELIMINAR_GRUPO_USUARIOS_ANADIDOS',
  SELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO: 'SELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO',
  DESELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO: 'DESELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO',
  SELECCIONAR_TODOS_MODAL_LIST_CENTROS: 'SELECCIONAR_TODOS_MODAL_LIST_CENTROS',
  DESELECCIONAR_TODOS_MODAL_LIST_CENTROS: 'DESELECCIONAR_TODOS_MODAL_LIST_CENTROS',
  ANADIR_LIST_CENTROS: 'ANADIR_LIST_CENTROS',
  ELIMINAR_CENTROS_ANADIDOS: 'ELIMINAR_CENTROS_ANADIDOS',
  SELECCIONAR_TODOS_TABLA_CENTROS: 'SELECCIONAR_TODOS_TABLA_CENTROS',
  DESELECCIONAR_TODOS_TABLA_CENTROS: 'DESELECCIONAR_TODOS_TABLA_CENTROS',
  SUBMIT_NUEVO_USUARIO: 'SUBMIT_NUEVO_USUARIO',
  SUBMIT_NUEVO_USUARIO_SUCCESS: 'SUBMIT_NUEVO_USUARIO_SUCCESS',
  RECUPERAR_DATOS_USUARIO: 'RECUPERAR_DATOS_USUARIO',
  RECUPERAR_DATOS_USUARIO_SUCCESS: 'RECUPERAR_DATOS_USUARIO_SUCCESS',
  EDITAR_USUARIOS: 'EDITAR_USUARIOS',
  DUPLICAR_USUARIO: 'DUPLICAR_USUARIO',
  DUPLICAR_USUARIO_SUCCESS: 'DUPLICAR_USUARIO_SUCCESS',
  CAMBIAR_ESTADO_USUARIO: 'CAMBIAR_ESTADO_USUARIO',
  ELIMINAR_USUARIO: 'ELIMINAR_USUARIO',
  SELECCIONAR_TODOS_USUARIOS: 'SELECCIONAR_TODOS_USUARIOS',
  DESELECCIONAR_TODOS_USUARIOS: 'DESELECCIONAR_TODOS_USUARIOS',
  DELETE_SELECCION_USUARIO: 'DELETE_SELECCION_USUARIO',
  CAMBIO_ESTADO_SELECCION_USUARIO: 'CAMBIO_ESTADO_SELECCION_USUARIO',
  CREAR_CSV_USUARIOS: 'CREAR_CSV_USUARIOS',
  CREAR_CSV_USUARIOS_SUCCESS: 'CREAR_CSV_USUARIOS_SUCCESS',
  IMPRIMIR_PDF_USUARIOS: 'IMPRIMIR_PDF_USUARIOS',
  IMPRIMIR_PDF_USUARIOS_SUCCESS: 'IMPRIMIR_PDF_USUARIOS_SUCCESS',
  CAMBIAR_PASSWORD_USUARIO: 'CAMBIAR_PASSWORD_USUARIO',
  CAMBIAR_PASSWORD_USUARIO_SUCCESS: 'CAMBIAR_PASSWORD_USUARIO_SUCCESS',
  ABRIR_MODAL_CAMBIAS_PASS: 'ABRIR_MODAL_CAMBIAS_PASS',
  CERRAR_MODAL_CAMBIAS_PASS: 'CERRAR_MODAL_CAMBIAS_PASS',
  ABRIR_MODAL_CAMBIAR_TOKEN_MAGAVISION: 'ABRIR_MODAL_CAMBIAR_TOKEN_MAGAVISION',
  CERRAR_MODAL_CAMBIAR_TOKEN_MAGAVISION: 'CERRAR_MODAL_CAMBIAR_TOKEN_MAGAVISION',
  CAMBIAR_TOKEN_MAGAVISION: 'CAMBIAR_TOKEN_MAGAVISION',
  SUBMIT_CAMBIAR_TOKEN_MAGAVISION: 'SUBMIT_CAMBIAR_TOKEN_MAGAVISION'
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesEstadoCentro from '../../components/informes/FiltrosInformesEstadoCentro'
import {obtenerInformeEstadoCentro, imprimirPdfInformesEstadoCentro, crearCsvInformesEstadoCentro} from '../../actions/informes/informes'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeEstadoCentro,
      imprimirPdfInformesEstadoCentro,
      crearCsvInformesEstadoCentro
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesEstadoCentro))
import actionTypes from '../../constants/actions/empresa/empresa'

export function initialState () {
  return {
    listEmpresa: [],
    listCentros: [],
    dataCentro: [],
    dataCentroDuplicar: [],
    showModalCentro: false,
    imagen: [],
    checkCentros: false,
    datosExcelEmpresa: [],
    datosPdfEmpresa: []
  }
}

export function fetchEmpresaSuccess (state, {listEmpresa}) {
  return {
    ...state,
    listEmpresa: listEmpresa.empresa,
    listCentros: listEmpresa.centros
  }
}

export function abrirModalCentro (state, {listEmpresa}) {
  return {
    ...state,
    showModalCentro: true
  }
}

export function cerrarModalCentro (state, {listEmpresa}) {
  return {
    ...state,
    showModalCentro: false,
    dataCentro: [],
    dataCentroDuplicar: [],
    imagen: []
  }
}

export function uploadFileEmpresa (state, {imagen}) {
  return {
    ...state,
    imagen: imagen
  }
}

export function recuperarDatosCentroSuccess (state, {dataCentro}) {
  return {
    ...state,
    dataCentro: dataCentro,
    dataCentroDuplicar: [],
    showModalCentro: true
  }
}

export function dataCentroDuplicar (state, {dataCentroDuplicar}) {
  return {
    ...state,
    dataCentroDuplicar: dataCentroDuplicar,
    dataCentro: [],
    showModalCentro: true
  }
}

export function seleccionarTodosEmpresa (state) {
  return {
    ...state,
    checkCentros: true
  }
}

export function deSeleccionarTodosEmpresa (state) {
  return {
    ...state,
    checkCentros: false
  }
}

export function crearCsvEmpresaSuccess (state, {datosExcelEmpresa}) {
  return {
    ...state,
    datosExcelEmpresa: datosExcelEmpresa
  }
}

export function imprimirPdfEmpresaSuccess (state, {datosPdfEmpresa}) {
  return {
    ...state,
    datosPdfEmpresa: datosPdfEmpresa
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_EMPRESA_SUCCESS:
      return fetchEmpresaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_CENTRO:
      return abrirModalCentro(state, action)
    case actionTypes.CERRAR_MODAL_CENTRO:
      return cerrarModalCentro(state, action)
    case actionTypes.UPLOAD_FILE_EMPRESA:
      return uploadFileEmpresa(state, action)
    case actionTypes.RECUPERAR_DATOS_CENTRO_SUCCESS:
      return recuperarDatosCentroSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_CENTRO_SUCCESS:
      return dataCentroDuplicar(state, action)
    case actionTypes.SELECCIONAR_TODOS_EMPRESA:
      return seleccionarTodosEmpresa(state, action)
    case actionTypes.DESELECCIONAR_TODOS_EMPRESA:
      return deSeleccionarTodosEmpresa(state, action)
    case actionTypes.CREAR_CSV_EMPRESA_SUCCESS:
      return crearCsvEmpresaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_EMPRESA_SUCCESS:
      return imprimirPdfEmpresaSuccess(state, action)
    default:
      return state
  }
}
export default {
  FETCH_COMBO_CAUSA_ELIMINACION: 'FETCH_COMBO_CAUSA_ELIMINACION',
  FETCH_COMBO_CAUSA_ELIMINACION_SUCCESS: 'FETCH_COMBO_CAUSA_ELIMINACION_SUCCESS',
  SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION: 'SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION',
  SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION_SUCCESS: 'SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION_SUCCESS',
  RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION: 'RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION',
  RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION_SUCCESS',
  ABRIR_MODAL_VACIO_COMBO_CAUSA_ELIMINACION: 'ABRIR_MODAL_VACIO_COMBO_CAUSA_ELIMINACION',
  EDITAR_COMBO_CAUSA_ELIMINACION: 'EDITAR_COMBO_CAUSA_ELIMINACION',
  EDITAR_COMBO_CAUSA_ELIMINACION_SUCCESS: 'EDITAR_COMBO_CAUSA_ELIMINACION_SUCCESS',
  ON_DELETE_COMBO_CAUSA_ELIMINACION: 'ON_DELETE_COMBO_CAUSA_ELIMINACION',
  ON_DELETE_COMBO_CAUSA_ELIMINACION_SUCCESS: 'ON_DELETE_COMBO_CAUSA_ELIMINACION_SUCCESS'
}

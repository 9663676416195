export default {
  FETCH_GRUPO_VERRACO: 'FETCH_GRUPO_VERRACO',
  FETCH_GRUPO_VERRACO_SUCCESS: 'FETCH_GRUPO_VERRACO_SUCCESS',
  SUBMIT_NUEVO_GRUPO_VERRACO: 'SUBMIT_NUEVO_GRUPO_VERRACO',
  SUBMIT_NUEVO_GRUPO_VERRACO_SUCCESS: 'SUBMIT_NUEVO_GRUPO_VERRACO_SUCCESS',
  RECUPERAR_DATOS_MODAL_GRUPO_VERRACO: 'RECUPERAR_DATOS_MODAL_GRUPO_VERRACO',
  RECUPERAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS: 'RECUPERAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS',
  ABRIR_MODAL_VACIO_GRUPO_VERRACO: 'ABRIR_MODAL_VACIO_GRUPO_VERRACO',
  EDITAR_GRUPO_VERRACO: 'EDITAR_GRUPO_VERRACO',
  EDITAR_GRUPO_VERRACO_SUCCESS: 'EDITAR_GRUPO_VERRACO_SUCCESS',
  DUPLICAR_DATOS_MODAL_GRUPO_VERRACO: 'DUPLICAR_DATOS_MODAL_GRUPO_VERRACO',
  DUPLICAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS: 'DUPLICAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS',
  CAMBIAR_ESTADO_GRUPO_VERRACO: 'CAMBIAR_ESTADO_GRUPO_VERRACO',
  CAMBIAR_ESTADO_GRUPO_VERRACO_SUCCESS: 'CAMBIAR_ESTADO_GRUPO_VERRACO_SUCCESS',
  ON_DELETE_GRUPO_VERRACO: 'ON_DELETE_GRUPO_VERRACO',
  ON_DELETE_GRUPO_VERRACO_SUCCESS: 'ON_DELETE_GRUPO_VERRACO_SUCCESS',
  FETCH_FILTRAR_GRUPO_VERRACO: 'FETCH_FILTRAR_GRUPO_VERRACO',
  FETCH_FILTRAR_GRUPO_VERRACO_SUCCESS: 'FETCH_FILTRAR_GRUPO_VERRACO_SUCCESS',
  SELECCIONAR_TODOS_GRUPO_VERRACO: 'SELECCIONAR_TODOS_GRUPO_VERRACO',
  DESELECCIONAR_TODOS_GRUPO_VERRACO: 'DESELECCIONAR_TODOS_GRUPO_VERRACO',
  CAMBIAR_ESTADO_SELECCION_GRUPO_VERRACO: 'CAMBIAR_ESTADO_SELECCION_GRUPO_VERRACO',
  DELETE_SELECCION_GRUPO_VERRACO: 'DELETE_SELECCION_GRUPO_VERRACO',
  CREATE_CSV_GRUPO_VERRACO: 'CREATE_CSV_GRUPO_VERRACO',
  GUARDAR_Y_NUEVO_GRUPO_VERRACO: 'GUARDAR_Y_NUEVO_GRUPO_VERRACO',
  SELECCIONAR_TODOS_MODAL_GRUPO_VERRACO: 'SELECCIONAR_TODOS_MODAL_GRUPO_VERRACO',
  DESELECCIONAR_TODOS_MODAL_GRUPO_VERRACO: 'DESELECCIONAR_TODOS_MODAL_GRUPO_VERRACO',
  ABRIR_MODAL_SELECCIONAR_VERRACO: 'ABRIR_MODAL_SELECCIONAR_VERRACO',
  CERRAR_MODAL_SELECCIONAR_VERRACO: 'CERRAR_MODAL_SELECCIONAR_VERRACO',
  FETCH_VERRACOS_GRUPO_VERRACO: 'FETCH_VERRACOS_GRUPO_VERRACO',
  FETCH_VERRACOS_GRUPO_VERRACO_SUCCESS: 'FETCH_VERRACOS_GRUPO_VERRACO_SUCCESS',
  SELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO: 'SELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO',
  DESELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO: 'DESELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO',
  ANADIR_VERRACO: 'ANADIR_VERRACO',
  ANADIR_VERRACO_SUCCESS: 'ANADIR_VERRACO_SUCCESS',
  ON_DELETE_VERRACO_ANADIDO: 'ON_DELETE_VERRACO_ANADIDO',
  ON_DELETE_VERRACO_ANADIDO_SUCCESS: 'ON_DELETE_VERRACO_ANADIDO_SUCCESS',
  DELETE_SELECCION_VERRACOS_GRUPO_VERRACO: 'DELETE_SELECCION_VERRACOS_GRUPO_VERRACO',
  FETCH_FILTRAR_VERRACO_MODAL_VERRACO: 'FETCH_FILTRAR_VERRACO_MODAL_VERRACO',
  FETCH_FILTRAR_VERRACO_MODAL_VERRACO_SUCCESS: 'FETCH_FILTRAR_VERRACO_MODAL_VERRACO_SUCCESS',
  CREATE_CSV_GRUPO_VERRACO: 'CREATE_CSV_GRUPO_VERRACO',
  CREATE_CSV_GRUPO_VERRACO_SUCCESS: 'CREATE_CSV_GRUPO_VERRACO_SUCCESS',
  IMPRIMIR_PDF_GRUPO_VERRACO: 'IMPRIMIR_PDF_GRUPO_VERRACO',
  IMPRIMIR_PDF_GRUPO_VERRACO_SUCCESS: 'IMPRIMIR_PDF_GRUPO_VERRACO_SUCCESS'
}
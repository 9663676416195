import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosTipoAlimentacion from '../../../components/planSanitario/tipoAlimentacion/FiltrosTipoAlimentacion'
import {openModal} from '../../../actions/common'
import {abrirModalVacioTipoAlimentacion, seleccionarTodosTipoAlimentacion, deSeleccionarTodosTipoAlimentacion, cambiarEstadoSeleccionTipoAlimentacion, deleteSeleccionTipoAlimentacion,
  crearCsvTipoAlimentacion, imprimirPdfTipoAlimentacion} from '../../../actions/planSanitario/tipoAlimentacion/tipoAlimentacion'

export function mapStateToProps (state) {
  return {
    ...state.tipoAlimentacion,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioTipoAlimentacion,
      seleccionarTodosTipoAlimentacion,
      deSeleccionarTodosTipoAlimentacion,
      cambiarEstadoSeleccionTipoAlimentacion,
      deleteSeleccionTipoAlimentacion,
      crearCsvTipoAlimentacion,
      imprimirPdfTipoAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosTipoAlimentacion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import CambioEstadoVerraco from '../../components/cambioEstadoVerraco/CambioEstadoVerraco'
import {abrirModalUbicacion, cerrarModalUbicacion} from '../../actions/fichaVerraco/fichaVerraco'
import { fetchCambioEstadoVerraco, recuperarDatosModalCambioEstadoVerraco, duplicarDatosModalCambioEstadoVerraco, onDeleteCambioEstadoVerraco, fetchFiltrarCambioEstadoVerraco} from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'

export function mapStateToProps (state) {
  return {
    showModalUbicacion: state.fichaVerraco.showModalUbicacion,
    combos: {...state.combos},
    fichaVerraco: {...state.fichaVerraco},
    cambioEstadoVerraco: state.cambioEstadoVerraco,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalUbicacion,
      abrirModalUbicacion,
      fetchCambioEstadoVerraco,
      recuperarDatosModalCambioEstadoVerraco,
      duplicarDatosModalCambioEstadoVerraco,
      onDeleteCambioEstadoVerraco,
      fetchFiltrarCambioEstadoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CambioEstadoVerraco))
import actionTypes from '../../constants/actions/planing/planing'

export function initialState () {
  return {
    diasPlaning: 15,
    showModalPlaning: false,
    showModalVerracoPlaning: false,
    listPlaning: [],
    filtros: [],
    listVerracos: [],
    listVerracosAsignados: [],
    checkedVerracos: false,
    checkedVerracosAnadidos: false,
    showModalInicioPlaning: false,
    listVerracosSinExtraer: [],
    datosModalInicioPlaning: [],
    datosExcelModalInicioPlaning: [],
    datosTotalesPlaning: [],
    datosTotalesModalPlaning: {},
    datosTotalesPlaningAlbaran: [],
    showModalExportarImprimirPlaning: false,
    exportarImprimir: '',
    datosExportarPlaning: [],
    datosImprimirPlaning: [],
    filtrosSugerenciasPlaning: [],
    datosVerracoPlaning: [],
    order: true,
    dataExperotarSugerenciasExtracciones: [],
    dataImprimirSugerenciasExtracciones: [],
    checkedPlanning: false,
    showModalDuplicarPlaning: false,
    datosDuplicar: [],
    checkedPlanningPrincipal: false,
    checkedSugerenciasPlanning: false,
    checkedQuitarSugerenciaPlanning: false
  }
}

export function elegirDiasPlaning (state, {value}) {
  return {
    ...state,
    diasPlaning: value.value
  }
}

export function abrirModalPlaning (state) {
  return {
    ...state,
    showModalPlaning: true
  }
}

export function closeModalPlaning (state) {
  return {
    ...state,
    showModalPlaning: false,
    listVerracosAsignados: [],
    datosVerracoPlaning: [],
    filtrosSugerenciasPlaning: []
  }
}

export function abrirModalSeleccionarVerracoPlaning (state) {
  return {
    ...state,
    showModalVerracoPlaning: true
  }
}

export function cerrarModalSeleccionarVerracoPlaning (state) {
  return {
    ...state,
    showModalVerracoPlaning: false
  }
}

export function fetchPlaningSuccess (state, {listPlaning}) {
  console.log('listPlaning',listPlaning)
  return {
    ...state,
    listPlaning: listPlaning
  }
}

export function fetchFiltrarPlaningSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function asignarVerracosSuccess (state, {listVerracos}) {
  return {
    ...state,
    listVerracos: listVerracos
  }
}

export function seleccionarTodosVerracosPlaning (state) {
  return {
    ...state,
    checkedVerracos: true
  }
}

export function deSeleccionarTodosVerracosPlaning (state) {
  return {
    ...state,
    checkedVerracos: false
  }
}

export function anadirVerracosPlaning (state, {listVerracosAsignados}) {
  return {
    ...state,
    listVerracosAsignados: state.listVerracosAsignados.concat(listVerracosAsignados),
    showModalVerracoPlaning: false
  }
}

export function onDeleteVerracoPlaningSuccess (state, {listVerracosAsignados}) {
  return {
    ...state,
    listVerracosAsignados: listVerracosAsignados
  }
}

export function seleccionarTodosModalPlaning (state) {
  return {
    ...state,
    checkedVerracosAnadidos: true
  }
}

export function deSeleccionarTodosModalPlaning (state) {
  return {
    ...state,
    checkedVerracosAnadidos: false
  }
}

export function abrirModalInicioPlaning (state) {
  return {
    ...state,
    showModalInicioPlaning: true
  }
}

export function closeModalInicioPlaning (state) {
  return {
    ...state,
    showModalInicioPlaning: false,
    checkedPlanning: false
  }
}

export function obtenerVerracosSinExtraerSuccess (state, {listVerracosSinExtraer}) {
  return {
    ...state,
    listVerracosSinExtraer: listVerracosSinExtraer
  }
}

export function imprimirModalInicioPlaningSuccess (state, {datosModalInicioPlaning}) {
  return {
    ...state,
    datosModalInicioPlaning: datosModalInicioPlaning
  }
}

export function exportarModalInicioPlaningSuccess (state, {datosExcelModalInicioPlaning}) {
  return {
    ...state,
    datosExcelModalInicioPlaning: datosExcelModalInicioPlaning
  }
}

export function totalesPlaningSuccess (state, {datosTotalesPlaning}) {
  return {
    ...state,
    datosTotalesPlaning: datosTotalesPlaning.totales
  }
}

export function totalesModalPlaningSuccess (state, {datosTotalesModalPlaning}) {
  return {
    ...state,
    datosTotalesModalPlaning: datosTotalesModalPlaning.totales
  }
}

export function totalesPlaningAlbaranSuccess (state, {datosTotalesPlaningAlbaran}) {
  return {
    ...state,
    datosTotalesPlaningAlbaran: datosTotalesPlaningAlbaran.totales
  }
}

export function abrirModalExportarImprimirPlaning (state, {valor}) {
  return {
    ...state,
    showModalExportarImprimirPlaning: true,
    exportarImprimir: valor
  }
}

export function cerrarModalExportarImprimirPlaning (state, {valor}) {
  return {
    ...state,
    showModalExportarImprimirPlaning: false,
    exportarImprimir: '',
    datosExportarPlaning: [],
    datosImprimirPlaning: []
  }
}

export function exportarPlaningSuccess (state, {datosExportarPlaning}) {
  return {
    ...state,
    datosExportarPlaning: datosExportarPlaning
  }
}

export function imprimirPlaningSuccess (state, {datosImprimirPlaning}) {
  return {
    ...state,
    datosImprimirPlaning: datosImprimirPlaning
  }
}
export function imprimirZebraVerracosSuccess (state, {datosImprimirZebraVerracos}) {
  return {
    ...state,
    datosImprimirZebraVerracos: datosImprimirZebraVerracos
  }
}
export function filtrosModalSugerenciasPlaning (state, {filtrosSugerenciasPlaning}) {
  return {
    ...state,
    filtrosSugerenciasPlaning: filtrosSugerenciasPlaning
  }
}

export function obtenerDatosVerracoPlaning (state, {datosVerracoPlaning}) {
  return {
    ...state,
    datosVerracoPlaning: datosVerracoPlaning
  }
}

export function fetchOrderPlaning (state, {order}) {
  return {
    ...state,
    order: order
  }
}

export function exportarSugerenciaExtraccionesSuccess (state, {dataExperotarSugerenciasExtracciones}) {
  return {
    ...state,
    dataExperotarSugerenciasExtracciones: dataExperotarSugerenciasExtracciones
  }
}

export function imprimirSugerenciaExtraccionesSuccess (state, {dataImprimirSugerenciasExtracciones}) {
  return {
    ...state,
    dataImprimirSugerenciasExtracciones: dataImprimirSugerenciasExtracciones
  }
}

export function seleccionarTodosPlanning (state) {
  return {
    ...state,
    checkedPlanning: true
  }
}

export function deSeleccionarTodosPlanning (state) {
  return {
    ...state,
    checkedPlanning: false
  }
}

export function abrirDuplicarPlaning (state) {
  return {
    ...state,
    showModalDuplicarPlaning: true
  }
}

export function cerrarDuplicarPlaning (state) {
  return {
    ...state,
    showModalDuplicarPlaning: false,
    datosDuplicar: []
  }
}

export function enviarFechaDuplicarPlaningSuccess (state, {datosDuplicar}) {
  return {
    ...state,
    datosDuplicar: datosDuplicar
  }
}

export function seleccionarTodosPlanningPrincipal (state) {
  return {
    ...state,
    checkedPlanningPrincipal: true
  }
}

export function deSeleccionarTodosPlanningPrincipal (state) {
  return {
    ...state,
    checkedPlanningPrincipal: false
  }
}

export function seleccionarTodosSugerenciasPlanning (state) {
  return {
    ...state,
    checkedSugerenciasPlanning: true
  }
}

export function deSeleccionarTodosSugerenciasPlanning (state) {
  return {
    ...state,
    checkedSugerenciasPlanning: false
  }
}

export function seleccionarTodosQuitarSugerenciasPlanning (state) {
  return {
    ...state,
    checkedSugerenciasPlanning: true
  }
}

export function deSeleccionarTodosQuitarSugerenciasPlanning (state) {
  return {
    ...state,
    checkedSugerenciasPlanning: false
  }
}

export function obtenerPrevisionVerracosSuccess (state, {listVerracos}) {
  return {
    ...state,
    listVerracosAsignados: state.listVerracosAsignados.concat(listVerracos)    
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ELEGIR_DIAS_PLANING:
      return elegirDiasPlaning(state, action)
    case actionTypes.ABRIR_MODAL_PLANING:
      return abrirModalPlaning(state, action)
    case actionTypes.CLOSE_MODAL_PLANING:
      return closeModalPlaning(state, action)
    case actionTypes.ABRIR_MODAL_SELECCIONAR_VERRACO_PLANING:
      return abrirModalSeleccionarVerracoPlaning(state, action)
    case actionTypes.CERRAR_MODAL_SELECCIONAR_VERRACO_PLANING:
      return cerrarModalSeleccionarVerracoPlaning(state, action)
    case actionTypes.FETCH_PLANING_SUCCESS:
      return fetchPlaningSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_PLANING_SUCCESS:
      return fetchFiltrarPlaningSuccess(state, action)
    case actionTypes.ASIGNAR_VERRACOS_SUCCCESS:
      return asignarVerracosSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACOS_PLANING:
      return seleccionarTodosVerracosPlaning(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACOS_PLANING:
      return deSeleccionarTodosVerracosPlaning(state, action)
    case actionTypes.ANADIR_VERRACOS_PLANING:
      return anadirVerracosPlaning(state, action)
    case actionTypes.ON_DELETE_VERRACO_PLANING_SUCCESS:
      return onDeleteVerracoPlaningSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_PLANING:
      return seleccionarTodosModalPlaning(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_PLANING:
      return deSeleccionarTodosModalPlaning(state, action)
      case actionTypes.ABRIR_MODAL_INICIO_PLANING:
      return abrirModalInicioPlaning(state, action)
    case actionTypes.CLOSE_MODAL_INICIO_PLANING:
      return closeModalInicioPlaning(state, action)
    case actionTypes.OBTENER_VERRACOS_SIN_EXTRAER_SUCCESS:
      return obtenerVerracosSinExtraerSuccess(state, action)
    case actionTypes.IMPRIMIR_MODAL_INICIO_PLANING_SUCCESS:
      return imprimirModalInicioPlaningSuccess(state, action)
    case actionTypes.EXPORTAR_MODAL_INICIO_PLANING_SUCCESS:
      return exportarModalInicioPlaningSuccess(state, action)
    case actionTypes.TOTALES_PLANING_SUCCESS:
      return totalesPlaningSuccess(state, action)
    case actionTypes.TOTALES_MODAL_PLANING_SUCCESS:
      return totalesModalPlaningSuccess(state, action)
    case actionTypes.TOTALES_PLANING_ALBARAN_SUCCESS:
      return totalesPlaningAlbaranSuccess(state, action)
    case actionTypes.ABRIR_MODAL_EXPORTAR_IMPRIMIR_PLANING:
      return abrirModalExportarImprimirPlaning(state, action)
    case actionTypes.CERRAR_MODAL_EXPORTAR_IMPRIMIR_PLANING:
      return cerrarModalExportarImprimirPlaning(state, action)
    case actionTypes.EXPORTAR_PLANING_SUCCESS:
      return exportarPlaningSuccess(state, action)
    case actionTypes.IMPRIMIR_PLANING_SUCCESS:
      return imprimirPlaningSuccess(state, action)
    case actionTypes.IMPRIMIR_ZEBRA_VERRACOS_SUCCESS:
      return imprimirZebraVerracosSuccess(state, action)
    case actionTypes.FILTROS_MODAL_SUGERENCIAS_PLANING:
      return filtrosModalSugerenciasPlaning(state, action)
    case actionTypes.OBTENER_DATOS_VERRACO_PLANING:
      return obtenerDatosVerracoPlaning(state, action)
    case actionTypes.FETCH_ORDER_PLANING:
      return fetchOrderPlaning(state, action)
    case actionTypes.EXPORTAR_SUGERENCIA_EXTRACCIONES_SUCCESS:
      return exportarSugerenciaExtraccionesSuccess(state, action)
    case actionTypes.IMPRIMIR_SUGERENCIA_EXTRACCIONES_SUCCESS:
      return imprimirSugerenciaExtraccionesSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_PLANNING:
      return seleccionarTodosPlanning(state, action)
    case actionTypes.DESELECCIONAR_TODOS_PLANNING:
      return deSeleccionarTodosPlanning(state, action)
    case actionTypes.ABRIR_DUPLICAR_PLANING:
      return abrirDuplicarPlaning(state, action)
    case actionTypes.CERRAR_DUPLICAR_PLANING:
      return cerrarDuplicarPlaning(state, action)
    case actionTypes.ENVIAR_FECHA_DUPLICAR_PLANING_SUCCESS:
      return enviarFechaDuplicarPlaningSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_PLANNING_PRINCIPAL:
      return seleccionarTodosPlanningPrincipal(state, action)
    case actionTypes.DESELECCIONAR_TODOS_PLANNING_PRINCIPAL:
      return deSeleccionarTodosPlanningPrincipal(state, action)
    case actionTypes.SELECCIONAR_TODOS_SUGERENCIAS_PLANNING:
      return seleccionarTodosSugerenciasPlanning(state, action)
    case actionTypes.DESELECCIONAR_TODOS_SUGERENCIAS_PLANNING:
      return deSeleccionarTodosSugerenciasPlanning(state, action)
    case actionTypes.SELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING:
      return seleccionarTodosQuitarSugerenciasPlanning(state, action)
    case actionTypes.DESELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING:
      return deSeleccionarTodosQuitarSugerenciasPlanning(state, action)
    case actionTypes.OBTENER_PREVISION_VERRACOS_SUCCESS:
      return obtenerPrevisionVerracosSuccess(state,action)
    default:
      return state
  }
}
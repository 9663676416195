import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import './FormGroupSubtitle.scss'

class FormGroupSubtitle extends Component {
  render () {
    const { colSm = 12, small = false, className } = this.props
    return (
      <Col sm={colSm} className={className}>
        <h4 className={'form-group-subtitle' + (small ? ' form-group-subtitle-small' : '')}>
          {this.props.children}
        </h4>
      </Col>
    )
  }
}
export default FormGroupSubtitle

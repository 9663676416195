import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import CargaIndex from '../../components/cargaIndex/CargaIndex'
import {submitCargaIndex, cerrarModalCargaIndex, subirArchivosCargaIndex, descargarPlantillaCsv} from '../../actions/cargaIndex/cargaIndex'

export function mapStateToProps (state) {
  return {
    ...state.cargaIndex,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitCargaIndex,
      cerrarModalCargaIndex,
      subirArchivosCargaIndex,
      descargarPlantillaCsv
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CargaIndex))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import ModalAsignacionPedido from '../../components/asignacionPedidos/ModalAsignacionPedido'
import { withTranslation } from 'react-i18next'
import {closeModal,addDosisAPedido} from '../../actions/asignacionPedidos/asignacionPedidos'


export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    
    showModalAsignacionPedido: state.asignacionPedidos.showModal,
    modalForm: state.form.ModalAsignacionPedido,
    idDosis:state.asignacionPedidos.idDosis,
    idAlbaranDosis:state.asignacionPedidos.idAlbaranDosis,
    idNivelGenetico:state.asignacionPedidos.idNivelGenetico,
    advertenciaNivel:state.asignacionPedidos.advertenciaNivel,
    //modalNuevoPoolAsignacion: getFormValues('modalNuevoPoolAsignacion')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,      
      addDosisAPedido
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAsignacionPedido))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Raza from '../../components/raza/Raza'
import {fetchRazas, recuperarDatosModalRaza, duplicarDatosModalRaza, cambiarEstadoRaza, onDeleteRaza, fetchFiltrarRaza} from '../../actions/raza/raza'

export function mapStateToProps (state) {
  return {
    raza: {...state.raza},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRazas,
      recuperarDatosModalRaza,
      duplicarDatosModalRaza,
      cambiarEstadoRaza,
      onDeleteRaza,
      fetchFiltrarRaza
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Raza))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalClienteRuta from '../../components/ruta/ModalClienteRuta'
import { abrirModalClienteRuta, cerrarModalClienteRuta, seleccionarTodosModalClienteRuta, deSeleccionarTodosModalClienteRuta, anadirCliente, fetchClienteRuta, fetchFiltrarClienteRuta
  } from '../../actions/ruta/ruta'
import {closeModal} from '../../actions/common'
import {comboCliente, comboCifCliente, comboLocalidadCliente} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.ruta,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalClienteRuta,
      cerrarModalClienteRuta,
      seleccionarTodosModalClienteRuta,
      deSeleccionarTodosModalClienteRuta,
      anadirCliente,
      fetchClienteRuta,
      comboCliente,
      comboCifCliente,
      comboLocalidadCliente,
      fetchFiltrarClienteRuta
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalClienteRuta))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalConectividad from '../../components/conectividad/ModalConectividad'
import {recuperarDatosModalConectividad, submitNuevaConectividad, editarConectividad, guardarYnuevoConectividad, closeModalConectividad} from '../../actions/conectividad/conectividad'

export function mapStateToProps (state) {
  return {
    ...state.conectividad,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalConectividad,
      submitNuevaConectividad,
      editarConectividad,
      guardarYnuevoConectividad,
      closeModalConectividad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalConectividad))
import React, {Component} from 'react'
import {Row, Col, FormGroup, ControlLabel} from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import {getClosestIfOutsideOfRange} from '../../../util/formatFunctions'
import Numerical from './Numerical'
import Button from '../button/Button'
import './InputText.scss'

class InputNumericalWithCountKeys extends Component {
  constructor (props) {
    super(props)
    let value = props.defaultValue || props.input.value || 0
    value = getClosestIfOutsideOfRange(value, props)
    this.state = {
      value: props.numDecimales > 0 ? parseFloat(value) : parseInt(value, 10)
    }
    this.handleIncrementar = this.handleIncrementar.bind(this);
    this.handleDisminuir = this.handleDisminuir.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange (event, value, shouldBeCalculated = false) {
    this.setState((state) => {
      value = shouldBeCalculated ? state.value + value : value
      value = getClosestIfOutsideOfRange(value, this.props)
      return { value }
    }, () => {
      this.props.input.onChange(this.state.value)
      if (this.props.onInputChange) {
        this.props.onInputChange(event, this.state.value)
      }
    })
  }

  handleIncrementar (event) {
    this.handleChange(event, 1, true)
  }

  handleDisminuir (event) {
    this.handleChange(event, -1, true)
  }

  handleKeyDown (event) {
    if (event.key.toUpperCase() === this.props.incrementarKey.toUpperCase()) {
      this.handleChange(event, +1, true)
    } else if (event.key.toUpperCase() === this.props.disminuirKey.toUpperCase()) {
      this.handleChange(event, -1, true)
    }
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate () {
    let value = this.props.numDecimales > 0 ? parseFloat(this.props.input.value) : parseInt(this.props.input.value, 10)
    if (isNaN(value)) value = 0
    if (this.state.value !== value) {
      this.setState({value})
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  render () {
    const {
      id, colSm, customClass, disabled, placeholder = null, componentClass, controlLabel,
      numDecimales, minValue, maxValue, incrementarKey, disminuirKey,
      meta: { touched, error }
    } = this.props
    const errorMessage = (touched && error) ? error : null
    return (
    <Col sm={colSm} className={customClass}>
      <FormGroup validationState={errorMessage ? 'error' : null}>
        {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
        <Row className="row-flex row-collapsed">
          <Col sm={4}><Button type="button" className="btn btn-primary full-height" block onClick={this.handleIncrementar}>+ ({incrementarKey})</Button></Col>
          <Col sm={4}>
            <Numerical
              id={id}
              disabled={disabled}
              type="text"
              placeholder={placeholder}
              title={controlLabel}
              componentClass={componentClass}
              value={this.state.value}
              onChange={this.handleChange}
              numDecimales={numDecimales}
              minValue={minValue}
              maxValue={maxValue}
            />
          </Col>
          <Col  sm={4}><Button type="button" className="btn btn-primary full-height" block onClick={this.handleDisminuir}>({disminuirKey}) -</Button></Col>
        </Row>
        {
          errorMessage && <span className='help-block'><Warning /><span>{errorMessage}</span></span>
        }
      </FormGroup>
    </Col>
    )
  }
}

export default InputNumericalWithCountKeys

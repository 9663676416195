import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import {date_formatter} from '../../util/formatFunctions'
import Button from '../comun/button/Button'
import InputText from '../comun/form/InputText'
import InputPassword from '../comun/form/InputPassword'
import AlertDanger from '../comun/alert/AlertDanger'
import SimplePage from '../../containers/page/SimplePage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './login.scss'
import {modulos, translateModulos} from '../../constants/modulos'

class RenovarLicencia extends Component {
  componentDidMount () {
    this.props.actions.comprobarEntorno()
    if (!this.props.isCloud) {
      this.props.actions.obtenerNumeroSerie()
    }
    this.props.actions.mostrarInformacionLicencia()
  }

  submitRenovarToken(values) {
    this.props.actions.submitRenovarToken(values)
  }

  render () {
    const { t, handleSubmit, auth: {licenciaExpirada, isCloud, numeroSerie, datosLicencia} } = this.props
    const t_key = 'LOGIN.'

    let modalidades = []
    if (Object.keys(datosLicencia).length > 0){
      if (datosLicencia.modalidades !== null) {
        let datosLicenciaModalidades = datosLicencia.modalidades.split(';')
        datosLicenciaModalidades.filter((datos) => {
          if (datos === "1"){
            modalidades.push(t(translateModulos[modulos.BASICO]))
          }
          if (datos === "2"){
            modalidades.push(t(translateModulos[modulos.INFORMES]))
          }
          if (datos === "3"){
            modalidades.push(t(translateModulos[modulos.PEDIDOS_CLIENTES]))
          }
          if (datos === "4"){
            modalidades.push(t(translateModulos[modulos.TRAZABILIDAD_BIOSEGURIDAD]))
          }
        })
      }
    }

    let versionesMagavision = []
    if (Object.keys(datosLicencia).length > 0){
      if (datosLicencia.versionesMagavision !== null) {
        let datosLicenciaModalidades = datosLicencia.versionesMagavision.split(',')
        datosLicenciaModalidades.filter((datos) => {
          if (datos === "3"){
            versionesMagavision.push(t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_I'))
          }
          if (datos === "4"){
            versionesMagavision.push(t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_M'))
          }
          if (datos === "5"){
            versionesMagavision.push(t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.NUEVO_MAGAVISION'))
          }
        })
      }
    }

    return (
      <SimplePage className="renovar-licencia-container" noTitle={true}>
        <div>
          <MensajeAlertaPage />
          <form>
            <Row>
              {!isCloud && (
                <Col sm={12}>
                  <label>{t(`${t_key}FORM.NUMERO_SERIE`)}: </label><br />
                  <span>{numeroSerie}</span>
                </Col>
              )}
              <br />
              <Col sm={12}>
                <label>{t(`${t_key}FORM.FECHA_CADUCIDAD`)}: </label>
                <span style={{marginLeft: '5px'}}>{date_formatter(datosLicencia.fechaCaducidad, this.props.auth.formaFechaHora, false)}</span>
              </Col>
              <Col sm={12}>
                <label>{t(`${t_key}FORM.LIMITE_USUARIOS`)}: </label>
                <span style={{marginLeft: '5px'}}>{datosLicencia.limiteUsuarios}</span>
              </Col>
              <Col sm={12}>
                <label>{t(`${t_key}FORM.MODALIDADES`)}: </label>
                <span style={{marginLeft: '5px'}}>{modalidades.toString()}</span>
              </Col>
              <Col sm={12}>
                <label>{t(`${t_key}FORM.VERSIONES_MAGAVISION`)}: </label>
                <span style={{marginLeft: '5px'}}>{versionesMagavision.toString()}</span>
              </Col>
              <Field
                id="tokenRenovar"
                name="tokenRenovar"
                colSm={12}
                controlLabel={t(`${t_key}FORM.TOKEN`) + '*'}
                placeholder={t(`${t_key}FORM.TOKEN`)}
                component={InputText}
                validate={required}
              />
            </Row>
            <Row>
              <Col sm={12}>
                {
                  isCloud === false ? (
                    <div>
                    <Button type="button" onClick={handleSubmit(this.submitRenovarToken.bind(this))} className="btn-primary pull-right">{t(`${t_key}FORM.GUARDAR`)}</Button>
                    <Link to="/" className="link-volver-login pull-right">{t(`${t_key}FORM.VOLVER`)}</Link>
                    </div>
                  ) : null
                }
              </Col>
            </Row>
          </form>
        </div>
      </SimplePage>
    )
  }
}

export default reduxForm({
  form: 'renovarLicencia'
})(RenovarLicencia)

import actionTypes from '../../constants/actions/comboCausaEliminacion/comboCausaEliminacion'

export function initialState () {
  return {
    list: []
  }
}

export function fetchComboCausaEliminacionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalComboCausaEliminacionSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function submitNuevaComboCausaEliminacionSuccess (state, {comboCausaEliminacion}) {
  return {
    ...state,
    comboCausaEliminacion
  }
}

export function editarComboCausaEliminacionSuccess (state, {comboCausaEliminacion}) {
  return {
    ...state,
    comboCausaEliminacion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_COMBO_CAUSA_ELIMINACION_SUCCESS:
      return fetchComboCausaEliminacionSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION_SUCCESS:
      return recuperarDatosModalComboCausaEliminacionSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION_SUCCESS:
      return submitNuevaComboCausaEliminacionSuccess(state, action)
    case actionTypes.EDITAR_COMBO_CAUSA_ELIMINACION_SUCCESS:
      return editarComboCausaEliminacionSuccess(state, action)
    default:
      return state
  }
}
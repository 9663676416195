import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Loader from '../comun/loader/Loader'
import Button from '../comun/button/Button'
import {
  tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants
} from '../../constants/analisis'
import {tipoAnalisisControlMorfologicoSeguimiento as tipo} from '../../constants/comun'

class ModalNuevoMagavision extends Component {
  constructor (props) {
    super()
    this.obtenerYGuardarResultadoSistemaAnalisis = this.obtenerYGuardarResultadoSistemaAnalisis.bind(this)
    this.state = {
      checkIsPool: false
    }
  }

  componentDidMount() {
    this.setState({checkIsPool: (this.props.isFromSeguimientoList ? (this.props.seguimiento.idPool != null) : this.props.isPool)})
  }

  componentDidUpdate (prevProps) {
    //Cuando viene de la lista de seguimientos
    if(this.props.seguimiento.idPool != prevProps.seguimiento.idPool){
      this.setState({checkIsPool: (this.props.isFromSeguimientoList ? (this.props.seguimiento.idPool != null) : this.props.isPool)})
    }

    if(this.props.seguimiento.modalFormSeguimiento && this.props.seguimiento.modalFormSeguimiento.data 
      && prevProps.seguimiento.modalFormSeguimiento && prevProps.seguimiento.modalFormSeguimiento.data 
      && this.props.seguimiento.modalFormSeguimiento.data.isPool != prevProps.seguimiento.modalFormSeguimiento.data.isPool ){
        this.setState({checkIsPool: this.props.seguimiento.modalFormSeguimiento.data.isPool})
    }
  }

  obtenerYGuardarResultadoSistemaAnalisis () {
    console.log('obtenerYGuardarResultadoSistemaAnalisis',this.props.from)
    let is_multiseguimiento = this.props.extraccion && this.props.extraccion.extraccion && this.props.extraccion.extraccion.multiseguimiento && this.props.extraccion.extraccion.multiseguimiento.length > 0  && this.props.extraccion.extraccion.mgv_follow_id
    console.log(is_multiseguimiento)
    //is_multiseguimiento = true ////////////////////////////////////////////
    if(this.props.from == tipo.CONTROL_MORFOLOGICO){
      this.props.actions.obtenerYGuardarResultadoSistemaAnalisisControlMorfologico(tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION)
    }else if(is_multiseguimiento){
      console.log(this.props.extraccion.extraccion.mgv_follow_id)
      this.props.actions.obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento()
    }else{
      this.props.actions.obtenerYGuardarResultadoSistemaAnalisis(
        tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION,
        this.state.checkIsPool ? true : this.props.extraccion.extraccion.isSeguimiento,
        this.state.checkIsPool,
        (this.state.checkIsPool && this.props.seguimiento.list && this.props.seguimiento.list.length === 0) ? true : false
      )
    }
  }

  cerrarNuevoMagavision () {
    this.props.actions.cerrarNuevoMagavision(this.state.checkIsPool)
  }

  render () {
    const {
      t, isSeguimiento = false, extraccion, pool, controlMorfologico, panelControl: {urlMagavision}, auth: {idioma}
    } = this.props
    //console.log('controlMorfologico',controlMorfologico)
    //console.log('extraccion',extraccion)
    const tKey = 'ANALISIS_EXTRACCIONES.MAGAVISION.'
    const esperandoResultadoSistemaAnalisis = extraccion.extraccion.esperandoResultadoSistemaAnalisis
    const tokenUsuarioMagavision = this.state.checkIsPool ? pool.tokenUsuarioMagavision : extraccion.tokenUsuarioMagavision
    const t_anl = this.state.checkIsPool ? pool.t_anl : extraccion.t_anl
    const t_dtv = this.state.checkIsPool ? pool.t_dtv : (extraccion.extraccion.isControlMorfologico ? 'CM-'+controlMorfologico.modalFormControlMorfologico.data.idControlMorfologico : extraccion.t_dtv)
    console.log('t_dtv', t_dtv)
    const t_dtv_main = this.state.checkIsPool ? pool.pool.t_dtv_main : extraccion.extraccion.t_dtv_main
    if (!extraccion.extraccion.showModalMagavision) return false
    
    let aux_idioma = ''
    switch (idioma) {
      case 'es':
        aux_idioma = 'spanish'
        break;
      case 'en':
        aux_idioma = 'english'
        break;
      case 'ch':
        aux_idioma = 'chinese'
        break;
      case 'ru':
        aux_idioma = 'russian'
        break;
      case 'ja':
        aux_idioma = 'japanese'
        break;
      default:
        aux_idioma = 'english'
        break;
    }

    let url = urlMagavision + (urlMagavision[urlMagavision.length - 1] !== '/' ? '/' : '') +
                "login/validatetoken?t_usr=" + tokenUsuarioMagavision +
                "&t_anl=" + t_anl +
                "&t_dtv=" + t_dtv +
                "&lang=" + aux_idioma +
                '&t_sample_type=' + (this.state.checkIsPool ? 3 : isSeguimiento ? 2 : 1) +
                (t_dtv_main ? '&t_dtv_main=' + t_dtv_main : '')
    


    //console.log('multiseguimiento',extraccion.extraccion.multiseguimiento)
    if(extraccion && extraccion.extraccion && extraccion.extraccion.multiseguimiento 
        && extraccion.extraccion.multiseguimiento.length > 0  && extraccion.extraccion.mgv_follow_id ){
      console.log('hay multiseguimiento', extraccion.extraccion.mgv_follow_id)
      url = urlMagavision + (urlMagavision[urlMagavision.length - 1] !== '/' ? '/' : '') + 
        "login/validatetoken?t_usr=" + tokenUsuarioMagavision +
        "&mgv_follow_id=" + extraccion.extraccion.mgv_follow_id +
        "&lang=" + aux_idioma
    }


    console.log(url)

    return (
      <Modal show={extraccion.extraccion.showModalMagavision} onHide={() => this.cerrarNuevoMagavision() } bsSize="large" dialogClassName="full-screen" backdrop="static">
        <Modal.Body className="p-a-0">
          <iframe
            id="iframeMagavision"
            title="Magavision"
            style={{width: "100%", height: "100%", minHeight: "calc(100vh - 70px)"}}
            src={url}
          >
          </iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary iframeMagavision-btn-aceptar"
            onClick={() => this.obtenerYGuardarResultadoSistemaAnalisis() }
            disabled={esperandoResultadoSistemaAnalisis}
          >{esperandoResultadoSistemaAnalisis && (<Loader />)}{t(tKey + 'BUTTONS.ACEPTAR')}</Button>
          <Button
            type="button"
            className="btn btn-white iframeMagavision-btn-cancelar"
            onClick={() => this.cerrarNuevoMagavision() }
          >{t(tKey + 'BUTTONS.CERRAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalNuevoMagavision
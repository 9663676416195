import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import lineaGeneticaActionTypes from '../../constants/actions/lineaGenetica/lineaGenetica'
import {fetchLineaGeneticaSuccess, recuperarDatosModalSuccess, duplicarDatosModalSuccess, cambiarEstadoSuccess, onDeleteSuccess, submitNuevaLineaGeneticaSuccess, crearCsvSuccess,
deSeleccionarTodosLineaGenetica, fetchFiltrarLineaSuccess, editarLineaGeneticaSuccess, imprimirPdfLineaSuccess, openModalLineaGenetica, closeModalLineaGenetica} from '../../actions/lineaGenetica/lineaGenetica'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import getLineaGeneticaList from '../../services/lineaGenetica/getLineaGeneticaList'
import addLineaGenetica from '../../services/lineaGenetica/addLineaGenetica'
import getLineaGenetica from '../../services/lineaGenetica/getLineaGenetica'
import editLineaGenetica from '../../services/lineaGenetica/editLineaGenetica'
import getLineaGeneticaParaDuplicar from '../../services/lineaGenetica/getLineaGeneticaParaDuplicar'
import getLineaGeneticaCambioEstado from '../../services/lineaGenetica/getLineaGeneticaCambioEstado'
import deleteLiniaGenetica from '../../services/lineaGenetica/deleteLiniaGenetica'
import getFiltrarLinea from '../../services/lineaGenetica/getFiltrarLinea'
import getCambiarEstadoSeleccion from '../../services/lineaGenetica/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/lineaGenetica/getDeleteSeleccion'
import getCrearCsvLinea from '../../services/lineaGenetica/getCrearCsvLinea'
import getImprimirPdfLinea from '../../services/lineaGenetica/getImprimirPdfLinea'
import { yesNoModal } from '../modal/yesNoModal'
import {comboCodLineaGenetica, comboLineaGenetica} from '../../actions/combos/combos'

export function * fetchLineaGenetica () {
  try {
    // let auth = yield select(state => state.auth)
    const list = yield call(getLineaGeneticaList, null)
    yield put(fetchLineaGeneticaSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchLineaGenetica () {
  yield takeLatest(lineaGeneticaActionTypes.FETCH_LINEA_GENETICA, fetchLineaGenetica)
}

export function * submitNuevaLineaGenetica ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionLinea = yield call(addLineaGenetica, values, auth.token)
    yield put(submitNuevaLineaGeneticaSuccess(insercionLinea))
    if (insercionLinea.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(closeModalLineaGenetica())
    const val = yield select(state => state.lineaGenetica.filtros)
    const lista = yield call(getFiltrarLinea, val, auth.token)
    yield put(fetchLineaGeneticaSuccess(lista))
    yield put(fetchFiltrarLineaSuccess(val))
    yield put(comboCodLineaGenetica())
    yield put(comboLineaGenetica())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaLineaGenetica () {
  yield takeLatest(lineaGeneticaActionTypes.SUBMIT_NUEVA_LINEA_GENETICA, submitNuevaLineaGenetica)
}

export function * recuperarDatosModal ({idLinea}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getLineaGenetica, idLinea)
    yield put(recuperarDatosModalSuccess(registroModificar))
    yield put(initialize('ModalLineaGenetica', registroModificar))
    yield put(openModalLineaGenetica())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModal () {
  yield takeLatest(lineaGeneticaActionTypes.RECUPERAR_DATOS_MODAL, recuperarDatosModal)
}

export function * abrirModalVacio () {
  try {
    yield put(recuperarDatosModalSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalLineaGenetica', valores))
    yield put(openModalLineaGenetica())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacio () {
  yield takeLatest(lineaGeneticaActionTypes.ABRIR_MODAL_VACIO, abrirModalVacio)
}

export function * editarLineaGenetica ({values}) {
  try {
    if (values.cambiarEstado === false && values.activo === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        const edicionLinea = yield call(editLineaGenetica, values, auth.token)
        yield put(editarLineaGeneticaSuccess(edicionLinea))
        if (edicionLinea.actualizado === true) {
          yield put(openMensajeAlerta('modificacion'))
        } else {
          yield put(openMensajeAlerta('no-modificacion'))
        }
        yield put(closeModalLineaGenetica())
        yield put(comboCodLineaGenetica())
        yield put(comboLineaGenetica())
        const val = yield select(state => state.lineaGenetica.filtros)
        if (val.nombreLinea && values.nombreLinea){
          val.nombreLinea = {value: val.nombreLinea.value, label: values.nombreLinea}
        }
        if (val.codLinea && values.codLinea){
          val.codLinea = {value: val.codLinea.value, label: values.codLinea}
        }
        const lista = yield call(getFiltrarLinea, val, auth.token)
        yield put(fetchLineaGeneticaSuccess(lista))
        yield put(fetchFiltrarLineaSuccess(val))
      }
    } else {
      let auth = yield select(state => state.auth)
      const edicionLinea = yield call(editLineaGenetica, values, auth.token)
      yield put(editarLineaGeneticaSuccess(edicionLinea))
      if (edicionLinea.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
      yield put(closeModalLineaGenetica())
      yield put(comboCodLineaGenetica())
      yield put(comboLineaGenetica())
      const val = yield select(state => state.lineaGenetica.filtros)
      if (val.nombreLinea && values.nombreLinea){
        val.nombreLinea = {value: val.nombreLinea.value, label: values.nombreLinea}
      }
      if (val.codLinea && values.codLinea){
        val.codLinea = {value: val.codLinea.value, label: values.codLinea}
      }
      const lista = yield call(getFiltrarLinea, val, auth.token)
      yield put(fetchLineaGeneticaSuccess(lista))
      yield put(fetchFiltrarLineaSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarLineaGenetica () {
  yield takeLatest(lineaGeneticaActionTypes.EDITAR_LINEA_GENETICA, editarLineaGenetica)
}

export function * duplicarDatosModal ({idLinea}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalSuccess(null))
    const duplicar = yield call(getLineaGeneticaParaDuplicar, idLinea)
    yield put(duplicarDatosModalSuccess(duplicar))
    yield put(initialize('ModalLineaGenetica', duplicar))
    yield put(openModalLineaGenetica())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchDuplicarDatosModal () {
  yield takeLatest(lineaGeneticaActionTypes.DUPLICAR_DATOS_MODAL, duplicarDatosModal)
}

export function * cambiarEstado ({idLinea, cambiarEstado}) {
  try {
    if (cambiarEstado === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        const dataEstado = yield call(getLineaGeneticaCambioEstado, idLinea, auth.token)
        yield put(cambiarEstadoSuccess(dataEstado))
        if (dataEstado.actualizado === true) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }

        const val = yield select(state => state.lineaGenetica.filtros)
        const lista = yield call(getFiltrarLinea, val, auth.token)
        yield put(fetchLineaGeneticaSuccess(lista))
        yield put(fetchFiltrarLineaSuccess(val))
      }
    } else {
      yield put(openSimpleModal('cambiarEstado'))
      const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
      if (confirmed) {
        let auth = yield select(state => state.auth)
        const dataEstado = yield call(getLineaGeneticaCambioEstado, idLinea, auth.token)
        yield put(cambiarEstadoSuccess(dataEstado))
        if (dataEstado.actualizado === true) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }

        const val = yield select(state => state.lineaGenetica.filtros)
        const lista = yield call(getFiltrarLinea, val, auth.token)
        yield put(fetchLineaGeneticaSuccess(lista))
        yield put(fetchFiltrarLineaSuccess(val))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarEstado () {
  yield takeLatest(lineaGeneticaActionTypes.CAMBIAR_ESTADO, cambiarEstado)
}

export function * onDelete ({idLinea}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataDelete = yield call(deleteLiniaGenetica, idLinea, auth.token)
      yield put(onDeleteSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboCodLineaGenetica())
      yield put(comboLineaGenetica())
      const val = yield select(state => state.lineaGenetica.filtros)
      if (val.nombreLinea && val.nombreLinea.value === idLinea) {
        val.nombreLinea = null
      }
      if (val.codLinea && val.codLinea.value === idLinea) {
        val.codLinea = null
      }
      const lista = yield call(getFiltrarLinea, val, auth.token)
      yield put(fetchLineaGeneticaSuccess(lista))
      yield put(fetchFiltrarLineaSuccess(val))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDelete () {
  yield takeLatest(lineaGeneticaActionTypes.ON_DELETE, onDelete)
}

export function * fetchFiltrarLinea ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarLinea, values, auth.token)
    yield put(fetchLineaGeneticaSuccess(list))
    yield put(fetchFiltrarLineaSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarLinea () {
  yield takeLatest(lineaGeneticaActionTypes.FETCH_FILTRAR_LINEA, fetchFiltrarLinea)
}

export function * cambiarEstadoSeleccion ({list}) {
  try {
    let tieneVerracosVivos = true
    list.forEach(function(li){
      if (li.cambiarEstado === false){
        tieneVerracosVivos = false
      }
    })
    if (tieneVerracosVivos === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
        if (dataEstadoSeleccion.actualizado === true) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        yield put(initialize('simpleTable'))
        yield put(deSeleccionarTodosLineaGenetica())
        yield put(initialize('FiltrosLineaGenetica'))
        const val = yield select(state => state.lineaGenetica.filtros)
        const lista = yield call(getFiltrarLinea, val, auth.token)
        yield put(fetchLineaGeneticaSuccess(lista))
        yield put(fetchFiltrarLineaSuccess(val))
      }
    } else {
      yield put(openSimpleModal('cambiarEstado'))
      const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
      if (confirmed) {
        let auth = yield select(state => state.auth)
        let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
        if (dataEstadoSeleccion.actualizado === true) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        yield put(deSeleccionarTodosLineaGenetica())
        yield put(initialize('FiltrosLineaGenetica'))
        /* const inicializaTabla = yield call(fetchLineaGenetica)
        yield put(initialize('simpleTable', inicializaTabla)) */
        const val = yield select(state => state.lineaGenetica.filtros)
        const lista = yield call(getFiltrarLinea, val, auth.token)
        yield put(fetchLineaGeneticaSuccess(lista))
        yield put(fetchFiltrarLineaSuccess(val))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarEstadoSeleccion () {
  yield takeLatest(lineaGeneticaActionTypes.CAMBIAR_ESTADO_SELECCION, cambiarEstadoSeleccion)
}

export function * deleteSeleccion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccion, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true && dataDeleteSeleccion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosLineaGenetica())
      yield put(initialize('FiltrosLineaGenetica'))
      yield put(comboCodLineaGenetica())
      yield put(comboLineaGenetica())
      const val = yield select(state => state.lineaGenetica.filtros)
      list.forEach((li) => {
        if (val.nombreLinea && val.nombreLinea.value === li.idLinea) {
          val.nombreLinea = null
        }
        if (val.codLinea && val.codLinea.value === li.idLinea) {
          val.codLinea = null
        }
      })
      const lista = yield call(getFiltrarLinea, val, auth.token)
      yield put(fetchLineaGeneticaSuccess(lista))
      yield put(fetchFiltrarLineaSuccess(val))
      yield put(initialize('filtrosLineaGenetica', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccion () {
  yield takeLatest(lineaGeneticaActionTypes.DELETE_SELECCION, deleteSeleccion)
}

export function * crearCsv ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelLinea = yield call(getCrearCsvLinea, list, auth.token)
    yield put(crearCsvSuccess(datosExcelLinea))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCrearCsv () {
  yield takeLatest(lineaGeneticaActionTypes.CREATE_CSV, crearCsv)
}

export function * imprimirPdfLinea ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfLinea = yield call(getImprimirPdfLinea, list, auth.token)
    yield put(imprimirPdfLineaSuccess(datosPdfLinea))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchImprimirPdfLinea () {
  yield takeLatest(lineaGeneticaActionTypes.IMPRIMIR_PDF_LINEA, imprimirPdfLinea)
}

export function * guardarYnuevo ({values}) {
  console.log(values)
  try {
    let auth = yield select(state => state.auth)
    const insercionLinea = yield call(addLineaGenetica, values, auth.token)
    yield put(submitNuevaLineaGeneticaSuccess(insercionLinea))
    if (insercionLinea.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    const valores = {activo: true}
    yield put(initialize('ModalLineaGenetica', valores))
    const val = yield select(state => state.lineaGenetica.filtros)
    const lista = yield call(getFiltrarLinea, val, auth.token)
    yield put(fetchLineaGeneticaSuccess(lista))
    yield put(fetchFiltrarLineaSuccess(val))
    yield put(comboCodLineaGenetica())
    yield put(comboLineaGenetica())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchGuardarYnuevo () {
  yield takeLatest(lineaGeneticaActionTypes.GUARDAR_Y_NUEVO, guardarYnuevo)
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerracoGrupoVerraco from '../../components/grupoVerraco/ModalVerracoGrupoVerraco'
import { abrirModalSeleccionarVerraco, cerrarModalSeleccionarVerraco, fetchVerracosGrupoVerraco, seleccionarTodosModalVerracoGrupoVerraco, deSeleccionarTodosModalVerracoGrupoVerraco, anadirVerraco,
  fetchFiltrarVerracoModalVerraco} from '../../actions/grupoVerraco/GrupoVerraco'
import {closeModal} from '../../actions/common'
import {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboEstadoVerraco, comboVerraco} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.grupoVerraco,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalSeleccionarVerraco,
      cerrarModalSeleccionarVerraco,
      fetchVerracosGrupoVerraco,
      seleccionarTodosModalVerracoGrupoVerraco,
      deSeleccionarTodosModalVerracoGrupoVerraco,
      anadirVerraco,
      fetchFiltrarVerracoModalVerraco,
      comboRaza,
      comboLineaGenetica,
      comboUbicacionPadre,
      comboVerraco
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerracoGrupoVerraco))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEntradaMercancia from '../../components/entradaMercancia/FiltrosEntradaMercancia'
import { comboProveedoresEntradaMercancia, comboReferenciaProductos,comboAlbaranesEntradaMercancia, comboOrigenesEntradaMercancia } from '../../actions/combos/combos'
import { filtrarEntradaMercancia, abrirModalEntradaMercancia, seleccionarTodosEntradaMercancia, deSeleccionarTodosEntradaMercancia, deleteSeleccionEntradaMercancia, crearCsvEntradaMercancia,
  imprimirPdfEntradaMercancia } from '../../actions/entradaMercancia/entradaMercancia'

export function mapStateToProps (state) {
  return {
    ...state.entradaMercancia,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      filtrarEntradaMercancia,
      comboProveedoresEntradaMercancia,
      comboReferenciaProductos,
      comboAlbaranesEntradaMercancia,
      comboOrigenesEntradaMercancia,
      abrirModalEntradaMercancia,
      seleccionarTodosEntradaMercancia,
      deSeleccionarTodosEntradaMercancia,
      deleteSeleccionEntradaMercancia,
      crearCsvEntradaMercancia,
      imprimirPdfEntradaMercancia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEntradaMercancia))
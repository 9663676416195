import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import {fetchAgenda, fetchTareasDia, abrirModalAgenda} from '../../actions/agenda/agenda'
import Agenda from '../../components/agenda/Agenda'

export function mapStateToProps (state) {
  return {
    ...state.agenda,
    combos: {
      comboTipoTareasAgenda: state.combos.comboTipoTareasAgenda,
      comboOperario: state.combos.comboOperario
    },
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchAgenda,
      fetchTareasDia,
      abrirModalAgenda
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Agenda))
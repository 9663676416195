import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, Fields, setSubmitFailed} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import InputText from '../comun/form/InputText'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Warning } from '@material-ui/icons'
import './ValidacionMateriaPrima.scss'
import FiltrosVerracoPoolPage from '../../containers/validacionMateriaPrima/FiltrosVerracoPoolPage'
import ModalAnadirVerracoPoolPage from '../../containers/validacionMateriaPrima/ModalAnadirVerracoPoolPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalValidacionMateriaPrima extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
    }
  }

  componentDidMount () {
    this.props.actions.comboProductosValidacionActivos()
  }

  componentDidUpdate (prevProps) {
    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.mostrarEnviarAviso === true) {
      this.props.dispatch(setSubmitFailed('ModalValidacionMateriaPrima'))
    }

    if (this.props.showModalValidacionMateriaPrima && !prevProps.showModalValidacionMateriaPrima){
      this.props.actions.comboLotesProductoEspecificoActivo(
        (this.props.data && this.props.data.materiaPrima ? this.props.data.materiaPrima.idProducto : 0),
        (this.props.data && this.props.data.materiaPrima ? this.props.data.materiaPrima.idEntradaAlmacenProducto : 0)
      )
    }

    if (this.props.listVerracoPool !== prevProps.listVerracoPool) {
      if (Object.keys(this.props.listVerracoPool).length > 0) {
        this.setState({mostrarEnviarAviso: false})
      }
    }
  }

  closeModalValidacionMateriaPrima () {
    this.props.actions.closeModalValidacionMateriaPrima()
    this.setState({mostrarEnviarAviso: false})
  }

  submitNuevoValidacionMateriaPrima (values) {
    values.Verracos = this.props.listVerracosMateriaPrima
    values.Pools = this.props.listPoolMateriaPrima
    if (Object.keys(this.props.listVerracoPool).length > 0) {
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarValidacionMateriaPrima(values)
      } else {
        this.props.actions.submitNuevoValidacionMateriaPrima(values)
      }
      this.setState({mostrarEnviarAviso: false})
    } else {
      this.setState({mostrarEnviarAviso: true})
    }
  }
  guardarYnuevoValidacionMateriaPrima(values){
    values.Verracos = this.props.listVerracosMateriaPrima
    values.Pools = this.props.listPoolMateriaPrima
    if (Object.keys(this.props.listVerracoPool).length > 0) {
      this.props.actions.guardarYnuevoValidacionMateriaPrima(values)
      this.setState({mostrarEnviarAviso: false})
    } else {
      this.setState({mostrarEnviarAviso: true})
    }
  }
  cambiarAviso (value) {
    if (value === true) {
      this.setState({mostrarEnviarAviso: true})
    } else {
      this.setState({mostrarEnviarAviso: false})
      this.props.change('usuarioAvisoStockMinimo','')
    }
  }

  enviarProductoParaLote (value) {
    if (value !== null){
      this.props.actions.comboLotesProductoEspecificoActivo(
        value.value,
        this.props.data.materiaPrima ? this.props.data.materiaPrima.idEntradaAlmacenProducto : 0
      )
      this.props.change('lote', '')
    }
  }

  seleccionarLote (val) {
    this.props.change('cantidad', val.cantidad)
  }

  render () {
    const {
      t, handleSubmit, submitting, listValidacionMateriaPrima, showModalValidacionMateriaPrima, data, duplicar, numeroRegistrosPorPagina, paginaActual, listVerracoPool, checkedFiltrosVerracoPool, dataVerracoPool,
      actions: {recuperarDatosModalValidacionMateriaPrima, closeModalValidacionMateriaPrima, recuperarDatosModalVerracoPool, duplicarDatosModalVerracoPool, onDeleteVerracoPool},
      combos: {comboProductosValidacionActivos, comboLotesProductoEspecificoActivo}
    } = this.props

    const comboApto = [
      {value: true, label: this.props.t('COMUN.COMBOS.APTO.SI')},
      {value: false, label: this.props.t('COMUN.COMBOS.APTO.NO')}
    ]

    listVerracoPool.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'verracoPool'
    )

    const tableVerracoPool = {
      id: 'verracoPoolTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      mostrarCambioEstado: false,
      columns: [
        {id: 'pool', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.POOL')},
        {id: 'verraco', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.VERRACO')},
        {id: 'fecha', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.FECHA'), type: 'fecha'},
        {id: 'motilidad', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.MOTILIDAD')},
        {id: 'aglutinacion', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.AGLUTINACION')},
        {id: 'motilidadControl', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.MOT_CONTROL')},
        {id: 'aglutinacionControl', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.AGLU_CONTROL')}
      ],
      rows: listVerracoPool,
      filtros: <FiltrosVerracoPoolPage />,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      onEdit: (row) => recuperarDatosModalVerracoPool(row.idElemento),
      data: dataVerracoPool,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      duplicar: (row) =>duplicarDatosModalVerracoPool(row.idElemento),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      onDelete: (row) => onDeleteVerracoPool(row.idElemento),
      showModal: this.props.showModalVerracoPool,
      initialValues: {},
      idBlockOptions: 1,
      tablaModal: true
    }
    tableVerracoPool.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracoPool.initialValues['check' + row.idElemento] = checkedFiltrosVerracoPool
        }
      }
    )

    return (
      <div>
        <ModalAnadirVerracoPoolPage duplicar={(row) =>duplicarDatosModalVerracoPool(row.idElemento)} />
        <Modal show={showModalValidacionMateriaPrima} onHide={() => this.closeModalValidacionMateriaPrima()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-validacionMateriaPrima">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(this.props.data).length > 0 ? (
                t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_EDITAR')
              ) : (
                Object.keys(this.props.dataDuplicar).length > 0 ? (
                  t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_DUPLICAR')
                ) : (
                  t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
            {data && data.materiaPrima && data.materiaPrima.idValidacionMateriaPrima && (
              <ButtonChangeRecord list={listValidacionMateriaPrima} idElemento="idValidacionMateriaPrima" currentId={data.materiaPrima.idValidacionMateriaPrima} getNextRecord={recuperarDatosModalValidacionMateriaPrima} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={2}
                id="producto"
                name="producto"
                component={InputSelect}
                options={comboProductosValidacionActivos}
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.PRODUCTO') + '*'}
                customClass="producto"
                onInputChange={(value) => this.enviarProductoParaLote(value)}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={2}
                id="lote"
                name="lote"
                component={InputSelect}
                options={comboLotesProductoEspecificoActivo}
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.LOTE') + '*'}
                customClass="lote"
                validate={required}
                isClearable={false}
                onInputChange={(val) => this.seleccionarLote(val)}
              />
              <Field
                colSm={2}
                id="cantidad"
                name="cantidad"
                component={InputText}
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.CANTIDAD')}
                disabled={true}
              />
              <Field
                colSm={2}
                id="apto"
                name="apto"
                component={InputSelect}
                options={comboApto}
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.APTO') + '*'}
                customClass="apto"
                validate={required}
              />
              <Fields
                names={['fechaEntrada', 'fechaSalida']}
                component={InputRangeDatePickerDynamicNames}
                colSm={4}
                nameFrom="fechaEntrada"
                nameTo="fechaSalida"
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.PERIODO')}
              />
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.OBSERVACIONES')}
                customClass='observaciones'
              />
            </Row>
            <div className="clearfix"></div>
            <Row>
              <Col sm={12}>
                <SimpleTablePage {...tableVerracoPool}>
                </SimpleTablePage>
              </Col>
              <div className="clearfix"></div>
              {
                this.state.mostrarEnviarAviso === true && (
                  <Col sm={12}>
                    <span className='help-block'>
                      <Warning /><span className="texto-alerta-validar-materias-primas">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.VALIDACION_TABLAS')}</span>
                    </span>
                  </Col>
                )
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(this.props.data).length > 0 ? (
                <Button
                  type="button"
                  className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1) ? handleSubmit(duplicar) : undefined}
                >{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.DUPLICAR')}</Button>
              ) : (
                <Button type="button" className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoValidacionMateriaPrima.bind(this)) : null}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.GUARDAR_Y_NUEVO')}</Button>
              )
            }
            <Button
              type="button"
              onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoValidacionMateriaPrima.bind(this)) : null}
              className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2)}
            >{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalValidacionMateriaPrima()}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalValidacionMateriaPrima',
})(ModalValidacionMateriaPrima))
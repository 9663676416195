import actionTypes from '../../constants/actions/cambioEstadoVerraco/cambioEstadoVerraco'

export function initialState () {
  return {
    list: [],
    verracoMovidoHoy: [],
    ultimoCambioEstado:[],
    registroModificar: [],
    registroDuplicar: [],
    checkedFiltrosCambioEstadoVerraco: false,
    filtros: {},
    datosExcelCambioEstado: [],
    datosPdfCambioEstado: []
  }
}

export function enviarFechaCambioEstadoSuccess (state, {verracoMovidoHoy}) {
  return {
    ...state,
    verracoMovidoHoy
  }
}

export function submitNuevoCambioEstadoVerracoSuccess (state, {ultimoCambioEstado}) {
  return {
    ...state,
    ultimoCambioEstado
  }
}

export function fetchCambioEstadoVerracoSuccess (state, {list}) {
  return {
    ...state,
    list,
    verracoMovidoHoy: []
  }
}

export function recuperarDatosModalCambioEstadoVerracoSuccess (state, {registroModificar}) {
  return {
    ...state,
    registroModificar
  }
}

export function duplicarDatosModalCambioEstadoVerracoSuccess (state, {registroDuplicar}) {
  return {
    ...state,
    registroDuplicar
  }
}

export function seleccionarTodosCambioEstadoVerraco (state) {
  return {
    ...state,
    checkedFiltrosCambioEstadoVerraco: true
  }
}

export function deSeleccionarTodosCambioEstadoVerraco (state) {
  return {
    ...state,
    checkedFiltrosCambioEstadoVerraco: false
  }
}

export function fetchFiltrarCambioEstadoVerracoSuccess (state, {filtros}){
  return {
    ...state,
    filtros
  }
}

export function restartCambioEstadoVerraco (state){
  return {
    ...state,
    registroModificar: [],
    registroDuplicar: []
  }
}

export function crearCsvCambioEstadoSuccess (state, {datosExcelCambioEstado}) {
  return {
    ...state,
    datosExcelCambioEstado
  }
}

export function imprimirPdfCambioEstadoSuccess (state, {datosPdfCambioEstado}) {
  return {
    ...state,
    datosPdfCambioEstado
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ENVIAR_FECHA_CAMBIO_ESTADO_SUCCESS:
      return enviarFechaCambioEstadoSuccess(state, action)
    case actionTypes.SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO_SUCCESS:
      return submitNuevoCambioEstadoVerracoSuccess(state, action)
    case actionTypes.FETCH_CAMBIO_ESTADO_VERRACO_SUCCESS:
      return fetchCambioEstadoVerracoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS:
      return recuperarDatosModalCambioEstadoVerracoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS:
      return duplicarDatosModalCambioEstadoVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS:
      return seleccionarTodosCambioEstadoVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS:
      return deSeleccionarTodosCambioEstadoVerraco(state, action)
    case actionTypes.FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO_SUCCESS:
      return fetchFiltrarCambioEstadoVerracoSuccess(state, action)
    case actionTypes.RESTART_CAMBIO_ESTADO_VERRACO:
      return restartCambioEstadoVerraco(state, action)
    case actionTypes.CREATE_CSV_CAMBIO_ESTADO_SUCCESS:
      return crearCsvCambioEstadoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_CAMBIO_ESTADO_SUCCESS:
      return imprimirPdfCambioEstadoSuccess(state, action)
    default:
      return state
  }
}
import {call, put, takeLatest, all, select} from 'redux-saga/effects'
import {initialize, getFormValues} from 'redux-form'
import actionTypes from '../../constants/actions/PICTraq/PICTraq'
import {fetchPoolParaPICSuccess, fetchSplitsParaPICSuccess, fetchAnalisisParaPICSuccess, fetchPICTraitsSuccess, insertAnalisisPICSuccess, 
        insertPoolPICSuccess, saveDataPICSuccess, getUrlPICSuccess, sincronizarIndexPICSuccess, saveDataPoolPICSuccess,
        insertSplitsPICSuccess, saveDataSplitsPICSuccess
} from '../../actions/PICTraq/PICTraq'
import {dateTimeZones} from '../../util/formatFunctions'
import {openSimpleModal, openMensajeAlerta, setLoadingTrue, setLoadingFalse, reloadComponent} from '../../actions/common'
import {yesNoModal} from '../modal/yesNoModal'
import getAnalisisPICTraq from '../../services/PICTraq/getAnalisisPICTraq'
import getPoolPICTraq from '../../services/PICTraq/getPoolPICTraq'
import getSplitPICTraq from '../../services/PICTraq/getSplitPICTraq'
import getPICTraits from '../../services/PICTraq/getPICTraits'
import insertAnalisisPICTraq from '../../services/PICTraq/insertAnalisisPIC'
import insertPoolPICTraq from '../../services/PICTraq/insertPoolPIC'
import insertSplitsPICTraq from '../../services/PICTraq/insertSplitsPIC'
import saveDataPIC from '../../services/PICTraq/saveDataPIC'
import saveDataPoolPIC from '../../services/PICTraq/saveDataPoolPIC'
import saveDataSplitsPIC from '../../services/PICTraq/saveDataSplitsPIC'
import getUrlPIC from '../../services/PICTraq/getUrlPIC'
import sincronizarIndexPIC from '../../services/PICTraq/sincronizarIndexPIC'


export function * fetchAnalisisParaPIC(values) {
  try {
    yield put(setLoadingTrue())
    //console.log('sagas-fetchAnalisisParaPIC')
    const state = yield select(state => state)
    
    const response = yield call(getAnalisisPICTraq,values.filtros, state.auth.token)
    
    yield put(fetchAnalisisParaPICSuccess(response.data))
    yield put(fetchPICTraitsSuccess(response.traits))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchAnalisisParaPIC () {
  yield takeLatest(actionTypes.FETCH_ANALISIS_PARA_PIC, fetchAnalisisParaPIC)
}

export function * fetchPoolSplitsParaPIC(values) {
  
  try {
    yield put(setLoadingTrue())
    // console.log('sagas-fetchPoolSplitsParaPIC')
    const state = yield select(state => state)    

    const response = yield call(getPoolPICTraq,values.filtros, state.auth.token)    

    yield put(fetchPoolParaPICSuccess(response.pools))
    yield put(fetchSplitsParaPICSuccess(response.splits))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchfetchPoolParaPIC () {
  yield takeLatest(actionTypes.FETCH_POOL_SPLITS_PARA_PIC, fetchPoolSplitsParaPIC)
}

export function * fetchPICTraits(values) {
  try {      
    const state = yield select(state => state)    
    const listTraits = yield call(getPICTraits)
    yield put(fetchPICTraitsSuccess(listTraits))        
  } catch (error) {
    console.error(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchPICTraits () {
  yield takeLatest(actionTypes.FETCH_PIC_TRAITS, fetchPICTraits)
}

export function * insertAnalisisPIC(values) {  
  try {
    yield put(setLoadingTrue())
    //console.log('insertAnalisisPIC')
    const state = yield select(state => state)    
    const response = yield call(insertAnalisisPICTraq,values, state.auth.token)    
    //console.log(state.PICTraq.listAnalisis) //COLLNUM
    let listAnalisis = state.PICTraq.listAnalisis
    //console.log(response.data) //G_MSG, EVENT_VALUES_PARSED['1461']
    let eventValueList = response.data

    //'COLLNUM' => 1461
    let resultAnalisis = []
    for (var i = 0; i < listAnalisis.length; i++) {
      let msg = 'NO FOUND'
      for (var e = 0; e < eventValueList.length; e++) {
        if(eventValueList[e].EVENT_VALUES_PARSED['1461'] == listAnalisis[i].COLLNUM){
          msg = eventValueList[e].G_MSG
        }
      }    
      let analisis = JSON.parse(JSON.stringify(listAnalisis[i])) 
      analisis.STATUS = msg 
      resultAnalisis.push(analisis)
    }
    //console.log(resultAnalisis)
    yield put(fetchAnalisisParaPICSuccess(resultAnalisis))
    yield put(insertAnalisisPICSuccess())
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchInsertAnalisisPIC () {
  yield takeLatest(actionTypes.INSERT_ANALISIS_PIC, insertAnalisisPIC)
}


export function * insertPoolPIC(values) {
  try {
    yield put(setLoadingTrue())
    // console.log('insertPoolPIC',values)

    const state = yield select(state => state)    
    const response = yield call(insertPoolPICTraq,values.lists, state.auth.token)    
    // console.log('insertPoolPIC-response',response)

    //console.log(state.PICTraq.listAnalisis) //COLLNUM
    let listPool = state.PICTraq.listPool

    let eventValueListPool = response.pool.data
    
    let resultPool = []
    for (var i = 0; i < listPool.length; i++) {
      let msg = 'NO FOUND'
      for (var e = 0; e < eventValueListPool.length; e++) {
        if(eventValueListPool[e].EVENT_VALUES_PARSED['1270'] == listPool[i].BATCHID){
          msg = eventValueListPool[e].G_MSG
        }
      }
      let pool = JSON.parse(JSON.stringify(listPool[i]))
      pool.STATUS = msg 
      resultPool.push(pool)
    }

    yield put(fetchPoolParaPICSuccess(resultPool))
    yield put(insertPoolPICSuccess())
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchInsertPoolPIC () {
  yield takeLatest(actionTypes.INSERT_POOL_PIC, insertPoolPIC)
}

export function * insertSplitsPIC(values) {
  try {
    yield put(setLoadingTrue())
    // console.log('insertSplitsPIC',values)

    const state = yield select(state => state)    
    const response = yield call(insertSplitsPICTraq,values.lists, state.auth.token)    
    // console.log('insertSplitsPIC-response',response)

    //console.log(state.PICTraq.listAnalisis) //COLLNUM
    let listSplits = state.PICTraq.listSplits

    let eventValueListSplits = response.splits.data
    
    let resultSplits = []
    for (var i = 0; i < listSplits.length; i++) {
      let msg = 'NO FOUND'
      for (var e = 0; e < eventValueListSplits.length; e++) {
        if(eventValueListSplits[e].EVENT_VALUES_PARSED['1253'] == listSplits[i].SPLITNUM){
          msg = eventValueListSplits[e].G_MSG
        }
      }
      let split = JSON.parse(JSON.stringify(listSplits[i]))
      split.STATUS = msg 
      resultSplits.push(split)
    }

    // console.log('insertPoolPIC-resultSplits',resultSplits)

    yield put(fetchSplitsParaPICSuccess(resultSplits))
    yield put(insertSplitsPICSuccess())
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchInsertSplitsPIC () {
  yield takeLatest(actionTypes.INSERT_SPLITS_PIC, insertSplitsPIC)
}

export function * saveDataPICSaga() {
  try {
    yield put(setLoadingTrue())
    //console.log('saveDataPICSaga')
    const state = yield select(state => state)    
    const result = yield call(saveDataPIC, state.auth.token)
    //console.log(result)
    yield put(saveDataPICSuccess(result))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchSaveDataPIC() {
  yield takeLatest(actionTypes.SAVE_DATE_PIC, saveDataPICSaga)
}

export function * saveDataPoolPICSaga() {
  try {
    yield put(setLoadingTrue())
    //console.log('saveDataPICSaga')
    const state = yield select(state => state)    
    const result = yield call(saveDataPoolPIC, state.auth.token)
    //console.log(result)
    yield put(saveDataPoolPICSuccess(result.pool))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchSaveDataPoolPIC() {
  yield takeLatest(actionTypes.SAVE_DATA_POOL_PIC, saveDataPoolPICSaga)
}

export function * saveDataSplitsPICSaga() {
  try {
    yield put(setLoadingTrue())
    //console.log('saveDataPICSaga')
    const state = yield select(state => state)    
    const result = yield call(saveDataSplitsPIC, state.auth.token)
    //console.log(result)
    yield put(saveDataSplitsPICSuccess(result.splits))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchSaveDataSplitsPIC() {
  yield takeLatest(actionTypes.SAVE_DATA_SPLITS_PIC, saveDataSplitsPICSaga)
}

export function * getUrlPICSaga() {
  try {      
    //console.log('getUrlPICSaga')
    const state = yield select(state => state)    
    const result = yield call(getUrlPIC, state.auth.token)
    //console.log(result)
    yield put(getUrlPICSuccess(result))
    
  } catch (error) {
    console.error(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGetUrlPICSaga() {
  yield takeLatest(actionTypes.GET_URL_PIC, getUrlPICSaga)
}



export function * sincronizarIndexPICSaga() {
  try {
    yield put(setLoadingTrue())
    //console.log('sincronizarIndexPICSaga')
    const state = yield select(state => state)    
    const result = yield call(sincronizarIndexPIC, state.auth.token)
    //console.log(result)
    yield put(sincronizarIndexPICSuccess(result.data, result.updated))
    
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingFalse())
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSincronizarIndexPICSaga() {
  yield takeLatest(actionTypes.SINCRONIZAR_INDEX_PIC, sincronizarIndexPICSaga)
}



import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Planing from '../../components/planing/Planing'
import { getFormValues } from 'redux-form'
import {fetchFiltrarPlaning, totalesPlaning, totalesPlaningAlbaran} from '../../actions/planing/planing'
import {obtenerDatosPanelControl} from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    diasPlaning: state.planing.diasPlaning,
    panelControl: state.panelControl,
    formPlaning: getFormValues('formPlaning')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFiltrarPlaning,
      totalesPlaning,
      obtenerDatosPanelControl,
      totalesPlaningAlbaran
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Planing))
import React, { Component } from 'react'

class SimpleLeyenda extends Component {
  render () {
    const { items } = this.props
    if (!items || items.length === 0 )
      return null

    return (
      <div className="leyenda">
        <ul>
          {items.map((item, idx) => {
            return <li key={idx}>
              <div style={{
                backgroundColor: item.color,
                border: (item.border ? "1px solid " + item.border : "" )
              }}></div>
              {item.name=="&nbsp;" ? <>&nbsp;</> : item.name}
            </li>
          })}
        </ul>
      </div>
    )
  }
}

export default SimpleLeyenda

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalEvolucion from '../../components/fichaVerraco/ModalEvolucion'
import {submitNuevaEvolucion, editarEvolucion, guardarYnuevoEvolucion, abrirModalEvolucion, closeModalEvolucion} from '../../actions/fichaVerraco/fichaVerraco'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    auth: state.auth,
    showModal: state.common.showModal,
    fichaVerraco: {...state.fichaVerraco}
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevaEvolucion,
      editarEvolucion,
      guardarYnuevoEvolucion,
      abrirModalEvolucion,
      closeModalEvolucion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEvolucion))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePicker from '../comun/form/InputRangeDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class FiltrosProducto extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarProducto(values){
    this.props.actions.filtrarProducto(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosProducto()
    }
    if (this.props.datosExcelProducto !== prevProps.datosExcelProducto) {
      if (this.props.datosExcelProducto.excel && this.props.datosExcelProducto.excel !== null){
        let pdf = this.props.datosExcelProducto.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.PRODUCTOS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfProducto !== prevProps.datosPdfProducto) {
      if (this.props.datosPdfProducto.pdf && this.props.datosPdfProducto.pdf !== null){
        let pdf = this.props.datosPdfProducto.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.PRODUCTOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosProducto()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.filtrarProducto({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
    this.props.actions.comboNombreProductos()
    this.props.actions.comboReferenciaProductos()
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosProducto()
    } else {
      this.props.actions.deSeleccionarTodosProducto()
    }
  }

 cambiarEstadoSeleccionProducto(){
    const itemsSeleccion = this.props.listProducto.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idProducto': row.idProducto
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionProducto(list)
  }

  deleteSeleccionProducto(){
    const itemsSeleccion = this.props.listProducto.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idProducto': row.idProducto,
          'referencia': row.referencia
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionProducto(list)
  }

  crearCsvProducto(){
    this.props.actions.crearCsvProducto(this.props.filtros)
  }

  imprimirPdfProducto () {
    this.props.actions.imprimirPdfProducto(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.filtrarProducto(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalProducto
    },
    combos: {
      comboNombreProductos, comboReferenciaProductos
    }
  } = this.props

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  const comboTrazabilidad = [
    {value: true, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.NO')}
  ]

  const comboValidacion = [
    {value: true, label: this.props.t('COMUN.COMBOS.VALIDACION.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.VALIDACION.NO')}
  ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="referencia"
              name="referencia"
              controlLabel={t('ALMACEN.PRODUCTO.FILTROS.REFERENCIA')}
              component={InputSelect}
              options={comboReferenciaProductos}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.fetchFiltrarProducto.bind(this))}
            />
            <Field
              colSm={2}
              id="nombre"
              name="nombre"
              controlLabel={t('ALMACEN.PRODUCTO.FILTROS.PRODUCTO')}
              component={InputSelect}
              options={comboNombreProductos}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.fetchFiltrarProducto.bind(this))}
            />
            <Field
              colSm={2}
              id="activo"
              name="activo"
              controlLabel={t('ALMACEN.PRODUCTO.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.fetchFiltrarProducto.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="trazabilidad"
                name="trazabilidad"
                controlLabel={t('ALMACEN.PRODUCTO.FILTROS.TRAZABILIDAD')}
                component={InputSelect}
                options={comboTrazabilidad}
                valueKey="value"
                labelKey="label"
                customClass='select-producto'
                disabled={false}
                onInputChange={handleSubmit(this.fetchFiltrarProducto.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="validacion"
              name="validacion"
              controlLabel={t('ALMACEN.PRODUCTO.FILTROS.VALIDACION')}
              component={InputSelect}
              options={comboValidacion}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.fetchFiltrarProducto.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.referencia && filtros.referencia !== null ? (
                      <span className="badge-dark">{t('ALMACEN.PRODUCTO.FILTROS.REFERENCIA') + ': ' + filtros.referencia.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('referencia')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nombre && filtros.nombre !== null ? (
                      <span className="badge-dark">{t('ALMACEN.PRODUCTO.FILTROS.PRODUCTO') + ': ' + filtros.nombre.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombre')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo && filtros.activo !== null ? (
                      <span className="badge-dark">{t('ALMACEN.PRODUCTO.FILTROS.ESTADO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtros).length > 0 && (
                    filtros.trazabilidad && filtros.trazabilidad !== null ? (
                      <span className="badge-dark">{t('ALMACEN.PRODUCTO.FILTROS.TRAZABILIDAD') + ': ' + filtros.trazabilidad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('trazabilidad')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.validacion && filtros.validacion !== null ? (
                      <span className="badge-dark">{t('ALMACEN.PRODUCTO.FILTROS.VALIDACION') + ': ' + filtros.validacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('validacion')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionProducto()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionProducto()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvProducto()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfProducto()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PRODUCTOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1) ? abrirModalProducto : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosProducto',
})(FiltrosProducto))

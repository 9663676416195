export default {
  FETCH_EMPRESA: 'FETCH_EMPRESA',
  FETCH_EMPRESA_SUCCESS: 'FETCH_EMPRESA_SUCCESS',
  EDITAR_EMPRESA: 'EDITAR_EMPRESA',
  SELECCIONAR_TODOS_EMPRESA: 'SELECCIONAR_TODOS_EMPRESA',
  DESELECCIONAR_TODOS_EMPRESA: 'DESELECCIONAR_TODOS_EMPRESA',
  ABRIR_MODAL_CENTRO: 'ABRIR_MODAL_CENTRO',
  CERRAR_MODAL_CENTRO: 'CERRAR_MODAL_CENTRO',
  SUBMIT_NUEVO_CENTRO: 'SUBMIT_NUEVO_CENTRO',
  RECUPERAR_DATOS_CENTRO: 'RECUPERAR_DATOS_CENTRO',
  RECUPERAR_DATOS_CENTRO_SUCCESS: 'RECUPERAR_DATOS_CENTRO_SUCCESS',
  EDITAR_CENTRO: 'EDITAR_CENTRO',
  UPLOAD_FILE_EMPRESA: 'UPLOAD_FILE_EMPRESA',
  DUPLICAR_DATOS_CENTRO: 'DUPLICAR_DATOS_CENTRO',
  DUPLICAR_DATOS_CENTRO_SUCCESS: 'DUPLICAR_DATOS_CENTRO_SUCCESS',
  CAMBIAR_ESTADO_CENTRO: 'CAMBIAR_ESTADO_CENTRO',
  ELIMINAR_CENTRO: 'ELIMINAR_CENTRO',
  DELETE_SELECCION_CENTROS: 'DELETE_SELECCION_CENTROS',
  CAMBIO_ESTADO_SELECCION_CENTROS: 'CAMBIO_ESTADO_SELECCION_CENTROS',
  CREAR_CSV_EMPRESA: 'CREAR_CSV_EMPRESA',
  CREAR_CSV_EMPRESA_SUCCESS: 'CREAR_CSV_EMPRESA_SUCCESS',
  IMPRIMIR_PDF_EMPRESA: 'IMPRIMIR_PDF_EMPRESA',
  IMPRIMIR_PDF_EMPRESA_SUCCESS: 'IMPRIMIR_PDF_EMPRESA_SUCCESS'
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosUsuarios from '../../components/usuarios/FiltrosUsuarios'
import { fetchUsuarios, abrirModalUsuarios, seleccionarTodosUsuarios, deSeleccionarTodosUsuarios, deleteSeleccionUsuario, cambioEstadoSeleccionUsuario, crearCsvUsuarios,
  imprimirPdfUsuarios} from '../../actions/usuarios/usuarios'
import { comboUsuariosCentro } from '../../actions/combos/combos'
import { yesNoModal } from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUsuarios,
      comboUsuariosCentro,
      abrirModalUsuarios,
      seleccionarTodosUsuarios,
      deSeleccionarTodosUsuarios,
      deleteSeleccionUsuario,
      cambioEstadoSeleccionUsuario,
      crearCsvUsuarios,
      imprimirPdfUsuarios,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosUsuarios))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalAnadirVerracoPool from '../../components/validacionMateriaPrima/ModalAnadirVerracoPool'
// import {} from '../../actions/combos/combos'
import { cerrarModalVerracoPool, obtenerVerracosMateriaPrima, obtenerPoolMateriaPrima, editarVerracoMateriaPrima, anadirVerracoMateriaPrima, editarVerracoPool, editarPoolMateriaPrima,
  anadirPoolMateriaPrima, submitNuevoVerracoPool, guardarYnuevoVerracoPool } from '../../actions/validacionMateriaPrima/validacionMateriaPrima'

export function mapStateToProps (state) {
  return {
    ...state.validacionMateriaPrima,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalVerracoPool,
      obtenerVerracosMateriaPrima,
      obtenerPoolMateriaPrima,
      editarVerracoMateriaPrima,
      anadirVerracoMateriaPrima,
      editarVerracoPool,
      editarPoolMateriaPrima,
      anadirPoolMateriaPrima,
      submitNuevoVerracoPool,
      guardarYnuevoVerracoPool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAnadirVerracoPool))
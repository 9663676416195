import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputTextArea from '../comun/form/InputTextArea'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './PedidosVenta.scss'

class ModalDetallePedido extends Component {
  cerrarDetallesPedido () {
    this.props.actions.cerrarDetallesPedido()
  }

  abrirModalVacioAlbaran () {
    this.props.actions.crearAlbaranDesdePedido()
  }

  componentDidUpdate (prevProps) {
    if (this.props.listPedidosAnadidos !== prevProps.listPedidosAnadidos){
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        let totalDosis = 0
        this.props.listPedidosAnadidos.forEach( function(row){
          if (row.numeroDosis){
            totalDosis += parseFloat(row.numeroDosis)
          }
        })
        this.props.change('numeroDosis', totalDosis)
      }
    }
  }

  render () {
    const {
      t, handleSubmit, showModalDetallePedido, numeroRegistrosPorPagina, paginaActual, listPedidosAnadidos, comboDireccionesCliente,
      actions: {cerrarDetallesPedido},
      combos:{comboClienteActivo}
    } = this.props

    const tablePedidos = {
      id: 'modalPedidosVenta',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      hasPagination: true,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      tablaModal: true,
      idBlockOptions: 1,
      columns: [
        {id: 'verraco', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.VERRACO')},
        {id: 'raza', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.RAZA')},
        {id: 'lineaGenetica', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.LINEA_GENETICA')},
        {id: 'tipoDosis', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.CANTIDAD'), type: 'numero', numDecimales:0},
      ],
      rows: listPedidosAnadidos,
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.propiedad === false) {
          classRow = 'analisis-pertenece-pool'
        }
        return classRow
      }
    }

  return (
    <div>
        <Modal show={showModalDetallePedido} onHide={() => this.cerrarDetallesPedido()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
          <Modal.Header closeButton>
            <Modal.Title>
            {t('PEDIDOS.PEDIDOS_VENTA.TITLE')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={2}
                id="fecha"
                name="fecha"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.FECHA') + '*'}
                component={InputDatePicker}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                validate={required}
                disabled={true}
              />
              <Field
                colSm={2}
                id="cliente"
                name="cliente"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.CLIENTE') + '*'}
                component={InputSelect}
                options={comboClienteActivo}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                isClearable={false}
                validate={required}
                disabled={true}
              />
              {/*
              <Field
                colSm={2}
                id="ruta"
                name="ruta"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.RUTA')}
                component={InputSelect}
                // options={comboNombreGrupoVerraco}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
              />*/}
              <Field
                colSm={4}
                id="idDireccionCliente"
                name="idDireccionCliente"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.DIRECCION') + '*'}
                component={InputSelect}
                options={comboDireccionesCliente}
                valueKey="value"
                labelKey="label"
                disabled={true}
                customClass='nombre-pedidos-venta'
                validate={required}
              />
              <Field
                colSm={2}
                id="numeroDosis"
                name="numeroDosis"
                component={InputNumerical}
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.DOSIS_PEDIDAS')}
                customClass='dosisPedidas'
                disabled={true}
              />
            </Row>
            <div className="clearfix"></div>
            <SimpleTablePage {...tablePedidos}></SimpleTablePage>
            <div className="clearfix"></div>
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.OBSERVACIONES')}
                customClass='observaciones-pedidos-venta'
                disabled={true}
              />
              <Field
                colSm={2}
                id="estado"
                name="estado"
                component={InputText}
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.ESTADO')}
                customClass='estado'
                disabled={true}
              />
            </Row>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarDetallesPedido()}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalDetallePedido',
})(ModalDetallePedido))

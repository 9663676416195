
export const tipoTareasAgenda = {
  CUARENTENA: 1,
  BIOSEGURIDAD_HIGIENE: 2,
  INTERVENCIONES_SANITARIAS: 3,
  COPIA_SEGURIDAD: 4,
  VIGILANCIA: 5
}

export const translateTipoTareasAgenda = {
  1: 'TIPO_TAREAS_AGENDA.CUARENTENA',
  2: 'TIPO_TAREAS_AGENDA.BIOSEGURIDAD_HIGIENE',
  3: 'TIPO_TAREAS_AGENDA.INTERVENCIONES_SANITARIAS',
  4: 'TIPO_TAREAS_AGENDA.COPIA_SEGURIDAD',
  5: 'TIPO_TAREAS_AGENDA.VIGILANCIA'
}

export const colorTipoTareasAgenda = {
  null: {backgroundColor: '#FFF', color: '#000', borderColor: '#666'},
  1: {backgroundColor: '#0BE0FE', color: '#000', borderColor: '#0BE0FE'},
  2: {backgroundColor: '#086ACA', color: '#000', borderColor: '#086ACA'},
  3: {backgroundColor: '#FF0', color: '#000', borderColor: '#FF0'},
  4: {backgroundColor: '#06F902', color: '#000', borderColor: '#06F902'},
  5: {backgroundColor: '#F37108', color: '#000', borderColor: '#F37108'}
}

export const tipoFrecuencia = {
  DIARIA: "1",
  SEMANAL: "2",
  MENSUAL: "3",
  ANUAL: "4"
}

export const translateTipoFrecuencia = {
  "1": "FRECUENCIA_AGENDA.DIARIA",
  "2": "FRECUENCIA_AGENDA.SEMANAL",
  "3": "FRECUENCIA_AGENDA.MENSUAL",
  "4": "FRECUENCIA_AGENDA.ANUAL"
}

export const tipoFrecuenciaSimple = {
  DIA: "1",
  MES: "2",
  ANNO: "3"
}

export const translateTipoFrecuenciaSimple = {
  "1": "FRECUENCIA_AGENDA.DIA",
  "2": "FRECUENCIA_AGENDA.MES",
  "3": "FRECUENCIA_AGENDA.ANNO",
}

export const tipoFrecuenciaDiaria = {
  CADA_X_DIAS: "1",
  TODOS_DIAS: "2"
}
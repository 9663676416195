import actionTypes from '../../../constants/actions/admin/licencias/licencias'

export function initialState () {
  return {
    list: [],
    showModalLicencias: false,
    data: {}
  }
}

export function fetchLicenciasSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function abrirModalLicenciasSuccess (state, {data}) {
  return {
    ...state,
    showModalLicencias: true,
    data
  }
}

export function cerrarModalLicenciasSuccess (state) {
  return {
    ...state,
    showModalLicencias: false,
    data: {}
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_LICENCIAS_SUCCESS:
      return fetchLicenciasSuccess(state, action)
    case actionTypes.ABRIR_MODAL_LICENCIAS_SUCCESS:
      return abrirModalLicenciasSuccess(state, action)
    case actionTypes.CERRAR_MODAL_LICENCIAS_SUCCESS:
      return cerrarModalLicenciasSuccess(state, action)
    default:
      return state
  }
}
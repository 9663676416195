import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInicioControlMorfologico from '../../../components/planSanitario/controlCalidad/ModalInicioControlMorfologico'
import {closeModalInicio, fetchFiltrarModalInicio, seleccionarTodosVerracosInicio, deSeleccionarTodosVerracosInicio} from '../../../actions/planSanitario/controlCalidad/controlMorfologico'

export function mapStateToProps (state) {
  return {
    ...state.controlMorfologico,
    filtros:state.form.FiltrosModalAnnadirControlMorfologico,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalInicio,
      fetchFiltrarModalInicio,
      seleccionarTodosVerracosInicio,
      deSeleccionarTodosVerracosInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInicioControlMorfologico))
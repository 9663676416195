import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import FichaVerraco from '../../components/fichaVerraco/FichaVerraco'
import { fetchFichaVerraco, submitNuevoVerraco, cambiarVigilado, onDeleteVerraco, abrirModalCambioEstadoVerraco, restartVerraco, obtenerPresenciaVerraco, restartVerracoDejandoFiltros, fetchFiltrarFichaVerraco } from '../../actions/fichaVerraco/fichaVerraco'
import {restartMovimientoVerraco} from '../../actions/movimientoVerraco/movimientoVerraco'
import {comboCliente} from '../../actions/combos/combos'
import {setLoadingFalse,setLoadingTrue} from '../../actions/common'
export function mapStateToProps (state) {
  return {
    fichaVerraco: {...state.fichaVerraco},
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formFichaVerraco: state.form.fichaVerraco,
    auth: state.auth,
    comboCliente: state.combos.comboCliente,
    isLoading: state.common.isLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      setLoadingFalse,
      setLoadingTrue,
      fetchFichaVerraco,
      submitNuevoVerraco,
      cambiarVigilado,
      onDeleteVerraco,
      abrirModalCambioEstadoVerraco,
      restartVerraco,
      restartMovimientoVerraco,
      obtenerPresenciaVerraco,
      comboCliente,
      restartVerracoDejandoFiltros,
      fetchFiltrarFichaVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FichaVerraco))
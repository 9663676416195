export default {
  FETCH_COPIAS_SEGURIDAD: 'FETCH_COPIAS_SEGURIDAD',
  FETCH_COPIAS_SEGURIDAD_SUCCESS: 'FETCH_COPIAS_SEGURIDAD_SUCCESS',
  ABRIR_MODAL_COPIA_SEGURIDAD: 'ABRIR_MODAL_COPIA_SEGURIDAD',
  CERRAR_MODAL_COPIA_SEGURIDAD: 'CERRAR_MODAL_COPIA_SEGURIDAD',
  SUBMIT_NUEVA_COPIA_SEGURIDAD: 'SUBMIT_NUEVA_COPIA_SEGURIDAD',
  DESCARGAR_COPIA_SEGURIDAD: 'DESCARGAR_COPIA_SEGURIDAD',
  DESCARGAR_COPIA_SEGURIDAD_SUCCESS: 'DESCARGAR_COPIA_SEGURIDAD_SUCCESS',
  ON_DELETE_COPIA_SEGURIDAD: 'ON_DELETE_COPIA_SEGURIDAD',
  SELECCIONAR_TODOS_COPIA_SEGURIDAD: 'SELECCIONAR_TODOS_COPIA_SEGURIDAD',
  DESELECCIONAR_TODOS_COPIA_SEGURIDAD: 'DESELECCIONAR_TODOS_COPIA_SEGURIDAD',
  DELETE_SELECCION_COPIA_SEGURIDAD: 'DELETE_SELECCION_COPIA_SEGURIDAD',
  RESTAURAR_COPIA_SEGURIDAD: 'RESTAURAR_COPIA_SEGURIDAD',
  ABRIR_MODAL_COPIA_SEGURIDAD_PROGRAMADA: 'ABRIR_MODAL_COPIA_SEGURIDAD_PROGRAMADA',
  CERRAR_MODAL_COPIA_SEGURIDAD_PROGRAMADA: 'CERRAR_MODAL_COPIA_SEGURIDAD_PROGRAMADA',
  OBTENER_PROGRAMACION_COPIA_SEGURIDAD: 'OBTENER_PROGRAMACION_COPIA_SEGURIDAD',
  OBTENER_PROGRAMACION_COPIA_SEGURIDAD_SUCCESS: 'OBTENER_PROGRAMACION_COPIA_SEGURIDAD_SUCCESS',
  SUBMIT_NUEVA_COPIA_SEGURIDAD_PROGRAMADA: 'SUBMIT_NUEVA_COPIA_SEGURIDAD_PROGRAMADA',
  ABRIR_MODAL_RESTAURAR_CON_ARCHIVO: 'ABRIR_MODAL_RESTAURAR_CON_ARCHIVO',
  CERRAR_MODAL_RESTAURAR_CON_ARCHIVO: 'CERRAR_MODAL_RESTAURAR_CON_ARCHIVO',
  SUBIR_ARCHIVOS_COPIA_SEGURIDAD: 'SUBIR_ARCHIVOS_COPIA_SEGURIDAD',
  SUBMIT_COPIA_SEGURIDAD_DESDE_ARCHIVO: 'SUBMIT_COPIA_SEGURIDAD_DESDE_ARCHIVO',
  ABRIR_MODAL_RESTAURAR_MIGRACION: 'ABRIR_MODAL_RESTAURAR_MIGRACION',
  CERRAR_MODAL_RESTAURAR_MIGRACION: 'CERRAR_MODAL_RESTAURAR_MIGRACION',
  SUBMIT_RESTAURAR_MIGRACION: 'SUBMIT_RESTAURAR_MIGRACION'
}
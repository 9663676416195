import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import './TipoInstalacion.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTipoInstalacion extends Component {
  submitNuevaTipoInstalacion (values) {
    if (this.props.data){
      this.props.actions.editarTipoInstalacion(values)
    } else {
      this.props.actions.submitNuevaTipoInstalacion(values)
    }
  }
  guardarYnuevoTipoInstalacion(values){
    this.props.actions.guardarYnuevoTipoInstalacion(values)
  }
  render () {
    const {
      t, handleSubmit, showModal, list, data, duplicar, actions: {recuperarDatosModalTipoInstalacion, closeModal}, combos: {comboTipoInstalacionPadre}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" className="modal-tipo-instalacion" backdrop="static">
      <form className="form-tipo-instalacion">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('TIPO_INSTALACION.MODAL.TITULO_EDITAR')
            ) : (
              t('TIPO_INSTALACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idTipoInstalacion && (
            <ButtonChangeRecord list={list} idElemento="idTipoInstalacion" currentId={data.idTipoInstalacion} getNextRecord={recuperarDatosModalTipoInstalacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Field
              id="nombreTipoInstalacion"
              name="nombreTipoInstalacion"
              colSm={12}
              component={InputText}
              controlLabel={t('TIPO_INSTALACION.MODAL.NOMBRE_TIPO_INSTALACION') + '*'}
              claseActivo="nombre-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="animales"
              name="animales"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.ANIMALES')}
            />
            <Field
              id="silo"
              name="silo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.SILOS')}
            />
            <Field
              id="salaExtraccion"
              name="salaExtraccion"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.SALA_EXTRACCION')}
            />
            <Field
              colSm={12}
              id="idTipoInstalacionPadre"
              name="idTipoInstalacionPadre"
              controlLabel={t('TIPO_INSTALACION.MODAL.TIPO_PADRE')}
              component={InputSelect}
              options={comboTipoInstalacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-padre'
              valorValue={data && data.idTipoInstalacionPadre}
              valorLabel={data && data.nombreTipoInstalacion}
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.ACTIVO')}
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('TIPO_INSTALACION.MODAL.OBSERVACIONES')}
              customClass='tipo-instalacion-observaciones'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('TIPO_INSTALACION.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoTipoInstalacion.bind(this)) : null : undefined}
              >{t('TIPO_INSTALACION.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaTipoInstalacion.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2)}
          >{t('TIPO_INSTALACION.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModal}>{t('TIPO_INSTALACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoInstalacion',
})(ModalTipoInstalacion))
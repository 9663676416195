export default {
  FETCH_CONTROL_SEMEN: "FETCH_CONTROL_SEMEN",
  FETCH_CONTROL_SEMEN_SUCCESS: "FETCH_CONTROL_SEMEN_SUCCESS",
  SELECCIONAR_TODOS_CONTROL_SEMEN: "SELECCIONAR_TODOS_CONTROL_SEMEN",
  DESELECCIONAR_TODOS_CONTROL_SEMEN: "DESELECCIONAR_TODOS_CONTROL_SEMEN",
  DELETE_SELECCION_CONTROL_SEMEN: 'DELETE_SELECCION_CONTROL_SEMEN',
  OPEN_MODAL_LISTADO_CONTROL_SEMEN: 'OPEN_MODAL_LISTADO_CONTROL_SEMEN',
  OPEN_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS: 'OPEN_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS',
  CLOSE_MODAL_LISTADO_CONTROL_SEMEN: 'CLOSE_MODAL_LISTADO_CONTROL_SEMEN',
  CLOSE_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS: 'CLOSE_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS',
  SELECCIONAR_TODOS_MODAL_CONTROL_SEMEN: 'SELECCIONAR_TODOS_MODAL_CONTROL_SEMEN',
  DESELECCIONAR_TODOS_MODAL_CONTROL_SEMEN: 'DESELECCIONAR_TODOS_MODAL_CONTROL_SEMEN',
  DELETE_CONTROL_SEMEN: 'DELETE_CONTROL_SEMEN',
  DELETE_SELECCION_CONTROL_SEMEN: 'DELETE_SELECCION_CONTROL_SEMEN',
  CREATE_CSV_CONTROL_SEMEN: 'CREATE_CSV_CONTROL_SEMEN',
  IMPRIMIR_PDF_CONTROL_SEMEN: 'IMPRIMIR_PDF_CONTROL_SEMEN',
  ABRIR_MODAL_INICIO_CONTROL_SEMEN: 'ABRIR_MODAL_INICIO_CONTROL_SEMEN',
  CLOSE_MODAL_INICIO_CONTROL_SEMEN: 'CLOSE_MODAL_INICIO_CONTROL_SEMEN',
  FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN: 'FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN',
  FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN_SUCCESS: 'FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN_SUCCESS',
  SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN: 'SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN',
  DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN: 'DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN',
  ANADIR_VERRACOS_INICIO_CONTROL_SEMEN: 'ANADIR_VERRACOS_INICIO_CONTROL_SEMEN',
  ANADIR_VERRACOS_INICIO_CONTROL_SEMEN_SUCCESS: 'ANADIR_VERRACOS_INICIO_CONTROL_SEMEN_SUCCESS',
  SUBIR_ARCHIVO_INFORME: 'SUBIR_ARCHIVO_INFORME',
  DESCARGAR_ARCHIVO_CONTROL_SEMEN: 'DESCARGAR_ARCHIVO_CONTROL_SEMEN',
  IMPRIMIR_ETIQ_CONTROL_SEMEN: 'IMPRIMIR_ETIQ_CONTROL_SEMEN',
  RECUPERAR_DATOS_MODAL_CONTROL_SEMEN_SUCCESS: 'RECUPERAR_DATOS_MODAL_CONTROL_SEMEN_SUCCESS',
  ABRIR_MODAL_CONTROL_SEMEN: 'ABRIR_MODAL_CONTROL_SEMEN',
  CLOSE_MODAL_CONTROL_SEMEN: 'CLOSE_MODAL_CONTROL_SEMEN',
  GUARDAR_MODAL_CONTROL_SEMEN: 'GUARDAR_MODAL_CONTROL_SEMEN',
  RECUPERAR_DATOS_MODAL_CONTROL_SEMEN: 'RECUPERAR_DATOS_MODAL_CONTROL_SEMEN'
}  
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosBioseguridadHigiene from '../../../components/planSanitario/bioseguridadHigiene/FiltrosBioseguridadHigiene'
import {openModal} from '../../../actions/common'
import { abrirModalVacioBioseguridadHigiene, seleccionarTodosBioseguridadHigiene, deSeleccionarTodosBioseguridadHigiene, deleteSeleccionBioseguridadHigiene, fetchFiltrarBioseguridadHigiene,
 crearCsvBioseguridad, imprimirPdfBioseguridad} from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {comboOperario, comboTodasUbicaciones, comboInstalacionesPadreOSinHijos} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioBioseguridadHigiene,
      seleccionarTodosBioseguridadHigiene,
      deSeleccionarTodosBioseguridadHigiene,
      deleteSeleccionBioseguridadHigiene,
      comboOperario,
      comboTodasUbicaciones,
      fetchFiltrarBioseguridadHigiene,
      crearCsvBioseguridad,
      imprimirPdfBioseguridad,
      comboInstalacionesPadreOSinHijos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosBioseguridadHigiene))

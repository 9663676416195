import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/notificaciones/notificaciones'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'
import marcarNotificacionLeida from '../../services/notificaciones/marcarNotificacionLeida'
import marcarNotificacionLeidaStock from '../../services/notificaciones/marcarNotificacionLeidaStock'

export function * obtenerNotificaconesUsuario ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {}
}
export function * watchObtenerNotificaconesUsuario () {
  yield takeLatest(actionTypes.OBTENER_NOTIFICACIONES_USUARIO, obtenerNotificaconesUsuario)
}

export function * marcarComoLeidoNotificacion ({idAgenda, valLeido}) {
  try {
    let auth = yield select(state => state.auth)

    yield yield call(marcarNotificacionLeida, idAgenda, valLeido, null)

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {}
}
export function * watchMarcarComoLeidoNotificacion () {
  yield takeLatest(actionTypes.MARCAR_COMO_LEIDO_NOTIFICACION, marcarComoLeidoNotificacion)
}

export function * marcarComoLeidoNotificacionStock ({idProducto, valLeido}) {
  try {
    let auth = yield select(state => state.auth)

    yield yield call(marcarNotificacionLeidaStock, idProducto, valLeido, null)

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {}
}
export function * watchMarcarComoLeidoNotificacionStock () {
  yield takeLatest(actionTypes.MARCAR_COMO_LEIDO_NOTIFICACION_STOCK, marcarComoLeidoNotificacionStock)
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {comboProductosActivos, comboLotesActivos} from '../../actions/combos/combos'
import {
  seleccionarTodosMaterialDisponible, deSeleccionarTodosMaterialDisponible
} from '../../actions/trazabilidadMaterial/materialDisponible'
import { fetchMaterialDisponible } from '../../actions/trazabilidadMaterial/materialDisponible'
import FiltrosMaterialDisponible from '../../components/trazabilidadMaterial/FiltrosMaterialDisponible'

const mapStateToProps = (state) => {
  return {
    ...state.materialDisponible,
    combos: {
      comboProductosActivos: state.combos.comboProductosActivos,
      comboLotesActivos: state.combos.comboLotesActivos
    },
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchMaterialDisponible,
      seleccionarTodosMaterialDisponible,
      deSeleccionarTodosMaterialDisponible,
      comboProductosActivos,
      comboLotesActivos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosMaterialDisponible))
import actionTypes from '../../constants/actions/ruta/ruta'
import actionTypesComun from '../../constants/actions/common'

export function initialState () {
  return {
    list: [],
    checkedFiltrosRuta: false,
    filtros:{},
    clientesAnadidos: [],
    showModalClienteRuta: false,
    checkedClienteRuta: false,
    data: [],
    dataDuplicar: [],
    listClientes: [],
    checkedRuta: false,
    filtrosCliente: {},
    datosExcelRuta: [],
    datosPdfRuta: [],
    showModalRuta: false
  }
}

export function abrirModalVacioRutaSuccess (state) {
  return {
    ...state,
    showModalRuta: true
  }
}

export function closeModal (state) {
  return {
    ...state,
    clientesAnadidos: [],
    checkedFiltrosRuta: false,
    data: [],
    dataDuplicar: [],
    showModalRuta: false
  }
}

export function limpiar (state) {
  return {
    ...state,
    clientesAnadidos: [],
    data: [],
    dataDuplicar: []
  }
}

export function fetchRutaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function abrirModalClienteRuta (state) {
  return {
    ...state,
    showModalClienteRuta: true
  }
}

export function cerrarModalClienteRuta (state) {
  return {
    ...state,
    showModalClienteRuta: false,
    checkedClienteRuta: false,
  }
}

export function seleccionarTodosModalClienteRuta (state) {
  return {
    ...state,
    checkedClienteRuta: true
  }
}

export function deSeleccionarTodosModalClienteRuta (state) {
  return {
    ...state,
    checkedClienteRuta: false
  }
}

export function anadirCliente (state, {clientesAnadidos}) {
  return {
    ...state,
    clientesAnadidos: state.clientesAnadidos.concat(clientesAnadidos),
    showModalClienteRuta: false,
    checkedClienteRuta: false
  }
}

export function onDeleteClienteAnadidoSuccess (state, {clientesAnadidos}) {
  return {
    ...state,
    clientesAnadidos,
    checkedRuta: false
  }
}

export function fetchClienteRutaSuccess (state, {listClientes}) {
  return {
    ...state,
    listClientes
  }
}

export function seleccionarTodosModalRuta (state) {
  return {
    ...state,
    checkedRuta: true
  }
}

export function deSeleccionarTodosModalRuta (state) {
  return {
    ...state,
    checkedRuta: false
  }
}

export function recuperarDatosModalRutaSuccess (state,{data}) {
  return {
    ...state,
    data,
    clientesAnadidos: data.cliente ? (data.cliente) : [],
    dataDuplicar: []
  }
}

export function duplicarDatosModalRutaSuccess (state,{dataDuplicar}) {
  return {
    ...state,
    dataDuplicar,
    clientesAnadidos: dataDuplicar.cliente ? (dataDuplicar.cliente) : [],
    data: []
  }
}

export function seleccionarTodosRuta (state) {
  return {
    ...state,
    checkedFiltrosRuta: true
  }
}

export function deSeleccionarTodosRuta (state) {
  return {
    ...state,
    checkedFiltrosRuta: false
  }
}

export function fetchFiltrarRutaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function fetchFiltrarClienteRutaSuccess (state, {filtrosCliente}) {
  return {
    ...state,
    filtrosCliente
  }
}

export function crearCsvRutaSuccess (state, {datosExcelRuta}) {
  return {
    ...state,
    datosExcelRuta
  }
}

export function imprimirPdfRutaSuccess (state, {datosPdfRuta}) {
  return {
    ...state,
    datosPdfRuta
  }
}

export function cambiarEstadoRutaSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteRutaSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}
export function submitNuevaRutaSuccess (state, {insercionRuta}) {
  return {
    ...state,
    insercionRuta
  }
}

export function editRutaSuccess (state, {edicionRuta}) {
  return {
    ...state,
    edicionRuta
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_VACIO_RUTA_SUCCESS:
      return abrirModalVacioRutaSuccess(state, action)
    case actionTypes.FETCH_RUTA_SUCCESS:
      return fetchRutaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_CLIENTE_RUTA:
      return abrirModalClienteRuta(state, action)
    case actionTypes.CERRAR_MODAL_CLIENTE_RUTA:
      return cerrarModalClienteRuta(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_CLIENTE_RUTA:
      return seleccionarTodosModalClienteRuta(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_CLIENTE_RUTA:
      return deSeleccionarTodosModalClienteRuta(state, action)
    case actionTypes.ANADIR_CLIENTE:
      return anadirCliente(state, action)
    case actionTypes.ON_DELETE_CLIENTE_ANADIDO_SECCESS:
      return onDeleteClienteAnadidoSuccess(state, action)
    case actionTypes.FETCH_CLIENTE_RUTA_SUCCESS:
      return fetchClienteRutaSuccess(state, action)
    case actionTypesComun.CLOSE_MODAL:
      return closeModal(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_RUTA:
      return seleccionarTodosModalRuta(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_RUTA:
      return deSeleccionarTodosModalRuta(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_RUTA_SUCCESS:
      return recuperarDatosModalRutaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_RUTA_SUCCESS:
      return duplicarDatosModalRutaSuccess(state, action)
    case actionTypes.LIMPIAR:
      return limpiar(state, action)
    case actionTypes.SELECCIONAR_TODOS_RUTA:
      return seleccionarTodosRuta(state, action)
    case actionTypes.DESELECCIONAR_TODOS_RUTA:
      return deSeleccionarTodosRuta(state, action)
    case actionTypes.FETCH_FILTRAR_RUTA_SUCCESS:
      return fetchFiltrarRutaSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_CLIENTE_RUTA_SUCCESS:
      return fetchFiltrarClienteRutaSuccess(state, action)
    case actionTypes.CREATE_CSV_RUTA_SUCCESS:
      return crearCsvRutaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_RUTA_SUCCESS:
      return imprimirPdfRutaSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_RUTA_SUCCESS:
      return submitNuevaRutaSuccess(state, action)
    case actionTypes.EDIT_RUTA_SUCCESS:
      return editRutaSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_RUTA_SUCCESS:
      return cambiarEstadoRutaSuccess(state, action)
    case actionTypes.ON_DELETE_RUTA_SUCCESS:
      return onDeleteRutaSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col } from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import Button from '../comun/button/Button'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import { date_parser, number_formatter, dateTimeZones } from '../../util/formatFunctions'
import ModalNuevoPoolAsignacionPage from '../../containers/asignacionPoolVerraco/ModalNuevoPoolAsignacionPage'

class ModalPoolAnalisisExtraccion extends Component {
  constructor (props) {
    super()
    this.state = {
      idPoolFormAnalisis: null,
      PoolSeleccionado: null,
      idPool: null,
      idPoolAnterior: 0,
      nombrePool: null
    }
    this.RowClickCallback = this.RowClickCallback.bind(this);
    this.asignarPoolAnalisisExtraccion = this.asignarPoolAnalisisExtraccion.bind(this)
    this.asignarNewPoolAnalisisExtraccion = this.asignarNewPoolAnalisisExtraccion.bind(this)
    this.asignarPoolAnalisisExtraccionEnvasar = this.asignarPoolAnalisisExtraccionEnvasar.bind(this)
  }

  componentDidMount () {
  }

  componentDidUpdate (prevProps) {
    //console.log(this.props)
    let idPool = (this.props.formAnalisisExtraccion && this.props.formAnalisisExtraccion.idPool != 'undefined') ? this.props.formAnalisisExtraccion.idPool: null;
    if (this.props.showModalPoolAnalisisExtraccion && this.props.showModalPoolAnalisisExtraccion !== prevProps.showModalPoolAnalisisExtraccion) {
      this.setState({idPoolFormAnalisis: idPool, PoolSeleccionado: null, idPool: idPool, idPoolAnterior: 0, nombrePool: null})
      this.fetchPools(idPool)
    }
  }

  fetchPools(idPool){
    let filtros = { ...this.props.filtros, poolEliminado: true, desde: '', 'seleccionar-todo': false, 'raza': {"value": this.props.formModalPoolAnalisisExtraccion.idRaza, "label": this.props.formModalPoolAnalisisExtraccion.nombreRaza} }
    if(idPool != null) {
      filtros = { ...filtros, pool: {value: idPool, label: ""}}
      this.props.actions.recuperarDatosPool(idPool);
    }else{
      filtros = { ...filtros, 'filtrar_no_envasados': true}
    }
    this.props.actions.fetchPoolExtracciones(filtros);
  }

  RowClickCallback(row, idRow) {
    if(this.state.idPoolFormAnalisis == null){
      this.setState({
        idPoolAnterior: (this.state.idPool != this.state.idPoolAnterior) ? this.state.idPool : 0,
        idPool: row.idPool,
        nombrePool: row.nombrePool,
        selectedRow: idRow,
        idDilucion: row.idDilucion,
        filtroRazaTemporal: false
      },
        this.updateTableInfo
      );
    }
  }

  updateTableInfo() {
    let filtros = { ...this.props.filtros, poolEliminado: true, 'filtrar_no_envasados': true, 'raza': {"value": this.props.formModalPoolAnalisisExtraccion.idRaza, "label": this.props.formModalPoolAnalisisExtraccion.nombreRaza} }
    //if (this.state.idPool == this.state.idPoolAnterior) {
    //  filtros = { ...this.props.filtros }
    //}
    this.props.actions.fetchPoolExtracciones(filtros)
    this.props.actions.recuperarDatosPool(this.state.idPool);
  }




  asignarPoolAnalisisExtraccion(data) {
    if (this.state.idPool != null) {
      return new Promise((resolve, reject) => {
        this.props.actions.guardarModalPoolAnalisisExtraccion(data, this.state.idPool, null, null, this.props.match.params.idAnalisisExtraccion || null, this.props.history, () => {
          resolve()
        }, reject)
      })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
    } else {
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('ASIGNACION_ANALISIS_POOL.ALERTAS.NO_POOL'))
    }
  }

  asignarPoolAnalisisExtraccionEnvasar(data){
    data.envasar = true
    this.asignarPoolAnalisisExtraccion(data)
  }

  asignarNewPoolAnalisisExtraccion(data) {
    data.redirect = 'RedirectAnalisis'
    data.idAnalisis = this.props.match.params.idAnalisisExtraccion
    //gestionamos la logica con la variable redirect
    this.props.actions.abrirModalNuevoPoolAsignacion(data);
  }




  render () {
    const {
      t, handleSubmit, showModalPoolAnalisisExtraccion, poolList, asignadosAnalisisList,
      actions: {cerrarModalPoolAnalisisExtraccion}
    } = this.props
    const tKey = 'ANALISIS_EXTRACCIONES.DILUCION.'
    const tKeyPool = 'POOL_EXTRACCIONES.FORM.'
    const idElemento = 'idAnalisis'
    
    // TABLA POOLS
    const table = {
      id: 'poolTable',
      multiHeader: false,
      hasNestedRows: true,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      //onEdit: (row) => this.onEdit(row),
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      mostrarFilaAnnadir: false,
      mostrarFilaEliminar: false,
      hasPagination: true,
      selectedRow:this.state.selectedRow,
      isDraggable:false,             
      //dropCellCallback:this.dropCellCallback,
      onClickCallback:this.RowClickCallback,
      columns: [
        { id: 'nombrePool', name: t(tKeyPool + 'FORM.NOMBRE') },
        { id: 'nombreRaza', name: t(tKeyPool + 'FORM.RAZA') },
        { id: 'nombreTipoDosis', name: t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS') },
        { id: 'porFaTotal', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        { id: 'volumenDiluyente', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0 },
        { id: 'dosisReales', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.DOSIS') },
        { id: 'concentracionXDosisUtil', name: t(tKeyPool + 'FORM.CONCENTRACION_X_DOSIS_UTIL') },
        { id: 'numeroVerracos', name: t(tKeyPool + 'FORM.NUMERO_VERRACOS') },
      ],

      rows: poolList,
      //showModal: this.props.showModal,
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.idPool == this.state.idPool && this.state.idPool != this.state.idPoolAnterior) {
          classRow = 'analisis-pertenece-pool'
        }
        return classRow
      }
    }


    //control que no hay pool filtrado
    let rowsAsignadosAnalisisList = asignadosAnalisisList
    /*if(this.props.filtros != null && this.props.filtros.pool == null){      
      rowsAsignadosAnalisisList = []
    } */
    if (this.state.idPool != null && this.state.idPool == this.state.idPoolAnterior) {
      rowsAsignadosAnalisisList = []
    }
    //con un pool seleccionado, evitamos filtrar y que aparezca
    if(poolList.find(x => x.idPool == this.state.idPool) === undefined) rowsAsignadosAnalisisList = []


    const tablePoolExtracciones = {
      id: 'poolExtraccionesTable',
      multiHeader: false,
      hasPagination: false,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      mostrarEliminarDelPool: false,
      idElemento,    
      columns: [
        { id: 'codigo', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.CODIGO'), type: 'numero', numDecimales: 0 },
        { id: 'codVerraco', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VERRACO') },
        { id: 'volumen', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOL'), type: 'numero', numDecimales: 0 },
        { id: 'volumenDiluyente', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0 },
        { id: 'nombreTipoDosis', name: t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS') },
        { id: 'faTotalPercent', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        { id: 'dosisReales', name: t(tKeyPool + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.DOSIS'), type: 'numero', numDecimales: 0 }
      ],
      rows: rowsAsignadosAnalisisList,
      //showModal: this.props.showModalAsignacionAnalisisPool,
      initialValues: {}
    }


    return (
      <Modal show={showModalPoolAnalisisExtraccion} onHide={cerrarModalPoolAnalisisExtraccion} backdrop="static" bsSize="large">
        <Modal.Header closeButton={true}>
          <Modal.Title>{t('ASIGNACION_ANALISIS_POOL.TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ModalNuevoPoolAsignacionPage />
          <form>
            <Row>
              <Field
                colSm={2}
                id="codVerraco"
                name="codVerraco"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.VERRACO')}
                component={InputText}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={2}
                id="nombreRaza"
                name="nombreRaza"
                controlLabel={t(tKeyPool + 'FORM.RAZA')}
                component={InputText}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={2}
                id="numeroDosis"
                name="numeroDosis"
                controlLabel={t(tKey + 'FORM.NUMERO_DOSIS')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={2}
                id="volumenExtraccion"
                name="volumenExtraccion"
                controlLabel={t(tKey + 'FORM.VOL_EYACULADO')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={2}
                id="volumenDiluyente"
                name="volumenDiluyente"
                controlLabel={t(tKey + 'FORM.VOL_DILUYENTE')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={2}
                id="tipoDosis"
                name="tipoDosis"
                controlLabel={t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS')}
                component={InputText}
                disabled={true}
              />
            </Row>

            <Row>
              <Col sm={12}>
                <h3>{t('ASIGNACION_ANALISIS_POOL.POOLS')}</h3>
                <SimpleTablePage
                  {...table}>
                </SimpleTablePage>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <h3>{t('ASIGNACION_ANALISIS_POOL.DETALLE_POOLS')}</h3>
                <SimpleTablePage
                  {...tablePoolExtracciones}>
                </SimpleTablePage>
              </Col>
            </Row>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-flex">
            <Col sm={12}>
              {this.state.idPoolFormAnalisis == null && 
                <>
                <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.asignarNewPoolAnalisisExtraccion.bind(this))}>{t('ASIGNACION_ANALISIS_POOL.ACCIONES.ANADIR_NUEVO_POOL')}</Button>
                <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.asignarPoolAnalisisExtraccion.bind(this))}>{t(tKey + 'BUTTONS.GUARDAR')}</Button>
                </>
              }
              {this.state.idPoolFormAnalisis == null && this.state.idPool &&
                <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.asignarPoolAnalisisExtraccionEnvasar.bind(this))}>{t('ASIGNACION_ANALISIS_POOL.ACCIONES.GUARDAR_Y_ENVASAR')}</Button>
              }
              <Button type="button" className="btn btn-white" onClick={cerrarModalPoolAnalisisExtraccion}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalPoolAnalisisExtraccion',
})(ModalPoolAnalisisExtraccion))
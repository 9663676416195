import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalInstalacion extends Component {
  submitNuevaInstalacion (values) {
    if (Object.keys(this.props.data).length > 0){
      this.props.actions.editarInstalacion(values)
    } else {
      this.props.actions.submitNuevaInstalacion(values)
    }
  }
  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.data).length > 0){
      if (this.props.data !== prevProps.data) {
        this.props.actions.comboInstalacionPadre({value: this.props.data.idTipoInstalacion})
      }
    }
    if (Object.keys(this.props.dataDuplicar).length > 0){
      if (this.props.dataDuplicar !== prevProps.dataDuplicar) {
        this.props.actions.comboInstalacionPadre({value: this.props.dataDuplicar.idTipoInstalacion})
      }
    }
  }
  guardarYnuevoInstalacion(values){
    this.props.actions.guardarYnuevoInstalacion(values)
  }
  handleTipoInstalacionPadreChange(value){
    this.props.actions.comboInstalacionPadre(value)
  }
  closeModal () {
    this.props.actions.closeModal()
    this.props.actions.comboInstalacionPadre({value: null})
  }
  render () {
    const {
      t, handleSubmit, showModal, list, data, duplicar, actions: {recuperarDatosModalInstalacion, closeModal}, combos: {comboTipoInstalacion, comboInstalacionPadre}
    } = this.props
    return (
      <Modal show={showModal} onHide={() => this.closeModal()} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-instalacion">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('INSTALACION.MODAL.TITULO_EDITAR')
            ) : (
              t('INSTALACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idInstalacion && (
            <ButtonChangeRecord list={list} idElemento="idInstalacion" currentId={data.idInstalacion} getNextRecord={recuperarDatosModalInstalacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="nombreInstalacion"
              name="nombreInstalacion"
              colSm={4}
              component={InputText}
              controlLabel={t('INSTALACION.MODAL.NOMBRE_INSTALACION') + '*'}
              claseActivo="nombre-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="codInstalacion"
              name="codInstalacion"
              colSm={4}
              component={InputText}
              controlLabel={t('INSTALACION.MODAL.CODIGO') + '*'}
              claseActivo="cod-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              id="dimensionesInstalacion"
              name="dimensionesInstalacion"
              colSm={2}
              component={InputText}
              controlLabel={t('INSTALACION.MODAL.DIMENSIONES1')}
              claseActivo="dimensiones-operario"
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              id="dimensiones2Instalacion"
              name="dimensiones2Instalacion"
              colSm={2}
              component={InputText}
              controlLabel={t('INSTALACION.MODAL.DIMENSIONES2')}
              claseActivo="dimensiones-operario"
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              colSm={4}
              id="idTipoInstalacion"
              name="idTipoInstalacion"
              controlLabel={t('INSTALACION.MODAL.TIPO_UBICACION') + '*'}
              component={InputSelect}
              options={comboTipoInstalacion}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-instalacion'
              onInputChange={(value) => this.handleTipoInstalacionPadreChange(value)}
              validate={required}
            />
            <Field
              colSm={4}
              id="idInstalacionPadre"
              name="idInstalacionPadre"
              controlLabel={t('INSTALACION.MODAL.INSTALACION_PADRE') + (Object.keys(comboInstalacionPadre).length > 0 ? '*' : '')}
              component={InputSelect}
              options={comboInstalacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-padre'
              validate={Object.keys(comboInstalacionPadre).length > 0 ? required : null}
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('INSTALACION.MODAL.OBSERVACIONES')}
              customClass='instalacion-observaciones'
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('INSTALACION.MODAL.ACTIVO')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.data).length > 0 && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.INSTALACIONES] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('INSTALACION.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            Object.keys(this.props.data).length === 0 && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.INSTALACIONES] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoInstalacion.bind(this)) : null : undefined}
              >{t('INSTALACION.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaInstalacion.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.INSTALACIONES] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.INSTALACIONES] < 2)}
          >{t('INSTALACION.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.closeModal()}>{t('INSTALACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalInstalacion',
})(ModalInstalacion))
import React, { Component } from 'react'
import { Modal, Button, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import './Escandallo.scss'
import FiltrosEscandalloAnalisisPage from '../../containers/escandallo/FiltrosEscandalloAnalisisPage'

class ModalAnadirEscandallo extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
    }
  }

  componentDidMount () {
    this.props.actions.comboProductoEscandallo()
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectorProceso !== prevProps.selectorProceso) {
      let textProceso = ''
      if (this.props.selectorProceso === 1) {
        textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')
      } else if (this.props.selectorProceso === 2) {
        textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')
      } else if (this.props.selectorProceso === 3) {
        textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')
      }
      this.props.change('proceso', {value: this.props.selectorProceso, label: textProceso})
    }
  }

  closeModalAnadirEscandallo () {
    this.props.actions.closeModalAnadirEscandallo()
    this.setState({mostrarEnviarAviso: false})
    /*this.props.change('producto', '')
    this.props.change('cantidadEscandallo', '')
    this.props.change('unidadMedida', '')*/
    let textProceso = ''
    if (this.props.selectorProceso === 1) {
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')
    } else if (this.props.selectorProceso === 2) {
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')
    } else if (this.props.selectorProceso === 3) {
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')
    }
    this.props.change('proceso', {value: this.props.selectorProceso, label: textProceso})
    const initialValues = {}
    initialValues.proceso = {value: this.props.selectorProceso, label: textProceso}
    this.props.initialize(initialValues)
  }

  submitNuevoEscandallo (values) {
    values.unidadMedida = values.producto.unidadMedida
    values.nombreProducto = values.producto.label
    values.esGuardarYnuevo = false
    let textProceso = ''
    if (values.proceso.value === 1){
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')
      if (Object.keys(this.props.dataEscandallo).length > 0){
        this.props.actions.editarEscandalloAnadido(values, values.proceso.value)
      } else {
        this.props.actions.andadirEscandalloAnalisis(values)
      }
    } else if (values.proceso.value === 2) {
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')
      if (Object.keys(this.props.dataEscandallo).length > 0){
        this.props.actions.editarEscandalloAnadido(values, values.proceso.value)
      } else {
        this.props.actions.andadirEscandalloDilucion(values)
      }
    } else if (values.proceso.value === 3) {
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')
      if (Object.keys(this.props.dataEscandallo).length > 0){
        this.props.actions.editarEscandalloAnadido(values, values.proceso.value)
      } else {
        this.props.actions.andadirEscandalloEnvasado(values)
      }
    }

    this.props.change('proceso', {value: this.props.selectorProceso, label: textProceso})
    const initialValues = {}
    initialValues.proceso = {value: this.props.selectorProceso, label: textProceso}
    this.props.initialize(initialValues)
    this.closeModalAnadirEscandallo()
  }

  guardarYnuevoEscandallo (values) {
    values.unidadMedida = values.producto.unidadMedida
    values.nombreProducto = values.producto.label
    values.esGuardarYnuevo = true
    let textProceso = ''
    if (values.proceso.value === 1){
      this.props.actions.andadirEscandalloAnalisis(values)
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')
    } else if (values.proceso.value === 2) {
      this.props.actions.andadirEscandalloDilucion(values)
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')
    } else if (values.proceso.value === 3) {
      this.props.actions.andadirEscandalloEnvasado(values)
      textProceso = this.props.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')
    }
    this.props.change('proceso', {value: this.props.selectorProceso, label: textProceso})
    const initialValues = {}
    initialValues.proceso = {value: values.proceso.value, label: textProceso}
    this.props.initialize(initialValues)
  }

  handleChangeProducto (values) {
    if (values !== null) {
      if (values.unidadMedida === 1){
        this.props.change('unidadMedida', this.props.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES'))
      } else if (values.unidadMedida === 2) {
        this.props.change('unidadMedida', this.props.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS'))
      } else {
        this.props.change('unidadMedida', '')
      }
    }
  }

  render () {
    const {
      t, handleSubmit, showModalAnadirEscandallo, dataEscandallo,
      escandalloAnalisis, escandalloDilucion, escandalloEnvasado,
      duplicar
    } = this.props

    const comboProcesoEscandallo = [
      {value: 1, label: t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')},
      {value: 2, label: t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')},
      {value: 3, label: t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')}
    ]

    // Filtrar los productos ya utilizados en el escandallo.
    let comboProductoEscandallo = this.props.combos.comboProductoEscandallo
    let proceso = this.props.formModalAnadirEscandallo && this.props.formModalAnadirEscandallo.proceso && this.props.formModalAnadirEscandallo.proceso.value
    let productoActual = (dataEscandallo.idProducto || (dataEscandallo.producto && dataEscandallo.producto.value) || null)
    if (proceso) {
      comboProductoEscandallo = comboProductoEscandallo.filter(productoEscandallo => {
        let escandallosProcesoProductos = (
          proceso === 1 ? escandalloAnalisis :
          proceso === 2 ? escandalloDilucion :
          escandalloEnvasado
        )
        return !escandallosProcesoProductos.some(escandallosProcesoProducto => {
          let idProducto = escandallosProcesoProducto.idProducto || escandallosProcesoProducto.producto.value
          return idProducto === productoEscandallo.value && idProducto !== productoActual
        })
      })
    }

    return (
      <Modal show={showModalAnadirEscandallo} onHide={() => this.closeModalAnadirEscandallo()} bsSize="large" dialogClassName="xs" backdrop="static" aria-labelledby="contained-modal-title-lg">
      <form className="form-escandallo" onSubmit={handleSubmit(this.submitNuevoEscandallo.bind(this))}>
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(dataEscandallo).length > 0 ? (
              t('ALMACEN.ESCANDALLO.MODAL.TITLE_PRODUCTO_EDITAR')
            ) : (
              Object.keys(this.props.dataDuplicarEscandallo).length > 0 ? (
                t('ALMACEN.ESCANDALLO.MODAL.TITLE_PRODUCTO_DUPLICAR')
              ) : (
                t('ALMACEN.ESCANDALLO.MODAL.TITLE_PRODUCTO_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={3}
              id="proceso"
              name="proceso"
              component={InputSelect}
              options={comboProcesoEscandallo}
              controlLabel={t('ALMACEN.ESCANDALLO.MODAL.PROCESO') + '*'}
              customClass='proceso'
              validate={required}
              isClearable={false}
              valueKey="value"
              labelKey="label"
              disabled={Object.keys(dataEscandallo).length > 0 ? true : false}
            />
            <Field
              colSm={3}
              id="producto"
              name="producto"
              component={InputSelect}
              options={comboProductoEscandallo}
              controlLabel={t('ALMACEN.ESCANDALLO.MODAL.PRODUCTO') + '*'}
              customClass='producto'
              validate={required}
              onInputChange={(value) => this.handleChangeProducto(value)}
              isClearable={false}
              valueKey="value"
              labelKey="label"
            />
            <Field
              colSm={3}
              id="cantidadEscandallo"
              name="cantidadEscandallo"
              component={InputNumerical}
              controlLabel={t('ALMACEN.ESCANDALLO.MODAL.CANTIDAD')}
              customClass="cantidadEscandallo"
            />
            <Field
              colSm={3}
              id="unidadMedida"
              name="unidadMedida"
              component={InputText}
              controlLabel={t('ALMACEN.ESCANDALLO.MODAL.UNIDAD_MEDIDA')}
              customClass="unidad-medida"
              disabled={true}
            />
            <div className="clearfix"></div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(dataEscandallo).length > 0 ? (
              <Button type="button" className="btn btn-white" onClick={handleSubmit(duplicar)}>{t('ALMACEN.ESCANDALLO.MODAL.DUPLICAR')}</Button>
            ) : (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoEscandallo.bind(this))}>{t('ALMACEN.ESCANDALLO.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button type="submit" className="btn btn-primary">{t('ALMACEN.ESCANDALLO.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.closeModalAnadirEscandallo()}>{t('ALMACEN.ESCANDALLO.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirEscandallo',
})(ModalAnadirEscandallo))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { yesNoModal } from '../../actions/common'
import { guardarModalPoolAnalisisExtraccion, cerrarModalPoolAnalisisExtraccion } from '../../actions/analisisExtracciones/analisisExtracciones'
import {fetchPoolExtracciones} from '../../actions/poolExtracciones/poolExtracciones'
import {recuperarDatosPool, abrirModalNuevoPoolAsignacion} from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool'
import ModalPoolAnalisisExtraccion from '../../components/analisisExtracciones/ModalPoolAnalisisExtraccion'

export function mapStateToProps (state) {
  return {
    ...state.analisisExtracciones.extraccion,
    poolList:state.poolExtracciones.list,
    asignadosAnalisisList:state.asignacionAnalisisPool.analisis.asignadosAnalisisList, 
    formAnalisisExtraccion: getFormValues('formAnalisisExtraccion')(state),
    formModalPoolAnalisisExtraccion: getFormValues('ModalPoolAnalisisExtraccion')(state),
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      guardarModalPoolAnalisisExtraccion,
      cerrarModalPoolAnalisisExtraccion,
      yesNoModal,
      fetchPoolExtracciones, 
      recuperarDatosPool,
      abrirModalNuevoPoolAsignacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalPoolAnalisisExtraccion)))
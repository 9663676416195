import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputTextArea from '../comun/form/InputTextArea'
import InputSelect from '../comun/form/InputSelect'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { comboRazonSocialClientesActivos } from '../../actions/combos/combos'
//import './PedidosVenta.scss'

class ModalDatosArticuloDosis extends Component {
   constructor () {
    super()
    this.state = {
      classErrorVerracos: false,
      imputSelected: false,
      idRaza: null,
      idLinea: null,
      tipoDosis: null,
      idNivelGenetico: null
    }
  }

  componentDidMount () {
    this.props.actions.comboLineaGeneticaActiva()
    this.props.actions.comboRazaActiva()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboNivelGeneticoActivo()
  }


  cerrarModalDatosDosis () {
    this.props.actions.cerrarModalDatosArticuloDosis()
    this.props.initialize()
    this.setState({idRaza: null})
    this.setState({idLinea: null})
    this.setState({tipoDosis: null})
    this.setState({idNivelGenetico: null})
  }

  anadirDatosDosis (values) {
    if (values.raza) {values.idRaza = values.raza.value}
    if (values.lineaGenetica) {values.idLinea = values.lineaGenetica.value}
    if (values.tipoDosis) {values.idTipoDosis = values.tipoDosis.value}
    if (values.nivelGenetico) {values.idNivelGenetico = values.nivelGenetico.value}
    if (values.raza) {values.raza = values.raza.label}
    if (values.lineaGenetica) {values.lineaGenetica = values.lineaGenetica.label}
    if (values.tipoDosis) {values.tipoDosis = values.tipoDosis.label}
    if (values.nivelGenetico) {values.nivelGenetico = values.nivelGenetico.label}

    this.props.actions.anadirDatosArticuloDosis(values)
    this.cerrarModalDatosDosis()
    
    /*if (Object.keys(this.props.dataPedidosVenta).length > 0) {
      this.props.actions.editarDatosDosis(values)
      this.cerrarModalDatosDosis()
    } else {
      this.props.actions.anadirDatosDosis(values)
      this.cerrarModalDatosDosis()
    }
    */
  }


  asignarIdRaza (val) {
    this.setState({idRaza: val !== null ? val.value : null})
  }

  asignarIdLineaGenetica (val) {
    this.setState({idLinea: val !== null ? val.value : null})
  }

  asignarIdNivelGenetico (val) {
    this.setState({idNivelGenetico: val !== null ? val.value : null})
  }


  render () {
    const {
      t, handleSubmit, showModalDatosArticuloDosis, dataPedidosVenta, dataPedidosVentaDuplicar, duplicar, comboVerracoPedido,
      combos:{ comboLineaGeneticaActiva, comboRazaActiva, comboTipoDosis, comboNivelGeneticoActivo }
    } = this.props


  return (
      <Modal show={showModalDatosArticuloDosis} onHide={() => this.cerrarModalDatosDosis()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              dataPedidosVenta!=null && Object.keys(dataPedidosVenta).length > 0 ? (
                t('PEDIDOS.PEDIDOS_VENTA.MODAL.DATOS_DOSIS_MODIFICAR')
              ) : (
                t('PEDIDOS.PEDIDOS_VENTA.MODAL.DATOS_DOSIS_NUEVO')
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              colSm={3}
              id="raza"
              name="raza"
              component={InputSelect}
              options={comboRazaActiva}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.RAZA') + '*'}
              customClass='raza'
              validate={required}
              onInputChange={(val) => this.asignarIdRaza(val)}
            />
            <Field
              colSm={3}
              id="lineaGenetica"
              name="lineaGenetica"
              component={InputSelect}
              options={comboLineaGeneticaActiva}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.LINEA_GENETICA')}
              customClass='lineaGenetica'
              onInputChange={(val) => this.asignarIdLineaGenetica(val)}
            />
            <Field
              colSm={3}
              id="nivelGenetico"
              name="nivelGenetico"
              component={InputSelect}
              options={comboNivelGeneticoActivo}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.NIVEL_GENETICO')}
              customClass='nivelGenetico'
              noPredeterminado={false}
              isClearable={false}
              onInputChange={(val) => this.asignarIdNivelGenetico(val)}
            />
            <Field
              colSm={3}
              id="tipoDosis"
              name="tipoDosis"
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.TIPO_DOSIS')}
              customClass='tipoDosis'
              noPredeterminado={false}
              isClearable={false}
            />            
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirDatosDosis.bind(this))}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalDatosDosis()}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDatosArticuloDosis',
})(ModalDatosArticuloDosis))

import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosInformesProductividadCentro extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: true,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
      radioParteUno: false,
      radioParteDos: false,
      radioParteTres: false,
      radioParteDosRaza: false,
      radioParteDosLinea: false,
      formFiltrosProCentro: []
    }
  }

  UNSAFE_componentWillMount () {
    this.props.change('datoMostrar', '1')
    this.props.change('agrupacion', '1')
    this.props.change('periodo', '1')
    this.props.actions.comboVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.obtenerInformeProductividadCentro({datoMostrar: 1, agrupacion: 1, periodo: 1})
  }

  obtenerInformeProductividadCentro(values){
    if (values.fechaEntrada) { values.desde = values.fechaEntrada }
    if (values.fechaSalida) { values.hasta = values.fechaSalida }
    this.props.actions.obtenerInformeProductividadCentro(values)
  }

  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesProductividadCentro !== prevProps.datosPdfInformesProductividadCentro) {
      if (this.props.datosPdfInformesProductividadCentro.pdf && this.props.datosPdfInformesProductividadCentro.pdf !== null){
        let pdf = this.props.datosPdfInformesProductividadCentro.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.PRODUCTIVIDAD_CENTRO') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formFiltrosProCentro !== prevProps.formFiltrosProCentro) {
        const valores = {
          datoMostrar: this.props.formFiltrosProCentro.values.datoMostrar,
          agrupacion: this.props.formFiltrosProCentro.values.agrupacion,
          periodo: this.props.formFiltrosProCentro.values.periodo,
          raza: this.props.formFiltrosProCentro.values.raza,
          verraco: this.props.formFiltrosProCentro.values.verraco,
          lineaGenetica: this.props.formFiltrosProCentro.values.lineaGenetica,
          desde: this.props.formFiltrosProCentro.values.desde,
          hasta: this.props.formFiltrosProCentro.values.hasta,
          tipoDosis: this.props.formFiltrosProCentro.values.tipoDosis
        }
        if (valores.agrupacion === '2' && (valores.verraco === null || valores.verraco === undefined)) {
          valores.agrupacion = '1'
        }
        if (valores.agrupacion === '3' && (valores.raza === null || valores.raza === undefined)) {
          valores.agrupacion = '1'
        }
        if (valores.agrupacion === '4' && (valores.lineaGenetica === null || valores.lineaGenetica === undefined)) {
          valores.agrupacion = '1'
        }
      this.obtenerInformeProductividadCentro(valores)
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesProductividadCentro(){
    let grafica1 = document.getElementsByTagName('canvas')[0].toDataURL("image/png")
    let filtros = this.props.filtrosProductividadCentro
    filtros.grafica1 = grafica1
    this.props.actions.imprimirPdfInformesProductividadCentro(filtros)
  }

  crearCsvInformeProductividadCentro(){
    let grafica1 = document.getElementsByTagName('canvas')[0].toDataURL("image/png")
    let filtros = this.props.filtrosProductividadCentro
    filtros.grafica1 = grafica1
    this.props.actions.crearCsvInformeProductividadCentro(filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosProductividadCentro
    values[value] = null
    this.props.actions.obtenerInformeProductividadCentro(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  elegirNumextracionesDosis (value) {
    if (value === '1') {
      this.setState({radioParteUno: false})
      this.props.change('tipoDosis', null)
    } else if (value === '2') {
      this.setState({radioParteUno: true})
    }
  }

  elegirTotalesVerraco (value) {
    if (value === '1') {
      this.setState({radioParteDos: false})
      this.setState({radioParteDosRaza: false})
      this.setState({radioParteDosLinea: false})
      this.props.change('verraco', null)
      this.props.change('raza', null)
      this.props.change('lineaGenetica', null)
    } else if (value === '2') {
      this.setState({radioParteDosRaza: false})
      this.setState({radioParteDosLinea: false})
      this.setState({radioParteDos: true})
      this.props.change('raza', null)
      this.props.change('lineaGenetica', null)
    } else if (value === '3') {
      this.setState({radioParteDosRaza: true})
      this.setState({radioParteDosLinea: false})
      this.setState({radioParteDos: false})
      this.props.change('verraco', null)
      this.props.change('lineaGenetica', null)
    } else if (value === '4') {
      this.setState({radioParteDosRaza: false})
      this.setState({radioParteDosLinea: true})
      this.setState({radioParteDos: false})
      this.props.change('verraco', null)
      this.props.change('raza', null)
    }
  }

  elegirPorDias (value) {
    if (value === '1') {
      this.setState({radioParteTres: false})
      this.props.change('fechaEntrada', null)
      this.props.change('fechaSalida', null)
    } else if (value === '2') {
      this.setState({radioParteTres: false})
      this.props.change('fechaEntrada', null)
      this.props.change('fechaSalida', null)
    } else if (value === '3') {
      this.setState({radioParteTres: true})
    }
  }

  render () {
  const {
    t, handleSubmit, filtrosProductividadCentro,
    combos: {comboVerraco, comboTipoDosis, comboRaza, comboLineaGenetica}
  } = this.props

  const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Row>
              <Col sm={12}>
                <Col sm={4}>
                  <Col sm={12}>
                    <Field name="datoMostrar" component={InputRadioButton} props={{ value: "1" }} onChange={(e, value) => this.elegirNumextracionesDosis(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.NUM_EXTRACCIONES')}
                    </Field>
                    <Field name="datoMostrar" component={InputRadioButton} props={{ value: "2" }} onChange={(e, value) => this.elegirNumextracionesDosis(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.NUM_DOSIS')}
                    </Field>
                    {
                      this.state.radioParteUno ? (
                        <Field
                          colSm={12}
                          id="tipoDosis"
                          name="tipoDosis"
                          component={InputSelect}
                          options={comboDosisFiltros}
                          valueKey="value"
                          labelKey="label"
                          customClass='nombre-tipo-dosis'
                          onInputChange={handleSubmit(this.obtenerInformeProductividadCentro.bind(this))}
                          isClearable={false}
                        />
                      ) : null
                    }
                  </Col>
                </Col>
                <Col sm={4}>
                  <Col sm={12}>
                    <Field name="agrupacion" component={InputRadioButton} props={{ value: "1" }} onChange={(e, value) => this.elegirTotalesVerraco(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.TOTALES')}
                    </Field>
                    <Field name="agrupacion" component={InputRadioButton} props={{ value: "2" }} onChange={(e, value) => this.elegirTotalesVerraco(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_VERRACO')}
                    </Field>
                    {
                      this.state.radioParteDos ? (
                        <Field
                          colSm={12}
                          id="verraco"
                          name="verraco"
                          component={InputSelect}
                          options={comboVerraco}
                          valueKey="value"
                          labelKey="label"
                          customClass='nombre-verraco'
                          onInputChange={handleSubmit(this.obtenerInformeProductividadCentro.bind(this))}
                          isClearable={false}
                        />
                      ) : null
                    }
                  </Col>
                  <Col sm={12}>
                    <Field name="agrupacion" className="nom-raza-productividad-centro" component={InputRadioButton} props={{ value: "3" }} onChange={(e, value) => this.elegirTotalesVerraco(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_RAZA')}
                    </Field>
                    {
                      this.state.radioParteDosRaza ? (
                        <Field
                          colSm={12}
                          id="raza"
                          name="raza"
                          component={InputSelect}
                          options={comboRaza}
                          valueKey="value"
                          labelKey="label"
                          customClass='nombre-raza'
                          onInputChange={handleSubmit(this.obtenerInformeProductividadCentro.bind(this))}
                          isClearable={false}
                        />
                      ) : null
                    }
                  </Col>
                  <Col sm={12}>
                  <Field name="agrupacion" component={InputRadioButton} props={{ value: "4" }} onChange={(e, value) => this.elegirTotalesVerraco(value)}>
                      {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_LINEA_GENETICA')}
                    </Field>
                    {
                      this.state.radioParteDosLinea ? (
                        <Field
                          colSm={12}
                          id="lineaGenetica"
                          name="lineaGenetica"
                          component={InputSelect}
                          options={comboLineaGenetica}
                          valueKey="value"
                          labelKey="label"
                          customClass='nombre-linea-genetica'
                          onInputChange={handleSubmit(this.obtenerInformeProductividadCentro.bind(this))}
                          isClearable={false}
                        />
                      ) : null
                    }
                  </Col>
                </Col>
                <Col sm={4} style={{padding: 0}}>
                  <Field name="periodo" component={InputRadioButton} props={{ value: "1" }} onChange={(e, value) => this.elegirPorDias(value)}>
                    {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_ANO')}
                  </Field>
                  <Field name="periodo" component={InputRadioButton} props={{ value: "2" }} onChange={(e, value) => this.elegirPorDias(value)}>
                    {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_MESES')}
                  </Field>
                  <Field name="periodo" component={InputRadioButton} props={{ value: "3" }} onChange={(e, value) => this.elegirPorDias(value)}>
                    {t('INFORMES.PRODUCTIVIDAD_CENTRO.FILTROS.POR_DIAS')}
                  </Field>
                  {
                    this.state.radioParteTres ? (
                      <Fields
                        names={['fechaEntrada', 'fechaSalida']}
                        component={InputRangeDatePickerDynamicNames}
                        colSm={12}
                        nameFrom="fechaEntrada"
                        nameTo="fechaSalida"
                        controlLabel={t('INFORMES.CLIENTES.FILTROS.PERIODO')}
                        onInputChange={handleSubmit(this.obtenerInformeProductividadCentro.bind(this))}
                        isClearable={false}
                      />
                    ) : null
                  }
                </Col>
              </Col>
            </Row>
            <div className="clearFix"></div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeProductividadCentro()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesProductividadCentro()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesProductividadCentro',
})(FiltrosInformesProductividadCentro))

import React, { Component } from 'react'
import './simple.scss'

const PageTitle = ({title}) => <h2 className='page-title'>{title}</h2>

class Simple extends Component {
  render () {
    const { t, t_key, className = 'simple-page', extraClassName = '', noTitle } = this.props
    const title = noTitle !== true ? this.props.title || t(`${t_key}TITLE`) : ''
    return (
      <div className={'page-container ' + className + ' ' + extraClassName}>
        {
          noTitle !== true ? (
            <PageTitle title={title} />
          ) : null
        }
        {this.props.children}
      </div>
    )
  }
}

export default Simple

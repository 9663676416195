import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import { CloudUpload, Delete, Warning, AddCircleOutline } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalCambiarTokenMagavision extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  submitCambiarTokenMagavision (values) {
    values.idElemento = this.props.idElemento
    this.props.actions.submitCambiarTokenMagavision(values)
  }

  cerrarModalCambiarTokenMagavision () {
    this.props.actions.cerrarModalCambiarTokenMagavision()
  }

  render () {
    const {
      t, handleSubmit, showModalCambiarTokenMagavision,
      actions: {recuperarDatosUsuario}
    } = this.props

    return (
      <Modal show={showModalCambiarTokenMagavision} onHide={() => this.cerrarModalCambiarTokenMagavision()} backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-token-magavision">
      <form className="form-token-magavision">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('USUARIOS.MODAL.CAMBIAR_TOKEN_MAGAVISION')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={12}
              id="tokenMagavision"
              name="tokenMagavision"
              component={InputText}
              controlLabel={t('USUARIOS.MODAL.TOKEN_MAGAVISION') + '*'}
              customClass='token-magavision'
              validate={required}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2)}
            onClick={handleSubmit(this.submitCambiarTokenMagavision.bind(this))}
          >{t('USUARIOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalCambiarTokenMagavision()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCambiarTokenMagavision',
})(ModalCambiarTokenMagavision))
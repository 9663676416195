import actionTypes from '../../constants/actions/asignacionPoolVerraco/asignacionPoolVerraco';


export function initialState() {
  return {
    codigo: 1,
    pendientesAsignarList: [],
    extraccion: null,
    showModal: false,
    analisis: {
      asignadosAnalisisList: []
    },
    numeroPool: ''
  }
}
export function abrirModalNuevoPoolAsignacion(state, { extraccion }) {
  return {
    ...state,
    extraccion
  }
}
export function abrirModalNuevoPoolAsignacionSuccess(state, { extraccion }) {

  return {
    ...state,
    showModal: true,
    extraccion
  }
}

export function closeModal(state) {
  return {
    ...state,
    showModal: false,
  }
}

export function closeModalSuccess(state) {
  return {
    ...state    
  }
}

export function createNewPool(state, { values }) {  
  return {
    ...state,
    values
  }
}

export function createNewPoolSuccess(state, { idPool }) {  
  return {
    ...state,
    idPool
  }
}

export function recuperarDatosPoolSuccess(state, { analisis }) {    
  return {
    ...state,
    analisis
  }
}

export function fetchExtraccionesPendientesDeAsignar(state, { idRaza }) {
  return {
    ...state,
    idRaza
  }
}
export function fetchExtraccionesPendientesDeAsignarSuccess(state, { pendientesAsignarList }) {
  return {
    ...state,
    pendientesAsignarList
  }
}

export function initializeExtraccionesPendientesAsignar(state, { }) {
  return { ...state }
}

export function initializeExtraccionesPendientesAsignarSuccess(state, { pendientesAsignarList }) {
  return {
    ...state,
    pendientesAsignarList
  }
}

export function addAnalisisPool(state, { idDilucion, idPool, idRaza }) {
  return {
    ...state,
    idDilucion,
    idPool,
    idRaza
  }
}

export function addAnalisisPoolSuccess(state) {
  return {
    ...state
  }
}

export function removeAnalisisPool(state, { idAnalisis, idPool, idRaza }) {
  return {
    ...state,
    idAnalisis,
    idPool,
    idRaza
  }
}

export function removeAnalisisPoolSuccess(state) {
  return {
    ...state
  }
}

export function obtenerNumeroPoolSuccess (state, {numeroPool}) {
  return {
    ...state,
    numeroPool: numeroPool.numeroPool
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ADD_ANALISIS_POOL_SUCCESS:
      return addAnalisisPoolSuccess(state, action);
    case actionTypes.REMOVE_ANALISIS_POOL_SUCCESS:
      return removeAnalisisPoolSuccess(state, action);
    case actionTypes.INITIALIZE_ANALISIS_EXTRACCIONES_SUCCESS:
      return initializeExtraccionesPendientesAsignarSuccess(state, action);
    case actionTypes.FETCH_EXTRACCIONES_SIN_ASIGNAR_SUCCESS:
      return fetchExtraccionesPendientesDeAsignarSuccess(state, action);
    case actionTypes.CREATE_NEW_POOL_SUCCESS:
      return createNewPoolSuccess(state, action);
    case actionTypes.RECUPERAR_DATOS_POOL_SUCCESS:
      return recuperarDatosPoolSuccess(state, action);
    case actionTypes.ABRIR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS:
      return abrirModalNuevoPoolAsignacionSuccess(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    case actionTypes.OBTENER_NUMERO_POOL_SUCCESS:
      return obtenerNumeroPoolSuccess(state, action)
    default:
      return state;
  }
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRecomendacionesMatadero from '../../components/informes/ModalRecomendacionesMatadero'
import {cerrarModalRecomendacionesMatadero, obtenerRecomendacionesMatadero, crearCsvInformeProductividadExtraccionesMatadero, imprimirPdfInformesProductividadExtraccionesMatadero} from '../../actions/informes/informes'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalRecomendacionesMatadero,
      comboVerraco,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      comboTipoIntervencionSanitaria,
      obtenerRecomendacionesMatadero,
      crearCsvInformeProductividadExtraccionesMatadero,
      imprimirPdfInformesProductividadExtraccionesMatadero
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRecomendacionesMatadero))
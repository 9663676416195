import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import RegistroIntervencionSanitaria from '../../../components/planSanitario/registroIntervencionSanitaria/RegistroIntervencionSanitaria'
import {fetchRegistroIntervencionSanitaria, recuperarDatosModalRegistroIntervencionSanitaria, duplicarDatosModalRegistroIntervencionSanitaria, cambiarEstadoRegistroIntervencionSanitaria, onDeleteRegistroIntervencionSanitaria, fetchFiltrarRegistroIntervencionSanitaria } from '../../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

export function mapStateToProps (state) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    registroIntervencionSanitaria: state.registroIntervencionSanitaria,
    showModal: state.common.showModal,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRegistroIntervencionSanitaria,
      recuperarDatosModalRegistroIntervencionSanitaria,
      duplicarDatosModalRegistroIntervencionSanitaria,
      cambiarEstadoRegistroIntervencionSanitaria,
      onDeleteRegistroIntervencionSanitaria,
      fetchFiltrarRegistroIntervencionSanitaria
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistroIntervencionSanitaria))
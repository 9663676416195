import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import '../cambioEstadoVerraco/ModalCambioEstadoVerraco.scss'
import {estadoVerraco, translateEstadoVerraco} from '../../constants/estadoVerraco'
import { Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import { onSubmitFail } from '../../util/util'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import {date_formatter} from '../../util/formatFunctions'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'

class ModalVerracoCambioEstado extends Component {
  constructor (props) {
    super()
    this.state = {
      eliminado: false,
      comboEstadosPermitidos: [],
      idVerraco: null,
      fecha: '',
      cambioEstadoExistente: false
    }
  }

  componentDidMount () {
    this.props.change('fechaCambioEstado', new Date)
    this.props.actions.comboVerraco()
  }

  handleChangeEstado (value) {
    if(value !== null){
      if (value.value === 5 || value.value === 6) {
        this.props.actions.yesNoModal('cambioEstadoEliminado')
      }
      if (value.value === estadoVerraco.BAJA || value.value === estadoVerraco.MUERTO) {
        this.setState({eliminado: true})
      } else {
        this.setState({eliminado: false})
      }
    } else {
      this.setState({eliminado: false})
    }
  }

  nuevoCambioEstadoVerraco (values) {
    /*if ( Object.keys(this.props.cambioEstadoVerraco.registroModificar).length > 0) {
      this.props.actions.editarCambioEstadoVerraco(values)
    } else {
      if (this.state.eliminado === false) {
        values.causaEliminacion = null
      }
      if (this.props.idVerraco){
        values.idVerraco = this.props.idVerraco
      } else {
        values.idVerraco = values.idVerraco.value
      }
      this.props.actions.submitNuevoCambioEstadoVerraco(values)
    }*/
    //if (values.nuevoEstado !== null) {
      this.props.actions.guardarDatosCambioEstado(values)
    // }
  }

  enviarFecha (fecha) {
    this.setState({fecha: fecha})
    if ( this.state.idVerraco !== null) {
      const values = {fechaCambio: fecha, idVerraco: this.state.idVerraco, idCambioEstado: null}
      this.props.actions.enviarFechaCambioEstado(values)
    } else {
      const values = {fechaCambio: fecha, idVerraco: null, idCambioEstado: null}
      this.props.actions.enviarFechaCambioEstado(values)
    }
  }

  cerrarModalVerracoCambioEstado () {
    this.props.actions.cerrarModalVerracoCambioEstado()
    this.setState({idVerraco: null})
    this.props.change('fechaCambioEstado', new Date)
    this.setState({cambioEstadoExistente: false})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.showModalVerracoCambioEstado !== prevProps.showModalVerracoCambioEstado) {
      if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0){
        if (Object.keys(this.props.fichaVerraco.listVerraco.cambioEstado).length > 0 ) {
          this.setState({idVerraco: this.props.fichaVerraco.listVerraco.verraco.idVerraco})
          this.props.actions.enviarFechaCambioEstado({
            fechaCambio: new Date,
            idVerraco: this.props.fichaVerraco.listVerraco.verraco.idVerraco,
            idCambioEstado: null
          })
        } else {
          const values = {fechaCambio: new Date, idVerraco: this.state.idVerraco, idCambioEstado: null}
          this.props.actions.enviarFechaCambioEstado(values)
        }
      } else{
        // const values = {fechaCambio: this.props.formVerracoModalCambioEstado.values.fechaCambioEstado, idVerraco: this.state.idVerraco}
        const values = {fechaCambio: new Date, idVerraco: this.state.idVerraco, idCambioEstado: null}
        this.props.actions.enviarFechaCambioEstado(values)
      }
    }
    if (Object.keys(this.props.cambioEstadoVerraco).length > 0) {
      if (Object.keys(this.props.cambioEstadoVerraco.verracoMovidoHoy).length > 0 && Array.isArray(this.props.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos)) {
        const estadosPermitidos = this.props.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos.map((estados) => estados )
        const comboEstadosPermitidos = []
        estadosPermitidos.forEach(function (idEstados){
          comboEstadosPermitidos.push(
          {
            value: idEstados,
            label: prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstados]}`)
          }
          )
        })
        if (this.props.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos !== prevProps.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos){
          this.setState({comboEstadosPermitidos: comboEstadosPermitidos})
        }
        if (this.props.cambioEstadoVerraco.verracoMovidoHoy !== prevProps.cambioEstadoVerraco.verracoMovidoHoy) {
          this.setState({cambioEstadoExistente: this.props.cambioEstadoVerraco.verracoMovidoHoy.cambioEstadoExistente})
        }
      }
    }
    if (prevProps.cambioEstadoVerraco.verracoMovidoHoy && Object.keys(this.props.cambioEstadoVerraco.registroModificar).length === 0) {
      const idEstadoVerraco = prevProps.cambioEstadoVerraco.verracoMovidoHoy.ultimoEstado
      if (idEstadoVerraco === estadoVerraco.CUARENTENA) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.PRESENTADO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.ACTIVO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.ENFERMO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.BAJA) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.MUERTO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else {
        prevProps.change('estadoActual', null)
      }
    }
  }

  handleChangeVerraco (id) {
    if (id !== null){
      this.setState({idVerraco: id.value})
    } else {
      this.setState({idVerraco: null})
      this.props.change('fechaCambioEstado', '')
    }
  }

  render () {
    const {
      t, showModalVerracoCambioEstado, formVerracoModalCambioEstado, mostrarComboVerraco, handleSubmit,
      actions:{cerrarModalVerracoCambioEstado},
      cambioEstadoVerraco: {verracoMovidoHoy, registroModificar, registroDuplicar},
      combos: {comboVerraco, comboCausaEliminacion}
    } = this.props

    // let comboCausaEliminacion = this.props.combos.comboCausaEliminacion
    // comboCausaEliminacion = comboCausaEliminacion.map((causaEliminacion) => {
    //   return {...causaEliminacion, label: t(causaEliminacion.label)}
    // })

    return (
      <Modal show={showModalVerracoCambioEstado} onHide={() => this.cerrarModalVerracoCambioEstado()} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-cambio-estado-verraco">
        <Modal.Header closeButton>
          <Modal.Title>
            {
              Object.keys(registroModificar).length > 0 ? (
                t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_EDITAR`)
              ) : (Object.keys(registroDuplicar).length > 0 ? (
                t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_DUPLICAR`)
                ) : ( t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_NUEVO`) )
              )
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SimpleModalPage/>
          <MensajeAlertaPage/>
          <Row>
            {
              this.state.cambioEstadoExistente === true ? (
                <p className="existe-cambio-estado"><Warning/>{t('CAMBIO_ESTADO_VERRACOS.MODAL.YA_EXISTE_CAMBIO_ESTADO')}</p>
              ) : null
            }
            {
              Object.keys(this.props.cambioEstadoVerraco.registroModificar).length === 0 && Object.keys(this.props.cambioEstadoVerraco.registroDuplicar).length === 0 && mostrarComboVerraco !== false ? (
                <Field
                  colSm={3}
                  id="idVerraco"
                  name="idVerraco"
                  controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.VERRACO')}
                  component={InputSelect}
                  options={comboVerraco}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-idVerraco'
                  validate={required}
                  onInputChange={(id) => this.handleChangeVerraco(id)}
                />
              ) : null
            }
            { Object.keys(this.state.comboEstadosPermitidos).length > 0 &&
            <Field
              id="fechaCambioEstado"
              name="fechaCambioEstado"
              // component={InputDatePicker}
              component={(this.state.idVerraco === '' || this.state.idVerraco === undefined  || this.state.idVerraco === null) && mostrarComboVerraco !== false ? (InputText) : (InputDatePicker)}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.FECHA`) + '*'}
              onInputChange={(value) => this.enviarFecha(value)}
              // fechaPrincipio={verracoMovidoHoy && verracoMovidoHoy.fechaCuarentena !== null ? verracoMovidoHoy.fechaCuarentena : undefined}
              // fechaFin={verracoMovidoHoy && verracoMovidoHoy.fechaEliminado !== null ? verracoMovidoHoy.fechaEliminado : undefined}
              validate={required}
              disabled={(this.state.idVerraco === '' || this.state.idVerraco === undefined || this.state.idVerraco === null) && mostrarComboVerraco !== false ? true : false}
            />
            }
            <Field
              id="estadoActual"
              name="estadoActual"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ACTUAL`)}
              disabled={true}
            />
            { Object.keys(this.state.comboEstadosPermitidos).length > 0 &&
            <Field
              colSm={3}
              id="nuevoEstado"
              name="nuevoEstado"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.NUEVO_ESTADO')}
              component={InputSelect}
              options={this.state.comboEstadosPermitidos}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-verraco'
              onInputChange={(value) => this.handleChangeEstado(value)}
              validate={required}
            />
            }
            <Field
              colSm={5}
              id="causaEliminacion"
              name="causaEliminacion"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.CAUSA_ELIMINACION')}
              component={InputSelect}
              options={comboCausaEliminacion}
              valueKey="value"
              labelKey="label"
              customClass={this.state.eliminado === true || verracoMovidoHoy.causaEliminacion ? 'select-causa-eliminado-visible' : 'select-causa-eliminado-oculto'}
              disabled={this.state.eliminado === true ? false : true}
            />

            <Field
              colSm={12}
              id="observacionesMovimiento"
              name="observacionesMovimiento"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.OBSERVACIONES')}
              customClass='observaciones-verraco'
            />
            <Field
              id="sangriaInicial"
              name="sangriaInicial"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_INICIAL`)}
            />
            <Field
              id="sangriaFinal"
              name="sangriaFinal"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_FINAL`)}
            />
            <Field
              id="pesoInicial"
              name="pesoInicial"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.PESO_INICIAL`)}
            />
            <Field
              id="pesoFinal"
              name="pesoFinal"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.PESO_FINAL`)}
            />
            <Field
              colSm={12}
              id="incidenciasClinicas"
              name="incidenciasClinicas"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.INCIDENCIAS_CLINICAS')}
              customClass='incidencias-clinicas-verraco'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.nuevoCambioEstadoVerraco.bind(this))}>{t('CAMBIO_ESTADO_VERRACOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalVerracoCambioEstado}>{t('CAMBIO_ESTADO_VERRACOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: "ModalVerracoCambioEstado",
  onSubmitFail
})(ModalVerracoCambioEstado))

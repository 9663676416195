
export const verracoSiNo = {
  VERRACO: 1,
  POOL: 2
}

export const translateVerracoSiNo = {
  1: 'VERRACO_POOL.VERRACO',
  2: 'VERRACO_POOL.POOL'
}
export const tipoControlSeguimiento = {
  INTERNO: 1,
  EXTERNO: 2
}

export const translateTipoControlSeguimiento = {
  1: 'SEGUIMIENTO.INTERNO',
  2: 'SEGUIMIENTO.EXTERNO'
}
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { Delete, Launch, Print, FilterList, AddCircleOutline } from '@material-ui/icons'
import {date_formatter, dateTimeZones, date_parser} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosModalAnnadirControlMorfologico extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      tipo: 1,
      fitrosSeleccionados: []
    }   
    this.fetchFiltrarModalInicio = this.fetchFiltrarModalInicio.bind(this);
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
  }

  componentDidUpdate (prevProps) {
   
  }
  
  fetchFiltrarModalInicio(values){
    values = {...values, ...this.props.periodoForm.values}
    this.props.actions.fetchFiltrarModalInicio(values)    
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalControlMorfologico()
    } else {
      this.props.actions.deSeleccionarTodosModalControlMorfologico()
    }
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarModalInicio(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  anadirSeleccionControlMorfologico() {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'fecha': dateTimeZones(this.props.auth.zonaHorariaString)
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (list.length > 0) {
      this.props.actions.anadirVerracosModalInicio(list)
      this.props.change('seleccionar-todo', false)
    }else{
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('SIMPLE_MODAL.VERRACO_REQUERIDO'))
    }
  }

  render () {
    const {
       t, 
       handleSubmit, auth: { formaFechaHora }, filtros = {}, periodoForm,
      combos: { comboVerraco, comboLineaGenetica, comboRaza }
    } = this.props
    const tKey = 'PLAN_SANITARIO.CONTROL_MORFOLOGICO.'

    let comboVerracoSiNo = this.props.combos.comboVerracoSiNo
    comboVerracoSiNo = comboVerracoSiNo.map((verracoSiNoItem) => {
      return {...verracoSiNoItem, label: t(`COMUN.COMBOS.${verracoSiNoItem.label}`)}
    })

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    comboTipoControlSeguimiento = comboTipoControlSeguimiento.map((tipoControlSeguimientoItem) => {
      return {...tipoControlSeguimientoItem, label: t(`COMUN.COMBOS.${tipoControlSeguimientoItem.label}`)}
    })

    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <div className="custom-form">
            <Field
              colSm={4}
              id="verraco"
              name="verraco"
              controlLabel={t(tKey + 'FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            <Field
              colSm={4}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            <Field
              colSm={4}
              id="linea"
              name="linea"
              controlLabel={t(tKey + 'FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            {/*<Field
              colSm={2}
              id="tipoControl"
              name="tipoControl"
              controlLabel={t(tKey + 'FILTROS.TIPO_CONTROL')}
              component={InputSelect}
              options={comboTipoControlSeguimiento}
              valueKey="value"
              labelKey="label"
              noPredeterminado={false}
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />*/}
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.verraco && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>),
                    (filtros.linea && <span key="filtros.linea"  className="badge-dark">{t(tKey + 'FILTROS.LINEA_GENETICA') + ': ' + filtros.linea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('linea')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza" className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>),
                    (filtros.tipoControl && <span key="filtros.tipoControl" className="badge-dark">{t(tKey + 'FILTROS.TIPO_CONTROL') + ': ' + filtros.tipoControl.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoControl')}> x </button></span>)
                  ]
                }
              </div>
            </div>
          </div>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-add-control-mofologico"
                    name="seleccionar-todos-add-control-mofologico"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>
              ]}
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-primary nuevo" onClick={(e) => this.anadirSeleccionControlMorfologico()}>
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.ANADIR')}
                  </button>
                </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalAnnadirControlMorfologico',
  initialValues: {    
  }
})(FiltrosModalAnnadirControlMorfologico))

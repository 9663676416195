import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col} from 'react-bootstrap'
import { Unarchive } from '@material-ui/icons'
import './ModalInicioControlSemen.scss'
import Dropzone from 'react-dropzone'
import InputSelect from '../../comun/form/InputSelect'
import Button from '../../comun/button/Button'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputCheckBox from '../../comun/form/InputCheckBox'
import FiltrosModalAnnadirControlSemenPage from '../../../containers/planSanitario/controlSemen/FiltrosModalAnnadirControlSemenPage'
import SimpleTablePage from '../../../containers/comun/table/SimpleTablePage'
import {dateTimeZones, date_parser} from '../../../util/formatFunctions'


class ModalInicioControlSemen extends Component {
   constructor () {
    super()
    this.state = {
      inputSelected: false,
      archivos: {},
      formValues: {
        fecha: null,
        tipo: null,
        controlDirigido:false,
        tipoDirigido:null
      }
    }
  }

  componentDidMount () {
    this.props.initialize({
      fecha: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora),
      tipo: {value: 1, label: this.props.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO')},
      tipoDirigido:{value: 1, label: this.props.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN')}
    })
    this.setState({        
      formValues: {
        fecha: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora),
        tipo: {value: 1, label: this.props.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO')},
        tipoDirigido:{value: 1, label: this.props.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN')}
      }    
    })
    this.props.change('periodo', {value: 7, label: this.props.t('PLANING.7_DIAS')})// Set 7 days  
  }
  
  componentDidUpdate(prevProps){
    if (this.props.showModalInicio !== prevProps.showModalInicio) {
      this.props.change('periodo', {value: 7, label: this.props.t('PLANING.7_DIAS')})// Set 7 days
    }
   
    if (this.props.filtrosInicio.tipo !== prevProps.filtrosInicio.tipo) {
      if (this.props.filtrosInicio.tipo){
        if (this.props.filtrosInicio.tipo.value==1){
          this.setState({
            controlDirigido:false                  
          })
        }else{
          this.setState({
            controlDirigido:true
          })
        }
      }      
    }
  }

  closeModalInicio () {
    this.props.actions.closeModalInicio()
  }

  intercambiarCheck = (value) => {
    
    this.setState({ inputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracosInicio()
    } else {
      this.props.actions.deSeleccionarTodosVerracosInicio()
    }
  }


  fetchFiltrarModalInicio(values){
    values = {...values, ...this.props.filtros.values}
    this.props.actions.fetchFiltrarModalInicio(values)
  }

  changeValuesModalInicio(values){    
    if(this.controlDirigido==false){
      this.setState({              
        formValues: {
          fecha: values.fecha,
          tipo: values.tipo,
          tipoDirigido:null    
        }          
      })
    }
    else{
      this.setState({              
        formValues: {
          fecha: values.fecha,
          tipo: values.tipo,
          tipoDirigido:values.tipoDirigido
        }          
      })
    }   
  }



  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosInforme({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
      }
      reader.readAsDataURL(archivo)
    })
  }


  render () {
    const comboTipoDirigidoControlSemen = [
      {value: 1, label: this.props.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN')},
      {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRIGIDO.ENFERMEDAD')},
      {value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRIGIDO.CONTACTO')}      
    ]

    const comboDiasPlaning = [
      {value: 0, label: this.props.t('PLANING.0_DIAS')},
      {value: 7, label: this.props.t('PLANING.7_DIAS')},
      {value: 14, label: this.props.t('PLANING.14_DIAS')},
      {value: 21, label: this.props.t('PLANING.21_DIAS')}
    ]

    const {
      t, handleSubmit, showModalInicio, archivo, checkedFiltrosListado, listVerracos, checkedVerracosAnadidos, numeroRegistrosPorPagina, paginaActual,
      actions:{onDeleteVerracoPlaning}
    } = this.props

    let comboTipoControlSemen = this.props.combos.comboTipoControlSemen
    comboTipoControlSemen = comboTipoControlSemen.map((combo) => {
      return {...combo, label: t(combo.label)}
    })

    // TABLA VERRACO
    listVerracos.forEach(
      (row) => row.idElemento = row.idVerraco + 'extraer'
    )
    const tableInicio = {
      id: 'tableInicio',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      hasPagination: true,
      noBlockOptions: false,
      mostrarModificar: true,
      onEdit: (row) => {
        //tableInicio.initialValues['check' + row.idElemento] = true
      },
      columns: [
        {id: 'codVerraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'vigilado', name: t('FICHA_VERRACO.VIGILADO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'diasSinExtraer', name: t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.DIAS_ULTIMO_CONTROL'), type: 'numero', numDecimales: 0}
      ],
      filtros:<FiltrosModalAnnadirControlSemenPage listVerracos={this.props.listVerracos} modalValues={this.state.formValues} archivos={this.state.archivos} />,
      rows: listVerracos,
      rowsVerraco: true,
      initialValues: {}
    }

    tableInicio.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableInicio.initialValues['check' + row.idElemento] = checkedVerracosAnadidos || checkedFiltrosListado
        }
      }
    )

  return (
      <Modal show={showModalInicio} onHide={() => this.closeModalInicio()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
              <span className='control-calidad-filtro-periodo'>
                {t('PLAN_SANITARIO.CONTROL_SEMEN.MODAL_INICIO.TITLE')}
              </span>
              <Field
                colSm={2}
                id="periodo"
                name="periodo"
                // controlLabel={t('PLANING.PERIODO')}
                component={InputSelect}
                options={comboDiasPlaning}
                valueKey="value"
                labelKey="label"
                customClass='control-calidad-filtro-periodo'
                isClearable={false}
                // onInputChange={(value) => this.elegirDiasPlaning(value)}
                onInputChange={handleSubmit(this.fetchFiltrarModalInicio.bind(this))}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix"></div>
            <Row>
              <Field
                  colSm={3}
                  id="fecha"
                  name="fecha"
                  isClearable={false}
                  controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.FECHA')}
                  component={InputDatePicker}
                  onInputChange={handleSubmit(this.changeValuesModalInicio.bind(this))}
                />
                <Field
                  colSm={3}
                  id="tipo"
                  name="tipo"
                  controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.TIPO_CONTROL')}
                  component={InputSelect}
                  options={comboTipoControlSemen}
                  isClearable={false}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={handleSubmit(this.changeValuesModalInicio.bind(this))}
                />                
                <Col sm={4}>
                  <div className="archivos">
                    {t('PLAN_SANITARIO.CONTROL_SEMEN.MODAL_INICIO.FILE')}
                    <Dropzone
                      accept=".pdf"
                      onDrop={this.onDrop.bind(this)}
                      uploadMultiple={true}
                      maxFiles={null}
                      // maxSize={200000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}  className="custom-file-label">
                          <input {...getInputProps()} />
                          <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  {
                    Object.keys(archivo).length > 0 ? (
                      <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
                    ) : null
                  }
                </Col>
            </Row>
            <Row>
            { this.state.controlDirigido ?
                <Field
                  colSm={3}
                  id="tipoDirigido"
                  name="tipoDirigido"
                  controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.TIPO_DIRIGIDO')}
                  component={InputSelect}
                  defaultValue={1}
                  isClearable={false}
                  options={comboTipoDirigidoControlSemen}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={handleSubmit(this.changeValuesModalInicio.bind(this))}
                />:null}
            </Row>
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-verracos">
              <SimpleTablePage {...tableInicio}></SimpleTablePage>
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalInicio()}>{t('PLANING.MODAL_INICIO.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalInicioControlSemen',
})(ModalInicioControlSemen))

export default {
  FETCH_INSTALACION: 'FETCH_INSTALACION',
  FETCH_INSTALACION_SUCCESS: 'FETCH_INSTALACION_SUCCESS',
  SUBMIT_NUEVA_INSTALACION: 'SUBMIT_NUEVA_INSTALACION',
  RECUPERAR_DATOS_MODAL_INSTALACION: 'RECUPERAR_DATOS_MODAL_INSTALACION',
  RECUPERAR_DATOS_MODAL_INSTALACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_INSTALACION_SUCCESS',
  ABRIR_MODAL_VACIO_INSTALACION: 'ABRIR_MODAL_VACIO_INSTALACION',
  EDITAR_INSTALACION: 'EDITAR_INSTALACION',
  EDITAR_INSTALACION_SUCCESS: 'EDITAR_INSTALACION_SUCCESS',
  SUBMIT_NUEVA_INSTALACION_SUCCESS: 'SUBMIT_NUEVA_INSTALACION_SUCCESS',
  DUPLICAR_DATOS_MODAL_INSTALACION: 'DUPLICAR_DATOS_MODAL_INSTALACION',
  DUPLICAR_DATOS_MODAL_INSTALACION_SUCCESS: 'DUPLICAR_DATOS_MODAL_INSTALACION_SUCCESS',
  CAMBIAR_ESTADO_INSTALACION: 'CAMBIAR_ESTADO_INSTALACION',
  CAMBIAR_ESTADO_INSTALACION_SUCCESS: 'CAMBIAR_ESTADO_INSTALACION_SUCCESS',
  ON_DELETE_INSTALACION: 'ON_DELETE_INSTALACION',
  ON_DELETE_INSTALACION_SUCCESS: 'ON_DELETE_INSTALACION_SUCCESS',
  FETCH_FILTRAR_INSTALACION: 'FETCH_FILTRAR_INSTALACION',
  FETCH_FILTRAR_INSTALACION_SUCCESS: 'FETCH_FILTRAR_INSTALACION_SUCCESS',
  SELECCIONAR_TODOS_INSTALACION: 'SELECCIONAR_TODOS_INSTALACION',
  DESELECCIONAR_TODOS_INSTALACION: 'DESELECCIONAR_TODOS_INSTALACION',
  CAMBIAR_ESTADO_SELECCION_INSTALACION: 'CAMBIAR_ESTADO_SELECCION_INSTALACION',
  DELETE_SELECCION_INSTALACION: 'DELETE_SELECCION_INSTALACION',
  GUARDAR_Y_NUEVO_INSTALACION: 'GUARDAR_Y_NUEVO_INSTALACION',
  CREATE_CSV_INSTALACION: 'CREATE_CSV_INSTALACION',
  CREATE_CSV_INSTALACION_SUCCESS: 'CREATE_CSV_INSTALACION_SUCCESS',
  IMPRIMIR_PDF_INSTALACION: 'IMPRIMIR_PDF_INSTALACION',
  IMPRIMIR_PDF_INSTALACION_SUCCESS: 'IMPRIMIR_PDF_INSTALACION_SUCCESS'
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosDilucion from '../../components/dilucion/FiltrosDilucion'
import {openModal} from '../../actions/common'
import {fetchFiltrarDilucion, crearCsvDilucion, imprimirPdfDilucion, seleccionarTodosDilucion, deSeleccionarTodosDilucion, deleteSeleccionDilucion} from '../../actions/dilucion/dilucion'

export function mapStateToProps (state) {
  return {
    ...state.dilucion,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      fetchFiltrarDilucion,
      crearCsvDilucion,
      imprimirPdfDilucion,
      seleccionarTodosDilucion,
      deSeleccionarTodosDilucion,
      deleteSeleccionDilucion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosDilucion))
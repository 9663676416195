import comunActionTypes from '../../constants/actions/comun/comun'

export function uploadFile (file) {
  const imagen = {name: file.name, file}
  return {
    type: comunActionTypes.UPLOAD_FILE,
    imagen
  }
}

export function quitarDirectorioTemporal(){
  return {
    type: comunActionTypes.QUITAR_DIRECTORIO_TEMPORAL
  }
}

export function eliminaImagen(){
  const imagen = null
  return {
    type: comunActionTypes.ELIMINAR_IMAGEN,
    imagen
  }
}

export function subirArchivos (archivos) {
  return {
    type: comunActionTypes.SUBIR_ARCHIVOS,
    archivos
  }
}

export function obtenerArchivoContent(archivo){
  return {
    type: comunActionTypes.OBTENER_ARCHIVO_CONTENT,
    archivo
  }
}

export function eliminarArchvio(archivo){
  return {
    type: comunActionTypes.ELIMINAR_ARCHIVO,
    archivo
  }
}

export function obtenerArchivoSuccess(archivoDescarga){
  return {
    type: comunActionTypes.OBTENER_ARCHIVO_SUCCESS,
    archivoDescarga
  }
}

export function cambiarCentro (idCentro, history) {
  return {
    type: comunActionTypes.CAMBIAR_CENTRO,
    idCentro,
    history
  }
}

export function cambiarCentroSuccess () {
  return {
    type: comunActionTypes.CAMBIAR_CENTRO_SUCCESS
  }
}

export default {
  uploadFile,
  quitarDirectorioTemporal,
  eliminaImagen,
  subirArchivos,
  obtenerArchivoContent,
  obtenerArchivoSuccess,
  eliminarArchvio,
  cambiarCentro,
  cambiarCentroSuccess
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { yesNoModal } from '../../actions/common'
import { comboTipoDosis } from '../../actions/combos/combos'
import { guardarModalAsignacionAnalisisPool, cerrarModalAsignacionAnalisisPool } from '../../actions/poolExtracciones/poolExtracciones'
import {createNewPool} from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool'
import ModalAsignacionAnalisisPool from '../../components/poolExtracciones/ModalAsignacionAnalisisPool'

export function mapStateToProps (state) {
  return {
    auth: state.auth,
    ...state.poolExtracciones.pool,
    formDisponiblesAnalisisTable: getFormValues('disponiblesAnalisisTable')(state),
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboTipoDosis: state.combos.comboTipoDosis,
    },
    panelControl: state.panelControl
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      createNewPool,
      yesNoModal,
      comboTipoDosis,
      guardarModalAsignacionAnalisisPool,
      cerrarModalAsignacionAnalisisPool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAsignacionAnalisisPool))
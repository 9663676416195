import actionTypes from '../../constants/actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'

export function fetchComboObservacionesEyaculadoEliminado () {
  return {
    type: actionTypes.FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO
  }
}

export function fetchComboObservacionesEyaculadoEliminadoSuccess (list) {
  return {
    type: actionTypes.FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS,
    list
  }
}

export function submitNuevaComboObservacionesEyaculadoEliminado (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO,
    values
  }
}

export function submitNuevaComboObservacionesEyaculadoEliminadoSuccess (insercion) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS,
    insercion
  }
}

export function recuperarDatosModalComboObservacionesEyaculadoEliminado (id) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO,
    id
  }
}

export function recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS,
    data
  }
}

export function abrirModalVacioComboObservacionesEyaculadoEliminado () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO
  }
}

export function editarComboObservacionesEyaculadoEliminado (values) {
  return {
    type: actionTypes.EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO,
    values
  }
}

export function editarComboObservacionesEyaculadoEliminadoSuccess (edicionComboObservacionesEyaculadoEliminado) {
  return {
    type: actionTypes.EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS,
    edicionComboObservacionesEyaculadoEliminado
  }
}

export default {
  fetchComboObservacionesEyaculadoEliminado,
  fetchComboObservacionesEyaculadoEliminadoSuccess,
  submitNuevaComboObservacionesEyaculadoEliminado,
  submitNuevaComboObservacionesEyaculadoEliminadoSuccess,
  recuperarDatosModalComboObservacionesEyaculadoEliminado,
  recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess,
  abrirModalVacioComboObservacionesEyaculadoEliminado,
  editarComboObservacionesEyaculadoEliminado,
  editarComboObservacionesEyaculadoEliminadoSuccess
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesPool from '../../components/informes/FiltrosInformesPool'
import {obtenerInformePool, imprimirPdfInformesPool, crearCsvInformePool} from '../../actions/informes/informes'
import {fetchComboPool, comboNombreGrupoVerraco, comboTipoDosis, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboVerraco} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.informes,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformePool,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboTipoDosis,
      comboRaza,
      comboLineaGenetica,
      comboOperario,
      comboTipoIntervencionSanitaria,
      imprimirPdfInformesPool,
      comboVerraco,
      crearCsvInformePool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesPool))
export default {
  FETCH_CONTROL_MORFOLOGICO: "FETCH_CONTROL_MORFOLOGICO",
  FETCH_CONTROL_MORFOLOGICO_SUCCESS: "FETCH_CONTROL_MORFOLOGICO_SUCCESS",
  SELECCIONAR_TODOS_CONTROL_MORFOLOGICO: "SELECCIONAR_TODOS_CONTROL_MORFOLOGICO",
  DESELECCIONAR_TODOS_CONTROL_MORFOLOGICO: "DESELECCIONAR_TODOS_CONTROL_MORFOLOGICO",
  OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO: 'OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO',
  OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS: 'OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS',
  CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO: 'CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO',
  CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS: 'CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS',
  SELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO: 'SELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO',
  DESELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO: 'DESELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO',
  DELETE_CONTROL_MORFOLOGICO: 'DELETE_CONTROL_MORFOLOGICO',
  DELETE_SELECCION_CONTROL_MORFOLOGICO: 'DELETE_SELECCION_CONTROL_MORFOLOGICO',
  CREATE_CSV_CONTROL_MORFOLOGICO: 'CREATE_CSV_CONTROL_MORFOLOGICO',
  IMPRIMIR_PDF_CONTROL_MORFOLOGICO: 'IMPRIMIR_PDF_CONTROL_MORFOLOGICO',
  OPEN_MODAL_FORM_CONTROL_MORFOLOGICO: 'OPEN_MODAL_FORM_CONTROL_MORFOLOGICO',
  OPEN_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS: 'OPEN_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS',
  GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO: 'GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO',
  GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS: 'GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS',
  CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO: 'CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO',
  CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS: 'CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS',
  OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO: 'OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO',
  ABRIR_MODAL_INICIO: 'ABRIR_MODAL_INICIO',
  CLOSE_MODAL_INICIO: 'CLOSE_MODAL_INICIO',
  FETCH_FILTRAR_MODAL_INICIO: 'FETCH_FILTRAR_MODAL_INICIO',
  FETCH_FILTRAR_MODAL_INICIO_SUCCESS: 'FETCH_FILTRAR_MODAL_INICIO_SUCCESS',
  SELECCIONAR_TODOS_VERRACOS_INICIO: 'SELECCIONAR_TODOS_VERRACOS_INICIO',
  DESELECCIONAR_TODOS_VERRACOS_INICIO: 'DESELECCIONAR_TODOS_VERRACOS_INICIO',
  ANADIR_VERRACOS_INICIO: 'ANADIR_VERRACOS_INICIO',
  ANADIR_VERRACOS_INICIO_SUCCESS: 'ANADIR_VERRACOS_INICIO_SUCCESS',
  GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO: 'GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO'
}
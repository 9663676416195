import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import instalacionsActionTypes from '../../constants/actions/instalacion/instalacion'
import {fetchInstalacionSuccess, recuperarDatosModalInstalacionSuccess, duplicarDatosModalInstalacionSuccess, cambiarEstadoInstalacionSuccess, onDeleteInstalacionSuccess, imprimirPdfInstalacionSuccess,
  deSeleccionarTodosInstalacion, fetchFiltrarInstalacionSuccess, submitNuevaInstalacionSuccess, editarInstalacionSuccess, crearCsvInstalacionSuccess} from '../../actions/instalacion/instalacion'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getInstalacionList from '../../services/instalacion/getInstalacionList'
import addInstalacion from '../../services/instalacion/addInstalacion'
import getInstalacion from '../../services/instalacion/getInstalacion'
import editInstalacion from '../../services/instalacion/editInstalacion'
import getInstalacionParaDuplicar from '../../services/instalacion/getInstalacionParaDuplicar'
import getInstalacionCambioEstado from '../../services/instalacion/getInstalacionCambioEstado'
import deleteInstalacion from '../../services/instalacion/deleteInstalacion'
import getFiltrarInstalacion from '../../services/instalacion/getFiltrarInstalacion'
import getCambiarEstadoSeleccion from '../../services/instalacion/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/instalacion/getDeleteSeleccion'
import {comboInstalacionPadre, comboTipoInstalacion} from '../combos/combos'
import getCrearCsvInstalacion from '../../services/instalacion/getCrearCsvInstalacion'
import getImprimirPdfInstalacion from '../../services/instalacion/getImprimirPdfInstalacion'
import {comboNombreInstalacion, comboCodInstalacion} from '../../actions/combos/combos'

export function * fetchInstalacion () {
  try {
    const list = yield call(getInstalacionList, null)
    yield put(fetchInstalacionSuccess(list))
    yield call(comboTipoInstalacion)
    yield call(comboInstalacionPadre)
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchInstalacion () {
  yield takeLatest(instalacionsActionTypes.FETCH_INSTALACION, fetchInstalacion)
}

export function * submitNuevaInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionInstalacion = yield call(addInstalacion, values, auth.token)
    yield put(submitNuevaInstalacionSuccess(insercionInstalacion))
    yield put(closeModal())
    if (insercionInstalacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    const val = yield select(state => state.instalacion.filtros)
    const lista = yield call(getFiltrarInstalacion, val, auth.token)
    yield put(fetchInstalacionSuccess(lista))
    yield put(fetchFiltrarInstalacionSuccess(val))
    yield put(comboNombreInstalacion())
    yield put(comboCodInstalacion())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaInstalacion () {
  yield takeLatest(instalacionsActionTypes.SUBMIT_NUEVA_INSTALACION, submitNuevaInstalacion)
}

export function * recuperarDatosModalInstalacion ({idInstalacion}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getInstalacion, idInstalacion)
    yield put(recuperarDatosModalInstalacionSuccess(registroModificar))
    registroModificar.idTipoInstalacion = {value: registroModificar.idTipoInstalacion, label: registroModificar.nombreTipoInstalacion}
    if (registroModificar.nombrePadre) {
      registroModificar.idInstalacionPadre = {value: registroModificar.idInstalacionPadre, label: registroModificar.nombrePadre}
    }
    yield put(initialize('ModalInstalacion', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalInstalacion () {
  yield takeLatest(instalacionsActionTypes.RECUPERAR_DATOS_MODAL_INSTALACION, recuperarDatosModalInstalacion)
}

export function * abrirModalVacioInstalacion () {
  try {
    yield put(recuperarDatosModalInstalacionSuccess([]))
    yield put(duplicarDatosModalInstalacionSuccess([]))
    const valores = {activo: true}
    yield put(initialize('ModalInstalacion', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioInstalacion () {
  yield takeLatest(instalacionsActionTypes.ABRIR_MODAL_VACIO_INSTALACION, abrirModalVacioInstalacion)
}

export function * editarInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionInstalacion = yield call(editInstalacion, values, auth.token)
    yield put(editarInstalacionSuccess(edicionInstalacion))
    yield put(closeModal())
    if (edicionInstalacion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(comboNombreInstalacion())
    yield put(comboCodInstalacion())
    const val = yield select(state => state.instalacion.filtros)
    if (val.nombreInstalacion && values.nombreInstalacion){
      val.nombreInstalacion = {value: val.nombreInstalacion.value, label: values.nombreInstalacion}
    }
    if (val.codInstalacion && values.codInstalacion){
      val.codInstalacion = {value: val.codInstalacion.value, label: values.codInstalacion}
    }
    const lista = yield call(getFiltrarInstalacion, val, auth.token)
    yield put(fetchInstalacionSuccess(lista))
    yield put(fetchFiltrarInstalacionSuccess(val))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarInstalacion () {
  yield takeLatest(instalacionsActionTypes.EDITAR_INSTALACION, editarInstalacion)
}

export function * duplicarDatosModalInstalacion ({idInstalacion}) {
  try {
    const duplicar = yield call(getInstalacionParaDuplicar, idInstalacion)
    yield put(duplicarDatosModalInstalacionSuccess(duplicar))
    duplicar.idTipoInstalacion = {value: duplicar.idTipoInstalacion, label: duplicar.nombreTipoInstalacion}
    if (duplicar.nombrePadre) {
      duplicar.idInstalacionPadre = {value: duplicar.idInstalacionPadre, label: duplicar.nombrePadre}
    }
    yield put(initialize('ModalInstalacion', duplicar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchDuplicarDatosModalInstalacion () {
  yield takeLatest(instalacionsActionTypes.DUPLICAR_DATOS_MODAL_INSTALACION, duplicarDatosModalInstalacion)
}

export function * cambiarEstadoInstalacion ({idInstalacion}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getInstalacionCambioEstado, idInstalacion, auth.token)
      yield put(cambiarEstadoInstalacionSuccess(cambioEstado))
      if (cambioEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      const val = yield select(state => state.instalacion.filtros)
      const lista = yield call(getFiltrarInstalacion, val, auth.token)
      yield put(fetchInstalacionSuccess(lista))
      yield put(fetchFiltrarInstalacionSuccess(val))
      let valoresFiltros = yield select(state => state.instalacion.filtros)
      yield put(initialize('FiltrosInstalacion', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarEstadoInstalacion () {
  yield takeLatest(instalacionsActionTypes.CAMBIAR_ESTADO_INSTALACION, cambiarEstadoInstalacion)
}

export function * onDeleteInstalacion ({idInstalacion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(deleteInstalacion, idInstalacion, auth.token)
      yield put(onDeleteInstalacionSuccess(onDelete))
      if (onDelete.eliminado === true && onDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (onDelete.eliminado === false && onDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboNombreInstalacion())
      yield put(comboCodInstalacion())
      const val = yield select(state => state.instalacion.filtros)
      if (val.nombreInstalacion && val.nombreInstalacion.value === idInstalacion){
        val.nombreInstalacion = null
      }
      if (val.codInstalacion && val.codInstalacion.value === idInstalacion){
        val.codInstalacion = null
      }
      const lista = yield call(getFiltrarInstalacion, val, auth.token)
      yield put(fetchInstalacionSuccess(lista))
      yield put(fetchFiltrarInstalacionSuccess(val))
      let valoresFiltros = yield select(state => state.instalacion.filtros)
      yield put(initialize('FiltrosInstalacion', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteInstalacion () {
  yield takeLatest(instalacionsActionTypes.ON_DELETE_INSTALACION, onDeleteInstalacion)
}

export function * fetchFiltrarInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarInstalacion, values, auth.token)
    yield put(fetchInstalacionSuccess(list))
    yield put(fetchFiltrarInstalacionSuccess(values))
    let valoresFiltros = yield select(state => state.instalacion.filtros)
    yield put(initialize('FiltrosInstalacion', valoresFiltros))
    yield call(comboTipoInstalacion)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarInstalacion () {
  yield takeLatest(instalacionsActionTypes.FETCH_FILTRAR_INSTALACION, fetchFiltrarInstalacion)
}

export function * cambiarEstadoSeleccionInstalacion ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
      if (cambioEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosInstalacion())
      yield put(initialize('FiltrosInstalacion'))
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.instalacion.filtros)
      const lista = yield call(getFiltrarInstalacion, val, auth.token)
      yield put(fetchInstalacionSuccess(lista))
      yield put(fetchFiltrarInstalacionSuccess(val))
      let valoresFiltros = yield select(state => state.instalacion.filtros)
      yield put(initialize('FiltrosInstalacion', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarEstadoSeleccionInstalacion () {
  yield takeLatest(instalacionsActionTypes.CAMBIAR_ESTADO_SELECCION_INSTALACION, cambiarEstadoSeleccionInstalacion)
}

export function * deleteSeleccionInstalacion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDeleteSeleccion = yield call(getDeleteSeleccion, list, auth.token)
      if (onDeleteSeleccion.eliminado === true && onDeleteSeleccion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosInstalacion())
      yield put(initialize('FiltrosInstalacion'))
      yield put(initialize('simpleTable'))
      yield put(comboNombreInstalacion())
      yield put(comboCodInstalacion())
      const val = yield select(state => state.instalacion.filtros)
      list.forEach((li) => {
        if (val.nombreInstalacion && val.nombreInstalacion.value === li.idInstalacion){
          val.nombreInstalacion = null
        }
        if (val.codInstalacion && val.codInstalacion.value === li.idInstalacion){
          val.codInstalacion = null
        }
      })
      const lista = yield call(getFiltrarInstalacion, val, auth.token)
      yield put(fetchInstalacionSuccess(lista))
      yield put(fetchFiltrarInstalacionSuccess(val))
      let valoresFiltros = yield select(state => state.instalacion.filtros)
      yield put(initialize('FiltrosInstalacion', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccionInstalacion () {
  yield takeLatest(instalacionsActionTypes.DELETE_SELECCION_INSTALACION, deleteSeleccionInstalacion)
}

export function * guardarYnuevoInstalacion ({values}) {
  console.log(values)
  try {
    let auth = yield select(state => state.auth)
    const insercionInstalacion = yield call(addInstalacion, values, auth.token)
    yield put(submitNuevaInstalacionSuccess(insercionInstalacion))
    const valores = {activo: true}
    yield put(initialize('ModalInstalacion', valores))
    if (insercionInstalacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    const val = yield select(state => state.instalacion.filtros)
    const list = yield call(getFiltrarInstalacion, val, auth.token)
    yield put(fetchInstalacionSuccess(list))
    yield put(fetchFiltrarInstalacionSuccess(val))
    yield put(comboNombreInstalacion())
    yield put(comboCodInstalacion())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchGuardarYnuevoInstalacion () {
  yield takeLatest(instalacionsActionTypes.GUARDAR_Y_NUEVO_INSTALACION, guardarYnuevoInstalacion)
}

export function * crearCsvInstalacion ({list}) {
  console.log('llego')
  try {
    let auth = yield select(state => state.auth)
    const datosExcelInstalacion = yield call(getCrearCsvInstalacion, list, auth.token)
    yield put(crearCsvInstalacionSuccess(datosExcelInstalacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvInstalacion () {
  yield takeLatest(instalacionsActionTypes.CREATE_CSV_INSTALACION, crearCsvInstalacion)
}
export function * imprimirPdfInstalacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInstalacion = yield call(getImprimirPdfInstalacion, list, auth.token)
    yield put(imprimirPdfInstalacionSuccess(datosPdfInstalacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInstalacion () {
  yield takeLatest(instalacionsActionTypes.IMPRIMIR_PDF_INSTALACION, imprimirPdfInstalacion)
}

import actionTypes from '../../constants/actions/raza/raza'

export function fetchRazas () {
  return {
    type: actionTypes.FETCH_RAZAS
  }
}

export function fetchRazasSuccess (list) {
  return {
    type: actionTypes.FETCH_RAZAS_SUCCESS,
    list
  }
}

export function submitNuevaRaza (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_RAZA,
    values
  }
}

export function submitNuevaRazaSuccess (insercionRaza) {
  return {
    type: actionTypes.SUBMIT_NUEVA_RAZA_SUCCESS,
    insercionRaza
  }
}

export function recuperarDatosModalRaza (idRaza) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_RAZA,
    idRaza
  }
}

export function recuperarDatosModalRazaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_RAZA_SUCCESS,
    data
  }
}

export function abrirModalVacioRaza () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_RAZA
  }
}

export function editarRaza (values) {
  return {
    type: actionTypes.EDITAR_RAZA,
    values
  }
}

export function editRazaSuccess (edicionRaza) {
  return {
    type: actionTypes.EDIT_RAZA_SUCCESS,
    edicionRaza
  }
}

export function duplicarDatosModalRaza (idRaza) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_RAZA,
    idRaza
  }
}

export function duplicarDatosModalRazaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_RAZA_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoRaza (idRaza, cambiarEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_RAZA,
    idRaza,
    cambiarEstado
  }
}

export function cambiarEstadoRazaSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_RAZA_SUCCESS,
    dataEstado
  }
}

export function onDeleteRaza (idRaza) {
  return {
    type: actionTypes.ON_DELETE_RAZA,
    idRaza
  }
}

export function onDeleteRazaSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_RAZA_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarRaza (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_RAZA,
    values
  }
}

export function fetchFiltrarRazaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_RAZA_SUCCESS,
    filtros
  }
}

export function seleccionarTodosRaza(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_RAZA
  }
}

export function deSeleccionarTodosRaza(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_RAZA
  }
}

export function cambiarEstadoSeleccionRaza(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_RAZA,
    list
  }
}

export function deleteSeleccionRaza(list){
  return {
    type: actionTypes.DELETE_SELECCION_RAZA,
    list
  }
}

export function crearCsvRaza(list){
  return {
    type: actionTypes.CREATE_CSV_RAZA,
    list
  }
}

export function crearCsvRazaSuccess(datosExcelRaza){
  return {
    type: actionTypes.CREATE_CSV_RAZA_SUCCESS,
    datosExcelRaza
  }
}

export function guardarYnuevoRaza(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_RAZA,
    values
  }
}

export function imprimirPdfRaza(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_RAZA,
    list
  }
}

export function imprimirPdfRazaSuccess(datosPdfRaza){
  return {
    type: actionTypes.IMPRIMIR_PDF_RAZA_SUCCESS,
    datosPdfRaza
  }
}

export function openModalRaza(){
  return {
    type: actionTypes.OPEN_MODAL_RAZA
  }
}

export function closeModalRaza(){
  return {
    type: actionTypes.CLOSE_MODAL_RAZA
  }
}

export default {
  fetchRazas,
  fetchRazasSuccess,
  submitNuevaRaza,
  submitNuevaRazaSuccess,
  recuperarDatosModalRaza,
  recuperarDatosModalRazaSuccess,
  abrirModalVacioRaza,
  editarRaza,
  duplicarDatosModalRaza,
  duplicarDatosModalRazaSuccess,
  cambiarEstadoRaza,
  cambiarEstadoRazaSuccess,
  onDeleteRaza,
  onDeleteRazaSuccess,
  fetchFiltrarRaza,
  fetchFiltrarRazaSuccess,
  seleccionarTodosRaza,
  deSeleccionarTodosRaza,
  cambiarEstadoSeleccionRaza,
  deleteSeleccionRaza,
  crearCsvRaza,
  crearCsvRazaSuccess,
  guardarYnuevoRaza,
  imprimirPdfRaza,
  imprimirPdfRazaSuccess,
  openModalRaza,
  closeModalRaza
}

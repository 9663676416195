import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalCopiaSeguridad from '../../components/copiaSeguridad/ModalCopiaSeguridad'
import {comboObtenerCentroUsuario} from '../../actions/combos/combos'
import {cerrarModalCopiaSeguridad, submitNuevaCopiaSeguridad} from '../../actions/copiaSeguridad/copiaSeguridad'

export function mapStateToProps (state) {
  return {
    ...state.copiaSeguridad,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalCopiaSeguridad,
      comboObtenerCentroUsuario,
      submitNuevaCopiaSeguridad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCopiaSeguridad))
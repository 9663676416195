import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosInformesStockDosis extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeStockDosis({})
    this.props.actions.comboVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
  }

  obtenerInformeStockDosis(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.obtenerInformeStockDosis(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesStockDosis !== prevProps.datosPdfInformesStockDosis) {
      if (this.props.datosPdfInformesStockDosis.pdf && this.props.datosPdfInformesStockDosis.pdf !== null){
        let pdf = this.props.datosPdfInformesStockDosis.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.STOCK_DOSIS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesStockDosis(){
    this.props.actions.imprimirPdfInformesStockDosis(this.props.filtrosStockDosis)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosStockDosis
    values[value] = null
    this.props.actions.obtenerInformeStockDosis(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  crearCsvInformeStockDosis () {
    this.props.actions.crearCsvInformeStockDosis(this.props.filtrosStockDosis)
  }

  render () {
  const {
    t, handleSubmit, filtrosStockDosis,
    combos: {
      comboVerraco, comboTipoDosis, comboRaza, comboLineaGenetica,
    }
  } = this.props

    const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })

    const comboVerracoPool = [
      {value: 1, label: t('COMUN.COMBOS.VERRACO_POOL.VERRACO')},
      {value: 2, label: t('COMUN.COMBOS.VERRACO_POOL.POOL')}
    ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="fecha"
              name="fecha"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.FECHA')}
              component={InputDatePicker}
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboDosisFiltros}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <Field
              colSm={2}
              id="verracoPool"
              name="verracoPool"
              controlLabel={t('INFORMES.STOCK_DOSIS.FILTROS.VERRACO_POOL')}
              component={InputSelect}
              options={comboVerracoPool}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeStockDosis.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    (filtrosStockDosis.fecha && filtrosStockDosis.fecha !== null && filtrosStockDosis.fecha !== '') ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.FECHA') + ': ' + date_formatter(filtrosStockDosis.fecha, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fecha')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    filtrosStockDosis.verraco && filtrosStockDosis.verraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.VERRACO') + ': ' + filtrosStockDosis.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    filtrosStockDosis.raza && filtrosStockDosis.raza !== null ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.RAZA') + ': ' + filtrosStockDosis.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    filtrosStockDosis.lineaGenetica && filtrosStockDosis.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.LINEA_GENETICA') + ': ' + filtrosStockDosis.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    filtrosStockDosis.tipoDosis && filtrosStockDosis.tipoDosis !== null ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.TIPO_DOSIS') + ': ' + filtrosStockDosis.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosStockDosis).length > 0 && (
                    filtrosStockDosis.verracoPool && filtrosStockDosis.verracoPool !== null ? (
                      <span className="badge-dark">{t('INFORMES.STOCK_DOSIS.FILTROS.TIPO_DOSIS') + ': ' + filtrosStockDosis.verracoPool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verracoPool')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={() => this.crearCsvInformeStockDosis()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesStockDosis()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesStockDosis',
})(FiltrosInformesStockDosis))

import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosEscandalloPage from '../../containers/escandallo/FiltrosEscandalloPage'
import ModalEscandalloPage from '../../containers/escandallo/ModalEscandalloPage'
import './Escandallo.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class Escandallo extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.ALMACEN.ESCANDALLOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }
  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listEscandallo, checkedFiltrosEscandallo, data, dataDuplicar,
      actions: {recuperarDatosModalEscandallo, duplicarDatosModalEscandallo, cambiarEstadoEscandallo, onDeleteEscandallo}
    } = this.props

    listEscandallo.forEach(
      (row) => row.idElemento = row.idEscandallo
    )

    const table = {
      id: 'escandalloTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombre', name: t('ALMACEN.ESCANDALLO.TABLA.NOMBRE')},
        {id: 'nombreTipoDosis', name: t('ALMACEN.ESCANDALLO.TABLA.TIPO_DOSIS')},
        {id: 'activo', name: t('ALMACEN.ESCANDALLO.TABLA.ACTIVO')}
      ],
      rows: listEscandallo,
      filtros: <FiltrosEscandalloPage />,
      onEdit: (row) => recuperarDatosModalEscandallo(row.idEscandallo),
      data: data,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      duplicar: (row) =>duplicarDatosModalEscandallo(row.idEscandallo),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      cambiarEstado: (row) => cambiarEstadoEscandallo(row.idEscandallo),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onDelete: (row) => onDeleteEscandallo(row.idEscandallo),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosEscandallo
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalEscandalloPage
          duplicar={(row) =>duplicarDatosModalEscandallo(row.idEscandallo)}
        />
        <h2 className="page-title">{t('ALMACEN.ESCANDALLO.TITLE')}</h2>
        <SimpleTablePage {...table}>
        </SimpleTablePage>
      </div>
    )
  }
}

export default Escandallo
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ImportarDatos from '../../components/importarDatos/ImportarDatos'
import {submitImportarDatos, cerrarModalImportarDatos, subirArchivosImportarDatos} from '../../actions/importarDatos/importarDatos'

export function mapStateToProps (state) {
  return {
    ...state.importarDatos,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitImportarDatos,
      cerrarModalImportarDatos,
      subirArchivosImportarDatos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportarDatos))
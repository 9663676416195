import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'

class ModalVehiculo extends Component {
  submitNuevoVehiculo (values) {
    if (Object.keys(this.props.transporte.vehiculoRecuperar).length > 0){
      this.props.actions.editVehiculoAnadido(values)
    } else {
      return new Promise((resolve, reject) => {
        this.props.actions.submitNuevoVehiculo(values, () => {
        resolve()
      }, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
    }
  }
  guardarYNuevoVehiculo(values){
    this.props.actions.guardarYNuevoVehiculo(values)
    this.props.initialize({activo: true, predefinido: false})
  }
  render () {
    const {
      t, handleSubmit, submitting,
      transporte:{showModalVehiculo, vehiculoRecuperar, duplicar, duplicarVehiculo},
      actions: {closeModalVehiculo}
    } = this.props
    return (
      <Modal show={showModalVehiculo} onHide={closeModalVehiculo} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-modal-conductor">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.TITLE')
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={4}
                id="matricula"
                name="matricula"
                component={InputText}
                controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.MATRICULA') + '*'}
                customClass='nombre-conductor'
                validate={required}
                maxLength={true}
                valorMaxLength={20}
              />
              <Field
                colSm={4}
                id="modelo"
                name="modelo"
                component={InputText}
                controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.MODELO') + '*'}
                customClass='dni-conductor'
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                id="activo-vehiculo"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.ACTIVO')}
              />
              <Field
                id="predefinido"
                name="predefinido"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.PREDEFINIDO')}
              />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(vehiculoRecuperar).length === 0 && Object.keys(duplicarVehiculo).length === 0 && (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYNuevoVehiculo.bind(this))}>{t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button 
            type="button"
            className={"btn btn-primary" + (submitting ? ' disabled' : '')}
            onClick={!submitting ? handleSubmit(this.submitNuevoVehiculo.bind(this)) : null}>{t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalVehiculo}>{t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_VEHICULO.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVehiculo'
})(ModalVehiculo))
import React, { Component } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import './informes.scss'
import FiltrosInformesProductividadCentroPage from '../../containers/informes/FiltrosInformesProductividadCentroPage'
import {date_formatter, number_formatter} from '../../util/formatFunctions'
import {Bar, Line} from 'react-chartjs-2';

class InformesProductividadCentro extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.PRODUCTIVIDAD_CENTRO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  UNSAFE_componentWillMount () {
    // this.props.actions.obtenerInformeProductividadCentro({})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeProductividadCentro, totalesProductividadCentro,
      auth: {separadorDec, separadorMil}
    } = this.props

    // grafica
    const colors = ['#395196', '#5498af', '#4ca1a0', '#56ccde', '#4db19b', '#5be6c7'];
    const options = {
      layout: {
        padding: {
          bottom: 0,
          top: 0
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontSize: 9
          }
            }],
      },
      responsive: true,
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: '#91929b',
            padding: 20
          }
        },
        plugins: {
          labels: false
        }
      };
    let datasets = []
    let fechas = []
    if (Object.keys(listInformeProductividadCentro).length > 0){
      for (let i = 0; i < Object.keys(listInformeProductividadCentro.datosGrafica).length; i++){
        datasets = datasets.concat(
        {
          label: listInformeProductividadCentro.datosGrafica[i].tipoDosis,
          data: listInformeProductividadCentro.datosGrafica[i].numeroExtracciones ? listInformeProductividadCentro.datosGrafica[i].numeroExtracciones : listInformeProductividadCentro.datosGrafica[i].numeroDosis,
          stack: i,
          backgroundColor: colors[i],
          borderWidth: 0
        })
      }

      fechas = listInformeProductividadCentro.labels.map((f) => {
        return f
      })
    }

    let chartData = {
      labels: fechas,
      datasets: datasets
    };

    return (
      <div>
        <h2 className="page-title">{t('INFORMES.PRODUCTIVIDAD_CENTRO.TITLE')}</h2>
          <FiltrosInformesProductividadCentroPage />
        <Row>
          <Bar
            data={chartData}
            options={options}
            width={700}
            height={200}
          />
        </Row>
      </div>
    )
  }
}

export default InformesProductividadCentro
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalDatosArticuloDosis from '../../components/cliente/FiltrosModalDatosArticuloDosis'
import {abrirModalDatosArticuloDosis, seleccionarTodosModalDireccionesArticulos, deSeleccionarTodosModalDireccionesArticulos, actualizarDatosArticuloDosis} from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalDatosArticuloDosis,
      seleccionarTodosModalDireccionesArticulos,
      deSeleccionarTodosModalDireccionesArticulos,
      actualizarDatosArticuloDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalDatosArticuloDosis))
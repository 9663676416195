import actionTypes from '../../constants/actions/empresa/empresa'

export function fetchEmpresa () {
  return {
    type: actionTypes.FETCH_EMPRESA
  }
}

export function fetchEmpresaSuccess (listEmpresa) {
  return {
    type: actionTypes.FETCH_EMPRESA_SUCCESS,
    listEmpresa
  }
}

export function editarEmpresa (values) {
  return {
    type: actionTypes.EDITAR_EMPRESA,
    values
  }
}

export function seleccionarTodosEmpresa () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_EMPRESA
  }
}

export function deSeleccionarTodosEmpresa () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_EMPRESA
  }
}

export function abrirModalCentro (values) {
  return {
    type: actionTypes.ABRIR_MODAL_CENTRO,
    values
  }
}

export function cerrarModalCentro (values) {
  return {
    type: actionTypes.CERRAR_MODAL_CENTRO,
    values
  }
}

export function submitNuevoCentro (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_CENTRO,
    values
  }
}

export function recuperarDatosCentro (idEmpresa) {
  return {
    type: actionTypes.RECUPERAR_DATOS_CENTRO,
    idEmpresa
  }
}

export function recuperarDatosCentroSuccess (dataCentro) {
  return {
    type: actionTypes.RECUPERAR_DATOS_CENTRO_SUCCESS,
    dataCentro
  }
}

export function editarCentro (values) {
  return {
    type: actionTypes.EDITAR_CENTRO,
    values
  }
}

export function uploadFileEmpresa (file) {
  const imagen = {name: file.name, file}
  return {
    type: actionTypes.UPLOAD_FILE_EMPRESA,
    imagen
  }
}

export function eliminarImagenEmpresa () {
  const imagen = null
  return {
    type: actionTypes.ELIMINAR_IMAGEN_EMPRESA,
    imagen
  }
}

export function duplicarCentro (idEmpresa) {
  return {
    type: actionTypes.DUPLICAR_DATOS_CENTRO,
    idEmpresa
  }
}

export function duplicarCentroSuccess (dataCentroDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_CENTRO_SUCCESS,
    dataCentroDuplicar
  }
}

export function cambiarEstadoCentro (idCentro) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CENTRO,
    idCentro
  }
}

export function eliminarCentro (idCentro) {
  return {
    type: actionTypes.ELIMINAR_CENTRO,
    idCentro
  }
}

export function deleteSeleccionCentros (listCentro) {
  return {
    type: actionTypes.DELETE_SELECCION_CENTROS,
    listCentro
  }
}

export function cambioEstadoSeleccionCentros (listCentro) {
  return {
    type: actionTypes.CAMBIO_ESTADO_SELECCION_CENTROS,
    listCentro
  }
}

export function crearCsvEmpresa () {
  return {
    type: actionTypes.CREAR_CSV_EMPRESA
  }
}

export function crearCsvEmpresaSuccess (datosExcelEmpresa) {
  return {
    type: actionTypes.CREAR_CSV_EMPRESA_SUCCESS,
    datosExcelEmpresa
  }
}

export function imprimirPdfEmpresa () {
  return {
    type: actionTypes.IMPRIMIR_PDF_EMPRESA
  }
}

export function imprimirPdfEmpresaSuccess (datosPdfEmpresa) {
  return {
    type: actionTypes.IMPRIMIR_PDF_EMPRESA_SUCCESS,
    datosPdfEmpresa
  }
}

export default {
  fetchEmpresa,
  fetchEmpresaSuccess,
  editarEmpresa,
  seleccionarTodosEmpresa,
  deSeleccionarTodosEmpresa,
  abrirModalCentro,
  cerrarModalCentro,
  submitNuevoCentro,
  recuperarDatosCentro,
  recuperarDatosCentroSuccess,
  editarCentro,
  uploadFileEmpresa,
  eliminarImagenEmpresa,
  duplicarCentro,
  duplicarCentroSuccess,
  cambiarEstadoCentro,
  eliminarCentro,
  deleteSeleccionCentros,
  cambioEstadoSeleccionCentros,
  crearCsvEmpresa,
  imprimirPdfEmpresa,
  crearCsvEmpresaSuccess,
  imprimirPdfEmpresaSuccess
}

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/empresa/empresa'
import { fetchEmpresaSuccess, cerrarModalCentro, recuperarDatosCentroSuccess, duplicarCentroSuccess, crearCsvEmpresaSuccess, imprimirPdfEmpresaSuccess } from '../../actions/empresa/empresa'
import { comboObtenerCentroUsuario } from '../../actions/combos/combos'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import getEmpresa from '../../services/empresa/getEmpresa'
import editEmpresa from '../../services/empresa/editEmpresa'
import addCentro from '../../services/empresa/addCentro'
import getCentro from '../../services/empresa/getCentro'
import editCentro from '../../services/empresa/editCentro'
import getCambioEstadoCentro from '../../services/empresa/getCambioEstadoCentro'
import onDeleteCentro from '../../services/empresa/onDeleteCentro'
import onDeleteCentroSeleccion from '../../services/empresa/onDeleteCentroSeleccion'
import getCambioEstadoCentroSeleccion from '../../services/empresa/getCambioEstadoCentroSeleccion'
import csvEmpresa from '../../services/empresa/csvEmpresa'
import pdfEmpresa from '../../services/empresa/pdfEmpresa'
import {loginSuccess} from '../../actions/auth/auth'
import saveSessionService from '../../services/session/saveSession'
import putCambiarCentro from '../../services/comun/putCambiarCentro'

export function * fetchEmpresa () {
  try {
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
  } catch (error) {}
}
export function * watchFetchEmpresa () {
  yield takeLatest(actionTypes.FETCH_EMPRESA, fetchEmpresa)
}

export function * editarEmpresa ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionEmpresa = yield call(editEmpresa, values, auth.token)
    if (edicionEmpresa.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
  } catch (error) {}
}
export function * watchEditarEmpresa () {
  yield takeLatest(actionTypes.EDITAR_EMPRESA, editarEmpresa)
}

export function * submitNuevoCentro ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionCentro = yield call(addCentro, values, auth.token)
    if (insercionCentro.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(cerrarModalCentro())
    yield put(comboObtenerCentroUsuario())
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
    let valors = {activo: true}
    yield put(initialize('ModalCentro', valors))
  } catch (error) {}
}
export function * watchSubmitNuevoCentro () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_CENTRO, submitNuevoCentro)
}

export function * recuperarDatosCentro ({idEmpresa}) {
  try {
    // let auth = yield select(state => state.auth)
    const dataCentro = yield call(getCentro, idEmpresa, null)
    yield put(initialize('ModalCentro', dataCentro))
    yield put (recuperarDatosCentroSuccess(dataCentro))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosCentro () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_CENTRO, recuperarDatosCentro)
}

export function * editarCentro ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const modificacionCentro = yield call(editCentro, values, auth.token)
    if (modificacionCentro.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      //Si es mismo centro y distinto farmId
      if(auth.centro == values.idCentro && auth.farmId != values.farmId){
        let response = yield call(putCambiarCentro, values.idCentro, null)
        if (response.idModalidad) response.modulos = response.idModalidad.split(';')
        if(response.customizations) response.customizations = response.customizations.split(';');
        yield put(loginSuccess(response))
        yield call(saveSessionService, response)
      }
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(cerrarModalCentro())
    yield put(comboObtenerCentroUsuario())
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
    let valors = {activo: true}
    yield put(initialize('ModalCentro', valors))
  } catch (error) {}
}
export function * watchEditarCentro () {
  yield takeLatest(actionTypes.EDITAR_CENTRO, editarCentro)
}

export function * duplicarCentro ({idEmpresa}) {
  try {
    const dataDuplicarCentro = yield call(getCentro, idEmpresa, null)
    yield put(initialize('ModalCentro', dataDuplicarCentro))
    yield put (duplicarCentroSuccess(dataDuplicarCentro))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarCentro () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_CENTRO, duplicarCentro)
}

export function * cambiarEstadoCentro ({idCentro}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getCambioEstadoCentro, idCentro, null)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoCentro () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_CENTRO, cambiarEstadoCentro)
}

export function * eliminarCentro ({idCentro}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(onDeleteCentro, idCentro, null)
      if (eliminar.eliminado === true && eliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
    yield put(comboObtenerCentroUsuario())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchEliminarCentro () {
  yield takeLatest(actionTypes.ELIMINAR_CENTRO, eliminarCentro)
}

export function * deleteSeleccionCentros ({listCentro}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(onDeleteCentroSeleccion, {list: listCentro}, auth.token)
      if (eliminar.eliminado === true && eliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
    yield put(initialize('simpleTable', {}))
    yield put(comboObtenerCentroUsuario())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDeleteSeleccionCentros () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CENTROS, deleteSeleccionCentros)
}

export function * cambioEstadoSeleccionCentros ({listCentro}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getCambioEstadoCentroSeleccion, {list: listCentro}, auth.token)
      if (cambioEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    const list = yield call(getEmpresa, null)
    yield put(fetchEmpresaSuccess(list))
    yield put(initialize('formEmpresa', list.empresa))
    yield put(initialize('simpleTable', {}))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambioEstadoSeleccionCentros () {
  yield takeLatest(actionTypes.CAMBIO_ESTADO_SELECCION_CENTROS, cambioEstadoSeleccionCentros)
}

export function * crearCsvEmpresa () {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelEmpresa = yield call(csvEmpresa, auth.token)
    yield put(crearCsvEmpresaSuccess(datosExcelEmpresa))
  } catch (error) {}
}
export function * watchCrearCsvEmpresa () {
  yield takeLatest(actionTypes.CREAR_CSV_EMPRESA, crearCsvEmpresa)
}

export function * imprimirPdfEmpresa () {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfEmpresa = yield call(pdfEmpresa, auth.token)
    yield put(imprimirPdfEmpresaSuccess(datosPdfEmpresa))
  } catch (error) {}
}
export function * watchImprimirPdfEmpresa () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_EMPRESA, imprimirPdfEmpresa)
}

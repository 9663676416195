import actionTypes from '../../constants/actions/envasado/envasado'

export function initialState () {
  return {
    list: [],
    checkedFiltrosEnvasado: false,
    filtros:{},
    showModalEnvasado: false,
    checkedFiltrosModalEnvasado: false,
    data: {},
    showModalEnvasadoMultiDosis: false,
    dataMultiDosis: {},
    codigoEstadoMaquinaEnvasado: ''
  }
}

export function fetchEnvasadoSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function fetchFiltrarEnvasadoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function crearCsvEnvasadoSuccess (state, {datosExcelEnvasado}) {
  return {
    ...state,
    datosExcelEnvasado
  }
}

export function imprimirPdfEnvasadoSuccess (state, {datosPdfEnvasado}) {
  return {
    ...state,
    datosPdfEnvasado
  }
}

export function abrirDetallesEnvasadoSuccess (state, {data}) {
  return {
    ...state,
    showModalEnvasado: true,
    data
  }
}

export function cerrarDetallesEnvasadoSuccess (state, {data}) {
  return {
    ...state,
    showModalEnvasado: false,
    data: {}
  }
}

export function seleccionarTodosEnvasadoMultiDosis (state) {
  return {
    ...state,
    checkedFiltrosModalEnvasado: true
  }
}

export function deseleccionarTodosEnvasadoMultiDosis (state) {
  return {
    ...state,
    checkedFiltrosModalEnvasado: false
  }
}

export function deleteEnvasadoMultiDosisSuccess (state, {list}) {
  return {
    ...state,
    data: {
      ...state.data,
      multiDosis: list
    }
  }
}

export function abrirModalEnvasadoMultiDosisSuccess (state, {data}) {
  return {
    ...state,
    showModalEnvasadoMultiDosis: true,
    dataMultiDosis: data
  }
}

export function cerrarModalEnvasadoMultiDosisSuccess (state) {
  return {
    ...state,
    showModalEnvasadoMultiDosis: false,
    dataMultiDosis: {},
    codigoEstadoMaquinaEnvasado: ''
  }
}

export function guardarModalEnvasadoMultiDosisSuccess (state, {list}) {
  return {
    ...state,
    showModalEnvasadoMultiDosis: false,
    data: {
      ...state.data,
      multiDosis: list
    }
  }
}

export function updateTotalUtilizadoDetalleEnvasadoSuccess (state, {totalUtilizado}) {
  return {
    ...state,
    data: {
      ...state.data,
      totalUtilizado
    }
  }
}

export function actualizarEnvasadoMultiDosisSuccess (state, {list}) {
  return {
    ...state,
    data: {
      ...state.data,
      multiDosis: list
    }
  }
}

export function actualizarEstadoMaquinaEnvasado (state, {codigo}) {
  return {
    ...state,
    codigoEstadoMaquinaEnvasado: codigo
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_ENVASADO_SUCCESS:
      return fetchEnvasadoSuccess(state, action)
    case actionTypes.ABRIR_DETALLES_ENVASADO_SUCCESS:
      return abrirDetallesEnvasadoSuccess(state, action)
    case actionTypes.CERRAR_DETALLES_ENVASADO_SUCCESS:
      return cerrarDetallesEnvasadoSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_ENVASADO_SUCCESS:
      return fetchFiltrarEnvasadoSuccess(state, action)
    case actionTypes.CREATE_CSV_ENVASADO_SUCCESS:
      return crearCsvEnvasadoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_ENVASADO_SUCCESS:
      return imprimirPdfEnvasadoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS:
      return seleccionarTodosEnvasadoMultiDosis(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS:
      return deseleccionarTodosEnvasadoMultiDosis(state, action)
    case actionTypes.DELETE_ENVASADO_MULTI_DOSIS_SUCCESS:
      return deleteEnvasadoMultiDosisSuccess(state, action)
    case actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS:
      return abrirModalEnvasadoMultiDosisSuccess(state, action)
    case actionTypes.CERRAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS:
      return cerrarModalEnvasadoMultiDosisSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS:
      return guardarModalEnvasadoMultiDosisSuccess(state, action)
    case actionTypes.UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO_SUCCESS:
      return updateTotalUtilizadoDetalleEnvasadoSuccess(state, action)
    case actionTypes.ACTUALIZAR_ENVASADO_MULTI_DOSIS_SUCCESS:
      return actualizarEnvasadoMultiDosisSuccess(state, action)
    case actionTypes.ACTUALIZAR_ESTADO_MAQUINA_ENVASADO:
      return actualizarEstadoMaquinaEnvasado(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalProducto from '../../components/producto/ModalProducto'
import {comboUsuariosCentro} from '../../actions/combos/combos'
import { recuperarDatosModalProducto, closeModalProducto, submitNuevoProducto, editarProducto } from '../../actions/producto/producto'

export function mapStateToProps (state) {
  return {
    ...state.producto,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalProducto,
      closeModalProducto,
      submitNuevoProducto,
      comboUsuariosCentro,
      editarProducto
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalProducto))
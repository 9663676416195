import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalGrupoVerraco from '../../../components/planSanitario/bioseguridadHigiene/ModalGrupoVerraco'
import {closeModal} from '../../../actions/common'
import {fetchGrupoVerracoBioseguridad, cerrarModalGrupoVerraco, seleccionarTodosModalGrupoVerraco, deSeleccionarTodosModalGrupoVerraco, anadirGrupoVerracoBioseguridad
  } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchGrupoVerracoBioseguridad,
      cerrarModalGrupoVerraco,
      seleccionarTodosModalGrupoVerraco,
      deSeleccionarTodosModalGrupoVerraco,
      anadirGrupoVerracoBioseguridad
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGrupoVerraco))

import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import ModalGrupoUsuariosPage from '../../containers/grupoUsuarios/ModalGrupoUsuariosPage'
import FiltrosGrupoUsuariosPage from '../../containers/grupoUsuarios/FiltrosGrupoUsuariosPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class GrupoUsuarios extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosGrupoUsuarios()
    } else {
      this.props.actions.deSeleccionarTodosGrupoUsuarios()
    }
  }

  editarGrupoUsuarios (values) {
    this.props.actions.editarGrupoUsuarios(values)
  }

  crearCsvGrupoUsuarios () {
    this.props.actions.crearCsvGrupoUsuarios()
  }

  imprimirPdfGrupoUsuarios () {
    this.props.actions.imprimirPdfGrupoUsuarios()
  }

  componentDidMount () {
    document.title = this.props.t('MENU.SISTEMA.GRUPOS_USUARIOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchGrupoUsuarios({activo: {value: true, label: this.props.t('ESTADO.ACTIVO')}, 'seleccionar-todos-grupo-usuarios': false})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,listGrupoUsuarios, checkGrupoUsuarios,
      actions: {abrirModalGrupoUsuarios, duplicarGrupoUsuarios, eliminarGrupoUsuarios, cambiarEstadoGrupoUsuarios}
    } = this.props
    const tKey = 'GRUPO_USUARIOS.'

    const idElemento = 'idGrupoUsuario'
    const idForm = 'grupoUsuarioTable'

    const table = {
      id: 'grupoUsuariosTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1),
      idElemento,
      hasOptions: true,
      mostrarModificar: true,
      onEdit: (row) => abrirModalGrupoUsuarios(row.idGrupoUsuario),
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1),
      duplicar: (row) => duplicarGrupoUsuarios(row.idGrupoUsuario),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1),
      onDelete: (row) => eliminarGrupoUsuarios(row.idGrupoUsuario),
      mostrarCambioEstado: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1),
      activarDesactivar: true,
      cambiarEstado: (row) => cambiarEstadoGrupoUsuarios(row.idGrupoUsuario),
      columns: [
        {id: 'nombreGrupo', name: t(tKey + 'TABLA.NOMBRE')},
      ],
      rows: listGrupoUsuarios,
      filtros: <FiltrosGrupoUsuariosPage idElemento={idElemento} idForm={idForm} initialValues={{activo: {value: true, label: t('ESTADO.ACTIVO')}}} />,
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.admin){
          classRow = 'grupo-admin'
        }
        return classRow
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + idForm + row[idElemento]] = checkGrupoUsuarios
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalGrupoUsuariosPage/>
        <ListadoPage t_key={tKey} table={table} >
        </ListadoPage>
      </div>
    )
  }
}

export default (GrupoUsuarios)
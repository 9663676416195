import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { FilterList, AddCircleOutline } from '@material-ui/icons'
import {dateTimeZones} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosModalAnnadirControlSemen extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      tipo: 1,
      fitrosSeleccionados: [],
      tipoDirigido:null

    }   
    this.fetchFiltrarModalInicio = this.fetchFiltrarModalInicio.bind(this);
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
  }

  componentDidUpdate (prevProps) {
    if (this.props.modalValues && this.props.modalValues !== prevProps.modalValues) {
      //recargamos porque la fecha viene del modal
      let values = {fecha: this.props.modalValues.fecha}
      this.fetchFiltrarModalInicio(values)
    }
  }
  
  fetchFiltrarModalInicio(values){
    values = {...values, ...this.props.periodoForm.values}
    this.props.actions.fetchFiltrarModalInicio(values)    
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalControlSemen()
    } else {
      this.props.actions.deSeleccionarTodosModalControlSemen()
    }
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarModalInicio(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  anadirSeleccionControlSemen() {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'fecha': dateTimeZones(this.props.auth.zonaHorariaString)
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });

    
    if (list.length > 0) {
      this.props.actions.anadirVerracosModalInicio(list, this.props.modalValues, this.props.archivos)
      this.props.change('seleccionar-todo', false)
    }else{
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('SIMPLE_MODAL.VERRACO_REQUERIDO'))
    }
  }

  render () {
    const {
       t, 
       handleSubmit, auth: { formaFechaHora }, filtros = {}, periodoForm,
      combos: { comboVerraco, comboLineaGenetica, comboRaza }
    } = this.props
    const tKey = 'PLAN_SANITARIO.CONTROL_SEMEN.'


    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <div className="custom-form">
            <Field
              colSm={4}
              id="verraco"
              name="verraco"
              controlLabel={t(tKey + 'FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            <Field
              colSm={4}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            <Field
              colSm={4}
              id="linea"
              name="linea"
              controlLabel={t(tKey + 'FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalInicio)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.verraco && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>),
                    (filtros.linea && <span key="filtros.linea"  className="badge-dark">{t(tKey + 'FILTROS.LINEA_GENETICA') + ': ' + filtros.linea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('linea')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza" className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>)
                  ]
                }
              </div>
            </div>
          </div>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-add-control-mofologico"
                    name="seleccionar-todos-add-control-mofologico"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>
              ]}
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-primary nuevo" onClick={(e) => this.anadirSeleccionControlSemen()}>
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.ANADIR')}
                  </button>
                </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalAnnadirControlSemen',
  initialValues: {    
  }
})(FiltrosModalAnnadirControlSemen))

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import actionTypes from '../../../constants/actions/planSanitario/tipoAlimentacion/tipoAlimentacion'
import {fetchTipoAlimentacionSuccess, recuperarDatosModalTipoAlimentacionSuccess, duplicarDatosModalTipoAlimentacionSuccess, deSeleccionarTodosTipoAlimentacion, crearCsvTipoAlimentacionSuccess,
  imprimirPdfTipoAlimentacionSuccess} from '../../../actions/planSanitario/tipoAlimentacion/tipoAlimentacion'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import { yesNoModal } from '../../modal/yesNoModal'
import getTipoAlimentacionList from '../../../services/planSanitario/tipoAlimentacion/getTipoAlimentacionList'
import addTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/addTipoAlimentacion'
import getTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/getTipoAlimentacion'
import editTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/editTipoAlimentacion'
import getTipoAlimentacionParaDuplicar from '../../../services/planSanitario/tipoAlimentacion/getTipoAlimentacionParaDuplicar'
import getTipoAlimentacionCambioEstado from '../../../services/planSanitario/tipoAlimentacion/getTipoAlimentacionCambioEstado'
import deleteTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/deleteTipoAlimentacion'
import getCambiarEstadoSeleccionTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/getCambiarEstadoSeleccionTipoAlimentacion'
import getDeleteSeleccionTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/getDeleteSeleccionTipoAlimentacion'
import getCrearCsvTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/getCrearCsvTipoAlimentacion'
import getImprimirPdfTipoAlimentacion from '../../../services/planSanitario/tipoAlimentacion/getImprimirPdfTipoAlimentacion'

export function * fetchTipoAlimentacion () {
  try {
    const list = yield call(getTipoAlimentacionList, null)
    yield put(fetchTipoAlimentacionSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchTipoAlimentacion () {
  yield takeLatest(actionTypes.FETCH_TIPO_ALIMENTACION, fetchTipoAlimentacion)
}

export function * abrirModalVacioTipoAlimentacion () {
  try {
    // yield put(recuperarDatosModalTipoAlimentacionSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalTipoAlimentacion', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioTipoAlimentacion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_TIPO_ALIMENTACION, abrirModalVacioTipoAlimentacion)
}

export function * submitNuevoTipoAlimentacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoAlimentacion = yield call(addTipoAlimentacion, values, auth.token)
    yield call(fetchTipoAlimentacion)
    yield put(closeModal())
    if (insercionTipoAlimentacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchSubmitNuevoTipoAlimentacion () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_TIPO_ALIMENTACION, submitNuevoTipoAlimentacion)
}

export function * recuperarDatosModalTipoAlimentacion ({idTipoAlimentacion}) {
  try {
    yield put(duplicarDatosModalTipoAlimentacionSuccess(null))
    const registroModificar = yield call(getTipoAlimentacion, idTipoAlimentacion)
    yield put(recuperarDatosModalTipoAlimentacionSuccess(registroModificar))
    yield put(initialize('ModalTipoAlimentacion', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalTipoAlimentacion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION, recuperarDatosModalTipoAlimentacion)
}

export function * editarTipoAlimentacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionTipoAlimentacion = yield call(editTipoAlimentacion, values, auth.token)
    yield put(closeModal())
    yield call(fetchTipoAlimentacion)
    if (edicionTipoAlimentacion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarTipoAlimentacion () {
  yield takeLatest(actionTypes.EDITAR_TIPO_ALIMENTACION, editarTipoAlimentacion)
}

export function * duplicarDatosModalTipoAlimentacion ({idTipoAlimentacion}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalTipoAlimentacionSuccess(null))
    const duplicar = yield call(getTipoAlimentacionParaDuplicar, idTipoAlimentacion)
    duplicar.tipoAlimentacion = duplicar.tipoAlimentacion + ' - Copia'
    yield put(duplicarDatosModalTipoAlimentacionSuccess(duplicar))
    yield put(initialize('ModalTipoAlimentacion', duplicar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalTipoAlimentacion () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION, duplicarDatosModalTipoAlimentacion)
}

export function * cambiarEstadoTipoAlimentacion ({idTipoAlimentacion}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const dataEstado = yield call(getTipoAlimentacionCambioEstado, idTipoAlimentacion, null)
      yield call(fetchTipoAlimentacion)
      if (dataEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoTipoAlimentacion () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_TIPO_ALIMENTACION, cambiarEstadoTipoAlimentacion)
}

export function * onDeleteTipoAlimentacion ({idTipoAlimentacion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const dataDelete = yield call(deleteTipoAlimentacion , idTipoAlimentacion, null)
      yield call(fetchTipoAlimentacion)
      if (dataDelete.eliminado === true && dataDelete.asociado == false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado == true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteTipoAlimentacion () {
  yield takeLatest(actionTypes.ON_DELETE_TIPO_ALIMENTACION, onDeleteTipoAlimentacion)
}

export function * cambiarEstadoSeleccionTipoAlimentacion ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccionTipoAlimentacion, list, auth.token)
      if (dataEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosTipoAlimentacion())
      yield put(initialize('filtrosTipoAlimentacion'))
      const inicializaTabla = yield call(fetchTipoAlimentacion)
      yield put(initialize('simpleTable', inicializaTabla))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionTipoAlimentacion () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_ALIMENTACION, cambiarEstadoSeleccionTipoAlimentacion)
}

export function * deleteSeleccionTipoAlimentacion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccionTipoAlimentacion, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosTipoAlimentacion())
      yield put(initialize('filtrosTipoAlimentacion'))
      const inicializaTabla = yield call(fetchTipoAlimentacion)
      yield put(initialize('simpleTable', inicializaTabla))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionTipoAlimentacion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TIPO_ALIMENTACION, deleteSeleccionTipoAlimentacion)
}

export function * guardarYnuevoTipoAlimentacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoAlimentacion = yield call(addTipoAlimentacion, values, auth.token)
    const valores = {activo: true}
    yield put(initialize('ModalTipoAlimentacion', valores))
    yield call(fetchTipoAlimentacion)
    if (insercionTipoAlimentacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarYnuevoTipoAlimentacion () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_TIPO_ALIMENTACION, guardarYnuevoTipoAlimentacion)
}

export function * crearCsvTipoAlimentacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelTipoAlimentacion = yield call(getCrearCsvTipoAlimentacion, list, auth.token)
    yield put(crearCsvTipoAlimentacionSuccess(datosExcelTipoAlimentacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvTipoAlimentacion () {
  yield takeLatest(actionTypes.CREATE_CSV_TIPO_ALIMENTACION, crearCsvTipoAlimentacion)
}
export function * imprimirPdfTipoAlimentacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfTipoAlimentacion = yield call(getImprimirPdfTipoAlimentacion, list, auth.token)
    yield put(imprimirPdfTipoAlimentacionSuccess(datosPdfTipoAlimentacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfTipoAlimentacion () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_TIPO_ALIMENTACION, imprimirPdfTipoAlimentacion)
}

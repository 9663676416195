import React, { Component } from 'react'
import { Image as BootstrapImage } from 'react-bootstrap'
import './Img.scss'

class Img extends Component {
  render () {
    const {className} = this.props
    return (
      <BootstrapImage className={className + ' img'} {...this.props} />
    )
  }
}
export default Img

import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import './CopiaSeguridad.scss'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'

class ModalRestaurarCopiaDesdeArchivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: ''
    }
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosCopiaSeguridad({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitCopiaSeguridadDesdeArchivo () {
    this.props.actions.submitCopiaSeguridadDesdeArchivo(this.state.archivos)
  }

  cerrarModalRestaurarConArchivo () {
    this.props.actions.cerrarModalRestaurarConArchivo()
  }

  render () {
    const {
      t, handleSubmit, showModalRestaurarConArchivo, archivo
      } = this.props

    return (
      <Modal show={showModalRestaurarConArchivo} onHide={() => this.cerrarModalRestaurarConArchivo()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-copia-seguridad">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('COPIA_SEGURIDAD.RESTAURAR_COPIA_DESDE_ARCHIVO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-copia-serguridad">
              <Dropzone
                accept=".zip"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivo).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
              ) : null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={() => this.submitCopiaSeguridadDesdeArchivo()} className="btn btn-primary">{t('COPIA_SEGURIDAD.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalRestaurarConArchivo()}>{t('COPIA_SEGURIDAD.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRestaurarCopiaDesdeArchivo',
})(ModalRestaurarCopiaDesdeArchivo))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../../i18n'
import {downloadDocument} from '../../../util/util'
import {dateTimeZones, date_parser} from '../../../util/formatFunctions'
import actionTypes from '../../../constants/actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'
import {fetchRegistroIntervencionSanitariaSuccess, recuperarDatosModalRegistroIntervencionSanitariaSuccess, duplicarDatosModalRegistroIntervencionSanitariaSuccess, deSeleccionarTodosRegistroIntervencionSanitaria, 
  fetchFiltrarRegistroIntervencionSanitariaSuccess, fetchVerracosRegistroIntervencionSanitariaSuccess, cerrarModalVerracoRegistroIntervencionSanitaria, anadirVerracoModalRegistroIntervencionSanitariaSuccess, submitNuevoRegistroIntervencionSanitariaSuccess,
  fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess, fetchRegistroIntervencionSanitariaImprimirGuardarSuccess, onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess, inicializarVerraco, crearCsvRegistroIntervencionSuccess,
  imprimirPdfRegistroIntervencionSuccess, subirArchivosIntervencion, abrirModalAgendaIntervencionSanitariaSuccess, guardarModalAgendaIntervencionSanitariaSuccess, cerrarModalAgendaIntervencionSanitariaSuccess, obtenerArchivoIntervencionSuccess, closeRegistroIntervencion} from '../../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'
import {abrirModalAgendaSuccess, fetchAgenda, fetchTareasDia} from '../../../actions/agenda/agenda'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import getRegistroIntervencionSanitariaList from '../../../services/planSanitario/registroIntervencionSanitaria/getRegistroIntervencionSanitariaList'
import addRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/addRegistroIntervencionSanitaria'
import getRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/getRegistroIntervencionSanitaria'
import editRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/editRegistroIntervencionSanitaria'
import getRegistroIntervencionSanitariaParaDuplicar from '../../../services/planSanitario/registroIntervencionSanitaria/getRegistroIntervencionSanitariaParaDuplicar'
import deleteRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/deleteRegistroIntervencionSanitaria'
import getDeleteSeleccionRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/getDeleteSeleccionRegistroIntervencionSanitaria'
import getFiltrarRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/getFiltrarRegistroIntervencionSanitaria'
import getVerracoRegistroIntervencionSanitariaList from '../../../services/planSanitario/registroIntervencionSanitaria/getVerracoRegistroIntervencionSanitariaList'
import getAnadirVerracosRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/getAnadirVerracosRegistroIntervencionSanitaria'
import getFiltrarRegistroIntervencionSanitariaSinAsignar from '../../../services/planSanitario/registroIntervencionSanitaria/getFiltrarRegistroIntervencionSanitariaSinAsignar'
import getCrearCsvRegistroIntervencion from '../../../services/planSanitario/registroIntervencionSanitaria/getCrearCsvRegistroIntervencion'
import getImprimirPdfRegistroIntervencion from '../../../services/planSanitario/registroIntervencionSanitaria/getImprimirPdfRegistroIntervencion'
import obtenerCodigoRegistroIntervencion from '../../../services/planSanitario/registroIntervencionSanitaria/obtenerCodigoRegistroIntervencion'
import addRegistroIntervencionAgenda from '../../../services/planSanitario/registroIntervencionSanitaria/addRegistroIntervencionAgenda'
import imprimirDetalleRegistroIntervencion from '../../../services/planSanitario/registroIntervencionSanitaria/imprimirDetalleRegistroIntervencion'
import obtenerArchivoIntervencion from '../../../services/planSanitario/registroIntervencionSanitaria/obtenerArchivoIntervencion'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'

import { obtenerNotificaconesUsuarioSuccess } from '../../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../../services/notificaciones/getNotificaciones'

export function * fetchRegistroIntervencionSanitaria () {
  try {
    const list = yield call(getRegistroIntervencionSanitariaList, null)
    yield put(fetchRegistroIntervencionSanitariaSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA, fetchRegistroIntervencionSanitaria)
}

export function * abrirModalVacioRegistroIntervencionSanitaria () {
  try {
    let auth = yield select(state => state.auth)
    const codigoRegistroIntervencion = yield call(obtenerCodigoRegistroIntervencion, null)
    const valores = {activo: true, numeroRegistroIntervencionSanitaria: codigoRegistroIntervencion.numeroRegistroIntervencionSanitaria, fechaInicio: dateTimeZones(auth.zonaHorariaString)}
    yield put(initialize('ModalRegistroIntervencionSanitaria', valores))
    yield put(inicializarVerraco())
    // yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_REGISTRO_INTERVENCION_SANITARIA, abrirModalVacioRegistroIntervencionSanitaria)
}

export function * submitNuevoRegistroIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionRegistroIntervencion = yield call(addRegistroIntervencionSanitaria, values, auth.token)
    yield put(submitNuevoRegistroIntervencionSanitariaSuccess(insercionRegistroIntervencion.idIntervencion))
    let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
    const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
    yield put(fetchRegistroIntervencionSanitariaSuccess(lista))
    yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
    yield put(closeRegistroIntervencion())
    if (insercionRegistroIntervencion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    // Preguntar y abrir en caso afirmativo el modal para crear un evento asociado
    if (insercionRegistroIntervencion.insertado === true) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.fechaInicio, auth.formaFechaHora)
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.tieneFrecuencia = '1'
        if (values.intervalo === '0') {
          values.frecuenciaDiaria = '1'
        } else {
          values.frecuenciaDiaria = values.intervalo
        }
        values.fechaComienzo = date_parser(values.fechaInicio, auth.formaFechaHora)
        let fechaGuionesComienzo = values.fechaComienzo.split('-')
        if (fechaGuionesComienzo[0] === "" && fechaGuionesComienzo[1] === ""){
          values.fechaComienzo = fechaGuionesComienzo[2] + '-' + fechaGuionesComienzo[3] + '-' + fechaGuionesComienzo[4]
        }
        values.intervaloRepeticion = '3'
        values.fechaFinalizacion = date_parser(values.fechaFin, auth.formaFechaHora)
        values.idIntervencion = insercionRegistroIntervencion.idIntervencion
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchSubmitNuevoRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA, submitNuevoRegistroIntervencionSanitaria)
}

export function * recuperarDatosModalRegistroIntervencionSanitaria ({idIntervencion}) {
  try {
    let auth = yield select(state => state.auth)
    // yield put(duplicarDatosModalRegistroIntervencionSanitariaSuccess(null))
    const data = yield call(getRegistroIntervencionSanitaria, idIntervencion)
    yield put(recuperarDatosModalRegistroIntervencionSanitariaSuccess(data))
    let combos = yield select(state => state.combos)
    const comboTipoIntervencion = combos.comboTipoIntervencionSanitaria.filter((combo) => {
      if (combo.value === data.intervencion.idTipoIntervencion){
        return combo
      }
    })
    data.intervencion.idTipoIntervencion = comboTipoIntervencion[0]

    const comboOperario = combos.comboOperario.filter((combo) => {
      if (combo.value === data.intervencion.idOperario){
        return combo
      }
    })
    data.intervencion.idOperario = comboOperario[0]
    data.intervencion.fechaInicio = data.intervencion.fechaInicio
    data.intervencion.fechaFin = data.intervencion.fechaFin
    const diaHoy = new Date()
    if (diaHoy >= new Date(data.intervencion.fechaFin)) {
      data.intervencion.terminado = 'SI'
    } else {
      data.intervencion.terminado = 'NO'
    }

    yield put(subirArchivosIntervencion(data.archivos))

    yield put(initialize('ModalRegistroIntervencionSanitaria', data.intervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA, recuperarDatosModalRegistroIntervencionSanitaria)
}

export function * editarRegistroIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionRegistroIntervencion = yield call(editRegistroIntervencionSanitaria, values, auth.token)
    yield put(closeRegistroIntervencion())
    let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
    const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
    yield put(fetchRegistroIntervencionSanitariaSuccess(lista))
    yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
    if (edicionRegistroIntervencion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.EDITAR_REGISTRO_INTERVENCION_SANITARIA, editarRegistroIntervencionSanitaria)
}

export function * duplicarDatosModalRegistroIntervencionSanitaria ({idIntervencion}) {
  try {
    let auth = yield select(state => state.auth)
    const dataDuplicar = yield call(getRegistroIntervencionSanitariaParaDuplicar, idIntervencion)
    yield put(duplicarDatosModalRegistroIntervencionSanitariaSuccess(dataDuplicar))
    let combos = yield select(state => state.combos)
    const comboTipoIntervencion = combos.comboTipoIntervencionSanitaria.filter((combo) => {
      if (combo.value === dataDuplicar.intervencion.idTipoIntervencion){
        return combo
      }
    })
    dataDuplicar.intervencion.idTipoIntervencion = comboTipoIntervencion[0]
    const comboOperario = combos.comboOperario.filter((combo) => {
      if (combo.value === dataDuplicar.intervencion.idOperario){
        return combo
      }
    })
    dataDuplicar.intervencion.idOperario = comboOperario[0]
    const diaHoy = new Date()
    if (diaHoy >= new Date(dataDuplicar.intervencion.fechaFinal)) {
      dataDuplicar.intervencion.terminado = 'SI'
    } else {
      dataDuplicar.intervencion.terminado = 'NO'
    }
    dataDuplicar.intervencion.fechaInicio = dataDuplicar.intervencion.fechaInicio
    dataDuplicar.intervencion.fechaFin = dataDuplicar.intervencion.fechaInicio

    yield put(subirArchivosIntervencion(dataDuplicar.archivos))

    yield put(initialize('ModalRegistroIntervencionSanitaria', dataDuplicar.intervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA, duplicarDatosModalRegistroIntervencionSanitaria)
}

export function * onDeleteRegistroIntervencionSanitaria ({idIntervencion}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const dataDelete = yield call(deleteRegistroIntervencionSanitaria , idIntervencion, null)
      let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
      const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
      yield put(fetchRegistroIntervencionSanitariaSuccess(lista))
      yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true){
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(fetchAgenda())
      yield put(fetchTareasDia())
      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.ON_DELETE_REGISTRO_INTERVENCION_SANITARIA, onDeleteRegistroIntervencionSanitaria)
}

export function * deleteSeleccionRegistroIntervencionSanitaria ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccionRegistroIntervencionSanitaria, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosRegistroIntervencionSanitaria())
      yield put(initialize('filtrosRegistroIntervencionSanitaria'))
      let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
      const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
      yield put(fetchRegistroIntervencionSanitariaSuccess(lista))
      yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
      yield put(fetchAgenda())
      yield put(fetchTareasDia())
      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.DELETE_SELECCION_REGISTRO_INTERVENCION_SANITARIA, deleteSeleccionRegistroIntervencionSanitaria)
}

export function * guardarYnuevoRegistroIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let verracosAnadidos = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
    values.verraco = verracosAnadidos
    const insercionRegistroIntervencion = yield call(addRegistroIntervencionSanitaria, values, auth.token)

    // Reset formulario
    const codigoRegistroIntervencion = yield call(obtenerCodigoRegistroIntervencion, null)
    const valores = {activo: true, numeroRegistroIntervencionSanitaria: codigoRegistroIntervencion.numeroRegistroIntervencionSanitaria, fechaInicio: new Date()}
    yield put(initialize('ModalRegistroIntervencionSanitaria', valores))
    let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
    const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
    yield put(fetchRegistroIntervencionSanitariaSuccess(lista))
    yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
    if (insercionRegistroIntervencion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    // Preguntar y abrir en caso afirmativo el modal para crear un evento asociado
    if (insercionRegistroIntervencion.insertado === true) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.fechaInicio, auth.formaFechaHora)
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.tieneFrecuencia = '1'
        if (values.intervalo === '0') {
          values.frecuenciaDiaria = '1'
        } else {
          values.frecuenciaDiaria = values.intervalo
        }
        values.fechaComienzo = date_parser(values.fechaInicio, auth.formaFechaHora)
        let fechaGuionesComienzo = values.fechaComienzo.split('-')
        if (fechaGuionesComienzo[0] === "" && fechaGuionesComienzo[1] === ""){
          values.fechaComienzo = fechaGuionesComienzo[2] + '-' + fechaGuionesComienzo[3] + '-' + fechaGuionesComienzo[4]
        }
        values.intervaloRepeticion = '3'
        values.fechaFinalizacion = date_parser(values.fechaFin, auth.formaFechaHora)
        values.idIntervencion = insercionRegistroIntervencion.idIntervencion
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarYnuevoRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_REGISTRO_INTERVENCION_SANITARIA, guardarYnuevoRegistroIntervencionSanitaria)
}

export function * fetchFiltrarRegistroIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaRegistroIntervencionSanitariaDesde = values.fechaEntrada
    values.fechaRegistroIntervencionSanitariaHasta = values.fechaSalida
    const list = yield call(getFiltrarRegistroIntervencionSanitaria, values, auth.token)
    yield put(fetchRegistroIntervencionSanitariaSuccess(list))
    yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA, fetchFiltrarRegistroIntervencionSanitaria)
}

export function * fetchVerracosRegistroIntervencionSanitaria ({verracosAnadidos}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerracosEnRegistroIntervencionSanitaria = yield call(getVerracoRegistroIntervencionSanitariaList, verracosAnadidos, auth.token)
    yield put(fetchVerracosRegistroIntervencionSanitariaSuccess(listVerracosEnRegistroIntervencionSanitaria))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchVerracosRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA, fetchVerracosRegistroIntervencionSanitaria)
}

export function * anadirVerracoModalRegistroIntervencionSanitaria ({listadoAnadir}) {
  try {
    let auth = yield select(state => state.auth)
    const verracosAnadidos = yield call(getAnadirVerracosRegistroIntervencionSanitaria, listadoAnadir, auth.token)
    yield put(anadirVerracoModalRegistroIntervencionSanitariaSuccess(verracosAnadidos))
    yield put(cerrarModalVerracoRegistroIntervencionSanitaria())
    /*const verracosNoMostrar = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
    const listVerracosEnRegistroIntervencionSanitaria = yield call(getVerracoRegistroIntervencionSanitariaList, verracosNoMostrar, auth.token)
    yield put(fetchVerracosRegistroIntervencionSanitariaSuccess(listVerracosEnRegistroIntervencionSanitaria))*/
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirVerracoModalRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA, anadirVerracoModalRegistroIntervencionSanitaria)
}

export function * fetchFiltrarVerracoModalRegistroIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarRegistroIntervencionSanitariaSinAsignar, values, auth.token)
    yield put(fetchVerracosRegistroIntervencionSanitariaSuccess(list))
    yield put(fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess(values))
    // yield put(initialize('ModalVerracoRegistroIntervencionSanitaria'))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarVerracoModalRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA, fetchFiltrarVerracoModalRegistroIntervencionSanitaria)
}

export function * onDeleteVerracoAnadidoRegistroIntervencionSanitaria ({idVerraco}) {
  try {
    const verracosNoMostrar = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
    const verracosParaMostrar = verracosNoMostrar.filter((verraco) => {
      if (verraco.idVerraco !== idVerraco){
        return verraco
      }
    })
    let auth = yield select(state => state.auth)
    if (Object.keys(verracosParaMostrar).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracosRegistroIntervencionSanitaria, verracosParaMostrar, auth.token)
      yield put(onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
      yield put(onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess(verracosAnadidos))
    }

    /*const listaMostrar = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoRegistroIntervencionSanitariaList, listaMostrar, auth.token)
    yield put(fetchVerracosRegistroIntervencionSanitariaSuccess(listVerracosEnGrupo))*/

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoAnadidoRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA, onDeleteVerracoAnadidoRegistroIntervencionSanitaria)
}

export function * deleteSeleccionVerracosRegistroIntervencionSanitaria ({listVerracosQuitarRegistroIntervencionSanitaria}) {
  try {
    let auth = yield select(state => state.auth)
    if (Object.keys(listVerracosQuitarRegistroIntervencionSanitaria).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracosRegistroIntervencionSanitaria, listVerracosQuitarRegistroIntervencionSanitaria, auth.token)
      yield put(onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
      yield put(onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess(verracosAnadidos))
    }

    /*const listaMostrar = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoRegistroIntervencionSanitariaList, listaMostrar, auth.token)
    yield put(fetchVerracosRegistroIntervencionSanitariaSuccess(listVerracosEnGrupo))*/

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionVerracosRegistroIntervencionSanitaria () {
  yield takeLatest(actionTypes.DELETE_SELECCION_VERRACOS_REGISTRO_INTERVENCION_SANITARIA, deleteSeleccionVerracosRegistroIntervencionSanitaria)
}

export function * crearCsvRegistroIntervencion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelRegistroIntervencion = yield call(getCrearCsvRegistroIntervencion, list, auth.token)
    yield put(crearCsvRegistroIntervencionSuccess(datosExcelRegistroIntervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvRegistroIntervencion () {
  yield takeLatest(actionTypes.CREATE_CSV_REGISTRO_INTERVENCION, crearCsvRegistroIntervencion)
}
export function * imprimirPdfRegistroIntervencion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfRegistroIntervencion = yield call(getImprimirPdfRegistroIntervencion, list, auth.token)
    yield put(imprimirPdfRegistroIntervencionSuccess(datosPdfRegistroIntervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfRegistroIntervencion () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_REGISTRO_INTERVENCION, imprimirPdfRegistroIntervencion)
}

export function * abrirModalAgendaIntervencionSanitaria () {
  try {
    let auth = yield select(state => state.auth)
    yield put(initialize('ModalAgendaIntervencionSanitaria', {accion: i18n.t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL_AGENDA.FORM.INTERVENCION_SANITARIA')}))
    yield put(abrirModalAgendaIntervencionSanitariaSuccess())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalAgendaIntervencionSanitaria () {
  yield takeLatest(actionTypes.ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA, abrirModalAgendaIntervencionSanitaria)
}

export function * guardarModalAgendaIntervencionSanitaria ({values}) {
  try {
    console.log(values)
    let auth = yield select(state => state.auth)
    const insercionIntervencionAgenda = yield call(addRegistroIntervencionAgenda, values, auth.token)
    if (insercionIntervencionAgenda.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(guardarModalAgendaIntervencionSanitariaSuccess())
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarModalAgendaIntervencionSanitaria () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA, guardarModalAgendaIntervencionSanitaria)
}

export function * cerrarModalAgendaIntervencionSanitaria () {
  try {
    yield put(cerrarModalAgendaIntervencionSanitariaSuccess())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCerrarModalAgendaIntervencionSanitaria () {
  yield takeLatest(actionTypes.CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA, cerrarModalAgendaIntervencionSanitaria)
}

export function * imprimirInformeRegistroIntervencion ({values, idIntervencion}) {
  try {
    yield put(openSimpleModal('imprimirGuardar'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirGuardar'})
    if (confirmed) {
      // guardar modal antes de imprimirlo
      let auth = yield select(state => state.auth)
      let verracosAnadidos = yield select(state => state.registroIntervencionSanitaria.verracosAnadidos)
      values.verraco = verracosAnadidos
      const insercionRegistroIntervencion = yield call(editRegistroIntervencionSanitaria, values, auth.token)

      // Reset formulario
      yield put(initialize('ModalRegistroIntervencionSanitaria', values))
      let filtros = yield select(state => state.registroIntervencionSanitaria.filtros)
      const lista = yield call(getFiltrarRegistroIntervencionSanitaria, filtros, auth.token)
      yield put(fetchFiltrarRegistroIntervencionSanitariaSuccess(filtros))
      yield put(fetchRegistroIntervencionSanitariaImprimirGuardarSuccess(lista))

      // imprimir
      const datosPdfRegistroIntervencion = yield call(imprimirDetalleRegistroIntervencion, idIntervencion, null)
      let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfRegistroIntervencion.pdf)
      let name = i18n.t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.REGISTRO_INTERVENCION_SANITARIA') + ".pdf"
      downloadDocument(href, name)
    } else {
      yield put(initialize('ModalRegistroIntervencionSanitaria', values))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirInformeRegistroIntervencion () {
  yield takeLatest(actionTypes.IMPRIMIR_INFORME_REGISTRO_INTERVENCION, imprimirInformeRegistroIntervencion)
}

export function * obtenerArchivoContentIntervencion ({archivo}) {
  try {
    let auth = yield select(state => state.auth)
    const archivoDescarga = yield call(obtenerArchivoIntervencion, {archivo}, auth.token)
    yield put(obtenerArchivoIntervencionSuccess(archivoDescarga))
    let href = 'data:application/pdf;base64,' + encodeURIComponent(archivoDescarga.archivo)
    let name = archivoDescarga.nombreArchivo
    downloadDocument(href, name)
  } catch (error) {

  } finally {
    
  }
}
export function * watchObtenerArchivoContentIntervencion () {
  yield takeLatest(actionTypes.OBTENER_ARCHIVO_CONTENT_INTERVENCION, obtenerArchivoContentIntervencion)
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import NuevoTransportista from '../../components/transporte/NuevoTransportista'
import {abrirModalConductor, editConductorAnadido, recuperarModalConductor, cambiarEstadoConductor, onDeleteConductorAnadido, duplicarConductor, conductorPorDefecto, abrirModalVehiculo,
  editVehiculoAnadido, recuperarModalVehiculo, cambiarEstadoVehiculo, onDeleteVehiculoAnadido, duplicarVehiculo, vehiculoPorDefecto, abrirModalRuta, onDeleteRutaAnadida, rutaPorDefecto,
  submitNuevoTransportista, guardarYnuevoTransporte, reiniciarConductoresVehiculosRutas, seleccionarTodosConductores, deSeleccionarTodosConductores, seleccionarTodosVehiculos,
  deSeleccionarTodosVehiculos, seleccionarTodosRutas, deSeleccionarTodosRutas, deleteSeleccionConductor, deleteSeleccionVehiculo, deleteSeleccionRutaTransportista} from '../../actions/transporte/transporte'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.transporte,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable,
    formModalTransportista: state.form.NuevoTransportista,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      abrirModalConductor,
      editConductorAnadido,
      recuperarModalConductor,
      cambiarEstadoConductor,
      onDeleteConductorAnadido,
      duplicarConductor,
      conductorPorDefecto,
      abrirModalVehiculo,
      editVehiculoAnadido,
      recuperarModalVehiculo,
      cambiarEstadoVehiculo,
      onDeleteVehiculoAnadido,
      duplicarVehiculo,
      vehiculoPorDefecto,
      abrirModalRuta,
      onDeleteRutaAnadida,
      rutaPorDefecto,
      submitNuevoTransportista,
      guardarYnuevoTransporte,
      reiniciarConductoresVehiculosRutas,
      seleccionarTodosConductores,
      deSeleccionarTodosConductores,
      seleccionarTodosVehiculos,
      deSeleccionarTodosVehiculos,
      seleccionarTodosRutas,
      deSeleccionarTodosRutas,
      deleteSeleccionConductor,
      deleteSeleccionVehiculo,
      deleteSeleccionRutaTransportista
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NuevoTransportista))
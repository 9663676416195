import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'

class FiltrosInformesTrazabilidad extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeTrazabilidad({})
    this.props.actions.comboNombreProductos()
    this.props.actions.comboLotes()
  }

  handleChangeProducto (values) {
    this.props.change('lote', null)
    this.props.actions.comboLotes(values.producto)
    this.props.actions.obtenerInformeTrazabilidad({...values, lote: null})
  }

  obtenerInformeTrazabilidad(values){
    values.fechaDesde = values.fechaEntrada
    values.fechaHasta = values.fechaSalida
    this.props.actions.obtenerInformeTrazabilidad(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesTrazabilidad !== prevProps.datosPdfInformesTrazabilidad) {
      if (this.props.datosPdfInformesTrazabilidad.pdf && this.props.datosPdfInformesTrazabilidad.pdf !== null){
        let pdf = this.props.datosPdfInformesTrazabilidad.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.TRAZABILIDAD') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesTrazabilidad(){
    this.props.actions.imprimirPdfInformesTrazabilidad(this.props.filtrosTrazabilidad)
  }

  crearCsvInformeTrazabilidad() {
  this.props.actions.crearCsvInformeTrazabilidad(this.props.filtrosTrazabilidad)
  }

  quitarFiltros (value) {

    let values = this.props.filtrosTrazabilidad
    if (value === 'fechaHasta' || value === 'fechaSalida') {
      values.fechaSalida = null
      values.fechaHasta = null
    }
    if (value === 'fechaDesde' || value === 'fechaEntrada') {
      values.fechaEntrada = null
      values.fechaDesde = null
    }
    values[value] = null
    this.props.actions.obtenerInformeTrazabilidad(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosTrazabilidad,
    combos: {comboNombreProductos, comboLotes}
  } = this.props

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="producto"
              name="producto"
              controlLabel={t('INFORMES.TRAZABILIDAD.FILTROS.PRODUCTO')}
              component={InputSelect}
              options={comboNombreProductos}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.handleChangeProducto.bind(this))}
            />
            <Field
              colSm={2}
              id="lote"
              name="lote"
              controlLabel={t('INFORMES.TRAZABILIDAD.FILTROS.LOTE')}
              component={InputSelect}
              options={comboLotes}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeTrazabilidad.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.TRAZABILIDAD.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeTrazabilidad.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosTrazabilidad).length > 0 && (
                    (filtrosTrazabilidad.producto && filtrosTrazabilidad.producto !== null && filtrosTrazabilidad.producto !== '') ? (
                      <span className="badge-dark">{t('INFORMES.TRAZABILIDAD.FILTROS.PRODUCTO') + ': ' + filtrosTrazabilidad.producto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('producto')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosTrazabilidad).length > 0 && (
                    filtrosTrazabilidad.lote && filtrosTrazabilidad.lote !== null ? (
                      <span className="badge-dark">{t('INFORMES.TRAZABILIDAD.FILTROS.LOTE') + ': ' + filtrosTrazabilidad.lote.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lote')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosTrazabilidad).length > 0 && (
                    (filtrosTrazabilidad.fechaEntrada && filtrosTrazabilidad.fechaSalida && filtrosTrazabilidad.fechaEntrada !== null && filtrosTrazabilidad.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosTrazabilidad.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosTrazabilidad.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosTrazabilidad.fechaEntrada && filtrosTrazabilidad.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosTrazabilidad.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosTrazabilidad.fechaSalida && filtrosTrazabilidad.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosTrazabilidad.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeTrazabilidad()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesTrazabilidad()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesTrazabilidad',
})(FiltrosInformesTrazabilidad))

import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosEntradaMercancia extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  filtrarEntradaMercancia(values){
    this.props.actions.filtrarEntradaMercancia(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosEntradaMercancia()
    }
    if (this.props.datosExcelEntradaMercancia !== prevProps.datosExcelEntradaMercancia) {
      if (this.props.datosExcelEntradaMercancia.excel && this.props.datosExcelEntradaMercancia.excel !== null){
        let pdf = this.props.datosExcelEntradaMercancia.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.ENTRADAS_MERCANCIAS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfEntradaMercancia !== prevProps.datosPdfEntradaMercancia) {
      if (this.props.datosPdfEntradaMercancia.pdf && this.props.datosPdfEntradaMercancia.pdf !== null){
        let pdf = this.props.datosPdfEntradaMercancia.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.ENTRADAS_MERCANCIAS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosEntradaMercancia()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.filtrarEntradaMercancia({})
    this.props.actions.comboProveedoresEntradaMercancia()
    this.props.actions.comboReferenciaProductos()
    this.props.actions.comboAlbaranesEntradaMercancia()
    this.props.actions.comboOrigenesEntradaMercancia()
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosEntradaMercancia()
    } else {
      this.props.actions.deSeleccionarTodosEntradaMercancia()
    }
  }

  deleteSeleccionEntradaMercancia(){
    const itemsSeleccion = this.props.listEntradaMercancia.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idEntradaAlmacen': row.idEntradaAlmacen,
          'proveedor': row.proveedor,
          'numeroAlbaran': row.numeroAlbaran,
          'origen': row.origen
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionEntradaMercancia(list)
  }

  crearCsvEntradaMercancia(){
    this.props.actions.crearCsvEntradaMercancia(this.props.filtros)
  }

  imprimirPdfEntradaMercancia () {
    this.props.actions.imprimirPdfEntradaMercancia(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.filtrarEntradaMercancia(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {abrirModalEntradaMercancia},
    combos: {comboProveedoresEntradaMercancia, comboReferenciaProductos, comboAlbaranesEntradaMercancia, comboOrigenesEntradaMercancia}
  } = this.props
    const tKey = 'ALMACEN.ENTRADAS_MERCANCIA.'

  const comboTrazabilidad = [
    {value: true, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.NO')}
  ]

  const comboValidacion = [
    {value: true, label: this.props.t('COMUN.COMBOS.VALIDACION.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.VALIDACION.NO')}
  ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="referencia"
              name="referencia"
              controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.FILTROS.REFERENCIA')}
              component={InputSelect}
              options={comboReferenciaProductos}
              valueKey="value"
              labelKey="label"
              customClass='select-entrada-mercancia'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarEntradaMercancia.bind(this))}
            />
            <Field
              colSm={2}
              id="proveedor"
              name="proveedor"
              controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.FILTROS.PROVEEDOR')}
              component={InputSelect}
              options={comboProveedoresEntradaMercancia}
              valueKey="value"
              labelKey="label"
              customClass='select-entrada-mercancia'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarEntradaMercancia.bind(this))}
            />
            <Field
              colSm={2}
              id="nAlbaran"
              name="nAlbaran"
              controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.FILTROS.N_ALBARAN')}
              component={InputSelect}
              options={comboAlbaranesEntradaMercancia}
              valueKey="value"
              labelKey="label"
              customClass='select-entrada-mercancia'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarEntradaMercancia.bind(this))}
            />
            <Field
              colSm={2}
              id="origen"
              name="origen"
              controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.FILTROS.ORIGEN')}
              component={InputSelect}
              options={comboOrigenesEntradaMercancia}
              valueKey="value"
              labelKey="label"
              customClass='select-entrada-mercancia'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarEntradaMercancia.bind(this))}
            />
            <Field
              colSm={2}
              id="fecha"
              name="fecha"
              controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.FILTROS.FECHA')}
              component={InputDatePicker}
              customClass='select-entrada-mercancia'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarEntradaMercancia.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.referencia && filtros.referencia !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.REFERENCIA') + ': ' + filtros.referencia.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('referencia')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.proveedor && filtros.proveedor !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.PROVEEDOR') + ': ' + filtros.proveedor.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('proveedor')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nAlbaran && filtros.nAlbaran !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.N_ALBARAN') + ': ' + filtros.nAlbaran.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nAlbaran')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.origen && filtros.origen !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ORIGEN') + ': ' + filtros.origen.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('origen')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fecha && filtros.fecha !== null && filtros.fecha !== '') ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.FECHA') + ': ' + date_formatter(filtros.fecha, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fecha')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionEntradaMercancia()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvEntradaMercancia()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfEntradaMercancia()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) ? abrirModalEntradaMercancia : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosEntradaMercancia',
})(FiltrosEntradaMercancia))

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {comboDireccionCliente, comboTransportistasCliente, comboRutaTransportistaCliente, comboVehiculosTransportistaDeterminado,
  comboConductoresTransportistaDeterminado, comboOperarioPredeterminado} from '../../actions/combos/combos'
import {abrirDetallesAlbaran, editarAlbaran, cerrarDetallesAlbaran, deleteModalAlbaranDosis, abrirDetallesAlbaranDosis, editarAlbaranEImprimirDatosAlbaran,
  imprimirDatosAlbaran, imprimirDatosAlbaranCliente} from '../../actions/albaran/albaran'
import ModalAlbaranCliente from '../../components/informes/ModalAlbaranCliente'
import {abrirDetallesPedido} from '../../actions/pedidosVenta/pedidosVenta'

export function mapStateToProps (state) {
  return {
    ...state.albaran,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: {
      comboCliente: state.combos.comboCliente,
      comboDireccionCliente: state.combos.comboDireccionCliente,
      comboTransportistasCliente: state.combos.comboTransportistasCliente,
      comboRutaTransportistaCliente: state.combos.comboRutaTransportistaCliente,
      comboVehiculosTransportistaDeterminado: state.combos.comboVehiculosTransportistaDeterminado,
      comboConductoresTransportistaDeterminado: state.combos.comboConductoresTransportistaDeterminado,
      comboTipoDosis: state.combos.comboTipoDosis,
      comboOperarioPredeterminado: state.combos.comboOperarioPredeterminado
    },
    formModalAlbaran: getFormValues('ModalAlbaran')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirDetallesAlbaran,
      editarAlbaran,
      cerrarDetallesAlbaran,
      deleteModalAlbaranDosis,
      abrirDetallesAlbaranDosis,
      editarAlbaranEImprimirDatosAlbaran,
      imprimirDatosAlbaran,
      imprimirDatosAlbaranCliente,
      comboDireccionCliente,
      /*comboRuta,
      comboEmpresaTransportista,
      comboConductoresTransportista,
      comboVehiculosTransportista,
      comboTipoDosis,*/
      abrirDetallesPedido,
      comboTransportistasCliente,
      comboRutaTransportistaCliente,
      comboVehiculosTransportistaDeterminado,
      comboConductoresTransportistaDeterminado,
      comboOperarioPredeterminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAlbaranCliente))
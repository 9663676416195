import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalUbicacion from '../../../components/planSanitario/bioseguridadHigiene/ModalUbicacion'
import {closeModal} from '../../../actions/common'
import {fetchUbicacion, cerrarModalUbicacion, seleccionarTodosModalUbicacion, deSeleccionarTodosModalUbicacion, anadirUbicacion
  } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {comboInstalacionesPadre} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUbicacion,
      cerrarModalUbicacion,
      seleccionarTodosModalUbicacion,
      deSeleccionarTodosModalUbicacion,
      anadirUbicacion,
      comboInstalacionesPadre
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUbicacion))

import actionTypes from '../../constants/actions/fichaVerraco/fichaVerraco'

export function fetchFichaVerraco(){
  return {
    type: actionTypes.FETCH_FICHA_VERRACO
  }
}

export function fetchFichaVerracoSuccess(list){
  return {
    type: actionTypes.FETCH_FICHA_VERRACO_SUCCESS,
    list
  }
}

export function submitNuevoVerraco (values, history) {
  return {
    type: actionTypes.SUBMIT_NUEVO_VERRACO,
    values,
    history
  }
}

export function asignarCodRaza(codRaza){
  return {
    type: actionTypes.ASIGNAR_COD_RAZA,
    codRaza
  }
}

export function asignarCodRazaSuccess(data){
  return {
    type: actionTypes.ASIGNAR_COD_RAZA_SUCCESS,
    data
  }
}

export function abrirModalVacioEvolucion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_EVOLUCION
  }
}

export function abrirModalVacioEvolucionSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_EVOLUCION_SUCCESS
  }
}

export function restartVerraco () {
  return {
    type: actionTypes.RESTART_VERRACO
  }
}

export function restartVerracoSuccess(listEvolucion){
  return {
    type: actionTypes.RESTART_VERRACO_SUCCESS,
    listEvolucion
  }
}

export function submitNuevaEvolucion(evolucionesAnadidas){
  return{
    type: actionTypes.SUBMIT_NUEVA_EVOLUCION,
    evolucionesAnadidas
  }
}

export function cargarEvolucion(evolucionesAnadidas){
  return{
    type: actionTypes.CARGAR_EVOLUCION,
    evolucionesAnadidas
  }
}

export function cargarIndex(indexAnadidos){
  return{
    type: actionTypes.CARGAR_INDEX,
    indexAnadidos
  }
}

export function cargarAlimentacion(alimentacionAnadidas){
  return{
    type: actionTypes.CARGAR_ALIMENTACION,
    alimentacionAnadidas
  }
}

export function abrirModalEvolucion () {
  return {
    type: actionTypes.ABRIR_MODAL_EVOLUCION
  }
}

export function closeModalEvolucion () {
  return {
    type: actionTypes.CLOSE_MODAL_EVOLUCION
  }
}

export function submitNuevaEvolucionSuccess(nuevaEvolucion){
  return{
    type: actionTypes.SUBMIT_NUEVA_EVOLUCION_SUCCESS,
    nuevaEvolucion
  }
}

export function recuperarDatosModalEvolucion(idEvolucion){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_EVOLUCION,
    idEvolucion
  }
}

export function recuperarDatosModalEvolucionSuccess(){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_EVOLUCION_SUCCESS
  }
}

export function editarEvolucion(values){
  return{
    type: actionTypes.EDITAR_EVOLUCION,
    values
  }
}

export function editarEvolucionSuccess(evolucionesAnadidas){
  return{
    type: actionTypes.EDITAR_EVOLUCION_SUCCESS,
    evolucionesAnadidas
  }
}

export function deleteEvolucion(idEvolucion){
  return{
    type: actionTypes.DELETE_EVOLUCION,
    idEvolucion
  }
}

export function deleteEvolucionSuccess(evolucionesAnadidas){
  return{
    type: actionTypes.DELETE_EVOLUCION_SUCCESS,
    evolucionesAnadidas
  }
}

export function duplicarDatosModalEvolucion(idEvolucion){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_EVOLUCION,
    idEvolucion
  }
}

export function duplicarDatosModalEvolucionSuccess(){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_EVOLUCION_SUCCESS
  }
}

export function seleccionarTodosEvolucion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_EVOLUCION
  }
}

export function deSeleccionarTodosEvolucion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_EVOLUCION
  }
}

export function deleteSeleccionEvolucion(lista){
  return {
    type: actionTypes.DELETE_SELECCION_EVOLUCION,
    lista
  }
}

export function guardarYnuevoEvolucion(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_EVOLUCION,
    values
  }
}

export function abrirModalVacioAlimentacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALIMENTACION
  }
}

export function abrirModalVacioAlimentacionSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALIMENTACION_SUCCESS
  }
}

export function getAlimentacion () {
  return {
    type: actionTypes.GET_ALIMENTACION
  }
}

export function getAlimentacionSuccess(listAlimentacion){
  return {
    type: actionTypes.GET_ALIMENTACION_SUCCESS,
    listAlimentacion
  }
}

export function submitNuevaAlimentacion(values){
  return{
    type: actionTypes.SUBMIT_NUEVA_ALIMENTACION,
    values
  }
}

export function submitNuevaAlimentacionSucces(alimentacionAnadidas){
  return{
    type: actionTypes.SUBMIT_NUEVA_ALIMENTACION_SUCCESS,
    alimentacionAnadidas
  }
}

export function recuperarDatosModalAlimentacion(idElemento){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_ALIMENTACION,
    idElemento
  }
}

export function recuperarDatosModalAlimentacionSuccess(dataAlimentacion){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_ALIMENTACION_SUCCESS,
    dataAlimentacion
  }
}

export function editarAlimentacion(values){
  return{
    type: actionTypes.EDITAR_ALIMENTACION,
    values
  }
}

export function editarAlimentacionSuccess(alimentacionAnadidas){
  return{
    type: actionTypes.EDITAR_ALIMENTACION_SUCCESS,
    alimentacionAnadidas
  }
}

export function deleteAlimentacion(idElemento){
  return{
    type: actionTypes.DELETE_ALIMENTACION,
    idElemento
  }
}

export function deleteAlimentacionSuccess(alimentacionAnadidas){
  return{
    type: actionTypes.DELETE_ALIMENTACION_SUCCESS,
    alimentacionAnadidas
  }
}

export function duplicarDatosModalAlimentacion(idElemento){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_ALIMENTACION,
    idElemento
  }
}

export function duplicarDatosModalAlimentacionSuccess(dataAlimentacionDuplicar){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_ALIMENTACION_SUCCESS,
    dataAlimentacionDuplicar
  }
}

export function seleccionarTodosAlimentacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_ALIMENTACION
  }
}

export function deSeleccionarTodosAlimentacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ALIMENTACION
  }
}

export function deleteSeleccionAlimentacion(lista){
  return {
    type: actionTypes.DELETE_SELECCION_ALIMENTACION,
    lista
  }
}

export function guardarYnuevoAlimentacion(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ALIMENTACION,
    values
  }
}

export function openModalAlimentacion (){
  return {
    type: actionTypes.OPEN_MODAL_ALIMENTACION
  }
}

export function closeModalAlimentacion (){
  return {
    type: actionTypes.CLOSE_MODAL_ALIMENTACION
  }
}

export function abrirModalVacioIndex () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_INDEX
  }
}

export function abrirModalVacioIndexSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_INDEX_SUCCESS
  }
}

export function getIndex () {
  return {
    type: actionTypes.GET_INDEX
  }
}

export function getIndexSuccess(listIndex){
  return {
    type: actionTypes.GET_INDEX_SUCCESS,
    listIndex
  }
}

export function submitNuevaIndex(indexAnadidos){
  return{
    type: actionTypes.SUBMIT_NUEVA_INDEX,
    indexAnadidos
  }
}

export function recuperarDatosModalIndex(idIndex){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_INDEX,
    idIndex
  }
}

export function abrirModalIndex(){
  return{
    type: actionTypes.ABRIR_MODAL_INDEX
  }
}

export function recuperarDatosModalIndexSuccess(){
  return{
    type: actionTypes.RECUPERAR_DATOS_MODAL_INDEX_SUCCESS
  }
}

export function editarIndex(values){
  return{
    type: actionTypes.EDITAR_INDEX,
    values
  }
}

export function editarIndexSuccess(indexAnadidos){
  return{
    type: actionTypes.EDITAR_INDEX_SUCCESS,
    indexAnadidos
  }
}

export function deleteIndex(idIndex){
  return{
    type: actionTypes.DELETE_INDEX,
    idIndex
  }
}

export function deleteIndexSuccess(indexAnadidos){
  return{
    type: actionTypes.DELETE_INDEX_SUCCESS,
    indexAnadidos
  }
}

export function duplicarDatosModalIndex(idIndex){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_INDEX,
    idIndex
  }
}

export function duplicarDatosModalIndexSuccess(){
  return{
    type: actionTypes.DUPLICAR_DATOS_MODAL_INDEX_SUCCESS
  }
}

export function seleccionarTodosIndex(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_INDEX
  }
}

export function deSeleccionarTodosIndex(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_INDEX
  }
}

export function deleteSeleccionIndex(lista){
  return {
    type: actionTypes.DELETE_SELECCION_INDEX,
    lista
  }
}

export function guardarYnuevoIndex(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_INDEX,
    values
  }
}

export function openModalIndex (){
  return {
    type: actionTypes.OPEN_MODAL_INDEX
  }
}

export function closeModalIndex (){
  return {
    type: actionTypes.CLOSE_MODAL_INDEX
  }
}

export function abrirModalUbicacion(){
  return{
    type: actionTypes.ABRIR_MODAL_UBICACION
  }
}

export function cerrarModalUbicacion(){
  return{
    type: actionTypes.CERRAR_MODAL_UBICACION
  }
}

export function cambiarVigilado(idVerraco){
  return{
    type: actionTypes.CAMBIAR_VIGILADO,
    idVerraco
  }
}

export function onDeleteVerraco(idVerraco){
  console.log(idVerraco)
  return{
    type: actionTypes.ON_DELETE_VERRACO,
    idVerraco
  }
}

export function seleccionarTodosFichaVerraco(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_FICHA_VERRACO
  }
}

export function deSeleccionarTodosFichaVerraco(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_FICHA_VERRACO
  }
}

export function cambiarVigiladoSeleccionFichaVerraco(list){
  return {
    type: actionTypes.CAMBIAR_VIGILADO_SELECCION_FICHA_VERRACO,
    list
  }
}

export function deleteSeleccionFichaVerraco(list){
  return {
    type: actionTypes.DELETE_SELECCION_FICHA_VERRACO,
    list
  }
}

export function abrirModalCambioEstadoVerraco(idVerraco){
  return{
    type: actionTypes.ABRIR_MODAL_CAMBIO_ESTADO_VERRACO,
    idVerraco
  }
}

export function cerrarModalCambioEstadoVerraco(){
  return{
    type: actionTypes.CERRAR_MODAL_CAMBIO_ESTADO_VERRACO
  }
}

export function guardarYnuevoVerraco(values, duplicar){
  return{
    type: actionTypes.GUARDAR_Y_NUEVO_VERRACO,
    values,
    duplicar
  }
}

export function fetchFiltrarFichaVerraco(values){
  return{
    type: actionTypes.FETCH_FILTRAR_FICHA_VERRACO,
    values
  }
}

export function fetchFiltrarFichaVerracoSuccess(filtros){
  return{
    type: actionTypes.FETCH_FILTRAR_FICHA_VERRACO_SUCCESS,
    filtros
  }
}

export function abrirModalVerracoCambioEstado(){
  return{
    type: actionTypes.ABRIR_MODAL_VERRACO_CAMBIO_ESTADO
  }
}

export function cerrarModalVerracoCambioEstado(){
  return{
    type: actionTypes.CERRAR_MODAL_VERRACO_CAMBIO_ESTADO
  }
}

export function guardarDatosCambioEstado (values) {
  return {
    type: actionTypes.GUARDAR_DATOS_CAMBIO_ESTADO,
    values
  }
}

export function obtenerPresenciaVerraco (values) {
  return {
    type: actionTypes.OBTENER_PRESENCIA_VERRACO,
    values
  }
}

export function obtenerPresenciaVerracoSuccess (totalesPresencia) {
  return {
    type: actionTypes.OBTENER_PRESENCIA_VERRACO_SUCCESS,
    totalesPresencia
  }
}

export function guardarDatosUbicacion(values){
  return{
    type: actionTypes.GUARDAR_DATOS_UBICACION,
    values
  }
}

export function fetchDuplicarVerraco(idVerraco){
  return{
    type: actionTypes.FETCH_DUPLICAR_VERRACO,
    idVerraco
  }
}

export function fetchDuplicarVerracoSuccess(listVerraco){
  return {
    type: actionTypes.FETCH_DUPLICAR_VERRACO_SUCCESS,
    listVerraco
  }
}

export function crearCsvFichaVerraco(list){
  return {
    type: actionTypes.CREATE_CSV_FICHA_VERRACO,
    list
  }
}

export function crearCsvFichaVerracoSuccess(datosExcelFichaVerraco){
  return {
    type: actionTypes.CREATE_CSV_FICHA_VERRACO_SUCCESS,
    datosExcelFichaVerraco
  }
}
export function imprimirPdfFichaVerraco(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_FICHA_VERRACO,
    list
  }
}

export function imprimirPdfFichaVerracoSuccess(datosPdfFichaVerraco){
  return {
    type: actionTypes.IMPRIMIR_PDF_FICHA_VERRACO_SUCCESS,
    datosPdfFichaVerraco
  }
}

export function guardarYnuevoVerracoSuccess(){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VERRACO_SUCCESS
  }
}

export function imprimirDetalleVerraco(values, idVerraco){
  return {
    type: actionTypes.IMPRIMIR_DETALLE_VERRACO,
    values,
    idVerraco
  }
}

export function imprimirDetalleVerracoSuccess(datosImprimirDetalleVerraco){
  return {
    type: actionTypes.IMPRIMIR_DETALLE_VERRACO_SUCCESS,
    datosImprimirDetalleVerraco
  }
}

export function resetUbicacion(){
  return {
    type: actionTypes.RESET_UBICACION
  }
}

export function restartVerracoDejandoFiltros(){
  return {
    type: actionTypes.RESTART_VERRACO_DEJANDO_FILTROS
  }
}

export default {
  fetchFichaVerraco,
  fetchFichaVerracoSuccess,
  submitNuevoVerraco,
  asignarCodRaza,
  asignarCodRazaSuccess,
  abrirModalVacioEvolucion,
  restartVerraco,
  restartVerracoSuccess,
  submitNuevaEvolucion,
  submitNuevaEvolucionSuccess,
  recuperarDatosModalEvolucion,
  recuperarDatosModalEvolucionSuccess,
  editarEvolucion,
  editarEvolucionSuccess,
  cargarEvolucion,
  deleteEvolucion,
  cargarAlimentacion,
  deleteEvolucionSuccess,
  duplicarDatosModalEvolucion,
  duplicarDatosModalEvolucionSuccess,
  seleccionarTodosEvolucion,
  deSeleccionarTodosEvolucion,
  deleteSeleccionEvolucion,
  guardarYnuevoEvolucion,
  abrirModalVacioAlimentacion,
  abrirModalVacioAlimentacionSuccess,
  getAlimentacion,
  getAlimentacionSuccess,
  submitNuevaAlimentacion,
  submitNuevaAlimentacionSucces,
  recuperarDatosModalAlimentacion,
  recuperarDatosModalAlimentacionSuccess,
  editarAlimentacion,
  editarAlimentacionSuccess,
  deleteAlimentacion,
  deleteAlimentacionSuccess,
  duplicarDatosModalAlimentacion,
  duplicarDatosModalAlimentacionSuccess,
  seleccionarTodosAlimentacion,
  deSeleccionarTodosAlimentacion,
  deleteSeleccionAlimentacion,
  guardarYnuevoAlimentacion,
  openModalAlimentacion,
  closeModalAlimentacion,
  abrirModalVacioIndex,
  abrirModalVacioIndexSuccess,
  getIndex,
  getIndexSuccess,
  submitNuevaIndex,
  recuperarDatosModalIndex,
  recuperarDatosModalIndexSuccess,
  editarIndex,
  editarIndexSuccess,
  deleteIndex,
  deleteIndexSuccess,
  duplicarDatosModalIndex,
  duplicarDatosModalIndexSuccess,
  seleccionarTodosIndex,
  deSeleccionarTodosIndex,
  deleteSeleccionIndex,
  guardarYnuevoIndex,
  openModalIndex,
  closeModalIndex,
  abrirModalUbicacion,
  cerrarModalUbicacion,
  cambiarVigilado,
  onDeleteVerraco,
  seleccionarTodosFichaVerraco,
  deSeleccionarTodosFichaVerraco,
  cambiarVigiladoSeleccionFichaVerraco,
  deleteSeleccionFichaVerraco,
  guardarYnuevoVerraco,
  fetchFiltrarFichaVerraco,
  fetchFiltrarFichaVerracoSuccess,
  abrirModalVerracoCambioEstado,
  cerrarModalVerracoCambioEstado,
  guardarDatosCambioEstado,
  obtenerPresenciaVerraco,
  obtenerPresenciaVerracoSuccess,
  guardarDatosUbicacion,
  fetchDuplicarVerraco,
  fetchDuplicarVerracoSuccess,
  abrirModalEvolucion,
  closeModalEvolucion,
  abrirModalIndex,
  cargarIndex,
  crearCsvFichaVerraco,
  crearCsvFichaVerracoSuccess,
  imprimirPdfFichaVerraco,
  imprimirPdfFichaVerracoSuccess,
  guardarYnuevoVerracoSuccess,
  imprimirDetalleVerraco,
  imprimirDetalleVerracoSuccess,
  resetUbicacion,
  restartVerracoDejandoFiltros
}

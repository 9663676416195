import actionTypes from '../../constants/actions/importarVerracos/importarVerracos'

export function submitImportarVerracos (value) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_VERRACOS,
    value
  }
}

export function submitImportarVerracosSuccess (listImportarVerracos) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_VERRACOS_SUCCESS,
    listImportarVerracos
  }
}

export function abrirModalImportarVerracos () {
  return {
    type: actionTypes.ABRIR_MODAL_IMPORTAR_VERRACOS
  }
}

export function cerrarModalImportarVerracos () {
  return {
    type: actionTypes.CERRAR_MODAL_IMPORTAR_VERRACOS
  }
}

export function subirArchivosImportarVerracos (archivo) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_IMPORTAR_VERRACOS,
    archivo
  }
}

export function descargarPlantillaExcelVerracos () {
  return {
    type: actionTypes.DESCARGAR_PLANTILLA_EXCEL_VERRACOS
  }
}

export function descargarVerracos () {
  return {
    type: actionTypes.DESCARGAR_VERRACOS
  }
}

export default {
  submitImportarVerracos,
  abrirModalImportarVerracos,
  cerrarModalImportarVerracos,
  subirArchivosImportarVerracos,
  submitImportarVerracosSuccess,
  descargarPlantillaExcelVerracos,
  descargarVerracos
}
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalBioseguridadHigiene.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'

class ModalVerraco extends Component {
  anadirVerraco () {
    const itemsSeleccion = this.props.listVerraco.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'codVerraco': row.codVerraco,
          'estadoverraco': row.estadoverraco,
          'nombreRaza': row.nombreRaza,
          'nombreLinea': row.nombreLinea,
          'ubicacionverraco': row.ubicacionverraco,
          'index': row.index
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.anadirVerracoBioseguridad(list)
    this.props.change('seleccionar-todos-verraco', false)
  }

  intercambiarCheckModalVerraco (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalVerraco()
    } else {
      this.props.actions.deSeleccionarTodosModalVerraco()
    }
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.fetchVerraco(this.props.listVerracoAnadidos)
  }

  filtrarVerracos (values) {
    values.list = this.props.listVerracoAnadidos
    this.props.actions.fetchVerraco(values)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosVerracos
    values[value] = null
    values.list = this.props.listVerracoAnadidos
    this.props.actions.fetchVerraco(values)
    this.props.change(value, null)
  }

  render () {
    const {
      t, handleSubmit, listVerraco, showModalVerraco, checkedVerraco, numeroRegistrosPorPagina, paginaActual, filtrosVerracos,
      actions: { cerrarModalVerraco },
      combos:{comboVerraco, comboRaza, comboLineaGenetica}
    } = this.props

    listVerraco.forEach(
      (row) => row.idElemento = row.idVerraco
    )

    const tableVerraco = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'codVerraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.COD_VERRACO')},
        {id: 'nombreRaza', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'ubicacionverraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.UBICACION')},
        {id: 'index', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.INDEX')}
      ],
      rows: listVerraco,
      initialValues: {},
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableVerraco.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerraco.initialValues['check' + row.idElemento] = checkedVerraco
        }
      }
    )

    return (
      <Modal show={showModalVerraco} onHide={cerrarModalVerraco} aria-labelledby="contained-modal-title-lg" size="lg" className="submodal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.VERRACOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tabla-filtros open-filters" style={{display: 'inline-block'}}>
            <Field
              colSm={4}
              id="verraco"
              name="verraco"
              controlLabel={t('FICHA_VERRACO.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-verraco'
              onInputChange={handleSubmit(this.filtrarVerracos.bind(this))}
            />
            <Field
              colSm={4}
              id="raza"
              name="raza"
              controlLabel={t('FICHA_VERRACO.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-raza'
              onInputChange={handleSubmit(this.filtrarVerracos.bind(this))}
            />
            <Field
              colSm={4}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('FICHA_VERRACO.FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-lin-genetica'
              onInputChange={handleSubmit(this.filtrarVerracos.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosVerracos).length > 0 && (
                    (filtrosVerracos.verraco && filtrosVerracos.verraco !== null && filtrosVerracos.verraco !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.VERRACO') + ': ' + filtrosVerracos.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosVerracos).length > 0 && (
                    (filtrosVerracos.raza && filtrosVerracos.raza !== null && filtrosVerracos.raza !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.RAZA') + ': ' + filtrosVerracos.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosVerracos).length > 0 && (
                    (filtrosVerracos.lineaGenetica && filtrosVerracos.lineaGenetica !== null && filtrosVerracos.lineaGenetica !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.LIN_GENETICA') + ': ' + filtrosVerracos.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </div>
          <Row>
            <div className="tabla-submodal" style={{paddingTop: 0}}>
              <div className="filtros-tabla-modal-submodal">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-verraco"
                    name="seleccionar-todos-verraco"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalVerraco(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerraco}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={() => this.anadirVerraco()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalVerraco}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVerraco',
})(ModalVerraco))
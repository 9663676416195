import actionTypes from '../../constants/actions/notificaciones/notificaciones'

export function initialState () {
  return {
    listNotificacionesAgenda: [],
    listNotificacionesNotificaciones: [],
    numeroNotificaciones: ''
  }
}

export function obtenerNotificaconesUsuarioSuccess (state, {listNotificaciones}) {
  return {
    ...state,
    listNotificacionesAgenda: listNotificaciones.agenda,
    listNotificacionesNotificaciones: listNotificaciones.notificaciones,
    numeroNotificaciones: listNotificaciones.numeroNotificaciones
  }
}
export function fetchPICTraitsSuccess(state,{listTraits}){      
  return{ 
    ...state,
    listTraits
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.OBTENER_NOTIFICACIONES_USUARIO_SUCCESS:
      return obtenerNotificaconesUsuarioSuccess(state, action)
    default:
      return state
  }
}

import actionTypes from '../../constants/actions/notificaciones/notificaciones'

export function obtenerNotificaconesUsuario(){
  return {
    type: actionTypes.OBTENER_NOTIFICACIONES_USUARIO
  }
}

export function obtenerNotificaconesUsuarioSuccess(listNotificaciones){
  return {
    type: actionTypes.OBTENER_NOTIFICACIONES_USUARIO_SUCCESS,
    listNotificaciones
  }
}


export function marcarComoLeidoNotificacion(idAgenda, valLeido){
  return {
    type: actionTypes.MARCAR_COMO_LEIDO_NOTIFICACION,
    valLeido,
    idAgenda
  }
}

export function marcarComoLeidoNotificacionStock(idProducto, valLeido){
  return {
    type: actionTypes.MARCAR_COMO_LEIDO_NOTIFICACION_STOCK,
    valLeido,
    idProducto
  }
}

export default {
  obtenerNotificaconesUsuario,
  obtenerNotificaconesUsuarioSuccess,
  marcarComoLeidoNotificacion,
  marcarComoLeidoNotificacionStock
}

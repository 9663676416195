import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { yesNoModal } from '../../actions/common'
import {comboPoolsExtraccionAlbaran, comboVerracosExtraccionAlbaran, comboLineaGeneticaGranja, comboTipoDosisGranja, comboNivelGeneticoActivoGranja} from '../../actions/combos/combos'
import {cerrarDetallesAlbaranDosis, editarAlbaranDosis} from '../../actions/albaran/albaran'
import ModalAlbaranDosis from '../../components/albaran/ModalAlbaranDosis'

export function mapStateToProps (state) {
  return {
    ...state.albaran.data,
    formModalAlbaran: getFormValues('ModalAlbaran')(state),
    formModalAlbaranDosis: getFormValues('ModalAlbaranDosis')(state),
    auth: state.auth,
    combos: {
      comboTipoDosisGranja: state.combos.comboTipoDosisGranja,
      comboRazaGranja: state.combos.comboRazaGranja,
      comboLineaGeneticaGranja: state.combos.comboLineaGeneticaGranja,
      comboNivelGeneticoActivoGranja: state.combos.comboNivelGeneticoActivoGranja,
      comboPoolsExtraccionAlbaran: state.combos.comboPoolsExtraccionAlbaran,
      comboVerracosExtraccionAlbaran: state.combos.comboVerracosExtraccionAlbaran
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDetallesAlbaranDosis,
      editarAlbaranDosis,
      comboPoolsExtraccionAlbaran,
      comboVerracosExtraccionAlbaran,
      comboLineaGeneticaGranja, 
      comboNivelGeneticoActivoGranja,
      comboTipoDosisGranja,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAlbaranDosis))
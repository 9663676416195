import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, DoneAll } from '@material-ui/icons'
import {date_formatter} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosRegistroAlimentacion extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

   componentDidMount() {
    this.props.actions.comboVerraco({},true)
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacionPadre()
    this.props.actions.comboNombreGrupoVerraco()
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelRegistroAlimentacion !== prevProps.datosExcelRegistroAlimentacion) {
      if (this.props.datosExcelRegistroAlimentacion.excel && this.props.datosExcelRegistroAlimentacion.excel !== null){
        let pdf = this.props.datosExcelRegistroAlimentacion.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfRegistroAlimentacion !== prevProps.datosPdfRegistroAlimentacion) {
      if (this.props.datosPdfRegistroAlimentacion.pdf && this.props.datosPdfRegistroAlimentacion.pdf !== null){
        let pdf = this.props.datosPdfRegistroAlimentacion.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosRegistroAlimentacion()
          }
        }
      }
    }
  }

  fetchFiltrarRegistroAlimentacion(values){
    this.props.actions.fetchFiltrarRegistroAlimentacion(values)
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosRegistroAlimentacion()
    } else {
      this.props.actions.deSeleccionarTodosRegistroAlimentacion()
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  deleteSeleccionRegistroAlimentacion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idAlimentacion': row.idAlimentacion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionRegistroAlimentacion(list)
  }

  crearCsvRegistroAlimentacion(){
    this.props.actions.crearCsvRegistroAlimentacion(this.props.filtros)
  }

  imprimirPdfRegistroAlimentacion(){
    this.props.actions.imprimirPdfRegistroAlimentacion(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    if (value === 'fechaEntrada') {
      values.fechaRegistroAlimentacionDesde = null
    }
    if (value === 'fechaSalida') {
      values.fechaRegistroAlimentacionHasta = null
    }
    this.props.actions.fetchFiltrarRegistroAlimentacion(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  abrirModalVacioRegistroAlimentacion () {
    let valores = []
    valores.estado = {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}
    valores.list = Object.values(this.props.verracosAnadidos)
    this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(valores)
    this.props.actions.abrirModalVacioRegistroAlimentacion()
    this.props.actions.deSeleccionarTodosModalVerracoRegistroAlimentacion()
  }

  render () {
  const {
    t, handleSubmit, filtros,
    combos: {comboVerraco, comboRaza, comboLineaGenetica, comboNombreGrupoVerraco, comboUbicacionPadre, comboTipoAlimentacion},
    auth: {formaFechaHora}
  } = this.props

  let comboPendienteEncursoTerminado = this.props.combos.comboPendienteEncursoTerminado
  comboPendienteEncursoTerminado = comboPendienteEncursoTerminado.map((estadoFinalizacion) => {
    return {...estadoFinalizacion, label: t(estadoFinalizacion.label)}
  })

  let date1
  let date2
  let partes1
  let partes2
  return (
      <div>
      <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-raza'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-linea-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />

            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-grupo-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Field
              colSm={2}
              id="ubicacion"
              name="ubicacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.UBICACION')}
              component={InputSelect}
              options={comboUbicacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-ubicacion'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Field
              colSm={2}
              id="tipoAlimentacion"
              name="tipoAlimentacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.TIPO_ALIMENTACION')}
              component={InputSelect}
              options={comboTipoAlimentacion}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-tipo-alimentacion'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.ESTADO_FINALIZACION')}
              component={InputSelect}
              options={comboPendienteEncursoTerminado}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-tipo-alimentacion'
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.DESDE_HASTA')}
              onInputChange={handleSubmit(this.fetchFiltrarRegistroAlimentacion.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.verraco && filtros.verraco !== null && filtros.verraco !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.raza && filtros.raza !== null && filtros.raza !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.lineaGenetica && filtros.lineaGenetica !== null && filtros.lineaGenetica !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.LINEA_GENETICA') + ': ' + filtros.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.grupoVerraco && filtros.grupoVerraco !== null && filtros.grupoVerraco !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.GRUPO_VERRACO') + ': ' + filtros.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.ubicacion && filtros.ubicacion !== null && filtros.ubicacion !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.UBICACION') + ': ' + filtros.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.tipoAlimentacion && filtros.tipoAlimentacion !== null && filtros.tipoAlimentacion !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.TIPO_ALIMENTACION') + ': ' + filtros.tipoAlimentacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoAlimentacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.estado && filtros.estado !== null && filtros.estado !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.ESTADO_FINALIZACION') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionRegistroAlimentacion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvRegistroAlimentacion()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfRegistroAlimentacion()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1) ? () => this.abrirModalVacioRegistroAlimentacion() : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div
                className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'}
                onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'registroAlimentacion',
  enableReinitialize: true
})(FiltrosRegistroAlimentacion))

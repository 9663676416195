import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalAnadirVerracos from '../../components/panelControl/ModalAnadirVerracos'
import { cerrarModalAnadirVerracos, fetchListadoVerracos, seleccionarTodosVerracos, deSeleccionarTodosVerracos, anadirVerracos, reiniciarMuestraVerraco 
  } from '../../actions/panelControl/panelControl'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalAnadirVerracos,
      fetchListadoVerracos,
      seleccionarTodosVerracos,
      deSeleccionarTodosVerracos,
      anadirVerracos,
      reiniciarMuestraVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAnadirVerracos))
export default{
  ELEGIR_DIAS_PLANING: 'ELEGIR_DIAS_PLANING',
  ABRIR_MODAL_PLANING: 'ABRIR_MODAL_PLANING',
  CLOSE_MODAL_PLANING: 'CLOSE_MODAL_PLANING',
  ABRIR_MODAL_SELECCIONAR_VERRACO_PLANING: 'ABRIR_MODAL_SELECCIONAR_VERRACO_PLANING',
  CERRAR_MODAL_SELECCIONAR_VERRACO_PLANING: 'CERRAR_MODAL_SELECCIONAR_VERRACO_PLANING',
  FETCH_FILTRAR_PLANING: 'FETCH_FILTRAR_PLANING',
  FETCH_FILTRAR_PLANING_SUCCESS: 'FETCH_FILTRAR_PLANING_SUCCESS',
  FETCH_PLANING_SUCCESS: 'FETCH_PLANING_SUCCESS',
  ANADIR_FECHA_PLANING: 'ANADIR_FECHA_PLANING',
  ELIMINAR_FECHA_PLANING: 'ELIMINAR_FECHA_PLANING',
  ASIGNAR_VERRACOS: 'ASIGNAR_VERRACOS',
  ASIGNAR_VERRACOS_SUCCCESS: 'ASIGNAR_VERRACOS_SUCCCESS',
  SELECCIONAR_TODOS_VERRACOS_PLANING: 'SELECCIONAR_TODOS_VERRACOS_PLANING',
  DESELECCIONAR_TODOS_VERRACOS_PLANING: 'DESELECCIONAR_TODOS_VERRACOS_PLANING',
  ANADIR_VERRACOS_PLANING: 'ANADIR_VERRACOS_PLANING',
  ANADIR_PLANING: 'ANADIR_PLANING',
  CARGAR_VERRACOS_PLANIFICADOS: 'CARGAR_VERRACOS_PLANIFICADOS',
  ON_DELETE_VERRACO_PLANING: 'ON_DELETE_VERRACO_PLANING',
  ON_DELETE_VERRACO_PLANING_SUCCESS: 'ON_DELETE_VERRACO_PLANING_SUCCESS',
  SELECCIONAR_TODOS_MODAL_PLANING: 'SELECCIONAR_TODOS_MODAL_PLANING',
  DESELECCIONAR_TODOS_MODAL_PLANING: 'DESELECCIONAR_TODOS_MODAL_PLANING',
  DELETE_SELECCION_VERRACO_PLANING: 'DELETE_SELECCION_VERRACO_PLANING',
  ABRIR_MODAL_INICIO_PLANING: 'ABRIR_MODAL_INICIO_PLANING',
  CLOSE_MODAL_INICIO_PLANING: 'CLOSE_MODAL_INICIO_PLANING',
  OBTENER_VERRACOS_SIN_EXTRAER: 'OBTENER_VERRACOS_SIN_EXTRAER',
  OBTENER_VERRACOS_SIN_EXTRAER_SUCCESS: 'OBTENER_VERRACOS_SIN_EXTRAER_SUCCESS',
  IMPRIMIR_MODAL_INICIO_PLANING: 'IMPRIMIR_MODAL_INICIO_PLANING',
  IMPRIMIR_MODAL_INICIO_PLANING_SUCCESS: 'IMPRIMIR_MODAL_INICIO_PLANING_SUCCESS',
  EXPORTAR_MODAL_INICIO_PLANING: 'EXPORTAR_MODAL_INICIO_PLANING',
  EXPORTAR_MODAL_INICIO_PLANING_SUCCESS: 'EXPORTAR_MODAL_INICIO_PLANING_SUCCESS',
  TOTALES_PLANING: 'TOTALES_PLANING',
  TOTALES_PLANING_SUCCESS: 'TOTALES_PLANING_SUCCESS',
  TOTALES_MODAL_PLANING_SUCCESS: 'TOTALES_MODAL_PLANING_SUCCESS',
  ABRIR_MODAL_EXPORTAR_IMPRIMIR_PLANING: 'ABRIR_MODAL_EXPORTAR_IMPRIMIR_PLANING',
  CERRAR_MODAL_EXPORTAR_IMPRIMIR_PLANING: 'CERRAR_MODAL_EXPORTAR_IMPRIMIR_PLANING',
  EXPORTAR_PLANING: 'EXPORTAR_PLANING',
  EXPORTAR_PLANING_SUCCESS: 'EXPORTAR_PLANING_SUCCESS',
  IMPRIMIR_PLANING: 'IMPRIMIR_PLANING',
  IMPRIMIR_PLANING_SUCCESS: 'IMPRIMIR_PLANING_SUCCESS',
  FILTROS_MODAL_SUGERENCIAS_PLANING: 'FILTROS_MODAL_SUGERENCIAS_PLANING',
  OBTENER_DATOS_VERRACO_PLANING: 'OBTENER_DATOS_VERRACO_PLANING',
  FETCH_ORDER_PLANING: 'FETCH_ORDER_PLANING',
  EXPORTAR_SUGERENCIA_EXTRACCIONES: 'EXPORTAR_SUGERENCIA_EXTRACCIONES',
  EXPORTAR_SUGERENCIA_EXTRACCIONES_SUCCESS: 'EXPORTAR_SUGERENCIA_EXTRACCIONES_SUCCESS',
  IMPRIMIR_SUGERENCIA_EXTRACCIONES: 'IMPRIMIR_SUGERENCIA_EXTRACCIONES',
  IMPRIMIR_SUGERENCIA_EXTRACCIONES_SUCCESS: 'IMPRIMIR_SUGERENCIA_EXTRACCIONES_SUCCESS',
  IMPRIMIR_ZEBRA_VERRACOS: 'IMPRIMIR_ZEBRA_VERRACOS',
  IMPRIMIR_ZEBRA_VERRACOS_SUCCESS: 'IMPRIMIR_ZEBRA_VERRACOS_SUCCESS',
  SELECCIONAR_TODOS_PLANNING: 'SELECCIONAR_TODOS_PLANNING',
  DESELECCIONAR_TODOS_PLANNING: 'DESELECCIONAR_TODOS_PLANNING',
  ANADIR_SELECCION_PLANING: 'ANADIR_SELECCION_PLANING',
  ABRIR_DUPLICAR_PLANING: 'ABRIR_DUPLICAR_PLANING',
  CERRAR_DUPLICAR_PLANING: 'CERRAR_DUPLICAR_PLANING',
  ENVIAR_FECHA_DUPLICAR_PLANING: 'ENVIAR_FECHA_DUPLICAR_PLANING',
  ENVIAR_FECHA_DUPLICAR_PLANING_SUCCESS: 'ENVIAR_FECHA_DUPLICAR_PLANING_SUCCESS',
  SUBMIT_DUPLICAR_PLANING: 'SUBMIT_DUPLICAR_PLANING',
  SELECCIONAR_TODOS_PLANNING_PRINCIPAL: 'SELECCIONAR_TODOS_PLANNING_PRINCIPAL',
  DESELECCIONAR_TODOS_PLANNING_PRINCIPAL: 'DESELECCIONAR_TODOS_PLANNING_PRINCIPAL',
  ANADIR_SELECCION_PLANING_PRINCIPAL: 'ANADIR_SELECCION_PLANING_PRINCIPAL',
  ELIMINAR_SELECCION_PLANING_PRINCIPAL: 'ELIMINAR_SELECCION_PLANING_PRINCIPAL',
  SELECCIONAR_TODOS_SUGERENCIAS_PLANNING: 'SELECCIONAR_TODOS_SUGERENCIAS_PLANNING',
  DESELECCIONAR_TODOS_SUGERENCIAS_PLANNING: 'DESELECCIONAR_TODOS_SUGERENCIAS_PLANNING',
  SELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING: 'SELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING',
  DESELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING: 'DESELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING',
  TOTALES_PLANING_ALBARAN: 'TOTALES_PLANING_ALBARAN',
  TOTALES_PLANING_ALBARAN_SUCCESS: 'TOTALES_PLANING_ALBARAN_SUCCESS',
  OBTENER_PREVISION_VERRACOS: 'OBTENER_PREVISION_VERRACOS',
  OBTENER_PREVISION_VERRACOS_SUCCESS: 'OBTENER_PREVISION_VERRACOS_SUCCESS'
}
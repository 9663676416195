import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { DoneAll } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import ListadoPage from '../../containers/page/ListadoPage'

class ModalRuta extends Component {
  constructor () {
    super()
    this.state = {
      fitrosSeleccionados: []
    }
  }

  componentDidMount () {
    this.props.actions.comboRuta()
    this.props.actions.comboCliente()
    let list = this.props.transporte.rutasAnadidas
    this.props.actions.filtrarRutasTransporte({activo: {value: true, label: this.props.t('ESTADO.ACTIVO')}, list})
    this.props.change('seleccionar-todos-modal-ruta-transportista', false)
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
    this.props.change('ruta', null)
    this.props.change('cliente', null)
  }

  filtrarRutasTransporte (values) {
    values.list = this.props.transporte.rutasAnadidas
    this.props.actions.filtrarRutasTransporte(values)
  }

  anadirRutas (values) {
    const itemsSeleccion = this.props.transporte.listRutas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idRuta': row.idRuta,
          'idCentro': row.idCentro,
          'nombreRuta': row.nombreRuta,
          'observaciones': row.observaciones,
          'numeroClientes': row.numeroClientes,
          'activo': row.activo,
          'idElemento': row.idElemento,
          'predefinido': row.predefinido || false
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.anadirRutasTransportista(list)
    this.props.change('seleccionar-todos-modal-ruta-transportista', false)
  }

  intercambiarCheckModalRutaTransportista (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalRutaTransportista()
    } else {
      this.props.actions.deSeleccionarTodosModalRutaTransportista()
    }
  }

  quitarFiltros (value) {
    let values = this.props.transporte.filtrosModalRuta
    values[value] = null
    this.props.actions.filtrarRutasTransporte(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  closeModalRuta () {
    this.props.actions.closeModalRuta()
    let list = this.props.transporte.rutasAnadidas
    this.props.actions.filtrarRutasTransporte({activo: {value: true, label: this.props.t('ESTADO.ACTIVO')}, list})
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
    this.props.change('ruta', null)
    this.props.change('cliente', null)
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual,
      transporte:{showModalRuta, listRutas, checkedFiltrosTansporteRuta, filtrosModalRuta},
      actions: {closeModalRuta},
      combos: {comboRuta, comboCliente}
    } = this.props

    let comboEstado = this.props.combos.comboEstado
    comboEstado = comboEstado.map((estado) => {
      return {...estado, label: t(estado.label)}
    })

    listRutas.forEach(
      (row) => row.idElemento = row.idRuta
    )

    const tableRutaTransportista = {
      id: 'rutaTableTranspor',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombreRuta', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.RUTA')},
        {id: 'numeroClientes', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.NUM_CLIENTES')},
        {id: 'observaciones', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.OBSERVACIONES')},
        {id: 'predefinido', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.POR_DEFECTO')}
      ],
      rows: listRutas,
      initialValues: {},
      noBlockOptions: false,
      filaPorDefectoRuta: true
    }
    tableRutaTransportista.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableRutaTransportista.initialValues['check' + row.idElemento] = checkedFiltrosTansporteRuta
        }
      }
    )

    return (
      <Modal show={showModalRuta} onHide={() => this.closeModalRuta()} aria-labelledby="contained-modal-title-lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_RUTA.TITLE')
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="filtros-tabla-nuevo-transportista">
                <form className="custom-form">
                  <Field
                    colSm={3}
                    id="ruta"
                    name="ruta"
                    controlLabel={t('RUTA.FILTROS.NOMBRE_RUTA')}
                    component={InputSelect}
                    options={comboRuta}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-nombre-ruta'
                    onInputChange={handleSubmit(this.filtrarRutasTransporte.bind(this))}
                  />
                  <Field
                    colSm={3}
                    id="cliente"
                    name="cliente"
                    controlLabel={t('RUTA.FILTROS.CLIENTE')}
                    component={InputSelect}
                    options={comboCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-cliente'
                    onInputChange={handleSubmit(this.filtrarRutasTransporte.bind(this))}
                  />
                  <Field
                    colSm={3}
                    id="activo"
                    name="activo"
                    controlLabel={t('RUTA.FILTROS.ESTADO')}
                    component={InputSelect}
                    options={comboEstado}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-estado'
                    onInputChange={handleSubmit(this.filtrarRutasTransporte.bind(this))}
                  />
                  <div className="clearfix"></div>
                  <div className="aplicar-tabla-filtros">
                    <div className="filtros-anadidos">
                      {
                        Object.keys(filtrosModalRuta).length > 0 && (
                          filtrosModalRuta.ruta && filtrosModalRuta.ruta !== null && (
                            <span className="badge-dark">{t('RUTA.FILTROS.NOMBRE_RUTA') + ': ' + filtrosModalRuta.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>
                          )
                        )
                      }
                      {
                        Object.keys(filtrosModalRuta).length > 0 && (
                          filtrosModalRuta.cliente && filtrosModalRuta.cliente !== null && (
                            <span className="badge-dark">{t('RUTA.FILTROS.CLIENTE') + ': ' + filtrosModalRuta.cliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cliente')}> x </button></span>
                          )
                        )
                      }
                      {
                        Object.keys(filtrosModalRuta).length > 0 && (
                          filtrosModalRuta.activo && filtrosModalRuta.activo !== null && (
                            <span className="badge-dark">{t('RUTA.FILTROS.ESTADO') + ': ' + filtrosModalRuta.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                          )
                        )
                      }
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </Col>
            <div className="tabla-nuevo-transportista">
              <div className="filtros-tabla-nuevo-transportista">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-ruta-transportista"
                    name="seleccionar-todos-modal-ruta-transportista"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalRutaTransportista(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="RUTA." noTitle={true} table={tableRutaTransportista}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirRutas.bind(this))}>{t('TITULOS_BOTONES.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.closeModalRuta()}>{t('TRANSPORTE.NUEVO_TRANSPORTE.MODAL_RUTA.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRuta'
})(ModalRuta))
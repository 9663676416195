import actionTypes from '../../constants/actions/analisisExtracciones/contajeMaquinas'

export function openModalContajeMaquinas(tipoAnalisis, isSeguimiento){
  return {
    type: actionTypes.OPEN_MODAL_CONTAJE_MAQUINAS,
    tipoAnalisis,
    isSeguimiento
  }
}

export function openModalContajeMaquinasSuccess({tipoAnalisis, teclasContaje, isSeguimiento}){
  return {
    type: actionTypes.OPEN_MODAL_CONTAJE_MAQUINAS_SUCCESS,
    tipoAnalisis,
    teclasContaje,
    isSeguimiento
  }
}

export function closeModalContajeMaquinas(from,values){
  return {
    type: actionTypes.CLOSE_MODAL_CONTAJE_MAQUINAS,
    from,
    values
  }
}

export function closeModalContajeMaquinasSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_CONTAJE_MAQUINAS_SUCCESS
  }
}

export default {
  openModalContajeMaquinas,
  openModalContajeMaquinasSuccess,
  closeModalContajeMaquinas,
  closeModalContajeMaquinasSuccess
}

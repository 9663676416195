import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerracoCambioEstado from '../../components/fichaVerraco/ModalVerracoCambioEstado'
import { comboVerraco} from '../../actions/combos/combos'
import { cerrarModalVerracoCambioEstado, guardarDatosCambioEstado} from '../../actions/fichaVerraco/fichaVerraco'
import { enviarFechaCambioEstado, submitNuevoCambioEstadoVerraco, restartCambioEstadoVerraco, editarCambioEstadoVerraco } from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'
import { yesNoModal } from '../../actions/common'

export function mapStateToProps (state) {
  return {
    showModalVerracoCambioEstado: state.fichaVerraco.showModalVerracoCambioEstado,
    combos: {...state.combos},
    formVerracoModalCambioEstado: state.form.ModalVerracoCambioEstado,
    idVerraco: state.fichaVerraco.idVerraco,
    fichaVerraco: state.fichaVerraco,
    cambioEstadoVerraco: state.cambioEstadoVerraco,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalVerracoCambioEstado,
      enviarFechaCambioEstado,
      submitNuevoCambioEstadoVerraco,
      restartCambioEstadoVerraco,
      comboVerraco,
      editarCambioEstadoVerraco,
      guardarDatosCambioEstado,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerracoCambioEstado))
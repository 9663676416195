export default {
  ABRIR_MODAL_VACIO_BIOSEGURIDAD_HIGIENE: 'ABRIR_MODAL_VACIO_BIOSEGURIDAD_HIGIENE',
  OPEN_MODAL_BIOSEGURIDAD_HIGIENE: 'OPEN_MODAL_BIOSEGURIDAD_HIGIENE',
  CLOSE_MODAL_BIOSEGURIDAD_HIGIENE: 'CLOSE_MODAL_BIOSEGURIDAD_HIGIENE',
  FETCH_BIOSEGURIDAD_HIGIENE: 'FETCH_BIOSEGURIDAD_HIGIENE',
  FETCH_BIOSEGURIDAD_HIGIENE_SUCCESS: 'FETCH_BIOSEGURIDAD_HIGIENE_SUCCESS',
  ABRIR_MODAL_LIMPIEZA_SILOS: 'ABRIR_MODAL_LIMPIEZA_SILOS',
  ABRIR_MODAL_LIMPIEZA_SILOS_SUCCESS: 'ABRIR_MODAL_LIMPIEZA_SILOS_SUCCESS',
  CERRAR_MODAL_LIMPIEZA_SILOS: 'CERRAR_MODAL_LIMPIEZA_SILOS',
  FETCH_LIMPIEZA_SILOS: 'FETCH_LIMPIEZA_SILOS',
  FETCH_LIMPIEZA_SILOS_SUCCESS: 'FETCH_LIMPIEZA_SILOS_SUCCESS',
  SELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS: 'SELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS',
  DESELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS: 'DESELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS',
  ANADIR_SILO: 'ANADIR_SILO',
  ON_DELETE_SILO_ANADIDO: 'ON_DELETE_SILO_ANADIDO',
  ON_DELETE_SILO_ANADIDO_SUCCESS: 'ON_DELETE_SILO_ANADIDO_SUCCESS',
  SELECCIONAR_TODOS_MODAL_BIOSEGURIDAD: 'SELECCIONAR_TODOS_MODAL_BIOSEGURIDAD',
  DESELECCIONAR_TODOS_MODAL_BIOSEGURIDAD: 'DESELECCIONAR_TODOS_MODAL_BIOSEGURIDAD',
  DELETE_SELECCION_MODAL_SILOS: 'DELETE_SELECCION_MODAL_SILOS',
  ABRIR_MODAL_LIMPIEZA_CORRAL: 'ABRIR_MODAL_LIMPIEZA_CORRAL',
  ABRIR_MODAL_LIMPIEZA_CORRAL_SUCCESS: 'ABRIR_MODAL_LIMPIEZA_CORRAL_SUCCESS',
  CERRAR_MODAL_LIMPIEZA_CORRAL: 'CERRAR_MODAL_LIMPIEZA_CORRAL',
  FETCH_LIMPIEZA_CORRAL: 'FETCH_LIMPIEZA_CORRAL',
  FETCH_LIMPIEZA_CORRAL_SUCCESS: 'FETCH_LIMPIEZA_CORRAL_SUCCESS',
  SELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL: 'SELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL',
  DESELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL: 'DESELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL',
  ON_DELETE_CORRAL_ANADIDO_SUCCESS: 'ON_DELETE_CORRAL_ANADIDO_SUCCESS',
  ON_DELETE_CORRAL_ANADIDO: 'ON_DELETE_CORRAL_ANADIDO',
  DELETE_SELECCION_MODAL_CORRAL: 'DELETE_SELECCION_MODAL_CORRAL',
  ANADIR_CORRAL: 'ANADIR_CORRAL',
  ABRIR_MODAL_UBICACION: 'ABRIR_MODAL_UBICACION',
  ABRIR_MODAL_UBICACION_SUCCESS: 'ABRIR_MODAL_UBICACION_SUCCESS',
  CERRAR_MODAL_UBICACION: 'CERRAR_MODAL_UBICACION',
  FETCH_UBICACION: 'FETCH_UBICACION',
  FETCH_UBICACION_SUCCESS: 'FETCH_UBICACION_SUCCESS',
  SELCCIONAR_TODOS_MODAL_UBICACION: 'SELCCIONAR_TODOS_MODAL_UBICACION',
  DESELCCIONAR_TODOS_MODAL_UBICACION: 'DESELCCIONAR_TODOS_MODAL_UBICACION',
  ON_DELETE_UBICACION_ANADIDO_SUCCESS: 'ON_DELETE_UBICACION_ANADIDO_SUCCESS',
  ON_DELETE_UBICACION_ANADIDO: 'ON_DELETE_UBICACION_ANADIDO',
  DELETE_SELECCION_MODAL_UBICACION: 'DELETE_SELECCION_MODAL_UBICACION',
  ANADIR_UBICACION: 'ANADIR_UBICACION',
  ABRIR_MODAL_VERRACO: 'ABRIR_MODAL_VERRACO',
  ABRIR_MODAL_VERRACO_SUCCESS: 'ABRIR_MODAL_VERRACO_SUCCESS',
  CERRAR_MODAL_VERRACO: 'CERRAR_MODAL_VERRACO',
  FETCH_VERRACO: 'FETCH_VERRACO',
  FETCH_VERRACO_SUCCESS: 'FETCH_VERRACO_SUCCESS',
  SELCCIONAR_TODOS_MODAL_VERRACO: 'SELCCIONAR_TODOS_MODAL_VERRACO',
  DESELCCIONAR_TODOS_MODAL_VERRACO: 'DESELCCIONAR_TODOS_MODAL_VERRACO',
  ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD: 'ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD',
  ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS: 'ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS',
  ANADIR_VERRACO_BIOSEGURIDAD: 'ANADIR_VERRACO_BIOSEGURIDAD',
  DELETE_SELECCION_MODAL_VERRACO: 'DELETE_SELECCION_MODAL_VERRACO',
  ABRIR_MODAL_GRUPO_VERRACO: 'ABRIR_MODAL_GRUPO_VERRACO',
  ABRIR_MODAL_GRUPO_VERRACO_SUCCESS: 'ABRIR_MODAL_GRUPO_VERRACO_SUCCESS',
  CERRAR_MODAL_GRUPO_VERRACO: 'CERRAR_MODAL_GRUPO_VERRACO',
  FETCH_GRUPO_VERRACO_BIOSEGURIDAD: 'FETCH_GRUPO_VERRACO_BIOSEGURIDAD',
  FETCH_GRUPO_VERRACO_BIOSEGURIDAD_SUCCESS: 'FETCH_GRUPO_VERRACO_BIOSEGURIDAD_SUCCESS',
  SELCCIONAR_TODOS_MODAL_GRUPO_VERRACO: 'SELCCIONAR_TODOS_MODAL_GRUPO_VERRACO',
  DESELCCIONAR_TODOS_MODAL_GRUPO_VERRACO: 'DESELCCIONAR_TODOS_MODAL_GRUPO_VERRACO',
  ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD: 'ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD',
  ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS: 'ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS',
  ANADIR_GRUPO_VERRACO_BIOSEGURIDAD: 'ANADIR_GRUPO_VERRACO_BIOSEGURIDAD',
  DELETE_SELECCION_MODAL_GRUPO_VERRACO: 'DELETE_SELECCION_MODAL_GRUPO_VERRACO',
  SUBMIT_ANALITICA_AGUA: 'SUBMIT_ANALITICA_AGUA',
  SUBMIT_LIMPIEZA_SILOS: 'SUBMIT_LIMPIEZA_SILOS',
  SUBMIT_LIMPIEZA_CORRALES: 'SUBMIT_LIMPIEZA_CORRALES',
  SUBMIT_OTROS: 'SUBMIT_OTROS',
  RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE: 'RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE',
  RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS: 'RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS',
  MODIFICAR_ANALITICA_AGUA: 'MODIFICAR_ANALITICA_AGUA',
  MODIFICAR_LIMPIEZA_SILOS: 'MODIFICAR_LIMPIEZA_SILOS',
  MODIFICAR_LIMPIEZA_CORRAL: 'MODIFICAR_LIMPIEZA_CORRAL',
  MODIFICAR_OTROS: 'MODIFICAR_OTROS',
  DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE: 'DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE',
  DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS: 'DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS',
  SELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE: 'SELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE',
  DESELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE: 'DESELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE',
  ON_DELETE_BIOSEGURIDAD_HIGIENE: 'ON_DELETE_BIOSEGURIDAD_HIGIENE',
  GUARDAR_NUEVO_ANALITICA_AGUA: 'GUARDAR_NUEVO_ANALITICA_AGUA',
  GUARDAR_NUEVO_LIMPIEZA_SILOS: 'GUARDAR_NUEVO_LIMPIEZA_SILOS',
  GUARDAR_NUEVO_LIMPIEZA_CORRALES: 'GUARDAR_NUEVO_LIMPIEZA_CORRALES',
  GUARDAR_NUEVO_OTROS: 'GUARDAR_NUEVO_OTROS',
  VACIAR_TABLAS: 'VACIAR_TABLAS',
  DELETE_SELECCION_BIOSEGURIDAD_HIGIENE: 'DELETE_SELECCION_BIOSEGURIDAD_HIGIENE',
  FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE: 'FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE',
  FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE_SUCCESS: 'FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE_SUCCESS',
  SUBMIT_ANALITICA_AGUA_SUCCESS: 'SUBMIT_ANALITICA_AGUA_SUCCESS',
  CREATE_CSV_BIOSEGURIDAD: 'CREATE_CSV_BIOSEGURIDAD',
  CREATE_CSV_BIOSEGURIDAD_SUCCESS: 'CREATE_CSV_BIOSEGURIDAD_SUCCESS',
  IMPRIMIR_PDF_BIOSEGURIDAD: 'IMPRIMIR_PDF_BIOSEGURIDAD',
  IMPRIMIR_PDF_BIOSEGURIDAD_SUCCESS: 'IMPRIMIR_PDF_BIOSEGURIDAD_SUCCESS',
  ABRIR_MODAL_AGENDA_BIOSEGURIDAD: 'ABRIR_MODAL_AGENDA_BIOSEGURIDAD',
  ABRIR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS: 'ABRIR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS',
  GUARDAR_MODAL_AGENDA_BIOSEGURIDAD: 'GUARDAR_MODAL_AGENDA_BIOSEGURIDAD',
  GUARDAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS: 'GUARDAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS',
  CERRAR_MODAL_AGENDA_BIOSEGURIDAD: 'CERRAR_MODAL_AGENDA_BIOSEGURIDAD',
  CERRAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS: 'CERRAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS',
  FILTROS_UBICACION_SUCCESS: 'FILTROS_UBICACION_SUCCESS',
  FILTROS_VERRACOS_SUCCESS: 'FILTROS_VERRACOS_SUCCESS',
  SUBIR_ARCHIVOS_BIOSEGURIDAD: 'SUBIR_ARCHIVOS_BIOSEGURIDAD',
  ELIMINAR_ARCHIVOS_BIOSEGURIDAD: 'ELIMINAR_ARCHIVOS_BIOSEGURIDAD',
  OBTENER_ARCHIVO_CONTENT_BIOSEGURIDAD: 'OBTENER_ARCHIVO_CONTENT_BIOSEGURIDAD',
  OBTENER_ARCHIVO_BIOSEGURIDAD_SUCCESS: 'OBTENER_ARCHIVO_BIOSEGURIDAD_SUCCESS',
  IMPRIMIR_INFORME_BIOSEGURIDAD: 'IMPRIMIR_INFORME_BIOSEGURIDAD',
  SUBMIT_LIMPIEZA_SILOS_SUCCESS: 'SUBMIT_LIMPIEZA_SILOS_SUCCESS',
  SUBMIT_LIMPIEZA_CORRALES_SUCCESS: 'SUBMIT_LIMPIEZA_CORRALES_SUCCESS',
  SUBMIT_OTROS_SUCCESS: 'SUBMIT_OTROS_SUCCESS'
}
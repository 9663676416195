import actionTypes from '../../../constants/actions/planSanitario/visita/visita'

export function initialState () {
  return {
    list: [],
    checkedFiltrosVisita: false,
    filtros: {},
    ultimaVisita: [],
    datosExcelVisita: [],
    datosPdfVisita: [],
    data: [],
    dataDuplicar : [],
    showModalVisitas: false
  }
}

export function fetchVisitaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function seleccionarTodosVisita (state) {
  return {
    ...state,
    checkedFiltrosVisita: true
  }
}

export function deSeleccionarTodosVisita (state) {
  return {
    ...state,
    checkedFiltrosVisita: false
  }
}

export function recuperarDatosModalVisitaSuccess (state, {data}) {
  return {
    ...state,
    data,
    dataDuplicar: []
  }
}

export function duplicarDatosModalVisitaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar,
    data: []
  }
}

export function fetchFiltrarVisitaSuccess (state, {filtros}){
  return {
    ...state,
    filtros
  }
}

export function submitNuevoVisitaSuccess (state, {ultimaVisita}){
  return {
    ...state,
    ultimaVisita,
    dataDuplicar: [],
    data: []
  }
}

export function imprimirInformeVisitaSuccess (state, {ultimaVisita}){
  return {
    ...state,
    ultimaVisita
  }
}

export function crearCsvVisitaSuccess (state, {datosExcelVisita}) {
  return {
    ...state,
    datosExcelVisita
  }
}

export function imprimirPdfVisitaSuccess (state, {datosPdfVisita}) {
  return {
    ...state,
    datosPdfVisita
  }
}

export function restartVisitas (state) {
  return {
    ...state,
    dataDuplicar: [],
    data: []
  }
}

export function cerrarModalVisitas (state) {
  return {
    ...state,
    showModalVisitas: false
  }
}

export function abrirModalVisitas (state) {
  return {
    ...state,
    showModalVisitas: true
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_VISITA_SUCCESS:
      return fetchVisitaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VISITA:
      return seleccionarTodosVisita(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VISITA:
      return deSeleccionarTodosVisita(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_VISITA_SUCCESS:
      return recuperarDatosModalVisitaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_VISITA_SUCCESS:
      return duplicarDatosModalVisitaSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_VISITA_SUCCESS:
      return fetchFiltrarVisitaSuccess(state, action)
    case actionTypes.SUBMIT_NUEVO_VISITA_SUCCESS:
      return submitNuevoVisitaSuccess(state, action)
    case actionTypes.IMPRIMIR_INFORME_VISITA_SUCCESS:
      return imprimirInformeVisitaSuccess(state, action)
    case actionTypes.CREATE_CSV_VISITA_SUCCESS:
      return crearCsvVisitaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_VISITA_SUCCESS:
      return imprimirPdfVisitaSuccess(state, action)
    case actionTypes.RESTART_VISITAS:
      return restartVisitas(state, action)
    case actionTypes.CERRAR_MODAL_VISITAS:
      return cerrarModalVisitas(state, action)
    case actionTypes.ABRIR_MODAL_VISITAS:
      return abrirModalVisitas(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import {date_formatter} from '../../../util/formatFunctions'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputText from '../../comun/form/InputText'
import InputSelect from '../../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, DoneAll } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosVisita extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarVisita(values){
    this.props.actions.fetchFiltrarVisita(values)
  }

  componentDidMount () {
    this.props.actions.comboEmpresaVisitas()
    this.props.actions.comboContactoVistas()
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelVisita !== prevProps.datosExcelVisita) {
      if (this.props.datosExcelVisita.excel && this.props.datosExcelVisita.excel !== null){
        let pdf = this.props.datosExcelVisita.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.VISITAS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfVisita !== prevProps.datosPdfVisita) {
      if (this.props.datosPdfVisita.pdf && this.props.datosPdfVisita.pdf !== null){
        let pdf = this.props.datosPdfVisita.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.VISITAS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosVisita()
          }
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVisita()
    } else {
      this.props.actions.deSeleccionarTodosVisita()
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  cambiarEstadoSeleccionVisita(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVisita': row.idVisita
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionVisita(list)
  }

  deleteSeleccionVisita(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVisita': row.idVisita,
          'contacto': row.nombreVisita,
          'empresa': row.empresaVisita
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionVisita(list)
  }

  crearCsvVisita(){
    this.props.actions.crearCsvVisita(this.props.filtros)
  }

  imprimirPdfVisita(){
    this.props.actions.imprimirPdfVisita(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarVisita(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {abrirModalVacioVisita},
    combos: {comboEmpresaVisitas, comboContactoVistas},
    auth: {formaFechaHora}
  } = this.props

  let date1
  let date2
  let partes1
  let partes2

  return (
      <div>
      <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form" >
            <Field
              colSm={2}
              id="contacto"
              name="contacto"
              component={InputSelect}
              options={comboContactoVistas}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.NOMBRE_CONTACTO')}
              claseActivo="nombre-visita"
              onInputChange={handleSubmit(this.fetchFiltrarVisita.bind(this))}
            />
            <Field
              colSm={2}
              id="empresa"
              name="empresa"
              component={InputSelect}
              options={comboEmpresaVisitas}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.EMPRESA')}
              claseActivo="empresa-visita"
              onInputChange={handleSubmit(this.fetchFiltrarVisita.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('PLAN_SANITARIO.VISITA.FILTROS.DESDE_HASTA')}
              onInputChange={handleSubmit(this.fetchFiltrarVisita.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.contacto && filtros.contacto !== null && filtros.contacto !== '') ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.VISITA.MODAL.NOMBRE_CONTACTO') + ' ' + filtros.contacto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('contacto')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.empresa && filtros.empresa !== null ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.VISITA.MODAL.EMPRESA') + ' ' + filtros.empresa.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('empresa')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.VISITA.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.VISITA.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PLAN_SANITARIO.VISITA.FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={6}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionVisita()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvVisita()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfVisita()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1) ? abrirModalVacioVisita : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={6} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div
                className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'}
                onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosVisita',
  enableReinitialize: true
})(FiltrosVisita))

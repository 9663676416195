import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosIndex from '../../components/fichaVerraco/FiltrosIndex'
import {abrirModalVacioIndex, seleccionarTodosIndex, deSeleccionarTodosIndex, deleteSeleccionIndex} from '../../actions/fichaVerraco/fichaVerraco'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    fichaVerraco: state.fichaVerraco,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVacioIndex,
      seleccionarTodosIndex,
      deSeleccionarTodosIndex,
      deleteSeleccionIndex
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosIndex))
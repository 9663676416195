import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalEscandallo from '../../components/escandallo/ModalEscandallo'
import { comboUsuariosCentro, comboTipoDosis } from '../../actions/combos/combos'
import { recuperarDatosModalEscandallo, closeModalEscandallo, abrirModalAnadirEscandallo, submitNuevoEscandallo, recuperarDatosModalEscandalloAnadido, onDeleteEscandalloAnadido, duplicarDatosModalEscandalloAnadido,
  editarEscandallo, guardarYnuevoEscandallo} from '../../actions/escandallo/escandallo'

export function mapStateToProps (state) {
  return {
    ...state.escandallo,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    formModalEscandallo: getFormValues('ModalEscandallo')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalEscandallo,
      closeModalEscandallo,
      comboTipoDosis,
      abrirModalAnadirEscandallo,
      submitNuevoEscandallo,
      recuperarDatosModalEscandalloAnadido,
      onDeleteEscandalloAnadido,
      duplicarDatosModalEscandalloAnadido,
      editarEscandallo,
      guardarYnuevoEscandallo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEscandallo))
import apiFetch from '../apiFetch'

export default function (baseUrlMagavision, tokenUsuarioMagavision) {
  return apiFetch({
    server: null,
    endPoint: `${baseUrlMagavision + (baseUrlMagavision[baseUrlMagavision.length - 1] !== '/' ? '/' : '')}api/logout?t_usr=${tokenUsuarioMagavision}`,
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
    accept: '*/*',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}

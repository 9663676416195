import React, { Component } from 'react'
import {reduxForm} from 'redux-form'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import ModalCambioEstadoVerracoPage from '../../containers/cambioEstadoVerraco/ModalCambioEstadoVerracoPage'
import FiltrosCambioEstadoVerracoPage from '../../containers/cambioEstadoVerraco/FiltrosCambioEstadoVerracoPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class CambioEstadoVerraco extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.VERRACOS.CAMBIOS_ESTADO_VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarCambioEstadoVerraco({})
  }
  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      actions: {recuperarDatosModalCambioEstadoVerraco, duplicarDatosModalCambioEstadoVerraco, onDeleteCambioEstadoVerraco},
      cambioEstadoVerraco: {list, registroModificar, registroDuplicar, checkedFiltrosCambioEstadoVerraco}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idCambioEstadoVerraco
    )

    const table = {
      id: 'MovimientoVerracoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.COD_VERRACO')},
        {id: 'nombreRaza', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.NOMBRE_RAZA')},
        {id: 'nombreLinea', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.NOMBRE_LINEA')},
        {id: 'fechaCambio', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.FECHA_CAMBIO'), type: 'fecha'},
        {id: 'ultimaFechaExtraccion', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.FECHA_ULTIMA_EXTRACCION'), type: 'fecha'},
        {id: 'estadoanterior', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.ESTADO_ANTERIOR')},
        {id: 'estadoactual', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.ESTADO_ACTUAL')},
        {id: 'ObservacionesCambio', name: t('CAMBIO_ESTADO_VERRACOS.TABLA.OBSERVACIONES')}
      ],
      rows: list,
      initialValues: {},
      filtros: <FiltrosCambioEstadoVerracoPage/>,
      mostrarCambioEstado: false,
      onEdit: (row) => recuperarDatosModalCambioEstadoVerraco(row.idCambioEstadoVerraco),
      data: registroModificar,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1,
      duplicar: (row) =>duplicarDatosModalCambioEstadoVerraco(row.idCambioEstadoVerraco),
      dataDuplicar: registroDuplicar,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1,
      onDelete: (row) => onDeleteCambioEstadoVerraco(row.idCambioEstadoVerraco)
    }
    table.rows.forEach(
      (row, idRow) => {
       if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosCambioEstadoVerraco
        }
      }
    )
    return(
    <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalCambioEstadoVerracoPage/>
        <ListadoPage t_key="CAMBIO_ESTADO_VERRACOS." table={table}>
        </ListadoPage>
      </div>
    )
  }
}
export default (reduxForm({
  form: "cambioEstadoVerraco",
})(CambioEstadoVerraco))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosAjusteStock extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
    this.handleChangeProducto = this.handleChangeProducto.bind(this)
    this.fetchAjusteStock = this.fetchAjusteStock.bind(this)
    this.crearCsvAjusteStock = this.crearCsvAjusteStock.bind(this)
    this.imprimirPdfAjusteStock = this.imprimirPdfAjusteStock.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboProductosActivosTrazabilidad()
    this.props.actions.comboLotesProductoEspecificoActivoTrazabilidad('')
  }

  handleChangeProducto (values, callback) {
    this.props.change('entradaAlmacenProducto', null)
    if (values && values.value) {
      this.props.actions.comboLotesProductoEspecificoActivoTrazabilidad(values.value)
    }
    else{
      this.props.actions.comboLotesProductoEspecificoActivoTrazabilidad("")
    }

    if (callback) callback()
  }

  fetchAjusteStock(values){
    this.props.actions.fetchAjusteStock(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  crearCsvAjusteStock(){
    this.props.actions.crearCsvAjusteStock(this.props.ajusteStock.filtros)
  }

  imprimirPdfAjusteStock(){
    this.props.actions.imprimirPdfAjusteStock(this.props.ajusteStock.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.ajusteStock.filtros
    values[value] = null
    this.props.actions.fetchAjusteStock(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, auth: { permisos }, ajusteStock: {filtros = {}},
    actions: { openModalAjusteStock },
    combos: {
      comboProductosActivosTrazabilidad, comboLotesProductoEspecificoActivoTrazabilidad
    }
  } = this.props
  const tKey = 'ALMACEN.AJUSTE_STOCK.'

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="producto"
              name="producto"
              controlLabel={t(tKey + 'FILTROS.PRODUCTO')}
              component={InputSelect}
              options={comboProductosActivosTrazabilidad}
              valueKey="value"
              labelKey="label"
              onInputChange={(producto) => this.handleChangeProducto(producto, handleSubmit(this.fetchAjusteStock))}
            />
            <Field
              colSm={2}
              id="entradaAlmacenProducto"
              name="entradaAlmacenProducto"
              controlLabel={t(tKey + 'FILTROS.LOTE')}
              component={InputSelect}
              options={comboLotesProductoEspecificoActivoTrazabilidad}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAjusteStock)}
            />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchAjusteStock)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.producto && <span key="filtros.producto" className="badge-dark">{t(tKey + 'FILTROS.PRODUCTO') + ': ' + filtros.producto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('producto')}> x </button></span>),
                    (filtros.entradaAlmacenProducto && <span key="filtros.lote"  className="badge-dark">{t(tKey + 'FILTROS.LOTE') + ': ' + filtros.entradaAlmacenProducto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('entradaAlmacenProducto')}> x </button></span>),
                    (filtros.desde && filtros.hasta && filtros.desde !== null && filtros.hasta !== null) ? (
                      <span key="filtros.desdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtros.desde && filtros.desde !== null) ? (
                      <span key="filtros.desde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtros.hasta && filtros.hasta !== null) && (
                      <span key="filtros.hasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    )
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvAjusteStock()}>
                  <Launch></Launch> {t(tKey + 'FILTROS.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfAjusteStock()}>
                  <Print></Print> {t(tKey + 'FILTROS.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.AJUSTE_STOCK] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AJUSTE_STOCK] > 1) ? openModalAjusteStock : undefined}
                  >
                  <AddCircleOutline /> {t(tKey + 'FILTROS.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosAjusteStock'
})(FiltrosAjusteStock))

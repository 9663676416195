import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import {Scatter} from 'react-chartjs-2';
import 'chartjs-plugin-trendline';
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import ModalAnadirMuestrasPage from '../../containers/panelControl/ModalAnadirMuestrasPage'
import ModalAnadirVerracosPage from '../../containers/panelControl/ModalAnadirVerracosPage'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import {number_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalRectaColorimetro extends Component {
  constructor () {
    super()
    this.state = {
      checkTodosSelected: false,
      mostrarInsertarColorimetro: true,
      mostrarInsertarMuestras: false,
      valorR: true,
      muestrasAnadidas: [],
      verracosAnadidos: [],
      checkTodosMuestrasSelected: false,
      checkTodosVerracosSelected: false,
      formula: 'Y= ax + (b)',
      formulaR: ''
    }
  }
  componentDidMount() {
    this.props.change('colomitroOmuestras', {label: this.props.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1})
  }

  componentDidUpdate (prevProps) {
    if (this.props.muestrasAnadidas !== prevProps.muestrasAnadidas){
      this.setState({muestrasAnadidas: this.props.muestrasAnadidas})
    }
    if (this.props.formula !== prevProps.formula) {
      this.setState({formula: this.props.formula})
    }
    if (this.props.formulaR !== prevProps.formulaR) {
      this.setState({formulaR: this.props.formulaR})
    }
    if (Object.keys(this.props.dataRectaColorimetro).length > 0){
      if (this.props.dataRectaColorimetro !== prevProps.dataRectaColorimetro){
        this.setState({muestrasAnadidas: this.props.dataRectaColorimetro.muestras})
        this.setState({verracosAnadidos: this.props.dataRectaColorimetro.verracos})
        this.props.actions.anadirMuestra(this.props.dataRectaColorimetro.muestras)
        this.props.actions.anadirVerracos(this.props.dataRectaColorimetro.verracos)
      }
      if (Object.keys(this.props.dataRectaColorimetro.muestras).length > 0){
        if (this.props.dataRectaColorimetro.muestras !== prevProps.dataRectaColorimetro.muestras) {
          this.setState({mostrarInsertarColorimetro: false})
          this.setState({mostrarInsertarMuestras: true})
        }
      } else {
        if (this.props.dataRectaColorimetro.muestras !== prevProps.dataRectaColorimetro.muestras) {
          this.setState({mostrarInsertarColorimetro: true})
          this.setState({mostrarInsertarMuestras: false})
        }
      }
    }

    if (Object.keys(this.props.dataDuplicarRectaColorimetro).length > 0) {
      if (this.props.dataDuplicarRectaColorimetro !== prevProps.dataDuplicarRectaColorimetro){
        this.setState({muestrasAnadidas: this.props.dataDuplicarRectaColorimetro.muestras})
        this.setState({verracosAnadidos: this.props.dataDuplicarRectaColorimetro.verracos})
        this.props.actions.anadirMuestra(this.props.dataDuplicarRectaColorimetro.muestras)
        this.props.actions.anadirVerracos(this.props.dataDuplicarRectaColorimetro.verracos)
      }
      if (Object.keys(this.props.dataDuplicarRectaColorimetro.muestras).length > 0){
        if (this.props.dataDuplicarRectaColorimetro.muestras !== prevProps.dataDuplicarRectaColorimetro.muestras) {
          this.setState({mostrarInsertarColorimetro: false})
          this.setState({mostrarInsertarMuestras: true})
        }
      } else {
        if (this.props.dataDuplicarRectaColorimetro.muestras !== prevProps.dataDuplicarRectaColorimetro.muestras) {
          this.setState({mostrarInsertarColorimetro: true})
          this.setState({mostrarInsertarMuestras: false})
        }
      }
    }
    if (this.props.verracosAnadidos !== prevProps.verracosAnadidos){
      this.setState({verracosAnadidos: this.props.verracosAnadidos})
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-verracos', false)
            this.props.change('seleccionar-todo-muestras', false)
            this.setState({checkTodosMuestrasSelected: false})
            this.setState({checkTodosVerracosSelected: false})
            this.props.actions.deSeleccionarTodosVerracosAnadidos()
            this.props.actions.deSeleccionarTodosMuestas()
          }
        }
      }
    }
  }

  submitAnadirColorimetro (values) {
    if (this.state.valorR === true){
      values.muestras = this.state.muestrasAnadidas
      values.verraco = this.state.verracosAnadidos
      if (Object.keys(this.props.dataRectaColorimetro).length > 0) {
        values.idRecta = this.props.dataRectaColorimetro.recta.idRecta
        this.props.actions.editarColorimetro(values)
      } else {
        this.props.actions.submitAnadirColorimetro(values)
      }
    }
    this.props.actions.cerrarModalAnadirColorimetro()
    this.setState({mostrarInsertarColorimetro: true})
    this.setState({mostrarInsertarMuestras: false})
  }

  validarR (value, prevVal) {
    if ((parseInt(prevVal, 10) >=0 && parseInt(prevVal, 10) <=1) || prevVal === ''){
      this.setState({valorR: true})
    } else {
      this.setState({valorR: false})
    }
  }

  seleccionarColorimetoOmuestras (value) {
    if (value.value === 1) {
      this.setState({mostrarInsertarColorimetro: true})
      this.setState({mostrarInsertarMuestras: false})
    } else {
      this.setState({mostrarInsertarColorimetro: false})
      this.setState({mostrarInsertarMuestras: true})
    }
  }

  abrirModalAnadirMuestras () {
    this.props.actions.abrirModalAnadirMuestras()
  }

  cerrarModalAnadirColorimetro () {
    this.props.actions.reiniciarMuestraVerraco()
    this.props.actions.cerrarModalAnadirColorimetro()
    this.props.change('colomitroOmuestras', {label: this.props.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1})
    this.props.change('nombre', '')
    this.props.change('predeterminado', '')
    this.props.change('y', '')
    this.props.change('x', '')
    this.props.change('r', '')
    this.setState({mostrarInsertarColorimetro: true})
    this.setState({mostrarInsertarMuestras: false})
  }

  abrirModalAnadirVerracos () {
    this.props.actions.abrirModalAnadirVerracos()
  }

  intercambiarCheckVerracosAnadidos (value) {
    this.props.change('seleccionar-todo-muestras', false)
    this.setState({ checkTodosVerracosSelected: value })
    this.setState({ checkTodosMuestrasSelected: false })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracosAnadidos()
    } else {
      this.props.actions.deSeleccionarTodosVerracosAnadidos()
    }
  }

  intercambiarCheckMuestras (value) {
    this.props.change('seleccionar-todo-verracos', false)
    this.setState({ checkTodosMuestrasSelected: value })
    this.setState({ checkTodosVerracosSelected: false })
    if (value === true) {
      this.props.actions.seleccionarTodosMuestas()
    } else {
      this.props.actions.deSeleccionarTodosMuestas()
    }
  }

  deleteSeleccionMuestras () {
    const itemsSeleccion = this.state.muestrasAnadidas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'concentracionEyaculado': row.concentracionEyaculado,
          'absorbancia': row.absorbancia

        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item !== false
    })
    this.props.actions.editMuestrasAnadidasSuccess(list)
    this.props.actions.deSeleccionarTodosMuestas()
  }

  deleteSeleccionVerracos () {
    const itemsSeleccion = this.state.verracosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'idVerraco': row.idVerraco,
          'codVerraco':row.codVerraco,
          'nombreRaza':row.nombreRaza,
          'nombreLinea':row.nombreLinea,
          'ubicacionverraco':row.ubicacionverraco,
          'index':row.index,
          'estadoverraco':row.estadoverraco

        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item !== false
    })
    this.props.actions.editarVerracos(list)
    this.props.actions.deSeleccionarTodosVerracosAnadidos()
  }

  render () {
    const {
      t, handleSubmit, showModalAnadirColorimetro, checkedColorimetro, listMuestrasColorimetro, numeroRegistrosPorPagina, paginaActual, checkedVerracosAnadidos, listVerracosAnadidos,
      dataRectaColorimetro, dataDuplicarRectaColorimetro, checkedMuestras, auth:{separadorDec, separadorMil},
      actions: {cerrarModalAnadirColorimetro, recuperarDatosRectaColorimetro, recuperarDatosMuestras, duplicarDatosModalMuestras, onDeleteMuestras, onDeleteVerracos}
    } = this.props

    // Calculos/configuración necesarios para la gráfica de regresión sobre las muestras de verracos.
    let scatterChartMuestras = []
    this.state.muestrasAnadidas.forEach((row, idRow) => {
      scatterChartMuestras.push({x: parseFloat(row.absorbancia), y: parseFloat(row.concentracionEyaculado)})
      return row.idElemento = idRow + 1 + 'muestra'
    })

    let absorbanciaTicks = {
      suggestedMin: 0,
      suggestedMax: 0,
      distance: 0
    }
    let concentracionTicks = {
      suggestedMin: 0,
      suggestedMax: 0,
      distance: 0
    }
    scatterChartMuestras.forEach((pointData) => {
      absorbanciaTicks.suggestedMin = Math.min(pointData.x, absorbanciaTicks.suggestedMin)
      absorbanciaTicks.suggestedMax = Math.max(pointData.x, absorbanciaTicks.suggestedMax)
      concentracionTicks.suggestedMin = Math.min(pointData.y, concentracionTicks.suggestedMin)
      concentracionTicks.suggestedMax = Math.max(pointData.y, concentracionTicks.suggestedMax)
    })

    // Incrementar el inicio/fin de la grafica con el fin de centrar la linea de regresión.
    absorbanciaTicks.distance = Math.abs(absorbanciaTicks.suggestedMin) + Math.abs(absorbanciaTicks.suggestedMax)
    absorbanciaTicks.suggestedMin -= absorbanciaTicks.distance / 12
    absorbanciaTicks.suggestedMax += absorbanciaTicks.distance / 12
    concentracionTicks.distance = Math.abs(concentracionTicks.suggestedMin) + Math.abs(concentracionTicks.suggestedMax)
    concentracionTicks.suggestedMin -= concentracionTicks.distance / 12
    concentracionTicks.suggestedMax += concentracionTicks.distance / 12

    const tableAnadirMuestras = {
      id: 'tipoDiluyenteTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1),
      mostrarCambioEstado: false,
      // noBlockOptions: false,
      tablaModal: true,
      idBlockOptions: 2,
      columns: [
        {id: 'absorbancia', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.ABSORBANCIA'), type:'numero', numDecimales: 3},
        {id: 'concentracionEyaculado', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.CONCENTRACION'), type:'numero', numDecimales: 4}
      ],
      rows: this.state.muestrasAnadidas,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1),
      onEdit: (row, idRow) => recuperarDatosMuestras(row.idElemento),
      duplicar: (row, idRow) =>duplicarDatosModalMuestras(row.idElemento),
      onDelete: (row) => onDeleteMuestras(row.idElemento),
      initialValues: {}
    }
    tableAnadirMuestras.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableAnadirMuestras.initialValues['check' + row.idElemento] = checkedMuestras
        }
      }
    )

    // TABLA VERRACOS
    this.state.verracosAnadidos.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'verraco'
    )

    const tableVerracosAnadidos = {
      id: 'tableVerracosAnadidos',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1),
      // noBlockOptions: false,
      tablaModal: true,
      idBlockOptions: 1,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: this.state.verracosAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracos(row.idElemento),
      initialValues: {},
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }
    tableVerracosAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracosAnadidos.initialValues['check' + row.idElemento] = checkedVerracosAnadidos
        }
      }
    )

    const comboColomitroOmuestras = [
      {label: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1},
      {label: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_MUESTRAS'), value: 2}
    ]

    return (
      <Modal show={showModalAnadirColorimetro} onHide={() => this.cerrarModalAnadirColorimetro()} aria-labelledby="contained-modal-title-lg" className="modal-anadir-colorimetro" backdrop="static">
      <form className="form-anadir-colorimetro">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(dataRectaColorimetro).length > 0 ? (
              t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_EDITAR')
            ) : (
              Object.keys(dataDuplicarRectaColorimetro).length > 0 ? (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_DUPLICAR')
              ) : (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          {
            Object.keys(dataRectaColorimetro).length === 0 && Object.keys(dataDuplicarRectaColorimetro).length === 0 ? (
              <Field
                  colSm={4}
                  id="colomitroOmuestras"
                  name="colomitroOmuestras"
                  controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.COLORIMETRO_MUESTRAS')}
                  component={InputSelect}
                  options={comboColomitroOmuestras}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={(value) => this.seleccionarColorimetoOmuestras(value)}
                  isClearable={false}
                />
              ) : null
            }
            <Field
              colSm={3}
              id="nombre"
              name="nombre"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.NOMBRE')}
              component={InputText}
              customClass='nombre'
              validate={required}
            />
            <Field
              colSm={2}
              id="predeterminado"
              name="predeterminado"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.PREDETERMINADO')}
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
            />
          </Row>
          <hr/>
          <Row className={this.state.mostrarInsertarMuestras === true ? 'mostrar-campos-colorimetro' : null}>
            <Col sm={2} style={{width: '52px', padding: '7px 0px 0 30px'}}><span>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.Y')}</span></Col>
            <Field
              colSm={2}
              id="y"
              name="y"
              // controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.Y')}
              component={InputNumerical}
              customClass='y'
            />
            <Col sm={2} style={{width: '47px', padding: '7px 0px 0 0',textAlign: 'right'}}><span>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.X')}</span></Col>
            <Field
              colSm={2}
              id="x"
              name="x"
              // controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.X')}
              component={InputNumerical}
              customClass='x'
            />
            <Col sm={2} style={{width: '52px', padding: '6px 0px 26px 23px', borderLeft: '1px solid #d1d1d1', marginLeft: '22px'}}><span>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.R')}</span></Col>
            <Field
              colSm={4}
              id="r"
              name="r"
              // controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.R')}
              component={InputNumerical}
              customClass='r'
              onChange={(value, prevVal) => this.validarR(value, prevVal)}
              textoValorR={true}
              validarTextoValorR={this.state.valorR}
              t={t}
            />
          </Row>
          <Row className={this.state.mostrarInsertarColorimetro === true ? 'mostrar-campos-muestras' : null}>
            <Col sm={9} className="container-tabla-anadir-colorimetro">
              <h4>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MUESTRAS')}</h4>
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1) && (
                <div id="block-options2" className={this.state.checkTodosMuestrasSelected === true ? 'filtros-tabla-anadir-colorimetro' : 'filtros-tabla-anadir-colorimetro oculta-action'}>
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todo-muestras"
                      name="seleccionar-todo-muestras"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckMuestras(value)}
                      colSm={1}
                    />
                    <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionMuestras()}>
                      <Delete /> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                    <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary nuevo" onClick={() => this.abrirModalAnadirMuestras()}>
                      <AddCircleOutline /> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                  </div>
                </div>
              )}
              <ListadoPage noTitle={true} table={tableAnadirMuestras}>
              </ListadoPage>
              <ModalAnadirMuestrasPage />
            </Col>
            <Col sm={3}>
              <h4>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.FORMULA')}</h4>
              <p><b>{this.props.formula}</b></p>
              <p><b>{this.props.formulaR}</b></p>
            </Col>
          </Row>
          <hr />
          {scatterChartMuestras && scatterChartMuestras.length > 0 && [
            <Row key="row-muestras-chart">
              <Col sm={12}>
                <Scatter
                  data={{
                    datasets: [
                      {
                        data: scatterChartMuestras,
                        borderColor: "#FF6D29",
                        pointBackgroundColor: "#FF6D29",
                        fill: false,
                        pointRadius: 5,
                        pointHoverRadius: 7,
                        trendlineLinear: {
                          style: "#000",
                          lineStyle: "line",
                          width: 3
                        }
                      }
                    ]
                  }}
                  width={200}
                  height={200}
                  options={{
                    legend: {
                      display: false
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      labels: false
                    },
                    scales: {
                      yAxes: [{
                        type: 'linear', position: 'left', display: true,
                        gridLines: {display: true, drawOnChartArea: false, drawTicks: true},
                        scaleLabel: {
                          labelString: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.CONCENTRACION'),
                          fontFamily: "'opens-sans', sans-serif",
                          display: true
                        },
                        ticks: {
                          fontFamily: "'opens-sans', sans-serif",
                          display: true,
                          minor: {enabled: false},
                          major : {enabled: true},
                          beginAtZero: true,
                          callback: function(value) {
                            return number_formatter(value, 2, separadorDec, separadorMil)
                          },
                          //...concentracionTicks
                        }
                      }],
                      xAxes: [{
                        type: 'linear', position: 'bottom', display: true,
                        gridLines: {display: true, drawOnChartArea: false, drawTicks: true},
                        scaleLabel: {
                          labelString: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.ABSORBANCIA'),
                          fontFamily:  "'opens-sans', sans-serif",
                          display: true
                        },
                        ticks: {
                          fontFamily: "'opens-sans', sans-serif",
                          display: true,
                          minor: {enabled: false},
                          major : {enabled: true},
                          beginAtZero: true,
                          stepSize: 0.2,
                          callback: function(value) {
                            return number_formatter(value, 2, separadorDec, separadorMil)
                          },
                          // ...absorbanciaTicks
                        }
                      }],
                    },
                    tooltips: {
                      enabled: true,
                      displayColors: false,
                      bodyFontFamily: "'opens-sans', sans-serif",
                      callbacks: {
                        label: function(tooltipItem, data) {
                          let label = `${t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.CONCENTRACION')}: ` + number_formatter((Math.round(tooltipItem.yLabel * 100) / 100), 4, separadorDec, separadorMil)
                          label += `, ${t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TABLA.ABSORBANCIA')}: ` + number_formatter((Math.round(tooltipItem.xLabel * 100) / 100), 3, separadorDec, separadorMil)
                          return label
                        }
                      }
                    }
                  }}
                />
              </Col>
            </Row>,
            <hr key="line-separator-muestras-chart" />
          ]}
          <Row>
            <Col sm={12} className="container-tabla-anadir-colorimetro">
              <h4>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.ASIGNAR_VERRACOS')}</h4>
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1) && (
                <div id="block-options1" className={this.state.checkTodosVerracosSelected === true ? 'filtros-tabla-anadir-colorimetro' : 'filtros-tabla-anadir-colorimetro oculta-action'}>
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todo-verracos"
                      name="seleccionar-todo-verracos"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckVerracosAnadidos(value)}
                      colSm={1}
                    />
                    <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionVerracos()}>
                      <Delete /> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                    <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary nuevo" onClick={() => this.abrirModalAnadirVerracos()}>
                      <AddCircleOutline /> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                  </div>
                </div>
              )}
              <ListadoPage noTitle={true} table={tableVerracosAnadidos}>
              </ListadoPage>
              <ModalAnadirVerracosPage />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] < 2) ? ' disabled' : '')}
            onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1) ? handleSubmit(this.submitAnadirColorimetro.bind(this)) : undefined}
          >{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalAnadirColorimetro()}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRectaColorimetro',
})(ModalRectaColorimetro))
export default {
  COMPROBAR_STOCK_TRAZABILIDAD: 'COMPROBAR_STOCK_TRAZABILIDAD',
  COMPROBAR_STOCK_TRAZABILIDAD_SUCCESS: 'COMPROBAR_STOCK_TRAZABILIDAD_SUCCESS',
  UPDATE_STOCK_INSUFICIENTE: 'UPDATE_STOCK_INSUFICIENTE',
  UPDATE_STOCK_INSUFICIENTE_SUCCESS: 'UPDATE_STOCK_INSUFICIENTE_SUCCESS',
  OBTENER_LOTES_ESCANDALLO_ANALISIS: 'OBTENER_LOTES_ESCANDALLO_ANALISIS',
  OBTENER_LOTES_ESCANDALLO_ENVASADO: 'OBTENER_LOTES_ESCANDALLO_ENVASADO',
  UPDATE_LOTES_ESCANDALLO: 'UPDATE_LOTES_ESCANDALLO',
  UPDATE_LOTES_ESCANDALLO_SUCCESS: 'UPDATE_LOTES_ESCANDALLO_SUCCESS',
  OPEN_MODAL_TRAZABILIDAD_MATERIAL: 'OPEN_MODAL_TRAZABILIDAD_MATERIAL',
  OPEN_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS: 'OPEN_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS',
  GUARDAR_MODAL_TRAZABILIDAD_MATERIAL: 'GUARDAR_MODAL_TRAZABILIDAD_MATERIAL',
  GUARDAR_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS: 'GUARDAR_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS',
  CLOSE_MODAL_TRAZABILIDAD_MATERIAL: 'CLOSE_MODAL_TRAZABILIDAD_MATERIAL',
  CLOSE_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS: 'CLOSE_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS',
  SELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL: 'SELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL',
  DESELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL: 'DESELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL',
  DELETE_TRAZABILIDAD_MATERIAL: 'DELETE_TRAZABILIDAD_MATERIAL',
  DELETE_SELECCION_TRAZABILIDAD_MATERIAL: 'DELETE_SELECCION_TRAZABILIDAD_MATERIAL',
  OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL: 'OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL',
  OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS: 'OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS',
  GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL: 'GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL',
  GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS: 'GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS',
  CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL: 'CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL',
  CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS: 'CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS',
  RESET_UTILIZAR_LOTES_PREFERIDOS_DEL_USUARIO_SUCCESS: 'RESET_UTILIZAR_LOTES_PREFERIDOS_DEL_USUARIO_SUCCESS',
  ACTUALIZAR_TRAZABILIDAD_MATERIAL: 'ACTUALIZAR_TRAZABILIDAD_MATERIAL',
}
import actionTypes from '../../constants/actions/producto/producto'

export function initialState () {
  return {
    listProducto: [],
    checkedFiltrosProducto: false,
    showModalProducto: false,
    filtros: {},
    dataProductos: [],
    dataDuplicarProductos: [],
    datosExcelProducto: [],
    datosPdfProducto: [],
    listEntrada: []
  }
}

export function abrirModalProducto (state) {
  return {
    ...state,
    showModalProducto: true
  }
}

export function closeModalProducto (state) {
  return {
    ...state,
    showModalProducto: false,
    dataProductos: [],
    dataDuplicarProductos: []
  }
}

export function filtrarProductoSuccess (state, {listProducto}) {
  return {
    ...state,
    listProducto: listProducto
  }
}

export function filtrosProductoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function recuperarDatosModalProductoSuccess (state, {dataProductos}) {
  return {
    ...state,
    dataProductos: dataProductos,
    showModalProducto: true,
    dataDuplicarProductos: []
  }
}

export function duplicarDatosModalProductoSuccess (state, {dataDuplicarProductos}) {
  return {
    ...state,
    dataDuplicarProductos: dataDuplicarProductos,
    showModalProducto: true,
    dataProductos: []
  }
}

export function seleccionarTodosProducto (state) {
  return {
    ...state,
    checkedFiltrosProducto: true
  }
}

export function deSeleccionarTodosProducto (state) {
  return {
    ...state,
    checkedFiltrosProducto: false
  }
}

export function crearCsvProductoSuccess (state, {datosExcelProducto}) {
  return {
    ...state,
    datosExcelProducto
  }
}

export function imprimirPdfProductoSuccess (state, {datosPdfProducto}) {
  return {
    ...state,
    datosPdfProducto
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_PRODUCTO:
      return abrirModalProducto(state, action)
    case actionTypes.CLOSE_MODAL_PRODUCTO:
      return closeModalProducto(state, action)
    case actionTypes.FILTRAR_PRODUCTO_SUCCESS:
      return filtrarProductoSuccess(state, action)
    case actionTypes.FILTROS_PRODUCTO_SUCCESS:
      return filtrosProductoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_PRODUCTO_SUCCESS:
      return recuperarDatosModalProductoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_PRODUCTO_SUCCESS:
      return duplicarDatosModalProductoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_PRODUCTO:
      return seleccionarTodosProducto(state, action)
    case actionTypes.DESELECCIONAR_TODOS_PRODUCTO:
      return deSeleccionarTodosProducto(state, action)
    case actionTypes.CREATE_CSV_PRODUCTO_SUCCESS:
      return crearCsvProductoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_PRODUCTO_SUCCESS:
      return imprimirPdfProductoSuccess(state, action)
    default:
      return state
  }
}
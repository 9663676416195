import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosTipoIntervencionSanitaria from '../../../components/planSanitario/tipoIntervencionSanitaria/FiltrosTipoIntervencionSanitaria'
import {openModal} from '../../../actions/common'
import {abrirModalVacioTipoIntervencionSanitaria, seleccionarTodosTipoIntervencionSanitaria, deSeleccionarTodosTipoIntervencionSanitaria, cambiarEstadoSeleccionTipoIntervencionSanitaria,
  deleteSeleccionTipoIntervencionSanitaria, crearCsvTipoIntervencion, imprimirPdfTipoIntervencion} from '../../../actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'

export function mapStateToProps (state) {
  return {
    ...state.tipoIntervencionSanitaria,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioTipoIntervencionSanitaria,
      seleccionarTodosTipoIntervencionSanitaria,
      deSeleccionarTodosTipoIntervencionSanitaria,
      cambiarEstadoSeleccionTipoIntervencionSanitaria,
      deleteSeleccionTipoIntervencionSanitaria,
      crearCsvTipoIntervencion,
      imprimirPdfTipoIntervencion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosTipoIntervencionSanitaria))
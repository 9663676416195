import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Transporte from '../../components/transporte/Transporte'
import { filtrarTransporte, cambiarEstadoTransporte, onDeleteTransporte, reiniciarConductoresVehiculosRutas } from '../../actions/transporte/transporte'

export function mapStateToProps (state) {
  return {
    transporte: {...state.transporte},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      filtrarTransporte,
      cambiarEstadoTransporte,
      onDeleteTransporte,
      reiniciarConductoresVehiculosRutas
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Transporte))

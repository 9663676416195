import actionTypes from '../../constants/actions/conectividad/conectividad'

export function fetchConectividad () {
  return {
    type: actionTypes.FETCH_CONECTIVIDAD
  }
}

export function fetchConectividadSuccess (listConectividad) {
  return {
    type: actionTypes.FETCH_CONECTIVIDAD_SUCCESS,
    listConectividad
  }
}

export function submitNuevaConectividad (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_CONECTIVIDAD,
    values
  }
}

export function submitNuevaConectividadSuccess (insercionConectividad) {
  return {
    type: actionTypes.SUBMIT_NUEVA_CONECTIVIDAD_SUCCESS,
    insercionConectividad
  }
}

export function recuperarDatosModalConectividad (idConectividad) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONECTIVIDAD,
    idConectividad
  }
}

export function recuperarDatosModalConectividadSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS,
    data
  }
}

export function abrirModalVacioConectividad () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_CONECTIVIDAD
  }
}

export function closeModalConectividad () {
  return {
    type: actionTypes.CLOSE_MODAL_CONECTIVIDAD
  }
}

export function editarConectividad (values) {
  return {
    type: actionTypes.EDITAR_CONECTIVIDAD,
    values
  }
}

export function editarConectividadSuccess (edicionConectividad) {
  return {
    type: actionTypes.EDITAR_CONECTIVIDAD_SUCCESS,
    edicionConectividad
  }
}

export function duplicarDatosModalConectividad (idConectividad) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CONECTIVIDAD,
    idConectividad
  }
}

export function duplicarDatosModalConectividadSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoConectividad (idConectividad) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CONECTIVIDAD,
    idConectividad
  }
}

export function cambiarEstadoConectividadSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CONECTIVIDAD_SUCCESS,
    dataEstado
  }
}

export function onDeleteConectividad (idConectividad) {
  return {
    type: actionTypes.ON_DELETE_CONECTIVIDAD,
    idConectividad
  }
}

export function onDeleteConectividadSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_CONECTIVIDAD_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarConectividad (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_CONECTIVIDAD,
    values
  }
}

export function fetchFiltrarConectividadSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_CONECTIVIDAD_SUCCESS,
    filtros
  }
}

export function seleccionarTodosConectividad(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_CONECTIVIDAD
  }
}

export function deSeleccionarTodosConectividad(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CONECTIVIDAD
  }
}

export function cambiarEstadoSeleccionConectividad(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_CONECTIVIDAD,
    list
  }
}

export function deleteSeleccionConectividad(list){
  return {
    type: actionTypes.DELETE_SELECCION_CONECTIVIDAD,
    list
  }
}

export function crearCsvConectividad(list){
  return {
    type: actionTypes.CREATE_CSV_CONECTIVIDAD,
    list
  }
}

export function crearCsvConectividadSuccess(datosExcelConectividad){
  return {
    type: actionTypes.CREATE_CSV_CONECTIVIDAD_SUCCESS,
    datosExcelConectividad
  }
}

export function imprimirPdfConectividad(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CONECTIVIDAD,
    list
  }
}

export function imprimirPdfConectividadSuccess(datosPdfConectividad){
  return {
    type: actionTypes.IMPRIMIR_PDF_CONECTIVIDAD_SUCCESS,
    datosPdfConectividad
  }
}

export function guardarYnuevoConectividad(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_CONECTIVIDAD,
    values
  }
}

export function actualizarConectividadVpn(values){
  return {
    type: actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN,
    values
  }
}

export function actualizarConectividadVpnSuccess(dataVpn){
  return {
    type: actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN_SUCCESS,
    dataVpn
  }
}

export function actualizarConectividadVpnVacio(values){
  return {
    type: actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN_VACIO,
    values
  }
}

export function obternerConectividadVpn(values){
  return {
    type: actionTypes.OBTENER_CONECTIVIDAD_VPN,
    values
  }
}

export function probarConexionVpn(values){
  return {
    type: actionTypes.PROBAR_CONEXION_VPN,
    values
  }
}

export function probarConexionVpnSuccess(purebaConexionVpn){
  return {
    type: actionTypes.PROBAR_CONEXION_VPN_SUCCESS,
    purebaConexionVpn
  }
}

export default {
  fetchConectividad,
  fetchConectividadSuccess,
  submitNuevaConectividad,
  submitNuevaConectividadSuccess,
  recuperarDatosModalConectividad,
  recuperarDatosModalConectividadSuccess,
  abrirModalVacioConectividad,
  closeModalConectividad,
  editarConectividad,
  editarConectividadSuccess,
  duplicarDatosModalConectividad,
  duplicarDatosModalConectividadSuccess,
  cambiarEstadoConectividad,
  cambiarEstadoConectividadSuccess,
  onDeleteConectividad,
  onDeleteConectividadSuccess,
  fetchFiltrarConectividad,
  fetchFiltrarConectividadSuccess,
  seleccionarTodosConectividad,
  deSeleccionarTodosConectividad,
  cambiarEstadoSeleccionConectividad,
  deleteSeleccionConectividad,
  crearCsvConectividad,
  crearCsvConectividadSuccess,
  guardarYnuevoConectividad,
  imprimirPdfConectividad,
  imprimirPdfConectividadSuccess,
  actualizarConectividadVpn,
  actualizarConectividadVpnSuccess,
  obternerConectividadVpn,
  actualizarConectividadVpnVacio,
  probarConexionVpn,
  probarConexionVpnSuccess
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import PICTraq from '../../components/PICTraq/PICTraq'
import { insertPoolPIC, fetchAnalisisParaPIC, fetchPICTraits ,insertAnalisisPIC, resetAnalisisPIC, saveDataPIC, saveDataPoolPIC,
        getUrlPIC, sincronizarIndexPIC, fetchPoolSplitsParaPIC, saveDataSplitsPIC, insertSplitsPIC } from '../../actions/PICTraq/PICTraq'
import { getFormValues } from 'redux-form'

export function mapStateToProps (state) {
  return {
    ...state.PICTraq,
    auth: state.auth,
    isLoading: state.common.isLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAnalisisParaPIC,
      fetchPICTraits,
      saveDataPoolPIC,
      saveDataSplitsPIC,
      fetchPoolSplitsParaPIC,
      insertAnalisisPIC,
      resetAnalisisPIC,
      insertPoolPIC,
      insertSplitsPIC,
      saveDataPIC,
      getUrlPIC,
      sincronizarIndexPIC
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PICTraq))
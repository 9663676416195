import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { submitActualizarAplicacion, comprobarEntorno, subirArchivosActualizarAplicacion } from '../../actions/auth/auth'
import ActualizarAplicacion from '../../components/auth/ActualizarAplicacion'

const mapStateToProps = (state, props) => {
  return {
    auth: {
      desautorizado: state.auth.desautorizado,
      expiredSession: state.auth.expiredSession,
      licenciaExpirada: state.auth.licenciaExpirada,
      isCloud: state.auth.isCloud,
      numeroSerie: state.auth.numeroSerie,
      archivo: state.auth.archivo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitActualizarAplicacion,
      comprobarEntorno,
      subirArchivosActualizarAplicacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActualizarAplicacion))
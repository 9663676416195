import actionTypes from '../../constants/actions/informes/informes'

// INFORME EXTRACCIONES
export function obtenerInformeExtracciones (values) {
  return {
    type: actionTypes.OBTENER_INFORME_EXTRACCIONES,
    values
  }
}
export function obtenerInformeExtraccionesSuccess (listInformeExtracciones) {
  return {
    type: actionTypes.OBTENER_INFORME_EXTRACCIONES_SUCCESS,
    listInformeExtracciones
  }
}
export function fetchFiltrosSuccess (filtrosExtracciones) {
  return {
    type: actionTypes.FETCH_FILTROS_SUCCESS,
    filtrosExtracciones
  }
}

export function imprimirPdfInformesExtracciones (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_EXTRACCIONES,
    filtros
  }
}

export function imprimirPdfInformesExtraccionesSuccess (datosPdfInformesExtracciones) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_EXTRACCIONES_SUCCESS,
    datosPdfInformesExtracciones
  }
}

export function crearCsvInformeExtracciones (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORMES_EXTRACCIONES,
    filtros
  }
}

// INFORME STOCK DOSIS
export function obtenerInformeStockDosis (values) {
  return {
    type: actionTypes.OBTENER_INFORME_STOCK_DOSIS,
    values
  }
}
export function obtenerInformeStockDosisSuccess (listInformeStockDosis) {
  return {
    type: actionTypes.OBTENER_INFORME_STOCK_DOSIS_SUCCESS,
    listInformeStockDosis
  }
}
export function fetchFiltrosStockDosisSuccess (filtrosStockDosis) {
  return {
    type: actionTypes.FETCH_FILTROS_STOCK_DOSIS_SUCCESS,
    filtrosStockDosis
  }
}

export function imprimirPdfInformesStockDosis (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_STOCK_DOSIS,
    filtros
  }
}

export function imprimirPdfInformesStockDosisSuccess (datosPdfInformesStockDosis) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_STOCK_DOSIS_SUCCESS,
    datosPdfInformesStockDosis
  }
}

export function crearCsvInformeStockDosis (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORMES_STOCK_DOSIS,
    filtros
  }
}

// INFORME POOL
export function obtenerInformePool (values) {
  return {
    type: actionTypes.OBTENER_INFORME_POOL,
    values
  }
}
export function obtenerInformePoolSuccess (listInformePool) {
  return {
    type: actionTypes.OBTENER_INFORME_POOL_SUCCESS,
    listInformePool
  }
}
export function fetchFiltrosPoolSuccess (filtrosPool) {
  return {
    type: actionTypes.FETCH_FILTROS_POOL_SUCCESS,
    filtrosPool
  }
}

export function imprimirPdfInformesPool (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_POOL,
    filtros
  }
}

export function imprimirPdfInformesPoolSuccess (datosPdfInformesPool) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_POOL_SUCCESS,
    datosPdfInformesPool
  }
}

export function crearCsvInformePool (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORMES_POOL,
    filtros
  }
}

// INFORME PRODUCTIVIDAD EXTRACCIONES
export function obtenerInformeProductividadExtracciones (values) {
  return {
    type: actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES,
    values
  }
}
export function obtenerInformeProductividadExtraccionesSuccess (listInformeProductividadExtracciones) {
  return {
    type: actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS,
    listInformeProductividadExtracciones
  }
}
export function fetchFiltrosProductividadExtraccionesSuccess (filtrosProductividadExtracciones) {
  return {
    type: actionTypes.FETCH_FILTROS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS,
    filtrosProductividadExtracciones
  }
}
export function abrirModalProductividadVerracos () {
  return {
    type: actionTypes.ABRIR_MODAL_PRODUCTIVIDAD_VERRACOS
  }
}
export function cerrarModalProductividadVerracos () {
  return {
    type: actionTypes.CERRAR_MODAL_PRODUCTIVIDAD_VERRACOS
  }
}
export function abrirModalRecomendacionesMatadero () {
  return {
    type: actionTypes.ABRIR_MODAL_RECOMENDACIONES_MATADERO
  }
}
export function cerrarModalRecomendacionesMatadero () {
  return {
    type: actionTypes.CERRAR_MODAL_RECOMENDACIONES_MATADERO
  }
}

export function imprimirPdfInformesProductividadExtracciones (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES,
    filtros
  }
}

export function imprimirPdfInformesProductividadExtraccionesSuccess (datosPdfInformesProductividadExtracciones) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS,
    datosPdfInformesProductividadExtracciones
  }
}

export function crearCsvInformeProductividadExtracciones (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES,
    filtros
  }
}

    /*  MOLDAL VERRACOS DE PRODUCTIVIDAD EXTRACIONES   */

export function obtenerVerracosProductividadExtracciones (values) {
  return {
    type: actionTypes.OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES,
    values
  }
}

export function obtenerVerracosProductividadExtraccionesSuccess (listVerracosProductividad) {
  return {
    type: actionTypes.OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS,
    listVerracosProductividad
  }
}

export function obtenerRecomendacionesMatadero (values) {
  return {
    type: actionTypes.OBTENER_RECOMENDACIONES_MATADERO,
    values
  }
}

export function obtenerRecomendacionesMataderoSuccess (listRecomendacionesMatadero) {
  return {
    type: actionTypes.OBTENER_RECOMENDACIONES_MATADERO_SUCCESS,
    listRecomendacionesMatadero
  }
}

export function crearCsvInformeProductividadExtraccionesVerraco (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_VERRACO,
    filtros
  }
}

export function imprimirPdfInformesProductividadExtraccionesVerraco (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_VERRACO,
    filtros
  }
}

export function crearCsvInformeProductividadExtraccionesMatadero (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_MATADERO,
    filtros
  }
}

export function imprimirPdfInformesProductividadExtraccionesMatadero (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_MATADERO,
    filtros
  }
}

// INFORME SEGUIMIENTO EXTRACCIONES/POOL
export function obtenerInformeSeguimientoExtraccionesPool (values) {
  return {
    type: actionTypes.OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL,
    values
  }
}
export function obtenerInformeSeguimientoExtraccionesPoolSuccess (listInformeSeguimientoExtraccionesPool) {
  return {
    type: actionTypes.OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS,
    listInformeSeguimientoExtraccionesPool
  }
}
export function fetchFiltrosSeguimientoExtraccionesPoolSuccess (filtrosSeguimientoExtraccionesPool) {
  return {
    type: actionTypes.FETCH_FILTROS_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS,
    filtrosSeguimientoExtraccionesPool
  }
}
export function obtenerRegistrosSeguimientosExtraccionesPool (values) {
  return {
    type: actionTypes.OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL,
    values
  }
}
export function obtenerRegistrosSeguimientosExtraccionesPoolSuccess (listRegistrosSeguimientoExtraccionesPool) {
  return {
    type: actionTypes.OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL_SUCCESS,
    listRegistrosSeguimientoExtraccionesPool
  }
}

export function imprimirPdfInformesSeguimientoExtraccionesPool (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL,
    filtros
  }
}

export function imprimirPdfInformesSeguimientoExtraccionesPoolSuccess (datosPdfInformesSeguimientoExtraccionesPool) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS,
    datosPdfInformesSeguimientoExtraccionesPool
  }
}

export function abrirModalExportarImprimirInformesSeguimientoExtraccionesPool (valor) {
  return {
    type: actionTypes.ABRIR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL,
    valor
  }
}

export function cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool (valor) {
  return {
    type: actionTypes.CERRAR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL,
    valor
  }
}

export function exportarInformeSeguimientoExtraccionesPool (filtros) {
  return {
    type: actionTypes.EXPORTAR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL,
    filtros
  }
}

// INFORME PRODUCTIVIDAD CENTRO
export function obtenerInformeProductividadCentro (values) {
  return {
    type: actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_CENTRO,
    values
  }
}
export function obtenerInformeProductividadCentroSuccess (listInformeProductividadCentro) {
  return {
    type: actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_CENTRO_SUCCESS,
    listInformeProductividadCentro
  }
}
export function fetchFiltrosProductividadCentroSuccess (filtrosProductividadCentro) {
  return {
    type: actionTypes.FETCH_FILTROS_PRODUCTIVIDAD_CENTRO_SUCCESS,
    filtrosProductividadCentro
  }
}

export function imprimirPdfInformesProductividadCentro (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO,
    filtros
  }
}

export function imprimirPdfInformesProductividadCentroSuccess (datosPdfInformesProductividadCentro) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO_SUCCESS,
    datosPdfInformesProductividadCentro
  }
}

export function crearCsvInformeProductividadCentro (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_CENTRO,
    filtros
  }
}

// INFORME TRAZABILIDAD
export function obtenerInformeTrazabilidad (values) {
  return {
    type: actionTypes.OBTENER_INFORME_TRAZABILIDAD,
    values
  }
}
export function obtenerInformeTrazabilidadSuccess (listInformeTrazabilidad) {
  return {
    type: actionTypes.OBTENER_INFORME_TRAZABILIDAD_SUCCESS,
    listInformeTrazabilidad
  }
}
export function fetchFiltrosTrazabilidadSuccess (filtrosTrazabilidad) {
  return {
    type: actionTypes.FETCH_FILTROS_TRAZABILIDAD_SUCCESS,
    filtrosTrazabilidad
  }
}

export function imprimirPdfInformesTrazabilidad (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_TRAZABILIDAD,
    filtros
  }
}

export function imprimirPdfInformesTrazabilidadSuccess (datosPdfInformesTrazabilidad) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_TRAZABILIDAD_SUCCESS,
    datosPdfInformesTrazabilidad
  }
}

export function crearCsvInformeTrazabilidad (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_TRAZABILIDAD,
    filtros
  }
}

// INFORME INTERVENCIONES
export function obtenerInformeIntervenciones (values) {
  return {
    type: actionTypes.OBTENER_INFORME_INTERVENCIONES,
    values
  }
}
export function obtenerInformeIntervencionesSuccess (listInformeIntervenciones) {
  return {
    type: actionTypes.OBTENER_INFORME_INTERVENCIONES_SUCCESS,
    listInformeIntervenciones
  }
}
export function fetchFiltrosIntervencionesSuccess (filtrosIntervenciones) {
  return {
    type: actionTypes.FETCH_FILTROS_INTERVENCIONES_SUCCESS,
    filtrosIntervenciones
  }
}

export function imprimirPdfInformesIntervenciones (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_INTERVENCIONES,
    filtros
  }
}

export function imprimirPdfInformesIntervencionesSuccess (datosPdfInformesIntervenciones) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_INTERVENCIONES_SUCCESS,
    datosPdfInformesIntervenciones
  }
}

export function crearCsvInformeIntervenciones (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_INTERVENCIONES,
    filtros
  }
}

// CLIENTE
export function obtenerInformeCliente (values) {
  return {
    type: actionTypes.OBTENER_INFORME_CLIENTE,
    values
  }
}
export function obtenerInformeClienteSuccess (listInformeCliente) {
  return {
    type: actionTypes.OBTENER_INFORME_CLIENTE_SUCCESS,
    listInformeCliente
  }
}
export function fetchFiltrosClienteSuccess (filtrosCliente) {
  return {
    type: actionTypes.FETCH_FILTROS_CLIENTE_SUCCESS,
    filtrosCliente
  }
}

export function imprimirPdfInformesClientes (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_CLIENTES,
    filtros
  }
}

export function imprimirPdfInformesClientesSuccess (datosPdfInformesClientes) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_CLIENTES_SUCCESS,
    datosPdfInformesClientes
  }
}

export function crearCsvInformeClientes (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_CLIENTES,
    filtros
  }
}

// ESTADO_CENTRO
export function obtenerInformeEstadoCentro (values) {
  return {
    type: actionTypes.OBTENER_INFORME_ESTADO_CENTRO,
    values
  }
}
export function obtenerInformeEstadoCentroSuccess (listInformeEstadoCentro) {
  return {
    type: actionTypes.OBTENER_INFORME_ESTADO_CENTRO_SUCCESS,
    listInformeEstadoCentro
  }
}
export function fetchFiltrosEstadoCentroSuccess (filtrosEstadoCentro) {
  return {
    type: actionTypes.FETCH_FILTROS_ESTADO_CENTRO_SUCCESS,
    filtrosEstadoCentro
  }
}

export function imprimirPdfInformesEstadoCentro (filtros) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORMES_ESTADO_CENTRO,
    filtros
  }
}

export function crearCsvInformesEstadoCentro (filtros) {
  return {
    type: actionTypes.CREAR_CSV_INFORMES_ESTADO_CENTRO,
    filtros
  }
}

// INFORME PERSONALIZADO
export function seleccionarTodosInformePersonalizado (filtros) {
  return {
    type: actionTypes.SELECCIONAR_TODOS_INFORME_PERSONALIZADO,
    filtros
  }
}

export function deseleccionarTodosInformePersonalizado (filtros) {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_INFORME_PERSONALIZADO,
    filtros
  }
}

export function mostrarSeleccionInformePersonalizado (list, campo) {
  return {
    type: actionTypes.MOSTRAR_SELECCION_INFORME_PERSONALIZADO,
    list,
    campo
  }
}

export function mostrarSeleccionInformePersonalizadoSuccess (datosTablaPersonalizado) {
  return {
    type: actionTypes.MOSTRAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS,
    datosTablaPersonalizado
  }
}

export function valoresTablaPersonalizadoSuccess (valoresTablaPersonalizado) {
  return {
    type: actionTypes.VALORES_TABLA_PERSONALIZADO_SUCCESS,
    valoresTablaPersonalizado
  }
}

export function abrirModalGuardarSeleccion (list, campo) {
  return {
    type: actionTypes.ABRIR_MODAL_GUARDAR_SELECCION,
    list,
    campo
  }
}

export function cerrarModalGuardarSeleccion () {
  return {
    type: actionTypes.CERRAR_MODAL_GUARDAR_SELECCION
  }
}

export function camposSeleccionadosPersonalizadoSuccess (camposSeleccionadosPersonalizado) {
  return {
    type: actionTypes.CAMPOS_SELECCIONADOS_PERSONALIZADO_SUCCESS,
    camposSeleccionadosPersonalizado
  }
}

export function submitGuardarSeleccionInformePersonalizado (values) {
  return {
    type: actionTypes.SUBMIT_GUARDAR_SELECCION_INFORME_PERSONALIZADO,
    values
  }
}

export function comboSeleccionesInformePersonalizado (entidad) {
  return {
    type: actionTypes.COMBO_SELECCIONES_INFORME_PERSONALIZADO,
    entidad
  }
}

export function comboSeleccionesInformePersonalizadoSuccess (data) {
  return {
    type: actionTypes.COMBO_SELECCIONES_INFORME_PERSONALIZADO_SUCCESS,
    data
  }
}

export function cargarSeleccionInformePersonalizado (idInformePersonalizado) {
  return {
    type: actionTypes.CARGAR_SELECCION_INFORME_PERSONALIZADO,
    idInformePersonalizado
  }
}

export function cargarSeleccionInformePersonalizadoSuccess (data) {
  return {
    type: actionTypes.CARGAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS,
    data
  }
}

export function eliminarSeleccionInformePersonalizado (values) {
  return {
    type: actionTypes.ELIMINAR_SELECCION_INFORME_PERSONALIZADO,
    values
  }
}

export function crearCsvInformePersonalizado (values, campo) {
  return {
    type: actionTypes.CREAR_CSV_INFORME_PERSONALIZADO,
    values,
    campo
  }
}

export function imprimirPdfInformePersonalizado (values, campo) {
  return {
    type: actionTypes.IMPRIMIR_PDF_INFORME_PRESONALIZADO,
    values,
    campo
  }
}

export default{
  // INFORME EXTRACCIONES
  obtenerInformeExtracciones,
  obtenerInformeExtraccionesSuccess,
  fetchFiltrosSuccess,
  imprimirPdfInformesExtracciones,
  imprimirPdfInformesExtraccionesSuccess,
  crearCsvInformeExtracciones,

  // INFORME STOCK DOSIS
  obtenerInformeStockDosis,
  obtenerInformeStockDosisSuccess,
  fetchFiltrosStockDosisSuccess,
  imprimirPdfInformesStockDosis,
  imprimirPdfInformesStockDosisSuccess,
  crearCsvInformeStockDosis,

  // INFORME POOL
  obtenerInformePool,
  obtenerInformePoolSuccess,
  fetchFiltrosPoolSuccess,
  imprimirPdfInformesPool,
  imprimirPdfInformesPoolSuccess,
  crearCsvInformePool,

  // INFORME PRODUCTIVIDAD EXTRACCIONES
  obtenerInformeProductividadExtracciones,
  obtenerInformeProductividadExtraccionesSuccess,
  fetchFiltrosProductividadExtraccionesSuccess,
  abrirModalProductividadVerracos,
  cerrarModalProductividadVerracos,
  obtenerVerracosProductividadExtracciones,
  obtenerVerracosProductividadExtraccionesSuccess,
  imprimirPdfInformesProductividadExtracciones,
  imprimirPdfInformesProductividadExtraccionesSuccess,
  crearCsvInformeProductividadExtracciones,
  obtenerRecomendacionesMatadero,
  obtenerRecomendacionesMataderoSuccess,
  crearCsvInformeProductividadExtraccionesVerraco,
  imprimirPdfInformesProductividadExtraccionesVerraco,
  crearCsvInformeProductividadExtraccionesMatadero,
  imprimirPdfInformesProductividadExtraccionesMatadero,

  // INFORME SEGUIMIENTO EXTRACCIONES/POOL
  obtenerInformeSeguimientoExtraccionesPool,
  obtenerInformeSeguimientoExtraccionesPoolSuccess,
  fetchFiltrosSeguimientoExtraccionesPoolSuccess,
  obtenerRegistrosSeguimientosExtraccionesPool,
  obtenerRegistrosSeguimientosExtraccionesPoolSuccess,
  imprimirPdfInformesSeguimientoExtraccionesPool,
  imprimirPdfInformesSeguimientoExtraccionesPoolSuccess,
  abrirModalExportarImprimirInformesSeguimientoExtraccionesPool,
  cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool,
  exportarInformeSeguimientoExtraccionesPool,

  // INFORME PRODUCTIVIDAD CENTRO
  obtenerInformeProductividadCentro,
  obtenerInformeProductividadCentroSuccess,
  fetchFiltrosProductividadCentroSuccess,
  imprimirPdfInformesProductividadCentro,
  imprimirPdfInformesProductividadCentroSuccess,
  crearCsvInformeProductividadCentro,

  // INFORME TRAZABILIDAD
  obtenerInformeTrazabilidad,
  obtenerInformeTrazabilidadSuccess,
  fetchFiltrosTrazabilidadSuccess,
  imprimirPdfInformesTrazabilidad,
  imprimirPdfInformesTrazabilidadSuccess,
  crearCsvInformeTrazabilidad,

  // INFORME INTERVENCIONES
  obtenerInformeIntervenciones,
  obtenerInformeIntervencionesSuccess,
  fetchFiltrosIntervencionesSuccess,
  imprimirPdfInformesIntervenciones,
  imprimirPdfInformesIntervencionesSuccess,
  crearCsvInformeIntervenciones,

  // CLIENTE
  obtenerInformeCliente,
  obtenerInformeClienteSuccess,
  fetchFiltrosClienteSuccess,
  imprimirPdfInformesClientes,
  imprimirPdfInformesClientesSuccess,
  crearCsvInformeClientes,

  // ESTADO_CENTRO
  obtenerInformeEstadoCentro,
  obtenerInformeEstadoCentroSuccess,
  fetchFiltrosEstadoCentroSuccess,
  imprimirPdfInformesEstadoCentro,
  crearCsvInformesEstadoCentro,

  // INFORME PERSONALIZADO
  seleccionarTodosInformePersonalizado,
  deseleccionarTodosInformePersonalizado,
  mostrarSeleccionInformePersonalizado,
  mostrarSeleccionInformePersonalizadoSuccess,
  valoresTablaPersonalizadoSuccess,
  abrirModalGuardarSeleccion,
  cerrarModalGuardarSeleccion,
  camposSeleccionadosPersonalizadoSuccess,
  submitGuardarSeleccionInformePersonalizado,
  comboSeleccionesInformePersonalizado,
  comboSeleccionesInformePersonalizadoSuccess,
  cargarSeleccionInformePersonalizado,
  cargarSeleccionInformePersonalizadoSuccess,
  eliminarSeleccionInformePersonalizado,
  crearCsvInformePersonalizado,
  imprimirPdfInformePersonalizado
}
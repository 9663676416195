import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosAgenda extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      fitrosSeleccionados: []
    }
    this.fetchAgenda = this.fetchAgenda.bind(this)
    this.abrirModalAgenda = this.abrirModalAgenda.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboOperario()
  }

  fetchAgenda(values){
    this.props.actions.fetchAgenda(values)
    this.props.actions.fetchTareasDia(values)
  }

  abrirModalAgenda(){
    this.props.actions.abrirModalAgenda()
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  quitarFiltros (value) {
    let values = this.props.agenda.filtros
    values[value] = null
    this.props.actions.fetchAgenda(values)
    this.props.actions.fetchTareasDia(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, auth: { permisos }, agenda: {filtros = {}},
    combos: {
      comboOperario
    }
  } = this.props
  const tKey = 'AGENDA.'

  let comboTipoTareasAgenda = this.props.combos.comboTipoTareasAgenda
  comboTipoTareasAgenda = comboTipoTareasAgenda.map((tipoTarea) => {
    return {...tipoTarea, label: t('COMUN.COMBOS.' + tipoTarea.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="operario"
              name="operario"
              controlLabel={t(tKey + 'FILTROS.OPERARIO')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAgenda)}
            />
            <Field
              colSm={2}
              id="tipoTarea"
              name="tipoTarea"
              controlLabel={t(tKey + 'FILTROS.TIPO_TAREA')}
              component={InputSelect}
              options={comboTipoTareasAgenda}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAgenda)}
            />
            <Fields
              names={['fechaDesde', 'fechaHasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaDesde"
              nameTo="fechaHasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchAgenda)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.operario && <span key="filtros.operario"  className="badge-dark">{t(tKey + 'FILTROS.OPERARIO') + ': ' + filtros.operario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operario')}> x </button></span>),
                    (filtros.tipoTarea && <span key="filtros.tipoTarea"  className="badge-dark">{t(tKey + 'FILTROS.TIPO_TAREA') + ': ' + filtros.tipoTarea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoTarea')}> x </button></span>),
                    (filtros.fechaDesde && filtros.fechaHasta && filtros.fechaDesde !== null && filtros.fechaHasta !== null) ? (
                      <span key="filtros.desdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaDesde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => { this.quitarFiltros('fechaDesde'); this.quitarFiltros('fechaHasta') }}> x </button></span>
                    ) : (filtros.fechaDesde && filtros.fechaDesde !== null) ? (
                      <span key="filtros.desde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaDesde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaDesde')}> x </button></span>
                    ) : (filtros.fechaHasta && filtros.fechaHasta !== null) && (
                      <span key="filtros.fechaHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaHasta')}> x </button></span>
                    )
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.AGENDA] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) ? this.abrirModalAgenda : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosAgenda'
})(FiltrosAgenda))

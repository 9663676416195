import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalRazaPage from '../../containers/raza/ModalRazaPage'
import FiltrosRazaPage from '../../containers/raza/FiltrosRazaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Raza.scss'
import {number_formatter, round_decimal} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class Raza extends Component {
  constructor () {
    super()
    this.state = {
      listRazas: [],
      totalesRaza: [],
      porcentajesRaza: null
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.raza.list !== prevProps.raza.list) {
      this.state.listRazas = this.props.raza.list.razas
      this.state.totalesRaza = this.props.raza.list.totales
      this.state.porcentajesRaza = this.props.raza.list.porcentajes
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.RAZAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarRaza({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      raza: { list, data, dataDuplicar, dataEstado, checkedFiltrosRaza, dataDelete},
      actions: {recuperarDatosModalRaza, duplicarDatosModalRaza, cambiarEstadoRaza, onDeleteRaza },
      auth: {separadorDec, separadorMil}
    } = this.props

    this.state.listRazas.forEach(
      (row) => row.idElemento = row.idRaza
    )

    const table = {
      id: 'razaTable',
      multiHeader: true,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1),
      hasOptions: true,
      columns: [
        {id: 'codRaza', name: t('RAZA.TABLA.COD_RAZA')},
        {id: 'nombreRaza', name: t('RAZA.TABLA.RAZA')},
        {id: 'cuarentena', name: t('RAZA.TABLA.CUARENTENA'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoCuarentena', name: t('RAZA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoCuarentena', name: t('RAZA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoCuarentena', name: t('RAZA.TABLA.M_EDAD'), type: 'numero'}
        ]},
        {id: 'presentado', name: t('RAZA.TABLA.PRESENTES'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoPresentado', name: t('RAZA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoPresentado', name: t('RAZA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoPresentado', name: t('RAZA.TABLA.M_EDAD'), type: 'numero'}
        ]},
        {id: 'activos', name: t('RAZA.TABLA.ACTIVOS'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoActivo', name: t('RAZA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoActivo', name: t('RAZA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoActivo', name: t('RAZA.TABLA.M_EDAD'), type: 'numero'}
        ]},
        {id: 'baja', name: t('RAZA.TABLA.BAJA'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoBaja', name: t('RAZA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoBaja', name: t('RAZA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoBaja', name: t('RAZA.TABLA.M_EDAD'), type: 'numero'}
        ]},
        {id: 'eliminado', name: t('RAZA.TABLA.ELIMINADOS'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoEliminado', name: t('RAZA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoEliminado', name: t('RAZA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoEliminado', name: t('RAZA.TABLA.M_EDAD'), type: 'numero'}
        ]}
      ],
      rows: this.state.listRazas,
      filtros: <FiltrosRazaPage />,
      onEdit: (row) => recuperarDatosModalRaza(row.idRaza),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1,
      duplicar: (row) =>duplicarDatosModalRaza(row.idRaza),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1,
      cambiarEstado: (row) => cambiarEstadoRaza(row.idRaza, row.cambiarEstado),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1,
      onDelete: (row) => onDeleteRaza(row.idRaza),
      dataDelete: dataDelete,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosRaza
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalRazaPage
          duplicar={(row) =>duplicarDatosModalRaza(row.idRaza)}
        />
        <ListadoPage t_key="RAZA." table={table}>
        </ListadoPage>
        <Row className="tablaTotales">
          <Col sm={2} className="total-verracos">
            <span>{t('RAZA.TOTALES.TOTAL_VERRACOS')}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.CUARENTENA')}: {this.state.totalesRaza.totalVerracosCuarentena ? number_formatter(this.state.totalesRaza.totalVerracosCuarentena, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.ACTIVOS')}: {this.state.totalesRaza.totalVerracosActivos ? number_formatter(this.state.totalesRaza.totalVerracosActivos, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.PRESENTES')}: {this.state.totalesRaza.totalVerracosPresentes ? number_formatter(this.state.totalesRaza.totalVerracosPresentes, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.BAJA')}: {this.state.totalesRaza.totalVerracosBaja ? number_formatter(this.state.totalesRaza.totalVerracosBaja, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('RAZA.TOTALES.ELIMINADOS')}: {this.state.totalesRaza.totalVerracosEliminados ? number_formatter(this.state.totalesRaza.totalVerracosEliminados, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
        </Row>

        <Row className="tablaTotales">
          <Col sm={2} className="total-verracos">
            <span>{t('RAZA.TOTALES.TOTALES_PORCENTAJE')}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.CUARENTENA')}: {this.state.porcentajesRaza !== null && number_formatter(round_decimal(this.state.porcentajesRaza.VerracosCuarentena, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.ACTIVOS')}: {this.state.porcentajesRaza !== null && number_formatter(round_decimal(this.state.porcentajesRaza.VerracosActivos, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.PRESENTES')}: {this.state.porcentajesRaza !== null && number_formatter(round_decimal(this.state.porcentajesRaza.VerracosPresentes, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('RAZA.TOTALES.BAJA')}: {this.state.porcentajesRaza !== null && number_formatter(round_decimal(this.state.porcentajesRaza.VerracosBaja, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('RAZA.TOTALES.ELIMINADOS')}: {this.state.porcentajesRaza !== null && number_formatter(round_decimal(this.state.porcentajesRaza.VerracosEliminados, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Raza
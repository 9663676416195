import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInicioControlSemen from '../../../components/planSanitario/controlSemen/ModalInicioControlSemen'
import {closeModalInicio, fetchFiltrarModalInicio, seleccionarTodosVerracosInicio, deSeleccionarTodosVerracosInicio, subirArchivosInforme} from '../../../actions/planSanitario/controlSemen/controlSemen'


export function mapStateToProps (state) {
  return {
    ...state.controlSemen,
    filtros:state.form.FiltrosModalAnnadirControlSemen,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalInicio,
      fetchFiltrarModalInicio,
      seleccionarTodosVerracosInicio,
      deSeleccionarTodosVerracosInicio,
      subirArchivosInforme
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInicioControlSemen))
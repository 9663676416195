import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import ModalBioseguridadHigienePage from '../../../containers/planSanitario/bioseguridadHigiene/ModalBioseguridadHigienePage'
import FiltrosBioseguridadHigienePage from '../../../containers/planSanitario/bioseguridadHigiene/FiltrosBioseguridadHigienePage'
import ModalAgendaPage from '../../../containers/agenda/ModalAgendaPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'

class BioseguridadHigiene extends Component {

  componentDidMount(){
    document.title = this.props.t('MENU.PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarBioseguridadHigiene({})
  }

  /*componentDidUpdate(prevProps, prevState){
    if (this.props.showModalAgenda !== prevProps.showModalAgenda && this.props.showModalAgenda === false) {
      let idInter = null
      let tipoaction = null
      if (Object.keys(this.props.registroModificar).length > 0) {
        if (this.props.registroModificar.analitica) {
          idInter = this.props.registroModificar.analitica.idBioSeguridad
          tipoaction = 1
          if (idInter !== null) {
            this.props.actions.recuperarDatosModalBioseguridadHigiene(idInter, tipoaction)
          }
        }
        if (this.props.registroModificar.limpiezaCorrales) {
          idInter = this.props.registroModificar.limpiezaCorrales.idBioSeguridad
          tipoaction = 3
          if (idInter !== null) {
            this.props.actions.recuperarDatosModalBioseguridadHigiene(idInter, tipoaction)
          }
        }
        if (this.props.registroModificar.limpiezaSilos) {
          idInter = this.props.registroModificar.limpiezaSilos.idBioSeguridad
          tipoaction = 2
          if (idInter !== null) {
            this.props.actions.recuperarDatosModalBioseguridadHigiene(idInter, tipoaction)
          }
        }
        if (this.props.registroModificar.tipoOtros) {
          idInter = this.props.registroModificar.tipoOtros.idBioSeguridad
          tipoaction = 4
          if (idInter !== null) {
            this.props.actions.recuperarDatosModalBioseguridadHigiene(idInter, tipoaction)
          }
        }
      }
    }
  }*/

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      bioseguridadHigiene: {list, data, checkedFiltrosBioseguridadHigiene, registroModificar},
      actions: {recuperarDatosModalBioseguridadHigiene, duplicarDatosModalBioseguridadHigiene, onDeleteBioseguridadHigiene}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idBioSeguridad + '-' + row.tipoAccion
    )

    const table = {
      id: 'bioseguridadHigieneTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: true,
      mostrarCambioEstado: false,
      columns: [
        {id: 'nombreTipoAccion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.TIPO_ACCION')},
        {id: 'fecha', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FECHA'), type: 'fecha'},
        {id: 'estado', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.ESTADO')},
        {id: 'operario', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.OPERARIO')},
        {id: 'identificador', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.IDENTIFICACION')},
        {id: 'ubicaciones', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.UBICACION')},
        {id: 'observaciones', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.OBSERVACIONES')}
      ],
      rows: list,
      filtros: <FiltrosBioseguridadHigienePage />,
      onEdit: (row) => recuperarDatosModalBioseguridadHigiene(row.idBioSeguridad, row.tipoAccion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1,
      duplicar: (row) =>duplicarDatosModalBioseguridadHigiene(row.idBioSeguridad, row.tipoAccion),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1,
      onDelete: (row) => onDeleteBioseguridadHigiene(row.idBioSeguridad, row.tipoAccion),
      showModal: this.props.showModal,
      initialValues: {}
    }

    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosBioseguridadHigiene
        }
      }
    )

    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        {
        <ModalBioseguridadHigienePage
          duplicar={(row) => {duplicarDatosModalBioseguridadHigiene(row.idBioSeguridad, row.tipoAccion)}}
        />
        }
        <ModalAgendaPage
          idBioSeguridadAnalitica={Object.keys(registroModificar).length > 0 && registroModificar.analitica ? registroModificar.analitica.idBioSeguridad : null}
          idBioSeguridadLimpiezaCorral={Object.keys(registroModificar).length > 0 && registroModificar.limpiezaCorrales ? registroModificar.limpiezaCorrales.idBioSeguridad : null}
          idBioSeguridadLimpiezaSilo={Object.keys(registroModificar).length > 0 && registroModificar.limpiezaSilos ? registroModificar.limpiezaSilos.idBioSeguridad : null}
          idBioSeguridadOtro={Object.keys(registroModificar).length > 0 && registroModificar.tipoOtros ? registroModificar.tipoOtros.idBioSeguridad : null}
          tipoTarea={2}
        />
        <ListadoPage t_key="PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE." table={table}>
        </ListadoPage>
      </div>
    )

  }
}

export default BioseguridadHigiene

import actionTypes from '../../constants/actions/plano/plano'

export function fetchPlano () {
  return {
    type: actionTypes.FETCH_PLANO
  }
}

export function fetchPlanoSuccess (instalacionesHijas) {
  return {
    type: actionTypes.FETCH_PLANO_SUCCESS,
    instalacionesHijas
  }
}

export function vaciarPlano () {
  return {
    type: actionTypes.VACIAR_PLANO
  }
}

export default {
  fetchPlano,
  fetchPlanoSuccess,
  vaciarPlano
}

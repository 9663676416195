export default function () {
  try {
    console.log('Loading session from localStorage')
    const seriablizedSession = localStorage.getItem('session')
    if (seriablizedSession === null) {
      return undefined
    }
    return JSON.parse(seriablizedSession)
  } catch (err) {
    console.log('load session error:', err)
    return undefined
  }
}
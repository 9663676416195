import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/plano/plano'
import { fetchPlanoSuccess } from '../../actions/plano/plano'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import getPlano from '../../services/plano/getPlano'

export function * fetchPlano () {
  try {
    const listPlano = yield call(getPlano, null)
    yield put(fetchPlanoSuccess(listPlano))
  } catch (error) {}
}
export function * watchFetchPlano () {
  yield takeLatest(actionTypes.FETCH_PLANO, fetchPlano)
}

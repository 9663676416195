import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEscandalloAnalisis from '../../components/escandallo/FiltrosEscandalloAnalisis'
import { abrirModalAnadirEscandallo, seleccionarTodosEscandalloAnadidos, deSeleccionarTodosEscandalloAnadidos, deleteSeleccionEscandalloAnadido, editarEscandalloAnadidoSuccess
  } from '../../actions/escandallo/escandallo'

export function mapStateToProps (state) {
  return {
    ...state.escandallo,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalAnadirEscandallo,
      seleccionarTodosEscandalloAnadidos,
      deSeleccionarTodosEscandalloAnadidos,
      deleteSeleccionEscandalloAnadido,
      editarEscandalloAnadidoSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEscandalloAnalisis))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import nivelGeneticoActionTypes from '../../constants/actions/nivelGenetico/nivelGenetico'
import {fetchNivelGeneticoSuccess, submitNuevaNivelGeneticoSuccess, recuperarDatosModalNivelGeneticoSuccess, editarNivelGeneticoSuccess, onDeleteNivelGeneticoSuccess} from '../../actions/nivelGenetico/nivelGenetico'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getNivelGeneticoList from '../../services/nivelGenetico/getNivelGeneticoList'
import addNivelGenetico from '../../services/nivelGenetico/addNivelGenetico'
import getNivelGenetico from '../../services/nivelGenetico/getNivelGenetico'
import editNivelGenetico from '../../services/nivelGenetico/editNivelGenetico'
import deleteNivelGenetico from '../../services/nivelGenetico/deleteNivelGenetico'

export function * fetchNivelGenetico () {
  try {
    const list = yield call(getNivelGeneticoList, null)
    yield put(fetchNivelGeneticoSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.FETCH_NIVEL_GENETICO, fetchNivelGenetico)
}

export function * submitNuevaNivelGenetico ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionNivelGenetico = yield call(addNivelGenetico, values, auth.token)
    yield put(submitNuevaNivelGeneticoSuccess(insercionNivelGenetico))
    if (insercionNivelGenetico.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(closeModal())
    const list = yield call(getNivelGeneticoList, null)
    yield put(fetchNivelGeneticoSuccess(list))    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.SUBMIT_NUEVA_NIVEL_GENETICO, submitNuevaNivelGenetico)
}

export function * recuperarDatosModalNivelGenetico ({idNivelGenetico}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getNivelGenetico, idNivelGenetico)
    yield put(recuperarDatosModalNivelGeneticoSuccess(registroModificar))    
    yield put(initialize('ModalNivelGenetico', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.RECUPERAR_DATOS_MODAL_NIVEL_GENETICO, recuperarDatosModalNivelGenetico)
}

export function * abrirModalVacioNivelGenetico () {
  try {
    yield put(recuperarDatosModalNivelGeneticoSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalNivelGenetico', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.ABRIR_MODAL_VACIO_NIVEL_GENETICO, abrirModalVacioNivelGenetico)
}

export function * editarNivelGenetico ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionNivelGenetico = yield call(editNivelGenetico, values, auth.token)
    yield put(editarNivelGeneticoSuccess(edicionNivelGenetico))
    if (edicionNivelGenetico.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(closeModal())    
    const list = yield call(getNivelGeneticoList, null)
    yield put(fetchNivelGeneticoSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.EDITAR_NIVEL_GENETICO, editarNivelGenetico)
}

export function * onDeleteNivelGenetico ({id, nombre, prioridad}) {
  try {    
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)      
      const dataDelete = yield call(deleteNivelGenetico, id, auth.token)
      yield put(onDeleteNivelGeneticoSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }      
      const list = yield call(getNivelGeneticoList, null)
      yield put(fetchNivelGeneticoSuccess(list))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteNivelGenetico () {
  yield takeLatest(nivelGeneticoActionTypes.ON_DELETE_NIVEL_GENETICO, onDeleteNivelGenetico)
}

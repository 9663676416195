import actionTypes from '../../constants/actions/transporte/transporte'
import actionTypesComun from '../../constants/actions/common'

export function initialState () {
  return {
    list: [],
    checkedFiltrosTransporte: false,
    filtros:{},
    data: [],
    dataDuplicar: [],
    checkedTransporte: false,
    conductoresAnadidos: [],
    showModalConductor: false,
    conductorRecuperar: [],
    duplicarConductor: [],
    cambioPorDefecto: false,
    cambioEstadoTrasporte: false,
    showModalVehiculo: false,
    vehiculosAnadidos: [],
    showModalVehiculo: false,
    vehiculoRecuperar: [],
    duplicarVehiculo: [],
    cambioPorDefectoVehiculo: false,
    cambioEstadoVehiculo: false,
    showModalRuta: false,
    rutasAnadidas: [],
    listRutas: [],
    checkedFiltrosTansporteRuta: false,
    filtrosModalRuta: {},
    cambioPorDefectoRuta: false,
    checkedConductor: false,
    checkedVehiculo: false,
    checkedRutas: false,
    datosExcelTransporte: [],
    datosPdfTransporte: []
  }
}

export function fetchTransportistaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function abrirModalConductor (state) {
  return {
    ...state,
    showModalConductor: true,
    conductorRecuperar: [],
    duplicarConductor:[]
  }
}

export function closeModalConductor (state) {
  return {
    ...state,
    showModalConductor: false
  }
}

export function submitNuevoConductor (state, {values}) {
  const conductoresState = state.conductoresAnadidos
  const conductorRecuperar = conductoresState.filter((conductor) => {
    if (values.predefinido === true) {
      conductor.predefinido = false
    }
  })
  return {
    ...state,
    conductoresAnadidos: state.conductoresAnadidos.concat([values]),
    showModalConductor: false
  }
}

export function recuperarModalConductorSuccess (state, {conductorRecuperar}) {
  return {
    ...state,
    showModalConductor: true,
    conductorRecuperar,
    duplicarConductor:[]
  }
}

export function editConductorAnadidoSuccess (state, {conductoresAnadidos}) {
  return {
    ...state,
    conductoresAnadidos,
    showModalConductor: false
  }
}

export function cambiarEstadoConductorSuccess (state, {conductor}) {
  return {
    ...state,
    cambioEstadoTrasporte: conductor
  }
}

export function filtrarTrasnporteSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function duplicarConductorSucccess (state, {duplicarConductor}) {
  return {
    ...state,
    showModalConductor: true,
    duplicarConductor,
    conductorRecuperar: []
  }
}

export function conductorPorDefectoSuccess (state, {conductor}) {
  return {
    ...state,
    cambioPorDefecto: conductor
  }
}

export function reiniciarConductoresVehiculosRutas (state) {
  return {
    ...state,
    conductoresAnadidos: [],
    vehiculosAnadidos: [],
    rutasAnadidas: []
  }
}

export function abrirModalVehiculo (state) {
  return {
    ...state,
    showModalVehiculo: true,
    vehiculoRecuperar: [],
    duplicarVehiculo:[]
  }
}

export function closeModalVehiculo (state) {
  return {
    ...state,
    showModalVehiculo: false
  }
}

export function submitNuevoVehiculo (state, {values}) {
  const vehiculosState = state.vehiculosAnadidos
  const vehiculoRecuperar = vehiculosState.filter((vehiculo) => {
    if (values.predefinido === true) {
      vehiculo.predefinido = false
    }
  })
  return {
    ...state,
    vehiculosAnadidos: state.vehiculosAnadidos.concat([values]),
    showModalVehiculo: false
  }
}

export function recuperarModalVehiculoSuccess (state, {vehiculoRecuperar}) {
  return {
    ...state,
    showModalVehiculo: true,
    vehiculoRecuperar,
    duplicarVehiculo:[]
  }
}

export function editVehiculoAnadidoSuccess (state, {vehiculosAnadidos}) {
  return {
    ...state,
    vehiculosAnadidos,
    showModalVehiculo: false
  }
}

export function cambiarEstadoVehiculoSuccess (state, {vehiculo}) {
  return {
    ...state,
    cambioEstadoVehiculo: vehiculo
  }
}

export function duplicarVehiculoSucccess (state, {duplicarVehiculo}) {
  return {
    ...state,
    showModalVehiculo: true,
    duplicarVehiculo,
    vehiculoRecuperar: []
  }
}

export function vehiculoPorDefectoSuccess (state, {vehiculo}) {
  return {
    ...state,
    cambioPorDefectoVehiculo: vehiculo
  }
}

export function abrirModalRuta (state, {values}) {
  return {
    ...state,
    showModalRuta: true,
    conductorRecuperar: [],
    duplicarConductor:[]
  }
}

export function closeModalRuta (state) {
  return {
    ...state,
    showModalRuta: false
  }
}

export function filtrarRutasTransporteSuccess (state, {listRutas}) {
  return {
    ...state,
    listRutas
  }
}

export function seleccionarTodosModalRutaTransportista (state) {
  return {
    ...state,
    checkedFiltrosTansporteRuta: true
  }
}

export function deSeleccionarTodosModalRutaTransportista (state) {
  return {
    ...state,
    checkedFiltrosTansporteRuta: false
  }
}

export function anadirRutasTransportista (state, {list}) {
  let rutas = state.rutasAnadidas
  let nuevoPredefinido = false
  list.forEach((li) => {
    if (li.predefinido) {
      nuevoPredefinido = true
    }
  })
  if (nuevoPredefinido === true) {
    if (Object.keys(rutas).length > 0) {
      rutas.forEach((ru) => {
        ru.predefinido = false
      })
    }
  }
  return {
    ...state,
    rutasAnadidas: rutas.concat(list),
    showModalRuta: false,
    checkedFiltrosTansporteRuta: false
  }
}

export function onDeleteRutaAnadidaSuccess (state, {rutasAnadidas}) {
  return {
    ...state,
    rutasAnadidas,
    showModalRuta: false
  }
}

export function rutaPorDefectoSuccess (state, {ruta}) {
  return {
    ...state,
    cambioPorDefectoRuta: ruta
  }
}

export function filtrosAplicados (state, {filtrosModalRuta}) {
  return {
    ...state,
    filtrosModalRuta
  }
}

export function guardarYNuevoConductor (state, {conductoresAnadidos}) {
  const conductoresState = state.conductoresAnadidos
  const conductorRecuperar = conductoresState.filter((conductor) => {
    if (conductor.idElemento === conductoresAnadidos.idElemento){
      conductor.activo = conductoresAnadidos.activo
      conductor.dniConductor = conductoresAnadidos.dniConductor
      conductor.movilConductor = conductoresAnadidos.movilConductor
      conductor.nombreConductor = conductoresAnadidos.nombreConductor
      conductor.predefinido = conductoresAnadidos.predefinido
    } else {
      if (conductoresAnadidos.predefinido === true) {
        conductor.predefinido = false
      }
    }
  })
  return {
    ...state,
    conductoresAnadidos: state.conductoresAnadidos.concat(conductoresAnadidos)
  }
}

export function guardarYNuevoVehiculo (state, {vehiculosAnadidos}) {
  const vehiculosState = state.vehiculosAnadidos
  const vehiculoRecuperar = vehiculosState.filter((vehiculo) => {
    if (vehiculo.idElemento === vehiculosAnadidos.idElemento){
      vehiculo.activo = vehiculosAnadidos.activo
      vehiculo.dniConductor = vehiculosAnadidos.dniConductor
      vehiculo.movilConductor = vehiculosAnadidos.movilConductor
      vehiculo.nombreConductor = vehiculosAnadidos.nombreConductor
      vehiculo.predefinido = vehiculosAnadidos.predefinido
    } else {
      if (vehiculosAnadidos.predefinido === true) {
        vehiculo.predefinido = false
      }
    }
  })
  return {
    ...state,
    vehiculosAnadidos: state.vehiculosAnadidos.concat(vehiculosAnadidos)
  }
}

export function seleccionarTodosConductores (state) {
  return {
    ...state,
    checkedConductor: true
  }
}

export function deSeleccionarTodosConductores (state) {
  return {
    ...state,
    checkedConductor: false
  }
}

export function seleccionarTodosVehiculos (state) {
  return {
    ...state,
    checkedVehiculo: true
  }
}

export function deSeleccionarTodosVehiculos (state) {
  return {
    ...state,
    checkedVehiculo: false
  }
}

export function seleccionarTodosRutas (state) {
  return {
    ...state,
    checkedRutas: true
  }
}

export function deSeleccionarTodosRutas (state) {
  return {
    ...state,
    checkedRutas: false
  }
}

export function deleteSeleccionConductor (state, {conductoresAnadidos}) {
  return {
    ...state,
    conductoresAnadidos,
    checkedConductor: false
  }
}

export function deleteSeleccionVehiculo (state, {vehiculosAnadidos}) {
  return {
    ...state,
    vehiculosAnadidos,
    checkedVehiculo: false
  }
}

export function deleteSeleccionRutaTransportista (state, {rutasAnadidas}) {
  return {
    ...state,
    rutasAnadidas,
    checkedRutas: false
  }
}

export function seleccionarTodosTransporte (state) {
  return {
    ...state,
    checkedTransporte: true
  }
}

export function deSeleccionarTodosTransporte (state) {
  return {
    ...state,
    checkedTransporte: false
  }
}

export function crearCsvTransporteSuccess (state, {datosExcelTransporte}) {
  return {
    ...state,
    datosExcelTransporte
  }
}

export function imprimirPdfTransporteSuccess (state, {datosPdfTransporte}) {
  return {
    ...state,
    datosPdfTransporte
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_CONDUCTOR:
      return abrirModalConductor(state, action)
    case actionTypes.CLOSE_MODAL_CONDUCTOR:
      return closeModalConductor(state, action)
    case actionTypes.SUBMIT_NUEVO_CONDUCTOR:
      return submitNuevoConductor(state, action)
    case actionTypes.RECUPERAR_MODAL_CONDUCTOR_SUCCESS:
      return recuperarModalConductorSuccess(state, action)
    case actionTypes.EDIT_CONDUCTOR_ANADIDO_SUCCESS:
      return editConductorAnadidoSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_CONDUCTOR_SUCCESS:
      return cambiarEstadoConductorSuccess(state, action)
    case actionTypes.FETCH_TRANSPORTISTA_SUCCESS:
      return fetchTransportistaSuccess(state, action)
    case actionTypes.FILTRAR_TRANSPORTE_SUCCESS:
      return filtrarTrasnporteSuccess(state, action)
    case actionTypes.DUPLICAR_CONDUCTOR_SUCCESS:
      return duplicarConductorSucccess(state, action)
    case actionTypes.CONDUCTOR_POR_DEFECTO_SUCCESS:
      return conductorPorDefectoSuccess(state, action)
    case actionTypes.REINICIAR_CONDUCTORES_VEHICULOS_RUTAS:
      return reiniciarConductoresVehiculosRutas(state, action)
    case actionTypes.REINICIAR_CONDUCTORES_VEHICULOS_RUTAS:
      return initialState ()
    case actionTypes.ABRIR_MODAL_VEHICULO:
      return abrirModalVehiculo(state, action)
    case actionTypes.CLOSE_MODAL_VEHICULO:
      return closeModalVehiculo(state, action)
    case actionTypes.SUBMIT_NUEVO_VEHICULO:
      return submitNuevoVehiculo(state, action)
    case actionTypes.RECUPERAR_MODAL_VEHICULO_SUCCESS:
      return recuperarModalVehiculoSuccess(state, action)
    case actionTypes.EDIT_VEHICULO_ANADIDO_SUCCESS:
      return editVehiculoAnadidoSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_VEHICULO_SUCCESS:
      return cambiarEstadoVehiculoSuccess(state, action)
    case actionTypes.DUPLICAR_VEHICULO_SUCCESS:
      return duplicarVehiculoSucccess(state, action)
    case actionTypes.VEHICULO_POR_DEFECTO_SUCCESS:
      return vehiculoPorDefectoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_RUTA:
      return abrirModalRuta(state, action)
    case actionTypes.CLOSE_MODAL_RUTA:
      return closeModalRuta(state, action)
    case actionTypes.FILTRAR_RUTAS_TRANSPORTE_SUCCESS:
      return filtrarRutasTransporteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA:
      return seleccionarTodosModalRutaTransportista(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA:
      return deSeleccionarTodosModalRutaTransportista(state, action)
    case actionTypes.ANADIR_RUTAS_TRANPORTISTA:
      return anadirRutasTransportista(state, action)
    case actionTypes.ON_DELETE_RUTA_ANADADIDA_SUCCESS:
      return onDeleteRutaAnadidaSuccess(state, action)
    case actionTypes.RUTA_POR_DEFECTO_SUCCESS:
      return rutaPorDefectoSuccess(state, action)
    case actionTypes.FILTROS_APLICADOS:
      return filtrosAplicados(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_CONDUCTOR:
      return guardarYNuevoConductor(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_VEHICULO:
      return guardarYNuevoVehiculo(state, action)
    case actionTypes.SELECCIONAR_TODOS_CONDUCTORES:
      return seleccionarTodosConductores(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CONDUCTORES:
      return deSeleccionarTodosConductores(state, action)
    case actionTypes.SELECCIONAR_TODOS_VEHICULOS:
      return seleccionarTodosVehiculos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VEHICULOS:
      return deSeleccionarTodosVehiculos(state, action)
    case actionTypes.SELECCIONAR_TODOS_RUTAS:
      return seleccionarTodosRutas(state, action)
    case actionTypes.DESELECCIONAR_TODOS_RUTAS:
      return deSeleccionarTodosRutas(state, action)
    case actionTypes.DELETE_SELECCION_CONDUCTOR:
      return deleteSeleccionConductor(state, action)
    case actionTypes.DELETE_SELECCION_VEHICULO:
      return deleteSeleccionVehiculo(state, action)
    case actionTypes.DELETE_SELECCION_RUTA_TRANSPORTISTA:
      return deleteSeleccionRutaTransportista(state, action)
    case actionTypes.SELECCIONAR_TODOS_TRANSPORTE:
      return seleccionarTodosTransporte(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TRANSPORTE:
      return deSeleccionarTodosTransporte(state, action)
    case actionTypes.CREATE_CSV_TRANSPORTE_SUCCESS:
      return crearCsvTransporteSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_TRANSPORTE_SUCCESS:
      return imprimirPdfTransporteSuccess(state, action)
    default:
      return state
  }
}

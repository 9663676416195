import actionTypes from '../../constants/actions/asignacionPedidos/asignacionPedidos';

export function initialState() {
  return {
    showModal: false,
    listLineasAlbaranesPendientes:[],
    filtros: {}
  }
}

export function fetchLineasAlbaranes(state, {filtros}) {
  return {
    ...state,
    filtros    
  }
}

export function fetchLineasAlbaranesSuccess(state, {listLineasAlbaranesPendientes, filtros}) {
  return {
    ...state,
    listLineasAlbaranesPendientes,
    filtros
  }
}

export function abrirModalAsignacionDosis(state ) {  
  return {
    ...state
  }
}

export function abrirModalAsignacionDosisSuccess(state, {idDosis,idAlbaranDosis,idNivelGenetico,advertenciaNivel}) {  
  return {
    ...state,        
    showModal:true,
    idDosis,
    idAlbaranDosis,
    idNivelGenetico,
    advertenciaNivel
  }
}

export function cerrarModalAsignacionDosis(state) {
  return {
    ...state,
    showModal: false,
  }
}

export function addDosisAPedido(state , {idDosis, idAlbaranDosis, dosis}) {
  return {
    ...state,
    idDosis,
    idAlbaranDosis,
    dosis
  }
}

export function addDosisAPedidoSuccess(state) {
  return {
    ...state
  }
}

export function closeModal(state) {
  return {
    ...state,
    showModal: false,
    idDosis:null,
    idAlbaranDosis:null
  }
}

export function actualizarFiltrosAsignacionPedidos(state, {filtros}) {
  return {
    ...state,
    filtros
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ADD_DOSIS_A_PEDIDO_SUCCESS:
      return addDosisAPedidoSuccess(state, action);    
    case actionTypes.FETCH_LINEAS_ALBARANES_PENDIENTES_SUCCESS:
      return fetchLineasAlbaranesSuccess(state, action);    
    case actionTypes.ABRIR_MODAL_ASIGNACION_DOSIS_SUCCESS:      
      return abrirModalAsignacionDosisSuccess(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);  
    case actionTypes.ACTUALIZAR_FILTROS_ASIGNACION_PEDIDOS:
      return actualizarFiltrosAsignacionPedidos(state, action);  
    default:
      return state;
  }
}

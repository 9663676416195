import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './Perfil.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import InputPassword from '../comun/form/InputPassword'
import { DoneAll, Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'

class ModalCambiarPassPerfil extends Component {
  constructor () {
    super()
    this.state = {
      validarAnadirGrupo: false,
      dataCambiarPass: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.dataCambiarPass !== prevProps.dataCambiarPass) {
      if (this.props.dataCambiarPass === false) {
        this.setState({dataCambiarPass: true})
      } else {
        this.setState({dataCambiarPass: false})
      }
    }
  }

  cerrarCambiarPassPerfil () {
    this.props.actions.cerrarCambiarPassPerfil()
    this.props.initialize()
  }

  anadirCambiarPass (values) {
    values.idUsuario = this.props.auth.idUsuario
    this.props.actions.cambiarPasswordPerfil(values)
  }

  render () {
    const {
      t, handleSubmit, showModalCambiarPassPerfil, idUsuario,
      actions: { cerrarCambiarPassPerfil }
    } = this.props

    return (
      <Modal show={showModalCambiarPassPerfil} onHide={() => this.cerrarCambiarPassPerfil()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-cambiar-pass" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('USUARIOS.MODAL.CAMBIAR_PASS')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              this.state.dataCambiarPass === true ? (
                <Col sm={12}><div role="alert" className="alert alert-danger">{t('LOGIN.FORM.CREDENCIALES_INCORRECTOS')}</div></Col>
              ) : null
            }
            <form autoComplete="off">
              <Field
                colSm={12}
                id="oldPassword"
                name="oldPassword"
                controlLabel={t('USUARIOS.MODAL.PASS_VIEJA') + '*'}
                component={InputPassword}
                validate={required}
                maxLength={true}
                valorMaxLength={255}
              />
              <Field
                colSm={12}
                id="passwordUsuario"
                name="passwordUsuario"
                controlLabel={t('USUARIOS.MODAL.PASS_NUEVA') + '*'}
                component={InputPassword}
                validate={required}
                maxLength={true}
                valorMaxLength={255}
              />
            </form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirCambiarPass.bind(this))}>{t('USUARIOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarCambiarPassPerfil()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCambiarPassPerfil',
  enableReinitialize: true
})(ModalCambiarPassPerfil))
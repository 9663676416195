import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRuta from '../../components/ruta/ModalRuta'
import {recuperarDatosModalRuta, abrirModalClienteRuta, onDeleteClienteAnadido, submitNuevoRuta, seleccionarTodosModalRuta, deSeleccionarTodosModalRuta, deleteSeleccionClientesRuta, editarRuta,
  guardarYnuevoRuta, limpiar} from '../../actions/ruta/ruta'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.ruta,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    formModalRuta: state.form.ModalRuta,
    ruta: state.ruta,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      recuperarDatosModalRuta,
      abrirModalClienteRuta,
      onDeleteClienteAnadido,
      submitNuevoRuta,
      seleccionarTodosModalRuta,
      deSeleccionarTodosModalRuta,
      deleteSeleccionClientesRuta,
      editarRuta,
      guardarYnuevoRuta,
      limpiar
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRuta))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { submitRenovarToken, comprobarEntorno, obtenerNumeroSerie, mostrarInformacionLicencia } from '../../actions/auth/auth'
import RenovarLicencia from '../../components/auth/RenovarLicencia'

const mapStateToProps = (state, props) => {
  return {
    auth: {
      desautorizado: state.auth.desautorizado,
      expiredSession: state.auth.expiredSession,
      licenciaExpirada: state.auth.licenciaExpirada,
      isCloud: state.auth.isCloud,
      numeroSerie: state.auth.numeroSerie,
      datosLicencia: state.auth.datosLicencia
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitRenovarToken,
      comprobarEntorno,
      obtenerNumeroSerie,
      mostrarInformacionLicencia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RenovarLicencia))
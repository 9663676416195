import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalPlaning from '../../components/planing/FiltrosModalPlaning'
import {seleccionarTodosVerracosPlaning, deSeleccionarTodosVerracosPlaning, asignarVerracos, exportarSugerenciaExtracciones, imprimirSugerenciaExtracciones
  } from '../../actions/planing/planing'
import {comboRaza, comboLineaGenetica, comboUbicacion, comboNombreGrupoVerraco, comboOperario, comboVerracosPresentesActivos, comboIndex, comboNombreGrupoVerracoActiva, comboVerraco} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboRaza,
      comboLineaGenetica,
      comboUbicacion,
      comboNombreGrupoVerraco,
      seleccionarTodosVerracosPlaning,
      deSeleccionarTodosVerracosPlaning,
      asignarVerracos,
      comboOperario,
      comboVerracosPresentesActivos,
      comboVerraco,
      comboIndex,
      exportarSugerenciaExtracciones,
      imprimirSugerenciaExtracciones,
      comboNombreGrupoVerracoActiva
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalPlaning))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalExportarImprimirSeguimientoExtraccionesPool from '../../components/informes/ModalExportarImprimirSeguimientoExtraccionesPool'
import {cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool, imprimirPdfInformesSeguimientoExtraccionesPool,
exportarInformeSeguimientoExtraccionesPool} from '../../actions/informes/informes'
import {comboVerracoConSeguimiento} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool,
      comboVerracoConSeguimiento,
      imprimirPdfInformesSeguimientoExtraccionesPool,
      exportarInformeSeguimientoExtraccionesPool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalExportarImprimirSeguimientoExtraccionesPool))
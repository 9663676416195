import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, AccessTime, CloudUpload } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosCopiaSeguridad extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.archivoCopiaSeguridad !== prevProps.archivoCopiaSeguridad) {
      if (this.props.archivoCopiaSeguridad.backUp && this.props.archivoCopiaSeguridad.backUp !== null){
        let backUp = this.props.archivoCopiaSeguridad.backUp
        let nombreBackUp = this.props.archivoCopiaSeguridad.nombreArchivo
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:text/plain;base64,' + backUp);
        element.setAttribute('download', nombreBackUp)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        this.props.actions.descargarCopiaSeguridadSuccess([])
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosCopiaSeguridad()
          }
        }
      }
    }
  }

  /*abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarCopiaSeguridad(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }*/

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosCopiaSeguridad()
    } else {
      this.props.actions.deSeleccionarTodosCopiaSeguridad()
    }
  }

  deleteSeleccionCopiaSeguridad(){
    const itemsSeleccion = this.props.listCopiaSeguridad.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCopiaSeguridad': row.idCopiaSeguridad
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionCopiaSeguridad(list)
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalCopiaSeguridad, abrirModalCopiaSeguridadProgramada, abrirModalRestaurarConArchivo, abrirModalRestaurarMigracion
    }
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionCopiaSeguridad()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1) ? abrirModalCopiaSeguridad : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1) ? abrirModalCopiaSeguridadProgramada : undefined}
                >
                  <AccessTime></AccessTime> {t('COPIA_SEGURIDAD.PROGRAMAR_COPIA')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1) ? abrirModalRestaurarConArchivo : undefined}
                >
                  <CloudUpload></CloudUpload> {t('COPIA_SEGURIDAD.RESTAURAR_COPIA_DESDE_ARCHIVO')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1) ? abrirModalRestaurarMigracion : undefined}
                >
                  <CloudUpload></CloudUpload> {t('COPIA_SEGURIDAD.RESTAURAR_COPIA_MIGRACION')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
          {/*
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          */}
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosCopiaSeguridad',
})(FiltrosCopiaSeguridad))

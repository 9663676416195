export default {
    FETCH_MICROBIOLOGIA: 'FETCH_MICROBIOLOGIA',
    FETCH_MICROBIOLOGIA_SUCCESS: 'FETCH_MICROBIOLOGIA_SUCCESS',
    SUBMIT_NUEVA_MICROBIOLOGIA: 'SUBMIT_NUEVA_MICROBIOLOGIA',
    SUBMIT_NUEVA_MICROBIOLOGIA_SUCCESS: 'SUBMIT_NUEVA_MICROBIOLOGIA_SUCCESS',
    RECUPERAR_DATOS_MODAL_MICROBIOLOGIA: 'RECUPERAR_DATOS_MODAL_MICROBIOLOGIA',
    RECUPERAR_DATOS_MODAL_MICROBIOLOGIA_SUCCESS: 'RECUPERAR_DATOS_MODAL_MICROBIOLOGIA_SUCCESS',
    ABRIR_MODAL_VACIO_MICROBIOLOGIA: 'ABRIR_MODAL_VACIO_MICROBIOLOGIA',
    EDITAR_MICROBIOLOGIA: 'EDITAR_MICROBIOLOGIA',
    EDITAR_MICROBIOLOGIA_SUCCESS: 'EDITAR_MICROBIOLOGIA_SUCCESS',
    ON_DELETE_MICROBIOLOGIA: 'ON_DELETE_MICROBIOLOGIA',
    ON_DELETE_MICROBIOLOGIA_SUCCESS: 'ON_DELETE_MICROBIOLOGIA_SUCCESS',
    FETCH_FILTRAR_MICROBIOLOGIA: 'FETCH_FILTRAR_MICROBIOLOGIA',
    FETCH_FILTRAR_MICROBIOLOGIA_SUCCESS: 'FETCH_FILTRAR_MICROBIOLOGIA_SUCCESS',
    CERRAR_MODAL_MICROBIOLOGIA: 'CERRAR_MODAL_MICROBIOLOGIA',
    ABRIR_MODAL_MICROBIOLOGIA: 'ABRIR_MODAL_MICROBIOLOGIA',
    SELECCIONAR_TODOS_MICROBIOLOGIA: 'SELECCIONAR_TODOS_MICROBIOLOGIA',
    DESELECCIONAR_TODOS_MICROBIOLOGIA: 'DESELECCIONAR_TODOS_MICROBIOLOGIA',
    CREATE_CSV_MICROBIOLOGIA: 'CREATE_CSV_MICROBIOLOGIA',
    CREATE_CSV_MICROBIOLOGIA_SUCCESS: 'CREATE_CSV_MICROBIOLOGIA_SUCCESS'
  }
  
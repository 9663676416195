import actionTypes from '../../../constants/actions/fichaVerraco/editarVerraco/editarVerraco'


export function fetchEditarVerraco(idVerraco, t){

  return {  
    type: actionTypes.FETCH_EDITAR_VERRACO,
    idVerraco
  }
}

export function fetchEditarVerracoSuccess(listVerraco){
  
  return {
    type: actionTypes.FETCH_EDITAR_VERRACO_SUCCESS,
    listVerraco
  }
}

export function submitEditarEvolucionSuccess(nuevaEvolucion){
  return{
    type: actionTypes.SUBMIT_EDITAR_EVOLUCION_SUCCESS,
    nuevaEvolucion
  }
}

export function submitEditarIndexSuccess(nuevaIndex){
  return{
    type: actionTypes.SUBMIT_EDITAR_INDEX_SUCCESS,
    nuevaIndex
  }
}

export function submitEditarVerraco (values, history) {
  return {
    type: actionTypes.SUBMIT_EDITAR_VERRACO,
    values,
    history
  }
}

export function fetchHistoricoEstadosVerraco(idVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_ESTADOS_VERRACO,
    idVerraco
  }
}

export function fetchHistoricoEstadosVerracoSuccess(listHistoricoEstadosVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_ESTADOS_VERRACO_SUCCESS,
    listHistoricoEstadosVerraco
  }
}

export function fetchHistoricoGrupoVerraco(idVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_GRUPO_VERRACO,
    idVerraco
  }
}

export function fetchHistoricoGrupoVerracoSuccess(listHistoricoGrupoVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_GRUPO_VERRACO_SUCCESS,
    listHistoricoGrupoVerraco
  }
}

export function fetchHistoricoExtracciones(idVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_EXTRACCIONES,
    idVerraco
  }
}

export function fetchHistoricoExtraccionesSuccess(listHistoricoExtracciones){
  return {
    type: actionTypes.FETCH_HISTORICO_EXTRACCIONES_SUCCESS,
    listHistoricoExtracciones
  }
}

export function fetchHistoricoIntervenciones(idVerraco){
  return {
    type: actionTypes.FETCH_HISTORICO_INTERVENCIONES,
    idVerraco
  }
}

export function fetchHistoricoIntervencionesSuccess(listHistoricoIntervenciones){
  return {
    type: actionTypes.FETCH_HISTORICO_INTERVENCIONES_SUCCESS,
    listHistoricoIntervenciones
  }
}

export function abrirModalHistoricoGrupos(idGrupoVerraco){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_GRUPOS,
    idGrupoVerraco
  }
}

export function abrirModalHistoricoGruposSuccess(datosHistoricoGrupoVerraco){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_GRUPOS_SUCCESS,
    datosHistoricoGrupoVerraco
  }
}

export function cerrarModalHistoricoGrupos(){
  return {
    type: actionTypes.CERRAR_MODAL_HISTORICO_GRUPOS
  }
}

export function abrirModalHistoricoExtracciones(idAnalisis){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_EXTRACCIONES,
    idAnalisis
  }
}

export function abrirModalHistoricoExtraccionesSuccess(datosHistoricoExtracciones){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_EXTRACCIONES_SUCCESS,
    datosHistoricoExtracciones
  }
}

export function cerrarModalHistoricoExtracciones(){
  return {
    type: actionTypes.CERRAR_MODAL_HISTORICO_EXTRACCIONES
  }
}

export function abrirModalHistoricoEstado(idEstado){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_ESTADO,
    idEstado
  }
}

export function abrirModalHistoricoEstadoSuccess(datosHistoricoEstado){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_ESTADO_SUCCESS,
    datosHistoricoEstado
  }
}

export function cerrarModalHistoricoEstado(){
  return {
    type: actionTypes.CERRAR_MODAL_HISTORICO_ESTADO
  }
}

export function abrirModalHistoricoIntervenciones(idIntervencion){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_INTERVENCIONES,
    idIntervencion
  }
}

export function abrirModalHistoricoIntervencionesSuccess(datosHistoricoIntervenciones){
  return {
    type: actionTypes.ABRIR_MODAL_HISTORICO_INTERVENCIONES_SUCCESS,
    datosHistoricoIntervenciones
  }
}

export function cerrarModalHistoricoIntervenciones(){
  return {
    type: actionTypes.CERRAR_MODAL_HISTORICO_INTERVENCIONES
  }
}


export default {
  fetchEditarVerraco,
  fetchEditarVerracoSuccess,
  submitEditarIndexSuccess,
  submitEditarVerraco,
  fetchHistoricoEstadosVerraco,
  fetchHistoricoEstadosVerracoSuccess,
  fetchHistoricoGrupoVerraco,
  fetchHistoricoGrupoVerracoSuccess,
  fetchHistoricoExtracciones,
  fetchHistoricoExtraccionesSuccess,
  fetchHistoricoIntervenciones,
  fetchHistoricoIntervencionesSuccess,
  abrirModalHistoricoGrupos,
  abrirModalHistoricoGruposSuccess,
  cerrarModalHistoricoGrupos,
  abrirModalHistoricoExtracciones,
  abrirModalHistoricoExtraccionesSuccess,
  cerrarModalHistoricoExtracciones,
  abrirModalHistoricoEstado,
  abrirModalHistoricoEstadoSuccess,
  cerrarModalHistoricoEstado,
  abrirModalHistoricoIntervenciones,
  abrirModalHistoricoIntervencionesSuccess,
  cerrarModalHistoricoIntervenciones
}

import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePicker from '../comun/form/InputRangeDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosEscandalloAnalisis extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelectedEscandalloAnalisis: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-escandallo2', false)
            this.props.change('seleccionar-todo-escandallo3', false)
            this.props.change('seleccionar-todo-escandallo1', false)
            this.setState({ imputSelectedEscandalloAnalisis: false })
            this.props.actions.deSeleccionarTodosEscandalloAnadidos()
          }
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelectedEscandalloAnalisis: value })
    if (this.props.proceso === 1) {
      this.props.change('seleccionar-todo-escandallo2', false)
      this.props.change('seleccionar-todo-escandallo3', false)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(2)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(3)
      if (value === true){
        this.props.actions.seleccionarTodosEscandalloAnadidos(1)
      }
    } else if (this.props.proceso === 2) {
      this.props.change('seleccionar-todo-escandallo1', false)
      this.props.change('seleccionar-todo-escandallo3', false)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(1)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(3)
      if (value === true){
        this.props.actions.seleccionarTodosEscandalloAnadidos(2)
      }
    } else if (this.props.proceso === 3) {
      this.props.change('seleccionar-todo-escandallo1', false)
      this.props.change('seleccionar-todo-escandallo2', false)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(1)
      this.props.actions.deSeleccionarTodosEscandalloAnadidos(2)
      if (value === true){
        this.props.actions.seleccionarTodosEscandalloAnadidos(3)
      }
    }
  }

  deleteSeleccionEscandallo(proceso){
    let listado
    if (proceso === 1){
      listado = this.props.escandalloAnalisis
    } else if (proceso === 2){
      listado = this.props.escandalloDilucion
    } else if (proceso === 3){
      listado = this.props.escandalloEnvasado
    }
    const itemsSeleccion = listado.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idEscandallo': row.idEscandallo,
          'proceso': row.proceso,
          'producto': row.producto,
          'unidadMedida': row.unidadMedida,
          'cantidadEscandallo': row.cantidadEscandallo,
          'nombreProducto': row.nombreProducto,
          'esGuardarYnuevo': row.esGuardarYnuevo,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item !== false;
    })
    this.props.actions.deleteSeleccionEscandalloAnadido(list, proceso)
    this.props.change('seleccionar-todo-escandallo' + proceso, false)
  }

  render () {
  const {
    t, handleSubmit, filtros, proceso,
    actions: {
      abrirModalAnadirEscandallo
    },
    combos: {
      comboNombreEscandallo, comboReferenciaEscandallo
    }
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={12}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id={'seleccionar-todo-escandallo' + proceso}
                    name={'seleccionar-todo-escandallo' + proceso}
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id={'block-options' + proceso} key={'block-options' + proceso} className={this.state.imputSelectedEscandalloAnalisis === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionEscandallo(this.props.proceso)}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ESCANDALLOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) ? () => abrirModalAnadirEscandallo(proceso) : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosEscandalloAnalisis',
})(FiltrosEscandalloAnalisis))

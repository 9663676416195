import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import filtros from '../../components/grupoVerraco/FiltrosGrupoVerraco'
import {openModal} from '../../actions/common'
import {abrirModalVacioGrupoVerraco, fetchFiltrarGrupoVerraco, seleccionarTodosGrupoVerraco, deSeleccionarTodosGrupoVerraco, cambiarEstadoSeleccionGrupoVerraco, deleteSeleccionGrupoVerraco, 
  crearCsvGrupoVerraco, imprimirPdfGrupoVerraco} from '../../actions/grupoVerraco/GrupoVerraco'
import {comboNombreGrupoVerraco, comboVerraco} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.grupoVerraco,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioGrupoVerraco,
      comboNombreGrupoVerraco,
      comboVerraco,
      fetchFiltrarGrupoVerraco,
      seleccionarTodosGrupoVerraco,
      deSeleccionarTodosGrupoVerraco,
      cambiarEstadoSeleccionGrupoVerraco,
      deleteSeleccionGrupoVerraco,
      crearCsvGrupoVerraco,
      imprimirPdfGrupoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(filtros))
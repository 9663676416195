export default {
    FETCH_NIVEL_GENETICO: 'FETCH_NIVEL_GENETICO',
    FETCH_NIVEL_GENETICO_SUCCESS: 'FETCH_NIVEL_GENETICO_SUCCESS',
    SUBMIT_NUEVA_NIVEL_GENETICO: 'SUBMIT_NUEVA_NIVEL_GENETICO',
    SUBMIT_NUEVA_NIVEL_GENETICO_SUCCESS: 'SUBMIT_NUEVA_NIVEL_GENETICO_SUCCESS',
    RECUPERAR_DATOS_MODAL_NIVEL_GENETICO: 'RECUPERAR_DATOS_MODAL_NIVEL_GENETICO',
    RECUPERAR_DATOS_MODAL_NIVEL_GENETICO_SUCCESS: 'RECUPERAR_DATOS_MODAL_NIVEL_GENETICO_SUCCESS',
    ABRIR_MODAL_VACIO_NIVEL_GENETICO: 'ABRIR_MODAL_VACIO_NIVEL_GENETICO',
    EDITAR_NIVEL_GENETICO: 'EDITAR_NIVEL_GENETICO',
    EDITAR_NIVEL_GENETICO_SUCCESS: 'EDITAR_NIVEL_GENETICO_SUCCESS',
    ON_DELETE_NIVEL_GENETICO: 'ON_DELETE_NIVEL_GENETICO',
    ON_DELETE_NIVEL_GENETICO_SUCCESS: 'ON_DELETE_NIVEL_GENETICO_SUCCESS'
  }
  
import { select, put, call,  takeLatest } from 'redux-saga/effects'
import { dateTimeZones } from '../../util/formatFunctions'
//contants
import actionTypesAsignacionPedido from '../../constants/actions/asignacionPedidos/asignacionPedidos';

// actions
import {
  abrirModalAsignacionDosisSuccess, addDosisAPedidoSuccess, fetchLineasAlbaranesSuccess
} from '../../actions/asignacionPedidos/asignacionPedidos';

import {openSimpleModal, openMensajeAlerta} from '../../actions/common'

// Sagas
import { yesNoModal as yesNoModalSaga } from '../modal/yesNoModal'
import { obtenerInformeStockDosis as obtenerInformeStockDosisSaga } from '../informes/informes'

// Services
import addLineaAPedido from '../../services/asignacionPedidos/addLineaAPedido';
import filtrarLineasAlbaranes from '../../services/asignacionPedidos/filtrarLineasAlbaranes';
import deleteSeleccionDosisEnvasadasService from '../../services/asignacionPedidos/deleteSeleccionDosisEnvasadas';
import getComboRazaGranja from '../../services/combos/getComboRazaGranja'
import getComboLineaGeneticaGranja from '../../services/combos/getComboLineaGeneticaGranja'
import getComboTipoDosisGranja from '../../services/combos/getComboTipoDosisGranja'


export function* abrirModalAsignacion({ idDosis, idAlbaranDosis, idDireccionCliente, idRaza, idLinea, idTipoDosis, idNivelGenetico, advertenciaNivel }) {
  try {    
    /* Lo comento de momento no hace falta
    //comprobar restricciones raza, linea, tipoDosis x tipo de granja
    //RAZA
    let valid = false
    const auth = yield select(state => state.auth)
    let values = {}
    values.idDireccionCliente = idDireccionCliente
    let data = yield call(getComboRazaGranja, values, auth.token)
    if(data && data.length > 0){
      for (var index in data) {
        if(data[index].value == idRaza) valid = true
      }
    }
    if(!valid){
      //idRaza no valid
      yield call(yesNoModalSaga, {modalType: 'verracoAnalizadoHaceMenosDe24h'})
      return
    }
    
    //LINEA
    valid = false
    values = {}
    values.idDireccionCliente = idDireccionCliente
    values.idRaza = idRaza
    data = yield call(getComboLineaGeneticaGranja, values, auth.token)
    if(data && data.length > 0){
      for (var index in data) {
        if(data[index].value == idLinea) valid = true
      }
    }
    if(!valid){
      //idLinea no valid
      yield call(yesNoModalSaga, {modalType: 'verracoAnalizadoHaceMenosDe24h'})
      return
    }

    
    //TIPO DOSIS
    valid = false
    values = {}
    values.idDireccionCliente = idDireccionCliente
    values.idRaza = idRaza
    values.idLinea = idLinea
    data = yield call(getComboTipoDosisGranja, values, auth.token)
    if(data && data.length > 0){
      for (var index in data) {
        if(data[index].value == idTipoDosis) valid = true
      }
    }
    if(!valid){
      //idTipoDosis no valid
      yield call(yesNoModalSaga, {modalType: 'verracoAnalizadoHaceMenosDe24h'})
      return
    }
    */

    //todo bien -> abrimos modal
    yield put(abrirModalAsignacionDosisSuccess(idDosis,idAlbaranDosis,idNivelGenetico,advertenciaNivel));

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function* watchAbrirModalAsignacionDosis() {
  yield takeLatest(actionTypesAsignacionPedido.ABRIR_MODAL_ASIGNACION_DOSIS, abrirModalAsignacion)
}

export function* addDosisAPedido({ idDosis, idAlbaranDosis, dosis }) {
  try {    
    let auth = yield select(state => state.auth)
    let postData = {
      idDosis,
      idAlbaranDosis,
      dosis
    }        
    const respuesta = yield call(addLineaAPedido, postData, auth.token);  
    if (respuesta.error){
      alert(JSON.stringify(respuesta.error));
    }

    //recargar listados
    let filtros = yield select(state=>state.asignacionPedidos.filtros);
    filtros = typeof(filtros)==='undefined' ? null : filtros;
    yield call(fetchLineasAlbaranes, {filtros});
    yield call(obtenerInformeStockDosisSaga, {values:filtros});
    //yield put(addDosisAPedidoSuccess()); //no hace nada

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function* watchAddDosisAPedido() {
  yield takeLatest(actionTypesAsignacionPedido.ADD_DOSIS_A_PEDIDO, addDosisAPedido)
}

export function* fetchLineasAlbaranes({filtros}) {  
  try {
    let auth = yield select(state => state.auth)
    let lineasAlbaranes = yield call(filtrarLineasAlbaranes, filtros, auth.token);    
    yield put(fetchLineasAlbaranesSuccess(lineasAlbaranes, filtros));
  }
  catch (err) {
    console.error(err);
  }
}
export function* watchFetchLineasAlbaranes() {
  yield takeLatest(actionTypesAsignacionPedido.FETCH_LINEAS_ALBARANES_PENDIENTES, fetchLineasAlbaranes);
}

export function* deleteSeleccionDosisEnvasadas({ list }) {  
  try {
    yield put(openSimpleModal('onDeleteDosisEnvasadas'))
    const confirmed = yield call(yesNoModalSaga, { modalType: 'onDeleteDosis' })    
    if (confirmed) {
      let auth = yield select(state => state.auth)
      //let filtros = yield select(state => state.envasado.filtros)
      const { eliminado } = yield call(deleteSeleccionDosisEnvasadasService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      
      //recargar listados
      let filtros = yield select(state=>state.asignacionPedidos.filtros);
      filtros = typeof(filtros)==='undefined' ? null : filtros;
      yield call(fetchLineasAlbaranes, {filtros});
      yield call(obtenerInformeStockDosisSaga, {values:filtros});     
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchDeleteSeleccionDosisEnvasadas() {
  yield takeLatest(actionTypesAsignacionPedido.DELETE_SELECCION_DOSIS_ENVASADAS, deleteSeleccionDosisEnvasadas)
}


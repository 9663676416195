import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import './MensajeAlerta.scss'

class MensajeAlerta extends Component {

  componentDidUpdate(prevProps, prevState){
    /* if (prevProps.showMensajeAlerta) {
      setTimeout(() => this.props.actions.closeMensajeAlerta(), 3000)
    } */
    if (this.props.showMensajeAlerta && !prevProps.showMensajeAlerta) {
      setTimeout(() => this.props.actions.closeMensajeAlerta(), 3000)
    }
  }

  render () {
    const { 
      t, showMensajeAlerta, typeAlerta, customTextAlerta, customTextAlertaError,
      actions: { closeMensajeAlerta}
    } = this.props

    let message = ''
    let error = false

    switch (typeAlerta) {
      case 'insercion':
         message = t('MENSAJE_ALERTA.INSERCION')
      break
      case 'no-insercion':
         message = t('MENSAJE_ALERTA.NO_INSERCION')
         error = true
      break
      case 'modificacion':
         message = t('MENSAJE_ALERTA.MODIFICACION')
      break
      case 'no-modificacion':
         message = t('MENSAJE_ALERTA.NO_MODIFICACION')
         error = true
      break
      case 'eliminacion':
         message = t('MENSAJE_ALERTA.ELIMINACION')
      break
      case 'no-eliminacion':
         message = t('MENSAJE_ALERTA.NO_ELIMINACION')
         error = true
      break
      case 'cambio-estado':
         message = t('MENSAJE_ALERTA.CAMBIO_ESTADO')
      break
      case 'no-cambio-estado':
         message = t('MENSAJE_ALERTA.NO_CAMBIO_ESTADO')
         error = true
      break
      case 'operario-procesado':
         message = t('MENSAJE_ALERTA.CAMBIO_OPERARIO_PROCESADO')
      break
      case 'no-operario-procesado':
         message = t('MENSAJE_ALERTA.NO_CAMBIO_OPERARIO_PROCESADO')
         error = true
      break
      case 'operario-albaran':
         message = t('MENSAJE_ALERTA.CAMBIO_OPERARIO_ALBARAN')
      break
      case 'no-operario-albaran':
         message = t('MENSAJE_ALERTA.NO_CAMBIO_OPERARIO_ALBARAN')
         error = true
      break
      case 'cambio-vigilado':
         message = t('MENSAJE_ALERTA.CAMBIO_VIGILADO')
      break
      case 'no-cambio-vigilado':
         message = t('MENSAJE_ALERTA.NO_CAMBIO_VIGILADO')
         error = true
      break
      case 'ubicacion-libre':
         message = t('MENSAJE_ALERTA.UBICACION_LIBRE')
      break
      case 'ubicacion-no-libre':
         message = t('MENSAJE_ALERTA.UBICACION_NO_LIBRE')
         error = true
      break
      case 'cambio-eyaculado-eliminado':
        message = t('MENSAJE_ALERTA.CAMBIO_EYACULADO_ELIMINADO')
      break
      case 'no-cambio-eyaculado-eliminado':
        message = t('MENSAJE_ALERTA.NO_CAMBIO_EYACULADO_ELIMINADO')
        error = true
      break
      case 'actualizacion':
        message = t('MENSAJE_ALERTA.ACTUALIZADO')
      break
      case 'no-actualizacion':
        message = t('MENSAJE_ALERTA.NO_ACTUALIZADO')
        error = true
      break
      case 'conectado':
        message = t('MENSAJE_ALERTA.CONECTADO')
      break
      case 'no-conectado':
        message = t('MENSAJE_ALERTA.NO_CONECTADO')
        error = true
      break
      case 'custom-text':
        message = customTextAlerta
        error = customTextAlertaError
      break
      case 'insercion-planing':
         message = t('MENSAJE_ALERTA.INSERCION_PLANING')
      break
      case 'no-insercion-planing':
         message = t('MENSAJE_ALERTA.NO_INSERCION_PLANING')
         error = true
      break
      case 'cambioEstadoEliminado':
         message = t('MENSAJE_ALERTA.CAMBIO_ESTADO_ELIMINADO')
      break
      case 'no-insercion-pool':
         message = t('MENSAJE_ALERTA.NO_INSERCION_POOL')
         error = true
      break
      case 'confirmacion':
         message = t('MENSAJE_ALERTA.CONFIRMADO')
       break
      case 'no-confirmacion':
         message = t('MENSAJE_ALERTA.NO_CONFIRMADO')
         error = true
      break
      case 'error-agview':
         message = t('MENSAJE_ALERTA.ERROR_AGVIEW')
         error = true
      break
      case 'insercion-dataswine':
         message = t('MENSAJE_ALERTA.INSERCION_DATASWINE')
      break
      case 'no-insercion-dataswine':
         message = t('MENSAJE_ALERTA.NO_INSERCION_DATASWINE')
         error = true
      break
      case 'sincronizacion-dataswine':
         message = t('MENSAJE_ALERTA.SINCRONIZACION_DATASWINE')
      break
      case 'no-sincronizacion-dataswine':
         message = t('MENSAJE_ALERTA.NO_SINCRONIZACION_DATASWINE')
         error = true
      break
      default:
        message = ''
    }

    return (
      <Modal show={showMensajeAlerta} onHide={closeMensajeAlerta} className={error === true ? 'error-mensaje-alerta' : 'mensaje-alerta'} restoreFocus={false} backdrop={true}>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
      </Modal>
    )
  }
}
export default MensajeAlerta

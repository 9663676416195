import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import filtros from '../../components/raza/FiltrosRaza'
import {openModal} from '../../actions/common'
import {abrirModalVacioRaza, fetchFiltrarRaza, seleccionarTodosRaza, deSeleccionarTodosRaza, cambiarEstadoSeleccionRaza, deleteSeleccionRaza,
crearCsvRaza, imprimirPdfRaza } from '../../actions/raza/raza'
import {comboCodRaza, comboRaza} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.raza,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    formFiltrosRaza: state.form.filtrosRaza
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioRaza,
      comboCodRaza,
      comboRaza,
      fetchFiltrarRaza,
      seleccionarTodosRaza,
      deSeleccionarTodosRaza,
      cambiarEstadoSeleccionRaza,
      deleteSeleccionRaza,
      crearCsvRaza,
      imprimirPdfRaza
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(filtros))
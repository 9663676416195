import actionTypes from '../../../constants/actions/planSanitario/tipoAlimentacion/tipoAlimentacion'

export function initialState () {
  return {
    list: [],
    checkedFiltrosTipoAlimentacion: false,
    datosExcelTipoAlimentacion: [],
    datosPdfTipoAlimentacion: []
  }
}

export function fetchTipoAlimentacionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function seleccionarTodosTipoAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosTipoAlimentacion: true
  }
}

export function deSeleccionarTodosTipoAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosTipoAlimentacion: false
  }
}

export function recuperarDatosModalTipoAlimentacionSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalTipoAlimentacionSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function crearCsvTipoAlimentacionSuccess (state, {datosExcelTipoAlimentacion}) {
  return {
    ...state,
    datosExcelTipoAlimentacion
  }
}

export function imprimirPdfTipoAlimentacionSuccess (state, {datosPdfTipoAlimentacion}) {
  return {
    ...state,
    datosPdfTipoAlimentacion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_TIPO_ALIMENTACION_SUCCESS:
      return fetchTipoAlimentacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TIPO_ALIMENTACION:
      return seleccionarTodosTipoAlimentacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TIPO_ALIMENTACION:
      return deSeleccionarTodosTipoAlimentacion(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS:
      return recuperarDatosModalTipoAlimentacionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS:
      return duplicarDatosModalTipoAlimentacionSuccess(state, action)
    case actionTypes.CREATE_CSV_TIPO_ALIMENTACION_SUCCESS:
      return crearCsvTipoAlimentacionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_TIPO_ALIMENTACION_SUCCESS:
      return imprimirPdfTipoAlimentacionSuccess(state, action)
    default:
      return state
  }
}
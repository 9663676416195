import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTipoInstalacion from '../../components/tipoInstalacion/ModalTipoInstalacion'
import {recuperarDatosModalTipoInstalacion, submitNuevaTipoInstalacion, editarTipoInstalacion, guardarYnuevoTipoInstalacion} from '../../actions/tipoInstalacion/tipoInstalacion'
import {closeModal} from '../../actions/common'
import {comboTipoInstalacionPadre} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.tipoInstalacion,
    showModal: state.common.showModal,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalTipoInstalacion,
      submitNuevaTipoInstalacion,
      editarTipoInstalacion,
      closeModal,
      guardarYnuevoTipoInstalacion,
      comboTipoInstalacionPadre
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTipoInstalacion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Ruta from '../../components/ruta/Ruta'
import { fetchRuta, recuperarDatosModalRuta, duplicarDatosModalRuta, cambiarEstadoRuta, onDeleteRuta, fetchFiltrarRuta } from '../../actions/ruta/ruta'

export function mapStateToProps (state) {
  return {
    ruta: {...state.ruta},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    showModal: state.common.showModal,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRuta,
      recuperarDatosModalRuta,
      duplicarDatosModalRuta,
      cambiarEstadoRuta,
      onDeleteRuta,
      fetchFiltrarRuta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Ruta))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalDetallePedido from '../../components/pedidosVenta/ModalDetallePedido'
import {cerrarDetallesPedido} from '../../actions/pedidosVenta/pedidosVenta'
import {comboClienteActivo} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDetallesPedido
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDetallePedido))
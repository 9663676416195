import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalMovimientoVerraco from '../../components/movimientoVerraco/ModalMovimientoVerraco'
import {abrirModalUbicacion, cerrarModalUbicacion} from '../../actions/fichaVerraco/fichaVerraco'
import {comboUbicacion, comboVerraco, comboVerracoSinUbicacion} from '../../actions/combos/combos'
import { recuperarDatosModalMovimientoVerraco, submitNuevoMoviVerraco, ubicacionenesVacias, enviarFechaMovimientoVerraco, editarMoviVerraco, quitarCheckVerracosSinUbicacion, checkVerracosSinUbicacion, guardarYNuevoMovimiento, cerrarModalUbicacionMovimientoVerraco, fetchMovimientoVerraco } from '../../actions/movimientoVerraco/movimientoVerraco'

export function mapStateToProps (state) {
  return {
    auth: state.auth,
    showModalUbicacion: state.fichaVerraco.showModalUbicacion,
    combos: {...state.combos},
    fichaVerraco: {...state.fichaVerraco},
    movimientoVerraco: {...state.movimientoVerraco},
    formMovimientoVerraco: state.form.modalMovimientoVerraco
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalMovimientoVerraco,
      cerrarModalUbicacion,
      abrirModalUbicacion,
      comboUbicacion,
      comboVerraco,
      submitNuevoMoviVerraco,
      ubicacionenesVacias,
      enviarFechaMovimientoVerraco,
      editarMoviVerraco,
      comboVerracoSinUbicacion,
      quitarCheckVerracosSinUbicacion,
      checkVerracosSinUbicacion,
      guardarYNuevoMovimiento,
      cerrarModalUbicacionMovimientoVerraco,
      fetchMovimientoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalMovimientoVerraco))
import actionTypes from '../../constants/actions/panelControl/panelControl'
import {tipoAnalisisExtraccion, concentracionDisponible} from '../../constants/analisis'

export function initialState () {
  return {
    tipoAnalisisPredeterminado: null,
    // espermatozoidesContar: 100,
    concentracionUtilizada: concentracionDisponible.TOTAL,
    ConcentracionDosisSinCorrecciones: false,
    ConcentracionDosisFormasAnormales: false,
    ConcentracionDosisMotilesProgresivos: false,
    ConcentracionDosisMotilesTotales: false,
    ConcentracionDosisMotilidad: false,
    AlertaFACabezaMaxima: 5,
    AlertaFaColaMaxima: 10,
    AlertaFAGDistalMaxima: 15,
    AlertaFAGProximalMaxima: 15,
    AlertaFAGTotalMaxima: 30,
    AlertaMotilidadMasalMaxima: 5.5,
    AlertaMotilidadMasalMinima: 2.7,
    AlertaMotilidadMaxima: 100,
    AlertaMotilidadMinima: 70,
    AlertaMotilidadProgresivaMinimo: 40,
    AlertaMotilidadProgresivaMaximo: 100,
    AlertaVolumenMaximo: 800,
    AlertaVolumenMinimo: 100,
    AlertaConcentracionTotalMaximo: 800,
    AlertaConcentracionTotalMinimo: 100,
    AlertaMotilidadTotalMinimo: 70,
    AlertaMotilidadTotalMaximo: 100,
    TextoAlbaranVentasA: null,
    TextoAlbaranVentasB: null,
    TextoInformeVisitas: null,
    showModalTeclasContaje: false,
    teclasContaje: {},
    showModalTipoDiluyente: false,
    listTipoDiluyente: [],
    checkedTipoDiluyente: false,
    dataTipoDiluyente: [],
    dataDuplicarTipoDiluyente: [],
    showModalTipoDosis: false,
    dataTipoDosis: [],
    dataDuplicarTipoDosis: [],
    listTipoDosis: [],
    showModalRectaColorimetro: false,
    checkedColorimetro: false,
    listColorimetro: [],
    showModalAnadirColorimetro: false,
    listMuestrasColorimetro: [],
    checkedVerracosAnadidos: false,
    listVerracosAnadidos: [],
    listColorimetro: [],
    dataRectaColorimetro: [],
    dataDuplicarRectaColorimetro: [],
    showModalAnadirMuestra: false,
    muestrasAnadidas: [],
    muestrasRecuperar: [],
    muestrasDuplicar: [],
    showModalAnadirVerracos: false,
    listVerracos: [],
    verracosAnadidos: [],
    checkedVerracos: false,
    checkedMuestras: false,
    datosPanel: [],
    formula: '',
    formulaR: '',
    listSecuenciaNumeraciones: [],
    dataSecuenciaNumeraciones: [],
    showModalSecuenciasNumeracion: false,
    urlMagavision: ''
  }
}

export function fetchPanelControlSuccess (state, {data}) {
  return {
    ...state,
    ...data,
    tipoAnalisisPredeterminado: data.metodoEvaluacion,
    espermatozoidesContar: data.espermatozoidesContar,
    concentracionUtilizada: data.ConcentracionAplicada ? concentracionDisponible.TOTAL : concentracionDisponible.UTIL
  }
}

export function abrirModalTeclasContaje (state) {
  return {
    ...state,
    showModalTeclasContaje: true
  }
}

export function cerrarModalTeclasContaje (state) {
  return {
    ...state,
    showModalTeclasContaje: false,
    teclasContaje: {}
  }
}

export function valoresPredefinidosTeclasContaje (state) {
  return {
    ...state,
    teclasContaje: {
      sumarAcrAnormales: 'r',
      sumarBuenos: 'l',
      sumarCabezas: 'm',
      sumarColas: 'd',
      sumarContado: 'i',
      sumarCuadros: 'w',
      sumarGotaDistal: 'g',
      sumarGotaProximal: 'j',
      sumarVideo: 'y',
      restarAcrAnormales: 't',
      restarBuenos: 'q',
      restarCabezas: 's',
      restarColas: 'f',
      restarContado: 'o',
      restarCuadros: 'e',
      restarGotaDistal: 'h',
      restarGotaProximal: 'k',
      restarVideo: 'u'
    }
  }
}

export function obtenerDatosPanelControlSuccess (state, {panelControl}) {
  return {
    ...state,
    datosPanel: panelControl,
  }
}

export function obtenerValoresTeclasContajeSuccess (state, {teclasContaje}) {
  return {
    ...state,
    teclasContaje: teclasContaje,
  }
}

export function abrirModalTipoDiluyente (state) {
  return {
    ...state,
    showModalTipoDiluyente: true
  }
}

export function cerrarModalTipoDiluyente (state) {
  return {
    ...state,
    showModalTipoDiluyente: false,
    dataTipoDiluyente: [],
    dataDuplicarTipoDiluyente: []
  }
}

export function obtenerTipoDiluyenteSuccess (state, {tipoDiluyente}) {
  return {
    ...state,
    listTipoDiluyente: tipoDiluyente,
    dataTipoDiluyente: [],
    dataDuplicarTipoDiluyente: []
  }
}

export function recuperarDatosTipoDiluyenteSuccess (state, {dataTipoDiluyente}) {
  return {
    ...state,
    dataTipoDiluyente,
    dataDuplicarTipoDiluyente: []
  }
}

export function duplicarDatosModalTipoDiluyenteSuccess (state, {dataDuplicarTipoDiluyente}) {
  return {
    ...state,
    dataDuplicarTipoDiluyente,
    dataTipoDiluyente: []
  }
}

export function cambiaPredeterminadoDiluyenteSuccess (state, {tipoDiluyentePredeterminado}) {
  return {
    ...state,
    tipoDiluyentePredeterminado
  }
}

export function seleccionarTodosTipoDiluyente (state) {
  return {
    ...state,
    checkedTipoDiluyente: true
  }
}

export function deSeleccionarTodosTipoDiluyente (state) {
  return {
    ...state,
    checkedTipoDiluyente: false
  }
}

export function abrirModalTipoDosis (state) {
  return {
    ...state,
    showModalTipoDosis: true
  }
}

export function cerrarModalTipoDosis (state) {
  return {
    ...state,
    showModalTipoDosis: false,
    dataTipoDosis: [],
    dataDuplicarTipoDosis: []
  }
}

export function obtenerTipoDosisSuccess (state, {tipoDosis}) {
  return {
    ...state,
    listTipoDosis: tipoDosis,
    dataTipoDosis: [],
    dataDuplicarTipoDosis: []
  }
}

export function recuperarDatosTipoDosisSuccess (state, {dataTipoDosis}) {
  return {
    ...state,
    dataTipoDosis,
    dataDuplicarTipoDosis: []
  }
}

export function duplicarDatosModalTipoDosisSuccess (state, {dataDuplicarTipoDosis}) {
  return {
    ...state,
    dataDuplicarTipoDosis,
    dataTipoDosis: []
  }
}

export function cambiaPredeterminadoDosisSuccess (state, {tipoDosisPredeterminado}) {
  return {
    ...state,
    tipoDosisPredeterminado
  }
}

export function seleccionarTodosTipoDosis (state) {
  return {
    ...state,
    checkedTipoDosis: true
  }
}

export function deSeleccionarTodosTipoDosis (state) {
  return {
    ...state,
    checkedTipoDosis: false
  }
}

export function abrirModalRectaColorimetro (state) {
  return {
    ...state,
    showModalRectaColorimetro: true
  }
}

export function cerrarModalRectaColorimetro (state) {
  return {
    ...state,
    showModalRectaColorimetro: false
  }
}

export function abrirModalAnadirColorimetro (state) {
  return {
    ...state,
    showModalAnadirColorimetro: true,
    formula: "Y= ax + (b)"
  }
}

export function cerrarModalAnadirColorimetro (state) {
  return {
    ...state,
    showModalAnadirColorimetro: false,
    dataRectaColorimetro: [],
    formula: '',
    formulaR: '',
    dataDuplicarRectaColorimetro: [],
    showModalAnadirVerracos: false,
    showModalAnadirMuestras: false
  }
}

export function fetchRectaColorimetroSuccess (state, {listColorimetro}) {
  return {
    ...state,
    listColorimetro: listColorimetro
  }
}

export function recuperarDatosRectaColorimetroSuccess (state, {dataRectaColorimetro}) {
  return {
    ...state,
    dataRectaColorimetro: dataRectaColorimetro,
    showModalAnadirColorimetro: true,
    showModalAnadirVerracos: false,
    showModalAnadirMuestras: false
  }
}

export function duplicarDatosModalRectaColorimetroSuccess (state, {dataDuplicarRectaColorimetro}) {
  return {
    ...state,
    dataDuplicarRectaColorimetro: dataDuplicarRectaColorimetro,
    dataRectaColorimetro: [],
    showModalAnadirColorimetro: true,
    showModalAnadirVerracos: false,
    showModalAnadirMuestras: false
  }
}

export function verFormula (state, {formula}) {
  return {
    ...state,
    formula: formula
  }
}

export function verFormulaR (state, {formulaR}) {
  return {
    ...state,
    formulaR: formulaR
  }
}

export function abrirModalAnadirMuestras (state) {
  return {
    ...state,
    showModalAnadirMuestra: true
  }
}

export function cerrarModalAnadirMuestras (state) {
  return {
    ...state,
    showModalAnadirMuestra: false,
    muestrasRecuperar: [],
    muestrasDuplicar: []
  }
}

export function anadirMuestra (state, {muestrasAnadidas}) {
  return {
    ...state,
    muestrasAnadidas: state.muestrasAnadidas.concat(muestrasAnadidas),
    showModalAnadirMuestra: false,
    muestrasRecuperar: [],
    muestrasDuplicar: []
  }
}

export function guardarYnuevoMuestras (state, {muestrasAnadidas}) {
  return {
    ...state,
    muestrasAnadidas: state.muestrasAnadidas.concat(muestrasAnadidas),
    muestrasRecuperar: [],
    muestrasDuplicar: []
  }
}

export function recuperarDatosMuestrasSuccess (state, {muestrasRecuperar}) {
  return {
    ...state,
    showModalAnadirMuestra: true,
    muestrasRecuperar
  }
}

export function editMuestrasAnadidasSuccess (state, {muestrasAnadidas}) {
  return {
    ...state,
    muestrasAnadidas,
    showModalAnadirMuestra: false,
    muestrasRecuperar: [],
    muestrasDuplicar: [],
    checkedMuestras: false
  }
}

export function duplicarDatosModalMuestrasSuccess (state, {muestrasDuplicar}) {
  return {
    ...state,
    showModalAnadirMuestra: true,
    muestrasDuplicar
  }
}

export function reiniciarMuestraVerraco (state) {
  return {
    ...state,
    muestrasAnadidas: [],
    verracosAnadidos: []
  }
}

export function seleccionarTodosColorimetro (state) {
  return {
    ...state,
    checkedColorimetro: true
  }
}

export function deSeleccionarTodosColorimetro (state) {
  return {
    ...state,
    checkedColorimetro: false
  }
}

export function abrirModalAnadirVerracos (state) {
  return {
    ...state,
    showModalAnadirVerracos: true
  }
}

export function cerrarModalAnadirVerracos (state) {
  return {
    ...state,
    showModalAnadirVerracos: false
  }
}

export function fetchListadoVerracosSuccess (state, {listVerracos}) {
  return {
    ...state,
    listVerracos: listVerracos
  }
}

export function seleccionarTodosVerracos (state) {
  return {
    ...state,
    checkedVerracos: true
  }
}

export function deSeleccionarTodosVerracos (state) {
  return {
    ...state,
    checkedVerracos: false
  }
}

export function anadirVerracos (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos: state.verracosAnadidos.concat(verracosAnadidos),
    showModalAnadirVerracos: false
  }
}

export function editarVerracos (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos: verracosAnadidos,
    showModalAnadirVerracos: false
  }
}

export function seleccionarTodosVerracosAnadidos (state) {
  return {
    ...state,
    checkedVerracosAnadidos: true
  }
}

export function deSeleccionarTodosVerracosAnadidos (state) {
  return {
    ...state,
    checkedVerracosAnadidos: false
  }
}

export function seleccionarTodosMuestas (state) {
  return {
    ...state,
    checkedMuestras: true
  }
}

export function deSeleccionarTodosMuestas (state) {
  return {
    ...state,
    checkedMuestras: false
  }
}

export function uploadFilePanelControl (state, {imagen}) {
  return {
    ...state,
    imagen: imagen
  }
}

export function uploadLogoPanelControl (state, {logo}) {
  return {
    ...state,
    logo: logo
  }
}

export function onDeleteVerracosSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos
  }
}

export function calculoRecta (state, {formula}) {
  return {
    ...state,
    formula: formula.formula,
    formulaR: formula.formulaR
  }
}

export function recuperarSecuenciaNumeracionesSuccess (state, {dataSecuenciaNumeraciones}) {
  return {
    ...state,
    dataSecuenciaNumeraciones: dataSecuenciaNumeraciones
  }
}

export function abrirModalSecuenciasNumeracion (state) {
  return {
    ...state,
    showModalSecuenciasNumeracion: true
  }
}

export function cerrarModalSecuenciasNumeracion (state) {
  return {
    ...state,
    showModalSecuenciasNumeracion: false
  }
}

export function fecthSecuenciasNumeracionSuccess (state, {listSecuenciaNumeraciones}) {
  return {
    ...state,
    listSecuenciaNumeraciones: listSecuenciaNumeraciones
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_PANEL_CONTROL_SUCCESS:
      return fetchPanelControlSuccess(state, action)
    case actionTypes.ABRIR_MODAL_TECLAS_CONTAJE:
      return abrirModalTeclasContaje(state, action)
    case actionTypes.CERRAR_MODAL_TECLAS_CONTAJE:
      return cerrarModalTeclasContaje(state, action)
    case actionTypes.VALORES_PREDEFINIDOS_TECLAS_CONTAJE:
      return valoresPredefinidosTeclasContaje(state, action)
    case actionTypes.OBTENER_VALORES_TECLA_CONTAJE_SUCCESS:
      return obtenerValoresTeclasContajeSuccess(state, action)
    case actionTypes.ABRIR_MODAL_TIPO_DILUYENTE:
      return abrirModalTipoDiluyente(state, action)
    case actionTypes.CERRAR_MODAL_TIPO_DILUYENTE:
      return cerrarModalTipoDiluyente(state, action)
    case actionTypes.OBTENER_TIPO_DILUYENTE_SUCCESS:
      return obtenerTipoDiluyenteSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_TIPO_DILUYENTE_SUCCESS:
      return recuperarDatosTipoDiluyenteSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_TIPO_DILUYENTE_SUCCESS:
      return duplicarDatosModalTipoDiluyenteSuccess(state, action)
    case actionTypes.CAMBIA_PREDETERMINADO_DILUYENTE_SUCCESS:
      return cambiaPredeterminadoDiluyenteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TIPO_DILUYENTE:
      return seleccionarTodosTipoDiluyente(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TIPO_DILUYENTE:
      return deSeleccionarTodosTipoDiluyente(state, action)
    case actionTypes.ABRIR_MODAL_TIPO_DOSIS:
      return abrirModalTipoDosis(state, action)
    case actionTypes.CERRAR_MODAL_TIPO_DOSIS:
      return cerrarModalTipoDosis(state, action)
    case actionTypes.OBTENER_TIPO_DOSIS_SUCCESS:
      return obtenerTipoDosisSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_TIPO_DOSIS_SUCCESS:
      return recuperarDatosTipoDosisSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_TIPO_DOSIS_SUCCESS:
      return duplicarDatosModalTipoDosisSuccess(state, action)
    case actionTypes.CAMBIA_PREDETERMINADO_DOSIS_SUCCESS:
      return cambiaPredeterminadoDosisSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TIPO_DOSIS:
      return seleccionarTodosTipoDosis(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TIPO_DOSIS:
      return deSeleccionarTodosTipoDosis(state, action)
    case actionTypes.ABRIR_MODAL_RECTA_COLORIMETRO:
      return abrirModalRectaColorimetro(state, action)
    case actionTypes.CERRAR_MODAL_RECTA_COLORIMETRO:
      return cerrarModalRectaColorimetro(state, action)
    case actionTypes.ABRIR_MODAL_ANADIR_COLORIMETRO:
      return abrirModalAnadirColorimetro(state, action)
    case actionTypes.CERRAR_MODAL_ANADIR_COLORIMETRO:
      return cerrarModalAnadirColorimetro(state, action)
    case actionTypes.FETCH_RECTA_COLORIMETRO_SUCCESS:
      return fetchRectaColorimetroSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_RECTA_COLORIMETRO_SUCCESS:
      return recuperarDatosRectaColorimetroSuccess(state, action)
    case actionTypes.DUPLICAR_COLORIMETRO_SUCCESS:
      return duplicarDatosModalRectaColorimetroSuccess(state, action)
    case actionTypes.VER_FORMULA:
      return verFormula(state, action)
    case actionTypes.VER_FORMULA_R:
      return verFormulaR(state, action)
    case actionTypes.ABRIR_MODAL_ANADIR_MUESTRAS:
      return abrirModalAnadirMuestras(state, action)
    case actionTypes.CERRAR_MODAL_ANADIR_MUESTRAS:
      return cerrarModalAnadirMuestras(state, action)
    case actionTypes.ANADIR_MUESTRA:
      return anadirMuestra(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_MUESTRAS:
      return guardarYnuevoMuestras(state, action)
    case actionTypes.RECUPERAR_DATOS_MUESTRAS_SUCCESS:
      return recuperarDatosMuestrasSuccess(state, action)
    case actionTypes.EDIT_MUESTAS_ANADIDAS_SUCCESS:
      return editMuestrasAnadidasSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MUESTRAS_SUCCESS:
      return duplicarDatosModalMuestrasSuccess(state, action)
    case actionTypes.REINICIAR_MUESTRA_VERRACO:
      return reiniciarMuestraVerraco(state, action)
    case actionTypes.SELECCIONAR_TODOS_COLORIMETRO:
      return seleccionarTodosColorimetro(state, action)
    case actionTypes.DESELECCIONAR_TODOS_COLORIMETRO:
      return deSeleccionarTodosColorimetro(state, action)
    case actionTypes.ABRIR_MODAL_ANADIR_VERRACOS:
      return abrirModalAnadirVerracos(state, action)
    case actionTypes.CERRAR_MODAL_ANADIR_VERRACOS:
      return cerrarModalAnadirVerracos(state, action)
    case actionTypes.FETCH_LISTADO_VERRACOS_SUCCESS:
      return fetchListadoVerracosSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACOS:
      return seleccionarTodosVerracos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACOS:
      return deSeleccionarTodosVerracos(state, action)
    case actionTypes.ANADIR_VERRACOS:
      return anadirVerracos(state, action)
    case actionTypes.SELECCIONAR_VERRACOS_ANADIDOS:
      return seleccionarTodosVerracosAnadidos(state, action)
    case actionTypes.DESELECCIONAR_VERRACOS_ANADIDOS:
      return deSeleccionarTodosVerracosAnadidos(state, action)
    case actionTypes.SELECCIONAR_TODOS_MUESTRAS:
      return seleccionarTodosMuestas(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MUESTRAS:
      return deSeleccionarTodosMuestas(state, action)
    case actionTypes.EDITAR_VERRACOS:
      return editarVerracos(state, action)
    case actionTypes.UPLOAD_FILE_PANEL_CONTROL:
      return uploadFilePanelControl(state, action)
    case actionTypes.UPLOAD_LOGO_PANEL_CONTROL:
        return uploadLogoPanelControl(state, action)
    case actionTypes.OBTENER_DATOS_PANEL_CONTROL_SUCCESS:
      return obtenerDatosPanelControlSuccess(state, action)
    case actionTypes.ON_DELETE_VERRACOS_SUCCESS:
      return onDeleteVerracosSuccess(state, action)
    case actionTypes.CALCULO_RECTA:
      return calculoRecta(state, action)
    case actionTypes.RECUPERAR_SECUENCIA_NUMERACIONES_SUCCESS:
      return recuperarSecuenciaNumeracionesSuccess(state, action)
    case actionTypes.ABRIR_MODAL_SECUENCIAS_NUMERACION:
      return abrirModalSecuenciasNumeracion(state, action)
    case actionTypes.CERRAR_MODAL_SECUENCIAS_NUMERACION:
      return cerrarModalSecuenciasNumeracion(state, action)
    case actionTypes.FETCH_SECUENCIAS_NUMERACION_SUCCESS:
      return fecthSecuenciasNumeracionSuccess(state, action)
    default:
      return state
  }
}

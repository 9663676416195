import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import TipoInstalacion from '../../components/tipoInstalacion/TipoInstalacion'
import {fetchTipoInstalacion, recuperarDatosModalTipoInstalacion, duplicarDatosModalTipoInstalacion, cambiarEstadoTipoInstalacion, onDeleteTipoInstalacion, fetchFiltrarTipoInstalacion
  } from '../../actions/tipoInstalacion/tipoInstalacion'

export function mapStateToProps (state) {
  return {
    tipoInstalacion: {...state.tipoInstalacion},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTipoInstalacion,
      recuperarDatosModalTipoInstalacion,
      duplicarDatosModalTipoInstalacion,
      cambiarEstadoTipoInstalacion,
      onDeleteTipoInstalacion,
      fetchFiltrarTipoInstalacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TipoInstalacion))
import actionTypes from '../../constants/actions/PICTraq/PICTraq'

export function fetchAnalisisParaPIC(filtros){
  return{ 
    type: actionTypes.FETCH_ANALISIS_PARA_PIC,
    filtros
  }
}

export function fetchAnalisisParaPICSuccess(listAnalisis){      
  return{ 
    type: actionTypes.FETCH_ANALISIS_PARA_PIC_SUCCESS,
    listAnalisis
  }
}

export function fetchPoolSplitsParaPIC(filtros){    
  return{ 
    type: actionTypes.FETCH_POOL_SPLITS_PARA_PIC,
    filtros
  }
}

export function fetchPoolParaPICSuccess(listPool){      
  return{ 
    type: actionTypes.FETCH_POOL_PARA_PIC_SUCCESS,
    listPool:listPool
  }
}

export function fetchSplitsParaPICSuccess(listSplits){
  return{ 
    type: actionTypes.FETCH_SPLIT_PARA_PIC_SUCCESS,
    listSplits:listSplits
  }
}

export function fetchPICTraits(){
  return{ 
    type: actionTypes.FETCH_PIC_TRAITS,    
  }
}

export function fetchPICTraitsSuccess(listTraits){        
  return{ 
    type: actionTypes.FETCH_PIC_TRAITS_SUCCESS,
    listTraits
  }
}
export function insertSplitsPIC(lists){
  return{ 
    type: actionTypes.INSERT_SPLITS_PIC,
    lists
  }
}
export function insertPoolPIC(lists){
  return{ 
    type: actionTypes.INSERT_POOL_PIC,
    lists
  }
}

export function insertPoolPICSuccess(response){
  return{ 
    type: actionTypes.INSERT_POOL_PIC_SUCCESS,    
    response
  }
}

export function insertSplitsPICSuccess(response){
  return{
    type: actionTypes.INSERT_SPLITS_PIC_SUCCESS,
    response
  }
}

export function saveDataSplitsPIC(){
  return{ 
    type: actionTypes.SAVE_DATA_SPLITS_PIC
  }
}
export function saveDataPoolPIC(){
  return{ 
    type: actionTypes.SAVE_DATA_POOL_PIC
  }
}

export function saveDataPoolPICSuccess(response){
  return{
    type: actionTypes.SAVE_DATA_POOL_PIC_SUCCESS,
    response
  }
}

export function saveDataSplitsPICSuccess(response){
  return{
    type: actionTypes.SAVE_DATA_SPLITS_PIC_SUCCESS,
    response
  }
}

export function insertAnalisisPIC(listAnalisis,listTraits){
  return{ 
    type: actionTypes.INSERT_ANALISIS_PIC,    
    listAnalisis,
    listTraits
  }
}

export function insertAnalisisPICSuccess(response){
  return{ 
    type: actionTypes.INSERT_ANALISIS_PIC_SUCCESS,    
    response
  }
}
export function resetAnalisisPIC(){
  return{ 
    type: actionTypes.RESET_ANALISIS_PIC,  
    listAnalisis: [] 
  }
}


export function saveDataPIC(){
  return{ 
    type: actionTypes.SAVE_DATE_PIC
  }
}

export function saveDataPICSuccess(response){
  return{ 
    type: actionTypes.SAVE_DATE_PIC_SUCCESS,    
    response
  }
}


export function getUrlPIC(){
  return{ 
    type: actionTypes.GET_URL_PIC
  }
}

export function getUrlPICSuccess(response){
  return{ 
    type: actionTypes.GET_URL_PIC_SUCCESS,    
    url: response
  }
}


export function sincronizarIndexPIC(){
  return{ 
    type: actionTypes.SINCRONIZAR_INDEX_PIC
  }
}

export function sincronizarIndexPICSuccess(listVerracosIndex, updatedIndex){
  return{ 
    type: actionTypes.SINCRONIZAR_INDEX_PIC_SUCCESS,    
    listVerracosIndex,
    updatedIndex
  }
}


export default {
  fetchAnalisisParaPIC,
  fetchAnalisisParaPICSuccess,
  fetchPoolSplitsParaPIC,
  fetchPoolParaPICSuccess,
  fetchSplitsParaPICSuccess,
  insertPoolPIC,
  insertPoolPICSuccess,
  insertSplitsPIC,
  saveDataPoolPIC,
  saveDataPoolPICSuccess,
  saveDataSplitsPIC,
  saveDataSplitsPICSuccess,
  fetchPICTraits,
  fetchPICTraitsSuccess,
  insertAnalisisPIC,
  insertAnalisisPICSuccess,
  resetAnalisisPIC,
  saveDataPIC,
  saveDataPICSuccess,
  getUrlPIC,
  getUrlPICSuccess,
  sincronizarIndexPIC,
  sincronizarIndexPICSuccess
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosRegistroAlimentacion from '../../../components/planSanitario/registroAlimentacion/FiltrosRegistroAlimentacion'
import {openModal} from '../../../actions/common'
import {abrirModalVacioRegistroAlimentacion, seleccionarTodosRegistroAlimentacion, deSeleccionarTodosRegistroAlimentacion, cambiarEstadoSeleccionRegistroAlimentacion, deleteSeleccionRegistroAlimentacion, fetchFiltrarVerracoModalRegistroAlimentacion, deSeleccionarTodosModalVerracoRegistroAlimentacion,
  fetchFiltrarRegistroAlimentacion, crearCsvRegistroAlimentacion, imprimirPdfRegistroAlimentacion} from '../../../actions/planSanitario/registroAlimentacion/registroAlimentacion'
import {comboVerraco, comboRaza, comboLineaGenetica, comboNombreGrupoVerraco, comboUbicacionPadre} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.registroAlimentacion,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioRegistroAlimentacion,
      seleccionarTodosRegistroAlimentacion,
      deSeleccionarTodosRegistroAlimentacion,
      cambiarEstadoSeleccionRegistroAlimentacion,
      deleteSeleccionRegistroAlimentacion,
      fetchFiltrarRegistroAlimentacion,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      comboNombreGrupoVerraco,
      comboUbicacionPadre,
      crearCsvRegistroAlimentacion,
      imprimirPdfRegistroAlimentacion,
      fetchFiltrarVerracoModalRegistroAlimentacion,
      deSeleccionarTodosModalVerracoRegistroAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosRegistroAlimentacion))
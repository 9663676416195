import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Col, FormGroup, ControlLabel} from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Numerical from './Numerical'
import './InputText.scss'

const defaultNumDecimales = 2

class InputNumerical extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange (event, val) {
    if (""+val !==  ""+this.props.input.value) {
      val = ("" + val).replace(this.props.auth.separadorDec, '.')
      this.props.input.onChange(val)
      if (this.props.onInputChange) {
        setTimeout((() => {
          this.props.onInputChange(val, val)
        }).bind(this), 200)
      }
    }
  }

  handleFocus (event) {
    let val = ("" + event.target.value).replace(this.props.auth.separadorDec, '.')
    this.props.input.onFocus(event)
    if (this.props.onInputFocus) {
      this.props.onInputFocus(event, val)
    }
  }

  handleBlur (event) {
    let val = ("" + event.target.value).replace(this.props.auth.separadorDec, '.')
    this.props.input.onBlur(val)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(event, val)
    }
  }

  handleClick (event) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  componentDidUpdate (prevProps) {
    if (""+this.props.input.value !== ""+prevProps.input.value && ""+this.props.input.value !== ""+this.props.meta.initial) {
      let value = ("" + this.props.input.value).replace(this.props.auth.separadorDec, '.')
      if (this.props.onInputChange) {
        this.props.onInputChange(null, value)
      }
    }
  }

  render () {
    const {
      t, id, colSm, customClass, disabled, placeholder = null, componentClass, bsClass, controlLabel, showOnlyInput = false, inline = false,
      numDecimales = defaultNumDecimales, minValue, maxValue, textoValorR, validarTextoValorR, autoComplete = "on", setMyRef, textoValorTotalDosis, diasMedicarMayorQueIntervalo,
      input: {value}, meta: { touched, error }
    } = this.props
    const errorMessage = (touched && error) ? error : null
    const onInputChangeDelay = (this.props.onInputChangeDelay ? (this.props.onInputChangeDelay === true ? 500 : this.props.onInputChangeDelay) : 0)

    let input = <Numerical
      id={id}
      disabled={disabled}
      type="text"
      placeholder={placeholder}
      title={controlLabel}
      bsClass={bsClass}
      componentClass={componentClass}
      className={inline ? ' inline' : ''}
      value={value}
      autoComplete={autoComplete}
      onChange={this.handleChange}
      onClick={this.handleClick}
      onFocus={this.handleFocus}
      onBlur={this.handleBlur}
      numDecimales={numDecimales}
      minValue={minValue}
      maxValue={maxValue}
      setMyRef={setMyRef}
      onInputChangeDelay={onInputChangeDelay}
    />

    if (showOnlyInput) return input

    return (
      <Col sm={colSm} className={customClass}>
        <FormGroup validationState={errorMessage || validarTextoValorR === false || textoValorTotalDosis === true || diasMedicarMayorQueIntervalo === true ? 'error' : null}>
        {controlLabel && <ControlLabel>{controlLabel}{errorMessage && <span className='help-block'><Warning /></span>}
        {validarTextoValorR === false && <span className='help-block'><Warning /></span>}
        {textoValorTotalDosis === true && <span className='help-block'><Warning /></span>}
        {diasMedicarMayorQueIntervalo === true && <span className='help-block'><Warning /></span>}</ControlLabel>}
          {input}
          {
            // errorMessage && <span className='help-block'><Warning /><span>{errorMessage}</span></span>
          }
          {
            textoValorR === true && (
              <span style={{color: '#c1c1c1', fontStyle: 'italic', fontSize: '10px'}}>{t('COMUN.VALIDAR_VALOR_R')}</span>
            )
          }
          {
            textoValorTotalDosis === true && (
              <span style={{color: '#c1c1c1', fontStyle: 'italic', fontSize: '10px'}}>{t('COMUN.VALIDAR_VALOR_TOTAL_DOSIS')}</span>
            )
          }
          {
            diasMedicarMayorQueIntervalo === true && (
              <span style={{color: '#c1c1c1', fontStyle: 'italic', fontSize: '10px'}}>{t('COMUN.DIAS_MEDICAR_MAYOR_QUE_INTERVALO')}</span>
            )
          }
        </FormGroup>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(InputNumerical)

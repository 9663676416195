import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { yesNoModal } from '../../actions/common'
import { withTranslation } from 'react-i18next'
import AsignacionPedido from '../../components/asignacionPedidos/AsignacionPedido'
import { abrirDetallesAlbaran, imprimirAlbaran } from '../../actions/albaran/albaran'
import { abrirModalAsignacionDosis, fetchLineasAlbaranes, actualizarFiltrosAsignacionPedidos } from '../../actions/asignacionPedidos/asignacionPedidos'
import { obtenerInformeStockDosis } from '../../actions/informes/informes'
export function mapStateToProps(state) {
  return {
    ...state,
    showModal: state.common.showModal,
    list: state.albaran.list.albaranes,
    listDosis: state.informes.listInformeStockDosis,    
    listLineasAlbaran: state.asignacionPedidos.listLineasAlbaranesPendientes,
    filtros: state.asignacionPedidos.filtros,
    combos: {
      comboTipoDosisGranja: state.combos.comboTipoDosisGranja,
      comboRazaGranja: state.combos.comboRazaGranja,
      comboLineaGeneticaGranja: state.combos.comboLineaGeneticaGranja,
      comboPoolsExtraccionAlbaran: state.combos.comboPoolsExtraccionAlbaran,
      comboVerracosExtraccionAlbaran: state.combos.comboVerracosExtraccionAlbaran
    }
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      abrirDetallesAlbaran,
      imprimirAlbaran,
      abrirModalAsignacionDosis,
      obtenerInformeStockDosis,
      fetchLineasAlbaranes,
      actualizarFiltrosAsignacionPedidos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AsignacionPedido))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInicioNuevoSeguimiento from '../../components/seguimiento/ModalInicioNuevoSeguimiento'
import { closeModalNuevoSeguimiento, openModalFormSeguimiento, deleteSeguimiento } from '../../actions/seguimiento/seguimiento'
import {comboOperarioActivo} from '../../actions/combos/combos'


export function mapStateToProps (state) {
  return {
    ...state.seguimiento,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalNuevoSeguimiento,
      openModalFormSeguimiento,
      deleteSeguimiento,
      comboOperarioActivo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInicioNuevoSeguimiento))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAjusteStock from '../../components/ajusteStock/FiltrosAjusteStock'
import {
  fetchAjusteStock, openModalAjusteStock, crearCsvAjusteStock, imprimirPdfAjusteStock
} from '../../actions/ajusteStock/ajusteStock'
import {comboProductosActivosTrazabilidad, comboLotesProductoEspecificoActivoTrazabilidad} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ajusteStock: state.ajusteStock,
    combos: {
      comboProductosActivosTrazabilidad: state.combos.comboProductosActivosTrazabilidad,
      comboLotesProductoEspecificoActivoTrazabilidad: state.combos.comboLotesProductoEspecificoActivoTrazabilidad
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAjusteStock,
      openModalAjusteStock,
      crearCsvAjusteStock,
      imprimirPdfAjusteStock,
      comboProductosActivosTrazabilidad,
      comboLotesProductoEspecificoActivoTrazabilidad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAjusteStock))
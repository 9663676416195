import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesIntervencionesPage from '../../containers/informes/FiltrosInformesIntervencionesPage'
import {date_formatter, number_formatter} from '../../util/formatFunctions'

class InformesIntervenciones extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.INTERVENCIONES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeIntervenciones, totalesIntervenciones, totalIntervenciones,
      auth: {separadorDec, separadorMil}
    } = this.props

    const tableInformeIntervenciones = {
      id: 'informeIntervencionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'fechaInicio', name: t('INFORMES.INTERVENCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'tipoIntervencion', name: t('INFORMES.INTERVENCIONES.TABLA.INTERVENCION')},
        {id: 'codVerraco', name: t('INFORMES.INTERVENCIONES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.INTERVENCIONES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.INTERVENCIONES.TABLA.LIN_GENETICA')},
        {id: 'ubicacionverraco', name: t('INFORMES.INTERVENCIONES.TABLA.UBICACION')},
        {id: 'grupoVerraco', name: t('INFORMES.INTERVENCIONES.TABLA.GRUPO_VERRACO')},
        {id: 'estadoverraco', name: t('INFORMES.INTERVENCIONES.TABLA.ESTADO')},
        {id: 'producto', name: t('INFORMES.INTERVENCIONES.TABLA.PRODUCTO')},
        {id: 'terminado', name: t('INFORMES.INTERVENCIONES.TABLA.TERMINADO')},
        {id: 'supresion', name: t('INFORMES.INTERVENCIONES.TABLA.PERIODO_SUPRESION')}
      ],
      rows: listInformeIntervenciones,
      filtros: <FiltrosInformesIntervencionesPage />,
      initialValues: {}
    }

    return (
      <div>
        <h2 className="page-title">{t('INFORMES.INTERVENCIONES.TITLE')}</h2>
        <SimpleTablePage {...tableInformeIntervenciones}>
        </SimpleTablePage>
        <div className="totales-informes" style={{width: '20%'}}>
          <Col sm={8} className="titulo-totales-informes"><span>{t('INFORMES.INTERVENCIONES.TOTALES.NUMERO_ANIMALES')}</span></Col>
          <Col sm={4}><span>{totalIntervenciones && number_formatter(totalIntervenciones, 0, separadorDec, separadorMil)}</span></Col>
        </div>
      </div>
    )
  }
}

export default InformesIntervenciones
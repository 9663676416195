import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimplePage from '../../containers/page/SimplePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import FiltrosAsignacionPedidosPage from '../../containers/asignacionPedidos/FiltrosAsignacionPedidoPage'
import ModalNuevoPoolAsignacionPage from '../../containers/asignacionPedidos/ModalAsignacionPedidoPage';
import ModalAlbaranPage from '../../containers/albaran/ModalAlbaranPage'

class AsignacionPedido extends Component {
  constructor() {
    super();
    this.state = {
      idDireccionCliente: null,
      idRaza: null,
      idLineaGenetica: null,
      idRowDosis: null,
      idRowDosisAnterior: -1,
      idRowAlbaran: null,
      idRowAlbaranAnterior: -1
    }
    //this.RowClickCallback = this.RowClickCallback.bind(this);
    this.dragstartCallback = this.dragstartCallback.bind(this);
    this.dragendCallback = this.dragendCallback.bind(this);
    this.dropCallback = this.dropCallback.bind(this);
    this.dropCellCallback = this.dropCellCallback.bind(this);
    this.dosisDisponiblesClickCallback = this.dosisDisponiblesClickCallback.bind(this);
    this.lineasAlbaranClickCallback = this.lineasAlbaranClickCallback.bind(this);
  }

  /*RowClickCallback(row, idRow) {
    this.setState({ 
      idAlbaranSeleccionado: row.idAlbaranDosis,
      
    },
      this.updateTableInfo());
  }

  updateTableInfo() {
    this.props.actions.abrirDetallesAlbaran(this.state.idAlbaranSeleccionado);
  }*/

  asignarDosisAPedido(idDosis, idAlbaranDosis, idDireccionCliente, idRaza, idLinea, idTipoDosis, idNivelGenetico, advertenciaNivel) {    
    this.props.actions.abrirModalAsignacionDosis( idDosis, idAlbaranDosis, idDireccionCliente, idRaza, idLinea, idTipoDosis, idNivelGenetico, advertenciaNivel );
  }


  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    //if (this.props.filtros != prevProps.filtros){      
    //}
  }


  dragstartCallback(e, row, idRow) {   
    this.setState({
      idRowDosisAnterior: (this.state.idRowDosis != this.state.idRowDosisAnterior) ? this.state.idRowDosis : -1,
      idRowDosis: idRow,
      idRaza: row.idRaza,
      idTipoDosis: row.idTipoDosis,
      raza: row.nombreRaza,
      nombreTipoDosis: row.nombreTipoDosis
    },
      this.updateTableInfo
    );    
    e.dataTransfer.setData('row', JSON.stringify(row));
    e.dataTransfer.setData('originId', e.currentTarget.id);
  }

  dragendCallback() {
    this.setState({
      annadiendo: false,
      eliminando: false
    });
  }


  // este callback se activa en la tabla, pero necesita tener un pool seleccionado, que se guarda en el estado
  dropCallback(e) {

  }

  dosisDisponiblesClickCallback(row, idRow) {
    this.setState({
      idRowDosisAnterior: (this.state.idRowDosis != this.state.idRowDosisAnterior) ? this.state.idRowDosis : -1,
      idRowDosis: idRow,
      idRaza: row.idRaza,
      idTipoDosis: row.idTipoDosis,
      raza: row.nombreRaza,
      nombreTipoDosis: row.nombreTipoDosis
    },
      this.updateTableInfo
    );
  }

  updateTableInfo() {       
    let filtros = { ...this.props.filtros, raza: { value: this.state.idRaza, label: this.state.raza }, tipoDosis: {value: this.state.idTipoDosis, label: this.state.nombreTipoDosis}}   
    if (this.state.idRowDosis == this.state.idRowDosisAnterior) {
      filtros = { ...this.props.filtros, raza: null, tipoDosis: null }
    }
    this.props.actions.fetchLineasAlbaranes(filtros)
  }

  lineasAlbaranClickCallback(row, idRow) {
    this.setState({
      idRowAlbaranAnterior: (this.state.idRowAlbaran != this.state.idRowAlbaranAnterior) ? this.state.idRowAlbaran : -1,
      idRowAlbaran: idRow,
      idRaza: row.idRaza,
      idTipoDosis: row.idTipoDosis,
      raza: row.nombreRaza,
      nombreTipoDosis: row.tiposDosis
    },
      this.updateTableDosisInfo
    );
  }

  updateTableDosisInfo() {       
    let filtros = { ...this.props.filtros, raza: { value: this.state.idRaza, label: this.state.raza }, tipoDosis: {value: this.state.idTipoDosis, label: this.state.nombreTipoDosis}}   
    if (this.state.idRowAlbaran == this.state.idRowAlbaranAnterior) {
      filtros = { ...this.props.filtros, raza: null, tipoDosis: null }
    }
    this.props.actions.obtenerInformeStockDosis(filtros)
    this.props.actions.actualizarFiltrosAsignacionPedidos(filtros)
  }

  // Este callback se usa porque se puede enviar a la celda en que se suelte
  dropCellCallback(e, row) {        
    let originId = e.dataTransfer.getData('originId');    
    if (originId === 'dosisDisponibles' && e.currentTarget.id === 'lineasAlbaran') {      
      let envasadoSeleccionado = JSON.parse(e.dataTransfer.getData('row'));
      let albaranSeleccionado = row;
      this.sendDosisPedido(envasadoSeleccionado,albaranSeleccionado)
    }
  }


  asignarDosis(row) {
    //validacion
    if(this.state.idRowDosis == null || this.state.idRowDosis == this.state.idRowDosisAnterior){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_SELECCIONE_DOSIS'))
      return false
    }

    let listDosisMostrar = this.parselistDosis(this.props.listDosis)

    let envasadoSeleccionado = listDosisMostrar[this.state.idRowDosis]
    let albaranSeleccionado = row;

    this.sendDosisPedido(envasadoSeleccionado,albaranSeleccionado)
  }



  sendDosisPedido(envasadoSeleccionado, albaranSeleccionado){
    let idDosis = envasadoSeleccionado.idEnvasadoMultiDosis
    if (!this.comprobarRestriccionesFront(envasadoSeleccionado,albaranSeleccionado)) return false;
    this.setState({
      albaranSeleccionado,
      envasadoSeleccionado,
      dosisMaximas: envasadoSeleccionado.numeroDosisDisponibles < albaranSeleccionado.dosisPedidas ? envasadoSeleccionado.numeroDosisDisponibles:albaranSeleccionado.dosisPedidas
    }) 
    let advertenciaNivel = false
    if(envasadoSeleccionado.idNivelGenetico < albaranSeleccionado.idNivelGenetico){
      advertenciaNivel = true
    }    
    this.asignarDosisAPedido(idDosis, albaranSeleccionado.idAlbaranDosis, albaranSeleccionado.idDireccionCliente, envasadoSeleccionado.idRaza, envasadoSeleccionado.idLinea, envasadoSeleccionado.idTipoDosis, envasadoSeleccionado.idNivelGenetico, advertenciaNivel);
  }

  
  comprobarRestriccionesFront(envasado,albaran){ 
    // console.log(envasado)
    // console.log(albaran)
    if(albaran.dosisPedidas == albaran.dosisEnviadas){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_DOSIS_COMPLETA'))
      return false
    }
    if(envasado.idRaza != albaran.idRaza){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_RAZA_DISTINTA'))
      return false
    }
    if(envasado.idTipoDosis != albaran.idTipoDosis){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_TIPO_DOSIS_DISTINTA'))
      return false
    }
    if(albaran.idLinea && envasado.idLinea && envasado.idLinea != albaran.idLinea){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_LINEA_DISTINTA'))
      return false
    }
    if(albaran.codVerraco && envasado.verracoPool != albaran.codVerraco){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_VERRACO_DISTINTA'))
      return false
    }    
    if(albaran.prioridad != null && (envasado.prioridad > albaran.prioridad || envasado.prioridad == null)){
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ERROR_NIVEL_GENETICO'))
      return false
    }    
    return true
  }


  parselistDosis(listDosis){
    let listDosisMostrar = []
    Object.keys(listDosis).forEach(key => {if(listDosis[key].numeroDosisDisponibles>0){ listDosisMostrar.push(listDosis[key]) }} );
    return listDosisMostrar
  }
  

  render() {
    const {
      t,
      combos: { comboVerracosExtraccionAlbaran },
      actions:{
        imprimirAlbaran, abrirDetallesAlbaran
      },
      listDosis = [], listLineasAlbaran = [],
      showModal

    } = this.props;

    const tKey = 'PEDIDOS.'    
   // const idElemento = 'idAnalisis'
    const idElemento = 'idEnvasadoMultiDosis'

    let listDosisMostrar = this.parselistDosis(listDosis)    

    const tableDosisDisponibles = {
      id: 'dosisDisponibles',
      multiHeader: false,
      hasCheckbox: true,      
      idElemento,      
      hasPagination: false,
      isDraggable: true,
      dragstartCallback: this.dragstartCallback,
      dragendCallback: this.dragendCallback,
      dropCallback: this.dropCallback,
      onClickCallback: this.dosisDisponiblesClickCallback,
      columns:
        [
          {id: 'fechaEnvasado', name: t('INFORMES.STOCK_DOSIS.TABLA.FECHA'), type: 'fecha'},
          {id: 'nombreRaza', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_RAZA')},
          {id: 'nombreLinea', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_LINEA')},
          {id: 'nombreTipoDosis', name: t('INFORMES.STOCK_DOSIS.TABLA.TIPO_DOSIS')},
          {id: 'verracoPool', name: t('INFORMES.STOCK_DOSIS.TABLA.VERRACO_POLL')},
          {id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX'), type: 'numero', numDecimales: 0},
          {id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
          {id: 'numeroDosisRestantes', name: t('ALBARANES.MODAL.MODAL_MULTIDOSIS.FORM.DOSIS_RESTANTES'), type: 'numero', numDecimales: 0 },
          {id: 'numeroDosisReservadas', name: t('ALBARANES.MODAL.MODAL_MULTIDOSIS.FORM.DOSIS_RESERVADAS'), type: 'numero', numDecimales: 0 },
          {id: 'numeroDosisDisponibles', name: t('ALBARANES.MODAL.MODAL_MULTIDOSIS.FORM.DOSIS_DISPONIBLES'), type: 'numero', numDecimales: 0 },
        ],
      rows: listDosisMostrar,
      //filtros: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado ? <FiltrosFormPoolExtraccionesPage idElemento={idElemento} idForm={idForm} /> : null,
      leyenda: [
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.VERRACO'), color: '#FF6D29'},
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.POOL'), color: 'rgb(37, 56, 170)'}
      ],
      initialValues: {},
      classNameTr: (idRow, row) => {
        let classTr= ''
        if(this.state.idRowDosis != null && this.state.idRowDosis == idRow && this.state.idRowDosis != this.state.idRowDosisAnterior){
          classTr= 'selected-row'
        }
        return classTr
      },
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo === true){
          classRow = 'stock-dosis-verraco'
        } else if (row.tipo === false) {
          classRow = 'stock-dosis-pool'
        }
        return classRow
      }
    }

    // const tableAlbaranes = {
    //   id: 'albaranes',
    //   multiHeader: false,
    //   idElemento,
    //   hasPagination: false,
    //   dropCellCallback: this.dropCellCallback,
    //   isDraggable: true,
    //   columns:
    //     [
    //       // { id: 'idAlbaran', name: 'idAlbaran' },
    //       { id: 'numeroAlbaran', name: t('ALBARANES.MODAL.FORM.NUM_ALBARAN'), type: 'numero', numDecimales: 0 },
    //       { id: 'numeroDosis', name: t('PEDIDOS.PEDIDOS_VENTA.NUM_DOSIS'), type: 'numero', numDecimales: 0 },
    //       { id: 'nombreDireccion', name: t('TRANSPORTE.NUEVO_TRANSPORTE.DIRECCION') },
    //       { id: 'tiposDosis', name: t('ALBARANES.MODAL.MODAL_MULTIDOSIS.FORM.TIPO_DOSIS') },
    //     ],
    //   rows: list,
    //   onClickCallback: this.RowClickCallback,
    //   //filtros: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado ? <FiltrosFormPoolExtraccionesPage idElemento={idElemento} idForm={idForm} /> : null,
    //   initialValues: {}
    // }

    const tableLineasAlbaran = {
      id: 'lineasAlbaran',
      multiHeader: false,
      idElemento,
      hasOptions: true,
      isDraggable: true,
      hasPagination: false,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarEliminar: false,
      //mostrarAlbaran:true,
      //mostrarImprimirAlbaran:true,
      mostrarCambioEstado: false,
      //onImprimirAlbaran:(row) => imprimirAlbaran(row.idAlbaran),
      onEdit: (row) => abrirDetallesAlbaran(row.idAlbaran),
      dropCellCallback: this.dropCellCallback,
      mostrarAsignar: true,
      onAsignar: (row) => this.asignarDosis(row),
      onClickCallback: this.lineasAlbaranClickCallback,
      columns:
        [
          
          { id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') },
          { id: 'numeroAlbaran', name: t('ALBARANES.MODAL.FORM.NUM_ALBARAN') },
          { id: 'fechaCreacion', name: t('INFORMES.STOCK_DOSIS.TABLA.FECHA'), type: 'fecha'},
          { id: 'nombreRuta', name: t('ALBARANES.MODAL.FORM.RUTA') },
          { id: 'tiposDosis', name: t('ALBARANES.MODAL.COLUMNS.TIPO_DOSIS') },
          { id: 'nombreRaza', name: t('ALBARANES.RAZA') },
          { id: 'nombreLinea', name: t('ALBARANES.MODAL.COLUMNS.LINEA_GENETICA') },
          { id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
          { id: 'codVerraco', name: t('ALBARANES.MODAL.COLUMNS.VERRACO_POOL') },
          { id: 'dosisPedidas', name: t('ALBARANES.MODAL.COLUMNS.DOSIS_PEDIDAS'), type: 'numero', numDecimales: 0},
          { id: 'dosisEnviadas', name: t('ALBARANES.MODAL.COLUMNS.DOSIS_ENVIADAS'), type: 'numero', numDecimales: 0},
        ],      
      rows: listLineasAlbaran,
      //filtros: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado ? <FiltrosFormPoolExtraccionesPage idElemento={idElemento} idForm={idForm} /> : null,
      leyenda: [
        {name: t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.PEDIDO_COMPLETO'), color: 'rgb(192, 210, 161)'},
        {name: t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.PEDIDO_EMPEZADO'), color: 'rgb(228, 216, 65)'},
        {name: t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.LINEA_COMPLETA'), color: 'rgb(37, 56, 170)'},
        //{name: 'Faltan dosis', color: '#FF6D29'}
      ],
      initialValues: {},           
      classNameTr: (idRow, row) => {
        let classTr= ''
        if(row.idAlbaranDosis == row.maxIdAlbaranDosis){
          classTr= 'separador-row'
        }
        if(this.state.idRowAlbaran != null && this.state.idRowAlbaran == idRow && this.state.idRowAlbaran != this.state.idRowAlbaranAnterior){
          classTr= 'selected-row'
        }
        return classTr
      },
      colorearRow: (row) => {
        let classRow = ''
        if ((row.sumDosisPedidas == null && row.sumDosisEnviadas > 0) || (row.sumDosisPedidas === row.sumDosisEnviadas)){
          classRow = 'albaran-completo'
        } else if ((row.dosisPedidas == null && row.dosisEnviadas > 0) || (row.dosisPedidas === row.dosisEnviadas)) {
          classRow = 'stock-dosis-pool'
        } else if (row.sumDosisEnviadas > 0) {
          classRow = 'extraccion-sin-analisis'
        }
        return classRow
      }
    }
    return (
      <div>
        <SimpleModalPage/>
        <ModalNuevoPoolAsignacionPage dosisInfo={this.state.envasadoSeleccionado} albaranInfo={this.state.albaranSeleccionado} dosisMaximas={this.state.dosisMaximas}/>
        <ModalAlbaranPage/>
        <SimplePage t_key={"PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS."}>
          <FiltrosAsignacionPedidosPage hideButton={true} idElemento={idElemento}/>
          <Row>
            <Col sm={6}>
              <h3>{t('INFORMES.STOCK_DOSIS.TABLA.DOSIS_DISPONIBLES')}</h3>
              <SimpleTablePage
                {...tableDosisDisponibles}>
              </SimpleTablePage>
            </Col>
            {/* <Col sm={4}>
              <h3>{t('ALBARANES.TITLE')}</h3>
              <SimpleTablePage
                {...tableAlbaranes}>
              </SimpleTablePage>
            </Col> */}
            <Col sm={6}>
              <h3>{t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.LINEAS_ALBARAN')}</h3>
              <SimpleTablePage
                {...tableLineasAlbaran}>
              </SimpleTablePage>
            </Col>
          </Row>
        </SimplePage>
      </div>
    )
  }
}

export default AsignacionPedido

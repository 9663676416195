export default {
  FETCH_REGISTRO_INTERVENCION_SANITARIA: 'FETCH_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'FETCH_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  ABRIR_MODAL_VACIO_REGISTRO_INTERVENCION_SANITARIA: 'ABRIR_MODAL_VACIO_REGISTRO_INTERVENCION_SANITARIA',
  SELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA: 'SELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA',
  DESELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA: 'DESELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA',
  SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA: 'SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA',
  SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  EDITAR_REGISTRO_INTERVENCION_SANITARIA: 'EDITAR_REGISTRO_INTERVENCION_SANITARIA',
  DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  VACIAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'VACIAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  CAMBIAR_ESTADO_REGISTRO_INTERVENCION_SANITARIA: 'CAMBIAR_ESTADO_REGISTRO_INTERVENCION_SANITARIA',
  ON_DELETE_REGISTRO_INTERVENCION_SANITARIA: 'ON_DELETE_REGISTRO_INTERVENCION_SANITARIA',
  CAMBIAR_ESTADO_SELECCION_REGISTRO_INTERVENCION_SANITARIA: 'CAMBIAR_ESTADO_SELECCION_REGISTRO_INTERVENCION_SANITARIA',
  DELETE_SELECCION_REGISTRO_INTERVENCION_SANITARIA: 'DELETE_SELECCION_REGISTRO_INTERVENCION_SANITARIA',
  GUARDAR_Y_NUEVO_REGISTRO_INTERVENCION_SANITARIA: 'GUARDAR_Y_NUEVO_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA: 'FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  ABRIR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA: 'ABRIR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA',
  CERRAR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA: 'CERRAR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA: 'FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA: 'SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA',
  DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA: 'DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA',
  ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  SELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'SELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  DESELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA: 'DESELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA',
  DELETE_SELECCION_VERRACOS_REGISTRO_INTERVENCION_SANITARIA: 'DELETE_SELECCION_VERRACOS_REGISTRO_INTERVENCION_SANITARIA',
  ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA: 'ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA',
  ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS: 'ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS',
  INICIALIZAR_VERRACO: 'INICIALIZAR_VERRACO',
  CREATE_CSV_REGISTRO_INTERVENCION: 'CREATE_CSV_REGISTRO_INTERVENCION',
  CREATE_CSV_REGISTRO_INTERVENCION_SUCCESS: 'CREATE_CSV_REGISTRO_INTERVENCION_SUCCESS',
  IMPRIMIR_PDF_REGISTRO_INTERVENCION: 'IMPRIMIR_PDF_REGISTRO_INTERVENCION',
  IMPRIMIR_PDF_REGISTRO_INTERVENCION_SUCCESS: 'IMPRIMIR_PDF_REGISTRO_INTERVENCION_SUCCESS',
  SUBIR_ARCHIVOS_INTERVENCION: 'SUBIR_ARCHIVOS_INTERVENCION',
  OBTENER_ARCHIVO_CONTENT_INTERVENCION: 'OBTENER_ARCHIVO_CONTENT_INTERVENCION',
  ELIMINAR_ARCHIVO_INTERVENCION: 'ELIMINAR_ARCHIVO_INTERVENCION',
  OBTENER_ARCHIVO_INTERVENCION_SUCCESS: 'OBTENER_ARCHIVO_INTERVENCION_SUCCESS',
  ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA: 'ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA',
  ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS: 'ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS',
  GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA: 'GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA',
  GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS: 'GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS',
  CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA: 'CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA',
  CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS: 'CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS',
  IMPRIMIR_INFORME_REGISTRO_INTERVENCION: 'IMPRIMIR_INFORME_REGISTRO_INTERVENCION',
  CLOSE_REGISTRO_INTERVENCION: 'CLOSE_REGISTRO_INTERVENCION',
  FETCH_REGISTRO_INTERVENCION_SANITARIA_IMPRIMIR_GUARDAR_SUCCESS: 'FETCH_REGISTRO_INTERVENCION_SANITARIA_IMPRIMIR_GUARDAR_SUCCESS'
}
import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosControlMorfologicoPage from '../../../containers/planSanitario/controlCalidad/FiltrosControlMorfologicoPage'
import ModalInicioControlMorfologicoPage from '../../../containers/planSanitario/controlCalidad/ModalInicioControlMorfologicoPage'
import ModalNuevoMagavisionPage from '../../../containers/analisisExtracciones/ModalNuevoMagavisionPage'
import ModalFormControlMorfologicoPage from '../../../containers/planSanitario/controlCalidad/ModalFormControlMorfologicoPage'
import ModalContajeMaquinasPage from '../../../containers/analisisExtracciones/ModalContajeMaquinasPage'
import { date_parser, dateTimeZones } from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {tipoAnalisisControlMorfologicoSeguimiento as tipo} from '../../../constants/comun'

class ControlMorfologico extends Component {
  componentDidMount () {
    this.props.actions.comboOperario()
    document.title = this.props.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_MORFOLOGICO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    this.props.actions.fetchControlMorfologico({fechaDesde: fechaActual, 'seleccionar-todo': false})
    this.props.actions.fetchPanelControl()
  }

  render () {
    const {
      mainList = [], checkedFiltros, t, numeroRegistrosPorPagina, paginaActual, 
      modalFormControlMorfologico: {data},
      actions: {openModalFormControlMorfologico, deleteControlMorfologico}
    } = this.props

    const tKey = 'PLAN_SANITARIO.CONTROL_MORFOLOGICO.'
    const idElemento = 'idControlMorfologico'
    const idForm = 'controlMorfologicoTable'
    const tableControlMorfologico = {
      id: 'controlMorfologicoTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1),
      idElemento,
      hasOptions: true,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      onEdit: (row) => openModalFormControlMorfologico(row.idControlMorfologico),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1),
      onDelete: (row) => deleteControlMorfologico(row),
      columns: [
        {id: 'idControlMorfologico', name: t(tKey + 'COLUMNS.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombreVerraco', name: t(tKey + 'COLUMNS.VERRACO')},
        {id: 'vigilado', name: t('FICHA_VERRACO.VIGILADO')},
        {id: 'nombreRaza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},
        {id: 'fecha', name: t(tKey + 'COLUMNS.FECHA'), type: 'fecha'},
        //{id: 'estado', name: t(tKey + 'COLUMNS.ESTADO')},
        {id: 'fechaControlMorfologico', name: t(tKey + 'COLUMNS.FECHA_CONTROL'), type: 'fecha'},
        {id: 'diasUltimoControl', name: t(tKey + 'COLUMNS.DIAS_ULTIMO_CONTROL'), type: 'numero', numDecimales: 0},
        // {id: 'nombreCentro', name: t(tKey + 'COLUMNS.CENTRO')},
        {id: 'totalAcrosomasPercent', name: t('ANALISIS_EXTRACCIONES.CONTAJE_MAQUINAS.COMUN.FORM.ACROSOMAS')},
        {id: 'faTotalPercent', name: t(tKey + 'COLUMNS.FORMAS_ANORMALES_TOTAL_PERCENT_FULL')}
      ],
      rows: mainList,
      filtros: <FiltrosControlMorfologicoPage idElemento={idElemento} idForm={idForm} />,
      initialValues: {}
    }
    tableControlMorfologico.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableControlMorfologico.initialValues['check' + idForm + row[idElemento]] = checkedFiltros
        }
      }
    )
    return (
      <div>
        <ModalInicioControlMorfologicoPage />
        <ModalNuevoMagavisionPage from={tipo.CONTROL_MORFOLOGICO} isPool={false} isSeguimiento={false} />
        <ModalFormControlMorfologicoPage />
        <ModalContajeMaquinasPage from={tipo.CONTROL_MORFOLOGICO} />
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ListadoPage t_key={tKey} table={tableControlMorfologico}>
        </ListadoPage>
      </div>
    )
  }
}

export default ControlMorfologico
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTrazabilidadMaterial from '../../components/trazabilidadMaterial/ModalTrazabilidadMaterial'
import {closeModalTrazabilidadMaterial, guardarModalTrazabilidadMaterial, openModalEditarTrazabilidadMaterial, deleteTrazabilidadMaterial} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'

export function mapStateToProps (state) {
  return {
    ...state.trazabilidadMaterial,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalTrazabilidadMaterial,
      guardarModalTrazabilidadMaterial,
      openModalEditarTrazabilidadMaterial,
      deleteTrazabilidadMaterial
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTrazabilidadMaterial))
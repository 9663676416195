import actionTypes from '../constants/actions/common'

export function initialState () {
  return {
    showModal: false,
    showSimpleModal: false,
    typeModal: '',
    dynamicMessage: null,
    subDynamicMessage: null,
    numeroRegistrosPorPagina: 50,
    paginaActual: 1,
    showMensajeAlerta: false,
    typeAlerta: '',
    customTextAlerta: '',
    isLoading: false,
    sortList: {},
    reloadComponent: {
      reloadComponentStatus: false,
      reloadComponentName: '',
      reloadComponentParam: null
    }
  }
}

export function setSortList (state, {sortList}) {
  return {
    ...state,
    sortList:sortList
  }
}

export function setIsLoadingTrue (state) {
  return {
    ...state,
    isLoading:true
  }
}

export function setIsLoadingFalse (state) {
  return {
    ...state,
    isLoading:false
  }
}

export function openModal (state) {
  return {
    ...state,
    showModal: true
  }
}

export function closeModal (state) {
  return {
    ...state,
    showModal: false,
    showSimpleModal: false
  }
}

export function openSimpleModal (state, {typeModal, dynamicMessage, subDynamicMessage}) {
  return {
    ...state,
    showSimpleModal: true,
    typeModal,
    dynamicMessage,
    subDynamicMessage
  }
}

export function seleccionarPorPagina (state, {numeroRegistrosPorPagina}) {
  return {
    ...state,
    numeroRegistrosPorPagina: numeroRegistrosPorPagina
  }
}

export function cambiarPagina (state, {numeroPagina}) {
  return {
    ...state,
    paginaActual: numeroPagina
  }
}

export function openMensajeAlerta (state, typeAlerta) {
  return {
    ...state,
    showMensajeAlerta: true,
    typeAlerta: typeAlerta.typeAlerta
  }
}

export function openMensajeAlertaCustomText (state, {text, error}) {
  return {
    ...state,
    showMensajeAlerta: true,
    typeAlerta: 'custom-text',
    customTextAlerta: text,
    customTextAlertaError: error
  }
}

export function closeMensajeAlerta (state) {
  return {
    ...state,
    showMensajeAlerta: false
  }
}

export function reloadComponent (state, {reloadComponentStatus, reloadComponentName, reloadComponentParam}) {
  return {
    ...state,
    reloadComponent: {
      reloadComponentStatus: reloadComponentStatus,
      reloadComponentName: reloadComponentName,
      reloadComponentParam: reloadComponentParam
    }
  }
}

export function resetReloadComponent (state) {
  return {
    ...state,
    reloadComponent: {
      reloadComponentStatus: false,
      reloadComponentName: '',
      reloadComponentParam: null
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return openModal(state, action)
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action)
    case actionTypes.OPEN_SIMPLE_MODAL:
      return openSimpleModal(state, action)
    case actionTypes.SELECCIONAR_POR_PAGINA:
      return seleccionarPorPagina(state, action)
    case actionTypes.CAMBIAR_PAGINA:
      return cambiarPagina(state, action)
    case actionTypes.OPEN_MENSAJE_ALERTA:
      return openMensajeAlerta(state, action)
    case actionTypes.OPEN_MENSAJE_ALERTA_CUSTOM_TEXT:
      return openMensajeAlertaCustomText(state, action)
    case actionTypes.CLOSE_MENSAJE_ALERTA:
      return closeMensajeAlerta(state, action)
    case actionTypes.SET_LOADING_TRUE:
      return setIsLoadingTrue(state, action)
    case actionTypes.SET_LOADING_FALSE:
      return setIsLoadingFalse(state, action)
    case actionTypes.SET_SORT_LIST:
      return setSortList(state, action)
    case actionTypes.RELOAD_COMPONENT:
      return reloadComponent(state, action)
    case actionTypes.RESET_RELOAD_COMPONENT:
      return resetReloadComponent(state, action)
    default:
      return state
  }
}
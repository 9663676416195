import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Sidebar from '../../components/layout/Sidebar'
import {abrirModalCargaIndex} from '../../actions/cargaIndex/cargaIndex'
import {abrirModalImportarDatos} from '../../actions/importarDatos/importarDatos'

export function  mapStateToProps (state) {
  return {
    showModalCargaIndex: state.cargaIndex.showModalCargaIndex,
    showModalImportarDatos: state.importarDatos.showModalImportarDatos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalCargaIndex,
      abrirModalImportarDatos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar))
import actionTypes from '../../constants/actions/comboCausaEliminacion/comboCausaEliminacion'

export function fetchComboCausaEliminacion () {
  return {
    type: actionTypes.FETCH_COMBO_CAUSA_ELIMINACION
  }
}

export function fetchComboCausaEliminacionSuccess (list) {
  return {
    type: actionTypes.FETCH_COMBO_CAUSA_ELIMINACION_SUCCESS,
    list
  }
}

export function submitNuevaComboCausaEliminacion (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION,
    values
  }
}

export function submitNuevaComboCausaEliminacionSuccess (insercion) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION_SUCCESS,
    insercion
  }
}

export function recuperarDatosModalComboCausaEliminacion (id) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION,
    id
  }
}

export function recuperarDatosModalComboCausaEliminacionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION_SUCCESS,
    data
  }
}

export function abrirModalVacioComboCausaEliminacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_COMBO_CAUSA_ELIMINACION
  }
}

export function editarComboCausaEliminacion (values) {
  return {
    type: actionTypes.EDITAR_COMBO_CAUSA_ELIMINACION,
    values
  }
}

export function editarComboCausaEliminacionSuccess (edicionComboCausaEliminacion) {
  return {
    type: actionTypes.EDITAR_COMBO_CAUSA_ELIMINACION_SUCCESS,
    edicionComboCausaEliminacion
  }
}

export default {
  fetchComboCausaEliminacion,
  fetchComboCausaEliminacionSuccess,
  submitNuevaComboCausaEliminacion,
  submitNuevaComboCausaEliminacionSuccess,
  recuperarDatosModalComboCausaEliminacion,
  recuperarDatosModalComboCausaEliminacionSuccess,
  abrirModalVacioComboCausaEliminacion,
  editarComboCausaEliminacion,
  editarComboCausaEliminacionSuccess
}

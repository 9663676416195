import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosLineaGenetica extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarLinea(values){
    this.props.actions.fetchFiltrarLinea(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosLineaGenetica()
    }
    if (this.props.datosExcelLinea !== prevProps.datosExcelLinea) {
      if (this.props.datosExcelLinea.excel && this.props.datosExcelLinea.excel !== null){
        let pdf = this.props.datosExcelLinea.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.LINEAS_GENETICAS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfLinea !== prevProps.datosPdfLinea) {
      if (this.props.datosPdfLinea.pdf && this.props.datosPdfLinea.pdf !== null){
        let pdf = this.props.datosPdfLinea.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.LINEAS_GENETICAS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosLineaGenetica()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboCodLineaGenetica()
    this.props.actions.comboLineaGenetica()
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosLineaGenetica()
    } else {
      this.props.actions.deSeleccionarTodosLineaGenetica()
    }
  }

  cambiarEstadoSeleccion(){
    const itemsSeleccion = this.props.list.lineasGeneticas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idLinea': row.idLinea,
          'cambiarEstado': row.cambiarEstado
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccion(list)
  }

  deleteSeleccion(){
    const itemsSeleccion = this.props.list.lineasGeneticas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idLinea': row.idLinea
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccion(list)
  }

  crearCsv(){
    this.props.actions.crearCsv(this.props.filtros)
  }

  imprimirPdfLinea () {
    this.props.actions.imprimirPdfLinea(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarLinea(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros, paginaActual,
    actions: {
      abrirModalVacio
    },
    combos: {
      comboCodLineaGenetica, comboLineaGenetica
    }
  } = this.props

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="codLinea"
              name="codLinea"
              controlLabel={t('LINEA_GENETICA.FILTROS.COD_LINEA_GENETICA')}
              component={InputSelect}
              options={comboCodLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-linea-genetica'
              disabled={false}
              onInputChange={handleSubmit(this.fetchFiltrarLinea.bind(this))}
            />
            <Field
              colSm={2}
              id="nombreLinea"
              name="nombreLinea"
              controlLabel={t('LINEA_GENETICA.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-linea-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarLinea.bind(this))}
            />
            <Field
              colSm={2}
              id="activo-filtro-liena"
              name="activo"
              controlLabel={t('ESTADO.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-linea-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarLinea.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('LINEA_GENETICA.FILTROS.FECHA_ENTRADA_ELIMINACION')}
              onInputChange={handleSubmit(this.fetchFiltrarLinea.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.codLinea !== null && filtros.codLinea !== '' && filtros.codLinea) ? (
                      <span className="badge-dark">{t('LINEA_GENETICA.FILTROS.COD_LINEA_GENETICA') + ': ' + filtros.codLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('codLinea')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nombreLinea !== null && filtros.nombreLinea !== '' && filtros.nombreLinea ? (
                      <span className="badge-dark">{t('LINEA_GENETICA.FILTROS.LINEA_GENETICA') + ': ' + filtros.nombreLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreLinea')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo && filtros.activo !== null ? (
                      <span className="badge-dark">{t('ESTADO.ESTADO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('LINEA_GENETICA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('LINEA_GENETICA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('LINEA_GENETICA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) && [
                <div className="custom-check-w" key="checkbox_key">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="blockOptions_key" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccion()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsv()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfLinea()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) ? abrirModalVacio : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosLineaGenetica',
})(FiltrosLineaGenetica))

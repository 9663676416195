import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalComboCausaEliminacion from '../../components/comboCausaEliminacion/ModalComboCausaEliminacion'
import { recuperarDatosModalComboCausaEliminacion, submitNuevaComboCausaEliminacion, editarComboCausaEliminacion } from '../../actions/comboCausaEliminacion/comboCausaEliminacion'
import { closeModal } from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.comboCausaEliminacion,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalComboCausaEliminacion,
      submitNuevaComboCausaEliminacion,
      editarComboCausaEliminacion,
      closeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalComboCausaEliminacion))
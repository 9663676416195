import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import { abrirMagavision } from '../../actions/analisisExtracciones/analisisExtracciones'
import FiltrosModalInicioNuevoSeguimiento from '../../components/seguimiento/FiltrosModalInicioNuevoSeguimiento'
import { fetchFiltrarModalNuevoSeguimiento, openModalFormSeguimiento } from '../../actions/seguimiento/seguimiento'


const mapStateToProps = (state) => {
  return {
    ...state.seguimiento,
    auth: state.auth,
    seguimientoTable: state.form.seguimientoTable,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchFiltrarModalNuevoSeguimiento,
      openModalFormSeguimiento,
      abrirMagavision
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalInicioNuevoSeguimiento))
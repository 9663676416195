import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import FiltrosRegistroIntervencionSanitariaPage from '../../../containers/planSanitario/registroIntervencionSanitaria/FiltrosRegistroIntervencionSanitariaPage'
import ModalRegistroIntervencionSanitariaPage from '../../../containers/planSanitario/registroIntervencionSanitaria/ModalRegistroIntervencionSanitariaPage'
// import ModalAgendaIntervencionSanitariaPage from '../../../containers/planSanitario/registroIntervencionSanitaria/ModalAgendaIntervencionSanitariaPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'
import ModalAgendaPage from '../../../containers/agenda/ModalAgendaPage'

class RegistroIntervencionSanitaria extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.REGISTRO_INTERVENCION_SANITARIA') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarRegistroIntervencionSanitaria({})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      registroIntervencionSanitaria:{list, checkedFiltrosRegistroIntervencionSanitaria, data, dataDuplicar},
      actions: {recuperarDatosModalRegistroIntervencionSanitaria, duplicarDatosModalRegistroIntervencionSanitaria, cambiarEstadoRegistroIntervencionSanitaria, onDeleteRegistroIntervencionSanitaria}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idIntervencion
    )

    const tableRegistroIntervencionSanitaria = {
      id: 'RegistroIntervencionSanitariaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1),
      hasOptions: true,
      columns: [
        {id: 'codigo', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.CODIGO')},
        {id: 'tipoIntervencion', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.TIPO_INTERVENCION')},
        {id: 'fechaInicio', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.FECHA_INICIO'), type:'fecha'},
        {id: 'producto', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.PRODUCTO')},
        {id: 'lote', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.LOTE')},
        {id: 'intervalo', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.INTERVALO'), type: 'numero', numDecimales: 0},
        {id: 'numeroverracos', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.NUM_VERRACO'), type: 'numero', numDecimales: 0},
        {id: 'numeroRecetaProducto', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.NUM_RECETA_PRODUCTO')},
        {id: 'observaciones', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.OBSERVACIONES')},
      ],
      mostrarCambioEstado: false,
      rows: list,
      filtros: <FiltrosRegistroIntervencionSanitariaPage/>,
      onEdit: (row) => recuperarDatosModalRegistroIntervencionSanitaria(row.idIntervencion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1,
      duplicar: (row) =>duplicarDatosModalRegistroIntervencionSanitaria(row.idIntervencion),
      dataDuplicar: dataDuplicar,
      cambiarEstado: (row) => cambiarEstadoRegistroIntervencionSanitaria(row.idIntervencion),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1,
      onDelete: (row) => onDeleteRegistroIntervencionSanitaria(row.idIntervencion),
      showModal: this.props.showModal,
      initialValues: {}
    }
    tableRegistroIntervencionSanitaria.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableRegistroIntervencionSanitaria.initialValues['check' + row.idElemento] = checkedFiltrosRegistroIntervencionSanitaria
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalRegistroIntervencionSanitariaPage 
          duplicar={(row) =>duplicarDatosModalRegistroIntervencionSanitaria(row.idIntervencion)}
        />
        <ModalAgendaPage
          tipoTarea={3}
          idIntervencion={Object.keys(data).length > 0 ? data.intervencion.idIntervencion : null}
        />
        <ListadoPage t_key="PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA." table={tableRegistroIntervencionSanitaria}>
        </ListadoPage>
      </div>
    )
  }
}

export default RegistroIntervencionSanitaria
import actionTypes from '../../constants/actions/trazabilidadMaterial/materialDisponible'

export function fetchMaterialDisponible(filtros){
  return {
    type: actionTypes.FETCH_MATERIAL_DISPONIBLE,
    filtros
  }
}

export function fetchMaterialDisponibleSuccess(lotes, filtros){
  return {
    type: actionTypes.FETCH_MATERIAL_DISPONIBLE_SUCCESS,
    lotes,
    filtros
  }
}

export function openModalMaterialDisponible(){
  return {
    type: actionTypes.OPEN_MODAL_MATERIAL_DISPONIBLE
  }
}

export function openModalMaterialDisponibleSuccess(){
  return {
    type: actionTypes.OPEN_MODAL_MATERIAL_DISPONIBLE_SUCCESS
  }
}

export function guardarModalMaterialDisponible(){
  return {
    type: actionTypes.GUARDAR_MODAL_MATERIAL_DISPONIBLE
  }
}

export function guardarModalMaterialDisponibleSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_MATERIAL_DISPONIBLE_SUCCESS
  }
}

export function closeModalMaterialDisponible(){
  return {
    type: actionTypes.CLOSE_MODAL_MATERIAL_DISPONIBLE
  }
}

export function closeModalMaterialDisponibleSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_MATERIAL_DISPONIBLE_SUCCESS
  }
}

export function seleccionarTodosMaterialDisponible(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MATERIAL_DISPONIBLE
  }
}

export function deSeleccionarTodosMaterialDisponible(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MATERIAL_DISPONIBLE
  }
}

export default {
  fetchMaterialDisponible,
  fetchMaterialDisponibleSuccess,
  openModalMaterialDisponible,
  openModalMaterialDisponibleSuccess,
  guardarModalMaterialDisponible,
  guardarModalMaterialDisponibleSuccess,
  closeModalMaterialDisponible,
  closeModalMaterialDisponibleSuccess,
  seleccionarTodosMaterialDisponible,
  deSeleccionarTodosMaterialDisponible
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVisita from '../../../components/planSanitario/visita/ModalVisita'
import {submitNuevoVisita, recuperarDatosModalVisita, editarVisita, guardarYnuevoVisita, restartVisitas, imprimirInformeVisita, cerrarModalVisitas, abrirModalVisitas} from '../../../actions/planSanitario/visita/visita'
import {closeModal} from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.visita,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevoVisita,
      recuperarDatosModalVisita,
      editarVisita,
      guardarYnuevoVisita,
      restartVisitas,
      imprimirInformeVisita,
      cerrarModalVisitas,
      abrirModalVisitas
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVisita))
export default {
  // INFORME EXTRACCIONES
  OBTENER_INFORME_EXTRACCIONES: 'OBTENER_INFORME_EXTRACCIONES',
  OBTENER_INFORME_EXTRACCIONES_SUCCESS: 'OBTENER_INFORME_EXTRACCIONES_SUCCESS',
  FETCH_FILTROS_SUCCESS: 'FETCH_FILTROS_SUCCESS',
  IMPRIMIR_PDF_INFORMES_EXTRACCIONES: 'IMPRIMIR_PDF_INFORMES_EXTRACCIONES',
  IMPRIMIR_PDF_INFORMES_EXTRACCIONES_SUCCESS: 'IMPRIMIR_PDF_INFORMES_EXTRACCIONES_SUCCESS',
  CREAR_CSV_INFORMES_EXTRACCIONES: 'CREAR_CSV_INFORMES_EXTRACCIONES',

  // INFORME STOCK DOSIS
  OBTENER_INFORME_STOCK_DOSIS: 'OBTENER_INFORME_STOCK_DOSIS',
  OBTENER_INFORME_STOCK_DOSIS_SUCCESS: 'OBTENER_INFORME_STOCK_DOSIS_SUCCESS',
  FETCH_FILTROS_STOCK_DOSIS_SUCCESS: 'FETCH_FILTROS_STOCK_DOSIS_SUCCESS',
  IMPRIMIR_PDF_INFORMES_STOCK_DOSIS: 'IMPRIMIR_PDF_INFORMES_STOCK_DOSIS',
  IMPRIMIR_PDF_INFORMES_STOCK_DOSIS_SUCCESS: 'IMPRIMIR_PDF_INFORMES_STOCK_DOSIS_SUCCESS',
  CREAR_CSV_INFORMES_STOCK_DOSIS: 'CREAR_CSV_INFORMES_STOCK_DOSIS',

  // INFORME POOL
  OBTENER_INFORME_POOL: 'OBTENER_INFORME_POOL',
  OBTENER_INFORME_POOL_SUCCESS: 'OBTENER_INFORME_POOL_SUCCESS',
  FETCH_FILTROS_POOL_SUCCESS: 'FETCH_FILTROS_POOL_SUCCESS',
  IMPRIMIR_PDF_INFORMES_POOL: 'IMPRIMIR_PDF_INFORMES_POOL',
  IMPRIMIR_PDF_INFORMES_POOL_SUCCESS: 'IMPRIMIR_PDF_INFORMES_POOL_SUCCESS',
  CREAR_CSV_INFORMES_POOL: 'CREAR_CSV_INFORMES_POOL',

  // INFORME PRODUCTIVIDAD EXTRACCIONES
  OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES: 'OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES',
  OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS: 'OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS',
  FETCH_FILTROS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS: 'FETCH_FILTROS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS',
  ABRIR_MODAL_PRODUCTIVIDAD_VERRACOS: 'ABRIR_MODAL_PRODUCTIVIDAD_VERRACOS',
  CERRAR_MODAL_PRODUCTIVIDAD_VERRACOS: 'CERRAR_MODAL_PRODUCTIVIDAD_VERRACOS',
  ABRIR_MODAL_RECOMENDACIONES_MATADERO: 'ABRIR_MODAL_RECOMENDACIONES_MATADERO',
  CERRAR_MODAL_RECOMENDACIONES_MATADERO: 'CERRAR_MODAL_RECOMENDACIONES_MATADERO',
  OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES: 'OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES',
  OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS: 'OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS',
  OBTENER_RECOMENDACIONES_MATADERO: 'OBTENER_RECOMENDACIONES_MATADERO',
  OBTENER_RECOMENDACIONES_MATADERO_SUCCESS: 'OBTENER_RECOMENDACIONES_MATADERO_SUCCESS',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS',
  CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES: 'CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES',
  CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_VERRACO: 'CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_VERRACO',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_VERRACO: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_VERRACO',
  CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_MATADERO: 'CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_MATADERO',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_MATADERO: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_MATADERO',

  // INFORME SEGUIMIENTO EXTRACCIONES/POOL
  OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL: 'OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL',
  OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS: 'OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS',
  FETCH_FILTROS_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS: 'FETCH_FILTROS_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS',
  OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL: 'OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL',
  OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL_SUCCESS: 'OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL_SUCCESS',
  IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL: 'IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL',
  IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS: 'IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS',
  ABRIR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL: 'ABRIR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL',
  CERRAR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL: 'CERRAR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL',
  EXPORTAR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL: 'EXPORTAR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL',

  // INFORME PRODUCTIVIDAD CENTRO
  OBTENER_INFORME_PRODUCTIVIDAD_CENTRO: 'OBTENER_INFORME_PRODUCTIVIDAD_CENTRO',
  OBTENER_INFORME_PRODUCTIVIDAD_CENTRO_SUCCESS: 'OBTENER_INFORME_PRODUCTIVIDAD_CENTRO_SUCCESS',
  FETCH_FILTROS_PRODUCTIVIDAD_CENTRO_SUCCESS: 'FETCH_FILTROS_PRODUCTIVIDAD_CENTRO_SUCCESS',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO',
  IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO_SUCCESS: 'IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO_SUCCESS',
  CREAR_CSV_INFORME_PRODUCTIVIDAD_CENTRO: 'CREAR_CSV_INFORME_PRODUCTIVIDAD_CENTRO',

  // INFORME TRAZABILIDAD
  OBTENER_INFORME_TRAZABILIDAD: 'OBTENER_INFORME_TRAZABILIDAD',
  OBTENER_INFORME_TRAZABILIDAD_SUCCESS: 'OBTENER_INFORME_TRAZABILIDAD_SUCCESS',
  FETCH_FILTROS_TRAZABILIDAD_SUCCESS: 'FETCH_FILTROS_TRAZABILIDAD_SUCCESS',
  IMPRIMIR_PDF_INFORMES_TRAZABILIDAD: 'IMPRIMIR_PDF_INFORMES_TRAZABILIDAD',
  IMPRIMIR_PDF_INFORMES_TRAZABILIDAD_SUCCESS: 'IMPRIMIR_PDF_INFORMES_TRAZABILIDAD_SUCCESS',
  CREAR_CSV_INFORME_TRAZABILIDAD: 'CREAR_CSV_INFORME_TRAZABILIDAD',

  // INFORME INTERVENCIONES
  OBTENER_INFORME_INTERVENCIONES: 'OBTENER_INFORME_INTERVENCIONES',
  OBTENER_INFORME_INTERVENCIONES_SUCCESS: 'OBTENER_INFORME_INTERVENCIONES_SUCCESS',
  FETCH_FILTROS_INTERVENCIONES_SUCCESS: 'FETCH_FILTROS_INTERVENCIONES_SUCCESS',
  IMPRIMIR_PDF_INFORMES_INTERVENCIONES: 'IMPRIMIR_PDF_INFORMES_INTERVENCIONES',
  IMPRIMIR_PDF_INFORMES_INTERVENCIONES_SUCCESS: 'IMPRIMIR_PDF_INFORMES_INTERVENCIONES_SUCCESS',
  CREAR_CSV_INFORME_INTERVENCIONES: 'CREAR_CSV_INFORME_INTERVENCIONES',

  // CLIENTE
  OBTENER_INFORME_CLIENTE: 'OBTENER_INFORME_CLIENTE',
  OBTENER_INFORME_CLIENTE_SUCCESS: 'OBTENER_INFORME_CLIENTE_SUCCESS',
  FETCH_FILTROS_CLIENTE_SUCCESS: 'FETCH_FILTROS_CLIENTE_SUCCESS',
  IMPRIMIR_PDF_INFORMES_CLIENTES: 'IMPRIMIR_PDF_INFORMES_CLIENTES',
  IMPRIMIR_PDF_INFORMES_CLIENTES_SUCCESS: 'IMPRIMIR_PDF_INFORMES_CLIENTES_SUCCESS',
  CREAR_CSV_INFORME_CLIENTES: 'CREAR_CSV_INFORME_CLIENTES',

  // ESTADO_CENTRO
  OBTENER_INFORME_ESTADO_CENTRO: 'OBTENER_INFORME_ESTADO_CENTRO',
  OBTENER_INFORME_ESTADO_CENTRO_SUCCESS: 'OBTENER_INFORME_ESTADO_CENTRO_SUCCESS',
  FETCH_FILTROS_ESTADO_CENTRO_SUCCESS: 'FETCH_FILTROS_ESTADO_CENTRO_SUCCESS',
  IMPRIMIR_PDF_INFORMES_ESTADO_CENTRO: 'IMPRIMIR_PDF_INFORMES_ESTADO_CENTRO',
  CREAR_CSV_INFORMES_ESTADO_CENTRO: 'CREAR_CSV_INFORMES_ESTADO_CENTRO',

  // INFORME PERSONALIZADO
  SELECCIONAR_TODOS_INFORME_PERSONALIZADO: 'SELECCIONAR_TODOS_INFORME_PERSONALIZADO',
  DESELECCIONAR_TODOS_INFORME_PERSONALIZADO: 'DESELECCIONAR_TODOS_INFORME_PERSONALIZADO',
  MOSTRAR_SELECCION_INFORME_PERSONALIZADO: 'MOSTRAR_SELECCION_INFORME_PERSONALIZADO',
  MOSTRAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS: 'MOSTRAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS',
  VALORES_TABLA_PERSONALIZADO_SUCCESS: 'VALORES_TABLA_PERSONALIZADO_SUCCESS',
  ABRIR_MODAL_GUARDAR_SELECCION: 'ABRIR_MODAL_GUARDAR_SELECCION',
  CERRAR_MODAL_GUARDAR_SELECCION: 'CERRAR_MODAL_GUARDAR_SELECCION',
  CAMPOS_SELECCIONADOS_PERSONALIZADO_SUCCESS: 'CAMPOS_SELECCIONADOS_PERSONALIZADO_SUCCESS',
  SUBMIT_GUARDAR_SELECCION_INFORME_PERSONALIZADO: 'SUBMIT_GUARDAR_SELECCION_INFORME_PERSONALIZADO',
  COMBO_SELECCIONES_INFORME_PERSONALIZADO: 'COMBO_SELECCIONES_INFORME_PERSONALIZADO',
  COMBO_SELECCIONES_INFORME_PERSONALIZADO_SUCCESS: 'COMBO_SELECCIONES_INFORME_PERSONALIZADO_SUCCESS',
  CARGAR_SELECCION_INFORME_PERSONALIZADO: 'CARGAR_SELECCION_INFORME_PERSONALIZADO',
  CARGAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS: 'CARGAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS',
  ELIMINAR_SELECCION_INFORME_PERSONALIZADO: 'ELIMINAR_SELECCION_INFORME_PERSONALIZADO',
  CREAR_CSV_INFORME_PERSONALIZADO: 'CREAR_CSV_INFORME_PERSONALIZADO',
  IMPRIMIR_PDF_INFORME_PRESONALIZADO: 'IMPRIMIR_PDF_INFORME_PRESONALIZADO'
}
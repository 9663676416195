import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
// import InputText from '../comun/form/InputText'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import {date_formatter} from '../../util/formatFunctions'

class ModalEvolucion extends Component {
  submitNuevaEvolucion (values) {
    // values.fechaEvolucion = date_formatter(values.fechaEvolucion, this.props.auth.formaFechaHora, false)
    if (this.props.dataEvolucion === true){
      this.props.actions.editarEvolucion(values)
    } else {
      this.props.actions.submitNuevaEvolucion(values)
    }
  }
  guardarYnuevoEvolucion(values){
    this.props.actions.guardarYnuevoEvolucion(values)
  }
  render () {
    const {
      t, handleSubmit, dataEvolucion, dataEvolucionDuplicar,
      fichaVerraco: {evolucionesAnadidas, showModalEvolucion},
      actions: {closeModalEvolucion}
    } = this.props

    const ultimaEvolucion = Object.keys(this.props.fichaVerraco.evolucionesAnadidas).length > 0 ? this.props.fichaVerraco.evolucionesAnadidas.length -1 : null
    return (
      <Modal show={showModalEvolucion} onHide={closeModalEvolucion} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-evolucion">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            dataEvolucion === true ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.TITULO_EDITAR')
            ) : dataEvolucionDuplicar ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.TITULO_DUPLICAR')
            ) : (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              dataEvolucion === '' && dataEvolucionDuplicar === '' ? (
                <Col sm={6}>
                  <div className="form-group">
                    <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.FECHA_ULTIMA_EVOLUCION')}</label>
                    <input type="text" value={evolucionesAnadidas.length > 0 ? (
                      date_formatter(evolucionesAnadidas[ultimaEvolucion].fechaEvolucion, this.props.auth.formaFechaHora, false)
                    ): ('-')} disabled="disabled" className="form-control"/>
                  </div>
                </Col>
              ) : null
            }
            <Field
              id="fechaEvolucion"
              name="fechaEvolucion"
              component={InputDatePicker}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.FECHA') + '*'}
              validate={required}
              // onInputChange={(value) => this.handleChangeSemanasEstancia(value)}
            />
          </Row>
          <Row>
            {
              dataEvolucion === '' && dataEvolucionDuplicar === '' ? (
                <Col sm={6}>
                  <div className="form-group">
                    <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.CONDICION_CORPORAL_ULTIMA_EVOLUCION')}</label>
                    <input type="text" value={evolucionesAnadidas.length > 0 ? (evolucionesAnadidas[ultimaEvolucion].condicionCorporalVerraco): ('-')} disabled="disabled" className="form-control"/>
                  </div>
                </Col>
              ) : null
            }
            <Field
              id="condicionCorporalVerraco"
              name="condicionCorporalVerraco"
              component={InputNumerical}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.CONDICION_CORPORAL') + '*'}
              validate={required}
              numDecimales={2}
            />
          </Row>
          <Row>
            {
              dataEvolucion === '' && dataEvolucionDuplicar === '' ? (
              <Col sm={6}>
                <div className="form-group">
                  <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.PESO_ULTIMA_EVOLUCION')}</label>
                  <input type="text" value={evolucionesAnadidas.length > 0 ? (evolucionesAnadidas[ultimaEvolucion].pesoVerraco): ('-')} disabled="disabled" className="form-control"/>
                </div>
              </Col>
              ) : null
            }
            <Field
              id="pesoVerraco"
              name="pesoVerraco"
              component={InputNumerical}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.PESO') + '*'}
              validate={required}
              numDecimales={2}
            />
          </Row>
          <Row>
            {
              dataEvolucion === '' && dataEvolucionDuplicar === '' ? (
                <Col sm={6}>
                  <div className="form-group">
                    <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.KG_PIENSO_DIA_ULTIMA_EVOLUCION')}</label>
                    <input type="text" value={evolucionesAnadidas.length > 0 ? (evolucionesAnadidas[ultimaEvolucion].kgPiensoDiaVerraco): ('-')} disabled="disabled" className="form-control"/>
                  </div>
                </Col>
              ) : null
            }
            <Field
              id="kgPiensoDiaVerraco"
              name="kgPiensoDiaVerraco"
              component={InputNumerical}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.KG_PIENSO_DIA') + '*'}
              validate={required}
              numDecimales={2}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.submitNuevaEvolucion.bind(this))}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalEvolucion}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalEvolucion',
})(ModalEvolucion))
const setting = {
  'default': {
    url: process.env.REACT_APP_API_URL,
    urlRecursos: process.env.REACT_APP_FILE_URL
  },
  'camara': {
    url: 'http://127.0.0.1:5000/'
  },
  'camaraStarter': {
    url: 'appurl://magavision'
  },
  'oldMagavision': {
    url: 'http://127.0.0.1:5680/'
  },
  'numeroSerie': {
    url: 'http://127.0.0.1:8088/'
    //url: 'http://host.docker.internal:8088/'
  },
  'printronixRFID': {
    url: 'http://127.0.0.1:5600/'
  }
}

export default setting


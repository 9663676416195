import actionTypes from '../../constants/actions/analisisExtracciones/analisisExtracciones'

export function fetchAnalisisExtracciones(filtros){
  return {
    type: actionTypes.FETCH_ANALISIS_EXTRACCIONES,
    filtros
  }
}

export function fetchAnalisisExtraccionesSuccess(list, totales, filtros){
  return {
    type: actionTypes.FETCH_ANALISIS_EXTRACCIONES_SUCCESS,
    list,
    totales,
    filtros
  }
}



export function seleccionarTodosAnalisisExtracciones(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES
  }
}

export function deseleccionarTodosAnalisisExtracciones(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ANALISIS_EXTRACCIONES
  }
}

export function cambiarEyaculadoEliminadoAnalisisExtracciones(idAnalisisExtraccion){
  return {
    type: actionTypes.CAMBIAR_EYACULADO_ELIMINADO_ANALISIS_EXTRACCIONES,
    idAnalisisExtraccion
  }
}

export function cambiarVigiladoAnalisisExtracciones(idVerraco){
  return{
    type: actionTypes.CAMBIAR_VIGILADO_ANALISIS_EXTRACCIONES,
    idVerraco
  }
}

export function onDeleteAnalisisExtraccion (idAnalisisExtraccion) {
  return {
    type: actionTypes.ON_DELETE_ANALISIS_EXTRACCION,
    idAnalisisExtraccion
  }
}

export function cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones(list){
  return {
    type: actionTypes.CAMBIAR_EYACULADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES,
    list
  }
}

export function cambiarVigiladoSeleccionAnalisisExtracciones(list){
  return {
    type: actionTypes.CAMBIAR_VIGILADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES,
    list
  }
}

export function deleteSeleccionAnalisisExtracciones(list){
  return {
    type: actionTypes.DELETE_SELECCION_ANALISIS_EXTRACCIONES,
    list
  }
}

export function crearCsvAnalisisExtracciones(list){
  return {
    type: actionTypes.CREATE_CSV_ANALISIS_EXTRACCIONES,
    list
  }
}

export function imprimirPdfAnalisisExtracciones(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_ANALISIS_EXTRACCIONES,
    list
  }
}

export function calcularDiasDescanso(){
  return {
    type: actionTypes.CALCULAR_DIAS_DESCANSO
  }
}

export function sincronizarPendientesDataSwine(){
  return {
    type: actionTypes.SINCRONIZAR_PENDIENTES_DATASWINE
  }
}

export function submitFormAnalisisExtraccion (values, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback, redirect) {
  return {
    type: actionTypes.SUBMIT_FORM_ANALISIS_EXTRACCION,
    values,
    idAnalisisExtraccion,
    history,
    onSuccessCallback,
    onErrorCallback,
    redirect
  }
}

export function recuperarDatosFormAnalisisExtraccion (idAnalisisExtraccion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION,
    idAnalisisExtraccion
  }
}

export function recuperarDatosFormAnalisisExtraccionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION_SUCCESS,
    data
  }
}

export function fetchCodigoAnalisisExtracciones(){
  return{
    type: actionTypes.FETCH_CODIGO_ANALISIS_EXTRACCIONES
  }
}

export function fetchCodigoAnalisisExtraccionesSuccess(codigoAnalisis){
  return{
    type: actionTypes.FETCH_CODIGO_ANALISIS_EXTRACCIONES_SUCCESS,
    codigoAnalisis
  }
}

export function fetchTokenMagavision(){
  return{
    type: actionTypes.FETCH_TOKEN_MAGAVISION
  }
}

export function fetchTokenMagavisionSuccess(tokenUsuarioMagavision, t_anl, t_dtv){
  return{
    type: actionTypes.FETCH_TOKEN_MAGAVISION_SUCCESS,
    tokenUsuarioMagavision,
    t_anl,
    t_dtv
  }
}

export function fetchTokenVerracoMagavisionSuccess(t_dtv){
  return{
    type: actionTypes.FETCH_TOKEN_VERRACO_MAGAVISION_SUCCESS,
    t_dtv
  }
}

export function updateTokenMagavisionSuccess(t_dtv_final){
  return{
    type: actionTypes.UPDATE_TOKEN_MAGAVISION_SUCCESS,
    t_dtv_final
  }
}

export function updateTokenDtvMainMagavisionSuccess(t_dtv_main, t_dtv){
  return{
    type: actionTypes.UPDATE_TOKEN_DTV_MAIN_MAGAVISION_SUCCESS,
    t_dtv_main,
    t_dtv
  }
}

export function updateComboVerraco(){
  return{
    type: actionTypes.UPDATE_COMBO_VERRACO
  }
}

export function fetchDatosVerracoExtracciones(idVerraco, existsAnalisis = false){
  return{
    type: actionTypes.FETCH_DATOS_VERRACO_EXTRACCIONES,
    idVerraco,
    existsAnalisis
  }
}

export function fetchDatosVerracoExtraccionesSuccess(data){
  return{
    type: actionTypes.FETCH_DATOS_VERRACO_EXTRACCIONES_SUCCESS,
    data
  }
}

export function prepararVerracoParaCambiarAEstadoActivoSuccess(estado){
  return{
    type: actionTypes.PREPARAR_VERRACO_PARA_CAMBIAR_A_ESTADO_ACTIVO_SUCCESS,
    estado
  }
}

export function changeTipoAnalisisExtraccion(tipoAnalisisSeleccionado){
  return{
    type: actionTypes.CHANGE_TIPO_ANALISIS_EXTRACCION,
    tipoAnalisisSeleccionado
  }
}

export function changeTipoAnalisisExtraccionSuccess(data){
  return{
    type: actionTypes.CHANGE_TIPO_ANALISIS_EXTRACCION_SUCCESS,
    data
  }
}

export function abrirMagavision(tipoAnalisis, isSeguimiento, isPool, isControlMorfologico, list) {
  return{
    type: actionTypes.ABRIR_MAGAVISION,
    tipoAnalisis,
    isSeguimiento,
    isPool,
    isControlMorfologico,
    list
  }
}

export function abrirNuevoMagavisionSuccess(isSeguimiento, isControlMorfologico, list, mgv_follow_id) {
  return{
    type: actionTypes.ABRIR_NUEVO_MAGAVISION_SUCCESS,
    isSeguimiento,
    isControlMorfologico,
    list,
    mgv_follow_id
  }
}

export function cerrarNuevoMagavision(isPool) {
  return{
    type: actionTypes.CERRAR_NUEVO_MAGAVISION,
    isPool
  }
}

export function cerrarNuevoMagavisionSuccess() {
  return{
    type: actionTypes.CERRAR_NUEVO_MAGAVISION_SUCCESS
  }
}

export function verInformeMagavision() {
  return{
    type: actionTypes.VER_INFORME_MAGAVISION
  }
}

export function esperandoResultadoSistemaAnalisis({status}) {
  return{
    type: actionTypes.ESPERANDO_RESULTADO_SISTEMA_ANALISIS,
    status
  }
}

export function obtenerYGuardarResultadoSistemaAnalisis(tipoAnalisis, isSeguimiento, isPool, isPrimerSeguimientoDelPool) {
  return{
    type: actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS,
    tipoAnalisis,
    isSeguimiento,
    isPool,
    isPrimerSeguimientoDelPool
  }
}

export function guardarResultadoSistemaAnalisis(data, tipoAnalisis, isSeguimiento) {
  return{
    type: actionTypes.GUARDAR_RESULTADO_SISTEMA_ANALISIS,
    data,
    tipoAnalisis,
    isSeguimiento
  }
}

export function handleAnalisisCalcFunctionsOrden(calcFunctionList, caller) {
  return{
    type: actionTypes.HANDLE_ANALISIS_CALC_FUNCTIONS_ORDEN,
    calcFunctionList,
    caller
  }
}

export function calcConcentracionTotal() {
  return{
    type: actionTypes.CALC_CONCENTRACION_TOTAL
  }
}

export function calcConcentracionUtil() {
  return{
    type: actionTypes.CALC_CONCENTRACION_UTIL
  }
}

export function calcConcentracionXDosis() {
  return{
    type: actionTypes.CALC_CONCENTRACION_X_DOSIS
  }
}

export function calcNumeroDosis(updateAllDosis = true, useTipoDosisConcentracion = false) {
  return{
    type: actionTypes.CALC_NUMERO_DOSIS,
    updateAllDosis,
    useTipoDosisConcentracion
  }
}

export function reCalcConcentracionXDosis() {
  return{
    type: actionTypes.RE_CALC_CONCENTRACION_X_DOSIS
  }
}

export function calcVolumenDiluyente() {
  return{
    type: actionTypes.CALC_VOLUMEN_DILUYENTE
  }
}

export function initializeFormAnalisisExtraccion() {
  return{
    type: actionTypes.INITIALIZE_FORM_ANALISIS_EXTRACCION
  }
}

export function initializeFormAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.INITIALIZE_FORM_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function abrirModalDilucionAnalisisExtraccion() {
  return{
    type: actionTypes.ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION
  }
}

export function abrirModalDilucionAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function guardarModalDilucionAnalisisExtraccion(data, idAnalisisExtraccion, history) {
  return{
    type: actionTypes.GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION,
    data,
    idAnalisisExtraccion,
    history
  }
}

export function guardarModalDilucionAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function cerrarModalDilucionAnalisisExtraccion() {
  return{
    type: actionTypes.CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION
  }
}

export function cerrarModalDilucionAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function prepararMaquinaDilucionAnalisisExtraccion(data, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.PREPARAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION,
    data,
    idAnalisisExtraccion,
    history,
    onSuccessCallback,
    onErrorCallback
  }
}

export function cancelarMaquinaDilucionAnalisisExtraccion () {
  return {
    type: actionTypes.CANCELAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION
  }
}

export function actualizarEstadoMaquinaDilucionAnalisisExtraccion(codigo){
  return {
    type: actionTypes.ACTUALIZAR_ESTADO_MAQUINA_DILUCION_ANALISIS_EXTRACCION,
    codigo
  }
}

export function pdfEtiquetaExtraccion(idAnalisisExtraccion){
  return {
    type: actionTypes.PDF_ETIQUETA_EXTRACCION,
    idAnalisisExtraccion
  }
}

export function imprimirEtiquetaPDF(idAnalisisExtraccion){
  return {
    type: actionTypes.PDF_ETIQUETA_PDF,
    idAnalisisExtraccion
  }
}

export function imprimirMiniEtiquetaAnalisis(idAnalisisExtraccion){
  return {
    type: actionTypes.IMPRIMIR_MINI_ETIQUETA_ANALISIS,
    idAnalisisExtraccion
  }
}

export function imprimirEtiquetaPDFSuccess(etiquetaPdf){
  return {
    type: actionTypes.PDF_ETIQUETA_PDF_SUCCESS,
    etiquetaPdf
  }
}

export function abrirBascula() {
  return{
    type: actionTypes.ABRIR_BASCULA
  }
}



export function abrirModalPoolAnalisisExtraccion() {
  return{
    type: actionTypes.ABRIR_MODAL_POOL_ANALISIS_EXTRACCION
  }
}

export function abrirModalPoolAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.ABRIR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function guardarModalPoolAnalisisExtraccion(data, idPool, nombrePool, fechaPool, idAnalisisExtraccion, history) {
  return{
    type: actionTypes.GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION,
    data,
    idPool,
    nombrePool,
    fechaPool,
    idAnalisisExtraccion,
    history
  }
}

export function guardarModalPoolAnalisisExtraccionSuccess() {
  return{
    type: actionTypes.GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS
  }
}

export function cerrarModalPoolAnalisisExtraccion() {
  return{
    type: actionTypes.CERRAR_MODAL_POOL_ANALISIS_EXTRACCION
  }
}

export function showModalAlertaSuccess() {
  return{
    type: actionTypes.SHOW_MODAL_ALERTA_SUCCESS
  }
}


export function lecturaRFIDAnalisis() {
  return{
    type: actionTypes.LECTURA_RFID_ANALISIS
  }
}

export function showUltimasExtraccionesVerraco(idAnalisis,idVerraco) { 
  return{
    type: actionTypes.SHOW_ULTIMAS_EXTRACCIONES_VERRACO,
    idAnalisis,
    idVerraco
  }
}

export function showUltimasExtraccionesVerracoSuccess(ultimasExtraccionesVerraco){
  return {
    type: actionTypes.SHOW_ULTIMAS_EXTRACCIONES_VERRACO_SUCCESS,
    ultimasExtraccionesVerraco
  }
}

export default {  
  fetchAnalisisExtracciones,
  fetchAnalisisExtraccionesSuccess,
  seleccionarTodosAnalisisExtracciones,
  deseleccionarTodosAnalisisExtracciones,
  cambiarEyaculadoEliminadoAnalisisExtracciones,
  cambiarVigiladoAnalisisExtracciones,
  onDeleteAnalisisExtraccion,
  cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones,
  cambiarVigiladoSeleccionAnalisisExtracciones,
  deleteSeleccionAnalisisExtracciones,
  crearCsvAnalisisExtracciones,
  imprimirPdfAnalisisExtracciones,
  sincronizarPendientesDataSwine,
  submitFormAnalisisExtraccion,
  recuperarDatosFormAnalisisExtraccion,
  recuperarDatosFormAnalisisExtraccionSuccess,
  fetchCodigoAnalisisExtracciones,
  fetchCodigoAnalisisExtraccionesSuccess,
  fetchTokenMagavision,
  fetchTokenMagavisionSuccess,
  fetchTokenVerracoMagavisionSuccess,
  updateTokenMagavisionSuccess,
  updateTokenDtvMainMagavisionSuccess,
  updateComboVerraco,
  fetchDatosVerracoExtracciones,
  fetchDatosVerracoExtraccionesSuccess,
  prepararVerracoParaCambiarAEstadoActivoSuccess,
  changeTipoAnalisisExtraccion,
  changeTipoAnalisisExtraccionSuccess,
  abrirMagavision,
  abrirNuevoMagavisionSuccess,
  cerrarNuevoMagavision,
  cerrarNuevoMagavisionSuccess,
  verInformeMagavision,
  esperandoResultadoSistemaAnalisis,
  obtenerYGuardarResultadoSistemaAnalisis,
  guardarResultadoSistemaAnalisis,
  handleAnalisisCalcFunctionsOrden,
  calcConcentracionTotal,
  calcConcentracionUtil,
  calcConcentracionXDosis,
  calcNumeroDosis,
  reCalcConcentracionXDosis,
  calcVolumenDiluyente,
  initializeFormAnalisisExtraccion,
  initializeFormAnalisisExtraccionSuccess,
  abrirModalDilucionAnalisisExtraccion,
  abrirModalDilucionAnalisisExtraccionSuccess,
  guardarModalDilucionAnalisisExtraccion,
  guardarModalDilucionAnalisisExtraccionSuccess,
  cerrarModalDilucionAnalisisExtraccion,
  cerrarModalDilucionAnalisisExtraccionSuccess,
  prepararMaquinaDilucionAnalisisExtraccion,
  cancelarMaquinaDilucionAnalisisExtraccion,
  actualizarEstadoMaquinaDilucionAnalisisExtraccion,
  pdfEtiquetaExtraccion,
  imprimirEtiquetaPDF,
  imprimirMiniEtiquetaAnalisis,
  imprimirEtiquetaPDFSuccess,
  abrirBascula,
  abrirModalPoolAnalisisExtraccion,
  abrirModalPoolAnalisisExtraccionSuccess,
  guardarModalPoolAnalisisExtraccion,
  guardarModalPoolAnalisisExtraccionSuccess,
  cerrarModalPoolAnalisisExtraccion,
  showModalAlertaSuccess,
  lecturaRFIDAnalisis,
  showUltimasExtraccionesVerraco,
  showUltimasExtraccionesVerracoSuccess
}

import actionTypes from '../../constants/actions/instalacion/instalacion'

export function fetchInstalacion () {
  return {
    type: actionTypes.FETCH_INSTALACION
  }
}

export function fetchInstalacionSuccess (list) {
  return {
    type: actionTypes.FETCH_INSTALACION_SUCCESS,
    list
  }
}

export function submitNuevaInstalacion (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_INSTALACION,
    values
  }
}

export function submitNuevaInstalacionSuccess (insercionInstalacion) {
  return {
    type: actionTypes.SUBMIT_NUEVA_INSTALACION_SUCCESS,
    insercionInstalacion
  }
}

export function recuperarDatosModalInstalacion (idInstalacion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_INSTALACION,
    idInstalacion
  }
}

export function recuperarDatosModalInstalacionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_INSTALACION_SUCCESS,
    data
  }
}

export function abrirModalVacioInstalacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_INSTALACION
  }
}

export function editarInstalacion (values) {
  return {
    type: actionTypes.EDITAR_INSTALACION,
    values
  }
}

export function editarInstalacionSuccess (insercionInstalacion) {
  return {
    type: actionTypes.EDITAR_INSTALACION_SUCCESS,
    insercionInstalacion
  }
}


export function duplicarDatosModalInstalacion (idInstalacion) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_INSTALACION,
    idInstalacion
  }
}

export function duplicarDatosModalInstalacionSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_INSTALACION_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoInstalacion (idInstalacion) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_INSTALACION,
    idInstalacion
  }
}

export function cambiarEstadoInstalacionSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_INSTALACION_SUCCESS,
    dataEstado
  }
}

export function onDeleteInstalacion (idInstalacion) {
  return {
    type: actionTypes.ON_DELETE_INSTALACION,
    idInstalacion
  }
}

export function onDeleteInstalacionSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_INSTALACION_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarInstalacion (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_INSTALACION,
    values
  }
}

export function fetchFiltrarInstalacionSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_INSTALACION_SUCCESS,
    filtros
  }
}

export function seleccionarTodosInstalacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_INSTALACION
  }
}

export function deSeleccionarTodosInstalacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_INSTALACION
  }
}

export function cambiarEstadoSeleccionInstalacion(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_INSTALACION,
    list
  }
}

export function deleteSeleccionInstalacion(list){
  return {
    type: actionTypes.DELETE_SELECCION_INSTALACION,
    list
  }
}

export function guardarYnuevoInstalacion(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_INSTALACION,
    values
  }
}

export function crearCsvInstalacion(list){
  return {
    type: actionTypes.CREATE_CSV_INSTALACION,
    list
  }
}

export function crearCsvInstalacionSuccess(datosExcelInstalacion){
  return {
    type: actionTypes.CREATE_CSV_INSTALACION_SUCCESS,
    datosExcelInstalacion
  }
}
export function imprimirPdfInstalacion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_INSTALACION,
    list
  }
}

export function imprimirPdfInstalacionSuccess(datosPdfInstalacion){
  return {
    type: actionTypes.IMPRIMIR_PDF_INSTALACION_SUCCESS,
    datosPdfInstalacion
  }
}

export default {
  fetchInstalacion,
  fetchInstalacionSuccess,
  submitNuevaInstalacion,
  submitNuevaInstalacionSuccess,
  recuperarDatosModalInstalacion,
  recuperarDatosModalInstalacionSuccess,
  abrirModalVacioInstalacion,
  editarInstalacion,
  duplicarDatosModalInstalacion,
  duplicarDatosModalInstalacionSuccess,
  cambiarEstadoInstalacion,
  cambiarEstadoInstalacionSuccess,
  onDeleteInstalacion,
  onDeleteInstalacionSuccess,
  fetchFiltrarInstalacion,
  fetchFiltrarInstalacionSuccess,
  seleccionarTodosInstalacion,
  deSeleccionarTodosInstalacion,
  cambiarEstadoSeleccionInstalacion,
  deleteSeleccionInstalacion,
  guardarYnuevoInstalacion,
  crearCsvInstalacion,
  crearCsvInstalacionSuccess,
  imprimirPdfInstalacion,
  imprimirPdfInstalacionSuccess
}

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter, number_formatter} from '../../util/formatFunctions'
import ListadoPage from '../../containers/page/ListadoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'

class ModalHistoricoExtracciones extends Component {
  constructor () {
    super()
    this.state = {
      idMotilidad: '',
      nameMotilidad: ''
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosHistoricoExtracciones !== prevProps.datosHistoricoExtracciones) {
      if (this.props.datosHistoricoExtracciones[0]){
        if (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 1) {
          this.setState({idMotilidad: this.props.datosHistoricoExtracciones[0].motilidadTotal})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 2) {
          this.setState({idMotilidad: this.props.datosHistoricoExtracciones[0].motilidadTotal})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 4) {
          this.setState({idMotilidad: this.props.datosHistoricoExtracciones[0].motilidadMasal})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 3) {
          this.setState({idMotilidad: this.props.datosHistoricoExtracciones[0].mt})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 5) {
          this.setState({idMotilidad: this.props.datosHistoricoExtracciones[0].mt})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }
  }

  render () {
    const {
      t, handleSubmit, showrModalHistoricoExtracciones, datosHistoricoExtracciones,
      auth: {formaFechaHora, separadorDec, separadorMil},
      actions: {cerrarModalHistoricoExtracciones}
    } = this.props

    return (
      <Modal show={showrModalHistoricoExtracciones} onHide={cerrarModalHistoricoExtracciones} aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-historico">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TITULO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.FECHA_EXTRACCION')}: </b>{datosHistoricoExtracciones[0] ? date_formatter(datosHistoricoExtracciones[0].fechaExtraccion, formaFechaHora, false) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.VERRACO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].codVerraco : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CODIGO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].codigo : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.ORDEN')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].orden : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CODIGO_POOL')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].codigoPool : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.RAZA')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreRaza : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.LINEA_GENETICA')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreLinea : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.INDEX')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].ebv : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.PROPIEDAD')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].propiedadVerraco : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.ESTADO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].estadoactual : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.UBICACION')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].UbicacionVerraco : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.OPERARIO_COLECTA')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreOperarioColecta : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.OPERARIO_PROCESADO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreOperarioProcesado : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.HORA_EXTRACCION')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].horaExtraccion : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.HORA_ENTRADA_LABORATORIO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].horaEntradaLaboratorio : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.HORA_INICIO_PROCESADO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].horaInicioProcesado : null}</p></Col>
            <Col sm={2}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.HORA_FIN_PROCESADO')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].horaFinProcesado : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.VOL')}: </b>{datosHistoricoExtracciones[0] ? number_formatter(datosHistoricoExtracciones[0].volumen, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CALIDAD')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].calidad !== null ? number_formatter(datosHistoricoExtracciones[0].calidad, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.AGLUTINACION')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].aglutinacion !== null ? number_formatter(datosHistoricoExtracciones[0].aglutinacion, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TEMPERATURA_EYACULADO')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].temperaturaEyaculado !== null ? number_formatter(datosHistoricoExtracciones[0].temperaturaEyaculado, 2, separadorDec, separadorMil) : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TIPO_ANALISIS')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].tipoAnalisis : null}</p></Col>
            {
              this.props.datosHistoricoExtracciones[0] && this.props.datosHistoricoExtracciones[0].idTipoAnalisis !== 1 && this.props.datosHistoricoExtracciones[0].idTipoAnalisis !== 2 ? (
                <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.ESPERMATOZOIDES_CONTADOS')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].contados : null}</p></Col>
              ) : null
            }
            {
              this.props.datosHistoricoExtracciones[0] && this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 1 ? (
                <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.RECTAS')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreRecta : null}</p></Col>
              ) : null
            }
            {
              this.props.datosHistoricoExtracciones[0] && this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 1 ? (
                <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.LECTURA')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].lectura : null}</p></Col>
              ) : null
            }
            <Col sm={3}><p><b>{this.state.nameMotilidad}: </b>{this.state.idMotilidad}</p></Col>
            {
              this.props.datosHistoricoExtracciones[0] && (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 3 || this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 4) ? (
                  <Col sm={3}><p><b>{t('INFORMES.EXTRACCIONES.TABLA.MP')}: </b>{this.props.datosHistoricoExtracciones[0].mp}</p></Col>
                ) : null
            }
          </Row>
          <hr/>
           {
            this.props.datosHistoricoExtracciones[0] && (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 3 || this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 4) ? ([
              <Row>
              <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.FORMAS_ANORMALES')}: </b></p>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? number_formatter(datosHistoricoExtracciones[0].faTotal, 2, separadorDec, separadorMil) : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.PORCENTAJE')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].porcentajeFa !== undefined && number_formatter(datosHistoricoExtracciones[0].porcentajeFa, 2, separadorDec, separadorMil) : null}</p></Col>
              </Row>
            ]) : (
              this.props.datosHistoricoExtracciones[0] && (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 1 || this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 2) ? ([
              <Row>
              <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.ACROSOMAS')}: </b></p>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTAL_ACROSOMAS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].totalAcrosomas : null}</p></Col>
              </Row>,
              <hr/>,
              <Row>
              <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.FORMAS_ANORMALES')}: </b></p>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CABEZAS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faCabezas : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.COLAS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faColas : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.G_PROXIMAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faGotaProximal : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.G_DISTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faGotaDistal : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? number_formatter(datosHistoricoExtracciones[0].faTotal, 2, separadorDec, separadorMil) : null}</p></Col>
              <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.PORCENTAJE')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].porcentajeFa !== undefined && number_formatter(datosHistoricoExtracciones[0].porcentajeFa, 2, separadorDec, separadorMil) : null}</p></Col>
              </Row>
              ]) : (
                this.props.datosHistoricoExtracciones[0] && (this.props.datosHistoricoExtracciones[0].idTipoAnalisis === 5) ? (
                  <Row>
                    <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.FORMAS_ANORMALES')}: </b></p>
                    <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.COLAS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faColas : null}</p></Col>
                    <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.G_PROXIMAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faGotaProximal : null}</p></Col>
                    <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.G_DISTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? datosHistoricoExtracciones[0].faGotaDistal : null}</p></Col>
                    <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].faTotal !== null ? number_formatter(datosHistoricoExtracciones[0].faTotal, 2, separadorDec, separadorMil) : null}</p></Col>
                    <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.PORCENTAJE')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].porcentajeFa !== undefined && number_formatter(datosHistoricoExtracciones[0].porcentajeFa, 2, separadorDec, separadorMil) : null}</p></Col>
                  </Row>
                ) : null
              )
            )
          }
          <hr/>
          <Row>
            <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CONCENTRACION_MILLONES_ML')}: </b></p>
            <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTAL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].concentracionTotal !== null ? number_formatter(datosHistoricoExtracciones[0].concentracionTotal, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.UTIL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].concentracionUtil !== null ? number_formatter(datosHistoricoExtracciones[0].concentracionUtil, 2, separadorDec, separadorMil) : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.CONCENTRACION_SPZ_DOSIS')}: </b></p>
            <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.DOSIS_APLICADAS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].concentracionxdosisaplicada !== null ? number_formatter(datosHistoricoExtracciones[0].concentracionxdosisaplicada, 4, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={6}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.DOSIS_UTIL')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].concentracionxdosisutil !== null ? number_formatter(datosHistoricoExtracciones[0].concentracionxdosisutil, 4, separadorDec, separadorMil) : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={4}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TIPO_DOSIS')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreTipoDosis : null}</p></Col>
            <Col sm={4}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.VOL_DILUYENTE')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].volumenDiluyente !== null ? number_formatter(datosHistoricoExtracciones[0].volumenDiluyente, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={4}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TIPO_DILUYENTE')}: </b>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones[0].nombreTipoDiluyente : null}</p></Col>
          </Row>
          <hr/>
          <Row>
            <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.TOTALES')}: </b></p>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.DOSIS_TEORICAS')}: </b>{datosHistoricoExtracciones[0] ? number_formatter(datosHistoricoExtracciones[0].dosisTeoricas, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.DOSIS_REALES')}: </b>{datosHistoricoExtracciones[0] ? number_formatter(datosHistoricoExtracciones[0].dosisReales, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.MEDIA_ULTIMAS_DOSIS')}: </b>{datosHistoricoExtracciones[0] && datosHistoricoExtracciones[0].mediaUltimaDosis !== null ? number_formatter(datosHistoricoExtracciones[0].mediaUltimaDosis, 2, separadorDec, separadorMil) : null}</p></Col>
            <Col sm={3}><p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.EYACULADO_ELIMINADO')}: </b>{datosHistoricoExtracciones[0] ? (
              datosHistoricoExtracciones[0].eyaculadoEliminado === true ? t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.SI') : t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.NO')
              ) : null}</p></Col>
          </Row>
          <hr/>
          <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.SEGUIMIENTO')}: </b>{datosHistoricoExtracciones[0] ? (
              datosHistoricoExtracciones[0].seguimiento === true ? t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.SI') : t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.NO')
              ) : null}</p>
          <hr/>
          <Row>
            <p className="titulo-parafo"><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.MODAL.OBSERVACIONES')}: </b></p>
            <Col sm={12}><p>{datosHistoricoExtracciones[0] ? datosHistoricoExtracciones.observaciones : null}</p></Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalHistoricoExtracciones}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalHistoricoExtracciones',
})(ModalHistoricoExtracciones))
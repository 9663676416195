import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import {AddCircleOutline} from '@material-ui/icons'

class FiltrosLicencias extends Component {
  constructor () {
    super()
    this.abrirModalLicencias = this.abrirModalLicencias.bind(this)
  }

  abrirModalLicencias () {
    this.props.actions.abrirModalLicencias()
  }

  render () {
    const {t} = this.props
    return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-primary nuevo" onClick={(e) => this.abrirModalLicencias()}>
                  <AddCircleOutline /> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default FiltrosLicencias
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalEnvasado from '../../components/envasado/ModalEnvasado'
import {abrirDetallesEnvasado, editarEnvasado, cerrarDetallesEnvasadoSuccess, deleteEnvasadoMultiDosis, abrirModalEnvasadoMultiDosis} from '../../actions/envasado/envasado'
import { comboMaquina, comboTipoDosis } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.envasado,
    auth: state.auth,
    common: state.common,
    combos: state.combos,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirDetallesEnvasado,
      cerrarDetallesEnvasadoSuccess,
      editarEnvasado,
      deleteEnvasadoMultiDosis,
      abrirModalEnvasadoMultiDosis,
      comboMaquina,
      comboTipoDosis,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEnvasado))
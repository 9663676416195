import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import FiltrosModalAnnadirControlSemen from '../../../components/planSanitario/controlSemen/FiltrosModalAnnadirControlSemen'
import {
  fetchControlSemen, seleccionarTodosModalControlSemen, deSeleccionarTodosModalControlSemen, deleteControlSemenSeleccion,
  abrirModalInicio, fetchFiltrarModalInicio, anadirVerracosModalInicio
} from '../../../actions/planSanitario/controlSemen/controlSemen'
import {comboVerraco, comboRaza, comboLineaGenetica} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.ModalInicioControlSemen,
    auth: state.auth,
    filtros: state.controlMorfologico.filtrosInicio,
    seguimientoTable: state.form.seguimientoTable,
    periodoForm:state.form.ModalInicioControlSemen,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerraco: state.combos.comboVerraco,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchControlSemen,
      seleccionarTodosModalControlSemen,
      deSeleccionarTodosModalControlSemen,
      deleteControlSemenSeleccion,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      abrirModalInicio,
      fetchFiltrarModalInicio,
      anadirVerracosModalInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalAnnadirControlSemen))
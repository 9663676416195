export default {
  FETCH_ANALISIS_EXTRACCIONES: 'FETCH_ANALISIS_EXTRACCIONES',
  FETCH_ANALISIS_EXTRACCIONES_SUCCESS: 'FETCH_ANALISIS_EXTRACCIONES_SUCCESS',
  FETCH_EXTRACCIONES_SIN_ASIGNAR:'FETCH_EXTRACCIONES_SIN_ASIGNAR',
  SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES: 'SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES',
  DESELECCIONAR_TODOS_ANALISIS_EXTRACCIONES: 'DESELECCIONAR_TODOS_ANALISIS_EXTRACCIONES',
  CAMBIAR_EYACULADO_ELIMINADO_ANALISIS_EXTRACCIONES: 'CAMBIAR_EYACULADO_ELIMINADO_ANALISIS_EXTRACCIONES',
  CAMBIAR_VIGILADO_ANALISIS_EXTRACCIONES: 'CAMBIAR_VIGILADO_ANALISIS_EXTRACCIONES',
  ON_DELETE_ANALISIS_EXTRACCION: 'ON_DELETE_ANALISIS_EXTRACCION',
  CAMBIAR_EYACULADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES: 'CAMBIAR_EYACULADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES',
  CAMBIAR_VIGILADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES: 'CAMBIAR_VIGILADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES',
  DELETE_SELECCION_ANALISIS_EXTRACCIONES: 'DELETE_SELECCION_ANALISIS_EXTRACCIONES',
  CREATE_CSV_ANALISIS_EXTRACCIONES: 'CREATE_CSV_ANALISIS_EXTRACCIONES',
  CREATE_CSV_ANALISIS_EXTRACCIONES_SUCCESS: 'CREATE_CSV_ANALISIS_EXTRACCIONES_SUCCESS',
  IMPRIMIR_PDF_ANALISIS_EXTRACCIONES: 'IMPRIMIR_PDF_ANALISIS_EXTRACCIONES',
  IMPRIMIR_PDF_ANALISIS_EXTRACCIONES_SUCCESS: 'IMPRIMIR_PDF_ANALISIS_EXTRACCIONES_SUCCESS',
  CALCULAR_DIAS_DESCANSO: 'CALCULAR_DIAS_DESCANSO',  
  SINCRONIZAR_PENDIENTES_DATASWINE: 'SINCRONIZAR_PENDIENTES_DATASWINE', 
  SUBMIT_FORM_ANALISIS_EXTRACCION: 'SUBMIT_FORM_ANALISIS_EXTRACCION',
  RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION: 'RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION',
  RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION_SUCCESS: 'RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION_SUCCESS',
  FETCH_CODIGO_ANALISIS_EXTRACCIONES: 'FETCH_CODIGO_ANALISIS_EXTRACCIONES',
  FETCH_CODIGO_ANALISIS_EXTRACCIONES_SUCCESS: 'FETCH_CODIGO_ANALISIS_EXTRACCIONES_SUCCESS',
  FETCH_TOKEN_MAGAVISION: 'FETCH_TOKEN_MAGAVISION',
  FETCH_TOKEN_MAGAVISION_SUCCESS: 'FETCH_TOKEN_MAGAVISION_SUCCESS',
  FETCH_TOKEN_VERRACO_MAGAVISION_SUCCESS: 'FETCH_TOKEN_VERRACO_MAGAVISION_SUCCESS',
  UPDATE_TOKEN_MAGAVISION_SUCCESS: 'UPDATE_TOKEN_MAGAVISION_SUCCESS',
  UPDATE_TOKEN_DTV_MAIN_MAGAVISION_SUCCESS: 'UPDATE_TOKEN_DTV_MAIN_MAGAVISION_SUCCESS',
  UPDATE_COMBO_VERRACO: 'UPDATE_COMBO_VERRACO',
  FETCH_DATOS_VERRACO_EXTRACCIONES: 'FETCH_DATOS_VERRACO_EXTRACCIONES',
  FETCH_DATOS_VERRACO_EXTRACCIONES_SUCCESS: 'FETCH_DATOS_VERRACO_EXTRACCIONES_SUCCESS',
  PREPARAR_VERRACO_PARA_CAMBIAR_A_ESTADO_ACTIVO_SUCCESS: 'PREPARAR_VERRACO_PARA_CAMBIAR_A_ESTADO_ACTIVO_SUCCESS',
  CHANGE_TIPO_ANALISIS_EXTRACCION: 'CHANGE_TIPO_ANALISIS_EXTRACCION',
  CHANGE_TIPO_ANALISIS_EXTRACCION_SUCCESS: 'CHANGE_TIPO_ANALISIS_EXTRACCION_SUCCESS',
  ABRIR_MAGAVISION: 'ABRIR_MAGAVISION',
  ABRIR_NUEVO_MAGAVISION_SUCCESS: 'ABRIR_NUEVO_MAGAVISION_SUCCESS',
  CERRAR_NUEVO_MAGAVISION: 'CERRAR_NUEVO_MAGAVISION',
  CERRAR_NUEVO_MAGAVISION_SUCCESS: 'CERRAR_NUEVO_MAGAVISION_SUCCESS',
  ESPERANDO_RESULTADO_SISTEMA_ANALISIS: 'ESPERANDO_RESULTADO_SISTEMA_ANALISIS',
  OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS: 'OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS',
  GUARDAR_RESULTADO_SISTEMA_ANALISIS: 'GUARDAR_RESULTADO_SISTEMA_ANALISIS',
  HANDLE_ANALISIS_CALC_FUNCTIONS_ORDEN: 'HANDLE_ANALISIS_CALC_FUNCTIONS_ORDEN',
  CALC_CONCENTRACION_TOTAL: 'CALC_CONCENTRACION_TOTAL',
  CALC_CONCENTRACION_UTIL: 'CALC_CONCENTRACION_UTIL',
  CALC_CONCENTRACION_X_DOSIS: 'CALC_CONCENTRACION_X_DOSIS',
  CALC_NUMERO_DOSIS: 'CALC_NUMERO_DOSIS',
  RE_CALC_CONCENTRACION_X_DOSIS: 'RE_CALC_CONCENTRACION_X_DOSIS',
  CALC_VOLUMEN_DILUYENTE: 'CALC_VOLUMEN_DILUYENTE',
  INITIALIZE_FORM_ANALISIS_EXTRACCION: 'INITIALIZE_FORM_ANALISIS_EXTRACCION',
  INITIALIZE_FORM_ANALISIS_EXTRACCION_SUCCESS: 'INITIALIZE_FORM_ANALISIS_EXTRACCION_SUCCESS',
  ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION: 'ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION',
  ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS: 'ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS',
  GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION: 'GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION',
  GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS: 'GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS',
  CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION: 'CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION',
  CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS: 'CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS',
  PREPARAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION: 'PREPARAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION',
  CANCELAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION: 'CANCELAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION',
  ACTUALIZAR_ESTADO_MAQUINA_DILUCION_ANALISIS_EXTRACCION: 'ACTUALIZAR_ESTADO_MAQUINA_DILUCION_ANALISIS_EXTRACCION',
  PDF_ETIQUETA_EXTRACCION: 'PDF_ETIQUETA_EXTRACCION',
  PDF_ETIQUETA_PDF: 'PDF_ETIQUETA_PDF',
  IMPRIMIR_MINI_ETIQUETA_ANALISIS: 'IMPRIMIR_MINI_ETIQUETA_ANALISIS',
  PDF_ETIQUETA_PDF_SUCCESS: 'PDF_ETIQUETA_PDF_SUCCESS',
  ABRIR_BASCULA: 'ABRIR_BASCULA',
  ABRIR_MODAL_POOL_ANALISIS_EXTRACCION: 'ABRIR_MODAL_POOL_ANALISIS_EXTRACCION',
  ABRIR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS: 'ABRIR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS',
  GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION: 'GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION',
  GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS: 'GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS',
  CERRAR_MODAL_POOL_ANALISIS_EXTRACCION: 'CERRAR_MODAL_POOL_ANALISIS_EXTRACCION',
  SHOW_MODAL_ALERTA_SUCCESS: 'SHOW_MODAL_ALERTA_SUCCESS',
  LECTURA_RFID_ANALISIS: 'LECTURA_RFID_ANALISIS',
  SHOW_ULTIMAS_EXTRACCIONES_VERRACO: 'SHOW_ULTIMAS_EXTRACCIONES_VERRACO',
  SHOW_ULTIMAS_EXTRACCIONES_VERRACO_SUCCESS: 'SHOW_ULTIMAS_EXTRACCIONES_VERRACO_SUCCESS',
  ABRIR_NUEVO_MAGAVISION_ML_SUCCESS: 'ABRIR_NUEVO_MAGAVISION_ML_SUCCESS',
  VER_INFORME_MAGAVISION: 'VER_INFORME_MAGAVISION'
}
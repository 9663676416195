import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosModalAlbaranPage from '../../containers/albaran/FiltrosModalAlbaranPage'
import ModalAlbaranDosisPage from '../../containers/albaran/ModalAlbaranDosisPage'
import ModalAlbaranRfidValidarPage from '../../containers/albaran/ModalAlbaranRfidValidarPage'
import {estadoAlbaran as estadoAlbaranConstants} from '../../constants/albaran'
import {number_formatter} from '../../util/formatFunctions'
import { required } from '../../util/validationFunctions'
import './Albaran.scss'
import { RemoveRedEye } from '@material-ui/icons'
import ModalDetallePedidoPage from '../../containers/pedidosVenta/ModalDetallePedidoPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalAlbaran extends Component {
  constructor () {
    super()
    this.state = {
      idPedido: null,
      hayCliente: null,
      hayGranja: null
    }
    this.handleChangeCliente = this.handleChangeCliente.bind(this)
    this.handleChangeDireccionCliente = this.handleChangeDireccionCliente.bind(this)
    this.handleChangeClienteSecundario = this.handleChangeClienteSecundario.bind(this)        
    this.handleChangeMostrarIndex = this.handleChangeMostrarIndex.bind(this)
    this.handleChangeTransportistaCliente = this.handleChangeTransportistaCliente.bind(this)
    this.imprimirDatosAlbaran = this.imprimirDatosAlbaran.bind(this)
    this.imprimirDatosAlbaranCliente = this.imprimirDatosAlbaranCliente.bind(this)
    this.imprimirEtiquetaPDF = this.imprimirEtiquetaPDF.bind(this)
  }

  editarAlbaran (values) {     
    Object.keys(this.props.data.dosisAlbaran).map((key) => {       
      if(values.dosisAlbaran){        
        //values.dosisAlbaran[key].rfidid = this.props.data.dosisAlbaran[key].rfidid
        values.dosisAlbaran[key] = this.props.data.dosisAlbaran[key]
      }
    })

    let mostrarMensaje = 0
    if (this.state.idPedido !== null) {
      if (this.props.data.totalesPorDosis) {
        Object.keys(this.props.data.totalesPorDosis).map((key) => {
          if (this.props.data.totalesPorDosis[key].dosisPedidas > 0 && this.props.data.totalesPorDosis[key].dosisPorServir > 0 && values.idEstado !== estadoAlbaranConstants["BORRADOR"]) {
             mostrarMensaje = 1
            }
          })
        }
      }
      let location = this.props.location

      if (mostrarMensaje === 0) {
        if (values.mostrarAvisoImprimirAlbaran) {
          this.props.actions.editarAlbaranEImprimirDatosAlbaran(values, this.props.data.idAlbaran, this.props.data.numeroAlbaran, false, location)
        } else {
          this.props.actions.editarAlbaran(values, false, location)
        }
      } else {
        if (values.mostrarAvisoImprimirAlbaran) {
          this.props.actions.editarAlbaranEImprimirDatosAlbaran(values, this.props.data.idAlbaran, this.props.data.numeroAlbaran, true, location)
        } else {
          this.props.actions.editarAlbaran(values, true, location)
      }
    }
  }

  validarRfidAlbaran (values) {
    console.log('abrirModalAlbaranValidacionRfid')
    this.props.actions.abrirModalAlbaranValidacionRfid()
    
    
    
  }

  handleChangeCliente (cliente) {
    this.setState({hayCliente: cliente})
    this.props.change('direccionEnvio', '')
    this.props.actions.comboDireccionCliente(cliente ? cliente.value : '', true, (data) => {
      if (data.length === 0) {
        this.props.change('direccionEnvio', '')
        this.setState({hayGranja: null})
      } else {
        data.forEach((dato) => {
          if (dato.predeterminado === true) {
            this.props.change('direccionEnvio', {value: dato.value, label: dato.label})
            this.setState({hayGranja: dato.value})
          }
        })
      }
    })
  }

  handleChangeClienteSecundario (cliente) {
    //this.setState({hayCliente: cliente})
    this.props.change('idDireccionClienteSecundaria', '')
    this.props.actions.comboDireccionClienteSecundaria(cliente ? cliente.value : '', true, (data) => {
      if (data.length === 0) {
        this.props.change('idDireccionClienteSecundaria', '')
        //this.setState({hayGranja: null})
      } 
    })
  }

  handleChangeMostrarIndex (mostrarIndex) {    
    this.props.change('mostrarIndex', mostrarIndex)    
  }

  verDetallePedido () {
    this.props.actions.abrirDetallesPedido(this.state.idPedido)
  }

  handleChangeDireccionCliente (direccion) {
    this.setState({hayGranja: direccion})
    this.props.change('transporte', '')
    this.props.change('ruta', '')
    this.props.actions.comboTransportistasCliente(direccion ? direccion.value : '')
    this.props.actions.comboRutaTransportistaCliente(direccion ? direccion.value : null, this.props.formModalAlbaran.transporte ? this.props.formModalAlbaran.transporte.value : null)
  }

  handleChangeTransportistaCliente (transporte) {
    this.props.change('ruta', '')
    this.props.change('conductor', '')
    this.props.change('vehiculo', '')
    this.props.actions.comboVehiculosTransportistaDeterminado(transporte ? transporte.value : '')
    this.props.actions.comboConductoresTransportistaDeterminado(transporte ? transporte.value : '')
    this.props.actions.comboRutaTransportistaCliente(this.props.formModalAlbaran.direccionEnvio ? this.props.formModalAlbaran.direccionEnvio.value : null, transporte ? transporte.value : null)
  }

  imprimirDatosAlbaran (values) {    
    values.mostrarIndex = this.props.formModalAlbaran.mostrarIndex
    return new Promise((resolve, reject) => {      
      this.props.actions.imprimirDatosAlbaran(values, this.props.data.idAlbaran, this.props.data.numeroAlbaran || null, this.props.history, () => {
        this.props.change('mostrarIndex', false) 
        resolve()
      }, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  imprimirDatosAlbaranCliente (values) {
    this.props.actions.imprimirDatosAlbaranCliente(values, this.props.data.idAlbaran, this.props.data.numeroAlbaran)
  }

  imprimirEtiquetaPDF () {
    this.props.actions.imprimirEtiquetaPDF(this.props.data.idAlbaran)
  }

  componentDidUpdate (prevProps) {
    if (this.props.data !== prevProps.data) {
      if (Object.keys(this.props.data).length > 0){
        this.props.data.cliente && this.props.actions.comboDireccionCliente(this.props.data.cliente.value)
        this.props.data.direccionEnvio && this.props.actions.comboTransportistasCliente(this.props.data.direccionEnvio.value)
        this.setState({idPedido: this.props.data.idPedido ? this.props.data.idPedido : null})
        this.props.data.cliente && this.setState({hayCliente: this.props.data.cliente.value})
        this.props.data.direccionEnvio && this.setState({hayGranja: this.props.data.direccionEnvio.value})
      }
    }

    if (this.props.combos.comboTransportistasCliente !== prevProps.combos.comboTransportistasCliente) {
      this.props.combos.comboTransportistasCliente.filter((dato) => {
        if (dato.predeterminado === true) {
          this.props.change('transporte', {value: dato.value, label: dato.label})
        }
      })
    }
    if (this.props.combos.comboRutaTransportistaCliente !== prevProps.combos.comboRutaTransportistaCliente) {
      this.props.combos.comboRutaTransportistaCliente.filter((dato) => {
        if (dato.predeterminado === true) {
          this.props.change('ruta', {value: dato.value, label: dato.label})
        }
      })
    }
    if (this.props.combos.comboConductoresTransportistaDeterminado !== prevProps.combos.comboConductoresTransportistaDeterminado) {
      this.props.combos.comboConductoresTransportistaDeterminado.filter((dato) => {
        if (dato.predeterminado === true) {
          this.props.change('conductor', {value: dato.value, label: dato.label})
        }
      })
    }
    if (this.props.combos.comboVehiculosTransportistaDeterminado !== prevProps.combos.comboVehiculosTransportistaDeterminado) {
      this.props.combos.comboVehiculosTransportistaDeterminado.filter((dato) => {
        if (dato.predeterminado === true) {
          this.props.change('vehiculo', {value: dato.value, label: dato.label})
        }
      })
    }    
  }

  componentDidMount () {
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.fetchPanelControl()
    this.props.actions.comboClienteSecundarioActivo()    
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, handleSubmit, list, showModalAlbaran, data, hasButtonChangeRecord = true,
      auth: {separadorMil, separadorDec},
      actions: {
        cerrarDetallesAlbaran, deleteModalAlbaranDosis, abrirDetallesAlbaran, abrirDetallesAlbaranDosis
      },
      combos: {
        comboCliente, comboDireccionCliente, comboClienteSecundarioActivo, comboDireccionClienteSecundaria, comboTransportistasCliente, comboRutaTransportistaCliente,
        comboConductoresTransportistaDeterminado, comboVehiculosTransportistaDeterminado, comboOperarioPredeterminado
      }
    } = this.props
    const idEstado = data.idEstado || estadoAlbaranConstants["BORRADOR"]

    /* console.log('panelControl',this.props.panelControl.boxRfid) */

    const tKey = 'ALBARANES.MODAL.'
    const idElemento = 'idAlbaranDosis'
    const table = {
      id: 'albaranExtraccionesTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && idEstado !== estadoAlbaranConstants["REALIZADO"],
      idElemento,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && idEstado !== estadoAlbaranConstants["REALIZADO"],
      noBlockOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && idEstado !== estadoAlbaranConstants["REALIZADO"],
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && idEstado !== estadoAlbaranConstants["REALIZADO"],
      onEdit: (row) => { abrirDetallesAlbaranDosis(row) },
      mostrarDuplicar: false,
      mostrarEliminar: idEstado !== estadoAlbaranConstants["REALIZADO"],
      onDelete: (row) => { deleteModalAlbaranDosis(row.idAlbaranDosis) },
      mostrarCambioEstado: false,
      columns: [
        {id: 'verracoPool', name: t(tKey + 'COLUMNS.VERRACO_POOL')},
        {id: 'idVerracoPool', name: t(tKey + 'COLUMNS.EXTRACCION_POOL'), type: 'numero', numDecimales: 0},
        {id: 'nombreRaza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},
        {id: 'nombreNivelGenetico', name: t(tKey + 'COLUMNS.NIVEL_GENETICO')},
        {id: 'nombreTipoDosis', name: t(tKey + 'COLUMNS.TIPO_DOSIS')},
        {id: 'dosisPedidas', name: t(tKey + 'COLUMNS.DOSIS_PEDIDAS'), type: 'numero', numDecimales: 0},
        {id: 'dosisEnviadas', name: t(tKey + 'COLUMNS.DOSIS_ENVIADAS'), type: 'numero', numDecimales: 0},    
      ],
      rows: data.dosisAlbaran,
      filtros: idEstado !== estadoAlbaranConstants["REALIZADO"] && <FiltrosModalAlbaranPage idElemento={idElemento}  hayCliente={this.state.hayCliente} hayGranja={this.state.hayGranja} />,
      showModal: this.props.showModalAlbaranDosis,
      tablaModal: true,
      idBlockOptions: 2,
      initialValues: {},
      leyenda: [
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.VERRACO'), color: '#FF6D29'},
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.POOL'), color: 'rgb(37, 56, 170)'}
      ],
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo === 'pool') {
          classRow = 'stock-dosis-pool'
        }else if (row.tipo === 'verraco') {
          classRow = 'stock-dosis-verraco'
        }
        return classRow
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row[idElemento]] = data.checkedFiltrosAlbaranDosis
        }
      }
    )

    return (
      <Modal show={showModalAlbaran} onHide={cerrarDetallesAlbaran} dialogClassName="xl" backdrop="static">
        <ModalAlbaranRfidValidarPage editarAlbaran= {handleSubmit((values) => { this.editarAlbaran.call(this, {...values, idEstado: estadoAlbaranConstants["REALIZADO"], mostrarAvisoImprimirAlbaran: true}) })}/>
        <ModalAlbaranDosisPage />
        <ModalDetallePedidoPage />
        <form className="form-albaran" onSubmit={handleSubmit(this.editarAlbaran.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tKey + (data && data.idAlbaran ? 'TITLE_MODIFICAR' : data && data.idAlbaranDuplicado ? 'TITLE_DUPLICAR' : 'TITLE_NUEVO'))}
            </Modal.Title>
            {data && data.idAlbaran && hasButtonChangeRecord && (
              <ButtonChangeRecord list={list.albaranes} idElemento="idAlbaran" currentId={data.idAlbaran} getNextRecord={abrirDetallesAlbaran} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="numeroAlbaran"
                name="numeroAlbaran"
                controlLabel={t(tKey + 'FORM.NUM_ALBARAN')}
                component={InputText}
                disabled={true}
                maxLength={true}
                valorMaxLength={150}
              />
              {
                this.state.idPedido !== null ? (
                  <Col sm={1} className="ver-pedido-albaran">
                    <Button type="button" className="btn btn-primary" onClick={() => this.verDetallePedido()}><RemoveRedEye/><span>{t(tKey + 'FORM.PEDIDO')}</span></Button>
                  </Col>
               ) : null
              }
              <Field
                colSm={this.state.idPedido !== null ? 2 : 3}
                id="origen"
                name="origen"
                controlLabel={t(tKey + 'FORM.ORIGEN')}
                component={InputText}
                disabled={true}
              />
              <Field
                colSm={3}
                id="fechaCreacion"
                name="fechaCreacion"
                controlLabel={t(tKey + 'FORM.FECHA_CREACION')}
                component={InputDatePicker}
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />
              <Field
                colSm={3}
                id="fechaRealizado"
                name="fechaRealizado"
                controlLabel={t(tKey + 'FORM.FECHA_REALIZADO')}
                component={InputDatePicker}
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />
            </Row>
            <Row>
              <Field
                colSm={3}
                id="cliente"
                name="cliente"
                controlLabel={t(tKey + 'FORM.CLIENTE') + '*'}
                component={InputSelect}
                options={comboCliente}
                valueKey="value"
                labelKey="label"
                validate={required}
                isClearable={false}
                disabled={idEstado !== estadoAlbaranConstants["BORRADOR"]}
                onInputChange={(cliente) => this.handleChangeCliente(cliente)}
              />
              <Field
                colSm={3}
                id="direccionEnvio"
                name="direccionEnvio"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + '*'}
                component={InputSelect}
                options={comboDireccionCliente}
                valueKey="value"
                labelKey="label"
                validate={required}
                isClearable={false}
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
                onInputChange={(direccion) => this.handleChangeDireccionCliente(direccion)}
              />
              <Field
                colSm={3}
                id="transporte"
                name="transporte"
                controlLabel={t(tKey + 'FORM.TRANSPORTE')}
                component={InputSelect}
                options={comboTransportistasCliente}
                valueKey="value"
                labelKey="label"
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
                onInputChange={(transporte) => this.handleChangeTransportistaCliente(transporte)}
              />
              <Field
                colSm={3}
                id="ruta"
                name="ruta"
                controlLabel={t(tKey + 'FORM.RUTA')}
                component={InputSelect}
                options={comboRutaTransportistaCliente}
                valueKey="value"
                labelKey="label"
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />
            </Row>            
            <Row>
              <Field
                colSm={3}
                id="conductor"
                name="conductor"
                controlLabel={t(tKey + 'FORM.CONDUCTOR')}
                component={InputSelect}
                options={comboConductoresTransportistaDeterminado}
                valueKey="value"
                labelKey="label"
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />
              <Field
                colSm={3}
                id="vehiculo"
                name="vehiculo"
                controlLabel={t(tKey + 'FORM.VEHICULO')}
                component={InputSelect}
                options={comboVehiculosTransportistaDeterminado}
                valueKey="value"
                labelKey="label"
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />
              <Field
                colSm={3}
                id="operario"
                name="operario"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.OPERARIO')}
                component={InputSelect}
                options={comboOperarioPredeterminado}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                isClearable={false}
              />
              {
                this.props.auth.codLicencia == 'LLCPIC' ? (
                  <Field                
                    id="mostrarIndex"
                    name="mostrarIndex"                    
                    colSm={3}                    
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.MOSTRARINDEX')}
                    onInputChange={(mostrarIndex) => this.handleChangeMostrarIndex(mostrarIndex)}                 
                    />  
                ) : null
              }
            </Row>
            {
                this.props.auth.codLicencia == 'CUARTESA' ? (
                  <Row>
                    <Field
                      colSm={3}
                      id="clienteSecundario"
                      name="clienteSecundario"
                      controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.CLIENTESECUNDARIO')}
                      component={InputSelect}
                      options={comboClienteSecundarioActivo}
                      valueKey="value"
                      labelKey="label"                    
                      isClearable={false}
                      disabled={idEstado !== estadoAlbaranConstants["BORRADOR"]}
                      onInputChange={(row) => this.handleChangeClienteSecundario(row)}
                    />
                    <Field
                      colSm={3}
                      id="idDireccionClienteSecundaria"
                      name="idDireccionClienteSecundaria"
                      controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.GRANJASECUNDARIA')}
                      component={InputSelect}
                      options={comboDireccionClienteSecundaria}
                      valueKey="value"
                      labelKey="label"                      
                      isClearable={false}
                      disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}                     
                    />
                  </Row>
                ) : null
            }
            <hr />
            <SimpleTablePage {...table} />
            <hr />
            <Row>
              <FormGroupSubtitle colSm={12} small={true}>
                {t(tKey + 'FORM.RESUMEN')}
              </FormGroupSubtitle>
              <Col sm={12}>
                <Row className="tablaTotales" style={{ margin: '0'}}>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.TIPO_DOSIS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_PEDIDAS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_ENVIADAS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_FALTAN_POR_SERVIR')}</span>
                  </Col>
                </Row>
                {data.totalesPorDosis && Object.keys(data.totalesPorDosis).map((key) => {
                  let estado = 0
                  if (data.totalesPorDosis[key].dosisPedidas > 0 && data.totalesPorDosis[key].dosisPorServir > 0) {
                    estado = 'has-error'
                  }
                  return (
                    <Row key={key} className={data.totalesPorDosis[key].tipoDosis === 'total' ? 'tablaTotales resumen-totales' : 'tablaTotales'} style={{margin: '0', borderTop: 'none'}}>
                      <Col sm={3} className="estados-total-verraco" style={{borderLeft: '1px solid #d1d1d1'}}>
                        <span>{data.totalesPorDosis[key].tipoDosis === 'total' ? t(tKey + 'FORM.TOTAL') : data.totalesPorDosis[key].tipoDosis}</span>
                      </Col>
                      <Col sm={3} className="estados-total-verraco">
                        <span>{number_formatter(data.totalesPorDosis[key].dosisPedidas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={3} className="estados-total-verraco">
                        <span>{number_formatter(data.totalesPorDosis[key].dosisEnviadas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={3} className={`estados-total-verraco ${estado} no-bg`}>
                        <span>{number_formatter(data.totalesPorDosis[key].dosisPorServir, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Row>
                  )
                })}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={2}>
                <Row>
                  <Field
                    colSm={12}
                    id="estado"
                    name="estado"
                    controlLabel={t(tKey + 'FORM.ESTADO')}
                    component={InputText}
                    disabled={true}
                  />

                  {/* SIEMPRE Borrador */} 
                  {idEstado !== estadoAlbaranConstants["BORRADOR"] && (
                    <Col sm={12}>
                      <Button
                        type="button"
                        className={"btn btn-primary btn-input-style no-margin" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) ? handleSubmit((values) => { this.editarAlbaran.call(this, {...values, idEstado: estadoAlbaranConstants["BORRADOR"]}) }) : undefined}
                      >
                        {t(tKey + 'BUTTONS.BORRADOR')}
                      </Button>
                    </Col>
                  )}
                  
                  {/* Validar, estructurado con un else vacío */} 
                  {idEstado === estadoAlbaranConstants["BORRADOR"]  && this.props.panelControl.boxRfid ? 
                   <Col key="boton-estado-validar" sm={12}>
                    <Button
                      type="button"
                      className={"btn btn-primary btn-input-style no-margin" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) ? 
                        handleSubmit((values) => { this.validarRfidAlbaran.call(this, {...values, idEstado: estadoAlbaranConstants["VALIDADO"], mostrarAvisoImprimirAlbaran : true}) })
                        : undefined}
                    >
                      {t(tKey + 'BUTTONS.VALIDAR')}
                    </Button>
                  </Col>
                  : 
                    <>
                    </> 
                  }

                  {/* Realizar*/} 
                  {idEstado === estadoAlbaranConstants["BORRADOR"] && !this.props.panelControl.boxRfid  && (
                    <Col key="boton-estado-realizar" sm={12}>
                      <Button
                        type="button"
                        className={"btn btn-primary btn-input-style no-margin" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) 
                        ? handleSubmit((values) => { this.editarAlbaran.call(this, {...values, idEstado: estadoAlbaranConstants["REALIZADO"], mostrarAvisoImprimirAlbaran: true}) }) : undefined}
                      >
                        {t(tKey + 'BUTTONS.REALIZAR')}
                      </Button>
                    </Col>
                  )}                  

                  {/* Cancelar */} 
                  {idEstado === estadoAlbaranConstants["BORRADOR"] && (
                    <Col key="boton-estado-cancelar"  sm={12}>
                      <Button
                        type="button"
                        className={"btn btn-primary btn-input-style no-margin" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) ? handleSubmit((values) => { this.editarAlbaran.call(this, {...values, idEstado: estadoAlbaranConstants["CANCELADO"]}) }) : undefined}
                      >
                        {t(tKey + 'BUTTONS.CANCELAR')}
                      </Button>
                    </Col>
                  )}

                </Row>
              </Col>
              <Field
                colSm={5}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
              <Field
                colSm={5}
                id="observacionesCliente"
                name="observacionesCliente"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES_CLIENTE')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {data.idAlbaran && [
              <Button key="boton-imprimir-datos-albaran" type="button" className="btn btn-primary" onClick={handleSubmit(this.imprimirDatosAlbaran.bind(this))}>{t(tKey + 'BUTTONS.IMPRIMIR_ALBARAN')}</Button>,
              //<Button key="boton-imprimir-datos-albaran-cliente" type="button" className="btn btn-primary" onClick={handleSubmit(this.imprimirDatosAlbaranCliente.bind(this))}>{t(tKey + 'BUTTONS.IMPRIMIR_DATOS_CLIENTE')}</Button>
              <Button key="boton-imprimir-etiqueta" type="button" className="btn btn-primary" onClick={handleSubmit(this.imprimirEtiquetaPDF.bind(this))}>{t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.IMPRIMIR_ETIQUETA')}</Button>
            ]}
            <Button
              type="submit"
              className={'btn btn-primary' + (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2 ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2)}
            >{t(tKey + 'BUTTONS.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={cerrarDetallesAlbaran}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaran',
})(ModalAlbaran))
export default {
  ABRIR_MODAL_VALIDACION_MATERIA_PRIMA: 'ABRIR_MODAL_VALIDACION_MATERIA_PRIMA',
  CLOSE_MODAL_VALIDACION_MATERIA_PRIMA: 'CLOSE_MODAL_VALIDACION_MATERIA_PRIMA',
  SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA: 'SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA',
  SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS: 'SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS',
  OBTENER_VALIDACION_MATERIA_PRIMAS_ALMACEN: 'OBTENER_VALIDACION_MATERIA_PRIMAS_ALMACEN',
  FILTRAR_VALIDACION_MATERIA_PRIMA: 'FILTRAR_VALIDACION_MATERIA_PRIMA',
  FILTRAR_VALIDACION_MATERIA_PRIMA_SUCCESS: 'FILTRAR_VALIDACION_MATERIA_PRIMA_SUCCESS',
  FILTROS_VALIDACION_MATERIA_PRIMA_SUCCESS: 'FILTROS_VALIDACION_MATERIA_PRIMA_SUCCESS',
  RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA: 'RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA',
  RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS: 'RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS',
  EDITAR_VALIDACION_MATERIA_PRIMA: 'EDITAR_VALIDACION_MATERIA_PRIMA',
  DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA: 'DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA',
  DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS: 'DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS',
  CAMBIAR_ESTADO_VALIDACION_MATERIA_PRIMA: 'CAMBIAR_ESTADO_VALIDACION_MATERIA_PRIMA',
  ON_DELETE_VALIDACION_MATERIA_PRIMA: 'ON_DELETE_VALIDACION_MATERIA_PRIMA',
  SELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA: 'SELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA',
  DESELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA: 'DESELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA',
  CAMBIAR_ESTADO_SELECCION_VALIDACION_MATERIA_PRIMA: 'CAMBIAR_ESTADO_SELECCION_VALIDACION_MATERIA_PRIMA',
  DELETE_SELECCION_VALIDACION_MATERIA_PRIMA: 'DELETE_SELECCION_VALIDACION_MATERIA_PRIMA',
  CREATE_CSV_VALIDACION_MATERIA_PRIMA: 'CREATE_CSV_VALIDACION_MATERIA_PRIMA',
  CREATE_CSV_VALIDACION_MATERIA_PRIMA_SUCCESS: 'CREATE_CSV_VALIDACION_MATERIA_PRIMA_SUCCESS',
  IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA: 'IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA',
  IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA_SUCCESS: 'IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA_SUCCESS',
  ABRIR_MODAL_VERRACO_POOL: 'ABRIR_MODAL_VERRACO_POOL',
  CERRAR_MODAL_VERRACO_POOL: 'CERRAR_MODAL_VERRACO_POOL',
  OBTENER_VERRACOS_MATERIA_PRIMA: 'OBTENER_VERRACOS_MATERIA_PRIMA',
  OBTENER_POOL_MATERIA_PRIMA: 'OBTENER_POOL_MATERIA_PRIMA',
  OBTENER_VERRACOS_MATERIA_PRIMA_SUCCESS: 'OBTENER_VERRACOS_MATERIA_PRIMA_SUCCESS',
  OBTENER_POOL_MATERIA_PRIMA_SUCCESS: 'OBTENER_POOL_MATERIA_PRIMA_SUCCESS',
  SUBMIT_NUEVO_VERRACO_POOL: 'SUBMIT_NUEVO_VERRACO_POOL',
  ANADIR_VERRACO_MATERIA_PRIMA: 'ANADIR_VERRACO_MATERIA_PRIMA',
  ANADIR_POOL_MATERIA_PRIMA: 'ANADIR_POOL_MATERIA_PRIMA',
  EDITAR_VERRACO_MATERIA_PRIMA: 'EDITAR_VERRACO_MATERIA_PRIMA',
  EDITAR_POOL_MATERIA_PRIMA: 'EDITAR_POOL_MATERIA_PRIMA',
  GUARDAR_Y_NUEVO_VERRACO_POOL: 'GUARDAR_Y_NUEVO_VERRACO_POOL',
  RECUPERAR_DATOS_MODAL_VERRACO_POOL: 'RECUPERAR_DATOS_MODAL_VERRACO_POOL',
  RECUPERAR_DATOS_MODAL_VERRACO_POOL_SUCCESS: 'RECUPERAR_DATOS_MODAL_VERRACO_POOL_SUCCESS',
  EDITAR_VERRACO_POOL: 'EDITAR_VERRACO_POOL',
  EDITAR_VERRACO_POOL_SUCCESS: 'EDITAR_VERRACO_POOL_SUCCESS',
  DUPLICAR_DATOS_MODAL_VERRACO_POOL: 'DUPLICAR_DATOS_MODAL_VERRACO_POOL',
  DUPLICAR_DATOS_MODAL_VERRACO_POOL_SUCCESS: 'DUPLICAR_DATOS_MODAL_VERRACO_POOL_SUCCESS',
  ON_DELETE_VERRACO_POOL: 'ON_DELETE_VERRACO_POOL',
  SELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS: 'SELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS',
  DESELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS: 'DESELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS',
  DELETE_SELECCION_VERRACO_POOL: 'DELETE_SELECCION_VERRACO_POOL',
  GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA: 'GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA',
  GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS: 'GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS',
  EDITAR_VERRACO_MATERIA_PRIMA_SUCCESS: 'EDITAR_VERRACO_MATERIA_PRIMA_SUCCESS',
  EDITAR_POOL_MATERIA_PRIMA_SUCCESS: 'EDITAR_POOL_MATERIA_PRIMA_SUCCESS',
  RESET_VALIDACION_MATERIA_PRIMA: 'RESET_VALIDACION_MATERIA_PRIMA'
}
import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalComboCausaEliminacionPage from '../../containers/comboCausaEliminacion/ModalComboCausaEliminacionPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosComboCausaEliminacion from '../../containers/comboCausaEliminacion/FiltrosComboCausaEliminacionPage'

class ComboCausaEliminacion extends Component {

  componentDidMount () {
    document.title = this.props.t('COMBO_CAUSA_ELIMINACION.TITLE') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchComboCausaEliminacion()
  }

  render () {
    const {
      t,
      comboCausaEliminacion: { list },
      actions: {recuperarDatosModalComboCausaEliminacion }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.id
    )

    const table = {
      id: 'comboCausaEliminacionTable',
      // hasCheckbox: true,
      hasOptions: false,
      columns: [
        {id: 'text', name: t('COMBO_CAUSA_ELIMINACION.TABLA.NOMBRE')},
        {id: 'code', name: t('COMBO_CAUSA_ELIMINACION.TABLA.CODIGO')},
        {id: 'activo', name: t('COMBO_CAUSA_ELIMINACION.TABLA.ACTIVO')}
      ],
      rows: list,
      onEdit: (row) => recuperarDatosModalComboCausaEliminacion(row.id),
      filtros: <FiltrosComboCausaEliminacion/>
    }

    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalComboCausaEliminacionPage/>
        <ListadoPage t_key="COMBO_CAUSA_ELIMINACION." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default ComboCausaEliminacion
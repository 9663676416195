export default {
  FETCH_TIPO_INTERVENCION_SANITARIA: 'FETCH_TIPO_INTERVENCION_SANITARIA',
  FETCH_TIPO_INTERVENCION_SANITARIA_SUCCESS: 'FETCH_TIPO_INTERVENCION_SANITARIA_SUCCESS',
  ABRIR_MODAL_VACIO_TIPO_INTERVENCION_SANITARIA: 'ABRIR_MODAL_VACIO_TIPO_INTERVENCION_SANITARIA',
  SELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA: 'SELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA',
  DESELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA: 'DESELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA',
  SUBMIT_NUEVO_TIPO_INTERVENCION_SANITARIA: 'SUBMIT_NUEVO_TIPO_INTERVENCION_SANITARIA',
  RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA: 'RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA',
  RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS: 'RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS',
  EDITAR_TIPO_INTERVENCION_SANITARIA: 'EDITAR_TIPO_INTERVENCION_SANITARIA',
  DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA: 'DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA',
  DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS: 'DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS',
  CAMBIAR_ESTADO_TIPO_INTERVENCION_SANITARIA: 'CAMBIAR_ESTADO_TIPO_INTERVENCION_SANITARIA',
  ON_DELETE_TIPO_INTERVENCION_SANITARIA: 'ON_DELETE_TIPO_INTERVENCION_SANITARIA',
  CAMBIAR_ESTADO_SELECCION_TIPO_INTERVENCION_SANITARIA: 'CAMBIAR_ESTADO_SELECCION_TIPO_INTERVENCION_SANITARIA',
  DELETE_SELECCION_TIPO_INTERVENCION_SANITARIA: 'DELETE_SELECCION_TIPO_INTERVENCION_SANITARIA',
  GUARDAR_Y_NUEVO_TIPO_INTERVENCION_SANITARIA: 'GUARDAR_Y_NUEVO_TIPO_INTERVENCION_SANITARIA',
  CREATE_CSV_TIPO_INTERVENCION: 'CREATE_CSV_TIPO_INTERVENCION',
  CREATE_CSV_TIPO_INTERVENCION_SUCCESS: 'CREATE_CSV_TIPO_INTERVENCION_SUCCESS',
  IMPRIMIR_PDF_TIPO_INTERVENCION: 'IMPRIMIR_PDF_TIPO_INTERVENCION',
  IMPRIMIR_PDF_TIPO_INTERVENCION_SUCCESS: 'IMPRIMIR_PDF_TIPO_INTERVENCION_SUCCESS'
}
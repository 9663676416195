import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import { permisos as permisosConstant } from '../../constants/permisos'

class FiltrosComboObservacionesEyaculadoEliminado extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
    }
  }

  render () {
  const {
    t,
    actions: {
      abrirModalVacioComboObservacionesEyaculadoEliminado
    }
  } = this.props

  const tKey = 'COMBO_OBSERVACIONES_EYACULADO_ELIMINADO.'

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {/*(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && [
                <div key="block-options" id="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={null}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]*/}
              { this.props.auth && (this.props.auth.version >= 2 || this.props.auth.codLicencia == 'LLCPIC' || (this.props.auth.customizations && this.props.auth.customizations.find((customization) => {return customization=='USA'}))) && [
                <div key="block-options" className="button-group buttons-min">
                  <button
                    type="button"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VERRACOS] < 2) ? ' disabled' : '')}
                    onClick={abrirModalVacioComboObservacionesEyaculadoEliminado}
                  >
                    <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                  </button>
                </div>
              ]}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosComboObservacionesEyaculadoEliminado'
})(FiltrosComboObservacionesEyaculadoEliminado))

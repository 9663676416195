import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import GrupoVerraco from '../../components/grupoVerraco/GrupoVerraco'
import {fetchGrupoVerraco, submitNuevoGrupoVerraco, recuperarDatosModalGrupoVerraco, duplicarDatosModalGrupoVerraco, cambiarEstadoGrupoVerraco, fetchFiltrarGrupoVerraco,
  onDeleteGrupoVerraco} from '../../actions/grupoVerraco/GrupoVerraco'

export function mapStateToProps (state) {
  return {
    grupoVerraco: {...state.grupoVerraco},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    showModal: state.common.showModal,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchGrupoVerraco,
      submitNuevoGrupoVerraco,
      recuperarDatosModalGrupoVerraco,
      duplicarDatosModalGrupoVerraco,
      cambiarEstadoGrupoVerraco,
      onDeleteGrupoVerraco,
      fetchFiltrarGrupoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GrupoVerraco))
import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import FiltrosTipoIntervencionSanitariaPage from '../../../containers/planSanitario/tipoIntervencionSanitaria/FiltrosTipoIntervencionSanitariaPage'
import ModalTipoIntervencionSanitariaPage from '../../../containers/planSanitario/tipoIntervencionSanitaria/ModalTipoIntervencionSanitariaPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'

class TipoIntervencionSanitaria extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.TIPOS_INTERVENCION') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchTipoIntervencionSanitaria()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina,paginaActual,
      tipoIntervencionSanitaria:{list, checkedFiltrosTipoIntervencionSanitaria, data, dataDuplicar},
      actions: {recuperarDatosModalTipoIntervencionSanitaria, duplicarDatosModalTipoIntervencionSanitaria, cambiarEstadoTipoIntervencionSanitaria, onDeleteTipoIntervencionSanitaria}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idTipoIntervencion
    )

    const tableTipoIntervencionSanitaria = {
      id: 'tipoIntervencionSanitariaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1),
      hasOptions: true,
      columns: [
        {id: 'tipoIntervencion', name: t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.TIPO_INTERVENCION_SANITARIA')},
        {id: 'observaciones', name: t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.DESCRIPCION')},
      ],
      rows: list,
      filtros: <FiltrosTipoIntervencionSanitariaPage/>,
      onEdit: (row) => recuperarDatosModalTipoIntervencionSanitaria(row.idTipoIntervencion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1,
      duplicar: (row) =>duplicarDatosModalTipoIntervencionSanitaria(row.idTipoIntervencion),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1,
      cambiarEstado: (row) => cambiarEstadoTipoIntervencionSanitaria(row.idTipoIntervencion),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1,
      onDelete: (row) => onDeleteTipoIntervencionSanitaria(row.idTipoIntervencion),
      initialValues: {}
    }
    tableTipoIntervencionSanitaria.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableTipoIntervencionSanitaria.initialValues['check' + row.idElemento] = checkedFiltrosTipoIntervencionSanitaria
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalTipoIntervencionSanitariaPage
          duplicar={(row) =>duplicarDatosModalTipoIntervencionSanitaria(row.idTipoIntervencion)}
        />
        <ListadoPage t_key="PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA." table={tableTipoIntervencionSanitaria}>
        </ListadoPage>
      </div>
    )
  }
}

export default TipoIntervencionSanitaria
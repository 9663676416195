import actionTypes from '../../constants/actions/validacionMateriaPrima/validacionMateriaPrima'

export function initialState () {
  return {
    listValidacionMateriaPrima: [],
    checkedFiltrosValidacionMateriaPrima: false,
    showModalValidacionMateriaPrima: false,
    filtros: {},
    data: [],
    dataDuplicar: [],
    datosExcelValidacionMateriaPrima: [],
    datosPdfValidacionMateriaPrima: [],
    listVerracoPool: [],
    checkedFiltrosVerracoPool: false,
    showModalVerracoPool: false,
    listVerracosMateriaPrima: [],
    listPoolMateriaPrima: [],
    comboVerracosMateriaPrima: [],
    comboPoolMateriaPrima: [],
    dataVerracoPool: [],
    dataDuplicarVerracoPool: []
  }
}

export function filtrarValidacionMateriaPrimaSuccess (state, {listValidacionMateriaPrima}) {
  return {
    ...state,
    listValidacionMateriaPrima: listValidacionMateriaPrima
  }
}

export function filtrosValidacionMateriaPrimaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function abrirModalValidacionMateriaPrima (state) {
  return {
    ...state,
    showModalValidacionMateriaPrima: true,
    data: [],
    dataDuplicar: [],
    listVerracoPool: [],
    listVerracosMateriaPrima: [],
    listPoolMateriaPrima: []
  }
}

export function resetValidacionMateriaPrima (state) {
  return {
    ...state,
    data: [],
    dataDuplicar: [],
    listVerracoPool: [],
    listVerracosMateriaPrima: [],
    listPoolMateriaPrima: []
  }
}

export function closeModalValidacionMateriaPrima (state) {
  return {
    ...state,
    showModalValidacionMateriaPrima: false,
    data: [],
    dataDuplicar: [],
    listVerracoPool: [],
    listVerracosMateriaPrima: [],
    listPoolMateriaPrima: []
  }
}

export function recuperarDatosModalValidacionMateriaPrimaSuccess (state, {data}) {
  return {
    ...state,
    data: data,
    showModalValidacionMateriaPrima: true,
    dataDuplicar: []
  }
}

export function duplicarDatosModalValidacionMateriaPrimaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    showModalValidacionMateriaPrima: true,
    data: []
  }
}

export function seleccionarTodosValidacionMateriaPrima (state) {
  return {
    ...state,
    checkedFiltrosValidacionMateriaPrima: true
  }
}

export function deSeleccionarTodosValidacionMateriaPrima (state) {
  return {
    ...state,
    checkedFiltrosValidacionMateriaPrima: false
  }
}

export function crearCsvValidacionMateriaPrimaSuccess (state, {datosExcelValidacionMateriaPrima}) {
  return {
    ...state,
    datosExcelValidacionMateriaPrima
  }
}

export function imprimirPdfValidacionMateriaPrimaSuccess (state, {datosPdfValidacionMateriaPrima}) {
  return {
    ...state,
    datosPdfValidacionMateriaPrima
  }
}

export function abrirModalVerracoPool (state) {
  return {
    ...state,
    showModalVerracoPool: true,
    dataVerracoPool: [],
    dataDuplicarVerracoPool: []
  }
}

export function cerrarModalVerracoPool (state) {
  return {
    ...state,
    showModalVerracoPool: false,
    dataVerracoPool: [],
    dataDuplicarVerracoPool: []
  }
}

export function obtenerVerracosMateriaPrimaSuccess (state, {comboVerracosMateriaPrima}) {
  return {
    ...state,
    comboVerracosMateriaPrima: comboVerracosMateriaPrima
  }
}

export function obtenerPoolMateriaPrimaSucccess (state, {comboPoolMateriaPrima}) {
  return {
    ...state,
    comboPoolMateriaPrima: comboPoolMateriaPrima
  }
}

export function submitNuevoVerracoPool (state, {listVerracoPool}) {
  return {
    ...state,
    listVerracoPool: state.listVerracoPool.concat(listVerracoPool),
    showModalVerracoPool: false
  }
}

export function anadirVerracoMateriaPrima (state, {listVerracosMateriaPrima}) {
  return {
    ...state,
    listVerracosMateriaPrima: state.listVerracosMateriaPrima.concat(listVerracosMateriaPrima)
  }
}

export function anadirPoolMateriaPrima (state, {listPoolMateriaPrima}) {
  return {
    ...state,
    listPoolMateriaPrima: state.listPoolMateriaPrima.concat(listPoolMateriaPrima)
  }
}

export function guardarYnuevoVerracoPool (state, {listVerracoPool}) {
  return {
    ...state,
    listVerracoPool: state.listVerracoPool.concat(listVerracoPool)
  }
}

export function recuperarDatosModalVerracoPoolSuccess (state, {dataVerracoPool}) {
  return {
    ...state,
    showModalVerracoPool: true,
    dataVerracoPool: dataVerracoPool,
    dataDuplicarVerracoPool: []
  }
}

export function editarVerracoPoolSuccess (state, {listVerracoPool}) {
  return {
    ...state,
    listVerracoPool: listVerracoPool,
    showModalVerracoPool: false
  }
}

export function editarVerracoMateriaPrimaSuccess (state, {listVerracosMateriaPrima}) {
  return {
    ...state,
    listVerracosMateriaPrima: listVerracosMateriaPrima
  }
}

export function editarPoolMateriaPrimaSuccess (state, {listPoolMateriaPrima}) {
  return {
    ...state,
    listPoolMateriaPrima: listPoolMateriaPrima
  }
}

export function duplicarDatosModalVerracoPoolSuccess (state, {dataDuplicarVerracoPool}) {
  return {
    ...state,
    showModalVerracoPool: true,
    dataDuplicarVerracoPool: dataDuplicarVerracoPool,
    dataVerracoPool: []
  }
}

export function seleccionarTodosVerracoPoolAnadidos (state) {
  return {
    ...state,
    checkedFiltrosVerracoPool: true
  }
}

export function deSeleccionarTodosVerracoPoolAnadidos (state) {
  return {
    ...state,
    checkedFiltrosVerracoPool: false
  }
}

export function deleteSeleccionVerracoPool (state, {listVerracoPool}) {
  return {
    ...state,
    listVerracoPool: listVerracoPool,
    checkedFiltrosVerracoPool: false,
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FILTRAR_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return filtrarValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.FILTROS_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return filtrosValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VALIDACION_MATERIA_PRIMA:
      return abrirModalValidacionMateriaPrima(state, action)
    case actionTypes.CLOSE_MODAL_VALIDACION_MATERIA_PRIMA:
      return closeModalValidacionMateriaPrima(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return recuperarDatosModalValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return duplicarDatosModalValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA:
      return seleccionarTodosValidacionMateriaPrima(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA:
      return deSeleccionarTodosValidacionMateriaPrima(state, action)
    case actionTypes.CREATE_CSV_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return crearCsvValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA_SUCCESS:
      return imprimirPdfValidacionMateriaPrimaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VERRACO_POOL:
      return abrirModalVerracoPool(state, action)
    case actionTypes.CERRAR_MODAL_VERRACO_POOL:
      return cerrarModalVerracoPool(state, action)
    case actionTypes.OBTENER_VERRACOS_MATERIA_PRIMA_SUCCESS:
      return obtenerVerracosMateriaPrimaSuccess(state, action)
    case actionTypes.OBTENER_POOL_MATERIA_PRIMA_SUCCESS:
      return obtenerPoolMateriaPrimaSucccess(state, action)
    case actionTypes.SUBMIT_NUEVO_VERRACO_POOL:
      return submitNuevoVerracoPool(state, action)
    case actionTypes.ANADIR_VERRACO_MATERIA_PRIMA:
      return anadirVerracoMateriaPrima(state, action)
    case actionTypes.ANADIR_POOL_MATERIA_PRIMA:
      return anadirPoolMateriaPrima(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_VERRACO_POOL:
      return guardarYnuevoVerracoPool(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_VERRACO_POOL_SUCCESS:
      return recuperarDatosModalVerracoPoolSuccess(state, action)
    case actionTypes.EDITAR_VERRACO_POOL_SUCCESS:
      return editarVerracoPoolSuccess(state, action)
    case actionTypes.EDITAR_VERRACO_MATERIA_PRIMA_SUCCESS:
      return editarVerracoMateriaPrimaSuccess(state, action)
    case actionTypes.EDITAR_POOL_MATERIA_PRIMA_SUCCESS:
      return editarPoolMateriaPrimaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_VERRACO_POOL_SUCCESS:
      return duplicarDatosModalVerracoPoolSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS:
      return seleccionarTodosVerracoPoolAnadidos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS:
      return deSeleccionarTodosVerracoPoolAnadidos(state, action)
    case actionTypes.DELETE_SELECCION_VERRACO_POOL:
      return deleteSeleccionVerracoPool(state, action)
    case actionTypes.RESET_VALIDACION_MATERIA_PRIMA:
      return resetValidacionMateriaPrima(state, action)
    default:
      return state
  }
}
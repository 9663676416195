import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTipoIntervencionSanitaria from '../../../components/planSanitario/tipoIntervencionSanitaria/ModalTipoIntervencionSanitaria'
import {submitNuevoTipoIntervencionSanitaria, recuperarDatosModalTipoIntervencionSanitaria, editarTipoIntervencionSanitaria, guardarYnuevoTipoIntervencionSanitaria
} from '../../../actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'
import {closeModal} from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.tipoIntervencionSanitaria,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevoTipoIntervencionSanitaria,
      recuperarDatosModalTipoIntervencionSanitaria,
      editarTipoIntervencionSanitaria,
      guardarYnuevoTipoIntervencionSanitaria
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTipoIntervencionSanitaria))
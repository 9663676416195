import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import {fetchPanelControl} from '../../actions/panelControl/panelControl'
import { fetchSeguimientos, deleteSeguimiento, openModalFormSeguimiento, openModalListadoSeguimiento } from '../../actions/seguimiento/seguimiento'
import Seguimiento from '../../components/seguimiento/Seguimiento'
import {comboOperario} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.seguimiento,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: {
      comboOperario: state.combos.comboOperario
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSeguimientos,
      deleteSeguimiento,
      openModalFormSeguimiento,
      openModalListadoSeguimiento,
      yesNoModal,
      fetchPanelControl,
      comboOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Seguimiento))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalEntradaMercancia from '../../components/entradaMercancia/ModalEntradaMercancia'
// import {} from '../../actions/combos/combos'
import { recuperarDatosModalEntradaMercancia, closeModalEntradaMercancia, submitNuevoEntradaMercancia, editarEntradaMercancia, recuperarModalEntradaMercanciaProductoAnadido, duplicarDatosModalEntradaMercanciaAnadido,
  onDeleteEntradaMercanciaProducto, editarEntradaMercanciaProductoSuccess, guardarYnuevoEntradaMercancia } from '../../actions/entradaMercancia/entradaMercancia'

export function mapStateToProps (state) {
  return {
    ...state.entradaMercancia,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalEntradaMercancia,
     closeModalEntradaMercancia,
     submitNuevoEntradaMercancia,
     editarEntradaMercancia,
     recuperarModalEntradaMercanciaProductoAnadido,
     duplicarDatosModalEntradaMercanciaAnadido,
     onDeleteEntradaMercanciaProducto,
     editarEntradaMercanciaProductoSuccess,
     guardarYnuevoEntradaMercancia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEntradaMercancia))
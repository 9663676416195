import actionTypes from '../../../constants/actions/planSanitario/controlCalidad/controlMorfologico'

export function fetchControlMorfologico(filtros){
  return {
    type: actionTypes.FETCH_CONTROL_MORFOLOGICO,
    filtros
  }
}

export function fetchControlMorfologicoSuccess(mainList, filtros){
  return {
    type: actionTypes.FETCH_CONTROL_MORFOLOGICO_SUCCESS,
    mainList,
    filtros
  }
}

export function seleccionarTodosControlMorfologico(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_CONTROL_MORFOLOGICO
  }
}

export function deSeleccionarTodosControlMorfologico(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CONTROL_MORFOLOGICO
  }
}

export function openModalListadoControlMorfologico(idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO,
    idAnalisis,
    idPool
  }
}

export function openModalListadoControlMorfologicoSuccess(list, conservacion, codigo, idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool
  }
}

export function closeModalListadoControlMorfologico(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO
  }
}

export function closeModalListadoControlMorfologicoSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO_SUCCESS
  }
}

export function seleccionarTodosModalControlMorfologico(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO
  }
}

export function deSeleccionarTodosModalControlMorfologico(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_MORFOLOGICO
  }
}

export function deleteControlMorfologico(row){
  return {
    type: actionTypes.DELETE_CONTROL_MORFOLOGICO,
    row
  }
}

export function deleteSeleccionControlMorfologico(list){
  return {
    type: actionTypes.DELETE_SELECCION_CONTROL_MORFOLOGICO,
    list
  }
}

export function crearCsvControlMorfologico(list){
  return {
    type: actionTypes.CREATE_CSV_CONTROL_MORFOLOGICO,
    list
  }
}

export function imprimirPdfControlMorfologico(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CONTROL_MORFOLOGICO,
    list
  }
}

export function openModalFormControlMorfologico(idControlMorfologico){
  return {
    type: actionTypes.OPEN_MODAL_FORM_CONTROL_MORFOLOGICO,
    idControlMorfologico
  }
}

export function openModalFormControlMorfologicoSuccess(data){
  return {
    type: actionTypes.OPEN_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS,
    data
  }
}

export function guardarModalFormControlMorfologico(values){
  return {
    type: actionTypes.GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO,
    values
  }
}

export function guardarModalFormControlMorfologicoSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS
  }
}

export function closeModalFormControlMorfologico(){
  return {
    type: actionTypes.CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO
  }
}

export function closeModalFormControlMorfologicoSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO_SUCCESS
  }
}

export function obtenerYGuardarResultadoSistemaAnalisisControlMorfologico(tipoAnalisis){
  return {
    type: actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO,
    tipoAnalisis
  }
}

export function abrirModalInicio () {
  return {
    type: actionTypes.ABRIR_MODAL_INICIO
  }
}

export function closeModalInicio () {
  return {
    type: actionTypes.CLOSE_MODAL_INICIO
  }
}

export function fetchFiltrarModalInicio (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO,
    values
  }
}

export function fetchFiltrarModalInicioSuccess (listVerracos,filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO_SUCCESS,
    listVerracos,
    filtros
  }
}

export function seleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO
  }
}

export function deSeleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO
  }
}

export function anadirVerracosModalInicio (listVerracosAsignados) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO,
    listVerracosAsignados
  }
}

export function anadirVerracosModalInicioSuccess (listVerracosAsignados) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO_SUCCESS,
    listVerracosAsignados
  }
}

export function guardarResultadoSistemaAnalisisControlMorfologico(data, tipoAnalisis) {
  return{
    type: actionTypes.GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO,
    data,
    tipoAnalisis
  }
}

export default {
  fetchControlMorfologico,
  fetchControlMorfologicoSuccess,
  seleccionarTodosControlMorfologico,
  deSeleccionarTodosControlMorfologico,
  openModalListadoControlMorfologico,
  openModalListadoControlMorfologicoSuccess,
  closeModalListadoControlMorfologico,
  closeModalListadoControlMorfologicoSuccess,
  seleccionarTodosModalControlMorfologico,
  deSeleccionarTodosModalControlMorfologico,
  deleteControlMorfologico,
  deleteSeleccionControlMorfologico,
  crearCsvControlMorfologico,
  imprimirPdfControlMorfologico,
  openModalFormControlMorfologico,
  openModalFormControlMorfologicoSuccess,
  guardarModalFormControlMorfologico,
  guardarModalFormControlMorfologicoSuccess,
  closeModalFormControlMorfologico,
  closeModalFormControlMorfologicoSuccess,
  obtenerYGuardarResultadoSistemaAnalisisControlMorfologico,
  abrirModalInicio,
  closeModalInicio,
  fetchFiltrarModalInicio,
  fetchFiltrarModalInicioSuccess,
  seleccionarTodosVerracosInicio,
  deSeleccionarTodosVerracosInicio,
  anadirVerracosModalInicio,
  anadirVerracosModalInicioSuccess,
  guardarResultadoSistemaAnalisisControlMorfologico
}

import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'

class FiltrosInformesIntervenciones extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeIntervenciones({})
    this.props.actions.comboNombreProductos()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboVerraco()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboOperario()
    this.props.actions.comboUbicacionPadre()
  }

  obtenerInformeIntervenciones(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.obtenerInformeIntervenciones(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesIntervenciones !== prevProps.datosPdfInformesIntervenciones) {
      if (this.props.datosPdfInformesIntervenciones.pdf && this.props.datosPdfInformesIntervenciones.pdf !== null){
        let pdf = this.props.datosPdfInformesIntervenciones.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.INTERVENCIONES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesIntervenciones() {
    this.props.actions.imprimirPdfInformesIntervenciones(this.props.filtrosIntervenciones)
  }

  crearCsvInformeIntervenciones() {
    this.props.actions.crearCsvInformeIntervenciones(this.props.filtrosIntervenciones)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosIntervenciones
    if (value === 'hasta') {
      values.fechaSalida = null
    }
    if (value === 'desde') {
      values.fechaEntrada = null
    }
    values[value] = null
    this.props.actions.obtenerInformeIntervenciones(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosIntervenciones,
    combos: {comboNombreProductos, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria, comboVerraco, comboNombreGrupoVerraco, comboOperario,
    comboUbicacionPadre}
  } = this.props

  let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

  const comboTerminado= [
      {value: true, label: t('COMUN.COMBOS.TERMINADO.SI')},
      {value: false, label: t('COMUN.COMBOS.TERMINADO.NO')}
    ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="tipoIntervencion"
              name="tipoIntervencion"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.TIPO_INTERVENCION')}
              component={InputSelect}
              options={comboTipoIntervencionSanitaria}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="idLinea"
              name="idLinea"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="terminado"
              name="terminado"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.TERMINADO')}
              component={InputSelect}
              options={comboTerminado}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="diasSupresion"
              name="diasSupresion"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.DIAS_SUPRESION')}
              component={InputText}
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="producto"
              name="producto"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.PRODUCTO')}
              component={InputText}
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="operario"
              name="operario"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.OPERARIO')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="ubicacion"
              name="ubicacion"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.UBICACION_PADRE')}
              component={InputSelect}
              options={comboUbicacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.GRUPO_VERRACOS')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('INFORMES.INTERVENCIONES.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeIntervenciones.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.tipoIntervencion && filtrosIntervenciones.tipoIntervencion !== null && filtrosIntervenciones.tipoIntervencion !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.TIPO_INTERVENCION') + ': ' + filtrosIntervenciones.tipoIntervencion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoIntervencion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.idLinea && filtrosIntervenciones.idLinea !== null && filtrosIntervenciones.idLinea !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.LINEA_GENETICA') + ': ' + filtrosIntervenciones.idLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idLinea')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.raza && filtrosIntervenciones.raza !== null && filtrosIntervenciones.raza !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.RAZA') + ': ' + filtrosIntervenciones.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.terminado && filtrosIntervenciones.terminado !== null && filtrosIntervenciones.terminado !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.TERMINADO') + ': ' + filtrosIntervenciones.terminado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('terminado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.diasSupresion && filtrosIntervenciones.diasSupresion !== null && filtrosIntervenciones.diasSupresion !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.DIAS_SUPRESION') + ': ' + filtrosIntervenciones.diasSupresion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('diasSupresion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.producto && filtrosIntervenciones.producto !== null && filtrosIntervenciones.producto !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.PRODUCTO') + ': ' + filtrosIntervenciones.producto}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('producto')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.operario && filtrosIntervenciones.operario !== null && filtrosIntervenciones.operario !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.OPERARIO') + ': ' + filtrosIntervenciones.operario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.verraco && filtrosIntervenciones.verraco !== null && filtrosIntervenciones.verraco !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.VERRACO') + ': ' + filtrosIntervenciones.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.ubicacion && filtrosIntervenciones.ubicacion !== null && filtrosIntervenciones.ubicacion !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.UBICACION_PADRE') + ': ' + filtrosIntervenciones.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.grupoVerraco && filtrosIntervenciones.grupoVerraco !== null && filtrosIntervenciones.grupoVerraco !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.GRUPO_VERRACOS') + ': ' + filtrosIntervenciones.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.fechaEntrada && filtrosIntervenciones.fechaSalida && filtrosIntervenciones.fechaEntrada !== null && filtrosIntervenciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosIntervenciones.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosIntervenciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosIntervenciones.fechaEntrada && filtrosIntervenciones.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosIntervenciones.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosIntervenciones.fechaSalida && filtrosIntervenciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosIntervenciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosIntervenciones).length > 0 && (
                    (filtrosIntervenciones.estado && filtrosIntervenciones.estado !== null && filtrosIntervenciones.estado !== '') ? (
                      <span className="badge-dark">{t('INFORMES.INTERVENCIONES.FILTROS.ESTADO') + ': ' + filtrosIntervenciones.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeIntervenciones()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesIntervenciones()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesIntervenciones',
})(FiltrosInformesIntervenciones))

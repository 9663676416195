import React, { Component } from 'react'
import { Modal, Button, Col, Tabs, Tab } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import { Warning, Delete, AddCircleOutline } from '@material-ui/icons'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import ModalAnadirRutaPage from '../../containers/cliente/ModalAnadirRutaPage'
import ModalDatosArticuloDosisPage from '../../containers/cliente/ModalDatosArticuloDosisPage'
import FiltrosModalDireccionesRutaPage from '../../containers/cliente/FiltrosModalDireccionesRutaPage'
import FiltrosModalDatosArticuloDosisPage from '../../containers/cliente/FiltrosModalDatosArticuloDosisPage'
import {modulos as modulosConstant} from '../../constants/modulos'
import './Cliente.scss'

class ModalDireccionesCliente extends Component {
  constructor() {
    super()
    this.state = {
      hayPorDefecto: false,
      tipoDireccion: 2,
      customAgView: false,
      customUsa: false
    }
  }

  componentDidMount() {
    this.props.change('activo', true)
    this.props.change('tipo', { value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO') })
    this.seleccionTipoEnvio({ value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO') });  
    if (this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='AgView')) {      
      this.setState({customAgView:true})
    }  
    if (this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='USA')) {      
      this.setState({customUsa:true})
    } 
  }

  componentDidUpdate(prevProps) {

    if (this.props.dataDirecciones !== prevProps.dataDirecciones) {
      if (Object.keys(this.props.dataDirecciones).length > 0) {        
        this.setState({tipoDireccion: this.props.dataDirecciones.idTipo})
        this.props.change('tipo', { value: this.props.dataDirecciones.idTipo, label: this.props.dataDirecciones.tipo })
        if (this.state.customUsa) {
          this.props.change('provincia', { value: this.props.dataDirecciones.provincia, label: this.props.dataDirecciones.provincia })
        }
        this.props.change('banda', { value: this.props.dataDirecciones.banda, label: this.props.dataDirecciones.banda })
      }
    }

    if (this.props.dataDuplicarDirecciones !== prevProps.dataDuplicarDirecciones) {
      if (Object.keys(this.props.dataDuplicarDirecciones).length > 0) {
        this.setState({tipoDireccion: this.props.dataDuplicarDirecciones.idTipo})
        this.props.change('tipo', { value: this.props.dataDuplicarDirecciones.idTipo, label: this.props.dataDuplicarDirecciones.tipo })
        if (this.state.customUsa) {
          this.props.change('provincia', { value: this.props.dataDirecciones.provincia, label: this.props.dataDirecciones.provincia })
        }
        this.props.change('banda', { value: this.props.dataDirecciones.banda, label: this.props.dataDirecciones.banda })
      }
    }

    if (this.props.listDireccionesCliente !== prevProps.listDireccionesCliente) {
      if (Object.keys(this.props.listDireccionesCliente).length > 0) {
        let hayPorDefecto = this.props.listDireccionesCliente.filter((direccion) => {
          if (direccion.idTipo === 2) {
            return direccion
          }
        })
        if (Object.keys(hayPorDefecto).length > 0) {
          this.setState({ hayPorDefecto: true })
        } else {
          this.setState({ hayPorDefecto: false })
        }
      }
    }
  }

  closeModalDireccionesCliente() {
    this.props.actions.closeModalDireccionesCliente()
    this.props.initialize()
  }

  submitDireccionCliente(values) {
    if (this.state.customUsa && values.provincia !== undefined) {
      values.provincia = values.provincia.value
    }
    values.idTipo = values.tipo.value
    values.tipo = values.tipo.label
    values.rutas = this.props.listRutas
    if(values.banda !== undefined) {
      values.banda = values.banda.value
    }
    values.listArticulos = this.props.listArticulos
    if (Object.keys(this.props.dataDirecciones).length > 0) {
      this.props.actions.editarDireccionCliente(values)
    } else {
      this.props.actions.submitDireccionCliente(values)
    }
    this.closeModalDireccionesCliente()
  }

  guardarYnuevoCliente(values) {
    this.props.actions.guardarYnuevoCliente(values)
    this.closeModalDireccionesCliente('ModalDireccionesCliente')
  }

  seleccionTipoEnvio(value) {    
    this.setState({ tipoDireccion: value.value })
  }

  render() {
    const {
      t, handleSubmit, showModalDireccionesCliente, dataDirecciones, clienteAnalisis, numeroRegistrosPorPagina, paginaActual, checkedFiltrosClienteAnalisis, checkedFiltrosClienteDilucion,
      clienteDilucion, checkedFiltrosClienteEnvasado, clienteEnvasado, dataCliente, duplicar, listDireccionesCliente = [], checkedFiltrosdDireccionesRuta, listRutas, listArticulos, checkedFiltrosdDireccionesArticulos,
      actions: { onDeleteRutaCliente, onDeleteDatosArticuloDosis }
    } = this.props

    listRutas.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'direccionRuta'
    )
    const tablaRutas = {
      id: 'rutasTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      columns: [
        { id: 'transportista', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.TRANSPORTISTA') },
        { id: 'nombreRuta', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.RUTA') }
      ],
      rows: listRutas,
      onDelete: (row) => onDeleteRutaCliente(row.idElemento),
      filtros: <FiltrosModalDireccionesRutaPage />,
      showModal: this.props.showModalAnadirRuta,
      tablaModal: true,
      idBlockOptions: 2,
      initialValues: {}
    }
    tablaRutas.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tablaRutas.initialValues['check' + row.idElemento] = checkedFiltrosdDireccionesRuta
        }
      }
    )
    
    listArticulos.forEach(
      //(row, idRow) => row.idElemento = idRow + 1 + 'articulo'
      (row, idRow) => row.idElemento = row.idRaza + '-' + row.idLinea + '-' + row.idTipoDosis
    )
    const tablaArticulos = {
      id: 'articulosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      columns: [
        { id: 'raza', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.RAZA') },
        { id: 'lineaGenetica', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.LINEA_GENETICA') },
        { id: 'nivelGenetico', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.NIVEL_GENETICO') },
        { id: 'tipoDosis', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TIPO_DOSIS') }        
      ],
      rows: listArticulos,
      onDelete: (row) => onDeleteDatosArticuloDosis(row.idElemento),
      filtros: <FiltrosModalDatosArticuloDosisPage />,
      showModal: this.props.showModalDatosArticuloDosis,
      tablaModal: true,
      idBlockOptions: 3,
      initialValues: {}
    }
    tablaArticulos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tablaArticulos.initialValues['check' + row.idElemento] = checkedFiltrosdDireccionesArticulos
        }
      }
    )

    const comboTipoDireccion = [
      { value: 1, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.POR_DEFECTO') },
      { value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO') },
      { value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO') },
      { value: 4, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.PARTICULAR') }
    ]

    const comboTipoDireccionSinPorDefecto = [
      { value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO') },
      { value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO') },
      { value: 4, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.PARTICULAR') }
    ]

    const comboTipoDireccionNuevo = [
      { value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO') },
      { value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO') }
    ]

    const comboUsaStates = [
      { value: 'AL', label: 'Alabama' }, 
      { value: 'AK', label: 'Alaska' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DE', label: 'Delaware' },
      { value: 'DC', label: 'District Of Columbia' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'HI', label: 'Hawaii' },    
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VA', label: 'Virginia' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' },
    ]   

    const comboBandas = [
      { value: 1, label: 1 },
      { value: 3, label: 3 },
      { value: 5, label: 5 }
    ]

    return (
      <div>
        <ModalAnadirRutaPage />
        <ModalDatosArticuloDosisPage />
        <Modal show={showModalDireccionesCliente} onHide={() => this.closeModalDireccionesCliente()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
          <form className="form-cliente">
            <Modal.Header closeButton>
              <Modal.Title>
                {
                  Object.keys(this.props.dataDirecciones).length > 0 ? (
                    t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_EDITAR')
                  ) : (
                    Object.keys(this.props.dataDuplicar).length > 0 ? (
                      t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_DUPLICAR')
                    ) : (
                      t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_NUEVO')
                    )
                  )
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Field
                  colSm={3}
                  id="tipo"
                  name="tipo"
                  controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TIPO') + '*'}
                  component={InputSelect}
                  // options={this.state.hayPorDefecto === true ? comboTipoDireccionSinPorDefecto : comboTipoDireccion}
                  options={comboTipoDireccionNuevo}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-tipo'
                  validate={required}
                  isClearable={false}
                  onInputChange={(value) => this.seleccionTipoEnvio(value)}
                />
                {
                  this.state.tipoDireccion === 2 ? (
                    <div key="form1">
                      <Field
                        colSm={3}
                        id="nombreDireccion"
                        name="nombreDireccion"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + '*'}
                        customClass="nombre"
                        validate={required}
                        maxLength={true}
                        valorMaxLength={60}
                      />
                      <Field
                        colSm={3}
                        id="direccion"
                        name="direccion"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.DIRECCION') + '*'}
                        customClass="direccion"
                        validate={required}
                        maxLength={true}
                        valorMaxLength={60}
                      />
                      <Field
                        colSm={3}
                        id="localidad"
                        name="localidad"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.LOCALIDAD') + '*'}
                        customClass="localidad"
                        validate={required}
                        maxLength={true}
                        valorMaxLength={35}
                      />
                      { this.state.customUsa &&
                        <Field
                          colSm={3}
                          id="provincia"
                          name="provincia"
                          controlLabel={this.state.customAgView ? t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PROVINCIA') + '*' : t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PROVINCIA')}
                          component={InputSelect}                      
                          options={comboUsaStates}
                          valueKey="value"
                          labelKey="label"
                          customClass='provinciaselect'                        
                          isClearable={false}  
                          validate={this.state.customAgView ? required : null}                                           
                        />
                      }
                      { !this.state.customUsa &&
                        <Field
                          colSm={3}
                          id="provincia"
                          name="provincia"
                          component={InputText}
                          controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PROVINCIA')}
                          customClass="provincia"
                          maxLength={true}
                          valorMaxLength={35}
                        />
                      }
                      <Field
                        colSm={3}
                        id="codigoPostal"
                        name="codigoPostal"
                        component={InputText}
                        controlLabel={this.state.customAgView ? t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CP') + '*' : t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CP')}
                        customClass="codigoPostal"
                        validate={this.state.customAgView ? required : null}
                      />
                      <Field
                        colSm={3}
                        id="dni"
                        name="dni"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL.CIF')}
                        customClass="cif"
                        maxLength={true}
                        valorMaxLength={15}
                      />
                      <br />
                      <Field
                        colSm={3}
                        id="codigoRega"
                        name="codigoRega"
                        component={InputText}
                        controlLabel={this.state.customUsa ? this.state.customAgView ? t('PEDIDOS.CLIENTE.MODAL.USDA_PIN') + '*' : t('PEDIDOS.CLIENTE.MODAL.USDA_PIN') : t('PEDIDOS.CLIENTE.MODAL.CODIGO_REGA')}                        
                        customClass="codigo-rega"
                        maxLength={true}
                        valorMaxLength={20}
                        validate={this.state.customAgView ? required : null}
                      />
                      <Field
                        colSm={3}
                        id="movil"
                        name="movil"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.MOVIL')}
                        customClass="movil"
                        maxLength={true}
                        valorMaxLength={20}
                      />
                      <Field
                        colSm={3}
                        id="telefono_uno"
                        name="telefono_uno"
                        component={InputText}
                        controlLabel={this.state.customAgView ? t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO') + '*' : t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO')}
                        customClass="telefono"
                        maxLength={true}
                        valorMaxLength={20}
                        validate={this.state.customAgView ? required : null}
                      />
                      <Field
                        colSm={3}
                        id="email"
                        name="email"
                        component={InputText}
                        controlLabel={this.state.customAgView ? t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.EMAIL') + '*' : t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.EMAIL')}
                        customClass="email"
                        maxLength={true}
                        valorMaxLength={50}
                        validate={this.state.customAgView ? required : null}
                      />
                      <Field
                        colSm={3}
                        id="codigoDireccion"
                        name="codigoDireccion"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CODIGO_DIRECCION')}
                        customClass="codigo-direccion"
                        maxLength={true}
                        valorMaxLength={50}
                      />
                      <Field
                        colSm={3}
                        id="codigoGenetico"
                        name="codigoGenetico"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CODIGO_GENETICO')}
                        customClass="codigo-direccion"
                        maxLength={true}
                        valorMaxLength={50}
                      />
                      {
                        this.props.auth.codLicencia == 'CUARTESA' ? (
                          <Field
                            id="establo"
                            name="establo"
                            inline={true}
                            component={InputCheckBox}
                            claseActivo="onoffswitch-label"
                            controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.ESTABLO')}
                            customClass="establo"
                            colSm={3}
                          />
                        ) : null
                      }
                      <Field
                        colSm={3}
                        id="latitud"
                        name="latitud"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.LATITUD')}
                        customClass="codigo-direccion"
                        maxLength={true}
                        valorMaxLength={20}                        
                      />
                      <Field
                        colSm={3}
                        id="longitud"
                        name="longitud"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.LONGITUD')}
                        customClass="codigo-direccion"
                        maxLength={true}
                        valorMaxLength={20}                        
                      />
                      { this.state.customAgView &&
                        <Field
                          colSm={3}
                          id="numeroAnimales"
                          name="numeroAnimales"
                          component={InputText}
                          controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.NUMERO_ANIMALES') + '*'}
                          customClass="codigo-direccion"                          
                          validate={required}
                        />
                      }
                      { this.state.customAgView &&
                        <Field
                          colSm={3}
                          id="numeroEstablos"
                          name="numeroEstablos"
                          component={InputText}
                          controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.NUMERO_ESTABLOS') + '*'}
                          customClass="codigo-direccion"                          
                          validate={required}
                        />
                      }
                      { this.state.customAgView &&
                        <Field
                          colSm={3}
                          id="numeroAnimalesEstablo"
                          name="numeroAnimalesEstablo"
                          component={InputText}
                          controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.NUMERO_ANIMALES_ESTABLOS') + '*'}
                          customClass="codigo-direccion"
                          validate={required}
                        />
                      }
                      <Field
                        colSm={3}
                        id="banda"
                        name="banda"
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.BANDAS')}
                        component={InputSelect}                        
                        options={comboBandas}
                        valueKey="value"
                        labelKey="label"
                        customClass='select-tipo'                        
                        isClearable={false}                      
                      />
                    </div>
                  ) : null
                }
                {
                  this.state.tipoDireccion === 3 ? (
                    <div key="form2">
                      <Field
                        colSm={3}
                        id="nombreDireccion"
                        name="nombreDireccion"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.NOMBRE') + '*'}
                        customClass="nombre"
                        validate={required}
                        maxLength={true}
                        valorMaxLength={60}
                      />
                      <Field
                        colSm={3}
                        id="funcion"
                        name="funcion"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.FUNCION')}
                        customClass="funcion"
                        maxLength={true}
                        valorMaxLength={60}
                      />
                      <Field
                        colSm={3}
                        id="contacto_telefono_uno"
                        name="contacto_telefono_uno"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CONTACTO_TELEFONO')}
                        customClass="telefono"
                        maxLength={true}
                        valorMaxLength={30}
                      />
                      <Field
                        colSm={3}
                        id="telefono_dos"
                        name="telefono_dos"
                        component={InputText}
                        controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO2')}
                        customClass="telefono"
                        maxLength={true}
                        valorMaxLength={20}
                      />                      
                    </div>
                  ) : null
                }
              </Row>

              {
                  this.state.tipoDireccion === 2 && this.props.auth.modulos.includes(modulosConstant.APP) ? (
                    <>
                      <hr/>
                      <Row>
                        <div key="form2">
                          <Field
                            id="activoApp"
                            name="activoApp"
                            inline
                            component={InputCheckBox}
                            claseActivo="onoffswitch-label"
                            controlLabel={'APP '+t('PEDIDOS.CLIENTE.MODAL.ACTIVO')}
                            colSm={3}
                          />
                          <Field
                            colSm={3}
                            id="userApp"
                            name="userApp"
                            component={InputText}
                            controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.USER_APP')}
                            customClass="nombre"
                            maxLength={true}
                            valorMaxLength={50}
                          />
                          <Field
                            colSm={3}
                            id="passApp"
                            name="passApp"
                            component={InputText}
                            controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PASS_APP')}
                            customClass="nombre"
                            maxLength={true}
                            valorMaxLength={50}
                          />
                        </div>
                      </Row>
                    </>
                  ) : null
                }

              {
                this.state.tipoDireccion === 2 ? (
                  <div>
                    <SimpleTablePage {...tablaRutas}></SimpleTablePage>
                  <br/>
                    <SimpleTablePage {...tablaArticulos}></SimpleTablePage>
                  </div>
                ) : null
              }
              <div className="clearfix"></div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.submitDireccionCliente.bind(this))}>{t('PEDIDOS.CLIENTE.MODAL.ACEPTAR')}</Button>
              <Button type="button" className="btn btn-white" onClick={() => this.closeModalDireccionesCliente()}>{t('PEDIDOS.CLIENTE.MODAL.CANCELAR')}</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalDireccionesCliente',
})(ModalDireccionesCliente))
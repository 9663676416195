export default {
  FETCH_CONECTIVIDAD: 'FETCH_CONECTIVIDAD',
  FETCH_CONECTIVIDAD_SUCCESS: 'FETCH_CONECTIVIDAD_SUCCESS',
  SUBMIT_NUEVA_CONECTIVIDAD: 'SUBMIT_NUEVA_CONECTIVIDAD',
  SUBMIT_NUEVA_CONECTIVIDAD_SUCCESS: 'SUBMIT_NUEVA_CONECTIVIDAD_SUCCESS',
  RECUPERAR_DATOS_MODAL_CONECTIVIDAD: 'RECUPERAR_DATOS_MODAL_CONECTIVIDAD',
  RECUPERAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS: 'RECUPERAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS',
  ABRIR_MODAL_VACIO_CONECTIVIDAD: 'ABRIR_MODAL_VACIO_CONECTIVIDAD',
  CLOSE_MODAL_CONECTIVIDAD: 'CLOSE_MODAL_CONECTIVIDAD',
  EDITAR_CONECTIVIDAD: 'EDITAR_CONECTIVIDAD',
  EDITAR_CONECTIVIDAD_SUCCESS: 'EDITAR_CONECTIVIDAD_SUCCESS',
  DUPLICAR_DATOS_MODAL_CONECTIVIDAD: 'DUPLICAR_DATOS_MODAL_CONECTIVIDAD',
  DUPLICAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS: 'DUPLICAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS',
  CAMBIAR_ESTADO_CONECTIVIDAD: 'CAMBIAR_ESTADO_CONECTIVIDAD',
  CAMBIAR_ESTADO_CONECTIVIDAD_SUCCESS: 'CAMBIAR_ESTADO_CONECTIVIDAD_SUCCESS',
  ON_DELETE_CONECTIVIDAD: 'ON_DELETE_CONECTIVIDAD',
  ON_DELETE_CONECTIVIDAD_SUCCESS: 'ON_DELETE_CONECTIVIDAD_SUCCESS',
  FETCH_FILTRAR_CONECTIVIDAD: 'FETCH_FILTRAR_CONECTIVIDAD',
  FETCH_FILTRAR_CONECTIVIDAD_SUCCESS: 'FETCH_FILTRAR_CONECTIVIDAD_SUCCESS',
  SELECCIONAR_TODOS_CONECTIVIDAD: 'SELECCIONAR_TODOS_CONECTIVIDAD',
  DESELECCIONAR_TODOS_CONECTIVIDAD: 'DESELECCIONAR_TODOS_CONECTIVIDAD',
  CAMBIAR_ESTADO_SELECCION_CONECTIVIDAD: 'CAMBIAR_ESTADO_SELECCION_CONECTIVIDAD',
  DELETE_SELECCION_CONECTIVIDAD: 'DELETE_SELECCION_CONECTIVIDAD',
  CREATE_CSV_CONECTIVIDAD: 'CREATE_CSV_CONECTIVIDAD',
  CREATE_CSV_CONECTIVIDAD_SUCCESS: 'CREATE_CSV_CONECTIVIDAD_SUCCESS',
  GUARDAR_Y_NUEVO_CONECTIVIDAD: 'GUARDAR_Y_NUEVO_CONECTIVIDAD',
  IMPRIMIR_PDF_CONECTIVIDAD: 'IMPRIMIR_PDF_CONECTIVIDAD',
  IMPRIMIR_PDF_CONECTIVIDAD_SUCCESS: 'IMPRIMIR_PDF_CONECTIVIDAD_SUCCESS',
  ACTUALIZAR_CONECTIVIDAD_VPN: 'ACTUALIZAR_CONECTIVIDAD_VPN',
  OBTENER_CONECTIVIDAD_VPN: 'OBTENER_CONECTIVIDAD_VPN',
  ACTUALIZAR_CONECTIVIDAD_VPN_SUCCESS: 'ACTUALIZAR_CONECTIVIDAD_VPN_SUCCESS',
  ACTUALIZAR_CONECTIVIDAD_VPN_VACIO: 'ACTUALIZAR_CONECTIVIDAD_VPN_VACIO',
  PROBAR_CONEXION_VPN: 'PROBAR_CONEXION_VPN',
  PROBAR_CONEXION_VPN_SUCCESS: 'PROBAR_CONEXION_VPN_SUCCESS'
}

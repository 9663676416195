import actionTypes from '../../constants/actions/agenda/agenda'

export function fetchAgenda(filtros){
  return {
    type: actionTypes.FETCH_AGENDA,
    filtros
  }
}

export function fetchAgendaSuccess(listTareasCalendario, filtros){
  return {
    type: actionTypes.FETCH_AGENDA_SUCCESS,
    listTareasCalendario,
    filtros
  }
}

export function fetchTareasDia(filtros){
  return {
    type: actionTypes.FETCH_TAREAS_DIA,
    filtros
  }
}

export function fetchTareasDiaSuccess(listTareasDiarias){
  return {
    type: actionTypes.FETCH_TAREAS_DIA_SUCCESS,
    listTareasDiarias
  }
}

export function abrirModalAgenda(idAgenda){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA,
    idAgenda
  }
}

export function abrirModalAgendaSuccess(data){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA_SUCCESS,
    data
  }
}

export function guardarModalAgenda(values){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA,
    values
  }
}

export function guardarModalAgendaSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA_SUCCESS
  }
}

export function closeModalAgenda(){
  return {
    type: actionTypes.CLOSE_MODAL_AGENDA
  }
}

export function closeModalAgendaSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_AGENDA_SUCCESS
  }
}

export function onDeleteAgenda (idAgenda) {
  return {
    type: actionTypes.ON_DELETE_AGENDA,
    idAgenda
  }
}

export function onDeleteAgendaSerie (idAgenda) {
  return {
    type: actionTypes.ON_DELETE_AGENDA_SERIE,
    idAgenda
  }
}

export default {
  fetchAgenda,
  fetchAgendaSuccess,
  fetchTareasDia,
  fetchTareasDiaSuccess,
  abrirModalAgenda,
  abrirModalAgendaSuccess,
  guardarModalAgenda,
  guardarModalAgendaSuccess,
  closeModalAgenda,
  closeModalAgendaSuccess,
  onDeleteAgenda,
  onDeleteAgendaSerie
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {abrirModalLicencias} from '../../../actions/admin/licencias/licencias'
import FiltrosLicencias from '../../../components/admin/licencias/FiltrosLicencias'

export function mapStateToProps (state) {
  return {
    ...state.grupoUsuarios,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalLicencias
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosLicencias))
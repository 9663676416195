import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosComboCausaEliminacion from '../../components/comboCausaEliminacion/FiltrosComboCausaEliminacion'
import { abrirModalVacioComboCausaEliminacion } from '../../actions/comboCausaEliminacion/comboCausaEliminacion'

export function mapStateToProps (state) {
  return {
    comboCausaEliminacion: {...state.comboCausaEliminacion},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVacioComboCausaEliminacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosComboCausaEliminacion))
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosValidacionMateriaPrimaPage from '../../containers/validacionMateriaPrima/FiltrosValidacionMateriaPrimaPage'
import ModalValidacionMateriaPrimaPage from '../../containers/validacionMateriaPrima/ModalValidacionMateriaPrimaPage'
import './ValidacionMateriaPrima.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ValidacionMateriaPrima extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.ALMACEN.VALIDACION_MATERIAS_PRIMAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.filtrarValidacionMateriaPrima({})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listValidacionMateriaPrima, checkedFiltrosValidacionMateriaPrima, data,
      actions: {recuperarDatosModalValidacionMateriaPrima, duplicarDatosModalValidacionMateriaPrima, cambiarEstadoValidacionMateriaPrima, onDeleteValidacionMateriaPrima}
    } = this.props

    listValidacionMateriaPrima.forEach(
      (row) => row.idElemento = row.idValidacionMateriaPrima
    )

    const table = {
      id: 'validacionMateriaPrimaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      hasOptions: true,
      mostrarCambioEstado: false,
      columns: [
        {id: 'nombre', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.PRODUCTO')},
        {id: 'lote', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.LOTE')},
        {id: 'cantidad', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.CANTIDAD')},
        {id: 'fechaInicio', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FECHA_INICIO'), type: 'fecha'},
        {id: 'fechaFin', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FECHA_FIN'), type: 'fecha'},
        {id: 'apto', name: t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.APTO')}
      ],
      rows: listValidacionMateriaPrima,
      filtros: <FiltrosValidacionMateriaPrimaPage />,
      onEdit: (row) => recuperarDatosModalValidacionMateriaPrima(row.idValidacionMateriaPrima),
      data: data,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      duplicar: (row) =>duplicarDatosModalValidacionMateriaPrima(row.idValidacionMateriaPrima),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1),
      onDelete: (row) => onDeleteValidacionMateriaPrima(row.idValidacionMateriaPrima),
      showModal: this.props.showModalValidacionMateriaPrima,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosValidacionMateriaPrima
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalValidacionMateriaPrimaPage
          duplicar={(row) =>duplicarDatosModalValidacionMateriaPrima(row.idValidacionMateriaPrima)}
        />
        <h2 className="page-title">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.TITLE')}</h2>
        <SimpleTablePage {...table}>
        </SimpleTablePage>
      </div>
    )
  }
}

export default ValidacionMateriaPrima
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import Button from '../comun/button/Button'

class ModalGuardarSeleccionInformePersonalizado extends Component {
   constructor () {
    super()
    this.state = {
      
    }
  }

  cerrarModalGuardarSeleccionInformePersonalizado () {
    this.props.actions.cerrarModalGuardarSeleccion()
  }

  submitGuardarSeleccionInformePersonalizado (values) {
    values.camposSeleccionados = this.props.camposSeleccionadosPersonalizado.camposSeleccionados
    values.entidad = this.props.camposSeleccionadosPersonalizado.entidad
    this.props.actions.submitGuardarSeleccionInformePersonalizado(values)
  }

  render () {
    const {
      t, handleSubmit, showModalGuardarSeleccion, camposSeleccionadosPersonalizado
    } = this.props

  return (
      <Modal show={showModalGuardarSeleccion} onHide={() => this.cerrarModalGuardarSeleccionInformePersonalizado()} bsSize="large" dialogClassName="sm" backdrop="static" aria-labelledby="contained-modal-title-lg"  className="modal-exportar-imprimir-planing">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
              {t('INFORMES.PERSONALIZADO.GUARDAR_SELECCION')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
                <Field
                  colSm={12}
                  id="nombre"
                  name="nombre"
                  controlLabel={t('INFORMES.PERSONALIZADO.NOMBRE_SELECCION')}
                  component={InputText}
                  validate={required}
                />
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitGuardarSeleccionInformePersonalizado.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true ? ' disabled' : '')}
          >{t('INFORMES.PERSONALIZADO.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalGuardarSeleccionInformePersonalizado()}>{t('INFORMES.PERSONALIZADO.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalGuardarSeleccionInformePersonalizado',
})(ModalGuardarSeleccionInformePersonalizado))

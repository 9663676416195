import actionTypes from '../../../constants/actions/planSanitario/controlSangre/controlSangre'

export function fetchControlSangre(filtros){
  return {
    type: actionTypes.FETCH_CONTROL_SANGRE,
    filtros
  }
}

export function fetchControlSangreSuccess(mainList, filtros){
  return {
    type: actionTypes.FETCH_CONTROL_SANGRE_SUCCESS,
    mainList,
    filtros
  }
}

export function seleccionarTodosControlSangre(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_CONTROL_SANGRE
  }
}

export function deSeleccionarTodosControlSangre(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CONTROL_SANGRE
  }
}

export function openModalListadoControlSangre(idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_SANGRE,
    idAnalisis,
    idPool
  }
}

export function openModalListadoControlSangreSuccess(list, conservacion, codigo, idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool
  }
}

export function closeModalListadoControlSangre(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SANGRE
  }
}

export function closeModalListadoControlSangreSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS
  }
}

export function seleccionarTodosModalControlSangre(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_SANGRE
  }
}

export function deSeleccionarTodosModalControlSangre(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_SANGRE
  }
}

export function deleteControlSangre(row){
  return {
    type: actionTypes.DELETE_CONTROL_SANGRE,
    row
  }
}

export function deleteSeleccionControlSangre(list){
  return {
    type: actionTypes.DELETE_SELECCION_CONTROL_SANGRE,
    list
  }
}

export function crearCsvControlSangre(list){
  return {
    type: actionTypes.CREATE_CSV_CONTROL_SANGRE,
    list
  }
}

export function imprimirPdfControlSangre(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CONTROL_SANGRE,
    list
  }
}


export function abrirModalInicio () {
  return {
    type: actionTypes.ABRIR_MODAL_INICIO_CONTROL_SANGRE
  }
}

export function closeModalInicio () {
  return {
    type: actionTypes.CLOSE_MODAL_INICIO_CONTROL_SANGRE
  }
}

export function fetchFiltrarModalInicio (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE,
    values
  }
}

export function fetchFiltrarModalInicioSuccess (listVerracos,filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE_SUCCESS,
    listVerracos,
    filtros
  }
}

export function seleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE
  }
}

export function deSeleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE
  }
}

export function anadirVerracosModalInicio (listVerracosAsignados, modalValues, archivos) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SANGRE,
    listVerracosAsignados,
    modalValues,
    archivos
  }
}

export function anadirVerracosModalInicioSuccess (listVerracosAsignados) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SANGRE_SUCCESS,
    listVerracosAsignados
  }
}


export function subirArchivosInforme (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVO_INFORME,
    archivos
  }
}

export function descargarArchivoControlSangre(controlSangre) {
  return {
    type: actionTypes.DESCARGAR_ARCHIVO_CONTROL_SANGRE,
    controlSangre
  }
}

export function imprimirEtiquetaControlSangre(filtros){
  console.log("imprimirEtiquetaControlSangre ACTION", filtros)
  return {
    type: actionTypes.IMPRIMIR_ETIQ_CONTROL_SANGRE,
    filtros
  }
}

export function recuperarDatosModalControlSangreSuccess(data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SANGRE_SUCCESS,
    data
  }
}

export function recuperarDatosModalControlSangre(idControlSangre) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SANGRE,
    idControlSangre
  }
}

export function abrirModalControlSangre () {
  return {
    type: actionTypes.ABRIR_MODAL_CONTROL_SANGRE
  }
}

export function closeModalControlSangre () {
  return {
    type: actionTypes.CLOSE_MODAL_CONTROL_SANGRE
  }
}

export function guardarDetalleControlSangre (data) {
  return {
    type: actionTypes.GUARDAR_MODAL_CONTROL_SANGRE,
    data
  }
}

export default {
  fetchControlSangre,
  fetchControlSangreSuccess,
  seleccionarTodosControlSangre,
  deSeleccionarTodosControlSangre,
  openModalListadoControlSangre,
  openModalListadoControlSangreSuccess,
  closeModalListadoControlSangre,
  closeModalListadoControlSangreSuccess,
  seleccionarTodosModalControlSangre,
  deSeleccionarTodosModalControlSangre,
  deleteControlSangre,
  deleteSeleccionControlSangre,
  crearCsvControlSangre,
  imprimirPdfControlSangre,
  abrirModalInicio,
  closeModalInicio,
  fetchFiltrarModalInicio,
  fetchFiltrarModalInicioSuccess,
  seleccionarTodosVerracosInicio,
  deSeleccionarTodosVerracosInicio,
  anadirVerracosModalInicio,
  anadirVerracosModalInicioSuccess,
  subirArchivosInforme,
  descargarArchivoControlSangre,
  imprimirEtiquetaControlSangre,
  recuperarDatosModalControlSangre,
  recuperarDatosModalControlSangreSuccess,
  abrirModalControlSangre,
  closeModalControlSangre,
  guardarDetalleControlSangre
}

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import { Delete, AddCircleOutline, Warning } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosModalAlbaran extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      showErrorGranja: false
    }
    this.deleteSeleccionModalAlbaranDosis = this.deleteSeleccionModalAlbaranDosis.bind(this)
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalAlbaranDosis()
    } else {
      this.props.actions.deseleccionarTodosModalAlbaranDosis()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-filtros-modal-albaran', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosModalAlbaranDosis()
          }
        }
      }
    }

    if(this.props.hayCliente !== null && this.props.hayGranja !== null && this.state.showErrorGranja == true){
      this.setState({ showErrorGranja: false })
    }
  }

  deleteSeleccionModalAlbaranDosis(){
    const itemsSeleccion = this.props.albaran.data.dosisAlbaran.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionModalAlbaranDosis(list)
  }

  render () {
  const {
    t, hayCliente, hayGranja, auth: { permisos }, albaran: {filtros = {}},
    actions: {abrirModalVacioAlbaranDosis}
  } = this.props
  const tKey = 'ALBARANES.MODAL.'

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo-filtros-modal-albaran"
                    name="seleccionar-todo-filtros-modal-albaran"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options2" key="block-options2" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionModalAlbaranDosis()}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && (hayCliente !== null && hayGranja !== null) ? abrirModalVacioAlbaranDosis :  () => this.setState({ showErrorGranja: true })}
                >
                  <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                </button>
                {
                  this.state.showErrorGranja ? (
                    <span className='error-linea-pedido'><Warning/>{t('PEDIDOS.PEDIDOS_VENTA.TIENES_SELECCIONAR_CLIENTE')}</span>
                  ) : null
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalAlbaran'
})(FiltrosModalAlbaran))

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import {downloadDocument} from '../../util/util'

import actionTypes from '../../constants/actions/grupoUsuarios/grupoUsuarios'

import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { fetchGrupoUsuariosSuccess, abrirModalGrupoUsuariosSuccess, duplicarGrupoUsuariosSuccess, cerrarModalGrupoUsuariosSuccess } from '../../actions/grupoUsuarios/grupoUsuarios'

import getGrupoUsuariosServices from '../../services/grupoUsuarios/getGrupoUsuarios'
import getFiltrarGrupoUsuariosServices from '../../services/grupoUsuarios/getFiltrarGrupoUsuarios'
import getGrupoUsuarioServices from '../../services/grupoUsuarios/getGrupoUsuario'
import addGrupoUsuariosServices from '../../services/grupoUsuarios/addGrupoUsuarios'
import editGrupoUsuariosServices from '../../services/grupoUsuarios/editGrupoUsuarios'
import deleteGrupoUsuariosService from '../../services/grupoUsuarios/deleteGrupoUsuarios'
import cambioEstadoGrupoUsuariosService from '../../services/grupoUsuarios/cambioEstadoGrupoUsuarios'
import onDeleteSeleccionGrupoUsuariosService from '../../services/grupoUsuarios/onDeleteSeleccionGrupoUsuarios'
import cambioEstadoSeleccionGrupoUsuariosService from '../../services/grupoUsuarios/cambioEstadoSeleccionGrupoUsuarios'
import csvGrupoUsuariosServices from '../../services/grupoUsuarios/csvGrupoUsuarios'
import pdfGrupoUsuariosServices from '../../services/grupoUsuarios/pdfGrupoUsuarios'

import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {comboGrupoUsuariosCentro} from '../../actions/combos/combos'

export function * fetchGrupoUsuarios ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let listGrupoUsuarios = []
    if (filtros && Object.keys(filtros).length > 1) {
      listGrupoUsuarios = yield call(getFiltrarGrupoUsuariosServices, filtros, auth.token)
    } else {
      listGrupoUsuarios = yield call(getGrupoUsuariosServices)
    }
    yield put(fetchGrupoUsuariosSuccess(listGrupoUsuarios, filtros))
  } catch (error) {}
}
export function * watchFetchGrupoUsuarios () {
  yield takeLatest(actionTypes.FETCH_GRUPO_USUARIOS, fetchGrupoUsuarios)
}

export function * abrirModalGrupoUsuarios ({idGrupoUsuario}) {
  try {
    let data = {}
    if (idGrupoUsuario) {
      data = yield call(getGrupoUsuarioServices, idGrupoUsuario)
      yield put(initialize('ModalGrupoUsuarios', {
        nombreGrupo: data.gruposUsuario[0].nombreGrupo,
        estaActivo: data.gruposUsuario[0].activo
      }))
    } else {
      data = {estaActivo: true}
      yield put(initialize('ModalGrupoUsuarios', data))
    }
    yield put(abrirModalGrupoUsuariosSuccess(data))
  } catch (error) {}
}
export function * watchAbrirModalGrupoUsuarios () {
  yield takeLatest(actionTypes.ABRIR_MODAL_GRUPO_USUARIOS, abrirModalGrupoUsuarios)
}

export function * duplicarGrupoUsuarios ({idGrupoUsuario}) {
  try {
    const data = yield call(getGrupoUsuarioServices, idGrupoUsuario)
    yield put(initialize('ModalGrupoUsuarios', {
      nombreGrupo: data.gruposUsuario[0].nombreGrupo + ' - Copia',
      estaActivo: data.gruposUsuario[0].activo
    }))
    delete data.gruposUsuario[0].idGrupoUsuario
    yield put(duplicarGrupoUsuariosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarGrupoUsuarios () {
  yield takeLatest(actionTypes.DUPLICAR_GRUPO_USUARIOS, duplicarGrupoUsuarios)
}

export function * submitGrupoUsuarios ({values, idGrupoUsuario, guardarYnuevo, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let preparedValues = {
      nombreGrupo: values.nombreGrupo,
      activo: values.estaActivo,
      permisos: values.permisos
    }

    // Convertir objeto permisos a un array.
    preparedValues.permisos = Object.keys(preparedValues.permisos).map((id) => {
      return {item: id, permiso: preparedValues.permisos[id]}
    })

    // Si se recibe el idGrupoUsuario se hara una actualización de dicho grupo, sino se creara uno nuevo.
    let estadoPeticion
    if (idGrupoUsuario) {
      preparedValues.idGrupoUsuario = idGrupoUsuario
      estadoPeticion = yield call(editGrupoUsuariosServices, preparedValues, state.auth.token)
      yield put(comboGrupoUsuariosCentro())
    } else {
      estadoPeticion = yield call(addGrupoUsuariosServices, preparedValues, state.auth.token)
      yield put(comboGrupoUsuariosCentro())
    }
    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)

    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(idGrupoUsuario ? 'modificacion' : 'insercion'))
      yield put(initialize('ModalGrupoUsuarios'))

      if (!guardarYnuevo) {
        yield put(cerrarModalGrupoUsuariosSuccess())
      }
      const val = yield select(state => state.grupoUsuarios.filtros)
      if (val.grupoUsuario && val.grupoUsuario.value === idGrupoUsuario){
        val.grupoUsuario = {value: idGrupoUsuario, label: values.nombreGrupo}
      }
      yield call(fetchGrupoUsuarios, {filtros: val})
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta(idGrupoUsuario ? 'no-modificacion' : 'no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  }
}
export function * watchSubmitGrupoUsuarios () {
  yield takeLatest(actionTypes.SUBMIT_GRUPO_USUARIOS, submitGrupoUsuarios)
}

export function * cerrarModalGrupoUsuarios () {
  try {
    yield put(initialize('ModalGrupoUsuarios'))
    yield put(cerrarModalGrupoUsuariosSuccess())
  } catch (error) {}
}
export function * watchCerrarModalGrupoUsuarios () {
  yield takeLatest(actionTypes.CERRAR_MODAL_GRUPO_USUARIOS, cerrarModalGrupoUsuarios)
}

export function * eliminarGrupoUsuarios ({idGrupoUsuario}) {
  try {
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteGrupoUsuariosService, idGrupoUsuario, null)
      yield put(comboGrupoUsuariosCentro())
      let filtros = yield select(state => state.grupoUsuarios.filtros)
      if (filtros.grupoUsuario && filtros.grupoUsuario.value === idGrupoUsuario){
        filtros.grupoUsuario = null
      }
      yield call(fetchGrupoUsuarios, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchEliminarGrupoUsuarios () {
  yield takeLatest(actionTypes.ELIMINAR_GRUPO_USUARIOS, eliminarGrupoUsuarios)
}

export function * cambiarEstadoGrupoUsuarios ({idGrupoUsuario}) {
  try {
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const cambioEstado = yield call(cambioEstadoGrupoUsuariosService, idGrupoUsuario, null)
      if (cambioEstado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    let filtros = yield select(state => state.grupoUsuarios.filtros)
    yield call(fetchGrupoUsuarios, {filtros})
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiarEstadoGrupoUsuarios () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_GRUPO_USUARIOS, cambiarEstadoGrupoUsuarios)
}

export function * crearCsvGrupoUsuarios ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcel = yield call(csvGrupoUsuariosServices, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcel.excel)
    let name = i18n.t('MENU.SISTEMA.GRUPOS_USUARIOS') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {}
}
export function * watchCrearCsvGrupoUsuarios () {
  yield takeLatest(actionTypes.CREAR_CSV_GRUPO_USUARIOS, crearCsvGrupoUsuarios)
}

export function * imprimirPdfGrupoUsuarios ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdf = yield call(pdfGrupoUsuariosServices, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdf.pdf)
    let name = i18n.t('MENU.SISTEMA.GRUPOS_USUARIOS') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {}
}
export function * watchImprimirPdfGrupoUsuarios () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_GRUPO_USUARIOS, imprimirPdfGrupoUsuarios)
}

export function * deleteSeleccionGrupoUsuarios ({list}) {
  try {
    let state = yield select(state => state)
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminar = yield call(onDeleteSeleccionGrupoUsuariosService, {list: list}, state.auth.token)
      if (eliminar.eliminado === true && eliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    yield put(initialize('simpleTable', {}))
    yield put(comboGrupoUsuariosCentro())
    let filtros = yield select(state => state.grupoUsuarios.filtros)
    list.forEach((li) => {
      if (filtros.grupoUsuario && filtros.grupoUsuario.value === li.idGrupoUsuario){
        filtros.grupoUsuario = null
      }
    })
    yield call(fetchGrupoUsuarios, {filtros: filtros})
    yield put(initialize('FiltrosGrupoUsuarios', filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDeleteSeleccionGrupoUsuarios () {
  yield takeLatest(actionTypes.DELETE_SELECCION_GRUPO_USUARIOS, deleteSeleccionGrupoUsuarios)
}

export function * cambioEstadoSeleccionGrupoUsuarios ({list}) {
  try {
    let state = yield select(state => state)
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const cambioEstado = yield call(cambioEstadoSeleccionGrupoUsuariosService, {list: list}, state.auth.token)
      if (cambioEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    yield put(initialize('simpleTable', {}))
    yield call(fetchGrupoUsuarios, {filtros: state.grupoUsuarios.filtros})
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambioEstadoSeleccionGrupoUsuarios () {
  yield takeLatest(actionTypes.CAMBIO_ESTADO_SELECCION_GRUPO_USUARIOS, cambioEstadoSeleccionGrupoUsuarios)
}

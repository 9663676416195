import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import microbiologiaActionTypes from '../../../constants/actions/planSanitario/microbiologia/microbiologia'
import {fetchMicrobiologiaSuccess, submitNuevaMicrobiologiaSuccess, recuperarDatosModalMicrobiologiaSuccess, editarMicrobiologiaSuccess, onDeleteMicrobiologiaSuccess, fetchFiltrarMicrobiologiaSuccess, abrirModalMicrobiologia, cerrarModalMicrobiologia, crearCsvMicrobiologiaSuccess} from '../../../actions/planSanitario/microbiologia/microbiologia'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import { yesNoModal } from '../../modal/yesNoModal'
import i18n from '../../../i18n';
import getMicrobiologiaList from '../../../services/planSanitario/microbiologia/getMicrobiologiaList'
import addMicrobiologia from '../../../services/planSanitario/microbiologia/addMicrobiologia'
import getMicrobiologia from '../../../services/planSanitario/microbiologia/getMicrobiologia'
import editMicrobiologia from '../../../services/planSanitario/microbiologia/editMicrobiologia'
import deleteMicrobiologia from '../../../services/planSanitario/microbiologia/deleteMicrobiologia'
import getFiltrarMicrobiologia from '../../../services/planSanitario/microbiologia/getFiltrarMicrobiologia'
import getCrearCsv from '../../../services/planSanitario/microbiologia/getCrearCsv'

export function * fetchMicrobiologia () {
  try {
    const list = yield call(getMicrobiologiaList, null)
    yield put(fetchMicrobiologiaSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.FETCH_MICROBIOLOGIA, fetchMicrobiologia)
}

export function * submitNuevaMicrobiologia ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionMicrobiologia = yield call(addMicrobiologia, values, auth.token)
    yield put(submitNuevaMicrobiologiaSuccess(insercionMicrobiologia))
    if (insercionMicrobiologia.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(cerrarModalMicrobiologia())  
    let state = yield select(state => state)          
    const list = yield call(getFiltrarMicrobiologia, state.microbiologia.filtros, auth.token)
    yield put(fetchMicrobiologiaSuccess(list))        
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.SUBMIT_NUEVA_MICROBIOLOGIA, submitNuevaMicrobiologia)
}

export function * recuperarDatosModalMicrobiologia ({idMicrobiologia}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getMicrobiologia, idMicrobiologia)  
    console.log('registroModificar', registroModificar)  
    if(registroModificar!=null)
    {
      if (registroModificar.tipo && registroModificar.tipo === 1){
        registroModificar.tipo = {value: 1, label: i18n.t('COMUN.COMBOS.MICROBIOLOGIA.DOSIS')}
      }
      if (registroModificar.tipo && registroModificar.tipo === 2){
        registroModificar.tipo = {value: 2, label: i18n.t('COMUN.COMBOS.MICROBIOLOGIA.DILUYENTE')}
      }
      if (registroModificar.tipo && registroModificar.tipo === 3){
        registroModificar.tipo = {value: 3, label: i18n.t('COMUN.COMBOS.MICROBIOLOGIA.PURO')}
      } 
      if (registroModificar.tipo && registroModificar.tipo === 4){
        registroModificar.tipo = {value: 4, label: i18n.t('COMUN.COMBOS.MICROBIOLOGIA.AGUA')}
      }  

      if (registroModificar.idVerraco){
        registroModificar.idVerraco = {value: registroModificar.idVerraco, label: registroModificar.codVerraco}
      }  
      if (registroModificar.idOperario){
        registroModificar.idOperario = {value: registroModificar.idOperario, label: registroModificar.nombreOperario}
      }    

      if (registroModificar.amoxicilina && registroModificar.amoxicilina === 1){
        registroModificar.amoxicilina = {value: 1, label: 'S'}
      }  
      if (registroModificar.amoxicilina && registroModificar.amoxicilina === 2){
        registroModificar.amoxicilina = {value: 2, label: 'R'}
      } 
      if (registroModificar.ampicilina && registroModificar.ampicilina === 1){
        registroModificar.ampicilina = {value: 1, label: 'S'}
      }  
      if (registroModificar.ampicilina && registroModificar.ampicilina === 2){
        registroModificar.ampicilina = {value: 2, label: 'R'}
      }  
      if (registroModificar.apramicina && registroModificar.apramicina === 1){
        registroModificar.apramicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.apramicina && registroModificar.apramicina === 2){
        registroModificar.apramicina = {value: 2, label: 'R'}
      }  
      // if (registroModificar.cefalexina && registroModificar.cefalexina === 1){
      //   registroModificar.cefalexina = {value: 1, label: 'S'}
      // }  
      // if (registroModificar.cefalexina && registroModificar.cefalexina === 2){
      //   registroModificar.cefalexina = {value: 2, label: 'R'}
      // }  
      // if (registroModificar.cefoperazona && registroModificar.cefoperazona === 1){
      //   registroModificar.cefoperazona = {value: 1, label: 'S'}
      // }  
      // if (registroModificar.cefoperazona && registroModificar.cefoperazona === 2){
      //   registroModificar.cefoperazona = {value: 2, label: 'R'}
      // }  
      // if (registroModificar.ceftiofur && registroModificar.ceftiofur === 1){
      //   registroModificar.ceftiofur = {value: 1, label: 'S'}
      // }  
      // if (registroModificar.ceftiofur && registroModificar.ceftiofur === 2){
      //   registroModificar.ceftiofur = {value: 2, label: 'R'}
      // }  
      // if (registroModificar.ciprofloxacina && registroModificar.ciprofloxacina === 1){
      //   registroModificar.ciprofloxacina = {value: 1, label: 'S'}
      // }  
      // if (registroModificar.ciprofloxacina && registroModificar.ciprofloxacina === 2){
      //   registroModificar.ciprofloxacina = {value: 2, label: 'R'}
      // }  
      if (registroModificar.espectinomicina && registroModificar.espectinomicina === 1){
        registroModificar.espectinomicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.espectinomicina && registroModificar.espectinomicina === 2){
        registroModificar.espectinomicina = {value: 2, label: 'R'}
      } 
      if (registroModificar.estreptomicina && registroModificar.estreptomicina === 1){
        registroModificar.estreptomicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.estreptomicina && registroModificar.estreptomicina === 2){
        registroModificar.estreptomicina = {value: 2, label: 'R'}
      }       
      if (registroModificar.gentamicina && registroModificar.gentamicina === 1){
        registroModificar.gentamicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.gentamicina && registroModificar.gentamicina === 2){
        registroModificar.gentamicina = {value: 2, label: 'R'}
      }  
      if (registroModificar.lincomicina && registroModificar.lincomicina === 1){
        registroModificar.lincomicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.lincomicina && registroModificar.lincomicina === 2){
        registroModificar.lincomicina = {value: 2, label: 'R'}
      }  
      if (registroModificar.neomicina && registroModificar.neomicina === 1){
        registroModificar.neomicina = {value: 1, label: 'S'}
      }  
      if (registroModificar.neomicina && registroModificar.neomicina === 2){
        registroModificar.neomicina = {value: 2, label: 'R'}
      } 
      if (registroModificar.tilosina && registroModificar.tilosina === 1){
        registroModificar.tilosina = {value: 1, label: 'S'}
      }  
      if (registroModificar.tilosina && registroModificar.tilosina === 2){
        registroModificar.tilosina = {value: 2, label: 'R'}
      }    
    }
    yield put(recuperarDatosModalMicrobiologiaSuccess(registroModificar))    
    yield put(initialize('ModalMicrobiologia', registroModificar))
    yield put(abrirModalMicrobiologia())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.RECUPERAR_DATOS_MODAL_MICROBIOLOGIA, recuperarDatosModalMicrobiologia)
}

export function * abrirModalVacioMicrobiologia () {
  try {
    yield put(recuperarDatosModalMicrobiologiaSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalMicrobiologia', valores))
    yield put(abrirModalMicrobiologia())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.ABRIR_MODAL_VACIO_MICROBIOLOGIA, abrirModalVacioMicrobiologia)
}

export function * editarMicrobiologia ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionMicrobiologia = yield call(editMicrobiologia, values, auth.token)
    yield put(editarMicrobiologiaSuccess(edicionMicrobiologia))
    if (edicionMicrobiologia.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(cerrarModalMicrobiologia())  
    let state = yield select(state => state)          
    const list = yield call(getFiltrarMicrobiologia, state.microbiologia.filtros, auth.token)
    yield put(fetchMicrobiologiaSuccess(list))    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.EDITAR_MICROBIOLOGIA, editarMicrobiologia)
}

export function * onDeleteMicrobiologia ({id}) {
  try {    
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)      
      const dataDelete = yield call(deleteMicrobiologia, id, auth.token)
      yield put(onDeleteMicrobiologiaSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }      
      let state = yield select(state => state)          
      const list = yield call(getFiltrarMicrobiologia, state.microbiologia.filtros, auth.token)
      yield put(fetchMicrobiologiaSuccess(list)) 
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.ON_DELETE_MICROBIOLOGIA, onDeleteMicrobiologia)
}

export function * fetchFiltrarMicrobiologia ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaVisitaDesde = values.fechaEntrada
    values.fechaVisitaHasta = values.fechaSalida
    const list = yield call(getFiltrarMicrobiologia, values, auth.token)
    yield put(fetchMicrobiologiaSuccess(list))
    yield put(fetchFiltrarMicrobiologiaSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.FETCH_FILTRAR_MICROBIOLOGIA, fetchFiltrarMicrobiologia)
}

export function * crearCsvMicrobiologia ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelMicrobiologia = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvMicrobiologiaSuccess(datosExcelMicrobiologia))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvMicrobiologia () {
  yield takeLatest(microbiologiaActionTypes.CREATE_CSV_MICROBIOLOGIA, crearCsvMicrobiologia)
}
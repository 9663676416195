import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesTrazabilidad from '../../components/informes/FiltrosInformesTrazabilidad'
import {obtenerInformeTrazabilidad, imprimirPdfInformesTrazabilidad, crearCsvInformeTrazabilidad} from '../../actions/informes/informes'
import {comboNombreProductos, comboLotes} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeTrazabilidad,
      comboNombreProductos,
      comboLotes,
      imprimirPdfInformesTrazabilidad,
      crearCsvInformeTrazabilidad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesTrazabilidad))
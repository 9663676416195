import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalTipoInstalacionPage from '../../containers/tipoInstalacion/ModalTipoInstalacionPage'
import FiltrosTipoInstalacionPage from '../../containers/tipoInstalacion/FiltrosTipoInstalacionPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class TipoInstalacion extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.PLANO') + ' - ' + this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.TIPOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarTipoInstalacion({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      tipoInstalacion: { list, data, dataDuplicar, dataEstado, checkedFiltrosTipoInstalacion, dataDelete},
      actions: {recuperarDatosModalTipoInstalacion, duplicarDatosModalTipoInstalacion, cambiarEstadoTipoInstalacion, onDeleteTipoInstalacion }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idTipoInstalacion
    )

    const table = {
      id: 'tipoInstalacionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreTipoInstalacion', name: t('TIPO_INSTALACION.TABLA.TIPO_UBICACION')},
        {id: 'animales', name: t('TIPO_INSTALACION.TABLA.CONTIENE_ANIMALES')},
        {id: 'silo', name: t('TIPO_INSTALACION.TABLA.SILO')},
        {id: 'salaExtraccion', name: t('TIPO_INSTALACION.TABLA.SALA_EXTRACCION')},
        {id: 'nombrePadre', name: t('TIPO_INSTALACION.TABLA.CONTENIDO_UBICACION')}
      ],
      rows: list,
      filtros: <FiltrosTipoInstalacionPage />,
      onEdit: (row) => recuperarDatosModalTipoInstalacion(row.idTipoInstalacion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1,
      duplicar: (row) =>duplicarDatosModalTipoInstalacion(row.idTipoInstalacion),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1,
      cambiarEstado: (row) => cambiarEstadoTipoInstalacion(row.idTipoInstalacion),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1,
      onDelete: (row) => onDeleteTipoInstalacion(row.idTipoInstalacion),
      dataDelete: dataDelete,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosTipoInstalacion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalTipoInstalacionPage
          duplicar={(row) =>duplicarDatosModalTipoInstalacion(row.idTipoInstalacion)}
        />
        <ListadoPage t_key="TIPO_INSTALACION." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default TipoInstalacion
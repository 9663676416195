import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalAlbaran from '../../components/albaran/FiltrosModalAlbaran'
import {
  seleccionarTodosModalAlbaranDosis, deseleccionarTodosModalAlbaranDosis, deleteSeleccionModalAlbaranDosis,
  abrirModalVacioAlbaranDosis
} from '../../actions/albaran/albaran'
import {comboTipoDosis, comboRaza, comboVerraco} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    albaran: state.albaran,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboTipoDosis: state.combos.comboTipoDosis,
      comboRaza: state.combos.comboRaza,
      comboVerraco: state.combos.comboVerraco
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      seleccionarTodosModalAlbaranDosis,
      deseleccionarTodosModalAlbaranDosis,
      deleteSeleccionModalAlbaranDosis,
      abrirModalVacioAlbaranDosis,
      comboTipoDosis,
      comboRaza,
      comboVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalAlbaran))
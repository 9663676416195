import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ValidacionMateriaPrima from '../../components/validacionMateriaPrima/ValidacionMateriaPrima'
import {filtrarValidacionMateriaPrima, recuperarDatosModalValidacionMateriaPrima, duplicarDatosModalValidacionMateriaPrima, onDeleteValidacionMateriaPrima} from '../../actions/validacionMateriaPrima/validacionMateriaPrima'

export function mapStateToProps (state) {
  return {
    ...state.validacionMateriaPrima,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      filtrarValidacionMateriaPrima,
      recuperarDatosModalValidacionMateriaPrima,
      duplicarDatosModalValidacionMateriaPrima,
      onDeleteValidacionMateriaPrima
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ValidacionMateriaPrima))
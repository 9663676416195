import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePicker from '../comun/form/InputRangeDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosVerracoPool extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelectedVerracoPool: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-verraco-pool', false)
            this.setState({ imputSelectedVerracoPool: false })
            this.props.actions.deSeleccionarTodosVerracoPoolAnadidos()
          }
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelectedVerracoPool: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracoPoolAnadidos()
    } else {
      this.props.actions.deSeleccionarTodosVerracoPoolAnadidos()
    }
  }

  deleteSeleccionVerracoPool(){
    const itemsSeleccion = this.props.listVerracoPool.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'verracoPool': row.verracoPool,
          'motilidad': row.motilidad,
          'aglutinacion': row.aglutinacion,
          'motilidadControl': row.motilidadControl,
          'aglutinacionControl': row.aglutinacionControl,
          'fecha': row.fecha,
          'idVerraco': row.idVerraco ? row.idVerraco : null,
          'idPool': row.idPool ? row.idPool : null,
          'idAnalisis': row.idAnalisis ? row.idAnalisis : null,
          'idElemento': row.idElemento,
          'verraco': row.verraco ? row.verraco : null,
          'pool': row.pool ? row.pool : null
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item !== false;
    })
    this.props.actions.deleteSeleccionVerracoPool(list)

    // verraco
    const itemsSeleccionVerraco = this.props.listVerracosMateriaPrima.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'verracoPool': row.verracoPool,
          'motilidad': row.motilidad,
          'aglutinacion': row.aglutinacion,
          'motilidadControl': row.motilidadControl,
          'aglutinacionControl': row.aglutinacionControl,
          'fecha': row.fecha,
          'idVerraco': row.idVerraco,
          'idElemento': row.idElemento,
          'verraco': row.verraco ? row.verraco : null,
          'idAnalisis': row.idAnalisis ? row.idAnalisis : null
        }
      )
    })

    let listVerracosMateriaPrima = itemsSeleccionVerraco.filter(function (item) {
      return item !== false;
    })
    this.props.actions.editarVerracoMateriaPrima(listVerracosMateriaPrima)

    // pool
    const itemsSeleccionPool = this.props.listPoolMateriaPrima.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'verracoPool': row.verracoPool,
          'motilidad': row.motilidad,
          'aglutinacion': row.aglutinacion,
          'motilidadControl': row.motilidadControl,
          'aglutinacionControl': row.aglutinacionControl,
          'idPool': row.idPool,
          'fecha': row.fecha,
          'idElemento': row.idElemento,
          'pool': row.pool ? row.pool : null
        }
      )
    })

    let listPoolMateriaPrima = itemsSeleccionPool.filter(function (item) {
      return item !== false;
    })
    this.props.actions.editarPoolMateriaPrima(listPoolMateriaPrima)

    this.props.change('seleccionar-todo-verraco-pool', false)
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVerracoPool
    }
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={12}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id={'seleccionar-todo-verraco-pool'}
                    name={'seleccionar-todo-verraco-pool'}
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id={'block-options1'} key={'block-options'} className={this.state.imputSelectedVerracoPool === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionVerracoPool()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1) ? abrirModalVerracoPool : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosVerracoPool',
})(FiltrosVerracoPool))

import React, { Component } from 'react'
import { reduxForm, Field, Fields } from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import { permisos as permisosConstant } from '../../constants/permisos'
import { date_formatter, dateTimeZones, date_parser } from '../../util/formatFunctions'

class FiltrosPoolExtracciones extends Component {
  constructor() {
    super()
    this.state = {
      hideButton: false,
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      filtroRazaFijo: false,
      quitarFiltroFechas: false
    }
    this.fetchPoolExtracciones = this.fetchPoolExtracciones.bind(this)
    this.crearCsvPoolExtracciones = this.crearCsvPoolExtracciones.bind(this)
    this.imprimirPdfPoolExtracciones = this.imprimirPdfPoolExtracciones.bind(this)
    this.crearExcelBatchUSA = this.crearExcelBatchUSA.bind(this)
  }

  componentDidMount() {
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.fetchComboPool()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboVerraco()
    this.props.actions.comboNivelGenetico()
    this.props.actions.comboCodPool()
    
    let filtros = {};
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora);    
    if (this.props.from == 'PoolExtracciones') {      
      filtros = { desde: fechaActual, ...this.props.poolExtracciones.filtros, poolEliminado: false, 'seleccionar-todo': false, 'filtrar_no_envasados': false }  
      if (filtros.desde == '') {    
        filtros.desde = fechaActual      
      }
    }else if(this.props.from == 'AsignacionPoolVerraco'){      
      filtros = { desde: fechaActual, ...this.props.poolExtracciones.filtros, poolEliminado: true, 'seleccionar-todo': false, 'filtrar_no_envasados': true }      
      if (filtros.desde == '') {    
        filtros.desde = fechaActual      
      }
    }
    this.fetchPoolExtracciones(filtros);
    this.props.initialize(filtros);

    if (this.props.hideButton) {
      this.setState({ hideButton: this.props.hideButton })
    }
  }

  fetchPoolExtracciones(values) {
    this.props.actions.fetchPoolExtracciones(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosPoolExtracciones()
    } else {
      this.props.actions.deseleccionarTodosPoolExtracciones()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.poolExtracciones.filtros != prevProps.poolExtracciones.filtros) {
      Object.entries(this.props.poolExtracciones.filtros).forEach(([key, value]) => {
        if (prevProps.poolExtracciones.filtros[key] != this.props.poolExtracciones.filtros[key]) {
          this.props.change(key, value);
        }
      });
      this.setState({quitarFiltroFechas: false})
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosPoolExtracciones()
          }
        }
      }
    }
  }

  deleteSeleccionPoolExtracciones() {
    const itemsSeleccion = this.props.poolExtracciones.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionPoolExtracciones(list)
  }

  crearCsvPoolExtracciones() {    
    this.props.actions.crearCsvPoolExtracciones(this.props.poolExtracciones.filtros)
  }

  imprimirPdfPoolExtracciones() {
    this.props.actions.imprimirPdfPoolExtracciones(this.props.poolExtracciones.filtros)
  }

  crearExcelBatchUSA(){
    this.props.actions.crearExcelBatchUSA(this.props.poolExtracciones.filtros)
  }

  quitarFiltros(value) {
    let values = this.props.poolExtracciones.filtros
    if (value == 'desde' || value == 'hasta') {        
      let ultimoMes = new Date()      
      ultimoMes.setMonth(ultimoMes.getMonth() - 1)
      values['desde'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['hasta'] = null     
      this.props.actions.fetchPoolExtracciones(values)
      this.props.change('desde', ultimoMes)  
      this.props.change('hasta', null)     
      this.setState({quitarFiltroFechas: true})
    } else {
      values[value] = null
      this.props.actions.fetchPoolExtracciones(values)
      this.props.change(value, null)
    }
    this.setState({ fitrosSeleccionados: values })
  }

  render() {
    const {
      t, handleSubmit, auth: { permisos }, poolExtracciones: { filtros = {} },
      combos: {
        comboPool, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboVerraco, comboNivelGenetico, comboCodPool
      }
    } = this.props
    const tKey = 'POOL_EXTRACCIONES.'

    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="pool"
              name="pool"
              controlLabel={t(tKey + 'FILTROS.POOL')}
              component={InputSelect}
              options={comboPool}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
              colSm={2}
              id="codpool"
              name="codpool"
              controlLabel={t(tKey + 'COLUMNS.CODIGO')}
              component={InputSelect}
              options={comboCodPool}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t(tKey + 'FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t(tKey + 'FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
              colSm={2}
              id="LineaGenetica"
              name="LineaGenetica"
              controlLabel={t(tKey + 'FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <Field
                colSm={2}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('PLANING.FILTROS.NIVEL_GENETICO')}
                component={InputSelect}
                options={comboNivelGenetico}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchPoolExtracciones)}
              />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchPoolExtracciones)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.pool && <span key="filtros.pool" className="badge-dark">{t(tKey + 'FILTROS.POOL') + ': ' + filtros.pool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('pool')}> x </button></span>),
                    (filtros.codpool && <span key="filtros.codpool" className="badge-dark">{t(tKey + 'COLUMNS.CODIGO') + ': ' + filtros.codpool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('codpool')}> x </button></span>),
                    (filtros.verraco && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>),
                    (filtros.grupoVerraco && <span key="filtros.grupoVerraco" className="badge-dark">{t(tKey + 'FILTROS.GRUPO_VERRACO') + ': ' + filtros.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza" className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>),
                    (filtros.LineaGenetica && <span key="filtros.LineaGenetica" className="badge-dark">{t(tKey + 'FILTROS.LIN_GENETICA') + ': ' + filtros.LineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('LineaGenetica')}> x </button></span>),
                    (filtros.nivelGenetico && <span key="filtros.nivelGenetico"  className="badge-dark">{t(tKey + 'FILTROS.NIVEL_GENETICO') + ': ' + filtros.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>),
                    (filtros.desde && filtros.hasta && filtros.desde !== null && filtros.hasta !== null) ? (
                      <span key="filtros.desdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtros.desde && filtros.desde !== null) ? (
                      <span key="filtros.desde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtros.hasta && filtros.hasta !== null) && (
                      <span key="filtros.hasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    )
                  ]
                }
                {(this.state.quitarFiltroFechas === true && <span> {t('ANALISIS_EXTRACCIONES.FILTROS.AVISO_FILTROS_CERRADOS')}</span>)}
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            {this.state.hideButton == false &&
              <div className="buttons-flex">
                {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && [
                  <div className="custom-check-w" key="custom-check-w">
                    <Field
                      id="seleccionar-todo"
                      name="seleccionar-todo"
                      colSm={12}
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheck(value)}
                    />
                  </div>,
                  <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                    <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionPoolExtracciones()}>
                      <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                    </button>
                    <div className="barra-buttons-min"></div>
                  </div>
                ]}
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvPoolExtracciones()}>
                    <Launch></Launch> {t(tKey + 'FILTROS.EXPORTAR')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfPoolExtracciones()}>
                    <Print></Print> {t(tKey + 'FILTROS.IMPRIMIR')}
                  </button>
                  { (this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='USA')) &&
                    <button type="button" className="btn btn-icono" onClick={(e) => this.crearExcelBatchUSA()}>
                      <Launch></Launch> {t(tKey + 'FILTROS.BATCH_EXPORT')}
                    </button>
                  }
                  {(permisos === '-1' || permisos[permisosConstant.POOL] > 1) ? (
                    <Link to="/NuevoPoolExtraccion" className="btn btn-primary nuevo" style={{ padding: '1px 6px', verticalAlign: 'inherit' }}>
                      <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary nuevo disabled"
                      disabled={true}
                    >{t(tKey + 'FILTROS.NUEVO')}</button>
                  )}
                </div>
              </div>
            }
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default reduxForm({
  form: 'FiltrosPoolExtracciones',
})(FiltrosPoolExtracciones)


export default {
  FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS: 'FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS',
  SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS: 'SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS',
  RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS: 'RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS',
  ABRIR_MODAL_VACIO_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'ABRIR_MODAL_VACIO_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS: 'EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS',
  ON_DELETE_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO: 'ON_DELETE_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO',
  ON_DELETE_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS: 'ON_DELETE_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS'
}

export default {
  FETCH_GRUPO_USUARIOS: 'FETCH_GRUPO_USUARIOS',
  FETCH_GRUPO_USUARIOS_SUCCESS: 'FETCH_GRUPO_USUARIOS_SUCCESS',
  ABRIR_MODAL_GRUPO_USUARIOS: "ABRIR_MODAL_GRUPO_USUARIOS",
  ABRIR_MODAL_GRUPO_USUARIOS_SUCCESS: "ABRIR_MODAL_GRUPO_USUARIOS_SUCCESS",
  SUBMIT_GRUPO_USUARIOS: "SUBMIT_GRUPO_USUARIOS",
  CERRAR_MODAL_GRUPO_USUARIOS: "CERRAR_MODAL_GRUPO_USUARIOS",
  CERRAR_MODAL_GRUPO_USUARIOS_SUCCESS: "CERRAR_MODAL_GRUPO_USUARIOS_SUCCESS",
  DUPLICAR_GRUPO_USUARIOS: "DUPLICAR_GRUPO_USUARIOS",
  DUPLICAR_GRUPO_USUARIOS_SUCCESS: "DUPLICAR_GRUPO_USUARIOS_SUCCESS",
  CAMBIAR_ESTADO_GRUPO_USUARIOS: "CAMBIAR_ESTADO_GRUPO_USUARIOS",
  ELIMINAR_GRUPO_USUARIOS: "ELIMINAR_GRUPO_USUARIOS",
  SELECCIONAR_TODOS_GRUPO_USUARIOS: "SELECCIONAR_TODOS_GRUPO_USUARIOS",
  DESELECCIONAR_TODOS_GRUPO_USUARIOS: "DESELECCIONAR_TODOS_GRUPO_USUARIOS",
  DELETE_SELECCION_GRUPO_USUARIOS: "DELETE_SELECCION_GRUPO_USUARIOS",
  CAMBIO_ESTADO_SELECCION_GRUPO_USUARIOS: "CAMBIO_ESTADO_SELECCION_GRUPO_USUARIOS",
  CREAR_CSV_GRUPO_USUARIOS: "CREAR_CSV_GRUPO_USUARIOS",
  IMPRIMIR_PDF_GRUPO_USUARIOS: "IMPRIMIR_PDF_GRUPO_USUARIOS"
}

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/escandallo/escandallo'
import { closeModalEscandallo, filtrarEscandalloSuccess, filtrosEscandalloSuccess, recuperarDatosModalEscandalloAnadidoSuccess, editarEscandalloAnadidoSuccess,
  duplicarDatosModalEscandalloAnadidoSuccess, recuperarDatosModalEscandalloSuccess, duplicarDatosModalEscandalloSuccess, guardarYnuevoEscandalloSuccess, deSeleccionarTodosEscandallo,
  crearCsvEscandalloSuccess, imprimirPdfEscandalloSuccess
} from '../../actions/escandallo/escandallo'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import filtrosEscandallo from '../../services/escandallo/filtrosEscandallo'
import insertarEscandallo from '../../services/escandallo/insertarEscandallo'
import getEscandallo from '../../services/escandallo/getEscandallo'
import editEscandallo from '../../services/escandallo/editEscandallo'
import getEscandalloCambioEstado from '../../services/escandallo/getEscandalloCambioEstado'
import deleteEscandallo from '../../services/escandallo/deleteEscandallo'
import getCambiarEstadoEscandalloSeleccion from '../../services/escandallo/getCambiarEstadoEscandalloSeleccion'
import eliminarEscandalloSeleccion from '../../services/escandallo/eliminarEscandalloSeleccion'
import getCrearCsv from '../../services/escandallo/getCrearCsv'
import getImprimirPdfEscandallo from '../../services/escandallo/getImprimirPdfEscandallo'
import {comboNombreEscandallo} from '../../actions/combos/combos'

export function * filtrarEscandallo ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrosEscandallo, values, auth.token)
    yield put(filtrarEscandalloSuccess(list))
    yield put(filtrosEscandalloSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFiltrarEscandallo () {
  yield takeLatest(actionTypes.FILTRAR_ESCANDALLO, filtrarEscandallo)
}

export function * abrirModalEscandallo ({values}) {
  try {
    const valores = {activo: true, avisoStockMinimo: false}
    yield put(initialize('ModalEscandallo', valores))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirEscandallo () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ESCANDALLO, abrirModalEscandallo)
}

export function * submitNuevoEscandallo ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionEscandallo = yield call(insertarEscandallo, values, auth.token)
    yield put(closeModalEscandallo())
    if (insercionEscandallo.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    let filtros = yield select(state => state.escandallo.filtros)
    const lista = yield call(filtrosEscandallo, filtros, auth.token)
    yield put(filtrarEscandalloSuccess(lista))
    yield put(filtrosEscandalloSuccess(filtros))
    yield put(comboNombreEscandallo())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoEscandallo() {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_ESCANDALLO, submitNuevoEscandallo)
}

export function * recuperarDatosModalEscandalloAnadido  ({idElemento, proceso}) {
  try {
    let auth = yield select(state => state.auth)
    let escandalloState
    if (proceso ===  1) {
      escandalloState = yield select(state => state.escandallo.escandalloAnalisis)
    } else if (proceso ===  2) {
      escandalloState = yield select(state => state.escandallo.escandalloDilucion)
    } else if (proceso ===  3) {
      escandalloState = yield select(state => state.escandallo.escandalloEnvasado)
    }
    const escandalloRecuperar = escandalloState.filter((escandallo) => {
      if (escandallo.idElemento === idElemento){
        if (escandallo.unidadMedida === 1){
          escandallo.unidadMedida = i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES')
        } else if (escandallo.unidadMedida === 2) {
          escandallo.unidadMedida = i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS')
        }
        return escandallo
      }
    })
    yield put(recuperarDatosModalEscandalloAnadidoSuccess(escandalloRecuperar[0]))
    yield put(initialize('ModalAnadirEscandallo', escandalloRecuperar[0]))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalEscandalloAnadido () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO, recuperarDatosModalEscandalloAnadido)
}

export function * editarEscandalloAnadido ({values, proceso}) {
  try {
    let escandalloState
    if (proceso ===  1) {
      escandalloState = yield select(state => state.escandallo.escandalloAnalisis)
    } else if (proceso ===  2) {
      escandalloState = yield select(state => state.escandallo.escandalloDilucion)
    } else if (proceso ===  3) {
      escandalloState = yield select(state => state.escandallo.escandalloEnvasado)
    }
    const escandalloRecuperar = escandalloState.filter((escandallo) => {
      if (escandallo.proceso.value === proceso){
        if (escandallo.idElemento === values.idElemento){
          escandallo.producto = values.producto
          escandallo.unidadMedida = values.producto.unidadMedida
          escandallo.cantidadEscandallo = values.cantidadEscandallo
          escandallo.nombreProducto = values.producto.label
        }
      }
      return escandallo
    })
    yield put(editarEscandalloAnadidoSuccess(escandalloRecuperar, proceso))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarEscandalloAnadido () {
  yield takeLatest(actionTypes.EDITAR_ESCANDALLO_ANADIDO, editarEscandalloAnadido )
}

export function * onDeleteEscandalloAnadido ({idElemento, proceso}) {
  try {
    let escandalloState
    if (proceso ===  1) {
      escandalloState = yield select(state => state.escandallo.escandalloAnalisis)
    } else if (proceso ===  2) {
      escandalloState = yield select(state => state.escandallo.escandalloDilucion)
    } else if (proceso ===  3) {
      escandalloState = yield select(state => state.escandallo.escandalloEnvasado)
    }
    const escandalloRecuperar = escandalloState.filter((escandallo) => {
      if (escandallo.proceso.value === proceso){
        if (escandallo.idElemento !== idElemento){
          return escandallo
        }
      }
    })
    yield put(editarEscandalloAnadidoSuccess(escandalloRecuperar, proceso))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteEscandalloAnadido () {
  yield takeLatest(actionTypes.ON_DELETE_ESCANDALLO_ANADIDO, onDeleteEscandalloAnadido )
}

// TODO Eliminar si al final eliminamos la funcionalidad de duplicar producto de un escandallo
export function * duplicarDatosModalEscandalloAnadido ({idElemento, proceso}) {
  try {
    let auth = yield select(state => state.auth)
    let escandalloState
    if (proceso ===  1) {
      escandalloState = yield select(state => state.escandallo.escandalloAnalisis)
    } else if (proceso ===  2) {
      escandalloState = yield select(state => state.escandallo.escandalloDilucion)
    } else if (proceso ===  3) {
      escandalloState = yield select(state => state.escandallo.escandalloEnvasado)
    }
    const escandalloRecuperar = escandalloState.filter((escandallo) => {
      if (escandallo.idElemento === idElemento){
        if (escandallo.unidadMedida === 1){
          escandallo.unidadMedida = i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES')
        } else if (escandallo.unidadMedida === 2) {
          escandallo.unidadMedida = i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS')
        }
        return escandallo
      }
    })
    yield put(duplicarDatosModalEscandalloAnadidoSuccess(escandalloRecuperar[0]))
    console.log(escandalloRecuperar[0])
    yield put(initialize('ModalAnadirEscandallo', escandalloRecuperar[0]))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalEscandalloAnadido () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO, duplicarDatosModalEscandalloAnadido )
}

export function * recuperarDatosModalEscandallo  ({idEscandallo}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getEscandallo, idEscandallo)
    const comboTipoDosis = yield select(state => state.combos.comboTipoDosis)
    const tipoDosis = comboTipoDosis.filter((dosis) => {
      if (dosis.value === registroModificar.escandallo.idTipoDosis) {
        return dosis
      }
    })
    registroModificar.escandallo.idTipoDosis = tipoDosis[0]
    yield put(recuperarDatosModalEscandalloSuccess(registroModificar))
    yield put(initialize('ModalEscandallo', registroModificar.escandallo))

    registroModificar.analisis.map((analisis) => {
      analisis.producto = {value: analisis.idProducto, label: analisis.nombreProducto}
      analisis.proceso = {value: 1, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')}
    })
    registroModificar.dilucion.map((dilucion) => {
      dilucion.producto = {value: dilucion.idProducto, label: dilucion.nombreProducto}
      dilucion.proceso = {value: 2, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')}
    })
    registroModificar.envasado.map((envasado) => {
      envasado.producto = {value: envasado.idProducto, label: envasado.nombreProducto}
      envasado.proceso = {value: 3, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')}
    })
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalEscandallo () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO, recuperarDatosModalEscandallo)
}

export function * editarEscandallo ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const modificacionEscandallo = yield call(editEscandallo, values, auth.token)
    yield put(closeModalEscandallo())
    if (modificacionEscandallo.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(comboNombreEscandallo())
    let filtros = yield select(state => state.escandallo.filtros)
    if (filtros.nombre && values.nombre){
      filtros.nombre = {value: filtros.nombre.value, label: values.nombre}
    }
    const lista = yield call(filtrosEscandallo, filtros, auth.token)
    yield put(filtrarEscandalloSuccess(lista))
    yield put(filtrosEscandalloSuccess(filtros))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarEscandallo() {
  yield takeLatest(actionTypes.EDITAR_ESCANDALLO, editarEscandallo)
}

export function * duplicarDatosModalEscandallo  ({idEscandallo}) {
  try {
    let auth = yield select(state => state.auth)
    const registroDuplicar = yield call(getEscandallo, idEscandallo)
    const comboTipoDosis = yield select(state => state.combos.comboTipoDosis)
    const tipoDosis = comboTipoDosis.filter((dosis) => {
      if (dosis.value === registroDuplicar.escandallo.idTipoDosis) {
        return dosis
      }
    })
    registroDuplicar.escandallo.idTipoDosis = null
    yield put(duplicarDatosModalEscandalloSuccess(registroDuplicar))
    yield put(initialize('ModalEscandallo', registroDuplicar.escandallo))

    registroDuplicar.analisis.map((analisis) => {
      analisis.producto = {value: analisis.idProducto, label: analisis.nombreProducto}
      analisis.proceso = {value: 1, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ANALISIS')}
    })
    registroDuplicar.dilucion.map((dilucion) => {
      dilucion.producto = {value: dilucion.idProducto, label: dilucion.nombreProducto}
      dilucion.proceso = {value: 2, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.DILUCION')}
    })
    registroDuplicar.envasado.map((envasado) => {
      envasado.producto = {value: envasado.idProducto, label: envasado.nombreProducto}
      envasado.proceso = {value: 3, label: i18n.t('COMUN.COMBOS.PROCESO_ESCANDALLO.ENVASADO')}
    })
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalEscandallo () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO, duplicarDatosModalEscandallo)
}

export function * guardarYnuevoEscandallo ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionEscandallo = yield call(insertarEscandallo, values, auth.token)
    const valores = {activo: true}
    yield put(initialize('ModalEscandallo', valores))
    yield put(guardarYnuevoEscandalloSuccess())
    if (insercionEscandallo.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    let filtros = yield select(state => state.escandallo.filtros)
    const lista = yield call(filtrosEscandallo, filtros, auth.token)
    yield put(filtrarEscandalloSuccess(lista))
    yield put(filtrosEscandalloSuccess(filtros))
    yield put(comboNombreEscandallo())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoEscandallo() {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_ESCANDALLO, guardarYnuevoEscandallo)
}

export function * cambiarEstadoEscandallo ({idEscandallo}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getEscandalloCambioEstado, idEscandallo, null)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      let filtros = yield select(state => state.escandallo.filtros)
      const lista = yield call(filtrosEscandallo, filtros, auth.token)
      yield put(filtrarEscandalloSuccess(lista))
      yield put(filtrosEscandalloSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoEscandallo () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_ESCANDALLO, cambiarEstadoEscandallo)
}

export function * onDeleteEscandallo ({idEscandallo}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(deleteEscandallo, idEscandallo, null)
      if (eliminar.asociado === false && eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboNombreEscandallo())
      let filtros = yield select(state => state.escandallo.filtros)
      if (filtros.nombre && filtros.nombre.value === idEscandallo){
        filtros.nombre = null
      }
      const lista = yield call(filtrosEscandallo, filtros, auth.token)
      yield put(filtrarEscandalloSuccess(lista))
      yield put(filtrosEscandalloSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteEscandallo () {
  yield takeLatest(actionTypes.ON_DELETE_ESCANDALLO, onDeleteEscandallo)
}

export function * cambiarEstadoSeleccionEscandallo ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(getCambiarEstadoEscandalloSeleccion, {list: list}, auth.token)
      if (cambioEstadoSeleccion.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosEscandallo())
      yield put(initialize('simpleTable'))
      let filtros = yield select(state => state.escandallo.filtros)
      const lista = yield call(filtrosEscandallo, filtros, auth.token)
      yield put(filtrarEscandalloSuccess(lista))
      yield put(filtrosEscandalloSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionEscandallo () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_ESCANDALLO, cambiarEstadoSeleccionEscandallo)
}

export function * deleteSeleccionEscandallo ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarSeleccion = yield call(eliminarEscandalloSeleccion, {list: list}, auth.token)
      if (eliminarSeleccion.asociado === false && eliminarSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosEscandallo())
      yield put(comboNombreEscandallo())
      let filtros = yield select(state => state.escandallo.filtros)
      list.forEach((li) => {
        if (filtros.nombre && filtros.nombre.value === li.idEscandallo){
          filtros.nombre = null
        }
      })
      const lista = yield call(filtrosEscandallo, filtros, auth.token)
      yield put(filtrarEscandalloSuccess(lista))
      yield put(filtrosEscandalloSuccess(filtros))
      yield put(initialize('filtrosEscandallo', filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionEscandallo () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ESCANDALLO, deleteSeleccionEscandallo)
}

export function * crearCsvEscandallo ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelEscandallo = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvEscandalloSuccess(datosExcelEscandallo))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvEscandallo () {
  yield takeLatest(actionTypes.CREATE_CSV_ESCANDALLO, crearCsvEscandallo)
}
export function * imprimirPdfEscandallo ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfEscandallo = yield call(getImprimirPdfEscandallo, list, auth.token)
    yield put(imprimirPdfEscandalloSuccess(datosPdfEscandallo))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfEscandallo () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_ESCANDALLO, imprimirPdfEscandallo)
}

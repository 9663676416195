import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Filtros from '../../components/nivelGenetico/FiltrosNivelGenetico'
import {openModal} from '../../actions/common'
import {abrirModalVacioNivelGenetico} from '../../actions/nivelGenetico/nivelGenetico'

export function mapStateToProps (state) {
  return {
    ...state.nivelGenetico,    
    formSimpleTable: state.form.simpleTable,    
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioNivelGenetico
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filtros))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTipoDiluyentePanelControl from '../../components/panelControl/ModalTipoDiluyentePanelControl'
import {cerrarModalTipoDiluyente, anadirDiluyentePanelControl, obtenerTipoDiluyente, recuperarDatosTipoDiluyente, editarDiluyentePanelControl, duplicarDatosModalTipoDiluyente,
  cambiaPredeterminadoDiluyente, onDeleteDiluyente, seleccionarTodosTipoDiluyente, deSeleccionarTodosTipoDiluyente, deleteSeleccionTipoDiluyente
  } from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalTipoDiluyente,
      anadirDiluyentePanelControl,
      obtenerTipoDiluyente,
      recuperarDatosTipoDiluyente,
      editarDiluyentePanelControl,
      duplicarDatosModalTipoDiluyente,
      cambiaPredeterminadoDiluyente,
      onDeleteDiluyente,
      seleccionarTodosTipoDiluyente,
      deSeleccionarTodosTipoDiluyente,
      deleteSeleccionTipoDiluyente
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTipoDiluyentePanelControl))
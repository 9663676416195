import React from 'react'
import {Radio} from 'react-bootstrap'
import './InputRadioButton.scss'

const InputRadioButton = (props) => {
  let {input, meta, ...rest} = props
  const errorMessage = (meta.touched && meta.error) ? meta.error : null

  return (
    <Radio {...input} {...rest} checked={input.value === rest.value} />
  )
}

export default InputRadioButton
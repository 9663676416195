import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import Pagination from 'react-js-pagination'
import PropTypes from 'prop-types'
import { Table as BootstrapTable, Dropdown, MenuItem, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import {
  RemoveCircle, MoreVert, Edit, FileCopy, Refresh, Delete, CheckBox, Opacity, RemoveRedEye, ArrowDownward, ArrowUpward, Print, Beenhere, VpnKey, Replay, CloudDownload, AddCircle
} from '@material-ui/icons'
import './simpleTable.scss'
import SimpleLeyenda from './SimpleLeyenda'
import InputCheckBox from '../form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { date_formatter, number_formatter, dateTimeZones, date_formatter_tabla, round_decimal } from '../../../util/formatFunctions'
import {verracoSiNo as verracoSiNoConstants} from '../../../constants/seguimiento'

class SimpleTable extends Component {
  constructor(props) {
    super()
    this.state = {
      imputSelected: false,
      activePage: 1,
      filaSelccionada: '',
      rowsOrdenado: [],
      typeOrder: '',
      columnId: '',
      idCol: '',
      columnaSelccionada: ''
    }
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onCheckboxChangeGeneric = this.onCheckboxChangeGeneric.bind(this)
    this.listenScrollEvent = this.listenScrollEvent.bind(this)
  }

  onCheckboxChangeGeneric(val, row, checkOrder){
    this.onCheckboxChange(val)
    if(checkOrder) checkOrder(val,row)
  }

  onCheckboxChange(val) {    
    if (this.props.onCheckboxChange) {
      this.props.onCheckboxChange()
    }
    let hayCheckActivo = false
    let contActivos = 0
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.simpleTable && Object.keys(this.props.formSimpleTable.simpleTable).length > 0) {
        Object.values(this.props.formSimpleTable.simpleTable.values).forEach((value) => {
          if (value === true) {
            contActivos++
          }
        })
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoTrazabilidadMaterialTable && Object.keys(this.props.formSimpleTable.listadoTrazabilidadMaterialTable).length > 0) {
        Object.values(this.props.formSimpleTable.listadoTrazabilidadMaterialTable.values).forEach((value) => {
          if (value === true) {
            contActivos++
          }
        })
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoSeguimientoTable && Object.keys(this.props.formSimpleTable.listadoSeguimientoTable).length > 0) {
        Object.values(this.props.formSimpleTable.listadoSeguimientoTable.values).forEach((value) => {
          if (value === true) {
            contActivos++
          }
        })
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.poolExtraccionesTable && Object.keys(this.props.formSimpleTable.poolExtraccionesTable).length > 0) {
        if (this.props.formSimpleTable.poolExtraccionesTable.values) {
          Object.values(this.props.formSimpleTable.poolExtraccionesTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.envasadoMultiDosisTable && Object.keys(this.props.formSimpleTable.envasadoMultiDosisTable).length > 0) {
        if (this.props.formSimpleTable.envasadoMultiDosisTable.values) {
          Object.values(this.props.formSimpleTable.envasadoMultiDosisTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.seguimientoTable && Object.keys(this.props.formSimpleTable.seguimientoTable).length > 0) {
        if (this.props.formSimpleTable.seguimientoTable.values) {
          Object.values(this.props.formSimpleTable.seguimientoTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.grupoUsuarioTable && Object.keys(this.props.formSimpleTable.grupoUsuarioTable).length > 0) {
        if (this.props.formSimpleTable.grupoUsuarioTable.values) {
          Object.values(this.props.formSimpleTable.grupoUsuarioTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.controlSemenTable && Object.keys(this.props.formSimpleTable.controlSemenTable).length > 0) {
        if (this.props.formSimpleTable.controlSemenTable.values) {
          Object.values(this.props.formSimpleTable.controlSemenTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.controlMorfologicoTable && Object.keys(this.props.formSimpleTable.controlMorfologicoTable).length > 0) {
        if (this.props.formSimpleTable.controlMorfologicoTable.values) {
          Object.values(this.props.formSimpleTable.controlMorfologicoTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.controlSangreTable && Object.keys(this.props.formSimpleTable.controlSangreTable).length > 0) {
        if (this.props.formSimpleTable.controlSangreTable.values) {
          Object.values(this.props.formSimpleTable.controlSangreTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.comboCausaEliminacionTable && Object.keys(this.props.formSimpleTable.comboCausaEliminacionTable).length > 0) {
        if (this.props.formSimpleTable.comboCausaEliminacionTable.values) {
          Object.values(this.props.formSimpleTable.comboCausaEliminacionTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (contActivos > 0) {
      this.addClass()
    } else {
      this.removeClass()
    }
  }

  quitarBotonesFiltros() {
    let contActivos = 0
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.simpleTable && Object.keys(this.props.formSimpleTable.simpleTable).length > 0) {
        if (this.props.formSimpleTable.simpleTable.values) {
          Object.values(this.props.formSimpleTable.simpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoTrazabilidadMaterialTable && Object.keys(this.props.formSimpleTable.listadoTrazabilidadMaterialTable).length > 0) {
        if (this.props.formSimpleTable.listadoTrazabilidadMaterialTable.values) {
          Object.values(this.props.formSimpleTable.listadoTrazabilidadMaterialTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoSeguimientoTable && Object.keys(this.props.formSimpleTable.listadoSeguimientoTable).length > 0) {
        if (this.props.formSimpleTable.listadoSeguimientoTable.values) {
          Object.values(this.props.formSimpleTable.listadoSeguimientoTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.poolExtraccionesTable && Object.keys(this.props.formSimpleTable.poolExtraccionesTable).length > 0) {
        if (this.props.formSimpleTable.poolExtraccionesTable.values) {
          Object.values(this.props.formSimpleTable.poolExtraccionesTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.envasadoMultiDosisTable && Object.keys(this.props.formSimpleTable.envasadoMultiDosisTable).length > 0) {
        if (this.props.formSimpleTable.envasadoMultiDosisTable.values) {
          Object.values(this.props.formSimpleTable.envasadoMultiDosisTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.seguimientoTable && Object.keys(this.props.formSimpleTable.seguimientoTable).length > 0) {
        if (this.props.formSimpleTable.seguimientoTable.values) {
          Object.values(this.props.formSimpleTable.seguimientoTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.grupoUsuarioTable && Object.keys(this.props.formSimpleTable.grupoUsuarioTable).length > 0) {
        if (this.props.formSimpleTable.grupoUsuarioTable.values) {
          Object.values(this.props.formSimpleTable.grupoUsuarioTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
        }
      }
    }
    if (contActivos === 0) {
      this.removeClass()
    }
  }

  addClass = (event) => {
    if (this.props.noBlockOptions !== false && !this.props.tablaModal) {
      if (document.getElementById('block-options')) {
        document.getElementById('block-options').classList.remove('oculta-action')
        this.setState({ imputSelected: true })
      }
    }

    if (this.props.noBlockOptions !== false && this.props.tablaModal === true) {
      if (document.getElementById('block-options' + this.props.idBlockOptions)) {
        document.getElementById('block-options' + this.props.idBlockOptions).classList.remove('oculta-action')
        this.setState({ imputSelected: true })
      }
    }
  }
  removeClass = (event) => {
    if (this.props.noBlockOptions !== false) {
      if (document.getElementById('block-options')) {
        document.getElementById('block-options').classList.add('oculta-action')
        this.setState({ imputSelected: false })
      }
    }

    if (this.props.noBlockOptions !== false && this.props.tablaModal === true) {
      if (document.getElementById('block-options' + this.props.idBlockOptions)) {
        document.getElementById('block-options' + this.props.idBlockOptions).classList.add('oculta-action')
        this.setState({ imputSelected: true })
      }
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber })
    this.props.actions.cambiarPagina(pageNumber)
  }

  cambiarTotalRegistro(event, newValue) {
    if (newValue != null) {
      this.props.actions.seleccionarPorPagina(newValue.value)
    }
  }

  componentDidMount() {
    if (this.props.rows) {
      this.setState({ rowsOrdenado: this.props.rows })
    }
    this.handlePageChange(this.state.activePage)
    this.checkSortTable()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.initialValues) !== JSON.stringify(prevProps.initialValues)) {
      this.props.initialize(this.props.initialValues)
    }

    if (this.props.rows !== prevProps.rows) {
      // this.setState({ rowsOrdenado: this.props.rows })
      // this.setState({ idCol: '' })
      if(this.props.common.sortList.id && this.props.id == this.props.common.sortList.id){
        let typeOrder = this.props.common.sortList.order.typeOrder
        let columnId = this.props.common.sortList.order.columnId
        let idCol = this.props.common.sortList.order.idCol
        let rowsOrdenado = this.props.rows
        this.setState({ typeOrder: typeOrder, columnId: columnId, idCol: idCol, rowsOrdenado: rowsOrdenado })
      }else{
        this.setState({ rowsOrdenado: this.props.rows })
        this.setState({ idCol: '' })
      }
    }

    if (this.props.showModal !== prevProps.showModal) {
      this.handlePageChange(this.state.activePage)
    }
    if (this.props.checkedTabla1 !== prevProps.checkedTabla1) {
      if (this.props.checkedTabla1 === true) {
        this.handlePageChange(this.state.activePage)
      }
    }
    if (this.props.checkedTabla2 !== prevProps.checkedTabla2) {
      if (this.props.checkedTabla2 === true) {
        this.handlePageChange(this.state.activePage)
      }
    }
    if (this.props.checkedTabla3 !== prevProps.checkedTabla3) {
      if (this.props.checkedTabla3 === true) {
        this.handlePageChange(this.state.activePage)
      }
    }

    if (this.props.planing.datosVerracoPlaning !== prevProps.planing.datosVerracoPlaning) {
      if (Object.keys(this.props.planing.datosVerracoPlaning).length === 0) {
        var elems = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function (el) {
          el.classList.remove("fila-seleccionada-planing")
        })

        var elems2 = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems2, function (el) {
          el.classList.remove("fila-seleccionada-planing")
        })
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.simpleTable && Object.keys(this.props.formSimpleTable.simpleTable).length > 0) {
        if (this.props.formSimpleTable.simpleTable !== prevProps.formSimpleTable.simpleTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoTrazabilidadMaterialTable && Object.keys(this.props.formSimpleTable.listadoTrazabilidadMaterialTable).length > 0) {
        if (this.props.formSimpleTable.listadoTrazabilidadMaterialTable !== prevProps.formSimpleTable.listadoTrazabilidadMaterialTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.listadoSeguimientoTable && Object.keys(this.props.formSimpleTable.listadoSeguimientoTable).length > 0) {
        if (this.props.formSimpleTable.listadoSeguimientoTable !== prevProps.formSimpleTable.listadoSeguimientoTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.poolExtraccionesTable && Object.keys(this.props.formSimpleTable.poolExtraccionesTable).length > 0) {
        if (this.props.formSimpleTable.poolExtraccionesTable !== prevProps.formSimpleTable.poolExtraccionesTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.envasadoMultiDosisTable && Object.keys(this.props.formSimpleTable.envasadoMultiDosisTable).length > 0) {
        if (this.props.formSimpleTable.envasadoMultiDosisTable !== prevProps.formSimpleTable.envasadoMultiDosisTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.seguimientoTable && Object.keys(this.props.formSimpleTable.seguimientoTable).length > 0) {
        if (this.props.formSimpleTable.seguimientoTable !== prevProps.formSimpleTable.seguimientoTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable.grupoUsuarioTable && Object.keys(this.props.formSimpleTable.grupoUsuarioTable).length > 0) {
        if (this.props.formSimpleTable.grupoUsuarioTable !== prevProps.formSimpleTable.grupoUsuarioTable) {
          this.quitarBotonesFiltros()
        }
      }
    }
    this.checkSortTable()
  }

  listenScrollEvent(event) {
    global.positionX = event.clientX
    global.positionY = event.clientY
    document.getElementById('planingTable1').getElementsByTagName('tbody')[0].onscroll = function (positionX, positionY) {
      let div1Coordinates = document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getBoundingClientRect()
      if ((global.positionX >= div1Coordinates.left && global.positionX <= div1Coordinates.right) && (global.positionY >= div1Coordinates.top && global.positionY <= div1Coordinates.bottom)) {
        let div1 = document.getElementById("planingTable1").getElementsByTagName('tbody')[0]
        let div2 = document.getElementById("planingTable2").getElementsByTagName('tbody')[0]
        div2.scrollTop = div1.scrollTop
      }
    }
    document.getElementById('planingTable2').getElementsByTagName('tbody')[0].onscroll = function (positionX, positionY) {
      let div2Coordinates = document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getBoundingClientRect()
      if ((global.positionX >= div2Coordinates.left && global.positionX <= div2Coordinates.right) && (global.positionY >= div2Coordinates.top && global.positionY <= div2Coordinates.bottom)) {
        let div1 = document.getElementById("planingTable1").getElementsByTagName('tbody')[0]
        let div2 = document.getElementById("planingTable2").getElementsByTagName('tbody')[0]
        div1.scrollTop = div2.scrollTop
      }
    }
  }

  anadirClasePlaning(id) {
    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-planing");
    })
    var elems2 = document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function (el) {
      el.classList.remove("fila-seleccionada-planing");
    })

    document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
  }

  anadirClaseFoso(id, idCol, row, valorColumna, nombreColumna) {
    if (valorColumna && valorColumna.idAnalisis) {
      this.props.actions.obtenerExtraccion(valorColumna.idAnalisis)
      this.setState({ columnaSelccionada: id })
      var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function (el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function (eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
        })
      })
      document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].getElementsByTagName('td')[idCol].classList.add("fila-seleccionada-foso")
    } else {
      if (nombreColumna !== 'Verraco') {
        this.props.actions.obtenerDatosVerraco(row.idVerraco, nombreColumna)
      } else {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let fechaVerraco
        if (month < 10) {
          if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24') {
            fechaVerraco = `${day}/0${month}/${year}`
          } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24') {
            fechaVerraco = `0${month}/${day}/${year}`
          }
        } else {
          if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24') {
            fechaVerraco = `${day}/${month}/${year}`
          } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24') {
            fechaVerraco = `${month}/${day}/${year}`
          }
        }
        this.props.actions.obtenerDatosVerraco(row.idVerraco, fechaVerraco)
      }
      this.setState({ columnaSelccionada: id })
      var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function (el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function (eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
        })
      })
      document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].getElementsByTagName('td')[idCol].classList.add("fila-seleccionada-foso")
    }
  }

  checkSortTable(){
    // console.log('checkSortTable1',this.props.id)
    if(this.props.id == this.props.common.sortList.id){
      // console.log('checkSortTable2',this.props.common.sortList.order)
      let id = this.props.common.sortList.id
      let typeOrder = this.props.common.sortList.order.typeOrder
      let columnId = this.props.common.sortList.order.columnId
      let idCol = this.props.common.sortList.order.idCol
      let columnType = this.props.common.sortList.order.columnType
      let columChild = this.props.common.sortList.order.columChild

      let rowsOrdenado = this.props.rows
      if(typeOrder === 'asc'){
        if (columnType === 'fecha') {
          rowsOrdenado.sort(function (a, b) {
            let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
            return dateB - dateA
          })
        } else if (columnType === 'numero') {
          rowsOrdenado.sort(function (a, b) {
            return b[columnId] - a[columnId]
          })
        } else {
          rowsOrdenado.sort(function (a, b) {
            let nameA, nameB
            if (columChild !== null) {
              nameA = a[columnId][columChild]
              nameB = b[columnId][columChild]
            } else {
              nameA = a[columnId]
              nameB = b[columnId]
            }

            if (nameA === null) {
              nameA = ' '
            }
            if (nameB === null) {
              nameB = ' '
            }
            if (nameA.toString().toLowerCase() > nameB.toString().toLowerCase()) {
              return -1
            }
            if (nameA.toString().toLowerCase() < nameB.toString().toLowerCase()) {
              return 1
            }
            return 0
          })
        }
      }else{
        if (columnType === 'fecha') {
          rowsOrdenado.sort(function (a, b) {
            let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
            return dateA - dateB
          })
        } else if (columnType === 'numero') {
          rowsOrdenado.sort(function (a, b) {
            return a[columnId] - b[columnId]
          })
        } else {
          rowsOrdenado.sort(function (a, b) {
            let nameA, nameB
            if (columChild !== null) {
              nameA = a[columnId][columChild]
              nameB = b[columnId][columChild]
            } else {
              nameA = a[columnId]
              nameB = b[columnId]
            }
            if (nameA === null) {
              nameA = ' '
            }
            if (nameB === null) {
              nameB = ' '
            }
            if (nameA.toString().toLowerCase() < nameB.toString().toLowerCase()) {
              return -1
            }
            if (nameA.toString().toLowerCase() > nameB.toString().toLowerCase()) {
              return 1
            }
            return 0
          })
        }
      }
    }
  }

  sortTable(columnId, columnType, idCol, columChild, id) {
    let typeOrder = ''
    let rowsOrdenado = this.props.rows
    if (this.state.typeOrder === 'desc' && this.state.columnId === columnId) {
      // DESCENDENTE
      if (columnType === 'fecha') {
        rowsOrdenado.sort(function (a, b) {
          let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
          return dateB - dateA
        })
      } else if (columnType === 'numero') {
        rowsOrdenado.sort(function (a, b) {
          return b[columnId] - a[columnId]
        })
      } else {
        rowsOrdenado.sort(function (a, b) {
          let nameA, nameB
          if (columChild !== null) {
            nameA = a[columnId][columChild]
            nameB = b[columnId][columChild]
          } else {
            nameA = a[columnId]
            nameB = b[columnId]
          }

          if (nameA === null) {
            nameA = ' '
          }
          if (nameB === null) {
            nameB = ' '
          }
          if (nameA.toString().toLowerCase() > nameB.toString().toLowerCase()) {
            return -1
          }
          if (nameA.toString().toLowerCase() < nameB.toString().toLowerCase()) {
            return 1
          }
          return 0
        })
      }
      typeOrder = 'asc'
      this.setState({ typeOrder: typeOrder})
      this.setState({ columnId: columnId })
    } else {
      // ASCENDENTE
      if (columnType === 'fecha') {
        rowsOrdenado.sort(function (a, b) {
          let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
          return dateA - dateB
        })
      } else if (columnType === 'numero') {
        rowsOrdenado.sort(function (a, b) {
          return a[columnId] - b[columnId]
        })
      } else {
        rowsOrdenado.sort(function (a, b) {
          // let nameA = a[columnId], nameB = b[columnId]
          let nameA, nameB
          if (columChild !== null) {
            nameA = a[columnId][columChild]
            nameB = b[columnId][columChild]
          } else {
            nameA = a[columnId]
            nameB = b[columnId]
          }
          if (nameA === null) {
            nameA = ' '
          }
          if (nameB === null) {
            nameB = ' '
          }
          if (nameA.toString().toLowerCase() < nameB.toString().toLowerCase()) {
            return -1
          }
          if (nameA.toString().toLowerCase() > nameB.toString().toLowerCase()) {
            return 1
          }
          return 0
        })
      }
      typeOrder = 'desc'
      this.setState({ typeOrder: typeOrder })
      this.setState({ columnId: columnId })
    }
    this.setState({ idCol: idCol })

    if (this.props.onSort) {
      this.props.onSort(rowsOrdenado)
      if (id === 'planingTable1') {
        this.props.actions.fetchPlaningSuccess(rowsOrdenado)
        this.props.actions.fetchOrderPlaning(!this.props.planing.order)
      }
    } else {
      if (id === 'planingTable1') {
        this.props.actions.fetchPlaningSuccess(rowsOrdenado)
        this.props.actions.fetchOrderPlaning(!this.props.planing.order)
      }
      this.setState({ rowsOrdenado: rowsOrdenado })
    }

    // Save common.sortList
    let sort = {"id":id, "order": {"columnId":columnId, "typeOrder":typeOrder, "idCol":idCol, "columnType":columnType, "columChild":columChild} }
    this.props.actions.setSortList(sort)
  }

  seleccionFilaSugerencias(id, row) {
    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-planing")
    })

    document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    this.props.actions.obtenerDatosVerracoPlaning(row)

    var elems2 = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function (el) {
      el.classList.remove("fila-seleccionada-planing")
    })
  }

  seleccionFilaSugerenciasQuitar(id, row) {
    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-planing")
    })

    document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    this.props.actions.obtenerDatosVerracoPlaning(row)

    var elems2 = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function (el) {
      el.classList.remove("fila-seleccionada-planing")
    })
  }

  seleccionFilaInformes(id, row) {
    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('informeClientesIzqTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-informes")
    })

    document.getElementById('informeClientesIzqTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
    const filtros = this.props.filtrosCliente
    filtros.idClienteAlbaran = { value: row.idDireccionCliente, seleccionFila: true }
    this.props.actions.obtenerInformeCliente(filtros)
  }

  seleccionarSeguimientoExtracciones(id, row) {
    const values = []
    console.log('seleccionarSeguimientoExtracciones',row)
    if (row.extraccionPool == verracoSiNoConstants.VERRACO) {
      values.idAnalisis = row.idAnalisis
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
     } else if (row.extraccionPool == verracoSiNoConstants.POOL) {
       values.idPool = row.idPool || row.idAnalisis 
       values.idTipo = row.extraccionPool
       this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
     }

    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-informes");
    })
    document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
  }

  seleccionarSeguimientoPool(id, row) {
    const values = []
    if (row.idAnalisis) {
      values.idAnalisis = row.idAnalisis
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    } else if (row.idPool) {
      values.idPool = row.idPool
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    }

    this.setState({ filaSelccionada: id })
    var elems = document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      el.classList.remove("fila-seleccionada-informes");
    })
    document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
  }

  anadirVerracosPlaning() {
    if (Object.keys(this.props.planing.datosVerracoPlaning).length > 0) {
      this.props.actions.anadirVerracosPlaning(this.props.planing.datosVerracoPlaning)
      this.props.actions.obtenerDatosVerracoPlaning([])
    }
  }
  createLoadingMockTable(numRows, numCols) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      let cols = [];
      for (let j = 0; j < numCols; j++) {
        cols.push(<td>-</td>);
      }
      rows.push(<tr>{cols}</tr>);
    }
    return rows;
  }
  quitarVerracosPlaning() {
    if (Object.keys(this.props.planing.datosVerracoPlaning).length > 0) {
      const itemsSeleccion = this.props.planing.listVerracosAsignados.filter((row) => {
        if (row.idVerraco !== this.props.planing.datosVerracoPlaning.idVerraco) {
          return row
        }
      })
      this.props.actions.deleteSeleccionVerracoPlaning(itemsSeleccion)
      this.props.actions.obtenerDatosVerracoPlaning([])
    }
  }
  anadirFechaPlaning(e,row,column) {
    const separadorFecha = column.name.split('/')
    let formatoFecha
    let fechaCoparar
    if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24'){
      formatoFecha = separadorFecha[0] + '-' + separadorFecha[1] + '-' + separadorFecha[2]
      fechaCoparar = new Date(separadorFecha[2], separadorFecha[1] - 1, separadorFecha[0])
    } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24'){
      formatoFecha = separadorFecha[1] + '-' + separadorFecha[0] + '-' + separadorFecha[2]
      fechaCoparar = new Date(separadorFecha[2], separadorFecha[0] - 1, separadorFecha[1])
    } else if (this.props.auth.formaFechaHora === 'year/month/day/12' || this.props.auth.formaFechaHora === 'year/month/day/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[1] + '-' + separadorFecha[0]
      fechaCoparar = new Date(separadorFecha[0], separadorFecha[1] - 1, separadorFecha[2])
    } else if (this.props.auth.formaFechaHora === 'year/day/month/12' || this.props.auth.formaFechaHora === 'year/day/month/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[0] + '-' + separadorFecha[1]
      fechaCoparar = new Date(separadorFecha[0], separadorFecha[2] - 1, separadorFecha[1])
    }
    let fechaHoy = new Date()
    fechaHoy.setHours(0,0,0,0)
    if (fechaCoparar >= fechaHoy){      
      e.currentTarget.classList.remove('planingAmarillo')
      e.currentTarget.classList.add('planingAzul')
      row[column.id] = '[[_#b0000ff_]]';
      this.props.actions.anadirFechaPlaning(row.idVerraco,column.name);
    } else {
      return;
    }
    
  }
  eliminarFechaPlaning(e,row, column) {
    if (row[column.id] === '[[_#rff0000_]]') {
      e.currentTarget.classList.remove('planingRojo')    
    } else if (row[column.id] === '[[_#b0000ff_]]') {
      e.currentTarget.classList.remove('planingAzul')    
    }
    
    const fechaHoy = date_formatter_tabla(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora, false)
    if (column.name == fechaHoy){
      e.currentTarget.classList.add('planingAmarillo');
      row[column.id] = '[[_#hffff00_]]';
    } else {
      row[column.id] = '';
    }
    this.props.actions.eliminarFechaPlaning(row.idVerraco, column.name)
  }
  filaPorDefectoRuta(e, id, row, rows) {
    let tag
    if (e.srcElement) {
      tag = e.srcElement.tagName
    } else if (e.target) {
      tag = e.target.tagName
    }
    if (tag !== 'LABEL' && tag !== "INPUT") {
      this.setState({ filaSelccionada: id })
      var elems = document.getElementById('rutaTableTranspor').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function (el) {
        el.classList.remove("fila-seleccionada-informes")
      })
      document.getElementById('rutaTableTranspor').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
      rows.forEach((ro) => {
        if (ro.idRuta === row.idRuta) {
          ro.predefinido = true
        } else {
          ro.predefinido = false
        }
      })
    }
  }
  mostrarUltimasExtraccionesVerraco(row) {    
    this.props.actions.showUltimasExtraccionesVerraco(row.idAnalisis,row.idVerraco)
  }

  render() {
    const {
      t, id, rows = [], multiHeader = false, hasCheckbox = false, hasOptions = false, hasPagination = true, filtros, esEspecfica, leyenda, mostrarImprimirInforme,
      onEdit, data, duplicar, dataDuplicar, cambiarEstado, dataEstado, onDelete, dataDelete, className = 'simple-table', textAlignLeft, imprimirInforme, rutaPorDefecto,
      mostrarCambioEstado, vigilado, cambiarVigilado, rowsVerraco, mostrarModificar, mostrarDuplicar, mostrarEliminar, mostrarVerDetalle, verDetalle, vehiculoPorDefecto,
      mostrarCambiarEyaculadoEliminado, cambiarEyaculadoEliminado, cambiarOperarioProcesado, cambiarOperarioAlbaran, operarioProcesado, operarioAlbaran, mostrarSubirBajar,
      conductorPorDefecto, cambiarPorDefecto, colorearRow, subirOrden, bajarOrden, mostrarPredeterminado, mostrarEnvasar, envasar, mostrarPaginado, paginar, classTableAlto, esPlaning, planingSeleccionarfila, planningFechas, verracoVigilado, filaPorDefectoRuta, mostrarUltimasExtraccionesVerraco,
      idElemento = 'idElemento', form, esFoso, mostrarIndicadorColor, seleccionarFilaSugerencias, seleccionarFilaSugerenciasQuitar, paginarConScroll, cambiarPassword, mostrarCambiarPassword, seleccionFilaInformes,
      informeSeguimientoExtracciones, informeSeguimientoPool, mostrarDescargarCopiaSeguridad, mostrarRestaurarCopiaSeguridad, descargarCopia, restaurarCopia, activarDesactivar, mostrarcambiarTokenMagavision, cambiarTokenMagavision,
      classNameTr,
      auth: { formaFechaHora, separadorDec, separadorMil },
      actions: { anadirFechaPlaning, eliminarFechaPlaning },
      onClickCallback, isLoading, numRowsLoading, isDraggable = false, selectedRow,
      dragstartCallback, dropCallback, dropCellCallback, dragendCallback,
      mostrarAnnadirAPool = false, onAddToPool, mostrarAnnadirANuevoPool = false, onAddToNewPool, mostrarEliminarDelPool = false, onRemoveFromPool,
      mostrarImprimirAlbaran = false, onImprimirAlbaran,
      mostrarFilaAnnadir = false, mostrarFilaEliminar=false, mostrarAsignar = false, onAsignar, checkOrder
    } = this.props

    let columns = this.props.columns.filter((col) => !!col) || [] // Filtar columnas === false (no generada debido a que no tiene la modalidad necesaria)

    let fechaHoy = date_formatter_tabla(dateTimeZones(this.props.auth.zonaHorariaString), formaFechaHora, false)

    const indexOfLastTodo = paginar !== false && paginarConScroll !== true ? this.state.activePage * this.props.numeroRegistrosPorPagina : this.state.activePage * this.state.rowsOrdenado.length;
    const indexOfFirstTodo = paginar !== false && paginarConScroll !== true ? indexOfLastTodo - this.props.numeroRegistrosPorPagina : indexOfLastTodo - this.state.rowsOrdenado.length;
    const currentTodos = this.state.rowsOrdenado.slice(indexOfFirstTodo, indexOfLastTodo);
    const totalPages = this.state.rowsOrdenado.length / this.props.numeroRegistrosPorPagina
    const t_key = 'COMUN.'
    const comboTotalPerPage = [
      { value: 10, label: 10 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 }
    ]   

    return (
      <div className={'table-container ' + className}>
        {
          filtros
        }


        <SimpleLeyenda items={leyenda} />

        <div id={id} className={paginar !== false ? 'table-scroll-container' : 'table-scroll-container ' + classTableAlto} onMouseOver={paginar !== false ? null : (e) => this.listenScrollEvent(e)}>
          <BootstrapTable striped condensed hover
            id={id}
            className="simple-table"
            draggable={isDraggable}
            onDrop={(e) => { if (dropCallback != null) dropCallback(e) }}
            onDragOver={(e) => {
              if (!isDraggable) return;
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <thead>
              {columns.length > 0 && (() => {
                let th1 = []
                let th2 = []
                let checkbox = hasCheckbox ? <th key="box" className="checkbox-col"></th> : colorearRow && mostrarIndicadorColor !== false ? <th key="box" className="checkbox-col"></th> : null
                let options = hasOptions && <th key="options" className="options-col"></th>

                th1 = columns.map((column, idCol) => {
                  if (multiHeader) {
                    if (column.children) {
                      column.children.forEach((child, idChild) => {
                        th2.push(<th className={!planingSeleccionarfila ? (child.borderDobleHeadeClass === true ? 'borderDobleHeadeClass ordenable' : 'ordenable') : null} key={idCol + '.' + idChild} onClick={() => this.sortTable(column.id, column.type, idCol + '.' + idChild, child.id, null)}>{child.name} {!planingSeleccionarfila && this.state.idCol === idCol + '.' + idChild ? (this.state.typeOrder === 'asc' ? <div className="font-gesipor-asc"></div> : <div className="font-gesipor-desc"></div>) : null}</th>)
                      })
                    } else {
                      th2.push(<th key={idCol}></th>)
                    }
                  }
                  return <th className={!planingSeleccionarfila ? (column.borderDobleHeadeClass === true ? 'borderDobleHeadeClass ordenable' : 'ordenable') : null} onClick={!planningFechas ? () => this.sortTable(column.id, column.type, idCol, null, id) : null} key={idCol} colSpan={multiHeader && column.children ? column.children.length : 1} style={planingSeleccionarfila && { width: column.anchoColum }}><span>{column.name} {!planningFechas && this.state.idCol === idCol ? (this.state.typeOrder === 'asc' ? <div className="font-gesipor-asc"></div> : <div className="font-gesipor-desc"></div>) : null}</span></th>
                })

                th1 = <tr key="th1">{[checkbox, th1, options]}</tr>
                if (multiHeader) th2 = <tr key="th2">{[checkbox, th2, options]}</tr>
                return [th1, th2]
              })()}
            </thead>
            <tbody>
              
              {mostrarFilaAnnadir ? <tr id="AnnadirNuevoPool" onDrop={(e) => { if (dropCellCallback != null) dropCellCallback(e, null) }}><td colSpan={100}><AddCircle /></td></tr> : null}
              {mostrarFilaEliminar ? <tr id="EliminarPool" onDrop={(e) => { if (dropCellCallback != null) dropCellCallback(e, null) }}><td colSpan={100}><RemoveCircle /></td></tr> : null}
              {!currentTodos || !currentTodos.length || currentTodos.length === 0 ? (
                <tr><td colSpan={100}>{t(`${t_key}SIN_RESULTADOS`)}</td></tr>
              ) : null}
              {currentTodos.map((row, idRow) =>

                <tr
                  id={id}
                  onDrop={(e) => { if (dropCellCallback != null) dropCellCallback(e, row) }}
                  draggable={isDraggable}
                  onDragStart={(e) => { if (dragstartCallback != null) dragstartCallback(e, row, idRow) }}
                  onDragEnd={(e) => { if (dragendCallback != null) dragendCallback(e, row) }}
                  onDragOver={(e) => {
                    if (!isDraggable) return;
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  key={idRow}
                  onDoubleClick={() => mostrarModificar !== false ? (onEdit(row, data)) : (seleccionarFilaSugerencias === true ? this.anadirVerracosPlaning() : seleccionarFilaSugerenciasQuitar === true ? this.quitarVerracosPlaning() : null)}
                  className={informeSeguimientoExtracciones === true && idRow === 0 ? 'fila-seleccionada-informes' : classNameTr && classNameTr(idRow, row)}
                  onClick={(e) => onClickCallback != undefined ? onClickCallback(row, idRow) : planingSeleccionarfila === true ? this.anadirClasePlaning(idRow) : (seleccionarFilaSugerencias === true ? this.seleccionFilaSugerencias(idRow, row) : (seleccionarFilaSugerenciasQuitar === true ? this.seleccionFilaSugerenciasQuitar(idRow, row) : (seleccionFilaInformes === true ? this.seleccionFilaInformes(idRow, row) : (filaPorDefectoRuta === true ? this.filaPorDefectoRuta(e, idRow, row, rows) : null))))}
                >

                  {columns.length > 0 && (() => {
                    let tr = []
                    if (hasCheckbox) {
                      tr.push(<td key="checkbox" className="checkbox-col">
                        {
                          mostrarIndicadorColor !== false ? (
                            <div className={colorearRow && colorearRow(row) + ' barra-color'}></div>
                          ) : null
                        }
                        <Field
                          id={`check` + (form !== 'simpleTable' ? form : '') + row[idElemento]}
                          name={`check` + (form !== 'simpleTable' ? form : '') + row[idElemento]}
                          colSm={12}
                          inline
                          component={InputCheckBox}
                          //onInputChange={(val) => this.onCheckboxChange(val)}
                          onInputChange={(val) => this.onCheckboxChangeGeneric(val,row, checkOrder)}
                        />
                      </td>)
                    } else {
                      if (colorearRow && mostrarIndicadorColor !== false) {
                        tr.push(<td key="checkbox" className="checkbox-col">
                          <div className={colorearRow(row) + ' barra-color'}></div>
                        </td>)
                      }
                    }

                    columns.forEach((column, idCol) => {
                      if (multiHeader && column.children) {
                        column.children.forEach((child, idChild) => {
                          tr.push(
                            row.activo === false && esEspecfica !== false ? (
                              <td style={{ color: '#c1c1c1', textDecoration: 'line-through' }} key={idCol + '.' + idChild}>
                                {(row[column.id] ? (child.type === 'numero' && (row[column.id]) ? number_formatter(round_decimal(row[column.id][child.id], (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil), (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil) : row[column.id][child.id]) : null)}
                              </td>
                            ) :
                              <td key={idCol + '.' + idChild}>{(row[column.id] ?
                                (child.type === 'numero' && (row[column.id]) ? number_formatter(round_decimal(row[column.id][child.id], (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil), (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil) : row[column.id][child.id])
                                : null)}</td>
                          )
                        })
                      } else {
                        let classes = column.classes || ''
                        if (column.textAlignLeft) {
                          classes += ' text-left'
                        } else {
                          classes += ' text-center'
                        }

                        tr.push(<td style={planingSeleccionarfila && { width: column.anchoColum }}
                          key={idCol}
                          className={row[column.id] === '[[_#rff0000_]]' ?
                            ('planingRojo ') :
                            (row[column.id] === '[[_#b0000ff_]]' ? (
                              'planingAzul ') :
                              (column.name === fechaHoy && esFoso !== true ? (
                                'planingAmarillo ') :
                                (row[column.id] === '[[_#hffff00_]]' && esFoso === true ? (
                                  'fosoHoy ') :
                                  (esFoso === true && (row[column.id] && row[column.id].dosisReales) ?
                                    ('fosoVerde') :
                                    classes
                                  )
                                )
                              )
                            )
                          }
                          onClick={(e) => esPlaning === true ? (row[column.id] === '[[_#rff0000_]]' || row[column.id] === '[[_#b0000ff_]]' ? this.eliminarFechaPlaning(e,row, column) : this.anadirFechaPlaning(e,row, column)) : esFoso === true ? this.anadirClaseFoso(idRow, idCol, row, row[column.id], column.name) : (informeSeguimientoExtracciones === true ? this.seleccionarSeguimientoExtracciones(idRow, row) : (informeSeguimientoPool === true ? this.seleccionarSeguimientoPool(idRow, row) : (column.mostrarUltimasExtraccionesVerracoNuevo === true ? this.mostrarUltimasExtraccionesVerraco(row) : null)))}>
                          
                          {
                            row[column.id] === true ?
                              verracoVigilado === true && column.id === 'vigilado' ? (
                                row.descripcionVigilado !== null ? (
                                  <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                    <Popover id={row.idVerraco} className="popover-simple-table">
                                      <p key={id}>{row.descripcionVigilado}</p>
                                    </Popover>
                                  )}>
                                    <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                      <div style={{ cursor: 'pointer' }}><RemoveRedEye /></div>
                                    </div>
                                  </OverlayTrigger>
                                ) : <RemoveRedEye />
                              ) : (
                                <CheckBox />) :
                              (
                                column.stilyzer ?
                                  column.stilyzer({
                                    ...row, valorFormateado: (
                                      column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                        column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                          column.type === 'fechaHoraTabla' && (row[column.id]) ? date_formatter_tabla(row[column.id], formaFechaHora, true) :
                                            column.type === 'numero' && (row[column.id]) ? number_formatter(round_decimal(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil), (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                              row[column.id]
                                    )
                                  }) :
                                  row.activo === false && esEspecfica !== false ? (
                                    <span style={{ color: '#c1c1c1', textDecoration: 'line-through' }}>
                                      {
                                        column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                          column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                            column.type === 'fechaHoraTabla' && (row[column.id]) ? date_formatter_tabla(row[column.id], formaFechaHora, true) :
                                              column.type === 'numero' && (row[column.id]) ? number_formatter(round_decimal(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil), (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                                row[column.id]
                                      }
                                    </span>
                                  ) : (
                                    column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                      column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                        column.type === 'fechaHoraTabla' && (row[column.id]) ? date_formatter_tabla(row[column.id], formaFechaHora, true) :
                                          column.type === 'numero' && (row[column.id]>=0) ? number_formatter(round_decimal(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil), (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                            (
                                              column.mostrarVerracosPopover === true ? (
                                                <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                                  <Popover id={row[column.id]} className="popover-simple-table">
                                                    {
                                                      row.verracos.map((verraco, id) => <p key={id}>{verraco.codVerraco}</p>)
                                                    }
                                                  </Popover>
                                                )}>
                                                  <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                                    <div style={{ cursor: 'pointer' }}>{row[column.id]}</div>
                                                  </div>
                                                </OverlayTrigger>
                                              ) : column.mostrarPoolsPopover === true ? (
                                                <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                                  <Popover id={row[column.id]} className="popover-simple-table">
                                                    {
                                                      row.verracosPool.map((verracoPool, id) => <p key={id}>{verracoPool.codigo + ' - ' + verracoPool.codVerraco}</p>)
                                                    }
                                                  </Popover>
                                                )}>
                                                  <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                                    <div style={{ cursor: 'pointer' }}>{row[column.id]}</div>
                                                  </div>
                                                </OverlayTrigger>
                                              ) : column.mostrarUltimasExtraccionesVerracoPopover === true ? (
                                                <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                                  <Popover id={row[column.id]} className="popover-simple-table popover-xl">
                                                    <table className="simple-table table table-striped table-condensed table-hover m-b-0">
                                                      <thead>
                                                        <tr>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.FECHA')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.VOL')}</th>
                                                          <th>{column.nameMotilidad}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.CONCENTRACION_TOTAL')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.FORMAS_ANORMALES')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.TIPO_DOSIS')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.N_DOSIS_REALES')}</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          row.ultimasExtraccionesVerraco && row.ultimasExtraccionesVerraco.length > 0 ? row.ultimasExtraccionesVerraco.map((extraccion, id) => {
                                                            return (
                                                              <tr key={id}>
                                                                <td>{date_formatter(extraccion.fechaExtraccion, formaFechaHora, false)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.volumen || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion[column.idMotilidad] || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.concentracionTotal || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.faTotalPercent || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{extraccion.nombreTipoDosis}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.dosisReales || 0, 0, separadorDec, separadorMil), 0, separadorDec, separadorMil)}</td>
                                                              </tr>
                                                            )
                                                          }) :
                                                            (
                                                              <tr>
                                                                <td colSpan="7">{t(`${t_key}SIN_RESULTADOS`)}</td>
                                                              </tr>
                                                            )
                                                        }
                                                      </tbody>
                                                    </table>
                                                  </Popover>
                                                )}>
                                                  <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                                    <div style={{ cursor: 'pointer' }}>{row[column.id]}</div>
                                                  </div>
                                                </OverlayTrigger>
                                              ) : column.mostrarUltimasExtraccionesVerracoNuevo === true ? (
                                                <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                                  <Popover id={row[column.id]} className="popover-simple-table popover-xl">
                                                    <table className="simple-table table table-striped table-condensed table-hover m-b-0">
                                                      <thead>
                                                        <tr>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.FECHA')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.VOL')}</th>
                                                          <th>{column.nameMotilidad}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.CONCENTRACION_TOTAL')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.FORMAS_ANORMALES')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.TIPO_DOSIS')}</th>
                                                          <th>{t('ANALISIS_EXTRACCIONES.COLUMNS.N_DOSIS_REALES')}</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          column.ultimasExtraccionesVerraco && column.ultimasExtraccionesVerraco.length > 0 ? column.ultimasExtraccionesVerraco.map((extraccion, id) => {
                                                            return (
                                                              <tr key={id}>
                                                                <td>{date_formatter(extraccion.fechaExtraccion, formaFechaHora, false)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.volumen || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion[column.idMotilidad] || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.concentracionTotal || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.faTotalPercent || 0, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</td>
                                                                <td>{extraccion.nombreTipoDosis}</td>
                                                                <td>{number_formatter(round_decimal(extraccion.dosisReales || 0, 0, separadorDec, separadorMil), 0, separadorDec, separadorMil)}</td>
                                                              </tr>
                                                            )
                                                          }) :
                                                            (
                                                              <tr>
                                                                <td colSpan="7">{t(`${t_key}SIN_RESULTADOS`)}</td>
                                                              </tr>
                                                            )
                                                        }
                                                      </tbody>
                                                    </table>
                                                  </Popover>
                                                )}>
                                                  <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                                    <div style={{ cursor: 'pointer' }}>{row[column.id]}</div>
                                                  </div>
                                                </OverlayTrigger>
                                              ) : row[column.id] !== '[[_#rff0000_]]' && row[column.id] !== '[[_#b0000ff_]]' && row[column.id] !== '[[_#hffff00_]]' && row[column.name] !== fechaHoy && (row[column.id] && !row[column.id].dosisReales) ? (
                                                column.type === 'numeroPlaning' && row[column.id] !== '' && row[column.id] !== undefined && row[column.id] !== null ? (number_formatter(round_decimal(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil), (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil)) : row[column.id]
                                              ) : row[column.id] && row[column.id].dosisReales ? (
                                                row[column.id].dosisReales
                                              ) : null
                                            )
                                  )
                              )
                          }
                          {(row['orden']) ? 
                            (row[column.id] === '[[_#b0000ff_]]') ? row['orden'][column.id] : null 
                          : null}                          
                          </td>)
                      }
                    })

                    if (hasOptions) {
                      let hasSeparator = false
                      tr.push(<td key="options" className="options-col" onClick={(e) => e.stopPropagation()}>
                        <Dropdown pullRight id={`${id}.${idRow}.options`}>
                          <Dropdown.Toggle noCaret>
                            <MoreVert />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              mostrarEnvasar === true ? hasSeparator = true && (
                                <MenuItem eventKey="16" onClick={() => envasar(row)}><Beenhere /><span>{t(`${t_key}ENVASAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarModificar !== false ? hasSeparator = true && (
                                <MenuItem eventKey="1" onClick={() => onEdit(row, data)}><Edit /><span>{t(`${t_key}MODIFICAR`)}</span></MenuItem>
                              ) : null
                            }

                            {
                              mostrarDuplicar !== false ? hasSeparator = true && (
                                <MenuItem eventKey="2" onClick={() => duplicar(row, dataDuplicar)}><FileCopy /><span>{t(`${t_key}DUPLICAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambioEstado !== false ? hasSeparator = true && (
                                <MenuItem eventKey="3" onClick={() => cambiarEstado(row, dataEstado)}><Refresh /><span>{activarDesactivar === true ? t(`${t_key}ACTIVAR_DESACTIVAR`) : t(`${t_key}CAMBIO_ESTADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambiarEyaculadoEliminado ? hasSeparator = true && (
                                <MenuItem eventKey="5" onClick={() => cambiarEyaculadoEliminado(row, dataEstado)}><Opacity /><span>{t(`${t_key}CAMBIO_EYACULADO_ELIMINADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              vigilado === true ? hasSeparator = true && (
                                <MenuItem eventKey="6" onClick={() => cambiarVigilado(row, dataEstado)}><Refresh /><span>{t(`${t_key}CAMBIO_VIGILADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              operarioProcesado === true ? hasSeparator = true && (
                                <MenuItem eventKey="7" onClick={() => cambiarOperarioProcesado(row, dataEstado)}><Refresh /><span>{t(`${t_key}OPERARIO_PROCESADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              operarioAlbaran === true ? hasSeparator = true && (
                                <MenuItem eventKey="8" onClick={() => cambiarOperarioAlbaran(row, dataEstado)}><Refresh /><span>{t(`${t_key}OPERARIO_ALBARAN`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              verDetalle ? hasSeparator = true && (
                                <MenuItem eventKey="9" onClick={() => verDetalle(row, dataEstado)}><RemoveRedEye /><span>{t(`${t_key}VER_DETALLE`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarSubirBajar === true ? hasSeparator = true && (
                                <MenuItem eventKey="10" onClick={() => subirOrden(row, dataEstado)}><ArrowUpward /><span>{t(`${t_key}SUBIR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarSubirBajar === true ? hasSeparator = true && (
                                <MenuItem eventKey="11" onClick={() => bajarOrden(row, dataEstado)}><ArrowDownward /><span>{t(`${t_key}BAJAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarImprimirInforme === true ? hasSeparator = true && (
                                <MenuItem eventKey="12" onClick={() => imprimirInforme(row, dataEstado)}><Print /><span>{t(`${t_key}IMPRIMIR_INFORME`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              conductorPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="13" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere /><span>{t(`${t_key}CONDUCTOR_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              vehiculoPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="14" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere /><span>{t(`${t_key}VEHICULO_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              rutaPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="15" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere /><span>{t(`${t_key}RUTA_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarPredeterminado === true ? hasSeparator = true && (
                                <MenuItem eventKey="16" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere /><span>{t(`${t_key}PREDETERMINADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambiarPassword === true ? (
                                <MenuItem eventKey="17" onClick={() => cambiarPassword(row)}><VpnKey /><span>{t(`${t_key}CAMBIAR_PASSWORD`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarDescargarCopiaSeguridad === true ? (
                                <MenuItem eventKey="18" onClick={() => descargarCopia(row)}><CloudDownload /><span>{t(`${t_key}DESCARGAR_COPIA`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarRestaurarCopiaSeguridad === true ? (
                                <MenuItem eventKey="19" onClick={() => restaurarCopia(row)}><Replay /><span>{t(`${t_key}RESTAURAR_COPIA`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarcambiarTokenMagavision === true ? hasSeparator = true && (
                                <MenuItem eventKey="20" onClick={() => cambiarTokenMagavision(row, dataEstado)}><VpnKey /><span>{t(`${t_key}CAMBIAR_TOKEN_MAGAVISION`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarAnnadirAPool !== false ? hasSeparator = true && (
                                <MenuItem eventKey="21" onClick={() => onAddToPool(row, data)}><AddCircle /><span>{t(`ASIGNACION_ANALISIS_POOL.ACCIONES.ANADIR_NUEVO_POOL_ES`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarAnnadirANuevoPool !== false ? hasSeparator = true && (
                                <MenuItem eventKey="22" onClick={() => onAddToNewPool(row, data)}><AddCircle /><span>{t(`ASIGNACION_ANALISIS_POOL.ACCIONES.ANADIR_NUEVO_POOL`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarEliminarDelPool !== false ? hasSeparator = true && (
                                <MenuItem eventKey="23" onClick={() => onRemoveFromPool(row, data)}><Delete /><span>{t(`ASIGNACION_ANALISIS_POOL.ACCIONES.ELIMINAR_POOL`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarImprimirAlbaran !== false ? hasSeparator = true && (
                                <MenuItem eventKey="24" onClick={() => onImprimirAlbaran(row, data)}><Print /><span>{t(`${t_key}IMPRIMIR_ALBARAN`)}</span></MenuItem>
                              ):null
                            }
                            {
                              mostrarAsignar !== false ? hasSeparator = true && (
                                <MenuItem eventKey="25" onClick={() => onAsignar(row, data)}><AddCircle /><span>{t(`PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ASIGNAR_DOSIS`)}</span></MenuItem>
                              ):null
                            }
                            {
                              hasSeparator && mostrarEliminar && (<MenuItem divider />)
                            }
                            {
                              mostrarEliminar !== false ? (
                                <MenuItem eventKey="4" onClick={() => onDelete(row, dataDelete)}><Delete /><span>{t(`${t_key}ELIMINAR`)}</span></MenuItem>
                              ) : null
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>)
                    }
                    return tr
                  })()}
                </tr>
              )

              }

            </tbody>

          </BootstrapTable>
        </div>
        {hasPagination && (
          <Row className="content-pagination row-flex">
            <div className="col-auto">
              <Row className="row-flex">
                <span className="pagination-info col-auto">
                  {t(`${t_key}MOSTRANDO_DEL`)}{rows.length > 0 ? (this.props.numeroRegistrosPorPagina * (this.state.activePage - 1) + 1) : 0} {t(`${t_key}AL`)} {this.props.numeroRegistrosPorPagina * this.state.activePage <= rows.length ? this.props.numeroRegistrosPorPagina * this.state.activePage : rows.length}
                  <span>{t(`${t_key}DE`)}</span> {rows.length}{t(`${t_key}REGISTROS`)}
                </span>
                <Field
                  colSm={0}
                  id="candidadRegistros"
                  name="candidadRegistros"
                  controlLabel={t('COMUN.NUMERO_REGISTROS')}
                  placeholder={this.props.numeroRegistrosPorPagina}
                  component={InputSelect}
                  options={comboTotalPerPage}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-total-registros col-auto'
                  onChange={(event, newValue) => this.cambiarTotalRegistro(event, newValue)}
                  menuPlacement="auto"
                />
              </Row>
            </div>
            <Pagination
              customClass="col-auto"
              activePage={this.state.activePage}
              itemsCountPerPage={1}
              totalItemsCount={totalPages}
              pageRangeDisplayed={1}
              onChange={this.handlePageChange.bind(this)}
              items={currentTodos}
              hideDisabled
            />
          </Row>
        )}
      </div>
    )
  }
}

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
}

export default (reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SimpleTable))

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter} from '../../util/formatFunctions'
import InputSelect from '../comun/form/InputSelect'

class ModalIndex extends Component {

  componentDidMount () {    
    this.props.actions.comboNivelGeneticoGeneral()
  }
  
  submitNuevaIndex (values) {
    if (this.props.dataIndex === true){
      this.props.actions.editarIndex(values)
    } else {
      this.props.actions.submitNuevaIndex(values)
    }
  }
  guardarYnuevoIndex(values){
    this.props.actions.guardarYnuevoIndex(values)
  }
  render () {
    const {
      t, handleSubmit, showModalIndex, dataIndex, dataIndexDuplicar, 
      fichaVerraco: {indexAnadidos},
      actions: {closeModalIndex},
      combos: {comboNivelGeneticoGeneral}
    } = this.props
        
    const ultimaIndex = Object.keys(this.props.fichaVerraco.indexAnadidos).length > 0 ? this.props.fichaVerraco.indexAnadidos.length -1 : null    
    return (
      <Modal show={showModalIndex} onHide={closeModalIndex} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-Index">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            dataIndex ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.TITULO_EDITAR')
            ) : dataIndexDuplicar ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.TITULO_DUPLICAR')
            ) : (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              dataIndex === '' && dataIndexDuplicar === '' ? (
                <Col sm={6}>
                  <div className="form-group">
                    <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX_ULTIMA_FECHA')}</label>
                    <input type="text" value={indexAnadidos.length > 0 ? (
                      date_formatter(indexAnadidos[0].fechaIndex, this.props.auth.formaFechaHora, false)
                    ): ('-')} disabled="disabled" className="form-control"/>
                  </div>
                </Col>
              ) : null
            }
            <Field
              id="fechaIndex"
              name="fechaIndex"
              component={InputDatePicker}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.FECHA') + '*'}
              validate={required}
            />
          </Row>
          <Row>
            {
              dataIndex === '' && dataIndexDuplicar === '' ? (
                <Col sm={6}>
                  <div className="form-group">
                    <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX_ULTIMA')}</label>
                    <input type="text" value={indexAnadidos.length > 0 ? (indexAnadidos[0].index): ('-')} disabled="disabled" className="form-control"/>
                  </div>
                </Col>
              ) : null
            }
            <Field
              id="index"
              name="index"
              component={InputNumerical}
              numDecimales={0}
              colSm={6}
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX') + '*'}
              validate={required}
            />
          </Row>
          <Row>
            {
                dataIndex === '' && dataIndexDuplicar === '' ? (
                  <Col sm={6}>
                    <div className="form-group">
                      <label>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO_ULTIMO')}</label>
                      <input type="text" value={indexAnadidos.length > 0 ? (indexAnadidos[0].nivelGenetico): ('-')} disabled="disabled" className="form-control"/>
                    </div>
                  </Col>
                ) : null
              }
              {/* <Field
                id="nivelGenetico"
                name="nivelGenetico"
                component={InputText}
                maxLength={true}
                valorMaxLength={20}
                colSm={6}
                controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')}
              /> */}
              <Field
                colSm={6}
                id="idNivelGenetico"
                name="idNivelGenetico"
                component={InputSelect}
                options={comboNivelGeneticoGeneral}
                valueKey="value"
                labelKey="label"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.NIVEL_GENETICO')}
                customClass='nivelGenetico'
                noPredeterminado={false}
                isClearable={false}
              />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaIndex.bind(this)) : null}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalIndex}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalIndex',
})(ModalIndex))
import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import InputTime from '../comun/form/InputTime'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosFosoPage from '../../containers/foso/FiltrosFosoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import { date_formatter, date_formatter_tabla, number_formatter, dateTimeZones } from '../../util/formatFunctions'
import './foso.scss'
import { required } from '../../util/validationFunctions'
import { permisos as permisosConstant } from '../../constants/permisos'

class Foso extends Component {
  constructor() {
    super()
    this.state = {
      analizado: false,
      seleccionarOperario: true,
      horaInicio: '',
      horaFinExtraccion: '',
      fecha: '',
      verraco: ''
    }
  }

  UNSAFE_componentWillMount() {
    document.title = this.props.t('MENU.EXTRACCIONES.FOSO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarFoso({})
    this.props.actions.comboOperarioActivo()
    this.props.actions.comboVerraco()
    this.props.actions.comboUbicacion()
    let fechaHora = dateTimeZones(this.props.auth.zonaHorariaString)
    let hour = fechaHora.getHours()
    let minute = fechaHora.getMinutes()
    let second = fechaHora.getSeconds()
    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    let horaFinExtraccion = hour + ':' + minute
    this.props.change('horaExtraccion', horaFinExtraccion)
    this.props.change('horaInicioExtraccion', null)
    this.props.change('fechaExtraccion', dateTimeZones(this.props.auth.zonaHorariaString))
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    /*if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24'){
      this.setState({fecha: day + '/' + month + '/' + year})
    } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24'){
      this.setState({fecha: month + '/' + day + '/' + year})
    } else if (this.props.auth.formaFechaHora === 'year/month/day/12' || this.props.auth.formaFechaHora === 'year/month/day/24'){
      this.setState({fecha: year + '/' + month + '/' + day})
    } else if (this.props.auth.formaFechaHora === 'year/day/month/12' || this.props.auth.formaFechaHora === 'year/day/month/24'){
      this.setState({fecha: year + '/' + day + '/' + month})
    }*/
    this.setState({ fecha: year + '-' + month + '-' + day })
    

  }

  componentDidUpdate(prevProps) {
    if (this.props.dataExtraccion !== prevProps.dataExtraccion) {
      if (Object.keys(this.props.dataExtraccion).length > 0) {
        this.setState({ analizado: this.props.dataExtraccion.analizado })
        if (this.props.dataExtraccion.nombreOperarioColecta !== null) {
          this.setState({ seleccionarOperario: false })
        } else {
          this.setState({ seleccionarOperario: true })
        }
      }
    }

    if (this.props.dataDatosVerraco !== prevProps.dataDatosVerraco) {
      if (Object.keys(this.props.dataDatosVerraco).length > 0) {
        this.setState({ analizado: false })
        // setteando valores cuando se selecciona verraco
        this.props.change('verraco', { value: this.props.dataDatosVerraco.verraco.idVerraco, label: this.props.dataDatosVerraco.verraco.codVerraco });

        let tiempoMontaSeleccionado = this.props.combos.comboTiempoMonta.find((option) => option.value === this.props.dataDatosVerraco.verraco.tiempoMonta);
        // si no hay opcion seleccionada, forzamos null para que haya un cambio a vacio
        if (tiempoMontaSeleccionado === undefined) {
          tiempoMontaSeleccionado = {};
          tiempoMontaSeleccionado.value = null;
          tiempoMontaSeleccionado.label = null;
        }
        this.props.change('tiempoMontaCombo', { value: tiempoMontaSeleccionado.value, label: this.props.t(tiempoMontaSeleccionado.label) });        
        if (this.props.dataDatosVerraco.ubicacion != null) {
          this.props.change('ubicacion', this.props.dataDatosVerraco.ubicacion);
        }
        if (this.props.dataDatosVerraco.nombreOperarioColecta !== null) {
          this.setState({ seleccionarOperario: false })
        } else {
          this.setState({ seleccionarOperario: true })
        }
      }
    }

    if (Object.keys(this.props.formFoso).length > 0) {
      if (Object.keys(this.props.formFoso.values).length > 0) {
        if (this.props.formFoso.values.horaInicioExtraccion && this.props.formFoso.values.horaExtraccion && this.props.formFoso.values.horaInicioExtraccion !== prevProps.formFoso.values.horaInicioExtraccion) {
          let fecha1 = this.props.formFoso.values.horaInicioExtraccion.split(':')
          let fecha1Minutos = (parseInt(fecha1[0], 10) * 60) + parseInt(fecha1[1], 10)
          let fecha2 = this.props.formFoso.values.horaExtraccion.split(':')
          let fecha2Minutos = (parseInt(fecha2[0], 10) * 60) + parseInt(fecha2[1], 10)
          let diferenciaHoras = fecha2Minutos - fecha1Minutos
          if (!isNaN(diferenciaHoras)) {
            this.props.change('tiempoMonta', diferenciaHoras)
          } else {
            this.props.change('tiempoMonta', null)
          }
        }
        if (this.props.formFoso.values.horaExtraccion && this.props.formFoso.values.horaInicioExtraccion && this.props.formFoso.values.horaExtraccion !== prevProps.formFoso.values.horaExtraccion) {
          let fecha1 = this.props.formFoso.values.horaInicioExtraccion.split(':')
          let fecha1Minutos = (parseInt(fecha1[0], 10) * 60) + parseInt(fecha1[1], 10)
          let fecha2 = this.props.formFoso.values.horaExtraccion.split(':')
          let fecha2Minutos = (parseInt(fecha2[0], 10) * 60) + parseInt(fecha2[1], 10)
          let diferenciaHoras = fecha2Minutos - fecha1Minutos
          if (!isNaN(diferenciaHoras)) {
            this.props.change('tiempoMonta', diferenciaHoras)
          } else {
            this.props.change('tiempoMonta', null)
          }
        }
      }
    }
  }

  guardarFoso(values) {
    values.orden = this.props.dataDatosVerraco.orden
    values.idVerraco = this.props.dataDatosVerraco.verraco.idVerraco
    values.idOperarioColecta = this.props.dataDatosVerraco.operarioColecta !== null ? this.props.dataDatosVerraco.operarioColecta : values.operarioColecta.value
    values.codigoAnalisis = this.props.codigoAnalisis.codigoAnalisis
    this.props.actions.guardarFoso(values)
  }

  modificarFoso(values) {
    values.idOperarioColecta = values.operarioColecta.value
    this.props.actions.modificarFoso(values)
  }

  eliminarFoso(values) {
    values.idAnalisis = this.props.dataExtraccion.idAnalisis
    this.props.actions.eliminarFoso(values)
  }

  quitarCeldaSeleccionada(id) {
    if (id.value !== undefined) {
      var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function (el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function (eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
        })
      })
      this.setState({ verraco: id })
      /*if (this.props.formFoso.values.fechaExtraccion) {
        let date = new Date(this.props.formFoso.values.fechaExtraccion)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24'){
          this.setState({fecha: day + '/' + month + '/' + year})
        } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24'){
          this.setState({fecha: month + '/' + day + '/' + year})
        } else if (this.props.auth.formaFechaHora === 'year/month/day/12' || this.props.auth.formaFechaHora === 'year/month/day/24'){
          this.setState({fecha: year + '/' + month + '/' + day})
        } else if (this.props.auth.formaFechaHora === 'year/day/month/12' || this.props.auth.formaFechaHora === 'year/day/month/24'){
          this.setState({fecha: year + '/' + day + '/' + month})
        }
      }*/
      this.props.actions.obtenerDatosVerraco(id.value, this.state.fecha)
    }
  }

  seleccionarFecha(fecha) {
    var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function (el) {
      var elemstd = el.getElementsByTagName('td');
      [].forEach.call(elemstd, function (eltd) {
        eltd.classList.remove("fila-seleccionada-foso");
      })
    })
    this.setState({ fecha: fecha })
    if (this.state.verraco !== '') {
      this.props.actions.obtenerDatosVerraco(this.state.verraco.value, fecha)
    }
  }

  imprimirEtiqueta(values){  
    console.log(values)
    //console.log('props->'+this.props.dataDatosVerraco.verraco.idVerraco)
    //console.log('value->'+values.verraco.value)
    //console.log('idverraco'+values.idVerraco)
    //values.idVerraco = this.props.dataDatosVerraco.verraco.idVerraco !== null ? this.props.dataDatosVerraco.verraco.idVerraco : values.idVerraco
    //values.idVerraco = values.idVerraco
    //values.idOperarioColecta = this.props.dataDatosVerraco.operarioColecta !== null ? this.props.dataDatosVerraco.operarioColecta : values.operarioColecta.value

    values.idVerraco = values.verraco.value ? values.verraco.value : values.verraco[0].value
    values.idOperarioColecta = values.operarioColecta.value

    let date = new Date(values.fechaExtraccion)   
    let dateFormater = date_formatter(values.fechaExtraccion, this.props.auth.formaFechaHora, false)    
    values.fechaFormateada = dateFormater    
    this.props.actions.imprimirEtiquetaZebra(values)
  }

  render() {
    const {
      t, numeroRegistrosPorPagina, paginaActual, checkedFiltrosFoso, listFoso, handleSubmit, dataExtraccion,
      combos: { comboOperarioActivo, comboVerraco, comboUbicacion }
    } = this.props

    let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
    let arrayAntes = []
    let arrayDespues = []
    for (var i = 1; i < 7 / 2; i++) {
      // dias antes de hoy
      let HaceUnaSemana = new Date(fecha.getTime() - (24 * 60 * 60 * 1000) * i)
      let fechaMontadaDespues = HaceUnaSemana.getFullYear() + "-" + parseInt(HaceUnaSemana.getMonth() + 1, 10) + "-" + HaceUnaSemana.getDate()
      arrayAntes.push(date_formatter_tabla(fechaMontadaDespues, this.props.auth.formaFechaHora, false))

      // dias despues de hoy
      let MasUnaSemana = new Date(fecha.getTime() + (24 * 60 * 60 * 1000) * i)
      let fechaMontadaAntes = MasUnaSemana.getFullYear() + "-" + parseInt(MasUnaSemana.getMonth() + 1, 10) + "-" + MasUnaSemana.getDate()
      arrayDespues.push(date_formatter_tabla(fechaMontadaAntes, this.props.auth.formaFechaHora, false))
    }
    let diasCalendario = arrayAntes.reverse() + ',' + date_formatter_tabla(fecha, this.props.auth.formaFechaHora, false) + ',' + arrayDespues
    let calendario = diasCalendario.split(',')
    const columVerraco = [{ id: 'verraco', name: t('PLANING.TABLA.VERRACO') }]
    const childrenCalendario = calendario.map((dia) => {
      return { id: dia, name: dia }
    })

    const listadoFoso = listFoso.map((foso) => {
      foso.fechas.verraco = foso.verraco
      foso.fechas.idVerraco = foso.idVerraco
      return foso.fechas
    })

    let comboTiempoMonta = this.props.combos.comboTiempoMonta
    comboTiempoMonta = comboTiempoMonta.map((tiempoMonta) => {
      return { ...tiempoMonta, label: t(tiempoMonta.label) }
    })
    // TABLA VERRACO
    const table = {
      id: 'fosoTable1',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: true,
      esFoso: true,
      columns: columVerraco.concat(childrenCalendario),
      rows: listadoFoso,
      leyenda: [
        { name: t('PLANING.EXTRACCIONES_FUTURAS'), color: '#1c8cdc' },
        { name: t('PLANING.EXTRACCIONES_PASADAS'), color: '#dc1c1c' },
        { name: t('PLANING.EXTRACCIONES_REGISTRADAS'), color: '#95cc64' },
        { name: t('PLANING.DIA_HOY'), color: '#b5b5b5' }
      ],
      initialValues: {}
    }

    return (
      <div>
        <SimpleModalPage />
        <MensajeAlertaPage />
        <h2 className="page-title">{t('FOSO.TITLE')}</h2>
        <FiltrosFosoPage />
        <div className="clearfix"></div>
        <Row>
          <Col sm={8}>
            <SimpleTablePage {...table}></SimpleTablePage>
          </Col>
          <Col sm={4} style={{ borderLeft: '1px solid #eee', padding: 0 }}>
            <Col sm={12}>
              <h3 style={{ fontWeight: 'bold' }}>{t('FOSO.FORM.TITLE')}</h3>
              {
                Object.keys(dataExtraccion).length > 0 && (
                  <p className="codigo-extraccion"><b>{t('FOSO.CODIGO_EXTRACCION')}: </b>{dataExtraccion.codigo}</p>
                )
              }
            </Col>
            <Field
              colSm={6}
              id="verraco"
              name="verraco"
              controlLabel={t('FOSO.FORM.VERRACO') + '*'}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='verraco-foso'
              isClearable={false}
              disabled={this.state.analizado === true ? true : false}
              onInputChange={(id) => this.quitarCeldaSeleccionada(id)}
              validate={required}
            />
            <Field
              colSm={6}
              id="operarioColecta"
              name="operarioColecta"
              controlLabel={t('FOSO.FORM.OPERARIO') + '*'}
              component={InputSelect}
              options={comboOperarioActivo}
              valueKey="value"
              labelKey="label"
              isClearable={false}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
            />
            <Field
              colSm={6}
              id="ubicacion"
              name="ubicacion"
              controlLabel={t('COMUN.COMBOS.TIPO_OTROS.UBICACION') + '*'}
              component={InputText}
              isClearable={false}
              disabled={true}
            />
            <Field
              colSm={6}
              id="tiempoMontaCombo"
              name="tiempoMontaCombo"
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TIEMPO_MONTA')}
              component={InputSelect}
              options={comboTiempoMonta}
              valueKey="value"
              labelKey="label"
              isClearable={true}
              disabled={false}
            />

            <Field
              colSm={6}
              id="fechaExtraccion"
              name="fechaExtraccion"
              controlLabel={t('FOSO.FORM.FECHA_EXTRACCION') + '*'}
              component={InputDatePicker}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
              onInputChange={(value) => this.seleccionarFecha(value)}
            />
            <Field
              colSm={6}
              id="volumen"
              name="volumen"
              controlLabel={t('FOSO.FORM.VOLUMEN')}
              component={InputNumerical}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={6}
              id="horaInicioExtraccion"
              name="horaInicioExtraccion"
              controlLabel={t('FOSO.FORM.HORA')}
              component={InputTime}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={6}
              id="horaExtraccion"
              name="horaExtraccion"
              controlLabel={t('FOSO.FORM.HORA_FIN') + '*'}
              component={InputTime}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
            />
            <Field
              colSm={6}
              id="tiempoMonta"
              name="tiempoMonta"
              controlLabel={t('FOSO.FORM.TIEMPO_MONTA')}
              component={InputNumerical}
              numDecimales={0}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={6}
              id="numeroMonta"
              name="numeroMonta"
              controlLabel={t('FOSO.FORM.NUMERO_MONTA')}
              component={InputNumerical}
              numDecimales={0}
              disabled={true}
            />
            {this.state.analizado === false &&
              <Field
                colSm={12}
                id="ultimasObservacionFoso"
                name="ultimasObservacionFoso"
                controlLabel={t('FOSO.FORM.ULTIMAS_OBSERVACIONES')}
                component={InputTextArea}
                disabled={true}
              />
            }
            <Field
              colSm={12}
              id="observacionFoso"
              name="observacionFoso"
              controlLabel={t('FOSO.FORM.OBSERVACIONES')}
              component={InputTextArea}
              disabled={this.state.analizado === true ? true : false}
            />
            <div className="clearfix"></div>
            <Col sm={12} style={{ textAlign: 'right' }}>    
              {
                this.state.analizado === false ? (
                  Object.keys(this.props.dataDatosVerraco).length > 0 ? (
                    <Button
                      type="button"
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && handleSubmit(this.imprimirEtiqueta.bind(this))}
                      className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) ? ' disabled' : '')}
                      style={{ float: 'left' }}
                    >{t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.IMPRIMIR_ETIQUETA')}</Button>
                  ) : (
                    Object.keys(this.props.dataExtraccion).length > 0 ? (
                      <Button
                        type="button"
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && handleSubmit(this.imprimirEtiqueta.bind(this))}
                        className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) ? ' disabled' : '')}
                        style={{ float: 'left' }}
                      >{t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.IMPRIMIR_ETIQUETA')}</Button>
                    ) : null
                  )
                ) : null                
              }          
              {
                Object.keys(this.props.dataExtraccion).length > 0 && this.state.analizado === false ? (
                  <Button
                    type="button"
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && handleSubmit(this.eliminarFoso.bind(this))}
                    className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) ? ' disabled' : '')}
                  >{t('FOSO.FORM.ELIMINAR')}</Button>
                ) : null
              }
              {
                this.state.analizado === false ? (
                  Object.keys(this.props.dataDatosVerraco).length > 0 ? (
                    <Button
                      type="button"
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && handleSubmit(this.guardarFoso.bind(this))}
                      className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) ? ' disabled' : '')}
                    >{t('FOSO.FORM.GUARDAR')}</Button>
                  ) : (
                    Object.keys(this.props.dataExtraccion).length > 0 ? (
                      <Button
                        type="button"
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && handleSubmit(this.modificarFoso.bind(this))}
                        className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) ? ' disabled' : '')}
                      >{t('FOSO.FORM.GUARDAR')}</Button>
                    ) : null
                  )
                ) : null
              }              
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formFoso',
})(Foso))
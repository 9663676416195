import actionTypes from '../../constants/actions/seguimiento/seguimiento'

export function fetchSeguimientos(filtros){
  return {
    type: actionTypes.FETCH_SEGUIMIENTOS,
    filtros
  }
}

export function fetchSeguimientosSuccess(mainList, filtros){
  return {
    type: actionTypes.FETCH_SEGUIMIENTOS_SUCCESS,
    mainList,
    filtros
  }
}

export function seleccionarTodosSeguimiento(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_SEGUIMIENTO
  }
}

export function deSeleccionarTodosSeguimiento(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_SEGUIMIENTO
  }
}

export function openModalListadoSeguimiento(idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_SEGUIMIENTO,
    idAnalisis,
    idPool
  }
}

export function openModalListadoSeguimientoSuccess(list, conservacion, codigo, idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_SEGUIMIENTO_SUCCESS,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool
  }
}

export function closeModalListadoSeguimiento(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_SEGUIMIENTO
  }
}

export function closeModalListadoSeguimientoSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_SEGUIMIENTO_SUCCESS
  }
}

export function seleccionarTodosModalSeguimiento(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_SEGUIMIENTO
  }
}

export function deSeleccionarTodosModalSeguimiento(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_SEGUIMIENTO
  }
}

export function deleteSeguimiento(row){
  return {
    type: actionTypes.DELETE_SEGUIMIENTO,
    row
  }
}

export function deleteSeleccionSeguimiento(list){
  return {
    type: actionTypes.DELETE_SELECCION_SEGUIMIENTO,
    list
  }
}

export function crearCsvSeguimiento(list){
  return {
    type: actionTypes.CREATE_CSV_SEGUIMIENTO,
    list
  }
}

export function imprimirPdfSeguimiento(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_SEGUIMIENTO,
    list
  }
}

export function openModalFormSeguimiento(idSeguimiento, duplicarSeguimiento = false, isPool = false, idAnalisis = null, idPool = null){
  return {
    type: actionTypes.OPEN_MODAL_FORM_SEGUIMIENTO,
    idSeguimiento,
    duplicarSeguimiento,
    isPool,
    idAnalisis,
    idPool
  }
}

export function openModalFormSeguimientoSuccess(data){
  return {
    type: actionTypes.OPEN_MODAL_FORM_SEGUIMIENTO_SUCCESS,
    data
  }
}

export function guardarModalFormSeguimiento(values){
  return {
    type: actionTypes.GUARDAR_MODAL_FORM_SEGUIMIENTO,
    values
  }
}

export function guardarModalFormSeguimientoSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_FORM_SEGUIMIENTO_SUCCESS
  }
}

export function closeModalFormSeguimiento(){
  return {
    type: actionTypes.CLOSE_MODAL_FORM_SEGUIMIENTO
  }
}

export function closeModalFormSeguimientoSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_FORM_SEGUIMIENTO_SUCCESS
  }
}

export function obtenerYGuardarResultadoSistemaAnalisisSeguimiento(tipoAnalisis){
  return {
    type: actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_SEGUIMIENTO,
    tipoAnalisis
  }
}

export function abrirModalNuevoSeguimiento () {
  return {
    type: actionTypes.ABRIR_MODAL_NUEVO_SEGUIMIENTO
  }
}

export function closeModalNuevoSeguimiento () {
  return {
    type: actionTypes.CLOSE_MODAL_NUEVO_SEGUIMIENTO
  }
}


export function fetchFiltrarModalNuevoSeguimiento (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_NUEVO_SEGUIMIENTO,
    filtros
  }
}


export function fetchFiltrarModalNuevoSeguimientoSuccess (list, filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_NUEVO_SEGUIMIENTO_SUCCESS,
    list, 
    filtros
  }
}


export function obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento(){
  return {
    type: actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_MULTISEGUIMIENTO
  }
}


export default {
  fetchSeguimientos,
  fetchSeguimientosSuccess,
  seleccionarTodosSeguimiento,
  deSeleccionarTodosSeguimiento,
  openModalListadoSeguimiento,
  openModalListadoSeguimientoSuccess,
  closeModalListadoSeguimiento,
  closeModalListadoSeguimientoSuccess,
  seleccionarTodosModalSeguimiento,
  deSeleccionarTodosModalSeguimiento,
  deleteSeguimiento,
  deleteSeleccionSeguimiento,
  crearCsvSeguimiento,
  imprimirPdfSeguimiento,
  openModalFormSeguimiento,
  openModalFormSeguimientoSuccess,
  guardarModalFormSeguimiento,
  guardarModalFormSeguimientoSuccess,
  closeModalFormSeguimiento,
  closeModalFormSeguimientoSuccess,
  obtenerYGuardarResultadoSistemaAnalisisSeguimiento,
  abrirModalNuevoSeguimiento,
  closeModalNuevoSeguimiento,
  fetchFiltrarModalNuevoSeguimiento,
  fetchFiltrarModalNuevoSeguimientoSuccess,
  obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento
}

import actionTypes from '../../constants/actions/lineaGenetica/lineaGenetica'

export function fetchLineaGenetica () {
  return {
    type: actionTypes.FETCH_LINEA_GENETICA
  }
}

export function fetchLineaGeneticaSuccess (list) {
  return {
    type: actionTypes.FETCH_LINEA_GENETICA_SUCCESS,
    list
  }
}

export function submitNuevaLineaGenetica (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_LINEA_GENETICA,
    values
  }
}

export function submitNuevaLineaGeneticaSuccess (insercionLinea) {
  return {
    type: actionTypes.SUBMIT_NUEVA_LINEA_GENETICA_SUCCESS,
    insercionLinea
  }
}

export function recuperarDatosModal (idLinea) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL,
    idLinea
  }
}

export function recuperarDatosModalSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_SUCCESS,
    data
  }
}

export function abrirModalVacio () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO
  }
}

export function editarLineaGenetica (values) {
  return {
    type: actionTypes.EDITAR_LINEA_GENETICA,
    values
  }
}

export function editarLineaGeneticaSuccess (edicionLinea) {
  return {
    type: actionTypes.EDITAR_LINEA_GENETICA_SUCCESS,
    edicionLinea
  }
}

export function duplicarDatosModal (idLinea) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL,
    idLinea
  }
}

export function duplicarDatosModalSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstado (idLinea, cambiarEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO,
    idLinea,
    cambiarEstado
  }
}

export function cambiarEstadoSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SUCCESS,
    dataEstado
  }
}

export function onDelete (idLinea) {
  return {
    type: actionTypes.ON_DELETE,
    idLinea
  }
}

export function onDeleteSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarLinea (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_LINEA,
    values
  }
}

export function fetchFiltrarLineaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_LINEA_SUCCESS,
    filtros
  }
}

export function seleccionarTodosLineaGenetica(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_LINEA_GENETICA
  }
}

export function deSeleccionarTodosLineaGenetica(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_LINEA_GENETICA
  }
}

export function cambiarEstadoSeleccion(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION,
    list
  }
}

export function deleteSeleccion(list){
  return {
    type: actionTypes.DELETE_SELECCION,
    list
  }
}

export function crearCsv(list){
  return {
    type: actionTypes.CREATE_CSV,
    list
  }
}

export function crearCsvSuccess(datosExcelLinea){
  return {
    type: actionTypes.CREATE_CSV_SUCCESS,
    datosExcelLinea
  }
}

export function imprimirPdfLinea(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_LINEA,
    list
  }
}

export function imprimirPdfLineaSuccess(datosPdfLinea){
  return {
    type: actionTypes.IMPRIMIR_PDF_LINEA_SUCCESS,
    datosPdfLinea
  }
}

export function guardarYnuevo(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO,
    values
  }
}

export function openModalLineaGenetica(){
  return {
    type: actionTypes.OPEN_MODAL_LINEA_GENETICA
  }
}

export function closeModalLineaGenetica(){
  return {
    type: actionTypes.CLOSE_MODAL_LINEA_GENETICA
  }
}

export default {
  fetchLineaGenetica,
  fetchLineaGeneticaSuccess,
  submitNuevaLineaGenetica,
  submitNuevaLineaGeneticaSuccess,
  recuperarDatosModal,
  recuperarDatosModalSuccess,
  abrirModalVacio,
  editarLineaGenetica,
  editarLineaGeneticaSuccess,
  duplicarDatosModal,
  duplicarDatosModalSuccess,
  cambiarEstado,
  cambiarEstadoSuccess,
  onDelete,
  onDeleteSuccess,
  fetchFiltrarLinea,
  seleccionarTodosLineaGenetica,
  deSeleccionarTodosLineaGenetica,
  cambiarEstadoSeleccion,
  deleteSeleccion,
  fetchFiltrarLineaSuccess,
  crearCsv,
  crearCsvSuccess,
  guardarYnuevo,
  imprimirPdfLinea,
  imprimirPdfLineaSuccess,
  openModalLineaGenetica,
  closeModalLineaGenetica
}
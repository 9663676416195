export default {
  FETCH_REGISTRO_ALIMENTACION: 'FETCH_REGISTRO_ALIMENTACION',
  FETCH_REGISTRO_ALIMENTACION_SUCCESS: 'FETCH_REGISTRO_ALIMENTACION_SUCCESS',
  ABRIR_MODAL_VACIO_REGISTRO_ALIMENTACION: 'ABRIR_MODAL_VACIO_REGISTRO_ALIMENTACION',
  SELECCIONAR_TODOS_REGISTRO_ALIMENTACION: 'SELECCIONAR_TODOS_REGISTRO_ALIMENTACION',
  DESELECCIONAR_TODOS_REGISTRO_ALIMENTACION: 'DESELECCIONAR_TODOS_REGISTRO_ALIMENTACION',
  SUBMIT_NUEVO_REGISTRO_ALIMENTACION: 'SUBMIT_NUEVO_REGISTRO_ALIMENTACION',
  RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION: 'RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION',
  RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS',
  EDITAR_REGISTRO_ALIMENTACION: 'EDITAR_REGISTRO_ALIMENTACION',
  DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION: 'DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION',
  DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS: 'DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS',
  CAMBIAR_ESTADO_REGISTRO_ALIMENTACION: 'CAMBIAR_ESTADO_REGISTRO_ALIMENTACION',
  ON_DELETE_REGISTRO_ALIMENTACION: 'ON_DELETE_REGISTRO_ALIMENTACION',
  CAMBIAR_ESTADO_SELECCION_REGISTRO_ALIMENTACION: 'CAMBIAR_ESTADO_SELECCION_REGISTRO_ALIMENTACION',
  DELETE_SELECCION_REGISTRO_ALIMENTACION: 'DELETE_SELECCION_REGISTRO_ALIMENTACION',
  GUARDAR_Y_NUEVO_REGISTRO_ALIMENTACION: 'GUARDAR_Y_NUEVO_REGISTRO_ALIMENTACION',
  FETCH_FILTRAR_REGISTRO_ALIMENTACION: 'FETCH_FILTRAR_REGISTRO_ALIMENTACION',
  FETCH_FILTRAR_REGISTRO_ALIMENTACION_SUCCESS: 'FETCH_FILTRAR_REGISTRO_ALIMENTACION_SUCCESS',
  ABRIR_MODAL_VERRACO_REGISTRO_ALIMENTACION: 'ABRIR_MODAL_VERRACO_REGISTRO_ALIMENTACION',
  CERRAR_MODAL_VERRACO_REGISTRO_ALIMENTACION: 'CERRAR_MODAL_VERRACO_REGISTRO_ALIMENTACION',
  FETCH_VERRACOS_REGISTRO_ALIMENTACION: 'FETCH_VERRACOS_REGISTRO_ALIMENTACION',
  FETCH_VERRACOS_REGISTRO_ALIMENTACION_SUCCESS: 'FETCH_VERRACOS_REGISTRO_ALIMENTACION_SUCCESS',
  SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION: 'SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION',
  DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION: 'DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION',
  ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION: 'ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION',
  ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS: 'ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS',
  FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION: 'FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION',
  FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS: 'FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS',
  SELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION: 'SELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION',
  DESELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION: 'DESELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION',
  DELETE_SELECCION_VERRACOS_REGISTRO_ALIMENTACION: 'DELETE_SELECCION_VERRACOS_REGISTRO_ALIMENTACION',
  ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION: 'ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION',
  ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION_SUCCESS: 'ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION_SUCCESS',
  RESETEAR_VERRACOS: 'RESETEAR_VERRACOS',
  CREATE_CSV_REGISTRO_ALIMENTACION: 'CREATE_CSV_REGISTRO_ALIMENTACION',
  CREATE_CSV_REGISTRO_ALIMENTACION_SUCCESS: 'CREATE_CSV_REGISTRO_ALIMENTACION_SUCCESS',
  IMPRIMIR_PDF_REGISTRO_ALIMENTACION: 'IMPRIMIR_PDF_REGISTRO_ALIMENTACION',
  IMPRIMIR_PDF_REGISTRO_ALIMENTACION_SUCCESS: 'IMPRIMIR_PDF_REGISTRO_ALIMENTACION_SUCCESS',
  OPEN_MODAL_REGISTRO_ALIMENTACION: 'OPEN_MODAL_REGISTRO_ALIMENTACION',
  CLOSE_MODAL_REGISTRO_ALIMENTACION: 'CLOSE_MODAL_REGISTRO_ALIMENTACION',
  IMPRIMIR_INFORME_REGISTRO_ALIMENTACION: 'IMPRIMIR_INFORME_REGISTRO_ALIMENTACION'
}
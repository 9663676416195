import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalGuardarSeleccionInformePersonalizado from '../../components/informes/ModalGuardarSeleccionInformePersonalizado'
import {cerrarModalGuardarSeleccion, submitGuardarSeleccionInformePersonalizado} from '../../actions/informes/informes'

export function mapStateToProps (state) {
  return {
    ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalGuardarSeleccion,
      submitGuardarSeleccionInformePersonalizado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGuardarSeleccionInformePersonalizado))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import { Delete, Launch, Print, FilterList, AddCircleOutline } from '@material-ui/icons'
import {date_formatter, dateTimeZones, date_parser} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosControlMorfologico extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      tipo: 1,
      fitrosSeleccionados: []
    }
    this.fetchControlMorfologico = this.fetchControlMorfologico.bind(this)
    this.crearCsvControlMorfologico = this.crearCsvControlMorfologico.bind(this)
    this.imprimirPdfControlMorfologico = this.imprimirPdfControlMorfologico.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.initialize({
      fechaDesde: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora),
      /*tipo: {value: 1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')}*/
    })
    this.props.change('periodo', {value: 15, label: this.props.t('PLANING.15_DIAS')})
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-modal-control-morfologico', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosControlMorfologico()
          }
        }
      }
    }
  }

  fetchControlMorfologico(values){
    this.props.actions.fetchControlMorfologico(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosControlMorfologico()
    } else {
      this.props.actions.deSeleccionarTodosControlMorfologico()
    }
  }

  deleteSeleccionControlMorfologico(){
    const itemsSeleccion = this.props.mainList.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionControlMorfologico(list)
  }

  crearCsvControlMorfologico(){
    this.props.actions.crearCsvControlMorfologico(this.props.filtros)
  }

  imprimirPdfControlMorfologico(){
    this.props.actions.imprimirPdfControlMorfologico(this.props.filtros)
  }

  abrirModalInicio(){
    this.props.actions.abrirModalInicio()
    this.props.actions.fetchFiltrarModalInicio({periodo: {value: 15}})
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchControlMorfologico(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
    const {
      t, handleSubmit, auth: { formaFechaHora }, filtros = {},
      combos: { comboVerraco, comboLineaGenetica, comboRaza }
    } = this.props
    const tKey = 'PLAN_SANITARIO.CONTROL_MORFOLOGICO.'

    let comboVerracoSiNo = this.props.combos.comboVerracoSiNo
    comboVerracoSiNo = comboVerracoSiNo.map((verracoSiNoItem) => {
      return {...verracoSiNoItem, label: t(`COMUN.COMBOS.${verracoSiNoItem.label}`)}
    })

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    comboTipoControlSeguimiento = comboTipoControlSeguimiento.map((tipoControlSeguimientoItem) => {
      return {...tipoControlSeguimientoItem, label: t(`COMUN.COMBOS.${tipoControlSeguimientoItem.label}`)}
    })

    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t(tKey + 'FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchControlMorfologico)}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchControlMorfologico)}
            />
            <Field
              colSm={2}
              id="linea"
              name="linea"
              controlLabel={t(tKey + 'FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchControlMorfologico)}
            />
            <Field
              colSm={2}
              id="tipoControl"
              name="tipoControl"
              controlLabel={t(tKey + 'FILTROS.TIPO_CONTROL')}
              component={InputSelect}
              options={comboTipoControlSeguimiento}
              valueKey="value"
              labelKey="label"
              noPredeterminado={false}
              onInputChange={handleSubmit(this.fetchControlMorfologico)}
            />
            <Fields
              names={['fechaDesde', 'fechaHasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaDesde"
              nameTo="fechaHasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchControlMorfologico)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.verraco && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>),
                    (filtros.linea && <span key="filtros.linea"  className="badge-dark">{t(tKey + 'FILTROS.LINEA_GENETICA') + ': ' + filtros.linea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('linea')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza" className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>),
                    (filtros.tipoControl && <span key="filtros.tipoControl" className="badge-dark">{t(tKey + 'FILTROS.TIPO_CONTROL') + ': ' + filtros.tipoControl.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoControl')}> x </button></span>),
                    (filtros.fechaDesde && filtros.fechaHasta && filtros.fechaDesde !== null && filtros.fechaHasta !== null) ? (
                      <span key="filtros.fechaDesdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaDesde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaHasta')}> x </button></span>
                    ) : (filtros.fechaDesde && filtros.fechaDesde !== null) ? (
                      <span key="filtros.fechaDesde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaDesde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaDesde')}> x </button></span>
                    ) : (filtros.fechaHasta && filtros.fechaHasta !== null) && (
                      <span key="filtros.fechaHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaHasta')}> x </button></span>
                    )
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-control-morfologico"
                    name="seleccionar-todos-modal-control-morfologico"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionControlMorfologico()}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                    </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvControlMorfologico()}>
                  <Launch></Launch> {t(tKey + 'FILTROS.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfControlMorfologico()}>
                  <Print></Print> {t(tKey + 'FILTROS.IMPRIMIR')}
                </button>
                <button type="button" className="btn btn-primary nuevo" onClick={(e) => this.abrirModalInicio()}>
                  <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'controlMorfologico',
  initialValues: {
    fechaDesde: new Date()
  }
})(FiltrosControlMorfologico))

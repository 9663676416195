import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import Button from '../comun/button/Button'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'

class FiltrosModalDireccionesRuta extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false
    }
  }

  checkedFiltrosdDireccionesRuta (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalDireccionesRuta()
    } else {
      this.props.actions.deSeleccionarTodosModalDireccionesRuta()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-cliente-ruta', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalDireccionesRuta()
          }
        }
      }
    }
  }

  deleteSeleccionDireccionRuta(){
    const itemsSeleccion = this.props.listRutas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === false && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'idRuta': row.idRuta,
          'nombreRuta': row.nombreRuta,
          'transportista': row.transportista
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === false
    });
    this.props.actions.actualizarDatosRuta(list)
    this.props.actions.deSeleccionarTodosModalDireccionesRuta()
  }

  render () {
  const {
    t, handleSubmit, filtros, estadoPedido,
    actions: {abrirModalDireccionesRuta}
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="custom-check-w">
                {
                  estadoPedido !== 2 && estadoPedido !== 3 ? (
                    <Field
                      id="seleccionar-todos-cliente-ruta"
                      name="seleccionar-todos-cliente-ruta"
                      colSm={12}
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.checkedFiltrosdDireccionesRuta(value)}
                    />
                  ) : null
                }
              </div>
              <div id="block-options2" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionDireccionRuta()}>
                  <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                </button>
                <div className="barra-buttons-min"></div>
              </div>
              <div className="button-group buttons-min">
                <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary nuevo" onClick={abrirModalDireccionesRuta}>
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalDireccionesRuta',
})(FiltrosModalDireccionesRuta))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalAnadirMuestras from '../../components/panelControl/ModalAnadirMuestras'
import { cerrarModalAnadirMuestras, anadirMuestra, editMuestrasAnadidas, guardarYnuevoMuestras } from '../../actions/panelControl/panelControl'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.panelControl
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalAnadirMuestras,
      anadirMuestra,
      editMuestrasAnadidas,
      guardarYnuevoMuestras
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAnadirMuestras))
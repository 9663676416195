import actionTypes from '../../constants/actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'

export function initialState () {
  return {
    list: []
  }
}

export function fetchComboObservacionesEyaculadoEliminadoSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function submitNuevaComboObservacionesEyaculadoEliminadoSuccess (state, {comboObservacionesEyaculadoEliminado}) {
  return {
    ...state,
    comboObservacionesEyaculadoEliminado
  }
}

export function editarComboObservacionesEyaculadoEliminadoSuccess (state, {comboObservacionesEyaculadoEliminado}) {
  return {
    ...state,
    comboObservacionesEyaculadoEliminado
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS:
      return fetchComboObservacionesEyaculadoEliminadoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS:
      return recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS:
      return submitNuevaComboObservacionesEyaculadoEliminadoSuccess(state, action)
    case actionTypes.EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS:
      return editarComboObservacionesEyaculadoEliminadoSuccess(state, action)
    default:
      return state
  }
}
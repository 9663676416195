import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerraco from '../../../components/planSanitario/bioseguridadHigiene/ModalVerraco'
import {closeModal} from '../../../actions/common'
import {fetchVerraco, cerrarModalVerraco, seleccionarTodosModalVerraco, deSeleccionarTodosModalVerraco, anadirVerracoBioseguridad
  } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {comboVerraco, comboRaza, comboLineaGenetica} from '../../../actions/combos/combos'



export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchVerraco,
      cerrarModalVerraco,
      seleccionarTodosModalVerraco,
      deSeleccionarTodosModalVerraco,
      anadirVerracoBioseguridad,
      comboVerraco,
      comboRaza,
      comboLineaGenetica
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerraco))

import actionTypes from '../../constants/actions/seguimiento/seguimiento'

export function initialState () {
  return {
    mainList: [],
    filtros: {},
    checkedFiltrosSeguimiento: false,
    esApartadoPrincipal: false,
    list: [],
    conservacion: null,
    codigo: '',
    idAnalisis: null,
    idPool: null,
    showModalListadoSeguimiento: false,
    checkedFiltrosListadoSeguimiento: false,
    modalFormSeguimiento: {
      data: {},
      showModalFormSeguimiento: false
    },
    showModalNuevoSeguimiento: false,
    filtrosModalNuevoSeguimiento: [],
    listModalNuevoSeguimiento: []
  }
}

export function fetchSeguimientosSuccess (state, {mainList, filtros}) {
  return {
    ...state,
    mainList,
    filtros,
    esApartadoPrincipal: true
  }
}

export function seleccionarTodosSeguimiento (state) {
  return {
    ...state,
    checkedFiltrosSeguimiento: true
  }
}

export function deSeleccionarTodosSeguimiento (state) {
  return {
    ...state,
    checkedFiltrosSeguimiento: false
  }
}

export function openModalListadoSeguimientoSuccess (state, {list, conservacion, codigo, idAnalisis, idPool}) {
  return {
    ...state,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool,
    showModalListadoSeguimiento: true
  }
}

export function closeModalListadoSeguimientoSuccess (state) {
  return {
    ...initialState(),
    mainList: state.mainList,
    filtros: state.filtros,
    checkedFiltrosSeguimiento: state.checkedFiltrosSeguimiento,
    esApartadoPrincipal: state.esApartadoPrincipal
  }
}

export function seleccionarTodosModalSeguimiento (state) {
  return {
    ...state,
    checkedFiltrosListadoSeguimiento: true
  }
}

export function deSeleccionarTodosModalSeguimiento (state) {
  return {
    ...state,
    checkedFiltrosListadoSeguimiento: false
  }
}

export function openModalFormSeguimientoSuccess (state, {data}) {
  return {
    ...state,
    modalFormSeguimiento: {
      ...state.modalFormSeguimiento,
      data,
      showModalFormSeguimiento: true
    }
  }
}

export function guardarModalFormSeguimientoSuccess (state) {
  return {
    ...state,
    modalFormSeguimiento: {
      ...state.modalFormSeguimiento,
      showModalFormSeguimiento: false
    }
  }
}

export function closeModalFormSeguimientoSuccess (state) {
  return {
    ...state,
    modalFormSeguimiento: {
      ...state.modalFormSeguimiento,
      showModalFormSeguimiento: false
    }
  }
}

export function abrirModalNuevoSeguimiento (state) {
  return {
    ...state,
    showModalNuevoSeguimiento: true
  }
}

export function closeModalNuevoSeguimiento (state) {
  return {
    ...state,
    showModalNuevoSeguimiento: false
  }
}

export function fetchFiltrarModalNuevoSeguimientoSuccess (state, {list,filtros}) {
  return {
    ...state,
    listModalNuevoSeguimiento: list,
    filtrosModalNuevoSeguimiento: filtros
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_SEGUIMIENTOS_SUCCESS:
      return fetchSeguimientosSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_SEGUIMIENTO:
      return seleccionarTodosSeguimiento(state, action)
    case actionTypes.DESELECCIONAR_TODOS_SEGUIMIENTO:
      return deSeleccionarTodosSeguimiento(state, action)
    case actionTypes.OPEN_MODAL_LISTADO_SEGUIMIENTO_SUCCESS:
      return openModalListadoSeguimientoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_LISTADO_SEGUIMIENTO_SUCCESS:
      return closeModalListadoSeguimientoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_SEGUIMIENTO:
      return seleccionarTodosModalSeguimiento(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_SEGUIMIENTO:
      return deSeleccionarTodosModalSeguimiento(state, action)
    case actionTypes.OPEN_MODAL_FORM_SEGUIMIENTO_SUCCESS:
      return openModalFormSeguimientoSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_FORM_SEGUIMIENTO_SUCCESS:
      return guardarModalFormSeguimientoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_FORM_SEGUIMIENTO_SUCCESS:
      return closeModalFormSeguimientoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_NUEVO_SEGUIMIENTO:
      return abrirModalNuevoSeguimiento(state, action)
    case actionTypes.CLOSE_MODAL_NUEVO_SEGUIMIENTO:
      return closeModalNuevoSeguimiento(state, action)
    case actionTypes.FETCH_FILTRAR_MODAL_NUEVO_SEGUIMIENTO_SUCCESS:
      return fetchFiltrarModalNuevoSeguimientoSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import './Conectividad.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalConectividad extends Component {

  constructor () {
    super()
    this.state = {
      isCom: false,
      showIpParte1: true,
      showIpParte2: true,
      showIpParte3: true,
      showIpParte4: true,
    }
  }

  submitNuevaConectividad (values) {
    if(this.state.isCom){
      values.IP = values.ipParte1
    }else{
      values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
    }
    if (Object.keys(this.props.data).length > 0){
      this.props.actions.editarConectividad(values)
    } else {
      this.props.actions.submitNuevaConectividad(values)
    }
  }

  componentDidMount () {
    let valors = {activo: true}
    this.props.initialize(valors)
  }

  componentDidUpdate(prevProps) {
      if(this.props.data.idTipoMaquina && (this.props.data.idTipoMaquina.value == 4 || this.props.data.idTipoMaquina.value == 5) && !this.state.isCom){
        this.setState({isCom:true,showIpParte2:false,showIpParte3:false,showIpParte4:false})
      }

      if(this.props.data.idTipoMaquina && this.props.data.idTipoMaquina.value != 4 && this.props.data.idTipoMaquina.value != 5 && this.state.isCom){
        this.setState({isCom:false,showIpParte2:true,showIpParte3:true,showIpParte4:true})
      }
  }

  guardarYnuevoConectividad(values){
    if(this.state.isCom){
      values.IP = values.ipParte1
    }else{
      values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
    }
    this.props.actions.guardarYnuevoConectividad(values)
  }

  closeModalConectividad () {
    this.props.actions.closeModalConectividad()
    let valors = {activo: true}
    this.props.initialize(valors)
  }

  handleChangePeriferico(periferico){
    if(periferico.value == 4 || periferico.value == 5){
      this.setState({isCom:true,showIpParte2:false,showIpParte3:false,showIpParte4:false})
    }else{
      this.setState({isCom:false,showIpParte2:true,showIpParte3:true,showIpParte4:true})
    }
  }

  render () {
    const {
      t, handleSubmit, listConectividad, showModalConectividad, data, duplicar, actions: {recuperarDatosModalConectividad}
      } = this.props

    let comboTipoPerifericoConectividad = this.props.combos.comboTipoPerifericoConectividad
    comboTipoPerifericoConectividad = comboTipoPerifericoConectividad.map((tipoPeriferico) => {
      return {...tipoPeriferico, label: t(tipoPeriferico.label)}
    })

    return (
      <Modal show={showModalConectividad} onHide={() => this.closeModalConectividad()} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-conectividad" onSubmit={handleSubmit(this.submitNuevaConectividad.bind(this))}>
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('CONECTIVIDAD.MODAL.TITULO_EDITAR')
            ) : (
              t('CONECTIVIDAD.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idMaquina && (
            <ButtonChangeRecord list={listConectividad} idElemento="idMaquina" currentId={data.idMaquina} getNextRecord={recuperarDatosModalConectividad} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}><h3>{t('CONECTIVIDAD.MODAL.DATOS_PERIFERICO')}</h3></Col>
            <Field
              id="idTipoMaquina"
              name="idTipoMaquina"
              colSm={4}
              component={InputSelect}
              options={comboTipoPerifericoConectividad}
              valueKey="value"
              labelKey="label"
              controlLabel={t('CONECTIVIDAD.MODAL.TIPO_PERIFERICO') + '*'}
              customClass="nombre-conectividad"
              validate={required}
              isClearable={false}
              onInputChange={(periferico) => this.handleChangePeriferico(periferico)}
            />
            <Field
              id="nombre"
              name="nombre"
              colSm={4}
              component={InputText}
              controlLabel={t('CONECTIVIDAD.MODAL.NOMBRE') + '*'}
              claseActivo="nombre-conectividad"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="activoConectividad"
              name="activo"
              colSm={4}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('CONECTIVIDAD.MODAL.ACTIVO')}
              customClass="check-activo-conectividad"
            />
            { this.state.isCom ?
            (<Col sm={12}>
              <h3>{t('CONECTIVIDAD.MODAL.DATOS_CONEXION')}</h3>
              <p style={{fontWeight: 'bold'}}>{t('CONECTIVIDAD.PUERTO')}*</p>
            </Col>) :
            (<Col sm={12}>
              <h3>{t('CONECTIVIDAD.MODAL.DATOS_CONEXION')}</h3>
              <p style={{fontWeight: 'bold'}}>{t('CONECTIVIDAD.MODAL.DIRECCION_IP')}*</p>
            </Col>)}
            { this.state.showIpParte1 && 
            <Field
              id="ipParte1"
              name="ipParte1"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />}
            { this.state.showIpParte2 && 
            <Field
              id="ipParte2"
              name="ipParte2"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />}
            { this.state.showIpParte3 && 
            <Field
              id="ipParte3"
              name="ipParte3"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />}
            { this.state.showIpParte4 && 
            <Field
              id="ipParte4"
              name="ipParte4"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />}
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CONECTIVIDAD.MODAL.OBSERVACIONES')}
              customClass='observaciones'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(data).length > 0 && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('CONECTIVIDAD.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            Object.keys(data).length === 0 && (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoConectividad.bind(this))}>{t('CONECTIVIDAD.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="submit"
            className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2)}
          >{t('CONECTIVIDAD.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.closeModalConectividad()}>{t('CONECTIVIDAD.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalConectividad',
})(ModalConectividad))
import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalGrupoVerracoPage from '../../containers/grupoVerraco/ModalGrupoVerracoPage'
import FiltrosGrupoVerracoPage from '../../containers/grupoVerraco/FiltrosGrupoVerracoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class GrupoVerraco extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.GRUPOS_DE_VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarGrupoVerraco({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      grupoVerraco: { list, data, dataDuplicar, dataEstado, checkedFiltrosGrupoVerraco, dataDelete },
      actions: {recuperarDatosModalGrupoVerraco, duplicarDatosModalGrupoVerraco, cambiarEstadoGrupoVerraco, onDeleteGrupoVerraco }
    } = this.props

    const idElemento = 'idGrupoVerraco'
    const table = {
      id: 'grupoVerracoTable',
      multiHeader: false,
      idElemento,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreGrupoVerraco', name: t('GRUPOS_VERRACO.TABLA.NOMBRE_GRUPO')},
        {id: 'numeroverracos', name: t('GRUPOS_VERRACO.TABLA.NUM_VERRACOS'), mostrarVerracosPopover: true},
        {id: 'fechaCreacion', name: t('GRUPOS_VERRACO.TABLA.FECHA_CREACION'), type: 'fecha'},
        {id: 'fechaDesactivacion', name: t('GRUPOS_VERRACO.TABLA.FECHA_DESACTIVACION'), type: 'fecha'},
        {id: 'descripcion', name: t('GRUPOS_VERRACO.TABLA.DESCRIPCION'), textAlignLeft: true}
      ],
      rows: list,
      filtros: <FiltrosGrupoVerracoPage idElemento={idElemento} />,
      onEdit: (row) => recuperarDatosModalGrupoVerraco(row.idGrupoVerraco),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1,
      duplicar: (row) =>duplicarDatosModalGrupoVerraco(row.idGrupoVerraco),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1,
      cambiarEstado: (row) => cambiarEstadoGrupoVerraco(row.idGrupoVerraco),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1,
      onDelete: (row) => onDeleteGrupoVerraco(row.idGrupoVerraco),
      dataDelete: dataDelete,
      showModal: this.props.showModal,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row[idElemento]] = checkedFiltrosGrupoVerraco
        }
      }
    )
  return (
      <div>
      <SimpleModalPage/>
      <MensajeAlertaPage/>
        <ModalGrupoVerracoPage
          duplicar={(row) =>duplicarDatosModalGrupoVerraco(row.idGrupoVerraco)}
        />
        <ListadoPage t_key="GRUPOS_VERRACO." table={table}>
        </ListadoPage>
      </div>
    )
  }
}
export default GrupoVerraco
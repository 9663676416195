import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalHistoricoGrupos from '../../components/fichaVerraco/ModalHistoricoGrupos'
import {cerrarModalHistoricoGrupos} from '../../actions/fichaVerraco/editarVerraco/editarVerraco'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    auth: state.auth,
    fichaVerraco: {...state.fichaVerraco}
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalHistoricoGrupos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalHistoricoGrupos))
import React, {Component} from 'react'
import {Col, FormControl, FormGroup, ControlLabel, InputGroup} from 'react-bootstrap'
import { Warning, Visibility, VisibilityOff } from '@material-ui/icons'
import './InputText.scss'

class InputPassword extends Component {
  constructor () {
    super()
    this.state = {
      showPassword: false
    }
  }

  handleChange (value) {
    this.props.input.onChange(value)
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
  }

  handleBlur (value) {
    this.props.input.onBlur(value)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(value)
    }
  }

  render () {
    const {
      id,
      colSm = 3,
      colSmOffset = 0,
      placeholder,
      controlLabel,
      componentClass,
      customClass = '',
      disabled = false,
      textAlign,
      maxLength,
      autoComplete = 'off',
      meta: { touched, error }
    } = this.props
    const errorMessage = (touched && error) ? error : null
    return (
    <Col sm={colSm} smOffset={colSmOffset} className={customClass}>
      <FormGroup
        validationState={errorMessage ? 'error' : null}
      >
        {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
        <InputGroup className="input-group-icon input-group-icon-right">
          <FormControl
            style={{textAlign: textAlign || 'left'}}
            id={id}
            disabled={disabled}
            type={this.state.showPassword ? 'text' : 'password'}
            componentClass={componentClass}
            placeholder={placeholder}
            maxLength={maxLength}
            autoComplete={autoComplete}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
          <InputGroup.Addon onClick={() => this.setState({showPassword: !this.state.showPassword})}>
            {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
          </InputGroup.Addon>
        </InputGroup>
        {
          errorMessage && <span className='help-block'><Warning /><span>{errorMessage}</span></span>
        }
      </FormGroup>
    </Col>
    )
  }
}

export default InputPassword

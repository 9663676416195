import actionTypes from '../../constants/actions/usuarios/usuarios'

export function initialState () {
  return {
    listUsuarios: [],
    filtros: [],
    dataUsuario: [],
    dataUsuarioDuplicar: [],
    showModalUsuarios: false,
    checkUsuarios: false,
    datosExcelUsuarios: [],
    datosPdfUsuarios: [],
    imagen: null,
    listGruposUsuarios: [],
    listCentros: [],
    checkGrupoUsuarios: false,
    checkListCentros: false,
    listGruposUsuariosAnadidos: [],
    listCentrosAnadidos: [],
    showModalListCentro: false,
    showModalListCentroAnadidos: false,
    showModalGrupoUsuarios: false,
    checkGrupoUsuariosAnadidos: false,
    checkCentrosAnadidos: false,
    checkTablaGrupoUsuarios: false,
    checkTablaCentros: false,
    showModalCambiarPass: false,
    dataCambiarPass: '',
    dataNuevoUsuario: [],
    showModalCambiarTokenMagavision: false,
    idElemento: ''
  }
}

export function fetchUsuariosSuccess (state, {listUsuarios}) {
  return {
    ...state,
    listUsuarios: listUsuarios
  }
}

export function filtrarUsuariosSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function abrirModalUsuarios (state, {filtros}) {
  return {
    ...state,
    showModalUsuarios: true
  }
}

export function cerrarModalUsuarios (state, {filtros}) {
  return {
    ...state,
    dataUsuario: [],
    dataUsuarioDuplicar: [],
    showModalUsuarios: false,
    imagen: null,
    listGruposUsuariosAnadidos: [],
    listCentrosAnadidos: []
  }
}

export function uploadFileUsuarios (state, {imagen}) {
  return {
    ...state,
    imagen: imagen
  }
}

export function fetchCentrosUsuariosSuccess (state, {listCentros}) {
  return {
    ...state,
    listCentros
  }
}

export function fetchUsuarioGrupoUsuariosSuccess (state, {listGruposUsuarios}) {
  return {
    ...state,
    listGruposUsuarios
  }
}

export function abrirModalListCentro (state) {
  return {
    ...state,
    showModalListCentro: true
  }
}

export function cerrarModalListCentro (state) {
  return {
    ...state,
    showModalListCentro: false
  }
}

export function abrirModalGruposUsuarios (state) {
  return {
    ...state,
    showModalGrupoUsuarios: true
  }
}

export function cerrarModalGruposUsuarios (state) {
  return {
    ...state,
    showModalGrupoUsuarios: false
  }
}

export function seleccionarTodosModalGruposUsuario (state) {
  return {
    ...state,
    checkGrupoUsuarios: true
  }
}

export function deSeleccionarTodosModalGruposUsuario (state) {
  return {
    ...state,
    checkGrupoUsuarios: false
  }
}

export function anadirGruposUsuario (state, {listGruposUsuariosAnadidos}) {
  return {
    ...state,
    listGruposUsuariosAnadidos: state.listGruposUsuariosAnadidos.concat(listGruposUsuariosAnadidos),
    showModalGrupoUsuarios: false
  }
}

export function eliminarGrupoUsuariosAnadidos (state, {listGruposUsuariosAnadidos}) {
  return {
    ...state,
    listGruposUsuariosAnadidos: listGruposUsuariosAnadidos
  }
}

export function seleccionarTodosTablaGruposUsuario (state) {
  return {
    ...state,
    checkTablaGrupoUsuarios: true
  }
}

export function deSeleccionarTodosTablaGruposUsuario (state) {
  return {
    ...state,
    checkTablaGrupoUsuarios: false
  }
}

export function seleccionarTodosModalListCentro (state) {
  return {
    ...state,
    checkListCentros: true
  }
}

export function deSeleccionarTodosModalListCentro (state) {
  return {
    ...state,
    checkListCentros: false
  }
}

export function anadirListCentro (state, {listCentrosAnadidos}) {
  return {
    ...state,
    listCentrosAnadidos: state.listCentrosAnadidos.concat(listCentrosAnadidos),
    showModalListCentro: false
  }
}

export function eliminarCentrosAnadidos (state, {listCentrosAnadidos}) {
  return {
    ...state,
    listCentrosAnadidos: listCentrosAnadidos
  }
}

export function seleccionarTodosTablaCentros (state) {
  return {
    ...state,
    checkTablaCentros: true
  }
}

export function deSeleccionarTodosTablaCentros (state) {
  return {
    ...state,
    checkTablaCentros: false
  }
}

export function recuperarDatosUsuarioSuccess (state, {dataUsuario}) {
  return {
    ...state,
    dataUsuario: dataUsuario,
    dataUsuarioDuplicar: [],
    showModalUsuarios: true
  }
}

export function duplicarUsuarioSuccess (state, {dataUsuarioDuplicar}) {
  return {
    ...state,
    dataUsuario: [],
    dataUsuarioDuplicar: dataUsuarioDuplicar,
    showModalUsuarios: true
  }
}

export function seleccionarTodosUsuarios (state) {
  return {
    ...state,
    checkUsuarios: true
  }
}

export function deSeleccionarTodosUsuarios (state) {
  return {
    ...state,
    checkUsuarios: false
  }
}

export function crearCsvUsuariosSuccess (state, {datosExcelUsuarios}) {
  return {
    ...state,
    datosExcelUsuarios: datosExcelUsuarios
  }
}

export function imprimirPdfUsuariosSuccess (state, {datosPdfUsuarios}) {
  return {
    ...state,
    datosPdfUsuarios: datosPdfUsuarios
  }
}

export function abrirModalCambiarPass (state) {
  return {
    ...state,
    showModalCambiarPass: true
  }
}

export function cerrarModalCambiarPass (state) {
  return {
    ...state,
    showModalCambiarPass: false,
    dataCambiarPass: ''
  }
}

export function cambiarPasswordUsuarioSuccess (state, {dataCambiarPass}) {
  return {
    ...state,
    dataCambiarPass: dataCambiarPass
  }
}

export function submitNuevoUsuarioSuccess (state, {dataNuevoUsuario}) {
  return {
    ...state,
    dataNuevoUsuario: dataNuevoUsuario
  }
}

export function abrirModalCambiarTokenMagavision (state) {
  return {
    ...state,
    showModalCambiarTokenMagavision: true
  }
}

export function cerrarModalCambiarTokenMagavision (state) {
  return {
    ...state,
    showModalCambiarTokenMagavision: false,
    idElemento: ''
  }
}

export function cambiarTokenMagavision (state, {idElemento}) {
  return {
    ...state,
    idElemento: idElemento
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_USUARIOS_SUCCESS:
      return fetchUsuariosSuccess(state, action)
    case actionTypes.FILTRAR_USUARIOS_SUCCESS:
      return filtrarUsuariosSuccess(state, action)
    case actionTypes.ABRIR_MODAL_USUARIOS:
      return abrirModalUsuarios(state, action)
    case actionTypes.CERRAR_MODAL_USUARIOS:
      return cerrarModalUsuarios(state, action)
    case actionTypes.UPLOAD_FILE_USUARIOS:
      return uploadFileUsuarios(state, action)
    case actionTypes.FETCH_CENTROS_USUARIOS_SUCCESS:
      return fetchCentrosUsuariosSuccess(state, action)
    case actionTypes.FETCH_USUARIO_GRUPO_USUARIOS_SUCCESS:
      return fetchUsuarioGrupoUsuariosSuccess(state, action)
    case actionTypes.ABRIR_MODAL_LIST_CENTRO:
      return abrirModalListCentro(state, action)
    case actionTypes.ABRIR_MODAL_GRUPOS_USUARIOS:
      return abrirModalGruposUsuarios(state, action)
    case actionTypes.CERRAR_MODAL_LIST_CENTRO:
      return cerrarModalListCentro(state, action)
    case actionTypes.CERRAR_MODAL_GRUPOS_USUARIOS:
      return cerrarModalGruposUsuarios(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO:
      return seleccionarTodosModalGruposUsuario(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO:
      return deSeleccionarTodosModalGruposUsuario(state, action)
    case actionTypes.ANADIR_GRUPO_USUARIO:
      return anadirGruposUsuario(state, action)
    case actionTypes.ELIMINAR_GRUPO_USUARIOS_ANADIDOS:
      return eliminarGrupoUsuariosAnadidos(state, action)
    case actionTypes.SELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO:
      return seleccionarTodosTablaGruposUsuario(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO:
      return deSeleccionarTodosTablaGruposUsuario(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_LIST_CENTROS:
      return seleccionarTodosModalListCentro(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_LIST_CENTROS:
      return deSeleccionarTodosModalListCentro(state, action)
    case actionTypes.ANADIR_LIST_CENTROS:
      return anadirListCentro(state, action)
    case actionTypes.ELIMINAR_CENTROS_ANADIDOS:
      return eliminarCentrosAnadidos(state, action)
    case actionTypes.SELECCIONAR_TODOS_TABLA_CENTROS:
      return seleccionarTodosTablaCentros(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TABLA_CENTROS:
      return deSeleccionarTodosTablaCentros(state, action)
    case actionTypes.RECUPERAR_DATOS_USUARIO_SUCCESS:
      return recuperarDatosUsuarioSuccess(state, action)
    case actionTypes.DUPLICAR_USUARIO_SUCCESS:
      return duplicarUsuarioSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_USUARIOS:
      return seleccionarTodosUsuarios(state, action)
    case actionTypes.DESELECCIONAR_TODOS_USUARIOS:
      return deSeleccionarTodosUsuarios(state, action)
    case actionTypes.CREAR_CSV_USUARIOS_SUCCESS:
      return crearCsvUsuariosSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_USUARIOS_SUCCESS:
      return imprimirPdfUsuariosSuccess(state, action)
    case actionTypes.ABRIR_MODAL_CAMBIAS_PASS:
      return abrirModalCambiarPass(state, action)
    case actionTypes.CERRAR_MODAL_CAMBIAS_PASS:
      return cerrarModalCambiarPass(state, action)
    case actionTypes.CAMBIAR_PASSWORD_USUARIO_SUCCESS:
      return cambiarPasswordUsuarioSuccess(state, action)
    case actionTypes.SUBMIT_NUEVO_USUARIO_SUCCESS:
      return submitNuevoUsuarioSuccess(state, action)
    case actionTypes.ABRIR_MODAL_CAMBIAR_TOKEN_MAGAVISION:
      return abrirModalCambiarTokenMagavision(state, action)
    case actionTypes.CERRAR_MODAL_CAMBIAR_TOKEN_MAGAVISION:
      return cerrarModalCambiarTokenMagavision(state, action)
    case actionTypes.CAMBIAR_TOKEN_MAGAVISION:
      return cambiarTokenMagavision(state, action)
    default:
      return state
  }
}
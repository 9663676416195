import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Microbiologia from '../../../components/planSanitario/microbiologia/Microbiologia'
import {fetchMicrobiologia, recuperarDatosModalMicrobiologia, onDeleteMicrobiologia, fetchFiltrarMicrobiologia} from '../../../actions/planSanitario/microbiologia/microbiologia'

export function mapStateToProps (state) {
  return {
    microbiologia: {...state.microbiologia},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchMicrobiologia,
      recuperarDatosModalMicrobiologia,
      onDeleteMicrobiologia,
      fetchFiltrarMicrobiologia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Microbiologia))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalPedidos from '../../components/pedidosVenta/FiltrosModalPedidos'
import { abrirModalDatosDosis, seleccionarTodosModalPedidos, deSeleccionarTodosModalPedidos, editarDatosDosisSuccess } from '../../actions/pedidosVenta/pedidosVenta'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalDatosDosis,
      seleccionarTodosModalPedidos,
      deSeleccionarTodosModalPedidos,
      editarDatosDosisSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalPedidos))
export default {
  ADD_DOSIS_A_PEDIDO:'ADD_DOSIS_A_PEDIDO',
  ADD_DOSIS_A_PEDIDO_SUCCESS:'ADD_DOSIS_A_PEDIDO_SUCCESS',
  CLOSE_MODAL:'CLOSE_MODAL',
  FETCH_LINEAS_ALBARANES_PENDIENTES:'FETCH_LINEAS_ALBARANES_PENDIENTES',
  FETCH_LINEAS_ALBARANES_PENDIENTES_SUCCESS:'FETCH_LINEAS_ALBARANES_PENDIENTES_SUCCESS',
  CLOSE_MODAL_SUCCESS:'CLOSE_MODAL_SUCCESS',
  CREATE_NEW_POOL_SUCCESS:'CREATE_NEW_POOL_SUCCESS',
  ABRIR_MODAL_ASIGNACION_DOSIS:'ABRIR_MODAL_ASIGNACION_DOSIS',
  ABRIR_MODAL_ASIGNACION_DOSIS_SUCCESS:'ABRIR_MODAL_ASIGNACION_DOSIS_SUCCESS',
  CERRAR_MODAL_ASIGNACION_DOSIS_ASIGNACION:'CERRAR_MODAL_ASIGNACION_DOSIS_ASIGNACION',
  CERRAR_MODAL_ASIGNACION_DOSIS_ASIGNACION_SUCCESS:'CERRAR_MODAL_ASIGNACION_DOSIS_ASIGNACION_SUCCESS',
  ACTUALIZAR_FILTROS_ASIGNACION_PEDIDOS:'ACTUALIZAR_FILTROS_ASIGNACION_PEDIDOS',
  DELETE_SELECCION_DOSIS_ENVASADAS:'DELETE_SELECCION_DOSIS_ENVASADAS' 
}
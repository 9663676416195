import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosCliente from '../../components/cliente/FiltrosCliente'
import { comboCifCliente, comboRazonSocialClientesActivos, comboAliasClientesActivos, comboLocalidadClientes, comboProvinciasClientes, comboRuta } from '../../actions/combos/combos'
import { abrirModalCliente, closeModalCliente, filtrarCliente, seleccionarTodosCliente, deSeleccionarTodosCliente, cambiarEstadoSeleccionCliente, deleteSeleccionCliente,
  crearCsvCliente, imprimirPdfCliente} from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    abrirModalCliente,
    closeModalCliente,
    filtrarCliente,
    seleccionarTodosCliente,
    deSeleccionarTodosCliente,
    cambiarEstadoSeleccionCliente,
    deleteSeleccionCliente,
    crearCsvCliente,
    imprimirPdfCliente,
    comboCifCliente,
    comboRazonSocialClientesActivos,
    comboAliasClientesActivos,
    comboLocalidadClientes,
    comboProvinciasClientes,
    comboRuta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosCliente))
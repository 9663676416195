import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRectaColorimetro from '../../components/panelControl/ModalRectaColorimetro'
import { cerrarModalRectaColorimetro, abrirModalAnadirColorimetro, fetchRectaColorimetro, recuperarDatosRectaColorimetro, duplicarDatosModalRectaColorimetro, onDeleteRectaColorimetro,
  cambiaPredeterminadoRectaColorimetro, seleccionarTodosColorimetro, deSeleccionarTodosColorimetro, deleteSeleccionRectaColorimetro
} from '../../actions/panelControl/panelControl'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalRectaColorimetro,
      abrirModalAnadirColorimetro,
      fetchRectaColorimetro,
      recuperarDatosRectaColorimetro,
      duplicarDatosModalRectaColorimetro,
      cambiaPredeterminadoRectaColorimetro,
      onDeleteRectaColorimetro,
      seleccionarTodosColorimetro,
      deSeleccionarTodosColorimetro,
      deleteSeleccionRectaColorimetro
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRectaColorimetro))
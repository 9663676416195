import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row } from 'react-bootstrap'
import Button from '../comun/button/Button'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import { required } from '../../util/validationFunctions'

class ModalEditarTrazabilidadMaterial extends Component {
  constructor () {
    super()
    this.state = {
      stockFinalNegativo: false,
      editarStockProductoTrazabilidad: false
    }
    this.handleChangeConsumido = this.handleChangeConsumido.bind(this)
    this.guardarModalEditarTrazabilidadMaterial = this.guardarModalEditarTrazabilidadMaterial.bind(this)
  }

  handleChangeConsumido (val) {
    let stockFinal = this.props.formModalEditarTrazabilidadMaterial.stock ? parseFloat(this.props.formModalEditarTrazabilidadMaterial.stock, 10) : 0
    stockFinal -= val ? parseFloat(val, 10) : 0
    this.setState({stockFinalNegativo: stockFinal < 0})
    this.props.change('stockFinal', stockFinal.toString())
  }

  guardarModalEditarTrazabilidadMaterial (values) {
    this.props.actions.guardarModalEditarTrazabilidadMaterial(values)
    this.setState({stockFinalNegativo: false})
    this.setState({editarStockProductoTrazabilidad: false})
  }

  componentDidUpdate (prevProps) {
    if (this.props.formModalEditarTrazabilidadMaterial && this.props.formModalEditarTrazabilidadMaterial !== prevProps.formModalEditarTrazabilidadMaterial){
      if(this.props.formModalEditarTrazabilidadMaterial.idEntradaAlmacenProducto !== null){
        this.setState({editarStockProductoTrazabilidad: false})
      }
      else{
        this.setState({editarStockProductoTrazabilidad: true})
      }
    }
    if (this.props.showModalEditarTrazabilidad && this.props.showModalEditarTrazabilidad !== prevProps.showModalEditarTrazabilidad) {
      let stockFinal = this.props.formModalEditarTrazabilidadMaterial.stockFinal ? parseFloat(this.props.formModalEditarTrazabilidadMaterial.stockFinal, 10) : 0
      this.setState({stockFinalNegativo: stockFinal < 0})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalEditarTrazabilidad, showStockColumns,
      actions: {closeModalEditarTrazabilidadMaterial}
    } = this.props

    const tKey = 'ANALISIS_EXTRACCIONES.TRAZABILIDAD_MATERIAL.'

    return (
      <Modal show={showModalEditarTrazabilidad} onHide={closeModalEditarTrazabilidadMaterial} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Field
                colSm={4}
                id="nombre"
                name="nombre"
                controlLabel={t(tKey + 'FORM.PRODUCTO')}
                component={InputText}
                disabled={true}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={4}
                id="lote"
                name="lote"
                controlLabel={t(tKey + 'FORM.LOTE')}
                component={InputText}
                disabled={true}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="caducidad"
                name="caducidad"
                controlLabel={t(tKey + 'FORM.FECHA_CADUCIDAD')}
                component={InputDatePicker}
                disabled={true}
              />
            </Row>
            <Row>
              {showStockColumns && (
                <Field
                  colSm={4}
                  id="stock"
                  name="stock"
                  controlLabel={t(tKey + 'FORM.STOCK')}
                  component={InputNumerical}
                  disabled={true}
                />
              )}
              <Field
                colSm={4}
                id="consumido"
                name="consumido"
                controlLabel={t(tKey + 'FORM.CONSUMIDO') + '*'}
                component={InputNumerical}
                minValue={0}
                onInputChange={(event, val) => this.handleChangeConsumido(val)}
                validate={required}
                disabled={this.state.editarStockProductoTrazabilidad}
              />
              {showStockColumns && (
                <Field
                  colSm={4}
                  id="stockFinal"
                  name="stockFinal"
                  controlLabel={t(tKey + 'FORM.STOCK_FINAL')}
                  component={InputNumerical}
                  disabled={true}
                  validarTextoValorR={!this.state.stockFinalNegativo}
                />
              )}
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarModalEditarTrazabilidadMaterial)}>{t(tKey + 'BUTTONS.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalEditarTrazabilidadMaterial}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalEditarTrazabilidadMaterial',
})(ModalEditarTrazabilidadMaterial))
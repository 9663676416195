export const estadoMaquinaDilucion = {
  PREPARADA_PARA_DILUIR: '1',
  NO_SE_HA_SELECCIONADO_DILUYENTE_EN_LA_MAQUINA: '2',
  JARRA_NO_DETECTADA: '3',
  DEPOSITO_AGUA_NO_LLENO: '4',
  EN_ESPERA_RETIRAR_RECIPIENTE: '5',
  PARADA_TRAS_ALCANZAR_PRIMER_UMBRAL: '21',
  PARADA_TRAS_ALCANZAR_SEGUNDO_UMBRAL: '31',
  PARADA_TRAS_ALCANZAR_CONSIGNA: '41',
  PARADA_EMERGENCIA: '10',
  ERROR_BASCULA: '11',
  FALTA_AGUA_DOSIFICAR: '12',
  RETIRADA_INESPERADA_RECIPIENTE: '13',
  SOBREPESO: '14',
  ERROR: '15',
  DILUYENDO: '-1',
  CONEXION_PERDIDA: '-2',
  FINALIZADO: '-3',
  NO_FINALIZADO: '-4',
  PREPARADA: '-5',
  NO_PREPARADA: '-6',
  PROCESANDO_DILUCION: '-7',
  CONEXION_VPN_NO_ESTABLECIDA: '-8',
  EN_USO: '-10'
}

export const translateEstadoMaquinaDilucion = {
  '1': "PREPARADA_PARA_DILUIR",
  '2': "NO_SE_HA_SELECCIONADO_DILUYENTE_EN_LA_MAQUINA",
  '3': "JARRA_NO_DETECTADA",
  '4': "DEPOSITO_AGUA_NO_LLENO",
  '5': 'EN_ESPERA_RETIRAR_RECIPIENTE',
  '21': "PARADA_TRAS_ALCANZAR_PRIMER_UMBRAL",
  '31': "PARADA_TRAS_ALCANZAR_SEGUNDO_UMBRAL",
  '41': "PARADA_TRAS_ALCANZAR_CONSIGNA",
  '10': "PARADA_EMERGENCIA",
  '11': "ERROR_BASCULA",
  '12': "FALTA_AGUA_DOSIFICAR",
  '13': "RETIRADA_INESPERADA_RECIPIENTE",
  '14': "SOBREPESO",
  '15': "ERROR",
  '-1': 'DILUYENDO',
  '-2': 'CONEXION_PERDIDA',
  '-3': 'FINALIZADO',
  '-4': 'NO_FINALIZADO',
  '-5': 'PREPARADA',
  '-6': 'NO_PREPARADA',
  '-7': 'PROCESANDO_DILUCION',
  '-8': 'CONEXION_VPN_NO_ESTABLECIDA',
  '-10': 'EN_USO'
}

export const estadoMaquinaEnvasado = {
  BRAZO_ETIQUETADO_BLOQUEADO: '1',
  CAJON_IMPRESORA_ABIERTO: '2',
  NO_HAY_BOLSAS: '3',
  NO_HAY_ETIQUETAS: '4',
  BAJA_PRESION: '5',
  COMPROBAR_TROQUELES: '6',
  AGUJA_BLOQUEADA: '7',
  CARRO_BLOQUEADO: '8',
  ENGARCES_BLOQUEADOS: '9',
  PARADA_EMERGENCIA_ACTIVADA: '10',
  ENVASANDO: '-1',
  CONEXION_PERDIDA: '-2',
  FINALIZADO: '-3',
  NO_FINALIZADO: '-4',
  PREPARADA: '-5',
  NO_PREPARADA: '-6',
  PROCESANDO_ENVASADO: '-7',
  CONEXION_VPN_NO_ESTABLECIDA: '-8',
  EN_ESPERA_SER_INICIADA: '-9',
  EN_USO: '-10'
}

export const translateEstadoMaquinaEnvasado = {
  '1': 'BRAZO_ETIQUETADO_BLOQUEADO',
  '2': 'CAJON_IMPRESORA_ABIERTO',
  '3': 'NO_HAY_BOLSAS',
  '4': 'NO_HAY_ETIQUETAS',
  '5': 'BAJA_PRESION',
  '6': 'COMPROBAR_TROQUELES',
  '7': 'AGUJA_BLOQUEADA',
  '8': 'CARRO_BLOQUEADO',
  '9': 'ENGARCES_BLOQUEADOS',
  '10': 'PARADA_EMERGENCIA_ACTIVADA',
  '-1': 'ENVASANDO',
  '-2': 'CONEXION_PERDIDA',
  '-3': 'FINALIZADO',
  '-4': 'NO_FINALIZADO',
  '-5': 'PREPARADA',
  '-6': 'NO_PREPARADA',
  '-7': 'PROCESANDO_ENVASADO',
  '-8': 'CONEXION_VPN_NO_ESTABLECIDA',
  '-9': 'EN_ESPERA_SER_INICIADA',
  '-10': 'EN_USO'
}
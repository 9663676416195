import React, { Component } from 'react'
import {Col, Row, Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, Fields} from 'redux-form'
import { required } from '../../util/validationFunctions'
import './informes.scss'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Launch, Print } from '@material-ui/icons'

class ModalRecomendacionesMatadero extends Component {
  componentDidMount () {
    this.props.actions.obtenerRecomendacionesMatadero()
  }

  crearCsvInformeProductividadExtraccionesMatadero () {
    this.props.actions.crearCsvInformeProductividadExtraccionesMatadero(this.props.filtrosParaMatadero)
  }
  imprimirPdfInformesProductividadExtraccionesMatadero () {
    this.props.actions.imprimirPdfInformesProductividadExtraccionesMatadero(this.props.filtrosParaMatadero)
  }

  render () {
    const {
      t, handleSubmit, showModalRecomendacionesMatadero, data, duplicar, listRecomendacionesMatadero,
      actions: {cerrarModalRecomendacionesMatadero},
      combos: {comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria, comboEstado}
    } = this.props

    const tableInformeProductividadExtraccionesMalas = {
      id: 'informeProductividadExtraccionesMalasTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      // hasPagination: false,
      noBlockOptions: false,
      // paginarConScroll: true,
      columns: [
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.VERRACO')},
        {id: 'extraccionesBuenas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.EXTRACCIONES_BUENAS')},
        {id: 'extraccionesMalas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.EXTRACCIONES_MALAS')},
        {id: 'porcentajeExtraccionesBuenas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.POR_EXTRACCIONES_BUENAS'), type: 'numero'},
        {id: 'edad', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.EDAD'), type: 'numero', numDecimales: 0},
      ],
      rows: listRecomendacionesMatadero,
      leyenda: [
        {name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.ES_INTERVENCION'), color: '#c14312'}
      ],
      colorearRow: (row) => {
        if (row.intervencionSanitaria === true) {
            return 'es-intervencion'
        }
      },
      initialValues: {}
    }

    return (
      <Modal show={showModalRecomendacionesMatadero} onHide={cerrarModalRecomendacionesMatadero} backdrop="static" aria-labelledby="contained-modal-title-lg" 
      className="modal-productividad-verracos">
      <form className="form-productividad-verracos">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            <h3>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.RECOMENDACIONES_MATADERO')}</h3>
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="acciones-tabla-filtros">
            <Col sm={10}>
              <div className="buttons-flex">
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeProductividadExtraccionesMatadero()}>
                    <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesProductividadExtraccionesMatadero()}>
                    <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={2} className="content-button-filtro">
            </Col>
          </Row>
          <SimpleTablePage {...tableInformeProductividadExtraccionesMalas}></SimpleTablePage>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalRecomendacionesMatadero}>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRecomendacionesMatadero',
})(ModalRecomendacionesMatadero))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './Usuarios.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Warning } from '@material-ui/icons'

class ModalGruposUsuario extends Component {
  constructor () {
    super()
    this.state = {
      validarAnadirGrupo: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalGrupoUsuarios !== prevProps.showModalGrupoUsuarios){
      this.props.actions.fetchUsuarioGrupoUsuarios(this.props.listGruposUsuariosAnadidos)
    }
  }

  anadirGruposUsuario (values) {
    const itemsSeleccion = this.props.listGruposUsuarios.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idGrupoUsuario': row.idGrupoUsuario,
          'idElemento': row.idElemento,
          'nombreGrupo': row.nombreGrupo
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (Object.keys(list).length > 0) {
      this.setState({validarAnadirGrupo: true})
      this.props.actions.anadirGruposUsuario(list)
    } else {
      this.setState({validarAnadirGrupo: false})
    }
    this.props.change('seleccionar-todos-modal-grupo-usuarios', false)
    this.props.actions.deSeleccionarTodosModalGruposUsuario()
  }

  intercambiarCheckModalGruposUsuario (value) {
    this.setState({validarAnadirGrupo: true})
    if (value === true) {
      this.props.actions.seleccionarTodosModalGruposUsuario()
    } else {
      this.props.actions.deSeleccionarTodosModalGruposUsuario()
    }
  }

  cerrarModalGruposUsuarios () {
    this.props.actions.cerrarModalGruposUsuarios()
  }

  render () {
    const {
      t, handleSubmit, listGruposUsuarios, showModalGrupoUsuarios, checkGrupoUsuarios, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalGruposUsuarios }
    } = this.props

    listGruposUsuarios.forEach(
      (row) => row.idElemento = '-gu-' + row.idGrupoUsuario
    )

    const tableGrupoUsuarios = {
      id: 'grupoUsuariosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      columns: [
        {id: 'nombreGrupo', name: t('USUARIOS.MODAL.TABLA_GRUPOS_USUARIOS.NOMBRE')},
      ],
      rows: listGruposUsuarios,
      rowsVerraco: true,
      mostrarModificar: false,
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      initialValues: {},
    }

    tableGrupoUsuarios.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableGrupoUsuarios.initialValues['check' + row.idElemento] = checkGrupoUsuarios
        }
      }
    )

    return (
      <Modal show={showModalGrupoUsuarios} onHide={() => this.cerrarModalGruposUsuarios()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-grupo-usuarios" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('USUARIOS.MODAL.TABLA_GRUPOS_USUARIOS.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-verracos-en-grupo-usuarios">
                <div className="filtros-tabla-modal-grupo-usuarios acciones-tabla-filtros">
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todos-modal-grupo-usuarios"
                      name="seleccionar-todos-modal-grupo-usuarios"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckModalGruposUsuario(value)}
                    />
                  </div>
                </div>
                <ListadoPage t_key="USUARIOS." noTitle={true} table={tableGrupoUsuarios}>
                </ListadoPage>
                {
                  /*this.state.validarAnadirGrupo === false ? (
                    <p className="validar-asignar-verracos"><span className='help-block'><Warning />{t('USUARIOS.MODAL.ERROR_ANADIR_VERRACO')}</span></p>
                  ) : null*/
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirGruposUsuario.bind(this))}>{t('USUARIOS.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalGruposUsuarios()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalGruposUsuario',
  enableReinitialize: true
})(ModalGruposUsuario))
import actionTypes from '../../constants/actions/ajusteStock/ajusteStock'

export function initialState () {
  return {
    list: [],
    filtros:{},
    showModal: false
  }
}

export function fetchAjusteStockSuccess (state, {list, filtros}) {
  return {
    ...state,
    list,
    filtros
  }
}

export function openModalAjusteStockSuccess (state) {
  return {
    ...state,
    showModal: true
  }
}

export function closeModalAjusteStockSuccess (state) {
  return {
    ...state,
    showModal: false
  }
}

export function crearCsvAjusteStockSuccess (state, {datosExcel}) {
  return {
    ...state,
    datosExcel
  }
}

export function imprimirPdfAjusteStockSuccess (state, {datosPdf}) {
  return {
    ...state,
    datosPdf
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_AJUSTE_STOCK_SUCCESS:
      return fetchAjusteStockSuccess(state, action)
    case actionTypes.OPEN_MODAL_AJUSTE_STOCK_SUCCESS:
      return openModalAjusteStockSuccess(state, action)
    case actionTypes.CLOSE_MODAL_AJUSTE_STOCK_SUCCESS:
      return closeModalAjusteStockSuccess(state, action)
    case actionTypes.CREATE_CSV_AJUSTE_STOCK_SUCCESS:
      return crearCsvAjusteStockSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_AJUSTE_STOCK_SUCCESS:
      return imprimirPdfAjusteStockSuccess(state, action)
    default:
      return state
  }
}
import actionTypes from '../../constants/actions/analisisExtracciones/camaraView'

export function initialState () {
  return {
    camaraConectada: false,
    base64Img: null
  }
}

function comprobarCamaraSuccess (state, {estado}) {
  return {
    ...state,
    camaraConectada: estado
  }
}

function getImagenCamaraSuccess (state, {base64Img}) {
  return {
    ...state,
    base64Img
  }
}

function apagarCamaraSuccess (state) {
  return {
    ...state,
    camaraConectada: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.COMPROBAR_CAMARA_SUCCESS:
      return comprobarCamaraSuccess(state, action)
    case actionTypes.GET_IMAGEN_CAMARA_SUCCESS:
      return getImagenCamaraSuccess(state, action)
    case actionTypes.APAGAR_CAMARA_SUCCESS:
      return apagarCamaraSuccess(state, action)
    default:
      return state
  }
}
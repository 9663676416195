import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import { Delete } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTipoDosisPanelControl extends Component {
  constructor () {
    super()
    this.state = {
      checkTodosSelected: false
    }
  }

  componentDidMount () {
    this.props.actions.obtenerTipoDosis()
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.listTipoDosis).length > 0) {
      if (this.props.listTipoDosis !== prevProps.listTipoDosis) {
        this.props.actions.obtenerTipoDosis()
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-tipo-dosis', false)
            this.setState({ checkTodosSelected: false })
            this.props.actions.deSeleccionarTodosTipoDosis()
          }
        }
      }
    }
  }

  anadirDosisPanelControl (values) {
    this.props.actions.anadirDosisPanelControl(values)
  }

  editarDosisPanelControl (values) {
    values.idTipoDosis = this.props.dataTipoDosis.idTipoDosis
      this.props.actions.editarDosisPanelControl(values)
  }

  intercambiarCheckTipoDosis (value) {
    this.setState({ checkTodosSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosTipoDosis()
    } else {
      this.props.actions.deSeleccionarTodosTipoDosis()
    }
  }

  deleteSeleccionTipoDosis(){
    const itemsSeleccion = this.props.listTipoDosis.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idTipoDosis': row.idTipoDosis
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionTipoDosis(list)
  }

  render () {
    const {
      t, handleSubmit, showModalTipoDosis, listTipoDosis, checkedTipoDosis,numeroRegistrosPorPagina, paginaActual, dataTipoDosis, dataDuplicarTipoDosis,
      actions: {cerrarModalTipoDosis, recuperarDatosTipoDosis, duplicarDatosModalTipoDosis, cambiaPredeterminadoDosis, onDeleteDosis}
    } = this.props

    listTipoDosis.forEach(
      (row) => row.idElemento = row.idTipoDosis
    )

    const table = {
      id: 'tipoDosisTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1),
      hasOptions: true,
      mostrarCambioEstado: false,
      mostrarPredeterminado: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1),
      columns: [
        {id: 'nombreTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.TIPO_DOSIS')},
        {id: 'volumenTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.VOLUMEN'), type: 'numero'},
        {id: 'concentracionTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.CONCENTRACION'), type: 'numero'},
        {id: 'codigoTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.CODIGO_TIPO_DOSIS')},
        /*{id: 'stockTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.STOCK'), type: 'numero'},*/
        {id: 'predeterminadoTipoDosis', name: t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.PREDETERMINADO')}
      ],
      rows: listTipoDosis,
      onEdit: (row) => recuperarDatosTipoDosis(row.idTipoDosis),
      data: dataTipoDosis,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1),
      duplicar: (row) =>duplicarDatosModalTipoDosis(row.idTipoDosis),
      dataDuplicar: dataDuplicarTipoDosis,
      cambiarPorDefecto: (row) => cambiaPredeterminadoDosis(row.idTipoDosis),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1),
      onDelete: (row) => onDeleteDosis(row.idTipoDosis),
      tablaModal: true,
      idBlockOptions: 1,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedTipoDosis
        }
      }
    )

    return (
      <Modal show={showModalTipoDosis} onHide={cerrarModalTipoDosis} aria-labelledby="contained-modal-title-lg" className="modal-tipo-dosis" backdrop="static">
      <form className="form-teclas-contaje">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(this.props.dataTipoDosis).length > 0 ? (
              t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TITLE_MODIFICAR')
            ) : (
              Object.keys(this.props.dataDuplicarTipoDosis).length > 0 ? (
                t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TITLE_DUPLICAR')
              ) : (
                t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={3}
              id="nombreTipoDosis"
              name="nombreTipoDosis"
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TIPO_DOSIS')}
              component={InputText}
              customClass='nombre-tipo-dosis'
              validate={required}
            />
            <Field
              colSm={3}
              id="volumenTipoDosis"
              name="volumenTipoDosis"
              component={InputNumerical}
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DOSIS.VOLUMEN')}
              customClass='volumen-tipo-dosis'
              validate={required}
              numDecimales={0}
            />
            <Field
              colSm={4}
              id="concentracionTipoDosis"
              name="concentracionTipoDosis"
              component={InputNumerical}
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DOSIS.CONCENTRACION')}
              customClass='concentracion-tipo-dosis'
              validate={required}
            />
            <Field
              colSm={3}
              id="codigoTipoDosis"
              name="codigoTipoDosis"
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DOSIS.TABLA.CODIGO_TIPO_DOSIS')}
              component={InputText}
              customClass='codigo-tipo-dosis'
              validate={required}
            />
            <Field
              colSm={3}
              id="predeterminadoTipoDosis"
              name="predeterminadoTipoDosis"
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DOSIS.PREDETERMINADO')}
            />
            <div className="clearfix"></div>
            <Col sm={12}>
            {
              Object.keys(this.props.dataTipoDosis).length > 0 ? (
                <div style={{textAlign: 'right'}}>
                  <Button
                    type="button"
                    className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DOSIS] < 2) ? ' disabled' : '')}
                    style={{marginLeft: '5px'}}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1) ? () => this.props.actions.duplicarDatosModalTipoDosis(dataTipoDosis.idTipoDosis) : undefined}
                  >{t('PANEL_CONTROL.MODAL_TIPO_DOSIS.DUPLICAR')}</Button>
                  <Button
                    type="button"
                    className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DOSIS] < 2) ? ' disabled' : '')}
                    style={{marginLeft: '5px'}}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1) ? handleSubmit(this.editarDosisPanelControl.bind(this)) : undefined}
                  >{t('PANEL_CONTROL.MODAL_TIPO_DOSIS.GUARDAR')}</Button>
                </div>
              ) : (
                <div style={{textAlign: 'right'}}>
                  <Button
                    type="button"
                    className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DOSIS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1) ? handleSubmit(this.anadirDosisPanelControl.bind(this)) : undefined}
                  >{t('PANEL_CONTROL.MODAL_TIPO_DOSIS.GUARDAR')}</Button>
                </div>
              )
            }
            </Col>
            <div className="clearfix" />
            <hr/>
            <Col sm={12} className="container-tabla-tipo-dosis">
              <div id="block-options1" className={this.state.checkTodosSelected === true ? 'filtros-tabla-tipo-dosis' : 'filtros-tabla-tipo-dosis oculta-action'}>
                <div className="custom-check-w">
                {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 1) && ([
                    <Field
                      id="seleccionar-todo-tipo-dosis"
                      name="seleccionar-todo-tipo-dosis"
                      key="seleccionar-todo-tipo-dosis"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckTipoDosis(value)}
                      colSm={1}
                    />,
                    <button key="eliminar_key_dosis" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionTipoDosis()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                  ])}
                </div>
              </div>
              <div className="clearfix"></div>
              <ListadoPage noTitle={true} t_key="PANEL_CONTROL.MODAL_TIPO_DOSIS." table={table}>
              </ListadoPage>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-white" onClick={cerrarModalTipoDosis}>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CERRAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoDosisPanelControl',
})(ModalTipoDosisPanelControl))
export default{
  ENVIAR_FECHA_CAMBIO_ESTADO: 'ENVIAR_FECHA_CAMBIO_ESTADO',
  ENVIAR_FECHA_CAMBIO_ESTADO_SUCCESS: 'ENVIAR_FECHA_CAMBIO_ESTADO_SUCCESS',
  SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO: 'SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO',
  SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO_SUCCESS: 'SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO_SUCCESS',
  RESTART_CAMBIO_ESTADO_VERRACO: 'RESTART_CAMBIO_ESTADO_VERRACO',
  FETCH_CAMBIO_ESTADO_VERRACO: 'FETCH_CAMBIO_ESTADO_VERRACO',
  FETCH_CAMBIO_ESTADO_VERRACO_SUCCESS: 'FETCH_CAMBIO_ESTADO_VERRACO_SUCCESS',
  ABRIR_MODAL_VACIO_CAMBIO_ESTADO: 'ABRIR_MODAL_VACIO_CAMBIO_ESTADO',
  RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO: 'RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO',
  RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS: 'RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS',
  EDITAR_CAMBIO_ESTADO_VERRACO: 'EDITAR_CAMBIO_ESTADO_VERRACO',
  DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO: 'DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO',
  DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS: 'DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS',
  ON_DELETE_CAMBIO_ESTADO_VERRACO: 'ON_DELETE_CAMBIO_ESTADO_VERRACO',
  SELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS: 'SELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS',
  DESELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS: 'DESELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS',
  DELETE_SELECCION_CAMBIO_ESTADO_VERRACO: 'DELETE_SELECCION_CAMBIO_ESTADO_VERRACO',
  FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO: 'FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO',
  FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO_SUCCESS: 'FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO_SUCCESS',
  CREATE_CSV_CAMBIO_ESTADO: 'CREATE_CSV_CAMBIO_ESTADO',
  CREATE_CSV_CAMBIO_ESTADO_SUCCESS: 'CREATE_CSV_CAMBIO_ESTADO_SUCCESS',
  IMPRIMIR_PDF_CAMBIO_ESTADO: 'IMPRIMIR_PDF_CAMBIO_ESTADO',
  IMPRIMIR_PDF_CAMBIO_ESTADO_SUCCESS: 'IMPRIMIR_PDF_CAMBIO_ESTADO_SUCCESS'
}
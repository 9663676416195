import React, {Component} from 'react'
import {Col, FormControl, FormGroup, ControlLabel} from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import './InputTextArea.scss'

class InputTextArea extends Component {

  handleChange (value) {
    this.props.input.onChange(value)
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
  }

  handleBlur (value) {
    this.props.input.onBlur(value)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(value)
    }
  }

  render () {
    const {
      id, colSm, customClass, disabled, placeholder, controlLabel, forceControlLabelInOneLine,
      input, meta: { touched, error/*, warning*/ }
    } = this.props
    const errorMessage = (touched && error) ? error : null
    return (
    <Col sm={colSm} className={customClass}>
      <FormGroup validationState={errorMessage ? 'error' : null}>
        {controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>}
        <FormControl
          id={id}
          disabled={disabled}
          type='textarea'
          placeholder={placeholder}
          componentClass='textarea'
          value={input.value}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
        {errorMessage && <span className='help-block'><Warning /><span>{errorMessage}</span></span>}
      </FormGroup>
    </Col>
    )
  }
}

export default InputTextArea

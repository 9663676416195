import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEntradaMercanciaProducto from '../../components/entradaMercancia/FiltrosEntradaMercanciaProducto'
import { abrirModalEntradaMercanciaAnadirProducto, seleccionarTodosEntradaMercanciaProducto, deSeleccionarTodosEntradaMercanciaProducto, deleteSeleccionEntradaMercanciaProductoAnadido} from '../../actions/entradaMercancia/entradaMercancia'

export function mapStateToProps (state) {
  return {
    ...state.entradaMercancia,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalEntradaMercanciaAnadirProducto,
      seleccionarTodosEntradaMercanciaProducto,
      deSeleccionarTodosEntradaMercanciaProducto,
      deleteSeleccionEntradaMercanciaProductoAnadido
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEntradaMercanciaProducto))
import actionTypes from '../../constants/actions/entradaMercancia/entradaMercancia'

export function abrirModalEntradaMercancia () {
  return {
    type: actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA
  }
}

export function closeModalEntradaMercancia () {
  return {
    type: actionTypes.CLOSE_MODAL_ENTRADA_MERCANCIA
  }
}

export function submitNuevoEntradaMercancia (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_ENTRADA_MERCANCIA,
    values
  }
}

export function submitNuevoEntradaMercanciaSuccess (listEntradaMercancia) {
  return {
    type: actionTypes.SUBMIT_NUEVO_ENTRADA_MERCANCIA_SUCCESS,
    listEntradaMercancia
  }
}

export function obtenerEntradaMercanciasMercancia () {
  return {
    type: actionTypes.OBTENER_ENTRADA_MERCANCIAS_MERCANCIA
  }
}

export function filtrarEntradaMercancia (values) {
  return {
    type: actionTypes.FILTRAR_ENTRADA_MERCANCIA,
    values
  }
}

export function filtrarEntradaMercanciaSuccess (listEntradaMercancia) {
  return {
    type: actionTypes.FILTRAR_ENTRADA_MERCANCIA_SUCCESS,
    listEntradaMercancia
  }
}

export function filtrosEntradaMercanciaSuccess (filtros) {
  return {
    type: actionTypes.FILTROS_ENTRADA_MERCANCIA_SUCCESS,
    filtros
  }
}

export function recuperarDatosModalEntradaMercancia (idEntradaAlmacen) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA,
    idEntradaAlmacen
  }
}

export function recuperarDatosModalEntradaMercanciaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS,
    data
  }
}

export function editarEntradaMercancia (values) {
  return {
    type: actionTypes.EDITAR_ENTRADA_MERCANCIA,
    values
  }
}

export function duplicarDatosModalEntradaMercancia (idEntradaAlmacen) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA,
    idEntradaAlmacen
  }
}

export function duplicarDatosModalEntradaMercanciaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoEntradaMercancia (idEntradaAlmacen) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_ENTRADA_MERCANCIA,
    idEntradaAlmacen
  }
}

export function onDeleteEntradaMercancia (idEntradaAlmacen, proveedor, numeroAlbaran, origen) {
  return {
    type: actionTypes.ON_DELETE_ENTRADA_MERCANCIA,
    idEntradaAlmacen,
    proveedor,
    numeroAlbaran,
    origen
  }
}

export function seleccionarTodosEntradaMercancia () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_ENTRADA_MERCANCIA
  }
}

export function deSeleccionarTodosEntradaMercancia () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ENTRADA_MERCANCIA
  }
}

export function cambiarEstadoSeleccionEntradaMercancia (list) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_ENTRADA_MERCANCIA,
    list
  }
}

export function deleteSeleccionEntradaMercancia (list) {
  return {
    type: actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA,
    list
  }
}

export function crearCsvEntradaMercancia(list){
  return {
    type: actionTypes.CREATE_CSV_ENTRADA_MERCANCIA,
    list
  }
}

export function crearCsvEntradaMercanciaSuccess(datosExcelEntradaMercancia){
  return {
    type: actionTypes.CREATE_CSV_ENTRADA_MERCANCIA_SUCCESS,
    datosExcelEntradaMercancia
  }
}

export function imprimirPdfEntradaMercancia(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_ENTRADA_MERCANCIA,
    list
  }
}

export function imprimirPdfEntradaMercanciaSuccess(datosPdfEntradaMercancia){
  return {
    type: actionTypes.IMPRIMIR_PDF_ENTRADA_MERCANCIA_SUCCESS,
    datosPdfEntradaMercancia
  }
}

export function abrirModalEntradaMercanciaAnadirProducto () {
  return {
    type: actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO
  }
}

export function cerrarModalEntradaMercanciaAnadirProducto () {
  return {
    type: actionTypes.CERRAR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO
  }
}

export function seleccionarTodosEntradaMercanciaProducto () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO
  }
}

export function deSeleccionarTodosEntradaMercanciaProducto () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO
  }
}

export function anadirProductoEntradaMercancia (entradaMercanciaProductos) {
  return {
    type: actionTypes.ANADIR_PRODUCTO_ENTRADA_MERCANCIA,
    entradaMercanciaProductos
  }
}

export function recuperarModalEntradaMercanciaProductoAnadido (idElemento) {
  return {
    type: actionTypes.RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO,
    idElemento
  }
}

export function recuperarModalEntradaMercanciaProductoAnadidoSuccess (dataProducto) {
  return {
    type: actionTypes.RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS,
    dataProducto
  }
}

export function editarEntradaMercanciaProducto (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ENTRADA_MERCANCIA_PRODUCTO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function editarEntradaMercanciaProductoSuccess (entradaMercanciaProductos) {
  return {
    type: actionTypes.EDITAR_ENTRADA_MERCANCIA_PRODUCTO_SUCCESS,
    entradaMercanciaProductos
  }
}

export function duplicarDatosModalEntradaMercanciaAnadido (idElemento) {
  return {
    type: actionTypes.DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO,
    idElemento
  }
}

export function duplicarDatosModalEntradaMercanciaAnadidoSuccess (dataProductoDuplicar) {
  return {
    type: actionTypes.DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS,
    dataProductoDuplicar
  }
}

export function onDeleteEntradaMercanciaProducto (idElemento, idProducto) {
  return {
    type: actionTypes.ON_DELETE_ENTRADA_MERCANCIA_PRODUCTO,
    idElemento,
    idProducto
  }
}

export function guardarYnuevoEntradaMercanciaProducto (entradaMercanciaProductos, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_PRODUCTO,
    entradaMercanciaProductos,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarYnuevoEntradaMercancia (values) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA,
    values
  }
}

export function guardarYnuevoEntradaMercanciaSuccess (values) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_SUCCESS,
    values
  }
}

export function resetDatosEntradaMercanciaProducto () {
  return {
    type: actionTypes.RESET_DATOS_ENTRADA_MERCANCIA_PRODUCTO
  }
}

export function deleteSeleccionEntradaMercanciaProductoAnadido (list) {
  return {
    type: actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO,
    list
  }
}

export function deleteSeleccionEntradaMercanciaProductoAnadidoSuccess (datosAnadidos) {
  return {
    type: actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS,
    datosAnadidos
  }
}

export default {
  closeModalEntradaMercancia,
  abrirModalEntradaMercancia,
  submitNuevoEntradaMercancia,
  submitNuevoEntradaMercanciaSuccess,
  obtenerEntradaMercanciasMercancia,
  filtrarEntradaMercancia,
  filtrarEntradaMercanciaSuccess,
  filtrosEntradaMercanciaSuccess,
  recuperarDatosModalEntradaMercancia,
  recuperarDatosModalEntradaMercanciaSuccess,
  editarEntradaMercancia,
  duplicarDatosModalEntradaMercancia,
  duplicarDatosModalEntradaMercanciaSuccess,
  cambiarEstadoEntradaMercancia,
  onDeleteEntradaMercancia,
  seleccionarTodosEntradaMercancia,
  deSeleccionarTodosEntradaMercancia,
  cambiarEstadoSeleccionEntradaMercancia,
  deleteSeleccionEntradaMercancia,
  crearCsvEntradaMercancia,
  imprimirPdfEntradaMercancia,
  crearCsvEntradaMercancia,
  crearCsvEntradaMercanciaSuccess,
  imprimirPdfEntradaMercancia,
  imprimirPdfEntradaMercanciaSuccess,
  abrirModalEntradaMercanciaAnadirProducto,
  cerrarModalEntradaMercanciaAnadirProducto,
  seleccionarTodosEntradaMercanciaProducto,
  deSeleccionarTodosEntradaMercanciaProducto,
  anadirProductoEntradaMercancia,
  recuperarModalEntradaMercanciaProductoAnadido,
  recuperarModalEntradaMercanciaProductoAnadidoSuccess,
  editarEntradaMercanciaProducto,
  editarEntradaMercanciaProductoSuccess,
  onDeleteEntradaMercanciaProducto,
  guardarYnuevoEntradaMercanciaProducto,
  guardarYnuevoEntradaMercancia,
  guardarYnuevoEntradaMercanciaSuccess,
  resetDatosEntradaMercanciaProducto,
  deleteSeleccionEntradaMercanciaProductoAnadido,
  deleteSeleccionEntradaMercanciaProductoAnadidoSuccess
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRaza from '../../components/raza/ModalRaza'
import {recuperarDatosModalRaza, submitNuevaRaza, editarRaza, guardarYnuevoRaza, closeModalRaza} from '../../actions/raza/raza'

export function mapStateToProps (state) {
  return {
    ...state.raza,
    auth: state.auth,
    showModal: state.common.showModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalRaza,
      submitNuevaRaza,
      editarRaza,
      closeModalRaza,
      guardarYnuevoRaza
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRaza))
export default function (token) {
  try {
    console.log('Updating session token in localStorage')
    let session = localStorage.getItem('session')
    session = {...JSON.parse(session), token}
    localStorage.setItem('session', JSON.stringify(session))
    return true
  } catch (err) {
    console.log('updating token error:', err)
    return false
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalCliente from '../../components/cliente/ModalCliente'
import { comboUsuariosCentro, comboTipoDosis } from '../../actions/combos/combos'
import { recuperarDatosModalCliente, closeModalCliente, abrirModalDireccionesCliente, submitNuevoCliente, editarCliente, guardarYnuevoCliente, recuperarDatosModalDireccionCliente, duplicarDatosModalDireccionCliente,
  onDeleteDireccionCliente, seleccionarTodosDireccionesCliente, deSeleccionarTodosDireccionesCliente, deleteSeleccionDireccionCliente} from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalCliente,
      closeModalCliente,
      comboTipoDosis,
      abrirModalDireccionesCliente,
      submitNuevoCliente,
      editarCliente,
      guardarYnuevoCliente,
      recuperarDatosModalDireccionCliente,
      duplicarDatosModalDireccionCliente,
      onDeleteDireccionCliente,
      seleccionarTodosDireccionesCliente,
      deSeleccionarTodosDireccionesCliente,
      deleteSeleccionDireccionCliente
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCliente))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTipoDosisPanelControl from '../../components/panelControl/ModalTipoDosisPanelControl'
import {cerrarModalTipoDosis, anadirDosisPanelControl, obtenerTipoDosis, recuperarDatosTipoDosis, editarDosisPanelControl, duplicarDatosModalTipoDosis,
  cambiaPredeterminadoDosis, onDeleteDosis, seleccionarTodosTipoDosis, deSeleccionarTodosTipoDosis, deleteSeleccionTipoDosis
  } from '../../actions/panelControl/panelControl'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalTipoDosis,
      anadirDosisPanelControl,
      obtenerTipoDosis,
      recuperarDatosTipoDosis,
      editarDosisPanelControl,
      duplicarDatosModalTipoDosis,
      cambiaPredeterminadoDosis,
      onDeleteDosis,
      seleccionarTodosTipoDosis,
      deSeleccionarTodosTipoDosis,
      deleteSeleccionTipoDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTipoDosisPanelControl))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import {logout} from '../../actions/auth/auth'
import {comboObtenerCentroUsuario} from '../../actions/combos/combos'
import {cambiarCentro} from '../../actions/comun/comun'
import UserSubHeader from '../../components/layout/UserSubHeader'
import {abrirModalPerfil} from '../../actions/perfil/perfil'
import {obtenerNotificaconesUsuario} from '../../actions/notificaciones/notificaciones'

export function mapStateToProps (state) {
  return {
    showModalPerfil: state.perfil.showModalPerfil,
    auth: state.auth,
    combos: state.combos,
    initialValues: {
      centro: {
        value: state.auth.centro,
        label: state.auth.nombreCentro
      }
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      logout,
      comboObtenerCentroUsuario,
      cambiarCentro,
      abrirModalPerfil,
      obtenerNotificaconesUsuario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(UserSubHeader)))

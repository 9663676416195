import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Col, ControlLabel } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import {date_formatter} from '../../../util/formatFunctions'
import 'react-day-picker/lib/style.css'
import './InputRangeDatePicker.scss'
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";


// TODO Reemplazar por InputRangeDatePickerDynamicNames y mejorar los handlers.
class InputRangeDatePicker extends Component {
  constructor(props) {
    super(props)
    this.handleFromChange = this.handleFromChange.bind(this)
    this.handleToChange = this.handleToChange.bind(this)
    this.state = {
      from: undefined,
      to: undefined,
    }
  }
  showFromMonth() {
    if (!this.state.from) {
      return
    }
    if (moment(this.state.to).diff(moment(this.state.from), 'months') < 2) {
      this.to.getDayPicker().showMonth(this.state.from)
    }
  }
  handleFromChange(value) {
    let updateValue = ''
    if (value) {
      updateValue = value
    }
    this.setState({from: updateValue })
    this.props[this.props.nameFrom].input.onChange(updateValue)
    if (this.props.onInputChange) {
      setTimeout((function() {
        this.props.onInputChange(updateValue)
      }).bind(this), 200)
    }
  }
  handleToChange(value) {
    let updateValue = ''
    if (value) {
      updateValue = value
    }
    this.setState({to: updateValue }, this.showFromMonth)
    this.props[this.props.nameTo].input.onChange(updateValue)
    if (this.props.onInputChange) {
      setTimeout((function() {
        this.props.onInputChange(updateValue)
      }).bind(this), 200)
    }
  }
  UNSAFE_componentWillMount () {
    /*if (!this.props.fechaEntrada.input.value) {
      this.props.fechaEntrada.input.onChange(null)
    }
    if (!this.props.fechaSalida.input.value) {
      this.props.fechaSalida.input.onChange(null)
    }*/
  }
  render() {
    const {
      colSm, nameFrom, nameTo, fechaEntrada, fechaSalida, controlLabel, t, border,
      auth: {formaFechaHora, lunesInicioSemana}
    } = this.props

    const modifiers = { start: this.state.from, end: this.state.to }
    const weekdays_long = [
      t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_LONG.LUNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MARTES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_LONG.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_LONG.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.SABADO'),
    ];
    const weekdays_short = [
      t('COMUN.DATE.WEEKDAYS_SHORT.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_SHORT.LUNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MARTES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.SABADO'),
    ];
    const months = [
      t('COMUN.DATE.MONTHS.ENERO'),
      t('COMUN.DATE.MONTHS.FEBRERO'),
      t('COMUN.DATE.MONTHS.MARZO'),
      t('COMUN.DATE.MONTHS.ABRIL'),
      t('COMUN.DATE.MONTHS.MAYO'),
      t('COMUN.DATE.MONTHS.JUNIO'),
      t('COMUN.DATE.MONTHS.JULIO'),
      t('COMUN.DATE.MONTHS.AGOSTO'),
      t('COMUN.DATE.MONTHS.SEPTIEMBRE'),
      t('COMUN.DATE.MONTHS.OCTUBRE'),
      t('COMUN.DATE.MONTHS.NOVIEMBRE'),
      t('COMUN.DATE.MONTHS.DICIEMBRE'),
    ];

    let formatoFecha
    if (formaFechaHora === 'day/month/year/12'){
      formatoFecha = "DD/MM/YYYY"
    } else if (formaFechaHora === 'month/day/year/12'){
      formatoFecha = "MM/DD/YYYY"
    } else if (formaFechaHora === 'year/month/day/12'){
      formatoFecha = "YYYY/MM/DD"
    } else if (formaFechaHora === 'year/day/month/12'){
      formatoFecha = "YYYY/DD/MM"
    } else if (formaFechaHora === 'day/month/year/24'){
      formatoFecha = "DD/MM/YYYY"
    } else if (formaFechaHora === 'month/day/year/24'){
      formatoFecha = "MM/DD/YYYY"
    } else if (formaFechaHora === 'year/month/day/24'){
      formatoFecha = "YYYY/MM/DD"
    } else if (formaFechaHora === 'year/day/month/24'){
      formatoFecha = "YYYY/DD/MM"
    }

    return (
      <Col sm={colSm}>
        <div className="form-group">
          <div className="InputFromTo">
            {
              border !== false && (
              controlLabel && <ControlLabel>{controlLabel}</ControlLabel>
              )
            }
            {
              border !== false && (
              <br/>
              )
            }
            <DayPickerInput
              format={formatoFecha}
              formatDate={formatDate}
              parseDate={parseDate}
              inputProps={{
                name: nameFrom,
                autoComplete: "off",
                title: controlLabel + ' ' + t('COMUN.DATE.DESDE')
              }}
              value={fechaEntrada.input.value}
              placeholder={t('COMUN.DATE.DESDE')}
              /*formatDate={(date) => {
                return date_formatter(date, formaFechaHora, false);
              }}*/
              dayPickerProps={{
                months: months,
                weekdaysLong: weekdays_long,
                weekdaysShort: weekdays_short,
                firstDayOfWeek: (lunesInicioSemana ? 1 : 0),
                selectedDays: [this.state.from, { from: this.state.from, to: this.state.to }],
                disabledDays: { after: this.state.to },
                toMonth: this.state.to,
                modifiers,
                numberOfMonths: 2,
                onDayClick: () => this.to.getInput().focus()
              }}
              onDayChange={this.handleFromChange}

            />{
              border !== false && (
              ' — '
              )
            }
            <span className="InputFromTo-to">
              <DayPickerInput
                format={formatoFecha}
                formatDate={formatDate}
                parseDate={parseDate}
                inputProps={{
                  name: nameTo,
                  autoComplete: "off",
                  title: controlLabel + ' ' + t('COMUN.DATE.HASTA')
                }}
                ref={el => (this.to = el)}
                value={fechaSalida.input.value}
                placeholder={t('COMUN.DATE.HASTA')}
                /*formatDate={(date) => {
                  return date_formatter(date, formaFechaHora, false);
                }}*/
                dayPickerProps={{
                  months: months,
                  weekdaysLong: weekdays_long,
                  weekdaysShort: weekdays_short,
                  firstDayOfWeek: (lunesInicioSemana ? 1 : 0),
                  selectedDays: [this.state.from, { from: this.state.from, to: this.state.to }],
                  disabledDays: { before: this.state.from },
                  modifiers,
                  month: this.state.from,
                  fromMonth: this.state.from,
                  numberOfMonths: 2
                }}
                onDayChange={this.handleToChange}
              />
            </span>
          </div>
        </div>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(withTranslation()(InputRangeDatePicker))
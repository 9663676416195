import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosVerracoPool from '../../components/validacionMateriaPrima/FiltrosVerracoPool'
// import {} from '../../actions/combos/combos'
import {abrirModalVerracoPool, seleccionarTodosVerracoPoolAnadidos, deSeleccionarTodosVerracoPoolAnadidos, deleteSeleccionVerracoPool, editarVerracoMateriaPrima,
editarPoolMateriaPrima} from '../../actions/validacionMateriaPrima/validacionMateriaPrima'

export function mapStateToProps (state) {
  return {
    ...state.validacionMateriaPrima,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVerracoPool,
      seleccionarTodosVerracoPoolAnadidos,
      deSeleccionarTodosVerracoPoolAnadidos,
      deleteSeleccionVerracoPool,
      editarVerracoMateriaPrima,
      editarPoolMateriaPrima
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosVerracoPool))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import actionTypes from '../../../constants/actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'
import {fetchTipoIntervencionSanitariaSuccess, recuperarDatosModalTipoIntervencionSanitariaSuccess, duplicarDatosModalTipoIntervencionSanitariaSuccess, deSeleccionarTodosTipoIntervencionSanitaria,
  crearCsvTipoIntervencionSuccess, imprimirPdfTipoIntervencionSuccess} from '../../../actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import { yesNoModal } from '../../modal/yesNoModal'
import getTipoIntervencionSanitariaList from '../../../services/planSanitario/tipoIntervencionSanitaria/getTipoIntervencionSanitariaList'
import addTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/addTipoIntervencionSanitaria'
import getTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/getTipoIntervencionSanitaria'
import editTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/editTipoIntervencionSanitaria'
import getTipoIntervencionSanitariaParaDuplicar from '../../../services/planSanitario/tipoIntervencionSanitaria/getTipoIntervencionSanitariaParaDuplicar'
import getTipoIntervencionSanitariaCambioEstado from '../../../services/planSanitario/tipoIntervencionSanitaria/getTipoIntervencionSanitariaCambioEstado'
import deleteTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/deleteTipoIntervencionSanitaria'
import getCambiarEstadoSeleccionTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/getCambiarEstadoSeleccionTipoIntervencionSanitaria'
import getDeleteSeleccionTipoIntervencionSanitaria from '../../../services/planSanitario/tipoIntervencionSanitaria/getDeleteSeleccionTipoIntervencionSanitaria'
import getCrearCsvTipoIntervencion from '../../../services/planSanitario/tipoIntervencionSanitaria/getCrearCsvTipoIntervencion'
import getImprimirPdfTipoIntervencion from '../../../services/planSanitario/tipoIntervencionSanitaria/getImprimirPdfTipoIntervencion'

export function * fetchTipoIntervencionSanitaria () {
  try {
    const list = yield call(getTipoIntervencionSanitariaList, null)
    yield put(fetchTipoIntervencionSanitariaSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.FETCH_TIPO_INTERVENCION_SANITARIA, fetchTipoIntervencionSanitaria)
}

export function * abrirModalVacioTipoIntervencionSanitaria () {
  try {
    // yield put(recuperarDatosModalTipoIntervencionSanitariaSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalTipoIntervencionSanitaria', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_TIPO_INTERVENCION_SANITARIA, abrirModalVacioTipoIntervencionSanitaria)
}

export function * submitNuevoTipoIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoIntervencion = yield call(addTipoIntervencionSanitaria, values, auth.token)
    yield call(fetchTipoIntervencionSanitaria)
    yield put(closeModal())
    if (insercionTipoIntervencion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchSubmitNuevoTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_TIPO_INTERVENCION_SANITARIA, submitNuevoTipoIntervencionSanitaria)
}

export function * recuperarDatosModalTipoIntervencionSanitaria ({idTipoIntervencionSanitaria}) {
  try {
    yield put(duplicarDatosModalTipoIntervencionSanitariaSuccess(null))
    const registroModificar = yield call(getTipoIntervencionSanitaria, idTipoIntervencionSanitaria)
    yield put(recuperarDatosModalTipoIntervencionSanitariaSuccess(registroModificar))
    yield put(initialize('ModalTipoIntervencionSanitaria', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA, recuperarDatosModalTipoIntervencionSanitaria)
}

export function * editarTipoIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionTipoIntervencion = yield call(editTipoIntervencionSanitaria, values, auth.token)
    yield put(closeModal())
    yield call(fetchTipoIntervencionSanitaria)
    if (edicionTipoIntervencion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.EDITAR_TIPO_INTERVENCION_SANITARIA, editarTipoIntervencionSanitaria)
}

export function * duplicarDatosModalTipoIntervencionSanitaria ({idTipoIntervencionSanitaria}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalTipoIntervencionSanitariaSuccess(null))
    const duplicar = yield call(getTipoIntervencionSanitariaParaDuplicar, idTipoIntervencionSanitaria)
    duplicar.tipoIntervencion = duplicar.tipoIntervencion + ' - Copia'
    yield put(duplicarDatosModalTipoIntervencionSanitariaSuccess(duplicar))
    yield put(initialize('ModalTipoIntervencionSanitaria', duplicar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA, duplicarDatosModalTipoIntervencionSanitaria)
}

export function * cambiarEstadoTipoIntervencionSanitaria ({idTipoIntervencionSanitaria}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const dataEstado = yield call(getTipoIntervencionSanitariaCambioEstado, idTipoIntervencionSanitaria, null)
      yield call(fetchTipoIntervencionSanitaria)
      if (dataEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_TIPO_INTERVENCION_SANITARIA, cambiarEstadoTipoIntervencionSanitaria)
}

export function * onDeleteTipoIntervencionSanitaria ({idTipoIntervencionSanitaria}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const dataEliminar = yield call(deleteTipoIntervencionSanitaria , idTipoIntervencionSanitaria, null)
      yield call(fetchTipoIntervencionSanitaria)
      if (dataEliminar.eliminado === true && dataEliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataEliminar.eliminado === false && dataEliminar.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.ON_DELETE_TIPO_INTERVENCION_SANITARIA, onDeleteTipoIntervencionSanitaria)
}

export function * cambiarEstadoSeleccionTipoIntervencionSanitaria ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccionTipoIntervencionSanitaria, list, auth.token)
      if (dataEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosTipoIntervencionSanitaria())
      yield put(initialize('filtrosTipoIntervencionSanitaria'))
      const inicializaTabla = yield call(fetchTipoIntervencionSanitaria)
      yield put(initialize('simpleTable', inicializaTabla))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_INTERVENCION_SANITARIA, cambiarEstadoSeleccionTipoIntervencionSanitaria)
}

export function * deleteSeleccionTipoIntervencionSanitaria ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataEliminarSeleccion = yield call(getDeleteSeleccionTipoIntervencionSanitaria, list, auth.token)
      if (dataEliminarSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosTipoIntervencionSanitaria())
      yield put(initialize('filtrosTipoIntervencionSanitaria'))
      const inicializaTabla = yield call(fetchTipoIntervencionSanitaria)
      yield put(initialize('simpleTable', inicializaTabla))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TIPO_INTERVENCION_SANITARIA, deleteSeleccionTipoIntervencionSanitaria)
}

export function * guardarYnuevoTipoIntervencionSanitaria ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoIntervencion = yield call(addTipoIntervencionSanitaria, values, auth.token)
    const valores = {activo: true}
    yield put(initialize('ModalTipoIntervencionSanitaria', valores))
    yield call(fetchTipoIntervencionSanitaria)
    if (insercionTipoIntervencion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarYnuevoTipoIntervencionSanitaria () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_TIPO_INTERVENCION_SANITARIA, guardarYnuevoTipoIntervencionSanitaria)
}

export function * crearCsvTipoIntervencion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelTipoIntervencion = yield call(getCrearCsvTipoIntervencion, list, auth.token)
    yield put(crearCsvTipoIntervencionSuccess(datosExcelTipoIntervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvTipoIntervencion () {
  yield takeLatest(actionTypes.CREATE_CSV_TIPO_INTERVENCION, crearCsvTipoIntervencion)
}
export function * imprimirPdfTipoIntervencion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfTipoIntervencion = yield call(getImprimirPdfTipoIntervencion, list, auth.token)
    yield put(imprimirPdfTipoIntervencionSuccess(datosPdfTipoIntervencion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfTipoIntervencion () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_TIPO_INTERVENCION, imprimirPdfTipoIntervencion)
}

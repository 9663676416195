export default {
  ABRIR_MODAL_CLIENTE: 'ABRIR_MODAL_CLIENTE',
  CLOSE_MODAL_CLIENTE: 'CLOSE_MODAL_CLIENTE',
  SUBMIT_NUEVO_CLIENTE: 'SUBMIT_NUEVO_CLIENTE',
  SUBMIT_NUEVO_CLIENTE_SUCCESS: 'SUBMIT_NUEVO_CLIENTE_SUCCESS',
  OBTENER_CLIENTES_ALMACEN: 'OBTENER_CLIENTES_ALMACEN',
  FILTRAR_CLIENTE: 'FILTRAR_CLIENTE',
  FILTRAR_CLIENTE_SUCCESS: 'FILTRAR_CLIENTE_SUCCESS',
  FILTROS_CLIENTE_SUCCESS: 'FILTROS_CLIENTE_SUCCESS',
  RECUPERAR_DATOS_MODAL_CLIENTE: 'RECUPERAR_DATOS_MODAL_CLIENTE',
  RECUPERAR_DATOS_MODAL_CLIENTE_SUCCESS: 'RECUPERAR_DATOS_MODAL_CLIENTE_SUCCESS',
  EDITAR_CLIENTE: 'EDITAR_CLIENTE',
  DUPLICAR_DATOS_MODAL_CLIENTE: 'DUPLICAR_DATOS_MODAL_CLIENTE',
  DUPLICAR_DATOS_MODAL_CLIENTE_SUCCESS: 'DUPLICAR_DATOS_MODAL_CLIENTE_SUCCESS',
  CAMBIAR_ESTADO_CLIENTE: 'CAMBIAR_ESTADO_CLIENTE',
  ON_DELETE_CLIENTE: 'ON_DELETE_CLIENTE',
  SELECCIONAR_TODOS_CLIENTE: 'SELECCIONAR_TODOS_CLIENTE',
  DESELECCIONAR_TODOS_CLIENTE: 'DESELECCIONAR_TODOS_CLIENTE',
  CAMBIAR_ESTADO_SELECCION_CLIENTE: 'CAMBIAR_ESTADO_SELECCION_CLIENTE',
  DELETE_SELECCION_CLIENTE: 'DELETE_SELECCION_CLIENTE',
  CREATE_CSV_CLIENTE: 'CREATE_CSV_CLIENTE',
  CREATE_CSV_CLIENTE_SUCCESS: 'CREATE_CSV_CLIENTE_SUCCESS',
  IMPRIMIR_PDF_CLIENTE: 'IMPRIMIR_PDF_CLIENTE',
  IMPRIMIR_PDF_CLIENTE_SUCCESS: 'IMPRIMIR_PDF_CLIENTE_SUCCESS',
  ABRIR_MODAL_DIRECCIONES_CLIENTE: 'ABRIR_MODAL_DIRECCIONES_CLIENTE',
  CLOSE_MODAL_DIRECCIONES_CLIENTE: 'CLOSE_MODAL_DIRECCIONES_CLIENTE',
  GUARDAR_Y_NUEVO_CLIENTE: 'GUARDAR_Y_NUEVO_CLIENTE',
  GUARDAR_Y_NUEVO_CLIENTE_SUCCESS: 'GUARDAR_Y_NUEVO_CLIENTE_SUCCESS',
  CAMBIAR_ESTADO_SELECCION_CLIENTE: 'CAMBIAR_ESTADO_SELECCION_CLIENTE',
  EDITAR_DIRECCION_CLIENTE: 'EDITAR_DIRECCION_CLIENTE',
  SUBMIT_DIRECCION_CLIENTE: 'SUBMIT_DIRECCION_CLIENTE',
  RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE: 'RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE',
  RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS: 'RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS',
  EDITAR_DIRECCION_CLIENTE_SUCCESS: 'EDITAR_DIRECCION_CLIENTE_SUCCESS',
  DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE: 'DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE',
  DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS: 'DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS',
  ON_DELETE_DIRECCION_CLIENTE: 'ON_DELETE_DIRECCION_CLIENTE',
  SELECCIONAR_TODOS_DIRECCIONES_CLIENTE: 'SELECCIONAR_TODOS_DIRECCIONES_CLIENTE',
  DESELECCIONAR_TODOS_DIRECCIONES_CLIENTE: 'DESELECCIONAR_TODOS_DIRECCIONES_CLIENTE',
  DELETE_SELECCION_DIRECCION_CLIENTE: 'DELETE_SELECCION_DIRECCION_CLIENTE',
  CARGAR_PEDIDOS_CLIENTE: 'CARGAR_PEDIDOS_CLIENTE',
  ABRIR_MODAL_DIRECCONES_RUTA: 'ABRIR_MODAL_DIRECCONES_RUTA',
  CERRAR_MODAL_DIRECCONES_RUTA: 'CERRAR_MODAL_DIRECCONES_RUTA',
  ANADIR_DATOS_RUTA: 'ANADIR_DATOS_RUTA',
  ANADIR_DATOS_RUTA_SUCCESS: 'ANADIR_DATOS_RUTA_SUCCESS',
  ON_DELETE_RUTA_CLIENTE: 'ON_DELETE_RUTA_CLIENTE',
  ACTUALIZAR_DATOS_RUTA: 'ACTUALIZAR_DATOS_RUTA',
  COMBO_RUTAS_NO_DIRECCIONES_CLIENTE: 'COMBO_RUTAS_NO_DIRECCIONES_CLIENTE',
  COMBO_RUTAS_NO_DIRECCIONES_CLIENTE_SUCCESS: 'COMBO_RUTAS_NO_DIRECCIONES_CLIENTE_SUCCESS',
  CARGAR_ALBARAN_CLIENTE: 'CARGAR_ALBARAN_CLIENTE',
  CARGAR_DOSIS_ENVIADAS_CLIENTE: 'CARGAR_DOSIS_ENVIADAS_CLIENTE',
  SELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA: 'SELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA',
  DESELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA: 'DESELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA',
  SELECCIONAR_TODOS_MODAL_DIRECCIONES_ARTICULOS: 'SELECCIONAR_TODOS_MODAL_DIRECCIONES_ARTICULOS',
  DESELECCIONAR_TODOS_MODAL_DIRECCIONES_ARTICULOS: 'DESELECCIONAR_TODOS_MODAL_DIRECCIONES_ARTICULOS',
  ABRIR_MODAL_DATOS_ARTICULOS_DOSIS: 'ABRIR_MODAL_DATOS_ARTICULOS_DOSIS',
  CERRAR_MODAL_DATOS_ARTICULOS_DOSIS: 'CERRAR_MODAL_DATOS_ARTICULOS_DOSIS',
  ANADIR_DATOS_ARTICULOS_DOSIS: 'ANADIR_DATOS_ARTICULOS_DOSIS',
  ANADIR_DATOS_ARTICULOS_DOSIS_SUCCESS: 'ANADIR_DATOS_ARTICULOS_DOSIS_SUCCESS',
  ON_DELETE_DATOS_ARTICULOS_DOSIS: 'ON_DELETE_DATOS_ARTICULOS_DOSIS',
  ACTUALIZAR_DATOS_ARTICULOS_DOSIS: 'ACTUALIZAR_DATOS_ARTICULOS_DOSIS'
}
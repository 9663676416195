import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalValidacionMateriaPrima from '../../components/validacionMateriaPrima/ModalValidacionMateriaPrima'
import { comboProductosValidacionActivos, comboLotesProductoEspecificoActivo } from '../../actions/combos/combos'
import { recuperarDatosModalValidacionMateriaPrima, closeModalValidacionMateriaPrima, submitNuevoValidacionMateriaPrima, editarValidacionMateriaPrima, recuperarDatosModalVerracoPool, duplicarDatosModalVerracoPool, onDeleteVerracoPool, guardarYnuevoValidacionMateriaPrima
  } from '../../actions/validacionMateriaPrima/validacionMateriaPrima'

export function mapStateToProps (state) {
  return {
    ...state.validacionMateriaPrima,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalValidacionMateriaPrima,
      closeModalValidacionMateriaPrima,
      submitNuevoValidacionMateriaPrima,
      editarValidacionMateriaPrima,
      comboProductosValidacionActivos,
      comboLotesProductoEspecificoActivo,
      recuperarDatosModalVerracoPool,
      duplicarDatosModalVerracoPool,
      onDeleteVerracoPool,
      guardarYnuevoValidacionMateriaPrima
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalValidacionMateriaPrima))
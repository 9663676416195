import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import filtros from '../../components/lineaGenetica/FiltrosLineaGenetica'
import {openModal} from '../../actions/common'
import {abrirModalVacio, fetchFiltrarLinea, seleccionarTodosLineaGenetica, deSeleccionarTodosLineaGenetica, cambiarEstadoSeleccion, deleteSeleccion, imprimirPdfLinea,
crearCsv} from '../../actions/lineaGenetica/lineaGenetica'
import {comboCodLineaGenetica, comboLineaGenetica} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.lineaGenetica,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacio,
      comboCodLineaGenetica,
      comboLineaGenetica,
      fetchFiltrarLinea,
      seleccionarTodosLineaGenetica,
      deSeleccionarTodosLineaGenetica,
      cambiarEstadoSeleccion,
      deleteSeleccion,
      crearCsv,
      imprimirPdfLinea
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(filtros))
import {select, put, call, takeLatest} from 'redux-saga/effects'
import { initialize } from 'redux-form';
import i18n from '../../../i18n';

// Constants
import actionTypes from '../../../constants/actions/admin/licencias/licencias'

// Actions
import {openSimpleModal, openMensajeAlerta, openMensajeAlertaCustomText} from '../../../actions/common'
import {
  fetchLicenciasSuccess, abrirModalLicenciasSuccess, cerrarModalLicenciasSuccess
} from '../../../actions/admin/licencias/licencias'

// Services
import getLicenciasService from '../../../services/admin/licencias/getLicencias'
import getLicenciaService from '../../../services/admin/licencias/getLicencia'
import addLicenciaService from '../../../services/admin/licencias/addLicencia'
import editLicenciaService from '../../../services/admin/licencias/editLicencia'
import deleteLicenciaService from '../../../services/admin/licencias/deleteLicencia'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'

export function * fetchLicencias () {
  try {
    let state = yield select(state => state)
    let list = yield call(getLicenciasService)
    list.map((licencia) => {
      licencia.modalidad = ''
      if (licencia.idModalidad) {
        licencia.modalidad = licencia.idModalidad.split(';').map((idModalidad) => {
          let modalidad = state.combos.comboModulos.find((modalidadLicencia) => {
            return modalidadLicencia.value === idModalidad
          })
          if (modalidad) modalidad = i18n.t(modalidad.label)
          return modalidad
        }).join(', ')
      }
      return licencia
    })
    yield put(fetchLicenciasSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchLicencias () {
  yield takeLatest(actionTypes.FETCH_LICENCIAS, fetchLicencias)
}

export function * abrirModalLicencias ({idLicencia}) {
  try {
    let state = yield select(state => state)
    let data = {}
    if (idLicencia) {
      data = yield call(getLicenciaService, idLicencia)
      if (data.idModalidad) {
        let modalidades = data.idModalidad.split(';')
        modalidades.forEach((idModalidad) => {
          data["modalidad" + idModalidad] = true
        })
      }
      if (data.customizations) {
        let customizations = data.customizations.split(';')
        customizations.forEach((customization) => {
          data["customizacion" + customization] = true
        })
      }
    }
    let arrayVersionesMagavision = []
    if (data.versionesMagavision && data.versionesMagavision !== null) {
      arrayVersionesMagavision = data.versionesMagavision.split(',')
    }
    if (arrayVersionesMagavision.length > 0) {
      arrayVersionesMagavision.forEach((version) => {
        if (version === "3") {
          data.magavisionI = true
        }
        if (version === "4") {
          data.magavisionM = true
        }
        if (version === "5") {
          data.magavision = true
        }
        if (version === "6") {
          data.magavisionML = true
        }
      })
    }
    yield put(initialize('ModalLicencias', data))
    yield put(abrirModalLicenciasSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirModalLicencias () {
  yield takeLatest(actionTypes.ABRIR_MODAL_LICENCIAS, abrirModalLicencias)
}

export function * submitLicencia ({values, idLicencia}) {
  try {
    let token = yield select(state => state.auth.token)
    
    values.idModalidad = []
    if (values.modalidad1) values.idModalidad.push('1')
    if (values.modalidad2) values.idModalidad.push('2')
    if (values.modalidad3) values.idModalidad.push('3')
    if (values.modalidad4) values.idModalidad.push('4')
    if (values.modalidad5) values.idModalidad.push('5')
    values.idModalidad = values.idModalidad.join(';')

    values.customizations = []
    if (values.customizacionPICTraq) values.customizations.push('PICTraq');    
    if (values.customizacionUSA) values.customizations.push('USA');
    if (values.customizacionMagapor) values.customizations.push('Magapor');
    if (values.customizacionAgView) values.customizations.push('AgView');
    if (values.customizacionITM) values.customizations.push('ITM');
    if (values.customizacionDataSwine) values.customizations.push('DataSwine');
    if (values.customizacionAbuelosBagmatic) values.customizations.push('AbuelosBagmatic');
    if (values.customizacionNetpork) values.customizations.push('Netpork');
    values.customizations = values.customizations.join(';')

    let cerrarModal = true
    if (idLicencia) {
      values.idLicencia = idLicencia
      const {actualizado} = yield call(editLicenciaService, values, token)
      if (actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
    } else {
      const {insertado, mensaje} = yield call(addLicenciaService, values, token)
      if (insertado === true) {
        yield put(openMensajeAlerta('insercion'))
      } else if(mensaje) {
        cerrarModal = false
        yield put(openMensajeAlertaCustomText(i18n.t('LICENCIAS.MODAL.MENSAJES.' + mensaje), true))
      } else {
        yield put(openMensajeAlerta('no-insercion'))
      }
    }

    if (cerrarModal) {
      yield put(cerrarModalLicenciasSuccess())
      yield call(fetchLicencias)
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchSubmitLicencia () {
  yield takeLatest(actionTypes.SUBMIT_LICENCIA, submitLicencia)
}

export function * cerrarModalLicencias () {
  try {
    yield put(cerrarModalLicenciasSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCerrarModalLicencias () {
  yield takeLatest(actionTypes.CERRAR_MODAL_LICENCIAS, cerrarModalLicencias)
}

export function * onDeleteLicencia ({idLicencia}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteLicenciaService, idLicencia, null)
      yield call(fetchLicencias)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteLicencia () {
  yield takeLatest(actionTypes.ON_DELETE_LICENCIA, onDeleteLicencia)
}
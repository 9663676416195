import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Usuarios from '../../components/usuarios/Usuarios'
import { fetchUsuarios, recuperarDatosUsuario, duplicarUsuario, cambiarEstadoUsuario, eliminarUsuario, abrirModalCambiarPass } from '../../actions/usuarios/usuarios'
import { yesNoModal } from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUsuarios,
      recuperarDatosUsuario,
      duplicarUsuario,
      cambiarEstadoUsuario,
      eliminarUsuario,
      abrirModalCambiarPass,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Usuarios))
export default {
  FETCH_TIPO_INSTALACION: 'FETCH_TIPO_INSTALACION',
  FETCH_TIPO_INSTALACION_SUCCESS: 'FETCH_TIPO_INSTALACION_SUCCESS',
  SUBMIT_NUEVA_TIPO_INSTALACION: 'SUBMIT_NUEVA_TIPO_INSTALACION',
  SUBMIT_NUEVA_TIPO_INSTALACION_SUCCESS: 'SUBMIT_NUEVA_TIPO_INSTALACION_SUCCESS',
  RECUPERAR_DATOS_MODAL_TIPO_INSTALACION: 'RECUPERAR_DATOS_MODAL_TIPO_INSTALACION',
  RECUPERAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS',
  ABRIR_MODAL_VACIO_TIPO_INSTALACION: 'ABRIR_MODAL_VACIO_TIPO_INSTALACION',
  EDITAR_TIPO_INSTALACION: 'EDITAR_TIPO_INSTALACION',
  EDITAR_TIPO_INSTALACION_SUCCESS: 'EDITAR_TIPO_INSTALACION_SUCCESS',
  DUPLICAR_DATOS_MODAL_TIPO_INSTALACION: 'DUPLICAR_DATOS_MODAL_TIPO_INSTALACION',
  DUPLICAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS: 'DUPLICAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS',
  CAMBIAR_ESTADO_TIPO_INSTALACION: 'CAMBIAR_ESTADO_TIPO_INSTALACION',
  CAMBIAR_ESTADO_TIPO_INSTALACION_SUCCESS: 'CAMBIAR_ESTADO_TIPO_INSTALACION_SUCCESS',
  ON_DELETE_TIPO_INSTALACION: 'ON_DELETE_TIPO_INSTALACION',
  ON_DELETE_TIPO_INSTALACION_SUCCESS: 'ON_DELETE_TIPO_INSTALACION_SUCCESS',
  FETCH_FILTRAR_TIPO_INSTALACION: 'FETCH_FILTRAR_TIPO_INSTALACION',
  FETCH_FILTRAR_TIPO_INSTALACION_SUCCESS: 'FETCH_FILTRAR_TIPO_INSTALACION_SUCCESS',
  SELECCIONAR_TODOS_TIPO_INSTALACION: 'SELECCIONAR_TODOS_TIPO_INSTALACION',
  DESELECCIONAR_TODOS_TIPO_INSTALACION: 'DESELECCIONAR_TODOS_TIPO_INSTALACION',
  CAMBIAR_ESTADO_SELECCION_TIPO_INSTALACION: 'CAMBIAR_ESTADO_SELECCION_TIPO_INSTALACION',
  DELETE_SELECCION_TIPO_INSTALACION: 'DELETE_SELECCION_TIPO_INSTALACION',
  GUARDAR_Y_NUEVO_TIPO_INSTALACION: 'GUARDAR_Y_NUEVO_TIPO_INSTALACION',
  CREATE_CSV_TIPO_INSTALACION: 'CREATE_CSV_TIPO_INSTALACION',
  CREATE_CSV_TIPO_INSTALACION_SUCCESS: 'CREATE_CSV_TIPO_INSTALACION_SUCCESS',
  IMPRIMIR_PDF_TIPO_INSTALACION: 'IMPRIMIR_PDF_TIPO_INSTALACION',
  IMPRIMIR_PDF_TIPO_INSTALACION_SUCCESS: 'IMPRIMIR_PDF_TIPO_INSTALACION_SUCCESS'
}

import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import {Delete, AddCircleOutline, Refresh, Launch, Print, FilterList} from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosGrupoUsuarios extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
    this.fetchGrupoUsuarios = this.fetchGrupoUsuarios.bind(this)
    this.abrirModalGrupoUsuarios = this.abrirModalGrupoUsuarios.bind(this)
    this.crearCsvGrupoUsuarios = this.crearCsvGrupoUsuarios.bind(this)
    this.imprimirPdfGrupoUsuarios = this.imprimirPdfGrupoUsuarios.bind(this)
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosGrupoUsuarios()
    } else {
      this.props.actions.deSeleccionarTodosGrupoUsuarios()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-grupo-usuarios', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosGrupoUsuarios()
          }
        }
      }
    }
  }

  fetchGrupoUsuarios (values) {
    this.props.actions.fetchGrupoUsuarios(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  deleteSeleccionGrupoUsuarios () {
    const itemsSeleccion = this.props.listGrupoUsuarios.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.deleteSeleccionGrupoUsuarios(list)
    this.props.actions.deSeleccionarTodosGrupoUsuarios()
  }

  cambioEstadoSeleccionGrupoUsuarios() {
    const itemsSeleccion = this.props.listGrupoUsuarios.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.cambioEstadoSeleccionGrupoUsuarios(list)
    this.props.change('seleccionar-todos-grupo-usuarios', false)
    this.props.actions.deSeleccionarTodosGrupoUsuarios()
  }

  abrirModalGrupoUsuarios () {
    this.props.actions.abrirModalGrupoUsuarios()
  }

  crearCsvGrupoUsuarios () {
    this.props.actions.crearCsvGrupoUsuarios(this.props.filtros)
  }

  imprimirPdfGrupoUsuarios () {
    this.props.actions.imprimirPdfGrupoUsuarios(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchGrupoUsuarios(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  componentDidMount () {
    this.props.actions.comboGrupoUsuariosCentro()
  }

  render () {
    const {
      t, handleSubmit, filtros,
      combos: {comboGrupoUsuariosCentro}
    } = this.props

    let comboEstado = this.props.combos.comboEstado
    comboEstado = comboEstado.map((estado) => {
      return {...estado, label: t(estado.label)}
    })

    const tKey = 'GRUPO_USUARIOS.'

    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="grupoUsuario"
              name="grupoUsuario"
              controlLabel={t(tKey + 'FILTROS.GRUPO_USUARIO')}
              component={InputSelect}
              options={comboGrupoUsuariosCentro}
              valueKey="value"
              labelKey="label"
              customClass='select-usuarios'
              disabled={false}
              onInputChange={handleSubmit(this.fetchGrupoUsuarios)}
            />
            <Field
              colSm={2}
              id="activo"
              name="activo"
              controlLabel={t(tKey + 'FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchGrupoUsuarios)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.grupoUsuario && <span key="filtros.grupoUsuario" className="badge-dark">{t(tKey + 'FILTROS.GRUPO_USUARIO') + ': ' + filtros.grupoUsuario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoUsuario')}> x </button></span>),
                    (filtros.activo && <span key="filtros.activo" className="badge-dark">{t(tKey + 'FILTROS.ESTADO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>),
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-grupo-usuarios"
                    name="seleccionar-todos-grupo-usuarios"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.cambioEstadoSeleccionGrupoUsuarios()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionGrupoUsuarios()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvGrupoUsuarios()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfGrupoUsuarios()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1) ? (e) => this.abrirModalGrupoUsuarios() : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosGrupoUsuarios'
})(FiltrosGrupoUsuarios))
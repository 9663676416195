import actionTypes from '../../constants/actions/movimientoVerraco/movimientoVerraco'

export function cerrarModalUbicacionMovimientoVerraco(values, esInsertar){
  return {
    type: actionTypes.CERRAR_MODAL_UBICACION_MOVIMIENTO_VERRACO,
    values,
    esInsertar
  }
}

export function submitNuevoMoviVerraco(values, esInsertar){
  return {
    type: actionTypes.SUBMIT_NUEVO_MOVI_VERRACO,
    values,
    esInsertar
  }
}

export function ubicacionenesVacias(values){
  return {
    type:actionTypes.UBICACIONES_VACIAS,
    values
  }
}

export function ubicacionenesVaciasSuccess(data){
  return {
    type:actionTypes.UBICACIONES_VACIAS_SUCCESS,
    data
  }
}

export function restartMovimientoVerraco () {
  return {
    type: actionTypes.RESTART_MOVIMIENTO_VERRACO
  }
}

export function enviarFechaMovimientoVerraco(values){
  return {
    type: actionTypes.ENVIAR_FECHA_MOVIMIENTO_VERRACO,
    values
  }
}

export function enviarFechaMovimientoVerracoSuccess(verracoMovidoHoy){
  return {
    type: actionTypes.ENVIAR_FECHA_MOVIMIENTO_VERRACO_SUCCESS,
    verracoMovidoHoy
  }
}

export function fetchMovimientoVerraco (){
  return {
    type: actionTypes.FETCH_MOVIMIENTO_VERRACO
  }
}

export function fetchMovimientoVerracoSuccess (list){
  return {
    type: actionTypes.FETCH_MOVIMIENTO_VERRACO_SUCCESS,
    list
  }
}

export function recuperarDatosModalMovimientoVerraco (idUbicacion){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO,
    idUbicacion
  }
}

export function recuperarDatosModalMovimientoVerracoSuccess (registroModificar){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS,
    registroModificar
  }
}

export function abrirModalVacioUbicacion (){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_UBICACION
  }
}

export function editarMoviVerraco (values, esInsertar) {
  return {
    type: actionTypes.EDITAR_MOVI_VERRACO,
    values,
    esInsertar
  }
}

export function duplicarDatosModalMovimientoVerraco (idUbicacion){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO,
    idUbicacion
  }
}

export function duplicarDatosModalMovimientoVerracoSuccess (registroDuplicar){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS,
    registroDuplicar
  }
}

export function onDeleteMovimientoVerraco (idUbicacion){
  return {
    type: actionTypes.ON_DELETE_MOVIMIENTO_VERRACO,
    idUbicacion
  }
}

export function seleccionarTodosMovimientoVerraco(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MOVIMIENTO_VERRACOS
  }
}

export function deSeleccionarTodosMovimientoVerraco(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MOVIMIENTO_VERRACOS
  }
}

export function deleteSeleccionMovimientoVerraco(list){
  return {
    type: actionTypes.DELETE_SELECCION_MOVIMIENTO_VERRACO,
    list
  }
}

export function fetchFiltrarMovimientoVerraco(values){
  return {
    type: actionTypes.FETCH_FILTRAR_MOVIMIENTO_VERRACO,
    values
  }
}

export function fetchFiltrarMovimientoVerracoSuccess(filtros){
  return {
    type: actionTypes.FETCH_FILTRAR_MOVIMIENTO_VERRACO_SUCCESS,
    filtros
  }
}

export function checkVerracosSinUbicacion(){
  return{
    type: actionTypes.CHECK_VERRACOS_SIN_UBICACION
  }
}

export function quitarCheckVerracosSinUbicacion(){
  return{
    type: actionTypes.QUITAR_CHECK_VERRACOS_SIN_UBICACION
  }
}

export function numVerracosSinUbicacionSuccess(numVerracosSinUbicacion){
  return{
    type: actionTypes.NUM_VERRACOS_SIN_UBICACION,
    numVerracosSinUbicacion
  }
}

export function origenMovimientoSuccess(origenMovimiento){
  return{
    type: actionTypes.ORIGEN_MOVIMIENTO_SUCCESS,
    origenMovimiento
  }
}

export function guardarYNuevoMovimiento(values, esInsertar){
  console.log('actions')
  return{
    type: actionTypes.GUARDAR_Y_NUEVO_MOVIMIENTO,
    values,
    esInsertar
  }
}

export function crearCsvMovimientoVerraco(list){
  return {
    type: actionTypes.CREATE_CSV_MOVIMIENTO_VERRACO,
    list
  }
}

export function crearCsvMovimientoVerracoSuccess(datosExcelMovimientoVerraco){
  return {
    type: actionTypes.CREATE_CSV_MOVIMIENTO_VERRACO_SUCCESS,
    datosExcelMovimientoVerraco
  }
}
export function imprimirPdfMovimientoVerraco(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_MOVIMIENTO_VERRACO,
    list
  }
}

export function imprimirPdfMovimientoVerracoSuccess(datosPdfMovimientoVerraco){
  return {
    type: actionTypes.IMPRIMIR_PDF_MOVIMIENTO_VERRACO_SUCCESS,
    datosPdfMovimientoVerraco
  }
}


export default {
  submitNuevoMoviVerraco,
  ubicacionenesVacias,
  ubicacionenesVaciasSuccess,
  restartMovimientoVerraco,
  enviarFechaMovimientoVerraco,
  enviarFechaMovimientoVerracoSuccess,
  fetchMovimientoVerraco,
  fetchMovimientoVerracoSuccess,
  recuperarDatosModalMovimientoVerraco,
  recuperarDatosModalMovimientoVerracoSuccess,
  abrirModalVacioUbicacion,
  editarMoviVerraco,
  duplicarDatosModalMovimientoVerraco,
  duplicarDatosModalMovimientoVerracoSuccess,
  onDeleteMovimientoVerraco,
  deleteSeleccionMovimientoVerraco,
  fetchFiltrarMovimientoVerraco,
  fetchFiltrarMovimientoVerracoSuccess,
  checkVerracosSinUbicacion,
  quitarCheckVerracosSinUbicacion,
  numVerracosSinUbicacionSuccess,
  origenMovimientoSuccess,
  guardarYNuevoMovimiento,
  crearCsvMovimientoVerraco,
  crearCsvMovimientoVerracoSuccess,
  imprimirPdfMovimientoVerraco,
  imprimirPdfMovimientoVerracoSuccess
}

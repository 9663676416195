import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {closeModal, nOkResponse, okResponse, openSimpleModal} from '../../../actions/common'
import SimpleModal from '../../../components/comun/modal/SimpleModal'
import { withTranslation } from 'react-i18next'

export function mapStateToProps (state) {
  return {
    ...state.common.modal,
    showSimpleModal: state.common.showSimpleModal,
    typeModal: state.common.typeModal,
    dynamicMessage: state.common.dynamicMessage,
    subDynamicMessage: state.common.subDynamicMessage,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      nOkResponse,
      okResponse,
      openSimpleModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SimpleModal))

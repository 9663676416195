import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import FiltrosModalAnnadirControlMorfologico from '../../../components/planSanitario/controlCalidad/FiltrosModalAnnadirControlMorfologico'
import {
  fetchControlMorfologico, seleccionarTodosModalControlMorfologico, deSeleccionarTodosModalControlMorfologico, deleteSeleccionControlMorfologico,
  crearCsvControlMorfologico, imprimirPdfControlMorfologico, abrirModalInicio, fetchFiltrarModalInicio, anadirVerracosModalInicio
} from '../../../actions/planSanitario/controlCalidad/controlMorfologico'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, fetchComboPool, comboCliente} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.ModalInicioControlMorfologico,
    auth: state.auth,
    filtros: state.controlMorfologico.filtrosInicio,
    seguimientoTable: state.form.seguimientoTable,
    periodoForm:state.form.ModalInicioControlMorfologico,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerracoSiNo: state.combos.comboVerracoSiNo,
      comboVerraco: state.combos.comboVerraco,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
      comboTipoControlSeguimiento: state.combos.comboTipoControlSeguimiento,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchControlMorfologico,
      seleccionarTodosModalControlMorfologico,
      deSeleccionarTodosModalControlMorfologico,
      deleteSeleccionControlMorfologico,
      comboVerraco,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      comboCliente,
      crearCsvControlMorfologico,
      imprimirPdfControlMorfologico,
      abrirModalInicio,
      fetchFiltrarModalInicio,
      anadirVerracosModalInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalAnnadirControlMorfologico))
import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosAsignarAnalisisPoolPage from '../../containers/poolExtracciones/FiltrosAsignarAnalisisPoolPage'
import { required } from '../../util/validationFunctions'
import { round_decimal } from '../../util/formatFunctions'

class ModalAsignacionAnalisisPool extends Component {
  constructor () {
    super()
    this.state = {
      tipoDosis: null,
      totalDosis: null,
      totalDosisMililitros: null,
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }
    this.calcSumatorioAnalisisSeleccionados = this.calcSumatorioAnalisisSeleccionados.bind(this)
    this.handleChangeTipoDosis = this.handleChangeTipoDosis.bind(this)
    this.guardarModalAsignacionAnalisisPool = this.guardarModalAsignacionAnalisisPool.bind(this)
  }

  calcSumatorioAnalisisSeleccionados () {
    if (this.state.tipoDosis) {
      // Filtrar las seleccionadas
      let seleccionadosAnalisisList = this.props.disponiblesAnalisisList.filter((row) => {
        return this.props.formDisponiblesAnalisisTable['checkdisponiblesAnalisisTable' + row.idDilucion]
      })

      // Calcular la cantidad de dosis en mililitros sobre el conjunto de análisis seleccionados.
      // numDosis * volumenDosis
      let totalDosisMililitros = seleccionadosAnalisisList.reduce((acc, row) => {
        let tipoDosis = this.props.combos.comboTipoDosis.find((combo) => combo.value === row.idTipoDosis)
        return acc + (parseInt(row.dosisReales, 10) * parseFloat(tipoDosis.volumen))
      }, 0)
      let totalDosis = Math.round(totalDosisMililitros / parseFloat(this.state.tipoDosis.volumen))

      // Calcular el % de formas anormales sobre el conjunto de análisis seleccionados.
      let sumatorioNumerator = 0 // Es el producto del %FA, la concentración total y el volumen.
      let sumatorioDenominator = 0 // Es el producto de la concentración total y el volumen.
      let sumatorioFormasAnormales = 0
      let formasAnormalesPercent = 0
      seleccionadosAnalisisList.forEach((seleccionadoAnalisis) => {
        formasAnormalesPercent = parseFloat(seleccionadoAnalisis.faTotalPercent || 0)
        sumatorioNumerator += formasAnormalesPercent * parseFloat(seleccionadoAnalisis.concentracionTotal) * parseFloat(seleccionadoAnalisis.volumen)
        sumatorioDenominator += parseFloat(seleccionadoAnalisis.concentracionTotal) * parseFloat(seleccionadoAnalisis.volumen)
      })

      if (sumatorioDenominator > 0) { // No se puede dividir entre 0.
        sumatorioFormasAnormales = round_decimal(sumatorioNumerator / sumatorioDenominator, 2, this.props.auth.separadorDec, this.props.auth.separadorMil)
      }

      this.setState({totalDosis, totalDosisMililitros})
      this.props.change('totalDosis', totalDosis)
      this.props.change('formasAnormales', sumatorioFormasAnormales)
    }
  }

  handleChangeTipoDosis (tipoDosis) {
    this.setState({tipoDosis}, this.calcSumatorioAnalisisSeleccionados)
  }

  guardarModalAsignacionAnalisisPool (mantenerModal) {
    let seleccionadosAnalisisList = this.props.disponiblesAnalisisList.filter((row) => {
      return this.props.formDisponiblesAnalisisTable['checkdisponiblesAnalisisTable' + row.idDilucion]
    })
    if (!this.state.tipoDosis) {
      this.props.actions.yesNoModal('tipoDosisRequerido')
    } else if (!seleccionadosAnalisisList || seleccionadosAnalisisList.length < 1) {
      this.props.actions.yesNoModal('unoAnalisisExtraccionRequerido')
    } else {
      this.props.actions.guardarModalAsignacionAnalisisPool(seleccionadosAnalisisList, mantenerModal)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.checkedFiltrosDisponiblesExtracciones !== prevProps.checkedFiltrosDisponiblesExtracciones) {
      setTimeout(this.calcSumatorioAnalisisSeleccionados, 200)
    }

    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.metodoEvaluacion})
        if (this.props.panelControl.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }

    if (this.props.showModalAsignacionAnalisisPool !== prevProps.showModalAsignacionAnalisisPool) {
      let tipoDosis = this.props.combos.comboTipoDosis.filter((combo) => {
        if (combo.predeterminado === true) {
          return combo
        }
      })
      this.setState({tipoDosis: tipoDosis[0]})
    }
  }

  render () {
    const {
      t, showModalAsignacionAnalisisPool, checkedFiltrosDisponiblesExtracciones, numeroRegistrosPorPagina, paginaActual, razaSeleccionada,
      disponiblesAnalisisList,
      combos: {comboTipoDosis},
      actions: {cerrarModalAsignacionAnalisisPool}
    } = this.props

    const tKey = 'POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.'
    const idElemento = 'idDilucion'
    const idForm = 'disponiblesAnalisisTable'

    const tableDisponiblesAnalisis = {
      id: 'disponiblesAnalisisTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: true,
      onCheckboxChange: () => {this.calcSumatorioAnalisisSeleccionados()},
      mostrarModificar: false,
      hasOptions: false,
      noBlockOptions: false,
      idElemento,
      columns: this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
        {id: 'codigo', name: t(tKey + 'TABLE.COLUMNS.COD_EXTRACCION'), type: 'numero', numDecimales: 0},
        {id: 'codVerraco', name: t(tKey + 'TABLE.COLUMNS.VERRACO')},
        {id: 'nombreRaza', name: t(tKey + 'TABLE.COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'TABLE.COLUMNS.LIN_GENETICA')},
        {id: 'nombreTipoDosis', name: t(tKey + 'TABLE.COLUMNS.TIPO_DOSIS')},
        {id: 'volumen', name: t(tKey + 'TABLE.COLUMNS.VOL'), type: 'numero', numDecimales: 0},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'formasAnormales', name: t(tKey + 'TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        {id: 'dosisReales', name: t(tKey + 'TABLE.COLUMNS.DOSIS'), type: 'numero', numDecimales: 0},
        {id: 'indexVerraco', name: t(tKey + 'TABLE.COLUMNS.INDEX')}
      ]) : ([
        {id: 'codigo', name: t(tKey + 'TABLE.COLUMNS.COD_EXTRACCION'), type: 'numero', numDecimales: 0},
        {id: 'codVerraco', name: t(tKey + 'TABLE.COLUMNS.VERRACO')},
        {id: 'nombreRaza', name: t(tKey + 'TABLE.COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'TABLE.COLUMNS.LIN_GENETICA')},
        {id: 'nombreTipoDosis', name: t(tKey + 'TABLE.COLUMNS.TIPO_DOSIS')},
        {id: 'volumen', name: t(tKey + 'TABLE.COLUMNS.VOL'), type: 'numero', numDecimales: 0},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'formasAnormales', name: t(tKey + 'TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        {id: 'dosisReales', name: t(tKey + 'TABLE.COLUMNS.DOSIS'), type: 'numero', numDecimales: 0},
        {id: 'indexVerraco', name: t(tKey + 'TABLE.COLUMNS.INDEX')}
      ]),
      rows: disponiblesAnalisisList,
      filtros: <FiltrosAsignarAnalisisPoolPage razaSeleccionada={razaSeleccionada} idElemento={idElemento} idForm={idForm} onUpdateList={()=>{ this.calcSumatorioAnalisisSeleccionados() }} />,
      initialValues: {}
    }
    tableDisponiblesAnalisis.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableDisponiblesAnalisis.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosDisponiblesExtracciones
        }
      }
    )

    return (
      <Modal show={showModalAsignacionAnalisisPool} onHide={cerrarModalAsignacionAnalisisPool} bsSize="large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tKey + 'TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <SimpleTablePage {...tableDisponiblesAnalisis}></SimpleTablePage>
            </Col>
          </Row>
          <hr />
          <Row>
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t(tKey + 'FORM.TIPO_DOSIS') + '*'}
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              isClearable={false}
              noPredeterminado={false}
              onInputChange={(tipoDosis) => this.handleChangeTipoDosis(tipoDosis)}
              validate={required}
            />
            <Field
              colSm={2}
              id="totalDosis"
              name="totalDosis"
              controlLabel={t(tKey + 'FORM.TOTAL_DOSIS')}
              component={InputNumerical}
              numDecimales={0}
              disabled={true}
            />
            <Field
              colSm={2}
              id="formasAnormales"
              name="formasAnormales"
              controlLabel={t(tKey + 'FORM.FORMAS_ANORMALES')}
              component={InputNumerical}
              numDecimales={2}
              disabled={true}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={() => this.guardarModalAsignacionAnalisisPool(true)}>{t(tKey + 'BUTTONS.ANADIR_Y_NUEVO')}</Button>
          <Button type="submit" className="btn btn-primary" onClick={() => this.guardarModalAsignacionAnalisisPool(false)}>{t(tKey + 'BUTTONS.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalAsignacionAnalisisPool}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAsignacionAnalisisPool'
})(ModalAsignacionAnalisisPool))
import actionTypes from '../../../constants/actions/admin/licencias/licencias'

export function fetchLicencias(){
  return {
    type: actionTypes.FETCH_LICENCIAS
  }
}

export function fetchLicenciasSuccess(list){
  return {
    type: actionTypes.FETCH_LICENCIAS_SUCCESS,
    list
  }
}

export function abrirModalLicencias(idLicencia){
  return {
    type: actionTypes.ABRIR_MODAL_LICENCIAS,
    idLicencia
  }
}

export function abrirModalLicenciasSuccess(data){
  return {
    type: actionTypes.ABRIR_MODAL_LICENCIAS_SUCCESS,
    data
  }
}

export function submitLicencia (values, idLicencia) {
  return {
    type: actionTypes.SUBMIT_LICENCIA,
    values,
    idLicencia
  }
}

export function cerrarModalLicencias(){
  return {
    type: actionTypes.CERRAR_MODAL_LICENCIAS
  }
}

export function cerrarModalLicenciasSuccess(){
  return {
    type: actionTypes.CERRAR_MODAL_LICENCIAS_SUCCESS
  }
}

export function onDeleteLicencia (idLicencia) {
  return {
    type: actionTypes.ON_DELETE_LICENCIA,
    idLicencia
  }
}

export default {
  fetchLicencias,
  fetchLicenciasSuccess,
  abrirModalLicencias,
  abrirModalLicenciasSuccess,
  submitLicencia,
  cerrarModalLicencias,
  cerrarModalLicenciasSuccess,
  onDeleteLicencia
}

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Clearfix, Button } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalConectividadPage from '../../containers/conectividad/ModalConectividadPage'
import FiltrosConectividadPage from '../../containers/conectividad/FiltrosConectividadPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FormSubtitle from '../comun/form/FormSubtitle'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import { required } from '../../util/validationFunctions'
import { Warning } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class Conectividad extends Component {
  constructor () {
    super()
    this.state = {
      errorIp: false,
      habilitarCheckVpn: false
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.HERRAMIENTAS.CONECTIVIDAD') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarConectividad({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
    this.props.actions.obternerConectividadVpn()
  }

  submitConexionVpn (values) {
    values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
    values.conexionVPN = values.checkConexionVPN
    this.props.actions.actualizarConectividadVpn(values)

  }

  componentDidUpdate (prevProps) {
    if (this.props.conectividadForm !== prevProps.conectividadForm) {
      if (this.props.conectividadForm.syncErrors) {
        if (Object.keys(this.props.conectividadForm.syncErrors).length > 0) {
          if (this.props.conectividadForm.syncErrors.ipParte1 === "Campo obligatorio" || this.props.conectividadForm.syncErrors.ipParte2 === "Campo obligatorio" ||
            this.props.conectividadForm.syncErrors.ipParte3 === "Campo obligatorio" || this.props.conectividadForm.syncErrors.ipParte4 === "Campo obligatorio") {
            if (this.props.conectividadForm.submitFailed && this.props.conectividadForm.submitFailed === true) {
              this.setState({errorIp: true})
            }
          } else {
            this.setState({errorIp: false})
          }
        } else{
          this.setState({errorIp: false})
        }
      } else {
        this.setState({errorIp: false})
      }
    }

    if (this.props.conectividad.dataVpn !== prevProps.conectividad.dataVpn ) {
      if (this.props.conectividad.dataVpn.conexionVPN === true) {
        this.props.change('checkConexionVPN', true)
        this.setState({habilitarCheckVpn: true})
      } else {
        this.props.change('checkConexionVPN', false)
        this.setState({habilitarCheckVpn: false})
      }
    }
  }

  handleChangeCheckVpn (value) {
    this.setState({habilitarCheckVpn: value})
    if (value === false) {
      this.setState({errorIp: false})
      // const valoresIniciales = {}
      //valoresIniciales.checkConexionVPN = value
      /*this.props.initialize(valoresIniciales)*/
      //this.props.actions.actualizarConectividadVpnVacio(valoresIniciales)
    }
    if (value === true) {
      const valoresIniciales = this.props.conectividad.dataVpn
      valoresIniciales.checkConexionVPN = value
      this.props.initialize(valoresIniciales)
    }
  }

  probarConexionVpn (values) {
     values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
      values.conexionVPN = values.checkConexionVPN
    this.props.actions.probarConexionVpn(values)
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual, conectividadForm,
      conectividad: { listConectividad, data, dataDuplicar, checkedFiltrosConectividad, dataDelete},
      actions: {recuperarDatosModalConectividad, duplicarDatosModalConectividad, cambiarEstadoConectividad, onDeleteConectividad }
    } = this.props

    listConectividad.forEach(
      (row) => row.idElemento = row.idMaquina
    )

    const table = {
      id: 'conectividadTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombre', name: t('CONECTIVIDAD.NOMBRE')},
        {id: 'maquina', name: t('CONECTIVIDAD.MAQUINA')},
        {id: 'IP', name: t('CONECTIVIDAD.DIRECCION_IP')},
        {id: 'observaciones', name: t('CONECTIVIDAD.OBSERVACIONES')}
      ],
      rows: listConectividad,
      filtros: <FiltrosConectividadPage />,
      onEdit: (row) => recuperarDatosModalConectividad(row.idMaquina),
      data: data,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1),
      duplicar: (row) =>duplicarDatosModalConectividad(row.idMaquina),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1),
      cambiarEstado: (row) => cambiarEstadoConectividad(row.idMaquina),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1),
      onDelete: (row) => onDeleteConectividad(row.idMaquina),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosConectividad
        }
      }
    )
    return (
      <div>
        <h2 className='page-title'>{t('CONECTIVIDAD.TITLE')}</h2>
        <FormSubtitle>{t('CONECTIVIDAD.VPN.TITLE')}</FormSubtitle>

        <Row>
          <Col sm={12}>
            <form className="form-conexion-vpn" onSubmit={handleSubmit(this.submitConexionVpn.bind(this))}>
              <Row>
                <Col sm={12}>
                  <Row className="row-flex">
                    <Field
                      id="checkConexionVPN"
                      name="checkConexionVPN"
                      colSm={0}
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('CONECTIVIDAD.TRABAJAR_CON_VPN')}
                      customClass="col-auto check-habilitar-conectividad"
                      onInputChange={(value) => this.handleChangeCheckVpn(value)}
                      disabled={this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2}
                    />
                    {
                      this.state.habilitarCheckVpn === true ?
                        <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary probar-conexion-vpn" onClick={handleSubmit(this.probarConexionVpn.bind(this))}>
                          {t('CONECTIVIDAD.VPN.PROBAR_CONEXION_VPN')}
                        </button>
                        : null
                    }
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={3}>
                      <Row className="row-collapsed">
                        <Col sm={12}>
                          <label className={this.state.errorIp === true ? 'error-form-conectividad' : ''}>
                            <b>
                              {t('CONECTIVIDAD.MODAL.DIRECCION_IP') + '*'}
                              {this.state.errorIp === true && <Warning/>}
                            </b>
                          </label>
                        </Col>
                        <Clearfix />
                        <Field
                          id="ipParte1"
                          name="ipParte1"
                          colSm={3}
                          component={InputNumerical}
                          numDecimales={0}
                          validate={this.state.habilitarCheckVpn === true ? required : null}
                          disabled={this.state.habilitarCheckVpn === false ? true : false}
                          minValue={0}
                          maxValue={255}
                        />
                        <Field
                          id="ipParte2"
                          name="ipParte2"
                          colSm={3}
                          component={InputNumerical}
                          numDecimales={0}
                          validate={this.state.habilitarCheckVpn === true ? required : null}
                          disabled={this.state.habilitarCheckVpn === false ? true : false}
                          minValue={0}
                          maxValue={255}
                        />
                        <Field
                          id="ipParte3"
                          name="ipParte3"
                          colSm={3}
                          component={InputNumerical}
                          numDecimales={0}
                          validate={this.state.habilitarCheckVpn === true ? required : null}
                          disabled={this.state.habilitarCheckVpn === false ? true : false}
                          minValue={0}
                          maxValue={255}
                        />
                        <Field
                          id="ipParte4"
                          name="ipParte4"
                          colSm={3}
                          component={InputNumerical}
                          numDecimales={0}
                          validate={this.state.habilitarCheckVpn === true ? required : null}
                          disabled={this.state.habilitarCheckVpn === false ? true : false}
                          minValue={0}
                          maxValue={255}
                        />
                      </Row>
                    </Col>
                    <Field
                      id="Nombre"
                      name="Nombre"
                      colSm={2}
                      component={InputText}
                      controlLabel={t('CONECTIVIDAD.VPN.NOMBRE') + '*'}
                      validate={this.state.habilitarCheckVpn === true ? required : null}
                      disabled={this.state.habilitarCheckVpn === false ? true : false}
                      maxLength={true}
                      valorMaxLength={100}
                    />
                    <Field
                      id="Password"
                      name="Password"
                      colSm={2}
                      component={InputText}
                      controlLabel={t('CONECTIVIDAD.VPN.CONTRASENA') + '*'}
                      validate={this.state.habilitarCheckVpn === true ? required : null}
                      disabled={this.state.habilitarCheckVpn === false ? true : false}
                      maxLength={true}
                      valorMaxLength={100}
                    />
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Button
                        type="submit"
                        style={{padding: '4px'}}
                        className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) ? ' disabled' : '')}
                        disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2)}
                      >{t('CONECTIVIDAD.MODAL.GUARDAR')}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={12}>
            <SimpleModalPage/>
            <MensajeAlertaPage/>
            <ModalConectividadPage
              duplicar={(row) =>duplicarDatosModalConectividad(row.idMaquina)}
            />
            <FormSubtitle style={{marginLeft: '5px'}}>{t('CONECTIVIDAD.MAQUINA')}</FormSubtitle>
            <ListadoPage t_key="CONECTIVIDAD." noTitle={true} table={table}>
            </ListadoPage>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formConectividad',
})(Conectividad))
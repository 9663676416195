import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimplePage from '../../containers/page/SimplePage'
import { date_parser, number_formatter, dateTimeZones } from '../../util/formatFunctions'
import FiltrosPoolExtraccionesPage from '../../containers/poolExtracciones/FiltrosPoolExtraccionesPage'
import { permisos as permisosConstant } from '../../constants/permisos'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import ModalNuevoPoolAsignacionPage from '../../containers/asignacionPoolVerraco/ModalNuevoPoolAsignacionPage';

class AsignacionPoolVerraco extends Component {
  constructor() {
    super();
    this.state = {
      PoolSeleccionado: null,
      idPool: null,
      idPoolAnterior: 0,
      nombrePool: null,
      analisisSeleccionado: null,
      annadiendo:true,
      eliminando:false,
      idRazaOrigen:null,
      idRazaDestino:null
    }
    this.RowClickCallback = this.RowClickCallback.bind(this);
    this.dragstartCallback = this.dragstartCallback.bind(this);
    this.dragendCallback = this.dragendCallback.bind(this);
    this.dropCallback = this.dropCallback.bind(this);
    this.dropCellCallback = this.dropCellCallback.bind(this);
  }

  componentDidMount() {
    this.props.actions.initializeFormPoolExtraccion();
    //this.props.actions.initializeExtraccionesPendientesAsignar();
  }

  RowClickCallback(row, idRow) {
    this.setState({
      idPoolAnterior: (this.state.idPool != this.state.idPoolAnterior) ? this.state.idPool : 0,
      idPool: row.idPool,
      nombrePool: row.nombrePool,
      selectedRow: idRow,
      idRaza: row.idRaza,
      idDilucion: row.idDilucion,
      filtroRazaTemporal: false,
      raza: row.nombreRaza
    },
      this.updateTableInfo
    );
  }

  updateTableInfo() {
    let filtros = { ...this.props.filtros, raza: { value: this.state.idRaza, label: this.state.raza }, 'filtrar_no_envasados': true }
    if (this.state.idPool == this.state.idPoolAnterior) {
      filtros = { ...this.props.filtros, raza: null }
    }
    this.props.actions.fetchPoolExtracciones(filtros)
    this.props.actions.recuperarDatosPool(this.state.idPool);
  }

  UNSAFE_componentWillMount() {
    document.title = this.props.t('MENU.EXTRACCIONES.ASIGNACION_POOL') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  crearNuevoPool(row) {
    this.props.actions.abrirModalNuevoPoolAsignacion(row);
  }


  /* necesitamos el id del analisis y el id del pool, tambien el id de la raza*/
  insertarExtraccionEnPool(idDilucion, idRaza) {    
    if (!this.state.idPool) this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('ASIGNACION_ANALISIS_POOL.ALERTAS.NO_POOL'))
    if (idRaza == this.state.idRaza) {
      if (this.state.idPool != null) {
        let nivelGeneticoPool = this.props.list.find(x => x.idPool == this.state.idPool).nivelGenetico
        let nivelGeneticoDilucion = this.props.pendientesAsignarList.find(x => x.idDilucion == idDilucion).nivelGenetico
        if((nivelGeneticoPool == null && nivelGeneticoDilucion != null)||(nivelGeneticoPool != null && nivelGeneticoDilucion == null)){
          this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('ASIGNACION_ANALISIS_POOL.ALERTAS.NIVEL_GENETICO'))
        }else{
          this.props.actions.addAnalisisPool(idDilucion, this.state.idPool, this.state.idRaza)
        }
      } else {
        this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('ASIGNACION_ANALISIS_POOL.ALERTAS.NO_POOL'))
      }
    } else {
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('ASIGNACION_ANALISIS_POOL.ALERTAS.RAZA_NO_CORRESPONDE'))
    }
  }

  /**
   * necesitamos el id de la dilucion
   */
  eliminarExtraccionDelPool(idDilucion) {
    this.props.actions.removeAnalisisPool(idDilucion, this.state.idPool, this.state.idRaza);
  }

  componentDidUpdate(prevProps) {
    if (this.props.idPoolSelected != prevProps.idPoolSelected) {  
      this.setState({
        idPool: this.props.idPoolSelected,
      })
    }
    //con un pool seleccionado, evitamos filtrar y que aparezca
    if(this.props.list != prevProps.list && this.props.list.find(x => x.idPool == this.state.idPool) === undefined){
      this.setState({
        idPool: null,
      })
    }
  }

  dragstartCallback(e, row ,idRow) {
    e.dataTransfer.setData('row', JSON.stringify(row));
    e.dataTransfer.setData('originId', e.currentTarget.id);
    
    const filtroRazaTemporal = this.props.filtros.raza == null;        
    
    // Si viene del detalle de las extracciones, mostramos eliminar    
    if (e.currentTarget.id == "poolExtraccionesTable") {
      this.setState({
        idDilucion: row.idDilucion,
        annadiendo:false,
        eliminando:true,        
      },()=>{
        this.props.actions.fetchPoolExtracciones({ ...this.props.filtros, 'filtrar_no_envasados': true });
      });
    } else {
      this.setState({
        idDilucion: row.idDilucion,
        idRazaOrigen: row.idRaza,
        filtroRazaTemporal: filtroRazaTemporal,
        annadiendo:true,
        eliminando:false
      }, () => {
        this.props.actions.fetchPoolExtracciones({ ...this.props.filtros, raza: { value: row.idRaza, label: row.nombreRaza }, 'seleccionar-todo': false, 'filtrar_no_envasados': true  });
      });
    }      
  }

  dragendCallback() {
    this.setState({
      annadiendo:false,
      eliminando:false
    });
    if (this.state.filtroRazaTemporal == true) {
      let filtros = this.props.filtros;
      filtros.raza = null;
      this.props.actions.fetchPoolExtracciones(filtros);
      this.setState({ filtroRazaTemporal: false });
    }
  }


  // este callback se activa en la tabla, pero necesita tener un pool seleccionado, que se guarda en el estado
  dropCallback(e) {    
    if (e.currentTarget.id == 'poolExtraccionesTable') {
      this.insertarExtraccionEnPool(this.state.idDilucion, this.state.idRazaOrigen);
    }
    else if (e.currentTarget.id == 'extraccionesPendientes') {
      this.eliminarExtraccionDelPool(this.state.idDilucion, this.state.idRaza);
    }
  }

  // Este callback se usa en la tabla de pools porque se puede enviar a la celda en que se suelte
  dropCellCallback(e, row) {
    let originId = e.dataTransfer.getData('originId');
    // si viene de las extracciones asignadas al pool, eliminar. Lo contrario resulta muy complejo de manejar
    if (originId == "poolExtraccionesTable") {
      this.eliminarExtraccionDelPool(this.state.idDilucion, this.state.idRaza);
    }
    else {
      if (row != null) {
        this.setState({ idPool: row.idPool, idRaza: row.idRaza }, () => {
          this.insertarExtraccionEnPool(this.state.idDilucion, row.idRaza)
        });
      }
      else {
        let rowdata = JSON.parse(e.dataTransfer.getData('row'));
        this.crearNuevoPool(rowdata);
      }
    }
  }

  onEdit(row) {
    this.props.actions.fromAsignacionView(true)
    this.props.history.push('EditarPoolExtraccion/' + row.idPool)
  }

  render() {
    const {
      t,
      list: poolList = [],
      numeroRegistrosPorPagina, paginaActual, handleSubmit, dataExtraccion, selectedRow,
      pendientesAsignarList = [],
      asignadosAnalisisList = [],
      pool: { checkedFiltrosAsignacionAnalisisPool, data },
      actions: {
        createNewPool,
        abrirModalNuevoPoolAsignacion,
        recuperarDatosFormPoolExtraccion,
        onDeleteAsignacionAnalisisPool,
        fetchExtraccionesPendientesDeAsignar,
        addAnalisisPool,
        removeAnalisisPool
      }
    } = this.props;

    //    let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
    const columVerraco = [{ id: 'verraco', name: t('PLANING.TABLA.VERRACO') }]

    const tKey = 'POOL_EXTRACCIONES.FORM.'
    const idElemento = 'idAnalisis'
    // const idForm = 'poolExtraccionesTable'
    const envasado = data && data.envasado


    // Tabla pendientes de <asignar></asignar>
    const tableExtraccionesPendientes = {
      id: 'extraccionesPendientes',
      multiHeader: false,
      hasOptions: true,
      idElemento,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      mostrarAnnadirAPool: true,
      onAddToPool: (row) => this.insertarExtraccionEnPool(row.idDilucion, row.idRaza),
      mostrarAnnadirANuevoPool: true,
      onAddToNewPool: (row) => this.crearNuevoPool(row),
      hasPagination: false,
      isDraggable:true,
      dragstartCallback:this.dragstartCallback,
      dragendCallback:this.dragendCallback,
      dropCallback:this.dropCallback,
      columns:
        [
          { id: 'codigo', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.CODIGO'), type: 'numero', numDecimales: 0 },
          { id: 'codVerraco', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VERRACO') },
          //{ id: 'volumen', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOL'), type: 'numero', numDecimales: 0 },
          { id: 'volumenDiluyente', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0 },
          { id: 'nombreTipoDosis', name: t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS') },
          { id: 'faTotalPercent', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
          { id: 'dosis', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.DOSIS'), type: 'numero', numDecimales: 0 },
          { id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX'), type: 'numero', numDecimales: 0},
          { id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
        ],
      rows: pendientesAsignarList,
      //filtros: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado ? <FiltrosFormPoolExtraccionesPage idElemento={idElemento} idForm={idForm} /> : null,
      initialValues: {}
    }

    // TABLA POOLS
    const table = {
      id: 'poolTable',
      multiHeader: false,
      hasNestedRows: true,
      hasCheckbox: false,
      hasOptions: true,
      mostrarModificar: true,
      onEdit: (row) => this.onEdit(row),
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      mostrarFilaAnnadir: false,
      mostrarFilaEliminar: false,
      hasPagination: false,
      selectedRow:this.state.selectedRow,
      isDraggable:true,             
      dropCellCallback:this.dropCellCallback,
      onClickCallback:this.RowClickCallback,
      mostrarFilaAnnadir:this.state.annadiendo,
      mostrarFilaEliminar:this.state.eliminando,
      columns: [
        { id: 'nombrePool', name: t(tKey + 'FORM.NOMBRE') },
        { id: 'nombreRaza', name: t(tKey + 'FORM.RAZA') },
        { id: 'nombreTipoDosis', name: t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS') },
        { id: 'volumenDiluyente', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0 },
        { id: 'porFaTotal', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        { id: 'dosisReales', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.DOSIS') },
        //{ id: 'concentracionXDosisUtil', name: t(tKey + 'FORM.CONCENTRACION_X_DOSIS_UTIL') },
        { id: 'indexAvgPool', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX'), type: 'numero', numDecimales: 0},
        { id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
      ],

      rows: poolList,
      showModal: this.props.showModal,
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.idPool == this.state.idPool && this.state.idPool != this.state.idPoolAnterior) {
          classRow = 'analisis-pertenece-pool'
        }
        return classRow
      }
      //filtros: <FiltrosPoolExtraccionesPage idElemento={idElemento} />,
    }



    //control que no hay pool filtrado
    let rowsAsignadosAnalisisList = asignadosAnalisisList
    /*if(this.props.filtros != null && this.props.filtros.pool == null){      
      rowsAsignadosAnalisisList = []
    } */
    if (this.state.idPool != null && this.state.idPool == this.state.idPoolAnterior) {
      rowsAsignadosAnalisisList = []
    }
    //con un pool seleccionado, evitamos filtrar y que aparezca
    if(poolList.find(x => x.idPool == this.state.idPool) === undefined) rowsAsignadosAnalisisList = []


    const tablePoolExtracciones = {
      id: 'poolExtraccionesTable',
      multiHeader: false,
      hasPagination: false,
      hasOptions: true,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      mostrarEliminarDelPool: true,
      onRemoveFromPool: (row) => this.eliminarExtraccionDelPool(row.idDilucion),
      idElemento,
      //mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado,      
      columns: [
        { id: 'codigo', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.CODIGO'), type: 'numero', numDecimales: 0 },
        { id: 'codVerraco', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VERRACO') },
        //{ id: 'volumen', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOL'), type: 'numero', numDecimales: 0 },
        { id: 'volumenDiluyente', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0 },
        { id: 'nombreTipoDosis', name: t('POOL_EXTRACCIONES.COLUMNS.TIPO_DOSIS') },
        { id: 'faTotalPercent', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.COLUMNS.FORMAS_ANORMALES'), type: 'numero'},
        { id: 'dosisReales', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.DOSIS'), type: 'numero', numDecimales: 0 },
        { id: 'indexVerraco', name: t(tKey + 'FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.INDEX'), type: 'numero', numDecimales: 0 },
        { id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
      ],
      rows: rowsAsignadosAnalisisList,
      //filtros: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.POOL] > 1) && !envasado ? <FiltrosFormPoolExtraccionesPage idElemento={idElemento} idForm={idForm} /> : null,
      showModal: this.props.showModalAsignacionAnalisisPool,
      initialValues: {}
    }



    return (
      <div>
        <ModalNuevoPoolAsignacionPage />
        <SimplePage t_key={"ASIGNACION_ANALISIS_POOL."}>
          <FiltrosPoolExtraccionesPage from={'AsignacionPoolVerraco'} hideButton={true} />
          <SimpleModalPage />
          <Row>
            <Col sm={4}>
              <h3>{t('ASIGNACION_ANALISIS_POOL.DILUCIONES_ASIGNAR')}</h3>
              <SimpleTablePage               
                {...tableExtraccionesPendientes} ></SimpleTablePage>
            </Col>

            <Col sm={4}>
              <h3>{t('ASIGNACION_ANALISIS_POOL.POOLS')}</h3>
              <SimpleTablePage
                {...table}>
                </SimpleTablePage>
                
            </Col>

            <Col sm={4}>
              <h3>{t('ASIGNACION_ANALISIS_POOL.DETALLE_POOLS')}</h3>
              <SimpleTablePage
                isDraggable={true}
                dragstartCallback={this.dragstartCallback}
                dragendCallback={this.dragendCallback}
                dropCallback={this.dropCallback}
                isLoading={this.props.isLoading} numRowsLoading={this.props.numRowsLoading} {...tablePoolExtracciones} ></SimpleTablePage>
            </Col>

          </Row>

        </SimplePage>
      </div>
    )
  }
}

export default AsignacionPoolVerraco

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Print, Launch, Warning } from '@material-ui/icons'

class ModalExportarImprimirSeguimientoExtraccionesPool extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      exportarTodoFecha: 'todo',
      errorExportar: false
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.comboVerracoConSeguimiento()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.showModalExportarImprimirInformesSeguimientoExtraccionesPool !== prevProps.showModalExportarImprimirInformesSeguimientoExtraccionesPool) {
      this.props.change('export', 'todo')
      this.setState({exportarTodoFecha: 'todo'})
    }
  }

  cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool () {
    this.props.actions.cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool()
  }

  exportarVerracoInforme (verracoExportar) {
    const filtros = this.props.filtrosSeguimientoExtraccionesPool
    filtros.verracoExportar = verracoExportar
    filtros.completo = false
    this.props.actions.exportarInformeSeguimientoExtraccionesPool(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  imprimirVerracoInforme (verracoExportar) {
    const filtros = this.props.filtrosSeguimientoExtraccionesPool
    filtros.verracoExportar = verracoExportar
    filtros.completo = false
    this.props.actions.imprimirPdfInformesSeguimientoExtraccionesPool(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  exportarInforme () {
    const filtros = this.props.filtrosSeguimientoExtraccionesPool
    filtros.verracoExportar = null
    filtros.completo = true
    this.props.actions.exportarInformeSeguimientoExtraccionesPool(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  imprimirInforme () {
    const filtros = this.props.filtrosSeguimientoExtraccionesPool
    filtros.verracoExportar = null
    filtros.completo = true
    this.props.actions.imprimirPdfInformesSeguimientoExtraccionesPool(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  elegirTipoExportacion (value) {
    this.setState({exportarTodoFecha: value})
    this.setState({errorExportar: false})
  }

  aceptarExportarPalning (values) {
    if (this.state.exportarTodoFecha === 'fecha') {
      this.exportarVerracoInforme(values.verracoExportar)
      this.setState({errorExportar: false})
    } else if (this.state.exportarTodoFecha === 'todo') {
      this.exportarInforme()
      this.setState({errorExportar: false})
    } else {
      this.setState({errorExportar: true})
    }
  }

  aceptarImprimirPalning (values) {
    if (this.state.exportarTodoFecha === 'fecha') {
      this.imprimirVerracoInforme(values.verracoExportar)
      this.setState({errorExportar: false})
    } else if (this.state.exportarTodoFecha === 'todo') {
      this.imprimirInforme()
      this.setState({errorExportar: false})
    } else {
      this.setState({errorExportar: true})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalExportarImprimirInformesSeguimientoExtraccionesPool, exportarImprimir,
      actions:{onDeleteVerracoInforme},
      combos: {comboVerracoConSeguimiento}
    } = this.props

  return (
      <Modal show={showModalExportarImprimirInformesSeguimientoExtraccionesPool} onHide={() => this.cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool()} bsSize="large" dialogClassName="sm" backdrop="static" aria-labelledby="contained-modal-title-lg"  className="modal-exportar-imprimir-planing">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              exportarImprimir === 'exportar' ? (
                t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.EXPORTAR')
              ) : (
                exportarImprimir === 'imprimir' ? (
                  t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.IMPRIMIR')
                ) : null
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {
              exportarImprimir === 'exportar' ? (
                <div>
                <Field name="export" component={InputRadioButton} props={{ value: "todo" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.EXPORTAR_TODO')}</Field>
                <Field name="export" component={InputRadioButton} props={{ value: "fecha" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.FILTROS.VERRACO')}</Field>
                {
                  this.state.exportarTodoFecha === 'fecha' ? (
                    <Field
                      colSm={12}
                      id="verracoExportar"
                      name="verracoExportar"
                      controlLabel={t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.FILTROS.VERRACO')}
                      component={InputSelect}
                      options={comboVerracoConSeguimiento}
                      valueKey="value"
                      labelKey="label"
                      validate={required}
                    />
                  ) : null
                }
                <div className="clearfix"></div>
                {
                  this.state.errorExportar === true ? (
                    <p className="error-eleccion-exportar-imprimir"><Warning /> {t('PLANING.ERROR_EXPORTAR')}</p>
                  ) : null
                }
                <div className="botones-footer-planing">
                  <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.aceptarExportarPalning.bind(this))}>{t('PLANING.MODAL.ACEPTAR')}</Button>
                  <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool()}>{t('PLANING.MODAL.CANCELAR')}</Button>
                </div>
                </div>
              ) : (
                exportarImprimir === 'imprimir' ? (
                  <div>
                    <Field name="export" component={InputRadioButton} props={{ value: "todo" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.IMPRIMIR_TODO')}</Field>
                    <Field name="export" component={InputRadioButton} props={{ value: "fecha" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.FILTROS.VERRACO')}</Field>
                    {
                      this.state.exportarTodoFecha === 'fecha' ? (
                        <Field
                          colSm={12}
                          id="verracoExportar"
                          name="verracoExportar"
                          controlLabel={t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.FILTROS.VERRACO')}
                          component={InputSelect}
                          options={comboVerracoConSeguimiento}
                          valueKey="value"
                          labelKey="label"
                          validate={required}
                        />
                     ) : null
                  }
                  <div className="clearfix"></div>
                {
                  this.state.errorExportar === true ? (
                    <p className="error-eleccion-exportar-imprimir"><Warning /> {t('PLANING.ERROR_EXPORTAR')}</p>
                  ) : null
                }
                <div className="botones-footer-planing">
                  <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.aceptarImprimirPalning.bind(this))}>{t('PLANING.MODAL.ACEPTAR')}</Button>
                  <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool()}>{t('PLANING.MODAL.CANCELAR')}</Button>
                </div>
                  </div>
                ) : null
              )
            }
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalExportarImprimirSeguimientoExtraccionesPool',
})(ModalExportarImprimirSeguimientoExtraccionesPool))

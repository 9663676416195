import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import {date_formatter, dateTimeZones, date_parser} from '../../../util/formatFunctions'
import InputSelect from '../../comun/form/InputSelect'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputText from '../../comun/form/InputText'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosMicrobiologia extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      quitarFiltroFechas: false
    }
  }  

  fetchFiltrarMicrobiologia(values){
    this.props.actions.fetchFiltrarMicrobiologia(values)
  }

  componentDidMount () {    
    this.props.initialize({
      fechaEntrada: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.filtros !== prevProps.filtros) {    
      this.setState({quitarFiltroFechas: false})
    }
    if (this.props.datosExcelMicrobiologia !== prevProps.datosExcelMicrobiologia) {
      if (this.props.datosExcelMicrobiologia.excel && this.props.datosExcelMicrobiologia.excel !== null){
        let pdf = this.props.datosExcelMicrobiologia.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.MICROBIOLOGIA') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    if (value == 'fechaEntrada' || value == 'fechaSalida') {        
      let ultimoMes = new Date()      
      ultimoMes.setMonth(ultimoMes.getMonth() - 1)
      values['fechaEntrada'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['fechaSalida'] = null
      values['desde'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['hasta'] = null     
      this.props.actions.fetchFiltrarMicrobiologia(values)
      this.props.change('fechaEntrada', ultimoMes)  
      this.props.change('fechaSalida', null)
      this.props.change('desde', ultimoMes)  
      this.props.change('hasta', null)     
      this.setState({quitarFiltroFechas: true})
    } else {
      values[value] = null
      this.props.actions.fetchFiltrarMicrobiologia(values)
      this.props.change(value, null)
    }
    this.setState({fitrosSeleccionados: values})
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosMicrobiologia()
    } else {
      this.props.actions.deSeleccionarTodosMicrobiologia()
    }
  }

  crearCsvMicrobiologia(){
    this.props.actions.crearCsvMicrobiologia(this.props.filtros)
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVacioMicrobiologia
    }
  } = this.props  

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form" >            
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('PLAN_SANITARIO.VISITA.FILTROS.DESDE_HASTA')}
              onInputChange={handleSubmit(this.fetchFiltrarMicrobiologia.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">                
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
                {(this.state.quitarFiltroFechas === true && <span> {t('ANALISIS_EXTRACCIONES.FILTROS.AVISO_FILTROS_CERRADOS')}</span>)}
              </div>
            </div>
          </form>
        </div>        
        <Row className="acciones-tabla-filtros">
          <Col sm={6}>
            <div className="buttons-flex">   
              {/* <div className="custom-check-w" key="checkbox_key">
                <Field
                  id="seleccionar-todo"
                  name="seleccionar-todo"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  onInputChange={(value) => this.intercambiarCheck(value)}
                />
              </div>           */}
              <div className="button-group buttons-min"> 
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvMicrobiologia()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>               
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MICROBIOLOGIA] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MICROBIOLOGIA] > 1) ? abrirModalVacioMicrobiologia : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={6} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div
                className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'}
                onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>          
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosMicrobiologia',
  initialValues: {
    fechaEntrada: new Date()
  }
})(FiltrosMicrobiologia))

import React, { PureComponent } from 'react'
import { Col, Navbar, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdminSubHeaderPage from '../../containers/layout/AdminSubHeaderPage'
import UserSubHeaderPage from '../../containers/layout/UserSubHeaderPage'
import logo from '../../assets/images/logo.jpg'
import logo_admin from '../../assets/images/logo_admin.jpg'
import logoResponsive from '../../assets/images/logo-responsive.jpg'


class Header extends PureComponent {
  render () {
    const { t, sm = 12, isAdmin, isUser } = this.props
    return (
      <Col sm={sm} componentClass="header" >
        <Navbar fluid fixedTop className='be-top-header' >
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <Image className="logo-desktop" src={isAdmin ? logo_admin : logo} title={t('HEADER.BRAND')} responsive />
                <Image className="logo-responsive" src={logoResponsive} title={t('HEADER.BRAND')} responsive />
              </Link>
            </Navbar.Brand>
          </Navbar.Header>
          {isAdmin ? (
            <div className='right-navbar'>
              <AdminSubHeaderPage/>
            </div>
          ) : isUser && (
            <div className='right-navbar'>
              <UserSubHeaderPage/>
            </div>
          )}
        </Navbar>
      </Col>
    )
  }
}

export default Header

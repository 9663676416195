import React, { Component } from 'react'
import { Tabs, Tab, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed, SubmissionError } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {Line} from 'react-chartjs-2';
import './NuevoVerraco.scss'
import { required } from '../../util/validationFunctions'
import FormSubtitle from '../comun/form/FormSubtitle'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import { CloudUpload, Delete, Photo, PictureAsPdf, FileCopy, Warning, Print, Close } from '@material-ui/icons'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import ListadoPage from '../../containers/page/ListadoPage'
import FiltrosEvolucionPage from '../../containers/fichaVerraco/FiltrosEvolucionPage'
import ModalEvolucionPage from '../../containers/fichaVerraco/ModalEvolucionPage'
import ModalAlimentacionPage from '../../containers/fichaVerraco/ModalAlimentacionPage'
import FiltrosAlimentacionPage from '../../containers/fichaVerraco/FiltrosAlimentacionPage'
import FiltrosIndexPage from '../../containers/fichaVerraco/FiltrosIndexPage'
import ModalIndexPage from '../../containers/fichaVerraco/ModalIndexPage'
import ModalMovimientoVerracoPage from '../../containers/movimientoVerraco/ModalMovimientoVerracoPage'
import { onSubmitFail } from '../../util/util'
import ModalVerracoCambioEstadoPage from '../../containers/fichaVerraco/ModalVerracoCambioEstadoPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {date_formatter, dateTimeZones} from '../../util/formatFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class NuevoVerraco extends Component {
  constructor (props) {
    super()
    this.state = {
      campoCliente: false,
      hasInvalidFile: false,
      accepted: '',
      rejected: '',
      rejectedArchivos: [],
      archivos: [],
      hayCambioEstado: '',
      fechaErronea: false
    }
  }
  submitNuevoVerraco (values) {
    values.archivos = this.props.fichaVerraco.archivo
    values.directorioTemporalArchivos = this.props.fichaVerraco.directorioTemporalArchivos.ultimoVerracoArchivos
    values.directorioTemporal = this.props.fichaVerraco.directorioTemporal
    values.idUsuario = this.props.fichaVerraco.idUsuario
    values.imagenVerraco = this.props.fichaVerraco.imagen
    values.nombreArchivos = this.props.fichaVerraco.directorioTemporalArchivos.nombreArchivos
    values.evolucion = this.props.fichaVerraco.evolucionesAnadidas
    values.index = this.props.fichaVerraco.indexAnadidos
    values.ubicacion = Object.keys(this.props.fichaVerraco.ubicacion).length > 0 ? this.props.fichaVerraco.ubicacion : null
    values.datosCambioEstado = this.props.fichaVerraco.datosCambioEstado
    values.alimentacion = this.props.fichaVerraco.alimentacionAnadidas
    if (Object.keys(this.props.fichaVerraco.datosCambioEstado).length > 0) {
       this.setState({hayCambioEstado: true})
    } else {
      this.setState({hayCambioEstado: false})
    }
    if (this.state.hayCambioEstado === true && this.state.fechaErronea === false) {
      this.props.actions.submitNuevoVerraco(values, this.props.history)
    } else {
      throw new SubmissionError({cambioEstadoVerraco: 'error-cambio-estado-vacio'})
    }
  }
  guardarYnuevoVerraco(values){
    values.archivos = this.props.fichaVerraco.archivo
    values.directorioTemporalArchivos = this.props.fichaVerraco.directorioTemporalArchivos.ultimoVerracoArchivos
    values.directorioTemporal = this.props.fichaVerraco.directorioTemporal
    values.idUsuario = this.props.fichaVerraco.idUsuario
    values.imagenVerraco = this.props.fichaVerraco.imagen
    values.nombreArchivos = this.props.fichaVerraco.directorioTemporalArchivos.nombreArchivos
    values.evolucion = this.props.fichaVerraco.evolucionesAnadidas
    values.index = this.props.fichaVerraco.indexAnadidos
    values.ubicacion = Object.keys(this.props.fichaVerraco.ubicacion).length > 0 ? this.props.fichaVerraco.ubicacion : null
    values.datosCambioEstado = this.props.fichaVerraco.datosCambioEstado
    values.alimentacion = this.props.fichaVerraco.alimentacionAnadidas
    if (Object.keys(this.props.fichaVerraco.datosCambioEstado).length > 0) {
       this.setState({hayCambioEstado: true})
    } else {
      this.setState({hayCambioEstado: false})
    }
    if (this.state.hayCambioEstado === true && this.state.fechaErronea === false) {
      this.props.actions.guardarYnuevoVerraco(values, false)
      this.handleSelectKey(1)
      window.scrollTo(0, 0)
      this.setState({accepted: ''})
      this.setState({rejected: ''})
    } else {
      throw new SubmissionError({cambioEstadoVerraco: 'error-cambio-estado-vacio'})
    }
  }
  componentDidMount () {
    document.title = this.props.t('MENU.VERRACOS.VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.comboLineaGeneticaActiva()
    this.props.actions.comboRazaActiva()
    this.props.actions.comboRaza()
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.comboSalaExtraccionActivo()
    this.props.actions.comboCliente()
    this.props.actions.ubicacionenesVacias(false)
    this.props.actions.comboTipoDosis()
    this.props.actions.comboUbicacion()
    this.props.change('propiedad', { value: true, label: this.props.t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},)
    this.props.actions.restartVerracoDejandoFiltros()
    this.props.actions.enviarFechaCambioEstado({
      fechaCambio: new Date,
      idVerraco: null,
      idCambioEstado: null
    })
    this.props.actions.comboDireccionesClienteSinPredeterminado(null)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.fichaVerraco.datosCambioEstado !== prevProps.fichaVerraco.datosCambioEstado) {
      if (Object.keys(this.props.fichaVerraco.datosCambioEstado).length > 0) {
        this.setState({hayCambioEstado: true})
        if (this.props.fichaVerraco.datosCambioEstado.nuevoEstado.value === 1) {
          if (this.props.fichaVerraco.datosCambioEstado.fechaCambioEstado) {
            this.props.change('fechaCuarentena', this.props.fichaVerraco.datosCambioEstado.fechaCambioEstado)
          }
        }
        if (this.props.fichaVerraco.datosCambioEstado.nuevoEstado.value === 5) {
          this.props.change('fechaEliminado', this.props.fichaVerraco.datosCambioEstado.fechaCambioEstado)
        }
      } else {
        // this.setState({hayCambioEstado: false})
      }
    }
    if (this.props.fichaVerraco.archivo !== prevProps.fichaVerraco.archivo){
      this.setState({archivos: this.props.fichaVerraco.archivo})
    }
    if (this.props.formVerraco.values !== undefined){
      if (this.props.formVerraco !== prevProps.formVerraco){
        if ((this.props.formVerraco.values.fechaCuarentena && this.props.formVerraco.values.fechaCuarentena === "error") ||
          (this.props.formVerraco.values.fechaEntradaGranja && this.props.formVerraco.values.fechaEntradaGranja === "error") ||
          (this.props.formVerraco.values.fechaNacimiento && this.props.formVerraco.values.fechaNacimiento === "error")) {
          this.setState({fechaErronea: true})
        } else {
          this.setState({fechaErronea: false})
        }
      }
    }
    // VALOR GMD
    if (Object.keys(this.props.fichaVerraco.evolucionesAnadidas).length > 0) {
      if (this.props.fichaVerraco.evolucionesAnadidas !== prevProps.fichaVerraco.evolucionesAnadidas){
        let cont = 0
        const restarGmd = this.props.fichaVerraco.evolucionesAnadidas.map((element) => {
          cont ++
          if (!element.idEvolucionVerraco && cont >= 2){
            return true
          }
        })
        let list = restarGmd.filter(function (item) {
          if (item === true){
            return true
          }
        })
        if (list[0] === true){
          let evolucionesAnnadidas = this.props.fichaVerraco.evolucionesAnadidas
          let numeroEvolucionesAnnadidas = evolucionesAnnadidas.length
          this.props.fichaVerraco.evolucionesAnadidas.map((element, index) => {
            if (index > 0) {
              let nuevoPeso = element.pesoVerraco - evolucionesAnnadidas[index-1].pesoVerraco
              element.gmdVerraco = nuevoPeso
              if((numeroEvolucionesAnnadidas-1)==index){
                this.props.change('gmdVerraco', nuevoPeso)
              }
              return element
            }
          })
        }
      }
    }
    if (Object.keys(this.props.fichaVerraco.indexAnadidos).length > 0) {
      if (this.props.fichaVerraco.indexAnadidos !== prevProps.fichaVerraco.indexAnadidos){
        let indexAnadidos = this.props.fichaVerraco.indexAnadidos
        let numeroindexAnadidos = indexAnadidos.length
        this.props.change('index', indexAnadidos[numeroindexAnadidos -1].index)
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.hayCambioEstado === false) {
      this.props.dispatch(setSubmitFailed('nuevoVerraco'))
    }

    if (this.props.combos.comboDireccionesClienteSinPredeterminado !== prevProps.combos.comboDireccionesClienteSinPredeterminado) {
      if (Object.keys(this.props.combos.comboDireccionesClienteSinPredeterminado).length === 0) {
        this.props.change('idDireccionCliente', '')
      } else {
        this.props.change('idDireccionCliente', '')
      }
    }
  }

  handleChangeCodVerraco(value){
    if (value !== null) {
      this.props.actions.asignarCodRaza(value.label)
    }
  }
  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }
  handleChangeCliente (value) {
    this.props.change('idDireccionCliente', '')
    if (value !== null){
      this.props.change('idDireccionCliente', '')
      this.props.actions.comboDireccionesClienteSinPredeterminado(value.value)
    } else {
      this.props.actions.comboDireccionesClienteSinPredeterminado(null)
      this.props.change('idDireccionCliente', '')
    }
  }
  handleChangeEdad(val, prevVal){
    const fechaHoy = moment(new Date())
    const fechaNac = moment(prevVal)
    const comparaFecha = fechaHoy.diff(fechaNac, 'days')
    this.props.change('edad', prevVal !== '' ? comparaFecha : '')
  }
  handleChangeSemanasEstancia(val, prevVal){
    const fechaHoy = moment(new Date())
    const fechaNac = moment(prevVal)
    const comparaFecha = fechaHoy.diff(fechaNac, 'weeks')
    this.props.change('semanasEstancia', prevVal !== '' ? comparaFecha : '')
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        this.props.actions.quitarDirectorioTemporal()
        const id_verraco = Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? this.props.fichaVerraco.listVerraco.verraco.idVerraco : null
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFile(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  onDropRejected = (rejectedFiles) => {
    const file = rejectedFiles[0]
    if (file.size > 200000) {
      this.setState({rejected: rejectedFiles[0].name})
    }
  }

  eliminaImagen(){
    this.props.actions.eliminaImagen()
    this.setState({accepted: ''})
    this.props.actions.quitarDirectorioTemporal()
    this.setState({rejected: ''})
  }

  onDropArchivos(archivos) {
    var numFiles=archivos.length
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        const archivoVerraco = {archivo: archivo.name, content: reader.result}
        this.setState({archivos: this.state.archivos.concat(archivoVerraco)})
        this.props.actions.subirArchivos(this.state.archivos)
      }
      reader.readAsDataURL(archivo)
    })
  }

  getFileExtension(filename) {
    return filename.split('.').pop()
  }

  eliminaArchivo(archivo){
    this.props.actions.eliminarArchvio(archivo, this.props.fichaVerraco.directorioTemporalArchivos.ultimoVerracoArchivos)
  }

  obtenerArchivo(archivo){
    if(archivo.content){
      let element = document.createElement('a')
      element.setAttribute('href', encodeURIComponent(archivo))
      element.setAttribute('href', archivo.content)
      element.setAttribute('download', archivo.archivo)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    else{
      this.props.actions.obtenerArchivoContent(archivo)
    }
  }

  handleSelectKey(key) {
    this.setState({key: key})
  }

  render () {
    const dataLine = {
      labels:  Object.keys(this.props.fichaVerraco.evolucionesAnadidas).length > 0 ? this.props.fichaVerraco.evolucionesAnadidas.map((datos) => date_formatter(datos.fechaEvolucion, this.props.auth.formaFechaHora, false)) : null,
      datasets: [
        {
          stack: 'stack1',
          label: 'Condicion Corporal',
          data: this.props.fichaVerraco.evolucionesAnadidas.length > 0 ? this.props.fichaVerraco.evolucionesAnadidas.map((datos) => datos.condicionCorporalVerraco) : null,
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,142,0,0.2)'
        },
        {
          stack: 'stack1',
          label: 'Peso',
          data: this.props.fichaVerraco.evolucionesAnadidas.length > 0 ? this.props.fichaVerraco.evolucionesAnadidas.map((datos) => datos.pesoVerraco) : null,
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,114,0,0.3)'
        },
        {
          stack: 'stack1',
          label: 'Kg pieso/día',
          data: this.props.fichaVerraco.evolucionesAnadidas.length > 0 ? this.props.fichaVerraco.evolucionesAnadidas.map((datos) => datos.kgPiensoDiaVerraco) : null,
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,78,0,0.3)'
        }
      ]
    }
    const {
      t, handleSubmit, numeroRegistrosPorPagina, urlRecursos, paginaActual,
      auth: {centro},
      fichaVerraco: { directorioTemporal, nombreImagen, directorioTemporalArchivos, evolucionesAnadidas, dataEvolucion, dataEvolucionDuplicar, checkedFiltrosEvolucion,
        checkedFiltrosAlimentacion, alimentacionAnadidas, checkedFiltrosIndex, dataIndex, dataIndexDuplicar, indexAnadidos, datosCambioEstado, dataAlimentacion, dataAlimentacionDuplicar },
      combos:{ comboRazaActiva, comboLineaGeneticaActiva, comboOperarioPredeterminado, comboSalaExtraccionActivo, comboCliente, comboTipoDosis, comboGenHalotano, comboConservacion, comboDireccionesClienteSinPredeterminado},
      actions:{recuperarDatosModalEvolucion, deleteEvolucion, duplicarDatosModalEvolucion, recuperarDatosModalIndex, deleteIndex, duplicarDatosModalIndex, recuperarDatosModalAlimentacion,
        deleteAlimentacion, duplicarDatosModalAlimentacion}
    } = this.props

    const comboPropiedad = [
      { value: true, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},
      { value: false, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.ALQUILADO')}
    ]

    let comboTiempoMonta = this.props.combos.comboTiempoMonta
    comboTiempoMonta = comboTiempoMonta.map((tiempoMonta) => {
      return {...tiempoMonta, label: t(tiempoMonta.label)}
    })

    let comboCausaEliminacion = this.props.combos.comboCausaEliminacion
    comboCausaEliminacion = comboCausaEliminacion.map((causaEliminacion) => {
      return {...causaEliminacion, label: t(causaEliminacion.label)}
    })

    evolucionesAnadidas.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'evolucion'
    )
    const tableEvolucion = {
      id: 'evolucionTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      columns: [
        {id: 'fechaEvolucion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.FECHA_EVOLUCION'), type: 'fecha'},
        {id: 'condicionCorporalVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.CONDICION_CORPORAL')},
        {id: 'pesoVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.PESO_VERRACO')},
        {id: 'kgPiensoDiaVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.KG_PIENSO_DIA')},
        {id: 'gmdVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.GMD_VERRACO')}
      ],
      rows: evolucionesAnadidas,
      noBlockOptions: false,
      filtros: <FiltrosEvolucionPage/>,
      onEdit: (row) => recuperarDatosModalEvolucion(row.idElemento),
      dataEvolucion: dataEvolucion,
      onDelete: (row) => deleteEvolucion(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalEvolucion(row.idElemento),
      dataEvolucionDuplicar: dataEvolucionDuplicar,
      initialValues: {}
    }
    tableEvolucion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEvolucion.initialValues['check' + row.idElemento] = checkedFiltrosEvolucion
        }
      }
    )

    alimentacionAnadidas.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'alimetacion'
    )
    const tableAlimentacion = {
      id: 'alimentacionTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      columns: [
        {id: 'numeroRegistroAlimentacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.CODIGO')},
        {id: 'nombreTipoAlimentacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.TIPO_ALIMENTACION')},
        {id: 'fechaInicio', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.FECHA_INICIO'), type: 'fecha'},
        {id: 'producto', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.PRODUCTO')},
        {id: 'cantidad', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.CANTIDAD')},
        {id: 'lote', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.LOTE')}
      ],
      rows: alimentacionAnadidas,
      noBlockOptions: false,
      onEdit: (row) => recuperarDatosModalAlimentacion(row.idElemento),
      dataAlimentacion: dataAlimentacion,
      onDelete: (row) => deleteAlimentacion(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalAlimentacion(row.idElemento),
      dataAlimentacionDuplicar: dataAlimentacionDuplicar,
      initialValues: {},
      candidadRegistros: 'candidadRegistrosAlimentacion'
    }
    tableAlimentacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableAlimentacion.initialValues['check' + row.idElemento] = checkedFiltrosAlimentacion
        }
      }
    )

    indexAnadidos.forEach(
      (row, idRow) => row.idElemento = row.idElemento = idRow + 1 + 'index'
    )
    const tableIndex = {
      id: 'indexTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      columns: [
        {id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.INDEX')},
        {id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.NIVEL_GENETICO')},
        {id: 'fechaIndex', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.FECHA'), type: 'fecha'},
      ],
      rows: indexAnadidos,
      // noBlockOptions: false,
      filtros: <FiltrosIndexPage/>,
      onEdit: (row) => recuperarDatosModalIndex(row.idElemento),
      dataIndex: dataIndex,
      onDelete: (row) => deleteIndex(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalIndex(row.idElemento),
      dataIndexDuplicar: dataIndexDuplicar,
      initialValues: {},
      candidadRegistros: 'candidadRegistrosIndex',
      tablaModal: true,
      idBlockOptions: 10
    }
    tableIndex.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableIndex.initialValues['check' + row.idElemento] = checkedFiltrosIndex
        }
      }
    )    
    return (
      <div>
        <SimpleModalPage/>
        <h2 className="page-title" style={{display: 'inline-block'}}>{t('FICHA_VERRACO.NUEVO_VERRACO.TITULO')}</h2>
        <span className="cierre-ventana-verraco"><Link to={{pathname: '/FichaVerraco', state: { from: this.props.location.pathname }}}><Close/></Link></span>
        <MensajeAlertaPage/>
        <ModalMovimientoVerracoPage
          mostrarComboVerraco={false}
          esInsertar={false}
          mostrarOrigenVerraco={false}
        />
        <form className="form-nuevo-verraco">
          <Tabs defaultActiveKey={1} activeKey={this.state.key} onSelect={() => this.handleSelectKey()} id="tab-ficha-verraco" className="tab-ficha-verraco">
            <Tab eventKey={1} title={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TITULO')}>
              <Row>
                <Field
                  colSm={2}
                  id="idRaza"
                  name="idRaza"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.RAZA') + '*'}
                  component={InputSelect}
                  options={comboRazaActiva}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-raza'
                  validate={required}
                  onInputChange={(value) => this.handleChangeCodVerraco(value)}
                />
                <Field
                  colSm={2}
                  id="idLinea"
                  name="idLinea"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.LINEA_GENETICA')}
                  component={InputSelect}
                  options={comboLineaGeneticaActiva}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-linea-genetica'
                />
                <Field
                  colSm={2}
                  id="codVerraco"
                  name="codVerraco"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.VERRACO') + '*'}
                  customClass='verraco'
                  validate={required}
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="crotal"
                  name="crotal"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CROTAL')}
                  customClass='crotal'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="tatuaje"
                  name="tatuaje"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TATUAJE')}
                  customClass='tatuaje'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="identificador"
                  name="identificador"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.IDENTIFICADOR')}
                  customClass='identificador'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="numero"
                  name="numero"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NUMERO')}
                  customClass='numero'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="registro"
                  name="registro"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.REGISTRO')}
                  customClass='registro'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="propiedad"
                  name="propiedad"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PROPIEDAD')}
                  component={InputSelect}
                  options={comboPropiedad}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-propiedad'
                  onInputChange={(value) => this.handleChangePropiedad(value)}
                  isClearable={false}
                />
                {this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
                  <Field
                    colSm={2}
                    id="idCliente"
                    name="idCliente"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EMPRESA')}
                    component={InputSelect}
                    options={comboCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
                    onInputChange={(value) => this.handleChangeCliente(value)}
                  />
                )}
                {this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
                  <Field
                    colSm={2}
                    id="idDireccionCliente"
                    name="idDireccionCliente"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GRANJA')}
                    component={InputSelect}
                    options={comboDireccionesClienteSinPredeterminado}
                    valueKey="value"
                    labelKey="label"
                    customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
                  />
                )}
                <Field
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_NACIMIENTO')}
                  onChange={(val, prevVal) => this.handleChangeEdad(val, prevVal)}
                />
                <Field
                  colSm={2}
                  id="edad"
                  name="edad"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EDAD')}
                  customClass='edad'
                  disabled={true}
                />
                <Field
                  colSm={2}
                  id="edadGenetica"
                  name="edadGenetica"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EDAD_GENETICA')}
                  customClass='edadGenetica'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  id="fechaCuarentena"
                  name="fechaCuarentena"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ENTRADA_CUARENTENA')}
                />
                <Field
                  id="fechaEntradaGranja"
                  name="fechaEntradaGranja"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ENTRADA_GRANJA')}
                  onChange={(val, prevVal) => this.handleChangeSemanasEstancia(val, prevVal)}
                />
                <Field
                  id="fecha1Extraccion"
                  name="fecha1Extraccion"
                  component={InputText}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_PRIMERA_EXTRACCION')}
                  disabled={true}
                />
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Col sm={2}>
                    <span className={"boton-ubicacion-verraco " + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] < 2) ? ' disabled' : '')} onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1) ? () => this.props.actions.abrirModalUbicacion() : undefined}>
                      {
                        Object.keys(this.props.fichaVerraco.ubicacion).length > 0 ? (
                          Object.keys(this.props.fichaVerraco.ubicacion.instalacion).length > 0 ? (
                            <OverlayTrigger placement="left" overlay={<Tooltip id="ubicacionTooltip" bsStyle="link">{this.props.fichaVerraco.ubicacion.instalacion[0].label}</Tooltip>}>
                              <span>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.UBICACION') + ': ' + this.props.fichaVerraco.ubicacion.instalacion[0].label}</span>
                            </OverlayTrigger>
                          ) : t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.UBICACION')
                        ) : t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.UBICACION')
                      }
                    </span>
                  </Col>
                )}
                <Col sm={2}>
                  <ModalVerracoCambioEstadoPage
                    esInsertar={false}
                    mostrarComboVerraco={false}
                  />
                  {
                    this.state.hayCambioEstado === false ? (
                      <p className="p-error-cambio-estado" style={{fontWeight: 'bold', margin: 0, color: '#b10000'}}><Warning />{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.INSERTA_CAMBIO_ESTADO')}</p>
                    ) : null
                  }
                  <span id="cambioEstadoVerraco" className={this.state.hayCambioEstado === false ? 'boton-cambio-estado-verraco error-cambio-estado' : 'boton-cambio-estado-verraco'} onClick={() => this.props.actions.abrirModalVerracoCambioEstado()}>
                    {
                      this.state.hayCambioEstado === false ? (
                        <span className="error-cambio-estado">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO') + '*'}<Warning/></span>
                      ) : <span>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO') + '*'}</span>
                      
                    }
                    {
                      Object.keys(datosCambioEstado).length > 0 ? (
                        datosCambioEstado.nuevoEstado !== null && datosCambioEstado.nuevoEstado !== undefined ? (
                          ': ' + datosCambioEstado.nuevoEstado.label
                        ) : null
                      ) : null
                    }
                  </span>
                </Col>
                <Field
                  colSm={2}
                  id="semanasEstancia"
                  name="semanasEstancia"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.SEMANAS_ESTANCIA')}
                  customClass='semanasEstancia'
                  disabled={true}
                />
                <Field
                  id="fechaEliminado"
                  name="fechaEliminado"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ELIMINACION')}
                  disabled={true}
                />
                <Field
                  colSm={2}
                  id="granjaOrigen"
                  name="granjaOrigen"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GRANJA_ORIGEN')}
                  customClass='granjaOrigen'
                  maxLength={true}
                  valorMaxLength={50}
                />
                <Field
                  colSm={2}
                  id="padre"
                  name="padre"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PADRE')}
                  customClass='padre'
                  maxLength={true}
                  valorMaxLength={50}
                />
                <Field
                  colSm={2}
                  id="madre"
                  name="madre"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.MADRE')}
                  customClass='madre'
                  maxLength={true}
                  valorMaxLength={50}
                />
                {(this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='AbuelosBagmatic')) && 
                <Field
                  colSm={2}
                  id="abuelo"
                  name="abuelo"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ABUELO')}
                  customClass='abuelo'
                  maxLength={true}
                  valorMaxLength={50}
                />
                }
                <Field
                  colSm={2}
                  id="numMachosLote"
                  name="numMachosLote"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NUM_MACHOS_LOTE')}
                  customClass='numMachosLote'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="idLoteEntrada"
                  name="idLoteEntrada"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ID_LOTE_ENTRADA')}
                  customClass='idLoteEntrada'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="idGuiaEntrada"
                  name="idGuiaEntrada"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ID_GUIA_ENTRADA')}
                  customClass='idGuiaEntrada'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="registroLote"
                  name="registroLote"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.REGISTRO_LOTE')}
                  customClass='registroLote'
                  maxLength={true}
                  valorMaxLength={20}
                />
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Field
                    id="finIntervencionSanitaria"
                    name="finIntervencionSanitaria"
                    component={InputText}
                    colSm={2}
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FIN_INTERVENCION_SANITARIA')}
                    disabled={true}
                  />
                )}
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Field
                    id="noMandarMatadero"
                    name="noMandarMatadero"
                    colSm={2}
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label no-matadero-check"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NO_MANDAR_MATADERO')}
                    disabled={true}
                  />
                )}
                {/*
                <Field
                  colSm={2}
                  id="idOperarioColecta"
                  name="idOperarioColecta"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.OPERARIO_COLECTA_PREFERIDO')}
                  component={InputSelect}
                  options={comboOperarioPredeterminado}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-operario-colecta-preferido'
                />*/}
                <Field
                  colSm={2}
                  id="idTipoDosis"
                  name="idTipoDosis"
                  component={InputSelect}
                  options={comboTipoDosis}
                  valueKey="value"
                  labelKey="label"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TIPO_DOSIS_PREFERENTE')}
                  customClass='tipo-de-dosis-preferente'
                  noPredeterminado={false}
                />
                <Field
                  colSm={2}
                  id="idSalaExtraccion"
                  name="idSalaExtraccion"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.SALA_EXTRACCION_PREFERENTE')}
                  component={InputSelect}
                  options={comboSalaExtraccionActivo}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-sala-extraccion-preferente'
                />
                <Field
                  colSm={2}
                  id="tiempoMonta"
                  name="tiempoMonta"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TIEMPO_MONTA')}
                  component={InputSelect}
                  options={comboTiempoMonta}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-tiempo-monta'
                />
                <Field
                  colSm={2}
                  id="conservacion"
                  name="conservacion"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CONSERVACION')}
                  component={InputSelect}
                  options={comboConservacion}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-conservacion'
                />
                <Field
                  id="vigilado"
                  name="vigilado"
                  colSm={2}
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('FICHA_VERRACO.VIGILADO')}
                />
                <Field
                  id="planning"
                  name="planning"
                  colSm={2}
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label checkbox-inline-activo"
                  controlLabel={t('FICHA_VERRACO.PLANNING')}                
                />
                <div className="clearfix"></div>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    colSm={6}
                    id="descripcionVigilado"
                    name="descripcionVigilado"
                    component={InputTextArea}
                    componentClass="textarea"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.DESCRIPCION_VIGILADO')}
                    customClass='observaciones-verraco'
                  />
                  <Field
                    colSm={6}
                    id="observacionesVerraco"
                    name="observacionesVerraco"
                    component={InputTextArea}
                    componentClass="textarea"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.OBSERVACIONES')}
                    customClass='observaciones-verraco'
                  />
              </Col>
                <Col sm={6}>
                  <Col sm={3}>
                    <div className="imagenVerraco">
                    <div className="content-dropzone">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected }) }}
                        maxSize={2000000}
                        onDropRejected={this.onDropRejected}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}  className="dropzone">
                            <input {...getInputProps()} />
                            <CloudUpload/>
                          </div>
                        )}
                      </Dropzone>
                      {
                        (this.state.accepted !== '' && this.state.accepted !== undefined) ? (
                          <div className="eliminar-imagen-verraco" onClick={this.eliminaImagen.bind(this)}>
                            <Delete/>
                          </div>
                        ) : null
                      }
                      <div className="imagen-perfil-verraco">
                      {
                        this.state.accepted !== '' && this.state.accepted !== undefined ? (
                          <img src={this.state.accepted} />
                        ) : (
                          <img src={urlRecursos + 'imagen-perfl-cerdo.jpg'} />
                        )
                      }
                      </div>
                      <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>Imagen desamiado grande</p>
                    </div>
                  </div>
                  </Col>
                  <Col sm={6}>
                    <div className="archivosVerraco">
                      {t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ARCHIVOS_VERRACO')}
                      <Dropzone
                        // accept="image/jpeg, image/png"
                        onDrop={this.onDropArchivos.bind(this)}
                        uploadMultiple={true}
                        maxFiles={null}
                        // maxSize={200000}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}  className="custom-file-label">
                            <input {...getInputProps()} />
                            <label><span className="explorar-input-file">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EXPLORAR')}</span></label>
                          </div>
                        )}
                      </Dropzone>
                      <ul>
                        {
                          Object.keys(this.state.archivos).length > 0 && (
                            this.state.archivos.map((item, idx) =>{
                              return (
                                <li
                                  key={idx}
                                >
                                  <span className="descargar-adjunto-verraco" onClick={() => this.obtenerArchivo(item)}>
                                    {
                                      this.getFileExtension(item.archivo) === 'jpg' || this.getFileExtension(item.archivo) === 'gif' || this.getFileExtension(item.archivo) === 'png' ||
                                      this.getFileExtension(item.archivo) === 'JPG' || this.getFileExtension(item.archivo) === 'GIF' || this.getFileExtension(item.archivo) === 'PNG' ? (
                                        <Photo/>
                                      ) : (
                                            this.getFileExtension(item.archivo) === 'pdf' ? (
                                              <PictureAsPdf/>
                                            ) : (<FileCopy/>)
                                          )
                                    }
                                    <p>{item.archivo}</p>
                                  </span>
                                  <span className="borrar" onClick={() => this.eliminaArchivo(item.archivo)}><Delete/></span>
                                </li>
                              )
                            })
                          )
                        }
                      </ul>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={2} title={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.TITULO')}>
              <Row className="parte-parametros">
                <Field
                  id="grasaDorsal"
                  name="grasaDorsal"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GRASA_DORSAL')}
                />
                <Field
                  id="indiceConversion"
                  name="indiceConversion"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDICE_CONVERSION')}
                />
                <Field
                  id="gmdVerraco"
                  name="gmdVerraco"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GMD')}
                  numDecimales={2}
                />
                <Field
                  id="porMagro"
                  name="porMagro"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.POR_MAGRO')}
                />
                <Field
                  id="genHalotano"
                  name="genHalotano"
                  component={InputSelect}
                  options={comboGenHalotano}
                  colSm={1}
                  valueKey="value"
                  labelKey="label"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GEN_HALOTANO')}
                />
                <Field
                  id="indexActual"
                  name="indexActual"
                  component={InputText}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX_ACTUAL')}
                  disabled={true}
                />
                <Field
                  id="nivelGeneticoActual"
                  name="nivelGeneticoActual"
                  component={InputText}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.NIVEL_GENETICO_ACTUAL')}
                  disabled={true}
                />
              </Row>
              <div className="clearfix"></div>
              <ModalIndexPage />
              <FormSubtitle>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.TITLE')}</FormSubtitle>
              <ListadoPage noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX." table={tableIndex}>
              </ListadoPage>
              <div className="clearfix"></div>
              {
              /*<ModalEvolucionPage />
              <FormSubtitle>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.TITLE')}</FormSubtitle>
              <ListadoPage noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION." table={tableEvolucion}>
              </ListadoPage>
              <div className="clearfix"></div>
              {
                this.props.fichaVerraco.evolucionesAnadidas.length > 0 ? (
                  <Line data={dataLine} height={50} />
                ) : null
              }*/
              }
              {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && [
                <ModalAlimentacionPage key="ModalAlimentacionPage" />,
                <FormSubtitle key="FormSubtitle">{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.TITLE')}</FormSubtitle>,
                <ListadoPage key="ListadoPage" noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION." table={tableAlimentacion}>
                </ListadoPage>
              ]}
              <div className="clearfix"></div>
            </Tab>
          </Tabs>
          <div className="clearfix"></div>
          <div className="botones-pie-form">
            <Button type="button" className="btn btn-primary" onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoVerraco.bind(this)) : null}>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GUARDAR_Y_NUEVO')}</Button>
            <Button
              type="button"
              onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoVerraco.bind(this)) : null}
              className={"btn btn-primary" + (this.props.submitSucceeded === true ? ' disabled' : '')}>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white">
              <Link to={{pathname: '/FichaVerraco', state: { from: this.props.location.pathname }}}>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CANCELAR')}</Link>
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'nuevoVerraco',
  onSubmitFail
})(NuevoVerraco))
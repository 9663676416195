import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InformesPool from '../../components/informes/InformesPool'
import {obtenerInformePool} from '../../actions/informes/informes'
import {obtenerDatosPanelControl} from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    panelControl: state.panelControl
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformePool,
      obtenerDatosPanelControl
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InformesPool))
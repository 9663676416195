import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import AjusteStock from '../../components/ajusteStock/AjusteStock'
import {fetchAjusteStock} from '../../actions/ajusteStock/ajusteStock'

export function mapStateToProps (state) {
  return {
    ...state.ajusteStock,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAjusteStock
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AjusteStock))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAsignarAnalisisPool from '../../components/poolExtracciones/FiltrosAsignarAnalisisPool'
import {
  seleccionarTodosDisponiblesExtracciones,  deseleccionarTodosDisponiblesExtracciones, deleteSeleccionDisponiblesAnalisisPool,
  fetchAnalisisDisponibles
} from '../../actions/poolExtracciones/poolExtracciones'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pool: state.poolExtracciones.pool,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboRaza: state.combos.comboRaza,
      comboTipoDiluyente: state.combos.comboTipoDiluyente
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAnalisisDisponibles,
      seleccionarTodosDisponiblesExtracciones,
      deseleccionarTodosDisponiblesExtracciones,
      deleteSeleccionDisponiblesAnalisisPool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAsignarAnalisisPool))

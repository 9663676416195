import combosActionTypes from '../../constants/actions/combos/combos'

export function comboCodLineaGenetica () {
  return {
    type: combosActionTypes.COMBO_COD_LINEA_GENETICA
  }
}

export function comboCodLineaGeneticaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_COD_LINEA_GENETICA_SUCCESS,
    data
  }
}

export function comboLineaGenetica () {
  return {
    type: combosActionTypes.COMBO_LINEA_GENETICA
  }
}

export function comboLineaGeneticaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LINEA_GENETICA_SUCCESS,
    data
  }
}

export function comboCodRaza () {
  return {
    type: combosActionTypes.COMBO_COD_RAZA
  }
}

export function comboCodRazaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_COD_RAZA_SUCCESS,
    data
  }
}

export function comboRaza () {
  return {
    type: combosActionTypes.COMBO_RAZA
  }
}

export function comboRazaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RAZA_SUCCESS,
    data
  }
}

export function comboNombreGrupoVerraco () {
  return {
    type: combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO
  }
}

export function comboNombreGrupoVerracoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_SUCCESS,
    data
  }
}

export function comboVerraco (values, noEliminado) {
  return {
    type: combosActionTypes.COMBO_VERRACO,
    values,
    noEliminado
  }
}

export function comboVerracoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VERRACO_SUCCESS,
    data
  }
}

export function comboTipoInstalacionPadre () {
  return {
    type: combosActionTypes.COMBO_TIPO_INSTALACION_PADRE,
  }
}

export function comboTipoInstalacionPadreSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_INSTALACION_PADRE_SUCCESS,
    data
  }
}

export function comboTipoInstalacion () {
  return {
    type: combosActionTypes.COMBO_TIPO_INSTALACION,
  }
}

export function comboTipoInstalacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_INSTALACION_SUCCESS,
    data
  }
}

export function comboInstalacionPadre (idTipoInstalacionPadre) {
  return {
    type: combosActionTypes.COMBO_INSTALACION_PADRE,
    idTipoInstalacionPadre
  }
}

export function comboInstalacionPadreSuccess (data) {
  return {
    type: combosActionTypes.COMBO_INSTALACION_PADRE_SUCCESS,
    data
  }
}

export function comboCodInstalacion () {
  return {
    type: combosActionTypes.COMBO_COD_INSTALACION
  }
}

export function comboCodInstalacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_COD_INSTALACION_SUCCESS,
    data
  }
}

export function comboNombreInstalacion (){
  return {
    type: combosActionTypes.COMBO_NOMBRE_INSTALACION
  }
}

export function comboNombreInstalacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRE_INSTALACION_SUCCESS,
    data
  }
}

export function comboOperario (){
  return {
    type: combosActionTypes.COMBO_OPERARIO
  }
}

export function comboOperarioSuccess (data) {
  return {
    type: combosActionTypes.COMBO_OPERARIO_SUCCESS,
    data
  }
}

export function comboSalaExtraccion (){
  return {
    type: combosActionTypes.COMBO_SALA_EXTRACCION
  }
}

export function comboSalaExtraccionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_SALA_EXTRACCION_SUCCESS,
    data
  }
}

export function comboSalaExtraccionActivo (){
  return {
    type: combosActionTypes.COMBO_SALA_EXTRACCION_ACTIVO
  }
}

export function comboSalaExtraccionActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_SALA_EXTRACCION_ACTIVO_SUCCESS,
    data
  }
}

export function comboCliente (){
  return {
    type: combosActionTypes.COMBO_CLIENTE
  }
}

export function comboClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CLIENTE_SUCCESS,
    data
  }
}

export function comboDireccionCliente (idCliente, mostrarAvisoClienteSinDirecciones, callback){
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE,
    idCliente,
    mostrarAvisoClienteSinDirecciones,
    callback
  }
}

export function comboDireccionClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_SUCCESS,
    data
  }
}

export function comboDireccionClienteSecundaria (idCliente){
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_SECUNDARIA,
    idCliente    
  }
}

export function comboDireccionClienteSecundariaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_SECUNDARIA_SUCCESS,
    data
  }
}

export function comboDireccionesClienteSinPredeterminado (idCliente){
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO,
    idCliente
  }
}

export function comboDireccionesClienteSinPredeterminadoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO_SUCCESS,
    data
  }
}

export function comboDireccionClienteActivo (form=null,field=null) {
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_ACTIVO,
    form,
    field
  }
}

export function comboDireccionClienteActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_DIRECCION_CLIENTE_ACTIVO_SUCCESS,
    data
  }
}

export function comboUbicacion (){
  return {
    type: combosActionTypes.COMBO_UBICACION
  }
}

export function comboUbicacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_UBICACION_SUCCESS,
    data
  }
}

export function comboTipoDosis (){
  return {
    type: combosActionTypes.COMBO_TIPO_DOSIS
  }
}

export function comboTipoDosisSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_DOSIS_SUCCESS,
    data
  }
}

export function comboObtenerCentroUsuario (){
  return {
    type: combosActionTypes.COMBO_OBTENER_CENTRO_USUARIO
  }
}

export function comboObtenerCentroUsuarioSuccess (data) {
  return {
    type: combosActionTypes.COMBO_OBTENER_CENTRO_USUARIO_SUCCESS,
    data
  }
}

export function comboListadoVerracos () {
  return {
    type: combosActionTypes.COMBO_LISTADO_VERRACO,
  }
}

export function comboListadoVerracosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LISTADO_VERRACO_SUCCESS,
    data
  }
}

export function comboObservacionesEyaculadoEliminado () {
  return {
    type: combosActionTypes.COMBO_OBSERVACIONES_EYACULADO_ELIMINADO,
  }
}

export function comboObservacionesEyaculadoEliminadoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_OBSERVACIONES_EYACULADO_ELIMINADO_SUCCESS,
    data
  }
}

export function comboCausaEliminacion () {
  return {
    type: combosActionTypes.COMBO_CAUSA_ELIMINACION,
  }
}

export function comboCausaEliminacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CAUSA_ELIMINACION_SUCCESS,
    data
  }
}

export function fetchComboPool () {
  return {
    type: combosActionTypes.FETCH_COMBO_POOL,
  }
}

export function fetchComboPoolSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMBO_POOL_SUCCESS,
    data
  }
}

export function fetchComboProducto (data) {
  return {
    type: combosActionTypes.FETCH_COMBO_PRODUCTO,
    data
  }
}

export function fetchComboProductoSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMBO_PRODUCTO_SUCCESS,
    data
  }
}

export function fetchComboTipoDiluyente () {
  return {
    type: combosActionTypes.FETCH_COMBO_TIPO_DILUYENTE
  }
}

export function fetchComboTipoDiluyenteSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMBO_TIPO_DILUYENTE_SUCCESS,
    data
  }
}

export function comboTipoAlimentacion () {
  return {
    type: combosActionTypes.COMBO_TIPO_ALIMENTACION,
  }
}

export function comboTipoAlimentacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_ALIMENTACION_SUCCESS,
    data
  }
}

export function comboTipoIntervencionSanitaria () {
  return {
    type: combosActionTypes.COMBO_TIPO_INTERVENCION_SANITARIA,
  }
}

export function comboTipoIntervencionSanitariaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_INTERVENCION_SANITARIA_SUCCESS,
    data
  }
}

export function comboVerracoSinUbicacion (valor) {
  return {
    type: combosActionTypes.COMBO_VERRACO_SIN_UBICACION,
    valor
  }
}

export function comboVerracoSinUbicacionSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VERRACO_SIN_UBICACION_SUCCESS,
    data
  }
}

export function comboMaquina (values) {
  return {
    type: combosActionTypes.COMBO_MAQUINA,
    values
  }
}

export function comboMaquinaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_MAQUINA_SUCCESS,
    data
  }
}

export function comboTipoDiluyente (values) {
  return {
    type: combosActionTypes.COMBO_TIPO_DILUYENTE,
    values
  }
}

export function comboRuta () {
  return {
    type: combosActionTypes.COMBO_RUTA
  }
}

export function comboRutaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RUTA_SUCCESS,
    data
  }
}

export function comboRutasActivas () {
  return {
    type: combosActionTypes.COMBO_RUTAS_ACTIVAS
  }
}

export function comboRutasActivasSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RUTAS_ACTIVAS_SUCCESS,
    data
  }
}

export function comboCifCliente () {
  return {
    type: combosActionTypes.COMBO_CIF_CLIENTE
  }
}

export function comboCifClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CIF_CLIENTE_SUCCESS,
    data
  }
}

export function comboLocalidadCliente () {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_CLIENTE
  }
}

export function comboLocalidadClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_CLIENTE_SUCCESS,
    data
  }
}

export function comboInstalacionesPadre () {
  return {
    type: combosActionTypes.COMBO_INSTALACIONES_PADRE
  }
}

export function comboInstalacionesPadreSuccess (data) {
  return {
    type: combosActionTypes.COMBO_INSTALACIONES_PADRE_SUCCESS,
    data
  }
}

export function comboNombresOperarios () {
  return {
    type: combosActionTypes.COMBO_NOMBRES_OPERARIOS
  }
}

export function comboNombresOperariosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRES_OPERARIOS_SUCCESS,
    data
  }
}

export function comboApellidosOperarios () {
  return {
    type: combosActionTypes.COMBO_APELLIDOS_OPERARIOS
  }
}

export function comboApellidosOperariosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_APELLIDOS_OPERARIOS_SUCCESS,
    data
  }
}

export function comboLocalidadOperarios () {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_OPERARIOS
  }
}

export function comboLocalidadOperariosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_OPERARIOS_SUCCESS,
    data
  }
}

export function comboEmpresaVisitas () {
  return {
    type: combosActionTypes.COMBO_EMPRESA_VISITAS
  }
}

export function comboEmpresaVisitasSuccess (data) {
  return {
    type: combosActionTypes.COMBO_EMPRESA_VISITAS_SUCCESS,
    data
  }
}

export function comboContactoVistas () {
  return {
    type: combosActionTypes.COMBO_CONTACTO_VISITAS
  }
}

export function comboContactoVistasSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CONTACTO_VISITAS_SUCCESS,
    data
  }
}

export function comboLineaGeneticaActiva () {
  return {
    type: combosActionTypes.COMBO_LINEA_ACTIVA_GENETICA
  }
}

export function comboLineaGeneticaActivaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LINEA_GENETICA_ACTIVA_SUCCESS,
    data
  }
}

export function comboRazaActiva () {
  return {
    type: combosActionTypes.COMBO_ACTIVA_RAZA
  }
}

export function comboRazaActivaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RAZA_ACTIVA_SUCCESS,
    data
  }
}

export function comboOperarioActivo () {
  return {
    type: combosActionTypes.COMBO_OPERARIO_ACTIVO
  }
}

export function comboOperarioActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_OPERARIO_ACTIVO_SUCCESS,
    data
  }
}

export function fetchComboRectaCalibrado () {
  return {
    type: combosActionTypes.FETCH_COMBO_RECTA_CALIBRADO
  }
}

export function fetchComboRectaCalibradoSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMBO_RECTA_CALIBRADO_SUCCESS,
    data
  }
}

export function comboEmpresaTransportista () {
  return {
    type: combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA
  }
}

export function comboEmpresaTransportistaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_SUCCESS,
    data
  }
}

export function comboConductoresTransportista () {
  return {
    type: combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA
  }
}

export function comboConductoresTransportistaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_SUCCESS,
    data
  }
}

export function comboVehiculosTransportista () {
  return {
    type: combosActionTypes.COMBO_VEHICULO_TRANSPORTISTA
  }
}

export function comboVehiculosTransportistaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VEHICULO_TRANSPORTISTA_SUCCESS,
    data
  }
}

export function comboUsuariosCentro () {
  return {
    type: combosActionTypes.COMBO_USUARIOS_CENTRO
  }
}

export function comboUsuariosCentroSuccess (data) {
  return {
    type: combosActionTypes.COMBO_USUARIOS_CENTRO_SUCCESS,
    data
  }
}

export function comboGrupoUsuariosCentro () {
  return {
    type: combosActionTypes.COMBO_GRUPO_USUARIOS_CENTRO
  }
}

export function comboGrupoUsuariosCentroSuccess (data) {
  return {
    type: combosActionTypes.COMBO_GRUPO_USUARIOS_CENTRO_SUCCESS,
    data
  }
}

export function comboNombreProductos () {
  return {
    type: combosActionTypes.COMBO_NOMBRE_PRODUCTO
  }
}

export function comboNombreProductosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRE_PRODUCTO_SUCCESS,
    data
  }
}

export function comboReferenciaProductos () {
  return {
    type: combosActionTypes.COMBO_REFERENCIA_PRODUCTO
  }
}

export function comboReferenciaProductosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_REFERENCIA_PRODUCTO_SUCCESS,
    data
  }
}

export function comboNombreEscandallo () {
  return {
    type: combosActionTypes.COMBO_NOMBRE_ESCANDALLO
  }
}

export function comboNombreEscandalloSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRE_ESCANDALLO_SUCCESS,
    data
  }
}

export function comboProductoEscandallo () {
  return {
    type: combosActionTypes.COMBO_PRODUCTO_ESCANDALLO
  }
}

export function comboProductoEscandalloSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PRODUCTO_ESCANDALLO_SUCCESS,
    data
  }
}

export function comboProveedoresEntradaMercancia () {
  return {
    type: combosActionTypes.COMBO_PROVEEDORES_ENTRADA_MERCANCIA
  }
}

export function comboProveedoresEntradaMercanciaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PROVEEDORES_ENTRADA_MERCANCIA_SUCCESS,
    data
  }
}

export function comboAlbaranesEntradaMercancia () {
  return {
    type: combosActionTypes.COMBO_ALBARANES_ENTRADA_MERCANCIA
  }
}

export function comboAlbaranesEntradaMercanciaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_ALBARANES_ENTRADA_MERCANCIA_SUCCESS,
    data
  }
}

export function comboOrigenesEntradaMercancia () {
  return {
    type: combosActionTypes.COMBO_ORIGENES_ENTRADA_MERCANCIA
  }
}

export function comboOrigenesEntradaMercanciaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_ORIGENES_ENTRADA_MERCANCIA_SUCCESS,
    data
  }
}

export function comboLotes (producto) {
  return {
    type: combosActionTypes.COMBO_LOTES,
    producto
  }
}

export function comboLotesSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOTES_SUCCESS,
    data
  }
}

export function comboLotesActivos () {
  return {
    type: combosActionTypes.COMBO_LOTES_ACTIVOS
  }
}

export function comboLotesActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOTES_ACTIVOS_SUCCESS,
    data
  }
}

export function comboProductosActivos () {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_ACTIVOS
  }
}

export function comboProductosActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_SUCCESS,
    data
  }
}

export function comboProductosActivosTrazabilidad () {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD
  }
}

export function comboProductosActivosTrazabilidadSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD_SUCCESS,
    data
  }
}

export function comboLotesProductoEspecificoActivo (idProducto, idLote) {
  return {
    type: combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO,
    idProducto,
    idLote
  }
}

export function comboLotesProductoEspecificoActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_SUCCESS,
    data
  }
}

export function comboLotesProductoEspecificoActivoTrazabilidad (idProducto) {
  return {
    type: combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD,
    idProducto
  }
}

export function comboLotesProductoEspecificoActivoTrazabilidadSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD_SUCCESS,
    data
  }
}

export function comboRazonSocialClientesActivos () {
  return {
    type: combosActionTypes.COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS
  }
}

export function comboRazonSocialClientesActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS_SUCCESS,
    data
  }
}

export function comboAliasClientesActivos () {
  return {
    type: combosActionTypes.COMBO_ALIAS_CLIENTES_ACTIVOS
  }
}

export function comboAliasClientesActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_ALIAS_CLIENTES_ACTIVOS_SUCCESS,
    data
  }
}

export function comboIndex () {
  return {
    type: combosActionTypes.COMBO_INDEX
  }
}

export function comboIndexSuccess (data) {
  return {
    type: combosActionTypes.COMBO_INDEX_SUCCESS,
    data
  }
}

export function comboUbicacionPadre () {
  return {
    type: combosActionTypes.COMBO_UBICACION_PADRE
  }
}

export function comboUbicacionPadreSuccess (data) {
  return {
    type: combosActionTypes.COMBO_UBICACION_PADRE_SUCCESS,
    data
  }
}

export function comboVerracosPresentesActivos () {
  return {
    type: combosActionTypes.COMBO_VERRACOS_PRESENTES_ACTIVOS
  }
}

export function comboVerracosPresentesActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VERRACOS_PRESENTES_ACTIVOS_SUCCESS,
    data
  }
}

export function comboVerracosExtraccionAlbaran(values) {
  return {
    type: combosActionTypes.COMBO_VERRACOS_EXTRACCION_ALBARAN,
    values
  }
}

export function comboVerracosExtraccionAlbaranSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VERRACOS_EXTRACCION_ALBARAN_SUCCESS,
    data
  }
}

export function comboClienteActivo () {
  return {
    type: combosActionTypes.COMBO_CLIENTE_ACTIVO
  }
}

export function comboClienteActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CLIENTE_ACTIVO_SUCCESS,
    data
  }
}

export function comboClienteSecundarioActivo () {
  return {
    type: combosActionTypes.COMBO_CLIENTE_SECUNDARIO_ACTIVO
  }
}

export function comboClienteSecundarioActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CLIENTE_SECUNDARIO_ACTIVO_SUCCESS,
    data
  }
}

export function comboClienteConVerracosEnPropiedad () {
  return {
    type: combosActionTypes.COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD
  }
}

export function comboClienteConVerracosEnPropiedadSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD_SUCCESS,
    data
  }
}


export function comboPoolsExtraccionAlbaran(values) {
  return {
    type: combosActionTypes.COMBO_POOLS_EXTRACCION_ALBARAN,
    values
  }
}

export function comboPoolsExtraccionAlbaranSuccess (data) {
  return {
    type: combosActionTypes.COMBO_POOLS_EXTRACCION_ALBARAN_SUCCESS,
    data
  }
}

export function comboNumeroPedido () {
  return {
    type: combosActionTypes.COMBO_NUMERO_PEDIDO
  }
}

export function comboNumeroPedidoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NUMERO_PEDIDO_SUCCESS,
    data
  }
}

export function comboTransportistasCliente (idDireccionCliente) {
  return {
    type: combosActionTypes.COMBO_TRANSPORTISTAS_CLIENTE,
    idDireccionCliente
  }
}

export function comboTransportistasClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TRANSPORTISTAS_CLIENTE_SUCCESS,
    data
  }
}

export function comboRutaTransportistaCliente (idDireccionCliente, idTransportista) {
  return {
    type: combosActionTypes.COMBO_RUTA_TRANSPORTISTA_CLIENTE,
    idDireccionCliente,
    idTransportista
  }
}

export function comboRutaTransportistaClienteSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RUTA_TRANSPORTISTA_CLIENTE_SUCCESS,
    data
  }
}

export function comboVehiculosTransportistaDeterminado (idTransportista) {
  return {
    type: combosActionTypes.COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO,
    idTransportista
  }
}

export function comboVehiculosTransportistaDeterminadoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO_SUCCESS,
    data
  }
}

export function comboConductoresTransportistaDeterminado (idTransportista) {
  return {
    type: combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO,
    idTransportista
  }
}

export function comboConductoresTransportistaDeterminadoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO_SUCCESS,
    data
  }
}

export function comboNumerosAlbaran () {
  return {
    type: combosActionTypes.COMBO_NUMEROS_ALBARAN
  }
}

export function comboNumerosAlbaranSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NUMEROS_ALBARAN_SUCCESS,
    data
  }
}

export function comboEmpresaTransportistaActivos () {
  return {
    type: combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS
  }
}

export function comboEmpresaTransportistaActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS_SUCCESS,
    data
  }
}

export function comboInstalacionPadreActiva () {
  return {
    type: combosActionTypes.COMBO_INSTALACION_PADRE_ACTIVA
  }
}

export function comboInstalacionPadreActivaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_INSTALACION_PADRE_ACTIVA_SUCCESS,
    data
  }
}

export function comboFiltroVerracosLimpieza () {
  return {
    type: combosActionTypes.COMBO_FILTRO_VERRACOS_LIMPIEZA
  }
}

export function comboFiltroVerracosLimpiezaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_FILTRO_VERRACOS_LIMPIEZA_SUCCESS,
    data
  }
}

export function comboLocalidadClientes () {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_CLIENTES
  }
}

export function comboLocalidadClientesSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LOCALIDAD_CLIENTES_SUCCESS,
    data
  }
}

export function comboProvinciasClientes () {
  return {
    type: combosActionTypes.COMBO_PROVINCIAS_CLIENTES
  }
}

export function comboProvinciasClientesSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PROVINCIAS_CLIENTES_SUCCESS,
    data
  }
}

export function comboUsuariosNoAsignadosOperario () {
  return {
    type: combosActionTypes.COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO
  }
}

export function comboUsuariosNoAsignadosOperarioSuccess (data) {
  return {
    type: combosActionTypes.COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO_SUCCESS,
    data
  }
}

export function comboOperarioPredeterminado () {
  return {
    type: combosActionTypes.COMBO_OPERARIO_PREDETERMINADO
  }
}

export function comboOperarioPredeterminadoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_OPERARIO_PREDETERMINADO_SUCCESS,
    data
  }
}

export function comboProductosValidacionActivos () {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_VALIDACION_ACTIVOS
  }
}

export function comboProductosValidacionActivosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_PRODUCTOS_VALIDACION_ACTIVOS_SUCCESS,
    data
  }
}

export function comboNombreGrupoVerracoActiva () {
  return {
    type: combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA
  }
}

export function comboNombreGrupoVerracoActivaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA_SUCCESS,
    data
  }
}

export function comboTodasUbicaciones () {
  return {
    type: combosActionTypes.COMBO_TODAS_UBICACIONES
  }
}

export function comboTodasUbicacionesSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TODAS_UBICACIONES_SUCCESS,
    data
  }
}

export function comboInstalacionesPadreOSinHijos () {
  return {
    type: combosActionTypes.COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS
  }
}

export function comboInstalacionesPadreOSinHijosSuccess (data) {
  return {
    type: combosActionTypes.COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS_SUCCESS,
    data
  }
}

export function comboVerracoConSeguimiento () {
  return {
    type: combosActionTypes.COMBO_VERRACOS_CON_SEGUIMIENTO
  }
}

export function comboVerracoConSeguimientoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_VERRACOS_CON_SEGUIMIENTO_SUCCESS,
    data
  }
}


export function comboRazaGranja (idDireccionCliente) {
  return {
    type: combosActionTypes.COMBO_RAZA_GRANJA,
    idDireccionCliente
  }
}

export function comboRazaGranjaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_RAZA_GRANJA_SUCCESS,
    data
  }
}

export function comboLineaGeneticaGranja (idDireccionCliente, idRaza) {
  return {
    type: combosActionTypes.COMBO_LINEA_GENETICA_GRANJA,
    idDireccionCliente,
    idRaza
  }
}

export function comboLineaGeneticaGranjaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_LINEA_GENETICA_GRANJA_SUCCESS,
    data
  }
}

export function comboTipoDosisGranja (idDireccionCliente, idRaza, idLinea, nivelGenetico){
  return {
    type: combosActionTypes.COMBO_TIPO_DOSIS_GRANJA,
    idDireccionCliente,
    idRaza,
    idLinea,
    nivelGenetico
  }
}

export function comboTipoDosisGranjaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_TIPO_DOSIS_GRANJA_SUCCESS,
    data
  }
}

export function comboNivelGenetico () {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO
  }
}

export function comboNivelGeneticoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_SUCCESS,
    data
  }
}

export function comboNivelGeneticoGeneral () {  
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_GENERAL,    
  }
}

export function comboNivelGeneticoGeneralSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_GENERAL_SUCCESS,
    data
  }
}

export function comboNivelGeneticoActivo () {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_ACTIVO,    
  }
}

export function comboNivelGeneticoActivoSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_ACTIVO_SUCCESS,
    data
  }
}

export function comboNivelGeneticoActivoGranja (idDireccionCliente, idRaza, idLinea) {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_ACTIVO_GRANJA,
    idDireccionCliente,
    idRaza,
    idLinea
  }
}

export function comboNivelGeneticoActivoGranjaSuccess (data) {
  return {
    type: combosActionTypes.COMBO_NIVEL_GENETICO_ACTIVO_GRANJA_SUCCESS,
    data
  }
}

export function comboCodPool () {
  return {
    type: combosActionTypes.COMBO_COD_POOL
  }
}

export function comboCodPoolSuccess (data) {
  return {
    type: combosActionTypes.COMBO_COD_POOL_SUCCESS,
    data
  }
}

export default {
  comboCodLineaGenetica,
  comboCodLineaGeneticaSuccess,
  comboLineaGenetica,
  comboLineaGeneticaSuccess,
  comboCodRaza,
  comboCodRazaSuccess,
  comboRaza,
  comboRazaSuccess,
  comboNombreGrupoVerraco,
  comboNombreGrupoVerracoSuccess,
  comboVerraco,
  comboVerracoSuccess,
  comboTipoInstalacionPadre,
  comboTipoInstalacionPadreSuccess,
  comboTipoInstalacion,
  comboTipoInstalacionSuccess,
  comboInstalacionPadre,
  comboInstalacionPadreSuccess,
  comboCodInstalacion,
  comboCodInstalacionSuccess,
  comboNombreInstalacion,
  comboNombreInstalacionSuccess,
  comboOperario,
  comboOperarioSuccess,
  comboSalaExtraccion,
  comboSalaExtraccionSuccess,
  comboCliente,
  comboClienteSuccess,
  comboDireccionCliente,
  comboDireccionClienteSuccess,
  comboDireccionesClienteSinPredeterminado,
  comboDireccionesClienteSinPredeterminadoSuccess,
  comboDireccionClienteActivo,
  comboDireccionClienteActivoSuccess,
  comboUbicacion,
  comboUbicacionSuccess,
  comboTipoDosis,
  comboTipoDosisSuccess,
  comboObtenerCentroUsuario,
  comboObtenerCentroUsuarioSuccess,
  comboListadoVerracos,
  comboListadoVerracosSuccess,
  comboObservacionesEyaculadoEliminado,
  comboObservacionesEyaculadoEliminadoSuccess,
  comboCausaEliminacion,
  comboCausaEliminacionSuccess,
  fetchComboPool,
  fetchComboPoolSuccess,
  fetchComboProducto,
  fetchComboProductoSuccess,
  fetchComboTipoDiluyente,
  fetchComboTipoDiluyenteSuccess,
  comboTipoAlimentacion,
  comboTipoAlimentacionSuccess,
  comboTipoIntervencionSanitaria,
  comboTipoIntervencionSanitariaSuccess,
  comboVerracoSinUbicacion,
  comboVerracoSinUbicacionSuccess,
  comboMaquina,
  comboMaquinaSuccess,
  comboRuta,
  comboRutaSuccess,
  comboRutasActivas,
  comboRutasActivasSuccess,
  comboCifCliente,
  comboCifClienteSuccess,
  comboLocalidadCliente,
  comboLocalidadClienteSuccess,
  comboInstalacionesPadre,
  comboInstalacionesPadreSuccess,
  comboNombresOperarios,
  comboNombresOperariosSuccess,
  comboApellidosOperarios,
  comboApellidosOperariosSuccess,
  comboLocalidadOperarios,
  comboLocalidadOperariosSuccess,
  comboEmpresaVisitas,
  comboEmpresaVisitasSuccess,
  comboContactoVistas,
  comboContactoVistasSuccess,
  comboLineaGeneticaActiva,
  comboLineaGeneticaActivaSuccess,
  comboRazaActiva,
  comboRazaActivaSuccess,
  comboOperarioActivo,
  comboOperarioActivoSuccess,
  comboSalaExtraccionActivo,
  comboSalaExtraccionActivoSuccess,
  fetchComboRectaCalibrado,
  fetchComboRectaCalibradoSuccess,
  comboEmpresaTransportista,
  comboEmpresaTransportistaSuccess,
  comboConductoresTransportista,
  comboConductoresTransportistaSuccess,
  comboVehiculosTransportista,
  comboVehiculosTransportistaSuccess,
  comboUsuariosCentro,
  comboUsuariosCentroSuccess,
  comboGrupoUsuariosCentro,
  comboGrupoUsuariosCentroSuccess,
  comboNombreProductos,
  comboNombreProductosSuccess,
  comboReferenciaProductos,
  comboReferenciaProductosSuccess,
  comboNombreEscandallo,
  comboNombreEscandalloSuccess,
  comboProductoEscandallo,
  comboProductoEscandalloSuccess,
  comboProveedoresEntradaMercancia,
  comboProveedoresEntradaMercanciaSuccess,
  comboAlbaranesEntradaMercancia,
  comboAlbaranesEntradaMercanciaSuccess,
  comboOrigenesEntradaMercancia,
  comboOrigenesEntradaMercanciaSuccess,
  comboLotes,
  comboLotesSuccess,
  comboLotesActivos,
  comboLotesActivosSuccess,
  comboProductosActivos,
  comboProductosActivosSuccess,
  comboProductosActivosTrazabilidad,
  comboProductosActivosTrazabilidadSuccess,
  comboLotesProductoEspecificoActivo,
  comboLotesProductoEspecificoActivoSuccess,
  comboLotesProductoEspecificoActivoTrazabilidad,
  comboLotesProductoEspecificoActivoTrazabilidadSuccess,
  comboRazonSocialClientesActivos,
  comboRazonSocialClientesActivosSuccess,
  comboIndex,
  comboIndexSuccess,
  comboUbicacionPadre,
  comboUbicacionPadreSuccess,
  comboVerracosPresentesActivos,
  comboVerracosPresentesActivosSuccess,
  comboVerracosExtraccionAlbaran,
  comboVerracosExtraccionAlbaranSuccess,
  comboPoolsExtraccionAlbaran,
  comboPoolsExtraccionAlbaranSuccess,
  comboClienteActivo,
  comboClienteActivoSuccess,
  comboClienteSecundarioActivo,
  comboClienteSecundarioActivoSuccess,
  comboClienteConVerracosEnPropiedad,
  comboClienteConVerracosEnPropiedadSuccess,
  comboNumeroPedido,
  comboNumeroPedidoSuccess,
  comboTransportistasCliente,
  comboTransportistasClienteSuccess,
  comboRutaTransportistaCliente,
  comboRutaTransportistaClienteSuccess,
  comboVehiculosTransportistaDeterminado,
  comboVehiculosTransportistaDeterminadoSuccess,
  comboConductoresTransportistaDeterminado,
  comboConductoresTransportistaDeterminadoSuccess,
  comboNumerosAlbaran,
  comboNumerosAlbaranSuccess,
  comboEmpresaTransportistaActivos,
  comboEmpresaTransportistaActivosSuccess,
  comboInstalacionPadreActiva,
  comboInstalacionPadreActivaSuccess,
  comboLocalidadClientes,
  comboLocalidadClientesSuccess,
  comboProvinciasClientes,
  comboProvinciasClientesSuccess,
  comboUsuariosNoAsignadosOperario,
  comboUsuariosNoAsignadosOperarioSuccess,
  comboOperarioPredeterminado,
  comboOperarioPredeterminadoSuccess,
  comboProductosValidacionActivos,
  comboProductosValidacionActivosSuccess,
  comboNombreGrupoVerracoActiva,
  comboNombreGrupoVerracoActivaSuccess,
  comboTodasUbicaciones,
  comboTodasUbicacionesSuccess,
  comboInstalacionesPadreOSinHijos,
  comboInstalacionesPadreOSinHijosSuccess,
  comboVerracoConSeguimiento,
  comboVerracoConSeguimientoSuccess,
  comboFiltroVerracosLimpieza,
  comboFiltroVerracosLimpiezaSuccess,
  comboRazaGranja,
  comboRazaGranjaSuccess,
  comboLineaGeneticaGranja,
  comboLineaGeneticaGranjaSuccess,
  comboTipoDosisGranja,
  comboTipoDosisGranjaSuccess,
  comboNivelGenetico,
  comboNivelGeneticoSuccess,
  comboNivelGeneticoGeneral,
  comboNivelGeneticoGeneralSuccess,
  comboNivelGeneticoActivo,
  comboNivelGeneticoActivoSuccess,
  comboNivelGeneticoActivoGranja,
  comboNivelGeneticoActivoGranjaSuccess,
  comboCodPool,
  comboCodPoolSuccess
}
import actionTypes from '../../../constants/actions/planSanitario/controlSemen/controlSemen'

export function initialState () {
  return {
    mainList: [],
    filtros: {},
    checkedFiltros: false,
    esApartadoPrincipal: false,
    list: [],
    conservacion: null,
    codigo: '',
    idAnalisis: null,
    idPool: null,
    showModalListado: false,
    checkedFiltrosListado: false,
    checkedInicio: false,
    filtrosInicio: [],
    listVerracos: [],
    listVerracosAsignados: [],
    checkedVerracosAnadidos: false,
    showModalInicio: false,
    showModalControlSemen: false,    
    data: {},
    archivo: []
  }
}

export function fetchSuccess (state, {mainList, filtros}) {
  return {
    ...state,
    mainList,
    filtros,
    esApartadoPrincipal: true,
    archivo: []
  }
}

export function seleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: true
  }
}

export function deSeleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: false
  }
}

export function openModalListadoSuccess (state, {list, conservacion, codigo, idAnalisis, idPool}) {
  return {
    ...state,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool,
    showModalListado: true
  }
}

export function closeModalListadoSuccess (state) {
  return {
    ...initialState(),
    mainList: state.mainList,
    filtros: state.filtros,
    checkedFiltros: state.checkedFiltros,
    esApartadoPrincipal: state.esApartadoPrincipal
  }
}

export function seleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: true
  }
}

export function deSeleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: false
  }
}


export function seleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: true
  }
}

export function deSeleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: false
  }
}

export function abrirModalInicio (state) {
  return {
    ...state,
    showModalInicio: true,
    archivo: []
  }
}

export function closeModalInicio (state) {
  return {
    ...state,
    showModalInicio: false,
    checkedInicio: false,
    archivo: []
  }
}

export function anadirVerracosModalInicioSuccess (state, {listVerracosAsignados}) {
  return {
    ...state,
    listVerracosAsignados: listVerracosAsignados,
    archivo: []
  }
}

export function fetchFiltrarModalInicioSuccess (state, {listVerracos,filtros}) {  
  return {
    ...state,
    listVerracos: listVerracos,    
    filtrosInicio: filtros,
    archivo: []
  }
}

export function subirArchivosInforme (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function recuperarDatosModalControlSemenSuccess(state, {data}) {
  return {
    ...state,
    data: data,
    showModalControlSemen: true
  }
}

export function abrirModalControlSemen(state) {
  return {
    ...state
  }
}

export function closeModalControlSemen(state) {
  return {
    ...state,
    showModalControlSemen: false
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_CONTROL_SEMEN_SUCCESS:
      return fetchSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CONTROL_SEMEN:
      return seleccionarTodos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CONTROL_SEMEN:
      return deSeleccionarTodos(state, action)
    case actionTypes.OPEN_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS:
      return openModalListadoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS:
      return closeModalListadoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_SEMEN:
      return seleccionarTodosModal(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_SEMEN:
      return deSeleccionarTodosModal(state, action)
    case actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN_SUCCESS:
      return fetchFiltrarModalInicioSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN:
      return seleccionarTodosVerracosInicio(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN:
      return deSeleccionarTodosVerracosInicio(state, action)
    case actionTypes.ABRIR_MODAL_INICIO_CONTROL_SEMEN:
      return abrirModalInicio(state, action)
    case actionTypes.CLOSE_MODAL_INICIO_CONTROL_SEMEN:
      return closeModalInicio(state, action)
    case actionTypes.SUBIR_ARCHIVO_INFORME:
      return subirArchivosInforme(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SEMEN_SUCCESS:
      return recuperarDatosModalControlSemenSuccess(state, action)     
    case actionTypes.ABRIR_MODAL_CONTROL_SEMEN:
      return abrirModalControlSemen(state, action)
    case actionTypes.CLOSE_MODAL_CONTROL_SEMEN:
      return closeModalControlSemen(state, action)      
    default:
      return state
  }
}
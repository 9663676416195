import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import {fetchPanelControl} from '../../../actions/panelControl/panelControl'
import { fetchControlMorfologico, deleteControlMorfologico, openModalFormControlMorfologico, openModalListadoControlMorfologico, abrirModalInicio } from '../../../actions/planSanitario/controlCalidad/controlMorfologico'
import ControlMorfologico from '../../../components/planSanitario/controlCalidad/ControlMorfologico'
import {comboOperario} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.controlMorfologico,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: {
      comboOperario: state.combos.comboOperario
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlMorfologico,
      deleteControlMorfologico,
      openModalFormControlMorfologico,
      openModalListadoControlMorfologico,
      yesNoModal,
      fetchPanelControl,
      comboOperario,
      abrirModalInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlMorfologico))
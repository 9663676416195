import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {logout} from '../../actions/auth/auth'
import AdminSubHeader from '../../components/layout/AdminSubHeader'

export function mapStateToProps (state) {
  return {
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      logout
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminSubHeader))

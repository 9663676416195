import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosProductoPage from '../../containers/producto/FiltrosProductoPage'
import ModalProductoPage from '../../containers/producto/ModalProductoPage'
import './Producto.scss'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class Producto extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.ALMACEN.PRODUCTOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerProductosAlmacen()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listProducto, checkedFiltrosProducto, dataProductos,
      actions: {recuperarDatosModalProducto, duplicarDatosModalProducto, cambiarEstadoProducto, onDeleteProducto}
    } = this.props

    listProducto.forEach(
      (row) => row.idElemento = row.idProducto
    )

    const table = {
      id: 'productoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'referencia', name: t('ALMACEN.PRODUCTO.REFERENCIA')},
        {id: 'nombre', name: t('ALMACEN.PRODUCTO.PRODUCTO')},
        {id: 'stockActual', name: t('ALMACEN.PRODUCTO.STOCK_REAL'), type: 'numero'},
        {id: 'unidadMedida', name: t('ALMACEN.PRODUCTO.MEDIDA')},
        {id: 'stockProduccion', name: t('ALMACEN.PRODUCTO.STOCK_PRODUCCION'), type: 'numero'},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'trazabilidad', name: t('ALMACEN.PRODUCTO.TRAZABILIDAD')}),
        {id: 'validacion', name: t('ALMACEN.PRODUCTO.VALIDACION')},
      ],
      rows: listProducto,
      filtros: <FiltrosProductoPage />,
      onEdit: (row) => recuperarDatosModalProducto(row.idProducto),
      data: dataProductos,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1),
      duplicar: (row) =>duplicarDatosModalProducto(row.idProducto),
      mostrarCambioEstado: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1),
      cambiarEstado: (row) => cambiarEstadoProducto(row.idProducto),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1),
      onDelete: (row) => onDeleteProducto(row.idProducto, row.referencia),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosProducto
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalProductoPage/>
        <h2 className="page-title">{t('ALMACEN.PRODUCTO.TITLE')}</h2>
        <SimpleTablePage {...table}>
        </SimpleTablePage>
      </div>
    )
  }
}

export default Producto
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosRuta from '../../components/ruta/FiltrosRuta'
import {openModal} from '../../actions/common'
import {abrirModalVacioRuta, seleccionarTodosRuta, deSeleccionarTodosRuta, deleteSeleccionRuta, cambioEstadoSeleccionRuta, fetchFiltrarRuta, crearCsvRuta, imprimirPdfRuta} from '../../actions/ruta/ruta'
import {comboRuta, comboCliente} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.ruta,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioRuta,
      seleccionarTodosRuta,
      deSeleccionarTodosRuta,
      deleteSeleccionRuta,
      cambioEstadoSeleccionRuta,
      comboRuta,
      comboCliente,
      fetchFiltrarRuta,
      crearCsvRuta,
      imprimirPdfRuta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosRuta))

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import comboObservacionesEyaculadoEliminadoActionTypes from '../../constants/actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'
import {fetchComboObservacionesEyaculadoEliminadoSuccess, recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess, submitNuevaComboObservacionesEyaculadoEliminadoSuccess, editarComboObservacionesEyaculadoEliminadoSuccess } from '../../actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getComboObservacionesEyaculadoEliminadoList from '../../services/comboObservacionesEyaculadoEliminado/getComboObservacionesEyaculadoEliminadoList'
import addComboObservacionesEyaculadoEliminado from '../../services/comboObservacionesEyaculadoEliminado/addComboObservacionesEyaculadoEliminado'
import getComboObservacionesEyaculadoEliminado from '../../services/comboObservacionesEyaculadoEliminado/getComboObservacionesEyaculadoEliminado'
import editComboObservacionesEyaculadoEliminado from '../../services/comboObservacionesEyaculadoEliminado/editComboObservacionesEyaculadoEliminado'

export function * fetchComboObservacionesEyaculadoEliminado () {
  try {
    const list = yield call(getComboObservacionesEyaculadoEliminadoList, null)
    yield put(fetchComboObservacionesEyaculadoEliminadoSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchComboObservacionesEyaculadoEliminado () {
  yield takeLatest(comboObservacionesEyaculadoEliminadoActionTypes.FETCH_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO, fetchComboObservacionesEyaculadoEliminado)
}

export function * submitNuevaComboObservacionesEyaculadoEliminado ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercion = yield call(addComboObservacionesEyaculadoEliminado, values, auth.token)
    yield put(submitNuevaComboObservacionesEyaculadoEliminadoSuccess(insercion))
    if (insercion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(closeModal())
    yield call(fetchComboObservacionesEyaculadoEliminado)
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaComboObservacionesEyaculadoEliminado () {
  yield takeLatest(comboObservacionesEyaculadoEliminadoActionTypes.SUBMIT_NUEVA_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO, submitNuevaComboObservacionesEyaculadoEliminado)
}

export function * recuperarDatosModalComboObservacionesEyaculadoEliminado ({id}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getComboObservacionesEyaculadoEliminado, id)
    yield put(recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess(registroModificar))
    console.log('recuperarDatosModalComboObservacionesEyaculadoEliminado',registroModificar)
    yield put(initialize('ModalComboObservacionesEyaculadoEliminado', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalComboObservacionesEyaculadoEliminado () {
  yield takeLatest(comboObservacionesEyaculadoEliminadoActionTypes.RECUPERAR_DATOS_MODAL_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO, recuperarDatosModalComboObservacionesEyaculadoEliminado)
}

export function * abrirModalVacioComboObservacionesEyaculadoEliminado () {
  try {
    yield put(recuperarDatosModalComboObservacionesEyaculadoEliminadoSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalComboObservacionesEyaculadoEliminado', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioComboObservacionesEyaculadoEliminado () {
  yield takeLatest(comboObservacionesEyaculadoEliminadoActionTypes.ABRIR_MODAL_VACIO_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO, abrirModalVacioComboObservacionesEyaculadoEliminado)
}

export function * editarComboObservacionesEyaculadoEliminado ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicion = yield call(editComboObservacionesEyaculadoEliminado, values, auth.token)
    yield put(editarComboObservacionesEyaculadoEliminadoSuccess(edicion))
    if (edicion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(closeModal())
    yield call(fetchComboObservacionesEyaculadoEliminado)
    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarComboObservacionesEyaculadoEliminado () {
  yield takeLatest(comboObservacionesEyaculadoEliminadoActionTypes.EDITAR_COMBO_OBSERVACIONES_EYACULADO_ELIMINADO, editarComboObservacionesEyaculadoEliminado)
}
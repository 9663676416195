import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAlimentacion from '../../components/fichaVerraco/FiltrosAlimentacion'
import {abrirModalVacioAlimentacion, seleccionarTodosAlimentacion, deSeleccionarTodosAlimentacion, deleteSeleccionAlimentacion} from '../../actions/fichaVerraco/fichaVerraco'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    fichaVerraco: state.fichaVerraco,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVacioAlimentacion,
      seleccionarTodosAlimentacion,
      deSeleccionarTodosAlimentacion,
      deleteSeleccionAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAlimentacion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Cliente from '../../components/cliente/Cliente'
import { filtrarCliente, recuperarDatosModalCliente, duplicarDatosModalCliente, cambiarEstadoCliente, onDeleteCliente } from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      filtrarCliente,
      recuperarDatosModalCliente,
      duplicarDatosModalCliente,
      cambiarEstadoCliente,
      onDeleteCliente
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cliente))
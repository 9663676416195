import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import GrupoUsuarios from '../../components/grupoUsuarios/GrupoUsuarios'
import { fetchGrupoUsuarios, abrirModalGrupoUsuarios, duplicarGrupoUsuarios, cambiarEstadoGrupoUsuarios, eliminarGrupoUsuarios } from '../../actions/grupoUsuarios/grupoUsuarios'

export function mapStateToProps (state) {
  return {
    ...state.grupoUsuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchGrupoUsuarios,
      abrirModalGrupoUsuarios,
      duplicarGrupoUsuarios,
      cambiarEstadoGrupoUsuarios,
      eliminarGrupoUsuarios
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GrupoUsuarios))
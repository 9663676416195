import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalRuta.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'

class ModalClienteRuta extends Component {
  anadirRuta (values) {
    const itemsSeleccion = this.props.listClientes.map((row) => {
    //const itemsSeleccion = listClientes.map((row, idRow) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'direccion': row.direccion,
          'idDireccionCliente': row.idDireccionCliente,
          'razonSocialCliente': row.razonSocialCliente,
          'cifCliente': row.cifCliente,
          'nombreTipoDireccion': row.nombreTipoDireccion,
          'nombreDireccion': row.nombreDireccion,
          'localidad': row.localidad,
          'provincia': row.provincia,
          'idElemento': row.idElemento + 'direccion'
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (Object.keys(list).length > 0) {
      this.props.actions.anadirCliente(list)
      this.props.change('seleccionar-todos-modal-cliente', false)
      this.props.change('cliente', '')
      this.props.change('cif', '')
      this.props.change('localidad', '')
      this.props.change('activo', '')
      this.props.actions.fetchFiltrarClienteRuta({list: this.props.clientesAnadidos})
    }
  }

  intercambiarCheckModalClienteRuta (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalClienteRuta()
    } else {
      this.props.actions.deSeleccionarTodosModalClienteRuta()
    }
  }

  componentDidMount () {
    this.props.actions.comboCliente()
    this.props.actions.comboCifCliente()
    this.props.actions.comboLocalidadCliente()
    this.props.actions.fetchClienteRuta(this.props.clientesAnadidos)
    this.props.change('cliente', '')
    this.props.change('cif', '')
    this.props.change('localidad', '')
    this.props.change('activo', '')
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.clientesAnadidos !== prevProps.clientesAnadidos) {
      let values = {}
      values.list = this.props.clientesAnadidos
      this.props.actions.fetchFiltrarClienteRuta(values)
    }
  }

  fetchFiltrarClienteRuta (values) {
    values.list = this.props.clientesAnadidos
    this.props.actions.fetchFiltrarClienteRuta(values)
  }

  close () {
    this.props.actions.cerrarModalClienteRuta()
    this.props.change('seleccionar-todos-modal-cliente', false)
    this.props.change('cliente', '')
    this.props.change('cif', '')
    this.props.change('localidad', '')
    this.props.change('activo', '')
    this.props.actions.fetchFiltrarClienteRuta({list: this.props.clientesAnadidos})
  }

  quitarFiltros (value) {
    let values = this.props.filtrosCliente
    values[value] = null
    this.props.actions.fetchClienteRuta(values)
    this.props.change(value, null)
  }

  render () {
    const {
      t, handleSubmit, showModalClienteRuta, checkedClienteRuta, numeroRegistrosPorPagina, filtrosCliente, listClientes, paginaActual,
      actions: { cerrarModalClienteRuta },
      combos: {comboCliente, comboCifCliente, comboLocalidadCliente},
    } = this.props

    let comboEstado = this.props.combos.comboEstado
    comboEstado = comboEstado.map((estado) => {
      return {...estado, label: t(estado.label)}
    })

    listClientes.forEach(
      (row) => row.idElemento = row.idDireccionCliente + 'direccion'
    )

    const tableClientes = {
      id: 'clienteTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      noBlockOptions: true,
      tablaModal: true,
      idBlockOptions: 1,
      mostrarModificar: false,
      columns: [
        {id: 'razonSocialCliente', name: t('RUTA.MODAL.TABLA.EMPRESA')},
        {id: 'cifCliente', name: t('RUTA.MODAL.TABLA.CIF')},
        {id: 'direccion', name: t('RUTA.MODAL.TABLA.DIRECCION')},
        {id: 'nombreTipoDireccion', name: t('RUTA.MODAL.TABLA.TIPO_DIRECCION')},
        {id: 'nombreDireccion', name: t('RUTA.MODAL.TABLA.GRANJA')},
        {id: 'localidad', name: t('RUTA.MODAL.TABLA.LOCALIDAD')},
        {id: 'codigoREGACliente', name: t('RUTA.MODAL.TABLA.CODIGO_REGA')}
      ],
      rows: listClientes,
      initialValues: {}
    }

    tableClientes.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableClientes.initialValues['check' + row.idElemento] = checkedClienteRuta
        }
      }
    )

    return (
      <Modal show={showModalClienteRuta} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-ruta" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('RUTA.MODAL.CLIENTES_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-filtros-modal-ruta">
                <form className="custom-form">
                  <Field
                    colSm={3}
                    id="cliente"
                    name="cliente"
                    controlLabel={t('RUTA.MODAL.EMPRESA')}
                    component={InputSelect}
                    options={comboCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-cliente'
                    onInputChange={handleSubmit(this.fetchFiltrarClienteRuta.bind(this))}
                  />
                  <Field
                    colSm={3}
                    id="cif"
                    name="cif"
                    controlLabel={t('RUTA.MODAL.CIF')}
                    component={InputSelect}
                    options={comboCifCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-cif'
                    onInputChange={handleSubmit(this.fetchFiltrarClienteRuta.bind(this))}
                  />
                  <Field
                    colSm={3}
                    id="localidad"
                    name="localidad"
                    controlLabel={t('RUTA.MODAL.LOCALIDAD')}
                    component={InputSelect}
                    options={comboLocalidadCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-localidad'
                    onInputChange={handleSubmit(this.fetchFiltrarClienteRuta.bind(this))}
                  />
                  <Field
                    colSm={3}
                    id="activo"
                    name="activo"
                    controlLabel={t('RUTA.MODAL.ESTADO')}
                    component={InputSelect}
                    options={comboEstado}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-activo'
                    onInputChange={handleSubmit(this.fetchFiltrarClienteRuta.bind(this))}
                  />
                  <div className="clearfix"></div>
                  <div className="aplicar-tabla-filtros">
                    <div className="filtros-anadidos">
                      {
                        Object.keys(filtrosCliente).length > 0 && (
                          filtrosCliente.cliente &&filtrosCliente.cliente !== null ? (
                            <span className="badge-dark">{t('RUTA.MODAL.EMPRESA') + ': ' + filtrosCliente.cliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cliente')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosCliente).length > 0 && (
                          (filtrosCliente.cif && filtrosCliente.cif !== null && filtrosCliente.cif !== '') ? (
                            <span className="badge-dark">{t('RUTA.MODAL.CIF') + ': ' + filtrosCliente.cif.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cif')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosCliente).length > 0 && (
                          (filtrosCliente.localidad && filtrosCliente.localidad !== null && filtrosCliente.localidad !== '') ? (
                            <span className="badge-dark">{t('RUTA.MODAL.LOCALIDAD') + ': ' + filtrosCliente.localidad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('localidad')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosCliente).length > 0 && (
                          (filtrosCliente.activo && filtrosCliente.activo !== null && filtrosCliente.activo !== '') ? (
                            <span className="badge-dark">{t('RUTA.MODAL.ESTADO') + ': ' + filtrosCliente.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                          ) : null
                        )
                      }
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </Col>
            <div className="tabla-cliente-en-ruta">
              <div className="filtros-tabla-modal-ruta">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-cliente"
                    name="seleccionar-todos-modal-cliente"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalClienteRuta(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableClientes}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirRuta.bind(this))}>{t('RUTA.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('RUTA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalClienteRuta',
  enableReinitialize: true
})(ModalClienteRuta))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {guardarModalEditarTrazabilidadMaterial, closeModalEditarTrazabilidadMaterial} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import ModalEditarTrazabilidadMaterial from '../../components/trazabilidadMaterial/ModalEditarTrazabilidadMaterial'

export function mapStateToProps (state) {
  return {
    showModalEditarTrazabilidad: state.trazabilidadMaterial.showModalEditarTrazabilidad,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formModalEditarTrazabilidadMaterial: getFormValues('ModalEditarTrazabilidadMaterial')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      guardarModalEditarTrazabilidadMaterial,
      closeModalEditarTrazabilidadMaterial
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEditarTrazabilidadMaterial))
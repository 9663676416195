import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosGrupoUsuarios from '../../components/grupoUsuarios/FiltrosGrupoUsuarios'
import {
  fetchGrupoUsuarios, abrirModalGrupoUsuarios, seleccionarTodosGrupoUsuarios, deSeleccionarTodosGrupoUsuarios,
  deleteSeleccionGrupoUsuarios, cambioEstadoSeleccionGrupoUsuarios, crearCsvGrupoUsuarios, imprimirPdfGrupoUsuarios
} from '../../actions/grupoUsuarios/grupoUsuarios'
import { comboGrupoUsuariosCentro } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.grupoUsuarios,
    auth: state.auth,
    grupoUsuarioTable: state.form.grupoUsuarioTable,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboGrupoUsuariosCentro: state.combos.comboGrupoUsuariosCentro,
      comboEstado: state.combos.comboEstado
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchGrupoUsuarios,
      abrirModalGrupoUsuarios,
      seleccionarTodosGrupoUsuarios,
      deSeleccionarTodosGrupoUsuarios,
      deleteSeleccionGrupoUsuarios,
      cambioEstadoSeleccionGrupoUsuarios,
      crearCsvGrupoUsuarios,
      imprimirPdfGrupoUsuarios,
      comboGrupoUsuariosCentro
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosGrupoUsuarios))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { cerrarNuevoMagavision, obtenerYGuardarResultadoSistemaAnalisis } from '../../actions/analisisExtracciones/analisisExtracciones'
import { obtenerYGuardarResultadoSistemaAnalisisControlMorfologico } from '../../actions/planSanitario/controlCalidad/controlMorfologico'
import { obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento } from '../../actions/seguimiento/seguimiento'
import ModalNuevoMagavision from '../../components/analisisExtracciones/ModalNuevoMagavision'

export function mapStateToProps (state) {
  return {
    extraccion: state.analisisExtracciones,
    pool: state.poolExtracciones,
    seguimiento: state.seguimiento,
    panelControl: state.panelControl,
    auth: state.auth,
    controlMorfologico: state.controlMorfologico
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarNuevoMagavision, 
      obtenerYGuardarResultadoSistemaAnalisis, 
      obtenerYGuardarResultadoSistemaAnalisisControlMorfologico,
      obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalNuevoMagavision)))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import { getFormValues } from 'redux-form';

// Constants
import actionTypes from '../../constants/actions/trazabilidadMaterial/materialDisponible'

// Actions
import {
  updateLotesEscandallo
} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import {
  openModalMaterialDisponibleSuccess, guardarModalMaterialDisponibleSuccess, closeModalMaterialDisponibleSuccess, fetchMaterialDisponibleSuccess
} from '../../actions/trazabilidadMaterial/materialDisponible'

// Services
import getLotesServicio from '../../services/trazabilidadMaterial/getLotes'

export function * fetchMaterialDisponible ({filtros}) {
  try {
    let state = yield select(state => state)
    let {idCategoria, idTipoDosis, idAnalisis, lotesEscandallo} = state.trazabilidadMaterial
    let filtrosFinal = {...filtros, idCategoria, idTipoDosis}

    if (idAnalisis) {
      filtrosFinal.idAnalisis = idAnalisis
    }

    if (lotesEscandallo && lotesEscandallo.length > 0) {
      filtrosFinal.list = lotesEscandallo.map((lote) => {
        return {idEntradaAlmacenProducto: lote.idEntradaAlmacenProducto}
      })
      filtrosFinal.listProducto = lotesEscandallo.map((lote) => {
        return {idProducto: lote.idProducto}
      })
    }

    const {lotes} = yield call(getLotesServicio, filtrosFinal, state.auth.token)
    yield put(fetchMaterialDisponibleSuccess(lotes, filtrosFinal))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchMaterialDisponible () {
  yield takeLatest(actionTypes.FETCH_MATERIAL_DISPONIBLE, fetchMaterialDisponible)
}

export function * openModalMaterialDisponible () {
  try {
    yield put(openModalMaterialDisponibleSuccess())
    yield call(fetchMaterialDisponible, {filtros: {}})
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOpenModalMaterialDisponible () {
  yield takeLatest(actionTypes.OPEN_MODAL_MATERIAL_DISPONIBLE, openModalMaterialDisponible)
}

export function * guardarModalMaterialDisponible () {
  try {
    const state = yield select(state => state)
    let lastIdTrazabilidadFront = null
    const listadoMaterialDisponibleTable = yield getFormValues('listadoMaterialDisponibleTable')(state)
    let newLotesEscandallo = state.materialDisponible.lotes.filter((lote) => {
      return listadoMaterialDisponibleTable['checklistadoMaterialDisponibleTable' + lote.idEntradaAlmacenProducto]
    }).map((lote, index) => {

      let newIdTrazabilidadFront = state.trazabilidadMaterial.lotesEscandallo.reduce((accumulator, currentValue) => {
        return parseInt(currentValue.idTrazabilidadFront, 10) > parseInt(accumulator, 10) ? parseInt(currentValue.idTrazabilidadFront, 10) : accumulator
      }, 0)
      if(newIdTrazabilidadFront === lastIdTrazabilidadFront){
        newIdTrazabilidadFront = newIdTrazabilidadFront + 1
      }
      lastIdTrazabilidadFront = newIdTrazabilidadFront
      return {...lote, idTrazabilidadFront: newIdTrazabilidadFront + 1, stockFinal: lote.stock}
    })
    yield put(guardarModalMaterialDisponibleSuccess())
    yield put(updateLotesEscandallo(state.trazabilidadMaterial.lotesEscandallo.concat(newLotesEscandallo)))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchGuardarModalMaterialDisponible () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_MATERIAL_DISPONIBLE, guardarModalMaterialDisponible)
}

export function * closeModalMaterialDisponible () {
  try {
    yield put(closeModalMaterialDisponibleSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCloseModalMaterialDisponible () {
  yield takeLatest(actionTypes.CLOSE_MODAL_MATERIAL_DISPONIBLE, closeModalMaterialDisponible)
}
import actionTypes from '../../constants/actions/producto/producto'

export function abrirModalProducto () {
  return {
    type: actionTypes.ABRIR_MODAL_PRODUCTO
  }
}

export function closeModalProducto () {
  return {
    type: actionTypes.CLOSE_MODAL_PRODUCTO
  }
}

export function submitNuevoProducto (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_PRODUCTO,
    values
  }
}

export function submitNuevoProductoSuccess (listProducto) {
  return {
    type: actionTypes.SUBMIT_NUEVO_PRODUCTO_SUCCESS,
    listProducto
  }
}

export function obtenerProductosAlmacen () {
  return {
    type: actionTypes.OBTENER_PRODUCTOS_ALMACEN
  }
}

export function filtrarProducto (values) {
  return {
    type: actionTypes.FILTRAR_PRODUCTO,
    values
  }
}

export function filtrarProductoSuccess (listProducto) {
  return {
    type: actionTypes.FILTRAR_PRODUCTO_SUCCESS,
    listProducto
  }
}

export function filtrosProductoSuccess (filtros) {
  return {
    type: actionTypes.FILTROS_PRODUCTO_SUCCESS,
    filtros
  }
}

export function recuperarDatosModalProducto (idProducto) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_PRODUCTO,
    idProducto
  }
}

export function recuperarDatosModalProductoSuccess (dataProductos) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_PRODUCTO_SUCCESS,
    dataProductos
  }
}

export function editarProducto (values) {
  return {
    type: actionTypes.EDITAR_PRODUCTO,
    values
  }
}

export function duplicarDatosModalProducto (idProducto) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_PRODUCTO,
    idProducto
  }
}

export function duplicarDatosModalProductoSuccess (dataDuplicarProductos) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_PRODUCTO_SUCCESS,
    dataDuplicarProductos
  }
}

export function cambiarEstadoProducto (idProducto) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_PRODUCTO,
    idProducto
  }
}

export function onDeleteProducto (idProducto, referencia) {
  return {
    type: actionTypes.ON_DELETE_PRODUCTO,
    idProducto,
    referencia
  }
}

export function seleccionarTodosProducto () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_PRODUCTO
  }
}

export function deSeleccionarTodosProducto () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_PRODUCTO
  }
}

export function cambiarEstadoSeleccionProducto (list) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_PRODUCTO,
    list
  }
}

export function deleteSeleccionProducto (list) {
  return {
    type: actionTypes.DELETE_SELECCION_PRODUCTO,
    list
  }
}

export function crearCsvProducto(list){
  return {
    type: actionTypes.CREATE_CSV_PRODUCTO,
    list
  }
}

export function crearCsvProductoSuccess(datosExcelProducto){
  return {
    type: actionTypes.CREATE_CSV_PRODUCTO_SUCCESS,
    datosExcelProducto
  }
}

export function imprimirPdfProducto(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_PRODUCTO,
    list
  }
}

export function imprimirPdfProductoSuccess(datosPdfProducto){
  return {
    type: actionTypes.IMPRIMIR_PDF_PRODUCTO_SUCCESS,
    datosPdfProducto
  }
}

export default {
  closeModalProducto,
  abrirModalProducto,
  submitNuevoProducto,
  submitNuevoProductoSuccess,
  obtenerProductosAlmacen,
  filtrarProducto,
  filtrarProductoSuccess,
  filtrosProductoSuccess,
  recuperarDatosModalProducto,
  recuperarDatosModalProductoSuccess,
  editarProducto,
  duplicarDatosModalProducto,
  duplicarDatosModalProductoSuccess,
  cambiarEstadoProducto,
  onDeleteProducto,
  seleccionarTodosProducto,
  deSeleccionarTodosProducto,
  cambiarEstadoSeleccionProducto,
  deleteSeleccionProducto,
  crearCsvProducto,
  imprimirPdfProducto,
  crearCsvProducto,
  crearCsvProductoSuccess,
  imprimirPdfProducto,
  imprimirPdfProductoSuccess
}
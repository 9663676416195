import apiFetch from '../../apiFetch'

export default function (idAnalisis, idPool) {
  let queryParams = ''
  if (idAnalisis) {
    queryParams = `idAnalisis=${idAnalisis}`
  } else {
    queryParams = `idPool=${idPool}`
  }
  return apiFetch({endPoint: `ControlMorfologico?${queryParams}`, method: 'GET', body: null})
}

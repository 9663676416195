export default {
  FETCH_EDITAR_VERRACO: 'FETCH_EDITAR_VERRACO',
  FETCH_EDITAR_VERRACO_SUCCESS: 'FETCH_EDITAR_VERRACO_SUCCESS',
  SUBMIT_EDITAR_EVOLUCION_SUCCESS: 'SUBMIT_EDITAR_EVOLUCION_SUCCESS',
  SUBMIT_EDITAR_INDEX_SUCCESS: 'SUBMIT_EDITAR_INDEX_SUCCESS',
  SUBMIT_EDITAR_VERRACO: 'SUBMIT_EDITAR_VERRACO',
  FETCH_HISTORICO_ESTADOS_VERRACO: 'FETCH_HISTORICO_ESTADOS_VERRACO',
  FETCH_HISTORICO_ESTADOS_VERRACO_SUCCESS: 'FETCH_HISTORICO_ESTADOS_VERRACO_SUCCESS',
  FETCH_HISTORICO_GRUPO_VERRACO: 'FETCH_HISTORICO_GRUPO_VERRACO',
  FETCH_HISTORICO_GRUPO_VERRACO_SUCCESS: 'FETCH_HISTORICO_GRUPO_VERRACO_SUCCESS',
  FETCH_HISTORICO_EXTRACCIONES: 'FETCH_HISTORICO_EXTRACCIONES',
  FETCH_HISTORICO_EXTRACCIONES_SUCCESS: 'FETCH_HISTORICO_EXTRACCIONES_SUCCESS',
  FETCH_HISTORICO_INTERVENCIONES: 'FETCH_HISTORICO_INTERVENCIONES',
  FETCH_HISTORICO_INTERVENCIONES_SUCCESS: 'FETCH_HISTORICO_INTERVENCIONES_SUCCESS',
  ABRIR_MODAL_HISTORICO_GRUPOS: 'ABRIR_MODAL_HISTORICO_GRUPOS',
  ABRIR_MODAL_HISTORICO_GRUPOS_SUCCESS: 'ABRIR_MODAL_HISTORICO_GRUPOS_SUCCESS',
  CERRAR_MODAL_HISTORICO_GRUPOS: 'CERRAR_MODAL_HISTORICO_GRUPOS',
  ABRIR_MODAL_HISTORICO_EXTRACCIONES: 'ABRIR_MODAL_HISTORICO_EXTRACCIONES',
  ABRIR_MODAL_HISTORICO_EXTRACCIONES_SUCCESS: 'ABRIR_MODAL_HISTORICO_EXTRACCIONES_SUCCESS',
  CERRAR_MODAL_HISTORICO_EXTRACCIONES: 'CERRAR_MODAL_HISTORICO_EXTRACCIONES',
  ABRIR_MODAL_HISTORICO_ESTADO: 'ABRIR_MODAL_HISTORICO_ESTADO',
  ABRIR_MODAL_HISTORICO_ESTADO_SUCCESS: 'ABRIR_MODAL_HISTORICO_ESTADO_SUCCESS',
  CERRAR_MODAL_HISTORICO_ESTADO: 'CERRAR_MODAL_HISTORICO_ESTADO',
  ABRIR_MODAL_HISTORICO_INTERVENCIONES: 'ABRIR_MODAL_HISTORICO_INTERVENCIONES',
  ABRIR_MODAL_HISTORICO_INTERVENCIONES_SUCCESS: 'ABRIR_MODAL_HISTORICO_INTERVENCIONES_SUCCESS',
  CERRAR_MODAL_HISTORICO_INTERVENCIONES: 'CERRAR_MODAL_HISTORICO_INTERVENCIONES'
}
export default {
  FETCH_RAZAS: 'FETCH_RAZAS',
  FETCH_RAZAS_SUCCESS: 'FETCH_RAZAS_SUCCESS',
  SUBMIT_NUEVA_RAZA: 'SUBMIT_NUEVA_RAZA',
  SUBMIT_NUEVA_RAZA_SUCCESS: 'SUBMIT_NUEVA_RAZA_SUCCESS',
  RECUPERAR_DATOS_MODAL_RAZA: 'RECUPERAR_DATOS_MODAL_RAZA',
  RECUPERAR_DATOS_MODAL_RAZA_SUCCESS: 'RECUPERAR_DATOS_MODAL_RAZA_SUCCESS',
  ABRIR_MODAL_VACIO_RAZA: 'ABRIR_MODAL_VACIO_RAZA',
  EDITAR_RAZA: 'EDITAR_RAZA',
  EDIT_RAZA_SUCCESS:'EDIT_RAZA_SUCCESS',
  DUPLICAR_DATOS_MODAL_RAZA: 'DUPLICAR_DATOS_MODAL_RAZA',
  DUPLICAR_DATOS_MODAL_RAZA_SUCCESS: 'DUPLICAR_DATOS_MODAL_RAZA_SUCCESS',
  CAMBIAR_ESTADO_RAZA: 'CAMBIAR_ESTADO_RAZA',
  CAMBIAR_ESTADO_RAZA_SUCCESS: 'CAMBIAR_ESTADO_RAZA_SUCCESS',
  ON_DELETE_RAZA: 'ON_DELETE_RAZA',
  ON_DELETE_RAZA_SUCCESS: 'ON_DELETE_RAZA_SUCCESS',
  FETCH_FILTRAR_RAZA: 'FETCH_FILTRAR_RAZA',
  FETCH_FILTRAR_RAZA_SUCCESS: 'FETCH_FILTRAR_RAZA_SUCCESS',
  SELECCIONAR_TODOS_RAZA: 'SELECCIONAR_TODOS_RAZA',
  DESELECCIONAR_TODOS_RAZA: 'DESELECCIONAR_TODOS_RAZA',
  CAMBIAR_ESTADO_SELECCION_RAZA: 'CAMBIAR_ESTADO_SELECCION_RAZA',
  DELETE_SELECCION_RAZA: 'DELETE_SELECCION_RAZA',
  CREATE_CSV_RAZA: 'CREATE_CSV_RAZA',
  CREATE_CSV_RAZA_SUCCESS: 'CREATE_CSV_RAZA_SUCCESS',
  GUARDAR_Y_NUEVO_RAZA: 'GUARDAR_Y_NUEVO_RAZA',
  IMPRIMIR_PDF_RAZA: 'IMPRIMIR_PDF_RAZA',
  IMPRIMIR_PDF_RAZA_SUCCESS: 'IMPRIMIR_PDF_RAZA_SUCCESS',
  OPEN_MODAL_RAZA: 'OPEN_MODAL_RAZA',
  CLOSE_MODAL_RAZA: 'CLOSE_MODAL_RAZA'
}

import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
// import ModalTransportePage from '../../containers/transporte/ModalTransportePage'
import FiltrosTransportePage from '../../containers/transporte/FiltrosTransportePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class Transporte extends Component {

  componentDidMount () {
    this.props.actions.reiniciarConductoresVehiculosRutas()
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTE') + ' - ' + this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTISTAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    if (this.props.location.state === undefined) {
      this.props.actions.filtrarTransporte({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
    } else {
      this.props.actions.filtrarTransporte(this.props.transporte.filtros)
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      transporte: { list, checkedTransporte, data, dataDuplicar },
      actions: { cambiarEstadoTransporte, onDeleteTransporte }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idTransportista
    )

    const table = {
      id: 'transporteTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreTransportista', name: t('TRANSPORTE.TABLA.EMPRESA')},
        {id: 'cifTransportista', name: t('TRANSPORTE.TABLA.CIF')},
        {id: 'conductorTransporte', name: t('TRANSPORTE.TABLA.CONDUCTOR')},
        {id: 'vehiculoTransporte', name: t('TRANSPORTE.TABLA.VEHICULO')},
        {id: 'nombreRuta', name: t('TRANSPORTE.TABLA.RUTA')}
      ],
      rows: list,
      filtros: <FiltrosTransportePage />,
      onEdit: (row) => window.location.href='/#/EditarTransportista/' + row.idTransportista,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      duplicar: (row) => window.location.href='/#/DuplicarTransportista/' + row.idTransportista,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      cambiarEstado: (row) => cambiarEstadoTransporte(row.idTransportista),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      onDelete: (row) => onDeleteTransporte(row.idTransportista),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedTransporte
        }
      }
    )
  return (
      <div>
      <SimpleModalPage/>
      <MensajeAlertaPage/>
        <ListadoPage t_key="TRANSPORTE." table={table}>
        </ListadoPage>
      </div>
    )
  }
}
export default Transporte
import actionTypes from '../../../constants/actions/planSanitario/microbiologia/microbiologia'

export function initialState () {
  return {
    list: [],
    filtros: [],
    data: [],
    dataDuplicar : [],
    showModalMicrobiologia: false,
    checkedFiltrosMicrobiologia: false,
    datosExcelMicrobiologia: [],
  }
}

export function fetchMicrobiologiaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalMicrobiologiaSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function onDeleteMicrobiologiaSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function submitNuevaMicrobiologiaSuccess (state, {insercionMicrobiologia}) {
  return {
    ...state,
    insercionMicrobiologia
  }
}

export function editarMicrobiologiaSuccess (state, {edicionMicrobiologia}) {
  return {
    ...state,
    edicionMicrobiologia
  }
}

export function fetchFiltrarMicrobiologiaSuccess (state, {filtros}){
  return {
    ...state,
    filtros
  }
}

export function cerrarModalMicrobiologia (state) {
  return {
    ...state,
    showModalMicrobiologia: false
  }
}

export function abrirModalMicrobiologia (state) {
  return {
    ...state,
    showModalMicrobiologia: true
  }
}

export function seleccionarTodosMicrobiologia (state) {
  return {
    ...state,
    checkedFiltrosMicrobiologia: true
  }
}

export function deSeleccionarTodosMicrobiologia (state) {
  return {
    ...state,
    checkedFiltrosMicrobiologia: false
  }
}

export function crearCsvMicrobiologiaSuccess (state, {datosExcelMicrobiologia}) {
  return {
    ...state,
    datosExcelMicrobiologia
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_MICROBIOLOGIA_SUCCESS:
      return fetchMicrobiologiaSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_MICROBIOLOGIA_SUCCESS:
      return recuperarDatosModalMicrobiologiaSuccess(state, action)
    case actionTypes.ON_DELETE_MICROBIOLOGIA_SUCCESS:
      return onDeleteMicrobiologiaSuccess(state, action)   
    case actionTypes.SUBMIT_NUEVA_MICROBIOLOGIA_SUCCESS:
      return submitNuevaMicrobiologiaSuccess(state, action)
    case actionTypes.EDITAR_MICROBIOLOGIA_SUCCESS:
      return editarMicrobiologiaSuccess(state, action)  
    case actionTypes.FETCH_FILTRAR_MICROBIOLOGIA_SUCCESS:
      return fetchFiltrarMicrobiologiaSuccess(state, action) 
    case actionTypes.CERRAR_MODAL_MICROBIOLOGIA:
      return cerrarModalMicrobiologia(state, action)
    case actionTypes.ABRIR_MODAL_MICROBIOLOGIA:
      return abrirModalMicrobiologia(state, action)
    case actionTypes.SELECCIONAR_TODOS_MICROBIOLOGIA:
      return seleccionarTodosMicrobiologia(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MICROBIOLOGIA:
      return deSeleccionarTodosMicrobiologia(state, action)
    case actionTypes.CREATE_CSV_MICROBIOLOGIA_SUCCESS:
      return crearCsvMicrobiologiaSuccess(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalAnadirColorimetro from '../../components/panelControl/ModalAnadirColorimetro'
import { cerrarModalAnadirColorimetro, submitAnadirColorimetro, editarColorimetro, abrirModalAnadirMuestras, recuperarDatosMuestras, duplicarDatosModalMuestras, onDeleteMuestras,
  reiniciarMuestraVerraco, abrirModalAnadirVerracos, cerrarModalAnadirVerracos, seleccionarTodosVerracosAnadidos, deSeleccionarTodosVerracosAnadidos, seleccionarTodosMuestas,
  deSeleccionarTodosMuestas, editMuestrasAnadidasSuccess, editarVerracos, anadirMuestra, anadirVerracos, onDeleteVerracos } from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalAnadirColorimetro,
      submitAnadirColorimetro,
      editarColorimetro,
      abrirModalAnadirMuestras,
      recuperarDatosMuestras,
      duplicarDatosModalMuestras,
      onDeleteMuestras,
      reiniciarMuestraVerraco,
      abrirModalAnadirVerracos,
      cerrarModalAnadirVerracos,
      seleccionarTodosVerracosAnadidos,
      deSeleccionarTodosVerracosAnadidos,
      seleccionarTodosMuestas,
      deSeleccionarTodosMuestas,
      editMuestrasAnadidasSuccess,
      editarVerracos,
      anadirMuestra,
      anadirVerracos,
      onDeleteVerracos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAnadirColorimetro))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputCheckBox from '../comun/form/InputCheckBox'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'
import { comboNivelGeneticoSuccess } from '../../actions/combos/combos'
import moment from 'moment'

class FiltrosInformesExtracciones extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
      comboTipoDosis: []
    }
    this.handleChangePropiedad = this.handleChangePropiedad.bind(this)
  }

  UNSAFE_componentWillMount () {    
    const fechaAux = moment().subtract(6, 'M').format('yyyy-MM-DD')     
    this.props.actions.obtenerInformeExtracciones({media:{value: false, label: this.props.t('COMUN.COMBOS.VALORES_MEDIA.NO')}, extraccionesSinDosis: true, fechaEntrada: fechaAux, desde: fechaAux})
    this.props.actions.comboVerraco()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboOperario()
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboClienteConVerracosEnPropiedad()
    this.props.actions.comboNivelGenetico()
    this.props.actions.comboObservacionesEyaculadoEliminado()
    this.props.change('media', {value: false, label: this.props.t('COMUN.COMBOS.VALORES_MEDIA.NO')})
    this.props.change('extraccionesSinDosis', true)
    this.props.change('fechaEntrada', fechaAux)
    this.props.change('desde', fechaAux)    
  }

  obtenerInformeExtracciones(values){
    if(values.propiedad){
      if (!values.propiedad || values.propiedad.value === true) {
        values.idCliente = null
        this.handleChangePropiedad(values.propiedad)
      } else if (values.propiedad && values.propiedad.value === false) {
        this.handleChangePropiedad(values.propiedad)
      }
    }
    
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.obtenerInformeExtracciones(values)
  }

  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesExtracciones !== prevProps.datosPdfInformesExtracciones) {
      if (this.props.datosPdfInformesExtracciones.pdf && this.props.datosPdfInformesExtracciones.pdf !== null){
        let pdf = this.props.datosPdfInformesExtracciones.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.EXTRACCIONES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }

    if (this.props.combos.comboTipoDosis !== prevProps.combos.comboTipoDosis) {
      const comboDosis = this.props.combos.comboTipoDosis.map((dosis) => dosis)
      const comboDosisFiltros = []
      comboDosis.forEach((dosis) => {
        comboDosisFiltros.push({
          value: dosis.value,
          label: this.props.t(dosis.label)
        })
      })
      this.setState({comboTipoDosis: comboDosisFiltros})
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesExtracciones(){
    const filtros = this.props.filtrosExtracciones
    if (filtros.media && filtros.media.value === false) {
      const grafica1 = document.getElementsByTagName('canvas')[0].toDataURL("image/png")
      const grafica2 = document.getElementsByTagName('canvas')[1].toDataURL("image/png")
      filtros.grafica1 = grafica1
      filtros.grafica2 = grafica2
    } else {
      filtros.grafica1 = null
      filtros.grafica2 = null
    }
    this.props.actions.imprimirPdfInformesExtracciones(filtros)
  }

  crearCsvInformeExtracciones () {
    const filtros = this.props.filtrosExtracciones
    this.props.actions.crearCsvInformeExtracciones(filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosExtracciones
    if (value === 'extraccionesSinDosis') {
      values.extraccionesSinDosis = false
      this.props.actions.obtenerInformeExtracciones(values)
      this.props.change(value, false)
      this.setState({fitrosSeleccionados: values})
    } else {
      values[value] = null
      this.props.actions.obtenerInformeExtracciones(values)
      this.props.change(value, null)
      this.setState({fitrosSeleccionados: values})
    }
  }

  render () {
    const {
      t, handleSubmit, filtrosExtracciones,
      combos: {
        comboVerraco, comboNombreGrupoVerraco, comboTipoDosis, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboClienteConVerracosEnPropiedad, comboConservacion, comboNivelGenetico, comboObservacionesEyaculadoEliminado
      }
    } = this.props

    let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

    /*const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })*/

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    const combosegimiento= [
      {value: true, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.SI')},
      {value: false, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.NO')}
    ]

    const combosContaminacion= [
      {value: true, label: t('COMUN.COMBOS.CONTAMINACION.SI')},
      {value: false, label: t('COMUN.COMBOS.CONTAMINACION.NO')}
    ]

    const comboEyaculadoEliminado= [
      {value: 1, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.NO')},
      {value: 2, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.TODOS')},
      {value: 3, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.ANALISIS')},
      {value: 4, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.DILUCION')},
      {value: 5, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.ENVASADO')}
    ]

    let comboTipoAnalisisExtraccion = this.props.combos.comboTipoAnalisisExtraccion
    comboTipoAnalisisExtraccion = comboTipoAnalisisExtraccion.map((extraccion) => {
      return {...extraccion, label: t('COMUN.COMBOS.' + extraccion.label)}
    })

    /*let comboConservacion = this.props.combos.comboConservacion
    comboConservacion = comboConservacion.map((conservacion) => {
      return {...conservacion, label: t(conservacion.label)}
    })*/

    let comboPropiedad = this.props.combos.comboPropiedad
    comboPropiedad = comboPropiedad.map((propiedad) => {
      return {...propiedad, label: t(propiedad.label)}
    })

    // let comboObservacionesEyaculadoEliminado = this.props.combos.comboObservacionesEyaculadoEliminado
    // comboObservacionesEyaculadoEliminado = comboObservacionesEyaculadoEliminado.map((observacionesEyaculadoEliminadoItem) => {
    //   return {...observacionesEyaculadoEliminadoItem, label: t(`COMUN.COMBOS.${observacionesEyaculadoEliminadoItem.label}`)}
    // })

    const combosValoresMedia= [
      {value: true, label: t('COMUN.COMBOS.VALORES_MEDIA.SI')},
      {value: false, label: t('COMUN.COMBOS.VALORES_MEDIA.NO')}
    ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="operarioColecta"
              name="operarioColecta"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.OPERARIO_COLECTA')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="intervencionSanitaria"
                name="intervencionSanitaria"
                controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA')}
                component={InputSelect}
                options={comboTipoIntervencionSanitaria}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={this.state.comboTipoDosis}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="tipoAnalisis"
              name="tipoAnalisis"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.TIPO_ANALISIS')}
              component={InputSelect}
              options={comboTipoAnalisisExtraccion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="eyaculadoEliminado"
              name="eyaculadoEliminado"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.EYACULADO_ELIMINADO')}
              component={InputSelect}
              options={comboEyaculadoEliminado}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="observacionesEyaculadoEliminado"
              name="observacionesEyaculadoEliminado"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CAUSA_ELIMINACION')}
              component={InputSelect}
              options={comboObservacionesEyaculadoEliminado}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="ebv"
              name="ebv"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.EBV')}
              component={InputNumerical}
              numDecimales={0}
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="nivelGenetico"
              name="nivelGenetico"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.NIVEL_GENETICO')}
              component={InputSelect}
              options={comboNivelGenetico}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="seguimiento"
              name="seguimiento"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.SEGUIMIENTO')}
              component={InputSelect}
              options={combosegimiento}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="contaminacion"
              name="contaminacion"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CONTAMINACION')}
              component={InputSelect}
              options={combosContaminacion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="conservacion"
              name="conservacion"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CONSERVACION')}
              component={InputSelect}
              options={comboConservacion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="propiedad"
              name="propiedad"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.PROPIEDAD')}
              component={InputSelect}
              options={comboPropiedad}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="cliente"
              name="cliente"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CLIENTE')}
              component={InputSelect}
              options={comboClienteConVerracosEnPropiedad}
              valueKey="value"
              labelKey="label"
              customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="media"
              name="media"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.VALORES_MEDIA')}
              component={InputSelect}
              options={combosValoresMedia}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
              isClearable={false}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              colSm={2}
              id="estadoActualVerraco"
              name="estadoActualVerraco"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.ESTADO_VERRACO')}
              component={InputSelect}
              options={comboEstadoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-destino'
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <Field
              id="extraccionesSinDosis"
              name="extraccionesSinDosis"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.INCLUIR_EXTRACIONES_CON_DOSIS_REALES_0')}
              onInputChange={handleSubmit(this.obtenerInformeExtracciones.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.verraco && filtrosExtracciones.verraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.VERRACO') + ': ' + filtrosExtracciones.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.operarioColecta && filtrosExtracciones.operarioColecta !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.OPERARIO_COLECTA') + ': ' + filtrosExtracciones.operarioColecta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operarioColecta')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.raza && filtrosExtracciones.raza !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.RAZA') + ': ' + filtrosExtracciones.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.lineaGenetica && filtrosExtracciones.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.LINEA_GENETICA') + ': ' + filtrosExtracciones.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.grupoVerraco && filtrosExtracciones.grupoVerraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.GRUPO_VERRACO') + ': ' + filtrosExtracciones.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.intervencionSanitaria && filtrosExtracciones.intervencionSanitaria !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA') + ': ' + filtrosExtracciones.intervencionSanitaria.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('intervencionSanitaria')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.tipoDosis && filtrosExtracciones.tipoDosis !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.TIPO_DOSIS') + ': ' + filtrosExtracciones.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.tipoAnalisis && filtrosExtracciones.tipoAnalisis !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.TIPO_ANALISIS') + ': ' + filtrosExtracciones.tipoAnalisis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoAnalisis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.eyaculadoEliminado && filtrosExtracciones.eyaculadoEliminado !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.EYACULADO_ELIMINADO') + ': ' + filtrosExtracciones.eyaculadoEliminado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('eyaculadoEliminado')}> x </button></span>
                    ) : null
                  )
                }

                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.observacionesEyaculadoEliminado && filtrosExtracciones.observacionesEyaculadoEliminado !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CAUSA_ELIMINACION') + ': ' + filtrosExtracciones.observacionesEyaculadoEliminado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('observacionesEyaculadoEliminado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.ebv && filtrosExtracciones.ebv !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.EBV') + ': ' + filtrosExtracciones.ebv}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.nivelGenetico && filtrosExtracciones.nivelGenetico !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.NIVEL_GENETICO') + ': ' + filtrosExtracciones.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.seguimiento && filtrosExtracciones.seguimiento !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.SEGUIMIENTO') + ': ' + filtrosExtracciones.seguimiento.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('seguimiento')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.contaminacion && filtrosExtracciones.contaminacion !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CONTAMINACION') + ': ' + filtrosExtracciones.contaminacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('contaminacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.conservacion && filtrosExtracciones.conservacion !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CONSERVACION') + ': ' + filtrosExtracciones.conservacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('conservacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.propiedad && filtrosExtracciones.propiedad !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.PROPIEDAD') + ': ' + filtrosExtracciones.propiedad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('propiedad')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.cliente && filtrosExtracciones.cliente !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CLIENTE') + ': ' + filtrosExtracciones.cliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cliente')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.media && filtrosExtracciones.media !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.VALORES_MEDIA') + ': ' + filtrosExtracciones.media.label}</span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    (filtrosExtracciones.fechaEntrada && filtrosExtracciones.fechaSalida && filtrosExtracciones.fechaEntrada !== null && filtrosExtracciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosExtracciones.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosExtracciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosExtracciones.fechaEntrada && filtrosExtracciones.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosExtracciones.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosExtracciones.fechaSalida && filtrosExtracciones.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosExtracciones.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.estadoActualVerraco && filtrosExtracciones.estadoActualVerraco !== null ? (
                      <span className="badge-dark">{t('MOVIMIENTO_VERRACOS.FILTROS.ACTUAL') + ': ' + filtrosExtracciones.estadoActualVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estadoActualVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosExtracciones).length > 0 && (
                    filtrosExtracciones.extraccionesSinDosis && filtrosExtracciones.extraccionesSinDosis !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.INCLUIR_EXTRACIONES_CON_DOSIS_REALES_0')}: {filtrosExtracciones.extraccionesSinDosis === true ? t('COMUN.COMBOS.EYACULADO_ELIMINADO.SI') : t('COMUN.COMBOS.EYACULADO_ELIMINADO.NO') }<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('extraccionesSinDosis')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeExtracciones()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesExtracciones()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesExtracciones',
})(FiltrosInformesExtracciones))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosCambioEstadoVerraco from '../../components/cambioEstadoVerraco/FiltrosCambioEstadoVerraco'
import { cambiarVigiladoSeleccionFichaVerraco, deleteSeleccionFichaVerraco } from '../../actions/fichaVerraco/fichaVerraco'
import {abrirModalUbicacion, cerrarModalUbicacion} from '../../actions/fichaVerraco/fichaVerraco'
import {abrirModalVacioCambioEstado, seleccionarTodosCambioEstadoVerraco, deSeleccionarTodosCambioEstadoVerraco, deleteSeleccionCambioEstadoVerraco, fetchFiltrarCambioEstadoVerraco, crearCsvCambioEstado,
      imprimirPdfCambioEstado } from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'
import {comboUbicacionPadre, comboRaza, comboLineaGenetica} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    fichaVerraco: state.fichaVerraco,
    formSimpleTable: state.form.simpleTable,
    cambioEstadoVerraco:state.cambioEstadoVerraco,
    combos:state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      seleccionarTodosCambioEstadoVerraco,
      deSeleccionarTodosCambioEstadoVerraco,
      cambiarVigiladoSeleccionFichaVerraco,
      deleteSeleccionFichaVerraco,
      abrirModalUbicacion,
      cerrarModalUbicacion,
      abrirModalVacioCambioEstado,
      deleteSeleccionCambioEstadoVerraco,
      comboUbicacionPadre,
      comboRaza,
      comboLineaGenetica,
      fetchFiltrarCambioEstadoVerraco,
      crearCsvCambioEstado,
      imprimirPdfCambioEstado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosCambioEstadoVerraco))
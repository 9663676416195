import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Instalacion from '../../components/instalacion/Instalacion'
import {fetchInstalacion, recuperarDatosModalInstalacion, duplicarDatosModalInstalacion, cambiarEstadoInstalacion, onDeleteInstalacion, fetchFiltrarInstalacion
  } from '../../actions/instalacion/instalacion'

export function mapStateToProps (state) {
  return {
    instalacion: {...state.instalacion},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchInstalacion,
      recuperarDatosModalInstalacion,
      duplicarDatosModalInstalacion,
      cambiarEstadoInstalacion,
      onDeleteInstalacion,
      fetchFiltrarInstalacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Instalacion))
import React, { Component } from 'react'
import SimplePage from '../../containers/page/SimplePage'
import './Dashboard.scss'
import { Row, Col, Popover, ProgressBar} from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'

class DashboardProduccion extends Component {
  constructor () {
    super()
    this.state = {     
    }
  }

  componentDidMount () {    
    this.props.actions.fetchDashboardProduccion()
  }

  render () {
    const {t, eyaculadosProduccion,dilucionProduccion,dosisDiluidasProduccion,envasadosProduccion,totalesProduccion,tiempoProduccion,diluidasProduccion} = this.props  
    
    const tKey = 'DASHBOARD.'

    return (
      <div className="page-dashboardProduccion">
        <SimplePage noTitle={true}>
          <Col sm={4}>
            <h1>{t(tKey + 'RESUMEN_PRODUCCION')}</h1>
            <Col sm={12} className="prod_col">
              <Col sm={5} className="prod_col_left">
                {t(tKey + 'EYACULADOS_PRODUCCION')}:
              </Col>
              <Col sm={7} className="prod_col_right">
                {eyaculadosProduccion}
              </Col>
            </Col>
            <Col sm={12} className="prod_col">
              <Col sm={5} className="prod_col_left">
                {t(tKey + 'DILUCION_PRODUCCION')}:
              </Col>
              <Col sm={7} className="prod_col_right">
                {dilucionProduccion}
              </Col>
            </Col>
            <Col sm={12} className="prod_col">
            {this.props.auth.customizations.includes("Netpork") ? 
              <Col sm={5} className="prod_col_left">
                {t(tKey + 'DOSIS_ASIGNADAS_PRODUCCION')}:
              </Col>
            : <Col sm={5} className="prod_col_left">
                {t(tKey + 'DOSIS_PRODUCCION')}:
              </Col>
            }
            {this.props.auth.customizations.includes("Netpork") ? 
              <Col sm={7} className="prod_col_right">
                {dosisDiluidasProduccion}
              </Col>
            : <Col sm={7} className="prod_col_right">
                {totalesProduccion ? totalesProduccion.envasadas : 0}
              </Col>
            }
            </Col>
            <Col sm={12} className="prod_col">
              <Col sm={5} className="prod_col_left">
                {t(tKey + 'TIEMPO_PRODUCCION')}:
              </Col>
              <Col sm={7} className="prod_col_right tiempo">
                {tiempoProduccion ? tiempoProduccion : '00:00:00'}
              </Col>
            </Col>
          </Col>          
          <Col sm={8} style={{paddingLeft: 80}}>
            <Col sm={12}><h1>{t(tKey + 'RESUMENDOSIS_PRODUCCION')}</h1></Col>
            {this.props.auth.customizations.includes("Netpork") ? 
              <Col sm={1} className="progress_porcent" style={totalesProduccion && totalesProduccion.porcentaje_diluidas == 100 ? {fontSize: 28} : {fontSize: 34}}>
                {totalesProduccion ? totalesProduccion.porcentaje_diluidas : 0}%
              </Col>
            : <Col sm={1} className="progress_porcent" style={totalesProduccion && totalesProduccion.porcentaje == 100 ? {fontSize: 28} : {fontSize: 34}}>
                {totalesProduccion ? totalesProduccion.porcentaje : 0}%
              </Col>
            }
            <Col sm={11}>
              <ProgressBar now={totalesProduccion ? totalesProduccion.porcentaje : 0} />
            </Col>   
            <Col sm={12}>         
              <table className="simple-table table table-striped table-condensed table-hover m-b-0">
                <thead>
                <tr>
                  <th style={{fontSize: 20, padding: 5}}>{t('DASHBOARD.RAZA_PRODUCCION')}</th>
                  <th style={{fontSize: 20, padding: 5}}>{t('DASHBOARD.TIPODOSIS_PRODUCCION')}</th>
                  <th style={{fontSize: 20, padding: 5}}>{t('DASHBOARD.PENDIENTES_PRODUCCION')}</th>
                  {this.props.auth.customizations.includes("Netpork") ? 
                    <th style={{fontSize: 20, padding: 5}}>{t('DASHBOARD.ASIGNADAS_PRODUCCION')}</th>                  
                  : <th style={{fontSize: 20, padding: 5}}>{t('DASHBOARD.ENVASADAS_PRODUCCION')}</th>                  
                  }
                </tr>
                </thead>
                {this.props.auth.customizations.includes("Netpork") ? 
                  <tbody>
                  {
                    diluidasProduccion && diluidasProduccion.length > 0 ? diluidasProduccion.map((diluidas) => {                    
                        return (
                          <tr>                        
                            <td style={{fontSize: 20, padding: 5}}>{diluidas.nombreRaza}</td>
                            <td style={{fontSize: 20, padding: 5}}>{diluidas.volumenTipoDosis}</td>
                            <td style={diluidas.pendientes_diluir > 0 ? {color: 'red', fontSize: 20, fontWeight: 'bold', padding: 5} : {color: 'black', fontSize: 20, padding: 5}}>{diluidas.pendientes_diluir}</td>
                            <td style={diluidas.diluidas > 0 ? {color: '#1E871B', fontSize: 20, fontWeight: 'bold', padding: 5} : {color: 'black', fontSize: 20, padding: 5}}>{diluidas.diluidas}</td>
                          </tr>
                        )
                      }) :
                      (
                        <tr>
                          <td colSpan="7">{t(`COMUN.SIN_RESULTADOS`)}</td>
                        </tr>
                      )
                  }  
                  <tr>
                    <td colSpan="2" className="col_total totales">{t(tKey + 'TOTALES_PRODUCCION')}:</td>
                    <td className="col_total pendientes" style={totalesProduccion && totalesProduccion.pendientes_diluir > 0 ? {color: 'red'} : {color: 'black'}}>{totalesProduccion ? totalesProduccion.pendientes_diluir : 0}</td>
                    <td className="col_total enviadas" style={totalesProduccion && totalesProduccion.diluidas > 0 ? {color: '#1E871B'} : {color: 'black'}}>{totalesProduccion ? totalesProduccion.diluidas : 0}</td>
                  </tr>                             
                  </tbody>
                :
                  <tbody>
                  {
                    envasadosProduccion && envasadosProduccion.length > 0 ? envasadosProduccion.map((envasado) => {                    
                        return (
                          <tr>                        
                            <td style={{fontSize: 20, padding: 5}}>{envasado.nombreRaza}</td>
                            <td style={{fontSize: 20, padding: 5}}>{envasado.volumenTipoDosis}</td>
                            <td style={envasado.pendientes > 0 ? {color: 'red', fontSize: 20, fontWeight: 'bold', padding: 5} : {color: 'black', fontSize: 20, padding: 5}}>{envasado.pendientes}</td>
                            <td style={envasado.envasadas > 0 ? {color: '#1E871B', fontSize: 20, fontWeight: 'bold', padding: 5} : {color: 'black', fontSize: 20, padding: 5}}>{envasado.envasadas}</td>
                          </tr>
                        )
                      }) :
                      (
                        <tr>
                          <td colSpan="7">{t(`COMUN.SIN_RESULTADOS`)}</td>
                        </tr>
                      )
                  }  
                  <tr>
                    <td colSpan="2" className="col_total totales">{t(tKey + 'TOTALES_PRODUCCION')}:</td>
                    <td className="col_total pendientes" style={totalesProduccion && totalesProduccion.pendientes > 0 ? {color: 'red'} : {color: 'black'}}>{totalesProduccion ? totalesProduccion.pendientes : 0}</td>
                    <td className="col_total enviadas" style={totalesProduccion && totalesProduccion.envasadas > 0 ? {color: '#1E871B'} : {color: 'black'}}>{totalesProduccion ? totalesProduccion.envasadas : 0}</td>
                  </tr>                             
                  </tbody>
                }
              </table>
            </Col>
          </Col>


          {/* <Row>
            <Col sm={6} className="prod_col">
              {t('DASHBOARD.EYACULADOS')}: {eyaculadosProduccion}
            </Col>
            <Col sm={6} className="prod_col">
              {t('DASHBOARD.DILUCION')}: {dilucionProduccion}
            </Col>
          </Row>                                         
          <Col sm={12}>
            <p className="titulo_itm" style={{marginLeft: 48}}>Dosis</p>
            <table className="simple-table table table-striped table-condensed table-hover m-b-0 width90">
              <thead>
              <tr>
                <th style={{fontSize: 20}}>{t('DASHBOARD.RAZA')}</th>
                <th style={{fontSize: 20}}>{t('DASHBOARD.TIPO_DOSIS')}</th>
                <th style={{fontSize: 20}}>{t('DASHBOARD.PENDIENTES')}</th>
                <th style={{fontSize: 20}}>{t('DASHBOARD.ENVASADAS')}</th>                  
              </tr>
              </thead>
              <tbody>
              {
                envasadosProduccion && envasadosProduccion.length > 0 ? envasadosProduccion.map((envasado) => {                    
                    return (
                      <tr>                        
                        <td style={{fontSize: 20}}>{envasado.nombreRaza}</td>
                        <td style={{fontSize: 20}}>{envasado.volumenTipoDosis}</td>
                        <td style={envasado.pendientes > 0 ? {color: 'red', fontSize: 20, fontWeight: 'bold'} : {color: 'black', fontSize: 20}}>{envasado.pendientes}</td>
                        <td style={{fontSize: 20}}>{envasado.envasadas}</td>
                      </tr>
                    )
                  }) :
                  (
                    <tr>
                      <td colSpan="7">{t(`COMUN.SIN_RESULTADOS`)}</td>
                    </tr>
                  )
              }  
              <tr>
                <td colSpan="2" style={{backgroundColor: 'white', fontSize: 80}}>TOTALES</td>
                <td style={{backgroundColor: 'white', fontSize: 80}}>{totalesProduccion ? totalesProduccion.pendientes : 0}</td>
                <td style={{backgroundColor: 'white', fontSize: 80}}>{totalesProduccion ? totalesProduccion.envasadas : 0}</td>
              </tr>                             
              </tbody>
            </table>            
          </Col>    */}
        </SimplePage>
      </div>
    )
  }
}

export default DashboardProduccion
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosInformesEstadoCentro extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeEstadoCentro({agrupacion: {value: 1, label: this.props.t('INFORMES.ESTADO_CENTRO.FILTROS.VERRACO')}})
    this.props.change('agrupacion', {value: 1, label: this.props.t('INFORMES.ESTADO_CENTRO.FILTROS.VERRACO')})
  }

  obtenerInformeEstadoCentro(values){
    this.props.actions.obtenerInformeEstadoCentro(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  crearCsvInformesEstadoCentro(){
    this.props.actions.crearCsvInformesEstadoCentro(this.props.filtrosEstadoCentro)
  }

  imprimirPdfInformesEstadoCentro(){
    this.props.actions.imprimirPdfInformesEstadoCentro(this.props.filtrosEstadoCentro)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosEstadoCentro
    values[value] = null
    this.props.actions.obtenerInformeEstadoCentro(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosEstadoCentro,
    combos: {comboAgrupacion}
  } = this.props

  const agrupacionCombo = comboAgrupacion.map((agrupacion) => agrupacion)
    const agrupacionComboFiltros = []
    agrupacionCombo.forEach(function(agrupacion){
      agrupacionComboFiltros.push({
        value: agrupacion.value,
        label: t(agrupacion.label)
      })
    })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="agrupacion"
              name="agrupacion"
              controlLabel={t('INFORMES.ESTADO_CENTRO.FILTROS.AGRUPACION')}
              component={InputSelect}
              options={agrupacionComboFiltros}
              valueKey="value"
              labelKey="label"
              customClass='select-informes hidden'
              onInputChange={handleSubmit(this.obtenerInformeEstadoCentro.bind(this))}
              isClearable={false}
            />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t('INFORMES.ESTADO_CENTRO.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeEstadoCentro.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosEstadoCentro).length > 0 && (
                    filtrosEstadoCentro.agrupacion && filtrosEstadoCentro.agrupacion !== null ? (
                      <span className="badge-dark hidden">{t('INFORMES.ESTADO_CENTRO.FILTROS.AGRUPACION') + ': ' + filtrosEstadoCentro.agrupacion.label}</span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosEstadoCentro).length > 0 && (
                    (filtrosEstadoCentro.desde && filtrosEstadoCentro.hasta && filtrosEstadoCentro.desde !== null && filtrosEstadoCentro.hasta !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosEstadoCentro.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosEstadoCentro.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtrosEstadoCentro.desde && filtrosEstadoCentro.desde !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosEstadoCentro.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtrosEstadoCentro.hasta && filtrosEstadoCentro.hasta !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosEstadoCentro.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={() => this.crearCsvInformesEstadoCentro()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesEstadoCentro()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesEstadoCentro',
})(FiltrosInformesEstadoCentro))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRuta from '../../components/transporte/ModalRuta'
import {closeModalRuta, filtrarRutasTransporte, seleccionarTodosModalRutaTransportista, deSeleccionarTodosModalRutaTransportista, anadirRutasTransportista} from '../../actions/transporte/transporte'
import {comboRuta, comboCliente} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    transporte: {...state.transporte},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalRuta,
      filtrarRutasTransporte,
      seleccionarTodosModalRutaTransportista,
      deSeleccionarTodosModalRutaTransportista,
      anadirRutasTransportista,
      comboRuta,
      comboCliente
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRuta))

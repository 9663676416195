import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Escandallo from '../../components/escandallo/Escandallo'
import { recuperarDatosModalEscandallo, duplicarDatosModalEscandallo, cambiarEstadoEscandallo, onDeleteEscandallo } from '../../actions/escandallo/escandallo'

export function mapStateToProps (state) {
  return {
    ...state.escandallo,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalEscandallo,
      duplicarDatosModalEscandallo,
      cambiarEstadoEscandallo,
      onDeleteEscandallo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Escandallo))
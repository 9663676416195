import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ModalVehiculo from '../../components/transporte/ModalVehiculo'
import {closeModalVehiculo, submitNuevoVehiculo, editVehiculoAnadido, guardarYNuevoVehiculo} from '../../actions/transporte/transporte'

export function mapStateToProps (state) {
  return {
    transporte: {...state.transporte},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalVehiculo,
      submitNuevoVehiculo,
      editVehiculoAnadido,
      guardarYNuevoVehiculo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalVehiculo)

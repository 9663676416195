import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { abrirModalAgenda, guardarModalAgenda, closeModalAgenda, onDeleteAgenda, onDeleteAgendaSerie } from '../../actions/agenda/agenda'
import {comboOperarioPredeterminado} from '../../actions/combos/combos'
import ModalAgenda from '../../components/agenda/ModalAgenda'

const mapStateToProps = (state) => {
  return {
    ...state.agenda.modalAgenda,
    listTareasCalendario: state.agenda.listTareasCalendario,
    auth: state.auth,
    combos: {
      comboOperarioPredeterminado: state.combos.comboOperarioPredeterminado,
      comboTipoTareasAgenda: state.combos.comboTipoTareasAgenda,
      comboDiasAntes: state.combos.comboDiasAntes,
      comboMeses: state.combos.comboMeses,
    },
    formModalAgenda: getFormValues('ModalAgenda')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalAgenda,
      guardarModalAgenda,
      closeModalAgenda,
      onDeleteAgenda,
      onDeleteAgendaSerie,
      comboOperarioPredeterminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalAgenda)))
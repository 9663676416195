import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInicioPlaning from '../../components/planing/ModalInicioPlaning'
import {closeModalInicioPlaning, obtenerVerracosSinExtraer, imprimirModalInicioPlaning, exportarModalInicioPlaning, seleccionarTodosPlanning, deSeleccionarTodosPlanning, anadirSeleccionPlaning} from '../../actions/planing/planing'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalInicioPlaning,
      obtenerVerracosSinExtraer,
      imprimirModalInicioPlaning,
      exportarModalInicioPlaning,
      seleccionarTodosPlanning,
      deSeleccionarTodosPlanning,
      anadirSeleccionPlaning
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInicioPlaning))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesIntervenciones from '../../components/informes/FiltrosInformesIntervenciones'
import {obtenerInformeIntervenciones, imprimirPdfInformesIntervenciones, crearCsvInformeIntervenciones} from '../../actions/informes/informes'
import {comboNombreProductos, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria, comboVerraco, comboNombreGrupoVerraco, comboOperario, comboUbicacionPadre
  } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeIntervenciones,
      comboNombreProductos,
      comboRaza,
      comboLineaGenetica,
      comboTipoIntervencionSanitaria,
      comboVerraco,
      comboNombreGrupoVerraco,
      comboOperario,
      comboUbicacionPadre,
      imprimirPdfInformesIntervenciones,
      crearCsvInformeIntervenciones
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesIntervenciones))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputDatePicker from '../comun/form/InputDatePicker'
import { date_parser, number_formatter, dateTimeZones } from '../../util/formatFunctions'
import { onDeleteLicencia } from '../../actions/admin/licencias/licencias'

class ModalNuevoPoolAsignacion extends Component {

  close(nuevoPool){  
    this.props.actions.closeModal(nuevoPool); 
  }


  guardarModalAsignacionAnalisisPool(values) {   

    if(this.props.extraccion.redirect != 'undefined' && this.props.extraccion.redirect == 'RedirectAnalisis'){
      //VIENE DESDE EL ANALISIS
      //console.log('vuelve al analisis ',this.props.extraccion.idAnalisis)
      return new Promise((resolve, reject) => {
        let data = []
        this.props.actions.guardarModalPoolAnalisisExtraccion(data, null, values.nombrePool, values.fechaExtraccion, this.props.extraccion.idAnalisis || null, this.props.history, () => {
          resolve()
        }, reject)
      })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })

    }else{
      //VIENE DE LA PANTALLA DE ASIGNACION DE POOL

      //let newPool = {"nombrePool": this.state.nombrePool, "tipoDosis": this.state.tipoDosis, "extraccion": this.props.extraccion}  
      if(values.nombrePool != ''){
        let body = this.createNuevoPoolBody(values);      
        this.props.actions.createNewPool(body);   
        this.close()
      }
    }
  }

  /**
   * Montamos una peticion post con la informacion que sera necesaria para crear el nuevo pool
   * @param {la fila con la informacion de la extraccion con la que se creara el nuevo pool} row 
   */
   createNuevoPoolBody(values) {
    let body = null;
      body = {
        nombre: values.nombrePool,
        fechaExtraccion: values.fechaExtraccion,
        poolEliminado: false,
        codigo: null,
        raza: {
          value: this.props.extraccion.idRaza,
          label: this.props.extraccion.nombreRaza
        },
        analisis: [
          { idAnalisis: this.props.extraccion.idAnalisis,
            idDilucion: this.props.extraccion.idDilucion }
        ],      
        tipoDosis: values.tipoDosis,
      }
    return body;
  }


  render () {
    const {
      t, handleSubmit, showModal, list, data, 
      actions: { createNewPool
      },
      combos: {comboTipoDosis},
    } = this.props
           
    return (      
      <Modal show={showModal} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-ruta" backdrop="static">
        <form className="form-nuevo-pool-asignacion">
          <Modal.Header closeButton>
            <Modal.Title>
              {t('POOL_EXTRACCIONES.FORM.TITLE_NUEVO')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="nombrePool"
                name="nombrePool"
                controlLabel={t('POOL_EXTRACCIONES.FORM.FORM.NOMBRE') + '*'}
                component={InputText}
                validate={required}
                maxLength={true}
                valorMaxLength={40}
              />
              <Field
                colSm={3}
                id="tipoDosis"
                name="tipoDosis"
                controlLabel={t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.FORM.TIPO_DOSIS') + '*'}
                component={InputSelect}
                options={comboTipoDosis}
                valueKey="value"
                labelKey="label"
                isClearable={false}
                noPredeterminado={true}
                validate={required}
              />
              <Field
                colSm={3}
                id="fechaExtraccion"
                name="fechaExtraccion"
                controlLabel={t('POOL_EXTRACCIONES.FORM.FORM.FECHA_EXTRACCION')+ '*'}
                component={InputDatePicker}
              />
              <Field
                colSm={3}
                id="nombreRaza"
                name="nombreRaza"
                controlLabel={t('POOL_EXTRACCIONES.FORM.FORM.RAZA')}
                component={InputText}
                disabled={true}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarModalAsignacionAnalisisPool.bind(this))}>{t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.BUTTONS.ANADIR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'modalNuevoPoolAsignacion'  
})(ModalNuevoPoolAsignacion))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ComboCausaEliminacion from '../../components/comboCausaEliminacion/ComboCausaEliminacion'
import {fetchComboCausaEliminacion, abrirModalVacioComboCausaEliminacion, recuperarDatosModalComboCausaEliminacion} from '../../actions/comboCausaEliminacion/comboCausaEliminacion'

export function mapStateToProps (state) {
  return {
    comboCausaEliminacion: {...state.comboCausaEliminacion},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchComboCausaEliminacion,
      abrirModalVacioComboCausaEliminacion,
      recuperarDatosModalComboCausaEliminacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ComboCausaEliminacion))
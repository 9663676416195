import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, formValueSelector} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalBioseguridadHigiene.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'

class ModalLimpiezaCorral extends Component {
  anadirCorral () {
    const itemsSeleccion = this.props.listLimpiezaCorral.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion,
          'nombreInstalacion': row.nombreInstalacion,
          'codInstalacion': row.codInstalacion,
          'animales': row.animales,
          'activo': row.activo,
          'Verraco': row.Verraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.anadirCorral(list)
    this.props.change('seleccionar-todos-corral', false)
  }

  intercambiarCheckModalLimpiezaCorral (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalLimpiezaCorral()
    } else {
      this.props.actions.deSeleccionarTodosModalLimpiezaCorral()
    }
  }

  componentDidMount () {
    this.props.actions.comboInstalacionPadreActiva()
    this.props.actions.comboFiltroVerracosLimpieza()
    this.props.actions.fetchLimpiezaCorral({list: this.props.listCorralAnadidos})
  }

  filtrarLimpiezaCorral (val) {
    // this.props.change('verraco', null)
    this.props.actions.fetchLimpiezaCorral(this.props.listCorralAnadidos)
  }

  filtrarLimpiezaCorral2 (val) {
    // this.props.change('ubicacion', null)
    this.props.actions.fetchLimpiezaCorral(this.props.listCorralAnadidos)
  }

  render () {
    const {
      t, handleSubmit, listLimpiezaCorral, showModalLimpiezaCorral, checkedLimpiezaCorral, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalLimpiezaCorral },
      combos: { comboInstalacionPadreActiva, comboFiltroVerracosLimpieza }
    } = this.props

    listLimpiezaCorral.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const tableLimpiezaCorral = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.NOMBRE_INDICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.CODIGO_INDICACION')},
        {id: 'Verraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.VERRACO')}
      ],
      rows: listLimpiezaCorral,
      initialValues: {}
    }

    tableLimpiezaCorral.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableLimpiezaCorral.initialValues['check' + row.idElemento] = checkedLimpiezaCorral
        }
      }
    )

    return (
      <Modal show={showModalLimpiezaCorral} onHide={cerrarModalLimpiezaCorral} aria-labelledby="contained-modal-title-lg" size="lg" className="submodal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CORRAL_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={6}
              id="ubicacion"
              name="ubicacion"
              component={InputSelect}
              options={comboInstalacionPadreActiva}
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.UBICACION_PADRE')}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-accion'
              onInputChange={(val) => this.filtrarLimpiezaCorral(val)}
            />
            <Field
              colSm={6}
              id="verraco"
              name="verraco"
              component={InputSelect}
              options={comboFiltroVerracosLimpieza}
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.VERRACO')}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-accion'
              onInputChange={(val) => this.filtrarLimpiezaCorral2(val)}
            />
            <div className="tabla-submodal">
              <div className="filtros-tabla-modal-submodal">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-corral"
                    name="seleccionar-todos-corral"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalLimpiezaCorral(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableLimpiezaCorral}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={() => this.anadirCorral()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalLimpiezaCorral}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalLimpiezaCorral',
})(ModalLimpiezaCorral))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import './ModalMovimientoVerraco.scss'
import { Warning } from '@material-ui/icons'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import {date_formatter, dateTimeZones} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalMovimientoVerraco extends Component {
  constructor (props) {
    super(props)
    this.state = {
      idVerraco: null,
      mostrarComboVerracoSinUbicacion: false,
      fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString)
    }
  }
  nuevoMoviVerraco (values) {
    if ( Object.keys(this.props.movimientoVerraco.registroModificar).length > 0) {
      this.props.actions.editarMoviVerraco(values, this.props.esInsertar)
    } else {
      if ( Object.keys(this.props.movimientoVerraco.registroDuplicar).length > 0) {
        values.idUbicacionVerraco = null
        values.idVerraco = this.props.movimientoVerraco.registroDuplicar.idVerraco
      } else {
        if (this.state.idVerraco !== null){
          values.idVerraco = this.state.idVerraco
        } else {
          if (this.props.esInsertar === true) {
            values.idVerraco = values.idVerraco.value
          }
        }
      }
      this.props.actions.submitNuevoMoviVerraco(values, this.props.esInsertar)
    }
    if (this.props.esInsertar === true) {
      this.props.actions.fetchMovimientoVerraco()
    }
  }

  guardarYNuevoMovimiento (values) {
    /* if (this.state.idVerraco !== ''){
      values.idVerraco = this.state.idVerraco
    } else {
      values.idVerraco = values.idVerraco.value
    } */
    this.props.actions.guardarYNuevoMovimiento(values, this.props.esInsertar)
    if (this.props.esInsertar === true) {
      this.props.actions.fetchMovimientoVerraco()
    }
  }

  enviarUbicacionVacia(value){
    let values = {ubicacion: value, fechaMovimiento: this.state.fechaMovimiento, idVerraco: this.state.idVerraco}
    this.props.actions.ubicacionenesVacias(values)
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboVerracoSinUbicacion(false)
  }

  componentDidUpdate(prevProps, prevState){
    if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
      if (this.props.fichaVerraco.listVerraco.ubicacion !== null) {
        this.props.change('origenMovimiento', this.props.fichaVerraco.listVerraco.ubicacion.nombreInstalacion)
      }
      if (this.props.fichaVerraco.listVerraco.verraco.idVerraco !== this.state.idVerraco) {
        this.setState({idVerraco: this.props.fichaVerraco.listVerraco.verraco.idVerraco})
      }
    }
    if (this.props.movimientoVerraco.registroModificar.idVerraco !== prevProps.movimientoVerraco.registroModificar.idVerraco) {
      this.setState({idVerraco: this.props.movimientoVerraco.registroModificar.idVerraco})
    }
    if (this.props.movimientoVerraco.registroDuplicar.idVerraco !== prevProps.movimientoVerraco.registroDuplicar.idVerraco) {
      this.setState({idVerraco: this.props.movimientoVerraco.registroDuplicar.idVerraco})
    }
    if (this.props.showModalUbicacion !== prevProps.showModalUbicacion && this.props.showModalUbicacion === false && this.props.esInsertar === true) {
      this.setState({idVerraco: null})
    }

    if (prevProps.movimientoVerraco.checkVerracosSinUbicacion !== this.props.movimientoVerraco.checkVerracosSinUbicacion) {
      if (this.props.movimientoVerraco.checkVerracosSinUbicacion === true) {
        this.props.change('verracosSinUbicacion', true)
        this.setState({mostrarComboVerracoSinUbicacion: true})
        this.props.actions.comboVerracoSinUbicacion(true)
      } else {
        this.props.change('verracosSinUbicacion', false)
        this.setState({mostrarComboVerracoSinUbicacion: false})
        this.props.actions.comboVerracoSinUbicacion(false)
      }
    }

    if (prevProps.movimientoVerraco.origenMovimiento !== this.props.movimientoVerraco.origenMovimiento) {
      this.props.change('origenMovimiento', this.props.movimientoVerraco.origenMovimiento.origen)
    }

    if (this.props.showModalUbicacion !== prevProps.showModalUbicacion &&
      Object.keys(this.props.movimientoVerraco.registroModificar).length === 0 &&
      Object.keys(this.props.movimientoVerraco.registroDuplicar).length === 0 &&
      Object.keys(this.props.fichaVerraco.listVerraco).length === 0){
      this.props.change('fechaMovimiento', dateTimeZones(this.props.auth.zonaHorariaString))
      this.setState({fechaMovimiento: new Date})
      let values = {ubicacion: true, fechaMovimiento: new Date, idVerraco: null}
      this.props.actions.ubicacionenesVacias(values)
      this.props.actions.enviarFechaMovimientoVerraco(values)
      this.props.change('ubicacionVacia', true)
    }

    if (this.props.showModalUbicacion !== prevProps.showModalUbicacion && Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
      if (this.props.fichaVerraco.listVerraco.ubicacion !== null) {
        this.setState({fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString)})
        this.props.change('fechaMovimiento', dateTimeZones(this.props.auth.zonaHorariaString))
        let values = {ubicacion: true, fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: this.state.idVerraco}
        this.props.actions.ubicacionenesVacias(values)
        this.props.actions.enviarFechaMovimientoVerraco(values)
        this.props.change('ubicacionVacia', true)
        if (Object.keys(this.props.fichaVerraco.listVerraco.ubicacion).length > 0) {
          this.setState({fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString)})
          this.props.change('fechaMovimiento', dateTimeZones(this.props.auth.zonaHorariaString))
          let values = {ubicacion: true, fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: this.state.idVerraco}
          this.props.actions.ubicacionenesVacias(values)
          this.props.actions.enviarFechaMovimientoVerraco(values)
          this.props.change('ubicacionVacia', true)
        } else {
          this.setState({fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString)})
          this.props.change('fechaMovimiento', dateTimeZones(this.props.auth.zonaHorariaString))
          let values = {ubicacion: true, fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: this.state.idVerraco}
          this.props.actions.ubicacionenesVacias(values)
          this.props.actions.enviarFechaMovimientoVerraco(values)
          this.props.change('ubicacionVacia', true)
        }
      } else {
        this.setState({fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString)})
        this.props.change('fechaMovimiento', dateTimeZones(this.props.auth.zonaHorariaString))
        let values = {ubicacion: true, fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: this.state.idVerraco}
        this.props.actions.ubicacionenesVacias(values)
        this.props.actions.enviarFechaMovimientoVerraco(values)
        this.props.change('ubicacionVacia', true)
      }
    }

    if (this.props.showModalUbicacion !== prevProps.showModalUbicacion ) {
      if (Object.keys(this.props.movimientoVerraco.registroModificar).length > 0) {
        let values = {ubicacion: true, fechaMovimiento: this.props.movimientoVerraco.registroModificar.fechaMovimiento, idVerraco: this.state.idVerraco, idUbicacionVerraco: this.props.movimientoVerraco.registroModificar.idUbicacionVerraco}
        this.props.actions.ubicacionenesVacias(values)
        this.props.actions.enviarFechaMovimientoVerraco(values)
        this.props.change('ubicacionVacia', true)
      }
      if (Object.keys(this.props.movimientoVerraco.registroDuplicar).length > 0) {
        let values = {ubicacion: true, fechaMovimiento: this.props.movimientoVerraco.registroDuplicar.fechaMovimiento, idVerraco: this.state.idVerraco}
        this.props.actions.ubicacionenesVacias(values)
        this.props.actions.enviarFechaMovimientoVerraco(values)
        this.props.change('ubicacionVacia', true)
      }
    }
  }

  enviarFecha (fecha) {
    this.setState({fechaMovimiento: fecha})
    const values = {fechaMovimiento: fecha, idVerraco: this.state.idVerraco, idUbicacionVerraco: this.props.movimientoVerraco.registroModificar.idUbicacionVerraco}
    this.props.actions.enviarFechaMovimientoVerraco(values)
    values.ubicacion = this.props.formMovimientoVerraco.values.ubicacionVacia
    this.props.actions.ubicacionenesVacias(values)
  }

  handleChangeVerraco (id) {
    if (id !== null){
      this.setState({idVerraco: id.value})
      if(this.props.formMovimientoVerraco.values != null){
        if (this.state.fechaMovimiento !== '') {
          const values = {fechaMovimiento: this.state.fechaMovimiento, idVerraco: id.value, ubicacion: this.props.formMovimientoVerraco.values.ubicacionVacia}
          this.props.actions.enviarFechaMovimientoVerraco(values)
          this.props.actions.ubicacionenesVacias(values)
        } else {
          const values = {fechaMovimiento: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: id.value, ubicacion: this.props.formMovimientoVerraco.values.ubicacionVacia}
          this.props.actions.enviarFechaMovimientoVerraco(values)
          this.props.actions.ubicacionenesVacias(values)
        }
      }
    } else {
      this.setState({idVerraco: null})
      this.props.change('origenMovimiento', '')
    }
  }

  cerrarVaciarModalUbicacion () {
    this.props.actions.cerrarModalUbicacion()
    this.props.actions.quitarCheckVerracosSinUbicacion()
    this.setState({fechaMovimiento: ''})
    if (Object.keys(this.props.movimientoVerraco.registroModificar).length === 0 && Object.keys(this.props.movimientoVerraco.registroDuplicar).length === 0 ) {
      this.setState({idVerraco: null})
    }
    this.setState({mostrarComboVerracoSinUbicacion: false})
    /*if (this.props.esInsertar === true) {
      this.props.actions.fetchMovimientoVerraco()
    }*/
    this.props.change('idInstalacion', null)
    this.props.change('observacionesMovimiento', null)
  }

  verracosSinUbicacion (value) {
    if (this.props.movimientoVerraco.checkVerracosSinUbicacion === true) {
      this.props.actions.quitarCheckVerracosSinUbicacion()
      // this.props.change('verracosSinUbicacion', true)
      this.setState({mostrarComboVerracoSinUbicacion: true})
    } else {
      this.props.actions.checkVerracosSinUbicacion()
      // this.props.change('verracosSinUbicacion', false)
      this.setState({mostrarComboVerracoSinUbicacion: false})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalUbicacion, formMovimientoVerraco, mostrarComboVerraco, mostrarOrigenVerraco,
      actions:{recuperarDatosModalMovimientoVerraco, cerrarModalUbicacion},
      movimientoVerraco: {list, verracoMovidoHoy, registroModificar},
      combos:{comboUbicacion, comboVerraco, comboVerracoSinUbicacion, comboUbicacionVacia}
    } = this.props

    return (
      <Modal show={showModalUbicacion} onHide={() => this.cerrarVaciarModalUbicacion()} aria-labelledby="contained-modal-title-lg" className="modal-movimiento-verraco" backdrop="static">
      <form className="form-movimiento-verraco">
        <Modal.Header closeButton>
          <Modal.Title>
            {
              Object.keys(this.props.movimientoVerraco.registroModificar).length > 0 ? (
                t(`MOVIMIENTO_VERRACOS.MODAL.TITULO_EDITAR`)
              ) : (
                t(`MOVIMIENTO_VERRACOS.MODAL.TITULO_NUEVO`)
              )
            }
          </Modal.Title>
          {registroModificar && registroModificar.idUbicacionVerraco && (
            <ButtonChangeRecord list={list} idElemento="idUbicacionVerraco" currentId={registroModificar.idUbicacionVerraco} getNextRecord={recuperarDatosModalMovimientoVerraco} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
          {
            Object.keys(this.props.movimientoVerraco.registroModificar).length > 0 &&
            <Col sm={12}><p><b>{t('MOVIMIENTO_VERRACOS.VERRACO') + ': ' + this.props.movimientoVerraco.registroModificar.codVerraco}</b></p></Col>
          }
          {
            Object.keys(this.props.movimientoVerraco.registroModificar).length === 0 && 
            Object.keys(this.props.movimientoVerraco.registroDuplicar).length === 0 &&
            Object.keys(this.props.fichaVerraco.listVerraco).length === 0 && mostrarComboVerraco !== false ? (
              <div>
                <Field
                  id="verracosSinUbicacion"
                  name="verracosSinUbicacion"
                  colSm={12}
                  component={InputCheckBox}
                  labelInput={t('MOVIMIENTO_VERRACOS.MODAL.MOSTRAR_VERRACOS_SIN_UBICACION')}
                  onInputChange={(value) => this.verracosSinUbicacion(value)}
                />
                {
                  this.state.mostrarComboVerracoSinUbicacion === true ? (
                    <Field
                      colSm={6}
                      id="idVerraco"
                      name="idVerraco"
                      controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.VERRACO')+ '*'}
                      component={InputSelect}
                      options={comboVerracoSinUbicacion}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-idVerraco'
                      validate={required}
                      onInputChange={(id) => this.handleChangeVerraco(id)}
                    />
                  ) : (
                    <Field
                      colSm={6}
                      id="idVerraco"
                      name="idVerraco"
                      controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.VERRACO')+ '*'}
                      component={InputSelect}
                      options={comboVerracoSinUbicacion}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-idVerraco'
                      validate={required}
                      onInputChange={(id) => this.handleChangeVerraco(id)}
                    />
                  )
                }
              </div>
            ) : null
          }
          <div className="clearfix"></div>
            <Field
              id="fechaMovimiento"
              name="fechaMovimiento"
              component={InputDatePicker}
              colSm={4}
              controlLabel={t(`MOVIMIENTO_VERRACOS.MODAL.FECHA`) + '*'}
              onInputChange={(value) => this.enviarFecha(value)}
              validate={required}
              // disabled={(this.state.idVerraco === '' || this.state.idVerraco === undefined) && mostrarComboVerraco !== false ? true : false}
            />
            {
              verracoMovidoHoy && verracoMovidoHoy.ubicacionVerracoExistente === true ? (
                <Col sm={12} style={{color: '#dc3545', marginBottom: '15px'}}>
                  <span style={{float: 'left', width: '28px'}}><Warning/></span>
                  <span style={{float: 'left', width: '94%', fontSize: '12px'}}>{t(`MOVIMIENTO_VERRACOS.MODAL.ALERTA_VERRACO_MOVIDO_HOY`)}</span>
                  <div className="clearfix"></div>
                </Col>
              ) : null
            }
            {
              mostrarOrigenVerraco !== false ? (
                <Field
                  id="origenMovimiento"
                  name="origenMovimiento"
                  component={InputText}
                  colSm={8}
                  controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.ORIGEN')}
                  disabled={true}
                />
              ) : null
            }
            <Field
              id="ubicacionVacia"
              name="ubicacionVacia"
              colSm={12}
              component={InputCheckBox}
              controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.DESTINO') + '*'}
              labelInput={t('MOVIMIENTO_VERRACOS.MODAL.MOSTRAR_UBICACIONES_VACIAS')}
              onInputChange={() => this.enviarUbicacionVacia(formMovimientoVerraco.values.ubicacionVacia)}
            />
            <Field
              colSm={8}
              id="idInstalacion"
              name="idInstalacion"
              // controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.DESTINO')}
              component={InputSelect}
              options={comboUbicacionVacia}
              valueKey="value"
              labelKey="label"
              customClass='select-destino-verraco'
              validate={required}
            />
            <Field
              colSm={12}
              id="observacionesMovimiento"
              name="observacionesMovimiento"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.OBSERVACIONES')}
              customClass='observaciones-verraco'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/*
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYNuevoMovimiento.bind(this))}>{t('MOVIMIENTO_VERRACOS.MODAL.GUARDAR_Y_NUEVO')}</Button>
           */}
          <Button
            type="button"
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] < 2) ? ' disabled' : '')}
            onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.nuevoMoviVerraco.bind(this)) : null : undefined}
          >{t('MOVIMIENTO_VERRACOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarVaciarModalUbicacion()}>{t('MOVIMIENTO_VERRACOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: "modalMovimientoVerraco",
})(ModalMovimientoVerraco))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalSecuenciasNumeracion from '../../components/panelControl/ModalSecuenciasNumeracion'
import { cerrarModalSecuenciasNumeracion, editarSecuencia} from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalSecuenciasNumeracion,
      editarSecuencia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalSecuenciasNumeracion))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalBioseguridadHigiene.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'

class ModalUbicacion extends Component {
  anadirUbicacion () {
    const itemsSeleccion = this.props.listUbicacion.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion,
          'nombreInstalacion': row.nombreInstalacion,
          'codInstalacion': row.codInstalacion,
          'animales': row.animales,
          'activo': row.activo
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.anadirUbicacion(list)
    this.props.change('seleccionar-todos-ubicacion', false)
  }

  intercambiarCheckModalUbicacion (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalUbicacion()
    } else {
      this.props.actions.deSeleccionarTodosModalUbicacion()
    }
  }

  componentDidMount () {
    this.props.actions.comboInstalacionesPadre()
    this.props.actions.fetchUbicacion({list: this.props.listUbicacionAnadidos})
  }

  filtrarUbicacion (values) {
    values.list = this.props.listUbicacionAnadidos
    this.props.actions.fetchUbicacion(values)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosUbicacion
    values[value] = null
    values.list = this.props.listUbicacionAnadidos
    this.props.actions.fetchUbicacion(values)
    this.props.change(value, null)
  }

  render () {
    const {
      t, handleSubmit, listUbicacion, showModalUbicacion, checkedUbicacion, numeroRegistrosPorPagina, paginaActual, filtrosUbicacion,
      combos: {comboInstalacionesPadre},
      actions: { cerrarModalUbicacion }
    } = this.props

    listUbicacion.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const tableUbicacion = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.NOMBRE_UBICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.CODIGO_UBICACION')}
      ],
      rows: listUbicacion,
      initialValues: {}
    }

    tableUbicacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableUbicacion.initialValues['check' + row.idElemento] = checkedUbicacion
        }
      }
    )

    return (
      <Modal show={showModalUbicacion} onHide={cerrarModalUbicacion} aria-labelledby="contained-modal-title-lg" size="lg" className="submodal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.UBICACION_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tabla-filtros open-filters" style={{display: 'inline-block'}}>
            <Field
              colSm={4}
              id="instalacionPadre"
              name="instalacionPadre"
              controlLabel={t('INSTALACION.FILTROS.INSTALACION_PADRE')}
              component={InputSelect}
              options={comboInstalacionesPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-ubicacion'
              onInputChange={handleSubmit(this.filtrarUbicacion.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosUbicacion).length > 0 && (
                    (filtrosUbicacion.instalacionPadre && filtrosUbicacion.instalacionPadre !== null && filtrosUbicacion.instalacionPadre !== '') ? (
                      <span className="badge-dark">{t('INSTALACION.FILTROS.INSTALACION_PADRE') + ': ' + filtrosUbicacion.instalacionPadre.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('instalacionPadre')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </div>
          <Row>
            <div className="tabla-submodal" style={{paddingTop: 0}}>
              <div className="filtros-tabla-modal-submodal">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-ubicacion"
                    name="seleccionar-todos-ubicacion"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalUbicacion(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableUbicacion}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={() => this.anadirUbicacion()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalUbicacion}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalUbicacion',
})(ModalUbicacion))
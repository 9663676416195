import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalGrupoUsuarios from '../../components/grupoUsuarios/ModalGrupoUsuarios'
import { abrirModalGrupoUsuarios, cerrarModalGrupoUsuarios, submitGrupoUsuarios } from '../../actions/grupoUsuarios/grupoUsuarios'

export function mapStateToProps (state) {
  return {
    ...state.grupoUsuarios,
    formModalGrupoUsuarios: getFormValues('ModalGrupoUsuarios')(state),
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalGrupoUsuarios,
      cerrarModalGrupoUsuarios,
      submitGrupoUsuarios
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGrupoUsuarios))
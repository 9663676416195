import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalComboObservacionesEyaculadoEliminadoPage from '../../containers/comboObservacionesEyaculadoEliminado/ModalComboObservacionesEyaculadoEliminadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosComboObservacionesEyaculadoEliminado from '../../containers/comboObservacionesEyaculadoEliminado/FiltrosComboObservacionesEyaculadoEliminadoPage'

class ComboObservacionesEyaculadoEliminado extends Component {

  componentDidMount () {
    document.title = this.props.t('COMBO_OBSERVACIONES_EYACULADO_ELIMINADO.TITLE') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchComboObservacionesEyaculadoEliminado()
  }

  render () {
    const {
      t,
      comboObservacionesEyaculadoEliminado: { list },
      actions: {recuperarDatosModalComboObservacionesEyaculadoEliminado }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.id
    )

    const table = {
      id: 'ComboObservacionesEyaculadoEliminadoTable',
      // hasCheckbox: true,
      hasOptions: false,
      columns: [
        {id: 'text', name: t('COMBO_OBSERVACIONES_EYACULADO_ELIMINADO.TABLA.NOMBRE')},
        {id: 'code', name: t('COMBO_OBSERVACIONES_EYACULADO_ELIMINADO.TABLA.CODIGO')},
        {id: 'activo', name: t('COMBO_OBSERVACIONES_EYACULADO_ELIMINADO.TABLA.ACTIVO')}
      ],
      rows: list,
      onEdit: (row) => recuperarDatosModalComboObservacionesEyaculadoEliminado(row.id),
      filtros: <FiltrosComboObservacionesEyaculadoEliminado/>
    }

    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalComboObservacionesEyaculadoEliminadoPage/>
        <ListadoPage t_key="COMBO_OBSERVACIONES_EYACULADO_ELIMINADO." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default ComboObservacionesEyaculadoEliminado
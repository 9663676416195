import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'
import './ImportarDatos.scss'

class ImportarDatos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: '',
      archivoCargado: false
    }
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosImportarDatos({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
        this.setState({archivoCargado: true})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitImportarDatos () {
    this.props.actions.submitImportarDatos(this.state.archivos)
    this.setState({archivoCargado: false})
  }

  cerrarModalImportarDatos () {
    this.props.actions.cerrarModalImportarDatos()
    this.setState({archivos: ''})
    this.setState({archivoCargado: false})
  }

  render () {
    const {
      t, handleSubmit, showModalImportarDatos, archivo, listImportarDatos
      } = this.props

    if(!showModalImportarDatos) return null
    return (
      <Modal show={showModalImportarDatos} onHide={() => this.cerrarModalImportarDatos()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-carga-index">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('IMPORTAR_DATOS.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-carga-index">
              <Dropzone
                accept=".csv"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivo).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
              ) : null
            }
            {
              Object.keys(listImportarDatos).length > 0 ? (
                <div className="resultado-importar-datos">
                {
                  listImportarDatos.esValido === 0 ? (
                    <ul>
                      <li>{t('IMPORTAR_DATOS.DOCUMENTO_NO_VALIDO')}</li>
                    </ul>
                  ) : (
                    <ul>
                      {
                        listImportarDatos.noExisteCliente.length > 0 ? (
                          listImportarDatos.noExisteCliente.map((mensajeCliente, index) => {
                            return <li key={index}><b>{mensajeCliente}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteRaza.length > 0 ? (
                          listImportarDatos.noExisteRaza.map((mensajeRazaVerraco, index) => {
                            return <li key={index}><b>{mensajeRazaVerraco}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteLinGenetica.length > 0 ? (
                          listImportarDatos.noExisteLinGenetica.map((mensajeLineaGenetica, index) => {
                            return <li key={index}><b>{mensajeLineaGenetica}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteVerracoGrupo.length > 0 ? (
                          listImportarDatos.noExisteVerracoGrupo.map((mensajeVerracoGrupo, index) => {
                            return <li key={index}><b>{mensajeVerracoGrupo}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteGrupo.length > 0 ? (
                          listImportarDatos.noExisteGrupo.map((mensajeGrupo, index) => {
                            return <li key={index}><b>{mensajeGrupo}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteClienteVerraco.length > 0 ? (
                          listImportarDatos.noExisteClienteVerraco.map((mensajeClienteVerraco, index) => {
                            return <li key={index}><b>{mensajeClienteVerraco}</b></li>
                          })
                        ) : null
                      }
                      {
                        listImportarDatos.noExisteGranjaVerraco.length > 0 ? (
                          listImportarDatos.noExisteGranjaVerraco.map((mensajeGranjaVerraco, index) => {
                            return <li key={index}><b>{mensajeGranjaVerraco}</b></li>
                          })
                        ) : null
                      }
                      {
                        Object.keys(listImportarDatos.noExisteCliente).length > 0 || listImportarDatos.noExisteLinGenetica.length > 0 || listImportarDatos.noExisteRaza.length > 0 || listImportarDatos.noExisteVerracoGrupo.length > 0 || listImportarDatos.noExisteGrupo.length > 0 || listImportarDatos.noExisteClienteVerraco.length > 0 || listImportarDatos.noExisteGranjaVerraco.length > 0 ? (
                          <li className="mensaje-no-inseta"></li>
                        ) : null
                      }
                      <li><b>{t('IMPORTAR_DATOS.NUM_RAZAS')}:</b> {listImportarDatos.numeroRazas}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_LINEAS')}:</b> {listImportarDatos.numeroLineas}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_CLIENTES')}:</b> {listImportarDatos.numeroClientes}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_GRANJAS_CLIENTE')}:</b> {listImportarDatos.numeroGranjasClientes}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_OPERARIOS')}:</b> {listImportarDatos.numeroOperarios}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_VERRACOS')}:</b> {listImportarDatos.numeroVerracos}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_GRUPO_VERRACOS')}:</b> {listImportarDatos.numeroGrupoVerracos}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_GRUPO_VERRACOS_VERRACOS')}:</b> {listImportarDatos.numGrupoVerracosVerracos}</li>
                      <li><b>{t('IMPORTAR_DATOS.NUM_COLORIMETRO')}:</b> {listImportarDatos.numeroColorimetro}</li>
                    </ul>
                  )
                }
              </div>
              ) : null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(listImportarDatos).length === 0 ? (
              <Button type="button" onClick={() => this.submitImportarDatos()} className="btn btn-primary">{t('CARGA_INDEX.ACEPTAR')}</Button>
            ) : null
          }
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalImportarDatos()}>{t('CARGA_INDEX.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ImportarDatos',
})(ImportarDatos))
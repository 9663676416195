import actionTypes from '../../../constants/actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

export function initialState () {
  return {
    list: [],
    checkedFiltrosRegistroIntervencionSanitaria: false,
    filtros: {},
    showModalVerracoRegistroIntervencionSanitaria: false,
    verracosAnadidos: [],
    listVerracosEnRegistroIntervencionSanitaria: [],
    checkedVerracosRegistroIntervencionSanitaria: false,
    filtrosModalVerracoRegistroIntervencionSanitaria: [],
    checkedModalRegistroIntervencionSanitaria: false,
    data: [],
    dataDuplicar: [],
    datosExcelRegistroIntervencion: [],
    datosPdfRegistroIntervencion: [],
    archivo: [],
    archivoDescarga: [],
    showModalAgendaIntervencionSanitaria: false,
    idIntervencion: '',
    showModalRegistroIntervencion: false
  }
}

export function fetchRegistroIntervencionSanitariaSuccess (state, {list}) {
  return {
    ...state,
    list,
    verracosAnadidos: [],
    archivo: [],
    dataDuplicar: [],
    data: []
  }
}

export function fetchRegistroIntervencionSanitariaImprimirGuardarSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function seleccionarTodosRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedFiltrosRegistroIntervencionSanitaria: true
  }
}

export function deSeleccionarTodosRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedFiltrosRegistroIntervencionSanitaria: false
  }
}

export function recuperarDatosModalRegistroIntervencionSanitariaSuccess (state, {data}) {
  return {
    ...state,
    showModalRegistroIntervencion: true,
    data,
    verracosAnadidos: data.verraco,
    dataDuplicar: []
  }
}

export function duplicarDatosModalRegistroIntervencionSanitariaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    showModalRegistroIntervencion: true,
    dataDuplicar,
    verracosAnadidos: dataDuplicar.verraco,
    data: []
  }
}

export function vaciarDatosModalRegistroIntervencionSanitariaSuccess (state, {data}) {
  return {
    ...state,
    data: []
  }
}

export function fetchFiltrarRegistroIntervencionSanitariaSuccess (state, {filtros}){
  return {
    ...state,
    filtros
  }
}

export function abrirModalVerracoRegistroIntervencionSanitaria (state){
  return {
    ...state,
    showModalVerracoRegistroIntervencionSanitaria: true
  }
}

export function cerrarModalVerracoRegistroIntervencionSanitaria (state){
  return {
    ...state,
    showModalVerracoRegistroIntervencionSanitaria: false
  }
}

export function fetchVerracosRegistroIntervencionSanitariaSuccess(state, {listVerracosEnRegistroIntervencionSanitaria}){
  return{
    ...state,
    listVerracosEnRegistroIntervencionSanitaria: listVerracosEnRegistroIntervencionSanitaria
  }
}

export function seleccionarTodosModalVerracoRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedVerracosRegistroIntervencionSanitaria: true
  }
}

export function deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedVerracosRegistroIntervencionSanitaria: false
  }
}

export function anadirVerracoModalRegistroIntervencionSanitariaSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos: state.verracosAnadidos.concat(verracosAnadidos)
  }
}

export function fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess (state, {filtrosModalVerracoRegistroIntervencionSanitaria}) {
  return {
    ...state,
    filtrosModalVerracoRegistroIntervencionSanitaria
  }
}

export function seleccionarTodosModalRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedModalRegistroIntervencionSanitaria: true
  }
}

export function deSeleccionarTodosModalRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    checkedModalRegistroIntervencionSanitaria: false
  }
}

export function onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos,
    checkedModalRegistroIntervencionSanitaria: false
  }
}

export function inicializarVerraco (state) {
  return {
    ...state,
    verracosAnadidos: [],
    archivo: []
  }
}

export function crearCsvRegistroIntervencionSuccess (state, {datosExcelRegistroIntervencion}) {
  return {
    ...state,
    datosExcelRegistroIntervencion
  }
}

export function imprimirPdfRegistroIntervencionSuccess (state, {datosPdfRegistroIntervencion}) {
  return {
    ...state,
    datosPdfRegistroIntervencion
  }
}

export function subirArchivosIntervencion (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function eliminarArchvioIntervencion (state, {archivo}) {
  const archivos = state.archivo
  const datos = archivos.filter(function(data) {
    if (data.archivo !== archivo) {
        return data
    }
  })
  return {
    ...state,
    archivo: datos
  }
}

export function obtenerArchivoIntervencionSuccess (state, {archivoDescarga}) {
  return {
    ...state,
    archivoDescarga
  }
}

export function abrirModalAgendaIntervencionSanitariaSuccess (state) {
  return {
    ...state,
    showModalAgendaIntervencionSanitaria: true
  }
}

export function guardarModalAgendaIntervencionSanitariaSuccess (state) {
  return {
    ...state,
    showModalAgendaIntervencionSanitaria: false
  }
}

export function cerrarModalAgendaIntervencionSanitariaSuccess (state) {
  return {
    ...state,
    showModalAgendaIntervencionSanitaria: false
  }
}

export function submitNuevoRegistroIntervencionSanitariaSuccess (state, {idIntervencion}) {
  return {
    ...state,
    idIntervencion: idIntervencion
  }
}

export function abrirModalVacioRegistroIntervencionSanitaria (state) {
  return {
    ...state,
    showModalRegistroIntervencion: true
  }
}

export function closeRegistroIntervencion (state) {
  return {
    ...state,
    showModalRegistroIntervencion: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return fetchRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA_IMPRIMIR_GUARDAR_SUCCESS:
      return fetchRegistroIntervencionSanitariaImprimirGuardarSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA:
      return seleccionarTodosRegistroIntervencionSanitaria(state, action)
    case actionTypes.DESELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA:
      return deSeleccionarTodosRegistroIntervencionSanitaria(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return recuperarDatosModalRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return duplicarDatosModalRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.VACIAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return vaciarDatosModalRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return fetchFiltrarRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA:
      return abrirModalVerracoRegistroIntervencionSanitaria(state, action)
    case actionTypes.CERRAR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA:
      return cerrarModalVerracoRegistroIntervencionSanitaria(state, action)
    case actionTypes.FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return fetchVerracosRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA:
      return seleccionarTodosModalVerracoRegistroIntervencionSanitaria(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA:
      return deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria(state, action)
    case actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return anadirVerracoModalRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA:
      return seleccionarTodosModalRegistroIntervencionSanitaria(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA:
      return deSeleccionarTodosModalRegistroIntervencionSanitaria(state, action)
    case actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess(state, action)
    case actionTypes.INICIALIZAR_VERRACO:
      return inicializarVerraco(state, action)
    case actionTypes.CREATE_CSV_REGISTRO_INTERVENCION_SUCCESS:
      return crearCsvRegistroIntervencionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_REGISTRO_INTERVENCION_SUCCESS:
      return imprimirPdfRegistroIntervencionSuccess(state, action)
    case actionTypes.SUBIR_ARCHIVOS_INTERVENCION:
      return subirArchivosIntervencion(state, action)
    case actionTypes.ELIMINAR_ARCHIVO_INTERVENCION:
      return eliminarArchvioIntervencion(state, action)
    case actionTypes.OBTENER_ARCHIVO_INTERVENCION_SUCCESS:
      return obtenerArchivoIntervencionSuccess(state, action)
    case actionTypes.ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS:
      return abrirModalAgendaIntervencionSanitariaSuccess (state, action)
    case actionTypes.GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS:
      return guardarModalAgendaIntervencionSanitariaSuccess (state, action)
    case actionTypes.CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS:
      return cerrarModalAgendaIntervencionSanitariaSuccess (state, action)
    case actionTypes.SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS:
      return submitNuevoRegistroIntervencionSanitariaSuccess (state, action)

    case actionTypes.ABRIR_MODAL_VACIO_REGISTRO_INTERVENCION_SANITARIA:
      return abrirModalVacioRegistroIntervencionSanitaria (state, action)
    case actionTypes.CLOSE_REGISTRO_INTERVENCION:
      return closeRegistroIntervencion (state, action)
    default:
      return state
  }
}
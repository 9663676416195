import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import Pagination from 'react-js-pagination'
import PropTypes from 'prop-types'
import { Table as BootstrapTable, Dropdown, MenuItem, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import { MoreVert, Edit, FileCopy, Refresh, Delete, CheckBox, Opacity, RemoveRedEye, ArrowDownward, ArrowUpward, Print, Beenhere, VpnKey, Replay, CloudDownload
  } from '@material-ui/icons'
import './simpleTable.scss'
import SimpleLeyenda from './SimpleLeyenda'
import InputCheckBox from '../form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import {date_formatter, number_formatter, dateTimeZones} from '../../../util/formatFunctions'

class SimpleTableNewChildren extends Component {
  constructor (props) {
    super()
    this.state = {
      imputSelected: false,
      activePage: 1,
      filaSelccionada: '',
      rowsOrdenado: [],
      typeOrder: '',
      columnId: '',
      idCol: '',
      columnaSelccionada: ''
    }
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.listenScrollEvent = this.listenScrollEvent.bind( this )
  }

  onCheckboxChange (val) {
    if (this.props.onCheckboxChange) {
      this.props.onCheckboxChange()
    }
    if (this.props.noBlockOptions !== false){
      this.addClass()
    }
  }
  addClass = (event) => {
    if (this.props.noBlockOptions !== false && !this.props.tablaModal){
      document.getElementById('block-options').classList.remove('oculta-action')
      this.setState({ imputSelected: true })
    }

    if (this.props.noBlockOptions !== false && this.props.tablaModal === true){
      document.getElementById('block-options' + this.props.idBlockOptions).classList.remove('oculta-action')
      this.setState({ imputSelected: true })
    }
  }
  removeClass = (event) => {
    if (this.props.noBlockOptions !== false){
      document.getElementById('block-options').classList.add('oculta-action')
      this.setState({ imputSelected: false })
    }

    if (this.props.noBlockOptions !== false && this.props.tablaModal === true){
      document.getElementById('block-options' + this.props.idBlockOptions).classList.add('oculta-action')
      this.setState({ imputSelected: true })
    }
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber})
    this.props.actions.cambiarPagina(pageNumber)
  }

  cambiarTotalRegistro(event, newValue) {
    if (newValue != null) {
      this.props.actions.seleccionarPorPagina(newValue.value)
    }
  }

  componentDidMount () {
    if (this.props.rows) {
      this.setState({rowsOrdenado: this.props.rows})
    }
    this.handlePageChange(this.state.activePage)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.initialValues) !== JSON.stringify(prevProps.initialValues)){
      this.props.initialize(this.props.initialValues)
    }

    if (this.props.rows !== prevProps.rows) {
      this.setState({rowsOrdenado: this.props.rows})
      this.setState({idCol: ''})
    }

    if (this.props.showModal !== prevProps.showModal) {
      this.handlePageChange(this.state.activePage)
    }
    if (this.props.checkedTabla1 !== prevProps.checkedTabla1) {
      if (this.props.checkedTabla1 === true){
        this.handlePageChange(this.state.activePage)
      }
    }
    if (this.props.checkedTabla2 !== prevProps.checkedTabla2) {
      if (this.props.checkedTabla2 === true){
        this.handlePageChange(this.state.activePage)
      }
    }
    if (this.props.checkedTabla3 !== prevProps.checkedTabla3) {
      if (this.props.checkedTabla3 === true){
        this.handlePageChange(this.state.activePage)
      }
    }

    if (this.props.planing.datosVerracoPlaning !== prevProps.planing.datosVerracoPlaning) {
      if (Object.keys(this.props.planing.datosVerracoPlaning).length === 0) {
        var elems = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function(el) {
           el.classList.remove("fila-seleccionada-planing")
        })

        var elems2 = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems2, function(el) {
           el.classList.remove("fila-seleccionada-planing")
        })
      }
    }
  }

  listenScrollEvent (event) {
    global.positionX = event.clientX
    global.positionY = event.clientY
    document.getElementById('planingTable1').getElementsByTagName('tbody')[0].onscroll = function(positionX, positionY){
      let div1Coordinates = document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getBoundingClientRect()
      if((global.positionX >= div1Coordinates.left && global.positionX <= div1Coordinates.right) && (global.positionY >= div1Coordinates.top && global.positionY <= div1Coordinates.bottom)){
        let div1 = document.getElementById("planingTable1").getElementsByTagName('tbody')[0]
        let div2 = document.getElementById("planingTable2").getElementsByTagName('tbody')[0]
        div2.scrollTop = div1.scrollTop
      }
    }
    document.getElementById('planingTable2').getElementsByTagName('tbody')[0].onscroll = function(positionX, positionY){
      let div2Coordinates = document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getBoundingClientRect()
      if((global.positionX >= div2Coordinates.left && global.positionX <= div2Coordinates.right) && (global.positionY >= div2Coordinates.top && global.positionY <= div2Coordinates.bottom)){
        let div1 = document.getElementById("planingTable1").getElementsByTagName('tbody')[0]
        let div2 = document.getElementById("planingTable2").getElementsByTagName('tbody')[0]
        div1.scrollTop = div2.scrollTop
      }
    }
  }

  anadirClasePlaning (id) {
    this.setState({filaSelccionada: id})
    var elems = document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-planing");
    })
    var elems2 = document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function(el) {
       el.classList.remove("fila-seleccionada-planing");
    })

    document.getElementById('planingTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    document.getElementById('planingTable2').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
  }

  anadirClaseFoso (id, idCol, row, valorColumna, nombreColumna) {
    if (!isNaN(valorColumna)) {
      this.props.actions.obtenerExtraccion(valorColumna)
      this.setState({columnaSelccionada: id})
      var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function(el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function(eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
       })
      })
      document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].getElementsByTagName('td')[idCol].classList.add("fila-seleccionada-foso")
    } else {
      if (nombreColumna !== 'Verraco' && valorColumna !== undefined && valorColumna !== '[[_#hffff00_]]'){
        this.props.actions.obtenerDatosVerraco(row.idVerraco, nombreColumna)
        this.setState({columnaSelccionada: id})
        var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function(el) {
          var elemstd = el.getElementsByTagName('td');
          [].forEach.call(elemstd, function(eltd) {
            eltd.classList.remove("fila-seleccionada-foso");
         })
        })
        document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].getElementsByTagName('td')[idCol].classList.add("fila-seleccionada-foso")
      }
    }
  }

  sortTable(columnId, columnType, idCol, columChild, id) {
    let rowsOrdenado = this.props.rows
    if (this.state.typeOrder === 'desc' && this.state.columnId === columnId){
      // DESCENDENTE
      if (columnType === 'fecha') {
        rowsOrdenado.sort(function(a, b) {
        let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
        return dateB - dateA
      })
      } else if (columnType === 'numero') {
        rowsOrdenado.sort(function(a, b) {
          return b[columnId] - a[columnId]
        })
      } else {
        rowsOrdenado.sort(function(a,b) {
          let nameA, nameB
          if (columChild !== null) {
            nameA = a[columnId][columChild]
            nameB = b[columnId][columChild]
          } else {
            nameA = a[columnId]
            nameB = b[columnId]
          }
          
          if (nameA === null) {
            nameA = ' '
          }
          if (nameB === null) {
            nameB = ' '
          }
          if ( nameA > nameB ){
            return -1
          }
          if ( nameA < nameB ){
            return 1
          }
          return 0
        })
      }
      this.setState({typeOrder: 'asc'})
      this.setState({columnId: columnId})
    } else {
      // ASCENDENTE
      if (columnType === 'fecha') {
        rowsOrdenado.sort(function(a, b) {
        let dateA = new Date(a[columnId]), dateB = new Date(b[columnId])
        return dateA-dateB
      })
      } else if (columnType === 'numero') {
        rowsOrdenado.sort(function(a, b) {
          return a[columnId] - b[columnId]
        })
      } else {
        rowsOrdenado.sort(function(a,b) {
          // let nameA = a[columnId], nameB = b[columnId]
          let nameA, nameB
          if (columChild !== null) {
            nameA = a[columnId][columChild]
            nameB = b[columnId][columChild]
          } else {
            nameA = a[columnId]
            nameB = b[columnId]
          }
          if (nameA === null) {
            nameA = ' '
          }
          if (nameB === null) {
            nameB = ' '
          }
          if ( nameA < nameB ){
            return -1
          }
          if ( nameA > nameB ){
            return 1
          }
          return 0
        })
      }
      this.setState({typeOrder: 'desc'})
      this.setState({columnId: columnId})
    }
    this.setState({idCol: idCol})

    if (this.props.onSort) {
      this.props.onSort(rowsOrdenado)
      if (id === 'planingTable1') {
        this.props.actions.fetchPlaningSuccess(rowsOrdenado)
        this.props.actions.fetchOrderPlaning(!this.props.planing.order)
      }
    } else {
      if (id === 'planingTable1') {
        this.props.actions.fetchPlaningSuccess(rowsOrdenado)
        this.props.actions.fetchOrderPlaning(!this.props.planing.order)
      }
      this.setState({rowsOrdenado: rowsOrdenado})
    }
  }

  seleccionFilaSugerencias (id, row) {
    this.setState({filaSelccionada: id})
    var elems = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-planing")
    })

    document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    this.props.actions.obtenerDatosVerracoPlaning(row)

    var elems2 = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function(el) {
       el.classList.remove("fila-seleccionada-planing")
    })
  }

  seleccionFilaSugerenciasQuitar (id, row) {
    this.setState({filaSelccionada: id})
    var elems = document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-planing")
    })

    document.getElementById('tableVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-planing")
    this.props.actions.obtenerDatosVerracoPlaning(row)

    var elems2 = document.getElementById('tableListadoVerracoPlaning').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems2, function(el) {
       el.classList.remove("fila-seleccionada-planing")
    })
  }

  seleccionFilaInformes (id, row) {
    this.setState({filaSelccionada: id})
    var elems = document.getElementById('informeClientesIzqTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-informes")
    })

    document.getElementById('informeClientesIzqTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
    this.props.actions.obtenerInformeCliente({idClienteAlbaran: {value: row.idDireccionCliente, seleccionFila: true}})
  }

  seleccionarSeguimientoExtracciones (id, row) {
    const values = []
    if (row.idAnalisis) {
      values.idAnalisis = row.idAnalisis
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    } else if (row.idPool) {
      values.idPool = row.idPool
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    }

    this.setState({filaSelccionada: id})
    var elems = document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-informes");
    })
    document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
  }

  seleccionarSeguimientoPool (id, row) {
    const values = []
    if (row.idAnalisis) {
      values.idAnalisis = row.idAnalisis
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    } else if (row.idPool) {
      values.idPool = row.idPool
      values.idTipo = row.extraccionPool
      this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
    }

    this.setState({filaSelccionada: id})
    var elems = document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    [].forEach.call(elems, function(el) {
       el.classList.remove("fila-seleccionada-informes");
    })
    document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[id].classList.add("fila-seleccionada-informes")
  }

  render () {
    const {
        t, id, columns = [], rows = [], multiHeader = false, multiHeaderChild = false, hasCheckbox = false, hasOptions = false, hasPagination = true, filtros, esEspecfica, leyenda, mostrarImprimirInforme,
        onEdit, data, duplicar, dataDuplicar, cambiarEstado, dataEstado, onDelete, dataDelete, className = 'simple-table', textAlignLeft, imprimirInforme, rutaPorDefecto,
        mostrarCambioEstado, vigilado, cambiarVigilado, rowsVerraco, mostrarModificar, mostrarDuplicar, mostrarEliminar, mostrarVerDetalle, verDetalle, vehiculoPorDefecto,
        mostrarCambiarEyaculadoEliminado, cambiarEyaculadoEliminado, cambiarOperarioProcesado, cambiarOperarioAlbaran,operarioProcesado, operarioAlbaran, mostrarSubirBajar,
        conductorPorDefecto, cambiarPorDefecto, colorearRow, subirOrden, bajarOrden, mostrarPredeterminado, mostrarEnvasar, envasar, mostrarPaginado, paginar, classTableAlto, esPlaning, planingSeleccionarfila,
        idElemento = 'idElemento', form, esFoso, mostrarIndicadorColor, seleccionarFilaSugerencias, seleccionarFilaSugerenciasQuitar, paginarConScroll, cambiarPassword, mostrarCambiarPassword, seleccionFilaInformes,
        informeSeguimientoExtracciones, informeSeguimientoPool, copiaSeguridad, descargarCopia, restaurarCopia, activarDesactivar,
        auth: {formaFechaHora, separadorDec, separadorMil},
        actions: {anadirFechaPlaning, eliminarFechaPlaning},
      } = this.props

    let fechaHoy = date_formatter(new Date(), formaFechaHora, false)

    const indexOfLastTodo = paginar !== false && paginarConScroll !== true ? this.state.activePage * this.props.numeroRegistrosPorPagina : this.state.activePage * this.state.rowsOrdenado.length;
    const indexOfFirstTodo = paginar !== false && paginarConScroll !== true ? indexOfLastTodo - this.props.numeroRegistrosPorPagina : indexOfLastTodo - this.state.rowsOrdenado.length;
    const currentTodos = this.state.rowsOrdenado.slice(indexOfFirstTodo, indexOfLastTodo);
    const totalPages = this.state.rowsOrdenado.length / this.props.numeroRegistrosPorPagina
    const t_key = 'COMUN.'
    const comboTotalPerPage = [
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 }
    ]

    return (
      <div className={'table-container ' + className}>
        {
          filtros
        }
        <SimpleLeyenda items={leyenda} />

        <div id={id} className={paginar !== false ? 'table-scroll-container' : 'table-scroll-container ' + classTableAlto} onMouseOver={paginar !== false ? null : (e) => this.listenScrollEvent(e)}>
          <BootstrapTable striped condensed hover id={id} className="simple-table">
            <thead>
              {columns.length > 0 && (() => {
                let th1 = []
                let th2 = []
                let th3 = []
                let contChildren = 0
                let checkbox = hasCheckbox ? <th key="box" className="checkbox-col"></th> : colorearRow && mostrarIndicadorColor !== false ? <th key="box" className="checkbox-col"></th> : null
                let options = hasOptions && <th key="options" className="options-col"></th>

                th1 = columns.map((column, idCol) => {
                  if (multiHeader) {
                    if (column.children) {
                      column.children.forEach((child, idChild) => {
                        if (column.children[idChild].childrenChild){
                          contChildren = column.children[idChild].childrenChild.length
                        }
                        th2.push(<th colSpan={multiHeaderChild ? contChildren : 1} className={!planingSeleccionarfila ? (child.borderDobleHeadeClass === true ? 'borderDobleHeadeClass ordenable' : 'ordenable') : null} key={idCol + '.' + idChild} onClick={() => this.sortTable(column.id, column.type, idCol + '.' + idChild, child.id, null)}>{child.name} {!planingSeleccionarfila && this.state.idCol === idCol + '.' + idChild ? (this.state.typeOrder === 'asc' ? <div className="font-gesipor-asc"></div> : <div className="font-gesipor-desc"></div>) : null}</th>)
                      })
                    } else {
                      th2.push(<th key={idCol}></th>)
                    }
                    if (multiHeaderChild) {
                      if (column.children) {
                        column.children.forEach((child, idChild) => {
                          if (column.children[idChild].childrenChild) {
                            column.children[idChild].childrenChild.forEach((chi, idChi) => {
                              th3.push(<th className={!planingSeleccionarfila ? (child.borderDobleHeadeClass === true ? 'borderDobleHeadeClass ordenable' : 'ordenable') : null} key={idChild + '-' + idChi} onClick={() => this.sortTable(column.id, column.type, idCol + '.' + chi, chi.id, null)}>{chi.name} {!planingSeleccionarfila && this.state.idCol === idCol + '.' + idChi ? (this.state.typeOrder === 'asc' ? <div className="font-gesipor-asc"></div> : <div className="font-gesipor-desc"></div>) : null}</th>)
                            })
                          }
                        })
                      } else {
                        th3.push(<th key={idCol}></th>)
                      }
                    }
                  }
                  return <th className={!planingSeleccionarfila ? (column.borderDobleHeadeClass === true ? 'borderDobleHeadeClass ordenable' : 'ordenable') : null} onClick={() => this.sortTable(column.id, column.type, idCol, null, id)} key={idCol} colSpan={multiHeader && column.children ? (multiHeaderChild ? column.children.length * contChildren : column.children.length) : 1} style={planingSeleccionarfila && {width: column.anchoColum}}><span>{column.name} {!planingSeleccionarfila && this.state.idCol === idCol ? (this.state.typeOrder === 'asc' ? <div className="font-gesipor-asc"></div> : <div className="font-gesipor-desc"></div>) : null}</span></th>
                })

                th1 = <tr key="th1">{[checkbox, th1, options]}</tr>
                if (multiHeader && !multiHeaderChild) {
                  th2 = <tr key="th2">{[checkbox, th2, options]}</tr>
                }
                if (multiHeaderChild) {
                  th2 = <tr key="th2">{[checkbox, th2, options]}</tr>
                  th3 = <tr key="th3">{[checkbox, th3, options]}</tr>
                }
                return [th1, th2, th3]
              })()}
            </thead>
            <tbody>
              {!currentTodos || !currentTodos.length || currentTodos.length === 0 ? (
                <tr><td colSpan={columns.length + (hasCheckbox ? 1 : 0) + (hasOptions ? 1 : 0)}>{t(`${t_key}SIN_RESULTADOS`)}</td></tr>
              ) : null}
              {currentTodos.map((row, idRow) =>

                <tr
                  key={idRow}
                  onDoubleClick={() => mostrarModificar !== false ? onEdit(row, data) : null}
                  className={informeSeguimientoExtracciones === true && idRow === 0 ? 'fila-seleccionada-informes' : null}
                  onClick={() => planingSeleccionarfila === true ? this.anadirClasePlaning(idRow) : (seleccionarFilaSugerencias === true ? this.seleccionFilaSugerencias(idRow, row) : (seleccionarFilaSugerenciasQuitar === true ? this.seleccionFilaSugerenciasQuitar(idRow, row) : ( seleccionFilaInformes === true ? this.seleccionFilaInformes(idRow, row) : null)))}
                >
                  {columns.length > 0 && (() => {
                    let tr = []
                    if (hasCheckbox) {
                      tr.push(<td key="checkbox" className="checkbox-col">
                        {
                          mostrarIndicadorColor !== false ? (
                            <div className={colorearRow && colorearRow(row) + ' barra-color'}></div>
                          ) : null
                        }
                        <Field
                          id={`check` + (form !== 'simpleTable' ? form : '') + row[idElemento]}
                          name={`check` + (form !== 'simpleTable' ? form : '') + row[idElemento]}
                          colSm={12}
                          inline
                          component={InputCheckBox}
                          onInputChange={(val) => this.onCheckboxChange(val)}
                        />
                      </td>)
                    } else {
                      if (colorearRow  && mostrarIndicadorColor !== false) {
                        tr.push(<td key="checkbox" className="checkbox-col">
                        <div className={colorearRow(row) + ' barra-color'}></div>
                        </td>)
                      }
                    }
                    columns.forEach((column, idCol) => {
                      if (multiHeader && column.children && !multiHeaderChild) {
                        column.children.forEach((child, idChild) => {
                          tr.push(
                            row.activo === false && esEspecfica !== false ? (
                              <td style={{color: '#c1c1c1', textDecoration: 'line-through'}} key={idCol + '.' + idChild}>
                              {(row[column.id] ? row[column.id][child.id] : null)}
                              </td>
                            ) :
                              <td key={idCol + '.' + idChild}>{(row[column.id] ? 
                                ( child.type === 'numero' && (row[column.id]) ? number_formatter(row[column.id][child.id], (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil) : row[column.id][child.id])
                              : null)}</td>
                            )
                        })
                      }
                        else if (multiHeaderChild && column.children) {
                        column.children.forEach((child, idChild) => {
                          column.children[idChild].childrenChild.forEach((chi, idChi) => {
                          tr.push(
                            row.activo === false && esEspecfica !== false ? (
                              <td style={{color: '#c1c1c1', textDecoration: 'line-through'}} key={idChild + '.' + idChi}>
                              {(row[column.id] ? row[column.id][chi.id] : null)}
                              </td>
                            ) :
                              <td key={idChild + '.' + idChi}>{
                                number_formatter(row.fecha[idChild][column.children[idChild].childrenChild[idChi].id], (child.numDecimales || child.numDecimales === 0) ? child.numDecimales : 2, separadorDec, separadorMil)
                              }</td>
                            )
                          })
                        })
                      } else {
                        let classes = column.classes || ''
                        if (column.textAlignLeft) {
                          classes += ' text-left'
                        } else {
                          classes += ' text-center'
                        }
                        tr.push(<td style={planingSeleccionarfila && {width: column.anchoColum}}
                          onDoubleClick={() => esPlaning === true ? (row[column.id] === '[[_#rff0000_]]' || row[column.id] === '[[_#b0000ff_]]' ? eliminarFechaPlaning(row.idVerraco, column.name) : anadirFechaPlaning(row.idVerraco, column.name)) : null}
                          key={idCol}
                          className={row[column.id] === '[[_#rff0000_]]' ?
                          ('planingRojo ') :
                            (row[column.id] === '[[_#b0000ff_]]' ? (
                              'planingAzul ') :
                              (column.name === fechaHoy && esFoso !== true ? (
                                'planingAmarillo ') :
                                (row[column.id] === '[[_#hffff00_]]' && esFoso === true ? (
                                  'fosoHoy ') :
                                  (esFoso === true && !isNaN(row[column.id]) ?
                                    ('fosoVerde') :
                                    classes
                                    )
                                  )
                                )
                              )
                            }
                          onClick={() => esFoso === true ? this.anadirClaseFoso(idRow, idCol, row, row[column.id], column.name) : (informeSeguimientoExtracciones === true ? this.seleccionarSeguimientoExtracciones(idRow, row) : (informeSeguimientoPool === true ? this.seleccionarSeguimientoPool(idRow, row) : null))}>
                        {
                          row[column.id] === true ?
                            <CheckBox/> :
                            (
                              column.stilyzer ?
                              column.stilyzer({...row, valorFormateado: (
                                column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                column.type === 'numero' && (row[column.id]) ? number_formatter(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                row[column.id]
                              ) }) :
                              row.activo === false && esEspecfica !== false ? (
                                <span style={{color: '#c1c1c1', textDecoration: 'line-through'}}>
                                {
                                  column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                  column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                  column.type === 'numero' && (row[column.id]) ? number_formatter(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                    row[column.id]
                                }
                                </span>
                                ) : (
                                  column.type === 'fecha' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, false) :
                                  column.type === 'fechaHora' && (row[column.id]) ? date_formatter(row[column.id], formaFechaHora, true) :
                                  column.type === 'numero' && (row[column.id]) ? number_formatter(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil) :
                                  (
                                    column.mostrarVerracosPopover === true ? (
                                      <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                          <Popover id={row[column.id]} className="popover-simple-table">
                                              {
                                                row.verracos.map((verraco, id) => <p key={id}>{verraco.codVerraco}</p>)
                                              }
                                          </Popover>
                                      )}>
                                        <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                            <div style={{cursor: 'pointer'}}>{row[column.id]}</div>
                                        </div>
                                      </OverlayTrigger>
                                    ) : column.mostrarPoolsPopover === true ? (
                                      <OverlayTrigger trigger={["click"]} placement="bottom" rootClose overlay={(
                                        <Popover id={row[column.id]} className="popover-simple-table">
                                          {
                                            row.verracosPool.map((verracoPool, id) => <p key={id}>{verracoPool.codigo + ' - ' + verracoPool.codVerraco}</p>)
                                          }
                                        </Popover>
                                      )}>
                                        <div onMouseOver={this.showOverlay} onMouseOut={this.hideOverlay}>
                                          <div style={{cursor: 'pointer'}}>{row[column.id]}</div>
                                        </div>
                                      </OverlayTrigger>
                                    ) : row[column.id] !== '[[_#rff0000_]]' && row[column.id] !== '[[_#b0000ff_]]' && row[column.id] !== '[[_#hffff00_]]' && row[column.name] !== fechaHoy && !(esFoso === true && !isNaN(row[column.id])) ? (
                                       column.type === 'numeroPlaning' && row[column.id] !== '' && row[column.id] !== undefined && row[column.id] !== null ? (number_formatter(row[column.id], (column.numDecimales || column.numDecimales === 0) ? column.numDecimales : 2, separadorDec, separadorMil)) : (row[column.id])
                                    ) : null
                                  )
                                )
                            )
                        }</td>)
                      }
                    })

                    if (hasOptions) {
                      let hasSeparator = false
                      tr.push(<td key="options" className="options-col" onClick={(e) => e.stopPropagation()}>
                        <Dropdown pullRight id={`${id}.${idRow}.options`}>
                          <Dropdown.Toggle noCaret>
                            <MoreVert/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              mostrarModificar !== false ? hasSeparator = true && (
                                <MenuItem eventKey="1" onClick={() => onEdit(row, data)}><Edit/><span>{t(`${t_key}MODIFICAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarDuplicar !== false ? hasSeparator = true && (
                                <MenuItem eventKey="2" onClick={() => duplicar(row, dataDuplicar)}><FileCopy/><span>{t(`${t_key}DUPLICAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambioEstado !== false ? hasSeparator = true && (
                              <MenuItem eventKey="3" onClick={() => cambiarEstado(row, dataEstado)}><Refresh/><span>{activarDesactivar === true ? t(`${t_key}ACTIVAR_DESACTIVAR`) : t(`${t_key}CAMBIO_ESTADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambiarEyaculadoEliminado ? hasSeparator = true && (
                                <MenuItem eventKey="5" onClick={() => cambiarEyaculadoEliminado(row, dataEstado)}><Opacity/><span>{t(`${t_key}CAMBIO_EYACULADO_ELIMINADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              vigilado === true ? hasSeparator = true && (
                              <MenuItem eventKey="6" onClick={() => cambiarVigilado(row, dataEstado)}><Refresh/><span>{t(`${t_key}CAMBIO_VIGILADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              operarioProcesado === true ? hasSeparator = true && (
                              <MenuItem eventKey="7" onClick={() => cambiarOperarioProcesado(row, dataEstado)}><Refresh/><span>{t(`${t_key}OPERARIO_PROCESADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              operarioAlbaran === true ? hasSeparator = true && (
                              <MenuItem eventKey="8" onClick={() => cambiarOperarioAlbaran(row, dataEstado)}><Refresh/><span>{t(`${t_key}OPERARIO_ALBARAN`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              verDetalle ? hasSeparator = true && (
                                <MenuItem eventKey="9" onClick={() => verDetalle(row, dataEstado)}><RemoveRedEye/><span>{t(`${t_key}VER_DETALLE`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarSubirBajar === true ? hasSeparator = true && (
                                <MenuItem eventKey="10" onClick={() => subirOrden(row, dataEstado)}><ArrowUpward/><span>{t(`${t_key}SUBIR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarSubirBajar === true ? hasSeparator = true && (
                                <MenuItem eventKey="11" onClick={() => bajarOrden(row, dataEstado)}><ArrowDownward/><span>{t(`${t_key}BAJAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarImprimirInforme === true ? hasSeparator = true && (
                                <MenuItem eventKey="12" onClick={() => imprimirInforme(row, dataEstado)}><Print/><span>{t(`${t_key}IMPRIMIR_INFORME`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              conductorPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="13" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere/><span>{t(`${t_key}CONDUCTOR_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              vehiculoPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="14" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere/><span>{t(`${t_key}VEHICULO_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              rutaPorDefecto === true ? hasSeparator = true && (
                                <MenuItem eventKey="15" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere/><span>{t(`${t_key}RUTA_POR_DEFECTO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarPredeterminado === true ? hasSeparator = true && (
                                <MenuItem eventKey="16" onClick={() => cambiarPorDefecto(row, dataEstado)}><Beenhere/><span>{t(`${t_key}PREDETERMINADO`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarEnvasar === true ? hasSeparator = true && (
                                <MenuItem eventKey="16" onClick={() => envasar(row)}><Beenhere/><span>{t(`${t_key}ENVASAR`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              mostrarCambiarPassword === true ? (
                                <MenuItem eventKey="17" onClick={() => cambiarPassword(row)}><VpnKey/><span>{t(`${t_key}CAMBIAR_PASSWORD`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              copiaSeguridad === true  ? (
                                <MenuItem eventKey="18" onClick={() => descargarCopia(row)}><CloudDownload/><span>{t(`${t_key}DESCARGAR_COPIA`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              copiaSeguridad === true  ? (
                                <MenuItem eventKey="19" onClick={() => restaurarCopia(row)}><Replay/><span>{t(`${t_key}RESTAURAR_COPIA`)}</span></MenuItem>
                              ) : null
                            }
                            {
                              hasSeparator && mostrarEliminar && (<MenuItem divider />)
                            }
                            {
                              mostrarEliminar !== false ? (
                                <MenuItem eventKey="4" onClick={() => onDelete(row, dataDelete)}><Delete/><span>{t(`${t_key}ELIMINAR`)}</span></MenuItem>
                              ) : null
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>)
                    }
                    return tr
                  })()}
                </tr>
              )}
            </tbody>
          </BootstrapTable>
        </div>
        {hasPagination && (
          <Row className="content-pagination row-flex">
            <div className="col-auto">
              <Row className="row-flex">
                <span className="pagination-info col-auto">
                  {t(`${t_key}MOSTRANDO_DEL`)}{rows.length > 0 ? (this.props.numeroRegistrosPorPagina * (this.state.activePage - 1) + 1) : 0} {t(`${t_key}AL`)} {this.props.numeroRegistrosPorPagina * this.state.activePage <= rows.length ? this.props.numeroRegistrosPorPagina * this.state.activePage : rows.length}
                  <span>{t(`${t_key}DE`)}</span> {rows.length}{t(`${t_key}REGISTROS`)}
                </span>
                <Field
                  colSm={0}
                  id="candidadRegistros"
                  name="candidadRegistros"
                  controlLabel={t('COMUN.NUMERO_REGISTROS')}
                  placeholder={this.props.numeroRegistrosPorPagina}
                  component={InputSelect}
                  options={comboTotalPerPage}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-total-registros col-auto'
                  onChange={(event, newValue) => this.cambiarTotalRegistro(event, newValue)}
                  menuPlacement="auto"
                />
              </Row>
            </div>
            <Pagination
              customClass="col-auto"
              activePage={this.state.activePage}
              itemsCountPerPage={1}
              totalItemsCount={totalPages}
              pageRangeDisplayed={1}
              onChange={this.handlePageChange.bind(this)}
              items={currentTodos}
              hideDisabled
            />
          </Row>
        )}
      </div>
    )
  }
}

SimpleTableNewChildren.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
}

export default (reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SimpleTableNewChildren))

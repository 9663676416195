import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { DoneAll, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class FiltrosMovimientoVerraco extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  componentDidMount () {
    this.props.actions.comboUbicacionPadre()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
  }

  componentDidUpdate (prevProps) {
    if (this.props.cambioEstadoVerraco.datosExcelCambioEstado !== prevProps.cambioEstadoVerraco.datosExcelCambioEstado) {
      if (this.props.cambioEstadoVerraco.datosExcelCambioEstado.excel && this.props.cambioEstadoVerraco.datosExcelCambioEstado.excel !== null){
        let pdf = this.props.cambioEstadoVerraco.datosExcelCambioEstado.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.VERRACOS.CAMBIOS_ESTADO_VERRACOS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.cambioEstadoVerraco.datosPdfCambioEstado !== prevProps.cambioEstadoVerraco.datosPdfCambioEstado) {
      if (this.props.cambioEstadoVerraco.datosPdfCambioEstado.pdf && this.props.cambioEstadoVerraco.datosPdfCambioEstado.pdf !== null){
        let pdf = this.props.cambioEstadoVerraco.datosPdfCambioEstado.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.VERRACOS.CAMBIOS_ESTADO_VERRACOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosCambioEstadoVerraco()
          }
        }
      }
    }
  }

  fetchFiltrarCambioEstadoVerraco (values) {
    this.props.actions.fetchFiltrarCambioEstadoVerraco(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
    this.props.actions.comboUbicacionPadre()
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosCambioEstadoVerraco()
    } else {
      this.props.actions.deSeleccionarTodosCambioEstadoVerraco()
    }
  }

  deleteSeleccionCambioEstadoVerraco(){
    const itemsSeleccion = this.props.cambioEstadoVerraco.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCambioEstadoVerraco': row.idCambioEstadoVerraco
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionCambioEstadoVerraco(list)
  }

  crearCsvCambioEstado(){
    this.props.actions.crearCsvCambioEstado(this.props.cambioEstadoVerraco.filtros)
  }

  imprimirPdfCambioEstado(){
    this.props.actions.imprimirPdfCambioEstado(this.props.cambioEstadoVerraco.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.cambioEstadoVerraco.filtros
    values[value] = null
    if (value === 'fechaEntrada') {
      values.fechaDesde = null
    }
    if (value === 'fechaSalida') {
      values.fechaHasta = null
    }
    this.props.actions.fetchFiltrarCambioEstadoVerraco(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit,
    actions: {abrirModalVacioCambioEstado},
    combos: {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboVerraco},
    cambioEstadoVerraco: {filtros}
  } = this.props

  let comboVigenteActual = this.props.combos.comboVigenteActual
  comboVigenteActual = comboVigenteActual.map((actual) => {
    return {...actual, label: t(actual.label)}
  })

  let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

    let tKey = 'CAMBIO_ESTADO_VERRACOS.'

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="estadoActual"
              name="estadoActual"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.ESTADO_ACTUAL')}
              component={InputSelect}
              options={comboEstadoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-raza'
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="linea"
              name="linea"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-lin-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="ubicacion"
                name="ubicacion"
                controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.UBICACION')}
                component={InputSelect}
                options={comboUbicacionPadre}
                valueKey="value"
                labelKey="label"
                customClass='select-ubicacion'
                onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="actual"
              name="actual"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.ACTUAL')}
              component={InputSelect}
              options={comboVigenteActual}
              valueKey="value"
              labelKey="label"
              customClass='select-destino'
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.DESDE_HASTA')}
              onInputChange={handleSubmit(this.fetchFiltrarCambioEstadoVerraco.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estadoActual && filtros.estadoActual !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ESTADO_ACTUAL') + ': ' + filtros.estadoActual.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estadoActual')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.verraco && filtros.verraco !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.raza && filtros.raza !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.linea && filtros.linea !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.LIN_GENETICA') + ': ' + filtros.linea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('linea')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtros).length > 0 && (
                    filtros.ubicacion && filtros.ubicacion !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.UBICACION') + ': ' + filtros.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtros).length > 0 && (
                    filtros.actual && filtros.actual !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ACTUAL') + ': ' + filtros.actual.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('actual')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionCambioEstadoVerraco()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvCambioEstado()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfCambioEstado()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1) ? abrirModalVacioCambioEstado : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosMovimientoVerraco',
})(FiltrosMovimientoVerraco))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {fetchDashboardProduccion} from '../../actions/dashboard/dashboard'
import DashboardProduccion from '../../components/dashboard/DashboardProduccion' 

const mapStateToProps = (state) => {
  return {
    ...state.dashboard,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({      
      fetchDashboardProduccion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardProduccion))
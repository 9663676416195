import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAnalisisExtracciones from '../../components/analisisExtracciones/FiltrosAnalisisExtracciones'
import {
  fetchAnalisisExtracciones, seleccionarTodosAnalisisExtracciones, deseleccionarTodosAnalisisExtracciones,
  cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones, cambiarVigiladoSeleccionAnalisisExtracciones, deleteSeleccionAnalisisExtracciones,
  crearCsvAnalisisExtracciones, imprimirPdfAnalisisExtracciones, calcularDiasDescanso, sincronizarPendientesDataSwine
} from '../../actions/analisisExtracciones/analisisExtracciones'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, fetchComboPool, comboClienteConVerracosEnPropiedad, comboLineaGenetica, comboNivelGenetico} from '../../actions/combos/combos'
import { comprobarEntornoLocal } from '../../actions/auth/auth'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    analisisExtracciones: state.analisisExtracciones,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerraco: state.combos.comboVerraco,
      comboPool: state.combos.comboPool,
      comboNombreGrupoVerraco: state.combos.comboNombreGrupoVerraco,
      comboRaza: state.combos.comboRaza,
      comboClienteConVerracosEnPropiedad: state.combos.comboClienteConVerracosEnPropiedad,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboNivelGenetico: state.combos.comboNivelGenetico
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAnalisisExtracciones,
      seleccionarTodosAnalisisExtracciones,
      deseleccionarTodosAnalisisExtracciones,
      cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones,
      cambiarVigiladoSeleccionAnalisisExtracciones,
      deleteSeleccionAnalisisExtracciones,
      comboVerraco,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboRaza,
      comboClienteConVerracosEnPropiedad,
      crearCsvAnalisisExtracciones,
      imprimirPdfAnalisisExtracciones,
      calcularDiasDescanso,
      sincronizarPendientesDataSwine,
      comboLineaGenetica,
      comboNivelGenetico,
      comprobarEntornoLocal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAnalisisExtracciones))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputText from '../comun/form/InputText'
import { AddCircleOutline, FilterList, Delete } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'

class FiltrosAsignacionPedidos extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
    this.fetchLineasAlbaranes = this.fetchLineasAlbaranes.bind(this)
    this.obtenerInformeStockDosis = this.obtenerInformeStockDosis.bind(this)
    this.fetchLineasAlbaranesStockDosis = this.fetchLineasAlbaranesStockDosis.bind(this)
  }

  componentDidMount () {
    //this.props.actions.comboNumerosAlbaran()
    this.props.actions.comboRuta()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboLineaGenetica()
    //this.props.actions.comboCliente()
    this.props.actions.comboRaza();
    this.props.actions.comboNivelGenetico()

    let filtros = {};
    // si no hay ningun filtro, ponemos por defecto el de fecha
    if (!this.props.asignacionPedidos.filtros || Object.keys(this.props.asignacionPedidos.filtros).length === 0) {
      let diasHastaExtraccion = 1
      filtros = { desde: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora), estado:{value:1,label:"Borrador"}, diasHastaExtraccion: diasHastaExtraccion, fecha: this.parseFechafromDiasHastaExtraccion(diasHastaExtraccion) }
    } else {
      filtros = this.props.asignacionPedidos.filtros
    }

    this.props.initialize(filtros)
    this.fetchLineasAlbaranes(filtros)
    this.obtenerInformeStockDosis(filtros)
  }

  fetchLineasAlbaranes(values) {
    this.props.actions.fetchLineasAlbaranes(values)
  }

  obtenerInformeStockDosis(values) {
    values['lineaGenetica'] = values.linea
    values['fecha'] = this.parseFechafromDiasHastaExtraccion(values.diasHastaExtraccion)
    this.props.actions.obtenerInformeStockDosis(values)
  }

  fetchLineasAlbaranesStockDosis(values){
    this.fetchLineasAlbaranes(values)
    this.obtenerInformeStockDosis(values)
  }

  parseFechafromDiasHastaExtraccion(diasHastaExtraccion){
    var fecha = new Date()
    let dias = (diasHastaExtraccion == null || diasHastaExtraccion == '') ? 365 : diasHastaExtraccion //por defecto
    fecha.setDate(fecha.getDate() - dias)
    return fecha
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }
 
  quitarFiltros (value) {
    let values = this.props.asignacionPedidos.filtros
    values[value] = null
    this.fetchLineasAlbaranesStockDosis(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  deleteSeleccionDosisEnvasadas(){      
    const itemsSeleccion = this.props.listDosis.map((row) => {     
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento],
          'numeroDosisDisponibles': row['numeroDosisDisponibles'],
        }
      )
    })       
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });      
    this.props.actions.deleteSeleccionDosisEnvasadas(list)
  }

  render () {
    const {
      t, handleSubmit, auth: { permisos }, asignacionPedidos: {filtros = {}},  
      combos: {
        comboNumerosAlbaran, comboCliente, comboRuta, comboTipoDosis, comboRaza, comboLineaGenetica, comboNivelGenetico
      }
    } = this.props
    const tKey = 'ALBARANES.'

    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            {/*<Field
              colSm={2}
              id="numeroAlbaran"
              name="numeroAlbaran"
              controlLabel={t(tKey + 'FILTROS.NUM_ALBARAN')}
              component={InputSelect}
              options={comboNumerosAlbaran}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchLineasAlbaranes)}
            />*/}
            {/* <Field
              colSm={2}
              id="cliente"
              name="cliente"
              controlLabel={t('RUTA.FILTROS.CLIENTE')}
              component={InputSelect}
              options={comboCliente}
              valueKey="value"
              labelKey="label"
              customClass='select-cliente'
              //onInputChange={handleSubmit(this.fetchLineasAlbaranes)}
            />    */}
            <Field
              colSm={2}
              id="nombreDireccion"
              name="nombreDireccion"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchLineasAlbaranes)}
            />
            <Field
              colSm={2}
              id="ruta"
              name="ruta"
              controlLabel={t(tKey + 'FILTROS.RUTA')}
              component={InputSelect}
              options={comboRuta}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchLineasAlbaranes)}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('FICHA_VERRACO.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-raza'
              onInputChange={handleSubmit(this.fetchLineasAlbaranesStockDosis)}
            />
            <Field
              colSm={2}
              id="linea"
              name="linea"
              controlLabel={t('FICHA_VERRACO.FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-linea'
              onInputChange={handleSubmit(this.fetchLineasAlbaranesStockDosis)}
            />
            <Field
                colSm={2}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('PLANING.FILTROS.NIVEL_GENETICO')}
                component={InputSelect}
                options={comboNivelGenetico}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchLineasAlbaranesStockDosis)}
              />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t(tKey + 'FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchLineasAlbaranesStockDosis)}
              noPredeterminado={false}
            />
            <Field
              colSm={2}
              id="diasHastaExtraccion"
              name="diasHastaExtraccion"
              controlLabel={t('INFORMES.PERSONALIZADO.PLANNING.DIAS_DESDE_ULTIMA_EXTRACCION')}              
              component={InputText}
              onInputChange={handleSubmit(this.fetchLineasAlbaranesStockDosis)}
            />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchLineasAlbaranes)}
            />           
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.nombreDireccion && <span key="filtros.nombreDireccion"  className="badge-dark">{t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + ': ' + filtros.nombreDireccion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreDireccion')}> x </button></span>),
                    (filtros.ruta && <span key="filtros.ruta"  className="badge-dark">{t(tKey + 'FILTROS.RUTA') + ': ' + filtros.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza"  className="badge-dark">{t('POOL_EXTRACCIONES.FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>),
                    (filtros.linea && <span key="filtros.LineaGenetica"  className="badge-dark">{t('POOL_EXTRACCIONES.FILTROS.LIN_GENETICA') + ': ' + filtros.linea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('linea')}> x </button></span>),
                    (filtros.nivelGenetico && <span key="filtros.nivelGenetico"  className="badge-dark">{t(tKey + 'FILTROS.NIVEL_GENETICO') + ': ' + filtros.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>),
                    (filtros.tipoDosis && <span key="filtros.tipoDosis"  className="badge-dark">{t(tKey + 'FILTROS.TIPO_DOSIS') + ': ' + filtros.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>),
                    (filtros.diasHastaExtraccion && <span key="filtros.diasHastaExtraccion"  className="badge-dark">{t('INFORMES.PERSONALIZADO.PLANNING.DIAS_DESDE_ULTIMA_EXTRACCION') + ': ' + filtros.diasHastaExtraccion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('diasHastaExtraccion')}> x </button></span>),
                    (filtros.desde && filtros.hasta && filtros.desde !== null && filtros.hasta !== null) ? (
                      <span key="filtros.desdeHasta"  className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtros.desde && filtros.desde !== null) ? (
                      <span key="filtros.desde"  className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtros.hasta && filtros.hasta !== null) && (
                      <span key="filtros.hasta"  className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    )
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                {/*(permisos === '-1' || permisos[permisosConstant.POOL] > 1) ? (
                  <Link to="/NuevoPoolExtraccion" className="btn btn-primary nuevo" style={{padding: '1px 6px', verticalAlign: 'inherit'}}>
                    <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary nuevo disabled"
                    disabled={true}
                  >{t(tKey + 'FILTROS.NUEVO')}</button>
                )*/}
              </div>
              <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>                
                <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionDosisEnvasadas()}>
                  <Delete></Delete> {t(tKey + 'FILTROS.DESCARTAR')}
                </button>
                <div className="barra-buttons-min"></div>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosAsignacionPedidos',
  initialValues: {
    desde: new Date()
  }
})(FiltrosAsignacionPedidos))

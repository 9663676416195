import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosConectividad extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarConectividad(values){
    this.props.actions.fetchFiltrarConectividad(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosConectividad()
    }
    if (this.props.datosExcelConectividad !== prevProps.datosExcelConectividad) {
      if (this.props.datosExcelConectividad.excel && this.props.datosExcelConectividad.excel !== null){
        let pdf = this.props.datosExcelConectividad.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.HERRAMIENTAS.CONECTIVIDAD') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfConectividad !== prevProps.datosPdfConectividad) {
      if (this.props.datosPdfConectividad.pdf && this.props.datosPdfConectividad.pdf !== null){
        let pdf = this.props.datosPdfConectividad.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.HERRAMIENTAS.CONECTIVIDAD') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosConectividad()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosConectividad()
    } else {
      this.props.actions.deSeleccionarTodosConectividad()
    }
  }

  cambiarEstadoSeleccionConectividad(){
    const itemsSeleccion = this.props.listConectividad.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idMaquina': row.idMaquina
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionConectividad(list)
  }

  deleteSeleccionConectividad(){
    const itemsSeleccion = this.props.listConectividad.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idMaquina': row.idMaquina
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionConectividad(list)
  }

  crearCsvConectividad(){
    this.props.actions.crearCsvConectividad(this.props.filtros)
  }

  imprimirPdfConectividad(){
    this.props.actions.imprimirPdfConectividad(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarConectividad(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVacioConectividad,
    }
  } = this.props

  let comboTipoPerifericoConectividad = this.props.combos.comboTipoPerifericoConectividad
  comboTipoPerifericoConectividad = comboTipoPerifericoConectividad.map((tipoPeriferico) => {
    return {...tipoPeriferico, label: t(tipoPeriferico.label)}
  })

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="tipoMaquina"
              name="tipoMaquina"
              controlLabel={t('CONECTIVIDAD.FILTROS.TIPO_PERIFERICO')}
              component={InputSelect}
              options={comboTipoPerifericoConectividad}
              valueKey="value"
              labelKey="label"
              customClass='nombre-conectividad'
              onInputChange={handleSubmit(this.fetchFiltrarConectividad.bind(this))}
            />
            <Field
              colSm={2}
              id="activo"
              name="activo"
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              controlLabel={t('CONECTIVIDAD.FILTROS.ACTIVO')}
              claseActivo="localidad-conectividad"
              onInputChange={handleSubmit(this.fetchFiltrarConectividad.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.tipoMaquina && filtros.tipoMaquina !== null ? (
                      <span className="badge-dark">{t('CONECTIVIDAD.FILTROS.TIPO_PERIFERICO') + ': ' + filtros.tipoMaquina.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoMaquina')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo && filtros.activo !== null ? (
                      <span className="badge-dark">{t('CONECTIVIDAD.FILTROS.ACTIVO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionConectividad()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionConectividad()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvConectividad()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfConectividad()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) ? abrirModalVacioConectividad : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosConectividad',
})(FiltrosConectividad))

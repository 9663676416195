import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Operario from '../../components/operario/Operario'
import {fetchOperario, recuperarDatosModalOperario, duplicarDatosModalOperario, cambiarEstadoOperario, onDeleteOperario, cambiarOperarioProcesado, cambiarOperarioAlbaran,
  fetchFiltrarOperario} from '../../actions/operario/operario'

export function mapStateToProps (state) {
  return {
    operario: {...state.operario},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchOperario,
      recuperarDatosModalOperario,
      duplicarDatosModalOperario,
      cambiarEstadoOperario,
      onDeleteOperario,
      cambiarOperarioProcesado,
      cambiarOperarioAlbaran,
      fetchFiltrarOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Operario))
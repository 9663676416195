import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosConectividad from '../../components/conectividad/FiltrosConectividad'
import {openModal} from '../../actions/common'
import {abrirModalVacioConectividad, fetchFiltrarConectividad, seleccionarTodosConectividad, deSeleccionarTodosConectividad, cambiarEstadoSeleccionConectividad, deleteSeleccionConectividad,
  imprimirPdfConectividad, crearCsvConectividad} from '../../actions/conectividad/conectividad'

export function mapStateToProps (state) {
  return {
    ...state.conectividad,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioConectividad,
      fetchFiltrarConectividad,
      seleccionarTodosConectividad,
      deSeleccionarTodosConectividad,
      cambiarEstadoSeleccionConectividad,
      deleteSeleccionConectividad,
      crearCsvConectividad,
      imprimirPdfConectividad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosConectividad))
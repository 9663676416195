import actionTypes from '../../constants/actions/importarVerracos/importarVerracos'

export function initialState () {
  return {
    showModalImportarVerracos: false,
    archivo: [],
    listImportarVerracos: []
  }
}

export function abrirModalImportarVerracos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarVerracos: true
  }
}

export function cerrarModalImportarVerracos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarVerracos: false,
    archivo: [],
    listImportarVerracos: []
  }
}

export function subirArchivosImportarVerracos (state, {archivo}) {
  return {
    ...state,
    archivo: archivo
  }
}

export function submitImportarVerracosSuccess (state, {listImportarVerracos}) {
  return {
    ...state,
    listImportarVerracos: listImportarVerracos
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_IMPORTAR_VERRACOS:
      return abrirModalImportarVerracos(state, action)
     case actionTypes.CERRAR_MODAL_IMPORTAR_VERRACOS:
      return cerrarModalImportarVerracos(state, action)
     case actionTypes.SUBIR_ARCHIVOS_IMPORTAR_VERRACOS:
      return subirArchivosImportarVerracos(state, action)
     case actionTypes.SUBMIT_IMPORTAR_VERRACOS_SUCCESS:
      return submitImportarVerracosSuccess(state, action)
    default:
      return state
  }
}

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalAlbaranRfidValidar from '../../components/albaran/ModalAlbaranRfidValidar'
import {editarAlbaran, editarAlbaranEImprimirDatosAlbaran, cerrarModalAlbaranValidacionRfid, leerEtiquetasRFID, asignaParcialRFID,asignaAcumuladoRFID} from '../../actions/albaran/albaran'


export function mapStateToProps (state) {
  return {
    ...state.albaran,
    formModalAlbaran: getFormValues('ModalAlbaran')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalAlbaranValidacionRfid,
      editarAlbaranEImprimirDatosAlbaran,
      editarAlbaran,
      leerEtiquetasRFID,
      asignaParcialRFID,
      asignaAcumuladoRFID
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalAlbaranRfidValidar)))
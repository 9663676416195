import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import './CopiaSeguridad.scss'

class ModalCopiaSeguridad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centro: '',
      incluyeArchivos: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboObtenerCentroUsuario()
  }

  submitNuevaCopiaSeguridad () {
    const values = []
    values.incluirArchivos = this.state.incluyeArchivos
    values.list = this.state.centro
    this.props.actions.submitNuevaCopiaSeguridad(values)
    this.setState({incluyeArchivos: false})
  }

  handleChange (value) {
    this.setState({centro: value})
  }

  changeIncluyeArchivos (value) {
    this.setState({incluyeArchivos: value})
  }

  cerrarModalCopiaSeguridad () {
    this.props.actions.cerrarModalCopiaSeguridad()
    this.props.initialize()
    this.setState({centro: ''})
    this.setState({incluyeArchivos: false})
  }

  render () {
    const {
      t, handleSubmit, showModalCopiaSeguridad,
      actions: {cerrarModalCopiaSeguridad},
      combos: {comboObtenerCentroUsuario}
      } = this.props

    return (
      <Modal show={showModalCopiaSeguridad} onHide={() => this.cerrarModalCopiaSeguridad()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-copia-seguridad">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('COPIA_SEGURIDAD.MODAL.TITULO_NUEVO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <InputSelect
              id="idCentro"
              name="idCentro"
              colSm={4}
              options={comboObtenerCentroUsuario}
              isMulti={true}
              valueKey="value"
              labelKey="label"
              controlLabel={t('COPIA_SEGURIDAD.MODAL.CENTRO') + '*'}
              customClass="centro"
              validate={required}
              onChange={this.handleChange}
            />
            <div className="clearfix"></div>
            <Field
              id="incluyeArchivos"
              name="incluyeArchivos"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COPIA_SEGURIDAD.INCLUYE_ARCHIVOS')}
              onInputChange={(value) => this.changeIncluyeArchivos(value)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={() => this.submitNuevaCopiaSeguridad()} className="btn btn-primary">{t('COPIA_SEGURIDAD.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalCopiaSeguridad()}>{t('COPIA_SEGURIDAD.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCopiaSeguridad',
})(ModalCopiaSeguridad))
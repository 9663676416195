export default {
  FETCH_CONTROL_SANGRE: "FETCH_CONTROL_SANGRE",
  FETCH_CONTROL_SANGRE_SUCCESS: "FETCH_CONTROL_SANGRE_SUCCESS",
  SELECCIONAR_TODOS_CONTROL_SANGRE: "SELECCIONAR_TODOS_CONTROL_SANGRE",
  DESELECCIONAR_TODOS_CONTROL_SANGRE: "DESELECCIONAR_TODOS_CONTROL_SANGRE",
  OPEN_MODAL_LISTADO_CONTROL_SANGRE: 'OPEN_MODAL_LISTADO_CONTROL_SANGRE',
  OPEN_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS: 'OPEN_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS',
  CLOSE_MODAL_LISTADO_CONTROL_SANGRE: 'CLOSE_MODAL_LISTADO_CONTROL_SANGRE',
  CLOSE_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS: 'CLOSE_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS',
  SELECCIONAR_TODOS_MODAL_CONTROL_SANGRE: 'SELECCIONAR_TODOS_MODAL_CONTROL_SANGRE',
  DESELECCIONAR_TODOS_MODAL_CONTROL_SANGRE: 'DESELECCIONAR_TODOS_MODAL_CONTROL_SANGRE',
  DELETE_CONTROL_SANGRE: 'DELETE_CONTROL_SANGRE',
  DELETE_SELECCION_CONTROL_SANGRE: 'DELETE_SELECCION_CONTROL_SANGRE',
  CREATE_CSV_CONTROL_SANGRE: 'CREATE_CSV_CONTROL_SANGRE',
  IMPRIMIR_PDF_CONTROL_SANGRE: 'IMPRIMIR_PDF_CONTROL_SANGRE',
  ABRIR_MODAL_INICIO_CONTROL_SANGRE: 'ABRIR_MODAL_INICIO_CONTROL_SANGRE',
  CLOSE_MODAL_INICIO_CONTROL_SANGRE: 'CLOSE_MODAL_INICIO_CONTROL_SANGRE',
  FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE: 'FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE',
  FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE_SUCCESS: 'FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE_SUCCESS',
  SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE: 'SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE',
  DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE: 'DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE',
  ANADIR_VERRACOS_INICIO_CONTROL_SANGRE: 'ANADIR_VERRACOS_INICIO_CONTROL_SANGRE',
  ANADIR_VERRACOS_INICIO_CONTROL_SANGRE_SUCCESS: 'ANADIR_VERRACOS_INICIO_CONTROL_SANGRE_SUCCESS',
  SUBIR_ARCHIVO_INFORME: 'SUBIR_ARCHIVO_INFORME',
  DESCARGAR_ARCHIVO_CONTROL_SANGRE: 'DESCARGAR_ARCHIVO_CONTROL_SANGRE',
  IMPRIMIR_ETIQ_CONTROL_SANGRE: 'IMPRIMIR_ETIQ_CONTROL_SANGRE',
  RECUPERAR_DATOS_MODAL_CONTROL_SANGRE: 'RECUPERAR_DATOS_MODAL_CONTROL_SANGRE',
  RECUPERAR_DATOS_MODAL_CONTROL_SANGRE_SUCCESS: 'RECUPERAR_DATOS_MODAL_CONTROL_SANGRE_SUCCESS',
  ABRIR_MODAL_CONTROL_SANGRE: 'ABRIR_MODAL_CONTROL_SANGRE',
  CLOSE_MODAL_CONTROL_SANGRE: 'CLOSE_MODAL_CONTROL_SANGRE',
  GUARDAR_MODAL_CONTROL_SANGRE: 'GUARDAR_MODAL_CONTROL_SANGRE'
}
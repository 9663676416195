import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Col, ControlLabel } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import './InputRangeDatePicker.scss'
import { formatDate, parseDate } from "react-day-picker/moment";
import {date_formatter, date_parser, getMomentFormat} from '../../../util/formatFunctions'

// TODO Reemplazar InputRangeDatePicker por este componente y mejorar los handlers.
// TODO Renombrar a InputRangeDatePicker cuando este se haya elmimado.
class InputRangeDatePickerDynamicNames extends Component {
  constructor(props) {
    super()

    // From - input
    let userFormatValueFrom = props[props.nameFrom].input.value
    let dateObjectValueFrom = props[props.nameFrom].input.value
    if (userFormatValueFrom !== '') {
      if (!(userFormatValueFrom instanceof Date)) {
        userFormatValueFrom = new Date(userFormatValueFrom)
        dateObjectValueFrom = userFormatValueFrom
      }
      userFormatValueFrom = date_formatter(props[props.nameFrom].input.value, props.auth.formaFechaHora, false)
    } else {
      dateObjectValueFrom = undefined
    }

    // To - input
    let userFormatValueTo = props[props.nameTo].input.value
    let dateObjectValueTo = props[props.nameTo].input.value
    if (userFormatValueTo !== '') {
      if (!(userFormatValueTo instanceof Date)) {
        userFormatValueTo = new Date(userFormatValueTo)
        dateObjectValueTo = userFormatValueTo
      }
      userFormatValueTo = date_formatter(props[props.nameTo].input.value, props.auth.formaFechaHora, false)
    } else {
      dateObjectValueTo = undefined
    }

    this.state = {
      from: userFormatValueFrom,
      dateFrom: dateObjectValueFrom,
      to: userFormatValueTo,
      dateTo: dateObjectValueTo,
    }
    this.handleFromChange = this.handleFromChange.bind(this)
    this.handleToChange = this.handleToChange.bind(this)
    this.handleClickFrom = this.handleClickFrom.bind(this)
    this.handleClickTo = this.handleClickTo.bind(this)
  }

  showFromMonth() {
    if (!this.state.dateFrom) {
      return
    }
    if (moment(this.state.dateTo).diff(moment(this.state.dateFrom), 'months') < 2) {
      this.to.getDayPicker().showMonth(this.state.dateFrom)
    }
  }

  handleFromChange(value) {
    if (value && !isNaN(value)){
      let userFormatValue = date_formatter(value, this.props.auth.formaFechaHora, false)
      this.setState({from: userFormatValue, dateFrom: new Date(value)})

      let standartFormatValue = date_parser(value, this.props.auth.formaFechaHora)
      this.props[this.props.nameFrom].input.onChange(standartFormatValue)
      if (this.props.onInputChange) {
        setTimeout((function() {
          this.props.onInputChange(standartFormatValue)
        }).bind(this), 200)
      }
    } else {
      this.setState({from: '', dateFrom: undefined}, () => {
        this.props[this.props.nameFrom].input.onChange('')
        if (this.props.onInputChange) {
          setTimeout((function() {
            this.props.onInputChange('')
          }).bind(this), 200)
        }
      })
    }
  }

  handleToChange(value) {
    if (value && !isNaN(value)){
      let userFormatValue = date_formatter(value, this.props.auth.formaFechaHora, false)
      this.setState({to: userFormatValue, dateTo: new Date(value)})

      let standartFormatValue = date_parser(value, this.props.auth.formaFechaHora)
      this.props[this.props.nameTo].input.onChange(standartFormatValue)
      if (this.props.onInputChange) {
        setTimeout((function() {
          this.props.onInputChange(standartFormatValue)
        }).bind(this), 200)
      }
    } else {
      this.setState({to: '', dateTo: undefined}, () => {
        this.props[this.props.nameTo].input.onChange('')
        if (this.props.onInputChange) {
          setTimeout((function() {
            this.props.onInputChange('')
          }).bind(this), 200)
        }
      })
    }
  }

  handleClickFrom (e) {
    if (this.props.onClickFrom) {
      this.props.onClickFrom(e)
    }
  }

  handleClickTo (e) {
    if (this.props.onClickTo) {
      this.props.onClickTo(e)
    }
  }

  componentDidUpdate (prevProps, prevState) {

    // From - input
    if (this.state.from !== prevState.from) {
      let standartFormatValue = this.state.from
      if (standartFormatValue) {
        standartFormatValue = date_parser(this.state.from, this.props.auth.formaFechaHora)
      }
      this.props[this.props.nameFrom].input.onChange(standartFormatValue)
    } else if (this.props[this.props.nameFrom].input.value !== prevProps[this.props.nameFrom].input.value) {
      let userFormatValue = this.props[this.props.nameFrom].input.value
      let dateObjectValue = this.props[this.props.nameFrom].input.value
      if (userFormatValue !== '') {
        if (!(userFormatValue instanceof Date)) {
          userFormatValue = new Date(userFormatValue)
          dateObjectValue = userFormatValue
        }
        userFormatValue = date_formatter(this.props[this.props.nameFrom].input.value, this.props.auth.formaFechaHora, false)
      }
      else {
        dateObjectValue = undefined
      }
      this.setState({from: userFormatValue, dateFrom: dateObjectValue})
    }

    // To - input
    if (this.state.to !== prevState.to) {
      let standartFormatValue = this.state.to
      if (standartFormatValue) {
        standartFormatValue = date_parser(this.state.to, this.props.auth.formaFechaHora)
      }
      this.props[this.props.nameTo].input.onChange(standartFormatValue)
    } else if (this.props[this.props.nameTo].input.value !== prevProps[this.props.nameTo].input.value) {
      let userFormatValue = this.props[this.props.nameTo].input.value
      let dateObjectValue = this.props[this.props.nameTo].input.value
      if (userFormatValue !== '') {
        if (!(userFormatValue instanceof Date)) {
          userFormatValue = new Date(userFormatValue)
          dateObjectValue = userFormatValue
        }
        userFormatValue = date_formatter(this.props[this.props.nameTo].input.value, this.props.auth.formaFechaHora, false)
      }
      else {
        dateObjectValue = undefined
      }
      this.setState({to: userFormatValue, dateTo: dateObjectValue})
    }
  }

  clearValorInputFrom () {
    this.setState({from: ''})
    this.handleFromChange('')
  }

  clearValorInputTo () {
    this.setState({to: ''})
    this.handleToChange('')
  }

  render() {
    const {
      colSm, nameFrom, nameTo, controlLabel, t, border, classRange, inline, noIntro,
      auth: {formaFechaHora, lunesInicioSemana}
    } = this.props

    const modifiers = { start: this.state.from, end: this.state.to }
    const weekdays_long = [
      t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_LONG.LUNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MARTES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_LONG.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_LONG.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.SABADO'),
    ];
    const weekdays_short = [
      t('COMUN.DATE.WEEKDAYS_SHORT.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_SHORT.LUNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MARTES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.SABADO'),
    ];
    const months = [
      t('COMUN.DATE.MONTHS.ENERO'),
      t('COMUN.DATE.MONTHS.FEBRERO'),
      t('COMUN.DATE.MONTHS.MARZO'),
      t('COMUN.DATE.MONTHS.ABRIL'),
      t('COMUN.DATE.MONTHS.MAYO'),
      t('COMUN.DATE.MONTHS.JUNIO'),
      t('COMUN.DATE.MONTHS.JULIO'),
      t('COMUN.DATE.MONTHS.AGOSTO'),
      t('COMUN.DATE.MONTHS.SEPTIEMBRE'),
      t('COMUN.DATE.MONTHS.OCTUBRE'),
      t('COMUN.DATE.MONTHS.NOVIEMBRE'),
      t('COMUN.DATE.MONTHS.DICIEMBRE'),
    ];

    let formatoMoment = getMomentFormat(formaFechaHora)

    return (
      <Col sm={colSm} className={(classRange ? classRange : '') + (inline ? 'inline-block' : '')}>
        <div className="form-group">
          <div className="InputFromTo">
            {
              border !== false && (
              controlLabel && <ControlLabel>{controlLabel}</ControlLabel>
              )
            }
            {
              border !== false ? (
                noIntro !== true ? (
                  <br/>
                ) : null
              ) : null
            }
            <div className="cont-input-range">
              <DayPickerInput
                id={nameFrom}
                format={formatoMoment}
                formatDate={formatDate}
                parseDate={parseDate}
                inputProps={{
                  name: nameFrom,
                  autoComplete: "off",
                  title: (controlLabel ? controlLabel + ' ' : '') + t('COMUN.DATE.DESDE'),
                  className: 'form-control',
                  onClick: this.handleClickFrom.bind(this)
                }}
                value={this.state.from}
                placeholder={t('COMUN.DATE.DESDE')}
                dayPickerProps={{
                  months: months,
                  weekdaysLong: weekdays_long,
                  weekdaysShort: weekdays_short,
                  firstDayOfWeek: (lunesInicioSemana ? 1 : 0),
                  selectedDays: [this.state.dateFrom, { from: this.state.dateFrom, to: this.state.dateTo }],
                  disabledDays: { after: this.state.dateTo },
                  toMonth: this.state.dateTo,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => this.to.getInput().focus()
                }}
                onDayChange={this.handleFromChange}
              />
              <span className="clear-fecha" onClick={() => this.clearValorInputFrom()}>x</span>
            </div>
            {
              border !== false && (
              ' — '
              )
            }
            <span className="InputFromTo-to">
              <div className="cont-input-range">
                <DayPickerInput
                  id={nameTo}
                  format={formatoMoment}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  inputProps={{
                    name: nameTo,
                    autoComplete: "off",
                    title: (controlLabel ? controlLabel + ' ' : '') + t('COMUN.DATE.HASTA'),
                    className: 'form-control',
                    onClick: this.handleClickTo.bind(this)
                  }}
                  ref={el => (this.to = el)}
                  value={this.state.to}
                  placeholder={t('COMUN.DATE.HASTA')}
                  dayPickerProps={{
                    months: months,
                    weekdaysLong: weekdays_long,
                    weekdaysShort: weekdays_short,
                    firstDayOfWeek: (lunesInicioSemana ? 1 : 0),
                    selectedDays: [this.state.dateFrom, { from: this.state.dateFrom, to: this.state.dateTo }],
                    disabledDays: { before: this.state.dateFrom },
                    modifiers,
                    month: this.state.dateFrom,
                    fromMonth: this.state.dateFrom,
                    numberOfMonths: 2
                  }}
                  onDayChange={this.handleToChange}
                />
                <span className="clear-fecha" onClick={() => this.clearValorInputTo()}>x</span>
              </div>
            </span>
          </div>
        </div>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(withTranslation()(InputRangeDatePickerDynamicNames))
import actionTypes from '../../constants/actions/ruta/ruta'

export function fetchRuta () {
  return {
    type: actionTypes.FETCH_RUTA
  }
}

export function fetchRutaSuccess (list) {
  return {
    type: actionTypes.FETCH_RUTA_SUCCESS,
    list
  }
}

export function abrirModalVacioRuta (){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_RUTA
  }
}

export function abrirModalVacioRutaSuccess (){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_RUTA_SUCCESS
  }
}

export function abrirModalClienteRuta (){
  return {
    type: actionTypes.ABRIR_MODAL_CLIENTE_RUTA
  }
}

export function cerrarModalClienteRuta (){
  return {
    type: actionTypes.CERRAR_MODAL_CLIENTE_RUTA
  }
}

export function seleccionarTodosModalClienteRuta (){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_CLIENTE_RUTA
  }
}

export function deSeleccionarTodosModalClienteRuta (){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_CLIENTE_RUTA
  }
}

export function anadirCliente (clientesAnadidos){
  return {
    type: actionTypes.ANADIR_CLIENTE,
    clientesAnadidos
  }
}

export function onDeleteClienteAnadido (idDireccionCliente){
  return {
    type: actionTypes.ON_DELETE_CLIENTE_ANADIDO,
    idDireccionCliente
  }
}

export function onDeleteClienteAnadidoSuccess (clientesAnadidos){
  return {
    type: actionTypes.ON_DELETE_CLIENTE_ANADIDO_SECCESS,
    clientesAnadidos
  }
}

export function submitNuevoRuta (values){
  return {
    type: actionTypes.SUBMIT_NUEVO_RUTA,
    values
  }
}

export function fetchClienteRuta (clienteAnadidos){
  return {
    type: actionTypes.FETCH_CLIENTE_RUTA,
    clienteAnadidos
  }
}

export function fetchClienteRutaSuccess (listClientes){
  return {
    type: actionTypes.FETCH_CLIENTE_RUTA_SUCCESS,
    listClientes
  }
}

export function seleccionarTodosModalRuta (){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_RUTA
  }
}

export function deSeleccionarTodosModalRuta (){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_RUTA
  }
}

export function deleteSeleccionClientesRuta (listClientes){
  return {
    type: actionTypes.DELETE_SELECCION_CLIENTE_RUTA,
    listClientes
  }
}

export function recuperarDatosModalRuta (idRuta){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_RUTA,
    idRuta
  }
}

export function recuperarDatosModalRutaSuccess (data){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_RUTA_SUCCESS,
    data
  }
}

export function editarRuta (values){
  return {
    type: actionTypes.EDITAR_RUTA,
    values
  }
}

export function duplicarDatosModalRuta (idRuta){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_RUTA,
    idRuta
  }
}

export function duplicarDatosModalRutaSuccess (dataDuplicar){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_RUTA_SUCCESS,
    dataDuplicar
  }
}

export function guardarYnuevoRuta (values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_RUTA,
    values
  }
}

export function limpiar (){
  return {
    type: actionTypes.LIMPIAR
  }
}

export function cambiarEstadoRuta (idRuta){
  return {
    type: actionTypes.CAMBIAR_ESTADO_RUTA,
    idRuta
  }
}

export function onDeleteRuta (idRuta){
  return {
    type: actionTypes.ON_DELETE_RUTA,
    idRuta
  }
}

export function seleccionarTodosRuta (){
  return {
    type: actionTypes.SELECCIONAR_TODOS_RUTA
  }
}

export function deSeleccionarTodosRuta (){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_RUTA
  }
}

export function deleteSeleccionRuta (list){
  return {
    type: actionTypes.DELETE_SELECCION_RUTA,
    list
  }
}

export function cambioEstadoSeleccionRuta (list){
  return {
    type: actionTypes.CAMBIO_ESTADO_SELECCION_RUTA,
    list
  }
}

export function fetchFiltrarRuta (values){
  return {
    type: actionTypes.FETCH_FILTRAR_RUTA,
    values
  }
}

export function fetchFiltrarRutaSuccess (filtros){
  return {
    type: actionTypes.FETCH_FILTRAR_RUTA_SUCCESS,
    filtros
  }
}

export function fetchFiltrarClienteRuta (values){
  return {
    type: actionTypes.FETCH_FILTRAR_CLIENTE_RUTA,
    values
  }
}

export function fetchFiltrarClienteRutaSuccess (filtrosCliente){
  return {
    type: actionTypes.FETCH_FILTRAR_CLIENTE_RUTA_SUCCESS,
    filtrosCliente
  }
}

export function crearCsvRuta(list){
  return {
    type: actionTypes.CREATE_CSV_RUTA,
    list
  }
}

export function crearCsvRutaSuccess(datosExcelRuta){
  return {
    type: actionTypes.CREATE_CSV_RUTA_SUCCESS,
    datosExcelRuta
  }
}
export function imprimirPdfRuta(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_RUTA,
    list
  }
}

export function imprimirPdfRutaSuccess(datosPdfRuta){
  return {
    type: actionTypes.IMPRIMIR_PDF_RUTA_SUCCESS,
    datosPdfRuta
  }
}

export function submitNuevaRutaSuccess (insercionRuta) {
  return {
    type: actionTypes.SUBMIT_NUEVA_RUTA_SUCCESS,
    insercionRuta
  }
}
export function editarRutaSuccess (edicionRuta) {
  return {
    type: actionTypes.EDIT_RUTA_SUCCESS,
    edicionRuta
  }
}
export function cambiarEstadoRutaSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_RUTA_SUCCESS,
    dataEstado
  }
}
export function onDeleteRutaSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_RUTA_SUCCESS,
    dataDelete
  }
}

export default {
  fetchRuta,
  fetchRutaSuccess,
  abrirModalVacioRuta,
  abrirModalClienteRuta,
  cerrarModalClienteRuta,
  seleccionarTodosModalClienteRuta,
  deSeleccionarTodosModalClienteRuta,
  anadirCliente,
  onDeleteClienteAnadido,
  onDeleteClienteAnadidoSuccess,
  submitNuevoRuta,
  fetchClienteRuta,
  fetchClienteRutaSuccess,
  seleccionarTodosModalRuta,
  deSeleccionarTodosModalRuta,
  deleteSeleccionClientesRuta,
  recuperarDatosModalRuta,
  recuperarDatosModalRutaSuccess,
  editarRuta,
  duplicarDatosModalRuta,
  duplicarDatosModalRutaSuccess,
  guardarYnuevoRuta,
  limpiar,
  cambiarEstadoRuta,
  onDeleteRuta,
  seleccionarTodosRuta,
  deSeleccionarTodosRuta,
  deleteSeleccionRuta,
  cambioEstadoSeleccionRuta,
  fetchFiltrarRuta,
  fetchFiltrarRutaSuccess,
  fetchFiltrarClienteRuta,
  fetchFiltrarClienteRuta,
  fetchFiltrarClienteRutaSuccess,
  crearCsvRuta,
  crearCsvRutaSuccess,
  imprimirPdfRuta,
  imprimirPdfRutaSuccess,
  submitNuevaRutaSuccess,
  editarRutaSuccess,
  cambiarEstadoRutaSuccess,
  onDeleteRutaSuccess
}

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Loader from '../comun/loader/Loader'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import ModalTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/ModalTrazabilidadMaterialPage'
import {number_formatter} from '../../util/formatFunctions'
import { required } from '../../util/validationFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaEnvasado, translateEstadoMaquinaEnvasado} from '../../constants/maquinas'

class ModalEnvasadoMultiDosis extends Component {
  constructor (props) {
    super()
    this.state = {
      totalDisponibleDispar: false,
      totalUtilizado: props.data.totalUtilizado || 0,
      tipoDosis: (props.formModalEnvasadoMultiDosis && props.formModalEnvasadoMultiDosis.tipoDosis) || '',
      numeroDosis: (props.formModalEnvasadoMultiDosis && props.formModalEnvasadoMultiDosis.numeroDosis) || 0,
      bloquearTodo: false,
      loteBolsasDisabled: false
    }
    this.calcTotalUtilizadoActual = this.calcTotalUtilizadoActual.bind(this)
    this.handleChangeMaquina = this.handleChangeMaquina.bind(this)
    this.handleChangeTipoDosis = this.handleChangeTipoDosis.bind(this)
    this.handleChangeNumeroDosis = this.handleChangeNumeroDosis.bind(this)
    this.seleccionarEliminado = this.seleccionarEliminado.bind(this)
    this.handleOpenModalTrazabilidadMaterial = this.handleOpenModalTrazabilidadMaterial.bind(this)
    this.prepararMaquinaEnvasado = this.prepararMaquinaEnvasado.bind(this)
    this.cancelarMaquinaEnvasado = this.cancelarMaquinaEnvasado.bind(this)
    this.guardarModalEnvasadoMultiDosis = this.guardarModalEnvasadoMultiDosis.bind(this)
  }

  guardarModalEnvasadoMultiDosis (values) {
    return new Promise((resolve, reject) => {
    this.props.actions.guardarModalEnvasadoMultiDosis(values, () => {
        resolve()
      }, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  calcTotalUtilizadoActual () {
    let initialValue = 0
    if (this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis) {
      initialValue = this.props.data.multiDosis.filter((dosis) => {
        return dosis.idEnvasadoMultiDosis === this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis
      })
      initialValue = (initialValue && initialValue.length > 0) ? initialValue[0].volumen : 0
    }
    this.setState({
      totalUtilizado: this.props.data.totalUtilizado - initialValue + (((this.state.tipoDosis && parseFloat(this.state.tipoDosis.volumen)) || 0) * (this.state.numeroDosis || 0))
    })
  }

  handleChangeMaquina (maquina) {
    this.props.change('fechaCaducidadEtiqueta', !!maquina)
    this.props.change('etiqueta', !!maquina)
    this.setState({loteBolsasDisabled: !!maquina})
    this.props.change('loteBolsas', '')
  }

  handleChangeTipoDosis (tipoDosis) {
    this.setState({tipoDosis}, this.calcTotalUtilizadoActual)
    this.calcNumDosis()

    if (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && tipoDosis && tipoDosis.value && this.props.formModalEnvasadoMultiDosis.numeroDosis) {
      this.props.actions.comprobarStockTrazabilidad(categoriasEscandallos.ENVASADO, tipoDosis.value, false)
      this.props.actions.obtenerLotesEscandalloEnvasado({
        idTipoDosis: tipoDosis.value,
        numeroDosis: this.props.formModalEnvasadoMultiDosis.numeroDosis,
        idCategoria: categoriasEscandallos.ENVASADO,
        idEnvasadoMultiDosis: this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis || null
      }, false)
    }

    this.updateTrazabilidad()
  }

  calcNumDosis(){
    let disponible = this.props.data.totalUtilizado - this.state.totalUtilizado
    this.props.change('numeroDosis', parseInt(this.state.numeroDosis) + parseInt(disponible / this.state.tipoDosis.volumen))
  }

  handleChangeNumeroDosis (val) {
    this.setState({numeroDosis: val}, this.calcTotalUtilizadoActual)

    if (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && val && this.props.formModalEnvasadoMultiDosis.tipoDosis && this.props.formModalEnvasadoMultiDosis.tipoDosis.value) {
      this.props.actions.comprobarStockTrazabilidad(categoriasEscandallos.ENVASADO, this.props.formModalEnvasadoMultiDosis.tipoDosis.value, this.props.utilizarLotesPreferidosDelUsuario)
      this.props.actions.obtenerLotesEscandalloEnvasado({
        idTipoDosis: this.props.formModalEnvasadoMultiDosis.tipoDosis.value,
        numeroDosis: val,
        idCategoria: categoriasEscandallos.ENVASADO,
        idEnvasadoMultiDosis: this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis || null
      }, this.props.utilizarLotesPreferidosDelUsuario)
    }

    this.updateTrazabilidad()
  }

  seleccionarEliminado (value) {
    this.setState({bloquearTodo: value})
    if (value === true) {
      this.props.change('fechaCaducidadEtiqueta', false)
      this.props.change('etiqueta', false)
      //this.props.change('envasado', false)
    }
  }

  handleOpenModalTrazabilidadMaterial () {
    if (this.props.data.idTipoDosis) {
      const multiDosisRow = (this.props.formModalEnvasadoMultiDosis && this.props.data.multiDosis) ?
        this.props.data.multiDosis.find((e) => e.idEnvasadoMultiDosis === this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis)
        : null

      this.props.actions.openModalTrazabilidadMaterial({
        idTipoDosis: this.props.formModalEnvasadoMultiDosis.tipoDosis.value,
        idCategoria: categoriasEscandallos.ENVASADO,
        idEnvasadoMultiDosis: this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis || null,
        ultimaModificacion: (multiDosisRow && multiDosisRow.ultimaModificacion) || null,
        showStockColumns: (!multiDosisRow || (!multiDosisRow.estadoInicialEnvasado || /^new/.test(multiDosisRow.idEnvasadoMultiDosis))),
        utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario
      })
    }
  }

  updateTrazabilidad(){
    if (this.props.data.idTipoDosis) {
      const multiDosisRow = (this.props.formModalEnvasadoMultiDosis && this.props.data.multiDosis) ?
        this.props.data.multiDosis.find((e) => e.idEnvasadoMultiDosis === this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis)
        : null

      this.props.actions.actualizarTrazabilidadMaterial({
        idTipoDosis: this.props.formModalEnvasadoMultiDosis.tipoDosis.value,
        idCategoria: categoriasEscandallos.ENVASADO,
        idEnvasadoMultiDosis: this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis || null,
        ultimaModificacion: (multiDosisRow && multiDosisRow.ultimaModificacion) || null,
        showStockColumns: (!multiDosisRow || (!multiDosisRow.estadoInicialEnvasado || /^new/.test(multiDosisRow.idEnvasadoMultiDosis))),
        utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario
      })
    }
  }

  prepararMaquinaEnvasado (values) {
    if (!values.idMaquina || !values.idMaquina.value) {
      this.props.actions.yesNoModal('maquinaRequerida')
    } else {
      this.props.actions.prepararMaquinaEnvasado(values)
    }
  }

  cancelarMaquinaEnvasado () {
    this.props.actions.cancelarMaquinaEnvasado()
  }

  componentDidUpdate (prevProps) {
    if (!this.props.showModalEnvasadoMultiDosis && this.props.showModalEnvasadoMultiDosis !== prevProps.showModalEnvasadoMultiDosis) {
      if (this.state.bloquearTodo === true) {
        this.setState({ bloquearTodo: false })
      }
    } else if (this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.envasadoEliminado !== this.state.bloquearTodo) {
      this.setState({ bloquearTodo: this.props.formModalEnvasadoMultiDosis.envasadoEliminado })
    }

    if (this.props.data.totalDisponible !== this.state.totalUtilizado && !this.state.totalDisponibleDispar) {
      this.setState({totalDisponibleDispar: true})
    } else if (this.props.data.totalDisponible === this.state.totalUtilizado && this.state.totalDisponibleDispar) {
      this.setState({totalDisponibleDispar: false})
    }

    if (this.props.data.totalUtilizado && this.props.data.totalUtilizado !== prevProps.data.totalUtilizado && this.props.data.totalUtilizado !== this.state.totalUtilizado) {
      this.setState({totalUtilizado: this.props.data.totalUtilizado})
    }
    if (
      this.props.formModalEnvasadoMultiDosis !== undefined && this.props.formModalEnvasadoMultiDosis.tipoDosis !== undefined && this.props.formModalEnvasadoMultiDosis.tipoDosis.value && (
        (prevProps.formModalEnvasadoMultiDosis === undefined || prevProps.formModalEnvasadoMultiDosis.tipoDosis === undefined || !prevProps.formModalEnvasadoMultiDosis.tipoDosis.value) ||
        this.props.formModalEnvasadoMultiDosis.tipoDosis.value !== prevProps.formModalEnvasadoMultiDosis.tipoDosis.value
      )
    ) {
      if (this.props.showModalEnvasadoMultiDosis && this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
        this.props.actions.comprobarStockTrazabilidad(categoriasEscandallos.ENVASADO, this.props.formModalEnvasadoMultiDosis.tipoDosis.value, false)
      }
      this.setState({tipoDosis: this.props.formModalEnvasadoMultiDosis.tipoDosis})
    }
    if (this.props.formModalEnvasadoMultiDosis && (!prevProps.formModalEnvasadoMultiDosis || this.props.formModalEnvasadoMultiDosis.numeroDosis !== prevProps.formModalEnvasadoMultiDosis.numeroDosis)) {
      this.setState({numeroDosis: this.props.formModalEnvasadoMultiDosis.numeroDosis}, this.calcTotalUtilizadoActual)
    }
  }

  render () {
    const {
      t, handleSubmit, showModalEnvasadoMultiDosis, data: {totalDisponible},
      stockInsuficiente, codigoEstadoMaquinaEnvasado, submitting,
      auth: {modulos, separadorMil, separadorDec},
      actions: {cerrarModalEnvasadoMultiDosis}, combos: {comboTipoDosis, comboMaquina}
    } = this.props
    const tKey = 'EXTRACCIONES.ENVASADO.MODAL.MULTIDOSIS.'

    const editable = this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.editable
    
    
    const envasando = false;
    const envasado = this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.envasado;    
    
    //const envasando = ( codigoEstadoMaquinaEnvasado === estadoMaquinaEnvasado['PROCESANDO_ENVASADO'] || codigoEstadoMaquinaEnvasado === estadoMaquinaEnvasado['ENVASANDO'] || codigoEstadoMaquinaEnvasado === estadoMaquinaEnvasado['EN_ESPERA_SER_INICIADA'] )

    if (!showModalEnvasadoMultiDosis) return null

    return (
      <Modal show={showModalEnvasadoMultiDosis} onHide={cerrarModalEnvasadoMultiDosis} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-envasado">
        <Modal.Header closeButton={!envasando}>
          <Modal.Title>
              {t(tKey + (this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.idEnvasadoMultiDosis ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
            <ModalTrazabilidadMaterialPage />
          )}
          <Row>
            <Field
              colSm={4}
              id="idMaquina"
              name="idMaquina"
              controlLabel={t(tKey + 'FORM.MAQUINA')}
              component={InputSelect}
              options={comboMaquina}
              valueKey="value"
              labelKey="label"
              customClass='select-maquina'
              isClearable={true}
              disabled={!editable || envasando || this.state.bloquearTodo}
              onInputChange={(maquina) => this.handleChangeMaquina(maquina)}
            />
            <Field
              colSm={4}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t(tKey + 'FORM.TIPO_DOSIS') + '*'}
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              isClearable={false}
              onInputChange={(tipoDosis) => this.handleChangeTipoDosis(tipoDosis)}
              validate={required}
              disabled={!editable || envasando || this.state.bloquearTodo}
            />
            <Field
              colSm={4}
              id="numeroDosis"
              name="numeroDosis"
              controlLabel={t(tKey + 'FORM.NUMERO_DOSIS') + '*'}
              component={InputNumerical}
              onInputChange={(event, val) => this.handleChangeNumeroDosis(val)}
              validate={required}
              numDecimales={0}
              autoComplete="off"
              disabled={!editable || envasando || this.state.bloquearTodo}
            />
          </Row>
          <Row>
            <Field
              colSm={4}
              id="loteBolsas"
              name="loteBolsas"
              controlLabel={t(tKey + 'FORM.LOTE_BOLSA')}
              component={InputText}
              numDecimales={0}
              autoComplete="off"
              disabled={this.props.formModalEnvasadoMultiDosis.idMaquina || !editable || envasando || this.state.loteBolsasDisabled || this.state.bloquearTodo}
            />
          </Row>
          <Row className="tablaTotales" style={{ margin: '0 0 10px 0'}}>
            <Col sm={6} className="estados-total-verraco">
              <span>{t(tKey + 'TOTALES.DISPONIBLE')}: {number_formatter(totalDisponible, 2, separadorDec, separadorMil)} ml</span>
            </Col>
            <Col sm={6} className={"estados-total-verraco" + (this.state.totalDisponibleDispar ? ' has-error' : '')}>
              {this.state.totalDisponibleDispar && (<span className='help-block'><Warning /></span>)}
              <span>{t(tKey + 'TOTALES.UTILIZADO')}: {number_formatter(this.state.totalUtilizado, 2, separadorDec, separadorMil)} ml</span>
            </Col>
          </Row>
          {/*totalDisponible < this.state.totalUtilizado && (
            <Row className="m-b-20">
              <Col sm={12}>
                <p className="has-error help-block">
                  <span className="help-block"><Warning /></span>
                  <span className="bold">{t(tKey + 'TOTALES.AVISO_DISPONIBLE_SUPERADO')}</span>
                </p>
              </Col>
            </Row>
          )*/}
          <Row>
            <Field
              id="fechaCaducidadEtiqueta"
              name="fechaCaducidadEtiqueta"
              colSm={6}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t(tKey + 'FORM.CADUCIDAD')}
              disabled={!editable || envasando || this.state.bloquearTodo}
            />
            <Field
              id="etiqueta"
              x
              name="etiqueta"
              colSm={6}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t(tKey + 'FORM.ETIQUETA')}
              disabled={!editable || envasando || this.state.bloquearTodo}
            />
            <Field
              id="envasado"
              name="envasado"
              colSm={6}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t(tKey + 'FORM.ENVASADO')}
              disabled={!editable || envasando || this.state.bloquearTodo}
            />
            <Field
              id="envasadoEliminado"
              name="envasadoEliminado"
              colSm={6}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t(tKey + 'FORM.ENVASADO_ELIMINADO')}
              onInputChange={(values) => this.seleccionarEliminado(values)}
              disabled={!editable}
            />
          </Row>
          {this.state.bloquearTodo && (
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
                disabled={!editable || envasando}
              />
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-flex">
            <div className="col-flex text-left bold">
              {envasando && (<Loader />)}
              {codigoEstadoMaquinaEnvasado ? t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaEnvasado[codigoEstadoMaquinaEnvasado]) : ''}
            </div>
            <Col sm={envasando ? 8 : 7}>
              {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                <Button
                  type="button" className="btn btn-white"
                  // disabled={envasando || this.state.bloquearTodo || (totalDisponible < this.state.totalUtilizado)}
                  disabled={envasando || this.state.bloquearTodo}
                  onClick={this.handleOpenModalTrazabilidadMaterial}
                >
                  {stockInsuficiente && (<span className='help-block white'><Warning /></span>)} {t('EXTRACCIONES.DILUCION.MODAL.TRAZABILIDAD')}
                </Button>
              )}
              <Button
                type="button" className="btn btn-primary"
                // disabled={!editable || this.state.bloquearTodo || (totalDisponible < this.state.totalUtilizado)}
                disabled={(this.props.formModalEnvasadoMultiDosis && this.props.formModalEnvasadoMultiDosis.envasado) ||
                          !editable || this.state.bloquearTodo}
                onClick={!envasando ? handleSubmit(this.prepararMaquinaEnvasado) : this.cancelarMaquinaEnvasado}
              >
                {envasando ? t(tKey + 'BUTTONS.PARAR_ENVASADO') : t(tKey + 'BUTTONS.ENVASAR')}
              </Button>
              <Button type="button" className="btn btn-primary" disabled={!editable || envasando || submitting} onClick={!submitting ? handleSubmit(this.guardarModalEnvasadoMultiDosis) : null}>{t(tKey + 'BUTTONS.GUARDAR')}</Button>
              <Button type="button" className="btn btn-white" disabled={envasando} onClick={cerrarModalEnvasadoMultiDosis}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
            </Col>
          </Row>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalEnvasadoMultiDosis',
})(ModalEnvasadoMultiDosis))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import BioseguridadHigiene from '../../../components/planSanitario/bioseguridadHigiene/BioseguridadHigiene'
import { fetchBioseguridadHigiene, recuperarDatosModalBioseguridadHigiene, duplicarDatosModalBioseguridadHigiene, onDeleteBioseguridadHigiene, fetchFiltrarBioseguridadHigiene
 } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'

export function mapStateToProps (state) {
  return {
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    bioseguridadHigiene: state.bioseguridadHigiene,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    showModalAgenda: state.agenda.modalAgenda.showModalAgenda
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchBioseguridadHigiene,
      recuperarDatosModalBioseguridadHigiene,
      duplicarDatosModalBioseguridadHigiene,
      onDeleteBioseguridadHigiene,
      fetchFiltrarBioseguridadHigiene
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BioseguridadHigiene))
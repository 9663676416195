import actionTypes from '../../constants/actions/validacionMateriaPrima/validacionMateriaPrima'

export function abrirModalValidacionMateriaPrima () {
  return {
    type: actionTypes.ABRIR_MODAL_VALIDACION_MATERIA_PRIMA
  }
}

export function closeModalValidacionMateriaPrima () {
  return {
    type: actionTypes.CLOSE_MODAL_VALIDACION_MATERIA_PRIMA
  }
}

export function submitNuevoValidacionMateriaPrima (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA,
    values
  }
}

export function submitNuevoValidacionMateriaPrimaSuccess (listValidacionMateriaPrima) {
  return {
    type: actionTypes.SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS,
    listValidacionMateriaPrima
  }
}

export function guardarYnuevoValidacionMateriaPrima (values) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA,
    values
  }
}

export function guardarYnuevoValidacionMateriaPrimaSuccess (listValidacionMateriaPrima) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA_SUCCESS,
    listValidacionMateriaPrima
  }
}

export function obtenerValidacionMateriaPrimasAlmacen () {
  return {
    type: actionTypes.OBTENER_VALIDACION_MATERIA_PRIMAS_ALMACEN
  }
}

export function filtrarValidacionMateriaPrima (values) {
  return {
    type: actionTypes.FILTRAR_VALIDACION_MATERIA_PRIMA,
    values
  }
}

export function filtrarValidacionMateriaPrimaSuccess (listValidacionMateriaPrima) {
  return {
    type: actionTypes.FILTRAR_VALIDACION_MATERIA_PRIMA_SUCCESS,
    listValidacionMateriaPrima
  }
}

export function filtrosValidacionMateriaPrimaSuccess (filtros) {
  return {
    type: actionTypes.FILTROS_VALIDACION_MATERIA_PRIMA_SUCCESS,
    filtros
  }
}

export function recuperarDatosModalValidacionMateriaPrima (idValidacionMateriaPrima) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA,
    idValidacionMateriaPrima
  }
}

export function recuperarDatosModalValidacionMateriaPrimaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS,
    data
  }
}

export function editarValidacionMateriaPrima (values) {
  return {
    type: actionTypes.EDITAR_VALIDACION_MATERIA_PRIMA,
    values
  }
}

export function duplicarDatosModalValidacionMateriaPrima (idValidacionMateriaPrima) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA,
    idValidacionMateriaPrima
  }
}

export function duplicarDatosModalValidacionMateriaPrimaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoValidacionMateriaPrima (idValidacionMateriaPrima) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_VALIDACION_MATERIA_PRIMA,
    idValidacionMateriaPrima
  }
}

export function onDeleteValidacionMateriaPrima (idValidacionMateriaPrima) {
  return {
    type: actionTypes.ON_DELETE_VALIDACION_MATERIA_PRIMA,
    idValidacionMateriaPrima
  }
}

export function seleccionarTodosValidacionMateriaPrima () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA
  }
}

export function deSeleccionarTodosValidacionMateriaPrima () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VALIDACION_MATERIA_PRIMA
  }
}

export function cambiarEstadoSeleccionValidacionMateriaPrima (list) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_VALIDACION_MATERIA_PRIMA,
    list
  }
}

export function deleteSeleccionValidacionMateriaPrima (list) {
  return {
    type: actionTypes.DELETE_SELECCION_VALIDACION_MATERIA_PRIMA,
    list
  }
}

export function crearCsvValidacionMateriaPrima(list){
  return {
    type: actionTypes.CREATE_CSV_VALIDACION_MATERIA_PRIMA,
    list
  }
}

export function crearCsvValidacionMateriaPrimaSuccess(datosExcelValidacionMateriaPrima){
  return {
    type: actionTypes.CREATE_CSV_VALIDACION_MATERIA_PRIMA_SUCCESS,
    datosExcelValidacionMateriaPrima
  }
}

export function imprimirPdfValidacionMateriaPrima(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA,
    list
  }
}

export function imprimirPdfValidacionMateriaPrimaSuccess(datosPdfValidacionMateriaPrima){
  return {
    type: actionTypes.IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA_SUCCESS,
    datosPdfValidacionMateriaPrima
  }
}

export function abrirModalVerracoPool(){
  return {
    type: actionTypes.ABRIR_MODAL_VERRACO_POOL
  }
}

export function cerrarModalVerracoPool(){
  return {
    type: actionTypes.CERRAR_MODAL_VERRACO_POOL
  }
}

export function obtenerVerracosMateriaPrima(list){
  return {
    type: actionTypes.OBTENER_VERRACOS_MATERIA_PRIMA,
    list
  }
}

export function obtenerVerracosMateriaPrimaSuccess(comboVerracosMateriaPrima){
  return {
    type: actionTypes.OBTENER_VERRACOS_MATERIA_PRIMA_SUCCESS,
    comboVerracosMateriaPrima
  }
}

export function obtenerPoolMateriaPrima(list){
  return {
    type: actionTypes.OBTENER_POOL_MATERIA_PRIMA,
    list
  }
}

export function obtenerPoolMateriaPrimaSucccess(comboPoolMateriaPrima){
  return {
    type: actionTypes.OBTENER_POOL_MATERIA_PRIMA_SUCCESS,
    comboPoolMateriaPrima
  }
}

export function submitNuevoVerracoPool(listVerracoPool){
  return {
    type: actionTypes.SUBMIT_NUEVO_VERRACO_POOL,
    listVerracoPool
  }
}

export function anadirVerracoMateriaPrima(listVerracosMateriaPrima){
  return {
    type: actionTypes.ANADIR_VERRACO_MATERIA_PRIMA,
    listVerracosMateriaPrima
  }
}

export function anadirPoolMateriaPrima(listPoolMateriaPrima){
  return {
    type: actionTypes.ANADIR_POOL_MATERIA_PRIMA,
    listPoolMateriaPrima
  }
}

export function editarVerracoPool(values){
  return {
    type: actionTypes.EDITAR_VERRACO_POOL,
    values
  }
}

export function editarVerracoPoolSuccess(listVerracoPool){
  return {
    type: actionTypes.EDITAR_VERRACO_POOL_SUCCESS,
    listVerracoPool
  }
}

export function editarVerracoMateriaPrima(values){
  return {
    type: actionTypes.EDITAR_VERRACO_MATERIA_PRIMA,
    values
  }
}

export function editarVerracoMateriaPrimaSuccess(listVerracosMateriaPrima){
  return {
    type: actionTypes.EDITAR_VERRACO_MATERIA_PRIMA_SUCCESS,
    listVerracosMateriaPrima
  }
}

export function editarPoolMateriaPrima(values){
  return {
    type: actionTypes.EDITAR_POOL_MATERIA_PRIMA,
    values
  }
}

export function editarPoolMateriaPrimaSuccess(listPoolMateriaPrima){
  return {
    type: actionTypes.EDITAR_POOL_MATERIA_PRIMA_SUCCESS,
    listPoolMateriaPrima
  }
}

export function guardarYnuevoVerracoPool(listVerracoPool){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VERRACO_POOL,
    listVerracoPool
  }
}

export function recuperarDatosModalVerracoPool(idElemento){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VERRACO_POOL,
    idElemento
  }
}

export function recuperarDatosModalVerracoPoolSuccess(dataVerracoPool){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VERRACO_POOL_SUCCESS,
    dataVerracoPool
  }
}

export function duplicarDatosModalVerracoPool(idElemento){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VERRACO_POOL,
    idElemento
  }
}

export function duplicarDatosModalVerracoPoolSuccess(dataDuplicarVerracoPool){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VERRACO_POOL_SUCCESS,
    dataDuplicarVerracoPool
  }
}

export function onDeleteVerracoPool(idElemento){
  return {
    type: actionTypes.ON_DELETE_VERRACO_POOL,
    idElemento
  }
}

export function seleccionarTodosVerracoPoolAnadidos () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS
  }
}

export function deSeleccionarTodosVerracoPoolAnadidos () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACO_POOL_ANADIDOS
  }
}

export function deleteSeleccionVerracoPool (listVerracoPool) {
  return {
    type: actionTypes.DELETE_SELECCION_VERRACO_POOL,
    listVerracoPool
  }
}

export function resetValidacionMateriaPrima () {
  return {
    type: actionTypes.RESET_VALIDACION_MATERIA_PRIMA
  }
}

export default {
  closeModalValidacionMateriaPrima,
  abrirModalValidacionMateriaPrima,
  submitNuevoValidacionMateriaPrima,
  submitNuevoValidacionMateriaPrimaSuccess,
  obtenerValidacionMateriaPrimasAlmacen,
  filtrarValidacionMateriaPrima,
  filtrarValidacionMateriaPrimaSuccess,
  filtrosValidacionMateriaPrimaSuccess,
  recuperarDatosModalValidacionMateriaPrima,
  recuperarDatosModalValidacionMateriaPrimaSuccess,
  editarValidacionMateriaPrima,
  duplicarDatosModalValidacionMateriaPrima,
  duplicarDatosModalValidacionMateriaPrimaSuccess,
  cambiarEstadoValidacionMateriaPrima,
  onDeleteValidacionMateriaPrima,
  seleccionarTodosValidacionMateriaPrima,
  deSeleccionarTodosValidacionMateriaPrima,
  cambiarEstadoSeleccionValidacionMateriaPrima,
  deleteSeleccionValidacionMateriaPrima,
  crearCsvValidacionMateriaPrima,
  imprimirPdfValidacionMateriaPrima,
  crearCsvValidacionMateriaPrima,
  crearCsvValidacionMateriaPrimaSuccess,
  imprimirPdfValidacionMateriaPrima,
  imprimirPdfValidacionMateriaPrimaSuccess,
  abrirModalVerracoPool,
  cerrarModalVerracoPool,
  obtenerVerracosMateriaPrima,
  obtenerPoolMateriaPrima,
  obtenerVerracosMateriaPrimaSuccess,
  obtenerPoolMateriaPrimaSucccess,
  submitNuevoVerracoPool,
  anadirVerracoMateriaPrima,
  anadirPoolMateriaPrima,
  editarVerracoMateriaPrima,
  editarPoolMateriaPrima,
  editarVerracoPool,
  guardarYnuevoVerracoPool,
  recuperarDatosModalVerracoPool,
  recuperarDatosModalVerracoPoolSuccess,
  duplicarDatosModalVerracoPoolSuccess,
  onDeleteVerracoPool,
  seleccionarTodosVerracoPoolAnadidos,
  deSeleccionarTodosVerracoPoolAnadidos,
  deleteSeleccionVerracoPool,
  guardarYnuevoValidacionMateriaPrima,
  guardarYnuevoValidacionMateriaPrimaSuccess,
  resetValidacionMateriaPrima
}
import actionTypes from '../../constants/actions/operario/operario'

export function initialState () {
  return {
    list: [],
    checkedFiltrosOperario: false,
    filtros:{},
    datosExcelOperario: [],
    datosPdfOperario: []
  }
}

export function fetchOperarioSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalOperarioSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalOperarioSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function cambiarEstadoOperarioSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteOperarioSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosOperario (state) {
  return {
    ...state,
    checkedFiltrosOperario: true
  }
}

export function deSeleccionarTodosOperario (state) {
  return {
    ...state,
    checkedFiltrosOperario: false
  }
}

export function fetchFiltrarOperarioSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function submitNuevaOperarioSuccess (state, {insercionOperario}) {
  return {
    ...state,
    insercionOperario
  }
}

export function editarOperarioSuccess (state, {edicionOperario}) {
  return {
    ...state,
    edicionOperario
  }
}

export function crearCsvOperarioSuccess (state, {datosExcelOperario}) {
  return {
    ...state,
    datosExcelOperario
  }
}

export function imprimirPdfOperarioSuccess (state, {datosPdfOperario}) {
  return {
    ...state,
    datosPdfOperario
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_OPERARIO_SUCCESS:
      return fetchOperarioSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_OPERARIO_SUCCESS:
      return recuperarDatosModalOperarioSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_OPERARIO_SUCCESS:
      return duplicarDatosModalOperarioSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_OPERARIO_SUCCESS:
      return cambiarEstadoOperarioSuccess(state, action)
    case actionTypes.ON_DELETE_OPERARIO_SUCCESS:
      return onDeleteOperarioSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_OPERARIO:
      return seleccionarTodosOperario(state, action)
    case actionTypes.DESELECCIONAR_TODOS_OPERARIO:
      return deSeleccionarTodosOperario(state, action)
    case actionTypes.FETCH_FILTRAR_OPERARIO_SUCCESS:
      return fetchFiltrarOperarioSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_OPERARIO_SUCCESS:
      return submitNuevaOperarioSuccess(state, action)
    case actionTypes.EDITAR_OPERARIO_SUCCESS:
      return editarOperarioSuccess(state, action)
    case actionTypes.CREATE_CSV_OPERARIO_SUCCESS:
      return crearCsvOperarioSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_OPERARIO_SUCCESS:
      return imprimirPdfOperarioSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal} from 'react-bootstrap'
import InputSelect from '../../comun/form/InputSelect'
import Button from '../../comun/button/Button'
import FiltrosModalAnnadirControlMorfologicoPage from '../../../containers/planSanitario/controlCalidad/FiltrosModalAnnadirControlMorfologicoPage'
import SimpleTablePage from '../../../containers/comun/table/SimpleTablePage'


class ModalInicioControlMorfologico extends Component {
   constructor () {
    super()
    this.state = {
      inputSelected: false,
    }
  }

  componentDidMount () {
    this.props.change('periodo', {value: 15, label: this.props.t('PLANING.15_DIAS')})// Set 15 days  
   
    
  }
  
  componentDidUpdate(prevProps){
    if (this.props.showModalInicio !== prevProps.showModalInicio) {
      this.props.change('periodo', {value: 15, label: this.props.t('PLANING.15_DIAS')})// Set 15 days
      //this.setState(filtros, {value: 15, label: this.props.t('PLANING.15_DIAS')})// Set 15 days
    }
  }

  closeModalInicio () {
    this.props.actions.closeModalInicio()
  }

  intercambiarCheck = (value) => {
    
    this.setState({ inputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracosInicio()
    } else {
      this.props.actions.deSeleccionarTodosVerracosInicio()
    }
  }


  fetchFiltrarModalInicio(values){
    values = {...values, ...this.props.filtros.values}
    console.log("fetchFiltrarPlaning",values.periodo)
    this.props.actions.fetchFiltrarModalInicio(values)
  }

  render () {

    const comboDiasPlaning = [
      {value: 0, label: this.props.t('PLANING.0_DIAS')},
      {value: 15, label: this.props.t('PLANING.15_DIAS')},
      {value: 30, label: this.props.t('PLANING.30_DIAS')},
      {value: 45, label: this.props.t('PLANING.45_DIAS')}
    ]

    const {
      t, handleSubmit, showModalInicio, filtros, checkedFiltrosListado, listVerracos, checkedVerracosAnadidos, numeroRegistrosPorPagina, paginaActual,
      actions:{onDeleteVerracoPlaning}
    } = this.props

    // TABLA VERRACO
    listVerracos.forEach(
      (row) => row.idElemento = row.idVerraco + 'extraer'
    )
    const tableInicio = {
      id: 'tableInicio',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      hasPagination: true,
      noBlockOptions: false,
      mostrarModificar: true,
      onEdit: (row) => {
        //tableInicio.initialValues['check' + row.idElemento] = true
      },
      columns: [
        {id: 'codVerraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'vigilado', name: t('FICHA_VERRACO.VIGILADO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'diasSinExtraer', name: t('PLAN_SANITARIO.CONTROL_MORFOLOGICO.COLUMNS.DIAS_ULTIMO_CONTROL'), type: 'numero', numDecimales: 0}
      ],
      filtros:<FiltrosModalAnnadirControlMorfologicoPage listVerracos={this.props.listVerracos}/>,
      rows: listVerracos,
      rowsVerraco: true,
      initialValues: {}
    }

    tableInicio.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableInicio.initialValues['check' + row.idElemento] = checkedVerracosAnadidos || checkedFiltrosListado
        }
      }
    )

  return (
      <Modal show={showModalInicio} onHide={() => this.closeModalInicio()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
              <span className='control-calidad-filtro-periodo'>
                {t('PLAN_SANITARIO.CONTROL_MORFOLOGICO.MODAL_INICIO.TITLE')}
              </span>
              <Field
                colSm={2}
                id="periodo"
                name="periodo"
                // controlLabel={t('PLANING.PERIODO')}
                component={InputSelect}
                options={comboDiasPlaning}
                valueKey="value"
                labelKey="label"
                customClass='control-calidad-filtro-periodo'
                isClearable={false}
                // onInputChange={(value) => this.elegirDiasPlaning(value)}
                onInputChange={handleSubmit(this.fetchFiltrarModalInicio.bind(this))}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-verracos">
              <SimpleTablePage {...tableInicio}></SimpleTablePage>
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalInicio()}>{t('PLANING.MODAL_INICIO.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalInicioControlMorfologico',
})(ModalInicioControlMorfologico))

export default {
  FETCH_FICHA_VERRACO: 'FETCH_FICHA_VERRACO',
  FETCH_FICHA_VERRACO_SUCCESS: 'FETCH_FICHA_VERRACO_SUCCESS',
  SUBMIT_NUEVO_VERRACO: 'SUBMIT_NUEVO_VERRACO',
  ASIGNAR_COD_RAZA: 'ASIGNAR_COD_RAZA',
  ASIGNAR_COD_RAZA_SUCCESS: 'ASIGNAR_COD_RAZA_SUCCESS',
  ABRIR_MODAL_VACIO_EVOLUCION: 'ABRIR_MODAL_VACIO_EVOLUCION',
  RESTART_VERRACO: 'RESTART_VERRACO',
  RESTART_VERRACO_SUCCESS: 'RESTART_VERRACO_SUCCESS',
  SUBMIT_NUEVA_EVOLUCION: 'SUBMIT_NUEVA_EVOLUCION',
  SUBMIT_NUEVA_EVOLUCION_SUCCESS: 'SUBMIT_NUEVA_EVOLUCION_SUCCESS',
  RECUPERAR_DATOS_MODAL_EVOLUCION: 'RECUPERAR_DATOS_MODAL_EVOLUCION',
  RECUPERAR_DATOS_MODAL_EVOLUCION_SUCCESS: 'RECUPERAR_DATOS_MODAL_EVOLUCION_SUCCESS',
  EDITAR_EVOLUCION: 'EDITAR_EVOLUCION',
  EDITAR_EVOLUCION_SUCCESS: 'EDITAR_EVOLUCION_SUCCESS',
  DELETE_EVOLUCION: 'DELETE_EVOLUCION',
  DELETE_EVOLUCION_SUCCESS: 'DELETE_EVOLUCION_SUCCESS',
  DUPLICAR_DATOS_MODAL_EVOLUCION: 'DUPLICAR_DATOS_MODAL_EVOLUCION',
  DUPLICAR_DATOS_MODAL_EVOLUCION_SUCCESS: 'DUPLICAR_DATOS_MODAL_EVOLUCION_SUCCESS',
  SELECCIONAR_TODOS_EVOLUCION: 'SELECCIONAR_TODOS_EVOLUCION',
  DESELECCIONAR_TODOS_EVOLUCION: 'DESELECCIONAR_TODOS_EVOLUCION',
  DELETE_SELECCION_EVOLUCION: 'DELETE_SELECCION_EVOLUCION',
  GUARDAR_Y_NUEVO_EVOLUCION: 'GUARDAR_Y_NUEVO_EVOLUCION',
  ABRIR_MODAL_VACIO_ALIMENTACION: 'ABRIR_MODAL_VACIO_ALIMENTACION',
  ABRIR_MODAL_VACIO_ALIMENTACION_SUCCESS: 'ABRIR_MODAL_VACIO_ALIMENTACION_SUCCESS',
  GET_ALIMENTACION: 'GET_ALIMENTACION',
  GET_ALIMENTACION_SUCCESS: 'GET_ALIMENTACION_SUCCESS',
  SUBMIT_NUEVA_ALIMENTACION: 'SUBMIT_NUEVA_ALIMENTACION',
  SUBMIT_NUEVA_ALIMENTACION_SUCCESS: 'SUBMIT_NUEVA_ALIMENTACION_SUCCESS',
  RECUPERAR_DATOS_MODAL_ALIMENTACION: 'RECUPERAR_DATOS_MODAL_ALIMENTACION',
  RECUPERAR_DATOS_MODAL_ALIMENTACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_ALIMENTACION_SUCCESS',
  EDITAR_ALIMENTACION: 'EDITAR_ALIMENTACION',
  EDITAR_ALIMENTACION_SUCCESS: 'EDITAR_ALIMENTACION_SUCCESS',
  DELETE_ALIMENTACION: 'DELETE_ALIMENTACION',
  DELETE_ALIMENTACION_SUCCESS: 'DELETE_ALIMENTACION_SUCCESS',
  DUPLICAR_DATOS_MODAL_ALIMENTACION: 'DUPLICAR_DATOS_MODAL_ALIMENTACION',
  DUPLICAR_DATOS_MODAL_ALIMENTACION_SUCCESS: 'DUPLICAR_DATOS_MODAL_ALIMENTACION_SUCCESS',
  SELECCIONAR_TODOS_ALIMENTACION: 'SELECCIONAR_TODOS_ALIMENTACION',
  DESELECCIONAR_TODOS_ALIMENTACION: 'DESELECCIONAR_TODOS_ALIMENTACION',
  DELETE_SELECCION_ALIMENTACION: 'DELETE_SELECCION_ALIMENTACION',
  GUARDAR_Y_NUEVO_ALIMENTACION: 'GUARDAR_Y_NUEVO_ALIMENTACION',
  OPEN_MODAL_ALIMENTACION: 'OPEN_MODAL_ALIMENTACION',
  CLOSE_MODAL_ALIMENTACION: 'CLOSE_MODAL_ALIMENTACION',
  ABRIR_MODAL_VACIO_INDEX: 'ABRIR_MODAL_VACIO_INDEX',
  ABRIR_MODAL_VACIO_INDEX_SUCCESS: 'ABRIR_MODAL_VACIO_INDEX_SUCCESS',
  GET_INDEX: 'GET_INDEX',
  GET_INDEX_SUCCESS: 'GET_INDEX_SUCCESS',
  SUBMIT_NUEVA_INDEX: 'SUBMIT_NUEVA_INDEX',
  SUBMIT_NUEVA_INDEX_SUCCESS: 'SUBMIT_NUEVA_INDEX_SUCCESS',
  RECUPERAR_DATOS_MODAL_INDEX: 'RECUPERAR_DATOS_MODAL_INDEX',
  RECUPERAR_DATOS_MODAL_INDEX_SUCCESS: 'RECUPERAR_DATOS_MODAL_INDEX_SUCCESS',
  EDITAR_INDEX: 'EDITAR_INDEX',
  EDITAR_INDEX_SUCCESS: 'EDITAR_INDEX_SUCCESS',
  DELETE_INDEX: 'DELETE_INDEX',
  DELETE_INDEX_SUCCESS: 'DELETE_INDEX_SUCCESS',
  DUPLICAR_DATOS_MODAL_INDEX: 'DUPLICAR_DATOS_MODAL_INDEX',
  DUPLICAR_DATOS_MODAL_INDEX_SUCCESS: 'DUPLICAR_DATOS_MODAL_INDEX_SUCCESS',
  SELECCIONAR_TODOS_INDEX: 'SELECCIONAR_TODOS_INDEX',
  DESELECCIONAR_TODOS_INDEX: 'DESELECCIONAR_TODOS_INDEX',
  DELETE_SELECCION_INDEX: 'DELETE_SELECCION_INDEX',
  GUARDAR_Y_NUEVO_INDEX: 'GUARDAR_Y_NUEVO_INDEX',
  OPEN_MODAL_INDEX: 'OPEN_MODAL_INDEX',
  CLOSE_MODAL_INDEX: 'CLOSE_MODAL_INDEX',
  ABRIR_MODAL_UBICACION: 'ABRIR_MODAL_UBICACION',
  CERRAR_MODAL_UBICACION: 'CERRAR_MODAL_UBICACION',
  CAMBIAR_VIGILADO: 'CAMBIAR_VIGILADO',
  ON_DELETE_VERRACO: 'ON_DELETE_VERRACO',
  SELECCIONAR_TODOS_FICHA_VERRACO: 'SELECCIONAR_TODOS_FICHA_VERRACO',
  DESELECCIONAR_TODOS_FICHA_VERRACO: 'DESELECCIONAR_TODOS_FICHA_VERRACO',
  CAMBIAR_VIGILADO_SELECCION_FICHA_VERRACO: 'CAMBIAR_VIGILADO_SELECCION_FICHA_VERRACO',
  DELETE_SELECCION_FICHA_VERRACO: 'DELETE_SELECCION_FICHA_VERRACO',
  ABRIR_MODAL_CAMBIO_ESTADO_VERRACO: 'ABRIR_MODAL_CAMBIO_ESTADO_VERRACO',
  CERRAR_MODAL_CAMBIO_ESTADO_VERRACO: 'CERRAR_MODAL_CAMBIO_ESTADO_VERRACO',
  GUARDAR_Y_NUEVO_VERRACO: 'GUARDAR_Y_NUEVO_VERRACO',
  FETCH_FILTRAR_FICHA_VERRACO: 'FETCH_FILTRAR_FICHA_VERRACO',
  FETCH_FILTRAR_FICHA_VERRACO_SUCCESS: 'FETCH_FILTRAR_FICHA_VERRACO_SUCCESS',
  ABRIR_MODAL_VERRACO_CAMBIO_ESTADO: 'ABRIR_MODAL_VERRACO_CAMBIO_ESTADO',
  CERRAR_MODAL_VERRACO_CAMBIO_ESTADO: 'CERRAR_MODAL_VERRACO_CAMBIO_ESTADO',
  GUARDAR_DATOS_CAMBIO_ESTADO: 'GUARDAR_DATOS_CAMBIO_ESTADO',
  OBTENER_PRESENCIA_VERRACO: 'OBTENER_PRESENCIA_VERRACO',
  OBTENER_PRESENCIA_VERRACO_SUCCESS: 'OBTENER_PRESENCIA_VERRACO_SUCCESS',
  GUARDAR_DATOS_UBICACION: 'GUARDAR_DATOS_UBICACION',
  FETCH_DUPLICAR_VERRACO: 'FETCH_DUPLICAR_VERRACO',
  FETCH_DUPLICAR_VERRACO_SUCCESS: 'FETCH_DUPLICAR_VERRACO_SUCCESS',
  ABRIR_MODAL_EVOLUCION: 'ABRIR_MODAL_EVOLUCION',
  CLOSE_MODAL_EVOLUCION: 'CLOSE_MODAL_EVOLUCION',
  CARGAR_EVOLUCION: 'CARGAR_EVOLUCION',
  ABRIR_MODAL_INDEX: 'ABRIR_MODAL_INDEX',
  CARGAR_INDEX: 'CARGAR_INDEX',
  CREATE_CSV_FICHA_VERRACO: 'CREATE_CSV_FICHA_VERRACO',
  CREATE_CSV_FICHA_VERRACO_SUCCESS: 'CREATE_CSV_FICHA_VERRACO_SUCCESS',
  IMPRIMIR_PDF_FICHA_VERRACO: 'IMPRIMIR_PDF_FICHA_VERRACO',
  IMPRIMIR_PDF_FICHA_VERRACO_SUCCESS: 'IMPRIMIR_PDF_FICHA_VERRACO_SUCCESS',
  GUARDAR_Y_NUEVO_VERRACO_SUCCESS: 'GUARDAR_Y_NUEVO_VERRACO_SUCCESS',
  CARGAR_ALIMENTACION: 'CARGAR_ALIMENTACION',
  IMPRIMIR_DETALLE_VERRACO: 'IMPRIMIR_DETALLE_VERRACO',
  IMPRIMIR_DETALLE_VERRACO_SUCCESS: 'IMPRIMIR_DETALLE_VERRACO_SUCCESS',
  RESET_UBICACION: 'RESET_UBICACION',
  RESTART_VERRACO_DEJANDO_FILTROS: 'RESTART_VERRACO_DEJANDO_FILTROS'
}
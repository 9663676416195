import React, { PureComponent } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import './layout.scss'
import HeaderPage from '../../containers/layout/HeaderPage'
import FooterPage from '../../containers/layout/FooterPage'

class PublicLayout extends PureComponent {
  render () {
    return (
      <Grid fluid className="public-layout">
        <Row>
          <HeaderPage sm={12}/>
        </Row>
        <Row>
          <Col sm={12} className="main-container">
            {this.props.children}
          </Col>
        </Row>
        <Row>
          <FooterPage sm={12} />
        </Row>
      </Grid>
    )
  }
}

export default PublicLayout

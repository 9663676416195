import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import Notificaciones from '../../components/notificaciones/Notificaciones'
import {obtenerNotificaconesUsuario, marcarComoLeidoNotificacion, marcarComoLeidoNotificacionStock} from '../../actions/notificaciones/notificaciones'
import {abrirModalAgenda} from '../../actions/agenda/agenda'
import {recuperarDatosModalProducto} from '../../actions/producto/producto'

export function mapStateToProps (state) {
  return {
    ...state.notificaciones,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    urlRecursos: setting.default.urlRecursos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerNotificaconesUsuario,
      abrirModalAgenda,
      recuperarDatosModalProducto,
      marcarComoLeidoNotificacion,
      marcarComoLeidoNotificacionStock
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Notificaciones))
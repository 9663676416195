import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import InputSelect from '../comun/form/InputSelect'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import ModalPedidosVentaPage from '../../containers/pedidosVenta/ModalPedidosVentaPage'
import ModalPrevisionPedidosPage from '../../containers/pedidosVenta/ModalPrevisionPedidosPage'
import FiltrosPedidosVentaPage from '../../containers/pedidosVenta/FiltrosPedidosVentaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './PedidosVenta.scss'
import {date_formatter, date_parser, dateTimeZones} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class PedidosVenta extends Component {

  UNSAFE_componentWillMount () {
    document.title = this.props.t('MENU.PEDIDO.PEDIDOS_VENTA') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    this.props.actions.fetchFiltrarPedidosVenta({fechaEntrada: fechaActual})
    this.props.actions.comprobarEntorno()
    
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, checkedPedidosVenta, listPedidosVenta, data, dataDuplicar, isCloud,
      actions: {recuperarDatosModalPedidosVenta, duplicarDatosModalPedidosVenta, onDeletePedidosVenta}
    } = this.props

    listPedidosVenta.forEach(
      (row) => row.idElemento = row.idPedido
    )
    let _leyenda = [
      {name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'), color: '#9eccf3'},
      {name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.CANCELADO'), color: '#f98f8f'},
      {name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.CONFIRMADO'), color: '#efd038'},
      {name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.REALIZADO'), color: '#74de7c'},     
    ]
    if(isCloud){ _leyenda.push({name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.PENDIENTE_DE_RECIBIR'), color: '#de74de'})}
    
    const table = {
      id: 'pedidosVenta',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1),
      hasOptions: true,
      hasPagination: true,
      mostrarCambioEstado: false,
      filtros: <FiltrosPedidosVentaPage/>,
      columns: [
        {id: 'numeroPedido', name: t('PEDIDOS.PEDIDOS_VENTA.NUM_PEDIDO')},
        {id: 'idEstado', name: t('PEDIDOS.PEDIDOS_VENTA.ESTADO'), stilyzer: (row) => {
          switch(row.idEstado) {
            case 1:
              return <span className='estadopedido-borrador'>{t('COMUN.ESTADO_PEDIDO.BORRADOR')}</span>
            case 2:
              return <span className='estadopedido-cancelado'>{t('COMUN.ESTADO_PEDIDO.CANDELADO')}</span>
            case 3:
              return <span className='estadopedido-confirmado'>{t('COMUN.ESTADO_PEDIDO.CONFIRMADO')}</span>
            case 4:
              return <span className='estadopedido-realizado'>{t('COMUN.ESTADO_PEDIDO.REALIZADO')}</span>
            default:
              return 'ningun';
          }
        }},
        {id: 'razonSocialCliente', name: t('PEDIDOS.PEDIDOS_VENTA.CLIENTE')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')},
        {id: 'fecha', name: t('PEDIDOS.PEDIDOS_VENTA.FECHA'), type: 'fecha'},
        {id: 'numeroDosis', name: t('PEDIDOS.PEDIDOS_VENTA.NUM_DOSIS'), type: 'numero', numDecimales:0},
        //{id: 'nombreDosis', name: t('PEDIDOS.PEDIDOS_VENTA.TIPO_DOSIS')},
        {id: 'nombreRaza', name: t('PEDIDOS.PEDIDOS_VENTA.RAZA')},
        {id: 'lineasGeneticas', name: t('PEDIDOS.PEDIDOS_VENTA.LINEA_GENETICA')},
        {id: 'nombreRuta', name: t('PEDIDOS.PEDIDOS_VENTA.RUTA')},
        {id: 'observaciones', name: t('PEDIDOS.PEDIDOS_VENTA.DESCRIPCION')},
      ],
      rows: listPedidosVenta,
      onEdit: (row) => recuperarDatosModalPedidosVenta(row.idPedido),
      data: data,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1),
      duplicar: (row) =>duplicarDatosModalPedidosVenta(row.idPedido),
      dataDuplicar: dataDuplicar,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1),
      onDelete: (row) => onDeletePedidosVenta(row.idPedido),
      showModal: this.props.showModalPedidosVenta,
      initialValues: {},
      
      leyenda: _leyenda,
      
      
      //if(){ table.leyenda.push({name: t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.PENDIENTE_DE_RECIBIR'), color: '#de74de'})},
      
      colorearRow: (row) => {
        switch(!row.bloqueado && isCloud) {
          case true:
            return row.idEstado==3  ?'estadopedido-bloqueado':'ningun';
          // case 2:
          //   return 'estadopedido-cancelado'
          // case 3:
          //   return 'estadopedido-confirmado'
          // case 4:
          //   return 'estadopedido-realizado'
          default:
            return 'ningun';
        }
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedPedidosVenta
        }
      }
    )

    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalPedidosVentaPage />
        <ModalPrevisionPedidosPage />
        <h2 className="page-title">{t('PEDIDOS.PEDIDOS_VENTA.TITLE')}</h2>
        <div className="clearfix"></div>
        <Row>
          <Col sm={12}>
            <SimpleTablePage {...table}></SimpleTablePage>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formPedidosVenta',
})(PedidosVenta))
import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import ModalMicrobiologiaPage from '../../../containers/planSanitario/microbiologia/ModalMicrobiologiaPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosNivelGeneticoPage from '../../../containers/nivelGenetico/FiltrosNivelGeneticoPage'
import FiltrosMicrobiologiaPage from '../../../containers/planSanitario/microbiologia/FiltrosMicrobiologiaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {modulos as modulosConstant} from '../../../constants/modulos'
import { Row, Col} from 'react-bootstrap'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter, dateTimeZones, date_parser} from '../../../util/formatFunctions'

class Microbiologia extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.MICROBIOLOGIA') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    this.props.actions.fetchFiltrarMicrobiologia({fechaEntrada: fechaActual})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      microbiologia: { list, data, dataDelete, checkedFiltrosMicrobiologia},
      actions: {recuperarDatosModalMicrobiologia, onDeleteMicrobiologia}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.id
    )

    const table = {
      id: 'microbiologiaTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: true,
      columns: [
        {id: 'fecha', name: t('MICROBIOLOGIA.TABLA.FECHA'), type: 'fecha'},        
        {id: 'tipo', name: t('MICROBIOLOGIA.TABLA.TIPO'), stilyzer: (row) => {
          switch(row.tipo) {
            case 1:
              return <span>{t('MICROBIOLOGIA.TABLA.TIPO1')}</span>
            case 2:
              return <span>{t('MICROBIOLOGIA.TABLA.TIPO2')}</span>
            case 3:
              return <span>{t('MICROBIOLOGIA.TABLA.TIPO3')}</span> 
            case 4:
              return <span>{t('MICROBIOLOGIA.TABLA.TIPO4')}</span>            
            default:
              return 'ninguno';
          }
        }},
        {id: 'nombre_tipo', name: t('MICROBIOLOGIA.TABLA.NOMBRE_TIPO')},        
      ],
      rows: list, 
      filtros: <FiltrosMicrobiologiaPage />,         
      onEdit: (row) => recuperarDatosModalMicrobiologia(row.id),
      data: data,      
      mostrarEliminar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      onDelete: (row) => onDeleteMicrobiologia(row.id),
      dataDelete: dataDelete,      
      initialValues: {}
    } 
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosMicrobiologia
        }
      }
    )   
    return (
      <div>        
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalMicrobiologiaPage/>
        <ListadoPage t_key="MICROBIOLOGIA." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default Microbiologia
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import NivelGenetico from '../../components/nivelGenetico/NivelGenetico'
import {fetchNivelGenetico, recuperarDatosModalNivelGenetico, onDeleteNivelGenetico} from '../../actions/nivelGenetico/nivelGenetico'

export function mapStateToProps (state) {
  return {
    nivelGenetico: {...state.nivelGenetico},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchNivelGenetico,
      recuperarDatosModalNivelGenetico,
      onDeleteNivelGenetico
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NivelGenetico))
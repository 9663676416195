import React, {Component} from 'react'
import Select from 'react-select'
import { components } from 'react-select'

const SelectContainer = ({ children, ...props }) => {
  return (
    <div title={props.selectProps.title} onClick={props.selectProps.onClick} >
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    </div>
  );
};

const defaultStyles = {
  control: (styles, state) => {
    let newStyles = {
      ...styles
    }
    if (state.isDisabled) {
      newStyles.backgroundColor = '#eee'
      newStyles.borderColor = '#ccc';
    }
    return newStyles
  },
  indicatorSeparator: (styles, state) => {
    let newStyles = {
      ...styles
    }
    if (state.isDisabled) {
      newStyles.borderColor = '#ccc';
    }
    return newStyles
  },
  menu: styles => ({
    ...styles,
    zIndex: 2
  }),
  input: (styles, state) => {
    let newStyles = {
      ...styles
    }
    return newStyles
  },
  singleValue: (styles, state) => {
    let newStyles = {
      ...styles
    }
    if (state.isDisabled) {
      newStyles.color = '#555';
    }
    return newStyles
  },
  placeholder: (styles, state) => {
    let newStyles = {
      ...styles,
      color: '#999',
      opacity: '0.4'
    }
    return newStyles
  }
}

const smallSizeStyles = {
  control: (styles, state) => {
    let newStyles = {
      ...styles,
      height: 25,
      minHeight: 'fit-content'
    }
    if (state.isDisabled) {
      newStyles.backgroundColor = '#eee'
      newStyles.borderColor = '#ccc';
    }
    return newStyles
  },
  dropdownIndicator: styles => ({
    ...styles,
    padding: 0
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: 0
  }),
  indicatorSeparator: (styles, state) => {
    let newStyles = {
      ...styles
    }
    if (state.isDisabled) {
      newStyles.borderColor = '#ccc';
    }
    return newStyles
  },
  valueContainer: styles => ({
    ...styles,
    maxHeight: 25
  }),
  menu: styles => ({
    ...styles,
    zIndex: 2
  }),
  menuList: styles => ({
    ...styles,
    padding: 0
  }),
  input: (styles, state) => {
    let newStyles = {
      ...styles,
      margin: 0,
      padding: 0
    }
    return newStyles
  },
  singleValue: (styles, state) => {
    let newStyles = {
      ...styles
    }
    if (state.isDisabled) {
      newStyles.color = '#555';
    }
    return newStyles
  },
  placeholder: (styles, state) => {
    let newStyles = {
      ...styles,
      color: '#999',
      opacity: '0.4'
    }
    return newStyles
  }
}

class MySelect extends Component {

  handleChange (value) {
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
  }

  handleClick (value) {
    if (this.props.onClick) {
      this.props.onClick(value)
    }
  }

  filterOption = ({ label, value, data }, string) => {
    // default search
    //if (label.includes(string) || value.includes(string)) return true;
    if (string && label) {
      // return label.includes(string);
      return (""+label).toLowerCase().includes((""+string).toLowerCase());
    } else {
      return true;
    }
  };

  render () {
    const {
      id, labelKey = 'label', valueKey = 'value', options, value = null, menuContainerStyle = {},
      placeholder, disabled = false, controlLabel, multi, defaultValue = null, isSmall = false, isClearable = true, setMyRef,
      menuPlacement = "auto"
    } = this.props
    return (
      <Select
        className="select-control"
        id={id}
        menuContainerStyle={menuContainerStyle}
        isDisabled={disabled}
        placeholder={placeholder}
        components={{SelectContainer}}
        title={controlLabel}
        options={options}
        getOptionValue={(option) => option[valueKey]}
        getOptionLabel={(option) => option[labelKey]}
        value={value || defaultValue}
        onChange={this.handleChange.bind(this)}
        onClick={this.handleClick.bind(this)}
        multi={multi}
        searchable
        isClearable={isClearable}
        menuPlacement={menuPlacement}
        styles={isSmall ? smallSizeStyles : defaultStyles}
        filterOption={this.filterOption}
        ref={setMyRef}
      />
    )
  }
}

export default MySelect

import React, { Component } from 'react'
import { Modal, Button, Col } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { Warning } from '@material-ui/icons'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import './EntradaMercancia.scss'
import FiltrosEntradaMercanciaProductoPage from '../../containers/entradaMercancia/FiltrosEntradaMercanciaProductoPage'
import ModalEntradaMercanciaAnadirProductoPage from '../../containers/entradaMercancia/ModalEntradaMercanciaAnadirProductoPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalEntradaMercancia extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.data !== prevProps.data) {
      if (Object.keys(this.props.data).length > 0) {
        let entradaMercanciaProductos = this.props.data
        this.props.actions.editarEntradaMercanciaProductoSuccess(entradaMercanciaProductos.entradaAlmacenProductos)
      }
    }

    if (this.props.dataDuplicar !== prevProps.dataDuplicar) {
      if (Object.keys(this.props.dataDuplicar).length > 0) {
        let entradaMercanciaProductos = this.props.dataDuplicar
        this.props.actions.editarEntradaMercanciaProductoSuccess(entradaMercanciaProductos.entradaAlmacenProductos)
      }
    }

    if (this.props.entradaMercanciaProductos !== prevProps.entradaMercanciaProductos){
      if (Object.keys(this.props.entradaMercanciaProductos).length > 0){
        this.setState({mostrarEnviarAviso: false})
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.mostrarEnviarAviso === true) {
      this.props.dispatch(setSubmitFailed('ModalEntradaMercancia'))
    }
  }

  closeModalEntradaMercancia () {
    this.props.actions.closeModalEntradaMercancia()
    this.setState({mostrarEnviarAviso: false})
  }

  submitNuevoEntradaMercancia (values) {
    values.entradaMercanciaProductos = this.props.entradaMercanciaProductos
    if (Object.keys(this.props.entradaMercanciaProductos).length > 0){
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarEntradaMercancia(values)
      } else {
        this.props.actions.submitNuevoEntradaMercancia(values)
      }
      this.setState({mostrarEnviarAviso: false})
    } else {
      this.setState({mostrarEnviarAviso: true})
    }
  }

  guardarYnuevoEntradaMercancia (values) {
    values.entradaMercanciaProductos = this.props.entradaMercanciaProductos
    if (Object.keys(this.props.entradaMercanciaProductos).length > 0){
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarEntradaMercancia(values)
      } else {
        this.props.actions.guardarYnuevoEntradaMercancia(values)
      }
      this.setState({mostrarEnviarAviso: false})
    } else {
      this.setState({mostrarEnviarAviso: true})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalEntradaMercancia, listEntradaMercancia, data, dataProducto, entradaMercanciaProductos,
      numeroRegistrosPorPagina, paginaActual, duplicar, checkedFiltrosEntradaMercanciaProducto, submitting,
      actions: {recuperarDatosModalEntradaMercancia, abrirModalEntradaMercancia, recuperarModalEntradaMercanciaProductoAnadido, duplicarDatosModalEntradaMercanciaAnadido, onDeleteEntradaMercanciaProducto}
    } = this.props

    // TABLA PRODUCTOS
    entradaMercanciaProductos.forEach(
      (row, idRow) => row.idElemento = idRow + 1
    )

    const tableProductos = {
      id: 'productosTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      mostrarCambioEstado: false,
      tablaModal: true,
      idBlockOptions: 1,
      columns: [
        {id: 'nombre', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.PRODUCTO')},
        {id: 'cantidad', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CANTIDAD')},
        // {id: 'stockProduccion', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.STOCK_PRODUCCION')},
        {id: 'lote', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.LOTE')},
        {id: 'fechaCaducidad', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CADUCIDAD'), type: 'fecha'},
        {id: 'acabado', name: t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.ACABADO')}
      ],
      rows: entradaMercanciaProductos,
      filtros: <FiltrosEntradaMercanciaProductoPage/>,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      onEdit: (row) => recuperarModalEntradaMercanciaProductoAnadido(row.idElemento),
      data: dataProducto,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      duplicar: (row) =>duplicarDatosModalEntradaMercanciaAnadido(row.idElemento),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      onDelete: (row) => onDeleteEntradaMercanciaProducto(row.idElemento, row.idEntradaAlmacenProducto),
      showModal: this.props.showModalEntradaMercanciaProducto,
      initialValues: {}
    }
    tableProductos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableProductos.initialValues['check' + row.idElemento] = checkedFiltrosEntradaMercanciaProducto
        }
      }
    )


    return (
      <div>
        {
        <ModalEntradaMercanciaAnadirProductoPage
          duplicar={(row) =>duplicarDatosModalEntradaMercanciaAnadido(row.idElemento)}
        />
        }
        <Modal show={showModalEntradaMercancia} onHide={() => this.closeModalEntradaMercancia()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-entradaMercancia">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(this.props.data).length > 0 ? (
                t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_EDITAR')
              ) : (
                Object.keys(this.props.dataDuplicar).length > 0 ? (
                  t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_DUPLICAR')
                ) : (
                  t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
            {data && data.entradaAlmacen && data.entradaAlmacen.idEntradaAlmacen && (
              <ButtonChangeRecord list={listEntradaMercancia} idElemento="idEntradaAlmacen" currentId={data.entradaAlmacen.idEntradaAlmacen} getNextRecord={recuperarDatosModalEntradaMercancia} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="numeroAlbaran"
                name="numeroAlbaran"
                component={InputText}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.N_ALBARAN') + '*'}
                customClass="numeroAlbaran"
                validate={required}
                maxLength={true}
                valorMaxLength={40}
              />
              <Field
                colSm={3}
                id="proveedor"
                name="proveedor"
                component={InputText}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.PROVEEDOR') + '*'}
                customClass="proveedor"
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={3}
                id="origen"
                name="origen"
                component={InputText}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.ORIGEN')}
                customClass="origen"
                maxLength={true}
                valorMaxLength={80}
              />
              <Field
                colSm={3}
                id="fecha"
                name="fecha"
                component={InputDatePicker}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.FECHA') + '*'}
                customClass="fecha"
                validate={required}
              />
              <div className="clearfix"></div>
              <Col sm={12}>
                <FormSubtitle>{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITULO_TABLA_PRODUCTOS')}</FormSubtitle>
                <SimpleTablePage {...tableProductos}>
                </SimpleTablePage>
              </Col>
              <div className="clearfix"></div>
              {
                this.state.mostrarEnviarAviso === true && (
                  <Col sm={12}>
                    <span className='help-block'>
                      <Warning /><span className="texto-alerta-entrada-mercancia">{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.VALIDACION_TABLA')}</span>
                    </span>
                  </Col>
                )
              }
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.OBSERVACIONES')}
                customClass='observaciones'
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(this.props.data).length > 0 ? (
                <Button
                  type="button"
                  className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) ? handleSubmit(duplicar) : undefined}
                >{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.DUPLICAR')}</Button>
              ) : (
                <Button type="button" className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoEntradaMercancia.bind(this)) : null}>{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.GUARDAR_Y_NUEVO')}</Button>
              )
            }
            <Button
              type="button"
              onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoEntradaMercancia.bind(this)) : null}
              className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2)}
            >{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalEntradaMercancia()}>{t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalEntradaMercancia',
})(ModalEntradaMercancia))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import Button from '../comun/button/Button'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosModalPedidos extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      showErrorGranja: false
    }
  }

  fetchFiltrarPedidosVenta (values) {
    console.log(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheckDatosPedido (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalPedidos()
    } else {
      this.props.actions.deSeleccionarTodosModalPedidos()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-datos-pedido', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalPedidos()
          }
        }
      }
    }

    if(this.props.hayCliente !== null && this.props.hayGranja !== null && this.state.showErrorGranja == true){
      this.setState({ showErrorGranja: false })
    }
  }

  deleteSeleccionDatosDosis(){
    const itemsSeleccion = this.props.listPedidosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === false && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'verraco': row.verraco,
          'raza': row.raza,
          'lineaGenetica': row.lineaGenetica,
          'tipoDosis': row.tipoDosis,
          'cantidad': row.cantidad,
          'idElemento': row.idElemento,
          'idVerraco': row.idVerraco,
          'idRaza': row.idRaza,
          'idLineaGenetica': row.idLineaGenetica,
          'idTipoDosis': row.idTipoDosis
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === false
    });
    this.props.actions.editarDatosDosisSuccess(list)
  }

  render () {
  const {
    t, handleSubmit, filtros, estadoPedido, hayCliente, hayGranja,
    actions: {abrirModalDatosDosis}
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  {
                    estadoPedido !== 2 && estadoPedido !== 3 && estadoPedido !== 4 ? (
                      <Field
                        id="seleccionar-todo-datos-pedido"
                        name="seleccionar-todo-datos-pedido"
                        colSm={12}
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheckDatosPedido(value)}
                      />
                    ) : null
                  }
                </div>,
                <div id="block-options1" key="block-options1" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionDatosDosis()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              {
                estadoPedido !== 2 && estadoPedido !== 3 && estadoPedido !== 4 ? (
                  <div className="button-group buttons-min">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#new-edit-modal"
                      className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && (hayCliente !== null && hayGranja !== null) ? () => abrirModalDatosDosis(hayGranja) : () => this.setState({ showErrorGranja: true })}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                    {
                      this.state.showErrorGranja ? (
                        <span className='error-linea-pedido'><Warning/>{t('PEDIDOS.PEDIDOS_VENTA.TIENES_SELECCIONAR_CLIENTE')}</span>
                      ) : null
                    }
                  </div>
                ) : null
              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalPedidos',
})(FiltrosModalPedidos))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert as BootstrapAlert } from 'react-bootstrap'

class Alert extends Component {
  render () {
    const { className, bsStyle } = this.props
    return (
      <BootstrapAlert className={className} bsStyle={bsStyle}>
        {this.props.children}
      </BootstrapAlert>
    )
  }
}

Alert.propTypes = {
  bsStyle: PropTypes.string.isRequired
};

export default Alert

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import ModalCambiarTokenMagavision from '../../components/usuarios/ModalCambiarTokenMagavision'
import {cerrarModalCambiarTokenMagavision, submitCambiarTokenMagavision} from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalCambiarTokenMagavision,
      submitCambiarTokenMagavision
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCambiarTokenMagavision))
import actionTypes from '../../constants/actions/operario/operario'

export function fetchOperario () {
  return {
    type: actionTypes.FETCH_OPERARIO
  }
}

export function fetchOperarioSuccess (list) {
  return {
    type: actionTypes.FETCH_OPERARIO_SUCCESS,
    list
  }
}

export function submitNuevaOperario (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_OPERARIO,
    values
  }
}

export function submitNuevaOperarioSuccess (insercionOperario) {
  return {
    type: actionTypes.SUBMIT_NUEVA_OPERARIO_SUCCESS,
    insercionOperario
  }
}

export function recuperarDatosModalOperario (idOperario) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_OPERARIO,
    idOperario
  }
}

export function recuperarDatosModalOperarioSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_OPERARIO_SUCCESS,
    data
  }
}

export function abrirModalVacioOperario () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_OPERARIO
  }
}

export function editarOperario (values) {
  return {
    type: actionTypes.EDITAR_OPERARIO,
    values
  }
}

export function editarOperarioSuccess (edicionOperario) {
  return {
    type: actionTypes.EDITAR_OPERARIO_SUCCESS,
    edicionOperario
  }
}

export function duplicarDatosModalOperario (idOperario) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_OPERARIO,
    idOperario
  }
}

export function duplicarDatosModalOperarioSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_OPERARIO_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoOperario (idOperario) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_OPERARIO,
    idOperario
  }
}

export function cambiarEstadoOperarioSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_OPERARIO_SUCCESS,
    dataEstado
  }
}

export function onDeleteOperario (idOperario, nombreOperario, apellidosOperario, localidadOperario) {
  return {
    type: actionTypes.ON_DELETE_OPERARIO,
    idOperario,
    nombreOperario,
    apellidosOperario,
    localidadOperario
  }
}

export function onDeleteOperarioSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_OPERARIO_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarOperario (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_OPERARIO,
    values
  }
}

export function fetchFiltrarOperarioSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_OPERARIO_SUCCESS,
    filtros
  }
}

export function seleccionarTodosOperario(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_OPERARIO
  }
}

export function deSeleccionarTodosOperario(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_OPERARIO
  }
}

export function cambiarEstadoSeleccionOperario(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_OPERARIO,
    list
  }
}

export function deleteSeleccionOperario(list){
  return {
    type: actionTypes.DELETE_SELECCION_OPERARIO,
    list
  }
}

export function crearCsvOperario(list){
  return {
    type: actionTypes.CREATE_CSV_OPERARIO,
    list
  }
}

export function crearCsvOperarioSuccess(datosExcelOperario){
  return {
    type: actionTypes.CREATE_CSV_OPERARIO_SUCCESS,
    datosExcelOperario
  }
}

export function imprimirPdfOperario(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_OPERARIO,
    list
  }
}

export function imprimirPdfOperarioSuccess(datosPdfOperario){
  return {
    type: actionTypes.IMPRIMIR_PDF_OPERARIO_SUCCESS,
    datosPdfOperario
  }
}

export function guardarYnuevoOperario(values){
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_OPERARIO,
    values
  }
}

export function cambiarOperarioProcesado(idOperario){
  return{
    type: actionTypes.CAMBIAR_OPERARIO_PROCESADO,
    idOperario
  }
}

export function cambiarOperarioAlbaran(idOperario){
  return{ 
    type: actionTypes.CAMBIAR_OPERARIO_ALBARAN,
    idOperario
  }
}

export function imprimirEtiquetaOperario (values) {
  return {
    type: actionTypes.IMPRIMIR_ETIQUETA_OPERARIO,
    values
  }
}

export default {
  fetchOperario,
  fetchOperarioSuccess,
  submitNuevaOperario,
  submitNuevaOperarioSuccess,
  recuperarDatosModalOperario,
  recuperarDatosModalOperarioSuccess,
  abrirModalVacioOperario,
  editarOperario,
  editarOperarioSuccess,
  duplicarDatosModalOperario,
  duplicarDatosModalOperarioSuccess,
  cambiarEstadoOperario,
  cambiarEstadoOperarioSuccess,
  onDeleteOperario,
  onDeleteOperarioSuccess,
  fetchFiltrarOperario,
  fetchFiltrarOperarioSuccess,
  seleccionarTodosOperario,
  deSeleccionarTodosOperario,
  cambiarEstadoSeleccionOperario,
  deleteSeleccionOperario,
  crearCsvOperario,
  crearCsvOperarioSuccess,
  guardarYnuevoOperario,
  cambiarOperarioProcesado,
  cambiarOperarioAlbaran,
  imprimirPdfOperario,
  imprimirPdfOperarioSuccess,
  imprimirEtiquetaOperario
}

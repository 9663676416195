import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import EntradaMercancia from '../../components/entradaMercancia/EntradaMercancia'
import { recuperarDatosModalEntradaMercancia, duplicarDatosModalEntradaMercancia, onDeleteEntradaMercancia } from '../../actions/entradaMercancia/entradaMercancia'

export function mapStateToProps (state) {
  return {
    ...state.entradaMercancia,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalEntradaMercancia,
      duplicarDatosModalEntradaMercancia,
      onDeleteEntradaMercancia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EntradaMercancia))
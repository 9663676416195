import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Filtros from '../../components/tipoInstalacion/FiltrosTipoInstalacion'
import {openModal} from '../../actions/common'
import {abrirModalVacioTipoInstalacion, fetchFiltrarTipoInstalacion, seleccionarTodosTipoInstalacion, deSeleccionarTodosTipoInstalacion, cambiarEstadoSeleccionTipoInstalacion, deleteSeleccionTipoInstalacion,
crearCsvTipoInstalacion, imprimirPdfTipoInstalacion} from '../../actions/tipoInstalacion/tipoInstalacion'
import {comboTipoInstalacionPadre} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.tipoInstalacion,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioTipoInstalacion,
      fetchFiltrarTipoInstalacion,
      seleccionarTodosTipoInstalacion,
      deSeleccionarTodosTipoInstalacion,
      cambiarEstadoSeleccionTipoInstalacion,
      deleteSeleccionTipoInstalacion,
      comboTipoInstalacionPadre,
      crearCsvTipoInstalacion,
      imprimirPdfTipoInstalacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filtros))
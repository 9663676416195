import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Producto from '../../components/producto/Producto'
import {obtenerProductosAlmacen, recuperarDatosModalProducto, duplicarDatosModalProducto, cambiarEstadoProducto, onDeleteProducto} from '../../actions/producto/producto'

export function mapStateToProps (state) {
  return {
    ...state.producto,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
     obtenerProductosAlmacen,
     recuperarDatosModalProducto,
     duplicarDatosModalProducto,
     cambiarEstadoProducto,
     onDeleteProducto
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Producto))
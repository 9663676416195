import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { resetPassword, cambiarIdiomaAuth } from '../../actions/auth/auth'
import ResetPassword from '../../components/auth/ResetPassword'

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      resetPassword,
      cambiarIdiomaAuth
    }, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(ResetPassword))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import ModalPerfil from '../../components/perfil/ModalPerfil'
import { cerrarModalPerfil, editarPerfil, fetchPerfil, abrirCambiarPassPerfil } from '../../actions/perfil/perfil'
import { uploadFileUsuarios } from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.perfil,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    urlRecursos: setting.default.urlRecursos,
    formSimpleTable: state.form.simpleTable,
    usuarios: state.usuarios,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalPerfil,
      editarPerfil,
      fetchPerfil,
      uploadFileUsuarios,
      abrirCambiarPassPerfil
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPerfil))
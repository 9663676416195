import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import comboCausaEliminacionActionTypes from '../../constants/actions/comboCausaEliminacion/comboCausaEliminacion'
import {fetchComboCausaEliminacionSuccess, recuperarDatosModalComboCausaEliminacionSuccess, submitNuevaComboCausaEliminacionSuccess, editarComboCausaEliminacionSuccess } from '../../actions/comboCausaEliminacion/comboCausaEliminacion'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getComboCausaEliminacionList from '../../services/comboCausaEliminacion/getComboCausaEliminacionList'
import addComboCausaEliminacion from '../../services/comboCausaEliminacion/addComboCausaEliminacion'
import getComboCausaEliminacion from '../../services/comboCausaEliminacion/getComboCausaEliminacion'
import editComboCausaEliminacion from '../../services/comboCausaEliminacion/editComboCausaEliminacion'

export function * fetchComboCausaEliminacion () {
  try {
    const list = yield call(getComboCausaEliminacionList, null)
    yield put(fetchComboCausaEliminacionSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchComboCausaEliminacion () {
  yield takeLatest(comboCausaEliminacionActionTypes.FETCH_COMBO_CAUSA_ELIMINACION, fetchComboCausaEliminacion)
}

export function * submitNuevaComboCausaEliminacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercion = yield call(addComboCausaEliminacion, values, auth.token)
    yield put(submitNuevaComboCausaEliminacionSuccess(insercion))
    if (insercion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(closeModal())
    yield call(fetchComboCausaEliminacion)
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaComboCausaEliminacion () {
  yield takeLatest(comboCausaEliminacionActionTypes.SUBMIT_NUEVA_COMBO_CAUSA_ELIMINACION, submitNuevaComboCausaEliminacion)
}

export function * recuperarDatosModalComboCausaEliminacion ({id}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getComboCausaEliminacion, id)
    yield put(recuperarDatosModalComboCausaEliminacionSuccess(registroModificar))
    console.log('recuperarDatosModalComboCausaEliminacion',registroModificar)
    yield put(initialize('ModalComboCausaEliminacion', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalComboCausaEliminacion () {
  yield takeLatest(comboCausaEliminacionActionTypes.RECUPERAR_DATOS_MODAL_COMBO_CAUSA_ELIMINACION, recuperarDatosModalComboCausaEliminacion)
}

export function * abrirModalVacioComboCausaEliminacion () {
  try {
    yield put(recuperarDatosModalComboCausaEliminacionSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalComboCausaEliminacion', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioComboCausaEliminacion () {
  yield takeLatest(comboCausaEliminacionActionTypes.ABRIR_MODAL_VACIO_COMBO_CAUSA_ELIMINACION, abrirModalVacioComboCausaEliminacion)
}

export function * editarComboCausaEliminacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicion = yield call(editComboCausaEliminacion, values, auth.token)
    yield put(editarComboCausaEliminacionSuccess(edicion))
    if (edicion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(closeModal())
    yield call(fetchComboCausaEliminacion)
    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarComboCausaEliminacion () {
  yield takeLatest(comboCausaEliminacionActionTypes.EDITAR_COMBO_CAUSA_ELIMINACION, editarComboCausaEliminacion)
}
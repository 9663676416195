import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerracoPlaning from '../../components/planing/ModalVerracoPlaning'
import {cerrarModalSeleccionarVerracoPlaning, asignarVerracos, anadirVerracosPlaning} from '../../actions/planing/planing'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalSeleccionarVerracoPlaning,
      asignarVerracos,
      anadirVerracosPlaning
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerracoPlaning))

export const estadoAlbaran = {
  BORRADOR: 1,
  CANCELADO: 2,
  REALIZADO: 3,
  VALIDADO: 4
}

export const translateEstadoAlbaran = {
  1: 'ALBARAN.BORRADOR',
  2: 'ALBARAN.CANCELADO',
  3: 'ALBARAN.REALIZADO',
  4: 'ALBARAN.VALIDADO'
}

export const estadoAlbaranValidacion = {
  1: 'ALBARAN.VALIDADO',
  2: 'ALBARAN.NOEXISTE',
  3: 'ALBARAN.DESCUADRE'
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosMoviminetoVerraco from '../../components/movimientoVerraco/FiltrosMovimientoVerraco'
import { cambiarVigiladoSeleccionFichaVerraco, deleteSeleccionFichaVerraco } from '../../actions/fichaVerraco/fichaVerraco'
import {abrirModalUbicacion, cerrarModalUbicacion} from '../../actions/fichaVerraco/fichaVerraco'
import {abrirModalVacioUbicacion, seleccionarTodosMovimientoVerraco, deSeleccionarTodosMovimientoVerraco, deleteSeleccionMovimientoVerraco, fetchFiltrarMovimientoVerraco, checkVerracosSinUbicacion,
  crearCsvMovimientoVerraco, imprimirPdfMovimientoVerraco} from '../../actions/movimientoVerraco/movimientoVerraco'
import {comboUbicacion, comboInstalacionPadre} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    fichaVerraco: state.fichaVerraco,
    formSimpleTable: state.form.simpleTable,
    movimientoVerraco:state.movimientoVerraco,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cambiarVigiladoSeleccionFichaVerraco,
      deleteSeleccionFichaVerraco,
      abrirModalUbicacion,
      cerrarModalUbicacion,
      abrirModalVacioUbicacion,
      seleccionarTodosMovimientoVerraco,
      deSeleccionarTodosMovimientoVerraco,
      deleteSeleccionMovimientoVerraco,
      fetchFiltrarMovimientoVerraco,
      comboUbicacion,
      checkVerracosSinUbicacion,
      crearCsvMovimientoVerraco,
      imprimirPdfMovimientoVerraco,
      comboInstalacionPadre
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosMoviminetoVerraco))
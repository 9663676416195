import { select, put, call, all, takeLatest, take, race } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, reset, change, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import { dateTimeZones } from '../../util/formatFunctions'

//contants
import actionTypesAsignacionPool from '../../constants/actions/asignacionPoolVerraco/asignacionPoolVerraco';
//import actionTypes from '../../constants/actions/poolExtracciones/poolExtracciones'

// actions
import {
  addAnalisisPoolSuccess, removeAnalisisPoolSuccess, recuperarDatosPoolSuccess,
  initializeExtraccionesPendientesAsignarSuccess, createNewPoolSuccess, fetchExtraccionesPendientesDeAsignarSuccess,
  abrirModalNuevoPoolAsignacionSuccess, obtenerNumeroPoolSuccess
} from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool';
import { fetchPoolExtracciones } from '../../actions/poolExtracciones/poolExtracciones';
import { openMensajeAlerta, reloadComponent } from '../../actions/common';

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

// Services
import addAnalisisPoolService from '../../services/AsignacionPoolVerraco/addAnalisisPool';
import removeAnalisisPoolService from '../../services/AsignacionPoolVerraco/removeAnalisisDePool';
import getCodigoPoolExtraccionesService from '../../services/poolExtracciones/getCodigoPoolExtracciones';
import submitNuevoPoolExtraccionService from '../../services/poolExtracciones/submitNuevoPoolExtraccion';
import getExtraccionesPendienteAsignar from '../../services/AsignacionPoolVerraco/getExtraccionesPendienteAsignar';
import getPoolExtraccionService from '../../services/poolExtracciones/getPoolExtraccion';
import getObtenerNumeroPool from '../../services/AsignacionPoolVerraco/getObtenerNumeroPool';


export function* abrirModalNuevoPoolAsignacion({ extraccion }) {
  try {
    const numeroPool = yield call(getObtenerNumeroPool, null)
    yield put(obtenerNumeroPoolSuccess(numeroPool))
    let asignacionAnalisisPool = yield select(state => state.asignacionAnalisisPool)
    let auth = yield select(state => state.auth)
    yield put(initialize('modalNuevoPoolAsignacion', { nombrePool: asignacionAnalisisPool.numeroPool, fechaExtraccion: dateTimeZones(auth.zonaHorariaString), nombreRaza: extraccion.nombreRaza }))
    yield put(abrirModalNuevoPoolAsignacionSuccess(extraccion));
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function* watchAbrirModalNuevoPoolAsignacion() {
  yield takeLatest(actionTypesAsignacionPool.ABRIR_MODAL_NUEVO_POOL_ASIGNACION, abrirModalNuevoPoolAsignacion)
}


export function* initializeExtraccionesPendientesAsignar() {
  try {
    let auth = yield select(state => state.auth)
    let values = { 'idRaza': 'todas' };
    let analisisExtraccionesPendientesAsignarList = [];
    analisisExtraccionesPendientesAsignarList = yield call(getExtraccionesPendienteAsignar, values, auth.token);
    yield put(initializeExtraccionesPendientesAsignarSuccess(analisisExtraccionesPendientesAsignarList));
  } catch (err) {
    console.error(err);
  }
}

export function* fetchExtraccionesPendientesDeAsignar() {
  try {
    let auth = yield select(state => state.auth)
    let filtros = yield select(state => {
      return state.poolExtracciones.filtros;
    });
    //let values = { raza: raza };
    let analisisExtraccionesPendientesAsignarList = [];
    analisisExtraccionesPendientesAsignarList = yield call(getExtraccionesPendienteAsignar, filtros, auth.token);
    yield put(fetchExtraccionesPendientesDeAsignarSuccess(analisisExtraccionesPendientesAsignarList));
  }
  catch (err) {
    console.error(err);
  }
}


export function* createNewPool({ values }) {
  try {
    let codigo = yield call(getCodigoPoolExtraccionesService);
    let auth = yield select(state => state.auth);
    values.codigo = codigo.codigo;
    let respuesta = yield call(submitNuevoPoolExtraccionService, values, auth.token);
    if(respuesta.error && respuesta.error=='check_nombre_pool'){
      yield put(openMensajeAlerta('no-insercion-pool'))
      return
    }
    let filtros = yield select(state => state.poolExtracciones.filtros);
    filtros.pool = { value: respuesta.idPool, label: values.nombre };
    filtros.raza = values.raza;

    yield all([
      call(recuperarDatosPool, { idPool: filtros.pool.value }),
      call(fetchExtraccionesPendientesDeAsignar, { idRaza: filtros.raza.value })
    ]);
    yield put(fetchPoolExtracciones(filtros));
    yield put(createNewPoolSuccess(respuesta.idPool));    
  } catch (error) {
    console.error(error);
  }
}

export function* watchCreateNewPool() {
  yield takeLatest(actionTypesAsignacionPool.CREATE_NEW_POOL, createNewPool)
}
export function* recuperarDatosPool({ idPool }) {
  try {

    if (idPool == null) { yield put(recuperarDatosPoolSuccess([])); return; }

    let { analisis, pool } = yield call(getPoolExtraccionService, idPool);
    let analisisArr = Object.values(analisis);
    let poolArr = [];
    poolArr.push(pool);
    let analisisRes = {
      asignadosAnalisisList: analisisArr,
      poolList: poolArr
    }
    yield put(recuperarDatosPoolSuccess(analisisRes))
  } catch (error) {

  }
}

export function* watchRecuperarDatosPool() {
  yield takeLatest(actionTypesAsignacionPool.RECUPERAR_DATOS_POOL, recuperarDatosPool)
}

export function* addAnalisisPool({ idDilucion, idPool, idRaza }) {
  try {
    let auth = yield select(state => state.auth)

    let raza = yield select(state => {
      return state.poolExtracciones.filtros.raza;
    });
    let values = {
      idDilucion: idDilucion,
      idPool: idPool
    };
    
    //Alertas Pool
    let confirmed = true
    let panelControl = yield select(state => state.panelControl)
    let asignacionAnalisisPool = yield select(state => state.asignacionAnalisisPool)
    //alerta num eyaculados    
    if(panelControl && panelControl.AlertaPoolNumEyaculados && panelControl.AlertaPoolNumEyaculados > 0){
      let poolNumEyaculados = asignacionAnalisisPool.analisis.asignadosAnalisisList.length
      if(poolNumEyaculados >= panelControl.AlertaPoolNumEyaculados){
        confirmed = yield call(yesNoModalSaga, {modalType: 'alertaPoolNumEyaculados'})
      }
    }
    //alerta num dosis
    if(confirmed && panelControl && panelControl.AlertaPoolNumDosis && panelControl.AlertaPoolNumDosis > 0){
      let poolNumDosis = asignacionAnalisisPool.analisis.poolList[0].dosisReales
      let poolVolumenTipoDosis = asignacionAnalisisPool.analisis.poolList[0].volumenTipoDosis
      const dilucion = asignacionAnalisisPool.pendientesAsignarList.find(x => x.idDilucion == idDilucion);
      let totalDosis = ((parseInt(dilucion.dosis)*parseInt(dilucion.volumenTipoDosis))+(parseInt(poolNumDosis)*parseInt(poolVolumenTipoDosis))) / parseInt(poolVolumenTipoDosis)
      if(totalDosis >= panelControl.AlertaPoolNumDosis){
        confirmed = yield call(yesNoModalSaga, {modalType: 'alertaPoolNumDosis'})
      }
    }

    
    
    if (confirmed) {
      yield call(addAnalisisPoolService, values, auth.token);

      let filtros = yield select(state => {
        return state.poolExtracciones.filtros;
      });
  
      yield all([
        call(recuperarDatosPool, { idPool }),
        call(fetchExtraccionesPendientesDeAsignar)
      ])
  
      yield put(addAnalisisPoolSuccess());
      //columna pool
      yield put(fetchPoolExtracciones(filtros));
      
      yield put(reloadComponent(true,'FormPoolExtraccion',idPool))
    }


  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* removeAnalisisPool({ idDilucion, idPool, idRaza }) {
  try {    
    let auth = yield select(state => state.auth)
    let values = {
      idDilucion: idDilucion,
      idPool: idPool
    };
    yield call(removeAnalisisPoolService, values, auth.token)
    yield all([
      call(recuperarDatosPool, { idPool }),
      call(fetchExtraccionesPendientesDeAsignar, { idRaza })
    ])

    yield put(removeAnalisisPoolSuccess());

    //columna pool
    let filtros = yield select(state => {
      return state.poolExtracciones.filtros;
    });
    yield put(fetchPoolExtracciones(filtros))
    
    yield put(reloadComponent(true,'FormPoolExtraccion',idPool))

  } catch (error) {
    console.log(error);
  } finally {

  }
}

export function* watchAddAnalisisPool() {
  yield takeLatest(actionTypesAsignacionPool.ADD_ANALISIS_POOL, addAnalisisPool)
}
export function* watchRemoveAnalisisPool() {
  yield takeLatest(actionTypesAsignacionPool.REMOVE_ANALISIS_POOL, removeAnalisisPool)
}

export function* watchInitializeExtraccionesPendientesAsignar() {
  yield takeLatest(actionTypesAsignacionPool.INITIALIZE_ANALISIS_EXTRACCIONES, initializeExtraccionesPendientesAsignar)
}

export function* watchFetchExtraccionesPendientesDeAsignar() {
  yield takeLatest(actionTypesAsignacionPool.FETCH_EXTRACCIONES_SIN_ASIGNAR, fetchExtraccionesPendientesDeAsignar);
}




export function* obtenerNumeroPool() {
  try {
    const numeroPool = yield call(getObtenerNumeroPool, null)
    yield put(obtenerNumeroPoolSuccess(numeroPool))
  } catch (error) {

  } finally {

  }
}
export function* watchObtenerNumeroPool() {
  yield takeLatest(actionTypesAsignacionPool.OBTENER_NUMERO_POOL, obtenerNumeroPool)
}

import actionTypes from '../../constants/actions/perfil/perfil'

export function abrirModalPerfil () {
  return {
    type: actionTypes.ABRIR_MODAL_PERFIL
  }
}

export function cerrarModalPerfil () {
  return {
    type: actionTypes.CERRAR_MODAL_PERFIL
  }
}

export function editarPerfil (values) {
  return {
    type: actionTypes.EDITAR_PERFIL,
    values
  }
}

export function fetchPerfil () {
  return {
    type: actionTypes.FETCH_PERFIL
  }
}

export function fetchPerfilSuccess (dataPerfil) {
  return {
    type: actionTypes.FETCH_PERFIL_SUCCESS,
    dataPerfil
  }
}

export function abrirCambiarPassPerfil () {
  return {
    type: actionTypes.ABRIR_CAMBIAR_PASS_PERFIL
  }
}

export function cerrarCambiarPassPerfil () {
  return {
    type: actionTypes.CERRAR_CAMBIAR_PASS_PERFIL
  }
}

export function cambiarPasswordPerfil (values) {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_PERFIL,
    values
  }
}

export function cambiarPasswordPerfilSuccess (dataCambiarPass) {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_PERFIL_SUCCESS,
    dataCambiarPass
  }
}

export default {
  abrirModalPerfil,
  cerrarModalPerfil,
  editarPerfil,
  fetchPerfil,
  fetchPerfilSuccess,
  abrirCambiarPassPerfil,
  cerrarCambiarPassPerfil,
  cambiarPasswordPerfil,
  cambiarPasswordPerfilSuccess
}

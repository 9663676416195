import actionTypes from '../../constants/actions/asignacionPoolVerraco/asignacionPoolVerraco'
import getLocalidadesOperarios from '../../services/combos/getLocalidadesOperarios';

export function abrirModalNuevoPoolAsignacion(extraccion) {
  return {
    type: actionTypes.ABRIR_MODAL_NUEVO_POOL_ASIGNACION,
    extraccion
  }
}

export function abrirModalNuevoPoolAsignacionSuccess(extraccion) {
  return {
    type: actionTypes.ABRIR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS,
    extraccion
  }
}

export function recuperarDatosPool(idPool) {
  return {
    type: actionTypes.RECUPERAR_DATOS_POOL,
    idPool
  }
}

export function recuperarDatosPoolSuccess(analisis) {
  return {
    type: actionTypes.RECUPERAR_DATOS_POOL_SUCCESS,
    analisis
  }
}
export function cerrarModalNuevoPoolAsignacion() {
  return {
    type: actionTypes.CERRAR_MODAL_NUEVO_POOL_ASIGNACION
  }
}

export function fetchExtraccionesPendientesDeAsignar(idRaza) {
  return {
    type: actionTypes.FETCH_EXTRACCIONES_SIN_ASIGNAR,
    idRaza
  }
}

export function fetchExtraccionesPendientesDeAsignarSuccess(pendientesAsignarList) {
  return {
    type: actionTypes.FETCH_EXTRACCIONES_SIN_ASIGNAR_SUCCESS,
    pendientesAsignarList
  }
}

export function initializeExtraccionesPendientesAsignar() {
  return {
    type: actionTypes.INITIALIZE_ANALISIS_EXTRACCIONES
  }
}

export function initializeExtraccionesPendientesAsignarSuccess(pendientesAsignarList) {
  return {
    type: actionTypes.INITIALIZE_ANALISIS_EXTRACCIONES_SUCCESS,
    pendientesAsignarList
  }
}

export function createNewPool(values) {
  return {
    type: actionTypes.CREATE_NEW_POOL,
    values
  }
}

export function createNewPoolSuccess(idPool) {  
  return {
    type: actionTypes.CREATE_NEW_POOL_SUCCESS,
    idPool
  }
}

export function addAnalisisPool(idDilucion, idPool, idRaza) {
  return {
    type: actionTypes.ADD_ANALISIS_POOL,
    idDilucion,
    idPool,
    idRaza
  }
}

export function addAnalisisPoolSuccess() {
  return {
    type: actionTypes.ADD_ANALISIS_POOL_SUCCESS,
  }
}

export function removeAnalisisPool(idDilucion, idPool, idRaza) {
  return {
    type: actionTypes.REMOVE_ANALISIS_POOL,
    idDilucion,
    idPool,
    idRaza
  }
}

export function removeAnalisisPoolSuccess() {
  return {
    type: actionTypes.REMOVE_ANALISIS_POOL_SUCCESS,
  }
}

export function closeModal(nuevoPool) {
  return {
    type: actionTypes.CLOSE_MODAL,
    nuevoPool
  }
}

export function obtenerNumeroPool() {
  return {
    type: actionTypes.OBTENER_NUMERO_POOL
  }
}

export function obtenerNumeroPoolSuccess(numeroPool) {
  return {
    type: actionTypes.OBTENER_NUMERO_POOL_SUCCESS,
    numeroPool
  }
}


export default {
  closeModal,
  initializeExtraccionesPendientesAsignar,
  initializeExtraccionesPendientesAsignarSuccess,
  recuperarDatosPool,
  recuperarDatosPoolSuccess,
  abrirModalNuevoPoolAsignacion,
  abrirModalNuevoPoolAsignacionSuccess,
  createNewPool,
  createNewPoolSuccess,
  addAnalisisPool,
  addAnalisisPoolSuccess,
  removeAnalisisPool,
  removeAnalisisPoolSuccess,
  obtenerNumeroPool,
  obtenerNumeroPoolSuccess
}
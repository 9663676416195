import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosClientePage from '../../containers/cliente/FiltrosClientePage'
import ModalClientePage from '../../containers/cliente/ModalClientePage'
import './Cliente.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class Cliente extends Component {
  constructor () {
    super()
    this.state = {
      listCliente: []
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.PEDIDO.CLIENTES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  componentDidUpdate (prevProps) {
    if (this.props.listCliente !== prevProps.listCliente) {
      if (this.props.listCliente.clientes) {
        this.setState({listCliente: this.props.listCliente.clientes})
      }
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listCliente, checkedFiltrosCliente, data, dataDuplicar,
      actions: {recuperarDatosModalCliente, duplicarDatosModalCliente, cambiarEstadoCliente, onDeleteCliente}
    } = this.props

    this.state.listCliente.forEach(
      (row) => row.idElemento = row.idCliente
    )

    const table = {
      id: 'clienteTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1),
      hasOptions: true,
      columns: [
        {id: 'razonSocialCliente', name: t('PEDIDOS.CLIENTE.TITLE')},
        {id: 'cifCliente', name: t('PEDIDOS.CLIENTE.CIF')},
        {id: 'direccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.DIRECCION')},
        {id: 'localidad', name: t('PEDIDOS.CLIENTE.LOCALIDADES')},
        {id: 'provincia', name: t('PEDIDOS.CLIENTE.PROVINCIAS')},
        {id: 'rutas', name: t('PEDIDOS.CLIENTE.RUTA')},
        {id: 'email', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.EMAIL')},
        {id: 'telefono', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO')}
        

      ],
      rows: this.state.listCliente,
      filtros: <FiltrosClientePage />,
      onEdit: (row) => recuperarDatosModalCliente(row.idCliente),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1,
      duplicar: (row) =>duplicarDatosModalCliente(row.idCliente),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1,
      cambiarEstado: (row) => cambiarEstadoCliente(row.idCliente),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1,
      onDelete: (row) => onDeleteCliente(row.idCliente, row.razonSocialCliente),
      showModal: this.props.showModalCliente,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosCliente
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalClientePage
          duplicar={(row) =>duplicarDatosModalCliente(row.idCliente)}
        />
        <h2 className="page-title">{t('PEDIDOS.CLIENTE.TITLE')}</h2>
        <SimpleTablePage {...table}>
        </SimpleTablePage>
        <Row style={{marginTop: '20px'}}>
          <Col sm={12}>
            <Col className="totales-informes" style={{width: '20%'}}>
              <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_CLIENTES')}</span></Col>
              <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{listCliente && listCliente.totales ? listCliente.totales.clientes : 0}</span></Col>
            </Col>
            <Col className="totales-informes" style={{width: '20%', borderLeft: 'none'}}>
              <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_GRANJAS')}</span></Col>
              <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{listCliente && listCliente.totales ? listCliente.totales.granjas : 0}</span></Col>
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Cliente
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import moment from 'moment'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputSelect from '../comun/form/InputSelect'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Print, Launch, Warning } from '@material-ui/icons'
import './Planing.scss'

class ModalDuplicarPlaning extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      exportarTodoFecha: 'todo',
      errorExportar: false,
      alertaDuplicar: '',
      alertaPeriodo: '',
      alertaDias: ''
    }
  }

  cerrarDuplicarPlaning () {
    this.props.actions.cerrarDuplicarPlaning()
    this.setState({alertaDuplicar: ''})
  }

  enviarFechaDuplicarPlaning (fecha) {
    if (fecha !== "") {
      this.props.actions.enviarFechaDuplicarPlaning(fecha)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formDuplicarPlaning !== prevProps.formDuplicarPlaning){
      if (this.props.formDuplicarPlaning.submitFailed === true && !this.props.formDuplicarPlaning.values.fechaEntrada && !this.props.formDuplicarPlaning.values.fechaSalida) {
        this.setState({alertaPeriodo: true})
      } else {
        this.setState({alertaPeriodo: false})
      }

      if (this.props.formDuplicarPlaning.submitFailed === true && !this.props.formDuplicarPlaning.values.fechaEntrada) {
        this.setState({alertaPeriodo: true})
      } else {
        this.setState({alertaPeriodo: false})
      }

      if (this.props.formDuplicarPlaning.submitFailed === true && !this.props.formDuplicarPlaning.values.fechaSalida) {
        this.setState({alertaPeriodo: true})
      } else {
        this.setState({alertaPeriodo: false})
      }

      if (this.state.alertaDuplicar === true && (this.props.formDuplicarPlaning.values.lunes === false && this.props.formDuplicarPlaning.values.martes === false && this.props.formDuplicarPlaning.values.miercoles === false && this.props.formDuplicarPlaning.values.jueves === false && this.props.formDuplicarPlaning.values.viernes === false && this.props.formDuplicarPlaning.values.sabado === false && this.props.formDuplicarPlaning.values.domingo === false)) {
        this.setState({alertaDias: true})
      } else {
        this.setState({alertaDias: false})
      }

      /*if (this.props.formDuplicarPlaning.values.fechaEntrada && this.props.formDuplicarPlaning.values.fechaSalida && (this.props.formDuplicarPlaning.values.lunes === true || this.props.formDuplicarPlaning.values.martes === true || this.props.formDuplicarPlaning.values.miercoles === true || this.props.formDuplicarPlaning.values.jueves === true || this.props.formDuplicarPlaning.values.viernes === true || this.props.formDuplicarPlaning.values.sabado === true || this.props.formDuplicarPlaning.values.domingo === true)){
        this.setState({alertaDuplicar: false})
      } else {
        this.setState({alertaDuplicar: true})
      }*/
    }
  }

  submitDuplicarPlaning (values) {
    if (values.fechaEntrada && values.fechaSalida && (values.lunes === true || values.martes === true || values.miercoles === true || values.jueves === true || values.viernes === true || values.sabado === true || values.domingo === true)){
      this.setState({alertaDuplicar: false})
      let diasEntreFechas = function(desde, hasta) {
        let dia_actual = desde
        let fechas = []
        while (dia_actual.isSameOrBefore(hasta)) {
          fechas.push(dia_actual.format('YYYY-MM-DD'))
          dia_actual.add(1, 'days')
        }
        return fechas;
      }
      let periodoFechas = diasEntreFechas(moment(values.fechaEntrada), moment(values.fechaSalida))
      let valores = []
      valores.verracos = this.props.datosDuplicar.verracos
      periodoFechas.forEach((fecha) => {
        if (new Date(fecha).getDay() === 1 && values.lunes === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 2 && values.martes === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 3 && values.miercoles === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 4 && values.jueves === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 5 && values.viernes === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 6 && values.sabado === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
        if (new Date(fecha).getDay() === 0 && values.domingo === true) {
          valores.fecha = fecha
          this.props.actions.submitDuplicarPlaning(valores)
        }
      })
    } else {
      this.setState({alertaDuplicar: true})
      if (this.props.formDuplicarPlaning.values.lunes === false && this.props.formDuplicarPlaning.values.martes === false && this.props.formDuplicarPlaning.values.miercoles === false && this.props.formDuplicarPlaning.values.jueves === false && this.props.formDuplicarPlaning.values.viernes === false && this.props.formDuplicarPlaning.values.sabado === false && this.props.formDuplicarPlaning.values.domingo === false) {
        this.setState({alertaDias: true})
      } else {
        this.setState({alertaDias: false})
      }
    }
  }

  comprobarSeleccionPlaning (values) {
    if (values.fechaEntrada && values.fechaSalida && (values.lunes === true || values.martes === true || values.miercoles === true || values.jueves === true || values.viernes === true || values.sabado === true || values.domingo === true)){
      this.setState({alertaDuplicar: false})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalDuplicarPlaning, datosDuplicar,
      actions:{onDeleteVerracoPlaning}
    } = this.props

  return (
      <Modal show={showModalDuplicarPlaning} onHide={() => this.cerrarDuplicarPlaning()} bsSize="large" dialogClassName="sm" backdrop="static" aria-labelledby="contained-modal-title-lg"  className="modal-exportar-imprimir-planing modal-duplicar-planing">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {t('PLANING.MODAL.DUPLICAR')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p><b>{t('PLANING.MODAL.ELIGE_FECHA_DUPLICAR')}: </b></p>
              <Field
                colSm={12}
                id="fecha"
                name="fecha"
                controlLabel={t('PLANING.FECHA') + '*'}
                component={InputDatePicker}
                validate={required}
                onInputChange={(value) => this.enviarFechaDuplicarPlaning(value)}
              />
              {
                datosDuplicar.verracos && Object.keys(datosDuplicar.verracos).length === 0 ? (
                  <p className="alerta-planing-sin-verracos">{t('PLANING.MODAL.NO_HAY_VERRACOS')}</p>
                ) : null
              }
              {
                datosDuplicar.verracos && Object.keys(datosDuplicar.verracos).length > 0 ? (
                  <div>
                    <p className={this.state.alertaPeriodo === true ? ('alerta-planing-sin-verracos') : null}><b>{t('PLANING.MODAL.PERIODO_FECHAS') + '*'}</b>{this.state.alertaPeriodo === true ? <Warning/> : null}</p>
                    <Fields
                      names={['fechaEntrada', 'fechaSalida']}
                      component={InputRangeDatePickerDynamicNames}
                      colSm={12}
                      nameFrom="fechaEntrada"
                      nameTo="fechaSalida"
                      // controlLabel={t('PLANING.MODAL.PERIODO_FECHAS') + '*'}
                      // onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                      validate={required}
                      noIntro={true}
                    />
                    <p className={this.state.alertaDias === true ? ('alerta-planing-sin-verracos') : null}><b>{t('PLANING.MODAL.DIAS') + '*'}</b>{this.state.alertaDias === true ? <Warning/> : null}</p>
                    <Field
                      id="lunes"
                      name="lunes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.LUNES')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="martes"
                      name="martes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MARTES')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="miercoles"
                      name="miercoles"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="jueves"
                      name="jueves"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.JUEVES')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="viernes"
                      name="viernes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.VIERNES')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="sabado"
                      name="sabado"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.SABADO')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                    <Field
                      id="domingo"
                      name="domingo"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO')}
                      colSm={4}
                      onInputChange={handleSubmit(this.comprobarSeleccionPlaning.bind(this))}
                    />
                  </div>
                ) : null
              }
              <div className="clearfix"></div>
              {/*
                this.state.alertaDuplicar === true ? (
                  <p className="alerta-planing-sin-verracos">{t('PLANING.MODAL.ALERTA_DUPLICAR')}</p>
                ) : null
              */}
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarDuplicarPlaning()}>{t('PLANING.MODAL.CANCELAR')}</Button>
            {
              datosDuplicar.verracos && Object.keys(datosDuplicar.verracos).length > 0 ? (
                <Button type="button" onClick={handleSubmit(this.submitDuplicarPlaning.bind(this))} className="btn btn-primary">{t('PLANING.MODAL.GUARDAR')}</Button>
              ) : null
            }
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDuplicarPlaning',
})(ModalDuplicarPlaning))

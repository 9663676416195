import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Footer from '../../components/layout/Footer'

export function mapStateToProps (state) {
    return {
      auth: state.auth
    }
  }

export default connect(mapStateToProps, null)(withTranslation()(Footer))

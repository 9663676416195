import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesClientes from '../../components/informes/FiltrosInformesClientes'
import {obtenerInformeCliente, imprimirPdfInformesClientes, crearCsvInformeClientes} from '../../actions/informes/informes'
import {comboRaza, comboLineaGenetica, comboVerraco, comboProvinciasClientes, comboCliente, comboTipoDosis, fetchComboPool, comboRuta} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeCliente,
      comboRaza,
      comboLineaGenetica,
      comboVerraco,
      comboProvinciasClientes,
      comboCliente,
      comboTipoDosis,
      fetchComboPool,
      comboRuta,
      imprimirPdfInformesClientes,
      crearCsvInformeClientes
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesClientes))
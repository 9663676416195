import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import { yesNoModal } from '../../actions/common'
import { comboMaquina, comboOperarioPredeterminado, fetchComboTipoDiluyente } from '../../actions/combos/combos'
import { openModalTrazabilidadMaterial } from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import { guardarModalDilucionAnalisisExtraccion, cerrarModalDilucionAnalisisExtraccion, prepararMaquinaDilucionAnalisisExtraccion, cancelarMaquinaDilucionAnalisisExtraccion } from '../../actions/analisisExtracciones/analisisExtracciones'
import ModalDilucionAnalisisExtraccion from '../../components/analisisExtracciones/ModalDilucionAnalisisExtraccion'

export function mapStateToProps (state) {
  return {
    ...state.analisisExtracciones.extraccion,
    utilizarLotesPreferidosDelUsuario: state.trazabilidadMaterial.utilizarLotesPreferidosDelUsuario,
    stockInsuficiente: state.trazabilidadMaterial.stockInsuficiente[categoriasEscandallos.ANALISIS_DILUCION],
    combos: {
      comboMaquina: state.combos.comboMaquina,
      comboOperarioPredeterminado: state.combos.comboOperarioPredeterminado,
      comboTipoDiluyente: state.combos.comboTipoDiluyente
    },
    formAnalisisExtraccion: getFormValues('formAnalisisExtraccion')(state),
    formModalDilucionAnalisisExtraccion: getFormValues('ModalDilucionAnalisisExtraccion')(state),
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboMaquina,
      comboOperarioPredeterminado,
      fetchComboTipoDiluyente,
      openModalTrazabilidadMaterial,
      guardarModalDilucionAnalisisExtraccion,
      cerrarModalDilucionAnalisisExtraccion,
      prepararMaquinaDilucionAnalisisExtraccion,
      cancelarMaquinaDilucionAnalisisExtraccion,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalDilucionAnalisisExtraccion)))
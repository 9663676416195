import actionTypes from '../../constants/actions/analisisExtracciones/analisisExtracciones'

export function initialState () {
  return {
    list: [],
    totales: {},    
    checkedFiltrosAnalisisExtracciones: false,
    filtros:{},
    tokenUsuarioMagavision: '',
    t_anl: '', // tokenAnalisisMagavision
    t_dtv: '', // identificadorAnalisisMagavision generado por gesipor. unicamente se utiliza en la llamada a la api de Magavision.
    extraccion: {
      loading: true,
      cambioEstado: false,
      tipoAnalisisSeleccionado: null,
      data: {
        codigoAnalisis: '',
      },
      showModalDilucionAnalisisExtraccion: false,
      showModalPoolAnalisisExtraccion: false,
      codigoEstadoMaquinaDilucionAnalisisExtraccion: '',
      t_dtv_main: '',  // identificadorAnalisisMagavision correspondiente al analisis inicial a partir del cual se utiliza para relacionarlo con sus seguimientos.
      t_dtv_final: '',  // identificadorAnalisisMagavision devuelto por magavision y que se guarda en base de datos.
      esperandoResultadoSistemaAnalisis: false,
      showModalMagavision: false,
      isSeguimiento: false,
      isControlMorfologico: false,
      multiseguimiento: null,
      mgv_follow_id: null
    },
    etiquetaPdf: '',
    showAlerta: false
  }
}

export function fetchAnalisisExtraccionesSuccess (state, {list, totales, filtros}) {
  return {
    ...state,
    list,
    totales,
    filtros
  }
}

export function seleccionarTodosAnalisisExtracciones (state) {
  return {
    ...state,
    checkedFiltrosAnalisisExtracciones: true
  }
}

export function deseleccionarTodosAnalisisExtracciones (state) {
  return {
    ...state,
    checkedFiltrosAnalisisExtracciones: false
  }
}

export function recuperarDatosFormAnalisisExtraccionSuccess (state, {data}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      tipoAnalisisSeleccionado: data.idTipoAnalisis,
      data,
      t_dtv_main: data.t_dtv_main,
      loading: false
    }
  }
}

export function fetchCodigoAnalisisExtraccionesSuccess (state, {codigoAnalisis}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      data: {
        ...state.extraccion.data,
        codigoAnalisis
      }
    }
  }
}

export function fetchTokenMagavisionSuccess (state, {tokenUsuarioMagavision, t_anl, t_dtv}) {
  return {
    ...state,
    tokenUsuarioMagavision,
    t_anl,
    t_dtv: t_dtv ? t_dtv : state.t_dtv
  }
}

export function fetchTokenVerracoMagavisionSuccess (state, {t_dtv}) {
  return {
    ...state,
    t_dtv
  }
}

export function updateTokenMagavisionSuccess (state, {t_dtv_final}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      t_dtv_final
    }
  }
}

export function updateTokenDtvMainMagavisionSuccess (state, {t_dtv_main, t_dtv}) {
  return {
    ...state,
    t_dtv,
    extraccion: {
      ...state.extraccion,
      t_dtv_main
    }
  }
}

export function fetchDatosVerracoExtraccionesSuccess (state, {data}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      verraco: {
        ...state.extraccion.verraco,
        ...data
      }
    }
  }
}

export function prepararVerracoParaCambiarAEstadoActivoSuccess (state, {estado}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      cambioEstado: estado
    }
  }
}

export function changeTipoAnalisisExtraccionSuccess (state, {data}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      tipoAnalisisSeleccionado: data.tipoAnalisisSeleccionado
    }
  }
}

export function abrirNuevoMagavisionSuccess (state, {isSeguimiento, isControlMorfologico, list, mgv_follow_id}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalMagavision: true,
      isSeguimiento,
      isControlMorfologico,
      multiseguimiento: list,
      mgv_follow_id
    }
  }
}

export function cerrarNuevoMagavisionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalMagavision: false
    }
  }
}

export function esperandoResultadoSistemaAnalisis (state, {status}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      esperandoResultadoSistemaAnalisis: status
    }
  }
}

export function initializeFormAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: (initialState()).extraccion,
    showAlerta: false
  }
}

export function abrirModalDilucionAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalDilucionAnalisisExtraccion: true
    }
  }
}

export function guardarModalDilucionAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalDilucionAnalisisExtraccion: false,
      codigoEstadoMaquinaDilucionAnalisisExtraccion: ''
    }
  }
}

export function cerrarModalDilucionAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalDilucionAnalisisExtraccion: false,
      codigoEstadoMaquinaDilucionAnalisisExtraccion: ''
    }
  }
}

export function actualizarEstadoMaquinaDilucionAnalisisExtraccion (state, {codigo}) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      codigoEstadoMaquinaDilucionAnalisisExtraccion: codigo
    }
  }
}

export function imprimirEtiquetaPDFSuccess (state, {etiquetaPdf}) {
  return {
    ...state,
    etiquetaPdf: etiquetaPdf
  }
}


export function abrirModalPoolAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalPoolAnalisisExtraccion: true
    }
  }
}

export function guardarModalPoolAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalPoolAnalisisExtraccion: false
    }
  }
}

export function cerrarModalPoolAnalisisExtraccionSuccess (state) {
  return {
    ...state,
    extraccion: {
      ...state.extraccion,
      showModalPoolAnalisisExtraccion: false
    }
  }
}

export function showModalAlertaSuccess (state) {
  return {
    ...state,
    showAlerta: true
  }
}

export function showUltimasExtraccionesVerracoSuccess (state, {ultimasExtraccionesVerraco}) { 
  return {
    ...state,
    ultimasExtraccionesVerraco: ultimasExtraccionesVerraco
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_ANALISIS_EXTRACCIONES_SUCCESS:  
      return fetchAnalisisExtraccionesSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES:
      return seleccionarTodosAnalisisExtracciones(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ANALISIS_EXTRACCIONES:
      return deseleccionarTodosAnalisisExtracciones(state, action)
    case actionTypes.RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION_SUCCESS:
      return recuperarDatosFormAnalisisExtraccionSuccess(state, action)
    case actionTypes.FETCH_CODIGO_ANALISIS_EXTRACCIONES_SUCCESS:
      return fetchCodigoAnalisisExtraccionesSuccess(state, action)
    case actionTypes.FETCH_TOKEN_MAGAVISION_SUCCESS:
      return fetchTokenMagavisionSuccess(state, action)
    case actionTypes.FETCH_TOKEN_VERRACO_MAGAVISION_SUCCESS:
      return fetchTokenVerracoMagavisionSuccess(state, action)
    case actionTypes.UPDATE_TOKEN_MAGAVISION_SUCCESS:
      return updateTokenMagavisionSuccess(state, action)
    case actionTypes.UPDATE_TOKEN_DTV_MAIN_MAGAVISION_SUCCESS:
      return updateTokenDtvMainMagavisionSuccess(state, action)
    case actionTypes.FETCH_DATOS_VERRACO_EXTRACCIONES_SUCCESS:
      return fetchDatosVerracoExtraccionesSuccess(state, action)
    case actionTypes.PREPARAR_VERRACO_PARA_CAMBIAR_A_ESTADO_ACTIVO_SUCCESS:
      return prepararVerracoParaCambiarAEstadoActivoSuccess(state, action)
    case actionTypes.CHANGE_TIPO_ANALISIS_EXTRACCION_SUCCESS:
      return changeTipoAnalisisExtraccionSuccess(state, action)
    case actionTypes.ABRIR_NUEVO_MAGAVISION_SUCCESS:
      return abrirNuevoMagavisionSuccess(state, action)
    case actionTypes.CERRAR_NUEVO_MAGAVISION_SUCCESS:
      return cerrarNuevoMagavisionSuccess(state, action)
    case actionTypes.ESPERANDO_RESULTADO_SISTEMA_ANALISIS:
      return esperandoResultadoSistemaAnalisis(state, action)
    case actionTypes.INITIALIZE_FORM_ANALISIS_EXTRACCION_SUCCESS:
      return initializeFormAnalisisExtraccionSuccess(state, action)
    case actionTypes.ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS:
      return abrirModalDilucionAnalisisExtraccionSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS:
      return guardarModalDilucionAnalisisExtraccionSuccess(state, action)
    case actionTypes.CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION_SUCCESS:
      return cerrarModalDilucionAnalisisExtraccionSuccess(state, action)
    case actionTypes.ACTUALIZAR_ESTADO_MAQUINA_DILUCION_ANALISIS_EXTRACCION:
      return actualizarEstadoMaquinaDilucionAnalisisExtraccion(state, action)
    case actionTypes.PDF_ETIQUETA_PDF_SUCCESS:
      return imprimirEtiquetaPDFSuccess(state, action)
    case actionTypes.ABRIR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS:
      return abrirModalPoolAnalisisExtraccionSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_POOL_ANALISIS_EXTRACCION_SUCCESS:
      return guardarModalPoolAnalisisExtraccionSuccess(state, action)
    case actionTypes.CERRAR_MODAL_POOL_ANALISIS_EXTRACCION:
      return cerrarModalPoolAnalisisExtraccionSuccess(state, action)
    case actionTypes.SHOW_MODAL_ALERTA_SUCCESS:
      return showModalAlertaSuccess(state, action)
    case actionTypes.SHOW_ULTIMAS_EXTRACCIONES_VERRACO_SUCCESS:
      return showUltimasExtraccionesVerracoSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalGrupoVerraco.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import { DoneAll, Warning } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalVerracoGrupoVerraco extends Component {
  constructor () {
    super()
    this.state = {
      fitrosSeleccionados: [],
      validarAsignarVerracos: true
    }
  }

  anadirVerraco (values) {
    const itemsSeleccion = this.props.listVerracosEnGrupo.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (Object.keys(list).length > 0) {
      this.setState({validarAsignarVerracos: true})
      this.props.actions.anadirVerraco(list)
      this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
    } else {
      this.setState({validarAsignarVerracos: false})
    }
    this.props.change('seleccionar-todos-modal-verraco-grupo-verraco', false)
  }

  guardarYnuevoGrupoVerraco(values){
    this.props.actions.guardarYnuevoGrupoVerraco(values)
  }

  intercambiarCheckModalVerracosGrupoVerraco (value) {
    this.setState({validarAsignarVerracos: true})
    if (value === true) {
      this.props.actions.seleccionarTodosModalVerracoGrupoVerraco()
    } else {
      this.props.actions.deSeleccionarTodosModalVerracoGrupoVerraco()
    }
  }

  componentDidMount () {
    // this.props.actions.fetchVerracosGrupoVerraco(this.props.verracosAnadidos)
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacionPadre()
    this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
    this.props.actions.fetchFiltrarVerracoModalVerraco({estado: {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}})
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalSeleccionarVerraco !== prevProps.showModalSeleccionarVerraco && this.props.showModalSeleccionarVerraco === true) {
      let valores = {
        estado: {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')},
        list: this.props.verracosAnadidos
      }
      this.props.change('idVerraco', '')
      this.props.change('idRaza', '')
      this.props.change('idLinea', '')
      this.props.change('idUbicacion', '')
      this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
      this.props.change('ebv', '')
      this.props.actions.fetchFiltrarVerracoModalVerraco(valores)
    }
  }

  fetchFiltrarVerracoModalVerraco (values) {
    this.props.actions.fetchFiltrarVerracoModalVerraco(values)
  }

  cerrarModalSeleccionarVerraco () {
    this.props.actions.cerrarModalSeleccionarVerraco()
    this.props.change('idVerraco', '')
    this.props.change('idRaza', '')
    this.props.change('idLinea', '')
    this.props.change('idUbicacion', '')
    this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
    this.props.change('ebv', '')
    this.props.actions.fetchFiltrarVerracoModalVerraco({estado: {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}})
  }

  quitarFiltros (value) {
    let values = this.props.filtrosModal
    values[value] = null
    this.props.actions.fetchFiltrarVerracoModalVerraco(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
    const {
      t, handleSubmit, listVerracosEnGrupo, showModalSeleccionarVerraco, checkedVerracosGrupoVerraco, numeroRegistrosPorPagina, filtrosModal, paginaActual,
      actions: { cerrarModalSeleccionarVerraco },
      combos: {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboVerraco},
    } = this.props

  let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

    listVerracosEnGrupo.forEach(
      (row) => row.idElemento = row.idVerraco + 'verraco-grupo-verraco'
    )

    const tableVerracos = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      noBlockOptions: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: listVerracosEnGrupo,
      rowsVerraco: true,
      mostrarModificar: false,
      initialValues: {},
    }

    tableVerracos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracos.initialValues['check' + row.idElemento] = checkedVerracosGrupoVerraco
        }
      }
    )

    return (
      <Modal show={showModalSeleccionarVerraco} onHide={() => this.cerrarModalSeleccionarVerraco()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-grupo-verraco" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('GRUPOS_VERRACO.MODAL.VERRACOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-filtros-modal-grupo-verracos">
                <form className="custom-form">
                  <Field
                    colSm={2}
                    id="idVerraco"
                    name="idVerraco"
                    controlLabel={t('FICHA_VERRACO.FILTROS.VERRACO')}
                    component={InputSelect}
                    options={comboVerraco}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-verraco'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idRaza"
                    name="idRaza"
                    controlLabel={t('FICHA_VERRACO.FILTROS.RAZA')}
                    component={InputSelect}
                    options={comboRaza}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-raza'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idLinea"
                    name="idLinea"
                    controlLabel={t('FICHA_VERRACO.FILTROS.LIN_GENETICA')}
                    component={InputSelect}
                    options={comboLineaGenetica}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-lin-genetica'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="estado"
                    name="estado"
                    controlLabel={t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO')}
                    component={InputSelect}
                    options={comboEstadoVerraco}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-estado-verraco'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                  />
                  {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                    <Field
                      colSm={2}
                      id="idUbicacion"
                      name="idUbicacion"
                      controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.UBICACION')}
                      component={InputSelect}
                      options={comboUbicacionPadre}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-ubicacion'
                      onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                    />
                  )}
                  <Field
                    colSm={2}
                    id="ebv"
                    name="ebv"
                    controlLabel={t('FICHA_VERRACO.FILTROS.INDEX')}
                    component={InputNumerical}
                    numDecimales={0}
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalVerraco.bind(this))}
                  />
                  <div className="clearfix"></div>
                  <div className="aplicar-tabla-filtros">
                    <div className="filtros-anadidos">
                      {
                        Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.idVerraco && filtrosModal.idVerraco !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.VERRACO') + ': ' + filtrosModal.idVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idVerraco')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.idRaza && filtrosModal.idRaza !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.RAZA') + ': ' + filtrosModal.idRaza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idRaza')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.idLinea && filtrosModal.idLinea !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.LIN_GENETICA') + ': ' + filtrosModal.idLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idLinea')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.estado && filtrosModal.estado !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO') + ': ' + filtrosModal.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.idUbicacion && filtrosModal.idUbicacion !== null ? (
                            <span className="badge-dark">{t('CAMBIO_ESTADO_VERRACOS.FILTROS.UBICACION') + ': ' + filtrosModal.idUbicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idUbicacion')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosModal).length > 0 && (
                          filtrosModal.ebv && filtrosModal.ebv !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.INDEX') + ': ' + filtrosModal.ebv}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                          ) : null
                        )
                      }
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </Col>
            <div className="tabla-verracos-en-grupo-verracos">
              <div className="filtros-tabla-modal-grupo-verracos">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-verraco-grupo-verraco"
                    name="seleccionar-todos-modal-verraco-grupo-verraco"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalVerracosGrupoVerraco(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracos}>
              </ListadoPage>
              {
                this.state.validarAsignarVerracos === false ? (
                  <p className="validar-asignar-verracos"><span className='help-block'><Warning />{t('GRUPOS_VERRACO.MODAL.ERROR_ANADIR_VERRACO')}</span></p>
                ) : null
              }
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirVerraco.bind(this))}>{t('GRUPOS_VERRACO.MODAL.ANADIR_GRUPO')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalSeleccionarVerraco()}>{t('GRUPOS_VERRACO.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVerracoGrupoVerraco',
  enableReinitialize: true
})(ModalVerracoGrupoVerraco))
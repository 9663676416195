import smoothscroll from 'smoothscroll-polyfill';

export function getFirstDeepestElement(object, parentKey = null) {
  const objectKeys = typeof object === 'object' && Object.keys(object)
  if (objectKeys && objectKeys.length > 0) {
    const currentKey = (parentKey ? parentKey + '.' : '') + objectKeys[0]
    return getFirstDeepestElement(object[objectKeys[0]], currentKey)
  }
  return parentKey
}

// Conjuto de funciones utilitarias generales.
export function onSubmitFail(errors) {
  console.log('[onSubmitFail]', errors)

  const elementId = getFirstDeepestElement(errors)
  if (elementId) {
    const element = document.getElementById(elementId)
    activateTabs(element)
    scrollToElement(element)

  }
}

export function activateTabs(element) {
  let tabPane = element.closest('.tab-pane')
  if (tabPane) {
    let tab = document.querySelector('[aria-controls=' + tabPane.id + ']')
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    tab.dispatchEvent(event);
  }
}

export function scrollToElement(element) {
  smoothscroll.polyfill() // Polyfill necesario para MS IE y MS Edge ya que no soportan ScrollToOptions object.

  const y = element.getBoundingClientRect().top + window.pageYOffset;
  window.scroll({
    top: y - (window.innerHeight * 0.4), // window.innerHeight * 0.4 => hacer scroll hasta que el elemento este en la parte central de la pantalla (ligeramente por encima)
    behavior: 'smooth'
  });
}

export function downloadDocument(href, name) {
  let element = document.createElement('a')
  element.setAttribute('href', href)
  element.setAttribute('download', name)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

// Leer el contenido de un fichero a traves de la api File.
// https://w3c.github.io/FileAPI/#example-db2fd131
export function readDocument(archivo, onProgress, onLoad, onError) {

  let reader = new FileReader();
  reader.readAsText(archivo, "UTF-8");

  // Este manejador permite controlar el estado de carga del fichero.
  reader.onprogress = onProgress

  // Este manejador permite trabajar con el fichero una vez ha cargado.
  reader.onload = onLoad

  // Este manejador permite controlar si ha habido errores relacionados con la lectura del fichero.
  reader.onerror = onError
}

// Source:
// https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
export function throttle(func, limit) {
  let inThrottle
  return function() {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

// Source:
// https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
export function debounce(func, delay) {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import './TipoIntervencionSanitaria.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'

class ModalTipoIntervencionSanitaria extends Component {
  submitNuevoTipoIntervencionSanitaria (values) {
    if (this.props.data){
      this.props.actions.editarTipoIntervencionSanitaria(values)
    } else {
      this.props.actions.submitNuevoTipoIntervencionSanitaria(values)
    }
  }
  guardarYnuevoTipoIntervencionSanitaria(values){
    this.props.actions.guardarYnuevoTipoIntervencionSanitaria(values)
  }
  render () {
    const {
      t, handleSubmit, list, showModal, data, duplicar,
      actions: {closeModal, recuperarDatosModalTipoIntervencionSanitaria}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" className="modal-tipo-intervencion" backdrop="static">
      <form className="form-TipoIntervencionSanitaria">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idTipoIntervencion && (
            <ButtonChangeRecord list={list} idElemento="idTipoIntervencion" currentId={data.idTipoIntervencion} getNextRecord={recuperarDatosModalTipoIntervencionSanitaria} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="tipoIntervencion"
              name="tipoIntervencion"
              colSm={12}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.NOMBRE_TIPO_INTERVENCION_SANITARIA') + '*'}
              claseActivo="nombre-tipo-intervencion-sanitaria"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="observaciones"
              name="observaciones"
              colSm={12}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.OBSERVACIONES')}
              claseActivo="observaciones-tipo-intervencion-sanitaria"
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.ACTIVO')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button type="button" className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoTipoIntervencionSanitaria.bind(this)) : null}>{t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoTipoIntervencionSanitaria.bind(this)) : null}
            className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2)}
          >{t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModal}>{t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoIntervencionSanitaria',
})(ModalTipoIntervencionSanitaria))
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import Img from '../comun/img/Img'
import './CamaraView.scss'

class CamaraView extends Component {
  render () {
    const {
      camaraConectada, urlCamara, base64Img,
      t, actions: {comprobarCamara, apagarCamara}
    } = this.props
    const tKey = 'ANALISIS_EXTRACCIONES.CAMARA_VIEW.'

    return (
      <div className="camara-container">
        <Img
          className="center-block"
          src={camaraConectada ? `${urlCamara}video_feed?t=${Date.now()}` : base64Img ? `data:image/png;base64, ${base64Img}` : 'https://via.placeholder.com/600x600?text=Camara+no+iniciada'}
        />
        <Row className="row-flex row-collapsed">
          <Col className="col-flex">
            <Button type="button" className="btn btn-primary btn-input-style" onClick={comprobarCamara}>{t(tKey + 'BUTTONS.INICIAR')}</Button>
          </Col>
          <Col className="col-flex">
            <Button type="button" className="btn btn-primary btn-input-style" onClick={apagarCamara}>{t(tKey + 'BUTTONS.PARAR')}</Button>
          </Col>
          <Col className="col-flex">
            <Button type="button" className="btn btn-primary btn-input-style" onClick={() => {}}>{t(tKey + 'BUTTONS.GUARDAR')}</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default CamaraView
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import actionTypes from '../../constants/actions/cargaIndex/cargaIndex'
import {submitCargaIndexSuccess} from '../../actions/cargaIndex/cargaIndex'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getCargaIndex from '../../services/cargaIndex/getCargaIndex'
import getDescargarPlantillaCsv from '../../services/cargaIndex/getDescargarPlantillaCsv'

export function * submitCargaIndex ({value}) {
  try {
    let auth = yield select(state => state.auth)
    const listCargaIndex = yield call(getCargaIndex, value, auth.token)
    yield put(submitCargaIndexSuccess(listCargaIndex))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitCargaIndex () {
  yield takeLatest(actionTypes.SUBMIT_CARGA_INDEX, submitCargaIndex)
}

export function * descargarPlantillaCsv () {
  try {
    const archivoCsv = yield call(getDescargarPlantillaCsv, null)
    let href = 'data:application/csv;base64,' + encodeURIComponent(archivoCsv.csv)
    let name = i18n.t('CARGA_INDEX.EJEMPLO_CSV_CARGA_INDEX') + ".csv"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDescargarPlantillaCsv () {
  yield takeLatest(actionTypes.DESCARGAR_PLANTILLA_CSV, descargarPlantillaCsv)
}

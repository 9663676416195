import settings from '../setting'
import { logout } from '../actions/auth/auth'
import { createStore } from 'redux'
import configureStore from '../store/configureStore'

export default ({
  server = 'default', endPoint, method, body, fileBody = false, token, credentials = 'include',
  accept = 'application/json', contentType = 'application/json; charset=utf-8', mode = 'same-origin',
  signal = undefined
}) =>

  new Promise((resolve, reject) => {
    const myHeaders = {}
    if (!fileBody) {
      myHeaders['Accept'] = accept
      myHeaders['Content-Type'] = contentType
    }
    if (token) {
      body = {...body, token}
    }

    let myInit = {
      method: method,
      headers: myHeaders,
      credentials: credentials,
      mode: mode
    }
    if (body) {
      myInit.body = fileBody ? body : JSON.stringify(body)
    }
    if (signal) {
      myInit.signal = signal
    }

    let finalAddress
    if (server) {
      finalAddress = settings[server].url + endPoint
    } else {
      finalAddress = endPoint
    }

    fetch(finalAddress, myInit)
      .then(response => {
        if (response.ok) {
          if (response.status === 204 || response.status === 201) {
            resolve()
          } else if (accept  === 'text/html') {
            response.text().then(resolve).catch(reject)
          } else {
            response.json().then((datos)=>{
              if (datos && datos.login === false){
                setTimeout(() => { window.location = '/#/LostSession' }, 10)
              } else {
                return resolve(datos)
              }
            }).catch(reject)
          }
        } else {
          response.json().then(errJson => {
            console.log(errJson)
            return reject({status: response.status, json: errJson})
          }).catch(reject)
          /* if (response.status === 401) {
            console.log(window.location)
            setTimeout(() => { window.location.assign('/') }, 10)
          } */
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Request aborted', error)
          return reject({status: 'gesipor::REQUEST_ABORTED', json: error})
        } else {
          console.log('It looks like a network error', error)
          return reject({status: 'net::ERR_CONNECTION_REFUSED', json: error})
        }
      });
  })

import actionTypes from '../../constants/actions/raza/raza'

export function initialState () {
  return {
    list: [],
    checkedFiltrosRaza: false,
    filtros:{},
    datosExcelRaza: [],
    datosPdfRaza: [],
    showModalRaza: false
  }
}

export function fetchRazasSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalRazaSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalRazaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function cambiarEstadoRazaSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteRazaSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosRaza (state) {
  return {
    ...state,
    checkedFiltrosRaza: true
  }
}

export function deSeleccionarTodosRaza (state) {
  return {
    ...state,
    checkedFiltrosRaza: false
  }
}

export function fetchFiltrarRazaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function submitNuevaRazaSuccess (state, {insercionRaza}) {
  return {
    ...state,
    insercionRaza
  }
}

export function editRazaSuccess (state, {edicionRaza}) {
  return {
    ...state,
    edicionRaza
  }
}

export function crearCsvRazaSuccess (state, {datosExcelRaza}) {
  return {
    ...state,
    datosExcelRaza
  }
}

export function imprimirPdfRazaSuccess (state, {datosPdfRaza}) {
  return {
    ...state,
    datosPdfRaza
  }
}

export function openModalRaza (state) {
  return {
    ...state,
    showModalRaza: true
  }
}

export function closeModalRaza (state) {
  return {
    ...state,
    showModalRaza: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_RAZAS_SUCCESS:
      return fetchRazasSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_RAZA_SUCCESS:
      return recuperarDatosModalRazaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_RAZA_SUCCESS:
      return duplicarDatosModalRazaSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_RAZA_SUCCESS:
      return cambiarEstadoRazaSuccess(state, action)
    case actionTypes.ON_DELETE_RAZA_SUCCESS:
      return onDeleteRazaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_RAZA:
      return seleccionarTodosRaza(state, action)
    case actionTypes.DESELECCIONAR_TODOS_RAZA:
      return deSeleccionarTodosRaza(state, action)
    case actionTypes.FETCH_FILTRAR_RAZA_SUCCESS:
      return fetchFiltrarRazaSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_RAZA_SUCCESS:
      return submitNuevaRazaSuccess(state, action)
    case actionTypes.EDIT_RAZA_SUCCESS:
      return editRazaSuccess(state, action)
    case actionTypes.CREATE_CSV_RAZA_SUCCESS:
      return crearCsvRazaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_RAZA_SUCCESS:
      return imprimirPdfRazaSuccess(state, action)
    case actionTypes.OPEN_MODAL_RAZA:
      return openModalRaza(state, action)
    case actionTypes.CLOSE_MODAL_RAZA:
      return closeModalRaza(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalNivelGenetico from '../../components/nivelGenetico/ModalNivelGenetico'
import {recuperarDatosModalNivelGenetico, submitNuevaNivelGenetico, editarNivelGenetico} from '../../actions/nivelGenetico/nivelGenetico'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.nivelGenetico,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalNivelGenetico,
      submitNuevaNivelGenetico,
      editarNivelGenetico,
      closeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalNivelGenetico))
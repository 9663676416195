import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalTeclasContajePanelControl from '../../components/panelControl/ModalTeclasContajePanelControl'
import {cerrarModalTeclasContaje, obtenerValoresTeclasContaje,valoresPredefinidosTeclasContaje, guardarTeclasContaje} from '../../actions/panelControl/panelControl'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth,
    // showModalTeclasContaje: state.common.showModalTeclasContaje
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalTeclasContaje,
      obtenerValoresTeclasContaje,
      valoresPredefinidosTeclasContaje,
      guardarTeclasContaje
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTeclasContajePanelControl))
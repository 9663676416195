import actionTypes from '../../constants/actions/importarPedidos/importarPedidos'

export function initialState () {
  return {
    showModalImportarPedidos: false,
    archivo: [],
    listImportarPedidos: []
  }
}

export function abrirModalImportarPedidos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarPedidos: true
  }
}

export function cerrarModalImportarPedidos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarPedidos: false,
    archivo: [],
    listImportarPedidos: []
  }
}

export function subirArchivosImportarPedidos (state, {archivo}) {
  return {
    ...state,
    archivo: archivo
  }
}

export function submitImportarPedidosSuccess (state, {listImportarPedidos}) {
  return {
    ...state,
    listImportarPedidos: listImportarPedidos
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_IMPORTAR_PEDIDOS:
      return abrirModalImportarPedidos(state, action)
     case actionTypes.CERRAR_MODAL_IMPORTAR_PEDIDOS:
      return cerrarModalImportarPedidos(state, action)
     case actionTypes.SUBIR_ARCHIVOS_IMPORTAR_PEDIDOS:
      return subirArchivosImportarPedidos(state, action)
     case actionTypes.SUBMIT_IMPORTAR_PEDIDOS_SUCCESS:
      return submitImportarPedidosSuccess(state, action)
    default:
      return state
  }
}

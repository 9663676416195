import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import ModalUsuarios from '../../components/usuarios/ModalUsuarios'
import { recuperarDatosUsuario, cerrarModalUsuarios, uploadFileUsuarios, fetchCentrosUsuarios, fetchUsuarioGrupoUsuarios, abrirModalListCentro, abrirModalGruposUsuarios, eliminarGrupoUsuariosAnadidos,
  seleccionarTodosTablaGruposUsuario, deSeleccionarTodosTablaGruposUsuario, seleccionarTodosTablaCentros, deSeleccionarTodosTablaCentros, eliminarCentrosAnadidos,
  submitNuevoUsuario, editarUsuarios, abrirModalCambiarTokenMagavision, cambiarTokenMagavision} from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    urlRecursos: setting.default.urlRecursos,
    formSimpleTable: state.form.simpleTable,
    formModalUsuarios: state.form.ModalUsuarios,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosUsuario,
      cerrarModalUsuarios,
      uploadFileUsuarios,
      fetchCentrosUsuarios,
      fetchUsuarioGrupoUsuarios,
      abrirModalListCentro,
      abrirModalGruposUsuarios,
      eliminarGrupoUsuariosAnadidos,
      seleccionarTodosTablaGruposUsuario,
      deSeleccionarTodosTablaGruposUsuario,
      seleccionarTodosTablaCentros,
      deSeleccionarTodosTablaCentros,
      eliminarCentrosAnadidos,
      submitNuevoUsuario,
      editarUsuarios,
      abrirModalCambiarTokenMagavision,
      cambiarTokenMagavision
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUsuarios))
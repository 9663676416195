export default {
  FETCH_MATERIAL_DISPONIBLE: 'FETCH_MATERIAL_DISPONIBLE',
  FETCH_MATERIAL_DISPONIBLE_SUCCESS: 'FETCH_MATERIAL_DISPONIBLE_SUCCESS',
  OPEN_MODAL_MATERIAL_DISPONIBLE: 'OPEN_MODAL_MATERIAL_DISPONIBLE',
  OPEN_MODAL_MATERIAL_DISPONIBLE_SUCCESS: 'OPEN_MODAL_MATERIAL_DISPONIBLE_SUCCESS',
  GUARDAR_MODAL_MATERIAL_DISPONIBLE: 'GUARDAR_MODAL_MATERIAL_DISPONIBLE',
  GUARDAR_MODAL_MATERIAL_DISPONIBLE_SUCCESS: 'GUARDAR_MODAL_MATERIAL_DISPONIBLE_SUCCESS',
  CLOSE_MODAL_MATERIAL_DISPONIBLE: 'CLOSE_MODAL_MATERIAL_DISPONIBLE',
  CLOSE_MODAL_MATERIAL_DISPONIBLE_SUCCESS: 'CLOSE_MODAL_MATERIAL_DISPONIBLE_SUCCESS',
  SELECCIONAR_TODOS_MATERIAL_DISPONIBLE: 'SELECCIONAR_TODOS_MATERIAL_DISPONIBLE',
  DESELECCIONAR_TODOS_MATERIAL_DISPONIBLE: 'DESELECCIONAR_TODOS_MATERIAL_DISPONIBLE'
}
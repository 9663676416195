import actionTypes from '../../constants/actions/plano/plano'

export function initialState () {
  return {
    instalacionesHijas: [],
  }
}

export function fetchPlanoSuccess (state, {instalacionesHijas}) {
  return {
    ...state,
    instalacionesHijas: instalacionesHijas
  }
}

export function vaciarPlano (state) {
  return {
    ...state,
    instalacionesHijas: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_PLANO_SUCCESS:
      return fetchPlanoSuccess(state, action)
    case actionTypes.VACIAR_PLANO:
      return vaciarPlano(state, action)
    default:
      return state
  }
}
export default {
  ABRIR_MODAL_ESCANDALLO: 'ABRIR_MODAL_ESCANDALLO',
  CLOSE_MODAL_ESCANDALLO: 'CLOSE_MODAL_ESCANDALLO',
  SUBMIT_NUEVO_ESCANDALLO: 'SUBMIT_NUEVO_ESCANDALLO',
  SUBMIT_NUEVO_ESCANDALLO_SUCCESS: 'SUBMIT_NUEVO_ESCANDALLO_SUCCESS',
  OBTENER_ESCANDALLOS_ALMACEN: 'OBTENER_ESCANDALLOS_ALMACEN',
  FILTRAR_ESCANDALLO: 'FILTRAR_ESCANDALLO',
  FILTRAR_ESCANDALLO_SUCCESS: 'FILTRAR_ESCANDALLO_SUCCESS',
  FILTROS_ESCANDALLO_SUCCESS: 'FILTROS_ESCANDALLO_SUCCESS',
  RECUPERAR_DATOS_MODAL_ESCANDALLO: 'RECUPERAR_DATOS_MODAL_ESCANDALLO',
  RECUPERAR_DATOS_MODAL_ESCANDALLO_SUCCESS: 'RECUPERAR_DATOS_MODAL_ESCANDALLO_SUCCESS',
  EDITAR_ESCANDALLO: 'EDITAR_ESCANDALLO',
  DUPLICAR_DATOS_MODAL_ESCANDALLO: 'DUPLICAR_DATOS_MODAL_ESCANDALLO',
  DUPLICAR_DATOS_MODAL_ESCANDALLO_SUCCESS: 'DUPLICAR_DATOS_MODAL_ESCANDALLO_SUCCESS',
  CAMBIAR_ESTADO_ESCANDALLO: 'CAMBIAR_ESTADO_ESCANDALLO',
  ON_DELETE_ESCANDALLO: 'ON_DELETE_ESCANDALLO',
  SELECCIONAR_TODOS_ESCANDALLO: 'SELECCIONAR_TODOS_ESCANDALLO',
  DESELECCIONAR_TODOS_ESCANDALLO: 'DESELECCIONAR_TODOS_ESCANDALLO',
  CAMBIAR_ESTADO_SELECCION_ESCANDALLO: 'CAMBIAR_ESTADO_SELECCION_ESCANDALLO',
  DELETE_SELECCION_ESCANDALLO: 'DELETE_SELECCION_ESCANDALLO',
  CREATE_CSV_ESCANDALLO: 'CREATE_CSV_ESCANDALLO',
  CREATE_CSV_ESCANDALLO_SUCCESS: 'CREATE_CSV_ESCANDALLO_SUCCESS',
  IMPRIMIR_PDF_ESCANDALLO: 'IMPRIMIR_PDF_ESCANDALLO',
  IMPRIMIR_PDF_ESCANDALLO_SUCCESS: 'IMPRIMIR_PDF_ESCANDALLO_SUCCESS',
  ANADIR_ESCANDALLO_ANALISIS: 'ANADIR_ESCANDALLO_ANALISIS',
  ANADIR_ESCANDALLO_DILUCION: 'ANADIR_ESCANDALLO_DILUCION',
  ANADIR_ESCANDALLO_ENVASADO: 'ANADIR_ESCANDALLO_ENVASADO',
  ABRIR_MODAL_ANADIR_ESCANDALLO: 'ABRIR_MODAL_ANADIR_ESCANDALLO',
  CLOSE_MODAL_ANADIR_ESCANDALLO: 'CLOSE_MODAL_ANADIR_ESCANDALLO',
  EDITAR_ESCANDALLO_ANADIDO: 'EDITAR_ESCANDALLO_ANADIDO',
  EDITAR_ESCANDALLO_ANADIDO_SUCCESS: 'EDITAR_ESCANDALLO_ANADIDO_SUCCESS',
  ON_DELETE_ESCANDALLO_ANADIDO: 'ON_DELETE_ESCANDALLO_ANADIDO',
  ON_DELETE_ESCANDALLO_ANADIDO_SUCCESS: 'ON_DELETE_ESCANDALLO_ANADIDO_SUCCESS',
  DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO: 'DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO',
  DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS: 'DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS',
  SELECCIONAR_TODOS_ESCANDALLO_ANADIDOS: 'SELECCIONAR_TODOS_ESCANDALLO_ANADIDOS',
  DESELECCIONAR_TODOS_ESCANDALLO_ANADIDOS: 'DESELECCIONAR_TODOS_ESCANDALLO_ANADIDOS',
  DELETE_SELECCION_ESCANDALLO_ANADIDO: 'DELETE_SELECCION_ESCANDALLO_ANADIDO',
  RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO: 'RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO',
  RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS: 'RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS',
  GUARDAR_Y_NUEVO_ESCANDALLO: 'GUARDAR_Y_NUEVO_ESCANDALLO',
  GUARDAR_Y_NUEVO_ESCANDALLO_SUCCESS: 'GUARDAR_Y_NUEVO_ESCANDALLO_SUCCESS',
  CAMBIAR_ESTADO_SELECCION_ESCANDALLO: 'CAMBIAR_ESTADO_SELECCION_ESCANDALLO'
}
import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'
import './CargaIndex.scss'

class CargaIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: '',
      archivoCargado: false
    }
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosCargaIndex({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
        this.setState({archivoCargado: true})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitCargaIndex () {
    this.props.actions.submitCargaIndex(this.state.archivos)
    this.setState({archivoCargado: false})
  }

  cerrarModalCargaIndex () {
    this.props.actions.cerrarModalCargaIndex()
    this.setState({archivos: ''})
    this.setState({archivoCargado: false})
  }

  descargarPlantillaCsv () {
    this.props.actions.descargarPlantillaCsv()
  }

  render () {
    const {
      t, handleSubmit, showModalCargaIndex, archivo, listCargaIndex
      } = this.props

    if(!showModalCargaIndex) return null
    return (
      <Modal show={showModalCargaIndex} onHide={() => this.cerrarModalCargaIndex()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-carga-index">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('CARGA_INDEX.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-carga-index">
              <Dropzone
                accept=".csv"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivo).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
              ) : null
            }
            {
              Object.keys(listCargaIndex).length > 0 ? (
                <div className="resultado-verracos-index">
                  {
                    listCargaIndex.map((list, i) => {
                      return <p key={i} className={list.isertado === true ? 'index-insertado' : 'index-no-insertado'}>
                        {
                          list.isertado === true ? (
                            '- ' + list.codVerraco + ' | ' + list.fecha + ' | ' + list.indexVerraco + '. ' + t('CARGA_INDEX.INSERTADO_CORRECTAMENTE')
                          ) : (
                            '- ' + list.codVerraco + ' | ' + list.fecha + ' | ' + list.indexVerraco + ' ' + t('CARGA_INDEX.FECHA_COINCIDENTE')
                          )
                        }
                      </p>
                    })
                  }
                </div>
                ) : (
                  listCargaIndex === false && this.state.archivoCargado === false ? (
                    <p className="index-fallido">{t('CARGA_INDEX.NO_SE_PUEDE_SUBIR_ARCHIVO')}</p>
                  ) : null
                )
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={() => this.descargarPlantillaCsv()} className="btn btn-primary">{t('CARGA_INDEX.DESCARGAR_EJEMPLO_CSV')}</Button>
          {
            Object.keys(listCargaIndex).length === 0 ? (
              <Button type="button" onClick={() => this.submitCargaIndex()} className="btn btn-primary">{t('CARGA_INDEX.ACEPTAR')}</Button>
            ) : null
          }
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalCargaIndex()}>{Object.keys(listCargaIndex).length > 0 ? (t('CARGA_INDEX.CERRAR')) : (t('CARGA_INDEX.CANCELAR'))}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'CargaIndex',
})(CargaIndex))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {yesNoModal} from '../../actions/common'
import {closeModalContajeMaquinas, closeModalContajeMaquinasSuccess} from '../../actions/analisisExtracciones/contajeMaquinas'
import ModalContajeMaquinas from '../../components/analisisExtracciones/ModalContajeMaquinas'

export function mapStateToProps (state) {
  return {
    auth: state.auth,
    tipoAnalisis: state.contajeMaquinas.tipoAnalisis || state.panelControl.tipoAnalisisPredeterminado,
    ...state.contajeMaquinas,
    initialValues: {
      ...state.contajeMaquinas.initialValues,
      espermatozoidesContar: state.panelControl.espermatozoidesContar
    },
    combos: {
      comboDispositivosVideo: state.combos.comboDispositivosVideo,
    },
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      closeModalContajeMaquinas,
      closeModalContajeMaquinasSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalContajeMaquinas))
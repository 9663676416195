import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosValidacionMateriaPrima extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  filtrarValidacionMateriaPrima(values){
    this.props.actions.filtrarValidacionMateriaPrima(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosValidacionMateriaPrima()
    }
    if (this.props.datosExcelValidacionMateriaPrima !== prevProps.datosExcelValidacionMateriaPrima) {
      if (this.props.datosExcelValidacionMateriaPrima.excel && this.props.datosExcelValidacionMateriaPrima.excel !== null){
        let pdf = this.props.datosExcelValidacionMateriaPrima.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.VALIDACION_MATERIAS_PRIMAS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfValidacionMateriaPrima !== prevProps.datosPdfValidacionMateriaPrima) {
      if (this.props.datosPdfValidacionMateriaPrima.pdf && this.props.datosPdfValidacionMateriaPrima.pdf !== null){
        let pdf = this.props.datosPdfValidacionMateriaPrima.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.ALMACEN.VALIDACION_MATERIAS_PRIMAS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosValidacionMateriaPrima()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboNombreProductos()
    this.props.actions.comboLotes()
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosValidacionMateriaPrima()
    } else {
      this.props.actions.deSeleccionarTodosValidacionMateriaPrima()
    }
  }

  deleteSeleccionValidacionMateriaPrima(){
    const itemsSeleccion = this.props.listValidacionMateriaPrima.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idValidacionMateriaPrima': row.idValidacionMateriaPrima
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionValidacionMateriaPrima(list)
    this.props.change('seleccionar-todo', false)
  }

  crearCsvValidacionMateriaPrima(){
    this.props.actions.crearCsvValidacionMateriaPrima(this.props.filtros)
  }

  imprimirPdfValidacionMateriaPrima () {
    this.props.actions.imprimirPdfValidacionMateriaPrima(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.filtrarValidacionMateriaPrima(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalValidacionMateriaPrima
    },
    combos: {comboNombreProductos, comboLotes}
  } = this.props

  const comboApto = [
    {value: true, label: this.props.t('COMUN.COMBOS.APTO.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.APTO.NO')}
  ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="producto"
              name="producto"
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PRODUCTOS')}
              component={InputSelect}
              options={comboNombreProductos}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarValidacionMateriaPrima.bind(this))}
            />
            <Field
              colSm={2}
              id="lote"
              name="lote"
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.LOTE')}
              component={InputSelect}
              options={comboLotes}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarValidacionMateriaPrima.bind(this))}
            />
            <Field
              colSm={2}
              id="apto"
              name="apto"
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.APTO')}
              component={InputSelect}
              options={comboApto}
              valueKey="value"
              labelKey="label"
              customClass='select-producto'
              disabled={false}
              onInputChange={handleSubmit(this.filtrarValidacionMateriaPrima.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.filtrarValidacionMateriaPrima.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.producto && filtros.producto !== null ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PRODUCTOS') + ': ' + filtros.producto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('producto')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.lote && filtros.lote !== null ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.LOTE') + ': ' + filtros.lote.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lote')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.apto && filtros.apto !== null ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.APTO') + ': ' + filtros.apto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('apto')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionValidacionMateriaPrima()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvValidacionMateriaPrima()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfValidacionMateriaPrima()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                type="button"
                data-toggle="modal"
                data-target="#new-edit-modal"
                className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] > 1) ? abrirModalValidacionMateriaPrima : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosValidacionMateriaPrima',
})(FiltrosValidacionMateriaPrima))

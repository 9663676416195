import actionTypes from '../../constants/actions/cargaIndex/cargaIndex'

export function submitCargaIndex (value) {
  return {
    type: actionTypes.SUBMIT_CARGA_INDEX,
    value
  }
}

export function submitCargaIndexSuccess (listCargaIndex) {
  return {
    type: actionTypes.SUBMIT_CARGA_INDEX_SUCCESS,
    listCargaIndex
  }
}

export function abrirModalCargaIndex () {
  return {
    type: actionTypes.ABRIR_MODAL_CARGA_INDEX
  }
}

export function cerrarModalCargaIndex () {
  return {
    type: actionTypes.CERRAR_MODAL_CARGA_INDEX
  }
}

export function subirArchivosCargaIndex (archivo) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_CARGA_INDEX,
    archivo
  }
}

export function descargarPlantillaCsv () {
  return{
    type: actionTypes.DESCARGAR_PLANTILLA_CSV
  }
}

export default {
  submitCargaIndex,
  abrirModalCargaIndex,
  cerrarModalCargaIndex,
  subirArchivosCargaIndex,
  submitCargaIndexSuccess,
  descargarPlantillaCsv
}
import actionTypes from '../../constants/actions/auth/auth'
import {defaultlanguage} from '../../i18n.js'

export function initialState () {
  return {
    token: null,
    nombreUsuario: null,
    idioma: defaultlanguage,
    permisos: [],
    modulos: [],
    formaFechaHora: 'day/month/year/24',
    lunesInicioSemana: false,
    separadorDec: '.',
    separadorMil: ',',
    zonaHoraria: null,
    imagenUsuario: null,
    centro: null,
    pertenece_grupo_admin: false,
    desautorizado: false,
    expiredSession: false,
    adminLogin: false,
    licenciaExpirada: false,
    isCloud: true,
    numeroSerie: '',
    archivo: {},
    datosLicencia: [],
    farmId: '',
  }
}

export function fetchTokenSuccess (state, {token}) {
  return {
    ...state,
    token
  }
}

export function loginSuccess (state, {data}) {
  return {
    ...state,
    ...data,
    desautorizado: false,
    licenciaExpirada: false
  }
}

export function loginError (state, {data}) {
  return {
    ...state,
    desautorizado: true
  }
}

export function logoutSuccess (state, {expiredSession}) {
  return {
    ...state,
    ...initialState(),
    expiredSession
  }
}

export function cambiarImagenAuth (state, {imagen}) {
  return {
    ...state,
    imagenUsuario: imagen
  }
}

export function cambiarNombreUsuarioAuth (state, {nombreUsuario}) {
  return {
    ...state,
    nombreUsuario: nombreUsuario
  }
}

export function licenciaExpiradaSuccess (state, {licenciaExpirada}) {
  return {
    ...state,
    licenciaExpirada: licenciaExpirada
  }
}

export function comprobarEntornoSuccess (state, {isCloud}) {
  return {
    ...state,
    isCloud: isCloud.cloud
  }
}

export function comprobarEntornoLocalSuccess (state, {isLocal}) {
  return {
    ...state,
    isLocal: isLocal.local
  }
}

export function cambiarIdiomaAuth (state, {idioma}) {
  return {
    ...state,
    idioma: idioma
  }
}

export function obtenerNumeroSerieSuccess (state, {numeroSerie}) {
  return {
    ...state,
    numeroSerie: numeroSerie
  }
}

export function subirArchivosActualizarAplicacion (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function mostrarInformacionLicenciaSuccess (state, {datosLicencia}) {
  return {
    ...state,
    datosLicencia: datosLicencia
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN_SUCCESS:
      return fetchTokenSuccess(state, action)
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action)
    case actionTypes.LOGIN_ERROR:
      return loginError(state, action)
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action)
    case actionTypes.CAMBIAR_IMAGEN_AUTH:
      return cambiarImagenAuth(state, action)
    case actionTypes.CAMBIAR_NOMBRE_USUARIO_AUTH:
      return cambiarNombreUsuarioAuth(state, action)
    case actionTypes.LICENCIA_EXPIRADA_SUCCESS:
      return licenciaExpiradaSuccess(state, action)
    case actionTypes.COMPROBAR_ENTORNO_SUCCESS:
      return comprobarEntornoSuccess(state, action)
    case actionTypes.COMPROBAR_ENTORNO_LOCAL_SUCCESS:
      return comprobarEntornoLocalSuccess(state, action)
    case actionTypes.CAMBIAR_IDIOMA_AUTH:
      return cambiarIdiomaAuth(state, action)
    case actionTypes.OBTENER_NUMERO_SERIE_SUCCESS:
      return obtenerNumeroSerieSuccess(state, action)
    case actionTypes.SUBIR_ARCHIVOS_ACTUALIZAR_APLICACION:
      return subirArchivosActualizarAplicacion(state, action)
    case actionTypes.MOSTRAR_INFORMACION_LICENCIA_SUCCESS:
      return mostrarInformacionLicenciaSuccess(state, action)
    default:
      return state
  }
}
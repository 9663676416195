import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import MovimientoVerraco from '../../components/movimientoVerraco/MovimientoVerraco'
import {abrirModalUbicacion, cerrarModalUbicacion, restartVerraco} from '../../actions/fichaVerraco/fichaVerraco'
import { submitNuevoMoviVerraco, ubicacionenesVacias, enviarFechaMovimientoVerraco, fetchMovimientoVerraco, recuperarDatosModalMovimientoVerraco, abrirModalVacioUbicacion,
  duplicarDatosModalMovimientoVerraco, onDeleteMovimientoVerraco, fetchFiltrarMovimientoVerraco} from '../../actions/movimientoVerraco/movimientoVerraco'

export function mapStateToProps (state) {
  return {
    showModalUbicacion: state.fichaVerraco.showModalUbicacion,
    combos: {...state.combos},
    fichaVerraco: {...state.fichaVerraco},
    movimientoVerraco: {...state.movimientoVerraco},
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalUbicacion,
      abrirModalUbicacion,
      submitNuevoMoviVerraco,
      ubicacionenesVacias,
      enviarFechaMovimientoVerraco,
      fetchMovimientoVerraco,
      recuperarDatosModalMovimientoVerraco,
      abrirModalVacioUbicacion,
      duplicarDatosModalMovimientoVerraco,
      onDeleteMovimientoVerraco,
      restartVerraco,
      fetchFiltrarMovimientoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MovimientoVerraco))
import actionTypes from '../../constants/actions/conectividad/conectividad'

export function initialState () {
  return {
    listConectividad: [],
    checkedFiltrosConectividad: false,
    filtros:{},
    showModalConectividad: false,
    datosExcelConectividad: [],
    datosPdfConectividad: [],
    dataDuplicar: [],
    data: [],
    dataVpn: []
  }
}

export function abrirModalVacioConectividad (state) {
  return {
    ...state,
    showModalConectividad: true
  }
}

export function closeModalConectividad (state) {
  return {
    ...state,
    showModalConectividad: false,
    dataDuplicar: [],
    data: []
  }
}

export function fetchConectividadSuccess (state, {listConectividad}) {
  return {
    ...state,
    listConectividad
  }
}

export function recuperarDatosModalConectividadSuccess (state, {data}) {
  return {
    ...state,
    data: data,
    showModalConectividad: true,
    dataDuplicar: []
  }
}

export function duplicarDatosModalConectividadSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    data: [],
    showModalConectividad: true
  }
}

export function cambiarEstadoConectividadSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteConectividadSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosConectividad (state) {
  return {
    ...state,
    checkedFiltrosConectividad: true
  }
}

export function deSeleccionarTodosConectividad (state) {
  return {
    ...state,
    checkedFiltrosConectividad: false
  }
}

export function fetchFiltrarConectividadSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function submitNuevaConectividadSuccess (state, {insercionConectividad}) {
  return {
    ...state,
    insercionConectividad
  }
}

export function editarConectividadSuccess (state, {edicionConectividad}) {
  return {
    ...state,
    edicionConectividad
  }
}

export function crearCsvConectividadSuccess (state, {datosExcelConectividad}) {
  return {
    ...state,
    datosExcelConectividad
  }
}

export function imprimirPdfConectividadSuccess (state, {datosPdfConectividad}) {
  return {
    ...state,
    datosPdfConectividad
  }
}

export function actualizarConectividadVpnSuccess (state, {dataVpn}) {
  return {
    ...state,
    dataVpn: dataVpn
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.CLOSE_MODAL_CONECTIVIDAD:
      return closeModalConectividad(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_CONECTIVIDAD:
      return abrirModalVacioConectividad(state, action)
    case actionTypes.FETCH_CONECTIVIDAD_SUCCESS:
      return fetchConectividadSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS:
      return recuperarDatosModalConectividadSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_CONECTIVIDAD_SUCCESS:
      return duplicarDatosModalConectividadSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_CONECTIVIDAD_SUCCESS:
      return cambiarEstadoConectividadSuccess(state, action)
    case actionTypes.ON_DELETE_CONECTIVIDAD_SUCCESS:
      return onDeleteConectividadSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CONECTIVIDAD:
      return seleccionarTodosConectividad(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CONECTIVIDAD:
      return deSeleccionarTodosConectividad(state, action)
    case actionTypes.FETCH_FILTRAR_CONECTIVIDAD_SUCCESS:
      return fetchFiltrarConectividadSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_CONECTIVIDAD_SUCCESS:
      return submitNuevaConectividadSuccess(state, action)
    case actionTypes.EDITAR_CONECTIVIDAD_SUCCESS:
      return editarConectividadSuccess(state, action)
    case actionTypes.CREATE_CSV_CONECTIVIDAD_SUCCESS:
      return crearCsvConectividadSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_CONECTIVIDAD_SUCCESS:
      return imprimirPdfConectividadSuccess(state, action)
    case actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN_SUCCESS:
      return actualizarConectividadVpnSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalLineaGeneticaPage from '../../containers/lineaGenetica/ModalLineaGeneticaPage'
import FiltrosLineasGeneticaPage from '../../containers/lineaGenetica/FiltrosLineaGeneticaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {date_formatter, number_formatter, round_decimal} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class LineaGenetica extends Component {
  constructor () {
    super()
    this.state = {
      listLinea: [],
      totalesLinea: [],
      porcentajesLinea: null
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.LINEAS_GENETICAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarLinea({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.lineaGenetica.list !== prevProps.lineaGenetica.list) {
      this.state.listLinea = this.props.lineaGenetica.list.lineasGeneticas
      this.state.totalesLinea = this.props.lineaGenetica.list.totales
      this.state.porcentajesLinea = this.props.lineaGenetica.list.porcentajes
    }
  }

  render () {
    const { 
      t, numeroRegistrosPorPagina, paginaActual,
      lineaGenetica: { list, data, dataDuplicar, dataEstado, checkedFiltros },
      actions: {recuperarDatosModal, duplicarDatosModal, cambiarEstado, onDelete, dataDelete},
      auth: {separadorDec, separadorMil}
    } = this.props

    this.state.listLinea.forEach(
      (row) => row.idElemento = row.idLinea
    )

    const table = {
      id: 'lineaGeneticaTable',
      multiHeader: true,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1),
      hasOptions: true,
      columns: [
        {id: 'codLinea', name: t('LINEA_GENETICA.TABLA.COD_LINEA_GENETICA')},
        {id: 'nombreLinea', name: t('LINEA_GENETICA.TABLA.LINEA_GENETICA')},
        {id: 'cuarentena', name: t('LINEA_GENETICA.TABLA.CUARENTENA'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoCuarentena', name: t('LINEA_GENETICA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoCuarentena', name: t('LINEA_GENETICA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoCuarentena', name: t('LINEA_GENETICA.TABLA.M_EDAD')}
        ]},
        {id: 'presentado', name: t('LINEA_GENETICA.TABLA.PRESENTES'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoPresentado', name: t('LINEA_GENETICA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoPresentado', name: t('LINEA_GENETICA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoPresentado', name: t('LINEA_GENETICA.TABLA.M_EDAD')}
        ]},
        {id: 'activos', name: t('LINEA_GENETICA.TABLA.ACTIVOS'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoActivo', name: t('LINEA_GENETICA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoActivo', name: t('LINEA_GENETICA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoActivo', name: t('LINEA_GENETICA.TABLA.M_EDAD')}
        ]},
        {id: 'baja', name: t('LINEA_GENETICA.TABLA.BAJA'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoBaja', name: t('LINEA_GENETICA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoBaja', name: t('LINEA_GENETICA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoBaja', name: t('LINEA_GENETICA.TABLA.M_EDAD')}
        ]},
        {id: 'eliminado', name: t('LINEA_GENETICA.TABLA.ELIMINADOS'), borderDobleHeadeClass: true, children: [
          {id: 'VerracoEliminado', name: t('LINEA_GENETICA.TABLA.N'), borderDobleHeadeClass: true},
          {id: 'PorcentajeVerracoEliminado', name: t('LINEA_GENETICA.TABLA.PORCENT'), type: 'numero'},
          {id: 'MediaEdadVerracoEliminado', name: t('LINEA_GENETICA.TABLA.M_EDAD')}
        ]}
      ],
      rows: this.state.listLinea,
      filtros: <FiltrosLineasGeneticaPage />,
      onEdit: (row) => recuperarDatosModal(row.idLinea),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1,
      duplicar: (row) =>duplicarDatosModal(row.idLinea),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1,
      cambiarEstado: (row) => cambiarEstado(row.idLinea, row.cambiarEstado),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1,
      onDelete: (row) => onDelete(row.idLinea),
      dataDelete: dataDelete,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltros
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalLineaGeneticaPage
          duplicar={(row) =>duplicarDatosModal(row.idLinea)}
        />
        <ListadoPage
          t_key="LINEA_GENETICA."
          table={table}
        />
        <Row className="tablaTotales">
          <Col sm={2} className="total-verracos">
            <span>{t('LINEA_GENETICA.TOTALES.TOTAL_VERRACOS')}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.CUARENTENA')}: {this.state.totalesLinea.totalVerracosCuarentena}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.ACTIVOS')}: {this.state.totalesLinea.totalVerracosActivos}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.PRESENTES')}: {this.state.totalesLinea.totalVerracosPresentes}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.BAJA')}: {this.state.totalesLinea.totalVerracosBaja}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('LINEA_GENETICA.TOTALES.ELIMINADOS')}: {this.state.totalesLinea.totalVerracosEliminados}</span>
          </Col>
        </Row>

        <Row className="tablaTotales">
          <Col sm={2} className="total-verracos">
            <span>{t('LINEA_GENETICA.TOTALES.TOTALES_PORCENTAJE')}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.CUARENTENA')}: {this.state.porcentajesLinea !== null && number_formatter(round_decimal(this.state.porcentajesLinea.VerracosCuarentena, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.ACTIVOS')}: {this.state.porcentajesLinea !== null && number_formatter(round_decimal(this.state.porcentajesLinea.VerracosActivos, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.PRESENTES')}: {this.state.porcentajesLinea !== null && number_formatter(round_decimal(this.state.porcentajesLinea.VerracosPresentes, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('LINEA_GENETICA.TOTALES.BAJA')}: {this.state.porcentajesLinea !== null && number_formatter(round_decimal(this.state.porcentajesLinea.VerracosBaja, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('LINEA_GENETICA.TOTALES.ELIMINADOS')}: {this.state.porcentajesLinea !== null && number_formatter(round_decimal(this.state.porcentajesLinea.VerracosEliminados, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (LineaGenetica)

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InformesPersonalizado from '../../components/informes/InformesPersonalizado'
import {seleccionarTodosInformePersonalizado, deseleccionarTodosInformePersonalizado, mostrarSeleccionInformePersonalizado, abrirModalGuardarSeleccion, comboSeleccionesInformePersonalizado, cargarSeleccionInformePersonalizado, eliminarSeleccionInformePersonalizado, crearCsvInformePersonalizado, imprimirPdfInformePersonalizado, cargarSeleccionInformePersonalizadoSuccess, mostrarSeleccionInformePersonalizadoSuccess} from '../../actions/informes/informes'
import {obtenerDatosPanelControl} from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    panelControl: state.panelControl
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      seleccionarTodosInformePersonalizado,
      deseleccionarTodosInformePersonalizado,
      mostrarSeleccionInformePersonalizado,
      abrirModalGuardarSeleccion,
      comboSeleccionesInformePersonalizado,
      cargarSeleccionInformePersonalizado,
      eliminarSeleccionInformePersonalizado,
      crearCsvInformePersonalizado,
      imprimirPdfInformePersonalizado,
      cargarSeleccionInformePersonalizadoSuccess,
      mostrarSeleccionInformePersonalizadoSuccess,
      obtenerDatosPanelControl
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InformesPersonalizado))
import actionTypes from '../../constants/actions/fichaVerraco/fichaVerraco'
import comunActionTypes from '../../constants/actions/comun/comun'
import editarActionTypes from '../../constants/actions/fichaVerraco/editarVerraco/editarVerraco'

export function initialState () {
  return {
    list: [],
    checkedFiltrosFichaVerraco: false,
    filtros:{},
    codVerraco: '',
    directorioTemporal: '',
    idUsuario: '',
    nombreImagen: '',
    directorioTemporalArchivos: {},
    listEvolucion: [],
    showModalEvolucion: false,
    evolucionesAnadidas: [],
    dataEvolucion: '',
    dataEvolucionDuplicar: '',
    checkedFiltrosEvolucion: false,
    listAlimentacion: [],
    alimentacionAnadidas: [],
    dataAlimentacion: [],
    dataAlimentacionDuplicar: [],
    checkedFiltrosAlimentacion: false,
    showModal: false,
    listIndex: [],
    indexAnadidos: [],
    dataIndex: '',
    dataIndexDuplicar: '',
    checkedFiltrosIndex: false,
    showModalIndex: false,
    listVerraco: [],
    editarIndex: [],
    editarEvolucion: [],
    showModalUbicacion: false,
    showModalCambioEstadoVerraco: false,
    listHistoricoEstadosVerraco: [],
    listHistoricoGrupoVerraco: [],
    totalesHistoricoExtracciones: [],
    listHistoricoExtracciones: [],
    listHistoricoIntervenciones: [],
    showModalVerracoCambioEstado: false,
    datosCambioEstado: [],
    ubicacion: [],
    totalesPresenciaVerracos: [],
    archivoDescarga: [],
    imagen: [],
    archivo: [],
    datosExcelFichaVerraco: [],
    datosPdfFichaVerraco: [],
    datosImprimirDetalleVerraco: [],
    datosHistoricoGrupoVerraco: [],
    showrModalHistoricoGrupos: false,
    datosHistoricoExtracciones: [],
    showrModalHistoricoExtracciones: false,
    datosHistoricoEstado: [],
    showrModalHistoricoEstado: false,
    datosHistoricoIntervenciones: [],
    showrModalHistoricoIntervenciones: false,
  }
}


export function fetchFichaVerracoSuccess (state, {list}) {
  return {
    ...state,
    loading:false,
    list,
    directorioTemporal: '',
    idUsuario: '',
    nombreImagen: '',
    directorioTemporalArchivos: {}
  }
}

export function asignarCodRazaSuccess (state, {data}) {
  return {
    ...state,
    codVerraco: data
  }
}

export function recuperarDatosModalFichaVerracoSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalFichaVerracoSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function cambiarEstadoFichaVerracoSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteFichaVerracoSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosFichaVerraco (state) {
  return {
    ...state,
    checkedFiltrosFichaVerraco: true
  }
}

export function deSeleccionarTodosFichaVerraco (state) {
  return {
    ...state,
    checkedFiltrosFichaVerraco: false
  }
}

export function fetchFiltrarFichaVerracoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function uploadFile (state, {imagen}) {
  return {
    ...state,
    imagen: imagen
  }
}

export function subirArchivos (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function eliminaImagen (state, {imagen}) {
  return {
    ...state,
    imagen: imagen
  }
}

export function quitarDirectorioTemporal (state) {
  return {
    ...state,
    directorioTemporal: '',
    nombreImagen: ''
  }
}

export function eliminarArchvio (state, {archivo}) {
  const archivos = state.archivo
  const datos = archivos.filter(function(data) {
    if (data.archivo !== archivo) {
        return data
    }
  })
  return {
    ...state,
    archivo: datos
  }
}

export function obtenerArchivoSuccess (state, {archivoDescarga}) {
  return {
    ...state,
    archivoDescarga
  }
}

export function getEvolucionSuccess (state, {listEvolucion}) {
  return {
    ...state,
    listEvolucion
  }
}

export function abrirModalEvolucion (state) {
  return {
    ...state,
    showModalEvolucion: true
  }
}

export function closeModalEvolucion (state) {
  return {
    ...state,
    showModalEvolucion: false,
    dataEvolucion: false,
    dataEvolucionDuplicar: false
  }
}

export function submitNuevaEvolucion (state, {evolucionesAnadidas}) {
  return {
    ...state,
    evolucionesAnadidas: state.evolucionesAnadidas.concat(evolucionesAnadidas),
    showModalEvolucion: false
  }
}

export function guardarYnuevoEvolucion (state, {evolucionesAnadidas}) {
  return {
    ...state,
    evolucionesAnadidas: state.evolucionesAnadidas.concat(evolucionesAnadidas)
  }
}

export function cargarEvolucion (state, {evolucionesAnadidas}) {
  return {
    ...state,
    evolucionesAnadidas: evolucionesAnadidas
  }
}

export function cargarAlimentacion (state, {alimentacionAnadidas}) {
  return {
    ...state,
    alimentacionAnadidas: alimentacionAnadidas
  }
}

export function recuperarDatosModalEvolucion (state) {
  return {
    ...state,
    dataEvolucion: true,
    dataEvolucionDuplicar: false
  }
}

export function editarEvolucionSuccess (state, {evolucionesAnadidas}) {
  return {
    ...state,
    evolucionesAnadidas: evolucionesAnadidas,
    showModalEvolucion: false
  }
}

export function deleteEvolucionSuccess (state, {evolucionesAnadidas}) {
  return {
    ...state,
    evolucionesAnadidas: evolucionesAnadidas,
    checkedFiltrosEvolucion: false
  }
}

export function duplicarDatosModalEvolucionSuccess (state) {
  return {
    ...state,
    dataEvolucionDuplicar: true,
    dataEvolucion: false
  }
}

export function abrirModalVacioEvolucion(state){
  return{
    ...state,
    dataEvolucion: '',
    dataEvolucionDuplicar: ''
  }
}

export function seleccionarTodosEvolucion (state) {
  return {
    ...state,
    checkedFiltrosEvolucion: true,
    checkedFiltrosAlimentacion: false,
    checkedFiltrosIndex: false
  }
}

export function deSeleccionarTodosEvolucion (state) {
  return {
    ...state,
    checkedFiltrosEvolucion: false
  }
}

export function getAlimentacionSuccess (state, {listAlimentacion}) {
  return {
    ...state,
    listAlimentacion
  }
}

export function submitNuevaAlimentacionSuccess (state, {alimentacionAnadidas}) {
  return {
    ...state,
    alimentacionAnadidas: state.alimentacionAnadidas.concat([alimentacionAnadidas])
  }
}

export function recuperarDatosModalAlimentacionSuccess (state, {dataAlimentacion}) {
  return {
    ...state,
    dataAlimentacion: dataAlimentacion,
    dataAlimentacionDuplicar: []
  }
}


export function editarAlimentacionSuccess (state, {alimentacionAnadidas}) {
  return {
    ...state,
    alimentacionAnadidas: alimentacionAnadidas
  }
}

export function deleteAlimentacionSuccess (state, {alimentacionAnadidas}) {
  return {
    ...state,
    alimentacionAnadidas: alimentacionAnadidas,
    checkedFiltrosAlimentacion: false
  }
}

export function duplicarDatosModalAlimentacionSuccess (state, {dataAlimentacionDuplicar}) {
  return {
    ...state,
    dataAlimentacionDuplicar: dataAlimentacionDuplicar,
    dataAlimentacion: []
  }
}

export function abrirModalVacioAlimentacion(state){
  return{
    ...state,
    dataAlimentacion: [],
    dataAlimentacionDuplicar: []
  }
}

export function seleccionarTodosAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosAlimentacion: true,
    checkedFiltrosEvolucion: false,
    checkedFiltrosIndex: false
  }
}

export function deSeleccionarTodosAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosAlimentacion: false
  }
}

export function openModalAlimentacion (state) {
  return {
    ...state,
    showModal: true
  }
}

export function closeModalAlimentacion (state) {
  return {
    ...state,
    showModal: false,
    dataAlimentacion: [],
    dataAlimentacionDuplicar: []
  }
}

export function getIndexSuccess (state, {listIndex}) {
  return {
    ...state,
    listIndex
  }
}

export function submitNuevaIndex (state, {indexAnadidos}) {  
  if(indexAnadidos.idNivelGenetico) indexAnadidos.nivelGenetico = indexAnadidos.idNivelGenetico.label
  return {
    ...state,
    indexAnadidos: state.indexAnadidos.concat(indexAnadidos),
    showModalIndex: false,
  }
}

export function cargarIndex (state, {indexAnadidos}) {
  return {
    ...state,
    indexAnadidos: indexAnadidos
  }
}

export function recuperarDatosModalIndex (state) {
  return {
    ...state,
    dataIndex: true,
    dataIndexDuplicar: ''
  }
}


export function editarIndexSuccess (state, {indexAnadidos}) {
  return {
    ...state,
    indexAnadidos: indexAnadidos,
    showModalIndex: false
  }
}

export function deleteIndexSuccess (state, {indexAnadidos}) {
  return {
    ...state,
    indexAnadidos: indexAnadidos,
    checkedFiltrosIndex: false
  }
}

export function duplicarDatosModalIndexSuccess (state) {
  return {
    ...state,
    dataIndexDuplicar: true,
    dataIndex: ''
  }
}

export function abrirModalVacioIndex(state){
  return{
    ...state,
    dataIndex: '',
    dataIndexDuplicar: ''
  }
}

export function abrirModalIndex (state) {
  return {
    ...state,
    showModalIndex: true
  }
}

export function seleccionarTodosIndex (state) {
  return {
    ...state,
    checkedFiltrosIndex: true,
    checkedFiltrosEvolucion: false,
    checkedFiltrosAlimentacion: false
  }
}

export function deSeleccionarTodosIndex (state) {
  return {
    ...state,
    checkedFiltrosIndex: false
  }
}

export function openModalIndex (state) {
  return {
    ...state,
    showModalIndex: true
  }
}

export function closeModalIndex (state) {
  return {
    ...state,
    showModalIndex: false
  }
}

export function fetchEditarVerracoSuccess (state, {listVerraco}) {
  return {
    ...state,
    listVerraco,
    archivo: listVerraco.archivo
  }
}

export function submitEditarIndexSuccess (state, {indexAnadidos}) {
  const datosBack = state.listVerraco.index || []
  const datosFront = state.indexAnadidos || []
  const arrayEditarIndex = [...datosBack, ...datosFront]
  return {
    ...state,
    editarIndex: arrayEditarIndex
  }
}

export function submitEditarEvolucionSuccess (state, {evolucionesAnadidas}) {
  const datosBack = state.listVerraco.evolucion || []
  const datosFront = state.evolucionesAnadidas || []
  const arrayEditarEvolucion = [...datosBack, ...datosFront]
  return {
    ...state,
    editarEvolucion: arrayEditarEvolucion
  }
}

export function abrirModalUbicacion(state){
  return{
    ...state,
    showModalUbicacion: true
  }
}

export function cerrarModalUbicacion(state){
  return{
    ...state,
    showModalUbicacion: false
  }
}

export function abrirModalCambioEstadoVerraco(state, {idVerraco}){
  return{
    ...state,
    showModalCambioEstadoVerraco: true,
    idVerraco
  }
}

export function cerrarModalCambioEstadoVerraco(state){
  return{
    ...state,
    showModalCambioEstadoVerraco: false
  }
}

export function fetchHistoricoEstadosVerracoSuccess(state, {listHistoricoEstadosVerraco}){
  return{
    ...state,
    listHistoricoEstadosVerraco
  }
}

export function fetchHistoricoGrupoVerracoSuccess(state, {listHistoricoGrupoVerraco}){
  return{
    ...state,
    listHistoricoGrupoVerraco
  }
}

export function abrirModalVerracoCambioEstado(state){
  return{
    ...state,
    showModalVerracoCambioEstado: true
  }
}

export function cerrarModalVerracoCambioEstado(state){
  return{
    ...state,
    showModalVerracoCambioEstado: false
  }
}

export function guardarDatosCambioEstado(state, {values}){
  return{
    ...state,
    datosCambioEstado: values,
    showModalVerracoCambioEstado: false
  }
}

export function guardarDatosUbicacion(state, {values}){
  return{
    ...state,
    ubicacion: values
  }
}

export function obtenerPresenciaVerracoSuccess(state, {totalesPresencia}){
  return{
    ...state,
    totalesPresenciaVerracos: totalesPresencia
  }
}

export function fetchDuplicarVerracoSuccess (state, {listVerraco}) {
  return {
    ...state,
    listVerraco
  }
}

export function crearCsvFichaVerracoSuccess (state, {datosExcelFichaVerraco}) {
  return {
    ...state,
    datosExcelFichaVerraco
  }
}

export function imprimirPdfFichaVerracoSuccess (state, {datosPdfFichaVerraco}) {
  return {
    ...state,
    datosPdfFichaVerraco
  }
}

export function fetchHistoricoExtraccionesSuccess(state, {listHistoricoExtracciones}){
  return{
    ...state,
    listHistoricoExtracciones: listHistoricoExtracciones.extracciones,
    totalesHistoricoExtracciones: listHistoricoExtracciones.totales
  }
}

export function fetchHistoricoIntervencionesSuccess(state, {listHistoricoIntervenciones}){
  return{
    ...state,
    listHistoricoIntervenciones
  }
}

export function guardarYnuevoVerracoSuccess(state){
  return{
    ...state,
    evolucionesAnadidas: [],
    indexAnadidos: [],
    ubicacion: [],
    datosCambioEstado: [],
    archivo: [],
    imagen: [],
    alimentacionAnadidas:[]
  }
}

export function imprimirDetalleVerracoSuccess (state, {datosImprimirDetalleVerraco}) {
  return {
    ...state,
    datosImprimirDetalleVerraco
  }
}

export function resetUbicacion (state) {
  return {
    ...state,
    ubicacion: []
  }
}

export function abrirModalHistoricoGruposSuccess (state, {datosHistoricoGrupoVerraco}) {
  return {
    ...state,
    datosHistoricoGrupoVerraco: datosHistoricoGrupoVerraco,
    showrModalHistoricoGrupos: true
  }
}

export function cerrarModalHistoricoGrupos (state) {
  return {
    ...state,
    datosHistoricoGrupoVerraco: [],
    showrModalHistoricoGrupos: false
  }
}

export function abrirModalHistoricoExtraccionesSuccess (state, {datosHistoricoExtracciones}) {
  return {
    ...state,
    datosHistoricoExtracciones: datosHistoricoExtracciones,
    showrModalHistoricoExtracciones: true
  }
}

export function cerrarModalHistoricoExtracciones (state) {
  return {
    ...state,
    datosHistoricoExtracciones: [],
    showrModalHistoricoExtracciones: false
  }
}

export function abrirModalHistoricoEstadoSuccess (state, {datosHistoricoEstado}) {
  return {
    ...state,
    datosHistoricoEstado: datosHistoricoEstado,
    showrModalHistoricoEstado: true
  }
}

export function cerrarModalHistoricoEstado (state) {
  return {
    ...state,
    datosHistoricoEstado: [],
    showrModalHistoricoEstado: false
  }
}

export function abrirModalHistoricoIntervencionesSuccess (state, {datosHistoricoIntervenciones}) {
  return {
    ...state,
    datosHistoricoIntervenciones: datosHistoricoIntervenciones,
    showrModalHistoricoIntervenciones: true
  }
}

export function cerrarModalHistoricoIntervenciones (state) {
  return {
    ...state,
    datosHistoricoIntervenciones: [],
    showrModalHistoricoIntervenciones: false
  }
}

export function restartVerracoDejandoFiltros (state) {
  return {
    list: [],
    checkedFiltrosFichaVerraco: false,
    filtros: state.filtros,
    codVerraco: '',
    directorioTemporal: '',
    idUsuario: '',
    nombreImagen: '',
    directorioTemporalArchivos: {},
    listEvolucion: [],
    showModalEvolucion: false,
    evolucionesAnadidas: [],
    dataEvolucion: '',
    dataEvolucionDuplicar: '',
    checkedFiltrosEvolucion: false,
    listAlimentacion: [],
    alimentacionAnadidas: [],
    dataAlimentacion: [],
    dataAlimentacionDuplicar: [],
    checkedFiltrosAlimentacion: false,
    showModal: false,
    listIndex: [],
    indexAnadidos: [],
    dataIndex: '',
    dataIndexDuplicar: '',
    checkedFiltrosIndex: false,
    showModalIndex: false,
    listVerraco: [],
    editarIndex: [],
    editarEvolucion: [],
    showModalUbicacion: false,
    showModalCambioEstadoVerraco: false,
    listHistoricoEstadosVerraco: [],
    listHistoricoGrupoVerraco: [],
    totalesHistoricoExtracciones: [],
    listHistoricoExtracciones: [],
    listHistoricoIntervenciones: [],
    showModalVerracoCambioEstado: false,
    datosCambioEstado: [],
    ubicacion: [],
    totalesPresenciaVerracos: [],
    archivoDescarga: [],
    imagen: [],
    archivo: [],
    datosExcelFichaVerraco: [],
    datosPdfFichaVerraco: [],
    datosImprimirDetalleVerraco: [],
    datosHistoricoGrupoVerraco: [],
    showrModalHistoricoGrupos: false,
    datosHistoricoExtracciones: [],
    showrModalHistoricoExtracciones: false,
    datosHistoricoEstado: [],
    showrModalHistoricoEstado: false,
    datosHistoricoIntervenciones: [],
    showrModalHistoricoIntervenciones: false,
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_FICHA_VERRACO_SUCCESS:
      return fetchFichaVerracoSuccess(state, action)
    case actionTypes.ASIGNAR_COD_RAZA_SUCCESS:
      return asignarCodRazaSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_FICHA_VERRACO_SUCCESS:
      return recuperarDatosModalFichaVerracoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_FICHA_VERRACO_SUCCESS:
      return duplicarDatosModalFichaVerracoSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_FICHA_VERRACO_SUCCESS:
      return cambiarEstadoFichaVerracoSuccess(state, action)
    case actionTypes.ON_DELETE_FICHA_VERRACO_SUCCESS:
      return onDeleteFichaVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_FICHA_VERRACO:
      return seleccionarTodosFichaVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_FICHA_VERRACO:
      return deSeleccionarTodosFichaVerraco(state, action)
    case actionTypes.FETCH_FILTRAR_FICHA_VERRACO_SUCCESS:
      return fetchFiltrarFichaVerracoSuccess(state, action)
    case comunActionTypes.UPLOAD_FILE:
      return uploadFile(state, action)
    case comunActionTypes.ELIMINAR_IMAGEN:
      return eliminaImagen(state, action)
    case comunActionTypes.SUBIR_ARCHIVOS:
      return subirArchivos(state, action)
    case comunActionTypes.QUITAR_DIRECTORIO_TEMPORAL:
      return quitarDirectorioTemporal(state, action)
    case comunActionTypes.ELIMINAR_ARCHIVO:
      return eliminarArchvio(state, action)
    case comunActionTypes.OBTENER_ARCHIVO_SUCCESS:
      return obtenerArchivoSuccess(state, action)
    case actionTypes.GET_EVOLUCION_SUCCESS:
      return getEvolucionSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_EVOLUCION:
      return submitNuevaEvolucion(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_EVOLUCION:
      return guardarYnuevoEvolucion(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_EVOLUCION:
      return recuperarDatosModalEvolucion(state, action)
    case actionTypes.EDITAR_EVOLUCION_SUCCESS:
      return editarEvolucionSuccess(state, action)
    case actionTypes.CARGAR_EVOLUCION:
      return cargarEvolucion(state, action)
    case actionTypes.CARGAR_INDEX:
      return cargarIndex(state, action)
    case actionTypes.CARGAR_ALIMENTACION:
      return cargarAlimentacion(state, action)
    case actionTypes.DELETE_EVOLUCION_SUCCESS:
      return deleteEvolucionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_EVOLUCION_SUCCESS:
      return duplicarDatosModalEvolucionSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_EVOLUCION:
      return abrirModalVacioEvolucion(state, action)
    case actionTypes.SELECCIONAR_TODOS_EVOLUCION:
      return seleccionarTodosEvolucion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_EVOLUCION:
      return deSeleccionarTodosEvolucion(state, action)
    case actionTypes.GET_ALIMENTACION_SUCCESS:
      return getAlimentacionSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_ALIMENTACION_SUCCESS:
      return submitNuevaAlimentacionSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_ALIMENTACION_SUCCESS:
      return recuperarDatosModalAlimentacionSuccess(state, action)
    case actionTypes.EDITAR_ALIMENTACION_SUCCESS:
      return editarAlimentacionSuccess(state, action)
    case actionTypes.DELETE_ALIMENTACION_SUCCESS:
      return deleteAlimentacionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_ALIMENTACION_SUCCESS:
      return duplicarDatosModalAlimentacionSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_ALIMENTACION:
      return abrirModalVacioAlimentacion(state, action)
    case actionTypes.SELECCIONAR_TODOS_ALIMENTACION:
      return seleccionarTodosAlimentacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ALIMENTACION:
      return deSeleccionarTodosAlimentacion(state, action)
    case actionTypes.OPEN_MODAL_ALIMENTACION:
      return openModalAlimentacion(state, action)
    case actionTypes.CLOSE_MODAL_ALIMENTACION:
      return closeModalAlimentacion(state, action)
    case actionTypes.GET_INDEX_SUCCESS:
      return getIndexSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_INDEX:
      return submitNuevaIndex(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_INDEX:
      return recuperarDatosModalIndex(state, action)
    case actionTypes.EDITAR_INDEX_SUCCESS:
      return editarIndexSuccess(state, action)
    case actionTypes.DELETE_INDEX_SUCCESS:
      return deleteIndexSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_INDEX_SUCCESS:
      return duplicarDatosModalIndexSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_INDEX:
      return abrirModalVacioIndex(state, action)
    case actionTypes.ABRIR_MODAL_INDEX:
      return abrirModalIndex(state, action)
    case actionTypes.SELECCIONAR_TODOS_INDEX:
      return seleccionarTodosIndex(state, action)
    case actionTypes.DESELECCIONAR_TODOS_INDEX:
      return deSeleccionarTodosIndex(state, action)
    case actionTypes.OPEN_MODAL_INDEX:
      return openModalIndex(state, action)
    case actionTypes.CLOSE_MODAL_INDEX:
      return closeModalIndex(state, action)
    case editarActionTypes.FETCH_EDITAR_VERRACO_SUCCESS:
      return fetchEditarVerracoSuccess(state, action)
    case editarActionTypes.SUBMIT_EDITAR_INDEX_SUCCESS:
      return submitEditarIndexSuccess(state, action)
    case editarActionTypes.SUBMIT_EDITAR_EVOLUCION_SUCCESS:
      return submitEditarEvolucionSuccess(state, action)
    case actionTypes.RESTART_VERRACO:
      return initialState()
    case actionTypes.ABRIR_MODAL_UBICACION:
      return abrirModalUbicacion(state, action)
    case actionTypes.CERRAR_MODAL_UBICACION:
      return cerrarModalUbicacion(state, action)
    case actionTypes.ABRIR_MODAL_CAMBIO_ESTADO_VERRACO:
      return abrirModalCambioEstadoVerraco(state, action)
    case actionTypes.CERRAR_MODAL_CAMBIO_ESTADO_VERRACO:
      return cerrarModalCambioEstadoVerraco(state, action)
    case editarActionTypes.FETCH_HISTORICO_ESTADOS_VERRACO_SUCCESS:
      return fetchHistoricoEstadosVerracoSuccess(state, action)
    case editarActionTypes.FETCH_HISTORICO_GRUPO_VERRACO_SUCCESS:
      return fetchHistoricoGrupoVerracoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VERRACO_CAMBIO_ESTADO:
      return abrirModalVerracoCambioEstado(state, action)
    case actionTypes.CERRAR_MODAL_VERRACO_CAMBIO_ESTADO:
      return cerrarModalVerracoCambioEstado(state, action)
    case actionTypes.GUARDAR_DATOS_CAMBIO_ESTADO:
      return guardarDatosCambioEstado(state, action)
    case actionTypes.OBTENER_PRESENCIA_VERRACO_SUCCESS:
      return obtenerPresenciaVerracoSuccess(state, action)
    case actionTypes.GUARDAR_DATOS_UBICACION:
      return guardarDatosUbicacion(state, action)
    case actionTypes.FETCH_DUPLICAR_VERRACO_SUCCESS:
      return fetchDuplicarVerracoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_EVOLUCION:
      return abrirModalEvolucion(state, action)
    case actionTypes.CLOSE_MODAL_EVOLUCION:
      return closeModalEvolucion(state, action)
    case actionTypes.CREATE_CSV_FICHA_VERRACO_SUCCESS:
      return crearCsvFichaVerracoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_FICHA_VERRACO_SUCCESS:
      return imprimirPdfFichaVerracoSuccess(state, action)
    case editarActionTypes.FETCH_HISTORICO_EXTRACCIONES_SUCCESS:
      return fetchHistoricoExtraccionesSuccess(state, action)
    case editarActionTypes.FETCH_HISTORICO_INTERVENCIONES_SUCCESS:
      return fetchHistoricoIntervencionesSuccess(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_VERRACO_SUCCESS:
      return guardarYnuevoVerracoSuccess(state, action)
    case actionTypes.IMPRIMIR_DETALLE_VERRACO_SUCCESS:
      return imprimirDetalleVerracoSuccess(state, action)
    case actionTypes.RESET_UBICACION:
      return resetUbicacion(state, action)
    case editarActionTypes.ABRIR_MODAL_HISTORICO_GRUPOS_SUCCESS:
      return abrirModalHistoricoGruposSuccess(state, action)
    case editarActionTypes.CERRAR_MODAL_HISTORICO_GRUPOS:
      return cerrarModalHistoricoGrupos(state, action)
    case editarActionTypes.ABRIR_MODAL_HISTORICO_EXTRACCIONES_SUCCESS:
      return abrirModalHistoricoExtraccionesSuccess(state, action)
    case editarActionTypes.CERRAR_MODAL_HISTORICO_EXTRACCIONES:
      return cerrarModalHistoricoExtracciones(state, action)
    case editarActionTypes.ABRIR_MODAL_HISTORICO_ESTADO_SUCCESS:
      return abrirModalHistoricoEstadoSuccess(state, action)
    case editarActionTypes.CERRAR_MODAL_HISTORICO_ESTADO:
      return cerrarModalHistoricoEstado(state, action)
    case editarActionTypes.ABRIR_MODAL_HISTORICO_INTERVENCIONES_SUCCESS:
      return abrirModalHistoricoIntervencionesSuccess(state, action)
    case editarActionTypes.CERRAR_MODAL_HISTORICO_INTERVENCIONES:
      return cerrarModalHistoricoIntervenciones(state, action)
    case actionTypes.RESTART_VERRACO_DEJANDO_FILTROS:
      return restartVerracoDejandoFiltros(state, action)
    default:
      return state
  }
}

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalBioseguridadHigiene.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import InputText from '../../comun/form/InputText'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import { DoneAll } from '@material-ui/icons'
import {dateTimeZones} from '../../../util/formatFunctions'

class ModalLimpiezaSilos extends Component {
  anadirSilo (values) {
    const itemsSeleccion = this.props.listLimpiezaSilos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion,
          'nombreInstalacion': row.nombreInstalacion,
          'codInstalacion': row.codInstalacion,
          'animales': row.animales,
          'activo': row.activo,
          'idInstalacionPadre': row.idInstalacionPadre,
          'idBioseguridadLimpiezaSilo': row.idBioseguridadLimpiezaSilo,
          'producto': values.producto ? values.producto : null,
          'fechaCaducidad': values.fechaCaducidad ? values.fechaCaducidad : null,
          'lote': values.lote ? values.lote : null,
          'cantidad': values.cantidad ? values.cantidad : null
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.anadirSilo(list)
    this.props.change('seleccionar-todos-silos', false)
    this.props.actions.deSeleccionarTodosModalLimpiezaSilos()
  }

  intercambiarCheckModalLimpiezaSilos (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalLimpiezaSilos()
    } else {
      this.props.actions.deSeleccionarTodosModalLimpiezaSilos()
    }
  }

  componentDidMount () {
    this.props.actions.fetchLimpiezaSilos(this.props.limpiezaSilosAnadidas)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalLimpiezaSilos !== prevProps.showModalLimpiezaSilos) {
      this.props.change('fechaCaducidad', dateTimeZones(this.props.auth.zonaHorariaString))
    }
  }

  cerrarModalLimpiezaSilos () {
    this.props.actions.cerrarModalLimpiezaSilos()
    this.props.change('seleccionar-todos-silos', false)
    this.props.actions.deSeleccionarTodosModalLimpiezaSilos()
  }

  render () {
    const {
      t, handleSubmit, listLimpiezaSilos, showModalLimpiezaSilos, checkedLimpiezaSilos, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalLimpiezaSilos }
    } = this.props

    listLimpiezaSilos.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const tableLimpiezaSilos = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.NOMBRE_UBICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.CODIGO_UBICACION')}
      ],
      rows: listLimpiezaSilos,
      initialValues: {}
    }

    tableLimpiezaSilos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableLimpiezaSilos.initialValues['check' + row.idElemento] = checkedLimpiezaSilos
        }
      }
    )

    return (
      <Modal show={showModalLimpiezaSilos} onHide={() => this.cerrarModalLimpiezaSilos()} aria-labelledby="contained-modal-title-lg" size="lg" className="submodal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.SILOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <form>
              <Field
                colSm={2}
                id="producto"
                name="producto"
                controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.PRODUCTO')}
                component={InputText}
                maxLength={true}
                valorMaxLength={120}
              />
              <Field
                id="fechaCaducidad"
                name="fechaCaducidad"
                component={InputDatePicker}
                colSm={2}
                controlLabel={t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.FECHA_CADUCIDAD`)}
              />
              <Field
                id="lote"
                name="lote"
                colSm={2}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.LOTE')}
                claseActivo="nombre-lote"
                maxLength={true}
                valorMaxLength={120}
              />
              <Field
                id="cantidad"
                name="cantidad"
                colSm={2}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.CANTIDAD')}
                claseActivo="nombre-cantidad"
                maxLength={true}
                valorMaxLength={100}
              />
            </form>
            <div className="tabla-submodal">
              <div className="filtros-tabla-modal-submodal">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-silos"
                    name="seleccionar-todos-silos"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalLimpiezaSilos(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableLimpiezaSilos}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirSilo.bind(this))}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalLimpiezaSilos()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalLimpiezaSilos',
})(ModalLimpiezaSilos))
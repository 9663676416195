import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesPoolPage from '../../containers/informes/FiltrosInformesPoolPage'
import {Bar} from 'react-chartjs-2';
import {date_formatter, number_formatter, round_decimal} from '../../util/formatFunctions'
import {cantidadCeroVacio} from '../comun/table/Stilyzers'

class InformesPool extends Component {
  constructor () {
    super()
    this.state = {
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.POOL') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformePool({})
    this.props.actions.obtenerDatosPanelControl(null)
  }

  componentDidUpdate (prevProps) {
    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.POOL.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.POOL.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.POOL.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.POOL.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.POOL.TABLA.MT')})
        }
      }
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformePool, totalesPool, graficaPool,
      auth: {separadorDec, separadorMil}
    } = this.props

    const tableInformePool = {
      id: 'productoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? ([
        {id: 'nombre', name: t('INFORMES.POOL.TABLA.POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.POOL.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumen', name: t('INFORMES.POOL.TABLA.VOL'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'concentracionTotal', name: t('INFORMES.POOL.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.POOL.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionXDosisAplicada', name: t('INFORMES.POOL.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionXDosisUtil', name: t('INFORMES.POOL.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.POOL.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'nombreDosis', name: t('INFORMES.POOL.TABLA.TIPO_DOSIS')},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'numseguimientos', name: t('INFORMES.POOL.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        // {id: 'porFaTotal', name: t('INFORMES.POOL.TABLA.FA'), type: 'numero'},
        // {id: 'porFaCabezas', name: t('INFORMES.POOL.TABLA.CA'), type: 'numero'},
        // {id: 'porFaColas', name: t('INFORMES.POOL.TABLA.CO'), type: 'numero'},
        // {id: 'porFaGotaProximal', name: t('INFORMES.POOL.TABLA.GP'), type: 'numero'},
        // {id: 'porFaGotaDistal', name: t('INFORMES.POOL.TABLA.GD'), type: 'numero'},
        {id: 'indexAvgPool', name: t('POOL_EXTRACCIONES.COLUMNS.INDEX_MEDIO'), type: 'numero', numDecimales: 2},
        {id: 'poolEliminado', name: t('INFORMES.POOL.TABLA.POOL_ELIMINADO')},
        {id: 'observaciones', name: t('INFORMES.POOL.TABLA.OBSERVACIONES')}
      ]) : this.state.metodoEvaluacion === 3 ? ([
        {id: 'nombre', name: t('INFORMES.POOL.TABLA.POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.POOL.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumen', name: t('INFORMES.POOL.TABLA.VOL'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'concentracionTotal', name: t('INFORMES.POOL.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.POOL.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionXDosisAplicada', name: t('INFORMES.POOL.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionXDosisUtil', name: t('INFORMES.POOL.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.POOL.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'nombreDosis', name: t('INFORMES.POOL.TABLA.TIPO_DOSIS')},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.POOL.TABLA.MP'), type: 'numero'},
        {id: 'numseguimientos', name: t('INFORMES.POOL.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        // {id: 'porFaTotal', name: t('INFORMES.POOL.TABLA.FA'), type: 'numero'},
        {id: 'indexAvgPool', name: t('POOL_EXTRACCIONES.COLUMNS.INDEX_MEDIO'), type: 'numero', numDecimales: 2},
        {id: 'poolEliminado', name: t('INFORMES.POOL.TABLA.POOL_ELIMINADO')},
        {id: 'observaciones', name: t('INFORMES.POOL.TABLA.OBSERVACIONES')}
      ]) : this.state.metodoEvaluacion === 4 ? ([
        {id: 'nombre', name: t('INFORMES.POOL.TABLA.POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.POOL.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumen', name: t('INFORMES.POOL.TABLA.VOL'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'concentracionTotal', name: t('INFORMES.POOL.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.POOL.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionXDosisAplicada', name: t('INFORMES.POOL.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionXDosisUtil', name: t('INFORMES.POOL.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.POOL.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'nombreDosis', name: t('INFORMES.POOL.TABLA.TIPO_DOSIS')},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'numseguimientos', name: t('INFORMES.POOL.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        // {id: 'porFaTotal', name: t('INFORMES.POOL.TABLA.FA'), type: 'numero'},
        {id: 'indexAvgPool', name: t('POOL_EXTRACCIONES.COLUMNS.INDEX_MEDIO'), type: 'numero', numDecimales: 2},
        {id: 'poolEliminado', name: t('INFORMES.POOL.TABLA.POOL_ELIMINADO')},
        {id: 'observaciones', name: t('INFORMES.POOL.TABLA.OBSERVACIONES')}
      ]) : ([
        {id: 'nombre', name: t('INFORMES.POOL.TABLA.POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.POOL.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumen', name: t('INFORMES.POOL.TABLA.VOL'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.TABLE.COLUMNS.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'concentracionTotal', name: t('INFORMES.POOL.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.POOL.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionXDosisAplicada', name: t('INFORMES.POOL.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionXDosisUtil', name: t('INFORMES.POOL.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.POOL.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'nombreDosis', name: t('INFORMES.POOL.TABLA.TIPO_DOSIS')},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.POOL.TABLA.MP'), type: 'numero'},
        {id: 'numseguimientos', name: t('INFORMES.POOL.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        // {id: 'porFaTotal', name: t('INFORMES.POOL.TABLA.FA'), type: 'numero'},
        // {id: 'porFaColas', name: t('INFORMES.POOL.TABLA.CO'), type: 'numero'},
        // {id: 'porFaGotaProximal', name: t('INFORMES.POOL.TABLA.GP'), type: 'numero'},
        // {id: 'porFaGotaDistal', name: t('INFORMES.POOL.TABLA.GD'), type: 'numero'},
        {id: 'indexAvgPool', name: t('POOL_EXTRACCIONES.COLUMNS.INDEX_MEDIO'), type: 'numero', numDecimales: 2},
        {id: 'poolEliminado', name: t('INFORMES.POOL.TABLA.POOL_ELIMINADO')},
        {id: 'observaciones', name: t('INFORMES.POOL.TABLA.OBSERVACIONES')}
      ]),
      rows: listInformePool,
      filtros: <FiltrosInformesPoolPage />,
      initialValues: {}
    }

    const colors = ['#395196', '#5498af', '#4ca1a0', '#56ccde', '#4db19b', '#5be6c7'];
    const options = {
      layout: {
        padding: {
          bottom: 0,
          top: 0
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontSize: 9
          }
            }],
      },
      responsive: true,
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: '#91929b',
            padding: 20
          }
        },
        plugins: {
          labels: false
        }
      };
    let datasets = []
    let fechas = []
    if (Object.keys(graficaPool).length > 0){
      for (let i = 0; i < Object.keys(graficaPool.datosGrafica).length; i++){
        datasets = datasets.concat(
        {
          label: graficaPool.datosGrafica[i].tipoDosis,
          data: graficaPool.datosGrafica[i].sumaDosis,
          stack: i,
          backgroundColor: colors[i],
          borderWidth: 0
        })
      }

      fechas = graficaPool.labels.map((f) => {
        return date_formatter(f, this.props.auth.formaFechaHora, false)
      })
    }

    let chartData = {
      labels: fechas,
      datasets: datasets
    };

    return (
      <div>
        <h2 className="page-title">{t('INFORMES.POOL.TITLE')}</h2>
        <SimpleTablePage {...tableInformePool}>
        </SimpleTablePage>
        {/*
          <Bar
            data={chartData}
            options={options}
            width={700}
            height={200}
          />
        */}

        <div className="totales-informes">
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.POOL.TOTALES.TOTALES_POOL')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.POOL.TOTALES.MEDIA_DOSIS_REALES')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.POOL.TOTALES.MEDIA_CONCENTRACION_TOTAL')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.POOL.TOTALES.MEDIA_CONCENTRACION_UTIL')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.POOL.TOTALES.MEDIA_DOSIS_APLICADA')}</span></Col>
          <Col sm={2} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.POOL.TOTALES.MEDIA_DOSIS_UTIL')}</span></Col>

          <Col sm={2} className="valores-totales-informes"><span>{totalesPool.totalPool && number_formatter(totalesPool.totalPool, 0, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesPool.mediaDosisReales && number_formatter(round_decimal(totalesPool.mediaDosisReales, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesPool.mediaConcentracionTotal && number_formatter(round_decimal(totalesPool.mediaConcentracionTotal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesPool.mediaConcentracionUtil && number_formatter(round_decimal(totalesPool.mediaConcentracionUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesPool.mediaDosisAplicada && number_formatter(round_decimal(totalesPool.mediaDosisAplicada, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes" style={{border: 'none'}}><span>{totalesPool.mediaDosisUtil && number_formatter(round_decimal(totalesPool.mediaDosisUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
        </div>

        <div className="totales-informes" style={{border: 'none'}}>
          <Row style={{marginRight: '0', marginLeft: '0'}}>
            <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_FA')}</span></Col>
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_CA')}</span></Col>
              ) : null
            }
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 || this.state.metodoEvaluacion === 5 ? ([
                <Col key="co1" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_CO')}</span></Col>,
                <Col key="gp1" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_GP')}</span></Col>,
                <Col key="gp3" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_GD')}</span></Col>
              ]) : null
            }
            <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.POOL.TOTALES.MEDIA_VOLUMEN')}</span></Col>
          </Row>
          <Row style={{marginRight: '0', marginLeft: '0'}}>
            <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaFA && number_formatter(round_decimal(totalesPool.mediaFA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaCA && number_formatter(round_decimal(totalesPool.mediaCA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
              ) : null
            }
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 || this.state.metodoEvaluacion === 5 ? ([
                <Col key="co2" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaCO && number_formatter(round_decimal(totalesPool.mediaCO, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>,
                <Col key="gp2" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaGP && number_formatter(round_decimal(totalesPool.mediaGP, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>,
                <Col key="gp4" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaGD && number_formatter(round_decimal(totalesPool.mediaGD, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
              ]) : null
            }
            <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesPool.mediaVolumen && number_formatter(round_decimal(totalesPool.mediaVolumen, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default InformesPool
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Print, Launch, AddCircleOutline } from '@material-ui/icons'
import './Planing.scss'
import ModalVerracoPlaningPage from '../../containers/planing/ModalVerracoPlaningPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerracoInicioPlaning as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import {dateTimeZones} from '../../util/formatFunctions'

class ModalInicioPlaning extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerVerracosSinExtraer()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosExcelModalInicioPlaning !== prevProps.datosExcelModalInicioPlaning) {
      if (this.props.datosExcelModalInicioPlaning.excel && this.props.datosExcelModalInicioPlaning.excel !== null){
        let pdf = this.props.datosExcelModalInicioPlaning.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.MODAL_INICIO.PDF') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }

    if (this.props.datosModalInicioPlaning !== prevProps.datosModalInicioPlaning) {
      if (this.props.datosModalInicioPlaning.pdf && this.props.datosModalInicioPlaning.pdf !== null){
        let pdf = this.props.datosModalInicioPlaning.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.MODAL_INICIO.PDF') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  closeModalInicioPlaning () {
    this.props.actions.closeModalInicioPlaning()
  }

  imprimirModalInicioPlaning () {
    this.props.actions.imprimirModalInicioPlaning()
  }

  exportarModalInicioPlaning () {
    this.props.actions.exportarModalInicioPlaning()
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosPlanning()
    } else {
      this.props.actions.deSeleccionarTodosPlanning()
    }
  }

  anadirSeleccionPlaning () {
    const itemsSeleccion = this.props.listVerracosSinExtraer.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'fecha': dateTimeZones(this.props.auth.zonaHorariaString)
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (list.length > 0) {
      this.props.actions.anadirSeleccionPlaning(list)
      this.props.change('seleccionar-todo', false)
    }
  }

  render () {
    const {
      t, handleSubmit, showModalInicioPlaning, checkedPlanning, listVerracosSinExtraer, checkedVerracosAnadidos, numeroRegistrosPorPagina, paginaActual,
      actions:{onDeleteVerracoPlaning}
    } = this.props

    // TABLA VERRACO
    listVerracosSinExtraer.forEach(
      (row) => row.idElemento = row.idVerraco + 'extraer'
    )
    const tableInicioPlaning = {
      id: 'tableVerraco',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      hasPagination: true,
      noBlockOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'estadoVerraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'codVerraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'espermatozoidesUtiles', name: t('PLANING.MODAL.TABLE.ESPERMA_UTIL'), type: 'numero'},
        {id: 'mediaDosis', name: t('PLANING.MODAL.TABLE.X_DOSIS'), type: 'numero'},
        {id: 'totalFormasAnormales', name: t('PLANING.MODAL.TABLE.FORMAS_ANORMALES'), type: 'numero'},
        {id: 'diasSinExtraer', name: t('PLANING.MODAL.TABLE.DIAS_SIN_EXTRAER'), type: 'numero', numDecimales: 0}
      ],
      rows: listVerracosSinExtraer,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracoPlaning(row.idElemento),
      initialValues: {}
    }
    tableInicioPlaning.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableInicioPlaning.initialValues['check' + row.idElemento] = checkedPlanning
        }
      }
    )

  return (
      <Modal show={showModalInicioPlaning} onHide={() => this.closeModalInicioPlaning()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {t('PLANING.MODAL_INICIO.TITLE')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-verracos">
              <div className={this.state.imputSelected === true ? 'filtros-tabla-modal-verracos' : 'filtros-tabla-modal-verracos oculta-action'}>
                {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PLANNING] > 1) && [
                  <div className="custom-check-w" key="checkbox_key">
                    <Field
                      id="seleccionar-todo"
                      name="seleccionar-todo"
                      colSm={12}
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheck(value)}
                    />
                  </div>,
                  <button key="boton1" type="button" className="anadir-verracos-sin-extraer btn btn-primary" onClick={(e) => this.anadirSeleccionPlaning()}>
                    <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.ANADIR')}
                  </button>,
                  <span key="barra3" style={{borderLeft: '1px solid #d1d1d1'}}></span>,
                  <Button key="boton2" type="button" style={{margin: '3px', marginLeft: '15px'}} className="btn btn-primary print" onClick={() => this.exportarModalInicioPlaning()}><Launch />{t('PLANING.MODAL.EXPORTAR')}</Button>,
                  <Button key="boton3"  type="button" style={{margin: '3px'}} className="btn btn-primary print" onClick={() => this.imprimirModalInicioPlaning()}><Print />{t('PLANING.MODAL.IMPRIMIR')}</Button>
                ]}
              </div>
              <SimpleTablePage {...tableInicioPlaning}></SimpleTablePage>
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalInicioPlaning()}>{t('PLANING.MODAL_INICIO.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalInicioPlaning',
})(ModalInicioPlaning))

import actionTypes from '../../constants/actions/escandallo/escandallo'

export function initialState () {
  return {
    listEscandallo: [],
    checkedFiltrosEscandallo: false,
    showModalEscandallo: false,
    filtros: {},
    dataEscandallo: [],
    dataDuplicarEscandallo: [],
    datosExcelEscandallo: [],
    datosPdfEscandallo: [],
    listEscandalloAnalisis: [],
    listEscandalloDilucion: [],
    listEscandalloEnvasado: [],
    checkedFiltrosEscandalloAnalisis: false,
    checkedFiltrosEscandalloDilucion: false,
    checkedFiltrosEscandalloEnvasado: false,
    escandalloAnalisis: [],
    escandalloDilucion: [],
    escandalloEnvasado: [],
    showModalAnadirEscandallo: false,
    selectorProceso: '',
    data: [],
    dataDuplicar: []
  }
}

export function abrirModalEscandallo (state) {
  return {
    ...state,
    showModalEscandallo: true,
    data: [],
    dataDuplicar: []
  }
}

export function closeModalEscandallo (state) {
  return {
    ...state,
    showModalEscandallo: false,
    dataEscandallo: [],
    dataDuplicarEscandallo: [],
    escandalloAnalisis: [],
    escandalloDilucion: [],
    escandalloEnvasado: [],
    data: [],
    dataDuplicar: []
  }
}

export function filtrarEscandalloSuccess (state, {listEscandallo}) {
  return {
    ...state,
    listEscandallo: listEscandallo
  }
}

export function filtrosEscandalloSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function recuperarDatosModalEscandallo (state, {data}) {
  return {
    ...state,
    data: data,
    showModalEscandallo: true,
    dataDuplicar: [],
    escandalloAnalisis: data.analisis,
    escandalloDilucion: data.dilucion,
    escandalloEnvasado: data.envasado
  }
}

export function duplicarDatosModalEscandalloSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    showModalEscandallo: true,
    data: [],
    escandalloAnalisis: dataDuplicar.analisis,
    escandalloDilucion: dataDuplicar.dilucion,
    escandalloEnvasado: dataDuplicar.envasado
  }
}

export function seleccionarTodosEscandallo (state) {
  return {
    ...state,
    checkedFiltrosEscandallo: true
  }
}

export function deSeleccionarTodosEscandallo (state) {
  return {
    ...state,
    checkedFiltrosEscandallo: false
  }
}

export function crearCsvEscandalloSuccess (state, {datosExcelEscandallo}) {
  return {
    ...state,
    datosExcelEscandallo
  }
}

export function imprimirPdfEscandalloSuccess (state, {datosPdfEscandallo}) {
  return {
    ...state,
    datosPdfEscandallo
  }
}

export function andadirEscandalloAnalisis (state, {escandalloAnalisis}) {
  let esAnalisis = {
    cantidadEscandallo:escandalloAnalisis.cantidadEscandallo,
    esGuardarYnuevo:escandalloAnalisis.esGuardarYnuevo,
    idElemento:escandalloAnalisis.idElemento,
    idEscandalloProducto:escandalloAnalisis.idEscandalloProducto,
    idProducto:escandalloAnalisis.idProducto,
    nombreProducto:escandalloAnalisis.nombreProducto,
    proceso:escandalloAnalisis.proceso,
    producto:escandalloAnalisis.producto,
    unidadMedida:escandalloAnalisis.unidadMedida
  }
  return {
    ...state,
    escandalloAnalisis: state.escandalloAnalisis.concat(esAnalisis),
    showModalAnadirEscandallo: escandalloAnalisis.esGuardarYnuevo === true ? true : false
  }
}

export function andadirEscandalloDilucion (state, {escandalloDilucion}) {
  let esDilucion = {
    cantidadEscandallo:escandalloDilucion.cantidadEscandallo,
    esGuardarYnuevo:escandalloDilucion.esGuardarYnuevo,
    idElemento:escandalloDilucion.idElemento,
    idEscandalloProducto:escandalloDilucion.idEscandalloProducto,
    idProducto:escandalloDilucion.idProducto,
    nombreProducto:escandalloDilucion.nombreProducto,
    proceso:escandalloDilucion.proceso,
    producto:escandalloDilucion.producto,
    unidadMedida:escandalloDilucion.unidadMedida
  }
  return {
    ...state,
    escandalloDilucion: state.escandalloDilucion.concat(esDilucion),
    showModalAnadirEscandallo: escandalloDilucion.esGuardarYnuevo === true ? true : false
  }
}

export function andadirEscandalloEnvasado (state, {escandalloEnvasado}) {
  let esEnvasado = {
    cantidadEscandallo:escandalloEnvasado.cantidadEscandallo,
    esGuardarYnuevo:escandalloEnvasado.esGuardarYnuevo,
    idElemento:escandalloEnvasado.idElemento,
    idEscandalloProducto:escandalloEnvasado.idEscandalloProducto,
    idProducto:escandalloEnvasado.idProducto,
    nombreProducto:escandalloEnvasado.nombreProducto,
    proceso:escandalloEnvasado.proceso,
    producto:escandalloEnvasado.producto,
    unidadMedida:escandalloEnvasado.unidadMedida
  }
  return {
    ...state,
    escandalloEnvasado: state.escandalloEnvasado.concat(esEnvasado),
    showModalAnadirEscandallo: escandalloEnvasado.esGuardarYnuevo === true ? true : false
  }
}

export function abrirModalAnadirEscandallo (state, {proceso}) {
  return {
    ...state,
    showModalAnadirEscandallo: true,
    selectorProceso: proceso,
    dataDuplicarEscandallo: [],
    dataEscandallo: []
  }
}

export function closeModalAnadirEscandallo (state) {
  return {
    ...state,
    showModalAnadirEscandallo: false,
    dataDuplicarEscandallo: [],
    dataEscandallo: []
  }
}

export function editarEscandalloAnadidoSuccess (state, {datosAnadidos, proceso}) {
  return {
    ...state,
    escandalloAnalisis: proceso === 1 ? datosAnadidos : state.escandalloAnalisis,
    escandalloDilucion: proceso === 2 ? datosAnadidos : state.escandalloDilucion,
    escandalloEnvasado: proceso === 3 ? datosAnadidos : state.escandalloEnvasado,
    showModalAnadirEscandallo: false,
  }
}

// TODO Eliminar si al final eliminamos la funcionalidad de duplicar producto de un escandallo
export function duplicarDatosModalEscandalloAnadidoSuccess (state, {dataDuplicarEscandallo}) {
  return {
    ...state,
    dataDuplicarEscandallo: dataDuplicarEscandallo,
    showModalAnadirEscandallo: true,
    dataEscandallo: []
  }
}

export function seleccionarTodosEscandalloAnadidos (state, {proceso}) {
  return {
    ...state,
    checkedFiltrosEscandalloAnalisis: proceso === 1 && true,
    checkedFiltrosEscandalloDilucion: proceso === 2 && true,
    checkedFiltrosEscandalloEnvasado: proceso === 3 && true
  }
}

export function deSeleccionarTodosEscandalloAnadidos (state, {proceso}) {
  return {
    ...state,
    checkedFiltrosEscandalloAnalisis: proceso === 1 && false,
    checkedFiltrosEscandalloDilucion: proceso === 2 && false,
    checkedFiltrosEscandalloEnvasado: proceso === 3 && false
  }
}

export function deleteSeleccionEscandalloAnadido (state, {datosAnadidos, proceso}) {
  return {
    ...state,
    escandalloAnalisis: proceso === 1 ? datosAnadidos : state.escandalloAnalisis,
    escandalloDilucion: proceso === 2 ? datosAnadidos : state.escandalloDilucion,
    escandalloEnvasado: proceso === 3 ? datosAnadidos : state.escandalloEnvasado,
    checkedFiltrosEscandalloAnalisis: false,
    checkedFiltrosEscandalloDilucion: false,
    checkedFiltrosEscandalloEnvasado: false
  }
}

export function recuperarDatosModalEscandalloAnadido (state, {dataEscandallo}) {
  return {
    ...state,
    dataEscandallo: dataEscandallo,
    showModalAnadirEscandallo: true,
    dataDuplicarEscandallo: [],
    selectorProceso: dataEscandallo.proceso.value
  }
}

export function guardarYnuevoEscandalloSuccess (state) {
  return {
    ...state,
    dataEscandallo: [],
    dataDuplicarEscandallo: [],
    escandalloAnalisis: [],
    escandalloDilucion: [],
    escandalloEnvasado: [],
    data: [],
    dataDuplicar: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_ESCANDALLO:
      return abrirModalEscandallo(state, action)
    case actionTypes.CLOSE_MODAL_ESCANDALLO:
      return closeModalEscandallo(state, action)
    case actionTypes.FILTRAR_ESCANDALLO_SUCCESS:
      return filtrarEscandalloSuccess(state, action)
    case actionTypes.FILTROS_ESCANDALLO_SUCCESS:
      return filtrosEscandalloSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_SUCCESS:
      return recuperarDatosModalEscandallo(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_SUCCESS:
      return duplicarDatosModalEscandalloSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ESCANDALLO:
      return seleccionarTodosEscandallo(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ESCANDALLO:
      return deSeleccionarTodosEscandallo(state, action)
    case actionTypes.CREATE_CSV_ESCANDALLO_SUCCESS:
      return crearCsvEscandalloSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_ESCANDALLO_SUCCESS:
      return imprimirPdfEscandalloSuccess(state, action)
    case actionTypes.ANADIR_ESCANDALLO_ANALISIS:
      return andadirEscandalloAnalisis(state, action)
    case actionTypes.ANADIR_ESCANDALLO_DILUCION:
      return andadirEscandalloDilucion(state, action)
    case actionTypes.ANADIR_ESCANDALLO_ENVASADO:
      return andadirEscandalloEnvasado(state, action)
    case actionTypes.ABRIR_MODAL_ANADIR_ESCANDALLO:
      return abrirModalAnadirEscandallo(state, action)
    case actionTypes.CLOSE_MODAL_ANADIR_ESCANDALLO:
      return closeModalAnadirEscandallo(state, action)
    case actionTypes.EDITAR_ESCANDALLO_ANADIDO_SUCCESS:
      return editarEscandalloAnadidoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS:
      return duplicarDatosModalEscandalloAnadidoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ESCANDALLO_ANADIDOS:
      return seleccionarTodosEscandalloAnadidos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ESCANDALLO_ANADIDOS:
      return deSeleccionarTodosEscandalloAnadidos(state, action)
    case actionTypes.DELETE_SELECCION_ESCANDALLO_ANADIDO:
      return deleteSeleccionEscandalloAnadido(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS:
      return recuperarDatosModalEscandalloAnadido(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_ESCANDALLO_SUCCESS:
      return guardarYnuevoEscandalloSuccess(state, action)
    default:
      return state
  }
}
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize, change} from 'redux-form'
import i18n from '../../i18n'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import actionTypes from '../../constants/actions/cambioEstadoVerraco/cambioEstadoVerraco'
import {estadoVerraco, translateEstadoVerraco} from '../../constants/estadoVerraco'
import {causaEliminado, translateCausaEliminado} from '../../constants/causasEliminado'
import {cerrarModalCambioEstadoVerraco, abrirModalCambioEstadoVerraco, fetchFichaVerracoSuccess, fetchFiltrarFichaVerracoSuccess} from '../../actions/fichaVerraco/fichaVerraco'
import { enviarFechaCambioEstadoSuccess, submitNuevoCambioEstadoVerracoSuccess, fetchCambioEstadoVerracoSuccess, fetchFiltrarCambioEstadoVerracoSuccess,
  recuperarDatosModalCambioEstadoVerracoSuccess, duplicarDatosModalCambioEstadoVerracoSuccess, deSeleccionarTodosCambioEstadoVerraco, crearCsvCambioEstadoSuccess,
  imprimirPdfCambioEstadoSuccess} from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'
import checkCambioEstadoFecha from '../../services/cambioEstadoVerraco/checkCambioEstadoFecha'
import getCambioEstadoVerracoList from '../../services/cambioEstadoVerraco/getCambioEstadoVerracoList'
import getCambioEstadoVerraco from '../../services/cambioEstadoVerraco/getCambioEstadoVerraco'
import editCambioEstadoVerraco from '../../services/cambioEstadoVerraco/editCambioEstadoVerraco'
import deleteCambioEstadoVerraco from '../../services/cambioEstadoVerraco/deleteCambioEstadoVerraco'
import deleteSeleccionMoviCambioEstado from '../../services/cambioEstadoVerraco/deleteSeleccionMovimientoCambioEstado'
import getFichaVerracoList from '../../services/fichaVerraco/getFichaVerracoList'
import getFiltrarCambioEstadoVerraco from '../../services/cambioEstadoVerraco/getFiltrarCambioEstadoVerraco'
import addNuevoEstado from '../../services/cambioEstadoVerraco/addNuevoEstado'
import getCrearCsvCambioEstado from '../../services/cambioEstadoVerraco/getCrearCsvCambioEstado'
import getImprimirPdfCambioEstado from '../../services/cambioEstadoVerraco/getImprimirPdfCambioEstado'
import getFiltrarVerraco from '../../services/fichaVerraco/getFiltrarVerraco'
import {date_formatter} from '../../util/formatFunctions'

export function * enviarFechaCambioEstado ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const verracoMovidoHoy = yield call(checkCambioEstadoFecha, values, auth.token)
    yield put(enviarFechaCambioEstadoSuccess(verracoMovidoHoy))
    if(verracoMovidoHoy.ultimoEstado){
      if (verracoMovidoHoy.ultimoEstado === estadoVerraco.BAJA || verracoMovidoHoy.ultimoEstado === estadoVerraco.MUERTO) {
        let comboCausaEliminacion = yield select(state => state.combos.comboCausaEliminacion)
        var causaEliminacion = comboCausaEliminacion.find((element) => {
          return element.value === verracoMovidoHoy.causaEliminacion
        })
        // yield put(change('ModalVerracoCambioEstado', 'causaEliminacion', { value: verracoMovidoHoy.causaEliminacion, label: i18n.t(`COMUN.COMBOS.CAUSA_ELIMINACION.${translateCausaEliminado[verracoMovidoHoy.causaEliminacion]}`) }))
        // yield put(change('ModalCambioEstadoVerraco', 'causaEliminacion', { value: verracoMovidoHoy.causaEliminacion, label: i18n.t(`COMUN.COMBOS.CAUSA_ELIMINACION.${translateCausaEliminado[verracoMovidoHoy.causaEliminacion]}`) }))
        yield put(change('ModalVerracoCambioEstado', 'causaEliminacion', causaEliminacion))
        yield put(change('ModalCambioEstadoVerraco', 'causaEliminacion', causaEliminacion))
      }else{
        yield put(change('ModalVerracoCambioEstado', 'causaEliminacion', null))
        if(values.idCambioEstado == null){
          yield put(change('ModalCambioEstadoVerraco', 'causaEliminacion', null))
        }
      }
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchEnviarFechaCambioEstado () {
  yield takeLatest(actionTypes.ENVIAR_FECHA_CAMBIO_ESTADO, enviarFechaCambioEstado)
}

export function * submitNuevoCambioEstadoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionCambioEstado = yield call(addNuevoEstado, values, auth.token)
    yield put(submitNuevoCambioEstadoVerracoSuccess(values))
    yield put(cerrarModalCambioEstadoVerraco())
    yield call(fetchCambioEstadoVerraco)
    if (insercionCambioEstado.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(initialize('ModalCambioEstadoVerraco'))

    if (values.esFichaVerraco) {
      let filtros = yield select(state => state.fichaVerraco.filtros)
      const lista = yield call(getFiltrarVerraco, filtros, auth.token)
      yield put(fetchFichaVerracoSuccess(lista))
      yield put(fetchFiltrarFichaVerracoSuccess(filtros))
    } else {
      let filtros = yield select(state => state.cambioEstadoVerraco.filtros)
      const list = yield call(getFiltrarCambioEstadoVerraco, filtros, auth.token)
      yield put(fetchCambioEstadoVerracoSuccess(list))
      yield put(fetchFiltrarCambioEstadoVerracoSuccess(filtros))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchsubmitNuevoCambioEstadoVerraco () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO, submitNuevoCambioEstadoVerraco)
}

export function * fetchCambioEstadoVerraco () {
  try {
    // yield put(restartVerraco())
    const list = yield call(getCambioEstadoVerracoList, null)
    yield put(fetchCambioEstadoVerracoSuccess(list))
    yield put(initialize('simpleTable', list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchCambioEstadoVerraco () {
  yield takeLatest(actionTypes.FETCH_CAMBIO_ESTADO_VERRACO, fetchCambioEstadoVerraco)
}

export function * abrirModalVacioCambioEstado () {
  try {
    yield put(duplicarDatosModalCambioEstadoVerracoSuccess([]))
    yield put(recuperarDatosModalCambioEstadoVerracoSuccess([]))
    yield put(enviarFechaCambioEstadoSuccess([]))
    yield put(initialize('ModalCambioEstadoVerraco', {}))
    yield put(abrirModalCambioEstadoVerraco(null))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchAbrirModalVacioCambioEstado () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_CAMBIO_ESTADO, abrirModalVacioCambioEstado)
}

export function * recuperarDatosModalCambioEstadoVerraco ({idCambioEstadoVerraco}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(duplicarDatosModalCambioEstadoVerracoSuccess([]))
    const registroModificar = yield call(getCambioEstadoVerraco, idCambioEstadoVerraco, null)
    yield put(recuperarDatosModalCambioEstadoVerracoSuccess(registroModificar))
    registroModificar.observacionesMovimiento = registroModificar.ObservacionesCambio
    registroModificar.pesoInicial = registroModificar.pesoInicio
    registroModificar.sangriaInicial = registroModificar.sangriaInicio
    registroModificar.fechaCambioEstado = registroModificar.fechaCambio
    let estadoanterior = yield select(state => state.combos.comboEstadoVerraco)
    if (registroModificar.estadoanterior !== null) {
      estadoanterior = estadoanterior.filter((estadoVerraco) => {
        if (estadoVerraco.value === registroModificar.estadoanterior){
          return estadoVerraco
        }
      })
      console.log(estadoanterior)
    registroModificar.estadoActual = i18n.t(estadoanterior[0].label)
    }
    let estanoNuevo = yield select(state => state.combos.comboEstadoVerraco)
    estanoNuevo = estanoNuevo.filter((estadoVerraco) => {
      if (estadoVerraco.value === registroModificar.estadoactual){
        return estadoVerraco
      }
    })
    registroModificar.nuevoEstado = {value: estanoNuevo[0].value, label: i18n.t(estanoNuevo[0].label)}
    if (registroModificar.estadoactual === estadoVerraco.BAJA || registroModificar.estadoactual === estadoVerraco.MUERTO) {
      let comboCausaEliminacion = yield select(state => state.combos.comboCausaEliminacion)
      registroModificar.causaEliminacion = comboCausaEliminacion.find((element) => {
        return element.value === registroModificar.idCausaEliminacion
      })
      //registroModificar.causaEliminacion = { value: registroModificar.idCausaEliminacion, label: i18n.t(`COMUN.COMBOS.CAUSA_ELIMINACION.${translateCausaEliminado[registroModificar.idCausaEliminacion]}`) }
    }
    yield put(initialize('ModalCambioEstadoVerraco', registroModificar))
    yield put(abrirModalCambioEstadoVerraco(registroModificar.idVerraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchRecuperarDatosModalCambioEstadoVerraco () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO, recuperarDatosModalCambioEstadoVerraco)
}

export function * editarCambioEstadoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaCambio = values.fechaCambioEstado
    //values.nuevoEstado = values.nuevoEstado[0]
    values.idEstado = values.nuevoEstado
    values.sangriaInicio = values.sangriaInicial
    values.pesoInicio = values.pesoInicial
    values.idCambioEstado = values.idCambioEstadoVerraco
    const edicionCambioEstado = yield call(editCambioEstadoVerraco, values, auth.token)

    let filtros = yield select(state => state.cambioEstadoVerraco.filtros)
    const list = yield call(getFiltrarCambioEstadoVerraco, filtros, auth.token)
    yield put(fetchCambioEstadoVerracoSuccess(list))
    yield put(fetchFiltrarCambioEstadoVerracoSuccess(filtros))

    yield put(cerrarModalCambioEstadoVerraco())
    if (edicionCambioEstado.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(initialize('ModalCambioEstadoVerraco'))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchEditarCambioEstadoVerraco () {
  yield takeLatest(actionTypes.EDITAR_CAMBIO_ESTADO_VERRACO, editarCambioEstadoVerraco)
}

export function * duplicarDatosModalCambioEstadoVerraco ({idCambioEstadoVerraco}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalCambioEstadoVerracoSuccess([]))
    const registroDuplicar = yield call(getCambioEstadoVerraco, idCambioEstadoVerraco, null)
    yield put(duplicarDatosModalCambioEstadoVerracoSuccess(registroDuplicar))
    registroDuplicar.observacionesMovimiento = registroDuplicar.ObservacionesCambio
    /*let estadoanterior = yield select(state => state.combos.comboEstadoVerraco)
    estadoanterior = estadoanterior.filter((estadoVerraco) => {
      if (estadoVerraco.value === registroDuplicar.estadoanterior){
        return estadoVerraco
      }
    })
    registroDuplicar.estadoActual = estadoanterior[0].label
    let estanoNuevo = yield select(state => state.combos.comboEstadoVerraco)
    estanoNuevo = estanoNuevo.filter((estadoVerraco) => {
      if (estadoVerraco.value === registroDuplicar.estadoactual){
        return estadoVerraco
      }
    })
    registroDuplicar.nuevoEstado = estanoNuevo*/
    registroDuplicar.pesoInicial = registroDuplicar.pesoInicio
    registroDuplicar.sangriaInicial = registroDuplicar.sangriaInicio
    registroDuplicar.fechaCambioEstado = registroDuplicar.fechaCambio
    yield put(initialize('ModalCambioEstadoVerraco', registroDuplicar))
    yield put(abrirModalCambioEstadoVerraco(registroDuplicar.idVerraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDuplicarDatosModalCambioEstadoVerraco () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO, duplicarDatosModalCambioEstadoVerraco)
}

export function * onDeleteCambioEstadoVerraco ({idCambioEstadoVerraco}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      idCambioEstadoVerraco = {idCambioEstadoVerraco: idCambioEstadoVerraco}
      const eliminarCambioEstado = yield call(deleteCambioEstadoVerraco, idCambioEstadoVerraco, auth.token)

      let filtros = yield select(state => state.cambioEstadoVerraco.filtros)
      const list = yield call(getFiltrarCambioEstadoVerraco, filtros, auth.token)
      yield put(fetchCambioEstadoVerracoSuccess(list))
      yield put(fetchFiltrarCambioEstadoVerracoSuccess(filtros))

      if (eliminarCambioEstado.eliminado === true && eliminarCambioEstado.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (eliminarCambioEstado.eliminado === false && eliminarCambioEstado.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteCambioEstadoVerraco () {
  yield takeLatest(actionTypes.ON_DELETE_CAMBIO_ESTADO_VERRACO, onDeleteCambioEstadoVerraco)
}

export function * deleteSeleccionCambioEstadoVerraco ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarCambioEstadoSeleccion = yield call(deleteSeleccionMoviCambioEstado, list, auth.token)
      if (eliminarCambioEstadoSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosCambioEstadoVerraco())
      yield put(initialize('simpleTable'))
      let filtros = yield select(state => state.cambioEstadoVerraco.filtros)
      const lista = yield call(getFiltrarCambioEstadoVerraco, filtros, auth.token)
      yield put(fetchCambioEstadoVerracoSuccess(lista))
      yield put(fetchFiltrarCambioEstadoVerracoSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccionCambioEstadoVerraco () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CAMBIO_ESTADO_VERRACO, deleteSeleccionCambioEstadoVerraco)
}

export function * fetchFiltrarCambioEstadoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaDesde = values.fechaEntrada
    values.fechaHasta = values.fechaSalida
    const list = yield call(getFiltrarCambioEstadoVerraco, values, auth.token)
    yield put(fetchCambioEstadoVerracoSuccess(list))
    yield put(fetchFiltrarCambioEstadoVerracoSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarCambioEstadoVerraco () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO, fetchFiltrarCambioEstadoVerraco)
}

export function * crearCsvCambioEstado ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelCambioEstado = yield call(getCrearCsvCambioEstado, list, auth.token)
    yield put(crearCsvCambioEstadoSuccess(datosExcelCambioEstado))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvCambioEstado () {
  yield takeLatest(actionTypes.CREATE_CSV_CAMBIO_ESTADO, crearCsvCambioEstado)
}
export function * imprimirPdfCambioEstado ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfCambioEstado = yield call(getImprimirPdfCambioEstado, list, auth.token)
    yield put(imprimirPdfCambioEstadoSuccess(datosPdfCambioEstado))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfCambioEstado () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CAMBIO_ESTADO, imprimirPdfCambioEstado)
}

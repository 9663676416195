import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesProductividadExtracciones from '../../components/informes/FiltrosInformesProductividadExtracciones'
import {obtenerInformeProductividadExtracciones, imprimirPdfInformesProductividadExtracciones, crearCsvInformeProductividadExtracciones} from '../../actions/informes/informes'
import {comboVerraco, comboNombreGrupoVerraco, comboTipoDosis, comboIndex, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboCliente
  } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeProductividadExtracciones,
      comboVerraco,
      comboNombreGrupoVerraco,
      comboTipoDosis,
      comboIndex,
      comboRaza,
      comboLineaGenetica,
      comboOperario,
      comboTipoIntervencionSanitaria,
      imprimirPdfInformesProductividadExtracciones,
      comboCliente,
      crearCsvInformeProductividadExtracciones
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesProductividadExtracciones))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import './Raza.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalRaza extends Component {
  constructor (props) {
    super()
    this.state = {
      customPictraq:  false
    }
  }

  componentDidMount () {
    if (this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='PICTraq')) {      
      this.setState({customPictraq:true})
    }
  }

  submitNuevaRaza (values) {
    if (this.props.data){
      this.props.actions.editarRaza(values)
    } else {
      this.props.actions.submitNuevaRaza(values)
    }
  }
  guardarYnuevoRaza(values){
    this.props.actions.guardarYnuevoRaza(values)
  }
  render () {
    const {
      t, handleSubmit, showModalRaza, list: {razas}, data, duplicar, actions: {recuperarDatosModalRaza, closeModalRaza}
    } = this.props

    console.log('dirty', this.props.dirty)
    console.log('pristine', this.props.pristine)
    console.log('--- END of RENDER---')

    return (
      <Modal show={showModalRaza} onHide={closeModalRaza} backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-raza">
      <form className="form-raza">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('RAZA.MODAL.TITULO_EDITAR')
            ) : (
              t('RAZA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idRaza && (
            <ButtonChangeRecord list={razas} idElemento="idRaza" currentId={data.idRaza} getNextRecord={recuperarDatosModalRaza} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={6}
                id="nombre-raza"
                name="nombreRaza"
                component={InputText}
                controlLabel={t('RAZA.MODAL.NOMBRE_RAZA') + '*'}
                customClass='nombre-raza'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={6}
                id="abreviatura-raza"
                name="codRaza"
                component={InputText}
                controlLabel={t('RAZA.MODAL.ABREVIATURA') + '*'}
                customClass='nombre-raza'
                validate={required}
                maxLength={true}
                subTextoMax={true}
                valorMaxLength={4}
                t={t}
              />
              <Field
                colSm={12}
                id="descripcion-raza"
                name="descripcionRaza"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('RAZA.MODAL.DESCRIPCION')}
                customClass='descripcion-raza'
              />
              <Col sm={5}>
                { this.state.customPictraq && <Field
                  id="pictraq"
                  name="pictraq"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('RAZA.MODAL.PICTRAQ')}
                /> }
                <Field
                id="activo"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('RAZA.MODAL.ACTIVO')}
              />
              <input type="hidden" name="cambiarEstado"/>
              </Col>
              <Col sm={7}>
                <Field
                  colSm={12}
                  id="dosisServidasMes"
                  name="dosisServidasMes"
                  component={InputText}
                  controlLabel={t('RAZA.MODAL.DOSIS_SERVIDAS_MES')}
                  customClass='nombre-raza'                
                />
                <Field
                  colSm={12}
                  id="dosisProducidasMes"
                  name="dosisProducidasMes"
                  component={InputText}
                  controlLabel={t('RAZA.MODAL.DOSIS_PRODUCIDAS_MES')}
                  customClass='nombre-raza'                
                />
              </Col>              
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RAZAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('RAZA.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RAZAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RAZAS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoRaza.bind(this)) : null : undefined}
              >{t('RAZA.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaRaza.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RAZAS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RAZAS] < 2)}
          >{t('RAZA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalRaza}>{t('RAZA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRaza',
})(ModalRaza))
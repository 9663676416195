import actionTypes from '../../constants/actions/grupoUsuarios/grupoUsuarios'

export function fetchGrupoUsuarios (filtros) {
  return {
    type: actionTypes.FETCH_GRUPO_USUARIOS,
    filtros
  }
}

export function fetchGrupoUsuariosSuccess (list, filtros) {
  return {
    type: actionTypes.FETCH_GRUPO_USUARIOS_SUCCESS,
    list,
    filtros
  }
}

export function abrirModalGrupoUsuarios (idGrupoUsuario) {
  return {
    type: actionTypes.ABRIR_MODAL_GRUPO_USUARIOS,
    idGrupoUsuario
  }
}

export function abrirModalGrupoUsuariosSuccess (data) {
  return {
    type: actionTypes.ABRIR_MODAL_GRUPO_USUARIOS_SUCCESS,
    data
  }
}

export function submitGrupoUsuarios (values, idGrupoUsuario, guardarYnuevo, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_GRUPO_USUARIOS,
    values,
    idGrupoUsuario,
    guardarYnuevo,
    onSuccessCallback,
    onErrorCallback
  }
}

export function cerrarModalGrupoUsuarios () {
  return {
    type: actionTypes.CERRAR_MODAL_GRUPO_USUARIOS
  }
}

export function cerrarModalGrupoUsuariosSuccess () {
  return {
    type: actionTypes.CERRAR_MODAL_GRUPO_USUARIOS_SUCCESS
  }
}

export function duplicarGrupoUsuarios (idGrupoUsuario) {
  return {
    type: actionTypes.DUPLICAR_GRUPO_USUARIOS,
    idGrupoUsuario
  }
}

export function duplicarGrupoUsuariosSuccess (data) {
  return {
    type: actionTypes.DUPLICAR_GRUPO_USUARIOS_SUCCESS,
    data
  }
}

export function cambiarEstadoGrupoUsuarios (idGrupoUsuario) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_GRUPO_USUARIOS,
    idGrupoUsuario
  }
}

export function eliminarGrupoUsuarios (idGrupoUsuario) {
  return {
    type: actionTypes.ELIMINAR_GRUPO_USUARIOS,
    idGrupoUsuario
  }
}

export function seleccionarTodosGrupoUsuarios () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_GRUPO_USUARIOS
  }
}

export function deSeleccionarTodosGrupoUsuarios () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_GRUPO_USUARIOS
  }
}

export function deleteSeleccionGrupoUsuarios (list) {
  return {
    type: actionTypes.DELETE_SELECCION_GRUPO_USUARIOS,
    list
  }
}

export function cambioEstadoSeleccionGrupoUsuarios (list) {
  return {
    type: actionTypes.CAMBIO_ESTADO_SELECCION_GRUPO_USUARIOS,
    list
  }
}

export function crearCsvGrupoUsuarios (list) {
  return {
    type: actionTypes.CREAR_CSV_GRUPO_USUARIOS,
    list
  }
}

export function imprimirPdfGrupoUsuarios (list) {
  return {
    type: actionTypes.IMPRIMIR_PDF_GRUPO_USUARIOS,
    list
  }
}

export default {
  fetchGrupoUsuarios,
  fetchGrupoUsuariosSuccess,
  abrirModalGrupoUsuarios,
  abrirModalGrupoUsuariosSuccess,
  submitGrupoUsuarios,
  cerrarModalGrupoUsuarios,
  cerrarModalGrupoUsuariosSuccess,
  duplicarGrupoUsuarios,
  duplicarGrupoUsuariosSuccess,
  cambiarEstadoGrupoUsuarios,
  eliminarGrupoUsuarios,
  seleccionarTodosGrupoUsuarios,
  deSeleccionarTodosGrupoUsuarios,
  deleteSeleccionGrupoUsuarios,
  cambioEstadoSeleccionGrupoUsuarios,
  crearCsvGrupoUsuarios,
  imprimirPdfGrupoUsuarios
}

import actionTypes from '../../constants/actions/trazabilidadMaterial/materialDisponible'

export function initialState () {
  return {
    showModal: false,
    checkedFiltrosModalMaterialDisponible: false,
    lotes: [],
    filtros: {}
  }
}

function fetchMaterialDisponibleSuccess (state, {lotes, filtros}) {
  return {
    ...state,
    lotes,
    filtros
  }
}

function openModalMaterialDisponibleSuccess (state) {
  return {
    ...state,
    showModal: true
  }
}

function guardarModalMaterialDisponibleSuccess (state) {
  return {
    ...state,
    showModal: false,
    checkedFiltrosModalMaterialDisponible: false,
    lotes: [],
    filtros: {}
  }
}

function closeModalMaterialDisponibleSuccess (state) {
  return {
    ...state,
    showModal: false,
    checkedFiltrosModalMaterialDisponible: false,
    lotes: [],
    filtros: {}
  }
}

export function seleccionarTodosMaterialDisponible (state) {
  return {
    ...state,
    checkedFiltrosModalMaterialDisponible: true
  }
}

export function deSeleccionarTodosMaterialDisponible (state) {
  return {
    ...state,
    checkedFiltrosModalMaterialDisponible: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_MATERIAL_DISPONIBLE_SUCCESS:
      return fetchMaterialDisponibleSuccess(state, action)
    case actionTypes.OPEN_MODAL_MATERIAL_DISPONIBLE_SUCCESS:
      return openModalMaterialDisponibleSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_MATERIAL_DISPONIBLE_SUCCESS:
      return guardarModalMaterialDisponibleSuccess(state, action)
    case actionTypes.CLOSE_MODAL_MATERIAL_DISPONIBLE_SUCCESS:
      return closeModalMaterialDisponibleSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MATERIAL_DISPONIBLE:
      return seleccionarTodosMaterialDisponible(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MATERIAL_DISPONIBLE:
      return deSeleccionarTodosMaterialDisponible(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalDatosArticuloDosis from '../../components/cliente/ModalDatosArticuloDosis'
import {cerrarModalDatosArticuloDosis, anadirDatosArticuloDosis} from '../../actions/cliente/cliente'
import {comboLineaGeneticaActiva, comboRazaActiva, comboTipoDosis, comboNivelGeneticoActivo} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboLineaGeneticaActiva,
      comboRazaActiva,
      comboTipoDosis,
      cerrarModalDatosArticuloDosis,
      anadirDatosArticuloDosis,
      comboNivelGeneticoActivo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDatosArticuloDosis))
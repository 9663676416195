import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosCopiaSeguridad from '../../components/copiaSeguridad/FiltrosCopiaSeguridad'
import {openModal} from '../../actions/common'
import {abrirModalCopiaSeguridad, descargarCopiaSeguridadSuccess, seleccionarTodosCopiaSeguridad, deSeleccionarTodosCopiaSeguridad, deleteSeleccionCopiaSeguridad,
  abrirModalCopiaSeguridadProgramada, obtenerProgramacionCopiaSeguridad, abrirModalRestaurarConArchivo, abrirModalRestaurarMigracion} from '../../actions/copiaSeguridad/copiaSeguridad'

export function mapStateToProps (state) {
  return {
    ...state.copiaSeguridad,
    auth: state.auth,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalCopiaSeguridad,
      descargarCopiaSeguridadSuccess,
      seleccionarTodosCopiaSeguridad,
      deSeleccionarTodosCopiaSeguridad,
      deleteSeleccionCopiaSeguridad,
      abrirModalCopiaSeguridadProgramada,
      obtenerProgramacionCopiaSeguridad,
      abrirModalRestaurarConArchivo,
      abrirModalRestaurarMigracion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosCopiaSeguridad))
import actionTypes from '../../constants/actions/PICTraq/PICTraq'

export function initialState () {
  return {
    listAnalisis: [],
    listTraits:[],
    listPool:[],
    listSplits:[],
    listVerracosIndex: [],
    responseInsert: null,
    responseSave: null,
    updatedIndex: null,
    url: '',
    responseInsertPool: false,
    responseSavePool: null,
    responseInsertSplits: false,
    responseSaveSplits: null
  }
}

export function fetchAnalisisParaPICSuccess(state, {listAnalisis}) {
  return {
    ...state,
    listAnalisis: listAnalisis,
    responseInsert: null,
    responseSave: null
  }
}

export function fetchPoolParaPICSuccess(state, {listPool}) {
  return {
    ...state,
    listPool: listPool,
    responseInsertPool: false,
    responseSavePool: null,
  }
}

export function fetchSplitsParaPICSuccess(state, {listSplits}) {
  return {
    ...state,
    listSplits: listSplits,
    responseInsertSplits: false,
    responseSaveSplits: null
  }
}

export function fetchPICTraitsSuccess(state, {listTraits}) {
  return {
    ...state,
    listTraits
  }
}

export function insertAnalisisPICSuccess(state){
  return{ 
    ...state,  
    responseInsert: true
  }
}

export function insertPoolPICSuccess(state) {      
  return {
    ...state,    
    responseInsertPool: true
  }
}

export function insertSplitsPICSuccess(state) {      
  return {
    ...state,    
    responseInsertSplits: true
  }
}

export function resetAnalisisPIC(state){  
  return{
    ...state,
    listAnalisis: [],
    listVerracosIndex: [],
    updatedIndex: null,
    responseInsert: null,
    responseSave: null
  }
}

export function saveDataPICSuccess(state, response){
  return{ 
    ...state, 
    responseSave: response
  }
}

export function saveDataPoolPICSuccess(state, {response}){
  return{ 
    ...state, 
    responseSavePool: response
  }
}

export function saveDataSplitsPICSuccess(state, {response}){
  return{
    ...state, 
    responseSaveSplits: response
  }
}

export function getUrlPICSuccess(state, response){
  return{ 
    ...state,  
    url: response
  }
}


export function sincronizaIndexPICSuccess(state, {listVerracosIndex, updatedIndex}){
  return{ 
    ...state,  
    listVerracosIndex: listVerracosIndex,
    updatedIndex: updatedIndex
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_ANALISIS_PARA_PIC_SUCCESS:
      return fetchAnalisisParaPICSuccess(state, action)      
    case actionTypes.INSERT_POOL_PIC_SUCCESS:
      return insertPoolPICSuccess(state, action)
    case actionTypes.INSERT_SPLITS_PIC_SUCCESS:
      return insertSplitsPICSuccess(state, action)
    case actionTypes.FETCH_POOL_PARA_PIC_SUCCESS:
      return fetchPoolParaPICSuccess(state, action)
    case actionTypes.FETCH_SPLIT_PARA_PIC_SUCCESS:
      return fetchSplitsParaPICSuccess(state, action)
    case actionTypes.FETCH_PIC_TRAITS_SUCCESS:
      return fetchPICTraitsSuccess(state, action)
    case actionTypes.INSERT_ANALISIS_PIC_SUCCESS:
      return insertAnalisisPICSuccess(state, action)
    case actionTypes.RESET_ANALISIS_PIC:
      return resetAnalisisPIC(state, action)
    case actionTypes.SAVE_DATE_PIC_SUCCESS:
      return saveDataPICSuccess(state, action)
    case actionTypes.SAVE_DATA_POOL_PIC_SUCCESS:
      return saveDataPoolPICSuccess(state, action)
    case actionTypes.SAVE_DATA_SPLITS_PIC_SUCCESS:
      return saveDataSplitsPICSuccess(state, action)
    case actionTypes.GET_URL_PIC_SUCCESS:
      return getUrlPICSuccess(state, action)
    case actionTypes.SINCRONIZAR_INDEX_PIC_SUCCESS:
      return sincronizaIndexPICSuccess(state, action)
    default:
      return state;
  }
}
import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import FiltrosRegistroAlimentacionPage from '../../../containers/planSanitario/registroAlimentacion/FiltrosRegistroAlimentacionPage'
import ModalRegistroAlimentacionPage from '../../../containers/planSanitario/registroAlimentacion/ModalRegistroAlimentacionPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'

class RegistroAlimentacion extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarRegistroAlimentacion({})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      registroAlimentacion:{list, checkedFiltrosRegistroAlimentacion, data, dataDuplicar},
      actions: {recuperarDatosModalRegistroAlimentacion, duplicarDatosModalRegistroAlimentacion, cambiarEstadoRegistroAlimentacion, onDeleteRegistroAlimentacion}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idAlimentacion
    )

    const tableRegistroAlimentacion = {
      id: 'RegistroAlimentacionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1),
      hasOptions: true,
      columns: [
        {id: 'codigo', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.CODIGO')},
        {id: 'tipoAlimentacion', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.TIPO_ALIMENTACION')},
        {id: 'fechaInicioAlimentacion', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FECHA_INICIO_ALIMENTACION'), type: 'fecha'},
        {id: 'producto', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.PRODUCTO')},
        {id: 'lote', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.LOTE')},
        {id: 'numeroverracos', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.NUM_VERRACOS'), type: 'numero', numDecimales: 0},
        {id: 'cantidad', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.CANTIDAD')},
        {id: 'fechaFinalAlimentacion', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FECHA_FIN'), type: 'fecha'},
        {id: 'observacionesAlimentacion', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.OBSERVACIONES_ALIMENTACION')},
      ],
      mostrarCambioEstado: false,
      rows: list,
      filtros: <FiltrosRegistroAlimentacionPage/>,
      onEdit: (row) => recuperarDatosModalRegistroAlimentacion(row.idAlimentacion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1,
      duplicar: (row) =>duplicarDatosModalRegistroAlimentacion(row.idAlimentacion),
      dataDuplicar: dataDuplicar,
      cambiarEstado: (row) => cambiarEstadoRegistroAlimentacion(row.idAlimentacion),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1,
      onDelete: (row) => onDeleteRegistroAlimentacion(row.idAlimentacion),
      showModal: this.props.showModal,
      initialValues: {}
    }
    tableRegistroAlimentacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableRegistroAlimentacion.initialValues['check' + row.idElemento] = checkedFiltrosRegistroAlimentacion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalRegistroAlimentacionPage 
          duplicar={(row) =>duplicarDatosModalRegistroAlimentacion(row.idAlimentacion)}
        />
        <ListadoPage t_key="PLAN_SANITARIO.REGISTRO_ALIMENTACION." table={tableRegistroAlimentacion}>
        </ListadoPage>
      </div>
    )
  }
}

export default RegistroAlimentacion
import actionTypes from '../../constants/actions/foso/foso'

export function fetchFosoSuccess (listFoso) {
  return {
    type: actionTypes.FETCH_FOSO_SUCCESS,
    listFoso
  }
}

export function fetchFiltrarFoso (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_FOSO,
    values
  }
}

export function fetchFiltrarFosoSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_FOSO_SUCCESS,
    filtros
  }
}

export function abrirModalFoso () {
  return {
    type: actionTypes.ABRIR_MODAL_FOSO
  }
}

export function cerrarModalFoso () {
  return {
    type: actionTypes.CERRAR_MODAL_FOSO
  }
}

export function obtenerDatosVerraco (idVerraco, fecha) {
  return {
    type: actionTypes.OBTENER_DATOS_VERRACO,
    idVerraco,
    fecha
  }
}

export function obtenerDatosVerracoSuccess (dataDatosVerraco) {
  return {
    type: actionTypes.OBTENER_DATOS_VERRACO_SUCCESS,
    dataDatosVerraco
  }
}

export function obtenerCodigoAnalisisSuccess (codigoAnalisis) {
  return {
    type: actionTypes.OBTENER_CODIGO_ANALISIS_SUCCESS,
    codigoAnalisis
  }
}

export function obtenerExtraccion (idAnalisis) {
  return {
    type: actionTypes.OBTENER_EXTRACCION,
    idAnalisis
  }
}

export function obtenerExtraccionSuccess (dataExtraccion) {
  return {
    type: actionTypes.OBTENER_EXTRACCION_SUCCESS,
    dataExtraccion
  }
}

export function guardarFoso (values) {
  return {
    type: actionTypes.GUARDAR_FOSO,
    values
  }
}

export function modificarFoso (values) {
  return {
    type: actionTypes.MODIFICAR_FOSO,
    values
  }
}

export function eliminarFoso (values) {
  return {
    type: actionTypes.ELIMINAR_FOSO,
    values
  }
}

export function crearCsvFoso(list){
  return {
    type: actionTypes.CREATE_CSV_FOSO,
    list
  }
}

export function crearCsvFosoSuccess(datosExcelFoso){
  return {
    type: actionTypes.CREATE_CSV_FOSO_SUCCESS,
    datosExcelFoso
  }
}
export function imprimirPdfFoso(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_FOSO,
    list
  }
}

export function imprimirPdfFosoSuccess(datosPdfFoso){
  return {
    type: actionTypes.IMPRIMIR_PDF_FOSO_SUCCESS,
    datosPdfFoso
  }
}

export function imprimirEtiquetaZebra(values){
  return {
    type: actionTypes.IMPRIMIR_ETIQUETA_FOSO,
    values
  }
}

export default {
  fetchFosoSuccess,
  fetchFiltrarFoso,
  fetchFiltrarFosoSuccess,
  abrirModalFoso,
  cerrarModalFoso,
  obtenerDatosVerraco,
  obtenerDatosVerracoSuccess,
  obtenerCodigoAnalisisSuccess,
  obtenerExtraccion,
  obtenerExtraccionSuccess,
  guardarFoso,
  modificarFoso,
  eliminarFoso,
  crearCsvFoso,
  crearCsvFosoSuccess,
  imprimirPdfFoso,
  imprimirPdfFosoSuccess,
  imprimirEtiquetaZebra
}
export default {
  FETCH_TIPO_ALIMENTACION: 'FETCH_TIPO_ALIMENTACION',
  FETCH_TIPO_ALIMENTACION_SUCCESS: 'FETCH_TIPO_ALIMENTACION_SUCCESS',
  ABRIR_MODAL_VACIO_TIPO_ALIMENTACION: 'ABRIR_MODAL_VACIO_TIPO_ALIMENTACION',
  SELECCIONAR_TODOS_TIPO_ALIMENTACION: 'SELECCIONAR_TODOS_TIPO_ALIMENTACION',
  DESELECCIONAR_TODOS_TIPO_ALIMENTACION: 'DESELECCIONAR_TODOS_TIPO_ALIMENTACION',
  SUBMIT_NUEVO_TIPO_ALIMENTACION: 'SUBMIT_NUEVO_TIPO_ALIMENTACION',
  RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION: 'RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION',
  RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS: 'RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS',
  EDITAR_TIPO_ALIMENTACION: 'EDITAR_TIPO_ALIMENTACION',
  DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION: 'DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION',
  DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS: 'DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS',
  CAMBIAR_ESTADO_TIPO_ALIMENTACION: 'CAMBIAR_ESTADO_TIPO_ALIMENTACION',
  ON_DELETE_TIPO_ALIMENTACION: 'ON_DELETE_TIPO_ALIMENTACION',
  CAMBIAR_ESTADO_SELECCION_TIPO_ALIMENTACION: 'CAMBIAR_ESTADO_SELECCION_TIPO_ALIMENTACION',
  DELETE_SELECCION_TIPO_ALIMENTACION: 'DELETE_SELECCION_TIPO_ALIMENTACION',
  GUARDAR_Y_NUEVO_TIPO_ALIMENTACION: 'GUARDAR_Y_NUEVO_TIPO_ALIMENTACION',
  CREATE_CSV_TIPO_ALIMENTACION: 'CREATE_CSV_TIPO_ALIMENTACION',
  CREATE_CSV_TIPO_ALIMENTACION_SUCCESS: 'CREATE_CSV_TIPO_ALIMENTACION_SUCCESS',
  IMPRIMIR_PDF_TIPO_ALIMENTACION: 'IMPRIMIR_PDF_TIPO_ALIMENTACION',
  IMPRIMIR_PDF_TIPO_ALIMENTACION_SUCCESS: 'IMPRIMIR_PDF_TIPO_ALIMENTACION_SUCCESS'
}
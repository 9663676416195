import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosInstalacion extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarInstalacion(values){
    this.props.actions.fetchFiltrarInstalacion(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosInstalacion()
    }
    if (this.props.datosExcelInstalacion !== prevProps.datosExcelInstalacion) {
      if (this.props.datosExcelInstalacion.excel && this.props.datosExcelInstalacion.excel !== null){
        let pdf = this.props.datosExcelInstalacion.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.INSTALACIONES') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfInstalacion !== prevProps.datosPdfInstalacion) {
      if (this.props.datosPdfInstalacion.pdf && this.props.datosPdfInstalacion.pdf !== null){
        let pdf = this.props.datosPdfInstalacion.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.INSTALACIONES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosInstalacion()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboCodInstalacion()
    this.props.actions.comboNombreInstalacion()
    this.props.actions.comboInstalacionesPadre()
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosInstalacion()
    } else {
      this.props.actions.deSeleccionarTodosInstalacion()
    }
  }

  cambiarEstadoSeleccionInstalacion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionInstalacion(list)
  }

  deleteSeleccionInstalacion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionInstalacion(list)
  }

  crearCsvInstalacion(){
    this.props.actions.crearCsvInstalacion(this.props.filtros)
  }

  imprimirPdfInstalacion(){
    this.props.actions.imprimirPdfInstalacion(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarInstalacion(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVacioInstalacion
    },
    combos: {
      comboTipoInstalacion, comboCodInstalacion, comboNombreInstalacion, comboInstalacionesPadre
    }
  } = this.props

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="nombreInstalacion"
              name="nombreInstalacion"
              controlLabel={t('INSTALACION.FILTROS.INSTALACION')}
              component={InputSelect}
              options={comboNombreInstalacion}
              valueKey="value"
              labelKey="label"
              customClass='select-nombre-instalacion'
              onInputChange={handleSubmit(this.fetchFiltrarInstalacion.bind(this))}
            />
            <Field
              colSm={2}
              id="codInstalacion"
              name="codInstalacion"
              controlLabel={t('INSTALACION.FILTROS.COD_INSTALACION')}
              component={InputSelect}
              options={comboCodInstalacion}
              valueKey="value"
              labelKey="label"
              customClass='select-cod-instalacion'
              onInputChange={handleSubmit(this.fetchFiltrarInstalacion.bind(this))}
            />
            <Field
              colSm={2}
              id="idTipoInstalacion"
              name="idTipoInstalacion"
              controlLabel={t('INSTALACION.FILTROS.TIPO_UBICACION')}
              component={InputSelect}
              options={comboTipoInstalacion}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-ubicacion'
              onInputChange={handleSubmit(this.fetchFiltrarInstalacion.bind(this))}
            />
            <Field
              colSm={2}
              id="idInstalacionPadre"
              name="idInstalacionPadre"
              controlLabel={t('INSTALACION.FILTROS.INSTALACION_PADRE')}
              component={InputSelect}
              options={comboInstalacionesPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-ubicacion'
              onInputChange={handleSubmit(this.fetchFiltrarInstalacion.bind(this))}
            />
            <Field
              colSm={2}
              id="activo-filtro-liena"
              name="activo"
              controlLabel={t('ESTADO.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-instalacion'
              onInputChange={handleSubmit(this.fetchFiltrarInstalacion.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nombreInstalacion && filtros.nombreInstalacion !== null ? (
                      <span className="badge-dark">{t('INSTALACION.FILTROS.INSTALACION') + ': ' + filtros.nombreInstalacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreInstalacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.codInstalacion && filtros.codInstalacion !== null ? (
                      <span className="badge-dark">{t('INSTALACION.FILTROS.COD_INSTALACION') + ': ' + filtros.codInstalacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('codInstalacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.idTipoInstalacion && filtros.idTipoInstalacion !== null ? (
                      <span className="badge-dark">{t('INSTALACION.FILTROS.TIPO_UBICACION') + ': ' + filtros.idTipoInstalacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idTipoInstalacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.idInstalacionPadre && filtros.idInstalacionPadre !== null ? (
                      <span className="badge-dark">{t('INSTALACION.FILTROS.INSTALACION_PADRE') + ': ' + filtros.idInstalacionPadre.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idInstalacionPadre')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo && filtros.activo !== null ? (
                      <span className="badge-dark">{t('ESTADO.ESTADO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div key="block-options" id="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionInstalacion()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionInstalacion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={() => this.crearCsvInstalacion()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInstalacion()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.INSTALACIONES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1) ? abrirModalVacioInstalacion : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInstalacion',
})(FiltrosInstalacion))

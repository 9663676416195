import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesTrazabilidadPage from '../../containers/informes/FiltrosInformesTrazabilidadPage'
import {date_formatter, number_formatter} from '../../util/formatFunctions'

class InformesTrazabilidad extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.TRAZABILIDAD') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeTrazabilidad, totalesTrazabilidad,
      auth: {separadorDec, separadorMil}
    } = this.props

    const tableInformeTrazabilidad = {
      id: 'informeTrazabilidadTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombre', name: t('INFORMES.TRAZABILIDAD.TABLA.PRODUCTO')},
        {id: 'lote', name: t('INFORMES.TRAZABILIDAD.TABLA.LOTE')},
        {id: 'fecha', name: t('INFORMES.TRAZABILIDAD.TABLA.FECHA'), type: 'fecha'},
        {id: 'verracoPool', name: t('INFORMES.TRAZABILIDAD.TABLA.VERRACO_POOL')},
        {id: 'nombreDireccion', name: t('INFORMES.TRAZABILIDAD.TABLA.GRANJA')},
        {id: 'dosisEnviadas', name: t('INFORMES.TRAZABILIDAD.TABLA.NUM_DOSIS')},
        {id: 'caducidad', name: t('INFORMES.TRAZABILIDAD.TABLA.FECHA_CADUCIDAD_PRODUCTO'), type: 'fecha'},
        {id: 'validacion', name: t('INFORMES.TRAZABILIDAD.TABLA.VALIDACION')}
      ],
      rows: Object.keys(listInformeTrazabilidad).length > 0 ? listInformeTrazabilidad : [],
      filtros: <FiltrosInformesTrazabilidadPage />,
      initialValues: {}
    }

    const tableTotalesTrazabilidad = {
      id: 'totalesTrazabilidadTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombre', name: t('INFORMES.TRAZABILIDAD.PRODUCTO')},
        {id: 'stockUsado', name: t('INFORMES.TRAZABILIDAD.STOCK_USADO'), type: 'numero'}
      ],
      rows: totalesTrazabilidad.stock!=null && Object.keys(totalesTrazabilidad).length > 0 ? totalesTrazabilidad.stock : [],
      initialValues: {}
    }

    return (
      <div>
        <h2 className="page-title">{t('INFORMES.TRAZABILIDAD.TITLE')}</h2>
        <SimpleTablePage {...tableInformeTrazabilidad}>
        </SimpleTablePage>
        <h3>{t('INFORMES.TRAZABILIDAD.INFORMACION_RESUMEN')}</h3>
        <Col sm={4} style={{padding: 0}}>
          <Col sm={12} style={{padding: 0}}>
            <div className="totales-informes" style={{borderRight: 0}}>
              <Col sm={8} className="titulo-totales-informes"><span>{t('INFORMES.TRAZABILIDAD.TOTALES.NUM_CLIENTES_GRANJAS_TOTALES')}:</span></Col>
              <Col sm={4} className="valores-totales-informes"><span>{totalesTrazabilidad.numeroClientes}</span></Col>
            </div>
          </Col>
          <Col sm={12} style={{padding: 0}}>
            <div className="totales-informes" style={{borderRight: 0}}>
              <Col sm={8} className="titulo-totales-informes"><span>{t('INFORMES.TRAZABILIDAD.TOTALES.NUM_DOSIS_TOTALES')}:</span></Col>
              <Col sm={4} className="valores-totales-informes"><span>{totalesTrazabilidad.numeroDosis}</span></Col>
            </div>
          </Col>
        </Col>
        <Col sm={8} style={{marginTop: '10px'}}>
          <SimpleTablePage {...tableTotalesTrazabilidad}>
        </SimpleTablePage>
        </Col>
      </div>
    )
  }
}

export default InformesTrazabilidad
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InformesIntervenciones from '../../components/informes/InformesIntervenciones'
import {obtenerInformeIntervenciones} from '../../actions/informes/informes'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeIntervenciones
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InformesIntervenciones))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import { FilterList } from '@material-ui/icons'

class FiltrosMaterialDisponible extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
    this.fetchMaterialDisponible = this.fetchMaterialDisponible.bind(this)
  }

  fetchMaterialDisponible(values){
    this.props.actions.fetchMaterialDisponible(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosMaterialDisponible()
    } else {
      this.props.actions.deSeleccionarTodosMaterialDisponible()
    }
  }

  componentDidMount () {
    this.props.actions.comboProductosActivos()
    this.props.actions.comboLotesActivos()
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchMaterialDisponible(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros = {}, combos: {comboProductosActivos, comboLotesActivos}
  } = this.props
  const tKey = 'ANALISIS_EXTRACCIONES.MATERIAL_DISPONIBLE.'

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="producto"
              name="producto"
              controlLabel={t(tKey + 'FILTROS.PRODUCTO')}
              component={InputSelect}
              options={comboProductosActivos}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchMaterialDisponible)}
            />
            <Field
              colSm={2}
              id="lote"
              name="lote"
              controlLabel={t(tKey + 'FILTROS.LOTE')}
              component={InputSelect}
              options={comboLotesActivos}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchMaterialDisponible)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.producto && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.PRODUCTO') + ': ' + filtros.producto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('producto')}> x </button></span>),
                    (filtros.lote && <span key="filtros.pool"  className="badge-dark">{t(tKey + 'FILTROS.LOTE') + ': ' + filtros.lote.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lote')}> x </button></span>)
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="custom-check-w">
                <Field
                  id="seleccionar-todos-modal-material-disponible"
                  name="seleccionar-todos-modal-material-disponible"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  onInputChange={(value) => this.intercambiarCheck(value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList /> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosMaterialDisponible',
})(FiltrosMaterialDisponible))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRestaurarCopiaDesdeArchivo from '../../components/copiaSeguridad/ModalRestaurarCopiaDesdeArchivo'
import {cerrarModalRestaurarConArchivo, subirArchivosCopiaSeguridad, submitCopiaSeguridadDesdeArchivo} from '../../actions/copiaSeguridad/copiaSeguridad'

export function mapStateToProps (state) {
  return {
    ...state.copiaSeguridad,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalRestaurarConArchivo,
      subirArchivosCopiaSeguridad,
      submitCopiaSeguridadDesdeArchivo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRestaurarCopiaDesdeArchivo))
import actionTypes from '../../../constants/actions/planSanitario/controlSangre/controlSangre'

export function initialState () {
  return {
    mainList: [],
    filtros: {},
    checkedFiltros: false,
    esApartadoPrincipal: false,
    list: [],
    conservacion: null,
    codigo: '',
    idAnalisis: null,
    idPool: null,
    showModalListado: false,
    checkedFiltrosListado: false,
    checkedInicio: false,
    filtrosInicio: [],
    listVerracos: [],
    listVerracosAsignados: [],
    checkedVerracosAnadidos: false,
    showModalInicio: false,
    showModalControlSangre: false,
    data: {},
    archivo: []
  }
}

export function fetchSuccess (state, {mainList, filtros}) {
  return {
    ...state,
    mainList,
    filtros,
    esApartadoPrincipal: true,
    archivo: []
  }
}

export function seleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: true
  }
}

export function deSeleccionarTodos (state) {
  return {
    ...state,
    checkedFiltros: false
  }
}

export function openModalListadoSuccess (state, {list, conservacion, codigo, idAnalisis, idPool}) {
  return {
    ...state,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool,
    showModalListado: true
  }
}

export function closeModalListadoSuccess (state) {
  return {
    ...initialState(),
    mainList: state.mainList,
    filtros: state.filtros,
    checkedFiltros: state.checkedFiltros,
    esApartadoPrincipal: state.esApartadoPrincipal
  }
}

export function seleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: true
  }
}

export function deSeleccionarTodosModal (state) {
  return {
    ...state,
    checkedFiltrosListado: false
  }
}


export function seleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: true
  }
}

export function deSeleccionarTodosVerracosInicio (state) {
  return {
    ...state,
    checkedVerracosAnadidos: false
  }
}

export function abrirModalInicio (state) {
  return {
    ...state,
    showModalInicio: true,
    archivo: []
  }
}

export function closeModalInicio (state) {
  return {
    ...state,
    showModalInicio: false,
    checkedInicio: false,
    archivo: []
  }
}

export function anadirVerracosModalInicioSuccess (state, {listVerracosAsignados}) {
  return {
    ...state,
    listVerracosAsignados: listVerracosAsignados,
    archivo: []
  }
}

export function fetchFiltrarModalInicioSuccess (state, {listVerracos,filtros}) {  
  return {
    ...state,
    listVerracos: listVerracos,    
    filtrosInicio: filtros,
    archivo: []
  }
}

export function subirArchivosInforme (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function recuperarDatosModalControlSangreSuccess(state, {data}) {
  return {
    ...state,
    data: data,
    showModalControlSangre: true
  }
}

export function abrirModalControlSangre(state) {
  return {
    ...state
  }
}

export function closeModalControlSangre(state) {
  return {
    ...state,
    showModalControlSangre: false
  }
}


export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_CONTROL_SANGRE_SUCCESS:
      return fetchSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CONTROL_SANGRE:
      return seleccionarTodos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CONTROL_SANGRE:
      return deSeleccionarTodos(state, action)
    case actionTypes.OPEN_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS:
      return openModalListadoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SANGRE_SUCCESS:
      return closeModalListadoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_SANGRE:
      return seleccionarTodosModal(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_SANGRE:
      return deSeleccionarTodosModal(state, action)
    case actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE_SUCCESS:
      return fetchFiltrarModalInicioSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE:
      return seleccionarTodosVerracosInicio(state, action)
    case actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SANGRE:
      return deSeleccionarTodosVerracosInicio(state, action)
    case actionTypes.ABRIR_MODAL_INICIO_CONTROL_SANGRE:
      return abrirModalInicio(state, action)
    case actionTypes.CLOSE_MODAL_INICIO_CONTROL_SANGRE:
      return closeModalInicio(state, action)
    case actionTypes.SUBIR_ARCHIVO_INFORME:
      return subirArchivosInforme(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SANGRE_SUCCESS:
      return recuperarDatosModalControlSangreSuccess(state, action)     
    case actionTypes.ABRIR_MODAL_CONTROL_SANGRE:
      return abrirModalControlSangre(state, action)
    case actionTypes.CLOSE_MODAL_CONTROL_SANGRE:
      return closeModalControlSangre(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalDatosDosis from '../../components/pedidosVenta/ModalDatosDosis'
import {cerrarModalDatosDosis, anadirDatosDosis, editarDatosDosis, verracosPedido} from '../../actions/pedidosVenta/pedidosVenta'
import {comboVerraco, comboRazaGranja, comboLineaGeneticaGranja, comboTipoDosisGranja, comboNivelGeneticoActivoGranja} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalDatosDosis,
      anadirDatosDosis,
      comboVerraco,
      editarDatosDosis,
      verracosPedido,
      comboRazaGranja,
      comboLineaGeneticaGranja,
      comboTipoDosisGranja,
      comboNivelGeneticoActivoGranja
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDatosDosis))
export default {
  SUBMIT_NUEVO_MOVI_VERRACO: 'SUBMIT_NUEVO_MOVI_VERRACO',
  SUBMIT_NUEVO_MOVI_VERRACO_SUCCESS: 'SUBMIT_NUEVO_MOVI_VERRACO_SUCCESS',
  UBICACIONES_VACIAS: 'UBICACIONES_VACIAS',
  UBICACIONES_VACIAS_SUCCESS: 'UBICACIONES_VACIAS_SUCCESS',
  RESTART_MOVIMIENTO_VERRACO: 'RESTART_MOVIMIENTO_VERRACO',
  ENVIAR_FECHA_MOVIMIENTO_VERRACO: 'ENVIAR_FECHA_MOVIMIENTO_VERRACO',
  ENVIAR_FECHA_MOVIMIENTO_VERRACO_SUCCESS: 'ENVIAR_FECHA_MOVIMIENTO_VERRACO_SUCCESS',
  FETCH_MOVIMIENTO_VERRACO: 'FETCH_MOVIMIENTO_VERRACO',
  FETCH_MOVIMIENTO_VERRACO_SUCCESS: 'FETCH_MOVIMIENTO_VERRACO_SUCCESS',
  RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO: 'RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO',
  RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS: 'RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS',
  ABRIR_MODAL_VACIO_UBICACION: 'ABRIR_MODAL_VACIO_UBICACION',
  EDITAR_MOVI_VERRACO: 'EDITAR_MOVI_VERRACO',
  DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO: 'DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO',
  DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS: 'DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS',
  ON_DELETE_MOVIMIENTO_VERRACO: 'ON_DELETE_MOVIMIENTO_VERRACO',
  SELECCIONAR_TODOS_MOVIMIENTO_VERRACOS: 'SELECCIONAR_TODOS_MOVIMIENTO_VERRACOS',
  DESELECCIONAR_TODOS_MOVIMIENTO_VERRACOS: 'DESELECCIONAR_TODOS_MOVIMIENTO_VERRACOS',
  DELETE_SELECCION_MOVIMIENTO_VERRACO: 'DELETE_SELECCION_MOVIMIENTO_VERRACO',
  FETCH_FILTRAR_MOVIMIENTO_VERRACO: 'FETCH_FILTRAR_MOVIMIENTO_VERRACO',
  FETCH_FILTRAR_MOVIMIENTO_VERRACO_SUCCESS: 'FETCH_FILTRAR_MOVIMIENTO_VERRACO_SUCCESS',
  CHECK_VERRACOS_SIN_UBICACION: 'CHECK_VERRACOS_SIN_UBICACION',
  QUITAR_CHECK_VERRACOS_SIN_UBICACION: 'QUITAR_CHECK_VERRACOS_SIN_UBICACION',
  NUM_VERRACOS_SIN_UBICACION: 'NUM_VERRACOS_SIN_UBICACION',
  ORIGEN_MOVIMIENTO_SUCCESS: 'ORIGEN_MOVIMIENTO_SUCCESS',
  GUARDAR_Y_NUEVO_MOVIMIENTO: 'GUARDAR_Y_NUEVO_MOVIMIENTO',
  CREATE_CSV_MOVIMIENTO_VERRACO: 'CREATE_CSV_MOVIMIENTO_VERRACO',
  CREATE_CSV_MOVIMIENTO_VERRACO_SUCCESS: 'CREATE_CSV_MOVIMIENTO_VERRACO_SUCCESS',
  IMPRIMIR_PDF_MOVIMIENTO_VERRACO: 'IMPRIMIR_PDF_MOVIMIENTO_VERRACO',
  IMPRIMIR_PDF_MOVIMIENTO_VERRACO_SUCCESS: 'IMPRIMIR_PDF_MOVIMIENTO_VERRACO_SUCCESS',
  CERRAR_MODAL_UBICACION_MOVIMIENTO_VERRACO: 'CERRAR_MODAL_UBICACION_MOVIMIENTO_VERRACO'
}
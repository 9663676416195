import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import PanelControl from '../../components/panelControl/PanelControl'
import { obtenerDatosPanelControl, guardarPanelControl, abrirModalTeclasContaje, abrirModalTipoDiluyente, abrirModalTipoDosis, abrirModalRectaColorimetro, valoresPredefinidosPanelControl,
  uploadFilePanelControl, uploadLogoPanelControl, recuperarSecuenciaNumeraciones, fecthSecuenciasNumeracion, resetPedido, abrirConfiguracionMagavision } from '../../actions/panelControl/panelControl'
import { getFormValues } from 'redux-form'

export function mapStateToProps (state) {
  return {
    ...state.panelControl,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: state.combos,
    urlRecursos: setting.default.urlRecursos,
    formPanelControl: getFormValues('panelControl')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerDatosPanelControl,
      guardarPanelControl,
      abrirModalTeclasContaje,
      abrirModalTipoDiluyente,
      abrirModalTipoDosis,
      abrirModalRectaColorimetro,
      valoresPredefinidosPanelControl,
      uploadFilePanelControl,
      uploadLogoPanelControl,
      recuperarSecuenciaNumeraciones,
      fecthSecuenciasNumeracion,
      resetPedido,
      abrirConfiguracionMagavision
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PanelControl))
import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2';
import Button from '../comun/button/Button'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosListadoSeguimientoPage from '../../containers/seguimiento/FiltrosListadoSeguimientoPage'
import ModalFormSeguimientoPage from '../../containers/seguimiento/ModalFormSeguimientoPage'
import {number_formatter, round_decimal, date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants} from '../../constants/analisis'

const translateLegend = {
  calidad: "CALIDAD_PERCENT",
  aglutinacion: "AGLUTINACION_PERCENT",
  formasAnormales: "FORMAS_ANORMALES_PERCENT",
  porMotilidad: "POR_MOTILIDAD",
  porMotilidadTotal: "POR_MOTILIDAD_TOTAL"
}

const chartListadoSeguimiento = (separadorDec, separadorMil) => {
  return {
    height: 100,
    options: {
      showLines: false,
      legend: {
        display: true,
        position: 'bottom'/*,
        onClick: (e, legendItem) => {
          console.log(this, e, legendItem)
        }*/
      },
      scales: {
        yAxes: [{
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value + '%';
            }/*,
            min: 0,
            max: 100*/
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''
            if (label) {
              label += ': '
            }
            label += number_formatter(tooltipItem.yLabel, 2, separadorDec, separadorMil) + '%'
            return label
          }
        }
      },
      plugins: {
        labels: false
      }
    },
    data: []
  }
}

const initialChartData = (t, tKey, tipoAnalisisPredeterminado) => {
  return {
    labels: [],
    datasets: [
      // {
      //   label: t(tKey + 'CHART.LEGEND.OPTIONS.' + translateLegend.calidad),
      //   backgroundColor: '#E0400A', //'rgba(224, 64, 10, 0.2)', // #E0400A
      //   borderColor: '#E0400A',
      //   borderWidth: 1,
      //   hoverBackgroundColor: '#E0400A',
      //   hoverBorderColor: '#E0400A',
      //   data: []
      // },
      // {
      //   label: t(tKey + 'CHART.LEGEND.OPTIONS.' + translateLegend.aglutinacion),
      //   backgroundColor: '#056492', // #056492
      //   borderColor: '#056492',
      //   borderWidth: 1,
      //   hoverBackgroundColor: '#056492',
      //   hoverBorderColor: '#056492',
      //   data: []
      // },
      {
        label: t(tKey + 'CHART.LEGEND.OPTIONS.' + translateLegend.formasAnormales),
        backgroundColor: '#BFBFBF', // #BFBFBF
        borderColor: '#BFBFBF',
        borderWidth: 1,
        hoverBackgroundColor: '#BFBFBF',
        hoverBorderColor: '#BFBFBF',
        data: []
      },
      ((tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_ML) ?
      {
        label: t(tKey + 'CHART.LEGEND.OPTIONS.' + translateLegend.porMotilidadTotal),
        backgroundColor: '#FFE382', // #FFE382
        borderColor: '#FFE382',
        borderWidth: 1,
        hoverBackgroundColor: '#FFE382',
        hoverBorderColor: '#FFE382',
        data: []
      } :
      {
        label: t(tKey + 'CHART.LEGEND.OPTIONS.' + translateLegend.porMotilidad),
        backgroundColor: '#1A3B69', // #1A3B69
        borderColor: '#1A3B69',
        borderWidth: 1,
        hoverBackgroundColor: '#1A3B69',
        hoverBorderColor: '#1A3B69',
        data: []
      })
    ]
  }
}

class ModalListadoSeguimiento extends Component {
  render () {
    const {
      t, renderForm = true, showModalListadoSeguimiento, checkedFiltrosListadoSeguimiento, list, numeroRegistrosPorPagina, paginaActual, isPool = false,
      panelControl: {tipoAnalisisPredeterminado},
      auth: {separadorDec, separadorMil, formaFechaHora},
      actions: {deleteSeguimiento, openModalFormSeguimiento, closeModalListadoSeguimiento}
    } = this.props

    const tKey = 'SEGUIMIENTO.LISTADO.'
    const idElemento = 'idSeguimiento'
    const idForm = 'listadoSeguimientoTable'

    const tableListadoSeguimiento = {
      id: 'listadoSeguimientoTable',
      form: idForm,
      idElemento,
      multiHeader: false,
      hasCheckbox:  (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      noBlockOptions: true,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      onEdit: (row) => openModalFormSeguimiento(row.idSeguimiento, false, this.props.isPool),
      mostrarDuplicar: true,
      duplicar: (row) => openModalFormSeguimiento(row.idSeguimiento, true, this.props.isPool),
      mostrarEliminar: true,
      onDelete: (row) => deleteSeguimiento(row),
      mostrarCambioEstado: false,
      columns: [
        {id: 'fecha', name: t(tKey + 'COLUMNS.FECHA'), type: 'fecha'},
        ...(
          (tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_ML) ? (
            [
              {id: 'porMotilidadTotal', key: 'porMotilidadTotal', name: t(tKey + 'COLUMNS.MOTILIDAD_TOTAL_PERCENT'), type: 'numero'},
              {id: 'porMotilidadProgresiva', key: 'porMotilidadProgresiva', name: t(tKey + 'COLUMNS.MOTILIDAD_PROGRESIVA_PERCENT'), type: 'numero'}
            ]
          ) :
            [{id: 'porMotilidad', key: 'porMotilidad', name: t(tKey + 'COLUMNS.POR_MOTILIDAD'), type: 'numero'}]
        ),
        {id: 'calidad', name: t(tKey + 'COLUMNS.CALIDAD'), type: 'numero'},
        {id: 'aglutinacion', name: t(tKey + 'COLUMNS.AGLUTINACION'), type: 'numero'},
        {id: 'contaminacion', name: t(tKey + 'COLUMNS.CONTAMINACION')},
        {id: 'concentracion', name: t(tKey + 'COLUMNS.CONCENTRACION'), type: 'numero'},
        {id: 'formasAnormales', name: t(tKey + 'COLUMNS.FORMAS_ANORMALES_PERCENT'), type: 'numero'},
        {id: 'operarioProcesado', name: t('ANALISIS_EXTRACCIONES.FORM.FORM.OPERARIO_PROCESADO')},
        {id: 'tipoControl', name: t(tKey + 'COLUMNS.TIPO_CONTROL')}
      ],
      rows: list,
      filtros: <FiltrosListadoSeguimientoPage idElemento={idElemento} idForm={idForm} isPool={isPool} />,
      tablaModal: true,
      idBlockOptions: 1,
      initialValues: {}
    }
    tableListadoSeguimiento.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableListadoSeguimiento.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosListadoSeguimiento
        }
      }
    )

    // Convertir el listado de seguimientos al formato requerido por el chart.
    const data = list.reduce((accumulator, currentValue) => {
      accumulator.labels.push(date_formatter(currentValue.fecha, formaFechaHora, false))
      // accumulator.datasets[0].data.push(round_decimal(currentValue.calidad * 100 / 5))
      // accumulator.datasets[1].data.push(round_decimal(currentValue.aglutinacion * 100 / 3))
      accumulator.datasets[0].data.push(currentValue.formasAnormales)
      if (tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION || tipoAnalisisPredeterminado === tipoAnalisisExtraccionConstants.MAGAVISION_ML) {
        accumulator.datasets[1].data.push(currentValue.porMotilidadTotal)
      } else {
        accumulator.datasets[1].data.push(currentValue.porMotilidad)
      }
      return accumulator
    }, initialChartData(t, tKey, tipoAnalisisPredeterminado))

    return (
      <Modal show={showModalListadoSeguimiento} onHide={closeModalListadoSeguimiento} dialogClassName="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderForm && (
            <ModalFormSeguimientoPage />
          )}
          <SimpleTablePage {...tableListadoSeguimiento} />
          <hr />
          <Row>
            <Col sm={12}>
              <Bar {...(chartListadoSeguimiento(separadorDec, separadorMil))} data={data} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={closeModalListadoSeguimiento}>{t(tKey + 'BUTTONS.CERRAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalListadoSeguimiento
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import { yesNoModal } from '../../actions/common'
import {cerrarModalEnvasadoMultiDosis, guardarModalEnvasadoMultiDosis, prepararMaquinaEnvasado, cancelarMaquinaEnvasado} from '../../actions/envasado/envasado'
import {comprobarStockTrazabilidad, obtenerLotesEscandalloEnvasado, openModalTrazabilidadMaterial, actualizarTrazabilidadMaterial} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import { comboTipoDosis, comboMaquina } from '../../actions/combos/combos'
import ModalEnvasadoMultiDosis from '../../components/envasado/ModalEnvasadoMultiDosis'

export function mapStateToProps (state) {
  return {
    ...state.envasado,
    utilizarLotesPreferidosDelUsuario: state.trazabilidadMaterial.utilizarLotesPreferidosDelUsuario,
    stockInsuficiente: state.trazabilidadMaterial.stockInsuficiente[categoriasEscandallos.ENVASADO],
    formModalEnvasadoMultiDosis: getFormValues('ModalEnvasadoMultiDosis')(state),
    auth: state.auth,
    combos: {
      comboTipoDosis: state.combos.comboTipoDosis,
      comboMaquina: state.combos.comboMaquina
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalEnvasadoMultiDosis,
      guardarModalEnvasadoMultiDosis,
      prepararMaquinaEnvasado,
      cancelarMaquinaEnvasado,
      comprobarStockTrazabilidad,
      obtenerLotesEscandalloEnvasado,
      openModalTrazabilidadMaterial,
      actualizarTrazabilidadMaterial,
      comboTipoDosis,
      comboMaquina,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEnvasadoMultiDosis))
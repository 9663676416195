import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import { required } from '../../util/validationFunctions'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import ModalDuplicarPlaningPage from '../../containers/planing/ModalDuplicarPlaningPage'
import InputCheckBox from '../comun/form/InputCheckBox'
import {dateTimeZones} from '../../util/formatFunctions'

class FiltrosPlaning extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      campoCliente: false,
      fitrosSeleccionados: [],
      fechaAnadir: false,
      fechaAnadirPlannig: '',
      customizacionUSA:false
    }
    this.handleChangePropiedad = this.handleChangePropiedad.bind(this)
  }

  fetchFiltrarPlaning(values){
    if (values.propiedad) {
      this.handleChangePropiedad(values.propiedad)
      if (values.propiedad.value === true) {
        values.idCliente = null
      }
    }

    values.fechaExportacion = null
    this.props.actions.fetchFiltrarPlaning(values)
    if (values.periodo) {
      this.elegirDiasPlaning (values.periodo)
    }
  }

  componentDidMount () {
    (this.props.auth.customizations && this.props.auth.customizations.find((customization) => {return customization=='USA'})) ? (       
      this.setState({customizacionUSA: true})) 
    :(
      this.setState({customizacionUSA: false})
    )  

    // this.props.change('propiedad', { value: true, label: this.props.t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},)
    this.props.change('mostrarPlanning', {value: 1, label: this.props.t('COMUN.COMBOS.DATOS_EN_PLANING.DOSIS')})
    this.props.change('calculoExtraccionesMedia', {value: 3, label: 3+" "+this.props.t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')})
    this.props.change('periodo', {value: 15, label: this.props.t('PLANING.15_DIAS')})
    this.props.actions.comboOperario()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacionPadre()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboCliente()
    this.props.actions.comboNivelGenetico()
    this.props.actions.fetchFiltrarPlaning({mostrarPlanning: {value: 1, label: this.props.t('COMUN.COMBOS.DATOS_EN_PLANING.DOSIS')},
      calculoExtraccionesMedia: {value: 3, label: 3+" "+this.props.t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')}, periodo: {value: 15, label: 15}, 
      })
    //propiedad:{ value: true, label: this.props.t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')}
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && this.props.showModalInicioPlaning === false) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        if (this.props.formSimpleTable.values) {
          const selection = this.props.listPlaning.map((row) => {
            return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
              {
                'check': this.props.formSimpleTable.values['check' + row.idElemento]
              }
            )
          })

          let list = selection.filter(function (item) {
            return item.check === true;
          });
          if (list.length > 0) {
            this.setState({fechaAnadir: true})
          } else {
            this.setState({fechaAnadir: false})
            this.props.change('fecha', null)
            this.setState({fechaAnadirPlannig: ''})
          }
        }
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  elegirDiasPlaning (value) {
    if (value !== null){
      this.props.actions.elegirDiasPlaning(value)
    }
  }

  abrirModalPlaning () {
    let fechaHoy = dateTimeZones(this.props.auth.zonaHorariaString)
    this.props.actions.cargarVerracosPlanificados(fechaHoy)
    this.props.actions.abrirModalPlaning()
  }

  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('idCliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('idCliente', null)
    }
  }

  exportarPlaning () {
    this.props.actions.abrirModalExportarImprimirPlaning('exportar')
  }

  imprimirPlaning () {
    this.props.actions.abrirModalExportarImprimirPlaning('imprimir')
  }
  imprimirEtiquetaZebra(){
    this.props.actions.abrirModalExportarImprimirPlaning('etiquetaZebra')
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarPlaning(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  abrirDuplicarPlaning () {
    this.props.actions.abrirDuplicarPlaning()
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosPlanningPrincipal()
    } else {
      this.props.actions.deSeleccionarTodosPlanningPrincipal()
    }
  }

  anadirSeleccionPlaningPrincipal (val) {
    if (this.props.formSimpleTable) {
      const itemsSeleccion = this.props.listPlaning.map((row) => {
        return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
          {
            'check': this.props.formSimpleTable.values['check' + row.idElemento],
            'idVerraco': row.idVerraco,
            'fecha': dateTimeZones(this.props.auth.zonaHorariaString)
          }
        )
      })

      let list = itemsSeleccion.filter(function (item) {
        return item.check === true;
      });
      if (list.length > 0) {
        this.props.actions.anadirSeleccionPlaningPrincipal(list, val.fecha)
        this.props.change('seleccionar-todo-planing-principal', false)
      }
    }
  }

  eliminarSeleccionPlaningPrincipal (val){
    if (this.props.formSimpleTable) {
      const itemsSeleccion = this.props.listPlaning.map((row) => {
        return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
          {
            'check': this.props.formSimpleTable.values['check' + row.idElemento],
            'idVerraco': row.idVerraco,
            'fecha': dateTimeZones(this.props.auth.zonaHorariaString)
          }
        )
      })

      let list = itemsSeleccion.filter(function (item) {
        return item.check === true;
      });
      if (list.length > 0) {
        this.props.actions.eliminarSeleccionPlaningPrincipal(list, val.fecha)
        this.props.change('seleccionar-todo-planing-principal', false)
      }
    }
  }

  cambiarFechaAñadirPlaning (value) {
    this.setState({fechaAnadirPlannig: value})
  }

  render () {
  const {
    t, handleSubmit, filtros, showModalDuplicarPlaning,
    combos: {
      comboRaza, comboLineaGenetica, comboUbicacionPadre, comboNombreGrupoVerraco, comboConservacion, comboCliente, comboOperario, comboNivelGenetico
    }
  } = this.props

  const comboDiasPlaning = [
    {value: 15, label: this.props.t('PLANING.15_DIAS')},
    {value: 30, label: this.props.t('PLANING.30_DIAS')},
    {value: 45, label: this.props.t('PLANING.45_DIAS')}
  ]

  const comboNumeroDiasSinExtraer = [
    {value: 1, label: 1},{value: 2, label: 2},{value: 3, label: 3},{value: 4, label: 4},{value: 5, label: 5},{value: 6, label: 6},{value: 7, label: 7},{value: 8, label: 8},
    {value: 9, label: 9},{value: 10, label: 10},
    {value: 11, label: 11},{value: 12, label: 12},{value: 13, label: 13},{value: 14, label: 14},{value: 15, label: 15},{value: 16, label: 16},{value: 17, label: 17},{value: 18, label: 18},
    {value: 19, label: 19},{value: 20, label: 20},
    {value: 21, label: 21},{value: 22, label: 22},{value: 23, label: 23},{value: 24, label: 24},{value: 25, label: 25},{value: 26, label: 26},{value: 27, label: 27},{value: 28, label: 28},
    {value: 29, label: 29},{value: 30, label: 30}
  ]

  const comboDatosEnPlaning = [
    {value: 1, label: t('COMUN.COMBOS.DATOS_EN_PLANING.DOSIS')}, {value: 2, label: t('COMUN.COMBOS.DATOS_EN_PLANING.FA')}, {value: 3, label: t('COMUN.COMBOS.DATOS_EN_PLANING.MOTILIDAD')}
  ]
  if(this.props.auth.version>=2 || this.state.customizacionUSA)
  {
    comboDatosEnPlaning.push({value: 4, label: t('COMUN.COMBOS.DATOS_EN_PLANING.DOSIS_TEORICAS')})
  }

  const comboCalculoMedia = [
    {value: 1, label: t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMA_EXTRACCION')},{value: 2, label: 2 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},
    {value: 3, label: 3 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},{value: 4, label: 4 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},
    {value: 5, label: 5 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},{value: 6, label: 6 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},
    {value: 7, label: 7 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},{value: 8, label: 8 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},
    {value: 9, label: 9 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')},{value: 10, label: 10 + " " + t('COMUN.COMBOS.CALCULO_MEDIA.ULTIMAS_EXTRACCIONES')}
  ]

  const comboPropiedad = [
      { value: true, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},
      { value: false, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.ALQUILADO')}
    ]

  let comboTiempoMonta = this.props.combos.comboTiempoMonta
  comboTiempoMonta = comboTiempoMonta.map((tiempoMonta) => {
    return {...tiempoMonta, label: t(tiempoMonta.label)}
  })

  let comboEstadoPlaning = this.props.combos.comboEstadoPlaning
    comboEstadoPlaning = comboEstadoPlaning.map((comboEstado) => {
      return {...comboEstado, label: t(comboEstado.label)}
    })

  return (
      <div>
        {showModalDuplicarPlaning === true ? (<ModalDuplicarPlaningPage/>) : null}
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Col sm={8} style={{borderRight: '1px solid #eee'}}>
              <Field
                colSm={2}
                id="raza"
                name="raza"
                controlLabel={t('PLANING.FILTROS.RAZA')}
                component={InputSelect}
                options={comboRaza}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="ubicacion"
                name="ubicacion"
                controlLabel={t('PLANING.FILTROS.UBICACION')}
                component={InputSelect}
                options={comboUbicacionPadre}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="lineaGenetica"
                name="lineaGenetica"
                controlLabel={t('PLANING.FILTROS.LIN_GENETICA')}
                component={InputSelect}
                options={comboLineaGenetica}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="grupoVerraco"
                name="grupoVerraco"
                controlLabel={t('PLANING.FILTROS.GRUPO_VERRACO')}
                component={InputSelect}
                options={comboNombreGrupoVerraco}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="estado"
                name="estado"
                controlLabel={t('PLANING.FILTROS.ESTADO')}
                component={InputSelect}
                options={comboEstadoPlaning}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="numDiasSinExtraer"
                name="numDiasSinExtraer"
                controlLabel={t('PLANING.FILTROS.NUM_DIAS_SIN_EXTRAER')}
                component={InputSelect}
                options={comboNumeroDiasSinExtraer}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="ebv"
                name="ebv"
                controlLabel={t('PLANING.FILTROS.EBV')}
                component={InputNumerical}
                customClass='nombre-operario'
                numDecimales={0}
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('PLANING.FILTROS.NIVEL_GENETICO')}
                component={InputSelect}
                options={comboNivelGenetico}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="tiempoMonta"
                name="tiempoMonta"
                controlLabel={t('PLANING.FILTROS.T_MONTA')}
                component={InputSelect}
                options={comboTiempoMonta}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="conservacion"
                name="conservacion"
                controlLabel={t('PLANING.FILTROS.CONSERVACION')}
                component={InputSelect}
                options={comboConservacion}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              {/*
              <Field
                colSm={2}
                id="operarioColecta"
                name="operarioColecta"
                controlLabel={t('PLANING.FILTROS.OPERARIO_COLECTA')}
                component={InputSelect}
                options={comboOperario}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />*/}
              <Field
                colSm={2}
                id="propiedad"
                name="propiedad"
                controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PROPIEDAD')}
                component={InputSelect}
                options={comboPropiedad}
                valueKey="value"
                labelKey="label"
                customClass='select-propiedad'
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
              <Field
                colSm={2}
                id="idCliente"
                name="idCliente"
                controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CLIENTE')}
                component={InputSelect}
                options={comboCliente}
                valueKey="value"
                labelKey="label"
                customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
                onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
              />
            </Col>
            <Col sm={4}>
                <Field
                  colSm={4}
                  id="mostrarPlanning"
                  name="mostrarPlanning"
                  controlLabel={t('PLANING.DATOS_PLANING')}
                  component={InputSelect}
                  options={comboDatosEnPlaning}
                  valueKey="value"
                  labelKey="label"
                  customClass='datos-planing'
                  isClearable={false}
                  onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
                />
                <Field
                  colSm={4}
                  id="periodo"
                  name="periodo"
                  controlLabel={t('PLANING.PERIODO')}
                  component={InputSelect}
                  options={comboDiasPlaning}
                  valueKey="value"
                  labelKey="label"
                  customClass='planing'
                  isClearable={false}
                  // onInputChange={(value) => this.elegirDiasPlaning(value)}
                  onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
                />
                <Field
                  colSm={4}
                  id="calculoExtraccionesMedia"
                  name="calculoExtraccionesMedia"
                  controlLabel={t('PLANING.CALCULO_MEDIA')}
                  component={InputSelect}
                  options={comboCalculoMedia}
                  valueKey="value"
                  labelKey="label"
                  customClass='calculo-media'
                  isClearable={false}
                  onInputChange={handleSubmit(this.fetchFiltrarPlaning.bind(this))}
                />
              </Col>
              <div className="aplicar-tabla-filtros">
                <div className="filtros-anadidos">
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.raza && filtros.raza !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.ubicacion && filtros.ubicacion !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.UBICACION') + ': ' + filtros.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.lineaGenetica && filtros.lineaGenetica !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.LIN_GENETICA') + ': ' + filtros.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.grupoVerraco && filtros.grupoVerraco !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.GRUPO_VERRACO') + ': ' + filtros.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.estado && filtros.estado !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.numDiasSinExtraer && filtros.numDiasSinExtraer !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.NUM_DIAS_SIN_EXTRAER') + ': ' + filtros.numDiasSinExtraer.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('numDiasSinExtraer')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.ebv && filtros.ebv !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.EBV') + ': ' + filtros.ebv}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.nivelGenetico && filtros.nivelGenetico !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.NIVEL_GENETICO') + ': ' + filtros.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.tiempoMonta && filtros.tiempoMonta !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.T_MONTA') + ': ' + filtros.tiempoMonta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tiempoMonta')}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.conservacion && filtros.conservacion !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.CONSERVACION') + ': ' + filtros.conservacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('conservacion')}> x </button></span>
                      ) : null
                    )
                  }
                  {/*
                    Object.keys(filtros).length > 0 && (
                      filtros.operarioColecta && filtros.operarioColecta !== null ? (
                        <span className="badge-dark">{t('PLANING.FILTROS.OPERARIO_COLECTA') + ': ' + filtros.operarioColecta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operarioColecta')}> x </button></span>
                      ) : null
                    )
                  */}
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.propiedad && filtros.propiedad !== null ? (
                        <span className="badge-dark">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PROPIEDAD') + ': ' + filtros.propiedad.label}<button type="button" className="quitar-filtro" onClick={() => {this.quitarFiltros('propiedad'); this.quitarFiltros('idCliente')}}> x </button></span>
                      ) : null
                    )
                  }
                  {
                    Object.keys(filtros).length > 0 && (
                      filtros.idCliente && filtros.idCliente !== null ? (
                        <span className="badge-dark">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CLIENTE') + ': ' + filtros.idCliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idCliente')}> x </button></span>
                      ) : null
                    )
                  }
                </div>
              </div>
            </form>
        </div>
        <Row className="acciones-tabla-filtros filtros-planning">
          <Col sm={10}>
          <div className="buttons-flex" style={{display: 'block'}}>
            <div className="button-group buttons-min" style={{paddingTop: '1px'}}>
              <div className="custom-check-w checkbox-planing-principal">
              <Field
                id="seleccionar-todo-planing-principal"
                name="seleccionar-todo-planing-principal"
                colSm={12}
                inline
                component={InputCheckBox}
                onInputChange={(value) => this.intercambiarCheck(value)}
              />
            </div>
            {
              this.state.fechaAnadir === true ? (
                <Field
                  colSm={2}
                  id="fecha"
                  name="fecha"
                  // controlLabel={t('PLANING.INTRODUCE_FECHA_PLANNING') + '*'}
                  component={InputDatePicker}
                  inline={true}
                  onInputChange={(value) => this.cambiarFechaAñadirPlaning(value)}
                />
              ) : null
            }
            {
              this.state.fechaAnadir === true && this.state.fechaAnadirPlannig !== '' ? (
                <button type="button" className="btn btn-primary nuevo boton-anadir-planing" onClick={handleSubmit(this.anadirSeleccionPlaningPrincipal.bind(this))}>
                  <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.ANADIR')}
                </button>
              ) : null
            }
            {
              (this.props.auth.version >= 2 || (this.props.auth.customizations && this.props.auth.customizations.includes("USA"))) && this.state.fechaAnadir === true && this.state.fechaAnadirPlannig !== '' ? (
                <button type="button" className="btn btn-primary nuevo boton-anadir-planing" onClick={handleSubmit(this.eliminarSeleccionPlaningPrincipal.bind(this))}>
                  <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.ELIMINAR')}
                </button>
              ) : null
            }
            <span style={{borderLeft: '1px solid #d1d1d1', marginRight: '15px'}}></span>
              <button
                type="button"
                data-toggle="modal"
                data-target="#new-edit-modal"
                style={{padding: '1px 7px', marginRight: '4px'}}
                className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PLANNING] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PLANNING] > 1) ? () => this.abrirModalPlaning() : undefined}
              >
                {t('PLANING.FILTROS.SUGERENCIAS_EXTRACCION')}
              </button>
              <Button type="button" style={{margin: '3px'}} className="btn btn-icono" onClick={() => this.exportarPlaning()}><Launch />{t('PLANING.MODAL.EXPORTAR')}</Button>
              <Button type="button" style={{margin: '3px'}} className="btn btn-icono" onClick={() => this.imprimirPlaning()}><Print />{t('PLANING.MODAL.IMPRIMIR')}</Button>
              <Button type="button" style={{margin: '3px'}} className="btn btn-icono" onClick={() => this.imprimirEtiquetaZebra()}><Print />{t('PLANING.MODAL.IMPRIMIR_ETIQUETA_ZEBRA')}</Button>
              <Button type="button" style={{margin: '3px'}} className="btn btn-primary nuevo" onClick={() => this.abrirDuplicarPlaning()}>{t('PLANING.MODAL.DUPLICAR')}</Button>
            </div>
          </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosPlaning',
})(FiltrosPlaning))

import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { initialize, change, getFormValues } from 'redux-form';
import i18n from '../../../i18n';
import {downloadDocument} from '../../../util/util'

// Constants
import actionTypes from '../../../constants/actions/planSanitario/controlSemen/controlSemen'

// Actions
import {openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import {
  fetchControlSemenSuccess, openModalListadoControlSemenSuccess, closeModalListadoControlSemenSuccess, abrirModalControlSemen, closeModalControlSemen,
  deSeleccionarTodosModalControlSemen, fetchFiltrarModalInicioSuccess, closeModalInicio, deleteControlSemenSeleccion, recuperarDatosModalControlSemenSuccess
} from '../../../actions/planSanitario/controlSemen/controlSemen'

// Services
import getFiltrarControlSemenListService from '../../../services/planSanitario/controlSemen/getFiltrarControlSemenList'
import getFiltrarModalInicio from '../../../services/planSanitario/controlSemen/getFiltrarModalInicio'
import deleteControlSemenService from '../../../services/planSanitario/controlSemen/deleteControlSemen'
import deleteSeleccionControlSemenService from '../../../services/planSanitario/controlSemen/deleteSeleccionControlSemen'
import getCrearCsvControlSemenService from '../../../services/planSanitario/controlSemen/getCrearCsvControlSemen'

import getImprimirPdfControlSemenService from '../../../services/planSanitario/controlSemen/getImprimirPdfControlSemen'
import submitNuevoControlSemenService from '../../../services/planSanitario/controlSemen/submitNuevoControlSemen'
import getDescargarArchivoControlSemenService from '../../../services/planSanitario/controlSemen/getDescargarArchivoControlSemen'
import etiquetasControlSemen from '../../../services/planSanitario/controlSemen/etiquetasControlSemen';
import getControlSemen from '../../../services/planSanitario/controlSemen/getControlSemen';
import updateControlSemen from '../../../services/planSanitario/controlSemen/updateControlSemen';
// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'


export function * fetchControlSemen ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let mainList = []
    mainList = yield call(getFiltrarControlSemenListService, filtros, auth.token)

    mainList.length > 0 && mainList.forEach((item) => {
      item.tipoControl = (item.tipoControl && item.tipoControl  == 1 ? i18n.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO') : item.tipoControl)
      item.tipoControl = (item.tipoControl && item.tipoControl  == 2 ? i18n.t('COMUN.COMBOS.CONTROL_SEMEN.DIRIGIDO') : item.tipoControl)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 1 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN') : item.tipoDirigido)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 2 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.ENFERMEDAD') : item.tipoDirigido)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 3 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.CONTACTO') : item.tipoDirigido)
      item.resultado = (item.resultado && item.resultado  == 1 ? i18n.t('COMUN.COMBOS.RESULTADO.POSITIVO') : item.resultado)
      item.resultado = (item.resultado && item.resultado  == 2 ? i18n.t('COMUN.COMBOS.RESULTADO.NEGATIVO') : item.resultado)
      item.resultado = (item.resultado && item.resultado  == 3 ? i18n.t('COMUN.COMBOS.RESULTADO.DUDOSO') : item.resultado)      
      return item 
    })

    yield put(fetchControlSemenSuccess(mainList, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchControlSemen () {
  yield takeLatest(actionTypes.FETCH_CONTROL_SEMEN, fetchControlSemen)
}



export function * closeModalListadoControlMorfologico () {
  try {
    let state = yield select(state => state)

    // Si se cierra el modal-listado desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
    // Si se cierra el modal-listado desde el modal de edición de analisis/pool, se actualizara el checkbox de seguimiento.
    if (state.seguimiento.esApartadoPrincipal) {
      yield call(fetchControlSemen, {filtros: state.seguimiento.filtros})
    } else {
      let newSeguimiento = state.seguimiento.list.length > 0
      let oldSeguimiento
      let formName = ''
      if (state.seguimiento.idAnalisis) {
        formName = 'formAnalisisExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      } else if (state.seguimiento.idPool) {
        formName = 'formPoolExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      }

      if (newSeguimiento !== oldSeguimiento) {
        yield put(change(formName, 'seguimiento', newSeguimiento))
      }
    }
    yield put(closeModalListadoControlSemenSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalListadoControlMorfologico () {
  yield takeLatest(actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SEMEN, closeModalListadoControlMorfologico)
}

export function * deleteControlSemen ({row}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteControlSemenService, row.idControlSemen, null)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }


      let state = yield select(state => state)
      yield call(fetchControlSemen, {filtros: state.controlSemen.filtros})
 
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteControlSemen () {
  yield takeLatest(actionTypes.DELETE_CONTROL_SEMEN, deleteControlSemen)
}

export function * deleteControlSemenSeleccionSaga ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const {eliminado} = yield call(deleteSeleccionControlSemenService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosModalControlSemen())
      yield put(initialize('listadoControlMorfologicoTable', {}))
      yield put(initialize('ControlMorfologicoTable', {}))

      
      let state = yield select(state => state)
      yield call(fetchControlSemen, {filtros: state.controlMorfologico.filtros})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionControlSemen () {  
  yield takeLatest(actionTypes.DELETE_SELECCION_CONTROL_SEMEN, deleteControlSemenSeleccionSaga)
}

export function * crearCsvControlSemen ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelControlSemen = yield call(getCrearCsvControlSemenService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelControlSemen.excel)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SEMEN') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvControlSemen () {
  yield takeLatest(actionTypes.CREATE_CSV_CONTROL_SEMEN, crearCsvControlSemen)
}

export function * imprimirPdfControlSemen ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfControl= yield call(getImprimirPdfControlSemenService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfControl.pdf)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SEMEN') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfControlSemen () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CONTROL_SEMEN, imprimirPdfControlSemen)
}



export function * descargarArchivoControlSemen ({controlSemen}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfControl= yield call(getDescargarArchivoControlSemenService, controlSemen, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfControl.archivo)
    let name = datosPdfControl.nombreArchivo
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDescargarArchivoControlSemen () {
  yield takeLatest(actionTypes.DESCARGAR_ARCHIVO_CONTROL_SEMEN, descargarArchivoControlSemen)
}



export function * fetchFiltrarModalInicio ({values}) {
  try {    
    console.log('sagas-fetchFiltrarModalInicio-semen',values)
    const auth = yield select(state => state.auth)
    const list = yield call(getFiltrarModalInicio, values, auth.token)
    yield put(fetchFiltrarModalInicioSuccess(list,values))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarModalInicioControlSemen () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN, fetchFiltrarModalInicio)
}

export function * anadirVerracosModalInicio ({listVerracosAsignados, modalValues, archivos}) {
  try {
    //console.log('sagas-fetchFiltrarModalInicio',listVerracosAsignados)
    const auth = yield select(state => state.auth)
    const filtros = yield select(state => state.form.FiltrosControlSemen.values)
    let body = {
      listVerracosAsignados: listVerracosAsignados,
      modalValues: modalValues,
      archivos: archivos
    }
    const status = yield call(submitNuevoControlSemenService, body, auth.token)
    
    yield call(fetchControlSemen, {filtros})
    
    yield put(closeModalInicio())

    if (status.insertado === true) {
      yield put(openMensajeAlerta('insercion-planing'))
    } else {
      yield put(openMensajeAlerta('no-insercion-planing'))
    }

    // const list = yield call(getFiltrarModalInicio,  {values}, auth.token)
    // yield put(fetchFiltrarModalInicioSuccess(listVerracosAsignados))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchAnadirVerracosModalInicioControlSemen () {
  yield takeLatest(actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SEMEN, anadirVerracosModalInicio)
}


export function * imprimirEtiquetaControlSemen ({filtros}) {   
  console.log("imprimirEtiquetaControlSemen SAGA", filtros) 
   try {
    console.log("imprimirEtiquetaControlSemen devol servicio SAGA")

    const auth = yield select(state => state.auth)
    const etiquetaPdf = yield call(etiquetasControlSemen, filtros, auth.token)
    console.log("imprimirEtiquetaControlSemen devol servicio SAGA",etiquetaPdf) 
           
    let selected_device;
    let devices = [];
    window.BrowserPrint.getDefaultDevice("printer", function (device) {
      selected_device = device;
      devices.push(device);
      window.BrowserPrint.getLocalDevices(function (device_list) {
        for (let i = 0; i < device_list.length; i++) {
          let device = device_list[i];
          if (!selected_device || device.uid != selected_device.uid) {
            devices.push(device);
          }
        }
        selected_device.send(etiquetaPdf.zebra, undefined, undefined)
      },
        function () { console.log("Error getting local devices") }, "printer");
    }, function (error) {
      console.log(error);
    })
  
  } catch (error) {
    console.log(error)
  }  
}

export function * watchimprimirEtiquetaControlSemen () {
  yield takeLatest(actionTypes.IMPRIMIR_ETIQ_CONTROL_SEMEN, imprimirEtiquetaControlSemen)
}




export function * recuperarDatosModalControlSemen ({idControlSemen}) {
  try {
    let registroModificar = yield call(getControlSemen, idControlSemen, null)
    if(registroModificar!=null)
    {
      if (registroModificar.tipoControl && registroModificar.tipoControl  == 1) registroModificar.tipoControl = i18n.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO')
      if (registroModificar.tipoControl && registroModificar.tipoControl  == 2) registroModificar.tipoControl = i18n.t('COMUN.COMBOS.CONTROL_SEMEN.DIRIGIDO')

      if (registroModificar.tipoDirigido == 1) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN')
      if (registroModificar.tipoDirigido == 2) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.ENFERMEDAD')
      if (registroModificar.tipoDirigido == 3) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.CONTACTO')
    }
     yield put(recuperarDatosModalControlSemenSuccess(registroModificar))    

     yield put(abrirModalControlSemen())
     
    if (registroModificar.resultado === 1){
      registroModificar.resultado = {value: 1, label: i18n.t('COMUN.COMBOS.RESULTADO.POSITIVO')}
    } 
    if (registroModificar.resultado === 2){
      registroModificar.resultado = {value: 2, label: i18n.t('COMUN.COMBOS.RESULTADO.NEGATIVO')}
    }
    if (registroModificar.resultado === 3){
      registroModificar.resultado = {value: 3, label: i18n.t('COMUN.COMBOS.RESULTADO.DUDOSO')}
    }

    yield put(initialize('ModalControlSemen', registroModificar)) 
  } catch (error) {

  } 
}

export function * watchRecuperarDatosModalControlSemen () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SEMEN, recuperarDatosModalControlSemen)
}


export function * guardarDetalleControlSemen ({data}) {
  try {
    const auth = yield select(state => state.auth)
    let $resultado = yield call(updateControlSemen, data, auth.token)

    if ($resultado.actualizado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    yield put(closeModalControlSemen())

    if ($resultado.actualizado === true) { 
      const filtros = yield select(state => state.form.FiltrosControlSemen.values)
      yield call(fetchControlSemen, {filtros})  
    }

  } catch (error) {
      console.log("error en saga DEVOLVER" ,error)
  } finally { } 
  
}
export function * watchguardarDetalleControlSemen () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_CONTROL_SEMEN, guardarDetalleControlSemen)
}
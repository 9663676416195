import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {guardarModalMaterialDisponible, closeModalMaterialDisponible} from '../../actions/trazabilidadMaterial/materialDisponible'
import ModalMaterialDisponible from '../../components/trazabilidadMaterial/ModalMaterialDisponible'

export function mapStateToProps (state) {
  return {
    ...state.materialDisponible,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      guardarModalMaterialDisponible,
      closeModalMaterialDisponible
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalMaterialDisponible))
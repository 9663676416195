import React, { Component } from 'react'

class LostSession extends Component {

  componentDidMount() {
    this.props.actions.logout(true)
  }

  render () {
    return null // TODO Aqui se podria mostrar una página intermedia entre la pagina inicial y el login, explicando que se ha perdido la sessión.
  }
}

export default LostSession

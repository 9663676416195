import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import Button from '../comun/button/Button'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import {permisos as permisosConstant} from '../../constants/permisos'
import FiltrosModalInicioNuevoSeguimientoPage from '../../containers/seguimiento/FiltrosModalInicioNuevoSeguimientoPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import {dateTimeZones, date_parser} from '../../util/formatFunctions'


class ModalInicioNuevoSeguimiento extends Component {
   constructor () {
    super()
    this.state = {
      inputSelected: false,
      formValues: {
        fechaSeguimiento: null,
        operarioProcesado: null
      },
      orderList: []
    }
    this.selectRowClickCallback = this.selectRowClickCallback.bind(this);
  }

  componentDidMount () {
    this.props.actions.comboOperarioActivo()

    this.props.initialize({
      fechaSeguimiento: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    })
    this.setState({        
      formValues: {
        fechaSeguimiento: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
      }    
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.filtrosModalNuevoSeguimiento && this.props.filtrosModalNuevoSeguimiento.timestamp && 
      (!prevProps.filtrosModalNuevoSeguimiento.timestamp || this.props.filtrosModalNuevoSeguimiento.timestamp !== prevProps.filtrosModalNuevoSeguimiento.timestamp)) {
        //console.log('Venimos de guardar Magavision - reset ordenList y check')
        this.setState({ orderList: [] })
        //reset checks
        let formSimpleTable = this.props.formSimpleTable
        let list = this.props.listModalNuevoSeguimiento || []
        list.forEach((row) => {
            let idElemento = row.idElemento = row.idAnalisis + '-' + row.tipo + '-' + row.idPool
            formSimpleTable.values['check' + idElemento] = false
        })
        this.props.change('formSimpleTable', formSimpleTable)
    }
  }


  

  closeModalNuevoSeguimiento () {
    this.setState({ orderList: [] })
    this.props.actions.closeModalNuevoSeguimiento()
  }

  intercambiarCheck = (value) => {
    
    this.setState({ inputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracosInicio()
    } else {
      this.props.actions.deSeleccionarTodosVerracosInicio()
    }
  }



  changeValuesModalInicio(values){
    this.setState({        
      formValues: {
        fechaSeguimiento: values.fechaSeguimiento,
        operarioProcesado: values.operarioProcesado,
      }    
    })
  }


  checkOrder(val,row){
    let orderList = this.state.orderList
    if(val){
      //add
      orderList.push(row.idElemento)
    }else{
      //delete
      orderList = orderList.filter(x => x != row.idElemento)
    }
    this.setState({orderList: orderList})

    //marcar el check    
    let formSimpleTable = this.props.formSimpleTable
    formSimpleTable.values['check' + row.idElemento] = val
    this.props.change('formSimpleTable', formSimpleTable)

  }

  selectRowClickCallback(row, idRow){
    let val = row.orden == null
    this.checkOrder(val,row)
  }

  render () {
    const {
      t, handleSubmit, showModalNuevoSeguimiento, checkedFiltrosListado, listModalNuevoSeguimiento, checkedVerracosAnadidos, numeroRegistrosPorPagina, paginaActual,
      actions:{openModalFormSeguimiento, deleteSeguimiento}
    } = this.props

    let comboOperarioProcesado = this.props.combos.comboOperarioActivo.filter(item => item.procesado===true)

    let list = listModalNuevoSeguimiento || []


    // TABLA VERRACO
    list.forEach(
      (row) => row.idElemento = row.idAnalisis + '-' + row.tipo + '-' + row.idPool
    )

    list.forEach(row => row.orden = null )
    this.state.orderList.forEach(x => {
      list.forEach(row => row.orden = (row.idElemento == x)? this.state.orderList.indexOf(x)+1 : (row.orden!=null)?row.orden:null )
    })

    const tableInicio = {
      id: 'tableInicio',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      hasPagination: true,
      noBlockOptions: false,
      mostrarModificar: true,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      onDelete: (row) => deleteSeguimiento(row),
      mostrarCambiarEyaculadoEliminado: false,
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      onClickCallback: this.selectRowClickCallback,
      onEdit: (row) => openModalFormSeguimiento(row.idSeguimiento, false, !!row.idPool, row.idAnalisis, row.idPool),
      checkOrder: (val,row) => this.checkOrder(val,row),
      columns: [
        {id: 'orden', name: t('ANALISIS_EXTRACCIONES.COLUMNS.ORDEN')},
        {id: 'nombre', name: t('ALBARANES.MODAL.COLUMNS.VERRACO_POOL')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'seguimiento', name: t('ANALISIS_EXTRACCIONES.COLUMNS.SEGUIMIENTO')}
      ],
      filtros:<FiltrosModalInicioNuevoSeguimientoPage listVerracos={list} modalValues={this.state.formValues} />,
      rows: list,
      leyenda: [
        {name: t("ANALISIS_EXTRACCIONES.LEYENDA.PERTENECE_POOL"), color: '#2538aa'}
      ],
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo == 2){
          classRow = 'analisis-pertenece-pool'
        }
        return classRow
      },
      rowsVerraco: true,
      initialValues: {}
    }

    tableInicio.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableInicio.initialValues['check' + row.idElemento] = checkedVerracosAnadidos || checkedFiltrosListado
        }
      }
    )

  return (
      <Modal show={showModalNuevoSeguimiento} onHide={() => this.closeModalNuevoSeguimiento()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
              <span className='control-calidad-filtro-periodo'>
                {t('MENU.EXTRACCIONES.SEGUIMIENTO')} - Magavision
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix"></div>
            <Row>
              {/*<Field
                  colSm={3}
                  id="fechaSeguimiento"
                  name="fechaSeguimiento"
                  controlLabel={t('PLAN_SANITARIO.CONTROL_SANGRE.COLUMNS.FECHA')}
                  component={InputDatePicker}
                  isClearable={false}
                  onInputChange={handleSubmit(this.changeValuesModalInicio.bind(this))}
              />*/}
                <Field
                  colSm={3}
                  id="operarioProcesado"
                  name="operarioProcesado"
                  controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.OPERARIO_PROCESADO')}
                  component={InputSelect}
                  options={comboOperarioProcesado}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={handleSubmit(this.changeValuesModalInicio.bind(this))}
                />
            </Row>
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-verracos">
              <SimpleTablePage {...tableInicio}></SimpleTablePage>
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalNuevoSeguimiento()}>{t('PLANING.MODAL_INICIO.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalInicioNuevoSeguimiento',
})(ModalInicioNuevoSeguimiento))

import React, {Component} from 'react'
import {Col, ControlLabel} from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Select from './Select'
import './InputSelect.scss'

class InputSelect extends Component {
  constructor (props) {
    super()
    this.state = {
      value: props.input.value
    }
  }

  handleChange (value) {
    this.setState({
      value: value
    }, () => {
      this.props.input.onChange(value)
      if (this.props.onInputChange) {
        setTimeout((() => {
          this.props.onInputChange(value)
        }), 200)
      }
    })
  }

  handleClick (e) {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  componentDidMount() {
    if (this.props.noPredeterminado !== false){
      this.asignarPredeterminado()
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.props.input.value && this.props.noPredeterminado !== false){
      this.asignarPredeterminado()
    } else if (this.props.input.value && prevProps.input.value && this.props.input.value[this.props.valueKey] !== prevProps.input.value[this.props.valueKey]) {
      if (this.props.input.value && this.props.input.value[this.props.valueKey] !== this.state.value[this.props.valueKey]) {
        this.setState({
          value: this.props.input.value
        }, () => {
          if (this.props.onInputChange) {
            setTimeout((() => {
              this.props.onInputChange(this.props.input.value)
            }), 200)
          }
        })
      }
    }
  }

  asignarPredeterminado() {
    if (!this.props.input.value && this.props.options && this.props.options.length) {
      let predeterminado = this.props.input.value
      if (this.props.options && this.props.options.length) {
        for (let i = 0; i < this.props.options.length; i++) {
          if (this.props.options[i].predeterminado) {
            predeterminado = this.props.options[i]
            break
          }
        }
      }
      if (predeterminado && this.props.input.value[this.props.valueKey] !== predeterminado[this.props.valueKey]) {
        this.setState({
          value: predeterminado
        })
        this.props.input.onChange(predeterminado)
        if (this.props.onInputChange) {
          setTimeout((() => {
            this.props.onInputChange(predeterminado)
          }), 200)
        }
      }
    }
  }

  render () {
    const {
      id, colSm = 3, labelKey = 'label', valueKey = 'value', options, meta, input, customClass = '', menuContainerStyle = {},
      placeholder, disabled = false, controlLabel, multi, paddingLeft, isSmall = false, isClearable = true, setMyRef,
      menuPlacement = "auto", helpBlockText, inline = false
    } = this.props
    const errorMessage = (meta.touched && meta.error && typeof meta.error !== 'boolean') ? meta.error : null
    return (
      <Col sm={colSm} className={`${meta.touched && meta.error ? 'has-error' : ''} ${customClass} ${inline ? 'select-inline' : ''} `} style={paddingLeft}>
        <div className="form-group">
          {controlLabel && <ControlLabel>{controlLabel}{errorMessage && <span className='help-block'><Warning /></span>}</ControlLabel>}
          <Select
            id={id}
            menuContainerStyle={menuContainerStyle}
            disabled={disabled}
            placeholder={placeholder}
            title={controlLabel}
            options={options && options.constructor === Array ? options : []}
            valueKey={valueKey}
            labelKey={labelKey}
            value={input.value}
            onInputChange={this.handleChange.bind(this)}
            onClick={this.handleClick.bind(this)}
            multi={multi}
            searchable
            isClearable={isClearable}
            isSmall={isSmall}
            menuPlacement={menuPlacement}
            setMyRef={setMyRef}
          />
          {
            helpBlockText && <span className='help-block light'>{helpBlockText}</span>
          }
        </div>
      </Col>
    )
  }
}

export default InputSelect

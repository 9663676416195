export default {
  ABRIR_MODAL_PERFIL: 'ABRIR_MODAL_PERFIL',
  CERRAR_MODAL_PERFIL: 'CERRAR_MODAL_PERFIL',
  EDITAR_PERFIL: 'EDITAR_PERFIL',
  FETCH_PERFIL: 'FETCH_PERFIL',
  FETCH_PERFIL_SUCCESS: 'FETCH_PERFIL_SUCCESS',
  ABRIR_CAMBIAR_PASS_PERFIL: 'ABRIR_CAMBIAR_PASS_PERFIL',
  CERRAR_CAMBIAR_PASS_PERFIL: 'CERRAR_CAMBIAR_PASS_PERFIL',
  CAMBIAR_PASSWORD_PERFIL: 'CAMBIAR_PASSWORD_PERFIL',
  CAMBIAR_PASSWORD_PERFIL_SUCCESS: 'CAMBIAR_PASSWORD_PERFIL_SUCCESS'
}
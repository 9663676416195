import React, { Component } from 'react'
import { reduxForm, Fields, Field } from 'redux-form'
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import ListadoPage from '../../containers/page/ListadoPage'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'
import InputText from '../comun/form/InputText'
import CircularProgress from '@material-ui/core/CircularProgress'

class PICTraq extends Component {
  constructor() {
    super()
    this.state = {
      fechaAnalisisDesde: '',
      fechaAnalisisHasta: '',
      fechaAnalisisDesdePool: '',
      fechaAnalisisHastaPool: '',
      customColumns: [],
      listTraits: [],
      stackErrores: [],
      importarAnalisisAPIDisabled: true,
      importarPoolAPIDisabled: true,
      importarSplitsAPIDisabled: true
    }
    this.cambiarFechaAnalisis = this.cambiarFechaAnalisis.bind(this);
  }

  cambiarFechaAnalisis(values) {    
    this.setState({ fechaAnalisisDesde: values.desde, fechaAnalisisHasta: values.hasta, fechaAnalisisDesdePool:values.desdePool, fechaAnalisisHastaPool:values.hastaPool })
    this.setState({ importarAnalisisAPIDisabled: true, importarPoolAPIDisabled: true, importarSplitsAPIDisabled: true })
  }
  
  componentDidMount() {
    document.title = this.props.t('PICTRAQ.TITLE') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.resetAnalisisPIC();
    this.props.actions.getUrlPIC();
    let filtros = {};
    filtros = { desde: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora), desdePool: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)}   
    this.setState({ fechaAnalisisDesde: filtros.desde, fechaAnalisisDesdePool:filtros.desdePool })
    this.props.initialize(filtros);             
    this.props.change('fechaIndex', filtros.desde)
  }

  cargarAnalisis() {
    this.props.actions.fetchAnalisisParaPIC({desde:this.state.fechaAnalisisDesde,hasta:this.state.fechaAnalisisHasta})
  }

  insertAnalisisPICAPI() {
    if (this.props.listAnalisis.length > 0) this.props.actions.insertAnalisisPIC(this.props.listAnalisis, this.props.listTraits)
  }

  saveAnalisisPICAPI(){
    if (this.props.listAnalisis.length > 0) this.props.actions.saveDataPIC()
  }

  sincronizarIndexPIC(){
    this.props.actions.sincronizarIndexPIC()
  }
  
  cargarBatch(){
    this.props.actions.fetchPoolSplitsParaPIC({desde:this.state.fechaAnalisisDesdePool,hasta:this.state.fechaAnalisisHastaPool})
    this.setState({ importarPoolAPIDisabled: true })
  }

  insertPoolPICAPI() {
    if (this.props.listPool.length > 0) this.props.actions.insertPoolPIC({'listPool':{...this.props.listPool}})
  }
  savePoolPICAPI(){
    if (this.props.listPool.length > 0) this.props.actions.saveDataPoolPIC()
  }

  insertSplitsPICAPI() {
    if (this.props.listSplits.length > 0) this.props.actions.insertSplitsPIC({'listSplits':{...this.props.listSplits}})
  }
  saveSplitsPICAPI(){
    if (this.props.listSplits.length > 0) this.props.actions.saveDataSplitsPIC()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.listTraits !== prevProps.listTraits || this.props.listAnalisis !== prevProps.listAnalisis) {
      this.setCustomColumns(this.props.listTraits);
      if(this.props.listAnalisis && this.props.listAnalisis.length > 0) this.setState({ importarAnalisisAPIDisabled: false })
    }
    // responseInsertPool: false,
    // responseSavePool: null,
    // responseInsertSplits: false,
    // responseSaveSplits: null
    if (this.props.listSplits !== prevProps.listSplits) {
      if(this.props.listSplits && this.props.listSplits.length > 0) this.setState({ importarSplitsAPIDisabled: false })
    }
    if (this.props.responseSaveSplits !== prevProps.responseSaveSplits && this.props.responseSaveSplits !== null) {
      if(this.props.listPool && this.props.listPool.length > 0){
        this.setState({ importarPoolAPIDisabled: false })
      }
    }
  }

  setCustomColumns(listTraits) {
    let customColumns = [];
    for (let trait of listTraits) {
      customColumns.push({ id: trait.TRAIT_NAME, name: trait.TRAIT_NAME })
    }
    if(customColumns.length>0) customColumns.push({ id: 'STATUS', name: 'STATUS' })
    this.setState({ customColumns })
  }

  render() {
    const { t, listAnalisis, listVerracosIndex, handleSubmit, listPool ,listSplits, isLoading
    } = this.props
    const tKey = 'PICTRAQ.'    
    const table = {
      id: 'PICTraqTable',
      columns: this.state.customColumns,//COLLNUM => "Analisis"."created_at"
      rows: listAnalisis,
      initialValues: {},
      mostrarModificar: false
    }
    const tablePool = {
      id: 'PICTraqTablePool',
      columns: [
        {id: 'BATCHID', name: 'BATCHID'},//"Pool"."created_at"
        {id: 'BATCHNUM', name: 'BATCHNUM'},//"Pool"."codigo"
        {id: 'COL_DATE', name: 'COL_DATE'},
        {id: 'DOSES_POSSIBLE', name: 'DOSES_POSSIBLE'},
        {id: 'DOSES_POURED', name: 'DOSES_POURED'},
        {id: 'STATUS', name: 'STATUS'},
      ],
      rows: listPool,
      initialValues: {},
      mostrarModificar: false
    }    
    const tableSplit = {
      id: 'PICTraqTableSplit',
      columns: [
        // {id: 'BATCHID', name: 'BATCHID'},//"Pool"."created_at"
        {id: 'SPLITNUM', name: 'SPLITNUM'},//"Dilucion"."created_at"
        {id: 'COLLNUM', name: 'COLLNUM'},//"Analisis"."created_at"
        {id: 'BATCHNUM', name: 'BATCHNUM'},//"Pool"."codigo"
        {id: 'DOSES', name: 'DOSES'},
        {id: 'STATUS', name: 'STATUS'},
   
      ],
      rows: listSplits,
      initialValues: {},
      mostrarModificar: false
    }

    const tableIndex  = {
      id: 'PICTraqTableIndex',
      hasPagination:false,
      columns: [
        {id: 'PED_IDENT', name: 'PED_IDENT'},
        {id: 'TATTOO', name: 'TATTOO'},
        {id: 'STIG', name: 'STIG'},
        {id: 'INDEX', name: 'INDEX'},
        {id: 'INDEX_DAY', name: 'INDEX_DAY'},
        {id: 'STATUS', name: 'STATUS'},
      ],
      rows: listVerracosIndex,
      initialValues: {},
      mostrarModificar: false
    }
    if(!this.props.auth.farmId){
      return (
        <div>
          <h2 className="page-title">{t(tKey + 'TITLE')}  -  {t(tKey + 'ERROR_NO_FARMID')}</h2>
        </div>
      )
    }

    return (
      <div>
        <h2 className="page-title">{t(tKey + 'TITLE')}  -  {t(tKey + 'FARM_ID')}: {this.props.auth.farmId}</h2>
        <div className="clearfix"></div>
        {isLoading &&
          <div className="loading-screen-pictraq">
            <CircularProgress />
          </div>}
        <form className="custom-form">        
          <Tabs defaultActiveKey={1} id="tab-panel-control" className="tab-panel-control">
            <Tab eventKey={1} title={t(tKey + 'ANALISIS')}>
              <Row>
                  <Fields
                    names={['desde', 'hasta']}
                    component={InputRangeDatePickerDynamicNames}
                    colSm={3}
                    nameFrom="desde"
                    nameTo="hasta"
                    controlLabel={t(tKey + 'FILTROS.PERIODO')}
                    onInputChange={handleSubmit(this.cambiarFechaAnalisis)}
                  />
                <Col sm={3}>
                  <Button type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.cargarAnalisis()}>{t(tKey + 'BUTTONS.CARGAR_ANALISIS')}</Button>                
                  <Button disabled={this.state.importarAnalisisAPIDisabled} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.insertAnalisisPICAPI()}>{t(tKey + 'BUTTONS.IMPORTAR_PIC')}</Button>
                  <Button disabled={!this.props.responseInsert} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.saveAnalisisPICAPI()}>{t(tKey + 'BUTTONS.GUARDAR_PIC')}</Button>
                </Col>
                <Col sm={6}>
                  <label className="control-label">{this.props.url != null && this.props.url.url != null ? this.props.url.url.replace('/API','') : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_PICTRAQ')}: {this.props.responseSave != null ? this.props.responseSave.response.message : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_NUM_SAVED')}: {this.props.responseSave != null ? this.props.responseSave.response.data[0].NUM_SAVED : ''}</label>
                </Col>
              </Row>
              <ListadoPage t_key={'ANALISIS_EXTRACCIONES.'} table={table}></ListadoPage>
            </Tab>
            <Tab eventKey={2} title={t(tKey + 'INDEX')}>
              <Row>
                <Col sm={2}>
                  {/*
                  <Field
                    colSm={9}
                    id="fechaIndex"
                    name="fechaIndex"
                    component={InputText}
                    controlLabel={t(tKey + 'FILTROS.FECHA')}
                    disabled={true}
                  />
                  */}
                </Col>
                <Col sm={2}>
                  <Button type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.sincronizarIndexPIC()}>{t(tKey + 'BUTTONS.SINCRONIZAR_INDEX')}</Button>                
                </Col>
                <Col sm={8}>
                  <label className="control-label">{this.props.url != null && this.props.url.url != null ? this.props.url.url.replace('/API','') : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_NUM_SAVED')}: {this.props.updatedIndex != null ? this.props.updatedIndex : ''}</label>
                </Col>
              </Row>
              <ListadoPage t_key={'FICHA_VERRACO.'} table={tableIndex}></ListadoPage>
            </Tab>
            <Tab eventKey={3} title={t(tKey + 'POOL.TITLE')}>
              <Row>
                  <Fields
                    names={['desdePool', 'hastaPool']}
                    component={InputRangeDatePickerDynamicNames}
                    colSm={3}
                    nameFrom="desdePool"
                    nameTo="hastaPool"
                    controlLabel={t(tKey + 'FILTROS.PERIODO')}                    
                    onInputChange={handleSubmit(this.cambiarFechaAnalisis)}                    
                  />
                <Col sm={3}>
                  <Button type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.cargarBatch()}>{t(tKey + 'BUTTONS.CARGAR_POOL')}</Button>                
                  <span className="float-right">
                    <Button disabled={this.state.importarSplitsAPIDisabled} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.insertSplitsPICAPI()}>{t(tKey + 'BUTTONS.IMPORTAR_SPLITS_PIC')}</Button>
                    <Button disabled={!this.props.responseInsertSplits} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.saveSplitsPICAPI()}>{t(tKey + 'BUTTONS.GUARDAR_SPLITS_PIC')}</Button>
                  </span>
                  <div className="clearfix"></div>
                  <span className="float-right">
                    <Button disabled={this.state.importarPoolAPIDisabled} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.insertPoolPICAPI()}>{t(tKey + 'BUTTONS.IMPORTAR_POOL_PIC')}</Button>
                    <Button disabled={!this.props.responseInsertPool} type="button" className="btn btn-primary nuevo" style={{ marginTop: '25px' }} onClick={() => this.savePoolPICAPI()}>{t(tKey + 'BUTTONS.GUARDAR_POOL_PIC')}</Button>
                  </span>
                </Col>
                <Col sm={3}>
                  <label className="control-label">{this.props.url != null && this.props.url.url != null ? this.props.url.url.replace('/API','') : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'POOL.TITLE')}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_PICTRAQ')}: {this.props.responseSavePool != null ? this.props.responseSavePool.message : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_NUM_SAVED')}: {this.props.responseSavePool != null ? this.props.responseSavePool.data[0].NUM_SAVED : ''}</label>
                </Col>
                <Col sm={3}>
                  <label className="control-label">{this.props.url != null && this.props.url.url != null ? this.props.url.url.replace('/API','') : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'SPLITS.TITLE')}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_PICTRAQ')}: {this.props.responseSaveSplits != null ? this.props.responseSaveSplits.message : ''}</label>
                  <div className="clearfix"></div>
                  <label className="control-label">{t(tKey + 'MSG_NUM_SAVED')}: {this.props.responseSaveSplits != null ? this.props.responseSaveSplits.data[0].NUM_SAVED : ''}</label>
                </Col>
              </Row>
              <Row> 
                <Col sm={8}>
                  <ListadoPage t_key={tKey+'POOL.'} table={tablePool}></ListadoPage>                  
                </Col>
                <Col sm={4}>
                  <ListadoPage t_key={tKey+'SPLITS.'} table={tableSplit}></ListadoPage>
                </Col>
              </Row>
              
              
            </Tab>
          </Tabs>
        </form>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formPICTraq',
})(PICTraq))
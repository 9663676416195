import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {permisos as permisosConstant} from '../../constants/permisos'
import { required } from '../../util/validationFunctions'

class ModalComboCausaEliminacion extends Component {
  
  submitNuevaModalComboCausaEliminacion (values) {
    if (this.props.data){
      this.props.actions.editarComboCausaEliminacion(values)
    } else {
      this.props.actions.submitNuevaComboCausaEliminacion(values)
    }
  }

  render () {
    const {
      t, handleSubmit, showModal, list, data,
      actions: {recuperarDatosModalComboCausaEliminacion, closeModal}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-operario">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('COMBO_CAUSA_ELIMINACION.MODAL.TITULO_EDITAR')
            ) : (
              t('COMBO_CAUSA_ELIMINACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idOperario && (
            <ButtonChangeRecord list={list} idElemento="id" currentId={data.id} getNextRecord={recuperarDatosModalComboCausaEliminacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="text"
              name="text"
              colSm={4}
              component={InputText}
              controlLabel={t('COMBO_CAUSA_ELIMINACION.MODAL.NOMBRE')+'*'}
              maxLength={true}
              validate={required}
              valorMaxLength={50}
            />
            <Field
              id="code"
              name="code"
              colSm={4}
              component={InputText}
              controlLabel={t('COMBO_CAUSA_ELIMINACION.MODAL.CODIGO')}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="activo"
              name="activo"
              colSm={4}
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMBO_CAUSA_ELIMINACION.MODAL.ACTIVO')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaModalComboCausaEliminacion.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VERRACOS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VERRACOS] < 2)}
          >{t('COMBO_CAUSA_ELIMINACION.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModal}>{t('COMBO_CAUSA_ELIMINACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalComboCausaEliminacion',
})(ModalComboCausaEliminacion))
export default {
    CHANGE_LOCALE: 'CHANGE_LOCALE',
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    CUSTOM_MODAL_NOK: 'CUSTOM_MODAL_NOK',
    CUSTOM_MODAL_OK: 'CUSTOM_MODAL_OK',
    OPEN_SIMPLE_MODAL: 'OPEN_SIMPLE_MODAL',
    YES_NO_MODAL: 'YES_NO_MODAL',
    SELECCIONAR_POR_PAGINA: 'SELECCIONAR_POR_PAGINA',
    CAMBIAR_PAGINA: 'CAMBIAR_PAGINA',
    OPEN_MENSAJE_ALERTA: 'OPEN_MENSAJE_ALERTA',
    OPEN_MENSAJE_ALERTA_CUSTOM_TEXT: 'OPEN_MENSAJE_ALERTA_CUSTOM_TEXT',
    CLOSE_MENSAJE_ALERTA: 'CLOSE_MENSAJE_ALERTA',
    SET_LOADING_TRUE:'SET_LOADING_TRUE',
    SET_LOADING_FALSE:'SET_LOADING_FALSE',
    SET_SORT_LIST:'SET_SORT_LIST',
    RELOAD_COMPONENT:'RELOAD_COMPONENT',
    RESET_RELOAD_COMPONENT:'RESET_RELOAD_COMPONENT'
}

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import InputText from '../../comun/form/InputText'
import './ModalInicioControlSemen.scss'
import Dropzone from 'react-dropzone'
import { Modal, Row, Col} from 'react-bootstrap'
import Button from '../../comun/button/Button'
import { Unarchive } from '@material-ui/icons'
import InputSelect from '../../comun/form/InputSelect'
import { required } from '../../../util/validationFunctions'

class ModalControlSemen extends Component {
  constructor () {
    super()
    this.state = {archivos: {}}
  }
  
  guardarDetalleControlSemen(values){
    if (this.props.submitSucceeded === false) {
      values.archivos = this.state.archivos
      this.props.actions.guardarDetalleControlSemen(values)
    }
  }  

  componentDidMount () {
  }
  componentDidUpdate (prevProps) {
    if(this.props.showModalControlSemen != prevProps.showModalControlSemen && this.props.showModalControlSemen == true){
      this.setState({archivos: {}})
    }    
  }
  closeModalControlSemen () {
    this.props.actions.closeModalControlSemen()
  }
  cerrarModal () {
    this.props.actions.closeModalControlSemen()
  }
  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result

        this.props.actions.subirArchivosInforme({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
      }
      reader.readAsDataURL(archivo)
    })
  }
  
  render () {
    const {
      t, handleSubmit, showModalControlSemen, archivo, data, listEntrada, listProducto,
      actions: {},
      combos: { comboResultadoControlSemen }
    } = this.props

    const tKey = 'PLAN_SANITARIO.CONTROL_SEMEN.'

    //Traduccion del combo
    let comboResultadoControlSemenT = comboResultadoControlSemen
    comboResultadoControlSemenT = comboResultadoControlSemenT.map((combo) => {
      return {...combo, label: t(combo.label)}
    }) 

    return (
      <Modal show={showModalControlSemen} onHide={() => this.closeModalControlSemen()} bsSize="small" 
          dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-control-semen">
      <form className="form-controlSemen">       
            
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tKey + 'MODAL.TITULO_EDITAR')}
          </Modal.Title>
        </Modal.Header>            

        <Modal.Body>      
          <Row>
            <Field
                id="nombreVerraco"
                name="nombreVerraco"
                colSm={4}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.VERRACO')}
                disabled={true}
                maxLength={true}
                valorMaxLength={70}
              />
            <Field 
                id="nombreRaza"
                name="nombreRaza"
                colSm={4}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.RAZA')}
                disabled={true}
                maxLength={true}
                valorMaxLength={70}
              />
            <Field 
                id="nombreLinea"
                name="nombreLinea"
                colSm={4}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.LINEA_GENETICA')}
                disabled={true}
                maxLength={true}
                valorMaxLength={70}
              />     
          </Row>
          <Row>
            <Field
                id="tipoControl"
                name="tipoControl"
                colSm={4}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.TIPO_CONTROL')}
                disabled={true}
                maxLength={true}
                valorMaxLength={70}
              />     
            <Field
                id="tipoDirigido"
                name="tipoDirigido"
                colSm={4}
                component={InputText}
                controlLabel={t('PLAN_SANITARIO.CONTROL_SEMEN.COLUMNS.TIPO_DIRIGIDO')}
                disabled={true}
                maxLength={true}
                valorMaxLength={70}
              />       
          </Row>
          <Row>           
            <Field
              colSm={4}
              id="resultado"
              name="resultado"
              controlLabel={t('PLAN_SANITARIO.CONTROL_SANGRE.MODAL.RESULTADO')}
              component={InputSelect}
              options={comboResultadoControlSemenT}
              valueKey="value"
              labelKey="label"
              isSmall={true}       
              noPredeterminado={false}
            />
            <Col sm={8}>
              <div className="archivos">
                {t('PLAN_SANITARIO.CONTROL_SANGRE.MODAL_INICIO.FILE')}  
                <Dropzone
                  accept=".pdf"
                  onDrop={this.onDrop.bind(this)}
                  uploadMultiple={true}
                  maxFiles={null}
                  // maxSize={200000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}  className="custom-file-label">
                      <input {...getInputProps()} />
                      <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                    </div>
                  )}
                </Dropzone>
              </div>
              {
                Object.keys(archivo).length > 0 ? (
                  <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
                ) : null
              }
            </Col>       
          </Row>
          <Row>
            <Col sm={4}>
            </Col>
            <Col sm={8}>
            <div className="archivos">
                {this.props.data.file}
            </div>
            </Col>
            
          </Row>
        </Modal.Body>

        <Modal.Footer>   

          <Button
            onClick={handleSubmit(this.guardarDetalleControlSemen.bind(this))}
            type="submit"
            className="btn btn-primary">
              {t('PLAN_SANITARIO.CONTROL_SANGRE.MODAL.GUARDAR')}
          </Button>
          
          
          
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModal()}>{t('PLAN_SANITARIO.CONTROL_SANGRE.MODAL.CANCELAR')}
          </Button>
        
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalControlSemen',
})(ModalControlSemen))
import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import FiltrosTipoAlimentacionPage from '../../../containers/planSanitario/tipoAlimentacion/FiltrosTipoAlimentacionPage'
import ModalTipoAlimentacionPage from '../../../containers/planSanitario/tipoAlimentacion/ModalTipoAlimentacionPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './TipoAlimentacion.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'

class TipoAlimentacion extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.TIPOS_ALIMENTACION') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchTipoAlimentacion()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      tipoAlimentacion:{list, checkedFiltrosTipoAlimentacion, data, dataDuplicar},
      actions: {recuperarDatosModalTipoAlimentacion, duplicarDatosModalTipoAlimentacion, cambiarEstadoTipoAlimentacion, onDeleteTipoAlimentacion}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idTipoAlimentacion
    )

    const tableTipoAlimentacion = {
      id: 'tipoAlimentacionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1),
      hasOptions: true,
      columns: [
        {id: 'tipoAlimentacion', name: t('PLAN_SANITARIO.TIPO_ALIMENTACION.TIPO_ALIMENTACION')},
        {id: 'observacionesTipoAlimentacion', name: t('PLAN_SANITARIO.TIPO_ALIMENTACION.DESCRIPCION')},
      ],
      rows: list,
      filtros: <FiltrosTipoAlimentacionPage/>,
      onEdit: (row) => recuperarDatosModalTipoAlimentacion(row.idTipoAlimentacion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1,
      duplicar: (row) =>duplicarDatosModalTipoAlimentacion(row.idTipoAlimentacion),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1,
      cambiarEstado: (row) => cambiarEstadoTipoAlimentacion(row.idTipoAlimentacion),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1,
      onDelete: (row) => onDeleteTipoAlimentacion(row.idTipoAlimentacion),
      initialValues: {}
    }
    tableTipoAlimentacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableTipoAlimentacion.initialValues['check' + row.idElemento] = checkedFiltrosTipoAlimentacion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalTipoAlimentacionPage
          duplicar={(row) =>duplicarDatosModalTipoAlimentacion(row.idTipoAlimentacion)}
        />
        <ListadoPage t_key="PLAN_SANITARIO.TIPO_ALIMENTACION." table={tableTipoAlimentacion}>
        </ListadoPage>
      </div>
    )
  }
}

export default TipoAlimentacion
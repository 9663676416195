export default {
  FETCH_AGENDA: 'FETCH_AGENDA',
  FETCH_AGENDA_SUCCESS: 'FETCH_AGENDA_SUCCESS',
  FETCH_TAREAS_DIA: 'FETCH_TAREAS_DIA',
  FETCH_TAREAS_DIA_SUCCESS: 'FETCH_TAREAS_DIA_SUCCESS',
  ABRIR_MODAL_AGENDA: 'ABRIR_MODAL_AGENDA',
  ABRIR_MODAL_AGENDA_SUCCESS: 'ABRIR_MODAL_AGENDA_SUCCESS',
  GUARDAR_MODAL_AGENDA: 'GUARDAR_MODAL_AGENDA',
  GUARDAR_MODAL_AGENDA_SUCCESS: 'GUARDAR_MODAL_AGENDA_SUCCESS',
  CLOSE_MODAL_AGENDA: 'CLOSE_MODAL_AGENDA',
  CLOSE_MODAL_AGENDA_SUCCESS: 'CLOSE_MODAL_AGENDA_SUCCESS',
  ON_DELETE_AGENDA: 'ON_DELETE_AGENDA',
  ON_DELETE_AGENDA_SERIE: 'ON_DELETE_AGENDA_SERIE',
}
import React, { Component } from 'react'
import {Col, Row, Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, Fields} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import './informes.scss'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import ModalRecomendacionesMataderoPage from '../../containers/informes/ModalRecomendacionesMataderoPage'
import {modulos as modulosConstant} from '../../constants/modulos'
import { Launch, Print } from '@material-ui/icons'

class ModalProductividadVerracos extends Component {
  constructor (props) {
    super()
    this.state = {
      campoVolumen: '',
      campoConcentracionTotal: '',
      campoConcentracionUtil: '',
      campoFormasAnormalesTotales: '',
      campoDosisReales: '',
      campoMotilidad: '',
      campoContaminacion: '',
      
      campoRaza: '',
      campoLineaGenetica: '',
      campoEstado: '',
      campoIntervencion: '',
      campoGrupoVerraco: '',
      campoDesde: '',
      campoHasta: ''
    }
  }

  componentDidMount () {
    const values = {
      volumen: this.state.campoVolumen,
      concentracionTotal: this.state.campoConcentracionTotal,
      concentracionUtil: this.state.campoConcentracionUtil,
      porcentajeTotalFormasAnormales: this.state.campoFormasAnormalesTotales,
      dosisReales: this.state.campoDosisReales,
      motilidad: this.state.campoMotilidad,
      contaminacion: this.state.campoContaminacion,
      campoRaza: this.state.campoRaza,
      campoLineaGenetica: this.state.campoLineaGenetica,
      campoEstado: this.state.campoEstado,
      campoIntervencion: this.state.campoIntervencion,
      campoGrupoVerraco: this.state.campoGrupoVerraco,
      campoDesde: this.state.campoDesde,
      campoHasta: this.state.campoHasta
    }
    this.props.actions.obtenerVerracosProductividadExtracciones(values)
    this.props.actions.obtenerRecomendacionesMatadero(values)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalProductividadExtracciones !== prevProps.showModalProductividadExtracciones) {
      const values = []
      if (this.props.filtrosProductividadExtracciones.volumen) {
        this.props.change('volumen', this.props.filtrosProductividadExtracciones.volumen)
        this.setState({campoVolumen: this.props.filtrosProductividadExtracciones.volumen})
        values.volumen = this.props.filtrosProductividadExtracciones.volumen
      } else {
        this.props.change('volumen', null)
      }
      if (this.props.filtrosProductividadExtracciones.concentracionTotal) {
        this.props.change('concentracionTotal', this.props.filtrosProductividadExtracciones.concentracionTotal)
        this.setState({campoConcentracionTotal: this.props.filtrosProductividadExtracciones.concentracionTotal})
        values.concentracionTotal = this.props.filtrosProductividadExtracciones.concentracionTotal
      } else {
        this.props.change('concentracionTotal', null)
      }
      if (this.props.filtrosProductividadExtracciones.concentracionUtil) {
        this.props.change('concentracionUtil', this.props.filtrosProductividadExtracciones.concentracionUtil)
        this.setState({campoConcentracionUtil: this.props.filtrosProductividadExtracciones.concentracionUtil})
        values.concentracionUtil = this.props.filtrosProductividadExtracciones.concentracionUtil
      } else {
        this.props.change('concentracionUtil', null)
      }
      if (this.props.filtrosProductividadExtracciones.fa) {
        this.props.change('porcentajeTotalFormasAnormales', this.props.filtrosProductividadExtracciones.fa)
        this.setState({campoFormasAnormalesTotales: this.props.filtrosProductividadExtracciones.fa})
        values.porcentajeTotalFormasAnormales = this.props.filtrosProductividadExtracciones.fa
      } else {
        this.props.change('porcentajeTotalFormasAnormales', null)
      }
      if (this.props.filtrosProductividadExtracciones.dosisReales) {
        this.props.change('dosisReales', this.props.filtrosProductividadExtracciones.dosisReales)
        this.setState({campoDosisReales: this.props.filtrosProductividadExtracciones.dosisReales})
        values.dosisReales = this.props.filtrosProductividadExtracciones.dosisReales
      } else {
        this.props.change('dosisReales', null)
      }
      if (this.props.filtrosProductividadExtracciones.motilidad) {
        this.props.change('motilidad', this.props.filtrosProductividadExtracciones.motilidad)
        this.setState({campoMotilidad: this.props.filtrosProductividadExtracciones.motilidad})
        values.motilidad = this.props.filtrosProductividadExtracciones.motilidad
      } else {
        this.props.change('motilidad', null)
      }
      if (this.props.filtrosProductividadExtracciones.contaminacion) {
        this.props.change('contaminacion', this.props.filtrosProductividadExtracciones.contaminacion)
        this.setState({campoContaminacion: this.props.filtrosProductividadExtracciones.contaminacion})
        values.contaminacion = this.props.filtrosProductividadExtracciones.contaminacion
      } else {
        this.props.change('contaminacion', null)
      }

      if (this.props.filtrosProductividadExtracciones.desde && this.props.filtrosProductividadExtracciones.desde !== null) {
        this.props.change('desde', this.props.filtrosProductividadExtracciones.desde)
        this.setState({campoDesde: this.props.filtrosProductividadExtracciones.desde})
        values.desde = this.props.filtrosProductividadExtracciones.desde
      } else {
        this.props.change('desde', null)
      }
      if (this.props.filtrosProductividadExtracciones.hasta && this.props.filtrosProductividadExtracciones.hasta !== null) {
        this.props.change('hasta', this.props.filtrosProductividadExtracciones.hasta)
        this.setState({campoHasta: this.props.filtrosProductividadExtracciones.hasta})
        values.hasta = this.props.filtrosProductividadExtracciones.hasta
      } else {
        this.props.change('hasta', null)
      }
      if (this.props.filtrosProductividadExtracciones.raza && this.props.filtrosProductividadExtracciones.raza !== null) {
        this.props.change('raza', this.props.filtrosProductividadExtracciones.raza)
        this.setState({campoRaza: this.props.filtrosProductividadExtracciones.raza})
        values.raza = this.props.filtrosProductividadExtracciones.raza
      } else {
        this.props.change('raza', null)
      }
      if (this.props.filtrosProductividadExtracciones.lineaGenetica && this.props.filtrosProductividadExtracciones.lineaGenetica !== null) {
        this.props.change('lineaGenetica', this.props.filtrosProductividadExtracciones.lineaGenetica)
        this.setState({campoLineaGenetica: this.props.filtrosProductividadExtracciones.lineaGenetica})
        values.lineaGenetica = this.props.filtrosProductividadExtracciones.lineaGenetica
      } else {
        this.props.change('lineaGenetica', null)
      }
      if (this.props.filtrosProductividadExtracciones.estado && this.props.filtrosProductividadExtracciones.estado !== null) {
        this.props.change('estado', this.props.filtrosProductividadExtracciones.estado)
        this.setState({campoEstado: this.props.filtrosProductividadExtracciones.estado})
        values.estado = this.props.filtrosProductividadExtracciones.estado
      } else {
        this.props.change('estado', null)
      }
      if (this.props.filtrosProductividadExtracciones.intervencionSanitaria && this.props.filtrosProductividadExtracciones.intervencionSanitaria !== null) {
        this.props.change('intervencionSanitaria', this.props.filtrosProductividadExtracciones.intervencionSanitaria)
        this.setState({campoIntervencion: this.props.filtrosProductividadExtracciones.intervencionSanitaria})
        values.intervencionSanitaria = this.props.filtrosProductividadExtracciones.intervencionSanitaria
      } else {
        this.props.change('intervencionSanitaria', null)
      }
      if (this.props.filtrosProductividadExtracciones.grupoVerraco && this.props.filtrosProductividadExtracciones.grupoVerraco !== null) {
        this.props.change('grupoVerraco', this.props.filtrosProductividadExtracciones.grupoVerraco)
        this.setState({campoGrupoVerraco: this.props.filtrosProductividadExtracciones.grupoVerraco})
        values.grupoVerraco = this.props.filtrosProductividadExtracciones.grupoVerraco
      } else {
        this.props.change('grupoVerraco', null)
      }
      this.setState({filtrosProductividadExtraccionesVerraco: values})
      this.props.actions.obtenerVerracosProductividadExtracciones(values)
      this.props.actions.obtenerRecomendacionesMatadero(values)
    }
  }

  filtrarModalProductividadVerracos (values) {
    this.setState({filtrosProductividadExtraccionesVerraco: values})
    this.props.actions.obtenerVerracosProductividadExtracciones(values)
    this.props.actions.obtenerRecomendacionesMatadero(values)
  }

  cerrarModalProductividadVerracos () {
    this.props.actions.cerrarModalProductividadVerracos()
  }

  crearCsvInformeProductividadExtraccionesVerraco () {
    const filtros = this.state.filtrosProductividadExtraccionesVerraco
    this.props.actions.crearCsvInformeProductividadExtraccionesVerraco(filtros)
  }
  imprimirPdfInformesProductividadExtraccionesVerraco () {
    const filtros = this.state.filtrosProductividadExtraccionesVerraco
    this.props.actions.imprimirPdfInformesProductividadExtraccionesVerraco(filtros)
  }

  render () {
    const {
      t, handleSubmit, showModalProductividadExtracciones, listVerracosProductividad,
      actions: {abrirModalRecomendacionesMatadero},
      combos: {comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria}
    } = this.props

    let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

    const tableInformeProductividadExtraccionesMalas = {
      id: 'informeProductividadExtraccionesMalasTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      // hasPagination: false,
      noBlockOptions: false,
      // paginarConScroll: true,
      columns: [
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.VERRACO')},
        {id: 'extraccionesBuenas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.EXTRACCIONES_BUENAS')},
        {id: 'extraccionesMalas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.EXTRACCIONES_MALAS')},
        {id: 'porcentajeExtraccionesBuenas', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.POR_EXTRACCIONES_BUENAS'), type: 'numero'},
      ],
      rows: listVerracosProductividad,
      colorearRow: (row) => {
        if (row.productivo === true) {
            return 'productivo'
        } else {
            return 'no-productivo'
        }
      },
      initialValues: {}
    }

    const filtrosParaMatadero = this.state.filtrosProductividadExtraccionesVerraco

    return (
      <Modal show={showModalProductividadExtracciones} onHide={() => this.cerrarModalProductividadVerracos()} backdrop="static" aria-labelledby="contained-modal-title-lg" 
      className="modal-productividad-verracos">
      <form className="form-productividad-verracos">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PRODUCTIVIDAD_VERRACOS')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Col sm={12}></Col>
              <Field
                id="porBuenas"
                name="porBuenas"
                colSm={4}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.BUENAS')}
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
              <Field
                colSm={4}
                id="raza"
                name="raza"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.RAZA')}
                component={InputSelect}
                options={comboRaza}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
              <Field
                colSm={4}
                id="lineaGenetica"
                name="lineaGenetica"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.LINEA_GENETICA')}
                component={InputSelect}
                options={comboLineaGenetica}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
              <Field
                colSm={4}
                id="estado"
                name="estado"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.ESTADO')}
                component={InputSelect}
                options={comboEstadoVerraco}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
              {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                <Field
                  colSm={4}
                  id="intervencionSanitaria"
                  name="intervencionSanitaria"
                  controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA')}
                  component={InputSelect}
                  options={comboTipoIntervencionSanitaria}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-informes'
                  onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
                />
              )}
              <Field
                colSm={4}
                id="grupoVerraco"
                name="grupoVerraco"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.GRUPO')}
                component={InputSelect}
                options={comboNombreGrupoVerraco}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
              <Fields
                names={['desde', 'hasta']}
                component={InputRangeDatePickerDynamicNames}
                colSm={8}
                nameFrom="desde"
                nameTo="hasta"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PERIODO')}
                onInputChange={handleSubmit(this.filtrarModalProductividadVerracos.bind(this))}
              />
            </Col>
            <Col sm={6} style={{borderLeft: '1px solid #d1d1d1', marginBottom: '10px'}}>
              <Col sm={12}><h3>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PARAMETROS_EN_LAS_EXTRACCIONES')}</h3></Col>
              <Field
              id="volumen"
              name="volumen"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.VOLUMEN')}
              disabled={true}
            />
            <Field
              id="concentracionTotal"
              name="concentracionTotal"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONCENTRACION_TOTAL')}
              disabled={true}
            />
            <Field
              id="concentracionUtil"
              name="concentracionUtil"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONCENTRACION_UTIL')}
              disabled={true}
            />
            <Field
              id="porcentajeTotalFormasAnormales"
              name="porcentajeTotalFormasAnormales"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.FORMAS_ANORMALES_TOTALES')}
              disabled={true}
            />
            <Field
              id="dosisReales"
              name="dosisReales"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.DOSIS_REALES')}
              disabled={true}
            />
            <Field
              id="motilidad"
              name="motilidad"
              colSm={4}
              component={InputNumerical}
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.MOTILIDAD')}
              disabled={true}
            />
            <Field
              colSm={4}
              id="contaminacion"
              name="contaminacion"
              controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONTAMINACION')}
              disabled={true}
              component={InputSelect}
            />
            </Col>
          </Row>
          <Row className="acciones-tabla-filtros">
            <Col sm={10}>
              <div className="buttons-flex">
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeProductividadExtraccionesVerraco()}>
                    <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesProductividadExtraccionesVerraco()}>
                    <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={2} className="content-button-filtro">
            </Col>
          </Row>
          <SimpleTablePage {...tableInformeProductividadExtraccionesMalas}></SimpleTablePage>
          <ModalRecomendacionesMataderoPage filtrosParaMatadero={filtrosParaMatadero} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={abrirModalRecomendacionesMatadero}>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.RECOMENDACIONES_MATADERO')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalProductividadVerracos()}>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalProductividadVerracos',
})(ModalProductividadVerracos))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Dilucion from '../../components/dilucion/Dilucion'
import { fetchFiltrarDilucion, fetchDilucionSuccess, abrirDetalles, editarDilucion, onDeleteDilucion } from '../../actions/dilucion/dilucion'

export function mapStateToProps (state) {
  return {
    dilucion: {...state.dilucion},
    combos: {
      comboDiluido: state.combos.comboDiluido
    },
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFiltrarDilucion,
      fetchDilucionSuccess,
      abrirDetalles,
      editarDilucion,
      onDeleteDilucion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dilucion))
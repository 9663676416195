import actionTypes from '../../constants/actions/importarDatos/importarDatos'

export function submitImportarDatos (value) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_DATOS,
    value
  }
}

export function submitImportarDatosSuccess (listImportarDatos) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_DATOS_SUCCESS,
    listImportarDatos
  }
}

export function abrirModalImportarDatos () {
  return {
    type: actionTypes.ABRIR_MODAL_IMPORTAR_DATOS
  }
}

export function cerrarModalImportarDatos () {
  return {
    type: actionTypes.CERRAR_MODAL_IMPORTAR_DATOS
  }
}

export function subirArchivosImportarDatos (archivo) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_IMPORTAR_DATOS,
    archivo
  }
}

export default {
  submitImportarDatos,
  abrirModalImportarDatos,
  cerrarModalImportarDatos,
  subirArchivosImportarDatos,
  submitImportarDatosSuccess
}
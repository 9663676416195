import actionTypes from '../../constants/actions/dashboard/dashboard'

export function fetchDashboard () {
  return {
    type: actionTypes.FETCH_DASHBOARD
  }
}

export function fetchDashboardSuccess (datosDashboard) {
  return {
    type: actionTypes.FETCH_DASHBOARD_SUCCESS,
    datosDashboard
  }
}

export function fetchDashboardProduccion () {
  return {
    type: actionTypes.FETCH_DASHBOARD_PRODUCCION
  }
}

export function fetchDashboardProduccionSuccess (datosDashboardProduccion) {  
  return {
    type: actionTypes.FETCH_DASHBOARD_PRODUCCION_SUCCESS,
    datosDashboardProduccion
  }
}

export default {
  fetchDashboard,
  fetchDashboardSuccess,
  fetchDashboardProduccion,
  fetchDashboardProduccionSuccess
}
import React, { Component } from 'react'
import Alert from './Alert'
import './Alert.scss'

class AlertSuccess extends Component {
  render () {
    const { className } = this.props
    return (
      <Alert className={className} bsStyle="success">
        {this.props.children}
      </Alert>
    )
  }
}

export default AlertSuccess

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'react-bootstrap'
import {ChevronLeft, ChevronRight } from '@material-ui/icons'
import './ButtonChangeRecord.scss'

class ButtonChangeRecord extends Component {
  constructor (props) {
    super()
    this.state = {
      currentId: props.currentId
    }
    this.loadRow = this.loadRow.bind(this)
  }

  loadRow (step) {
    let rowIndex = this.props.list.findIndex((row) => {
      return "" + row[this.props.idElemento] === "" + this.state.currentId
    })

    // Comprobar si el registro ha cambiado

    let nextRow = this.props.list[rowIndex + step] ? this.props.list[rowIndex + step] : (rowIndex + step > this.props.list.length - 1 ? this.props.list[0] : this.props.list[this.props.list.length - 1])
    if (nextRow) {
      this.setState({
        currentId: nextRow[this.props.idElemento]
      }, () => {
        this.props.getNextRecord(this.state.currentId)
      })
    }
  }

  shouldComponentUpdate() {
    return false
  }

  render () {
    return (
      <ButtonGroup bsSize="xsmall" className="record-pagination">
        <Button type="button" className="btn btn-icono" onClick={() => {
          this.loadRow(-1)
        }}><ChevronLeft/></Button>
        <Button type="button" className="btn btn-icono" onClick={() => {
          this.loadRow(1)
        }}><ChevronRight/></Button>
      </ButtonGroup>
    )
  }
}

ButtonChangeRecord.propTypes = {
  list: PropTypes.array.isRequired,
  idElemento: PropTypes.string.isRequired,
  currentId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  getNextRecord: PropTypes.func.isRequired,
}

export default ButtonChangeRecord
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import {fetchPoolExtracciones, initializeFormPoolExtraccion, onDeletePoolExtraccion, fromAsignacionView } from '../../actions/poolExtracciones/poolExtracciones'
import {addAnalisisPool, abrirModalNuevoPoolAsignacion, removeAnalisisPool, createNewPool, recuperarDatosPool, initializeExtraccionesPendientesAsignar, fetchExtraccionesPendientesDeAsignar} from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool'

import AsignacionPoolVerraco from '../../components/asignacionPoolVerraco/AsignacionPoolVerraco'

export function mapStateToProps (state) {
  return {    
    ...state.poolExtracciones,
    idPoolSelected:state.asignacionAnalisisPool.idPool,
    selectedRow:null,
    list:state.poolExtracciones.list,
    asignadosAnalisisList:state.asignacionAnalisisPool.analisis.asignadosAnalisisList,      
    pendientesAsignarList:state.asignacionAnalisisPool.pendientesAsignarList,    
    auth: state.auth,
    isLoading:state.common.isLoading,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,    
    numRowsLoading:0
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchPoolExtracciones,  
      createNewPool,    
      abrirModalNuevoPoolAsignacion,
      initializeExtraccionesPendientesAsignar,  
      fetchExtraccionesPendientesDeAsignar,      
      initializeFormPoolExtraccion,
      recuperarDatosPool,          
      onDeletePoolExtraccion,
      addAnalisisPool,
      removeAnalisisPool,
      fromAsignacionView
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AsignacionPoolVerraco))
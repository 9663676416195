import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './ValidacionMateriaPrima.scss'
import {permisos as permisosConstant} from '../../constants/permisos'
import { dateTimeZones } from '../../util/formatFunctions'

class ModalAnadirVerracoPool extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
      verracoPool: 1
    }
  }

  componentDidMount () {
    this.props.change('verracoPool', {value: 1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')})
    this.setState({verracoPool: 1})
    this.props.actions.obtenerVerracosMateriaPrima(this.props.listVerracosMateriaPrima)
    this.props.change('fecha', dateTimeZones(this.props.auth.zonaHorariaString))
  }

  componentDidUpdate (prevProps) {
    /*if (Object.keys(this.props.dataVerracoPool).length > 0) {
      if (this.props.dataVerracoPool !== prevProps.dataVerracoPool) {
        if (this.props.data.validacionMateriaPrima.avisoStockMinimo === true) {
          this.setState({mostrarEnviarAviso: true})
        } else {
          this.setState({mostrarEnviarAviso: false})
        }
      }
    }*/

    if (Object.keys(this.props.dataVerracoPool).length > 0){
      if (this.props.dataVerracoPool !== prevProps.dataVerracoPool) {
        if (this.props.dataVerracoPool.verraco && this.props.dataVerracoPool.verraco !== null) {
          this.props.change('verraco', {value: this.props.dataVerracoPool.idAnalisis, label: this.props.dataVerracoPool.verraco})
          this.setState({verracoPool: 1})
        } else if (this.props.dataVerracoPool.pool && this.props.dataVerracoPool.pool !== null) {
          this.props.change('pool', {value: this.props.dataVerracoPool.idPool, label: this.props.dataVerracoPool.pool})

          this.setState({verracoPool: 2})
        }
      }
    }

    if (Object.keys(this.props.dataDuplicarVerracoPool).length > 0){
      if (this.props.dataDuplicarVerracoPool !== prevProps.dataDuplicarVerracoPool) {
        if (this.props.dataDuplicarVerracoPool.verraco && this.props.dataDuplicarVerracoPool.verraco !== null) {
          // this.props.change('verraco', {value: this.props.dataDuplicarVerracoPool.idAnalisis, label: this.props.dataDuplicarVerracoPool.verraco})
          this.props.change('verraco', '')
          this.setState({verracoPool: 1})
        } else if (this.props.dataDuplicarVerracoPool.pool && this.props.dataDuplicarVerracoPool.pool !== null) {
          // this.props.change('pool', {value: this.props.dataDuplicarVerracoPool.idPool, label: this.props.dataDuplicarVerracoPool.pool})
          this.props.change('pool', '')
          this.setState({verracoPool: 2})
        }
      }
    }

    if (this.props.listVerracosMateriaPrima !== prevProps.listVerracosMateriaPrima) {
      this.props.actions.obtenerVerracosMateriaPrima(this.props.listVerracosMateriaPrima)
    }
    if (this.props.listPoolMateriaPrima !== prevProps.listPoolMateriaPrima) {
      this.props.actions.obtenerPoolMateriaPrima(this.props.listPoolMateriaPrima)
    }
  }

  cerrarModalVerracoPool () {
    this.props.actions.cerrarModalVerracoPool()
    this.setState({mostrarEnviarAviso: false})
    this.props.initialize(this.props.initialValues)
    this.props.change('verracoPool', {value: 1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')})
    this.setState({verracoPool: 1})
    // this.props.actions.obtenerVerracosMateriaPrima(this.props.listVerracosMateriaPrima)
    this.props.change('fecha', dateTimeZones(this.props.auth.zonaHorariaString))
  }

  submitNuevoVerracoPool (values) {
    if (Object.keys(this.props.dataVerracoPool).length > 0){
      if (values.verracoPool && values.verracoPool.value === 1){
        this.props.actions.editarVerracoMateriaPrima(values)
      } else if (values.verracoPool && values.verracoPool.value === 2){
        this.props.actions.editarPoolMateriaPrima(values)
      }
      this.props.actions.editarVerracoPool(values)
    } else {
      if (values.verracoPool && values.verracoPool.value === 1){
        values.idAnalisis = values.verraco.value
        values.verraco = values.verraco.label
        this.props.actions.anadirVerracoMateriaPrima(values)
      } else if (values.verracoPool && values.verracoPool.value === 2){
        values.idPool = values.pool.value
        values.pool = values.pool.label
        this.props.actions.anadirPoolMateriaPrima(values)
      }
      this.props.actions.submitNuevoVerracoPool(values)
    }
    this.cerrarModalVerracoPool()
  }
  guardarYnuevoVerracoPool(values){
    if (values.verracoPool.value === 1){
      values.idAnalisis = values.verraco.value
      values.verraco = values.verraco.label
      this.props.actions.anadirVerracoMateriaPrima(values)
    } else if (values.verracoPool.value === 2){
      values.idPool = values.pool.value
      values.pool = values.pool.label
      this.props.actions.anadirPoolMateriaPrima(values)
    }
    this.props.actions.guardarYnuevoVerracoPool(values)
    this.setState({mostrarEnviarAviso: false})
    this.props.initialize(this.props.initialValues)
    this.props.change('verracoPool', {value: 1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')})
    this.setState({verracoPool: 1})
    this.props.actions.obtenerVerracosMateriaPrima(this.props.listVerracosMateriaPrima)
    this.props.change('fecha', dateTimeZones(this.props.auth.zonaHorariaString))
  }
  cambiarAviso (value) {
    if (value === true) {
      this.setState({mostrarEnviarAviso: true})
    } else {
      this.setState({mostrarEnviarAviso: false})
      this.props.change('usuarioAvisoStockMinimo','')
    }
  }
  seleccionarVerracoPool (value) {
    this.setState({verracoPool: value.value})
    if (value.value === 1) {
      this.props.actions.obtenerVerracosMateriaPrima(this.props.listVerracosMateriaPrima)
    } else if (value.value === 2) {
      this.props.actions.obtenerPoolMateriaPrima(this.props.listPoolMateriaPrima)
    }
  }

  seleccionarVerraco (value) {
    this.props.change('fecha', value.fechaExtraccion)
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalVerracoPool, dataVerracoPool, duplicar, numeroRegistrosPorPagina, paginaActual, listVerracoPool, checkedFiltrosVerracoPool,
      actions: {cerrarModalVerracoPool}
    } = this.props

    const comboVerracoPool = [
      {value:1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')},
      {value:2, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.POOL')}
    ]

    return (
      <Modal show={showModalVerracoPool} onHide={() => this.cerrarModalVerracoPool()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-anadir-verraco-o-pool">
      <form className="form-validacionMateriaPrima">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(this.props.dataVerracoPool).length > 0 ? (
              t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_EDITAR')
            ) : (
              Object.keys(this.props.dataDuplicarVerracoPool).length > 0 ? (
                t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_DUPLICAR')
              ) : (
                t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              Object.keys(this.props.dataVerracoPool).length === 0 && (
                <Field
                  colSm={3}
                  id="verracoPool"
                  name="verracoPool"
                  component={InputSelect}
                  options={comboVerracoPool}
                  controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.ELIGE_VERRACO_O_POOL') + '*'}
                  customClass="verracoPool"
                  isClearable={false}
                  onInputChange={(value) => this.seleccionarVerracoPool(value)}
                  validate={required}
                  disabled={Object.keys(this.props.dataDuplicarVerracoPool).length === 0 ? false : true}
                />
              )
            }
            {
              this.state.verracoPool === 1 ? (
                <Field
                  colSm={3}
                  id="verraco"
                  name="verraco"
                  component={InputSelect}
                  options={this.props.comboVerracosMateriaPrima}
                  controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.VERRACO') + '*'}
                  customClass="verraco"
                  isClearable={false}
                  validate={required}
                  onInputChange={(value) => this.seleccionarVerraco(value)}
                />
              ) : ( this.state.verracoPool === 2 ?
                (
                  <Field
                    colSm={3}
                    id="pool"
                    name="pool"
                    component={InputSelect}
                    options={this.props.comboPoolMateriaPrima}
                    controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.POOL') + '*'}
                    customClass="pool"
                    isClearable={false}
                    validate={required}
                  />
                ) : null
              )
            }
            <Field
              colSm={3}
              id="fecha"
              name="fecha"
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.FECHA')}
              component={InputDatePicker}
              disabled={true}
            />
            <div className="clearfix"></div>
            <Field
              colSm={3}
              id="motilidad"
              name="motilidad"
              component={InputNumerical}
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.MOTILIDAD')}
              customClass="motilidad"
            />
            <Field
              colSm={3}
              id="aglutinacion"
              name="aglutinacion"
              component={InputNumerical}
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.AGLUTINACION')}
              customClass="aglutinacion"
            />
            <Field
              colSm={3}
              id="motilidadControl"
              name="motilidadControl"
              component={InputNumerical}
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.MOT_CONTROL')}
              customClass="motilidadControl"
            />
            <Field
              colSm={3}
              id="aglutinacionControl"
              name="aglutinacionControl"
              component={InputNumerical}
              controlLabel={t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.AGLU_CONTROL')}
              customClass="aglutinacionControl"
            />
          </Row>
          <div className="clearfix"></div>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.dataVerracoPool).length > 0 ? (
              <Button type="button" className="btn btn-white" onClick={handleSubmit(duplicar)}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.DUPLICAR')}</Button>
            ) : (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoVerracoPool.bind(this))}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.ACEPTAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={!submitting ? handleSubmit(this.submitNuevoVerracoPool.bind(this)) : null}
            className={'btn btn-primary' + (submitting || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VALIDACION_MATERIAS_PRIMAS] < 2) ? ' disabled' : '')}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalVerracoPool()}>{t('ALMACEN.VALIDACION_MATERIAS_PRIMAS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirVerracoPool',
})(ModalAnadirVerracoPool))
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesClientesPage from '../../containers/informes/FiltrosInformesClientesPage'
import {number_formatter, round_decimal} from '../../util/formatFunctions'
import ModalAlbaranClientePage from '../../containers/informes/ModalAlbaranClientePage'

class InformesClientes extends Component {
  constructor () {
    super()
    this.state = {
      totalesCliente: []
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.CLIENTES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  componentDidUpdate (prevProps) {
    if (this.props.totalesCliente !== prevProps.totalesCliente) {
      this.setState({totalesCliente: this.props.totalesCliente})
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeClientes, totalesCliente, albaranesClientes, checkedFiltrosAlbaran,
      auth: {separadorDec, separadorMil},
      actions: {abrirDetallesAlbaran}
    } = this.props

    const tableInformeClientesIzq = {
      id: 'informeClientesIzqTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      seleccionFilaInformes: true,
      columns: [
        {id: 'razonSocialCliente', name: t('INFORMES.CLIENTES.TABLA.NOMBRE_CLIENTE')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')},
        {id: 'provincia', name: t('INFORMES.CLIENTES.TABLA.PROVINCIA')},
        {id: 'telefono_uno', name: t('INFORMES.CLIENTES.TABLA.TELEFONO')},
        {id: 'movil', name: t('INFORMES.CLIENTES.TABLA.MOVIL')}
      ],
      rows: listInformeClientes,
      filtrosCliente: this.props.filtrosCliente,
      initialValues: {}
    }

    albaranesClientes.forEach(
      (row) => row.idElemento = row.idAlbaran
    )

    const tableInformeClientesDer = {
      id: 'informeClientesDerTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: true,
      paginarConScroll: true,
      hasPagination: false,
      columns: [
        {id: 'numeroAlbaran', name: t('INFORMES.CLIENTES.TABLA.NUM_ALBARAN')},
        {id: 'fechaCreacion', name: t('INFORMES.CLIENTES.TABLA.FECHA'), type: 'fecha'},
        {id: 'pool', name: t('INFORMES.CLIENTES.TABLA.POOL')},
        {id: 'codVerraco', name: t('INFORMES.CLIENTES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.CLIENTES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.CLIENTES.TABLA.LINEA_GENETICA')},
        {id: 'nombreTipoDosis', name: t('INFORMES.CLIENTES.TABLA.TIPO_DOSIS')},
        {id: 'dosisEnviadas', name: t('INFORMES.CLIENTES.TABLA.NUM_DOSIS')}
      ],
      rows: albaranesClientes,
      onEdit: (row) => abrirDetallesAlbaran(row.idAlbaran),
      initialValues: {}
    }
    tableInformeClientesDer.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableInformeClientesDer.initialValues['check' + row.idElemento] = checkedFiltrosAlbaran
        }
      }
    )

    return (
      <div>
        <ModalAlbaranClientePage/>
        <h2 className="page-title">{t('INFORMES.CLIENTES.TITLE')}</h2>
        <Row>
          <Col sm={12}>
            <FiltrosInformesClientesPage />
          </Col>
          <Col sm={6}><span>{}</span>
            <div className="contenedor-tablas-informes-clientes">
              <SimpleTablePage {...tableInformeClientesIzq}></SimpleTablePage>
            </div>
            <Col sm={12} style={{padding: 0}}>
              <Col className="totales-informes" style={{width: '33.33%'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_CLIENTES')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.totalClientes !== undefined ? number_formatter(this.state.totalesCliente.totalClientes, 0, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
              <Col className="totales-informes" style={{width: '33.33%', borderLeft: 'none'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_PROVINCIAS')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.totalProvincias !== undefined ? number_formatter(this.state.totalesCliente.totalProvincias, 0, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
              <Col className="totales-informes" style={{width: '33.33%', borderLeft: 'none'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_GRANJAS')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.totalGranjas !== undefined ? number_formatter(this.state.totalesCliente.totalGranjas, 0, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
            </Col>
          </Col>
          <Col sm={6}><span>{}</span>
            <div className="contenedor-tablas-informes-clientes">
              <SimpleTablePage {...tableInformeClientesDer}></SimpleTablePage>
            </div>
            <Col sm={12} style={{padding: 0}}>
              <Col className="totales-informes" style={{width: '33.33%'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.NUMERO_ALBARANES')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.totalAlbaranes !== undefined ? number_formatter(this.state.totalesCliente.totalAlbaranes, 0, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
              <Col className="totales-informes" style={{width: '33.33%', borderLeft: 'none'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.TOTAL_DOSIS')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.totalDosis !== undefined ? number_formatter(this.state.totalesCliente.totalDosis, 0, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
              <Col className="totales-informes" style={{width: '33.33%', borderLeft: 'none'}}>
                <Col sm={12} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.CLIENTES.TOTALES.MEDIA_DOSIS_POR_ALBARAN')}</span></Col>
                <Col sm={12} className="valores-totales-informes" style={{border: 'none'}}><span>{this.state.totalesCliente.mediaDosis !== undefined ? number_formatter(round_decimal(this.state.totalesCliente.mediaDosis, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil) : 0}</span></Col>
              </Col>
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

export default InformesClientes
import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalInstalacionPage from '../../containers/instalacion/ModalInstalacionPage'
import FiltrosInstalacionPage from '../../containers/instalacion/FiltrosInstalacionPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class Instalacion extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.INSTALACIONES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarInstalacion({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      instalacion: { list, data, dataDuplicar, dataEstado, checkedFiltrosInstalacion, dataDelete},
      actions: {recuperarDatosModalInstalacion, duplicarDatosModalInstalacion, cambiarEstadoInstalacion, onDeleteInstalacion }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const table = {
      id: 'instalacionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1),
      hasOptions: true,
      columns: [
        {id: 'codInstalacion', name: t('INSTALACION.CODIGO')},
        {id: 'nombreInstalacion', name: t('INSTALACION.NOMBRE')},
        {id: 'nombreTipoInstalacion', name: t('INSTALACION.TIPO_UBICACION')},
        {id: 'nombrePadre', name: t('INSTALACION.CONTENIDO_UBICACION')},
        {id: 'verracoUbicacion', name: t('INSTALACION.VERRACO')},
        {id: 'observaciones', name: t('INSTALACION.OBSERVACIONES')}
      ],
      rows: list,
      filtros: <FiltrosInstalacionPage />,
      onEdit: (row) => recuperarDatosModalInstalacion(row.idInstalacion),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1,
      duplicar: (row) =>duplicarDatosModalInstalacion(row.idInstalacion),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1,
      cambiarEstado: (row) => cambiarEstadoInstalacion(row.idInstalacion),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.INSTALACIONES] > 1,
      onDelete: (row) => onDeleteInstalacion(row.idInstalacion),
      dataDelete: dataDelete,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosInstalacion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalInstalacionPage
          duplicar={(row) =>duplicarDatosModalInstalacion(row.idInstalacion)}
        />
        <ListadoPage t_key="INSTALACION." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default Instalacion
import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Row, Col, FormControl, FormGroup, ControlLabel} from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Select from './Select'
import './InputText.scss'

const meridianSelectorOptions = [
  {value: '1', label: 'AM'},
  {value: '2', label: 'PM'}
]

class InputTime extends Component {
  constructor (props) {
    super()
    this.state = {
      twelveHoursFormat: false,
      selectedMeridiam: meridianSelectorOptions[0],
      showedValue: props.value ? '' + props.value : ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMeridian = this.handleChangeMeridian.bind(this);
  }

  isValid (value) {
    if (!/^\d{0,2}:?\d{0,2}$/.test(value)) return false

    let [hh, mm] = value.split(':')

    // Si no tiene valores lo damos por valido, permitiendo que se vacie el input.
    if (!hh && !mm) return true

    hh = parseInt(hh, 10)
    mm = !!mm ? parseInt(mm, 10) : 0
    if (this.state.twelveHoursFormat) {
      return hh >= 0 && hh <= 12 && mm >= 0 && mm <= 59
    } else {
      return hh >= 0 && hh <= 23 && mm >= 0 && mm <= 59
    }
  }

  normalize (value, diferencia = 0, twelveHoursFormat) {
    const minHours = twelveHoursFormat ? 1 : 2
    let [hh, mm] = value.split(':')
    let hhInt = parseInt(hh, 10)
    let mmInt = parseInt(mm, 10)

    if (isNaN(hhInt)) {
      hh = ''
    } else {
      hhInt += diferencia

      if (hhInt === 0 && (hh === '00' || (hh === '00' && diferencia === -12) || (hh === '12' && diferencia === -12))) {
        hh = '00'
      } else if (hh.length > 1 && (hh[0] === '0' || diferencia === -12) && hhInt < 10) {
        hh = '0' + hhInt
      } else {
        hh = '' + hhInt
      }

      if (hh.length === 1 && hhInt > minHours && hhInt < 10) {
        hh = '0' + hh
      }
    }

    if (isNaN(mmInt)) {
      mm = ''
    } else if (mm.length === 1 && mmInt > 5 && mmInt < 10) {
      mm = '0' + mm
    }

    let normalizedValue = hh + (hh.length === 2 ? ':' + mm : '')
    if (normalizedValue.length === 3 && this.props.input.value.length === 3) {
      normalizedValue = normalizedValue.slice(0, -2)
    }
    return normalizedValue
  }

  handleChange (event) {
    if(this.isValid(event.target.value)) {
      let diferencia = 0
      let [hh] = event.target.value.split(':')
      let hhInt = parseInt(hh, 10)

      if (hhInt >= 12 && this.state.twelveHoursFormat && this.state.selectedMeridiam.value === meridianSelectorOptions[0].value) {
        diferencia = -12
      } else if (hhInt < 12 && this.state.twelveHoursFormat && this.state.selectedMeridiam.value === meridianSelectorOptions[1].value) {
        diferencia = 12
      }

      let showedValue = this.normalize(event.target.value, 0, this.state.twelveHoursFormat)
      let normalizedValue = this.normalize(event.target.value, diferencia, this.state.twelveHoursFormat)

      this.setState((state, props) => {
        return {showedValue}
      })

      this.props.input.onChange(normalizedValue)
      if (this.props.onInputChange) {
        this.props.onInputChange(normalizedValue)
      }
    }
  }

  handleChangeMeridian (event) {
    if (this.state.selectedMeridiam.value !== event.value) {
      let diferencia = 0
      let [hh] = this.props.input.value.split(':')
      let hhInt = parseInt(hh, 10)

      if (hhInt >= 12 && this.state.twelveHoursFormat && event.value === meridianSelectorOptions[0].value) {
        diferencia = -12
      } else if (hhInt < 12 && this.state.twelveHoursFormat && event.value === meridianSelectorOptions[1].value) {
        diferencia = 12
      }

      let normalizedValue = this.normalize(this.props.input.value, diferencia, this.state.twelveHoursFormat)

      this.props.input.onChange(normalizedValue)
      if (this.props.onInputChange) {
        this.props.onInputChange(normalizedValue)
      }

      this.setState({
        selectedMeridiam: event
      })
    }
  }

  componentDidMount() {
    let newState = {}
    if (this.props.auth.formaFechaHora && this.props.auth.formaFechaHora.indexOf('12') !== -1) {
      newState.twelveHoursFormat = true
    }
    if (this.props.input.value) {
      let diferencia = 0

      if (newState.twelveHoursFormat || (newState.twelveHoursFormat === undefined && this.state.twelveHoursFormat)) {
        let [hh] = this.props.input.value.split(':')
        let hhInt = parseInt(hh, 10)
        if (hhInt === 0) {
          newState.selectedMeridiam = meridianSelectorOptions[0]
          diferencia = 12
        } else if (hhInt > 0 && hhInt < 12) {
          newState.selectedMeridiam = meridianSelectorOptions[0]
        } else if (hhInt === 12) {
          newState.selectedMeridiam = meridianSelectorOptions[1]
        } else if (hhInt > 12) {
          newState.selectedMeridiam = meridianSelectorOptions[1]
          diferencia = -12
        }
      }
      newState.showedValue = this.props.input.value ? this.normalize('' + this.props.input.value, diferencia, (newState.twelveHoursFormat !== undefined ? newState.twelveHoursFormat : this.state.twelveHoursFormat)) : ''
    }
    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    let newState = {}
    if (this.props.auth.formaFechaHora !== prevProps.auth.formaFechaHora) {
      newState.twelveHoursFormat = this.props.auth.formaFechaHora.indexOf('12') !== -1
    }

    // Unicamente se debería de ejcutar el siguiente código al inicializar el formulario o al modificar el campo
    // mediante la función change de redux-form. (tanto el action creator como la propiedad conectada).
    if (this.props.input.value !== prevProps.input.value && (!this.props.input.value || /^\d{2}:\d{2}$/.test(this.props.input.value)) && !this.props.meta.touched) {
      let diferencia = 0

      if (!!this.props.input.value && (newState.twelveHoursFormat || (newState.twelveHoursFormat === undefined && this.state.twelveHoursFormat))) {
        let [hh] = this.props.input.value.split(':')
        let hhInt = parseInt(hh, 10)
        if (hhInt === 0) {
          newState.selectedMeridiam = meridianSelectorOptions[0]
          diferencia = 12
        } else if (hhInt > 0 && hhInt < 12) {
          newState.selectedMeridiam = meridianSelectorOptions[0]
        } else if (hhInt === 12) {
          newState.selectedMeridiam = meridianSelectorOptions[1]
        } else if (hhInt > 12) {
          newState.selectedMeridiam = meridianSelectorOptions[1]
          diferencia = -12
        }
      }
      newState.showedValue = this.props.input.value ? this.normalize('' + this.props.input.value, diferencia, (newState.twelveHoursFormat !== undefined ? newState.twelveHoursFormat : this.state.twelveHoursFormat)) : ''
    }
    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }
  }

  render () {
    const {
      id, colSm, customClass, disabled = false, placeholder = null, componentClass, controlLabel, isSmall,
      input, meta: { touched, error }
    } = this.props
    const errorMessage = (touched && error) ? error : null

    // Eliminar los segundos
    if (input.value.length > 5) input.value = input.value.slice(0, 5)

    return (
      <Col sm={colSm} className={customClass}>
        <FormGroup validationState={errorMessage ? 'error' : null}>
          {controlLabel && <ControlLabel>{controlLabel}{errorMessage && <span className='help-block'><Warning /></span>}</ControlLabel>}
          <Row>
            <Col sm={this.state.twelveHoursFormat ? 8 : 12}>
              <FormControl
                id={id}
                disabled={disabled}
                type="text"
                placeholder={placeholder || (!disabled ? '00:00' : '')}
                title={controlLabel}
                componentClass={componentClass}
                value={this.state.showedValue}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
            </Col>
            {this.state.twelveHoursFormat && (
              <Col sm={4}  style={{paddingLeft: 0}}>
                <Select
                  id={id + 'MeridianSelector'}
                  disabled={disabled}
                  title={controlLabel}
                  value={this.state.selectedMeridiam}
                  onInputChange={this.handleChangeMeridian}
                  valueKey="value"
                  labelKey="label"
                  isClearable={false}
                  options={meridianSelectorOptions}
                  isSmall={isSmall}
                />
              </Col>
            )}
          </Row>
        </FormGroup>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(InputTime)

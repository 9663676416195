import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalNivelGeneticoPage from '../../containers/nivelGenetico/ModalNivelGeneticoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosNivelGeneticoPage from '../../containers/nivelGenetico/FiltrosNivelGeneticoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import { Row, Col} from 'react-bootstrap'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'

class NivelGenetico extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.NIVEL_GENETICO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchNivelGenetico()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      nivelGenetico: { list, data, dataDelete},
      actions: {recuperarDatosModalNivelGenetico, onDeleteNivelGenetico, abrirModalVacioNivelGenetico}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idNivelGenetico
    )

    const table = {
      id: 'nivelGeneticoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: true,
      columns: [
        {id: 'nombre', name: t('NIVEL_GENETICO.TABLA.NOMBRE')},
        {id: 'prioridad', name: t('NIVEL_GENETICO.TABLA.PRIORIDAD')}
      ],
      rows: list, 
      filtros: <FiltrosNivelGeneticoPage />,         
      onEdit: (row) => recuperarDatosModalNivelGenetico(row.id),
      data: data,      
      mostrarEliminar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      onDelete: (row) => onDeleteNivelGenetico(row.id, row.nombre, row.prioridad),
      dataDelete: dataDelete,      
      initialValues: {}
    }    
    return (
      <div>        
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalNivelGeneticoPage/>
        <ListadoPage t_key="NIVEL_GENETICO." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default NivelGenetico
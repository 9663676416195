import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosModalAlbaranPage from '../../containers/albaran/FiltrosModalAlbaranPage'
import ModalAlbaranDosisPage from '../../containers/albaran/ModalAlbaranDosisPage'
import {estadoAlbaran as estadoAlbaranConstants} from '../../constants/albaran'
import {number_formatter} from '../../util/formatFunctions'
import { required } from '../../util/validationFunctions'
// import './Albaran.scss'
import { RemoveRedEye } from '@material-ui/icons'
import ModalDetallePedidoPage from '../../containers/pedidosVenta/ModalDetallePedidoPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalAlbaranCliente extends Component {
  constructor () {
    super()
    this.state = {
      idPedido: null
    }
  }

  verDetallePedido () {
    this.props.actions.abrirDetallesPedido(this.state.idPedido)
  }

  imprimirDatosAlbaran () {
    this.props.actions.imprimirDatosAlbaran(this.props.data.idAlbaran, this.props.data.numeroAlbaran)
  }

  imprimirDatosAlbaranCliente () {
    this.props.actions.imprimirDatosAlbaranCliente(this.props.data.idAlbaran, this.props.data.numeroAlbaran)
  }

  componentDidUpdate (prevProps) {
    if (this.props.data !== prevProps.data) {
      if (Object.keys(this.props.data).length > 0){
        this.props.data.cliente && this.props.actions.comboDireccionCliente(this.props.data.cliente.value)
        this.props.data.direccionEnvio && this.props.actions.comboTransportistasCliente(this.props.data.direccionEnvio.value)
        this.setState({idPedido: this.props.data.idPedido ? this.props.data.idPedido : null})
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboOperarioPredeterminado()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, handleSubmit, list, showModalAlbaran, data,
      auth: {separadorMil, separadorDec},
      actions: {
        cerrarDetallesAlbaran, deleteModalAlbaranDosis, abrirDetallesAlbaran, abrirDetallesAlbaranDosis
      },
      combos: {
        comboCliente, comboDireccionCliente, comboTransportistasCliente, comboRutaTransportistaCliente,
        comboConductoresTransportistaDeterminado, comboVehiculosTransportistaDeterminado, comboOperarioPredeterminado
      }
    } = this.props
    const idEstado = data.idEstado || estadoAlbaranConstants["BORRADOR"]

    const tKey = 'ALBARANES.MODAL.'
    const idElemento = 'idAlbaranDosis'
    const table = {
      id: 'albaranExtraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      idElemento,
      hasOptions: false,
      noBlockOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarEliminar: false,
      mostrarCambioEstado: false,
      columns: [
        {id: 'verracoPool', name: t(tKey + 'COLUMNS.VERRACO_POOL')},
        {id: 'idVerracoPool', name: t(tKey + 'COLUMNS.EXTRACCION_POOL'), type: 'numero', numDecimales: 0},
        {id: 'nombreRaza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},
        {id: 'nombreTipoDosis', name: t(tKey + 'COLUMNS.TIPO_DOSIS')},
        {id: 'dosisPedidas', name: t(tKey + 'COLUMNS.DOSIS_PEDIDAS'), type: 'numero', numDecimales: 0},
        {id: 'dosisEnviadas', name: t(tKey + 'COLUMNS.DOSIS_ENVIADAS'), type: 'numero', numDecimales: 0},
      ],
      rows: data.dosisAlbaran,
      initialValues: {}
    }

    return (
      <Modal show={showModalAlbaran} onHide={cerrarDetallesAlbaran} dialogClassName="xl" backdrop="static">
        <ModalAlbaranDosisPage />
        <ModalDetallePedidoPage />
        <form className="form-albaran">
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tKey + (data && data.idAlbaran ? 'TITLE_MODIFICAR' : data && data.idAlbaranDuplicado ? 'TITLE_DUPLICAR' : 'TITLE_NUEVO'))}
            </Modal.Title>
            {/*data && data.idAlbaran && (
              <ButtonChangeRecord list={list} idElemento="idAlbaran" currentId={data.idAlbaran} getNextRecord={abrirDetallesAlbaran} />
            )*/}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="numeroAlbaran"
                name="numeroAlbaran"
                controlLabel={t(tKey + 'FORM.NUM_ALBARAN')}
                component={InputText}
                disabled={true}
              />
              {
                this.state.idPedido !== null ? (
                  <Col sm={1} className="ver-pedido-albaran">
                    <Button type="button" className="btn btn-primary" onClick={() => this.verDetallePedido()}><RemoveRedEye/><span>{t(tKey + 'FORM.PEDIDO')}</span></Button>
                  </Col>
               ) : null
              }
              <Field
                colSm={this.state.idPedido !== null ? 2 : 3}
                id="origen"
                name="origen"
                controlLabel={t(tKey + 'FORM.ORIGEN')}
                component={InputText}
                disabled={true}
              />
              <Field
                colSm={3}
                id="fechaCreacion"
                name="fechaCreacion"
                controlLabel={t(tKey + 'FORM.FECHA_CREACION')}
                component={InputDatePicker}
                disabled={true}
              />
              <Field
                colSm={3}
                id="fechaRealizado"
                name="fechaRealizado"
                controlLabel={t(tKey + 'FORM.FECHA_REALIZADO')}
                component={InputDatePicker}
                disabled={true}
              />
            </Row>
            <Row>
              <Field
                colSm={3}
                id="cliente"
                name="cliente"
                controlLabel={t(tKey + 'FORM.CLIENTE') + '*'}
                component={InputSelect}
                options={comboCliente}
                valueKey="value"
                labelKey="label"
                validate={required}
                isClearable={false}
                disabled={true}
              />
              <Field
                colSm={3}
                id="direccionEnvio"
                name="direccionEnvio"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + '*'}
                component={InputSelect}
                options={comboDireccionCliente}
                valueKey="value"
                labelKey="label"
                validate={required}
                isClearable={false}
                disabled={true}
              />
              <Field
                colSm={3}
                id="transporte"
                name="transporte"
                controlLabel={t(tKey + 'FORM.TRANSPORTE')}
                component={InputSelect}
                options={comboTransportistasCliente}
                valueKey="value"
                labelKey="label"
                disabled={true}
              />
              <Field
                colSm={3}
                id="ruta"
                name="ruta"
                controlLabel={t(tKey + 'FORM.RUTA')}
                component={InputSelect}
                options={comboRutaTransportistaCliente}
                valueKey="value"
                labelKey="label"
                disabled={true}
              />
            </Row>
            <Row>
              <Field
                colSm={3}
                id="conductor"
                name="conductor"
                controlLabel={t(tKey + 'FORM.CONDUCTOR')}
                component={InputSelect}
                options={comboConductoresTransportistaDeterminado}
                valueKey="value"
                labelKey="label"
                disabled={true}
              />
              <Field
                colSm={3}
                id="vehiculo"
                name="vehiculo"
                controlLabel={t(tKey + 'FORM.VEHICULO')}
                component={InputSelect}
                options={comboVehiculosTransportistaDeterminado}
                valueKey="value"
                labelKey="label"
                disabled={true}
              />
              <Field
                colSm={3}
                id="operario"
                name="operario"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.OPERARIO')}
                component={InputSelect}
                options={comboOperarioPredeterminado}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                isClearable={false}
                disabled={true}
              />
            </Row>
            <hr />
            <SimpleTablePage {...table} />
            <hr />
            <Row>
              <FormGroupSubtitle colSm={12} small={true}>
                {t(tKey + 'FORM.RESUMEN')}
              </FormGroupSubtitle>
              <Col sm={12}>
                <Row className="tablaTotales" style={{ margin: '0'}}>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.TIPO_DOSIS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_PEDIDAS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_ENVIADAS')}</span>
                  </Col>
                  <Col sm={3} className="total-verracos">
                    <span>{t(tKey + 'FORM.DOSIS_FALTAN_POR_SERVIR')}</span>
                  </Col>
                </Row>
                {data.totalesPorDosis && Object.keys(data.totalesPorDosis).map((key) => {
                  let estado = 0
                  if (data.totalesPorDosis[key].dosisPedidas > 0 && data.totalesPorDosis[key].dosisPorServir > 0) {
                    estado = 'has-error'
                  }
                  return (
                    <Row key={key} className={data.totalesPorDosis[key].tipoDosis === 'total' ? 'tablaTotales resumen-totales' : 'tablaTotales'} style={{margin: '0', borderTop: 'none'}}>
                      <Col sm={3} className="estados-total-verraco" style={{borderLeft: '1px solid #d1d1d1'}}>
                        <span>{data.totalesPorDosis[key].tipoDosis === 'total' ? t(tKey + 'FORM.TOTAL') : data.totalesPorDosis[key].tipoDosis}</span>
                      </Col>
                      <Col sm={3} className="estados-total-verraco">
                        <span>{number_formatter(data.totalesPorDosis[key].dosisPedidas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={3} className="estados-total-verraco">
                        <span>{number_formatter(data.totalesPorDosis[key].dosisEnviadas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={3} className={`estados-total-verraco ${estado} no-bg`}>
                        <span>{number_formatter(data.totalesPorDosis[key].dosisPorServir, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Row>
                  )
                })}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={2}>
                <Row>
                  <Field
                    colSm={12}
                    id="estado"
                    name="estado"
                    controlLabel={t(tKey + 'FORM.ESTADO')}
                    component={InputText}
                    disabled={true}
                  />
                </Row>
              </Col>
              <Field
                colSm={5}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
              <Field
                colSm={5}
                id="observacionesCliente"
                name="observacionesCliente"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES_CLIENTE')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-white" onClick={cerrarDetallesAlbaran}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaranCliente',
})(ModalAlbaranCliente))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalComboObservacionesEyaculadoEliminado from '../../components/comboObservacionesEyaculadoEliminado/ModalComboObservacionesEyaculadoEliminado'
import { recuperarDatosModalComboObservacionesEyaculadoEliminado, submitNuevaComboObservacionesEyaculadoEliminado, editarComboObservacionesEyaculadoEliminado } from '../../actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'
import { closeModal } from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.comboObservacionesEyaculadoEliminado,
    showModal: state.common.showModal,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalComboObservacionesEyaculadoEliminado,
      submitNuevaComboObservacionesEyaculadoEliminado,
      editarComboObservacionesEyaculadoEliminado,
      closeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalComboObservacionesEyaculadoEliminado))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter} from '../../util/formatFunctions'
import ListadoPage from '../../containers/page/ListadoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'

class ModalHistoricoGrupos extends Component {
  render () {
    const {
      t, handleSubmit, showrModalHistoricoGrupos, datosHistoricoGrupoVerraco,
      actions: {cerrarModalHistoricoGrupos}
    } = this.props

    const table = {
      id: 'grupoVerracoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'fechaAsignacion', name: t('GRUPOS_VERRACO.TABLA_VERRACO.FECHA_ASIGNACION'), type: 'fecha'},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: datosHistoricoGrupoVerraco.verraco ? datosHistoricoGrupoVerraco.verraco : [],
      initialValues: {}
    }

    return (
      <Modal show={showrModalHistoricoGrupos} onHide={cerrarModalHistoricoGrupos} aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-historico">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.TITULO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.NOMBRE_GRUPO')}: </b>{datosHistoricoGrupoVerraco.grupoVerraco ? datosHistoricoGrupoVerraco.grupoVerraco.nombreGrupoVerraco : null}</p>
          <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.FECHA_INICIO')}: </b>{datosHistoricoGrupoVerraco.grupoVerraco ? datosHistoricoGrupoVerraco.grupoVerraco.fechaCreacion !== null ? date_formatter(datosHistoricoGrupoVerraco.grupoVerraco.fechaCreacion, this.props.auth.formaFechaHora, false) : '-' : null}</p>
          <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.DESCRIPCION')}: </b>{datosHistoricoGrupoVerraco.grupoVerraco ? datosHistoricoGrupoVerraco.grupoVerraco.descripcionGrupoVerraco : null}</p>
          <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.ESTADO')}: </b>{datosHistoricoGrupoVerraco.grupoVerraco ? datosHistoricoGrupoVerraco.grupoVerraco.activo === true ? t('ESTADO.ACTIVO') : t('ESTADO.INACTIVO') : null}</p>

          <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={table}>
          </ListadoPage>

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalHistoricoGrupos}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalHistoricoGrupos',
})(ModalHistoricoGrupos))
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import { fetchControlSangre, deleteControlSangre, abrirModalInicio, descargarArchivoControlSangre, recuperarDatosModalControlSangre, guardarDetalleControlSangre, subirArchivosInforme } from '../../../actions/planSanitario/controlSangre/controlSangre'
import ControlSangre from '../../../components/planSanitario/controlSangre/ControlSangre'

export function mapStateToProps (state) {
  return {
    ...state.controlSangre,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlSangre,
      deleteControlSangre,
      yesNoModal,
      abrirModalInicio,
      descargarArchivoControlSangre,
      recuperarDatosModalControlSangre,
      guardarDetalleControlSangre,
      subirArchivosInforme
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlSangre))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalAnadirEscandallo from '../../components/escandallo/ModalAnadirEscandallo'
import { comboProductoEscandallo } from '../../actions/combos/combos'
import { closeModalAnadirEscandallo, andadirEscandalloAnalisis, andadirEscandalloDilucion, andadirEscandalloEnvasado, editarEscandalloAnadido } from '../../actions/escandallo/escandallo'

export function mapStateToProps (state) {
  return {
    ...state.escandallo,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    formModalAnadirEscandallo: getFormValues('ModalAnadirEscandallo')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalAnadirEscandallo,
      comboProductoEscandallo,
      andadirEscandalloAnalisis,
      andadirEscandalloDilucion,
      andadirEscandalloEnvasado,
      editarEscandalloAnadido
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAnadirEscandallo))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, DoneAll } from '@material-ui/icons'
import {date_formatter} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosBioseguridadHigiene extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

   componentDidMount() {
    this.props.actions.comboInstalacionesPadreOSinHijos()
    this.props.actions.comboOperario()
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelBioseguridad !== prevProps.datosExcelBioseguridad) {
      if (this.props.datosExcelBioseguridad.excel && this.props.datosExcelBioseguridad.excel !== null){
        let pdf = this.props.datosExcelBioseguridad.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfBioseguridad !== prevProps.datosPdfBioseguridad) {
      if (this.props.datosPdfBioseguridad.pdf && this.props.datosPdfBioseguridad.pdf !== null){
        let pdf = this.props.datosPdfBioseguridad.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosBioseguridadHigiene()
          }
        }
      }
    }
  }

  fetchFiltrarBioseguridadHigiene(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.fetchFiltrarBioseguridadHigiene(values)
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosBioseguridadHigiene()
    } else {
      this.props.actions.deSeleccionarTodosBioseguridadHigiene()
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  deleteSeleccionBioseguridadHigiene(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idBioSeguridad': row.idBioSeguridad,
          'tipoAccion': row.tipoAccion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionBioseguridadHigiene(list)
  }

  crearCsvBioseguridad(){
    this.props.actions.crearCsvBioseguridad(this.props.filtros)
  }

  imprimirPdfBioseguridad(){
    this.props.actions.imprimirPdfBioseguridad(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    if (value === 'fechaEntrada') {
      values.desde = null
    }
    if (value === 'fechaSalida') {
      values.hasta = null
    }
    this.props.actions.fetchFiltrarBioseguridadHigiene(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {abrirModalVacioBioseguridadHigiene},
    combos: {comboOperario, comboInstalacionesPadreOSinHijos}
  } = this.props
  let tKey = 'PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.'

  let comboTipoAccion = this.props.combos.comboTipoAccion
  comboTipoAccion = comboTipoAccion.map((tipoAccionBioseguridadHigiene) => {
    return {...tipoAccionBioseguridadHigiene, label: t(`COMUN.COMBOS.${tipoAccionBioseguridadHigiene.label}`)}
  })

  let comboEstadoAccion = this.props.combos.comboEstadoAccion
  comboEstadoAccion = comboEstadoAccion.map((estadoAccionBioseguridadHigiene) => {
    return {...estadoAccionBioseguridadHigiene, label: t(`COMUN.COMBOS.${estadoAccionBioseguridadHigiene.label}`)}
  })

  return (
      <div>
      <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="tipoAccion"
              name="tipoAccion"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FILTROS.TIPO_ACCION')}
              component={InputSelect}
              options={comboTipoAccion}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarBioseguridadHigiene.bind(this))}
            />
            <Field
              colSm={2}
              id="ubicacion"
              name="ubicacion"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FILTROS.UBICACION')}
              component={InputSelect}
              options={comboInstalacionesPadreOSinHijos}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-raza'
              onInputChange={handleSubmit(this.fetchFiltrarBioseguridadHigiene.bind(this))}
            />
            <Field
              colSm={2}
              id="operario"
              name="operario"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FILTROS.OPERARIO')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-linea-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarBioseguridadHigiene.bind(this))}
            />

            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoAccion}
              valueKey="value"
              labelKey="label"
              customClass='select-combo-grupo-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarBioseguridadHigiene.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.FILTROS.DESDE_HASTA')}
              onInputChange={handleSubmit(this.fetchFiltrarBioseguridadHigiene.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.tipoAccion && filtros.tipoAccion !== null && filtros.tipoAccion !== '') ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.TIPO_ACCION') + ': ' + filtros.tipoAccion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoAccion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.ubicacion && filtros.ubicacion !== null && filtros.ubicacion !== '') ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.UBICACION') + ': ' + filtros.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.operario && filtros.operario !== null && filtros.operario !== '') ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.OPERARIO') + ': ' + filtros.operario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.estado && filtros.estado !== null && filtros.estado !== '') ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                    <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionBioseguridadHigiene()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                   <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                  <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvBioseguridad()}>
                    <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfBioseguridad()}>
                    <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                  </button>
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? abrirModalVacioBioseguridadHigiene : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
                <div
                  className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'}
                  onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                  <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
                </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'bioseguridadHigiene',
  enableReinitialize: true
})(FiltrosBioseguridadHigiene))

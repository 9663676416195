import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import TipoIntervencionSanitaria from '../../../components/planSanitario/tipoIntervencionSanitaria/TipoIntervencionSanitaria'
import {fetchTipoIntervencionSanitaria, recuperarDatosModalTipoIntervencionSanitaria, duplicarDatosModalTipoIntervencionSanitaria, cambiarEstadoTipoIntervencionSanitaria, onDeleteTipoIntervencionSanitaria
  } from '../../../actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'

export function mapStateToProps (state) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    tipoIntervencionSanitaria: state.tipoIntervencionSanitaria,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTipoIntervencionSanitaria,
      recuperarDatosModalTipoIntervencionSanitaria,
      duplicarDatosModalTipoIntervencionSanitaria,
      cambiarEstadoTipoIntervencionSanitaria,
      onDeleteTipoIntervencionSanitaria
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TipoIntervencionSanitaria))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosNivelGenetico extends Component {
   constructor () {
    super()
    this.state = {
    }
  }  

  componentDidUpdate (prevProps, prevState) {
  }

  componentDidMount () {   
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVacioNivelGenetico
    }
  } = this.props  

  return (
      <div>        
        <Row className="acciones-tabla-filtros">
          <Col sm={12}>
            <div className="buttons-flex">             
              <div className="button-group buttons-min">                
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.NIVEL_GENETICO] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.NIVEL_GENETICO] > 1) ? abrirModalVacioNivelGenetico : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>          
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosNivelGenetico',
})(FiltrosNivelGenetico))

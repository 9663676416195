import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/cliente/cliente'
import { closeModalCliente, filtrarClienteSuccess, filtrosClienteSuccess, recuperarDatosModalClienteSuccess, andadirClienteAnalisis, andadirClienteDilucion, andadirClienteEnvasado,
  duplicarDatosModalClienteSuccess, guardarYnuevoClienteSuccess, deSeleccionarTodosCliente, crearCsvClienteSuccess, imprimirPdfClienteSuccess, abrirModalDireccionesCliente,
  recuperarDatosModalDireccionClienteSuccess, editarDireccionClienteSuccess, duplicarDatosModalDireccionClienteSuccess, submitDireccionCliente, cargarPedidosCliente, actualizarDatosRuta,
  actualizarDatosArticuloDosis, comboRutasNoDireccionClienteSuccess, cargarAlbaranCliente, cargarDosisEnviadasCliente, anadirDatosRutaSuccess, anadirDatosArticuloDosisSuccess} from '../../actions/cliente/cliente'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import filtrosCliente from '../../services/cliente/filtrosCliente'
import getCliente from '../../services/cliente/getCliente'
import insertarCliente from '../../services/cliente/insertarCliente'
import editCliente from '../../services/cliente/editCliente'
import getClienteCambioEstado from '../../services/cliente/getClienteCambioEstado'
import deleteCliente from '../../services/cliente/deleteCliente'
import getCambiarEstadoClienteSeleccion from '../../services/cliente/getCambiarEstadoClienteSeleccion'
import eliminarClienteSeleccion from '../../services/cliente/eliminarClienteSeleccion'
import getCrearCsv from '../../services/cliente/getCrearCsv'
import getImprimirPdfCliente from '../../services/cliente/getImprimirPdfCliente'
import getComboRutasNoDireccionCliente from '../../services/cliente/getComboRutasNoDireccionCliente'
import obternerTransportistaRuta from '../../services/cliente/obternerTransportistaRuta'
import {comboRazonSocialClientesActivos} from '../../actions/combos/combos'

export function * filtrarCliente ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrosCliente, values, auth.token)
    yield put(filtrarClienteSuccess(list))
    yield put(filtrosClienteSuccess(values))
  } catch (error) {

  } finally {
    
  }
}
export function * watchFiltrarCliente () {
  yield takeLatest(actionTypes.FILTRAR_CLIENTE, filtrarCliente)
}

export function * abrirModalCliente ({values}) {
  try {
    const valores = {activo: true}
    yield put(initialize('ModalCliente', valores))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirCliente () {
  yield takeLatest(actionTypes.ABRIR_MODAL_CLIENTE, abrirModalCliente)
}

export function * recuperarDatosModalCliente  ({idCliente}) {
  try {
    // let auth = yield select(state => state.auth)
    const clienteRecuperar = yield call(getCliente, idCliente, null)
    yield put(recuperarDatosModalClienteSuccess(clienteRecuperar))
    yield put(initialize('ModalCliente', clienteRecuperar.cliente))
    yield put(cargarPedidosCliente(clienteRecuperar.pedidos))
    yield put(cargarAlbaranCliente(clienteRecuperar.albaranes))
    yield put(cargarDosisEnviadasCliente(clienteRecuperar.dosisEnviadas))
  } catch (error) {

  } finally {
    
  }
}
export function * watchRecuperarDatosModalCliente () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_CLIENTE, recuperarDatosModalCliente)
}


export function * submitNuevoCliente ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionCliente = yield call(insertarCliente, values, auth.token)
    yield put(closeModalCliente())
    if (insercionCliente.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if ((auth.customizations && auth.customizations.includes("AgView"))) {
        if (insercionCliente.agview_error === true) {
          yield put(openMensajeAlerta('error-agview'))
        }
      }
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    let filtros = yield select(state => state.cliente.filtros)
    const lista = yield call(filtrosCliente, filtros, auth.token)
    yield put(filtrarClienteSuccess(lista))
    yield put(filtrosClienteSuccess(filtros))
    yield put(comboRazonSocialClientesActivos())
  } catch (error) {

  } finally {
    
  }
}
export function * watchSubmitNuevoCliente() {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_CLIENTE, submitNuevoCliente)
}

export function * editarCliente ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const modificacionCliente = yield call(editCliente, values, auth.token)
    yield put(closeModalCliente())
    if (modificacionCliente.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if ((auth.customizations && auth.customizations.includes("AgView"))) {
        if (modificacionCliente.agview_error === true) {
          yield put(openMensajeAlerta('error-agview'))
        }
      }
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }        
    yield put(comboRazonSocialClientesActivos())
    let filtros = yield select(state => state.cliente.filtros)
    if (filtros.razonSocial && values.razonSocialCliente){
      filtros.razonSocial = {value: values.razonSocialCliente, label: values.razonSocialCliente}
    }
    const lista = yield call(filtrosCliente, filtros, auth.token)
    yield put(filtrarClienteSuccess(lista))
    yield put(filtrosClienteSuccess(filtros))
  } catch (error) {

  } finally {
    
  }
}
export function * watchEditarCliente() {
  yield takeLatest(actionTypes.EDITAR_CLIENTE, editarCliente)
}

export function * duplicarDatosModalCliente  ({idCliente}) {
  try {
     // let auth = yield select(state => state.auth)
    const clienteDuplicar = yield call(getCliente, idCliente, null)
    yield put(duplicarDatosModalClienteSuccess(clienteDuplicar))
    yield put(initialize('ModalCliente', clienteDuplicar.cliente))
    yield put(submitDireccionCliente(clienteDuplicar.direcciones))
    yield put(cargarPedidosCliente(clienteDuplicar.pedidos))
    yield put(cargarAlbaranCliente(clienteDuplicar.albaranes))
    yield put(cargarDosisEnviadasCliente(clienteDuplicar.dosisEnviadas))
  } catch (error) {

  } finally {
    
  }
}
export function * watchDuplicarDatosModalCliente () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_CLIENTE, duplicarDatosModalCliente)
}

export function * cambiarEstadoCliente ({idCliente}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getClienteCambioEstado, idCliente, null)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      let filtros = yield select(state => state.cliente.filtros)
      const lista = yield call(filtrosCliente, filtros, auth.token)
      yield put(filtrarClienteSuccess(lista))
      yield put(filtrosClienteSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchCambiarEstadoCliente () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_CLIENTE, cambiarEstadoCliente)
}

export function * guardarYnuevoCliente ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionCliente = yield call(insertarCliente, values, auth.token)
    const valores = {activo: true}
    yield put(initialize('ModalCliente', valores))
    yield put(editarDireccionClienteSuccess([]))
    yield put(guardarYnuevoClienteSuccess())
    if (insercionCliente.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    let filtros = yield select(state => state.cliente.filtros)
    const lista = yield call(filtrosCliente, filtros, auth.token)
    yield put(filtrarClienteSuccess(lista))
    yield put(filtrosClienteSuccess(filtros))
    yield put(comboRazonSocialClientesActivos())
  } catch (error) {

  } finally {
    
  }
}
export function * watchGuardarYnuevoCliente() {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_CLIENTE, guardarYnuevoCliente)
}

export function * onDeleteCliente ({idCliente, razonSocialCliente}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(deleteCliente, idCliente, null)
      if (eliminar.asociado === false && eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboRazonSocialClientesActivos())
      let filtros = yield select(state => state.cliente.filtros)
      if (filtros.razonSocial && filtros.razonSocial.value === razonSocialCliente){
        filtros.razonSocial = null
      }
      const lista = yield call(filtrosCliente, filtros, auth.token)
      yield put(filtrarClienteSuccess(lista))
      yield put(filtrosClienteSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchOnDeleteCliente () {
  yield takeLatest(actionTypes.ON_DELETE_CLIENTE, onDeleteCliente)
}

export function * cambiarEstadoSeleccionCliente ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getCambiarEstadoClienteSeleccion, {list: list}, auth.token)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosCliente())
      yield put(initialize('simpleTable', {}))
      let filtros = yield select(state => state.cliente.filtros)
      const lista = yield call(filtrosCliente, filtros, auth.token)
      yield put(filtrarClienteSuccess(lista))
      yield put(filtrosClienteSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchCambiarEstadoSeleccionCliente () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_CLIENTE, cambiarEstadoSeleccionCliente)
}

export function * deleteSeleccionCliente ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(eliminarClienteSeleccion, {list: list}, auth.token)
      yield put(deSeleccionarTodosCliente())
      if (eliminar.asociado === false && eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable', {}))
      yield put(comboRazonSocialClientesActivos())
      let filtros = yield select(state => state.cliente.filtros)
      list.forEach((li) => {
        if (filtros.razonSocial && filtros.razonSocial.value === li.razonSocialCliente){
          filtros.razonSocial = null
        }
      })
      const lista = yield call(filtrosCliente, filtros, auth.token)
      yield put(filtrarClienteSuccess(lista))
      yield put(filtrosClienteSuccess(filtros))
      yield put(initialize('filtrosCliente', filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchDeleteSeleccionCliente () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CLIENTE, deleteSeleccionCliente)
}

export function * crearCsvCliente ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelCliente = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvClienteSuccess(datosExcelCliente))
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchCrearCsvCliente () {
  yield takeLatest(actionTypes.CREATE_CSV_CLIENTE, crearCsvCliente)
}

export function * imprimirPdfCliente ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfCliente = yield call(getImprimirPdfCliente, list, auth.token)
    yield put(imprimirPdfClienteSuccess(datosPdfCliente))
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchImprimirPdfCliente () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CLIENTE, imprimirPdfCliente)
}

export function * recuperarDatosModalDireccionCliente ({idElemento}) {
  try {
    const direccionesClienteState = yield select(state => state.cliente.listDireccionesCliente)
    const direccionesCliente = direccionesClienteState.filter((direccion) => {
      if (direccion.idElemento === idElemento) {
        return direccion
      }
    })
    yield put(initialize('ModalDireccionesCliente', direccionesCliente[0]))
    yield put(recuperarDatosModalDireccionClienteSuccess(direccionesCliente[0]))
    yield put(actualizarDatosRuta(direccionesCliente[0].rutas))
    yield put(actualizarDatosArticuloDosis(direccionesCliente[0].listArticulos))
    yield put(abrirModalDireccionesCliente())
  } catch (error) {

  } finally {
    
  }
}
export function * watchRecuperarDatosModalDireccionCliente () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE, recuperarDatosModalDireccionCliente)
}

export function * editarDireccionCliente ({values}) {
  try {
    const direccionesClienteState = yield select(state => state.cliente.listDireccionesCliente)
    const direccionesCliente = direccionesClienteState.map((direccion) => {
      if (direccion.idElemento === values.idElemento) {
        direccion = values
        let textoRutas = ''
        let textoTransportistas = ''
        let idRuta
        for (let i = 0; i < Object.keys(direccion.rutas).length; i++) {
          if (idRuta !== direccion.rutas[i].idRuta) {
            idRuta = direccion.rutas[i].idRuta
            textoRutas = textoRutas + direccion.rutas[i].nombreRuta + ", "
          }
          textoTransportistas = textoTransportistas + values.rutas[i].transportista + ", "
        }
        values.transportista = textoTransportistas.slice(0, -2)
        direccion.rutasDireccionCliente = textoRutas.slice(0, -2)
      }
      return direccion
    })
    yield put(editarDireccionClienteSuccess(direccionesCliente))
  } catch (error) {

  } finally {
    
  }
}
export function * watchEditarDireccionCliente () {
  yield takeLatest(actionTypes.EDITAR_DIRECCION_CLIENTE, editarDireccionCliente)
}

export function * duplicarDatosModalDireccionCliente ({idElemento}) {
  try {
    const direccionesClienteState = yield select(state => state.cliente.listDireccionesCliente)
    const direccionesCliente = direccionesClienteState.filter((direccion) => {
      if (direccion.idElemento === idElemento) {
        return direccion
      }
    })
    yield put(initialize('ModalDireccionesCliente', direccionesCliente[0]))
    yield put(duplicarDatosModalDireccionClienteSuccess(direccionesCliente[0]))
    yield put(abrirModalDireccionesCliente())
  } catch (error) {

  } finally {
    
  }
}
export function * watchDuplicarDatosModalDireccionCliente () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE, duplicarDatosModalDireccionCliente)
}

export function * onDeleteDireccionCliente ({idElemento}) {
  try {
     yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const direccionesClienteState = yield select(state => state.cliente.listDireccionesCliente)
      const direccionesCliente = direccionesClienteState.filter((direccion) => {
        if (direccion.idElemento !== idElemento) {
          return direccion
        }
      })
      yield put(editarDireccionClienteSuccess(direccionesCliente))
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchOnDeleteDireccionCliente () {
  yield takeLatest(actionTypes.ON_DELETE_DIRECCION_CLIENTE, onDeleteDireccionCliente)
}

export function * onDeleteRutaCliente ({idElemento}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const rutaClienteState = yield select(state => state.cliente.listRutas)
      const rutaCliente = rutaClienteState.filter((ruta) => {
        if (ruta.idElemento !== idElemento) {
          return ruta
        }
      })
      yield put(actualizarDatosRuta(rutaCliente))
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchOnDeleteRutaCliente () {
  yield takeLatest(actionTypes.ON_DELETE_RUTA_CLIENTE, onDeleteRutaCliente)
}


export function * comboRutasNoDireccionCliente ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const data = yield call(getComboRutasNoDireccionCliente, list, auth.token)
    yield put(comboRutasNoDireccionClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchComboRutasNoDireccionCliente () {
  yield takeLatest(actionTypes.COMBO_RUTAS_NO_DIRECCIONES_CLIENTE, comboRutasNoDireccionCliente)
}

export function * anadirDatosRuta ({idRuta}) {
  try {
    let auth = yield select(state => state.auth)
    let listRutas = yield call(obternerTransportistaRuta, idRuta, auth.token)
    if (Object.keys(listRutas).length === 0) {
      listRutas = {idRuta: idRuta.idRuta, nombreRuta: idRuta.nombreRuta, transportista: idRuta.transportista}
    }
    yield put(anadirDatosRutaSuccess(listRutas))
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchAnadirDatosRuta () {
  yield takeLatest(actionTypes.ANADIR_DATOS_RUTA, anadirDatosRuta)
}




export function * anadirDatosArticuloDosis ({values}) {
  try {
    let auth = yield select(state => state.auth)
    //let listArticulos = yield call(obternerTransportistaRuta, idRuta, auth.token)
    let listArticulos = []
    //console.log(values)
    if (Object.keys(listArticulos).length === 0) {
      listArticulos = {idRaza: values.idRaza, raza: values.raza, idLinea: values.idLinea, lineaGenetica: values.lineaGenetica, idTipoDosis: values.idTipoDosis, tipoDosis: values.tipoDosis, nivelGenetico: values.nivelGenetico, idNivelGenetico: values.idNivelGenetico}
    }  
    yield put(anadirDatosArticuloDosisSuccess(listArticulos))
  } catch (error) {
    console.log(error)
  } finally {
    
  }
}
export function * watchDatosArticuloDosis () {
  yield takeLatest(actionTypes.ANADIR_DATOS_ARTICULOS_DOSIS, anadirDatosArticuloDosis)
}

export function * onDeleteDatosArticuloDosis ({idElemento}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const listArticulosState = yield select(state => state.cliente.listArticulos)
      const listArticulos = listArticulosState.filter((row) => {
        let id = row.idRaza + '-' + row.idLinea + '-' + row.idTipoDosis
        if (idElemento !== id) {
          return row
        }
      })
      yield put(actualizarDatosArticuloDosis(listArticulos))
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchOnDeleteDatosArticuloDosis () {
  yield takeLatest(actionTypes.ON_DELETE_DATOS_ARTICULOS_DOSIS, onDeleteDatosArticuloDosis)
}

import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesExtraccionesPage from '../../containers/informes/FiltrosInformesExtraccionesPage'
import {Bar, Line} from 'react-chartjs-2';
import {date_formatter, number_formatter, round_decimal} from '../../util/formatFunctions'
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'
import {estadoVerracoInformeExtracciones as estadoVerracoStilyzer, cantidadCeroVacio} from '../comun/table/Stilyzers'

class InformesExtracciones extends Component {
  constructor () {
    super()
    this.state = {
      media: false,
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: '',
      desplegableGrafica1: false,
      desplegableGrafica2: false
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.EXTRACCIONES') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerDatosPanelControl(null)
  }

  componentDidUpdate (prevProps) {
    if (this.props.filtrosExtracciones !== prevProps.filtrosExtracciones) {
      if (Object.keys(this.props.filtrosExtracciones).length > 0){
        if (this.props.filtrosExtracciones.media){
          this.setState({media: this.props.filtrosExtracciones.media.value})
        }
      }
    }

    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }
  }

  desplegarGrafica1 () {
    this.setState({desplegableGrafica1: !this.state.desplegableGrafica1})
  }

  desplegarGrafica2 () {
    this.setState({desplegableGrafica2: !this.state.desplegableGrafica2})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeExtracciones, totalesExtracciones, graficaExtracciones, graficaExtraccionesDosis, graficaExtraccionesConcentracion,
      auth: {separadorDec, separadorMil}
    } = this.props

    const tableInformeExtracciones = {
      id: 'informeExtraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.media === false ? (
        this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'idEstadoVerraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'fechaExtraccion', name: t('INFORMES.EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'diasDescanso', name: t('INFORMES.EXTRACCIONES.TABLA.DIAS_DESCANSO'), type: 'numero', numDecimales: 0},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'nombreDosis', name: t('INFORMES.EXTRACCIONES.TABLA.TIPO_DOSIS')},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        // {id: 'numseguimientos', name: t('INFORMES.EXTRACCIONES.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        // {id: 'porCA', name: t('INFORMES.EXTRACCIONES.TABLA.CA'), type: 'numero'},
        // {id: 'porCO', name: t('INFORMES.EXTRACCIONES.TABLA.CO'), type: 'numero'},
        // {id: 'porGP', name: t('INFORMES.EXTRACCIONES.TABLA.GP'), type: 'numero'},
        // {id: 'porGD', name: t('INFORMES.EXTRACCIONES.TABLA.GD'), type: 'numero'},
        {id: 'eyaculadoEliminado', name: t('INFORMES.EXTRACCIONES.TABLA.EYACULADO_ELIMINADO')},
        {id: 'CausasEliminadoAnalisisText', name: t('INFORMES.EXTRACCIONES.TABLA.CAUSA_ELIMINACION')},
        {id: 'observaciones', name: t('INFORMES.EXTRACCIONES.TABLA.OBSERVACIONES')}
      ]) : this.state.metodoEvaluacion === 3 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'idEstadoVerraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'fechaExtraccion', name: t('INFORMES.EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'diasDescanso', name: t('INFORMES.EXTRACCIONES.TABLA.DIAS_DESCANSO'), type: 'numero', numDecimales: 0},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'nombreDosis', name: t('INFORMES.EXTRACCIONES.TABLA.TIPO_DOSIS')},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        // {id: 'numseguimientos', name: t('INFORMES.EXTRACCIONES.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'eyaculadoEliminado', name: t('INFORMES.EXTRACCIONES.TABLA.EYACULADO_ELIMINADO')},
        {id: 'CausasEliminadoAnalisisText', name: t('INFORMES.EXTRACCIONES.TABLA.CAUSA_ELIMINACION')},
        {id: 'observaciones', name: t('INFORMES.EXTRACCIONES.TABLA.OBSERVACIONES')}
      ]) : this.state.metodoEvaluacion === 4 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'idEstadoVerraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'fechaExtraccion', name: t('INFORMES.EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'diasDescanso', name: t('INFORMES.EXTRACCIONES.TABLA.DIAS_DESCANSO'), type: 'numero', numDecimales: 0},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'nombreDosis', name: t('INFORMES.EXTRACCIONES.TABLA.TIPO_DOSIS')},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        // {id: 'numseguimientos', name: t('INFORMES.EXTRACCIONES.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'eyaculadoEliminado', name: t('INFORMES.EXTRACCIONES.TABLA.EYACULADO_ELIMINADO')},
        {id: 'CausasEliminadoAnalisisText', name: t('INFORMES.EXTRACCIONES.TABLA.CAUSA_ELIMINACION')},
        {id: 'observaciones', name: t('INFORMES.EXTRACCIONES.TABLA.OBSERVACIONES')}
      ]) : ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'idEstadoVerraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'fechaExtraccion', name: t('INFORMES.EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'diasDescanso', name: t('INFORMES.EXTRACCIONES.TABLA.DIAS_DESCANSO'), type: 'numero', numDecimales: 0},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'nombreDosis', name: t('INFORMES.EXTRACCIONES.TABLA.TIPO_DOSIS')},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        // {id: 'numseguimientos', name: t('INFORMES.EXTRACCIONES.TABLA.SEGUIMIENTO'), type: 'numero', numDecimales: 0, stilyzer: cantidadCeroVacio},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        // {id: 'porCO', name: t('INFORMES.EXTRACCIONES.TABLA.CO'), type: 'numero'},
        // {id: 'porGP', name: t('INFORMES.EXTRACCIONES.TABLA.GP'), type: 'numero'},
        // {id: 'porGD', name: t('INFORMES.EXTRACCIONES.TABLA.GD'), type: 'numero'},
        {id: 'eyaculadoEliminado', name: t('INFORMES.EXTRACCIONES.TABLA.EYACULADO_ELIMINADO')},
        {id: 'CausasEliminadoAnalisisText', name: t('INFORMES.EXTRACCIONES.TABLA.CAUSA_ELIMINACION')},
        {id: 'observaciones', name: t('INFORMES.EXTRACCIONES.TABLA.OBSERVACIONES')}
      ])
      ) : (
        this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        // {id: 'porCA', name: t('INFORMES.EXTRACCIONES.TABLA.CA'), type: 'numero'},
        // {id: 'porCO', name: t('INFORMES.EXTRACCIONES.TABLA.CO'), type: 'numero'},
        // {id: 'porGP', name: t('INFORMES.EXTRACCIONES.TABLA.GP'), type: 'numero'},
        // {id: 'porGD', name: t('INFORMES.EXTRACCIONES.TABLA.GD'), type: 'numero'},
        {id: 'numeroEyaculados', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_EXTRACCIONES'), type: 'numero'},
        {id: 'totalDosisProducidas', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_DOSIS_PRODUCIDAS'), type: 'numero'},
        {id: 'porcentajeEyaculadosEliminados', name: t('INFORMES.EXTRACCIONES.TABLA.PORC_EYACULADO_ELIMINADO'), type: 'numero'}
      ]) : this.state.metodoEvaluacion === 3 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'numeroEyaculados', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_EXTRACCIONES'), type: 'numero'},
        {id: 'totalDosisProducidas', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_DOSIS_PRODUCIDAS'), type: 'numero'},
        {id: 'porcentajeEyaculadosEliminados', name: t('INFORMES.EXTRACCIONES.TABLA.PORC_EYACULADO_ELIMINADO'), type: 'numero'}
      ]) : this.state.metodoEvaluacion === 4 ? ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'numeroEyaculados', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_EXTRACCIONES'), type: 'numero'},
        {id: 'totalDosisProducidas', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_DOSIS_PRODUCIDAS'), type: 'numero'},
        {id: 'porcentajeEyaculadosEliminados', name: t('INFORMES.EXTRACCIONES.TABLA.PORC_EYACULADO_ELIMINADO'), type: 'numero'}
      ]) : ([
        {id: 'codVerraco', name: t('INFORMES.EXTRACCIONES.TABLA.VERRACO')},
        {id: 'volumen', name: t('INFORMES.EXTRACCIONES.TABLA.VOL'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'concentracionxdosisaplicada', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_APLIC'), type: 'numero'},
        {id: 'concentracionxdosisutil', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_UTIL'), type: 'numero'},
        {id: 'numeroDosis', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'},
        {id: 'ebv', name: t('INFORMES.EXTRACCIONES.TABLA.EBV'), type: 'numero'},
        {id: 'nivelgenetico', name: t('INFORMES.EXTRACCIONES.TABLA.NIVEL_GENETICO')},
        {id: 'dosisTeoricas', name: t('INFORMES.EXTRACCIONES.TABLA.DOSIS_TEORICAS'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.EXTRACCIONES.TABLA.AGLUTINACION'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.EXTRACCIONES.TABLA.CALIDAD'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.EXTRACCIONES.TABLA.FA'), type: 'numero'},
        // {id: 'porCO', name: t('INFORMES.EXTRACCIONES.TABLA.CO'), type: 'numero'},
        // {id: 'porGP', name: t('INFORMES.EXTRACCIONES.TABLA.GP'), type: 'numero'},
        // {id: 'porGD', name: t('INFORMES.EXTRACCIONES.TABLA.GD'), type: 'numero'},
        {id: 'numeroEyaculados', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_EXTRACCIONES'), type: 'numero'},
        {id: 'totalDosisProducidas', name: t('INFORMES.EXTRACCIONES.TABLA.NUM_DOSIS_PRODUCIDAS'), type: 'numero'},
        {id: 'porcentajeEyaculadosEliminados', name: t('INFORMES.EXTRACCIONES.TABLA.PORC_EYACULADO_ELIMINADO'), type: 'numero'}
      ])
      ),
      rows: listInformeExtracciones,
      filtros: <FiltrosInformesExtraccionesPage />,
      initialValues: {}
    }

    const colors = ['#395196', '#5498af', '#4ca1a0', '#56ccde', '#4db19b', '#5be6c7'];
    // graficaExtraccionesDosis
    const colors2 = 'rgba(57,81,150,0.3)';
    const colors3 = 'rgba(57,150,75,0.3)';
    const colors4 = '#ad950e';
    const options2 = {
      elements: {
        line: {
          tension: 0
        }
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0
        }
      },
      scales: {
        xAxes: [{
          stacked: false,
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9,
            label: 'izquierda'
          }
        }],
        yAxes: [{
          stacked: false,
          ticks: {
            fontSize: 9
          },
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true
          },
          labels: {
            show: true,
          },

        },{
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9
          },
          labels: {
            show: true
          }
        }],
      },
      id: 'chart',
      responsive: true,
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: '#91929b',
            padding: 20
          }
        },
        plugins: {
          labels: false
        }
      }
    let datasets2 = []
    let fechas2 = []
    if (Object.keys(graficaExtraccionesDosis).length > 0){
      datasets2 = datasets2.concat(
      {
        label: t('INFORMES.EXTRACCIONES.DOSIS_REALES_GRAFICA'),
        data: graficaExtraccionesDosis.datosGrafica.sumaDosisReales,
        // stack: 1,
        backgroundColor: colors2,
        borderWidth: 0,
        type:'line'
      },{
        label: t('INFORMES.EXTRACCIONES.DOSIS_TEORICAS_GRAFICA'),
        data: graficaExtraccionesDosis.datosGrafica.sumaDosisTeoricas,
        // stack: 2,
        backgroundColor: colors3,
        borderWidth: 0,
        type:'line'
      },{
        label: t('INFORMES.EXTRACCIONES.FORMAS_ANORMALES') + ' %',
        data: graficaExtraccionesDosis.datosGrafica.porFA,
        // stack: 3,
        borderColor: '#555',
        borderWidth: 2,
        hoverBackgroundColor: 'none',
        hoverBorderColor: 'rgba(255,142,0,1)',
        backgroundColor: 'rgba(255,255,255,0.01)',
        type:'line',
        yAxisID: 'y-axis-2',
      })

      fechas2 = graficaExtraccionesDosis.labels.map((f) => {
        return date_formatter(f, this.props.auth.formaFechaHora, false)
      })
    }

    let chartData2 = {
      labels: fechas2,
      datasets: datasets2
    }

    // graficaExtraccionesConcentracion
    const colors5 = '#5be6c7';
    const options3 = {
      layout: {
        padding: {
          bottom: 0,
          top: 0
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontSize: 9
          }
            }],
      },
      responsive: true,
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: '#91929b',
            padding: 20
          }
        },
        plugins: {
          labels: false
        }
      };
    let datasets3 = []
    let fechas3 = []
    if (Object.keys(graficaExtraccionesConcentracion).length > 0){
      datasets3 = datasets3.concat(
      {
        label: t('INFORMES.EXTRACCIONES.DOSIS_GRAFICA'),
        data: graficaExtraccionesConcentracion.datosGrafica.dosis3000,
        stack: 1,
        backgroundColor: colors5,
        borderWidth: 0
      })
      fechas3 = graficaExtraccionesConcentracion.labels.map((f) => {
        return date_formatter(f, this.props.auth.formaFechaHora, false)
      })
    }

    let chartData3 = {
      labels: fechas3,
      datasets: datasets3
    };


    return (
      <div>
        <h2 className="page-title">{t('INFORMES.EXTRACCIONES.TITLE')}</h2>
        <SimpleTablePage {...tableInformeExtracciones}>
        </SimpleTablePage>
        {
          this.state.media === false ? (
            <div>
              <Button type="button" onClick={() => this.desplegarGrafica1()} className="btn-desplegar-grafica" >{t('INFORMES.EXTRACCIONES.CONTRAER_EXPANDIR_GRAFICA')} 
                {
                  this.state.desplegableGrafica1 === true ? (
                    <KeyboardArrowUp/>
                  ) : (
                    <KeyboardArrowDown/>
                  )
                }
              </Button>
              <div className={this.state.desplegableGrafica1 === true ? 'grafica-dosis-formas-anormales contraer-grafica' : 'grafica-dosis-formas-anormales'}>
                <span className="label-dosis-reales-teoricas">{t('INFORMES.EXTRACCIONES.DOSIS_TEORICAS_DOSIS_REALES')}</span>
                <Line
                  data={chartData2}
                  options={options2}
                  height={200}
                  width={700}
                />
                <span className="label-formas-anormales">{t('INFORMES.EXTRACCIONES.FORMAS_ANORMALES') + ' %'}</span>
              </div>
              <Button type="button" onClick={() => this.desplegarGrafica2()} className="btn-desplegar-grafica" >{t('INFORMES.EXTRACCIONES.CONTRAER_EXPANDIR_GRAFICA')} 
                {
                  this.state.desplegableGrafica2 === true ? (
                    <KeyboardArrowUp/>
                  ) : (
                    <KeyboardArrowDown/>
                  )
                }
              </Button>
              <div className={this.state.desplegableGrafica2 === true ? 'grafica-barras-procuctividad-extracciones contraer-grafica' : 'grafica-barras-procuctividad-extracciones'}>
                <Bar
                  data={chartData3}
                  options={options3}
                  width={700}
                  height={200}
                />
              </div>
            </div>
          ) : null
        }

        <div className="totales-informes">
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.TOTALES_EXTRACCIONES')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_DOSIS_REALES')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_CONCENTRACION_TOTAL')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_CONCENTRACION_UTIL')}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_DOSIS_APLICADA')}</span></Col>
          <Col sm={2} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_DOSIS_UTIL')}</span></Col>

          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.totalExtracciones && number_formatter(round_decimal(totalesExtracciones.totalExtracciones, 0, separadorDec, separadorMil), 0, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.mediaDosisReales && number_formatter(round_decimal(totalesExtracciones.mediaDosisReales, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.mediaConcentracionTotal && number_formatter(round_decimal(totalesExtracciones.mediaConcentracionTotal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.mediaConcentracionUtil && number_formatter(round_decimal(totalesExtracciones.mediaConcentracionUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.mediaDosisAplicada && number_formatter(round_decimal(totalesExtracciones.mediaDosisAplicada, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="valores-totales-informes" style={{border: 'none'}}><span>{totalesExtracciones.mediaDosisUtil && number_formatter(round_decimal(totalesExtracciones.mediaDosisUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
        </div>

        <div className="totales-informes" style={{border: 'none'}}>
          <Row style={{marginRight: '0', marginLeft: '0'}}>
            <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_FA')}</span></Col>
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_CA')}</span></Col>
              ) : null
            }
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 || this.state.metodoEvaluacion === 5 ? ([
                <Col key="co1" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_CO')}</span></Col>,
                <Col key="gp1" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_GP')}</span></Col>,
                <Col key="gd1" sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_GD')}</span></Col>
              ]) : null
            }
            <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_VOLUMEN')}</span></Col>
            <Col sm={2} className="titulo-totales-informes medias-inf-extracciones"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_DESCANSO')}</span></Col>
          </Row>
          <Row style={{marginRight: '0', marginLeft: '0'}}>
            <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaFA && number_formatter(round_decimal(totalesExtracciones.mediaFA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaCA && number_formatter(round_decimal(totalesExtracciones.mediaCA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
              ) : null
            }
            {
              this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 || this.state.metodoEvaluacion === 5 ? ([
                <Col key="co2" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaCO && number_formatter(round_decimal(totalesExtracciones.mediaCO, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>,
                <Col key="gp2" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaGP && number_formatter(round_decimal(totalesExtracciones.mediaGP, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>,
                <Col key="gd2" sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaGD && number_formatter(round_decimal(totalesExtracciones.mediaGD, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
                ]) : null
            }
            <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaVolumen && number_formatter(round_decimal(totalesExtracciones.mediaVolumen, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
            <Col sm={2} className="valores-totales-informes medias-inf-extracciones"><span>{totalesExtracciones.mediaDiasDescanso && number_formatter(totalesExtracciones.mediaDiasDescanso, 0, separadorDec, separadorMil)}</span></Col>
          </Row>
        </div>

        <div className="totales-informes">
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.DOSIS_REALES')}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.dosisReales && number_formatter(round_decimal(totalesExtracciones.dosisReales, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.DOSIS_TEORICAS')}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.dosisTeoricas && number_formatter(round_decimal(totalesExtracciones.dosisTeoricas, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
          <Col sm={2} className="titulo-totales-informes"><span>{t('INFORMES.EXTRACCIONES.TOTALES.MEDIA_SALTO')}</span></Col>
          <Col sm={2} className="valores-totales-informes"><span>{totalesExtracciones.dosisTeoricas && number_formatter(round_decimal(totalesExtracciones.mediaSalto, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span></Col>
        </div>
      </div>
    )
  }
}

export default InformesExtracciones
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalListadoSeguimiento from '../../components/seguimiento/ModalListadoSeguimiento'
import {deleteSeguimiento, openModalFormSeguimiento, closeModalListadoSeguimiento} from '../../actions/seguimiento/seguimiento'

export function mapStateToProps (state) {
  return {
    ...state.seguimiento,
    panelControl: state.panelControl,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteSeguimiento,
      openModalFormSeguimiento,
      closeModalListadoSeguimiento
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalListadoSeguimiento))
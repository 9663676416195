import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize, change, getFormValues} from 'redux-form'
import i18n from '../../i18n'
import {round_decimal, number_formatter} from '../../util/formatFunctions'

// Constants
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import actionTypes from '../../constants/actions/trazabilidadMaterial/trazabilidadMaterial'

// Actions
import {
  comprobarStockTrazabilidadSuccess, updateStockInsuficienteSuccess, updateLotesEscandalloSuccess, openModalTrazabilidadMaterialSuccess,
  guardarModalTrazabilidadMaterialSuccess, closeModalTrazabilidadMaterialSuccess, deSeleccionarTodosTrazabilidadMaterial,
  openModalEditarTrazabilidadMaterialSuccess, guardarModalEditarTrazabilidadMaterialSuccess, closeModalEditarTrazabilidadMaterialSuccess
} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import {openSimpleModal} from '../../actions/common'

// Services
import getLotesEscandalloService from '../../services/trazabilidadMaterial/getLotesEscandallo'
import comprobarStockService from '../../services/trazabilidadMaterial/comprobarStock'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

export function * comprobarStockTrazabilidad ({idCategoria, idTipoDosis, utilizarLotesPreferidosDelUsuario}) {
  try {
    let state = yield select(state => state)
    let formValues
    let values = {
      idCategoria,
      idTipoDosis
    }

    switch(values.idCategoria) {
      case categoriasEscandallos.ANALISIS:
        formValues = yield getFormValues('formAnalisisExtraccion')(state)
        break
      case categoriasEscandallos.DILUCION:
        formValues = yield getFormValues('ModalDilucion')(state)
        values.volumenDiluyente = formValues.volumenDiluyente
        break
      case categoriasEscandallos.ENVASADO:
        formValues = yield getFormValues('ModalEnvasadoMultiDosis')(state)
        values.numeroDosis = formValues.numeroDosis
        if (utilizarLotesPreferidosDelUsuario) {
          values.lotesPreferidosDelUsuario = formValues.trazabilidad.map((lote) => {
            return lote.idEntradaAlmacenProducto
          })
        }
        break
      case categoriasEscandallos.ANALISIS_DILUCION:
        formValues = yield getFormValues('ModalDilucionAnalisisExtraccion')(state)
        values.idCategoria = categoriasEscandallos.DILUCION
        values.volumenDiluyente = formValues.volumenDiluyente
        break
      default:
        yield console.log('unhandler idCategoria: ', values.idCategoria)
    }

    const {stockInsuficiente} = yield call(comprobarStockService, values, state.auth.token)
    yield put(comprobarStockTrazabilidadSuccess({[idCategoria]: stockInsuficiente}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchComprobarStockTrazabilidad () {
  yield takeLatest(actionTypes.COMPROBAR_STOCK_TRAZABILIDAD, comprobarStockTrazabilidad)
}

export function * updateStockInsuficiente () {
  try {
    const state = yield select(state => state)
    let {idCategoria, lotesEscandallo} = state.trazabilidadMaterial

    // Si en alguno de los lotes de los productos la suma de sus stocks es negativa se alertara al usuario de que no hay stock suficiente.
    let stockInsuficiente = false
    let stockLotes = lotesEscandallo.reduce((acc, lote) => {
      return {
        ...acc,
        [lote.idLote]: (acc[lote.idLote] ? parseFloat(acc[lote.idLote], 10) : 0) + parseFloat(lote.stockFinal, 10)
      }
    }, {})
    if (stockLotes) {
      stockInsuficiente = !!Object.values(stockLotes).find((val) => {return val < 0})
    }

    yield put(updateStockInsuficienteSuccess({[idCategoria]: stockInsuficiente}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchUpdateStockInsuficiente () {
  yield takeLatest(actionTypes.UPDATE_STOCK_INSUFICIENTE, updateStockInsuficiente)
}

export function * obtenerLotesEscandalloAnalisis ({values}) {
  try {
    let state = yield select(state => state)
    const lotesEscandalloResult = yield call(getLotesEscandalloService, values, state.auth.token)
    let lotesEscandallo = lotesEscandalloResult.lotesEscandallo

    let lotesEscandalloConsumidos = lotesEscandallo/*.filter((lote) => {
        return (lote.consumido ? parseFloat(lote.consumido, 10) : 0) > 0
      })*/.map((lote) => {
      return {...lote, cantidad: lote.consumido}
    })
    yield put(change('formAnalisisExtraccion', 'trazabilidad', lotesEscandalloConsumidos))

    yield put(updateLotesEscandalloSuccess(lotesEscandallo))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchObtenerLotesEscandalloAnalisis () {
  yield takeLatest(actionTypes.OBTENER_LOTES_ESCANDALLO_ANALISIS, obtenerLotesEscandalloAnalisis)
}

export function * obtenerLotesEscandalloEnvasado ({values, utilizarLotesPreferidosDelUsuario}) {
  try {
    let state = yield select(state => state)
    if (utilizarLotesPreferidosDelUsuario) {
      let formValues = yield getFormValues('ModalEnvasadoMultiDosis')(state)
      values.lotesPreferidosDelUsuario = formValues.trazabilidad.map((lote) => {
        return lote.idEntradaAlmacenProducto
      })
    }

    const lotesEscandalloResult = yield call(getLotesEscandalloService, values, state.auth.token)
    let lotesEscandallo = lotesEscandalloResult.lotesEscandallo

    let lotesEscandalloConsumidos = lotesEscandallo/*.filter((lote) => {
        return (lote.consumido ? parseFloat(lote.consumido, 10) : 0) > 0
      })*/.map((lote) => {
      return {...lote, cantidad: lote.consumido}
    })
    yield put(change('ModalEnvasadoMultiDosis', 'trazabilidad', lotesEscandalloConsumidos))

    yield put(updateLotesEscandalloSuccess(lotesEscandallo))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchObtenerLotesEscandalloEnvasado () {
  yield takeLatest(actionTypes.OBTENER_LOTES_ESCANDALLO_ENVASADO, obtenerLotesEscandalloEnvasado)
}

export function * updateLotesEscandallo ({lotesEscandallo}) {
  try {
    yield put(updateLotesEscandalloSuccess(lotesEscandallo))
    yield call(updateStockInsuficiente)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchUpdateLotesEscandallo () {
  yield takeLatest(actionTypes.UPDATE_LOTES_ESCANDALLO, updateLotesEscandallo)
}

export function * openModalTrazabilidadMaterial ({values}) {
  try {
    let state = yield select(state => state)
    let formValues
    let lotesEscandallo
    let obtenerLotesEscandalloActualizados = false
    let idCategoriaBack = values.idCategoria
    let openModal = values.openModal !== false
    let dosis = values.dosis || 0
    let dosisMax = values.dosisMax || 0
    switch(values.idCategoria) {
      case categoriasEscandallos.ANALISIS:
        formValues = yield getFormValues('formAnalisisExtraccion')(state)
        lotesEscandallo = formValues.trazabilidad || []
        obtenerLotesEscandalloActualizados = !formValues.trazabilidad && (!values.idAnalisis || values.creadoDesdeFoso)
        break
        case categoriasEscandallos.DILUCION:
          formValues = yield getFormValues('ModalDilucion')(state)
          values.volumenDiluyente = formValues.volumenDiluyente
          lotesEscandallo = formValues.trazabilidad || []
          obtenerLotesEscandalloActualizados = !formValues.diluido && !values.utilizarLotesPreferidosDelUsuario
        break
      case categoriasEscandallos.ENVASADO:
        formValues = yield getFormValues('ModalEnvasadoMultiDosis')(state)
        values.numeroDosis = formValues.numeroDosis
        lotesEscandallo = formValues.trazabilidad || []
        obtenerLotesEscandalloActualizados = !formValues.envasado && !values.utilizarLotesPreferidosDelUsuario
        break
      case categoriasEscandallos.ANALISIS_DILUCION:
        idCategoriaBack = categoriasEscandallos.DILUCION
        formValues = yield getFormValues('ModalDilucionAnalisisExtraccion')(state)
        values.volumenDiluyente = formValues.volumenDiluyente
        lotesEscandallo = formValues.trazabilidad || []
        obtenerLotesEscandalloActualizados = !values.ultimaModificacion && !values.utilizarLotesPreferidosDelUsuario
        break
      default:
        yield console.log('unhandler idCategoria: ', values.idCategoria)
    }

    // Si el análisis es nuevo o la dilución / el envasado no ha sido modificado, se obtendran los lotes del escandallo correspondiente.
    if (obtenerLotesEscandalloActualizados) {
      const lotesEscandalloResult = yield call(getLotesEscandalloService, {...values, idCategoria: idCategoriaBack}, state.auth.token)
      lotesEscandallo = lotesEscandalloResult.lotesEscandallo
    }
    let newLotesEscandallo = lotesEscandallo && lotesEscandallo.map((lote, index) => {
      let stock = lote.stock === null ? '0' : ''+ round_decimal(parseFloat(lote.stock), 2, state.auth.separadorDec, state.auth.separadorMil)
      let consumido = lote.consumido === null ? '0' : ''+ round_decimal(parseFloat(lote.consumido), 2, state.auth.separadorDec, state.auth.separadorMil)
      let stockFinal = lote.stockFinal === null ? '0' : ''+ round_decimal(parseFloat(lote.stockFinal), 2, state.auth.separadorDec, state.auth.separadorMil)
      return {...lote, idTrazabilidadFront: index, loteEscandallo: true, stock, consumido, stockFinal, cantidad: consumido}
    })

    // Actualizado para poder utilizar el mismo metodo al abrir la dilucion / envasado y guardarlo sin tener que abrir el modal de trazabilidad.
    if (openModal) {
      yield put(openModalTrazabilidadMaterialSuccess(values.idCategoria, values.idTipoDosis, values.idAnalisis, values.idEnvasadoMultiDosis, newLotesEscandallo, values.disabled, values.showStockColumns, values.dosis, values.dosisMax))
    } else {
      switch (values.idCategoria) {
        case categoriasEscandallos.ANALISIS:
          yield put(change('formAnalisisExtraccion', 'trazabilidad', newLotesEscandallo))
          break
        case categoriasEscandallos.DILUCION:
          yield put(change('ModalDilucion', 'trazabilidad', newLotesEscandallo))
          break
        case categoriasEscandallos.ENVASADO:
          yield put(change('ModalEnvasadoMultiDosis', 'trazabilidad', newLotesEscandallo))
          break
        case categoriasEscandallos.ANALISIS_DILUCION:
          yield put(change('ModalDilucionAnalisisExtraccion', 'trazabilidad', newLotesEscandallo))
          break
        default:
          yield console.log('unhandler idCategoria: ', values.idCategoria)
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOpenModalTrazabilidadMaterial () {
  yield takeLatest(actionTypes.OPEN_MODAL_TRAZABILIDAD_MATERIAL, openModalTrazabilidadMaterial)
}

export function * actualizarTrazabilidadMaterial ({values}) {
  try {
    let state = yield select(state => state)
    let formValues
    switch(values.idCategoria) {
      case categoriasEscandallos.DILUCION:
        formValues = yield getFormValues('ModalDilucion')(state)
        values.volumenDiluyente = formValues.volumenDiluyente
        if(!formValues.diluido && !values.utilizarLotesPreferidosDelUsuario){
          const lotesEscandalloResult = yield call(getLotesEscandalloService, {...values}, state.auth.token)
          let newLotesEscandallo = lotesEscandalloResult.lotesEscandallo && lotesEscandalloResult.lotesEscandallo.map((lote, index) => {
            let stock = lote.stock === null ? '0' : ''+ round_decimal(parseFloat(lote.stock), 2, state.auth.separadorDec, state.auth.separadorMil)
            let consumido = lote.consumido === null ? '0' : ''+ round_decimal(parseFloat(lote.consumido), 2, state.auth.separadorDec, state.auth.separadorMil)
            let stockFinal = lote.stockFinal === null ? '0' : ''+ round_decimal(parseFloat(lote.stockFinal), 2, state.auth.separadorDec, state.auth.separadorMil)
            return {...lote, idTrazabilidadFront: index, loteEscandallo: true, stock, consumido, stockFinal, cantidad: consumido}
          })
          yield put(change('ModalDilucion', 'trazabilidad', newLotesEscandallo))
        }
      break
      case categoriasEscandallos.ENVASADO:
        formValues = yield getFormValues('ModalEnvasadoMultiDosis')(state)
        values.numeroDosis = formValues.numeroDosis
        if(!formValues.envasado && !values.utilizarLotesPreferidosDelUsuario){
          const lotesEscandalloResult = yield call(getLotesEscandalloService, {...values}, state.auth.token)
          let newLotesEscandallo = lotesEscandalloResult.lotesEscandallo && lotesEscandalloResult.lotesEscandallo.map((lote, index) => {
            let stock = lote.stock === null ? '0' : ''+ round_decimal(parseFloat(lote.stock), 2, state.auth.separadorDec, state.auth.separadorMil)
            let consumido = lote.consumido === null ? '0' : ''+ round_decimal(parseFloat(lote.consumido), 2, state.auth.separadorDec, state.auth.separadorMil)
            let stockFinal = lote.stockFinal === null ? '0' : ''+ round_decimal(parseFloat(lote.stockFinal), 2, state.auth.separadorDec, state.auth.separadorMil)
            return {...lote, idTrazabilidadFront: index, loteEscandallo: true, stock, consumido, stockFinal, cantidad: consumido}
          })
          yield put(change('ModalEnvasadoMultiDosis', 'trazabilidad', newLotesEscandallo))
        }
        break
      default:
        yield console.log('unhandler idCategoria: ', values.idCategoria)
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchActualizarTrazabilidadMaterial () {
  yield takeLatest(actionTypes.ACTUALIZAR_TRAZABILIDAD_MATERIAL, actualizarTrazabilidadMaterial)
}

export function * guardarModalTrazabilidadMaterial () {
  try {
    const state = yield select(state => state)
    let {idCategoria, lotesEscandallo, stockInsuficiente} = state.trazabilidadMaterial
    if (stockInsuficiente[idCategoria]) {
      yield call(yesNoModalSaga, {modalType: 'stockInsuficiente'})
      yield call(closeModalTrazabilidadMaterial)
    } else {
      let lotesEscandalloConsumidos = lotesEscandallo/*.filter((lote) => {
        return (lote.consumido ? parseFloat(lote.consumido, 10) : 0) > 0
      })*/.map((lote) => {
        return {...lote, cantidad: lote.consumido}
      })

      switch (idCategoria) {
        case categoriasEscandallos.ANALISIS:
          yield put(change('formAnalisisExtraccion', 'trazabilidad', lotesEscandalloConsumidos))
          break
        case categoriasEscandallos.DILUCION:
          yield put(change('ModalDilucion', 'trazabilidad', lotesEscandalloConsumidos))
          break
        case categoriasEscandallos.ENVASADO:
          yield put(change('ModalEnvasadoMultiDosis', 'trazabilidad', lotesEscandalloConsumidos))
          break
        case categoriasEscandallos.ANALISIS_DILUCION:
          yield put(change('ModalDilucionAnalisisExtraccion', 'trazabilidad', lotesEscandalloConsumidos))
          break
        default:
          yield console.log('unhandler idCategoria: ', idCategoria)
      }
      yield put(guardarModalTrazabilidadMaterialSuccess())
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchGuardarModalTrazabilidadMaterial () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_TRAZABILIDAD_MATERIAL, guardarModalTrazabilidadMaterial)
}

export function * closeModalTrazabilidadMaterial () {
  try {
    yield put(closeModalTrazabilidadMaterialSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCloseModalTrazabilidadMaterial () {
  yield takeLatest(actionTypes.CLOSE_MODAL_TRAZABILIDAD_MATERIAL, closeModalTrazabilidadMaterial)
}

export function * deleteTrazabilidadMaterial ({row}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let trazabilidadMaterial = yield select(state => state.trazabilidadMaterial)
      let newLotesEscandallo = trazabilidadMaterial.lotesEscandallo.filter((loteEscandallo) => {
        return row.idTrazabilidadFront !== loteEscandallo.idTrazabilidadFront
      })
      yield call(updateLotesEscandallo, {lotesEscandallo: newLotesEscandallo})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteTrazabilidadMaterial () {
  yield takeLatest(actionTypes.DELETE_TRAZABILIDAD_MATERIAL, deleteTrazabilidadMaterial)
}

export function * deleteSeleccionTrazabilidadMaterial ({list}) {
  try {
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let trazabilidadMaterial = yield select(state => state.trazabilidadMaterial)
      let newLotesEscandallo = trazabilidadMaterial.lotesEscandallo.filter((loteEscandallo) => {
        return !list.find((element) => element.idTrazabilidadFront === loteEscandallo.idTrazabilidadFront)
      })
      yield put(deSeleccionarTodosTrazabilidadMaterial())
      yield put(initialize('listadoTrazabilidadMaterialTable', {}))
      yield call(updateLotesEscandallo, {lotesEscandallo: newLotesEscandallo})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionTrazabilidadMaterial () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TRAZABILIDAD_MATERIAL, deleteSeleccionTrazabilidadMaterial)
}

export function * openModalEditarTrazabilidadMaterial ({row}) {
  try {
    let auth = yield select(state => state.auth)
    let stock = row.stock === null ? '0' :  (''+row.stock).replace('.', auth.separadorDec)
    let consumido = row.consumido === null ? '0' :  (''+row.consumido).replace('.', auth.separadorDec)
    let stockFinal = row.stockFinal === null ? '0' :  (''+row.stockFinal).replace('.', auth.separadorDec)
    yield put(initialize('ModalEditarTrazabilidadMaterial', {...row, stock, consumido, stockFinal}))
    yield put(openModalEditarTrazabilidadMaterialSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalEditarTrazabilidadMaterial () {
  yield takeLatest(actionTypes.OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL, openModalEditarTrazabilidadMaterial)
}

export function * guardarModalEditarTrazabilidadMaterial ({values}) {
  try {
    let lotesEscandallo = yield select(state => state.trazabilidadMaterial.lotesEscandallo)
    lotesEscandallo = lotesEscandallo.map((loteEscandallo) => {
      if (loteEscandallo.idTrazabilidadFront === values.idTrazabilidadFront) {
        return {...loteEscandallo, consumido: values.consumido, stockFinal: values.stockFinal}
      }
      return loteEscandallo
    })
    yield put(guardarModalEditarTrazabilidadMaterialSuccess())
    yield call(updateLotesEscandallo, {lotesEscandallo})
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarModalEditarTrazabilidadMaterial () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL, guardarModalEditarTrazabilidadMaterial)
}

export function * closeModalEditarTrazabilidadMaterial () {
  try {
    yield put(closeModalEditarTrazabilidadMaterialSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalEditarTrazabilidadMaterial () {
  yield takeLatest(actionTypes.CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL, closeModalEditarTrazabilidadMaterial)
}
export default function (session) {
  try {
    console.log('Saving session in localStorage')
    const seriablizedSession = JSON.stringify(session)
    localStorage.setItem('session', seriablizedSession)
    return true
  } catch (err) {
    console.log('save session error:', err)
    return false
  }
}
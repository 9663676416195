import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosTransporte from '../../components/transporte/FiltrosTransporte'
import {openModal} from '../../actions/common'
import {filtrarTransporte, seleccionarTodosTransporte, deSeleccionarTodosTransporte, cambioEstadoSeleccionTransporte, deleteSeleccionTransporte, crearCsvTransporte, 
  imprimirPdfTransporte} from '../../actions/transporte/transporte'
import {comboTransporte, comboCliente, comboEmpresaTransportista, comboConductoresTransportista, comboVehiculosTransportista, comboRuta} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.transporte,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      filtrarTransporte,
      seleccionarTodosTransporte,
      deSeleccionarTodosTransporte,
      cambioEstadoSeleccionTransporte,
      deleteSeleccionTransporte,
      comboEmpresaTransportista,
      comboConductoresTransportista,
      comboVehiculosTransportista,
      comboRuta,
      crearCsvTransporte,
      imprimirPdfTransporte
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosTransporte))

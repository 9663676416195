import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import NuevoVerraco from '../../components/fichaVerraco/NuevoVerraco'
import {comboLineaGeneticaActiva, comboRazaActiva, comboRaza, comboOperarioPredeterminado, comboSalaExtraccionActivo, comboCliente, comboUbicacion, comboTipoDosis, comboDireccionesClienteSinPredeterminado} from '../../actions/combos/combos'
import { submitNuevoVerraco, asignarCodRaza, restartVerracoDejandoFiltros, recuperarDatosModalEvolucion, deleteEvolucion, duplicarDatosModalEvolucion, getAlimentacion,
  recuperarDatosModalAlimentacion, deleteAlimentacion, duplicarDatosModalAlimentacion, getIndex, recuperarDatosModalIndex, deleteIndex,
  duplicarDatosModalIndex, abrirModalUbicacion, guardarYnuevoVerraco, abrirModalCambioEstadoVerraco, abrirModalVerracoCambioEstado, imprimirDetalleVerraco
} from '../../actions/fichaVerraco/fichaVerraco'
import { uploadFile, quitarDirectorioTemporal, eliminaImagen, subirArchivos, eliminarArchvio } from '../../actions/comun/comun'
import {ubicacionenesVacias} from '../../actions/movimientoVerraco/movimientoVerraco'
import { enviarFechaCambioEstado } from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: state.combos,
    fichaVerraco: {...state.fichaVerraco},
    movimientoVerraco: {...state.movimientoVerraco},
    formVerraco: state.form.nuevoVerraco,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    urlRecursos: setting.default.urlRecursos,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboLineaGeneticaActiva,
      comboRazaActiva,
      comboRaza,
      comboOperarioPredeterminado,
      comboSalaExtraccionActivo,
      submitNuevoVerraco,
      asignarCodRaza,
      comboCliente,
      comboUbicacion,
      comboTipoDosis,
      uploadFile,
      quitarDirectorioTemporal,
      eliminaImagen,
      subirArchivos,
      eliminarArchvio,
      restartVerracoDejandoFiltros,
      recuperarDatosModalEvolucion,
      deleteEvolucion,
      duplicarDatosModalEvolucion,
      getAlimentacion,
      recuperarDatosModalAlimentacion,
      deleteAlimentacion,
      duplicarDatosModalAlimentacion,
      getIndex,
      recuperarDatosModalIndex,
      deleteIndex,
      duplicarDatosModalIndex,
      abrirModalUbicacion,
      ubicacionenesVacias,
      guardarYnuevoVerraco,
      abrirModalCambioEstadoVerraco,
      abrirModalVerracoCambioEstado,
      imprimirDetalleVerraco,
      enviarFechaCambioEstado,
      comboDireccionesClienteSinPredeterminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NuevoVerraco))
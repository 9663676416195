import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Empresa from '../../components/empresa/Empresa'
import { comprobarEntorno } from '../../actions/auth/auth'
import { fetchEmpresa, editarEmpresa, abrirModalCentro, recuperarDatosCentro, duplicarCentro, cambiarEstadoCentro, eliminarCentro, seleccionarTodosEmpresa,
  deSeleccionarTodosEmpresa, deleteSeleccionCentros, cambioEstadoSeleccionCentros, crearCsvEmpresa, imprimirPdfEmpresa } from '../../actions/empresa/empresa'

export function mapStateToProps (state) {
  return {
    ...state.empresa,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comprobarEntorno,
      fetchEmpresa,
      editarEmpresa,
      abrirModalCentro,
      recuperarDatosCentro,
      duplicarCentro,
      cambiarEstadoCentro,
      eliminarCentro,
      seleccionarTodosEmpresa,
      deSeleccionarTodosEmpresa,
      deleteSeleccionCentros,
      cambioEstadoSeleccionCentros,
      crearCsvEmpresa,
      imprimirPdfEmpresa
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Empresa))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { login, comprobarEntorno, comprobarEntornoLocal } from '../../actions/auth/auth'
import Login from '../../components/auth/Login'

const mapStateToProps = (state, props) => {
  return {
    adminLogin: props.adminLogin || false,
    auth: {
      desautorizado: state.auth.desautorizado,
      expiredSession: state.auth.expiredSession,
      licenciaExpirada: state.auth.licenciaExpirada,
      isCloud: state.auth.isCloud
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      login,
      comprobarEntorno,
      comprobarEntornoLocal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login))
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosEntradaMercanciaPage from '../../containers/entradaMercancia/FiltrosEntradaMercanciaPage'
import ModalEntradaMercanciaPage from '../../containers/entradaMercancia/ModalEntradaMercanciaPage'
import './EntradaMercancia.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class EntradaMercancia extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.ALMACEN.ENTRADAS_MERCANCIAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }
  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listEntradaMercancia, checkedFiltrosEntradaMercancia, data, dataDuplicar,
      actions: {recuperarDatosModalEntradaMercancia, duplicarDatosModalEntradaMercancia, onDeleteEntradaMercancia}
    } = this.props

    listEntradaMercancia.forEach(
      (row) => row.idElemento = row.idEntradaAlmacen
    )

    const table = {
      id: 'entradaMercanciaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      hasOptions: true,
      mostrarCambioEstado: false,
      columns: [
        {id: 'numeroAlbaran', name: t('ALMACEN.ENTRADAS_MERCANCIA.N_ALBARAN')},
        {id: 'proveedor', name: t('ALMACEN.ENTRADAS_MERCANCIA.PROVEEDOR')},
        {id: 'origen', name: t('ALMACEN.ENTRADAS_MERCANCIA.ORIGEN')},
        {id: 'fecha', name: t('ALMACEN.ENTRADAS_MERCANCIA.FECHA'), type:'fecha'},
        {id: 'observaciones', name: t('ALMACEN.ENTRADAS_MERCANCIA.OBSERVACIONES')}
      ],
      rows: listEntradaMercancia,
      filtros: <FiltrosEntradaMercanciaPage />,
      onEdit: (row) => recuperarDatosModalEntradaMercancia(row.idEntradaAlmacen),
      data: data,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      duplicar: (row) =>duplicarDatosModalEntradaMercancia(row.idEntradaAlmacen),
      dataDuplicar: dataDuplicar,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1),
      onDelete: (row) => onDeleteEntradaMercancia(row.idEntradaAlmacen, row.proveedor, row.numeroAlbaran, row.origen),
      showModal: this.props.showModalEntradaMercancia,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosEntradaMercancia
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        {
        <ModalEntradaMercanciaPage
          duplicar={(row) =>duplicarDatosModalEntradaMercancia(row.idEntradaAlmacen)}
        />
        }
        <h2 className="page-title">{t('ALMACEN.ENTRADAS_MERCANCIA.TITLE')}</h2>
        <SimpleTablePage {...table}>
        </SimpleTablePage>
      </div>
    )
  }
}

export default EntradaMercancia
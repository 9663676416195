import actionTypes from '../../constants/actions/tipoInstalacion/tipoInstalacion'

export function initialState () {
  return {
    list: [],
    checkedFiltrosTipoInstalacion: false,
    filtros:{}
  }
}

export function fetchTipoInstalacionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalTipoInstalacionSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalTipoInstalacionSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function cambiarEstadoTipoInstalacionSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteTipoInstalacionSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosTipoInstalacion (state) {
  return {
    ...state,
    checkedFiltrosTipoInstalacion: true
  }
}

export function deSeleccionarTodosTipoInstalacion (state) {
  return {
    ...state,
    checkedFiltrosTipoInstalacion: false
  }
}

export function fetchFiltrarTipoInstalacionSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function submitNuevaTipoInstalacionSuccess (state, {insercionTipoInstalacion}) {
  return {
    ...state,
    insercionTipoInstalacion
  }
}

export function editarTipoInstalacionSuccess (state, {edicionTipoInstalacion}) {
  return {
    ...state,
    edicionTipoInstalacion
  }
}

export function crearCsvTipoInstalacionSuccess (state, {datosExcelTipoInstalacion}) {
  return {
    ...state,
    datosExcelTipoInstalacion
  }
}

export function imprimirPdfTipoInstalacionSuccess (state, {datosPdfTipoInstalacion}) {
  return {
    ...state,
    datosPdfTipoInstalacion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_TIPO_INSTALACION_SUCCESS:
      return fetchTipoInstalacionSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS:
      return recuperarDatosModalTipoInstalacionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS:
      return duplicarDatosModalTipoInstalacionSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_TIPO_INSTALACION_SUCCESS:
      return cambiarEstadoTipoInstalacionSuccess(state, action)
    case actionTypes.ON_DELETE_TIPO_INSTALACION_SUCCESS:
      return onDeleteTipoInstalacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TIPO_INSTALACION:
      return seleccionarTodosTipoInstalacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TIPO_INSTALACION:
      return deSeleccionarTodosTipoInstalacion(state, action)
    case actionTypes.FETCH_FILTRAR_TIPO_INSTALACION_SUCCESS:
      return fetchFiltrarTipoInstalacionSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_TIPO_INSTALACION_SUCCESS:
      return submitNuevaTipoInstalacionSuccess(state, action)
    case actionTypes.EDITAR_TIPO_INSTALACION_SUCCESS:
      return editarTipoInstalacionSuccess(state, action)
    case actionTypes.CREATE_CSV_TIPO_INSTALACION_SUCCESS:
      return crearCsvTipoInstalacionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_TIPO_INSTALACION_SUCCESS:
      return imprimirPdfTipoInstalacionSuccess(state, action)
    default:
      return state
  }
}
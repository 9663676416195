import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import { Row } from 'react-bootstrap'
import Button from '../comun/button/Button'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import { required } from '../../util/validationFunctions'
import { Warning } from '@material-ui/icons'


class ModalAsignacionPedido extends Component {
  constructor() {
    super()
    this.state = {

    }
    this.asignarDosisPedido = this.asignarDosisPedido.bind(this);
  }

  close(){    
    this.props.actions.closeModal(); 
  }

  asignarDosisPedido(){
    let dosis = this.props.modalForm.values.numeroDosis;
    this.props.actions.addDosisAPedido(this.props.idDosis,this.props.idAlbaranDosis,dosis)
    this.props.actions.closeModal(); 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dosisMaximas != this.props.dosisMaximas){      
      this.props.change('numeroDosis',this.props.dosisMaximas)
    }
    if (prevProps.albaranInfo != this.props.albaranInfo){     
      this.props.change('nombreRaza',this.props.albaranInfo.nombreRaza);
      this.props.change('tiposDosis',this.props.albaranInfo.tiposDosis)
      this.props.change('nombreDireccion',this.props.albaranInfo.nombreDireccion)
      this.props.change('dosisPedidas',this.props.albaranInfo.dosisPedidas)
      this.props.change('nombreLinea',this.props.albaranInfo.nombreLinea)
      this.props.change('nivelGenetico',this.props.albaranInfo.nivelGenetico)
    }
  }

  componentDidMount() {

  }

  render() {
    const {
      t, showModalAsignacionPedido, handleSubmit,
      dosisInfo, albaranInfo, dosisMaximas, 
      actions : {
        addDosisAPedido
      }      
      // auth: {separadorMil, separadorDec},      
    } = this.props    
    let advertenciaNivel = this.props.advertenciaNivel   

    const tKey = 'ALBARANES.MODAL.'
    const idElemento = 'idAlbaranDosis'

    return (
      <Modal show={showModalAsignacionPedido} dialogClassName="xl" backdrop="static">
        <form className="form-nuevo-asignacion-pedido ">
          <Modal.Header closeButton>
            <Modal.Title>
              {t('MENU.PEDIDO.ASIGNACION_PEDIDOS')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {/*<div>
                {JSON.stringify(dosisInfo)}
              </div>
              <div>
                {JSON.stringify(albaranInfo)}
              </div> */}
              <Field               
                colSm={3}
                id="nombreDireccion"
                name="nombreDireccion"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
                component={InputText}
                disabled={true}
              />
              <Field               
                colSm={3}
                id="tiposDosis"
                name="tiposDosis"
                controlLabel={t('ALBARANES.MODAL.COLUMNS.TIPO_DOSIS')}
                component={InputText}
                disabled={true}
              />
              <Field               
                colSm={3}
                id="nombreRaza"
                name="nombreRaza"
                controlLabel={t('ALBARANES.RAZA')}
                component={InputText}
                disabled={true}
              />
              <Field               
                colSm={3}
                id="nombreLinea"
                name="nombreLinea"
                controlLabel={t('ALBARANES.MODAL.COLUMNS.LINEA_GENETICA')}
                component={InputText}
                disabled={true}
              />
              <Field               
                colSm={3}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('ALBARANES.MODAL.COLUMNS.NIVEL_GENETICO')}
                component={InputText}
                disabled={true}
              />
              <Field               
                colSm={3}
                id="dosisPedidas"
                name="dosisPedidas"
                controlLabel={t('ALBARANES.MODAL.COLUMNS.DOSIS_PEDIDAS')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
                maxLength={true}
                valorMaxLength={40}
              />
              <Field               
                colSm={3}
                id="numeroDosis"
                name="numeroDosis"
                controlLabel={t('ALBARANES.MODAL.COLUMNS.DOSIS_ENVIADAS') + '*'}
                component={InputNumerical}
                numDecimales={0}
                validate={required}
                maxLength={true}
                valorMaxLength={40}
              />
              {advertenciaNivel && (
                <p className="error-verracos col-sm-6"><Warning/>{t('PEDIDOS.CLIENTE.MODAL.ASIGNACION_PEDIDOS.ADVERTENCIA_NIVEL_GENETICO')}</p>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.asignarDosisPedido.bind(this))}>{t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.BUTTONS.ANADIR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAsignacionPedido',
})(ModalAsignacionPedido))
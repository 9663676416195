import actionTypes from '../../constants/actions/asignacionPedidos/asignacionPedidos';
export function fetchLineasAlbaranes(filtros) {    
  return {
    type: actionTypes.FETCH_LINEAS_ALBARANES_PENDIENTES,
    filtros
  }
}

export function fetchLineasAlbaranesSuccess(listLineasAlbaranesPendientes, filtros) {
  return {
    type: actionTypes.FETCH_LINEAS_ALBARANES_PENDIENTES_SUCCESS,
    listLineasAlbaranesPendientes,
    filtros
  }
}

export function abrirModalAsignacionDosis(idDosis, idAlbaranDosis, idDireccionCliente, idRaza, idLinea, idTipoDosis, idNivelGenetico, advertenciaNivel) {    
  return {
    type: actionTypes.ABRIR_MODAL_ASIGNACION_DOSIS,
    idDosis,
    idAlbaranDosis,
    idDireccionCliente,
    idRaza,
    idLinea,
    idTipoDosis,
    idNivelGenetico,
    advertenciaNivel
  }
}

export function abrirModalAsignacionDosisSuccess(idDosis,idAlbaranDosis,idNivelGenetico,advertenciaNivel) {
  return {
    type: actionTypes.ABRIR_MODAL_ASIGNACION_DOSIS_SUCCESS,    
    idDosis,
    idAlbaranDosis,
    idNivelGenetico,
    advertenciaNivel
  }
}

export function cerrarModalAsignacionDosis() {
  return {
    type: actionTypes.CERRAR_MODAL_ASIGNACION_DOSIS_ASIGNACION
  }
}

export function addDosisAPedido(idDosis, idAlbaranDosis,dosis) {
  return {
    type: actionTypes.ADD_DOSIS_A_PEDIDO,
    idDosis,
    idAlbaranDosis,
    dosis
  }
}

export function addDosisAPedidoSuccess() {
  return {
    type: actionTypes.ADD_DOSIS_A_PEDIDO_SUCCESS,
  }
}

export function closeModal() {
  return {
    type: actionTypes.CLOSE_MODAL    
  }
}


export function actualizarFiltrosAsignacionPedidos(filtros) {
  return {
    type: actionTypes.ACTUALIZAR_FILTROS_ASIGNACION_PEDIDOS,
    filtros
  }
}

export function deleteSeleccionDosisEnvasadas(list){
  return {
    type: actionTypes.DELETE_SELECCION_DOSIS_ENVASADAS,
    list
  }
}

export default {
  closeModal,
  fetchLineasAlbaranes,
  fetchLineasAlbaranesSuccess,
  addDosisAPedido,
  addDosisAPedidoSuccess,
  abrirModalAsignacionDosis,
  abrirModalAsignacionDosisSuccess,
  cerrarModalAsignacionDosis,
  actualizarFiltrosAsignacionPedidos,
  deleteSeleccionDosisEnvasadas
}
import React, { Component } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesSeguimientoExtraccionesPoolPage from '../../containers/informes/FiltrosInformesSeguimientoExtraccionesPoolPage'
import {Bar} from 'react-chartjs-2';
import {date_formatter, number_formatter} from '../../util/formatFunctions'
import ModalExportarImprimirSeguimientoExtraccionesPoolPage from '../../containers/informes/ModalExportarImprimirSeguimientoExtraccionesPoolPage'
import {verracoSiNo as verracoSiNoConstants} from '../../constants/seguimiento'

class InformesSeguimientoExtraccionesPool extends Component {
  constructor () {
    super()
    this.state = {
      tabSeleccionada: 1,
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerDatosPanelControl(null)
  }

  componentDidUpdate (prevProps) {
    if (this.props.listInformeSeguimientoExtraccionesPool !== prevProps.listInformeSeguimientoExtraccionesPool || this.props.filtrosSeguimientoExtraccionesPool !== prevProps.filtrosSeguimientoExtraccionesPool){
      if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool).length > 0) {
        if (this.state.tabSeleccionada === 1) {
          if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool.extracciones).length > 0) {
            const values = this.props.filtrosSeguimientoExtraccionesPool
            values.idAnalisis = this.props.listInformeSeguimientoExtraccionesPool.extracciones[0].idAnalisis
            values.idTipo = this.props.listInformeSeguimientoExtraccionesPool.extracciones[0].extraccionPool
            this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
            var elems = document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            [].forEach.call(elems, function(el) {
               el.classList.remove("fila-seleccionada-informes");
            })
            document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[0].classList.add("fila-seleccionada-informes")
          } else {
            const values = this.props.filtrosSeguimientoExtraccionesPool
            values.idAnalisis = null
            values.idTipo = 1
            this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
          }
        } else if (this.state.tabSeleccionada === 2) {
          if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool.pool).length > 0) {
            const values = this.props.filtrosSeguimientoExtraccionesPool
            values.idPool = this.props.listInformeSeguimientoExtraccionesPool.pool[0].idPool
            values.idTipo = this.props.listInformeSeguimientoExtraccionesPool.pool[0].extraccionPool
            this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
            var elems = document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            [].forEach.call(elems, function(el) {
               el.classList.remove("fila-seleccionada-informes");
            })
            document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[0].classList.add("fila-seleccionada-informes")
          } else {
            const values = this.props.filtrosSeguimientoExtraccionesPool
            values.idPool = null
            values.idTipo = 1
            this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
          }
        }
      }
    }

    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }
  }

  handleSelect (key) {
    const values = this.props.filtrosSeguimientoExtraccionesPool
    if (key === 1) {
      this.setState({tabSeleccionada: 1})
      if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool.extracciones).length > 0) {
        values.idAnalisis = this.props.listInformeSeguimientoExtraccionesPool.extracciones[0].idAnalisis
        values.idTipo = this.props.listInformeSeguimientoExtraccionesPool.extracciones[0].extraccionPool
        this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
        var elems = document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function(el) {
           el.classList.remove("fila-seleccionada-informes");
        })
        document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[0].classList.add("fila-seleccionada-informes")
      }
      else{
        values.idAnalisis = null
        values.idTipo = 1
        this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
      }
    } else if (key === 2) {
      this.setState({tabSeleccionada: 2})
      if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool.pool).length > 0) {
        values.idPool = this.props.listInformeSeguimientoExtraccionesPool.pool[0].idPool
        values.idTipo = this.props.listInformeSeguimientoExtraccionesPool.pool[0].extraccionPool
        this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
        var elems = document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function(el) {
           el.classList.remove("fila-seleccionada-informes");
        })
        document.getElementById('seguimientoPoolTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[0].classList.add("fila-seleccionada-informes")
      }
      else{
        values.idPool = null
        values.idTipo = 2
        this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
      }
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeSeguimientoExtraccionesPool, totalesSeguimientoExtraccionesPool, listRegistrosSeguimientoExtraccionesPool,
      graficaRegistrosSeguimientoExtraccionesPool,
      auth:{formaFechaHora}
    } = this.props

    // grafica
    const colors = ['#395196', '#5498af', '#4ca1a0', '#56ccde', '#4db19b', '#5be6c7'];
    const options = {
      layout: {
        padding: {
          bottom: 0,
          top: 0
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 9
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontSize: 9
          }
            }],
      },
      responsive: true,
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: '#91929b',
            padding: 20
          }
        },
        plugins: {
          labels: false
        }
      };
    let datasets = []
    let fechas = []
    if (Object.keys(graficaRegistrosSeguimientoExtraccionesPool).length > 0){
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 1 || this.props.panelControl.datosPanel.metodoEvaluacion === 2 || this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
        datasets = datasets.concat(
        {
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.CALIDAD'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.calidad,
          stack: 2,
          backgroundColor: colors[1],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.AGLUTINACION'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.aglutinacion,
          stack: 3,
          backgroundColor: colors[2],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.FA'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.fa,
          stack: 4,
          backgroundColor: colors[3],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.MOTILIDAD'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.motilidad,
          stack: 1,
          backgroundColor: colors[0],
          borderWidth: 0
        })
      }
      else{
        datasets = datasets.concat(
        {
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.CALIDAD'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.calidad,
          stack: 2,
          backgroundColor: colors[1],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.AGLUTINACION'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.aglutinacion,
          stack: 3,
          backgroundColor: colors[2],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.FA'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.fa,
          stack: 4,
          backgroundColor: colors[3],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.TOTALES'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.totales,
          stack: 5,
          backgroundColor: colors[4],
          borderWidth: 0
        },{
          label: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.GRAFICA.PROGRESIVOS'),
          data: graficaRegistrosSeguimientoExtraccionesPool.datosGrafica.progresivos,
          stack: 6,
          backgroundColor: colors[5],
          borderWidth: 0
        })
      }
      fechas = graficaRegistrosSeguimientoExtraccionesPool.labels.map((f) => {
        return date_formatter(f, formaFechaHora, false)
      })
    }

    let chartData = {
      labels: fechas,
      datasets: datasets
    };

    const tableInformeSeguimientoExtraccionesPool = {
      id: 'informeSeguimientoExtraccionesPoolTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: 
        this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 || this.state.metodoEvaluacion === 4 ? ([
        {id: 'fechaExtraccion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FECHA_EXTRACCION'), type: 'fecha'},
        {id: 'codigoExtraccionPool', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CODIGO')},
        {id: 'fechaSeguimiento', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FECHA_SEGUIMIENTO'), type: 'fecha'},
        {id: 'concentracion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONCENTRACION'), type: 'numero'},
        {id: 'porMotilidad', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.MOTILIDAD'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CALIDAD'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.AGLUTINACION'), type: 'numero'},
        {id: 'formasAnormales', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FORMAS_ANORMALES'), type: 'numero'},
        {id: 'conservacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONSERVACION')},
        {id: 'contaminacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONTAMINACION')},
        {id: 'tipoControl', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.TIPO_CONTROL')},
      ]) : ([
        {id: 'fechaExtraccion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FECHA_EXTRACCION'), type: 'fecha'},
        {id: 'codigoExtraccionPool', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CODIGO')},
        {id: 'fechaSeguimiento', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FECHA_SEGUIMIENTO'), type: 'fecha'},
        {id: 'porMotilidadTotal', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.POR_TOTALES'), type: 'numero'},
        {id: 'porMotilidadProgresiva', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.POR_PROGRESIVOS'), type: 'numero'},
        {id: 'concentracion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONCENTRACION'), type: 'numero'},
        {id: 'calidad', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CALIDAD'), type: 'numero'},
        {id: 'aglutinacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.AGLUTINACION'), type: 'numero'},
        {id: 'formasAnormales', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.FORMAS_ANORMALES'), type: 'numero'},
        {id: 'conservacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONSERVACION')},
        {id: 'contaminacion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.CONTAMINACION')},
        {id: 'tipoControl', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA.TIPO_CONTROL')},
      ]),
      rows: listRegistrosSeguimientoExtraccionesPool,
      // filtros: <FiltrosInformesSeguimientoExtraccionesPoolPage />,
      initialValues: {}
    }

    // TABLA EXTRACCION
    const tableExtracciones = {
      id: 'seguimientoExtraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      informeSeguimientoExtracciones: true,
      columns: [
        {id: 'codigo', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_EXTRACCION.VERRACO_POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_EXTRACCION.FECHA'), type: 'fecha'},
        {id: 'numeroSeguimientos', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_EXTRACCION.NUM_SEG')}
      ],
      rows: Object.keys(listInformeSeguimientoExtraccionesPool).length > 0 ? listInformeSeguimientoExtraccionesPool.extracciones: [],
      leyenda: [
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.VERRACO'), color: '#FF6D29'},
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.POOL'), color: 'rgb(37, 56, 170)'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.extraccionPool == verracoSiNoConstants.VERRACO){
          classRow = 'stock-dosis-verraco'
        } else if (row.extraccionPool == verracoSiNoConstants.POOL){
          classRow = 'stock-dosis-pool'
        }
        return classRow
      }
    }

    // TABLA EXTRACCION
    const tablePool = {
      id: 'seguimientoPoolTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      informeSeguimientoPool: true,
      columns: [
        {id: 'nombre', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_POOL.POOL')},
        {id: 'fechaExtraccion', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_POOL.FECHA'), type: 'fecha'},
        {id: 'numeroSeguimientos', name: t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TABLA_POOL.NUM_SEG')}
      ],
      rows: Object.keys(listInformeSeguimientoExtraccionesPool).length > 0 ? listInformeSeguimientoExtraccionesPool.pool : [],
      initialValues: {}
    }

    return (
      <div>
      <ModalExportarImprimirSeguimientoExtraccionesPoolPage />
        <h2 className="page-title">{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.TITLE')}</h2>
        <FiltrosInformesSeguimientoExtraccionesPoolPage tabSeleccionada={this.state.tabSeleccionada} />
        <Row>
          <Col sm={4}>
            <SimpleTablePage {...tableExtracciones}></SimpleTablePage>
          </Col>
          <Col sm={8}>
            <SimpleTablePage {...tableInformeSeguimientoExtraccionesPool}></SimpleTablePage>
            {
              Object.keys(graficaRegistrosSeguimientoExtraccionesPool).length > 0 && graficaRegistrosSeguimientoExtraccionesPool !== null ? (
                <Bar
                  data={chartData}
                  options={options}
                  width={700}
                  height={200}
                />
              ) : null
            }
          </Col>
        </Row>
      </div>
    )
  }
}

export default InformesSeguimientoExtraccionesPool
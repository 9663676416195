import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import {server as version} from './../../constants/comun'

class Footer extends PureComponent {
  redirigirMagapor () {
    window.open("https://magapor.com/", '_blank')
  }
  render () {
    const { t, sm, auth } = this.props
    return (
      <Col sm={sm} componentClass="footer" >
        <p className="footer-link" onClick={() => this.redirigirMagapor()}>Gesipor 360 {(auth.version)&&'v'+auth.version+'.'+version.VERSION} {t('FOOTER.TEXTO_PIE')}</p>
      </Col>
    )
  }
}

export default Footer

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import CopiaSeguridad from '../../components/copiaSeguridad/CopiaSeguridad'
import {fetchCopiasSeguridad, descargarCopiaSeguridad, onDeleteCopiaSeguridad, restaurarCopiaSeguridad} from '../../actions/copiaSeguridad/copiaSeguridad'

export function mapStateToProps (state) {
  return {
    ...state.copiaSeguridad,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchCopiasSeguridad,
      descargarCopiaSeguridad,
      onDeleteCopiaSeguridad,
      restaurarCopiaSeguridad
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CopiaSeguridad))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'

class FiltrosInformesClientes extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeCliente({})
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboVerraco()
    this.props.actions.comboProvinciasClientes()
    this.props.actions.comboCliente()
    this.props.actions.comboTipoDosis()
    this.props.actions.fetchComboPool()
    this.props.actions.comboRuta()
  }

  obtenerInformeCliente(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    if (values.hasOwnProperty('idClienteAlbaran')) {
      delete values.idClienteAlbaran
    }
    this.props.actions.obtenerInformeCliente(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesClientes !== prevProps.datosPdfInformesClientes) {
      if (this.props.datosPdfInformesClientes.pdf && this.props.datosPdfInformesClientes.pdf !== null){
        let pdf = this.props.datosPdfInformesClientes.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.CLIENTES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesClientes() {
    this.props.actions.imprimirPdfInformesClientes(this.props.filtrosCliente)
  }

  crearCsvInformeClientes() {
    this.props.actions.crearCsvInformeClientes(this.props.filtrosCliente)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosCliente
    if (value === 'hasta') {
      values.fechaSalida = null
      this.props.change('fechaSalida', null)
    }
    if (value === 'desde') {
      values.fechaEntrada = null
      this.props.change('fechaEntrada', null)
    }
    values[value] = null
    this.props.actions.obtenerInformeCliente(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosCliente,
    combos: {comboNombreProductos, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria, comboVerraco, comboNombreGrupoVerraco, comboOperario, comboEstadoVerraco,
    comboUbicacion, comboProvinciasClientes, comboCliente, comboTipoDosis, comboPool, comboRuta}
  } = this.props

  const comboTerminado= [
      {value: true, label: t('COMUN.COMBOS.TERMINADO.SI')},
      {value: false, label: t('COMUN.COMBOS.TERMINADO.NO')}
    ]

  const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="idCliente"
              name="idCliente"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.CLIENTE')}
              component={InputSelect}
              options={comboCliente}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="provincia"
              name="provincia"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.PROVINCIA')}
              component={InputSelect}
              options={comboProvinciasClientes}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboDosisFiltros}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="pool"
              name="pool"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.POOL')}
              component={InputSelect}
              options={comboPool}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="idLinea"
              name="idLinea"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="nombreDireccion"
              name="nombreDireccion"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
              component={InputText}
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              id="sinAlbaranDesde"
              name="sinAlbaranDesde"
              component={InputDatePicker}
              colSm={2}
              controlLabel={t('INFORMES.CLIENTES.FILTROS.SIN_ALBARAN_DESDE')}
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="ruta"
              name="ruta"
              controlLabel={t('INFORMES.CLIENTES.FILTROS.RUTA')}
              component={InputSelect}
              options={comboRuta}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeCliente.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.idCliente && filtrosCliente.idCliente !== null && filtrosCliente.idCliente !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.CLIENTE') + ': ' + filtrosCliente.idCliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idCliente')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.provincia && filtrosCliente.provincia !== null && filtrosCliente.provincia !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.PROVINCIA') + ': ' + filtrosCliente.provincia.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('provincia')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.tipoDosis && filtrosCliente.tipoDosis !== null && filtrosCliente.tipoDosis !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.TIPO_DOSIS') + ': ' + filtrosCliente.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.verraco && filtrosCliente.verraco !== null && filtrosCliente.verraco !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.VERRACO') + ': ' + filtrosCliente.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.pool && filtrosCliente.pool !== null && filtrosCliente.pool !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.POOL') + ': ' + filtrosCliente.pool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('pool')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.idLinea && filtrosCliente.idLinea !== null && filtrosCliente.idLinea !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.LINEA_GENETICA') + ': ' + filtrosCliente.idLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idLinea')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.raza && filtrosCliente.raza !== null && filtrosCliente.raza !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.RAZA') + ': ' + filtrosCliente.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    filtrosCliente.nombreDireccion && filtrosCliente.nombreDireccion !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + ': ' + filtrosCliente.nombreDireccion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreDireccion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.fechaEntrada && filtrosCliente.fechaSalida && filtrosCliente.fechaEntrada !== null && filtrosCliente.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosCliente.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosCliente.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtrosCliente.fechaEntrada && filtrosCliente.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosCliente.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtrosCliente.fechaSalida && filtrosCliente.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosCliente.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.sinAlbaranDesde && filtrosCliente.sinAlbaranDesde !== null && filtrosCliente.sinAlbaranDesde !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.SIN_ALBARAN_DESDE') + ': ' + filtrosCliente.sinAlbaranDesde}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('sinAlbaranDesde')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosCliente).length > 0 && (
                    (filtrosCliente.ruta && filtrosCliente.ruta !== null && filtrosCliente.ruta !== '') ? (
                      <span className="badge-dark">{t('INFORMES.CLIENTES.FILTROS.RUTA') + ': ' + filtrosCliente.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformeClientes()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesClientes()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesClientes',
})(FiltrosInformesClientes))

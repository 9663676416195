import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { requestResetPassword } from '../../actions/auth/auth'
import RequestResetPassword from '../../components/auth/RequestResetPassword'

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      requestResetPassword
    }, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(RequestResetPassword))
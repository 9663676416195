import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row } from 'react-bootstrap'
import Button from '../comun/button/Button'
// import InputText from '../comun/form/InputText'
// import InputNumerical from '../comun/form/InputNumerical'
// import InputSelect from '../comun/form/InputSelect'
// import InputDatePicker from '../comun/form/InputDatePicker'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosMaterialDisponiblePage from '../../containers/trazabilidadMaterial/FiltrosMaterialDisponiblePage'

class ModalMaterialDisponible extends Component {
  render () {
    const {
      t, lotes, showModal, checkedFiltrosModalMaterialDisponible, numeroRegistrosPorPagina, paginaActual,
      actions: {guardarModalMaterialDisponible, closeModalMaterialDisponible}
    } = this.props

    const tKey = 'ANALISIS_EXTRACCIONES.MATERIAL_DISPONIBLE.'
    const idElemento = 'idEntradaAlmacenProducto'
    const idForm = 'listadoMaterialDisponibleTable'

    const tableMaterialDisponible = {
      id: 'listadoMaterialDisponibleTable',
      form: idForm,
      idElemento,
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarEliminar: false,
      columns: [
        {id: 'nombre', name: t(tKey + 'COLUMNS.PRODUCTO')},
        {id: 'lote', name: t(tKey + 'COLUMNS.LOTE')},
        {id: 'stock', name: t(tKey + 'COLUMNS.STOCK'), type: 'numero'},
        {id: 'caducidad', name: t(tKey + 'COLUMNS.FECHA_CADUCIDAD'), type: 'fecha'},
        {id: 'abierto', name: t(tKey + 'COLUMNS.ABIERTO')}
      ],
      rows: lotes,
      filtros: <FiltrosMaterialDisponiblePage idElemento={idElemento} idForm={idForm} />,
      initialValues: {}
    }
    tableMaterialDisponible.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableMaterialDisponible.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosModalMaterialDisponible
        }
      }
    )

    return (
      <Modal show={showModal} onHide={closeModalMaterialDisponible} bsSize="large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SimpleTablePage {...tableMaterialDisponible} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={guardarModalMaterialDisponible}>{t(tKey + 'BUTTONS.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalMaterialDisponible}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}


export default (reduxForm({
  form: 'ModalMaterialDisponible',
})(ModalMaterialDisponible))
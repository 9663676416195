export const estadoVerraco = {
  CUARENTENA: 1,
  PRESENTADO: 2,
  ACTIVO: 3,
  ENFERMO: 4,
  BAJA: 5,
  MUERTO: 6
}

export const translateEstadoVerraco = {
  1: 'CUARENTENA',
  2: 'PRESENTADO',
  3: 'ACTIVO',
  4: 'ENFERMO',
  5: 'BAJA',
  6: 'MUERTO'
}
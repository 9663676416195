import React, { Component } from 'react'
import {reduxForm, Field, initialize} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../../comun/form/InputCheckBox'
import { Refresh, Delete, Launch, Print, AddCircleOutline } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../../constants/permisos'

class FiltrosTipoAlimentacion extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelTipoAlimentacion !== prevProps.datosExcelTipoAlimentacion) {
      if (this.props.datosExcelTipoAlimentacion.excel && this.props.datosExcelTipoAlimentacion.excel !== null){
        let pdf = this.props.datosExcelTipoAlimentacion.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.TIPOS_ALIMENTACION') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfTipoAlimentacion !== prevProps.datosPdfTipoAlimentacion) {
      if (this.props.datosPdfTipoAlimentacion.pdf && this.props.datosPdfTipoAlimentacion.pdf !== null){
        let pdf = this.props.datosPdfTipoAlimentacion.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.ALIMENTACION.TIPOS_ALIMENTACION') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosTipoAlimentacion()
          }
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosTipoAlimentacion()
    } else {
      this.props.actions.deSeleccionarTodosTipoAlimentacion()
    }
  }

  cambiarEstadoSeleccionTipoAlimentacion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idTipoAlimentacion': row.idTipoAlimentacion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionTipoAlimentacion(list)
  }

  deleteSeleccionTipoAlimentacion(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idTipoAlimentacion': row.idTipoAlimentacion
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionTipoAlimentacion(list)
  }

  crearCsvTipoAlimentacion(){
    this.props.actions.crearCsvTipoAlimentacion()
  }

  imprimirPdfTipoAlimentacion(){
    this.props.actions.imprimirPdfTipoAlimentacion()
  }

  render () {
  const {
    t, handleSubmit,
    actions: {abrirModalVacioTipoAlimentacion}
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={12}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionTipoAlimentacion()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionTipoAlimentacion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvTipoAlimentacion()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfTipoAlimentacion()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1) ? abrirModalVacioTipoAlimentacion : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosTipoAlimentacion',
  enableReinitialize: true
})(FiltrosTipoAlimentacion))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesProductividadCentro from '../../components/informes/FiltrosInformesProductividadCentro'
import {obtenerInformeProductividadCentro, imprimirPdfInformesProductividadCentro, crearCsvInformeProductividadCentro} from '../../actions/informes/informes'
import {comboVerraco, comboTipoDosis, comboRaza, comboLineaGenetica } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos,
   formFiltrosProCentro: state.form.FiltrosInformesProductividadCentro
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeProductividadCentro,
      comboVerraco,
      comboTipoDosis,
      comboRaza,
      comboLineaGenetica,
      imprimirPdfInformesProductividadCentro,
      crearCsvInformeProductividadCentro
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesProductividadCentro))
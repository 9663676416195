import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import Button from '../comun/button/Button'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosModalPlaning extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: true,
      fitrosSeleccionados: []
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  componentDidMount () {
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacion()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboOperario()
    this.props.actions.comboVerracosPresentesActivos()
    this.props.actions.comboIndex()
    this.props.actions.comboNombreGrupoVerracoActiva()
    this.props.actions.comboVerraco({}, true)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.dataExperotarSugerenciasExtracciones !== prevProps.dataExperotarSugerenciasExtracciones) {
      if (this.props.dataExperotarSugerenciasExtracciones.excel && this.props.dataExperotarSugerenciasExtracciones.excel !== null){
        let pdf = this.props.dataExperotarSugerenciasExtracciones.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.FILTROS.SUGERENCIAS_EXTRACCION') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.dataImprimirSugerenciasExtracciones !== prevProps.dataImprimirSugerenciasExtracciones) {
      if (this.props.dataImprimirSugerenciasExtracciones.pdf && this.props.dataImprimirSugerenciasExtracciones.pdf !== null){
        let pdf = this.props.dataImprimirSugerenciasExtracciones.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.FILTROS.SUGERENCIAS_EXTRACCION') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  filtrarVerracosPlaning (values) {
    values.list = this.props.listVerracosAsignados
    this.props.actions.asignarVerracos(values)
  }

  intercambiarCheckVerracosPlaning (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosVerracosPlaning()
    } else {
      this.props.actions.deSeleccionarTodosVerracosPlaning()
    }
  }

  quitarFiltros (value) {
    let values = this.props.filtrosSugerenciasPlaning
    values[value] = null
    this.props.actions.asignarVerracos(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  exportarSugerenciaExtracciones () {
    this.props.actions.exportarSugerenciaExtracciones(this.props.filtrosSugerenciasPlaning)
  }
  imprimirSugerenciaExtracciones () {
    this.props.actions.imprimirSugerenciaExtracciones(this.props.filtrosSugerenciasPlaning)
  }

  render () {
  const {
    t, handleSubmit, filtrosSugerenciasPlaning,
    combos: {comboRaza, comboLineaGenetica, comboUbicacion, comboNombreGrupoVerraco, comboOperario, comboVerracosPresentesActivos, comboIndex, comboNombreGrupoVerracoActiva, comboVerraco}
  } = this.props

  const comboNumeroDiasSinExtraer = [
    {value: 1, label: 1},{value: 2, label: 2},{value: 3, label: 3},{value: 4, label: 4},{value: 5, label: 5},{value: 6, label: 6},{value: 7, label: 7},{value: 8, label: 8},
    {value: 9, label: 9},{value: 10, label: 10},
    {value: 11, label: 11},{value: 12, label: 12},{value: 13, label: 13},{value: 14, label: 14},{value: 15, label: 15},{value: 16, label: 16},{value: 17, label: 17},{value: 18, label: 18},
    {value: 19, label: 19},{value: 20, label: 20},
    {value: 21, label: 21},{value: 22, label: 22},{value: 23, label: 23},{value: 24, label: 24},{value: 25, label: 25},{value: 26, label: 26},{value: 27, label: 27},{value: 28, label: 28},
    {value: 29, label: 29},{value: 30, label: 30}
  ]

  let comboEstadoPlaning = this.props.combos.comboEstadoPlaning
  comboEstadoPlaning = comboEstadoPlaning.map((comboEstado) => {
    return {...comboEstado, label: t(comboEstado.label)}
  })

  return (
      <div style={{display: 'grid'}}>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          {/*<Field
            colSm={2}
            id="fecha"
            name="fecha"
            controlLabel={t('PLANING.MODAL.FILTROS.FECHA')}
            component={InputDatePicker}
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />*/}
          <Field
            colSm={2}
            id="diasSinExtraer"
            name="diasSinExtraer"
            controlLabel={t('PLANING.MODAL.FILTROS.NUM_DIAS_SIN_EXTRAER_MINMO')}
            component={InputSelect}
            options={comboNumeroDiasSinExtraer}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="raza"
            name="raza"
            controlLabel={t('PLANING.MODAL.FILTROS.RAZA')}
            component={InputSelect}
            options={comboRaza}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="lineaGenetica"
            name="lineaGenetica"
            controlLabel={t('PLANING.MODAL.FILTROS.LINEA_GENETICA')}
            component={InputSelect}
            options={comboLineaGenetica}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="estado"
            name="estado"
            controlLabel={t('PLANING.MODAL.FILTROS.ESTADO')}
            component={InputSelect}
            options={comboEstadoPlaning}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="ubicacion"
            name="ubicacion"
            controlLabel={t('PLANING.MODAL.FILTROS.UBICACION')}
            component={InputSelect}
            options={comboUbicacion}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          {/*<Field
            colSm={2}
            id="operarioColecta"
            name="operarioColecta"
            controlLabel={t('PLANING.MODAL.FILTROS.OPERARIO_COLECTA')}
            component={InputSelect}
            options={comboOperario}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />*/}
          <Field
            colSm={2}
            id="verraco"
            name="verraco"
            controlLabel={t('PLANING.MODAL.FILTROS.VERRACO')}
            component={InputSelect}
            options={comboVerraco}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="ebv"
            name="ebv"
            controlLabel={t('PLANING.MODAL.FILTROS.EBV')}
            component={InputSelect}
            options={comboIndex}
            valueKey="value"
            labelKey="label"
            customClass='nombre-operario'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
          <Field
            colSm={2}
            id="grupoVerraco"
            name="grupoVerraco"
            controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.GRUPO_VERRACO')}
            component={InputSelect}
            options={comboNombreGrupoVerracoActiva}
            valueKey="value"
            labelKey="label"
            customClass='select-ubicacion'
            onInputChange={handleSubmit(this.filtrarVerracosPlaning.bind(this))}
          />
        </div>
        <div className={ Object.keys(filtrosSugerenciasPlaning).length > 1 ? 'aplicar-tabla-filtros' : 'aplicar-tabla-filtros no-borde-filtros'}>
          <div className="filtros-anadidos">
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.fecha && filtrosSugerenciasPlaning.fecha !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.FECHA') + ': ' + date_formatter(filtrosSugerenciasPlaning.fecha, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fecha')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.diasSinExtraer && filtrosSugerenciasPlaning.diasSinExtraer !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.NUM_DIAS_SIN_EXTRAER_MINMO') + ': ' + filtrosSugerenciasPlaning.diasSinExtraer.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('diasSinExtraer')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.raza && filtrosSugerenciasPlaning.raza !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.RAZA') + ': ' + filtrosSugerenciasPlaning.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.lineaGenetica && filtrosSugerenciasPlaning.lineaGenetica !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.LINEA_GENETICA') + ': ' + filtrosSugerenciasPlaning.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.estado && filtrosSugerenciasPlaning.estado !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.ESTADO') + ': ' + filtrosSugerenciasPlaning.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.ubicacion && filtrosSugerenciasPlaning.ubicacion !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.UBICACION') + ': ' + filtrosSugerenciasPlaning.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                ) : null
              )
            }
            {/*
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.operarioColecta && filtrosSugerenciasPlaning.operarioColecta !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.OPERARIO_COLECTA') + ': ' + filtrosSugerenciasPlaning.operarioColecta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operarioColecta')}> x </button></span>
                ) : null
              )
            */}
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.verraco && filtrosSugerenciasPlaning.verraco !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.VERRACO') + ': ' + filtrosSugerenciasPlaning.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.ebv && filtrosSugerenciasPlaning.ebv !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.EBV') + ': ' + filtrosSugerenciasPlaning.ebv.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                ) : null
              )
            }
            {
              Object.keys(filtrosSugerenciasPlaning).length > 0 && (
                filtrosSugerenciasPlaning.grupoVerraco && filtrosSugerenciasPlaning.grupoVerraco !== null ? (
                  <span className="badge-dark">{t('PLANING.MODAL.FILTROS.EBV') + ': ' + filtrosSugerenciasPlaning.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                ) : null
              )
            }
          </div>
        </div>
        <Row className="filtros-tabla-modal-verracos acciones-tabla-filtros">
          <Col sm={10}>
          {/*
            <button type="button" className="btn btn-icono" style={{marginRight: '5px'}} onClick={() => this.exportarSugerenciaExtracciones()}>
              <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
            </button>
            <button type="button" className="btn btn-icono" onClick={() => this.imprimirSugerenciaExtracciones()}>
              <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
            </button>
          */}
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalPlaning',
})(FiltrosModalPlaning))

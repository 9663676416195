import actionTypes from '../../../constants/actions/planSanitario/registroAlimentacion/registroAlimentacion'

export function initialState () {
  return {
    list: [],
    checkedFiltrosRegistroAlimentacion: false,
    filtros: {},
    showModalVerracoRegistroAlimentacion: false,
    verracosAnadidos: [],
    listVerracosEnRegistroAlimentacion: [],
    checkedVerracosRegistroAlimentacion: false,
    filtrosModalVerracoRegistroAlimentacion: [],
    checkedModalRegistroAlimentacion: false,
    data: [],
    dataDuplicar: [],
    datosExcelRegistroAlimentacion: [],
    datosPdfRegistroAlimentacion: [],
    showModalRegistroAlimentacion: false
  }
}

export function fetchRegistroAlimentacionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function openModalRegistroAlimentacion (state) {
  return {
    ...state,
    showModalRegistroAlimentacion: true
  }
}

export function closeModalRegistroAlimentacion (state) {
  return {
    ...state,
    showModalRegistroAlimentacion: false,
    data: [],
    dataDuplicar: []
  }
}

export function seleccionarTodosRegistroAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosRegistroAlimentacion: true
  }
}

export function deSeleccionarTodosRegistroAlimentacion (state) {
  return {
    ...state,
    checkedFiltrosRegistroAlimentacion: false
  }
}

export function recuperarDatosModalRegistroAlimentacionSuccess (state, {data}) {
  return {
    ...state,
    data,
    verracosAnadidos: data.verracos,
    dataDuplicar: []
  }
}

export function duplicarDatosModalRegistroAlimentacionSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar,
    verracosAnadidos: dataDuplicar.verracos,
    data: []
  }
}

export function fetchFiltrarRegistroAlimentacionSuccess (state, {filtros}){
  return {
    ...state,
    filtros
  }
}

export function abrirModalVerracoRegistroAlimentacion (state){
  return {
    ...state,
    showModalVerracoRegistroAlimentacion: true
  }
}

export function cerrarModalVerracoRegistroAlimentacion (state){
  return {
    ...state,
    showModalVerracoRegistroAlimentacion: false
  }
}

export function fetchVerracosRegistroAlimentacionSuccess(state, {listVerracosEnRegistroAlimentacion}){
  return{
    ...state,
    listVerracosEnRegistroAlimentacion: listVerracosEnRegistroAlimentacion
  }
}

export function seleccionarTodosModalVerracoRegistroAlimentacion (state) {
  return {
    ...state,
    checkedVerracosRegistroAlimentacion: true
  }
}

export function deSeleccionarTodosModalVerracoRegistroAlimentacion (state) {
  return {
    ...state,
    checkedVerracosRegistroAlimentacion: false
  }
}

export function anadirVerracoModalRegistroAlimentacionSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos: state.verracosAnadidos.concat(verracosAnadidos)
  }
}

export function fetchFiltrarVerracoModalRegistroAlimentacionSuccess (state, {filtrosModalVerracoRegistroAlimentacion}) {
  return {
    ...state,
    filtrosModalVerracoRegistroAlimentacion: filtrosModalVerracoRegistroAlimentacion
  }
}

export function seleccionarTodosModalRegistroAlimentacion (state) {
  return {
    ...state,
    checkedModalRegistroAlimentacion: true
  }
}

export function deSeleccionarTodosModalRegistroAlimentacion (state) {
  return {
    ...state,
    checkedModalRegistroAlimentacion: false
  }
}

export function onDeleteVerracoAnadidoRegistroAlimentacionSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos,
    checkedModalRegistroAlimentacion: false
  }
}

export function resetearVerracos (state) {
  return {
    ...state,
    verracosAnadidos: []
  }
}

export function crearCsvRegistroAlimentacionSuccess (state, {datosExcelRegistroAlimentacion}) {
  return {
    ...state,
    datosExcelRegistroAlimentacion
  }
}

export function imprimirPdfRegistroAlimentacionSuccess (state, {datosPdfRegistroAlimentacion}) {
  return {
    ...state,
    datosPdfRegistroAlimentacion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTRO_ALIMENTACION_SUCCESS:
      return fetchRegistroAlimentacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_REGISTRO_ALIMENTACION:
      return seleccionarTodosRegistroAlimentacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_REGISTRO_ALIMENTACION:
      return deSeleccionarTodosRegistroAlimentacion(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS:
      return recuperarDatosModalRegistroAlimentacionSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS:
      return duplicarDatosModalRegistroAlimentacionSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_REGISTRO_ALIMENTACION_SUCCESS:
      return fetchFiltrarRegistroAlimentacionSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VERRACO_REGISTRO_ALIMENTACION:
      return abrirModalVerracoRegistroAlimentacion(state, action)
    case actionTypes.CERRAR_MODAL_VERRACO_REGISTRO_ALIMENTACION:
      return cerrarModalVerracoRegistroAlimentacion(state, action)
    case actionTypes.FETCH_VERRACOS_REGISTRO_ALIMENTACION_SUCCESS:
      return fetchVerracosRegistroAlimentacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION:
      return seleccionarTodosModalVerracoRegistroAlimentacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION:
      return deSeleccionarTodosModalVerracoRegistroAlimentacion(state, action)
    case actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS:
      return anadirVerracoModalRegistroAlimentacionSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS:
      return fetchFiltrarVerracoModalRegistroAlimentacionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION:
      return seleccionarTodosModalRegistroAlimentacion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION:
      return deSeleccionarTodosModalRegistroAlimentacion(state, action)
    case actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION_SUCCESS:
      return onDeleteVerracoAnadidoRegistroAlimentacionSuccess(state, action)
    case actionTypes.RESETEAR_VERRACOS:
      return resetearVerracos(state, action)
    case actionTypes.CREATE_CSV_REGISTRO_ALIMENTACION_SUCCESS:
      return crearCsvRegistroAlimentacionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_REGISTRO_ALIMENTACION_SUCCESS:
      return imprimirPdfRegistroAlimentacionSuccess(state, action)
    case actionTypes.OPEN_MODAL_REGISTRO_ALIMENTACION:
      return openModalRegistroAlimentacion(state, action)
    case actionTypes.CLOSE_MODAL_REGISTRO_ALIMENTACION:
      return closeModalRegistroAlimentacion(state, action)
    default:
      return state
  }
}
import {call, put, takeLatest, all, select} from 'redux-saga/effects'
import {initialize, getFormValues} from 'redux-form'
import moment from 'moment'
import actionTypes from '../../constants/actions/planing/planing'
import {fetchPlaningSuccess, fetchFiltrarPlaningSuccess, asignarVerracosSuccess, closeModalPlaning, anadirVerracosPlaning, onDeleteVerracoPlaningSuccess, obtenerVerracosSinExtraerSuccess,
  abrirModalInicioPlaning, imprimirModalInicioPlaningSuccess, deSeleccionarTodosModalPlaning, exportarModalInicioPlaningSuccess, totalesPlaningSuccess, totalesModalPlaningSuccess, totalesPlaningAlbaranSuccess, exportarPlaningSuccess,
  imprimirPlaningSuccess, cerrarModalExportarImprimirPlaning, filtrosModalSugerenciasPlaning, exportarSugerenciaExtraccionesSuccess, imprimirSugerenciaExtraccionesSuccess, closeModalInicioPlaning, enviarFechaDuplicarPlaningSuccess, cerrarDuplicarPlaning, deSeleccionarTodosPlanningPrincipal, obtenerPrevisionVerracosSuccess
} from '../../actions/planing/planing'
import {dateTimeZones} from '../../util/formatFunctions'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {yesNoModal} from '../modal/yesNoModal'
import getFiltrarPlaning from '../../services/planing/getFiltrarPlaning'
import addPlaning from '../../services/planing/addPlaning'
import deletePlaning from '../../services/planing/deletePlaning'
import getSugerenciasExtraccion from '../../services/planing/getSugerenciasExtraccion'
import annadirSugerenciasExtraccionPlanning from '../../services/planing/annadirSugerenciasExtraccionPlanning'
import getVerracosPlanificados from '../../services/planing/getVerracosPlanificados'
import getVerracosSinExtraer from '../../services/planing/getVerracosSinExtraer'
import getImprimirPdfInicioPlaning from '../../services/planing/getImprimirPdfInicioPlaning'
import generarExcelVerracosPlanificados from '../../services/planing/generarExcelVerracosPlanificados'
import getTotalesPlanning from '../../services/planing/getTotalesPlanning'
import getTotalesPlanningAlbaran from '../../services/planing/getTotalesPlanningAlbaran'
import generarExcelPlanning from '../../services/planing/generarExcelPlanning'
import imprimirPlanning from '../../services/planing/imprimirPlanning'
import generarExcelSugerenciasExtraccionPlanning from '../../services/planing/generarExcelSugerenciasExtraccionPlanning'
import imprimirSugerenciasExtraccionPlanning from '../../services/planing/imprimirSugerenciasExtraccionPlanning'
import addSeleccionPlanning from '../../services/planing/addSeleccionPlanning'
import deleteSeleccionPlanning from '../../services/planing/deleteSeleccionPlanning'
import getPlaningPorFecha from '../../services/planing/getPlaningPorFecha'
import insertarDuplicarPlanning from '../../services/planing/insertarDuplicarPlanning'
import imprimirEtiquetaVerracos from '../../services/planing/imprimirZebraVerracos'
import getPrevisionVerracos from '../../services/planing/getPrevisionVerracos'

export function * fetchFiltrarPlaning ({values}) {
  try {
    console.log('sagas-fetchFiltrarPlaning',values)
    yield recalcularTotales(values)
    const state = yield select(state => state)    
    const valuesFormPlaning = yield getFormValues('formPlaning')(state)
    const valuesFiltrosPlaning = yield getFormValues('FiltrosPlaning')(state)
    const list = yield call(getFiltrarPlaning, {...valuesFormPlaning, ...valuesFiltrosPlaning, ...values}, state.auth.token)
    yield put(fetchPlaningSuccess(list))
    yield put(fetchFiltrarPlaningSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * recalcularTotales(values) {
  try {    
    yield call(totalesPlaning, {values})
    yield call(totalesPlaningAlbaran)
  } catch(error){
    console.log(error)
  }
}
export function * watchFetchFiltrarPlaning () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_PLANING, fetchFiltrarPlaning)
}

export function * totalesPlaning ({values, desdeSugerenciasExtraccion = false}) {
  try {
    const state = yield select(state => state)
    let newValues = {}

    if(desdeSugerenciasExtraccion) {
      const valuesModalPlaning = yield getFormValues('ModalPlaning')(state)
      const valuesFiltrosModalPlaning = yield getFormValues('FiltrosModalPlaning')(state)
      newValues = {...valuesModalPlaning, ...valuesFiltrosModalPlaning, ...values}
    } else {
      const valuesFormPlaning = yield getFormValues('formPlaning')(state)
      const valuesFiltrosPlaning = yield getFormValues('FiltrosPlaning')(state)
      newValues = {...valuesFormPlaning, ...valuesFiltrosPlaning, ...values}
    }

    const datosTotalesPlaning = yield call(getTotalesPlanning, state.auth.token, newValues)
    if (desdeSugerenciasExtraccion) {
      yield put(totalesModalPlaningSuccess(datosTotalesPlaning))
    } else {
      yield put(totalesPlaningSuccess(datosTotalesPlaning))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchTotalesPlaning () {
  yield takeLatest(actionTypes.TOTALES_PLANING, totalesPlaning)
}

export function * totalesPlaningAlbaran () {
  try {
    const state = yield select(state => state)
    let newValues = {}
    const valuesFormPlaning = yield getFormValues('formPlaning')(state)
    const valuesFiltrosPlaning = yield getFormValues('FiltrosPlaning')(state)
    newValues = {...valuesFormPlaning, ...valuesFiltrosPlaning}
    const datosTotalesPlaningAlbaran = yield call(getTotalesPlanningAlbaran, state.auth.token, newValues)
    yield put(totalesPlaningAlbaranSuccess(datosTotalesPlaningAlbaran))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchTotalesPlaningAlbaran () {
  yield takeLatest(actionTypes.TOTALES_PLANING_ALBARAN, totalesPlaningAlbaran)
}

export function * anadirFechaPlaning ({idVerraco, fecha}) {
  try {
    const auth = yield select(state => state.auth)
    const separadorFecha = fecha.split('/')
    let formatoFecha
    let fechaCoparar
    if (auth.formaFechaHora === 'day/month/year/12' || auth.formaFechaHora === 'day/month/year/24'){
      formatoFecha = separadorFecha[0] + '-' + separadorFecha[1] + '-' + separadorFecha[2]
      fechaCoparar = new Date(separadorFecha[2], separadorFecha[1] - 1, separadorFecha[0])
    } else if (auth.formaFechaHora === 'month/day/year/12' || auth.formaFechaHora === 'month/day/year/24'){
      formatoFecha = separadorFecha[1] + '-' + separadorFecha[0] + '-' + separadorFecha[2]
      fechaCoparar = new Date(separadorFecha[2], separadorFecha[0] - 1, separadorFecha[1])
    } else if (auth.formaFechaHora === 'year/month/day/12' || auth.formaFechaHora === 'year/month/day/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[1] + '-' + separadorFecha[0]
      fechaCoparar = new Date(separadorFecha[0], separadorFecha[1] - 1, separadorFecha[2])
    } else if (auth.formaFechaHora === 'year/day/month/12' || auth.formaFechaHora === 'year/day/month/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[0] + '-' + separadorFecha[1]
      fechaCoparar = new Date(separadorFecha[0], separadorFecha[2] - 1, separadorFecha[1])
    }
    let fechaHoy = new Date()
    fechaHoy.setHours(0,0,0,0)
    if (fechaCoparar >= fechaHoy){
      yield call(addPlaning, {verraco: idVerraco, fecha: formatoFecha}, auth.token)
      const filtrosState = yield select(state => state.planing.filtros)
      yield call(fetchFiltrarPlaning, {values: filtrosState})
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirFechaPlaning () {
  yield takeLatest(actionTypes.ANADIR_FECHA_PLANING, anadirFechaPlaning)
}

export function * eliminarFechaPlaning ({idVerraco, fecha}) {
  try {
    const auth = yield select(state => state.auth)
    // solo existe popup en version uno sin la customizacion USA        
    if (auth.version == '1' && auth.customizations && !auth.customizations.find((customization) => customization === 'USA')) {
      yield put(openSimpleModal('onDelete'))
      const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
      if (!confirmed) { return; }
    }    

    const separadorFecha = fecha.split('/')
    let formatoFecha
    if (auth.formaFechaHora === 'day/month/year/12' || auth.formaFechaHora === 'day/month/year/24'){
      formatoFecha = separadorFecha[0] + '-' + separadorFecha[1] + '-' + separadorFecha[2]
    } else if (auth.formaFechaHora === 'month/day/year/12' || auth.formaFechaHora === 'month/day/year/24'){
      formatoFecha = separadorFecha[1] + '-' + separadorFecha[0] + '-' + separadorFecha[2]
    } else if (auth.formaFechaHora === 'year/month/day/12' || auth.formaFechaHora === 'year/month/day/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[1] + '-' + separadorFecha[0]
    } else if (auth.formaFechaHora === 'year/day/month/12' || auth.formaFechaHora === 'year/day/month/24'){
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[0] + '-' + separadorFecha[1]
    }
    yield call(deletePlaning, {verraco: idVerraco, fecha: formatoFecha}, auth.token)
    const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})    
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEliminarFechaPlaning () {
  yield takeLatest(actionTypes.ELIMINAR_FECHA_PLANING, eliminarFechaPlaning)
}

export function * asignarVerracos ({verracos}) {
  try {
    const auth = yield select(state => state.auth)
    const listVerracos = yield call(getSugerenciasExtraccion, verracos, auth.token)
    yield put(asignarVerracosSuccess(listVerracos))
    yield put(filtrosModalSugerenciasPlaning(verracos))
    yield call(totalesPlaning, {values: {verracos: verracos.list}, desdeSugerenciasExtraccion: true})
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAsignarVerrracos () {
  yield takeLatest(actionTypes.ASIGNAR_VERRACOS, asignarVerracos)
}

export function * anadirPlaning ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const insercionPlaning = yield call(annadirSugerenciasExtraccionPlanning, values, auth.token)

    if (insercionPlaning.insertado === true) {
      yield put(openMensajeAlerta('insercion-planing'))
    } else {
      yield put(openMensajeAlerta('no-insercion-planing'))
    }

const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})
    yield put(closeModalPlaning())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirPlaning () {
  yield takeLatest(actionTypes.ANADIR_PLANING, anadirPlaning)
}

export function * cargarVerracosPlanificados ({fecha}) {
  try {
    const auth = yield select(state => state.auth)
    const listVerracosAsignados = yield call(getVerracosPlanificados, fecha, auth.token)
    yield put(onDeleteVerracoPlaningSuccess(listVerracosAsignados))
    // const valores = {fecha: new Date()}
    // yield put(initialize('ModalPlaning', fecha))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCargarVerracosPlanificados () {
  yield takeLatest(actionTypes.CARGAR_VERRACOS_PLANIFICADOS, cargarVerracosPlanificados)
}

export function * onDeleteVerracoPlaning ({idElemento}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const verracosAsignadosState = yield select(state => state.planing.listVerracosAsignados)
      const listVerracosAsignados = verracosAsignadosState.filter((verraco) => {
        console.log(verraco)
          console.log(idElemento)
        if (verraco.idElemento !== idElemento){
          return verraco
        }
      })
      yield put(onDeleteVerracoPlaningSuccess(listVerracosAsignados))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoPlaning () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO_PLANING, onDeleteVerracoPlaning )
}

export function * obtenerVerracosSinExtraer ({}) {
  try {
    const auth = yield select(state => state.auth)
    const list = yield call(getVerracosSinExtraer, null)
    yield put(obtenerVerracosSinExtraerSuccess(list))
    if (Object.keys(list).length > 0) {
      yield put(abrirModalInicioPlaning())
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerVerracosSinExtraer () {
  yield takeLatest(actionTypes.OBTENER_VERRACOS_SIN_EXTRAER, obtenerVerracosSinExtraer)
}

export function * imprimirModalInicioPlaning () {
  try {
    const datosModalInicioPlaning = yield call(getImprimirPdfInicioPlaning, null)
    yield put(imprimirModalInicioPlaningSuccess(datosModalInicioPlaning))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirModalInicioPlaning () {
  yield takeLatest(actionTypes.IMPRIMIR_MODAL_INICIO_PLANING, imprimirModalInicioPlaning)
}

export function * abrirModalPlaning () {
  try {
    const auth = yield select(state => state.auth)
    let currentDate = new Date()
    const valores = {
      fecha: currentDate,
      fechaPedidos: currentDate
    }
    yield put(initialize('ModalPlaning', valores))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalPlaning () {
  yield takeLatest(actionTypes.ABRIR_MODAL_PLANING, abrirModalPlaning)
}

export function * deleteSeleccionVerracoPlaning ({list}) {
  try {
    /*yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      yield put(onDeleteVerracoPlaningSuccess(list))
      yield put(deSeleccionarTodosModalPlaning())
    }*/
    yield put(onDeleteVerracoPlaningSuccess(list))
    yield put(deSeleccionarTodosModalPlaning())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionVerracoPlaning () {
  yield takeLatest(actionTypes.DELETE_SELECCION_VERRACO_PLANING, deleteSeleccionVerracoPlaning)
}

export function * exportarModalInicioPlaning () {
  try {
    const datosExcelModalInicioPlaning = yield call(generarExcelVerracosPlanificados, null)
    yield put(exportarModalInicioPlaningSuccess(datosExcelModalInicioPlaning))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchExportarModalInicioPlaning () {
  yield takeLatest(actionTypes.EXPORTAR_MODAL_INICIO_PLANING, exportarModalInicioPlaning)
}

export function * exportarPlaning ({values}) {
  try {
    const auth = yield select(state => state.auth)
    let filtrosSeleccionados = yield select(state => state.form.simpleTable.values);    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('check'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    values = {...values, idSeleccionados}  
    const datosExportarPlaning = yield call(generarExcelPlanning, values, auth.token)    
    yield put(exportarPlaningSuccess(datosExportarPlaning))
    yield put(cerrarModalExportarImprimirPlaning())
    yield put(initialize('ModalExportarImprimirPlaning', {}))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchExportarPlaning () {
  yield takeLatest(actionTypes.EXPORTAR_PLANING, exportarPlaning)
}

export function * imprimirPlaning ({values}) {
  try {
    const auth = yield select(state => state.auth)
    let filtrosSeleccionados = yield select(state => state.form.simpleTable.values);    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('check'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    values = {...values, idSeleccionados}  
    const datosImprimirPlaning = yield call(imprimirPlanning, values, auth.token)
    yield put(imprimirPlaningSuccess(datosImprimirPlaning))
    yield put(cerrarModalExportarImprimirPlaning())
    yield put(initialize('ModalExportarImprimirPlaning', {}))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPlaning () {
  yield takeLatest(actionTypes.IMPRIMIR_PLANING, imprimirPlaning)
}

export function * imprimirZebraVerracos ({values}) {    
  try {
    const state = yield select(state => state)
    const valuesFiltrosPlaning = yield getFormValues('FiltrosPlaning')(state)
    let filtrosSeleccionados = yield select(state => state.form.simpleTable.values);    
    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('check'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);
    
    const etiquetaPdf = yield call(imprimirEtiquetaVerracos, {...valuesFiltrosPlaning, ...values, idSeleccionados}, state.auth.token)
    
    let selected_device;
    let devices = [];
    window.BrowserPrint.getDefaultDevice("printer", function (device) {
      selected_device = device;
      devices.push(device);
      window.BrowserPrint.getLocalDevices(function (device_list) {
        for (let i = 0; i < device_list.length; i++) {
          let device = device_list[i];
          if (!selected_device || device.uid != selected_device.uid) {
            devices.push(device);
          }
        }
        selected_device.send(etiquetaPdf.pdf, undefined, undefined)
      },
        function () { console.log("Error getting local devices") }, "printer");
    }, function (error) {
      console.log(error);
    })
    yield put(cerrarModalExportarImprimirPlaning())
    yield put(initialize('ModalExportarImprimirPlaning', {'fechaExportacion':new Date().toISOString().slice(0, 10)}))
  } catch (error) {
    console.log(error)
  } 
  //finally {
  //   /*yield put(commonActions.hideLoader())*/
  // }
}
export function * watchImprimirZebraVerracos () {
  yield takeLatest(actionTypes.IMPRIMIR_ZEBRA_VERRACOS, imprimirZebraVerracos)
}

export function * exportarSugerenciaExtracciones ({values}) {
  console.log(values)
  try {
    const auth = yield select(state => state.auth)
    const dataExperotarSugerenciasExtracciones = yield call(generarExcelSugerenciasExtraccionPlanning, values, auth.token)
    yield put(exportarSugerenciaExtraccionesSuccess(dataExperotarSugerenciasExtracciones))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchExportarSugerenciaExtracciones () {
  yield takeLatest(actionTypes.EXPORTAR_SUGERENCIA_EXTRACCIONES, exportarSugerenciaExtracciones)
}

export function * imprimirSugerenciaExtracciones ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const dataImprimirSugerenciasExtracciones = yield call(imprimirSugerenciasExtraccionPlanning, values, auth.token)
    yield put(imprimirSugerenciaExtraccionesSuccess(dataImprimirSugerenciasExtracciones))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirSugerenciaExtracciones () {
  yield takeLatest(actionTypes.IMPRIMIR_SUGERENCIA_EXTRACCIONES, imprimirSugerenciaExtracciones)
}

export function * anadirSeleccionPlaning ({list}) {
  try {
    const auth = yield select(state => state.auth)
    yield call(addSeleccionPlanning, {list: list, fecha: new Date()}, auth.token)
    yield put(closeModalInicioPlaning())
    const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirSeleccionPlaning () {
  yield takeLatest(actionTypes.ANADIR_SELECCION_PLANING, anadirSeleccionPlaning)
}

export function * enviarFechaDuplicarPlaning ({fecha}) {
  try {
    const auth = yield select(state => state.auth)
    const datosDuplicar = yield call(getPlaningPorFecha, {fecha: fecha}, auth.token)
    yield put(enviarFechaDuplicarPlaningSuccess(datosDuplicar))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEnviarFechaDuplicarPlaning () {
  yield takeLatest(actionTypes.ENVIAR_FECHA_DUPLICAR_PLANING, enviarFechaDuplicarPlaning)
}

export function * submitDuplicarPlaning ({values}) {
  try {
    const auth = yield select(state => state.auth)
    let insercionPlaning = yield call(insertarDuplicarPlanning, values, auth.token)
    yield put(cerrarDuplicarPlaning())
    const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})
    if (insercionPlaning.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitDuplicarPlaning () {
  yield takeLatest(actionTypes.SUBMIT_DUPLICAR_PLANING, submitDuplicarPlaning)
}

export function * anadirSeleccionPlaningPrincipal ({list, fecha}) {
  try {
    const auth = yield select(state => state.auth)
    yield call(addSeleccionPlanning, {list: list, fecha: fecha}, auth.token)
    yield put(initialize('simpleTable', {}))
    const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirSeleccionPlaningPrincipal () {
  yield takeLatest(actionTypes.ANADIR_SELECCION_PLANING_PRINCIPAL, anadirSeleccionPlaningPrincipal)
}


export function * eliminarSeleccionPlaningPrincipal ({list, fecha}) {
  try {
    const auth = yield select(state => state.auth)
    yield call(deleteSeleccionPlanning, {list: list, fecha: fecha}, auth.token)
    yield put(initialize('simpleTable', {}))
    const filtrosState = yield select(state => state.planing.filtros)
    yield call(fetchFiltrarPlaning, {values: filtrosState})
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEliminarSeleccionPlaningPrincipal () {
  yield takeLatest(actionTypes.ELIMINAR_SELECCION_PLANING_PRINCIPAL, eliminarSeleccionPlaningPrincipal)
}

export function * obtenerPrevisionVerracos ({fechaReferencia}) {
  try {     
    const auth = yield select(state => state.auth)
    const listVerracos = yield call(getPrevisionVerracos, fechaReferencia, auth.token)    
    yield put(obtenerPrevisionVerracosSuccess(listVerracos))
    
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerPrevisionVerracos () {
  yield takeLatest(actionTypes.OBTENER_PREVISION_VERRACOS, obtenerPrevisionVerracos)
}
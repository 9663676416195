import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import TipoAlimentacion from '../../../components/planSanitario/tipoAlimentacion/TipoAlimentacion'
import {fetchTipoAlimentacion, recuperarDatosModalTipoAlimentacion, duplicarDatosModalTipoAlimentacion, cambiarEstadoTipoAlimentacion, onDeleteTipoAlimentacion
  } from '../../../actions/planSanitario/tipoAlimentacion/tipoAlimentacion'

export function mapStateToProps (state) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    tipoAlimentacion: state.tipoAlimentacion,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTipoAlimentacion,
      recuperarDatosModalTipoAlimentacion,
      duplicarDatosModalTipoAlimentacion,
      cambiarEstadoTipoAlimentacion,
      onDeleteTipoAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TipoAlimentacion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalHistoricoIntervenciones from '../../components/fichaVerraco/ModalHistoricoIntervenciones'
import {cerrarModalHistoricoIntervenciones} from '../../actions/fichaVerraco/editarVerraco/editarVerraco'
import {comboTipoIntervencionSanitaria} from '../../actions/combos/combos'
import { obtenerArchivoContentIntervencion} from '../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    auth: state.auth,
    combos: {
      comboTipoIntervencionSanitaria: state.combos.comboTipoIntervencionSanitaria,
      comboOperario: state.combos.comboOperarioActivo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalHistoricoIntervenciones,
      comboTipoIntervencionSanitaria,
      obtenerArchivoContentIntervencion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalHistoricoIntervenciones))
import actionTypes from '../../constants/actions/trazabilidadMaterial/trazabilidadMaterial'

export function comprobarStockTrazabilidad(idCategoria, idTipoDosis, utilizarLotesPreferidosDelUsuario){
  return {
    type: actionTypes.COMPROBAR_STOCK_TRAZABILIDAD,
    idCategoria,
    idTipoDosis,
    utilizarLotesPreferidosDelUsuario
  }
}

export function comprobarStockTrazabilidadSuccess(stockInsuficiente){
  return {
    type: actionTypes.COMPROBAR_STOCK_TRAZABILIDAD_SUCCESS,
    stockInsuficiente
  }
}

export function updateStockInsuficiente(){
  return {
    type: actionTypes.UPDATE_STOCK_INSUFICIENTE
  }
}

export function updateStockInsuficienteSuccess(stockInsuficiente){
  return {
    type: actionTypes.UPDATE_STOCK_INSUFICIENTE_SUCCESS,
    stockInsuficiente
  }
}

export function obtenerLotesEscandalloAnalisis(values){
  return {
    type: actionTypes.OBTENER_LOTES_ESCANDALLO_ANALISIS,
    values
  }
}

export function obtenerLotesEscandalloEnvasado(values, utilizarLotesPreferidosDelUsuario){
  return {
    type: actionTypes.OBTENER_LOTES_ESCANDALLO_ENVASADO,
    values,
    utilizarLotesPreferidosDelUsuario
  }
}

export function updateLotesEscandallo(lotesEscandallo){
  return {
    type: actionTypes.UPDATE_LOTES_ESCANDALLO,
    lotesEscandallo
  }
}

export function updateLotesEscandalloSuccess(lotesEscandallo){
  return {
    type: actionTypes.UPDATE_LOTES_ESCANDALLO_SUCCESS,
    lotesEscandallo
  }
}

export function openModalTrazabilidadMaterial(values){
  return {
    type: actionTypes.OPEN_MODAL_TRAZABILIDAD_MATERIAL,
    values
  }
}

export function openModalTrazabilidadMaterialSuccess(idCategoria, idTipoDosis, idAnalisis, idEnvasadoMultiDosis, lotesEscandallo, disabled, showStockColumns, dosis, dosisMax){
  return {
    type: actionTypes.OPEN_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS,
    idCategoria,
    idTipoDosis,
    idAnalisis,
    idEnvasadoMultiDosis,
    lotesEscandallo,
    disabled,
    showStockColumns,
    dosis, 
    dosisMax
  }
}

export function guardarModalTrazabilidadMaterial(){
  return {
    type: actionTypes.GUARDAR_MODAL_TRAZABILIDAD_MATERIAL
  }
}

export function guardarModalTrazabilidadMaterialSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS
  }
}

export function closeModalTrazabilidadMaterial(){
  return {
    type: actionTypes.CLOSE_MODAL_TRAZABILIDAD_MATERIAL
  }
}

export function closeModalTrazabilidadMaterialSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS
  }
}

export function seleccionarTodosTrazabilidadMaterial(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL
  }
}

export function deSeleccionarTodosTrazabilidadMaterial(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL
  }
}

export function deleteTrazabilidadMaterial(row){
  return {
    type: actionTypes.DELETE_TRAZABILIDAD_MATERIAL,
    row
  }
}

export function deleteSeleccionTrazabilidadMaterial(list){
  return {
    type: actionTypes.DELETE_SELECCION_TRAZABILIDAD_MATERIAL,
    list
  }
}

export function openModalEditarTrazabilidadMaterial(row){
  return {
    type: actionTypes.OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL,
    row
  }
}

export function openModalEditarTrazabilidadMaterialSuccess(){
  return {
    type: actionTypes.OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS
  }
}

export function guardarModalEditarTrazabilidadMaterial(values){
  return {
    type: actionTypes.GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL,
    values
  }
}

export function guardarModalEditarTrazabilidadMaterialSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS
  }
}

export function closeModalEditarTrazabilidadMaterial(){
  return {
    type: actionTypes.CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL
  }
}

export function closeModalEditarTrazabilidadMaterialSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS
  }
}

export function resetUtilizarLotesPreferidosDelUsuarioSuccess(){
  return {
    type: actionTypes.RESET_UTILIZAR_LOTES_PREFERIDOS_DEL_USUARIO_SUCCESS
  }
}

export function actualizarTrazabilidadMaterial(values){
  return {
    type: actionTypes.ACTUALIZAR_TRAZABILIDAD_MATERIAL,
    values
  }
}

export default {
  comprobarStockTrazabilidad,
  comprobarStockTrazabilidadSuccess,
  updateStockInsuficiente,
  updateStockInsuficienteSuccess,
  obtenerLotesEscandalloAnalisis,
  obtenerLotesEscandalloEnvasado,
  updateLotesEscandallo,
  updateLotesEscandalloSuccess,
  openModalTrazabilidadMaterial,
  openModalTrazabilidadMaterialSuccess,
  closeModalTrazabilidadMaterial,
  closeModalTrazabilidadMaterialSuccess,
  guardarModalTrazabilidadMaterial,
  guardarModalTrazabilidadMaterialSuccess,
  seleccionarTodosTrazabilidadMaterial,
  deSeleccionarTodosTrazabilidadMaterial,
  deleteTrazabilidadMaterial,
  deleteSeleccionTrazabilidadMaterial,
  openModalEditarTrazabilidadMaterial,
  openModalEditarTrazabilidadMaterialSuccess,
  guardarModalEditarTrazabilidadMaterial,
  guardarModalEditarTrazabilidadMaterialSuccess,
  closeModalEditarTrazabilidadMaterial,
  closeModalEditarTrazabilidadMaterialSuccess,
  resetUtilizarLotesPreferidosDelUsuarioSuccess,
  actualizarTrazabilidadMaterial
}

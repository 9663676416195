import actionTypes from '../../../constants/actions/planSanitario/microbiologia/microbiologia'

export function fetchMicrobiologia () {
  return {
    type: actionTypes.FETCH_MICROBIOLOGIA
  }
}

export function fetchMicrobiologiaSuccess (list) {
  return {
    type: actionTypes.FETCH_MICROBIOLOGIA_SUCCESS,
    list
  }
}

export function submitNuevaMicrobiologia (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_MICROBIOLOGIA,
    values
  }
}

export function submitNuevaMicrobiologiaSuccess (insercionMicrobiologia) {
  return {
    type: actionTypes.SUBMIT_NUEVA_MICROBIOLOGIA_SUCCESS,
    insercionMicrobiologia
  }
}

export function recuperarDatosModalMicrobiologia (idMicrobiologia) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_MICROBIOLOGIA,
    idMicrobiologia
  }
}

export function recuperarDatosModalMicrobiologiaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_MICROBIOLOGIA_SUCCESS,
    data
  }
}

export function abrirModalVacioMicrobiologia () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_MICROBIOLOGIA
  }
}

export function editarMicrobiologia (values) {
  return {
    type: actionTypes.EDITAR_MICROBIOLOGIA,
    values
  }
}

export function editarMicrobiologiaSuccess (edicionMicrobiologia) {
  return {
    type: actionTypes.EDITAR_MICROBIOLOGIA_SUCCESS,
    edicionMicrobiologia
  }
}

export function onDeleteMicrobiologia (id) {
  return {
    type: actionTypes.ON_DELETE_MICROBIOLOGIA,
    id
  }
}

export function onDeleteMicrobiologiaSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_MICROBIOLOGIA_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarMicrobiologia (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_MICROBIOLOGIA,
    values
  }
}

export function fetchFiltrarMicrobiologiaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MICROBIOLOGIA_SUCCESS,
    filtros
  }
}

export function cerrarModalMicrobiologia(){
  return {
    type: actionTypes.CERRAR_MODAL_MICROBIOLOGIA
  }
}

export function abrirModalMicrobiologia(){
  return {
    type: actionTypes.ABRIR_MODAL_MICROBIOLOGIA
  }
}

export function seleccionarTodosMicrobiologia(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MICROBIOLOGIA
  }
}

export function deSeleccionarTodosMicrobiologia(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MICROBIOLOGIA
  }
}

export function crearCsvMicrobiologia(list){
  return {
    type: actionTypes.CREATE_CSV_MICROBIOLOGIA,
    list
  }
}

export function crearCsvMicrobiologiaSuccess(datosExcelMicrobiologia){
  return {
    type: actionTypes.CREATE_CSV_MICROBIOLOGIA_SUCCESS,
    datosExcelMicrobiologia
  }
}

export default {
  fetchMicrobiologia,
  fetchMicrobiologiaSuccess,
  submitNuevaMicrobiologia,
  submitNuevaMicrobiologiaSuccess,
  recuperarDatosModalMicrobiologia,
  recuperarDatosModalMicrobiologiaSuccess,
  abrirModalVacioMicrobiologia,
  editarMicrobiologia,
  editarMicrobiologiaSuccess,
  onDeleteMicrobiologia,
  onDeleteMicrobiologiaSuccess,
  fetchFiltrarMicrobiologia,
  fetchFiltrarMicrobiologiaSuccess,
  cerrarModalMicrobiologia,
  seleccionarTodosMicrobiologia,
  deSeleccionarTodosMicrobiologia,
  crearCsvMicrobiologia,
  crearCsvMicrobiologiaSuccess
}

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter} from '../../util/formatFunctions'
import ListadoPage from '../../containers/page/ListadoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import FormSubtitle from '../comun/form/FormSubtitle'
import { Delete, PictureAsPdf } from '@material-ui/icons'

class ModalHistoricoIntervenciones extends Component {
  constructor (props) {
    super(props)
    this.state = {
      operario: '',
      tipoIntervencion: '',
      terminado: ''
    }
  }
  componentDidUpdate(prevProps){
    if (this.props.datosHistoricoIntervenciones !== prevProps.datosHistoricoIntervenciones) {
      if (this.props.datosHistoricoIntervenciones.intervencion) {
        this.props.combos.comboTipoIntervencionSanitaria.filter((combo) => {
          if (this.props.datosHistoricoIntervenciones.intervencion.idTipoIntervencion === combo.value) {
            this.setState({tipoIntervencion: combo.label})
          }
        })
        this.props.combos.comboOperario.filter((combo) => {
          if (this.props.datosHistoricoIntervenciones.intervencion.idOperario === combo.value) {
            this.setState({operario: combo.label})
          }
        })
      }
    }

    if (this.props.datosHistoricoIntervenciones.intervencion && this.props.datosHistoricoIntervenciones.intervencion !== prevProps.datosHistoricoIntervenciones.intervencion) {
      if (new Date(this.props.datosHistoricoIntervenciones.intervencion.fechaInicio).getTime() <= new Date(this.props.datosHistoricoIntervenciones.intervencion.fechaFin).getTime()) {
        this.setState({terminado: this.props.t('COMUN.COMBOS.TRATAMIENTO.SI')})
      } else {
        this.setState({terminado: this.props.t('COMUN.COMBOS.TRATAMIENTO.NO')})
      }
    }
  }
  componentDidMount () {
    this.props.actions.comboTipoIntervencionSanitaria()
  }
  getFileExtension(filename) {
    return filename.split('.').pop()
  }
  obtenerArchivo (archivo) {
    if(archivo.content){
      let element = document.createElement('a')
      element.setAttribute('href', encodeURIComponent(archivo))
      element.setAttribute('href', archivo.content)
      element.setAttribute('download', archivo.archivo)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    else{
      this.props.actions.obtenerArchivoContentIntervencion(archivo)
    }
  }
  render () {
    const {
      t, handleSubmit, showrModalHistoricoIntervenciones, datosHistoricoIntervenciones,
      actions: {cerrarModalHistoricoIntervenciones},
      auth: {formaFechaHora}
    } = this.props

    const table = {
      id: 'intervencionTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'fechaAsignacion', name: t('GRUPOS_VERRACO.TABLA_VERRACO.FECHA_ASIGNACION'), type: 'fecha'},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: datosHistoricoIntervenciones.verraco ? datosHistoricoIntervenciones.verraco : [],
      initialValues: {}
    }

    return (
      <Modal show={showrModalHistoricoIntervenciones} onHide={cerrarModalHistoricoIntervenciones} aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-historico">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.TITULO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.CODIGO')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.numeroRegistroIntervencionSanitaria : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.TIPO_INTERVENCION')}: </b>{this.state.tipoIntervencion}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.PRODUCTO')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.producto : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.FECHA_CADUCIDAD')}: </b>{datosHistoricoIntervenciones.intervencion && datosHistoricoIntervenciones.intervencion.fechaCaducidad !== null ? date_formatter(datosHistoricoIntervenciones.intervencion.fechaCaducidad, formaFechaHora, false) : null}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.LOTE')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.lote : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.CANTIDAD')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.cantidad : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.OPERARIO')}: </b>{this.state.operario}</p>
            </Col>
            <Col sm={3}>
              {t('')}
              <p><b>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.NUM_RECETA_ASIGNADA_PRODUCTO')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.numeroRecetaProducto : null}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.DOSIFICACION')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.dosificacion : null}</p>
            </Col>
          </Row>
          <FormSubtitle>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.VERRACOS_ASIGNADOS')}</FormSubtitle>
          <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={table}>
          </ListadoPage>
          <hr style={{marginTop: '20px', marginBottom: '20px'}}/>
          <Row>
            <Col sm={4}><p><b>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.FECHA_1_APLICACION')}: </b>{datosHistoricoIntervenciones.intervencion ? date_formatter(datosHistoricoIntervenciones.intervencion.fechaInicio, formaFechaHora, false) : null}</p></Col>
            <Col sm={4}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.FECHA_FIN')}: </b>{datosHistoricoIntervenciones.intervencion ? date_formatter(datosHistoricoIntervenciones.intervencion.fechaFin, formaFechaHora, false) : null}</p>
            </Col>
            <Col sm={4}><p><b>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TERMINADO')}: </b>{this.state.terminado}</p></Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.NUMERO_INTERVENCIONES')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.numeroIntervenciones : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.INTERVALO')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.intervalo : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.DIAS_MEDICAR')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.diasMedicar : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.PERIODO_SUPRESION')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.periodoSupresion : null}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.OBSERVACIONES')}: </b>{datosHistoricoIntervenciones.intervencion ? datosHistoricoIntervenciones.intervencion.observaciones : null}</p>
            </Col>
            <Col sm={12}>
              <p><b>{t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.MODAL.ARCHIVOS_ADJUNTO')}: </b></p>
              <ul>
                  {
                    datosHistoricoIntervenciones.archivos && (
                      datosHistoricoIntervenciones.archivos.map((item, idx) =>{
                        return (
                          <li
                            key={idx}
                            style={{listStyle: 'none'}}
                          >
                            {
                              this.getFileExtension(item.archivo) === 'pdf' ? (
                                <PictureAsPdf/>
                              ) : null
                            }
                            <p style={{cursor: 'pointer'}} onClick={() => this.obtenerArchivo(item)}>{item.archivo}</p>
                          </li>
                        )
                      })
                    )
                  }
                </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalHistoricoIntervenciones}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalHistoricoIntervenciones',
})(ModalHistoricoIntervenciones))
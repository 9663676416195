import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRegistroIntervencionSanitaria from '../../../components/planSanitario/registroIntervencionSanitaria/ModalRegistroIntervencionSanitaria'
import {submitNuevoRegistroIntervencionSanitaria, recuperarDatosModalRegistroIntervencionSanitaria, editarRegistroIntervencionSanitaria,
  vaciarDatosModalRegistroIntervencionSanitariaSuccess, guardarYnuevoRegistroIntervencionSanitaria, abrirModalVerracoRegistroIntervencionSanitaria,
  seleccionarTodosModalRegistroIntervencionSanitaria, deSeleccionarTodosModalRegistroIntervencionSanitaria, onDeleteVerracoAnadidoRegistroIntervencionSanitaria,
  deleteSeleccionVerracosRegistroIntervencionSanitaria, subirArchivosIntervencion, eliminarArchvioIntervencion, abrirModalAgendaIntervencionSanitaria, imprimirInformeRegistroIntervencion, obtenerArchivoContentIntervencion, closeRegistroIntervencion} from '../../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'
import {closeModal} from '../../../actions/common'
import {comboTipoIntervencionSanitaria, comboOperarioPredeterminado} from '../../../actions/combos/combos'
import {abrirModalAgenda} from '../../../actions/agenda/agenda'

export function mapStateToProps (state) {
  return {
    ...state.registroIntervencionSanitaria,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth,
    showModalAgenda: state.agenda.modalAgenda.showModalAgenda,
    formModalRegistroIntervencionSanitaria: state.form.ModalRegistroIntervencionSanitaria,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevoRegistroIntervencionSanitaria,
      recuperarDatosModalRegistroIntervencionSanitaria,
      editarRegistroIntervencionSanitaria,
      vaciarDatosModalRegistroIntervencionSanitariaSuccess,
      guardarYnuevoRegistroIntervencionSanitaria,
      abrirModalVerracoRegistroIntervencionSanitaria,
      seleccionarTodosModalRegistroIntervencionSanitaria,
      deSeleccionarTodosModalRegistroIntervencionSanitaria,
      onDeleteVerracoAnadidoRegistroIntervencionSanitaria,
      deleteSeleccionVerracosRegistroIntervencionSanitaria,
      comboTipoIntervencionSanitaria,
      comboOperarioPredeterminado,
      subirArchivosIntervencion,
      eliminarArchvioIntervencion,
      abrirModalAgendaIntervencionSanitaria,
      imprimirInformeRegistroIntervencion,
      obtenerArchivoContentIntervencion,
      abrirModalAgenda,
      closeRegistroIntervencion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRegistroIntervencionSanitaria))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosVisita from '../../../components/planSanitario/visita/FiltrosVisita'
import {openModal} from '../../../actions/common'
import {abrirModalVacioVisita, seleccionarTodosVisita, deSeleccionarTodosVisita, cambiarEstadoSeleccionVisita, deleteSeleccionVisita, fetchFiltrarVisita, crearCsvVisita, imprimirPdfVisita
  } from '../../../actions/planSanitario/visita/visita'
import {comboEmpresaVisitas, comboContactoVistas} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.visita,
    auth: state.auth,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioVisita,
      seleccionarTodosVisita,
      deSeleccionarTodosVisita,
      cambiarEstadoSeleccionVisita,
      deleteSeleccionVisita,
      fetchFiltrarVisita,
      comboEmpresaVisitas,
      comboContactoVistas,
      crearCsvVisita,
      imprimirPdfVisita
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosVisita))

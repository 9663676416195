import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalCambiarPassPerfil from '../../components/perfil/ModalCambiarPassPerfil'
import { cerrarCambiarPassPerfil, cambiarPasswordPerfil } from '../../actions/perfil/perfil'

export function mapStateToProps (state) {
  return {
    ...state.perfil,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarCambiarPassPerfil,
      cambiarPasswordPerfil
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCambiarPassPerfil))
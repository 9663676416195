import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {testAuth} from '../../actions/auth/auth'
import {fetchDashboard} from '../../actions/dashboard/dashboard'
import Dashboard from '../../components/dashboard/Dashboard'

const mapStateToProps = (state) => {
  return {
    ...state.dashboard,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      testAuth,
      fetchDashboard
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))
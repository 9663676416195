import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InformesProductividadExtracciones from '../../components/informes/InformesProductividadExtracciones'
import {obtenerInformeProductividadExtracciones, abrirModalProductividadVerracos} from '../../actions/informes/informes'
import {obtenerDatosPanelControl} from '../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    panelControl: state.panelControl,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeProductividadExtracciones,
      abrirModalProductividadVerracos,
      obtenerDatosPanelControl
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InformesProductividadExtracciones))
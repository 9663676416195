import actionTypes from '../../constants/actions/agenda/agenda'

export function initialState () {
  return {
    listTareasCalendario: [],
    listTareasDiarias: [],
    filtros:{},
    modalAgenda: {
      data: {},
      showModalAgenda: false
    }
  }
}

export function fetchAgendaSuccess (state, {listTareasCalendario, filtros}) {
  return {
    ...state,
    listTareasCalendario,
    filtros
  }
}

export function fetchTareasDiaSuccess (state, {listTareasDiarias}) {
  return {
    ...state,
    listTareasDiarias
  }
}

export function abrirModalAgendaSuccess (state, {data}) {
  return {
    ...state,
    modalAgenda: {
      ...state.modalAgenda,
      data,
      showModalAgenda: true
    }
  }
}

export function guardarModalAgendaSuccess (state) {
  return {
    ...state,
    modalAgenda: {
      //...state.modalAgenda,
      data: {},
      showModalAgenda: false
    }
  }
}

export function closeModalAgendaSuccess (state) {
  return {
    ...state,
    modalAgenda: {
      // ...state.modalAgenda,
      data: {},
      showModalAgenda: false
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_AGENDA_SUCCESS:
      return fetchAgendaSuccess(state, action)
    case actionTypes.FETCH_TAREAS_DIA_SUCCESS:
      return fetchTareasDiaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_AGENDA_SUCCESS:
      return abrirModalAgendaSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_AGENDA_SUCCESS:
      return guardarModalAgendaSuccess(state, action)
    case actionTypes.CLOSE_MODAL_AGENDA_SUCCESS:
      return closeModalAgendaSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { FormGroup, Checkbox } from 'react-bootstrap'
import Button from '../comun/button/Button'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import ModalAlbaranPage from '../../containers/albaran/ModalAlbaranPage'
import {estadoAlbaran as estadoAlbaranConstants} from '../../constants/albaran'
import {estadoAlbaranValidacion as estadoValidacion} from '../../constants/albaran'
import { fetchAlbaranesSuccess } from '../../actions/albaran/albaran'
import InputCheckBox from '../comun/form/InputCheckBox'


class ModalAlbaranRfidValidar extends Component {
  constructor () {
    super()
    this.state = {
      idPedido: null,
      listaParcial: null,
      acumulado: true
    }
  }
 
  componentDidUpdate (prevProps) {    
    //Este evento se dispara si hay algun cambio. Lo hay por que showModalAlbaranRfidValidar ha cambiado.
    if(this.props.data.showModalAlbaranRfidValidar != prevProps.data.showModalAlbaranRfidValidar) 
    {
      this.props.actions.leerEtiquetasRFID()
      this.props.actions.asignaParcialRFID(null)  
      //this.props.actions.asignaAcumuladoRFID(null)    
    }    
  }

  componentDidMount () {
    this.props.change('acumulado', true)
    /* console.log('this.props.formModalAlbaran: ', this.props.formModalAlbaran.numeroAlbaran) */   
    this.props.actions.asignaAcumuladoRFID(this.state.acumulado)     
  }

  handleClickParcial () {
    this.setState({listaParcial: this.props.lecturasDosisAlbaranRFID}, () => {
      this.validacionParcial()
    })      
  }

  validacionParcial () {        
    this.props.actions.asignaParcialRFID(this.state.listaParcial)    
  }

  handleClickAcumulado (value) { 
    console.log('value',value)   
    if(value){//Acumulado
      this.setState({acumulado: true}, () => {
        this.validacionAcumulado()
      }) 
    }else{//Live
      this.props.actions.asignaAcumuladoRFID(false)
    }         
  }

  validacionAcumulado () {        
    this.props.actions.asignaAcumuladoRFID(this.state.acumulado)
  }

  render () { 
    const {
        t, data, handleSubmit, lecturasDosisAlbaranRFID,
        actions: {cerrarModalAlbaranValidacionRfid},
        listLineasPedido = [],
    } = this.props    

    // Variable que estará a true cuando todo estará ok
    var todoValidado = false; 
    const tKey = 'ALBARANES.MODAL'
    const idElemento = 'idValidacionPedido'
    let listValidacion = lecturasDosisAlbaranRFID.length == 0 ? data.dosisAlbaran : lecturasDosisAlbaranRFID   
    //let listValidacion = lecturasDosisAlbaranRFID.length == 0 ? data.dosisAlbaran : this.state.listaParcial != null ? this.state.listaParcial : lecturasDosisAlbaranRFID   

    //Calculamos si todo está correcto.
    todoValidado=true;
    let noExiste=false
    let esSuperior=false
    listValidacion.forEach(x => {        
      if(x.estado!=estadoValidacion[1]) todoValidado=false;
      if(x.estado==estadoValidacion[2]) noExiste=true;
      if(x.dosisValidadas>x.dosisEnviadas) esSuperior=true
    });    

    const tableLineasPedido = {
      id: 'lineaPedido',
      multiHeader: false,
      idElemento,
      hasPagination: false,
      isDraggable: true,
      dragstartCallback: this.dragstartCallback,
      dragendCallback: this.dragendCallback,
      dropCallback: this.dropCallback,
      onClickCallback: this.dosisDisponiblesClickCallback,
      columns:
        [
          {id: 'nombreTipoDosis', name: t('INFORMES.STOCK_DOSIS.TABLA.TIPO_DOSIS')},
          {id: 'nombreRaza', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_RAZA')},
          {id: 'nombreLinea', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_LINEA')},
          {id: 'verracoPool', name: t('INFORMES.STOCK_DOSIS.TABLA.VERRACO_POLL')},
          {id: 'dosisEnviadas', name: t(tKey + '.COLUMNS.DOSIS_ENVIADAS'), type: 'numero', numDecimales: 0 },
          {id: 'dosisValidadas', name: t(tKey + '.COLUMNS.DOSIS_VALIDADAS'), type: 'numero', numDecimales: 0 },          
        ],
      rows: listValidacion, 

      leyenda: [
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.VERRACO'), color: '#FF6D29'},
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.POOL'), color: 'rgb(37, 56, 170)'}
      ],   
      initialValues: {},
      classNameTr: (idRow, row) => {
        let classTr= ''

        if(row.estado === estadoValidacion[1]){
          classTr= 'validated-row'
        }
        if(row.estado === estadoValidacion[2]){
          classTr= 'unknown-row'
        }
        if(row.dosisValidadas > row.dosisEnviadas){
          classTr= 'unknown-row'
        }

        return classTr
      },
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo === "verraco"){
          classRow = 'stock-dosis-verraco'
          
        } else if (row.tipo === "pool") {
          classRow = 'stock-dosis-pool'          
        }
        return classRow
      }
    }

    return (   
      <Modal show={data.showModalAlbaranRfidValidar} onHide={cerrarModalAlbaranValidacionRfid} backdrop="static" dialogClassName="xl" > 

        <form className="form-albaran-rfid">

          <Modal.Header closeButton>
            <Modal.Title>
              {t(tKey + '.MODAL_VALIDACION.TITLE_VALIDACION')+ ' ' + this.props.formModalAlbaran.numeroAlbaran}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>           
            <Row>
              <Col sm={12}>
                {/* <h3>{t(tKey + '.MODAL_VALIDACION.LINEAS_PEDIDO_VALIDADAS')}</h3> */}
                <SimpleTablePage
                  {...tableLineasPedido}>
                </SimpleTablePage>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
          <Button style={{ float: "left" }} className={'btn btn-white' + (todoValidado==true || noExiste==true || esSuperior==true ? ' disabled' : '')}
            onClick={(!todoValidado && !noExiste && !esSuperior ? this.handleClickParcial.bind(this) : null)}>
            {t(tKey + '.BUTTONS.VALIDAR_PARCIAL')}             
          </Button>

          <Col sm={2}>            
            <Field
              id="acumulado"
              name="acumulado"   
              colSm={1}                   
              inline
              component={InputCheckBox}
              claseActivo={false}     
              onInputChange={this.handleClickAcumulado.bind(this)}                
              componentClass="styled-checkbox select-all checked"                                            
            />
            <span style={{ float: "left", marginTop: "7px" }}>{t(tKey + '.MODAL_VALIDACION.ACUMULADO')}</span>
          </Col>          
          <Button className={'btn btn' + (!todoValidado ? '-white disabled' : '-primary')}
            onClick={todoValidado?this.props.editarAlbaran:null}>
            {t(tKey + '.BUTTONS.VALIDAR')}             
          </Button>

          <Button type="button" className="btn btn-white" onClick={cerrarModalAlbaranValidacionRfid}>{t(tKey + '.BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaranRfidValidar',
})(ModalAlbaranRfidValidar))
import actionTypes from '../../constants/actions/dashboard/dashboard'

export function initialState () {
  return {
    porcentajeEyaculados: '',
    porcentajeNoEyaculados: '',
    porcentajeCuarentena: '',
    porcentajePresentado: '',
    porcentajeActivo: '',
    porcentajeBaja: '',
    porcentajeEliminado: '',
    razaVerraco: [],
    totalVerracosRaza: '',
    totalRazas: '',
    porcentaje06: '',
    porcentaje6a9: '',
    porcentaje9a12: '',
    porcentaje12a18: '',
    porcentaje18a24: '',
    porcentaje24a36: '',
    porcentajeMas36: '',
    tiposDosisProducidas: [],
    totalTipoDosis: [],
    numeroRazas: '',
    numeroVerracos: '',
    numeroLineasGeneticas: '',
    mediaDosisVerracos: '',
    dosisProducidas: '',
    verracosVigilados: '',
    numeroVerracosSinExtraer: '',
    verracosEnTratamiento: '',
    numeroPedios: '',
    dosisPedidas: '',
    extraccionesPlaning: '',
    sumaLitrosDiluyente: '',
    tareasParaHoy: [],
    stockMateriales: [],
    numDosisDescartadasEdad: [],
    graficosMostrar: [],
    porcentajeSinFechaNacimiento: '',
    razonNoUso: [],
    eyaculadosProduccion: null,
    dilucionProduccion: null,
    envasadosProduccion: null,
    totalesProduccion: null,
    tiempoProduccion: null,
    dosisDiluidasProduccion: null,
    diluidasProduccion: null
  }
}

export function fetchDashboardSuccess (state, {datosDashboard}) {
  return {
    ...state,
    razonNoUso: datosDashboard.razonNoUso,
    porcentajeEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.eyaculadosNoEliminados : null,
    porcentajeNoEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.eyaculadosEliminados : null,
    porcentajeCuarentena: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeCuarentena : null,
    porcentajePresentado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajePresentado : null,
    porcentajeActivo: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeActivo : null,
    porcentajeBaja: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeBaja : null,
    porcentajeEliminado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeEliminado : null,
    razaVerraco: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.razaVerraco : [],
    totalVerracosRaza: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.totalVerracosRaza : null,
    totalRazas: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.totalRazas : null,
    porcentaje06: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje06 : null,
    porcentaje6a9: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje6a9 : null,
    porcentaje9a12: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje9a12 : null,
    porcentaje12a18: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje12a18 : null,
    porcentaje18a24: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje18a24 : null,
    porcentaje24a36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje24a36 : null,
    porcentajeMas36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentajeMas36 : null,
    tiposDosisProducidas: datosDashboard.tiposDosisProducidas !== null ? datosDashboard.tiposDosisProducidas.tiposDosis : [],
    totalTipoDosis: datosDashboard.tiposDosisProducidas !== null ? datosDashboard.tiposDosisProducidas.totalTipoDosis : [],
    numeroRazas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroRazas : null,
    numeroVerracos: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroVerracos : null,
    numeroLineasGeneticas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroLineasGeneticas : null,
    mediaDosisVerracos: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.mediaDosisVerracos : null,
    dosisProducidas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.dosisProducidas : null,
    verracosVigilados: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.verracosVigilados : null,
    numeroVerracosSinExtraer: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroVerracosSinExtraer : null,
    verracosEnTratamiento: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.verracosEnTratamiento : null,
    numeroPedios: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.numeroPedios : null,
    dosisPedidas: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.dosisPedidas : null,
    extraccionesPlaning: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.extraccionesPlaning : null,
    sumaLitrosDiluyente: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.sumaLitrosDiluyente : null,
    tareasParaHoy: datosDashboard.tareasParaHoy !== null ? datosDashboard.tareasParaHoy : [],
    stockMateriales: datosDashboard.stockMateriales !== null ? datosDashboard.stockMateriales : [],
    numDosisDescartadasEdad: datosDashboard.numDosisDescartadasEdad !== null ? datosDashboard.numDosisDescartadasEdad : [],
    graficosMostrar: datosDashboard.graficosMostrar !== null ? datosDashboard.graficosMostrar : [],
    porcentajeSinFechaNacimiento: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentajeSinFechaNacimiento : []
  }
}

export function fetchDashboardProduccionSuccess (state, {datosDashboardProduccion}) { 
  return {
    ...state,
    eyaculadosProduccion: datosDashboardProduccion.eyaculadosProduccion !== null ? datosDashboardProduccion.eyaculadosProduccion : 0,
    dilucionProduccion: datosDashboardProduccion.dilucionProduccion !== null ? datosDashboardProduccion.dilucionProduccion : 0,
    envasadosProduccion: datosDashboardProduccion.envasadosProduccion !== null ? datosDashboardProduccion.envasadosProduccion : 0,
    totalesProduccion: datosDashboardProduccion.totalesProduccion !== null ? datosDashboardProduccion.totalesProduccion : 0,
    tiempoProduccion: datosDashboardProduccion.tiempoProduccion !== null ? datosDashboardProduccion.tiempoProduccion : '00:00:00',
    dosisDiluidasProduccion: datosDashboardProduccion.dosisDiluidasProduccion !== null ? datosDashboardProduccion.dosisDiluidasProduccion : 0,
    diluidasProduccion: datosDashboardProduccion.diluidasProduccion !== null ? datosDashboardProduccion.diluidasProduccion : 0,
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_SUCCESS:
      return fetchDashboardSuccess(state, action)
    case actionTypes.FETCH_DASHBOARD_PRODUCCION_SUCCESS:
      return fetchDashboardProduccionSuccess(state, action)
    default:
      return state
  }
}
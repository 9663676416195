export default {
  FETCH_LINEA_GENETICA: 'FETCH_LINEA_GENETICA',
  FETCH_LINEA_GENETICA_SUCCESS: 'FETCH_LINEA_GENETICA_SUCCESS',
  OPEN_MODAL_LINEA_GENETICA: 'OPEN_MODAL_LINEA_GENETICA',
  OPEN_MODAL_LINEA_GENETICA_SUCCESS: 'OPEN_MODAL_LINEA_GENETICA_SUCCESS',
  CLOSE_MODAL_LINEA_GENETICA: 'CLOSE_MODAL_LINEA_GENETICA',
  CLOSE_MODAL_LINEA_GENETICA_SUCCESS: 'CLOSE_MODAL_LINEA_GENETICA_SUCCESS',
  SUBMIT_NUEVA_LINEA_GENETICA: 'SUBMIT_NUEVA_LINEA_GENETICA',
  SUBMIT_NUEVA_LINEA_GENETICA_SUCCESS: 'SUBMIT_NUEVA_LINEA_GENETICA_SUCCESS',
  RECUPERAR_DATOS_MODAL: 'RECUPERAR_DATOS_MODAL',
  RECUPERAR_DATOS_MODAL_SUCCESS: 'RECUPERAR_DATOS_MODAL_SUCCESS',
  ABRIR_MODAL_VACIO: 'ABRIR_MODAL_VACIO',
  EDITAR_LINEA_GENETICA: 'EDITAR_LINEA_GENETICA',
  EDITAR_LINEA_GENETICA_SUCCESS: 'EDITAR_LINEA_GENETICA_SUCCESS',
  DUPLICAR_DATOS_MODAL: 'DUPLICAR_DATOS_MODAL',
  DUPLICAR_DATOS_MODAL_SUCCESS: 'DUPLICAR_DATOS_MODAL_SUCCESS',
  CAMBIAR_ESTADO: 'CAMBIAR_ESTADO',
  CAMBIAR_ESTADO_SUCCESS: 'CAMBIAR_ESTADO_SUCCESS',
  ON_DELETE: 'ON_DELETE',
  ON_DELETE_SUCCESS: 'ON_DELETE_SUCCESS',
  FETCH_FILTRAR_LINEA: 'FETCH_FILTRAR_LINEA',
  SELECCIONAR_TODOS_LINEA_GENETICA: 'SELECCIONAR_TODOS_LINEA_GENETICA',
  DESELECCIONAR_TODOS_LINEA_GENETICA: 'DESELECCIONAR_TODOS_LINEA_GENETICA',
  CAMBIAR_ESTADO_SELECCION: 'CAMBIAR_ESTADO_SELECCION',
  DELETE_SELECCION: 'DELETE_SELECCION',
  FETCH_FILTRAR_LINEA_SUCCESS: 'FETCH_FILTRAR_LINEA_SUCCESS',
  CREATE_CSV: 'CREATE_CSV',
  CREATE_CSV_SUCCESS: 'CREATE_CSV_SUCCESS',
  GUARDAR_Y_NUEVO: 'GUARDAR_Y_NUEVO',
  IMPRIMIR_PDF_LINEA: 'IMPRIMIR_PDF_LINEA',
  IMPRIMIR_PDF_LINEA_SUCCESS: 'IMPRIMIR_PDF_LINEA_SUCCESS',
  OPEN_MODAL_LINEA_GENETICA: 'OPEN_MODAL_LINEA_GENETICA',
  CLOSE_MODAL_LINEA_GENETICA: 'CLOSE_MODAL_LINEA_GENETICA'
}

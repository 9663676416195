import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import MenuHorizontal from '../../components/layout/MenuHorizontal'
import {abrirModalCargaIndex} from '../../actions/cargaIndex/cargaIndex'
import {abrirModalImportarDatos} from '../../actions/importarDatos/importarDatos'
import {abrirModalImportarVerracos} from '../../actions/importarVerracos/importarVerracos'
import {abrirModalImportarPedidos} from '../../actions/importarPedidos/importarPedidos'

export function  mapStateToProps (state) {
  return {
    showModalCargaIndex: state.cargaIndex.showModalCargaIndex,
    showModalImportarDatos: state.importarDatos.showModalImportarDatos,
    showModalImportarVerracos: state.importarVerracos.showModalImportarVerracos,
    showModalImportarPedidos: state.importarPedidos.showModalImportarPedidos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalCargaIndex,
      abrirModalImportarDatos,
      abrirModalImportarVerracos,
      abrirModalImportarPedidos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuHorizontal))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputSelect from '../comun/form/InputSelect'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './Cliente.scss'

class ModalAnadirRuta extends Component {
  constructor () {
    super()
    this.state = {
      transporte: null
    }
  }

  componentDidMount () {
    this.props.actions.comboRutasNoDireccionCliente({list: this.props.listRutas})
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalAnadirRuta !== prevProps.showModalAnadirRuta) {
      this.props.actions.comboRutasNoDireccionCliente({list: this.props.listRutas})
    }
  }

  cerrarModalDireccionesRuta () {
    this.props.actions.cerrarModalDireccionesRuta()
    this.props.initialize()
  }

  anadirDatosRuta (values) {
    let val = {}
    val.idRuta = values.ruta.value
    val.nombreRuta = values.ruta.label
    val.transportista = ''
    this.props.actions.anadirDatosRuta(val)
    this.cerrarModalDireccionesRuta()
  }

  guardarYnuevoDatosDosis (values) {
    
  }

  comboRutaTransportistaCliente () {
    // this.props.actions.comboRutaTransportistaCliente(this.state.transporte)
  }

  render () {
    const {
      t, handleSubmit, showModalAnadirRuta, comboRutasNoDireccionCliente
    } = this.props

  return (
      <Modal show={showModalAnadirRuta} onHide={() => this.cerrarModalDireccionesRuta()} bsSize="large" dialogClassName="sm" className="modal-anadi-ruta" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              t('PEDIDOS.CLIENTE.MODAL.MODAL_ANADIR_RUTA.TITLE_NUEVO')
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              colSm={12}
              id="ruta"
              name="ruta"
              component={InputSelect}
              options={comboRutasNoDireccionCliente}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL.MODAL_ANADIR_RUTA.RUTA')}
              customClass='ruta'
              isClearable={false}
              validate={required}
              // onInputChange={() => this.comboRutaTransportistaCliente()}
            />
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirDatosRuta.bind(this))}>{t('PEDIDOS.CLIENTE.MODAL.MODAL_ANADIR_RUTA.ACEPTAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalDireccionesRuta()}>{t('PEDIDOS.CLIENTE.MODAL.MODAL_ANADIR_RUTA.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirRuta',
})(ModalAnadirRuta))

export default {
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  QUITAR_DIRECTORIO_TEMPORAL: 'QUITAR_DIRECTORIO_TEMPORAL',
  ELIMINAR_IMAGEN: 'ELIMINAR_IMAGEN',
  SUBIR_ARCHIVOS: 'SUBIR_ARCHIVOS',
  SUBIR_ARCHIVOS_SUCCESS: 'SUBIR_ARCHIVOS_SUCCESS',
  OBTENER_ARCHIVO_CONTENT: 'OBTENER_ARCHIVO_CONTENT',
  OBTENER_ARCHIVO_SUCCESS: 'OBTENER_ARCHIVO_SUCCESS',
  ELIMINAR_ARCHIVO: 'ELIMINAR_ARCHIVO',
  ELIMINAR_ARCHIVO_SUCCESS: 'ELIMINAR_ARCHIVO_SUCCESS',
  CAMBIAR_CENTRO: 'CAMBIAR_CENTRO',
  CAMBIAR_CENTRO_SUCCESS: 'CAMBIAR_CENTRO_SUCCESS'
}
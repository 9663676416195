import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosControlSemenPage from '../../../containers/planSanitario/controlSemen/FiltrosControlSemenPage'
import ModalInicioControlSemenPage from '../../../containers/planSanitario/controlSemen/ModalInicioControlSemenPage'
import { date_parser, dateTimeZones } from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'
import ModalControlSemenPage from       '../../../containers/planSanitario/controlSemen/ModalControlSemenPage'

class ControlSemen extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SEMEN') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    this.props.actions.fetchControlSemen({fechaDesde: fechaActual, 'seleccionar-todo': false})
  }

  imprimirInforme(row){
    if(row.file == null){
        this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('SIMPLE_MODAL.NO_EXISTE_INFORME'))
    }else{
        this.props.actions.descargarArchivoControlSemen(row)
    }
  }


  render () {
    const {
      mainList = [], checkedFiltros, t, numeroRegistrosPorPagina, paginaActual, showModalControlSemen, 
      actions: {deleteControlSemen, recuperarDatosModalControlSemen}
    } = this.props

    const tKey = 'PLAN_SANITARIO.CONTROL_SEMEN.'
    const idElemento = 'idControlSemen'
    const idForm = 'controlSemenTable'
    const tableControlSemen = {
      id: 'controlSemenTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_SEMEN] > 1),
      idElemento,
      hasOptions: true,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarImprimirInforme: true,
      imprimirInforme: (row) => this.imprimirInforme(row),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_SEMEN] > 1),
      onDelete: (row) => deleteControlSemen(row),
      columns: [
        {id: 'idControlSemen', name: t(tKey + 'COLUMNS.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombreVerraco', name: t(tKey + 'COLUMNS.VERRACO')},
        {id: 'vigilado', name: t('FICHA_VERRACO.VIGILADO')},
        {id: 'nombreRaza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},
        {id: 'fecha', name: t(tKey + 'COLUMNS.FECHA'), type: 'fecha'},
        {id: 'tipoControl', name: t(tKey + 'COLUMNS.TIPO_CONTROL')},      
        {id: 'tipoDirigido', name: t(tKey + 'COLUMNS.TIPO_DIRIGIDO')},    
        {id: 'resultado', name: t(tKey + 'COLUMNS.RESULTADO')},
        {id: 'file', name: t(tKey + 'COLUMNS.FICHERO')},
        {id: 'diasUltimoControl', name: t(tKey + 'COLUMNS.DIAS_ULTIMO_CONTROL'), type: 'numero', numDecimales: 0}
      ],
      rows: mainList,
      filtros: <FiltrosControlSemenPage idElemento={idElemento} idForm={idForm} />,
      onEdit: (row) => recuperarDatosModalControlSemen(row.idControlSemen),      
      initialValues: {}
    }
    tableControlSemen.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableControlSemen.initialValues['check' + idForm + row[idElemento]] = checkedFiltros
        }
      }
    )
    return (
      <div>
        <ModalInicioControlSemenPage />
        <SimpleModalPage/>
        <ModalControlSemenPage/>
        <MensajeAlertaPage/>
        <ListadoPage t_key={tKey} table={tableControlSemen}>
        </ListadoPage>
      </div>
    )
  }
}

export default ControlSemen
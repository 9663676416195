import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInicioControlSangre from '../../../components/planSanitario/controlSangre/ModalInicioControlSangre'
import {closeModalInicio, fetchFiltrarModalInicio, seleccionarTodosVerracosInicio, deSeleccionarTodosVerracosInicio, subirArchivosInforme} from '../../../actions/planSanitario/controlSangre/controlSangre'


export function mapStateToProps (state) {
  return {
    ...state.controlSangre,
    filtros:state.form.FiltrosModalAnnadirControlSangre,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalInicio,
      fetchFiltrarModalInicio,
      seleccionarTodosVerracosInicio,
      deSeleccionarTodosVerracosInicio,
      subirArchivosInforme
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInicioControlSangre))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalProductividadVerracos from '../../components/informes/ModalProductividadVerracos'
import {cerrarModalProductividadVerracos, abrirModalRecomendacionesMatadero, obtenerVerracosProductividadExtracciones, obtenerRecomendacionesMatadero, crearCsvInformeProductividadExtraccionesVerraco, imprimirPdfInformesProductividadExtraccionesVerraco
  } from '../../actions/informes/informes'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboTipoIntervencionSanitaria} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalProductividadVerracos,
      comboVerraco,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      comboTipoIntervencionSanitaria,
      abrirModalRecomendacionesMatadero,
      obtenerVerracosProductividadExtracciones,
      obtenerRecomendacionesMatadero,
      crearCsvInformeProductividadExtraccionesVerraco,
      imprimirPdfInformesProductividadExtraccionesVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalProductividadVerracos))
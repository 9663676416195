export const tipoAccionBioseguridadHigiene = {
  ANALITICA_AGUA: 1,
  LIMPIEZA_SILO_DEPOSITO: 2,
  LIMPIEZA_CORRALES: 3,
  OTROS: 4
}

export const translateTipoAccionBioseguridadHigiene = {
  1: 'TIPO_ACCION.ANALITICA_AGUA',
  2: 'TIPO_ACCION.LIMPIEZA_SILO_DEPOSITO',
  3: 'TIPO_ACCION.LIMPIEZA_CORRALES',
  4: 'TIPO_ACCION.OTROS'
}

export const tipoOtros = {
  UBICACION: 1,
  VERRACO: 2,
  GRUPO_VERRACO: 3
}

export const translateTipoOtros = {
  1: 'TIPO_OTROS.UBICACION',
  2: 'TIPO_OTROS.VERRACO',
  3: 'TIPO_OTROS.GRUPO_VERRACO'
}

export const estadoAccion = {
  'NO': 0,
  'SI': 1,
  'PENDIENTE': 2,
  'DEBE_MEJORAR': 3
}

export const translateEstadoAccion = {
  0 : 'ESTADO_ACCION.NO',
  1 : 'ESTADO_ACCION.SI',
  2 : 'ESTADO_ACCION.PENDIENTE',
  3 : 'ESTADO_ACCION.DEBE_MEJORA'
}

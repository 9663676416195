import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col, Button} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'

class FiltrosInformesSeguimientoExtraccionesPool extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformeSeguimientoExtraccionesPool({})
    this.props.actions.comboVerraco()
    this.props.actions.fetchComboPool()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboIndex()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboOperario()
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboCliente()
    this.props.change('media', {value: false, label: this.props.t('COMUN.COMBOS.VALORES_MEDIA.NO')})
  }

  obtenerInformeSeguimientoExtraccionesPool(values){  

    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    if (this.props.tabSeleccionada === 1){
    
      values.idTipo=1
      this.props.actions.obtenerInformeSeguimientoExtraccionesPool(values)
      if (Object.keys(this.props.listInformeSeguimientoExtraccionesPool.extracciones).length > 0) {
        values.idAnalisis = this.props.listInformeSeguimientoExtraccionesPool.extracciones[0].idAnalisis
        this.props.actions.obtenerRegistrosSeguimientosExtraccionesPool(values)
        var elems = document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        [].forEach.call(elems, function(el) {
           el.classList.remove("fila-seleccionada-informes");
        })
        document.getElementById('seguimientoExtraccionesTable').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[0].classList.add("fila-seleccionada-informes")
      }
    } 
  }

  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesSeguimientoExtraccionesPool !== prevProps.datosPdfInformesSeguimientoExtraccionesPool) {
      if (this.props.datosPdfInformesSeguimientoExtraccionesPool.pdf && this.props.datosPdfInformesSeguimientoExtraccionesPool.pdf !== null){
        let pdf = this.props.datosPdfInformesSeguimientoExtraccionesPool.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  /*imprimirPdfInformesSeguimientoExtraccionesPool(){
    let grafica1 = document.getElementsByTagName('canvas')[0].toDataURL("image/png")
    const filtros = this.props.filtrosSeguimientoExtraccionesPool
    filtros.grafica1 = grafica1
    this.props.actions.imprimirPdfInformesSeguimientoExtraccionesPool(filtros)
  }*/

  quitarFiltros (value) {
    let values = this.props.filtrosSeguimientoExtraccionesPool
    values[value] = null
    this.props.actions.obtenerInformeSeguimientoExtraccionesPool(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  exportarInforme () {
    let filtros = this.props.filtrosSeguimientoExtraccionesPool
    this.props.actions.exportarInformeSeguimientoExtraccionesPool(filtros)
    // this.props.actions.abrirModalExportarImprimirInformesSeguimientoExtraccionesPool('exportar')
  }

  imprimirInforme () {
    let filtros = this.props.filtrosSeguimientoExtraccionesPool
    this.props.actions.imprimirPdfInformesSeguimientoExtraccionesPool(filtros)
    // this.props.actions.abrirModalExportarImprimirInformesSeguimientoExtraccionesPool('imprimir')
  }

  render () {
  const {
    t, handleSubmit, filtrosSeguimientoExtraccionesPool,
    combos: {
      comboVerraco, comboPool, comboNombreGrupoVerraco, comboTipoDosis, comboIndex, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboCliente
    }
  } = this.props

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    const combosegimiento= [
      {value: true, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.SI')},
      {value: false, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.NO')}
    ]

    const combosContaminacion= [
      {value: true, label: t('COMUN.COMBOS.CONTAMINACION.SI')},
      {value: false, label: t('COMUN.COMBOS.CONTAMINACION.NO')}
    ]

    const comboEyaculadoEliminado= [
      {value: true, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.SI')},
      {value: false, label: t('COMUN.COMBOS.EYACULADO_ELIMINADO.NO')}
    ]

    let comboTipoAnalisisExtraccion = this.props.combos.comboTipoAnalisisExtraccion
    comboTipoAnalisisExtraccion = comboTipoAnalisisExtraccion.map((extraccion) => {
      return {...extraccion, label: t('COMUN.COMBOS.' + extraccion.label)}
    })

    let comboConservacion = this.props.combos.comboConservacion
    comboConservacion = comboConservacion.map((conservacion) => {
      return {...conservacion, label: t(conservacion.label)}
    })

    let comboPropiedad = this.props.combos.comboPropiedad
    comboPropiedad = comboPropiedad.map((propiedad) => {
      return {...propiedad, label: t(propiedad.label)}
    })

    let comboObservacionesEyaculadoEliminado = this.props.combos.comboObservacionesEyaculadoEliminado
    comboObservacionesEyaculadoEliminado = comboObservacionesEyaculadoEliminado.map((observacionesEyaculadoEliminadoItem) => {
      return {...observacionesEyaculadoEliminadoItem, label: t(`COMUN.COMBOS.${observacionesEyaculadoEliminadoItem.label}`)}
    })

    const combosValoresMedia= [
      {value: true, label: t('COMUN.COMBOS.VALORES_MEDIA.SI')},
      {value: false, label: t('COMUN.COMBOS.VALORES_MEDIA.NO')}
    ]

    const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Field
              colSm={2}
              id="pool"
              name="pool"
              controlLabel={t('INFORMES.POOL.FILTROS.POOL')}
              component={InputSelect}
              options={comboPool}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="intervencionSanitaria"
                name="intervencionSanitaria"
                controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA')}
                component={InputSelect}
                options={comboTipoIntervencionSanitaria}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="contaminacion"
              name="contaminacion"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CONTAMINACION')}
              component={InputSelect}
              options={combosContaminacion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Field
              colSm={2}
              id="conservacion"
              name="conservacion"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.CONSERVACION')}
              component={InputSelect}
              options={comboConservacion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.EXTRACCIONES.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformeSeguimientoExtraccionesPool.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.verraco && filtrosSeguimientoExtraccionesPool.verraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.VERRACO') + ': ' + filtrosSeguimientoExtraccionesPool.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.pool && filtrosSeguimientoExtraccionesPool.pool !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.POOL') + ': ' + filtrosSeguimientoExtraccionesPool.pool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('pool')}> x </button></span>
                    ) : null
                  )                  
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.raza && filtrosSeguimientoExtraccionesPool.raza !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.RAZA') + ': ' + filtrosSeguimientoExtraccionesPool.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.lineaGenetica && filtrosSeguimientoExtraccionesPool.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.LINEA_GENETICA') + ': ' + filtrosSeguimientoExtraccionesPool.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.grupoVerraco && filtrosSeguimientoExtraccionesPool.grupoVerraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.GRUPO_VERRACO') + ': ' + filtrosSeguimientoExtraccionesPool.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.intervencionSanitaria && filtrosSeguimientoExtraccionesPool.intervencionSanitaria !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.INTERVENCION_SANITARIA') + ': ' + filtrosSeguimientoExtraccionesPool.intervencionSanitaria.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('intervencionSanitaria')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.contaminacion && filtrosSeguimientoExtraccionesPool.contaminacion !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CONTAMINACION') + ': ' + filtrosSeguimientoExtraccionesPool.contaminacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('contaminacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    filtrosSeguimientoExtraccionesPool.conservacion && filtrosSeguimientoExtraccionesPool.conservacion !== null ? (
                      <span className="badge-dark">{t('INFORMES.EXTRACCIONES.FILTROS.CONSERVACION') + ': ' + filtrosSeguimientoExtraccionesPool.conservacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('conservacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosSeguimientoExtraccionesPool).length > 0 && (
                    (filtrosSeguimientoExtraccionesPool.fechaEntrada && filtrosSeguimientoExtraccionesPool.fechaSalida && filtrosSeguimientoExtraccionesPool.fechaEntrada !== null && filtrosSeguimientoExtraccionesPool.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosSeguimientoExtraccionesPool.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosSeguimientoExtraccionesPool.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosSeguimientoExtraccionesPool.fechaEntrada && filtrosSeguimientoExtraccionesPool.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosSeguimientoExtraccionesPool.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosSeguimientoExtraccionesPool.fechaSalida && filtrosSeguimientoExtraccionesPool.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosSeguimientoExtraccionesPool.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                {/*<button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesSeguimientoExtraccionesPool()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>*/}
                <Button type="button" style={{margin: '3px'}} className="btn btn-icono" onClick={() => this.exportarInforme()}><Launch />{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.EXPORTAR')}</Button>
                <Button type="button" style={{margin: '3px'}} className="btn btn-icono" onClick={() => this.imprimirInforme()}><Print />{t('INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL.IMPRIMIR')}</Button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesSeguimientoExtraccionesPool',
})(FiltrosInformesSeguimientoExtraccionesPool))

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, label } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter} from '../../util/formatFunctions'
import ListadoPage from '../../containers/page/ListadoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'

class ModalHistoricoEstado extends Component {
  render () {
    const {
      t, handleSubmit, showrModalHistoricoEstado, datosHistoricoEstado,
      actions: {cerrarModalHistoricoEstado}
    } = this.props

    const table = {
      id: 'grupoVerracoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'fechaAsignacion', name: t('GRUPOS_VERRACO.TABLA_VERRACO.FECHA_ASIGNACION'), type: 'fecha'},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: datosHistoricoEstado ? datosHistoricoEstado : [],
      initialValues: {}
    }

    return (
      <Modal show={showrModalHistoricoEstado} onHide={cerrarModalHistoricoEstado} aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-historico">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.TITULO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.VERRACO')}: </b>{datosHistoricoEstado ? datosHistoricoEstado.codVerraco : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.FECHA')}: </b>{datosHistoricoEstado ? date_formatter(datosHistoricoEstado.fechaCambio, this.props.auth.formaFechaHora, false) : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ANTERIOR')}: </b>{datosHistoricoEstado ? t(datosHistoricoEstado.estadoActual) : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ACTUAL')}: </b>{datosHistoricoEstado ? t(datosHistoricoEstado.nuevoEstado) : null}</span>
            </Col>
            <br style={{clear: 'both'}} />
            <hr style={{margin: '20px 0px'}} />
            <Col sm={12}><span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.OBSERVACIONES')}: </b><br />{datosHistoricoEstado ? datosHistoricoEstado.ObservacionesCambio : null}</span></Col>
            <br style={{clear: 'both'}} />
            <hr style={{margin: '20px 0px'}} />
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_INICIAL')}: </b>{datosHistoricoEstado ? datosHistoricoEstado.sangriaInicio : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_FINAL')}: </b>{datosHistoricoEstado ? datosHistoricoEstado.sangriaFinal : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.PESO_INICIAL')}: </b>{datosHistoricoEstado ? datosHistoricoEstado.pesoInicio : null}</span>
            </Col>
            <Col sm={3}>
              <span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.PESO_FINAL')}: </b>{datosHistoricoEstado ? datosHistoricoEstado.pesoFinal : null}</span>
            </Col>
            <br style={{clear: 'both'}} />
            <hr style={{margin: '20px 0px'}} />
            <Col sm={12}><span><b>{t('CAMBIO_ESTADO_VERRACOS.MODAL.INCIDENCIAS_CLINICAS')}: </b><br />{datosHistoricoEstado ? datosHistoricoEstado.incidenciasClinicas : null}</span></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalHistoricoEstado}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalHistoricoEstado',
})(ModalHistoricoEstado))
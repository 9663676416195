import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import FiltrosModalAnnadirControlSangre from '../../../components/planSanitario/controlSangre/FiltrosModalAnnadirControlSangre'
import {
  fetchControlSangre, seleccionarTodosModalControlSangre, deSeleccionarTodosModalControlSangre, deleteSeleccionControlSangre,
  abrirModalInicio, fetchFiltrarModalInicio, anadirVerracosModalInicio
} from '../../../actions/planSanitario/controlSangre/controlSangre'
import {comboVerraco, comboRaza, comboLineaGenetica} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.ModalInicioControlSangre,
    auth: state.auth,
    filtros: state.controlMorfologico.filtrosInicio,
    seguimientoTable: state.form.seguimientoTable,
    periodoForm:state.form.ModalInicioControlSangre,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerraco: state.combos.comboVerraco,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchControlSangre,
      seleccionarTodosModalControlSangre,
      deSeleccionarTodosModalControlSangre,
      deleteSeleccionControlSangre,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      abrirModalInicio,
      fetchFiltrarModalInicio,
      anadirVerracosModalInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalAnnadirControlSangre))
import apiFetch from '../apiFetch'

export default function (baseUrlMagavision, tokenUsuarioMagavision, values) {
  return apiFetch({
    server: null, 
    mode: 'cors', 
    credentials: 'omit', 
    endPoint: `${baseUrlMagavision + (baseUrlMagavision[baseUrlMagavision.length - 1] !== '/' ? '/' : '')}login/multiseguimiento?t_usr=${tokenUsuarioMagavision}`, 
    method: 'POST', 
    body: values})
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEnvasadoMultiDosisTable from '../../components/envasado/FiltrosEnvasadoMultiDosisTable'
import {abrirModalEnvasadoMultiDosis, seleccionarTodosEnvasadoMultiDosis, deseleccionarTodosEnvasadoMultiDosis, deleteSeleccionEnvasadoMultiDosis} from '../../actions/envasado/envasado'

export function mapStateToProps (state) {
  return {
    ...state.envasado,
    combos: state.combos,
    envasadoMultiDosisTable: state.form.envasadoMultiDosisTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalEnvasadoMultiDosis,
      seleccionarTodosEnvasadoMultiDosis,
      deseleccionarTodosEnvasadoMultiDosis,
      deleteSeleccionEnvasadoMultiDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEnvasadoMultiDosisTable))
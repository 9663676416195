import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosFormPoolExtracciones from '../../components/poolExtracciones/FiltrosFormPoolExtracciones'
import {
  seleccionarTodosAsignacionAnalisisPool,  deseleccionarTodosAsignacionAnalisisPool, deleteSeleccionAsignacionAnalisisPool,
  abrirModalAsignacionAnalisisPool
} from '../../actions/poolExtracciones/poolExtracciones'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pool: state.poolExtracciones.pool,
    poolExtraccionesTable: state.form.poolExtraccionesTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      seleccionarTodosAsignacionAnalisisPool,
      deseleccionarTodosAsignacionAnalisisPool,
      deleteSeleccionAsignacionAnalisisPool,
      abrirModalAsignacionAnalisisPool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosFormPoolExtracciones))
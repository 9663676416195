import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './Planing.scss'
import ModalVerracoPlaningPage from '../../containers/planing/ModalVerracoPlaningPage'
import {date_parser, number_formatter, date_formatter} from '../../util/formatFunctions'
import FiltrosModalPlaningPage from '../../containers/planing/FiltrosModalPlaningPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalPlaning extends Component {
   constructor (props) {
    super()
    this.state = {
      classErrorVerracos: false,
      imputSelected: false,
      clickEnPrevision: false
    }
     this.handleChangeFechaPedidos = this.handleChangeFechaPedidos.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.listVerracosAsignados !== prevProps.listVerracosAsignados){
      let filtrosModal = this.props.filtrosSugerenciasPlaning
      filtrosModal.list = this.props.listVerracosAsignados
      this.props.actions.asignarVerracos(filtrosModal)
    }

    if (this.props.showModalPlaning && !prevProps.showModalPlaning) {
      let filtrosModal = this.props.filtrosSugerenciasPlaning
      filtrosModal.list = this.props.listVerracosAsignados
      this.props.actions.asignarVerracos(filtrosModal)
      this.props.actions.comboOperario()
    }
  }

  closeModalPlaning () {
    this.props.actions.closeModalPlaning()
    this.props.actions.cerrarModalSeleccionarVerracoPlaning()
  }

  anadirPlaning (values) {
    values.list = this.props.listVerracosAsignados
    this.props.actions.anadirPlaning(values)
    this.setState({clickEnPrevision: false})
  }

  handleChangeFechaPedidos (values) {    
    this.props.actions.totalesPlaning(values, true)
  }

  fechaVerracosExtraer (value) {
    this.props.actions.cargarVerracosPlanificados(value)
    this.props.actions.totalesPlaning(value, true)
  }

  anadirVerracosPlaning (){    
    if (Object.keys(this.props.datosVerracoPlaning).length > 0){
      this.props.actions.anadirVerracosPlaning(this.props.datosVerracoPlaning)
      this.props.actions.obtenerDatosVerracoPlaning([])
    }
  }

  quitarVerracosPlaning (){
    if (Object.keys(this.props.datosVerracoPlaning).length > 0){
      const itemsSeleccion = this.props.listVerracosAsignados.filter((row) => {
        if (row.idVerraco !== this.props.datosVerracoPlaning.idVerraco){
          return row
        }
      })
      this.props.actions.deleteSeleccionVerracoPlaning(itemsSeleccion)
      this.props.actions.obtenerDatosVerracoPlaning([])
    }
  }

  anadirTodos () {
    this.props.actions.anadirVerracosPlaning(this.props.listVerracos)
    this.props.actions.obtenerDatosVerracoPlaning([])
    let filtrosModal = this.props.filtrosSugerenciasPlaning
    filtrosModal.list = this.props.listVerracosAsignados
    this.props.actions.asignarVerracos(filtrosModal)
  }

  quitarTodos () {
    let filtrosModal = this.props.filtrosSugerenciasPlaning
    filtrosModal.list = this.props.listVerracosAsignados
    this.props.actions.asignarVerracos(filtrosModal)
    this.props.actions.deleteSeleccionVerracoPlaning([])
    this.props.actions.obtenerDatosVerracoPlaning([])
  }

  intercambiarCheckSugerencias = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosSugerenciasPlanning()
    } else {
      this.props.actions.deSeleccionarTodosSugerenciasPlanning()
    }
  }

  intercambiarCheckQuitarSugerencias = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosQuitarSugerenciasPlanning()
    } else {
      this.props.actions.deSeleccionarTodosQuitarSugerenciasPlanning()
    }
  }

  anadirSeleccionSugerenciasPlaning () {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'nombreRaza': row.nombreRaza,
          'verraco': row.verraco,
          'nombreLinea': row.nombreLinea,
          'dosisPedidas': row.dosisPedidas,
          'fechaUltimaExtraccion': row.fechaUltimaExtraccion,
          'Ubicacion': row.Ubicacion,
          'espermautil': row.espermautil,
          'xDosis': row.xDosis,
          'index': row.index,
          'diasUltimaExtraccion': row.diasUltimaExtraccion,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (list.length > 0) {
      this.props.actions.anadirVerracosPlaning(list)
      this.props.change('seleccionar-todo-sugerencias-planning', false)
      this.props.actions.deSeleccionarTodosSugerenciasPlanning()
    }
  }

  quitarSeleccionSugerenciasPlaning () {
    const itemsSeleccion = this.props.listVerracosAsignados.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'nombreRaza': row.nombreRaza,
          'verraco': row.verraco,
          'nombreLinea': row.nombreLinea,
          'dosisPedidas': row.dosisPedidas,
          'fechaUltimaExtraccion': row.fechaUltimaExtraccion,
          'Ubicacion': row.Ubicacion,
          'espermautil': row.espermautil,
          'xDosis': row.xDosis,
          'index': row.index,
          'diasUltimaExtraccion': row.diasUltimaExtraccion,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check !== true;
    });
    const listQuitar = list.filter((lis) => {
      if (lis !== false){
        return lis
      }
    })
    if (list.length > 0) {
      this.props.actions.deleteSeleccionVerracoPlaning(listQuitar)
      this.props.change('seleccionar-todo-quitar-sugerencias-planning', false)
      this.props.actions.deSeleccionarTodosQuitarSugerenciasPlanning()
    }
  }

  anadirPrevisionVerracos (values) {        
    let fechaReferencia = date_formatter(values.fechaPedidos, 'year-month-day', false) 
    console.log('fechaReferencia',fechaReferencia)
    let listVerracos = this.props.actions.obtenerPrevisionVerracos(fechaReferencia)
    this.props.actions.obtenerDatosVerracoPlaning([])     
    this.setState({clickEnPrevision: true})      
  }

  render () {
    const {
      t, handleSubmit, showModalPlaning, listVerracosAsignados, checkedVerracosAnadidos, numeroRegistrosPorPagina, paginaActual, datosTotalesModalPlaning, listVerracos,
      checkedVerracos, checkedSugerenciasPlanning, checkedQuitarSugerenciaPlanning,
      actions:{onDeleteVerracoPlaning},
      auth: {separadorMil, separadorDec},
      combos: {comboOperario}
    } = this.props

    // TABLA VERRACO
    listVerracosAsignados.forEach(
      (row, idRow) => row.idElemento = row.idVerraco + 'quitaSugerencia'
    )
    const table = {
      id: 'tableVerracoPlaning',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      hasPagination: false,
      noBlockOptions: false,
      paginar: false,
      seleccionarFilaSugerenciasQuitar: true,
      seleccionarFilaSugerencias: false,
      columns: [
        {id: 'verraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'index', name: t('PLANING.TABLA.INDEX')},
        {id: 'diasUltimaExtraccion', name: t('PLANING.MODAL.TABLE.DIAS_SIN_EXTRAER')},
        {id: 'espermautil', name: t('PLANING.MODAL.TABLE.ESPERMA_UTIL'), type: 'numero'},
        {id: 'xDosis', name: t('PLANING.MODAL.TABLE.X_DOSIS'), type: 'numero'},
        {id: 'Ubicacion', name: t('PLANING.MODAL.TABLE.UBICACION')},
        {id: 'dosisPedidas', name: t('PLANING.MODAL.TABLE.DOSIS_PEDIDAS')}
      ],
      rows: listVerracosAsignados,
      onDelete: (row) => onDeleteVerracoPlaning(row.idElemento),
      initialValues: {}
    }

    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['checkin' + row.idElemento] = checkedQuitarSugerenciaPlanning
        }
      }
    )

    // TABLA LISTADO VERRACO
    listVerracos.forEach(
      (row, idRow) => row.idElemento =  row.idVerraco + 'Sugerencia'
    )
    const tableVerraco = {
      id: 'tableListadoVerracoPlaning',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: false,
      noBlockOptions: false,
      paginar: false,
      seleccionarFilaSugerencias: true,
      seleccionarFilaSugerenciasQuitar: false,
      columns: [
        {id: 'verraco', name: t('PLANING.MODAL.TABLE.VERRACO')},
        {id: 'nombreRaza', name: t('PLANING.MODAL.TABLE.RAZA')},
        {id: 'nombreLinea', name: t('PLANING.MODAL.TABLE.LIN_GENETICA')},
        {id: 'index', name: t('PLANING.TABLA.INDEX')},
        {id: 'diasUltimaExtraccion', name: t('PLANING.MODAL.TABLE.DIAS_SIN_EXTRAER')},
        {id: 'espermautil', name: t('PLANING.MODAL.TABLE.ESPERMA_UTIL'), type: 'numero'},
        {id: 'xDosis', name: t('PLANING.MODAL.TABLE.X_DOSIS'), type: 'numero'},
        {id: 'Ubicacion', name: t('PLANING.MODAL.TABLE.UBICACION')},
        {id: 'dosisPedidas', name: t('PLANING.MODAL.TABLE.DOSIS_PEDIDAS')}
      ],
      rows: listVerracos,
      initialValues: {}
    }

    tableVerraco.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerraco.initialValues['check' + row.idElemento] = checkedSugerenciasPlanning
        }
      }
    )

  return (
      <Modal show={showModalPlaning} onHide={() => this.closeModalPlaning()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-sugerencias-planing">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {t('PLANING.MODAL.TITLE')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={2}
                id="fecha"
                name="fecha"
                controlLabel={t('PLANING.MODAL.FILTROS.FECHA')}
                component={InputDatePicker}
                validate={required}
                onInputChange={(value) => this.fechaVerracosExtraer({fecha: value})}
              />
              {/*
              <Field
                colSm={2}
                id="operario"
                name="operario"
                controlLabel={t('PLANING.FILTROS.OPERARIO_COLECTA')}
                component={InputSelect}
                options={comboOperario}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
              />*/}
              </Row>
              <FiltrosModalPlaningPage />
              <Row>
              <Col sm={7} style={{width: '55%', paddingRight: 0}}></Col>
              <Col sm={5} style={{width: '45%', paddingLeft: 0}}><h3>{t('PLANING.MODAL.TABLE.TITLE')}</h3></Col>
              <Col sm={5} className="ancho-tablas-sugerencias" style={{paddingRight: '0px'}}>
                <div className='filtros-tabla-modal-verracos'>
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PLANNING] > 1) && [
                    <div className="custom-check-w" key="checkbox_key">
                      <Field
                        id="seleccionar-todo-sugerencias-planning"
                        name="seleccionar-todo-sugerencias-planning"
                        colSm={12}
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheckSugerencias(value)}
                      />
                    </div>,
                    <button key="boton1" type="button" className="anadir-verracos-sin-extraer btn btn-primary anadirquitarsugerencias" onClick={(e) => this.anadirSeleccionSugerenciasPlaning()}>
                      <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.ANADIR')}
                    </button>
                  ]}
                </div>
                <div className="contenedor-tables-sugerencias">
                  <SimpleTablePage {...tableVerraco}></SimpleTablePage>
                </div>
                <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
              </Col>
              <Col sm={2} className="ancho-botones">
                <div className="botones-asignar-verracos">
                   <Button type="button" className="asignar-verracos" onClick={() => this.anadirTodos()}><span>{">>"}</span></Button>
                   <Button type="button" className="asignar-verracos" onClick={() => this.anadirVerracosPlaning()}><span>{">"}</span></Button>
                   <Button type="button" className="asignar-verracos" onClick={() => this.quitarVerracosPlaning()}><span>{"<"}</span></Button>
                   <Button type="button" className="asignar-verracos" onClick={() => this.quitarTodos()}><span>{"<<"}</span></Button>
                </div>
              </Col>
              <Col sm={5} className="ancho-tablas-sugerencias" style={{paddingLeft: '0px'}}>
                <div className='filtros-tabla-modal-verracos'>
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PLANNING] > 1) && [
                    <div className="custom-check-w" key="checkbox_key">
                      <Field
                        id="seleccionar-todo-quitar-sugerencias-planning"
                        name="seleccionar-todo-quitar-sugerencias-planning"
                        colSm={12}
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheckQuitarSugerencias(value)}
                      />
                    </div>,
                    <button key="boton1" type="button" className="anadir-verracos-sin-extraer btn btn-primary anadirquitarsugerencias" onClick={(e) => this.quitarSeleccionSugerenciasPlaning()}>
                      <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.QUITAR')}
                    </button>,
                    <button key="boton2" type="button" className="anadir-verracos-sin-extraer btn btn-primary anadirquitarsugerencias" onClick={handleSubmit(this.anadirPrevisionVerracos.bind(this))}>
                      <AddCircleOutline></AddCircleOutline>{t('TITULOS_BOTONES.ANADIR_PREVISION')}
                   </button>
                  ]}
                </div>
                <div className="tabla-verracos-en-verracos contenedor-tables-sugerencias">
                  <SimpleTablePage {...table}></SimpleTablePage>
                </div>
                <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
              </Col>
              <div className="clearfix"></div>
              <Col sm={12} style={{marginTop: '20px'}}>
                <Row>
                  <Field
                    id="fechaPedidos"
                    name="fechaPedidos"
                    controlLabel={t('PLANING.TOTAL_FECHA_REFERENCIA_PEDIDOS')}
                    component={InputDatePicker}
                    colSm={2}
                    onInputChange={(value) => this.handleChangeFechaPedidos({fechaPedidos: value})}
                  />
                </Row>
              </Col>
              <Col sm={12} style={{padding: '15px', paddingTop: 0}}>
                <div className="totales-planing">
                  <Col sm={3} className="planing-total-verraco planning-total-dosis-pedido">
                    {t('PLANING.TOTAL_DOSIS_PERDIDAS')}: {datosTotalesModalPlaning.dosisPedidas !== undefined && number_formatter(datosTotalesModalPlaning.dosisPedidas, 0, separadorDec, separadorMil)}
                  </Col>
                  <Col sm={3} className="planing-total-verraco planning-total-dosis-planning">
                    {t('PLANING.TOTAL_TOTAL_DOSIS')}: {datosTotalesModalPlaning.dosisTotales !== undefined && number_formatter(datosTotalesModalPlaning.dosisTotales, 0, separadorDec, separadorMil)}
                  </Col>
                  <Col sm={3} className="planing-total-verraco planning-total-dosis-planning">
                    {t('PLANING.TOTAL_DOSIS_SIN_ASIGNAR')}: {datosTotalesModalPlaning.dosisSinAsignar !== undefined && number_formatter(datosTotalesModalPlaning.dosisSinAsignar, 0, separadorDec, separadorMil)}
                  </Col>
                  <Col sm={3} className="planing-total-verraco planning-total-dosis-planning" style={{border: 'none'}}>
                    {t('PLANING.TOTAL_DOSIS_ASIGNADAS')}: {datosTotalesModalPlaning.dosisAsisgnadas !== undefined && number_formatter(datosTotalesModalPlaning.dosisAsisgnadas, 0, separadorDec, separadorMil)}
                  </Col>
                </div>
              </Col>
            </Row>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true && this.state.clickEnPrevision === false ? ' disabled' : '')} onClick={this.props.submitSucceeded === false || this.state.clickEnPrevision === true ? handleSubmit(this.anadirPlaning.bind(this)) : null}>{t('PLANING.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalPlaning()}>{t('PLANING.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalPlaning',
})(ModalPlaning))

import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import ListadoPage from '../../containers/page/ListadoPage'
import FiltrosUsuariosPage from '../../containers/usuarios/FiltrosUsuariosPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Usuarios.scss'
import { date_parser } from '../../util/formatFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import {Delete, AddCircleOutline, Refresh, Launch, Print, FilterList} from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosUsuarios extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosUsuarios()
    } else {
      this.props.actions.deSeleccionarTodosUsuarios()
    }
  }

  componentDidMount () {
    this.props.actions.comboUsuariosCentro()
    this.props.change('estado', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  fetchUsuarios (values) {
    this.props.actions.fetchUsuarios(values)
  }

   abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelUsuarios !== prevProps.datosExcelUsuarios) {
      if (this.props.datosExcelUsuarios.excel && this.props.datosExcelUsuarios.excel !== null){
        let pdf = this.props.datosExcelUsuarios.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.SISTEMA.USUARIOS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfUsuarios !== prevProps.datosPdfUsuarios) {
      if (this.props.datosPdfUsuarios.pdf && this.props.datosPdfUsuarios.pdf !== null){
        let pdf = this.props.datosPdfUsuarios.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.SISTEMA.USUARIOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-usuarios', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosUsuarios()
          }
        }
      }
    }
  }

  deleteSeleccionUsuario () {
    const itemsSeleccion = this.props.listUsuarios.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idUsuario': row.idUsuario,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.deleteSeleccionUsuario(list)
  }

  cambioEstadoSeleccionUsuario () {
    const itemsSeleccion = this.props.listUsuarios.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idUsuario': row.idUsuario,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.cambioEstadoSeleccionUsuario(list)
  }

  crearCsvUsuarios () {
    this.props.actions.crearCsvUsuarios(this.props.filtros)
  }

  imprimirPdfUsuarios () {
    this.props.actions.imprimirPdfUsuarios(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    values.verraco = null
    this.props.actions.fetchUsuarios(values)
    this.props.change('verraco', null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
    const {
      t, handleSubmit, filtros, listUsuarios,
      actions:{abrirModalUsuarios},
      combos: {comboUsuariosCentro},
      auth: {limiteUsuarios}
    } = this.props

    let comboEstado = this.props.combos.comboEstado
    comboEstado = comboEstado.map((estado) => {
      return {...estado, label: t(estado.label)}
    })

    return (
      <div>
        <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="usuario"
              name="usuario"
              controlLabel={t('USUARIOS.FILTROS.USUARIO')}
              component={InputSelect}
              options={comboUsuariosCentro}
              valueKey="value"
              labelKey="label"
              customClass='select-usuarios'
              disabled={false}
              onInputChange={handleSubmit(this.fetchUsuarios.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('USUARIOS.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-usuarios'
              onInputChange={handleSubmit(this.fetchUsuarios.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.usuario !== null && filtros.usuario !== '' && filtros.usuario) ? (
                      <span className="badge-dark">{t('USUARIOS.FILTROS.USUARIO') + ': ' + filtros.usuario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('usuario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estado !== null && filtros.estado ? (
                      <span className="badge-dark">{t('USUARIOS.FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-usuarios"
                    name="seleccionar-todos-usuarios"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambioEstadoSeleccionUsuario()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionUsuario()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvUsuarios()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfUsuarios()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) ?
                   (
                    listUsuarios.length < limiteUsuarios ? (
                    abrirModalUsuarios) : (
                      limiteUsuarios === '0' || limiteUsuarios === null ? (abrirModalUsuarios) :
                    () => this.props.actions.yesNoModal('limite-usuarios'))
                    ) : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosUsuarios',
})(FiltrosUsuarios))
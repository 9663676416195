import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/usuarios/usuarios'
import { fetchUsuariosSuccess, filtrarUsuariosSuccess, fetchCentrosUsuariosSuccess, fetchUsuarioGrupoUsuariosSuccess, cerrarModalUsuarios, recuperarDatosUsuarioSuccess,
  eliminarGrupoUsuariosAnadidos, eliminarCentrosAnadidos, duplicarUsuarioSuccess, crearCsvUsuariosSuccess, imprimirPdfUsuariosSuccess, cerrarModalCambiarPass,
  cambiarPasswordUsuarioSuccess, submitNuevoUsuarioSuccess, cerrarModalCambiarTokenMagavision} from '../../actions/usuarios/usuarios'
import {cambiarImagenAuth} from '../../actions/auth/auth'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import getUsuarios from '../../services/usuarios/getUsuarios'
import getCentrosList from '../../services/usuarios/getCentrosList'
import getGruposUsuarioList from '../../services/usuarios/getGruposUsuarioList'
import addUsuario from '../../services/usuarios/addUsuario'
import getUsuario from '../../services/usuarios/getUsuario'
import editarUsuario from '../../services/usuarios/editarUsuario'
import getCambioEstadoUsuario from '../../services/usuarios/getCambioEstadoUsuario'
import onDeleteUsuario from '../../services/usuarios/onDeleteUsuario'
import onDeleteSeleccionUsuario from '../../services/usuarios/onDeleteSeleccionUsuario'
import getCambioEstadoSeleccionUsuario from '../../services/usuarios/getCambioEstadoSeleccionUsuario'
import csvUsuarios from '../../services/usuarios/csvUsuarios'
import pdfUsuarios from '../../services/usuarios/pdfUsuarios'
import updateUserPassword from '../../services/usuarios/updateUserPassword'
import {comboUsuariosCentro} from '../../actions/combos/combos'

export function * fetchUsuarios ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listUsuarios = yield call(getUsuarios, values, auth.token)
    yield put(fetchUsuariosSuccess(listUsuarios))
    // const filtros = yield select(state => state.filtros)
    yield put(filtrarUsuariosSuccess(values))
  } catch (error) {}
}
export function * watchFetchUsuarios () {
  yield takeLatest(actionTypes.FETCH_USUARIOS, fetchUsuarios)
}

export function * fetchCentrosUsuarios ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listCentrosUsuarios = yield call(getCentrosList, values, auth.token)
    yield put(fetchCentrosUsuariosSuccess(listCentrosUsuarios))
  } catch (error) {}
}
export function * watchFetchCentrosUsuarios () {
  yield takeLatest(actionTypes.FETCH_CENTROS_USUARIOS, fetchCentrosUsuarios)
}

export function * fetchUsuarioGrupoUsuarios ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listGrupoUsuarios = yield call(getGruposUsuarioList, values, auth.token)
    yield put(fetchUsuarioGrupoUsuariosSuccess(listGrupoUsuarios))
  } catch (error) {}
}
export function * watchFetchUsuarioGrupoUsuarios () {
  yield takeLatest(actionTypes.FETCH_USUARIO_GRUPO_USUARIOS, fetchUsuarioGrupoUsuarios)
}

export function * submitNuevoUsuario ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionUsuario = yield call(addUsuario, values, auth.token)
    if (insercionUsuario.insertado === true && insercionUsuario.existeUsuario == false) {
      yield put(openMensajeAlerta('insercion'))
      yield put(cerrarModalUsuarios())
      yield put(initialize('ModalUsuarios', ''))
      yield put(submitNuevoUsuarioSuccess(insercionUsuario))
      let filtros = yield select(state => state.usuarios.filtros)
      const list = yield call(getUsuarios, filtros, auth.token)
      yield put(fetchUsuariosSuccess(list))
    } else if (insercionUsuario.insertado === false && insercionUsuario.existeUsuario == false) {
      yield put(openMensajeAlerta('no-insercion'))
      yield put(cerrarModalUsuarios())
      yield put(initialize('ModalUsuarios', ''))
      yield put(submitNuevoUsuarioSuccess(insercionUsuario))
    } else if (insercionUsuario.insertado === false && insercionUsuario.existeUsuario == true) {
      yield put(submitNuevoUsuarioSuccess(insercionUsuario))
    }
    yield put(comboUsuariosCentro())
  } catch (error) {}
}
export function * watchSubmitNuevoUsuario () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_USUARIO, submitNuevoUsuario)
}

export function * recuperarDatosUsuario ({idUsuario}) {
  try {
    const dataUsuario = yield call(getUsuario, idUsuario, null)
    dataUsuario.idioma = {value: dataUsuario.idioma, label: dataUsuario.idioma}
    dataUsuario.formaFechaHora = {value: dataUsuario.formaFechaHora, label: dataUsuario.formaFechaHora}
    dataUsuario.separadorDec = {value: dataUsuario.separadorDec, label: dataUsuario.separadorDec}
    dataUsuario.separadorMil = {value: dataUsuario.separadorMil, label: dataUsuario.separadorMil}
    dataUsuario.zonaHoraria = {value: dataUsuario.zonaHoraria, label: dataUsuario.zonaHoraria}

    let fotmatFecHor = dataUsuario.formaFechaHora.value.split('/')
    dataUsuario.formaFecha = {value: fotmatFecHor[0] + '/' + fotmatFecHor[1] + '/' + fotmatFecHor[2], label: fotmatFecHor[0] + '/' + fotmatFecHor[1] + '/' + fotmatFecHor[2]}
    dataUsuario.formaHora = {value: '/' + fotmatFecHor[3], label: fotmatFecHor[3] + 'h'}

    yield put(eliminarGrupoUsuariosAnadidos(dataUsuario.grupos))
    yield put(eliminarCentrosAnadidos(dataUsuario.centros))
    yield put(initialize('ModalUsuarios', dataUsuario))
    yield put (recuperarDatosUsuarioSuccess(dataUsuario))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarDatosUsuario () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_USUARIO, recuperarDatosUsuario)
}

export function * editarUsuarios ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionUsuarios = yield call(editarUsuario, values, auth.token)
    if (edicionUsuarios.actualizado === true && edicionUsuarios.existeUsuario === false) {
      yield put(openMensajeAlerta('modificacion'))
      yield put(cerrarModalUsuarios())
      yield put(initialize('ModalUsuarios', ''))
      yield put(submitNuevoUsuarioSuccess(edicionUsuarios))
      // AÑADIR IMAGEN AL AUTH
      if (values.nombreUsuario === auth.nombreUsuario) {
        yield put(cambiarImagenAuth(values.imagen.file.imagebase64))
      }
      yield put(comboUsuariosCentro())
      let filtros = yield select(state => state.usuarios.filtros)
      if (filtros.usuario && values.nombreUsuario){
        filtros.usuario = {value: values.idUsuario, label: values.nombreUsuario}
      }
      const list = yield call(getUsuarios, filtros, auth.token)
      yield put(fetchUsuariosSuccess(list))
    } else if (edicionUsuarios.actualizado === false && edicionUsuarios.existeUsuario === false) {
      yield put(openMensajeAlerta('no-modificacion'))
      yield put(cerrarModalUsuarios())
      yield put(initialize('ModalUsuarios', ''))
      yield put(submitNuevoUsuarioSuccess(edicionUsuarios))
    } else if (edicionUsuarios.actualizado === false && edicionUsuarios.existeUsuario === true) {
      yield put(submitNuevoUsuarioSuccess(edicionUsuarios))
    }
  } catch (error) {}
}
export function * watchEditarUsuarios () {
  yield takeLatest(actionTypes.EDITAR_USUARIOS, editarUsuarios)
}

export function * duplicarUsuario ({idUsuario}) {
  try {
    const dataDuplicarUsuario = yield call(getUsuario, idUsuario, null)
    dataDuplicarUsuario.nombreUsuario = dataDuplicarUsuario.nombreUsuario + ' - Copia'
    dataDuplicarUsuario.idioma = {value: dataDuplicarUsuario.idioma, label: dataDuplicarUsuario.idioma}
    dataDuplicarUsuario.formaFechaHora = {value: dataDuplicarUsuario.formaFechaHora, label: dataDuplicarUsuario.formaFechaHora}
    dataDuplicarUsuario.separadorDec = {value: dataDuplicarUsuario.separadorDec, label: dataDuplicarUsuario.separadorDec}
    dataDuplicarUsuario.separadorMil = {value: dataDuplicarUsuario.separadorMil, label: dataDuplicarUsuario.separadorMil}
    yield put(eliminarGrupoUsuariosAnadidos(dataDuplicarUsuario.grupos))
    yield put(eliminarCentrosAnadidos(dataDuplicarUsuario.centros))
    yield put(initialize('ModalUsuarios', dataDuplicarUsuario))
    yield put (duplicarUsuarioSuccess(dataDuplicarUsuario))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarUsuario () {
  yield takeLatest(actionTypes.DUPLICAR_USUARIO, duplicarUsuario)
}

export function * cambiarEstadoUsuario ({idUsuario}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const cambioEstado = yield call(getCambioEstadoUsuario, idUsuario, null)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    let auth = yield select(state => state.auth)
    let filtros = yield select(state => state.usuarios.filtros)
    const list = yield call(getUsuarios, filtros, auth.token)
    yield put(fetchUsuariosSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiarEstadoUsuario () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_USUARIO, cambiarEstadoUsuario)
}

export function * eliminarUsuario ({idUsuario}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(onDeleteUsuario, idUsuario, null)
      if (eliminar.eliminado === true && eliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    let auth = yield select(state => state.auth)
    yield put(comboUsuariosCentro())
    let filtros = yield select(state => state.usuarios.filtros)
    if (filtros.usuario && filtros.usuario.value === idUsuario){
      filtros.usuario = null
    }
    const list = yield call(getUsuarios, filtros, auth.token)
    yield put(fetchUsuariosSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchEliminarUsuario () {
  yield takeLatest(actionTypes.ELIMINAR_USUARIO, eliminarUsuario)
}

export function * deleteSeleccionUsuario ({list}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(onDeleteSeleccionUsuario, {list: list}, auth.token)
      if (eliminar.eliminado === true && eliminar.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    yield put(initialize('simpleTable', {}))
    yield put(initialize('FiltrosUsuarios', {}))
    yield put(comboUsuariosCentro())
    let filtros = yield select(state => state.usuarios.filtros)
    list.forEach((li) => {
      if (filtros.usuario && filtros.usuario.value === li.idUsuario){
        filtros.usuario = null
      }
    })
    const lista = yield call(getUsuarios, filtros, auth.token)
    yield put(fetchUsuariosSuccess(lista))
    yield put(initialize('FiltrosUsuarios', filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDeleteSeleccionUsuario () {
  yield takeLatest(actionTypes.DELETE_SELECCION_USUARIO, deleteSeleccionUsuario)
}

export function * cambioEstadoSeleccionUsuario ({list}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const cambioEstado = yield call(getCambioEstadoSeleccionUsuario, {list: list}, auth.token)
      if (cambioEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
    }
    yield put(initialize('simpleTable', {}))
    yield put(initialize('FiltrosUsuarios', {}))
    let filtros = yield select(state => state.usuarios.filtros)
    const lista = yield call(getUsuarios, filtros, auth.token)
    yield put(fetchUsuariosSuccess(lista))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambioEstadoSeleccionUsuario () {
  yield takeLatest(actionTypes.CAMBIO_ESTADO_SELECCION_USUARIO, cambioEstadoSeleccionUsuario)
}

export function * crearCsvUsuarios ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelUsuarios = yield call(csvUsuarios, list, auth.token)
    yield put(crearCsvUsuariosSuccess(datosExcelUsuarios))
  } catch (error) {}
}
export function * watchCrearCsvUsuarios () {
  yield takeLatest(actionTypes.CREAR_CSV_USUARIOS, crearCsvUsuarios)
}

export function * imprimirPdfUsuarios ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfUsuarios = yield call(pdfUsuarios, list, auth.token)
    yield put(imprimirPdfUsuariosSuccess(datosPdfUsuarios))
  } catch (error) {}
}
export function * watchImprimirPdfUsuarios () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_USUARIOS, imprimirPdfUsuarios)
}

export function * cambiarPasswordUsuario ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionPass = yield call(updateUserPassword, values, auth.token)
    if (edicionPass.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      yield put(cerrarModalCambiarPass())
      yield put(cambiarPasswordUsuarioSuccess(edicionPass.actualizado))
    } else {
      // yield put(openMensajeAlerta('no-modificacion'))
      yield put(cambiarPasswordUsuarioSuccess(edicionPass.actualizado))
    }
    yield put(initialize('ModalCambiarPass', ''))
    let filtros = yield select(state => state.usuarios.filtros)
    const list = yield call(getUsuarios, filtros, auth.token)
    yield put(fetchUsuariosSuccess(list))
  } catch (error) {}
}
export function * watchCambiarPasswordUsuario () {
  yield takeLatest(actionTypes.CAMBIAR_PASSWORD_USUARIO, cambiarPasswordUsuario)
}

export function * cambiarTokenMagavision ({idElemento}) {
  try {
    let auth = yield select(state => state.auth)
    let listCentrosAnadidos = yield select(state => state.usuarios.listCentrosAnadidos)
    let magavisionToken = '' 
    listCentrosAnadidos.filter((centros) => {
      if (centros.idElemento === idElemento) {
        magavisionToken = centros.tokenMagavision
      }
    })
    yield put(initialize('ModalCambiarTokenMagavision', {tokenMagavision: magavisionToken}))
  } catch (error) {}
}
export function * watchCambiarTokenMagavision () {
  yield takeLatest(actionTypes.CAMBIAR_TOKEN_MAGAVISION, cambiarTokenMagavision)
}

export function * submitCambiarTokenMagavision ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let listCentrosAnadidos = yield select(state => state.usuarios.listCentrosAnadidos)
    const list = listCentrosAnadidos.map((centros) => {
      if (centros.idElemento === values.idElemento) {
        centros.tokenMagavision = values.tokenMagavision
      }
      return centros
    })
    yield put(eliminarCentrosAnadidos(listCentrosAnadidos))
    yield put(cerrarModalCambiarTokenMagavision())
  } catch (error) {}
}
export function * watchSubmitCambiarTokenMagavision () {
  yield takeLatest(actionTypes.SUBMIT_CAMBIAR_TOKEN_MAGAVISION, submitCambiarTokenMagavision)
}

import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './Usuarios.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Warning } from '@material-ui/icons'

class ModalListadoCentros extends Component {
  constructor () {
    super()
    this.state = {
      validarAnadirCentro: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalListCentro !== prevProps.showModalListCentro){
      this.props.actions.fetchCentrosUsuarios(this.props.listCentrosAnadidos)
    }
  }

  anadirListCentro (values) {
    const itemsSeleccion = this.props.listCentros.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCentro': row.idCentro,
          'idElemento': row.idElemento,
          'nombreCentro': row.nombreCentro
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (Object.keys(list).length > 0) {
      this.setState({validarAnadirCentro: true})
      this.props.actions.anadirListCentro(list)
    } else {
      this.setState({validarAnadirCentro: false})
    }
    this.props.change('seleccionar-todos-modal-centro', false)
    this.props.actions.deSeleccionarTodosModalListCentro()
  }

  intercambiarCheckModalListCentro (value) {
    this.setState({validarAnadirCentro: true})
    if (value === true) {
      this.props.actions.seleccionarTodosModalListCentro()
    } else {
      this.props.actions.deSeleccionarTodosModalListCentro()
    }
  }

  cerrarModalListCentro () {
    this.props.actions.cerrarModalListCentro()
  }

  render () {
    const {
      t, handleSubmit, listCentros, showModalListCentro, checkListCentros, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalListCentro }
    } = this.props

    listCentros.forEach(
      (row) => row.idElemento = '-cen-' + row.idCentro
    )

    const tableCentros = {
      id: 'centrosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      columns: [
        {id: 'nombreCentro', name: t('USUARIOS.MODAL.TABLA_CENTROS.NOMBRE')},
      ],
      rows: listCentros,
      rowsVerraco: true,
      mostrarModificar: false,
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      initialValues: {},
    }

    tableCentros.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableCentros.initialValues['check' + row.idElemento] = checkListCentros
        }
      }
    )

    return (
      <Modal show={showModalListCentro} onHide={() => this.cerrarModalListCentro()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-listado-centro" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('USUARIOS.MODAL.TABLA_CENTROS.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-verracos-en-centros">
                <div className="filtros-tabla-modal-centro acciones-tabla-filtros">
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todos-modal-centro"
                      name="seleccionar-todos-modal-centro"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckModalListCentro(value)}
                    />
                  </div>
                </div>
                <ListadoPage t_key="USUARIOS." noTitle={true} table={tableCentros}>
                </ListadoPage>
                {
                  /*this.state.validarAnadirCentro === false ? (
                    <p className="validar-asignar-verracos"><span className='help-block'><Warning />{t('USUARIOS.MODAL.ERROR_ANADIR_VERRACO')}</span></p>
                  ) : null*/
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirListCentro.bind(this))}>{t('USUARIOS.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalListCentro()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalListadoCentros',
  enableReinitialize: true
})(ModalListadoCentros))
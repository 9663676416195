import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAlbaran from '../../components/albaran/FiltrosAlbaran'
import {
  fetchAlbaranes, seleccionarTodosAlbaranes, deseleccionarTodosAlbaranes, deleteSeleccionAlbaranes,
  abrirModalVacioAlbaran, crearCsvAlbaranes, imprimirPdfAlbaranes, imprimirDetalleAlbaranes
} from '../../actions/albaran/albaran'
import {comboNumerosAlbaran, comboCliente, comboRuta, comboTipoDosis} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    albaran: state.albaran,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboNumerosAlbaran: state.combos.comboNumerosAlbaran,
      comboCliente: state.combos.comboCliente,
      comboEstadoAlbaran: state.combos.comboEstadoAlbaran,
      comboRuta: state.combos.comboRuta,
      comboTipoDosis: state.combos.comboTipoDosis
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAlbaranes,
      seleccionarTodosAlbaranes,
      deseleccionarTodosAlbaranes,
      deleteSeleccionAlbaranes,
      abrirModalVacioAlbaran,
      imprimirDetalleAlbaranes,
      crearCsvAlbaranes,
      imprimirPdfAlbaranes,
      comboNumerosAlbaran,
      comboCliente,
      comboRuta,
      comboTipoDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAlbaran))
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import { FilterList, AddCircleOutline } from '@material-ui/icons'
import {dateTimeZones, date_parser} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {
  tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants
} from '../../constants/analisis'


class FiltrosModalInicioNuevoSeguimiento extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: true,
      imputSelected: false,
      tipo: 1,
      fitrosSeleccionados: []
    }   
    this.fetchFiltrarModalNuevoSeguimiento = this.fetchFiltrarModalNuevoSeguimiento.bind(this);
  }

  componentDidMount () {
    var yesterday = new Date();
    yesterday.setDate(dateTimeZones(this.props.auth.zonaHorariaString).getDate()-1);
    this.props.initialize({
      fecha: date_parser(yesterday, this.props.auth.formaFechaHora)
    })

    let values = {fecha: date_parser(yesterday, this.props.auth.formaFechaHora), fechaSeguimiento: this.props.modalValues.fechaSeguimiento, operarioProcesado: this.props.modalValues.operarioProcesado}
    this.fetchFiltrarModalNuevoSeguimiento(values)
  }

  componentDidUpdate (prevProps) {
    if (this.props.modalValues && this.props.modalValues !== prevProps.modalValues) {
      //recargamos porque la fecha viene del modal
      let values = {...this.props.filtrosModalNuevoSeguimiento, fechaSeguimiento: this.props.modalValues.fechaSeguimiento, operarioProcesado: this.props.modalValues.operarioProcesado}
      this.fetchFiltrarModalNuevoSeguimiento(values)
    }
  }
  
  fetchFiltrarModalNuevoSeguimiento(values){
    values = {...values, fechaSeguimiento: this.props.modalValues.fechaSeguimiento, operarioProcesado: this.props.modalValues.operarioProcesado}
    this.props.actions.fetchFiltrarModalNuevoSeguimiento(values)    
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalControlSangre()
    } else {
      this.props.actions.deSeleccionarTodosModalControlSangre()
    }
  }

  quitarFiltros (value) {
    let values = this.props.filtrosModalNuevoSeguimiento
    values[value] = null
    this.props.actions.fetchFiltrarModalNuevoSeguimiento(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  anadirSeleccionSeguimiento() {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'id': row.id,
          'orden': row.orden,
          'tipo': row.tipo,
          'nombre': row.nombre,
          'nombreRaza': row.nombreRaza,
          'idSeguimiento': row.idSeguimiento,
          'idPool': row.idPool,
          'idAnalisis': row.idAnalisis,
          't_dtv_main': row.t_dtv_main,
          'tokenAnalisisMagavision': row.tokenAnalisisMagavision
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });

    if (list.length > 1) {
      //ordenamos
      list = list.sort(function(a, b){return a.orden - b.orden})
      this.props.actions.abrirMagavision(tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION, true, !!list[0].idPool, false, list)
    }else if (list.length == 1) {
      this.props.actions.openModalFormSeguimiento(list[0].idSeguimiento, false, !!list[0].idPool, list[0].idAnalisis, list[0].idPool)
    }else{
      this.props.actions.yesNoModal('fueraLimite', null, null, this.props.t('SIMPLE_MODAL.VERRACO_REQUERIDO'))
    }
  }

  render () {
    const {
       t, handleSubmit, filtrosModalNuevoSeguimiento
    } = this.props
    const tKey = 'PLAN_SANITARIO.CONTROL_SANGRE.'

    let comboVerracoSiNo = this.props.combos.comboVerracoSiNo
    comboVerracoSiNo = comboVerracoSiNo.map((verracoSiNoItem) => {
      return {...verracoSiNoItem, label: t(`COMUN.COMBOS.${verracoSiNoItem.label}`)}
    })


    return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <div className="custom-form">
            <Field
              colSm={3}
              id="fecha"
              name="fecha"
              controlLabel={t('PLAN_SANITARIO.CONTROL_SANGRE.COLUMNS.FECHA')}
              component={InputDatePicker}
              isClearable={false}
              onInputChange={handleSubmit(this.fetchFiltrarModalNuevoSeguimiento)}
            />
            <Field
              colSm={3}
              id="tipo"
              name="tipo"
              controlLabel={t('SEGUIMIENTO.FILTROS.VERRACO_POOL_SI_NO')}
              component={InputSelect}
              options={comboVerracoSiNo}
              //isClearable={false}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarModalNuevoSeguimiento)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosModalNuevoSeguimiento).length > 0 && [
                    (filtrosModalNuevoSeguimiento.fecha && <span key="filtros.fecha" className="badge-dark">{t('PLAN_SANITARIO.CONTROL_SANGRE.COLUMNS.FECHA') + ': ' + filtrosModalNuevoSeguimiento.fecha}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fecha')}> x </button></span>),
                    (filtrosModalNuevoSeguimiento.tipo && <span key="filtros.tipo"  className="badge-dark">{t('SEGUIMIENTO.FILTROS.VERRACO_POOL_SI_NO') + ': ' + filtrosModalNuevoSeguimiento.tipo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipo')}> x </button></span>),
                  ]
                }
              </div>
            </div>
          </div>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {/*(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-add-control-mofologico"
                    name="seleccionar-todos-add-control-mofologico"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>
              ]*/}
                <div className="button-group buttons-min">
                  <button type="button" className="btn btn-primary nuevo" onClick={(e) => this.anadirSeleccionSeguimiento()}>
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.ANADIR')}
                  </button>
                </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalInicioNuevoSeguimiento',
  initialValues: {    
  }
})(FiltrosModalInicioNuevoSeguimiento))

import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosOperario extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarOperario(values){
    this.props.actions.fetchFiltrarOperario(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosOperario()
    }
    if (this.props.datosExcelOperario !== prevProps.datosExcelOperario) {
      if (this.props.datosExcelOperario.excel && this.props.datosExcelOperario.excel !== null){
        let pdf = this.props.datosExcelOperario.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.OPERARIOS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfOperario !== prevProps.datosPdfOperario) {
      if (this.props.datosPdfOperario.pdf && this.props.datosPdfOperario.pdf !== null){
        let pdf = this.props.datosPdfOperario.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.MANTENIMIENTO_DATOS.OPERARIOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (Object.keys(this.props.list).length > 0) {
      if (this.props.list !== prevProps.list) {
        this.props.actions.comboNombresOperarios()
        this.props.actions.comboApellidosOperarios()
        this.props.actions.comboLocalidadOperarios()
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosOperario()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboNombresOperarios()
    this.props.actions.comboApellidosOperarios()
    this.props.actions.comboLocalidadOperarios()
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosOperario()
    } else {
      this.props.actions.deSeleccionarTodosOperario()
    }
  }

  cambiarEstadoSeleccionOperario(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idOperario': row.idOperario
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionOperario(list)
  }

  deleteSeleccionOperario(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idOperario': row.idOperario,
          'nombreOperario': row.nombreOperario,
          'apellidosOperario': row.apellidosOperario,
          'localidadOperario': row.localidadOperario
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionOperario(list)
  }

  crearCsvOperario(){
    this.props.actions.crearCsvOperario(this.props.filtros)
  }

  imprimirPdfOperario(){
    this.props.actions.imprimirPdfOperario(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarOperario(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalVacioOperario
    },
    combos: {
      comboNombresOperarios,
      comboApellidosOperarios,
      comboLocalidadOperarios
    }
  } = this.props

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="nombreOperario"
              name="nombreOperario"
              controlLabel={t('OPERARIO.MODAL.NOMBRE') + '*'}
              component={InputSelect}
              options={comboNombresOperarios}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarOperario.bind(this))}
            />
            <Field
              colSm={2}
              id="apellidosOperario"
              name="apellidosOperario"
              component={InputSelect}
              options={comboApellidosOperarios}
              valueKey="value"
              labelKey="label"
              controlLabel={t('OPERARIO.MODAL.APELLIDOS')}
              claseActivo="apellidos-operario"
              onInputChange={handleSubmit(this.fetchFiltrarOperario.bind(this))}
            />
            <Field
              colSm={2}
              id="localidadOperario"
              name="localidadOperario"
              component={InputSelect}
              options={comboLocalidadOperarios}
              valueKey="value"
              labelKey="label"
              controlLabel={t('OPERARIO.MODAL.LOCALIDAD')}
              claseActivo="localidad-operario"
              onInputChange={handleSubmit(this.fetchFiltrarOperario.bind(this))}
            />
            <Field
              colSm={2}
              id="activo-filtro-liena"
              name="activo"
              controlLabel={t('ESTADO.ESTADO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-estado'
              onInputChange={handleSubmit(this.fetchFiltrarOperario.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nombreOperario && filtros.nombreOperario !== null ? (
                      <span className="badge-dark">{t('OPERARIO.MODAL.NOMBRE') + ': ' + filtros.nombreOperario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreOperario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.apellidosOperario && filtros.apellidosOperario !== null ? (
                      <span className="badge-dark">{t('OPERARIO.MODAL.APELLIDOS') + ': ' + filtros.apellidosOperario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('apellidosOperario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.localidadOperario && filtros.localidadOperario !== null ? (
                      <span className="badge-dark">{t('OPERARIO.MODAL.LOCALIDAD') + ': ' + filtros.localidadOperario.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('localidadOperario')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo !== null ? (
                      <span className="badge-dark">{t('ESTADO.ESTADO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) && [
                <div className="custom-check-w" key="checkbox_key">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="blockOptions_key" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionOperario()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionOperario()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvOperario()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfOperario()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) ? abrirModalVacioOperario : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosOperario',
})(FiltrosOperario))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalLimpiezaSilos from '../../../components/planSanitario/bioseguridadHigiene/ModalLimpiezaSilos'
import {closeModal} from '../../../actions/common'
import {fetchLimpiezaSilos, cerrarModalLimpiezaSilos, seleccionarTodosModalLimpiezaSilos, deSeleccionarTodosModalLimpiezaSilos, anadirSilo
  } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchLimpiezaSilos,
      cerrarModalLimpiezaSilos,
      seleccionarTodosModalLimpiezaSilos,
      deSeleccionarTodosModalLimpiezaSilos,
      anadirSilo
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalLimpiezaSilos))

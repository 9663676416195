import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { ArrowBack, Refresh } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import InputPassword from '../comun/form/InputPassword'
import AlertSuccess from '../comun/alert/AlertSuccess'
import SimplePage from '../../containers/page/SimplePage'
import './login.scss'

class ResetPassword extends Component {
  constructor() {
    super()
    this.state = {
      status: null,
      redirect: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    const url = new URL(window.location.href.replace('#/', '')) // Replace solo es necesario con HashRouter.
    const resetToken = url.searchParams.get('token')
    new Promise((resolve, reject) => {
      this.props.actions.resetPassword({...values, resetToken}, resolve, reject)
    }).then(() => {
      this.setState(
        () => {
          return {status: true}
        },
        () => {
          window.setTimeout(() => {
            this.setState(() => {
              return {redirect: true}
            })
          }, 3000)
        }
      )
    }).catch(() => {
      this.setState(() => {
        return {status: false}
      })
    })
  }

  render () {
    const { t, handleSubmit } = this.props
    const t_key = 'RESET_PASSWORD.'
    if (this.state.redirect) {
      return (
        <Redirect to="/Login" />
      )
    }
    const url = new URL(window.location.href.replace('#/', ''))
    const idioma = url.searchParams.get('idioma')
    if (idioma === 'es' || idioma === 'en' || idioma === 'ch'){
      this.props.actions.cambiarIdiomaAuth(idioma)
    }

    return (
      <SimplePage title={t(`${t_key}TITLE`)} className="reset-password-container">
        {this.state.status && <AlertSuccess>{t(`${t_key}FORM.SUCCESS`)}</AlertSuccess>}
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Field
                id="pswUsuario"
                name="pswUsuario"
                colSm={12}
                controlLabel={t(`${t_key}FORM.NEW_PASSWORD`) + '*'}
                placeholder={t(`${t_key}FORM.NEW_PASSWORD`)}
                component={InputPassword}
                validate={required}
              />
              <Field
                id="rePswUsuario"
                name="rePswUsuario"
                colSm={12}
                controlLabel={t(`${t_key}FORM.RE_PASSWORD`) + '*'}
                placeholder={t(`${t_key}FORM.RE_PASSWORD`)}
                component={InputPassword}
                validate={required}
              />
              <Col sm={12}>
                <Button type="submit" className="btn-primary pull-right"><Refresh/>{t(`${t_key}FORM.RESET_PASSWORD`)}</Button>
                <Link to="/Login" className="btn btn-link pull-right"><ArrowBack/>{t(`${t_key}FORM.LOGIN`)}</Link>
              </Col>
            </Row>
          </form>
        </div>
      </SimplePage>
    )
  }
}

export default reduxForm({
  form: 'resetPassword',
  validate: (values, props) => {
    const t_key = 'RESET_PASSWORD.'
    let error = {}
    if (values.pswUsuario !== values.rePswUsuario) {
      error.pswUsuario = props.t(`${t_key}FORM.PASSWORDS_NO_MATCH`)
      error.rePswUsuario = props.t(`${t_key}FORM.PASSWORDS_NO_MATCH`)
    }
    return error
  }
})(ResetPassword)

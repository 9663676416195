import actionTypes from '../../constants/actions/escandallo/escandallo'

export function abrirModalEscandallo () {
  return {
    type: actionTypes.ABRIR_MODAL_ESCANDALLO
  }
}

export function closeModalEscandallo () {
  return {
    type: actionTypes.CLOSE_MODAL_ESCANDALLO
  }
}

export function submitNuevoEscandallo (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_ESCANDALLO,
    values
  }
}

export function submitNuevoEscandalloSuccess (listEscandallo) {
  return {
    type: actionTypes.SUBMIT_NUEVO_ESCANDALLO_SUCCESS,
    listEscandallo
  }
}

export function obtenerEscandalloAlmacen () {
  return {
    type: actionTypes.OBTENER_ESCANDALLOS_ALMACEN
  }
}

export function filtrarEscandallo (values) {
  return {
    type: actionTypes.FILTRAR_ESCANDALLO,
    values
  }
}

export function filtrarEscandalloSuccess (listEscandallo) {
  return {
    type: actionTypes.FILTRAR_ESCANDALLO_SUCCESS,
    listEscandallo
  }
}

export function filtrosEscandalloSuccess (filtros) {
  return {
    type: actionTypes.FILTROS_ESCANDALLO_SUCCESS,
    filtros
  }
}

export function recuperarDatosModalEscandallo (idEscandallo) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO,
    idEscandallo
  }
}

export function recuperarDatosModalEscandalloSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_SUCCESS,
    data
  }
}

export function editarEscandallo (values) {
  return {
    type: actionTypes.EDITAR_ESCANDALLO,
    values
  }
}

export function duplicarDatosModalEscandallo (idEscandallo) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO,
    idEscandallo
  }
}

export function duplicarDatosModalEscandalloSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoEscandallo (idEscandallo) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_ESCANDALLO,
    idEscandallo
  }
}

export function onDeleteEscandallo (idEscandallo) {
  return {
    type: actionTypes.ON_DELETE_ESCANDALLO,
    idEscandallo
  }
}

export function seleccionarTodosEscandallo () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_ESCANDALLO
  }
}

export function deSeleccionarTodosEscandallo () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ESCANDALLO
  }
}

export function cambiarEstadoSeleccionEscandallo (list) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_ESCANDALLO,
    list
  }
}

export function deleteSeleccionEscandallo (list) {
  return {
    type: actionTypes.DELETE_SELECCION_ESCANDALLO,
    list
  }
}

export function crearCsvEscandallo(list){
  return {
    type: actionTypes.CREATE_CSV_ESCANDALLO,
    list
  }
}

export function crearCsvEscandalloSuccess(datosExcelEscandallo){
  return {
    type: actionTypes.CREATE_CSV_ESCANDALLO_SUCCESS,
    datosExcelEscandallo
  }
}

export function imprimirPdfEscandallo(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_ESCANDALLO,
    list
  }
}

export function imprimirPdfEscandalloSuccess(datosPdfEscandallo){
  return {
    type: actionTypes.IMPRIMIR_PDF_ESCANDALLO_SUCCESS,
    datosPdfEscandallo
  }
}

export function andadirEscandalloAnalisis (escandalloAnalisis) {
  return {
    type: actionTypes.ANADIR_ESCANDALLO_ANALISIS,
    escandalloAnalisis
  }
}

export function andadirEscandalloDilucion (escandalloDilucion) {
  return {
    type: actionTypes.ANADIR_ESCANDALLO_DILUCION,
    escandalloDilucion
  }
}

export function andadirEscandalloEnvasado (escandalloEnvasado) {
  return {
    type: actionTypes.ANADIR_ESCANDALLO_ENVASADO,
    escandalloEnvasado
  }
}

export function abrirModalAnadirEscandallo (proceso) {
  return {
    type: actionTypes.ABRIR_MODAL_ANADIR_ESCANDALLO,
    proceso
  }
}

export function closeModalAnadirEscandallo () {
  return {
    type: actionTypes.CLOSE_MODAL_ANADIR_ESCANDALLO
  }
}

export function editarEscandalloAnadido (values, proceso) {
  return {
    type: actionTypes.EDITAR_ESCANDALLO_ANADIDO,
    values,
    proceso
  }
}

export function editarEscandalloAnadidoSuccess (datosAnadidos, proceso) {
  return {
    type: actionTypes.EDITAR_ESCANDALLO_ANADIDO_SUCCESS,
    datosAnadidos,
    proceso
  }
}

export function onDeleteEscandalloAnadido (idElemento, proceso) {
  return {
    type: actionTypes.ON_DELETE_ESCANDALLO_ANADIDO,
    idElemento,
    proceso
  }
}

export function onDeleteEscandalloAnadidoSuccess (datosAnadidos, proceso) {
  return {
    type: actionTypes.ON_DELETE_ESCANDALLO_ANADIDO_SUCCESS,
    datosAnadidos,
    proceso
  }
}

// TODO Eliminar si al final eliminamos la funcionalidad de duplicar producto de un escandallo
export function duplicarDatosModalEscandalloAnadido (idElemento, proceso) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO,
    idElemento,
    proceso
  }
}

export function duplicarDatosModalEscandalloAnadidoSuccess (dataDuplicarEscandallo) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS,
    dataDuplicarEscandallo
  }
}

export function seleccionarTodosEscandalloAnadidos (proceso) {
  return {
    type: actionTypes.SELECCIONAR_TODOS_ESCANDALLO_ANADIDOS,
    proceso
  }
}

export function deSeleccionarTodosEscandalloAnadidos (proceso) {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ESCANDALLO_ANADIDOS,
    proceso
  }
}

export function deleteSeleccionEscandalloAnadido (datosAnadidos, proceso) {
  return {
    type: actionTypes.DELETE_SELECCION_ESCANDALLO_ANADIDO,
    datosAnadidos,
    proceso
  }
}

export function recuperarDatosModalEscandalloAnadido (idElemento, proceso) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO,
    idElemento,
    proceso
  }
}

export function recuperarDatosModalEscandalloAnadidoSuccess (dataEscandallo) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_ESCANDALLO_ANADIDO_SUCCESS,
    dataEscandallo
  }
}

export function guardarYnuevoEscandallo (values) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ESCANDALLO,
    values
  }
}

export function guardarYnuevoEscandalloSuccess () {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_ESCANDALLO_SUCCESS,
  }
}

export default {
  closeModalEscandallo,
  abrirModalEscandallo,
  submitNuevoEscandallo,
  submitNuevoEscandalloSuccess,
  obtenerEscandalloAlmacen,
  filtrarEscandallo,
  filtrarEscandalloSuccess,
  filtrosEscandalloSuccess,
  recuperarDatosModalEscandallo,
  recuperarDatosModalEscandalloSuccess,
  editarEscandallo,
  duplicarDatosModalEscandallo,
  duplicarDatosModalEscandalloSuccess,
  cambiarEstadoEscandallo,
  onDeleteEscandallo,
  seleccionarTodosEscandallo,
  deSeleccionarTodosEscandallo,
  cambiarEstadoSeleccionEscandallo,
  deleteSeleccionEscandallo,
  crearCsvEscandallo,
  imprimirPdfEscandallo,
  crearCsvEscandallo,
  crearCsvEscandalloSuccess,
  imprimirPdfEscandallo,
  imprimirPdfEscandalloSuccess,
  andadirEscandalloAnalisis,
  andadirEscandalloDilucion,
  andadirEscandalloEnvasado,
  abrirModalAnadirEscandallo,
  closeModalAnadirEscandallo,
  editarEscandalloAnadido,
  editarEscandalloAnadidoSuccess,
  onDeleteEscandalloAnadido,
  onDeleteEscandalloAnadidoSuccess,
  duplicarDatosModalEscandalloAnadido,
  duplicarDatosModalEscandalloAnadidoSuccess,
  seleccionarTodosEscandalloAnadidos,
  deSeleccionarTodosEscandalloAnadidos,
  deleteSeleccionEscandalloAnadido,
  recuperarDatosModalEscandalloAnadido,
  recuperarDatosModalEscandalloAnadidoSuccess,
  guardarYnuevoEscandallo,
  guardarYnuevoEscandalloSuccess
}
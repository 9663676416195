import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import InputDatePicker from '../comun/form/InputDatePicker'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {permisos as permisosConstant} from '../../constants/permisos'
import { required } from '../../util/validationFunctions'
import moment from 'moment'

class ModalPrevisionPedidos extends Component {
  
  submitCrearPrevisionPedidos (values) {   
    this.props.actions.anadirPrevisionPedidos(values)
  }

  cerrarModalPrevisionPedidos () {    
    //this.props.initialize({fecha: fecha})
    this.props.actions.cerrarModalPrevisionPedidos()
  }

  render () {
    const {
      t, handleSubmit, showModalPrevisionPedidos, list, data,
      actions: {closeModal}
    } = this.props
    return (
      <Modal show={showModalPrevisionPedidos} onHide={() => this.cerrarModalPrevisionPedidos()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-copia-seguridad">
      <form className="form-operario">
        <Modal.Header closeButton>
          <Modal.Title>
          {t('PEDIDOS.PEDIDOS_VENTA.MODAL.PREVISION_PEDIDOS.CREAR') }
          </Modal.Title>          
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={8}
              id="fecha"
              name="fecha"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.FECHA') + '*'}
              component={InputDatePicker}
              valueKey="value"
              labelKey="label"
              customClass='nombre-pedidos-venta'
              validate={required}
              fechaPrincipio={moment()}
              fechaFin={moment().add(6, 'days')}          
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            onClick={handleSubmit(this.submitCrearPrevisionPedidos.bind(this))}
            className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) ? ' disabled' : '')}            
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2)}
          >{t('PEDIDOS.PEDIDOS_VENTA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalPrevisionPedidos()}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalPrevisionPedidos',
})(ModalPrevisionPedidos))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {nOkResponse, okResponse, openMensajeAlerta, closeMensajeAlerta} from '../../../actions/common'
import MensajeAlerta from '../../../components/comun/mensajeAlerta/MensajeAlerta'
import { withTranslation } from 'react-i18next'

export function mapStateToProps (state) {
  return {
    ...state.common.mensajeAlerta,
    showMensajeAlerta: state.common.showMensajeAlerta,
    typeAlerta: state.common.typeAlerta,
    customTextAlerta: state.common.customTextAlerta,
    customTextAlertaError: state.common.customTextAlertaError,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      nOkResponse,
      okResponse,
      openMensajeAlerta,
      closeMensajeAlerta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MensajeAlerta))

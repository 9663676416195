import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosControlSemen from '../../../components/planSanitario/controlSemen/FiltrosControlSemen'
import {
  fetchControlSemen, seleccionarTodosControlSemen, deSeleccionarTodosControlSemen, deleteControlSemenSeleccion,
  crearCsvControlSemen, imprimirPdfControlSemen, abrirModalInicio, fetchFiltrarModalInicio, imprimirEtiquetaControlSemen
} from '../../../actions/planSanitario/controlSemen/controlSemen'
import {comboVerraco, comboRaza, comboLineaGenetica} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.controlSemen,
    auth: state.auth,
    controlSemenTable: state.form.controlSemenTable,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerraco: state.combos.comboVerraco,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
      comboTipoControlSemen: state.combos.comboTipoControlSemen
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlSemen,
      seleccionarTodosControlSemen,
      deSeleccionarTodosControlSemen,
      deleteControlSemenSeleccion,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      crearCsvControlSemen,
      imprimirPdfControlSemen,
      abrirModalInicio,
      fetchFiltrarModalInicio,
      imprimirEtiquetaControlSemen
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosControlSemen))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ArrowForward, Unarchive } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import AlertDanger from '../comun/alert/AlertDanger'
import SimplePage from '../../containers/page/SimplePage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './login.scss'
import Dropzone from 'react-dropzone'

class ActualizarAplicacion extends Component {
  constructor() {
    super();
    this.state = {
      archivos: ''
    }
  }

  componentDidMount () {
    this.props.actions.comprobarEntorno()
    this.props.actions.subirArchivosActualizarAplicacion({})
  }

  submitActualizarAplicacion(values) {
    this.props.actions.submitActualizarAplicacion(this.state.archivos)
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosActualizarAplicacion({archivo: archivo.name, content: reader.result})
        this.setState({archivos: {archivo: archivo.name, content: reader.result}})
      }
      reader.readAsDataURL(archivo)
    })
  }

  render () {
    const { t, handleSubmit, auth: {licenciaExpirada, isCloud, numeroSerie, archivo} } = this.props
    const t_key = 'LOGIN.'

    return (
      <SimplePage className="renovar-licencia-container" noTitle={true}>
        <div>
          <MensajeAlertaPage />
          <form>
          <h2>{t(`LOGIN.FORM.ACTUALIZAR_APLICACION`)}</h2>
            <Row>
              <div className="archivos-copia-serguridad">
                <Dropzone
                  accept=".zip"
                  onDrop={this.onDrop.bind(this)}
                  uploadMultiple={true}
                  maxFiles={null}
                  // maxSize={200000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}  className="custom-file-label">
                      <input {...getInputProps()} />
                      <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                    </div>
                  )}
                </Dropzone>
              </div>
              {
                Object.keys(archivo).length > 0 ? (
                  <p className="nombre-archivo-subido"><Unarchive />{archivo.archivo}</p>
                ) : null
              }
            </Row>
            <Row>
              <Col sm={12} style={{marginTop: '10px'}}>
                {
                  isCloud === false ? (
                    <div>
                    <Button type="button" onClick={handleSubmit(this.submitActualizarAplicacion.bind(this))} className="btn-primary pull-right">{t(`${t_key}FORM.GUARDAR`)}</Button>
                    <Link to="/" className="link-volver-login pull-right">{t(`${t_key}FORM.VOLVER`)}</Link>
                    </div>
                  ) : null
                }
              </Col>
            </Row>
          </form>
        </div>
      </SimplePage>
    )
  }
}

export default reduxForm({
  form: 'actualizarAplicacion'
})(ActualizarAplicacion)

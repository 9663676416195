import {select, put, call, takeLatest} from 'redux-saga/effects'
import {initialize, change} from 'redux-form'
import authActionTypes from '../../constants/actions/auth/auth'
import {fetchTokenSuccess, loginSuccess, loginError, logoutSuccess, licenciaExpiradaSuccess, comprobarEntornoSuccess, comprobarEntornoLocalSuccess, obtenerNumeroSerieSuccess, mostrarInformacionLicenciaSuccess} from '../../actions/auth/auth'
import {comboObtenerCentroUsuario} from '../../actions/combos/combos'
import {openMensajeAlerta} from '../../actions/common'
import fetchTokenService from '../../services/auth/fetchToken'
import loginService from '../../services/auth/login'
import adminLoginService from '../../services/auth/adminLogin'
import logoutService from '../../services/auth/logout'
import requestResetPasswordService from '../../services/auth/requestResetPassword'
import resetPasswordService from '../../services/auth/resetPassword'
import saveSessionService from '../../services/session/saveSession'
import deleteSessionService from '../../services/session/deleteSession'
import updateTokenService from '../../services/session/updateToken'
import testAuthService from '../../services/auth/testAuth'
import addNewToken from '../../services/auth/addNewToken'
import getComprobarEntorno from '../../services/auth/getComprobarEntorno'
import getComprobarEntornoLocal from '../../services/auth/getComprobarEntornoLocal'
import getNumeroSerie from '../../services/auth/getNumeroSerie'
import updateApplication from '../../services/auth/updateApplication'
import getInformacionLicencia from '../../services/auth/getInformacionLicencia'

export function * fetchToken ({resolve, reject}) {
  try {
    let {token} = yield call(fetchTokenService)
    yield put(fetchTokenSuccess(token))
    if (resolve) yield call(resolve)
  } catch (error) {
    console.log(error)
    if (reject) yield call(reject)
  } finally {
  }
}

export function * watchFetchToken () {
  yield takeLatest(authActionTypes.FETCH_TOKEN, fetchToken)
}

export function * updateToken ({token}) {
  try {
    yield call(updateTokenService, token)
    yield put(fetchTokenSuccess(token))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchUpdateToken () {
  yield takeLatest(authActionTypes.UPDATE_TOKEN, updateToken)
}

export function * login ({data, adminLogin}) {
  try {
    
    let auth = yield select(state => state.auth)
    let response = yield call(adminLogin ? adminLoginService : loginService, data, auth.token)
    response.adminLogin = adminLogin
    if (response.idModalidad) response.modulos = response.idModalidad.split(';')
    if(response.customizations) response.customizations = response.customizations.split(';');        
    yield put(loginSuccess(response))    
    yield call(saveSessionService, response)
    if (!adminLogin) yield put(comboObtenerCentroUsuario())
  } catch (error) {
    console.log('First login attempt failed, getting new token and trying again.')
    yield call(ensureUpdatedTokenAndLogin, {data, adminLogin})
  } finally {
  }
}

export function * watchLogin () {
  yield takeLatest(authActionTypes.LOGIN, login)
}

// Esta función permite asegurarnos que el login no se ha podido realizar debido a credenciales invalidas ya que el token está actualizado.
export function * ensureUpdatedTokenAndLogin ({data, adminLogin}) {
  try {
    let {token} = yield call(fetchTokenService)
    yield put(fetchTokenSuccess(token))
    let response = yield call(adminLogin ? adminLoginService : loginService, data, token)
    response.adminLogin = adminLogin    
    if (response.idModalidad) response.modulos = response.idModalidad.split(';')
    yield put(loginSuccess(response))
    yield call(saveSessionService, response)
    yield put(comboObtenerCentroUsuario())
  } catch (error) {
    console.log(error)
    console.log('Second login attempt failed, check logs for more info. Usually invalid credentials.')
    if (error.json) {
      yield put(licenciaExpiradaSuccess(error.json.licenciaExpirada))
    } else {
      yield put(licenciaExpiradaSuccess(false))
    }
    yield put(loginError())
  } finally {
  }
}

export function * logout ({expiredSession}) {
  try {
    yield call(logoutService)
    yield call(deleteSessionService)
    yield put(logoutSuccess(expiredSession))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchLogout () {
  yield takeLatest(authActionTypes.LOGOUT, logout)
}

export function * requestResetPassword ({data, resolve, reject}) {
  try {
    let auth = yield select(state => state.auth)
    yield call(requestResetPasswordService, data, auth.token)
    if(resolve) yield resolve()
  } catch (error) {
    console.log(error)
    if(reject) yield reject()
  } finally {
  }
}

export function * watchRequestResetPassword () {
  yield takeLatest(authActionTypes.REQUEST_RESET_PASSWORD, requestResetPassword)
}

export function * resetPassword ({data, resolve, reject}) {
  try {
    let auth = yield select(state => state.auth)
    yield call(resetPasswordService, data, auth.token)
    if(resolve) yield resolve()
  } catch (error) {
    console.log(error)
    if(reject) yield reject()
  } finally {
  }
}

export function * watchResetPassword () {
  yield takeLatest(authActionTypes.RESET_PASSWORD, resetPassword)
}

export function * testAuth ({val}) {
  try {
    let auth = yield select(state => state.auth)
    let {token} = yield call(testAuthService, {test: val}, auth.token)
    yield call(updateToken, {token})
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchTestAuth () {
  yield takeLatest(authActionTypes.TEST_AUTH, testAuth)
}

export function * submitRenovarToken ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const renovarLicencia = yield call(addNewToken, values, auth.token)
    console.log(renovarLicencia)
    if (renovarLicencia.licenciasModificadas === true) {
      yield put(openMensajeAlerta('modificacion'))
      yield put(initialize('renovarLicencia', {}))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    let datosLicencia = yield call(getInformacionLicencia, null)
    yield put(mostrarInformacionLicenciaSuccess(datosLicencia))

  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchSubmitRenovarToken () {
  yield takeLatest(authActionTypes.SUBMIT_RENOVAR_TOKEN, submitRenovarToken)
}

export function * comprobarEntorno () {
  try {
    let isCloud = yield call(getComprobarEntorno, null)
    yield put(comprobarEntornoSuccess(isCloud))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchComprobarEntorno () {
  yield takeLatest(authActionTypes.COMPROBAR_ENTORNO, comprobarEntorno)
}

export function * comprobarEntornoLocal () {
  try {
    let isLocal = yield call(getComprobarEntornoLocal, null)
    yield put(comprobarEntornoLocalSuccess(isLocal))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchComprobarEntornoLocal () {
  yield takeLatest(authActionTypes.COMPROBAR_ENTORNO_LOCAL, comprobarEntornoLocal)
}

export function * obtenerNumeroSerie () {
  try {
    let {id} = yield call(getNumeroSerie)
    yield put(obtenerNumeroSerieSuccess(id))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchObtenerNumeroSerie () {
  yield takeLatest(authActionTypes.OBTENER_NUMERO_SERIE, obtenerNumeroSerie)
}

export function * submitActualizarAplicacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let actualizado = yield call(updateApplication, {archivo: values}, auth.token)
    if (actualizado.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchSubmitActualizarAplicacion () {
  yield takeLatest(authActionTypes.SUBMIT_ACTUALIZAR_APLICACION, submitActualizarAplicacion)
}

export function * mostrarInformacionLicencia () {
  try {
    let datosLicencia = yield call(getInformacionLicencia, null)
    yield put(mostrarInformacionLicenciaSuccess(datosLicencia))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchMostrarInformacionLicencia () {
  yield takeLatest(authActionTypes.MOSTRAR_INFORMACION_LICENCIA, mostrarInformacionLicencia)
}

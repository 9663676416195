import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import FiltrosListadoSeguimiento from '../../components/seguimiento/FiltrosListadoSeguimiento'
import {
  openModalFormSeguimiento, seleccionarTodosModalSeguimiento, deSeleccionarTodosModalSeguimiento, deleteSeleccionSeguimiento
} from '../../actions/seguimiento/seguimiento'

const mapStateToProps = (state) => {
  return {
    ...state.seguimiento,
    listadoSeguimientoTable: state.form.listadoSeguimientoTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModalFormSeguimiento,
      seleccionarTodosModalSeguimiento,
      deSeleccionarTodosModalSeguimiento,
      deleteSeleccionSeguimiento
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FiltrosListadoSeguimiento)))
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import { yesNoModal } from '../../actions/common'
import {
  submitFormAnalisisExtraccion, recuperarDatosFormAnalisisExtraccion, fetchCodigoAnalisisExtracciones, updateComboVerraco,
  fetchDatosVerracoExtracciones, changeTipoAnalisisExtraccion, handleAnalisisCalcFunctionsOrden, calcConcentracionTotal,
  calcConcentracionUtil, calcConcentracionXDosis, calcNumeroDosis, reCalcConcentracionXDosis, calcVolumenDiluyente,
  initializeFormAnalisisExtraccion, initializeFormAnalisisExtraccionSuccess, abrirModalDilucionAnalisisExtraccion, abrirModalPoolAnalisisExtraccion,
  abrirMagavision, verInformeMagavision, obtenerYGuardarResultadoSistemaAnalisis, guardarResultadoSistemaAnalisis, fetchAnalisisExtracciones,
  pdfEtiquetaExtraccion, imprimirEtiquetaPDF, abrirBascula, imprimirMiniEtiquetaAnalisis, lecturaRFIDAnalisis } from '../../actions/analisisExtracciones/analisisExtracciones'
import {comprobarStockTrazabilidad, obtenerLotesEscandalloAnalisis, openModalTrazabilidadMaterial} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import {imprimirMiniEtiquetaPool} from '../../actions/poolExtracciones/poolExtracciones'
import {openModalContajeMaquinas} from '../../actions/analisisExtracciones/contajeMaquinas'
import {openModalListadoSeguimiento} from '../../actions/seguimiento/seguimiento'
import {fetchPanelControl} from '../../actions/panelControl/panelControl'
import {comboVerraco, comboOperarioPredeterminado, fetchComboRectaCalibrado, comboTipoDosis, fetchComboTipoDiluyente, comboOperarioActivo, comboObservacionesEyaculadoEliminado} from '../../actions/combos/combos'
import FormAnalisisExtraccion from '../../components/analisisExtracciones/FormAnalisisExtraccion'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: {
      comboAnalisisExtraccion: state.combos.comboAnalisisExtraccion,
      comboVerraco: state.combos.comboVerraco,
      comboOperarioPredeterminado: state.combos.comboOperarioPredeterminado,
      comboTipoAnalisisExtraccion: state.combos.comboTipoAnalisisExtraccion,
      comboTipoDosis: state.combos.comboTipoDosis,
      comboTipoDiluyente: state.combos.comboTipoDiluyente,
      comboRectaCalibrado: state.combos.comboRectaCalibrado,
      comboOperarioActivo: state.combos.comboOperarioActivo,
      comboObservacionesEyaculadoEliminado: state.combos.comboObservacionesEyaculadoEliminado
    },
    panelControl: state.panelControl,
    extraccion: state.analisisExtracciones.extraccion,
    filtros: state.analisisExtracciones.filtros,
    list: state.analisisExtracciones.list,
    stockInsuficiente: state.trazabilidadMaterial.stockInsuficiente[categoriasEscandallos.ANALISIS],
    showModalFormSeguimiento: state.seguimiento.modalFormSeguimiento.showModalFormSeguimiento,
    formAnalisisExtraccion: getFormValues('formAnalisisExtraccion')(state),
    showAlerta: state.analisisExtracciones.showAlerta
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchAnalisisExtracciones,
      submitFormAnalisisExtraccion,
      recuperarDatosFormAnalisisExtraccion,
      fetchCodigoAnalisisExtracciones,
      updateComboVerraco,
      fetchDatosVerracoExtracciones,
      changeTipoAnalisisExtraccion,
      comprobarStockTrazabilidad,
      obtenerLotesEscandalloAnalisis,
      openModalTrazabilidadMaterial,
      openModalContajeMaquinas,
      fetchPanelControl,
      comboVerraco,
      comboOperarioPredeterminado,
      fetchComboRectaCalibrado,
      comboTipoDosis,
      comboObservacionesEyaculadoEliminado,
      fetchComboTipoDiluyente,
      handleAnalisisCalcFunctionsOrden,
      calcConcentracionTotal,
      calcConcentracionUtil,
      calcConcentracionXDosis,
      calcNumeroDosis,
      reCalcConcentracionXDosis,
      calcVolumenDiluyente,
      initializeFormAnalisisExtraccion,
      initializeFormAnalisisExtraccionSuccess,
      abrirModalDilucionAnalisisExtraccion,
      abrirModalPoolAnalisisExtraccion,
      abrirMagavision,
      verInformeMagavision,
      obtenerYGuardarResultadoSistemaAnalisis,
      guardarResultadoSistemaAnalisis,
      openModalListadoSeguimiento,
      pdfEtiquetaExtraccion,
      imprimirMiniEtiquetaAnalisis,
      comboOperarioActivo,
      imprimirEtiquetaPDF,
      abrirBascula,
      imprimirMiniEtiquetaPool,
      lecturaRFIDAnalisis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormAnalisisExtraccion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalRegistroAlimentacion from '../../../components/planSanitario/registroAlimentacion/ModalRegistroAlimentacion'
import {submitNuevoRegistroAlimentacion, recuperarDatosModalRegistroAlimentacion, editarRegistroAlimentacion, guardarYnuevoRegistroAlimentacion, abrirModalVerracoRegistroAlimentacion,
  seleccionarTodosModalRegistroAlimentacion, deSeleccionarTodosModalRegistroAlimentacion, onDeleteVerracoAnadidoRegistroAlimentacion, deleteSeleccionVerracosRegistroAlimentacion, closeModalRegistroAlimentacion, imprimirInformeRegistroAlimentacion } from '../../../actions/planSanitario/registroAlimentacion/registroAlimentacion'
import {comboTipoAlimentacion, comboOperarioPredeterminado} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.registroAlimentacion,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalRegistroAlimentacion,
      submitNuevoRegistroAlimentacion,
      recuperarDatosModalRegistroAlimentacion,
      editarRegistroAlimentacion,
      guardarYnuevoRegistroAlimentacion,
      abrirModalVerracoRegistroAlimentacion,
      seleccionarTodosModalRegistroAlimentacion,
      deSeleccionarTodosModalRegistroAlimentacion,
      onDeleteVerracoAnadidoRegistroAlimentacion,
      deleteSeleccionVerracosRegistroAlimentacion,
      comboTipoAlimentacion,
      comboOperarioPredeterminado,
      imprimirInformeRegistroAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRegistroAlimentacion))
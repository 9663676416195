export default {
  FETCH_RUTA: 'FETCH_RUTA',
  FETCH_RUTA_SUCCESS: 'FETCH_RUTA_SUCCESS',
  ABRIR_MODAL_VACIO_RUTA: 'ABRIR_MODAL_VACIO_RUTA',
  ABRIR_MODAL_VACIO_RUTA_SUCCESS: 'ABRIR_MODAL_VACIO_RUTA_SUCCESS',
  ABRIR_MODAL_CLIENTE_RUTA: 'ABRIR_MODAL_CLIENTE_RUTA',
  CERRAR_MODAL_CLIENTE_RUTA: 'CERRAR_MODAL_CLIENTE_RUTA',
  SELECCIONAR_TODOS_MODAL_CLIENTE_RUTA: 'SELECCIONAR_TODOS_MODAL_CLIENTE_RUTA',
  DESELECCIONAR_TODOS_MODAL_CLIENTE_RUTA: 'DESELECCIONAR_TODOS_MODAL_CLIENTE_RUTA',
  ANADIR_CLIENTE: 'ANADIR_CLIENTE',
  ON_DELETE_CLIENTE_ANADIDO: 'ON_DELETE_CLIENTE_ANADIDO',
  ON_DELETE_CLIENTE_ANADIDO_SECCESS: 'ON_DELETE_CLIENTE_ANADIDO_SECCESS',
  SUBMIT_NUEVO_RUTA: 'SUBMIT_NUEVO_RUTA',
  FETCH_CLIENTE_RUTA: 'FETCH_CLIENTE_RUTA',
  FETCH_CLIENTE_RUTA_SUCCESS: 'FETCH_CLIENTE_RUTA_SUCCESS',
  SELECCIONAR_TODOS_MODAL_RUTA: 'SELECCIONAR_TODOS_MODAL_RUTA',
  DESELECCIONAR_TODOS_MODAL_RUTA: 'DESELECCIONAR_TODOS_MODAL_RUTA',
  DELETE_SELECCION_CLIENTE_RUTA: 'DELETE_SELECCION_CLIENTE_RUTA',
  RECUPERAR_DATOS_MODAL_RUTA: 'RECUPERAR_DATOS_MODAL_RUTA',
  RECUPERAR_DATOS_MODAL_RUTA_SUCCESS: 'RECUPERAR_DATOS_MODAL_RUTA_SUCCESS',
  EDITAR_RUTA: 'EDITAR_RUTA',
  DUPLICAR_DATOS_MODAL_RUTA: 'DUPLICAR_DATOS_MODAL_RUTA',
  DUPLICAR_DATOS_MODAL_RUTA_SUCCESS: 'DUPLICAR_DATOS_MODAL_RUTA_SUCCESS',
  GUARDAR_Y_NUEVO_RUTA: 'GUARDAR_Y_NUEVO_RUTA',
  LIMPIAR: 'LIMPIAR',
  CAMBIAR_ESTADO_RUTA: 'CAMBIAR_ESTADO_RUTA',
  ON_DELETE_RUTA: 'ON_DELETE_RUTA',
  SELECCIONAR_TODOS_RUTA: 'SELECCIONAR_TODOS_RUTA',
  DESELECCIONAR_TODOS_RUTA: 'DESELECCIONAR_TODOS_RUTA',
  DELETE_SELECCION_RUTA: 'DELETE_SELECCION_RUTA',
  CAMBIO_ESTADO_SELECCION_RUTA: 'CAMBIO_ESTADO_SELECCION_RUTA',
  FETCH_FILTRAR_RUTA: 'FETCH_FILTRAR_RUTA',
  FETCH_FILTRAR_RUTA_SUCCESS: 'FETCH_FILTRAR_RUTA_SUCCESS',
  FETCH_FILTRAR_CLIENTE_RUTA: 'FETCH_FILTRAR_CLIENTE_RUTA',
  FETCH_FILTRAR_CLIENTE_RUTA_SUCCESS: 'FETCH_FILTRAR_CLIENTE_RUTA_SUCCESS',
  CREATE_CSV_RUTA: 'CREATE_CSV_RUTA',
  CREATE_CSV_RUTA_SUCCESS: '  CREATE_CSV_RUTA_SUCCESS',
  IMPRIMIR_PDF_RUTA: '  IMPRIMIR_PDF_RUTA',
  IMPRIMIR_PDF_RUTA_SUCCESS: '  IMPRIMIR_PDF_RUTA_SUCCESS',
  SUBMIT_NUEVA_RUTA_SUCCESS: 'SUBMIT_NUEVA_RUTA_SUCCESS',
  EDIT_RUTA_SUCCESS: 'EDIT_RUTA_SUCCESS',
  CAMBIAR_ESTADO_RUTA_SUCCESS: 'CAMBIAR_ESTADO_RUTA_SUCCESS',
  ON_DELETE_RUTA_SUCCESS: 'ON_DELETE_RUTA_SUCCESS'
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesStockDosis from '../../components/informes/FiltrosInformesStockDosis'
import {obtenerInformeStockDosis, imprimirPdfInformesStockDosis, crearCsvInformeStockDosis} from '../../actions/informes/informes'
import {comboVerraco, comboTipoDosis, comboRaza, comboLineaGenetica
  } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeStockDosis,
      comboVerraco,
      comboTipoDosis,
      comboRaza,
      comboLineaGenetica,
      imprimirPdfInformesStockDosis,
      crearCsvInformeStockDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesStockDosis))
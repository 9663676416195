import actionTypes from '../../../constants/actions/planSanitario/controlSemen/controlSemen'

export function fetchControlSemen(filtros){
  return {
    type: actionTypes.FETCH_CONTROL_SEMEN,
    filtros
  }
}

export function fetchControlSemenSuccess(mainList, filtros){
  return {
    type: actionTypes.FETCH_CONTROL_SEMEN_SUCCESS,
    mainList,
    filtros
  }
}

export function seleccionarTodosControlSemen(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_CONTROL_SEMEN
  }
}

export function deSeleccionarTodosControlSemen(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CONTROL_SEMEN
  }
}

export function openModalListadoControlSemen(idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_SEMEN,
    idAnalisis,
    idPool
  }
}

export function openModalListadoControlSemenSuccess(list, conservacion, codigo, idAnalisis, idPool){
  return {
    type: actionTypes.OPEN_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS,
    list,
    conservacion,
    codigo,
    idAnalisis,
    idPool
  }
}

export function closeModalListadoControlSemen(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SEMEN
  }
}

export function closeModalListadoControlSemenSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SEMEN_SUCCESS
  }
}

export function seleccionarTodosModalControlSemen(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_CONTROL_SEMEN
  }
}

export function deSeleccionarTodosModalControlSemen(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_CONTROL_SEMEN
  }
}

export function deleteControlSemen(row){
  return {
    type: actionTypes.DELETE_CONTROL_SEMEN,
    row
  }
}

export function deleteControlSemenSeleccion(list){  
  return {
    type: actionTypes.DELETE_SELECCION_CONTROL_SEMEN,
    list
  }
}

export function crearCsvControlSemen(list){
  return {
    type: actionTypes.CREATE_CSV_CONTROL_SEMEN,
    list
  }
}

export function imprimirPdfControlSemen(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CONTROL_SEMEN,
    list
  }
}


export function abrirModalInicio () {
  return {
    type: actionTypes.ABRIR_MODAL_INICIO_CONTROL_SEMEN
  }
}

export function closeModalInicio () {
  return {
    type: actionTypes.CLOSE_MODAL_INICIO_CONTROL_SEMEN
  }
}

export function fetchFiltrarModalInicio (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN,
    values
  }
}

export function fetchFiltrarModalInicioSuccess (listVerracos,filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SEMEN_SUCCESS,
    listVerracos,
    filtros
  }
}

export function seleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN
  }
}

export function deSeleccionarTodosVerracosInicio () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACOS_INICIO_CONTROL_SEMEN
  }
}

export function anadirVerracosModalInicio (listVerracosAsignados, modalValues, archivos) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SEMEN,
    listVerracosAsignados,
    modalValues,
    archivos
  }
}

export function anadirVerracosModalInicioSuccess (listVerracosAsignados) {
  return {
    type: actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SEMEN_SUCCESS,
    listVerracosAsignados
  }
}


export function subirArchivosInforme (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVO_INFORME,
    archivos
  }
}

export function descargarArchivoControlSemen(controlSemen) {
  return {
    type: actionTypes.DESCARGAR_ARCHIVO_CONTROL_SEMEN,
    controlSemen
  }
}

export function imprimirEtiquetaControlSemen(filtros){
  console.log("imprimirEtiquetaControlSemen ACTION", filtros)
  return {
    type: actionTypes.IMPRIMIR_ETIQ_CONTROL_SEMEN,
    filtros
  }
}


export function recuperarDatosModalControlSemenSuccess(data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SEMEN_SUCCESS,
    data
  }
}

export function recuperarDatosModalControlSemen(idControlSemen) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SEMEN,
    idControlSemen
  }
}

export function abrirModalControlSemen () {
  return {
    type: actionTypes.ABRIR_MODAL_CONTROL_SEMEN
  }
}

export function closeModalControlSemen () {
  return {
    type: actionTypes.CLOSE_MODAL_CONTROL_SEMEN
  }
}

export function guardarDetalleControlSemen (data) {
  return {
    type: actionTypes.GUARDAR_MODAL_CONTROL_SEMEN,
    data
  }
}

export default {
  fetchControlSemen,
  fetchControlSemenSuccess,
  seleccionarTodosControlSemen,
  deSeleccionarTodosControlSemen,
  openModalListadoControlSemen,
  openModalListadoControlSemenSuccess,
  closeModalListadoControlSemen,
  closeModalListadoControlSemenSuccess,
  seleccionarTodosModalControlSemen,
  deSeleccionarTodosModalControlSemen,
  deleteControlSemen,
  deleteControlSemenSeleccion,
  crearCsvControlSemen,
  imprimirPdfControlSemen,
  abrirModalInicio,
  closeModalInicio,
  fetchFiltrarModalInicio,
  fetchFiltrarModalInicioSuccess,
  seleccionarTodosVerracosInicio,
  deSeleccionarTodosVerracosInicio,
  anadirVerracosModalInicio,
  anadirVerracosModalInicioSuccess,
  subirArchivosInforme,
  descargarArchivoControlSemen,
  imprimirEtiquetaControlSemen,
  recuperarDatosModalControlSemen,
  recuperarDatosModalControlSemenSuccess,
  abrirModalControlSemen,
  closeModalControlSemen,
  guardarDetalleControlSemen
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosControlMorfologico from '../../../components/planSanitario/controlCalidad/FiltrosControlMorfologico'
import {
  fetchControlMorfologico, seleccionarTodosControlMorfologico, deSeleccionarTodosControlMorfologico, deleteSeleccionControlMorfologico,
  crearCsvControlMorfologico, imprimirPdfControlMorfologico, abrirModalInicio, fetchFiltrarModalInicio
} from '../../../actions/planSanitario/controlCalidad/controlMorfologico'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, fetchComboPool, comboCliente} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.controlMorfologico,
    auth: state.auth,
    controlMorfologicoTable: state.form.controlMorfologicoTable,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerracoSiNo: state.combos.comboVerracoSiNo,
      comboVerraco: state.combos.comboVerraco,
      comboPool: state.combos.comboPool,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
      comboTipoControlSeguimiento: state.combos.comboTipoControlSeguimiento,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlMorfologico,
      seleccionarTodosControlMorfologico,
      deSeleccionarTodosControlMorfologico,
      deleteSeleccionControlMorfologico,
      comboVerraco,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      comboCliente,
      crearCsvControlMorfologico,
      imprimirPdfControlMorfologico,
      abrirModalInicio,
      fetchFiltrarModalInicio
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosControlMorfologico))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import './LineaGenetica.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalLineaGenetica extends Component {
  constructor (props) {
    super()
    this.state = {
      customPictraq:  false
    }
  }

  componentDidMount () {
    if (this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='PICTraq')) {      
      this.setState({customPictraq:true})
    }
  }

  submitNuevaLineaGenetica (values) {
    if (this.props.data){
      this.props.actions.editarLineaGenetica(values)
    } else {
      this.props.actions.submitNuevaLineaGenetica(values)
    }
  }
  guardarYnuevo(values){
    this.props.actions.guardarYnuevo(values)
  }
  render () {
    const {
      t, handleSubmit, showModalLineaGenetica, list: {lineasGeneticas}, data, duplicar, actions: {recuperarDatosModal, closeModalLineaGenetica}
    } = this.props
    return (
      <Modal show={showModalLineaGenetica} onHide={closeModalLineaGenetica} aria-labelledby="contained-modal-title-lg" className="modal-linea-genetica" backdrop="static">
      <form className="form-linea-genetica">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('LINEA_GENETICA.MODAL.TITULO_EDITAR')
            ) : (
              t('LINEA_GENETICA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idLinea && (
            <ButtonChangeRecord list={lineasGeneticas} idElemento="idLinea" currentId={data.idLinea} getNextRecord={recuperarDatosModal} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={6}
                id="nombre-linea-genetica"
                name="nombreLinea"
                component={InputText}
                controlLabel={t('LINEA_GENETICA.MODAL.NOMBRE_LINEA_GENETICA') + '*'}
                customClass='nombre-linea-genetica'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={6}
                id="abreviatura-linea-genetica"
                name="codLinea"
                component={InputText}
                controlLabel={t('LINEA_GENETICA.MODAL.ABREVIATURA') + '*'}
                customClass='nombre-linea-genetica'
                validate={required}
                maxLength={true}
                subTextoMax={true}
                valorMaxLength={4}
                t={t}
              />
              <Field
                colSm={12}
                id="descripcion-linea-genetica"
                name="descripcionLinea"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('LINEA_GENETICA.MODAL.DESCRIPCION')}
                customClass='descripcion-linea-genetica'
              />
              <Col sm={5}>
                { this.state.customPictraq && <Field
                  id="pictraq"
                  name="pictraq"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('LINEA_GENETICA.MODAL.PICTRAQ')}
                /> }
                <Field
                  id="activo"
                  name="activo"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('LINEA_GENETICA.MODAL.ACTIVO')}
                />
                <input type="hidden" name="cambiarEstado"/>
              </Col>
              <Col sm={7}>
                <Field
                  colSm={12}
                  id="dosisServidasMes"
                  name="dosisServidasMes"
                  component={InputText}
                  controlLabel={t('RAZA.MODAL.DOSIS_SERVIDAS_MES')}
                  customClass='nombre-raza'                
                />
              </Col>              
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('LINEA_GENETICA.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevo.bind(this)) : null : undefined}
              >{t('LINEA_GENETICA.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaLineaGenetica.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2)}
          >{t('LINEA_GENETICA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalLineaGenetica}>{t('LINEA_GENETICA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalLineaGenetica',
})(ModalLineaGenetica))

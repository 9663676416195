import actionTypes from '../../constants/actions/grupoVerraco/GrupoVerraco'

export function initialState () {
  return {
    list: [],
    checkedFiltrosGrupoVerraco: false,
    filtros:{},
    listVerracosEnGrupo: [],
    showModalSeleccionarVerraco: false,
    verracosAnadidos: [],
    checkedVerracosGrupoVerraco: false,
    checkedGrupoVerraco: false,
    dataDuplicar: [],
    data: [],
    filtrosModal:{},
    datosGrupoVerracoExcel: []
  }
}

export function fetchGrupoVerracoSuccess (state, {list}) {
  return {
    ...state,
    list,
    verracosAnadidos: []
  }
}

export function recuperarDatosModalGrupoVerracoSuccess (state, {data}) {
  return {
    ...state,
    data,
    verracosAnadidos: data.verraco,
    dataDuplicar: []
  }
}

export function duplicarDatosModalGrupoVerracoSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar,
    verracosAnadidos: dataDuplicar.verraco,
    data: []
  }
}

export function cambiarEstadoGrupoVerracoSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteGrupoVerracoSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosGrupoVerraco (state) {
  return {
    ...state,
    checkedFiltrosGrupoVerraco: true
  }
}

export function deSeleccionarTodosGrupoVerraco (state) {
  return {
    ...state,
    checkedFiltrosGrupoVerraco: false
  }
}

export function fetchFiltrarGrupoVerracoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function abrirModalSeleccionarVerraco(state){
  return{
    ...state,
    showModalSeleccionarVerraco: true
  }
}

export function cerrarModalCambioEstadoVerraco(state){
  return{
    ...state,
    showModalSeleccionarVerraco: false,
    checkedVerracosGrupoVerraco: false,
    filtrosModal:{}
  }
}

export function fetchVerracosGrupoVerracoSuccess(state, {listVerracosEnGrupo}){
  return{
    ...state,
    listVerracosEnGrupo: listVerracosEnGrupo
  }
}

export function seleccionarTodosModalVerracoGrupoVerraco (state) {
  return {
    ...state,
    checkedVerracosGrupoVerraco: true
  }
}

export function deSeleccionarTodosModalVerracoGrupoVerraco (state) {
  return {
    ...state,
    checkedVerracosGrupoVerraco: false
  }
}

export function anadirVerracoSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos: state.verracosAnadidos.concat(verracosAnadidos)
  }
}

export function seleccionarTodosModalGrupoVerraco (state) {
  return {
    ...state,
    checkedGrupoVerraco: true
  }
}

export function deSeleccionarTodosModalGrupoVerraco (state) {
  return {
    ...state,
    checkedGrupoVerraco: false
  }
}

export function onDeleteVerracoAnadidoSuccess (state, {verracosAnadidos}) {
  return {
    ...state,
    verracosAnadidos,
    checkedGrupoVerraco: false
  }
}

export function abrirModalVacioGrupoVerraco (state) {
  return {
    ...state,
    verracosAnadidos: [],
    checkedVerracosGrupoVerraco: false,
    checkedGrupoVerraco: false,
    dataDuplicar: [],
    data: []
  }
}

export function fetchFiltrarVerracoModalVerracoSuccess (state, {filtrosModal}) {
  return {
    ...state,
    filtrosModal
  }
}

export function submitNuevoGrupoVerracoSuccess (state, {insercionGrupoVerraco}) {
  return {
    ...state,
    insercionGrupoVerraco
  }
}

export function editarGrupoVerracoSuccess (state, {edicionGrupoVerraco}) {
  return {
    ...state,
    edicionGrupoVerraco
  }
}

export function crearCsvGrupoVerracoSuccess (state, {datosGrupoVerracoExcel}) {
  return {
    ...state,
    datosGrupoVerracoExcel
  }
}

export function imprimirPdfGrupoVerracoSuccess (state, {datosGrupoVerracoPdf}) {
  return {
    ...state,
    datosGrupoVerracoPdf
  }
}

export function guardarYnuevoGrupoVerraco (state) {
  return {
    ...state,
    verracosAnadidos: [],
    checkedVerracosGrupoVerraco: false,
    checkedGrupoVerraco: false,
    dataDuplicar: [],
    data: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_GRUPO_VERRACO_SUCCESS:
      return fetchGrupoVerracoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS:
      return recuperarDatosModalGrupoVerracoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS:
      return duplicarDatosModalGrupoVerracoSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_GRUPO_VERRACO_SUCCESS:
      return cambiarEstadoGrupoVerracoSuccess(state, action)
    case actionTypes.ON_DELETE_GRUPO_VERRACO_SUCCESS:
      return onDeleteGrupoVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_GRUPO_VERRACO:
      return seleccionarTodosGrupoVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_GRUPO_VERRACO:
      return deSeleccionarTodosGrupoVerraco(state, action)
    case actionTypes.FETCH_FILTRAR_GRUPO_VERRACO_SUCCESS:
      return fetchFiltrarGrupoVerracoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_SELECCIONAR_VERRACO:
      return abrirModalSeleccionarVerraco(state, action)
    case actionTypes.CERRAR_MODAL_SELECCIONAR_VERRACO:
      return cerrarModalCambioEstadoVerraco(state, action)
    case actionTypes.FETCH_VERRACOS_GRUPO_VERRACO_SUCCESS:
      return fetchVerracosGrupoVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO:
      return seleccionarTodosModalVerracoGrupoVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO:
      return deSeleccionarTodosModalVerracoGrupoVerraco(state, action)
    case actionTypes.ANADIR_VERRACO_SUCCESS:
      return anadirVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_GRUPO_VERRACO:
      return seleccionarTodosModalGrupoVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_GRUPO_VERRACO:
      return deSeleccionarTodosModalGrupoVerraco(state, action)
    case actionTypes.ON_DELETE_VERRACO_ANADIDO_SUCCESS:
      return onDeleteVerracoAnadidoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_GRUPO_VERRACO:
      return abrirModalVacioGrupoVerraco(state, action)
    case actionTypes.FETCH_FILTRAR_VERRACO_MODAL_VERRACO_SUCCESS:
      return fetchFiltrarVerracoModalVerracoSuccess(state, action)
    case actionTypes.SUBMIT_NUEVO_GRUPO_VERRACO_SUCCESS:
      return submitNuevoGrupoVerracoSuccess(state, action)
    case actionTypes.EDITAR_GRUPO_VERRACO_SUCCESS:
      return editarGrupoVerracoSuccess(state, action)
    case actionTypes.CREATE_CSV_GRUPO_VERRACO_SUCCESS:
      return crearCsvGrupoVerracoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_GRUPO_VERRACO_SUCCESS:
      return imprimirPdfGrupoVerracoSuccess(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_GRUPO_VERRACO:
      return guardarYnuevoGrupoVerraco(state, action)
    default:
      return state
  }
}
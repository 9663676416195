import actionTypes from '../../constants/actions/importarPedidos/importarPedidos'

export function submitImportarPedidos (value) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_PEDIDOS,
    value
  }
}

export function submitImportarPedidosSuccess (listImportarPedidos) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_PEDIDOS_SUCCESS,
    listImportarPedidos
  }
}

export function abrirModalImportarPedidos () {
  return {
    type: actionTypes.ABRIR_MODAL_IMPORTAR_PEDIDOS
  }
}

export function cerrarModalImportarPedidos () {
  return {
    type: actionTypes.CERRAR_MODAL_IMPORTAR_PEDIDOS
  }
}

export function subirArchivosImportarPedidos (archivo) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_IMPORTAR_PEDIDOS,
    archivo
  }
}

export function descargarPlantillaExcelPedidos () {
  return {
    type: actionTypes.DESCARGAR_PLANTILLA_EXCEL_PEDIDOS
  }
}

export default {
  submitImportarPedidos,
  abrirModalImportarPedidos,
  cerrarModalImportarPedidos,
  subirArchivosImportarPedidos,
  submitImportarPedidosSuccess,
  descargarPlantillaExcelPedidos,  
}
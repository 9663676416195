import React from 'react'
import { DonutLarge } from '@material-ui/icons'
import './loader.scss'

export default () => {
  return (
    <div className="loader-container">
      <div className='loader-main'>
        <span><DonutLarge /></span>
      </div>
    </div>
  )
}

import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosAjusteStockPage from '../../containers/ajusteStock/FiltrosAjusteStockPage'
import ModalAjusteStockPage from '../../containers/ajusteStock/ModalAjusteStockPage'
import { date_parser } from '../../util/formatFunctions'

class AjusteStock extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.ALMACEN.AJUSTE_STOCK') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchAjusteStock()
  }

  render () {
    const {
      t, list ,
    } = this.props

    const tKey = 'ALMACEN.AJUSTE_STOCK.'
    const idElemento = 'idProducto'
    const table = {
      id: 'ajusteStockTable',
      multiHeader: false,
      hasCheckbox: false,
      idElemento,
      hasOptions: false,
      columns: [
        {id: 'fechaAjuste', name: t(tKey + 'COLUMNS.FECHA'), type: 'fecha'},
        {id: 'nombre', name: t(tKey + 'COLUMNS.PRODUCTO')},
        {id: 'lote', name: t(tKey + 'COLUMNS.LOTE')},
        {id: 'cantidad', name: t(tKey + 'COLUMNS.STOCK_INICIAL'), type: 'numero', numDecimales: 0},
        {id: 'stockRealAnterior', name: t(tKey + 'COLUMNS.STOCK_MODIFICAR'), type: 'numero', numDecimales: 0},
        {id: 'stock', name: t(tKey + 'COLUMNS.STOCK_DEFINITIVO'), type: 'numero', numDecimales: 0},
        {id: 'observaciones', name: t(tKey + 'COLUMNS.OBSERVACIONES')}
      ],
      rows: list,
      filtros: <FiltrosAjusteStockPage />,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      initialValues: {}
    }
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalAjusteStockPage />
        <ListadoPage t_key={tKey} table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default AjusteStock
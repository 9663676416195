import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalGrupoVerraco from '../../components/grupoVerraco/ModalGrupoVerraco'
import {recuperarDatosModalGrupoVerraco, submitNuevoGrupoVerraco, editarGrupoVerraco, guardarYnuevoGrupoVerraco, seleccionarTodosModalGrupoVerraco, deSeleccionarTodosModalGrupoVerraco, abrirModalSeleccionarVerraco, onDeleteVerracoAnadido,
  deleteSeleccionVerracosGrupoVerraco, fetchFiltrarVerracoModalVerraco} from '../../actions/grupoVerraco/GrupoVerraco'
import {closeModal} from '../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.grupoVerraco,
    showModal: state.common.showModal,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable,
    formModalGrupoVerraco: state.form.ModalGrupoVerraco,
    grupoVerraco: state.grupoVerraco,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalGrupoVerraco,
      submitNuevoGrupoVerraco,
      closeModal,
      editarGrupoVerraco,
      guardarYnuevoGrupoVerraco,
      seleccionarTodosModalGrupoVerraco,
      deSeleccionarTodosModalGrupoVerraco,
      abrirModalSeleccionarVerraco,
      onDeleteVerracoAnadido,
      deleteSeleccionVerracosGrupoVerraco,
      fetchFiltrarVerracoModalVerraco
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGrupoVerraco))
import React, { Component } from 'react'
import {reduxForm, Fields, getFormValues} from 'redux-form'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import FiltrosFichaVerracoPage from '../../containers/fichaVerraco/FiltrosFichaVerracoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import ModalCambioEstadoVerracoPage from '../../containers/cambioEstadoVerraco/ModalCambioEstadoVerracoPage'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import {date_parser} from '../../util/formatFunctions'
import './FichaVerraco.scss'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {number_formatter, dateTimeZones, round_decimal} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class FichaVerraco extends Component {
  constructor () {
    super()
    this.state = {
      listFichaVerraco: [],
      totalesFichaVerraco: [],
      fechaDesde: '',
      fechaHasta: ''
    }
    let leyendaArray = [
      
    ]
  }

  componentDidUpdate(prevProps, prevState){
    if (Object.keys(this.props.fichaVerraco.list).length > 0) {
      if (this.props.fichaVerraco.list !== prevProps.fichaVerraco.list) {
        let verracos = []
        if (Object.keys(this.props.fichaVerraco.list.verracos).length > 0) {
          verracos = this.props.fichaVerraco.list.verracos.map((verraco) => {
            if (verraco.propiedad === true) {
              verraco.propiedad = this.props.t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')
            } else {
              verraco.propiedad = verraco.razonSocialCliente
            }
            if (verraco.estadoverraco === 5) {
              verraco.ubicacionverraco = null
            }
            return verraco
          })
        }
        this.setState({listFichaVerraco: verracos})
        this.setState({totalesFichaVerraco: this.props.fichaVerraco.list.totales})
      }
    }
  }

  componentDidMount () {
    this.props.actions.comboCliente()
    document.title = this.props.t('MENU.VERRACOS.FICHA_VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    if (this.props.location.state === undefined) {
      this.props.actions.restartVerraco()
      this.props.actions.fetchFiltrarFichaVerraco({})
    } else {
      this.props.actions.restartVerracoDejandoFiltros()
      this.props.actions.fetchFiltrarFichaVerraco(this.props.fichaVerraco.filtros)
    }
    this.props.actions.restartMovimientoVerraco()
    const fechaHoy = new Date()
    const fecha = new Date()
    const fechaInferior = new Date(fecha.setDate(fecha.getDate() - 30))
    this.props.change('fechaEntrada', fechaInferior)
    this.props.change('fechaSalida', fechaHoy)
    const values = {fechaDesde: date_parser(fechaInferior, this.props.auth.formaFechaHora), fechaHasta: date_parser(fechaHoy, this.props.auth.formaFechaHora)}
    this.props.actions.obtenerPresenciaVerraco(values)
  
    this.leyendaArray = [
      {name: this.props.t('FICHA_VERRACO.LEYENDA.ACTIVO'), color: '#c0d2a1'},
      {name: this.props.t('FICHA_VERRACO.LEYENDA.PRESENTE'), color: '#aacfdc'},
      {name: this.props.t('FICHA_VERRACO.LEYENDA.ENFERMO'), color: '#de9ed7'},
      {name: this.props.t('FICHA_VERRACO.LEYENDA.CUARENTENA'), color: '#edd3a0'},
      {name: this.props.t('FICHA_VERRACO.LEYENDA.BAJA'), color: '#e59f93'},    
      {name: this.props.t('FICHA_VERRACO.LEYENDA.MUERTO'), color: '#e27329'}
    ]              
  }

  changeTotalesVerracos(values) {
    values.fechaDesde = values.fechaEntrada
    values.fechaHasta = values.fechaSalida
    this.props.actions.obtenerPresenciaVerraco(values)
  }

  render () {
    const {
      t, history, numeroRegistrosPorPagina, formFichaVerraco, paginaActual, handleSubmit,
      fichaVerraco: { list, checkedFiltrosFichaVerraco, totalesPresenciaVerracos },
      actions: {cambiarVigilado, onDeleteVerraco, abrirModalCambioEstadoVerraco},
      auth:{separadorDec, separadorMil}
    } = this.props

      this.props.fichaVerraco.list.verracos && this.props.fichaVerraco.list.verracos.forEach(
        (row) => row.idElemento = row.idVerraco
      )

    const table = {
      id: 'fichaVerracoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'estadoverraco', name: t('FICHA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'codVerraco', name: t('FICHA_VERRACO.VERRACO')},
        {id: 'tatuaje', name: t('FICHA_VERRACO.TATUAJE')},
        {id: 'vigilado', name: t('FICHA_VERRACO.VIGILADO')},
        {id: 'nombreRaza', name: t('FICHA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('FICHA_VERRACO.LINEA_GENETICA')},
        {id: 'fechaEntradaGranja', name: t('FICHA_VERRACO.FECHA_ENTRADA_GRANJA'), type: 'fecha'},
        {id: 'fechaNacimiento', name: t('FICHA_VERRACO.FECHA_NACIMIENTO'), type: 'fecha'},
        {id: 'fechaEliminado', name: t('FICHA_VERRACO.FECHA_ELIMINADO'), type: 'fecha'},
        {id: 'edad', name: t('FICHA_VERRACO.EDAD'), type: 'numero', numDecimales: 0},
        {id: 'indexactual', name: t('FICHA_VERRACO.INDEX'), type: 'numero', numDecimales: 0},
        {id: 'nivelGeneticoActual', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.NIVEL_GENETICO')},
        {id: 'propiedad', name: t('FICHA_VERRACO.PROPIEDAD_CLIENTE')},
        (this.props.auth.modulos.includes(modulosConstant.BASICO) && {id: 'ubicacionverraco', name: t('FICHA_VERRACO.UBICACION_VERRACO')})
      ],
      rows: Object.keys(this.props.fichaVerraco.list).length > 0 ? this.props.fichaVerraco.list.verracos : [],
      rowsVerraco: true,
      filtros: <FiltrosFichaVerracoPage />,
      leyenda: this.leyendaArray,
      onEdit: (row) => history.push('EditarVerraco/' + row.idVerraco),
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1,
      duplicar: (row) => history.push('DuplicarVerraco/' + row.idVerraco),
      vigilado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1,
      cambiarVigilado: (row) => cambiarVigilado(row.idVerraco),
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1,
      cambiarEstado: (row) => abrirModalCambioEstadoVerraco(row.idVerraco),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1,
      onDelete: (row) => onDeleteVerraco(row.idVerraco),
      verracoVigilado: true,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosFichaVerraco
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalCambioEstadoVerracoPage
          mostrarComboVerraco={false}
          esFichaVerraco={true}
        />
        <ListadoPage t_key="FICHA_VERRACO." table={table}>
        </ListadoPage>
        <Row className="tablaTotales" style={{ margin: '20px 0px 0px 0px'}}>
          <Col sm={12} className="total-verracos">
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_VERRACOS')}: {this.state.totalesFichaVerraco.totalVerracos !== undefined && number_formatter(this.state.totalesFichaVerraco.totalVerracos, 0, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.MEDIA_INDEX')}: {this.state.totalesFichaVerraco.mediaIndex !== undefined && number_formatter(round_decimal(this.state.totalesFichaVerraco.mediaIndex, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_CUARENTENA')}: {this.state.totalesFichaVerraco.totalVerracosCuarentena !== undefined && number_formatter(this.state.totalesFichaVerraco.totalVerracosCuarentena, 0, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_ACTIVOS')}: {this.state.totalesFichaVerraco.totalVerracosActivo !== undefined && number_formatter(this.state.totalesFichaVerraco.totalVerracosActivo, 0, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_PRESENTES')}: {this.state.totalesFichaVerraco.totalVerracosPresente !== undefined && number_formatter(this.state.totalesFichaVerraco.totalVerracosPresente, 0, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_BAJA')}: {this.state.totalesFichaVerraco.totalVerracosBaja !== undefined ? number_formatter(this.state.totalesFichaVerraco.totalVerracosBaja, 0, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('FICHA_VERRACO.TOTALES.TOTAL_ELIMINADOS')}: {this.state.totalesFichaVerraco.totalVerracosEliminado !== undefined && number_formatter(this.state.totalesFichaVerraco.totalVerracosEliminado, 0, separadorDec, separadorMil)}</span>
          </Col>
        </Row>
        <Row className="tablaTotales"style={{margin: '0', borderTop: 'none'}}>
          <form className="custom-form totales-verracos">
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={2}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              border={false}
              onInputChange={handleSubmit(this.changeTotalesVerracos.bind(this))}
              classRange="estados-total-verraco range-totales-ficha-verraco"
            />
          </form>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.PRESENCIA_MEDIA_CUARENTENA')}: {totalesPresenciaVerracos.Cuarentena !== undefined && number_formatter(round_decimal(totalesPresenciaVerracos.Cuarentena, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.PRESENCIA_MEDIA_ACTIVOS')}: {totalesPresenciaVerracos.Activo !== undefined && number_formatter(round_decimal(totalesPresenciaVerracos.Activo, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.PRESENCIA_MEDIA_PRESENTES')}: {totalesPresenciaVerracos.Presente !== undefined && number_formatter(round_decimal(totalesPresenciaVerracos.Presente, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco">
            <span>{t('FICHA_VERRACO.TOTALES.PRESENCIA_MEDIA_BAJA')}: {totalesPresenciaVerracos.Baja !== undefined ? number_formatter(round_decimal(totalesPresenciaVerracos.Baja, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="estados-total-verraco" style={{borderRight: 'none'}}>
            <span>{t('FICHA_VERRACO.TOTALES.PRESENCIA_MEDIA_ELIMINADOS')}: {totalesPresenciaVerracos.Eliminado !== undefined && number_formatter(round_decimal(totalesPresenciaVerracos.Eliminado, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'fichaVerraco',
})(FichaVerraco))
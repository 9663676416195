import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../../actions/common'
import { fetchControlSangre, deleteControlSangre, abrirModalInicio, descargarArchivoControlSangre, recuperarDatosModalControlSangre, abrirModalControlSangre, closeModalControlSangre, guardarDetalleControlSangre,subirArchivosInforme} from '../../../actions/planSanitario/controlSangre/controlSangre'
import ModalControlSangre from '../../../components/planSanitario/controlSangre/ModalControlSangre'

export function mapStateToProps (state) {
  return {
    ...state.controlSangre,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    combos: {comboResultadoControlSangre: state.combos.comboResultadoControlSangre}
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlSangre,
      deleteControlSangre,
      yesNoModal,
      abrirModalInicio,
      descargarArchivoControlSangre,
      recuperarDatosModalControlSangre,
      closeModalControlSangre,
      abrirModalControlSangre,
      guardarDetalleControlSangre,
      subirArchivosInforme
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalControlSangre))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Button } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import FiltrosCopiaSeguridadPage from '../../containers/copiaSeguridad/FiltrosCopiaSeguridadPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FormSubtitle from '../comun/form/FormSubtitle'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import { required } from '../../util/validationFunctions'
import { Warning } from '@material-ui/icons'
import ModalCopiaSeguridadPage from '../../containers/copiaSeguridad/ModalCopiaSeguridadPage'
import ModalCopiaSeguridadProgramadaPage from '../../containers/copiaSeguridad/ModalCopiaSeguridadProgramadaPage'
import ModalRestaurarCopiaDesdeArchivoPage from '../../containers/copiaSeguridad/ModalRestaurarCopiaDesdeArchivoPage'
import ModalRestaurarCopiaMigracionPage from '../../containers/copiaSeguridad/ModalRestaurarCopiaMigracionPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class CopiaSeguridad extends Component {
  constructor () {
    super()
    this.state = {
      
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.UTILIDADES.COPIA_SEGURIDAD') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchCopiasSeguridad()
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual, listCopiaSeguridad, checkedCopiaSeguridad,
      actions: {descargarCopiaSeguridad, onDeleteCopiaSeguridad, restaurarCopiaSeguridad}
    } = this.props

    listCopiaSeguridad.forEach(
      (row) => row.idElemento = row.idCopiaSeguridad
    )

    const table = {
      id: 'copiaSeguridadTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombre', name: t('COPIA_SEGURIDAD.NOMBRE')},
        {id: 'fecha', name: t('COPIA_SEGURIDAD.FECHA'), type: 'fechaHoraTabla'},
        {id: 'incluyeArchivos', name: t('COPIA_SEGURIDAD.INCLUYE_ARCHIVOS')},
        {id: 'nombreCentro', name: t('COPIA_SEGURIDAD.NOMBRE_CENTRO')},
        {id: 'automatica', name: t('COPIA_SEGURIDAD.AUTOMATICA')}
      ],
      rows: listCopiaSeguridad,
      filtros: <FiltrosCopiaSeguridadPage />,
      copiaSeguridad: true,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarDescargarCopiaSeguridad: true,
      descargarCopia: (row) => descargarCopiaSeguridad(row.idCopiaSeguridad),
      mostrarRestaurarCopiaSeguridad: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1,
      restaurarCopia: (row) => restaurarCopiaSeguridad(row.idCopiaSeguridad),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.COPIA_SEGURIDAD] > 1,
      onDelete: (row) => onDeleteCopiaSeguridad(row.idCopiaSeguridad),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedCopiaSeguridad
        }
      }
    )
    return (
      <div>
        <Row>
          <Col sm={12}>
            <SimpleModalPage/>
            <MensajeAlertaPage/>
            <ModalCopiaSeguridadPage />
            <ModalCopiaSeguridadProgramadaPage />
            <ModalRestaurarCopiaDesdeArchivoPage />
            <ModalRestaurarCopiaMigracionPage />
            <ListadoPage t_key="COPIA_SEGURIDAD." table={table}>
            </ListadoPage>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formCopiaSeguridad',
})(CopiaSeguridad))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosValidacionMateriaPrima from '../../components/validacionMateriaPrima/FiltrosValidacionMateriaPrima'
import { comboNombreProductos, comboLotes } from '../../actions/combos/combos'
import {abrirModalValidacionMateriaPrima, filtrarValidacionMateriaPrima, seleccionarTodosValidacionMateriaPrima, deSeleccionarTodosValidacionMateriaPrima, deleteSeleccionValidacionMateriaPrima,
crearCsvValidacionMateriaPrima, imprimirPdfValidacionMateriaPrima} from '../../actions/validacionMateriaPrima/validacionMateriaPrima'

export function mapStateToProps (state) {
  return {
    ...state.validacionMateriaPrima,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    comboNombreProductos,
    comboLotes,
    abrirModalValidacionMateriaPrima,
    filtrarValidacionMateriaPrima,
    seleccionarTodosValidacionMateriaPrima,
    deSeleccionarTodosValidacionMateriaPrima,
    deleteSeleccionValidacionMateriaPrima,
    crearCsvValidacionMateriaPrima,
    imprimirPdfValidacionMateriaPrima
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosValidacionMateriaPrima))
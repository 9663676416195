import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesProductividadExtraccionesPage from '../../containers/informes/FiltrosInformesProductividadExtraccionesPage'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import ModalProductividadVerracosPage from '../../containers/informes/ModalProductividadVerracosPage'
import {number_formatter, round_decimal} from '../../util/formatFunctions'

class InformesProductividadExtracciones extends Component {
  constructor (props) {
    super()
    this.state = {
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }

    if (props.panelControl && props.panelControl.datosPanel && props.panelControl.datosPanel.metodoEvaluacion) {
      this.state = this.getMostrarColumnasPanelControl(props)
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.PRODUCTIVIDAD_MACHOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerDatosPanelControl(null)
    this.props.actions.obtenerInformeProductividadExtracciones({})
  }

  componentDidUpdate (prevProps) {
    let metodoEvaluacion = this.props.panelControl && this.props.panelControl.datosPanel ? this.props.panelControl.datosPanel.metodoEvaluacion : ''
    let prevMetodoEvaluacion = prevProps.panelControl && prevProps.panelControl.datosPanel ? prevProps.panelControl.datosPanel.metodoEvaluacion : ''

    if (metodoEvaluacion && (!prevMetodoEvaluacion || (prevMetodoEvaluacion && prevMetodoEvaluacion !== metodoEvaluacion ))) {
      this.setState(this.getMostrarColumnasPanelControl(this.props))
    }
  }

  getMostrarColumnasPanelControl (props) {
    let newState = {
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }

    if (props.panelControl && props.panelControl.datosPanel && props.panelControl.datosPanel.metodoEvaluacion) {
      newState.metodoEvaluacion = props.panelControl.datosPanel.metodoEvaluacion

      if (props.panelControl.datosPanel.metodoEvaluacion === 1) {
        newState.idMotilidad = 'motilidadTotal'
        newState.nameMotilidad = props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')
      }
      if (props.panelControl.datosPanel.metodoEvaluacion === 2) {
        newState.idMotilidad = 'motilidadTotal'
        newState.nameMotilidad = props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')
      }
      if (props.panelControl.datosPanel.metodoEvaluacion === 4) {
        newState.idMotilidad = 'motilidadMasal'
        newState.nameMotilidad = props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')
      }
      if (props.panelControl.datosPanel.metodoEvaluacion === 3) {
        newState.idMotilidad = 'mt'
        newState.nameMotilidad = props.t('INFORMES.EXTRACCIONES.TABLA.MT')
      }
      if (props.panelControl.datosPanel.metodoEvaluacion === 5) {
        newState.idMotilidad = 'mt'
        newState.nameMotilidad = props.t('INFORMES.EXTRACCIONES.TABLA.MT')
      }
    }
    return newState
  }

  filtrarExtraccionesMalas (values) {
    values.desde = this.props.filtrosProductividadExtracciones.desde
    values.hasta = this.props.filtrosProductividadExtracciones.hasta
    values.fechaEntrada = this.props.filtrosProductividadExtracciones.fechaEntrada
    values.fechaSalida = this.props.filtrosProductividadExtracciones.fechaSalida
    values.raza = this.props.filtrosProductividadExtracciones.raza
    values.lineaGenetica = this.props.filtrosProductividadExtracciones.lineaGenetica
    values.estado = this.props.filtrosProductividadExtracciones.estado
    values.intervencionSanitaria = this.props.filtrosProductividadExtracciones.intervencionSanitaria
    values.grupoVerraco = this.props.filtrosProductividadExtracciones.grupoVerraco
    this.props.actions.obtenerInformeProductividadExtracciones(values)
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual, listInformeProductividadExtraccionesBuenas, listInformeProductividadExtraccionesMalas,
      totalesProductividadExtraccionesBuenas, totalesProductividadExtraccionesMalas,
      actions: {abrirModalProductividadVerracos},
      auth: {separadorDec, separadorMil}
    } = this.props

     const combosContaminacion= [
      {value: true, label: t('COMUN.COMBOS.CONTAMINACION.SI')},
      {value: false, label: t('COMUN.COMBOS.CONTAMINACION.NO')}
    ]

    const tableInformeProductividadExtracciones = {
      id: 'informeProductividadExtraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      hasPagination: false,
      noBlockOptions: false,
      paginarConScroll: true,
      columns: this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
        {id: 'fechaExtraccion', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.LINEA_GENETICA')},
        {id: 'volumen', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VOLUMEN'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'}
      ]) : ([
        {id: 'fechaExtraccion', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.LINEA_GENETICA')},
        {id: 'volumen', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VOLUMEN'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'}
      ]),
      rows: listInformeProductividadExtraccionesBuenas,
      colorearRow: (row) => {
        if (row.dosisReales === "0") {
            return 'extracciones-sin-dosis'
        }
      },
      initialValues: {}
    }

    const tableInformeProductividadExtraccionesMalas = {
      id: 'informeProductividadExtraccionesMalasTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      hasPagination: false,
      noBlockOptions: false,
      paginarConScroll: true,
      columns: this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
        {id: 'fechaExtraccion', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.LINEA_GENETICA')},
        {id: 'volumen', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VOLUMEN'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'}
      ]) : ([
        {id: 'fechaExtraccion', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FECHA'), type: 'fecha'},
        {id: 'codVerraco', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VERRACO')},
        {id: 'nombreRaza', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.LINEA_GENETICA')},
        {id: 'volumen', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.VOLUMEN'), type: 'numero'},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'concentracionTotal', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_TOTAL'), type: 'numero'},
        {id: 'concentracionUtil', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.CONC_UTIL'), type: 'numero'},
        {id: 'porFA', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.FA'), type: 'numero'},
        {id: 'dosisReales', name: t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TABLA.DOSIS_REALES'), type: 'numero'}
      ]),
      rows: listInformeProductividadExtraccionesMalas,
      colorearRow: (row) => {
        if (row.dosisReales === "0") {
            return 'extracciones-sin-dosis'
        }
      },
      initialValues: {}
    }

    return (
      <div>
        <h2 className="page-title">{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TITLE')}</h2>
        <Row>
          <Col sm={12}>
            <FiltrosInformesProductividadExtraccionesPage />
          </Col>
          <Col sm={2} style={{paddingLeft: 0, paddingRight: 0}}>
            <form>
              <Field
                id="volumen"
                name="volumen"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.VOLUMEN')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                id="concentracionTotal"
                name="concentracionTotal"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONCENTRACION_TOTAL')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                id="concentracionUtil"
                name="concentracionUtil"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONCENTRACION_UTIL')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                id="fa"
                name="fa"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.FORMAS_ANORMALES_TOTALES')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                id="dosisReales"
                name="dosisReales"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.DOSIS_REALES')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                id="motilidad"
                name="motilidad"
                colSm={12}
                component={InputNumerical}
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.MOTILIDAD')}
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Field
                colSm={12}
                id="contaminacion"
                name="contaminacion"
                controlLabel={t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.CONTAMINACION')}
                component={InputSelect}
                options={combosContaminacion}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.filtrarExtraccionesMalas.bind(this))}
              />
              <Col sm={12}>
                <Button type="button" className="btn btn-primary" style={{width:'100%', marginTop:'15px'}} onClick={abrirModalProductividadVerracos}>
                  {t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PRODUCTIVIDAD_VERRACOS')}
                </Button>
              </Col>
            </form>
            <ModalProductividadVerracosPage />
          </Col>
          <Col sm={10}>
            <p style={{margin: 0}}><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.BUENAS')}</b></p>
            <div className="contenedor-tablas-productividad-extracciones">
              <SimpleTablePage {...tableInformeProductividadExtracciones}></SimpleTablePage>
            </div>
            <div>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.TOTAL')}</b>: {totalesProductividadExtraccionesBuenas.total}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MV')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaVolumen, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              {
                this.state.metodoEvaluacion === 4 ? (
                  <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMM')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaMotilidadMasal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ) : null
              }
              {
                this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
                  <span key="3" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMMT')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaMT, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>,
                  <span key="4" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMMP')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaMP, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ]) : null
              }
              {
                this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                  <span key="1" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MM')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaMotilidad, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ) : null
              }
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MCT')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaConcentracionTotal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MCU')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaConcentracionUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MFA')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaFA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MD')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesBuenas.mediaDosis, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
            </div>
            <div className="clearfix"></div>
            <p style={{margin: '10px 0px 0px 0px'}}><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MALAS')}</b></p>
            <div className="contenedor-tablas-productividad-extracciones">
              <SimpleTablePage {...tableInformeProductividadExtraccionesMalas}></SimpleTablePage>
            </div>
            <div>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.TOTAL')}</b>: {totalesProductividadExtraccionesMalas.total}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MV')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaVolumen, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              {
                this.state.metodoEvaluacion === 4 ? (
                  <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMM')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaMotilidadMasal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ) : null
              }
              {
                this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
                  <span key="5" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMMT')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaMT, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>,
                  <span key="6" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MMMP')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaMP, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ]) : null
              }
              {
                this.state.metodoEvaluacion === 1 || this.state.metodoEvaluacion === 2 ? (
                  <span key="7" className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MM')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaMotilidad, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                ) : null
              }
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MCT')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaConcentracionTotal, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MCU')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaConcentracionUtil, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MFA')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaFA, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
              <span className="totales-informe-pro-extracciones"><b>{t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.TOTALES.MD')}</b>: {number_formatter(round_decimal(totalesProductividadExtraccionesMalas.mediaDosis, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
            </div>
            <div className="clearfix"></div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'InformesProductividadExtracciones',
})(InformesProductividadExtracciones))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerracoRegistroIntervencionSanitaria from '../../../components/planSanitario/registroIntervencionSanitaria/ModalVerracoRegistroIntervencionSanitaria'
import { abrirModalSeleccionarVerraco, cerrarModalSeleccionarVerraco, fetchVerracosGrupoVerraco, seleccionarTodosModalVerracoGrupoVerraco, deSeleccionarTodosModalVerracoGrupoVerraco, anadirVerraco,
  fetchFiltrarVerracoModalVerraco} from '../../../actions/grupoVerraco/GrupoVerraco'
import {closeModal} from '../../../actions/common'
import {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboVerraco, comboNombreGrupoVerracoActiva,comboEstadoVerracoNoEliminado} from '../../../actions/combos/combos'

import {cerrarModalVerracoRegistroIntervencionSanitaria, fetchVerracosRegistroIntervencionSanitaria, seleccionarTodosModalVerracoRegistroIntervencionSanitaria, deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria,
  anadirVerracoModalRegistroIntervencionSanitaria, fetchFiltrarVerracoModalRegistroIntervencionSanitaria} from '../../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

export function mapStateToProps (state) {
  return {
    ...state.grupoVerraco,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    registroIntervencionSanitaria: state.registroIntervencionSanitaria
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalSeleccionarVerraco,
      cerrarModalSeleccionarVerraco,
      fetchVerracosGrupoVerraco,
      seleccionarTodosModalVerracoGrupoVerraco,
      deSeleccionarTodosModalVerracoGrupoVerraco,
      anadirVerraco,
      fetchFiltrarVerracoModalVerraco,
      comboRaza,
      comboLineaGenetica,
      comboUbicacionPadre,
      comboVerraco,
      comboNombreGrupoVerracoActiva,
      cerrarModalVerracoRegistroIntervencionSanitaria,
      fetchVerracosRegistroIntervencionSanitaria,
      seleccionarTodosModalVerracoRegistroIntervencionSanitaria,
      deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria,
      anadirVerracoModalRegistroIntervencionSanitaria,
      fetchFiltrarVerracoModalRegistroIntervencionSanitaria
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerracoRegistroIntervencionSanitaria))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import setting from '../../setting'
import ModalCentro from '../../components/empresa/ModalCentro'
import { recuperarDatosCentro, cerrarModalCentro, submitNuevoCentro, uploadFileEmpresa, eliminarImagenEmpresa, editarCentro } from '../../actions/empresa/empresa'

export function mapStateToProps (state) {
  return {
    ...state.empresa,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    urlRecursos: setting.default.urlRecursos,
    formModalCentro: state.form.ModalCentro
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosCentro,
      cerrarModalCentro,
      submitNuevoCentro,
      uploadFileEmpresa,
      eliminarImagenEmpresa,
      editarCentro
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCentro))
import actionTypes from '../../constants/actions/copiaSeguridad/copiaSeguridad'

export function initialState () {
  return {
    listCopiaSeguridad: [],
    showModalCopiaSeguridad: false,
    checkedCopiaSeguridad: false,
    archivoCopiaSeguridad: [],
    showModalCopiaSeguridadProgramada: false,
    showModalRestaurarConArchivo: false,
    archivo: []
  }
}

export function fetchCopiasSeguridadSuccess (state, {listCopiaSeguridad}) {
  return {
    ...state,
    listCopiaSeguridad: listCopiaSeguridad
  }
}

export function abrirModalCopiaSeguridad (state) {
  return {
    ...state,
    showModalCopiaSeguridad: true
  }
}

export function cerrarModalCopiaSeguridad (state) {
  return {
    ...state,
    showModalCopiaSeguridad: false
  }
}

export function descargarCopiaSeguridadSuccess (state, {archivoCopiaSeguridad}) {
  return {
    ...state,
    archivoCopiaSeguridad: archivoCopiaSeguridad
  }
}

export function seleccionarTodosCopiaSeguridad (state) {
  return {
    ...state,
    checkedCopiaSeguridad: true
  }
}

export function deSeleccionarTodosCopiaSeguridad (state) {
  return {
    ...state,
    checkedCopiaSeguridad: false
  }
}

export function abrirModalCopiaSeguridadProgramada (state) {
  return {
    ...state,
    showModalCopiaSeguridadProgramada: true
  }
}

export function cerrarModalCopiaSeguridadProgramada (state) {
  return {
    ...state,
    showModalCopiaSeguridadProgramada: false
  }
}

export function abrirModalRestaurarConArchivo (state) {
  return {
    ...state,
    showModalRestaurarConArchivo: true
  }
}

export function cerrarModalRestaurarConArchivo (state) {
  return {
    ...state,
    showModalRestaurarConArchivo: false,
    archivo: []
  }
}

export function subirArchivosCopiaSeguridad (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function abrirModalRestaurarMigracion (state) {
  return {
    ...state,
    showModalRestaurarMigracion: true
  }
}

export function cerrarModalRestaurarMigracion (state) {
  return {
    ...state,
    showModalRestaurarMigracion: false,
    archivo: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_COPIAS_SEGURIDAD_SUCCESS:
      return fetchCopiasSeguridadSuccess(state, action)
    case actionTypes.ABRIR_MODAL_COPIA_SEGURIDAD:
      return abrirModalCopiaSeguridad(state, action)
    case actionTypes.CERRAR_MODAL_COPIA_SEGURIDAD:
      return cerrarModalCopiaSeguridad(state, action)
    case actionTypes.DESCARGAR_COPIA_SEGURIDAD_SUCCESS:
      return descargarCopiaSeguridadSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_COPIA_SEGURIDAD:
      return seleccionarTodosCopiaSeguridad(state, action)
    case actionTypes.DESELECCIONAR_TODOS_COPIA_SEGURIDAD:
      return deSeleccionarTodosCopiaSeguridad(state, action)
    case actionTypes.ABRIR_MODAL_COPIA_SEGURIDAD_PROGRAMADA:
      return abrirModalCopiaSeguridadProgramada(state, action)
    case actionTypes.CERRAR_MODAL_COPIA_SEGURIDAD_PROGRAMADA:
      return cerrarModalCopiaSeguridadProgramada(state, action)
    case actionTypes.ABRIR_MODAL_RESTAURAR_CON_ARCHIVO:
      return abrirModalRestaurarConArchivo(state, action)
    case actionTypes.CERRAR_MODAL_RESTAURAR_CON_ARCHIVO:
      return cerrarModalRestaurarConArchivo(state, action)
    case actionTypes.SUBIR_ARCHIVOS_COPIA_SEGURIDAD:
      return subirArchivosCopiaSeguridad(state, action)
    case actionTypes.ABRIR_MODAL_RESTAURAR_MIGRACION:
      return abrirModalRestaurarMigracion(state, action)
    case actionTypes.CERRAR_MODAL_RESTAURAR_MIGRACION:
      return cerrarModalRestaurarMigracion(state, action)
    default:
      return state
  }
}
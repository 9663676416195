import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ImportarVerracos from '../../components/importarVerracos/ImportarVerracos'
import {submitImportarVerracos, cerrarModalImportarVerracos, subirArchivosImportarVerracos, abrirModalImportarVerracos, descargarPlantillaExcelVerracos, descargarVerracos} from '../../actions/importarVerracos/importarVerracos'

export function mapStateToProps (state) {
  return {
    ...state.importarVerracos,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitImportarVerracos,
      cerrarModalImportarVerracos,
      subirArchivosImportarVerracos,
      abrirModalImportarVerracos,
      descargarPlantillaExcelVerracos,
      descargarVerracos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportarVerracos))
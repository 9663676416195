import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Filtros from '../../../components/planSanitario/microbiologia/FiltrosMicrobiologia'
import {openModal} from '../../../actions/common'
import {abrirModalVacioMicrobiologia, fetchFiltrarMicrobiologia, seleccionarTodosMicrobiologia, deSeleccionarTodosMicrobiologia, crearCsvMicrobiologia} from '../../../actions/planSanitario/microbiologia/microbiologia'

export function mapStateToProps (state) {
  return {
    ...state.microbiologia,    
    formSimpleTable: state.form.simpleTable,    
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioMicrobiologia,
      fetchFiltrarMicrobiologia,
      seleccionarTodosMicrobiologia,
      deSeleccionarTodosMicrobiologia,
      crearCsvMicrobiologia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filtros))
import actionTypes from '../../constants/actions/poolExtracciones/poolExtracciones'

export function initialState () {
  return {
    list: [],
    totales: {},
    checkedFiltrosPoolExtracciones: false,
    filtros:{},
    tokenUsuarioMagavision: '',
    t_anl: '', // tokenAnalisisMagavision
    t_dtv: '', // identificadorAnalisisMagavision generado por gesipor. unicamente se utiliza en la llamada a la api de Magavision.
    asignacion_view: false,
    pool: {
      loading: true,
      asignadosAnalisisList: [],
      checkedFiltrosAsignacionAnalisisPool: false,
      showModalAsignacionAnalisisPool: false,
      data: {},
      checkedFiltrosDisponiblesExtracciones: false,
      disponiblesAnalisisList: [],
      disponiblesAnalisisFiltros: {},
      totalDosisMililitros: 0,
      isPrimerSeguimientoDelPool: false,
      t_dtv_main: '',  // identificadorAnalisisMagavision correspondiente al seguimiento inicial del pool a partir del cual se utiliza para relacionarlo con el resto de seguimientos.
    }
  }
}

export function fetchPoolExtraccionesSuccess (state, {list, totales, filtros}) {
  return {
    ...state,
    list,
    totales,
    filtros
  }
}

export function seleccionarTodosPoolExtracciones (state) {
  return {
    ...state,
    checkedFiltrosPoolExtracciones: true
  }
}

export function deseleccionarTodosPoolExtracciones (state) {
  return {
    ...state,
    checkedFiltrosPoolExtracciones: false
  }
}

export function recuperarDatosFormPoolExtraccionSuccess (state, {data, analisis, totalDosisMililitros}) {
  return {
    ...state,
    pool: {
      ...state.pool,
      asignadosAnalisisList: analisis,
      data,
      t_dtv_main: data.t_dtv_main,
      loading: false,
      totalDosisMililitros
    }
  }
}

export function fetchCodigoPoolExtraccionesSuccess (state, {codigoPool}) {
  return {
    ...state,
    pool: {
      ...state.pool,
      codigoPool
    }
  }
}

export function initializeFormPoolExtraccionSuccess (state) {
  return {
    ...state,
    pool: (initialState()).pool
  }
}

export function seleccionarTodosAsignacionAnalisisPool (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      checkedFiltrosAsignacionAnalisisPool: true
    }
  }
}

export function deseleccionarTodosAsignacionAnalisisPool (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      checkedFiltrosAsignacionAnalisisPool: false
    }
  }
}

export function setAsignacionAnalisisPool (state, {list}) {
  return {
    ...state,
    pool: {
      ...state.pool,
      asignadosAnalisisList: list
    }
  }
}

export function abrirModalAsignacionAnalisisPoolSuccess (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      showModalAsignacionAnalisisPool: true,
      checkedFiltrosDisponiblesExtracciones: false
    }
  }
}

export function cerrarModalAsignacionAnalisisPoolSuccess (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      showModalAsignacionAnalisisPool: false,
      disponiblesAnalisisList: []
    }
  }
}

export function guardarModalAsignacionAnalisisPoolSuccess (state, {seleccionadosAnalisisList, totalDosisMililitros, mantenerModal}) {
  return {
    ...state,
    pool: {
      ...state.pool,
      showModalAsignacionAnalisisPool: mantenerModal,
      asignadosAnalisisList: seleccionadosAnalisisList,
      disponiblesAnalisisList: [],
      totalDosisMililitros
    }
  }
}

export function seleccionarTodosDisponiblesExtracciones (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      checkedFiltrosDisponiblesExtracciones: true
    }
  }
}

export function deseleccionarTodosDisponiblesExtracciones (state) {
  return {
    ...state,
    pool: {
      ...state.pool,
      checkedFiltrosDisponiblesExtracciones: false
    }
  }
}

export function fetchAnalisisDisponiblesSuccess (state, {list, filtros}) {
  return {
    ...state,
    pool: {
      ...state.pool,
      disponiblesAnalisisList: list,
      disponiblesAnalisisFiltros: filtros
    }
  }
}

export function fetchTokenMagavisionPoolSuccess (state, {tokenUsuarioMagavision, t_anl, t_dtv}) {
  return {
    ...state,
    tokenUsuarioMagavision,
    t_anl,
    t_dtv
  }
}

export function updateTokenMagavisionPoolSuccess (state, {t_dtv_main, isPrimerSeguimientoDelPool, t_dtv}) {
  return {
    ...state,
    t_dtv: t_dtv ? t_dtv : state.t_dtv,
    pool: {
      ...state.pool,
      t_dtv_main: isPrimerSeguimientoDelPool ? t_dtv_main : state.pool.t_dtv_main,
      isPrimerSeguimientoDelPool: isPrimerSeguimientoDelPool
    }
  }
}

export function updateTokenDtvMagavisionPoolSuccess (state, {t_dtv_main, t_dtv}) {
  return {
    ...state,
    t_dtv,
    pool: {
      ...state.pool,
      t_dtv_main
    }
  }
}

export function fromAsignacionView (state, {asignacion_view}) {
  return {
    ...state,
    asignacion_view: asignacion_view
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_POOL_EXTRACCIONES_SUCCESS:
      return fetchPoolExtraccionesSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_POOL_EXTRACCIONES:
      return seleccionarTodosPoolExtracciones(state, action)
    case actionTypes.DESELECCIONAR_TODOS_POOL_EXTRACCIONES:
      return deseleccionarTodosPoolExtracciones(state, action)
    case actionTypes.RECUPERAR_DATOS_FORM_POOL_EXTRACCION_SUCCESS:
      return recuperarDatosFormPoolExtraccionSuccess(state, action)
    case actionTypes.FETCH_CODIGO_POOL_EXTRACCIONES_SUCCESS:
      return fetchCodigoPoolExtraccionesSuccess(state, action)
    case actionTypes.INITIALIZE_FORM_POOL_EXTRACCION_SUCCESS:
      return initializeFormPoolExtraccionSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL:
      return seleccionarTodosAsignacionAnalisisPool(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL:
      return deseleccionarTodosAsignacionAnalisisPool(state, action)
    case actionTypes.SET_ASIGNACION_ANALISIS_POOL:
      return setAsignacionAnalisisPool(state, action)
    case actionTypes.ABRIR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS:
      return abrirModalAsignacionAnalisisPoolSuccess(state, action)
    case actionTypes.CERRAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS:
      return cerrarModalAsignacionAnalisisPoolSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS:
      return guardarModalAsignacionAnalisisPoolSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES:
      return seleccionarTodosDisponiblesExtracciones(state, action)
    case actionTypes.DESELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES:
      return deseleccionarTodosDisponiblesExtracciones(state, action)
    case actionTypes.FETCH_ANALISIS_DISPONIBLES_SUCCESS:
      return fetchAnalisisDisponiblesSuccess(state, action)
    case actionTypes.FETCH_TOKEN_MAGAVISION_POOL_SUCCESS:
      return fetchTokenMagavisionPoolSuccess(state, action)
    case actionTypes.UPDATE_TOKEN_MAGAVISION_POOL_SUCCESS:
      return updateTokenMagavisionPoolSuccess(state, action)
    case actionTypes.UPDATE_TOKEN_DTV_MAIN_MAGAVISION_POOL_SUCCESS:
      return updateTokenDtvMagavisionPoolSuccess(state, action)
    case actionTypes.FROM_ASIGNACION_VIEW_POOL:
      return fromAsignacionView(state, action)
    default:
      return state
  }
}
import actionTypes from '../../constants/actions/importarDatos/importarDatos'

export function initialState () {
  return {
    showModalImportarDatos: false,
    archivo: [],
    listImportarDatos: []
  }
}

export function abrirModalImportarDatos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarDatos: true
  }
}

export function cerrarModalImportarDatos (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarDatos: false,
    archivo: [],
    listImportarDatos: []
  }
}

export function subirArchivosImportarDatos (state, {archivo}) {
  return {
    ...state,
    archivo: archivo
  }
}

export function submitImportarDatosSuccess (state, {listImportarDatos}) {
  return {
    ...state,
    listImportarDatos: listImportarDatos
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_IMPORTAR_DATOS:
      return abrirModalImportarDatos(state, action)
     case actionTypes.CERRAR_MODAL_IMPORTAR_DATOS:
      return cerrarModalImportarDatos(state, action)
     case actionTypes.SUBIR_ARCHIVOS_IMPORTAR_DATOS:
      return subirArchivosImportarDatos(state, action)
     case actionTypes.SUBMIT_IMPORTAR_DATOS_SUCCESS:
      return submitImportarDatosSuccess(state, action)
    default:
      return state
  }
}

export default {
  RECUPERAR_DATOS_POOL:'RECUPERAR_DATOS_POOL',
  RECUPERAR_DATOS_POOL_SUCCESS:'RECUPERAR_DATOS_POOL_SUCCESS',
  ADD_ANALISIS_POOL: 'ADD_ANALISIS_POOL',
  ADD_ANALISIS_POOL_SUCCESS:'ADD_ANALISIS_POOL_SUCCESS',
  INITIALIZE_ANALISIS_EXTRACCIONES_SUCCESS:'INITIALIZE_ANALISIS_EXTRACCIONES_SUCCESS',
  INITIALIZE_ANALISIS_EXTRACCIONES:'INITIALIZE_ANALISIS_EXTRACCIONES',
  FETCH_EXTRACCIONES_SIN_ASIGNAR:'FETCH_EXTRACCIONES_SIN_ASIGNAR',
  FETCH_EXTRACCIONES_SIN_ASIGNAR_SUCCESS:'FETCH_EXTRACCIONES_SIN_ASIGNAR_SUCCESS',
  SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES: 'SELECCIONAR_TODOS_ANALISIS_EXTRACCIONES',
  REMOVE_ANALISIS_POOL:'REMOVE_ANALISIS_POOL',
  REMOVE_ANALISIS_POOL_SUCCESS:'REMOVE_ANALISIS_POOL_SUCCESS',
  CREATE_NEW_POOL:'CREATE_NEW_POOL',
  CLOSE_MODAL:'CLOSE_MODAL',
  CREATE_NEW_POOL_SUCCESS:'CREATE_NEW_POOL_SUCCESS',
  ABRIR_MODAL_NUEVO_POOL_ASIGNACION:'ABRIR_MODAL_NUEVO_POOL_ASIGNACION',
  ABRIR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS:'ABRIR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS',
  CERRAR_MODAL_NUEVO_POOL_ASIGNACION:'CERRAR_MODAL_NUEVO_POOL_ASIGNACION',
  CERRAR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS:'CERRAR_MODAL_NUEVO_POOL_ASIGNACION_SUCCESS',
  OBTENER_NUMERO_POOL: 'OBTENER_NUMERO_POOL',
  OBTENER_NUMERO_POOL_SUCCESS: 'OBTENER_NUMERO_POOL_SUCCESS'
}
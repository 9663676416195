import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosEnvasadoMultiDosisTable extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      totalDisponibleAlcanzado: false
    }
    this.intercambiarCheckModalEnvasadoMultiDosis = this.intercambiarCheckModalEnvasadoMultiDosis.bind(this)
    this.abrirModalEnvasadoMultiDosis = this.abrirModalEnvasadoMultiDosis.bind(this)
    this.deleteSeleccionEnvasadoMultiDosis = this.deleteSeleccionEnvasadoMultiDosis.bind(this)
  }

  intercambiarCheckModalEnvasadoMultiDosis (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosEnvasadoMultiDosis()
    } else {
      this.props.actions.deseleccionarTodosEnvasadoMultiDosis()
    }
  }

  abrirModalEnvasadoMultiDosis (){
    this.props.actions.abrirModalEnvasadoMultiDosis()
  }

  deleteSeleccionEnvasadoMultiDosis (){
    const itemsSeleccion = this.props.data.multiDosis.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionEnvasadoMultiDosis(list)
    this.props.change('seleccionar-todos-modal-envasado-multi-dosis', false)
  }

  componentDidUpdate (prevProps) {
    if (this.props.data.totalDisponible <= this.props.data.totalUtilizado && !this.state.totalDisponibleAlcanzado) {
      this.setState({ totalDisponibleAlcanzado: true })
    } else if (this.props.data.totalDisponible > this.props.data.totalUtilizado && this.state.totalDisponibleAlcanzado) {
      this.setState({ totalDisponibleAlcanzado: false })
    }
  }

  render () {
  const {
    t, auth: { permisos }
  } = this.props

  const tKey = 'EXTRACCIONES.ENVASADO.MODAL.'

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-envasado-multi-dosis"
                    name="seleccionar-todos-modal-envasado-multi-dosis"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalEnvasadoMultiDosis(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionEnvasadoMultiDosis()}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENVASADO] < 2) || this.state.totalDisponibleAlcanzado ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1) && !this.state.totalDisponibleAlcanzado ? this.abrirModalEnvasadoMultiDosis : undefined}
                >
                  <AddCircleOutline /> {t(tKey + 'FILTROS.ANADIR')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosEnvasadoMultiDosisTable',
})(FiltrosEnvasadoMultiDosisTable))

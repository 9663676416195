import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {modulos as modulosConstant} from '../../constants/modulos'

class FiltrosInformesPool extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      campoCliente: false,
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerInformePool({})
    this.props.actions.fetchComboPool()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboOperario()
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboVerraco()
  }

  obtenerInformePool(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.obtenerInformePool(values)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosPdfInformesPool !== prevProps.datosPdfInformesPool) {
      if (this.props.datosPdfInformesPool.pdf && this.props.datosPdfInformesPool.pdf !== null){
        let pdf = this.props.datosPdfInformesPool.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.INFORMES.POOL') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  imprimirPdfInformesPool(){
    this.props.actions.imprimirPdfInformesPool(this.props.filtrosPool)
  }

  crearCsvInformePool () {
    const filtros = this.props.filtrosPool
    this.props.actions.crearCsvInformePool(filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtrosPool
    if (value === 'hasta') {
      values.fechaSalida = null
    }
    if (value === 'desde') {
      values.fechaEntrada = null
    }
    values[value] = null
    this.props.actions.obtenerInformePool(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtrosPool,
    combos: {
      comboPool, comboNombreGrupoVerraco, comboTipoDosis, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboVerraco
    }
  } = this.props

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    const combosegimiento= [
      {value: true, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.SI')},
      {value: false, label: t('COMUN.COMBOS.SEGUIMIENTO_INFORMES.NO')}
    ]

    const comboPoolEliminado= [
      {value: 1, label: t('COMUN.COMBOS.POOL_ELIMINADO.NO')},
      {value: 2, label: t('COMUN.COMBOS.POOL_ELIMINADO.TODOS')},
      {value: 3, label: t('COMUN.COMBOS.POOL_ELIMINADO.CREACION')},
      {value: 4, label: t('COMUN.COMBOS.POOL_ELIMINADO.ENVASADO')}
    ]

    let comboConservacion = this.props.combos.comboConservacion
    comboConservacion = comboConservacion.map((conservacion) => {
      return {...conservacion, label: t(conservacion.label)}
    })

    const comboDosis = comboTipoDosis.map((dosis) => dosis)
    const comboDosisFiltros = []
    comboDosis.forEach(function(dosis){
      comboDosisFiltros.push({
        value: dosis.value,
        label: t(dosis.label)
      })
    })

    let comboMotilidad = [
      {value: 1, label: t('COMUN.COMBOS.COMBO_MOTILIDAD.MOTILIDAD_MASAL')},
      {value: 2, label: t('COMUN.COMBOS.COMBO_MOTILIDAD.MT')},
      {value: 3, label: t('COMUN.COMBOS.COMBO_MOTILIDAD.MP')},
      {value: 4, label: t('COMUN.COMBOS.COMBO_MOTILIDAD.MOTILIDAD')}
    ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="pool"
              name="pool"
              controlLabel={t('INFORMES.POOL.FILTROS.POOL')}
              component={InputSelect}
              options={comboPool}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            {/*
            <Field
              colSm={2}
              id="operarioColecta"
              name="operarioColecta"
              controlLabel={t('INFORMES.POOL.FILTROS.OPERARIO_COLECTA')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />*/}
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('INFORMES.POOL.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('INFORMES.POOL.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('INFORMES.POOL.FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('INFORMES.POOL.FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                colSm={2}
                id="intervencionSanitaria"
                name="intervencionSanitaria"
                controlLabel={t('INFORMES.POOL.FILTROS.INTERVENCION_SANITARIA')}
                component={InputSelect}
                options={comboTipoIntervencionSanitaria}
                valueKey="value"
                labelKey="label"
                customClass='select-informes'
                onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
              />
            )}
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t('INFORMES.POOL.FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboDosisFiltros}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Field
              colSm={2}
              id="seguimiento"
              name="seguimiento"
              controlLabel={t('INFORMES.POOL.FILTROS.SEGUIMIENTO')}
              component={InputSelect}
              options={combosegimiento}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Field
              colSm={2}
              id="conservacion"
              name="conservacion"
              controlLabel={t('INFORMES.POOL.FILTROS.CONSERVACION')}
              component={InputSelect}
              options={comboConservacion}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            {/*
            <Field
              colSm={2}
              id="motilidad"
              name="motilidad"
              controlLabel={t('INFORMES.POOL.FILTROS.MOTILIDAD')}
              component={InputSelect}
              options={comboMotilidad}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />*/}
            <Field
              colSm={2}
              id="poolEliminado"
              name="poolEliminado"
              controlLabel={t('INFORMES.POOL.FILTROS.POOL_ELIMINADO')}
              component={InputSelect}
              options={comboPoolEliminado}
              valueKey="value"
              labelKey="label"
              customClass='select-informes'
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('INFORMES.POOL.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.obtenerInformePool.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.pool && filtrosPool.pool !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.POOL') + ': ' + filtrosPool.pool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('pool')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.verraco && filtrosPool.verraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.VERRACO') + ': ' + filtrosPool.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {/*
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.operarioColecta && filtrosPool.operarioColecta !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.OPERARIO_COLECTA') + ': ' + filtrosPool.operarioColecta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('operarioColecta')}> x </button></span>
                    ) : null
                  )
                */}
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.raza && filtrosPool.raza !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.RAZA') + ': ' + filtrosPool.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.lineaGenetica && filtrosPool.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.LINEA_GENETICA') + ': ' + filtrosPool.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.grupoVerraco && filtrosPool.grupoVerraco !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.GRUPO_VERRACO') + ': ' + filtrosPool.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.intervencionSanitaria && filtrosPool.intervencionSanitaria !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.INTERVENCION_SANITARIA') + ': ' + filtrosPool.intervencionSanitaria.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('intervencionSanitaria')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.tipoDosis && filtrosPool.tipoDosis !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.TIPO_DOSIS') + ': ' + filtrosPool.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.seguimiento && filtrosPool.seguimiento !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.SEGUIMIENTO') + ': ' + filtrosPool.seguimiento.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('seguimiento')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.conservacion && filtrosPool.conservacion !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.CONSERVACION') + ': ' + filtrosPool.conservacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('conservacion')}> x </button></span>
                    ) : null
                  )
                }
                {/*
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.motilidad && filtrosPool.motilidad !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.MOTILIDAD') + ': ' + filtrosPool.motilidad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('motilidad')}> x </button></span>
                    ) : null
                  )
                */}
                {
                  Object.keys(filtrosPool).length > 0 && (
                    filtrosPool.poolEliminado && filtrosPool.poolEliminado !== null ? (
                      <span className="badge-dark">{t('INFORMES.POOL.FILTROS.POOL_ELIMINADO') + ': ' + filtrosPool.poolEliminado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('poolEliminado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtrosPool).length > 0 && (
                    (filtrosPool.fechaEntrada && filtrosPool.fechaSalida && filtrosPool.fechaEntrada !== null && filtrosPool.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosPool.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosPool.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtrosPool.fechaEntrada && filtrosPool.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtrosPool.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtrosPool.fechaSalida && filtrosPool.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('RAZA.FILTROS.FECHA_ENTRADA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtrosPool.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvInformePool()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={() => this.imprimirPdfInformesPool()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosInformesPool',
})(FiltrosInformesPool))

import actionTypes from '../../constants/actions/auth/auth'

export function fetchToken (resolve, reject) {
  return {
    type: actionTypes.FETCH_TOKEN,
    resolve,
    reject
  }
}

export function fetchTokenSuccess (token) {
  return {
    type: actionTypes.FETCH_TOKEN_SUCCESS,
    token
  }
}


export function login (data, adminLogin) {
  return {
    type: actionTypes.LOGIN,
    data,
    adminLogin
  }
}

export function loginSuccess (data) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data
  }
}

export function loginError () {
  return {
    type: actionTypes.LOGIN_ERROR
  }
}

export function logout (expiredSession = false, adminLogin = false) {
  return {
    type: actionTypes.LOGOUT,
    expiredSession,
    adminLogin
  }
}

export function logoutSuccess (expiredSession = false) {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    expiredSession
  }
}

export function requestResetPassword (data, resolve, reject) {
  return {
    type: actionTypes.REQUEST_RESET_PASSWORD,
    data,
    resolve,
    reject
  }
}

export function resetPassword (data, resolve, reject) {
  return {
    type: actionTypes.RESET_PASSWORD,
    data,
    resolve,
    reject
  }
}

export function testAuth (val) {
  return {
    type: actionTypes.TEST_AUTH,
    val
  }
}

export function cambiarImagenAuth (imagen) {
  return {
    type: actionTypes.CAMBIAR_IMAGEN_AUTH,
    imagen
  }
}

export function cambiarNombreUsuarioAuth (nombreUsuario) {
  return {
    type: actionTypes.CAMBIAR_NOMBRE_USUARIO_AUTH,
    nombreUsuario
  }
}

export function licenciaExpiradaSuccess (licenciaExpirada) {
  return {
    type: actionTypes.LICENCIA_EXPIRADA_SUCCESS,
    licenciaExpirada
  }
}

export function submitRenovarToken (values) {
  return {
    type: actionTypes.SUBMIT_RENOVAR_TOKEN,
    values
  }
}

export function comprobarEntorno () {
  return {
    type: actionTypes.COMPROBAR_ENTORNO
  }
}

export function comprobarEntornoSuccess (isCloud) {
  return {
    type: actionTypes.COMPROBAR_ENTORNO_SUCCESS,
    isCloud
  }
}

export function comprobarEntornoLocal () {
  return {
    type: actionTypes.COMPROBAR_ENTORNO_LOCAL
  }
}

export function comprobarEntornoLocalSuccess (isLocal) {
  return {
    type: actionTypes.COMPROBAR_ENTORNO_LOCAL_SUCCESS,
    isLocal
  }
}

export function cambiarIdiomaAuth (idioma) {
  return {
    type: actionTypes.CAMBIAR_IDIOMA_AUTH,
    idioma
  }
}

export function obtenerNumeroSerie () {
  return {
    type: actionTypes.OBTENER_NUMERO_SERIE
  }
}

export function obtenerNumeroSerieSuccess (numeroSerie) {
  return {
    type: actionTypes.OBTENER_NUMERO_SERIE_SUCCESS,
    numeroSerie
  }
}

export function subirArchivosActualizarAplicacion (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_ACTUALIZAR_APLICACION,
    archivos
  }
}

export function submitActualizarAplicacion (values) {
  return {
    type: actionTypes.SUBMIT_ACTUALIZAR_APLICACION,
    values
  }
}

export function mostrarInformacionLicencia () {
  return {
    type: actionTypes.MOSTRAR_INFORMACION_LICENCIA
  }
}

export function mostrarInformacionLicenciaSuccess (datosLicencia) {
  return {
    type: actionTypes.MOSTRAR_INFORMACION_LICENCIA_SUCCESS,
    datosLicencia
  }
}

export default {
  fetchToken,
  fetchTokenSuccess,
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  requestResetPassword,
  resetPassword,
  testAuth,
  cambiarImagenAuth,
  cambiarNombreUsuarioAuth,
  licenciaExpiradaSuccess,
  submitRenovarToken,
  comprobarEntorno,
  comprobarEntornoSuccess,
  cambiarIdiomaAuth,
  obtenerNumeroSerie,
  obtenerNumeroSerieSuccess,
  subirArchivosActualizarAplicacion,
  submitActualizarAplicacion,
  mostrarInformacionLicencia
}

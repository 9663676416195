import actionTypes from '../../constants/actions/nivelGenetico/nivelGenetico'

export function fetchNivelGenetico () {
  return {
    type: actionTypes.FETCH_NIVEL_GENETICO
  }
}

export function fetchNivelGeneticoSuccess (list) {
  return {
    type: actionTypes.FETCH_NIVEL_GENETICO_SUCCESS,
    list
  }
}

export function submitNuevaNivelGenetico (values) {
  return {
    type: actionTypes.SUBMIT_NUEVA_NIVEL_GENETICO,
    values
  }
}

export function submitNuevaNivelGeneticoSuccess (insercionNivelGenetico) {
  return {
    type: actionTypes.SUBMIT_NUEVA_NIVEL_GENETICO_SUCCESS,
    insercionNivelGenetico
  }
}

export function recuperarDatosModalNivelGenetico (idNivelGenetico) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_NIVEL_GENETICO,
    idNivelGenetico
  }
}

export function recuperarDatosModalNivelGeneticoSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_NIVEL_GENETICO_SUCCESS,
    data
  }
}

export function abrirModalVacioNivelGenetico () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_NIVEL_GENETICO
  }
}

export function editarNivelGenetico (values) {
  return {
    type: actionTypes.EDITAR_NIVEL_GENETICO,
    values
  }
}

export function editarNivelGeneticoSuccess (edicionNivelGenetico) {
  return {
    type: actionTypes.EDITAR_NIVEL_GENETICO_SUCCESS,
    edicionNivelGenetico
  }
}

export function onDeleteNivelGenetico (id, nombre, prioridad) {
  return {
    type: actionTypes.ON_DELETE_NIVEL_GENETICO,
    id,
    nombre,
    prioridad
  }
}

export function onDeleteNivelGeneticoSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_NIVEL_GENETICO_SUCCESS,
    dataDelete
  }
}

export default {
  fetchNivelGenetico,
  fetchNivelGeneticoSuccess,
  submitNuevaNivelGenetico,
  submitNuevaNivelGeneticoSuccess,
  recuperarDatosModalNivelGenetico,
  recuperarDatosModalNivelGeneticoSuccess,
  abrirModalVacioNivelGenetico,
  editarNivelGenetico,
  editarNivelGeneticoSuccess,
  onDeleteNivelGenetico,
  onDeleteNivelGeneticoSuccess
}

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import tipoInstalacionsActionTypes from '../../constants/actions/tipoInstalacion/tipoInstalacion'
import {fetchTipoInstalacionSuccess, recuperarDatosModalTipoInstalacionSuccess, duplicarDatosModalTipoInstalacionSuccess, cambiarEstadoTipoInstalacionSuccess, onDeleteTipoInstalacionSuccess,
  deSeleccionarTodosTipoInstalacion, fetchFiltrarTipoInstalacionSuccess, submitNuevaTipoInstalacionSuccess, editarTipoInstalacionSuccess, crearCsvTipoInstalacionSuccess,
  imprimirPdfTipoInstalacionSuccess} from '../../actions/tipoInstalacion/tipoInstalacion'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getTipoInstalacionList from '../../services/tipoInstalacion/getTipoInstalacionList'
import addTipoInstalacion from '../../services/tipoInstalacion/addTipoInstalacion'
import getTipoInstalacion from '../../services/tipoInstalacion/getTipoInstalacion'
import editTipoInstalacion from '../../services/tipoInstalacion/editTipoInstalacion'
import getTipoInstalacionParaDuplicar from '../../services/tipoInstalacion/getTipoInstalacionParaDuplicar'
import getTipoInstalacionCambioEstado from '../../services/tipoInstalacion/getTipoInstalacionCambioEstado'
import deleteTipoInstalacion from '../../services/tipoInstalacion/deleteTipoInstalacion'
import getFiltrarTipoInstalacion from '../../services/tipoInstalacion/getFiltrarTipoInstalacion'
import getCambiarEstadoSeleccion from '../../services/tipoInstalacion/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/tipoInstalacion/getDeleteSeleccion'
import getCrearCsv from '../../services/tipoInstalacion/getCrearCsv'
import getCrearCsvTipoInstalacion from '../../services/tipoInstalacion/getCrearCsvTipoInstalacion'
import getImprimirPdfTipoInstalacion from '../../services/tipoInstalacion/getImprimirPdfTipoInstalacion'
import {comboTipoInstalacionPadre} from '../../actions/combos/combos'

export function * fetchTipoInstalacion () {
  try {
    const list = yield call(getTipoInstalacionList, null)
    yield put(fetchTipoInstalacionSuccess(list))
    yield call(comboTipoInstalacionPadre)
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.FETCH_TIPO_INSTALACION, fetchTipoInstalacion)
}

export function * submitNuevaTipoInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoInstalacion = yield call(addTipoInstalacion, values, auth.token)
    yield put(submitNuevaTipoInstalacionSuccess(insercionTipoInstalacion))
    yield put(closeModal())
    if (insercionTipoInstalacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    const val = yield select(state => state.tipoInstalacion.filtros)
    const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
    yield put(fetchTipoInstalacionSuccess(lista))
    yield put(fetchFiltrarTipoInstalacionSuccess(val))
    yield put(comboTipoInstalacionPadre())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.SUBMIT_NUEVA_TIPO_INSTALACION, submitNuevaTipoInstalacion)
}

export function * recuperarDatosModalTipoInstalacion ({idTipoInstalacion}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getTipoInstalacion, idTipoInstalacion)
    yield put(recuperarDatosModalTipoInstalacionSuccess(registroModificar))
    if (registroModificar.nombrePadre) {
      registroModificar.idTipoInstalacionPadre = {value: registroModificar.idTipoInstalacionPadre, label: registroModificar.nombrePadre}
    }
    yield put(initialize('ModalTipoInstalacion', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.RECUPERAR_DATOS_MODAL_TIPO_INSTALACION, recuperarDatosModalTipoInstalacion)
}

export function * abrirModalVacioTipoInstalacion () {
  try {
    yield put(recuperarDatosModalTipoInstalacionSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalTipoInstalacion', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.ABRIR_MODAL_VACIO_TIPO_INSTALACION, abrirModalVacioTipoInstalacion)
}

export function * editarTipoInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionTipoInstalacion = yield call(editTipoInstalacion, values, auth.token)
    yield put(editarTipoInstalacionSuccess(edicionTipoInstalacion))
    yield put(closeModal())
    if (edicionTipoInstalacion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(comboTipoInstalacionPadre())
    const val = yield select(state => state.tipoInstalacion.filtros)
    if (val.idTipoInstalacionPadre && values.idTipoInstalacion){
      val.idTipoInstalacionPadre = {value: val.idTipoInstalacionPadre.value, label: values.nombreTipoInstalacion}
    }
    const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
    yield put(fetchTipoInstalacionSuccess(lista))
    yield put(fetchFiltrarTipoInstalacionSuccess(val))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.EDITAR_TIPO_INSTALACION, editarTipoInstalacion)
}

export function * duplicarDatosModalTipoInstalacion ({idTipoInstalacion}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalTipoInstalacionSuccess(null))
    const duplicar = yield call(getTipoInstalacionParaDuplicar, idTipoInstalacion)
    yield put(duplicarDatosModalTipoInstalacionSuccess(duplicar))
    yield put(initialize('ModalTipoInstalacion', duplicar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchDuplicarDatosModalTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.DUPLICAR_DATOS_MODAL_TIPO_INSTALACION, duplicarDatosModalTipoInstalacion)
}

export function * cambiarEstadoTipoInstalacion ({idTipoInstalacion}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getTipoInstalacionCambioEstado, idTipoInstalacion, auth.token)
      yield put(cambiarEstadoTipoInstalacionSuccess(cambioEstado))
      if (cambioEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      const val = yield select(state => state.tipoInstalacion.filtros)
      const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
      yield put(fetchTipoInstalacionSuccess(lista))
      yield put(fetchFiltrarTipoInstalacionSuccess(val))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarEstadoTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.CAMBIAR_ESTADO_TIPO_INSTALACION, cambiarEstadoTipoInstalacion)
}

export function * onDeleteTipoInstalacion ({idTipoInstalacion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataDelete = yield call(deleteTipoInstalacion, idTipoInstalacion, auth.token)
      yield put(onDeleteTipoInstalacionSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboTipoInstalacionPadre())
      const val = yield select(state => state.tipoInstalacion.filtros)
      if (val.idTipoInstalacionPadre && val.idTipoInstalacionPadre.value === idTipoInstalacion){
        val.idTipoInstalacionPadre = null
      }
      const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
      yield put(fetchTipoInstalacionSuccess(lista))
      yield put(fetchFiltrarTipoInstalacionSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.ON_DELETE_TIPO_INSTALACION, onDeleteTipoInstalacion)
}

export function * fetchFiltrarTipoInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarTipoInstalacion, values, auth.token)
    yield put(fetchTipoInstalacionSuccess(list))
    yield put(fetchFiltrarTipoInstalacionSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.FETCH_FILTRAR_TIPO_INSTALACION, fetchFiltrarTipoInstalacion)
}

export function * cambiarEstadoSeleccionTipoInstalacion ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
      if (cambioEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosTipoInstalacion())
      yield put(initialize('FiltrosTipoInstalacion'))
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.tipoInstalacion.filtros)
      const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
      yield put(fetchTipoInstalacionSuccess(lista))
      yield put(fetchFiltrarTipoInstalacionSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarEstadoSeleccionTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_INSTALACION, cambiarEstadoSeleccionTipoInstalacion)
}

export function * deleteSeleccionTipoInstalacion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccion, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true && dataDeleteSeleccion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosTipoInstalacion())
      yield put(comboTipoInstalacionPadre())
      const val = yield select(state => state.tipoInstalacion.filtros)
      list.forEach((li) => {
        if (val.idTipoInstalacionPadre && val.idTipoInstalacionPadre.value === li.idTipoInstalacion){
          val.idTipoInstalacionPadre = null
        }
      })
      const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
      yield put(fetchTipoInstalacionSuccess(lista))
      yield put(fetchFiltrarTipoInstalacionSuccess(val))
      yield put(initialize('FiltrosTipoInstalacion', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccionTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.DELETE_SELECCION_TIPO_INSTALACION, deleteSeleccionTipoInstalacion)
}

export function * guardarYnuevoTipoInstalacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoInstalacion = yield call(addTipoInstalacion, values, auth.token)
    yield put(submitNuevaTipoInstalacionSuccess(insercionTipoInstalacion))
    const valores = {activo: true}
    yield put(initialize('ModalTipoInstalacion', valores))
    if (insercionTipoInstalacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    const val = yield select(state => state.tipoInstalacion.filtros)
    const lista = yield call(getFiltrarTipoInstalacion, val, auth.token)
    yield put(fetchTipoInstalacionSuccess(lista))
    yield put(fetchFiltrarTipoInstalacionSuccess(val))
    yield put(comboTipoInstalacionPadre())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchGuardarYnuevoTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.GUARDAR_Y_NUEVO_TIPO_INSTALACION, guardarYnuevoTipoInstalacion)
}

export function * crearCsvTipoInstalacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelTipoInstalacion = yield call(getCrearCsvTipoInstalacion, list, auth.token)
    yield put(crearCsvTipoInstalacionSuccess(datosExcelTipoInstalacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.CREATE_CSV_TIPO_INSTALACION, crearCsvTipoInstalacion)
}
export function * imprimirPdfTipoInstalacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfTipoInstalacion = yield call(getImprimirPdfTipoInstalacion, list, auth.token)
    yield put(imprimirPdfTipoInstalacionSuccess(datosPdfTipoInstalacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfTipoInstalacion () {
  yield takeLatest(tipoInstalacionsActionTypes.IMPRIMIR_PDF_TIPO_INSTALACION, imprimirPdfTipoInstalacion)
}

import React, { Component } from 'react'
import { Row, div, Button } from 'react-bootstrap'
import './Plano.scss'
import { date_parser } from '../../util/formatFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {Delete, AddCircleOutline, Refresh, Launch, Print, ViewModule, ViewWeek, Opacity, Dns, Looks, KeyboardArrowDown} from '@material-ui/icons'
import { Masonry } from "masonic";

class Plano extends Component {
  constructor () {
    super()
    this.state = {
      idInstalacion: '',
      desplegable: true
    }
  }

  UNSAFE_componentWillMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.PLANO.VER_MAPA') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchPlano()
  }

  componentWillUnmount() {
    this.props.actions.vaciarPlano()
  }

  desplegarNave (id) {
    var clase = document.getElementsByClassName('itemPlano' + id);
    if (clase.length > 0) {
      document.getElementById('itemPlano' + id).classList.remove('item-plegado', 'itemPlano' + id)
    } else {
      document.getElementById('itemPlano' + id).classList.add('item-plegado', 'itemPlano' + id)
    }
  }

  render () {
    const {
      t
    } = this.props

  const renderSubPlano = (children) => {
    if (children && children.length > 0) {
      return (
        <span className="sub-plano">
          {renderPlanoItems(children)}
        </span>
      );
    }
  };

  const renderPlanoItems = (instalacionesHijas) => 
    Object.values(instalacionesHijas).map((item, i) => {
      return (
        <span className={item.silo === true ? ('item-silo item-plano') : (item.salaExtraccion === true ? ('item-estraccion item-plano') : item.animales === true && item.verraco === null ? ('item-no-animales item-plano') : item.verraco !== null ? ('item-corral item-plano') : ('item-plano item-pasillo'))} key={item.idInstalacion}>
        <div id={"itemPlano" + item.idInstalacion}>
          <p>
            {
              item.idTipoInstalacionPadre === null ? (
                item.silo === true ? (
                  <Opacity />
                ) :
                <ViewModule />
              ) : (
                item.silo === true ? (
                  <Opacity />
                ) : (
                  item.animales === true ? (
                    <Dns />
                  ) : item.salaExtraccion === true ? (
                    <Looks />
                  ) :
                  <ViewWeek />
                )
              )
            }
            {
              item.nombreInstalacion
            }
            {
              item.idTipoInstalacionPadre !== null && !item.silo && !item.animales && !item.salaExtraccion && !item.salaExtraccion && !item.verraco ? (
                <span onClick={() => this.desplegarNave(item.idInstalacion)} className="pasillo-desplegable"><KeyboardArrowDown /></span>
              ) : null
            }
          </p>
          {
            item.verraco !== null ? (
              <span style={{float: 'right', display: 'block', fontWeight: 'normal', fontSize: '12px', marginBottom: '5px'}}>
                {
                  item.animales === true ? (
                    <div className="font-gesipor-cerdo cerdito"></div>
                  ) : null
                }
                {item.verraco}
              </span>
            ) : null
          }
          {
            item.instalacionesHijas !== null && (
              renderSubPlano(item.instalacionesHijas)
            )
          }
          </div>
        </span>
      )
    })

    const getFakeItems = (cur = 0) => {
      const fakeItems = [];
      for (let i = this.props.instalacionesHijas.length * cur; i < cur * this.props.instalacionesHijas.length + this.props.instalacionesHijas.length; i++)
        fakeItems.push({ id: i, instalacionesHijas: this.props.instalacionesHijas });
      return fakeItems;
    };
    const items = getFakeItems();

    const contenidoPlano = ({ data: { id, instalacionesHijas } }) => (
      Object.keys(instalacionesHijas).length > 0 && instalacionesHijas !== null ? (
      <span className={instalacionesHijas[id].silo === true ? ('item-silo item-plano') : (instalacionesHijas[id].salaExtraccion === true ? ('item-estraccion item-plano') : instalacionesHijas[id].animales === true && instalacionesHijas[id].verraco === null ? ('item-no-animales item-plano') : ('item-plano'))} key={instalacionesHijas[id].idInstalacion}>
          <div id={"itemPlano" + instalacionesHijas[id].idInstalacion}>
            <p>
              {
                instalacionesHijas[id].idTipoInstalacionPadre === null ? (
                  instalacionesHijas[id].silo === true ? (
                    <Opacity />
                  ) :
                  <ViewModule />
                ) : (
                  instalacionesHijas[id].silo === true ? (
                    <Opacity />
                  ) : (
                    instalacionesHijas[id].animales === true ? (
                      <Dns />
                    ) : instalacionesHijas[id].salaExtraccion === true ? (
                      <Looks />
                    ) :
                    <ViewWeek />
                  )
                )
              }
              {
                instalacionesHijas[id].nombreInstalacion
              }
              {
                instalacionesHijas[id].idTipoInstalacionPadre === null && !instalacionesHijas[id].silo && !instalacionesHijas[id].animales && !instalacionesHijas[id].salaExtraccion ? (
                  <span onClick={() => this.desplegarNave(instalacionesHijas[id].idInstalacion)} className="plano-desplegable"><KeyboardArrowDown /></span>
                ) : null
              }
            </p>
            {
              instalacionesHijas[id].verraco !== null ? (
                <span style={{float: 'right', display: 'block', fontWeight: 'normal', fontSize: '12px', marginBottom: '5px'}}>
                  {
                    instalacionesHijas[id].animales === true ? (
                      <div className="font-gesipor-cerdo cerdito"></div>
                    ) : null
                  }
                  {instalacionesHijas[id].verraco}
                </span>
              ) : null
            }
            {
              instalacionesHijas[id].instalacionesHijas !== null && (
                renderPlanoItems(instalacionesHijas[id].instalacionesHijas)
              )
            }
            </div>
          </span>

        ) : null
    )

    return (
      <div>
        <h2 className="page-title">{t('PLANO.TITLE')}</h2>
          <span className="content-plano">
            <Masonry
              items={items}
              columnGutter={8}
              columnWidth={400}
              overscanBy={5}
              render={contenidoPlano}
            />
        </span>
      </div>
    )
  }
}

export default (Plano)
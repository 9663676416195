import React, { Component } from 'react'
import {reduxForm} from 'redux-form'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import ModalMovimientoVerracoPage from '../../containers/movimientoVerraco/ModalMovimientoVerracoPage'
import FiltrosMovimientoVerracoPage from '../../containers/movimientoVerraco/FiltrosMovimientoVerracoPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class MovimientoVerraco extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.VERRACOS.MOVIMIENTO_VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarMovimientoVerraco({})
    this.props.actions.restartVerraco()
  }
  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      actions: {recuperarDatosModalMovimientoVerraco, duplicarDatosModalMovimientoVerraco, onDeleteMovimientoVerraco, restartVerraco},
      movimientoVerraco: {list, registroModificar, registroDuplicar, checkedFiltrosMovimientoVerraco}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idUbicacionVerraco
    )

    const table = {
      id: 'MovimientoVerracoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1),
      hasOptions: true,
      columns: [
        //{id: 'codigo', name: 'Código'},
        {id: 'fechaMovimiento', name: t('MOVIMIENTO_VERRACOS.FECHA'), type: 'fecha'},
        {id: 'codVerraco', name: t('MOVIMIENTO_VERRACOS.VERRACO')},
        {id: 'instalacionorigen', name: t('MOVIMIENTO_VERRACOS.ORIGEN')},
        {id: 'instalaciondestino', name: t('MOVIMIENTO_VERRACOS.DESTINO')},
        {id: 'observacionesMovimiento', name: t('MOVIMIENTO_VERRACOS.OBSERVACIONES')}
      ],
      rows: list,
      initialValues: {},
      filtros: <FiltrosMovimientoVerracoPage/>,
      onEdit: (row) => recuperarDatosModalMovimientoVerraco(row.idUbicacionVerraco),
      data: registroModificar,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1,
      duplicar: (row) =>duplicarDatosModalMovimientoVerraco(row.idUbicacionVerraco),
      dataDuplicar: registroDuplicar,
      mostrarCambioEstado: false,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1,
      onDelete: (row) => onDeleteMovimientoVerraco(row.idUbicacionVerraco),
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosMovimientoVerraco
        }
      }
    )
    return(
    <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalMovimientoVerracoPage
          esInsertar={true}
          mostrarComboVerraco={true}
        />
        <ListadoPage t_key="MOVIMIENTO_VERRACOS." table={table}>
        </ListadoPage>
      </div>
    )
  }
}
export default (reduxForm({
  form: "movimientoVerraco",
})(MovimientoVerraco))
import actionTypes from '../../constants/actions/foso/foso'

export function initialState () {
  return {
    showModalFoso: false,
    listFoso: [],
    filtros: [],
    dataDatosVerraco: [],
    codigoAnalisis: '',
    dataExtraccion: [],
    datosExcelFoso: [],
    datosPdfFoso: []
  }
}

export function fetchFosoSuccess (state, {listFoso}) {
  return {
    ...state,
    listFoso: listFoso
  }
}

export function fetchFiltrarFosoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function abrirModalFoso (state) {
  return {
    ...state,
    showModalFoso: true
  }
}

export function cerrarModalFoso (state) {
  return {
    ...state,
    showModalFoso: false
  }
}

export function obtenerDatosVerracoSuccess (state, {dataDatosVerraco}) {
  return {
    ...state,
    dataDatosVerraco: dataDatosVerraco
  }
}

export function obtenerCodigoAnalisisSuccess (state, {codigoAnalisis}) {
  return {
    ...state,
    codigoAnalisis: codigoAnalisis,
    dataExtraccion: []
  }
}

export function obtenerExtraccionSuccess (state, {dataExtraccion}) {
  return {
    ...state,
    dataExtraccion: dataExtraccion,
    dataDatosVerraco: [],
    codigoAnalisis: ''
  }
}

export function crearCsvFosoSuccess (state, {datosExcelFoso}) {
  return {
    ...state,
    datosExcelFoso: datosExcelFoso
  }
}

export function imprimirPdfFosoSuccess (state, {datosPdfFoso}) {
  return {
    ...state,
    datosPdfFoso: datosPdfFoso
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_FOSO_SUCCESS:
      return fetchFosoSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_FOSO_SUCCESS:
      return fetchFiltrarFosoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_FOSO:
      return abrirModalFoso(state, action)
    case actionTypes.CERRAR_MODAL_FOSO:
      return cerrarModalFoso(state, action)
    case actionTypes.OBTENER_DATOS_VERRACO_SUCCESS:
      return obtenerDatosVerracoSuccess(state, action)
    case actionTypes.OBTENER_CODIGO_ANALISIS_SUCCESS:
      return obtenerCodigoAnalisisSuccess(state, action)
    case actionTypes.OBTENER_EXTRACCION_SUCCESS:
      return obtenerExtraccionSuccess(state, action)
    case actionTypes.CREATE_CSV_FOSO_SUCCESS:
      return crearCsvFosoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_FOSO_SUCCESS:
      return imprimirPdfFosoSuccess(state, action)
    default:
      return state
  }
}
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Visita from '../../../components/planSanitario/visita/Visita'
import {fetchVisita, recuperarDatosModalVisita, duplicarDatosModalVisita, cambiarEstadoVisita, onDeleteVisita, imprimirInformeVisita, fetchFiltrarVisita
  } from '../../../actions/planSanitario/visita/visita'

export function mapStateToProps (state) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    visita: state.visita,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchVisita,
      recuperarDatosModalVisita,
      duplicarDatosModalVisita,
      cambiarEstadoVisita,
      onDeleteVisita,
      imprimirInformeVisita,
      fetchFiltrarVisita
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Visita))
import React, { Component } from 'react'
import { Tabs, Tab, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {Line} from 'react-chartjs-2';
import '../NuevoVerraco.scss'
import { required } from '../../../util/validationFunctions'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import FormSubtitle from '../../comun/form/FormSubtitle'
import InputText from '../../comun/form/InputText'
import InputSelect from '../../comun/form/InputSelect'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputTextArea from '../../comun/form/InputTextArea'
import InputNumerical from '../../comun/form/InputNumerical'
import InputCheckBox from '../../comun/form/InputCheckBox'
import { CloudUpload, Delete, Photo, PictureAsPdf, FileCopy, Print, Close } from '@material-ui/icons'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import ListadoPage from '../../../containers/page/ListadoPage'
import FiltrosEvolucionPage from '../../../containers/fichaVerraco/FiltrosEvolucionPage'
import ModalEvolucionPage from '../../../containers/fichaVerraco/ModalEvolucionPage'
import FiltrosAlimentacionPage from '../../../containers/fichaVerraco/FiltrosAlimentacionPage'
import ModalAlimentacionPage from '../../../containers/fichaVerraco/ModalAlimentacionPage'
import FiltrosIndexPage from '../../../containers/fichaVerraco/FiltrosIndexPage'
import ModalIndexPage from '../../../containers/fichaVerraco/ModalIndexPage'
import ModalMovimientoVerracoPage from '../../../containers/movimientoVerraco/ModalMovimientoVerracoPage'
import { onSubmitFail } from '../../../util/util'
import ModalVerracoCambioEstadoPage from '../../../containers/fichaVerraco/ModalVerracoCambioEstadoPage'
import {translateEstadoVerraco} from '../../../constants/estadoVerraco'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {date_formatter, number_formatter, dateTimeZones} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {modulos as modulosConstant} from '../../../constants/modulos'
import ModalHistoricoGruposPage from '../../../containers/fichaVerraco/ModalHistoricoGruposPage'
import ModalHistoricoEstadoPage from '../../../containers/fichaVerraco/ModalHistoricoEstadoPage'
import ModalHistoricoExtraccionesPage from '../../../containers/fichaVerraco/ModalHistoricoExtraccionesPage'
import ModalHistoricoIntervencionesPage from '../../../containers/fichaVerraco/ModalHistoricoIntervencionesPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import CircularProgress from '@material-ui/core/CircularProgress';

class EditarVerraco extends Component {
  constructor (props) {
    super(props)
    this.state = {
      campoCliente: false,
      hasInvalidFile: false,
      accepted: '',
      rejected: '',
      rejectedArchivos: [],
      archivos: [],
      listadoEvolucion: [],
      listadoIndex: [],
      idVerraco: '',
      hayCambioEstado: false,
      fechaErronea: false,
      listadoAlimentacion: [],
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: '',      
    }
  }
  submitEditarVerraco (values) {
    values.directorioTemporalArchivos = this.props.fichaVerraco.directorioTemporalArchivos.ultimoVerracoArchivos
    values.directorioTemporal = this.props.fichaVerraco.directorioTemporal
    values.idUsuario = this.props.auth.idUsuario
    values.imagenVerraco = this.props.fichaVerraco.imagen
    values.nombreArchivos = this.props.fichaVerraco.directorioTemporalArchivos.nombreArchivos
    values.evolucion = this.props.fichaVerraco.evolucionesAnadidas
    values.index = this.props.fichaVerraco.indexAnadidos
    const id_verraco = Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? this.props.fichaVerraco.listVerraco.verraco.idVerraco : null
    values.idVerraco = id_verraco
    values.ubicacion = this.props.fichaVerraco.ubicacion
    values.datosCambioEstado = this.props.fichaVerraco.datosCambioEstado
    values.archivos = this.props.fichaVerraco.archivo
    values.alimentacion = this.props.fichaVerraco.alimentacionAnadidas
    if (this.state.hayCambioEstado === true && this.state.fechaErronea === false) {
      this.props.actions.submitEditarVerraco(values, this.props.history)
    } else {
      window.scrollTo(0, 0)
    }
  }
  componentDidMount () {
    document.title = this.props.t('MENU.VERRACOS.VERRACOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.comboUbicacion()
    this.props.actions.comboRaza()
    this.props.actions.comboRazaActiva()
    this.props.actions.comboLineaGeneticaActiva()
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.comboSalaExtraccionActivo()
    this.props.actions.comboCliente()
    this.props.actions.comboTipoDosis()
    this.props.actions.comboCausaEliminacion()
    this.props.actions.ubicacionenesVacias(false)
    this.props.actions.fetchEditarVerraco(this.props.match.params, this.props.t)
    this.props.actions.fetchHistoricoEstadosVerraco(this.props.match.params)
    this.props.actions.fetchHistoricoGrupoVerraco(this.props.match.params)
    this.props.actions.fetchHistoricoExtracciones(this.props.match.params)
    this.props.actions.fetchHistoricoIntervenciones(this.props.match.params)
    this.props.actions.obtenerDatosPanelControl(null)
    this.props.actions.comboDireccionesClienteSinPredeterminado(null)

    if (this.props.match.params.idVerraco) {
      this.props.actions.fetchFiltrarFichaVerraco(this.props.fichaVerraco.filtros)
      this.props.actions.enviarFechaCambioEstado({
        fechaCambio: new Date,
        idVerraco: this.props.match.params.idVerraco,
        idCambioEstado: null
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.fichaVerraco.listVerraco.archivo !== prevProps.fichaVerraco.listVerraco.archivo){
      if(Object.keys(this.props.fichaVerraco.archivo).length > 0) {
        this.props.actions.subirArchivos(this.props.fichaVerraco.listVerraco.archivo)
      }
    }
    if (this.props.fichaVerraco.archivo !== prevProps.fichaVerraco.archivo){
      if(Object.keys(this.props.fichaVerraco.archivo).length > 0) {
        this.setState({archivos: this.props.fichaVerraco.archivo})
      } else {
        this.setState({archivos: []})
      }
    }
    if (this.props.fichaVerraco.listVerraco !== prevProps.fichaVerraco.listVerraco){
      if(Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        this.props.actions.cargarEvolucion(this.props.fichaVerraco.listVerraco.evolucion)
      }
    }
    if (this.props.fichaVerraco.evolucionesAnadidas !== prevProps.fichaVerraco.evolucionesAnadidas){
      if(Object.keys(this.props.fichaVerraco.evolucionesAnadidas).length > 0) {
        this.setState({listadoEvolucion: this.props.fichaVerraco.evolucionesAnadidas})
      } else {
        this.setState({listadoEvolucion: []})
      }
    }

    if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
      if (this.props.fichaVerraco.listVerraco.verraco !== prevProps.fichaVerraco.listVerraco.verraco) {
        if (this.props.fichaVerraco.listVerraco.verraco.propiedad === false) {
          this.setState({ campoCliente: true })
        } else {
          this.setState({ campoCliente: false })
        }
        if (this.props.fichaVerraco.listVerraco.verraco.idCliente !== null) {
          this.handleChangeCliente({value: this.props.fichaVerraco.listVerraco.verraco.idCliente})
          /*if (this.props.fichaVerraco.listVerraco.verraco.idDireccionCliente !== null) {
            let comboDireccionesClienteSinPredeterminado = this.props.combos.comboDireccionesClienteSinPredeterminado
            comboDireccionesClienteSinPredeterminado.forEach((direcc) => {
              if (direcc.value === this.props.fichaVerraco.listVerraco.verraco.idDireccionCliente){
                console.log(direcc)
                this.props.change('idDireccionCliente', direcc)
              }
            })
          }*/
        }
      }
    }

    if (this.props.fichaVerraco.listVerraco !== prevProps.fichaVerraco.listVerraco) {
      if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        if (this.props.fichaVerraco.listVerraco.verraco.imagenVerraco && this.props.fichaVerraco.listVerraco.verraco.imagenVerraco !== null && this.props.fichaVerraco.listVerraco.verraco.imagenVerraco !== undefined && this.props.fichaVerraco.listVerraco.verraco.imagenVerraco.file !== null && this.state.accepted === '') {
          this.setState({accepted: this.props.fichaVerraco.listVerraco.verraco.imagenVerraco.file.imagebase64})
          this.props.actions.uploadFile(this.props.fichaVerraco.listVerraco.verraco.imagenVerraco.file.imagebase64)
        } else {
          this.setState({accepted: ''})
        }
        if (this.props.fichaVerraco.listVerraco.verraco.idVerraco !== null) {
          this.setState({idVerraco: this.props.fichaVerraco.listVerraco.verraco.idVerraco})
        }
      }
    }

    if (this.props.fichaVerraco.listVerraco !== prevProps.fichaVerraco.listVerraco){
      if(Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        this.props.actions.cargarIndex(this.props.fichaVerraco.listVerraco.index)
      }
    }
    if (this.props.fichaVerraco.indexAnadidos !== prevProps.fichaVerraco.indexAnadidos){
      if(Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        this.setState({listadoIndex: this.props.fichaVerraco.indexAnadidos})
      } else {
        this.setState({listadoIndex: []})
      }
    }
    if (this.props.fichaVerraco.archivoDescarga !== prevProps.fichaVerraco.archivoDescarga) {
      if (Object.keys(this.props.fichaVerraco.archivoDescarga).length > 0) {
        if (this.props.fichaVerraco.archivoDescarga.archivo && this.props.fichaVerraco.archivoDescarga.archivo !== null){
          let archivo = this.props.fichaVerraco.archivoDescarga.archivo
          let element = document.createElement('a')
          element.setAttribute('href', encodeURIComponent(archivo))
          element.setAttribute('href', this.props.fichaVerraco.archivoDescarga.base64DataType + encodeURIComponent(archivo))
          element.setAttribute('download', this.props.fichaVerraco.archivoDescarga.nombreArchivo)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
        }
      }
    }
    if (this.props.fichaVerraco.listVerraco !== prevProps.fichaVerraco.listVerraco) {
      if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        if (Object.keys(this.props.fichaVerraco.listVerraco.cambioEstado).length > 0) {
          this.setState({hayCambioEstado: true})
        } else {
          this.setState({hayCambioEstado: false})
        }
      }
    }
    if (this.props.fichaVerraco.datosCambioEstado !== prevProps.fichaVerraco.datosCambioEstado) {
      if (Object.keys(this.props.fichaVerraco.datosCambioEstado).length > 0) {
        this.setState({hayCambioEstado: true})
        if (this.props.fichaVerraco.datosCambioEstado.nuevoEstado.value === 5 || this.props.fichaVerraco.datosCambioEstado.nuevoEstado.value === 6) {
          this.props.change('fechaEliminado', this.props.fichaVerraco.datosCambioEstado.fechaCambioEstado)
        }
      } else {
        this.setState({hayCambioEstado: false})
      }
    }
    if (this.props.formEditarVerraco != null && this.props.formEditarVerraco.values !== undefined){
      if (this.props.formEditarVerraco !== prevProps.formEditarVerraco){
        if (this.props.formEditarVerraco.values.fechaCuarentena && this.props.formEditarVerraco.values.fechaCuarentena === "error" ||
          this.props.formEditarVerraco.values.fechaEntradaGranja && this.props.formEditarVerraco.values.fechaEntradaGranja === "error" ||
          this.props.formEditarVerraco.values.fechaNacimiento && this.props.formEditarVerraco.values.fechaNacimiento === "error") {
          this.setState({fechaErronea: true})
        } else {
          this.setState({fechaErronea: false})
        }
      }
    }

    // VALOR GMD
    if (Object.keys(this.props.fichaVerraco.evolucionesAnadidas).length > 0) {
      if (this.props.fichaVerraco.evolucionesAnadidas !== prevProps.fichaVerraco.evolucionesAnadidas){
        let cont = 0
        const restarGmd = this.props.fichaVerraco.evolucionesAnadidas.map((element) => {
          cont ++
          if (!element.idEvolucionVerraco && cont >= 2){
            return true
          }
        })
        let list = restarGmd.filter(function (item) {
          if (item === true){
            return true
          }
        })
        if (list[0] === true){
          let evolucionesAnnadidas = this.props.fichaVerraco.evolucionesAnadidas
          let numeroEvolucionesAnnadidas = evolucionesAnnadidas.length
          this.props.fichaVerraco.evolucionesAnadidas.map((element, index) => {
            if (index > 0) {
              let nuevoPeso = element.pesoVerraco - evolucionesAnnadidas[index-1].pesoVerraco
              element.gmdVerraco = nuevoPeso
              if((numeroEvolucionesAnnadidas-1)==index){
                this.props.change('gmdVerraco', nuevoPeso)
              }
              return element
            }
          })
        }
      }
    }
    if (Object.keys(this.props.fichaVerraco.indexAnadidos).length > 0) {
      if (this.props.fichaVerraco.indexAnadidos !== prevProps.fichaVerraco.indexAnadidos){
        let indexAnadidos = this.props.fichaVerraco.indexAnadidos
        let numeroindexAnadidos = indexAnadidos.length
        this.props.change('index', indexAnadidos[numeroindexAnadidos -1].index)
      }
    }

    if (this.props.fichaVerraco.listVerraco !== prevProps.fichaVerraco.listVerraco){
      if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0) {
        this.props.actions.cargarAlimentacion(this.props.fichaVerraco.listVerraco.alimentacion)
      }
    }
    if (this.props.fichaVerraco.alimentacionAnadidas !== prevProps.fichaVerraco.alimentacionAnadidas){
      if (Object.keys(this.props.fichaVerraco.alimentacionAnadidas).length > 0) {
        this.setState({listadoAlimentacion: this.props.fichaVerraco.alimentacionAnadidas})
      } else {
        this.setState({listadoAlimentacion: []})
      }
    }

    if (this.props.fichaVerraco.datosImprimirDetalleVerraco !== prevProps.fichaVerraco.datosImprimirDetalleVerraco) {
      if (this.props.fichaVerraco.datosImprimirDetalleVerraco.pdf && this.props.fichaVerraco.datosImprimirDetalleVerraco.pdf !== null){
        let pdf = this.props.fichaVerraco.datosImprimirDetalleVerraco.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.VERRACOS.FICHA_VERRACOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }

    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }

    if (this.props.combos.comboDireccionesClienteSinPredeterminado !== prevProps.combos.comboDireccionesClienteSinPredeterminado) {
      if (Object.keys(this.props.combos.comboDireccionesClienteSinPredeterminado).length === 0) {
        this.props.change('idDireccionCliente', '')
      }
    }
  }

  handleChangeCodVerraco(value){
    if (value !== null) {
      if (this.props.fichaVerraco.listVerraco.verraco.idRaza !== value) {
        this.props.actions.asignarCodRaza(value.label)
      }
    }
  }
  handleChangePropiedad(value){
    if (value !== null) {
      if (!value.value) {
        this.setState({ campoCliente: true })
      } else {
        this.setState({ campoCliente: false })
       this.props.change('cliente', null)
      }
    } else {
      this.setState({ campoCliente: false })
     this.props.change('cliente', null)
    }
  }
  handleChangeCliente (value) {
    this.props.change('idDireccionCliente', '')
    if (value !== null){
      this.props.actions.comboDireccionesClienteSinPredeterminado(value.value)
      this.props.change('idDireccionCliente', '')
    } else {
      this.props.actions.comboDireccionesClienteSinPredeterminado(null)
      this.props.change('idDireccionCliente', '')
    }
  }
  handleChangeEdad(val, prevVal){
    if (this.props.fichaVerraco.listVerraco.verraco.fechaEliminado !== null) {
      const fechaHoy = moment(this.props.fichaVerraco.listVerraco.verraco.fechaEliminado)
      const fechaNac = moment(prevVal)
      const comparaFecha = fechaHoy.diff(fechaNac, 'days')
      this.props.change('edad', prevVal !== '' ? comparaFecha : '')
    } else {
      const fechaHoy = moment(new Date())
      const fechaNac = moment(prevVal)
      const comparaFecha = fechaHoy.diff(fechaNac, 'days')
      this.props.change('edad', prevVal !== '' ? comparaFecha : '')
    }
  }
  handleChangeSemanasEstancia(val, prevVal){
    if (this.props.fichaVerraco.listVerraco.verraco.fechaEliminado !== null) {
      const fechaHoy = moment(this.props.fichaVerraco.listVerraco.verraco.fechaEliminado)
      const fechaNac = moment(prevVal)
      const comparaFecha = fechaHoy.diff(fechaNac, 'weeks')
      this.props.change('semanasEstancia', prevVal !== '' ? comparaFecha : '')
    } else {
      const fechaHoy = moment(new Date())
      const fechaNac = moment(prevVal)
      const comparaFecha = fechaHoy.diff(fechaNac, 'weeks')
      this.props.change('semanasEstancia', prevVal !== '' ? comparaFecha : '')
    }
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        this.props.actions.quitarDirectorioTemporal()
        const id_verraco = Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? this.props.fichaVerraco.listVerraco.verraco.idVerraco : null
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFile(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  onDropRejected = (rejectedFiles) => {
    const file = rejectedFiles[0]
    if (file.size > 200000) {
      this.setState({rejected: rejectedFiles[0].name})
    }
  }

  eliminaImagen(){
    this.props.actions.eliminaImagen()
    this.setState({accepted: ''})
    this.props.actions.quitarDirectorioTemporal()
    this.setState({rejected: ''})
  }

  onDropArchivos(archivos) {
    console.log(archivos)
    var numFiles=archivos.length
    let archivoVerraco = {}
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        archivoVerraco = {archivo: archivo.name, content: reader.result}
        this.setState({archivos: this.state.archivos.concat(archivoVerraco)})
        this.props.actions.subirArchivos(this.state.archivos)
      }
      reader.readAsDataURL(archivo)
    })
  }

  getFileExtension(filename) {
    return filename.split('.').pop()
  }

  eliminaArchivo(archivo){
    this.props.actions.eliminarArchvio(archivo)
  }

  obtenerArchivo(archivo){
    if(archivo.content){
      let element = document.createElement('a')
      element.setAttribute('href', encodeURIComponent(archivo))
      element.setAttribute('href', archivo.content)
      element.setAttribute('download', archivo.archivo)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    else{
      this.props.actions.obtenerArchivoContent(archivo)
    }
  }

  fechaMayorCambioEstado () {
    if (Object.keys(this.props.cambioEstadoVerraco.ultimoCambioEstado).length > 0) {
      return ': ' + this.props.cambioEstadoVerraco.ultimoCambioEstado.nuevoEstado.label
    } else {
      if (Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ){
        if (Object.keys(this.props.fichaVerraco.listVerraco.cambioEstado).length > 0 ) {
          const fechaCambio = this.props.fichaVerraco.listVerraco.cambioEstado.map((estados) => estados.fechaCambio )
          let fechaMayor = fechaCambio[0]
          fechaCambio.forEach( function (fecha){
            if (fecha > fechaMayor) {
              fechaMayor = fecha
            }
          })
          for (let i = 0; i < this.props.fichaVerraco.listVerraco.cambioEstado.length; i++) {
            if (this.props.fichaVerraco.listVerraco.cambioEstado[i].fechaCambio === fechaMayor){
              return ': ' + this.props.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[this.props.fichaVerraco.listVerraco.cambioEstado[i].idEstado]}`)
            }
          }
        }
      }
    }
  }

  imprimirDetalleVerraco (values) {
    values.directorioTemporalArchivos = this.props.fichaVerraco.directorioTemporalArchivos.ultimoVerracoArchivos
    values.directorioTemporal = this.props.fichaVerraco.directorioTemporal
    values.idUsuario = this.props.auth.idUsuario
    values.imagenVerraco = this.props.fichaVerraco.imagen
    values.nombreArchivos = this.props.fichaVerraco.directorioTemporalArchivos.nombreArchivos
    values.evolucion = this.props.fichaVerraco.evolucionesAnadidas
    values.index = this.props.fichaVerraco.indexAnadidos
    const id_verraco = Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? this.props.fichaVerraco.listVerraco.verraco.idVerraco : null
    values.idVerraco = id_verraco
    values.ubicacion = this.props.fichaVerraco.ubicacion
    values.datosCambioEstado = this.props.fichaVerraco.datosCambioEstado
    values.archivos = this.props.fichaVerraco.archivo
    values.alimentacion = this.props.fichaVerraco.alimentacionAnadidas
    if (this.state.hayCambioEstado === true && this.state.fechaErronea === false) {
      this.props.actions.imprimirDetalleVerraco(values, this.state.idVerraco)
    } else {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const editEvolucion = this.props.fichaVerraco.editarEvolucion
    const dataLine = {
      labels: editEvolucion.length > 0 ? editEvolucion.map((datos) => 
        date_formatter(datos.fechaEvolucion, this.props.auth.formaFechaHora, false)) : this.state.listadoEvolucion.map((datos) => date_formatter(datos.fechaEvolucion, this.props.auth.formaFechaHora, false)),
      datasets: [
        {
          stack: 'stack1',
          label: 'Condicion Corporal',
          data: editEvolucion.length > 0 ? editEvolucion.map((datos) => datos.condicionCorporalVerraco) : this.state.listadoEvolucion.map((datos) => datos.condicionCorporalVerraco),
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,142,0,0.2)'
        },
        {
          stack: 'stack1',
          label: 'Peso',
          data: editEvolucion.length > 0 ? editEvolucion.map((datos) => datos.pesoVerraco) : this.state.listadoEvolucion.map((datos) => datos.pesoVerraco),
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,114,0,0.3)'
        },
        {
          stack: 'stack1',
          label: 'Kg pieso/día',
          data: editEvolucion.length > 0 ? editEvolucion.map((datos) => datos.kgPiensoDiaVerraco) : this.state.listadoEvolucion.map((datos) => datos.kgPiensoDiaVerraco),
          borderColor: '#555',
          borderWidth: 2,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,142,0,1)',
          backgroundColor: 'rgba(255,78,0,0.3)'
        }
      ]
    }
    const {
      t, history, handleSubmit, urlRecursos,numeroRegistrosPorPagina, paginaActual,
      auth: {centro, permisos, separadorDec, separadorMil},
      fichaVerraco: {list, directorioTemporalArchivos, dataEvolucion, dataEvolucionDuplicar, dataAlimentacion, dataAlimentacionDuplicar, alimentacionAnadidas, dataIndex, dataIndexDuplicar, editarIndex,
        editarEvolucion, listVerraco, listHistoricoEstadosVerraco, listHistoricoGrupoVerraco, checkedFiltrosEvolucion, checkedFiltrosIndex, archivo, datosCambioEstado,
        listHistoricoExtracciones, listHistoricoIntervenciones, checkedFiltrosAlimentacion, totalesHistoricoExtracciones},
      combos:{ comboRaza, comboLineaGenetica, comboOperarioPredeterminado, comboSalaExtraccionActivo, comboCliente, comboTipoDosis, comboConservacion, comboGenHalotano, comboRazaActiva, comboLineaGeneticaActiva, comboDireccionesClienteSinPredeterminado },
      actions:{fetchFichaVerraco, fetchEditarVerraco, recuperarDatosModalEvolucion, deleteEvolucion, duplicarDatosModalEvolucion, recuperarDatosModalAlimentacion, deleteAlimentacion, duplicarDatosModalAlimentacion, restartVerraco,fetchHistoricoEstadosVerraco, fetchHistoricoGrupoVerraco, fetchHistoricoExtracciones, fetchHistoricoIntervenciones, recuperarDatosModalIndex, deleteIndex, duplicarDatosModalIndex, resetUbicacion, abrirModalHistoricoGrupos, abrirModalHistoricoExtracciones, abrirModalHistoricoEstado, abrirModalHistoricoIntervenciones},
      isLoading
    } = this.props

    const comboPropiedad = [
      { value: true, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},
      { value: false, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.ALQUILADO')}
    ]

    let comboTiempoMonta = this.props.combos.comboTiempoMonta
    comboTiempoMonta = comboTiempoMonta.map((tiempoMonta) => {
      return {...tiempoMonta, label: t(tiempoMonta.label)}
    })

    let comboCausaEliminacion = this.props.combos.comboCausaEliminacion
    comboCausaEliminacion = comboCausaEliminacion.map((causaEliminacion) => {
      return {...causaEliminacion, label: t(causaEliminacion.label)}
    })

     // TABLA EVOLUCIÓN
    this.state.listadoEvolucion.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'evolucion'
    )

    const tableEvolucion = {
      id: 'evolucionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      columns: [
        {id: 'fechaEvolucion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.FECHA_EVOLUCION'), type: 'fecha'},
        {id: 'condicionCorporalVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.CONDICION_CORPORAL')},
        {id: 'pesoVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.PESO_VERRACO')},
        {id: 'kgPiensoDiaVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.KG_PIENSO_DIA')},
        {id: 'gmdVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.EVOLUCION.GMD_VERRACO')}
      ],
      rows: this.state.listadoEvolucion,
      noBlockOptions: false,
      filtros: <FiltrosEvolucionPage/>,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      onEdit: (row) => recuperarDatosModalEvolucion(row.idElemento),
      dataEvolucion: dataEvolucion,
      onDelete: (row) => deleteEvolucion(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalEvolucion(row.idElemento),
      dataEvolucionDuplicar: dataEvolucionDuplicar,
      initialValues: {}
    }
    tableEvolucion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEvolucion.initialValues['check' + row.idElemento] = checkedFiltrosEvolucion
        }
      }
    )

    this.state.listadoAlimentacion.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'alimetacion'
    )
    const tableAlimentacion = {
      id: 'alimentacionTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      columns: [
        {id: 'numeroRegistroAlimentacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.CODIGO')},
        {id: 'nombreTipoAlimentacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.TIPO_ALIMENTACION')},
        {id: 'fechaInicio', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.FECHA_INICIO'), type: 'fecha'},
        {id: 'producto', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.PRODUCTO')},
        {id: 'cantidad', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.CANTIDAD')},
        {id: 'lote', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.ALIMENTACION.LOTE')}
      ],
      rows: this.state.listadoAlimentacion,
      noBlockOptions: false,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      onEdit: (row) => recuperarDatosModalAlimentacion(row.idElemento),
      dataAlimentacion: dataAlimentacion,
      onDelete: (row) => deleteAlimentacion(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalAlimentacion(row.idElemento),
      dataAlimentacionDuplicar: dataAlimentacionDuplicar,
      initialValues: {},
      candidadRegistros: 'candidadRegistrosAlimentacion'
    }
    tableAlimentacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableAlimentacion.initialValues['check' + row.idElemento] = checkedFiltrosAlimentacion
        }
      }
    )      
    this.state.listadoIndex.forEach(
      (row, idRow) => row.idElemento = row.idElemento = idRow + 1 + 'index'
    )    
    const tableIndex = {
      id: 'indexTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      columns: [
        {id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.INDEX')},
        {id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.NIVEL_GENETICO')},
        {id: 'fechaIndex', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.FECHA'), type: 'fecha'}
      ],
      rows: this.state.listadoIndex,
      // noBlockOptions: false,
      filtros: <FiltrosIndexPage/>,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1),
      onEdit: (row) => recuperarDatosModalIndex(row.idElemento),
      dataIndex: dataIndex,
      onDelete: (row) => deleteIndex(row.idElemento),
      mostrarCambioEstado: false,
      duplicar: (row) =>duplicarDatosModalIndex(row.idElemento),
      dataIndexDuplicar: dataIndexDuplicar,
      initialValues: {},
      candidadRegistros: 'candidadRegistrosIndex',
      tablaModal: true,
      idBlockOptions: 10
    }
    tableIndex.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableIndex.initialValues['check' + row.idElemento] = checkedFiltrosIndex
        }
      }
    )

    const tableHEstado = {
      id: 'hEstadoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: true,
      columns: [
        {id: 'estadoanterior', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.ESTADO_ANTERIOR')},
        {id: 'estadoactual', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.ESTADO_ACTUAL')},
        {id: 'fechaanterior', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.FECHA_INICIO_ESTADO'), type: 'fecha'},
        {id: 'fechaactual', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.FECHA_FINAL_ESTADO'), type: 'fecha'},
        {id: 'days', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.DIAS')},
        {id: 'ObservacionesCambio', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.OBSERVACIONES')},
      ],
      rows: Object.keys(listHistoricoEstadosVerraco).length > 0 ? listHistoricoEstadosVerraco.estados : [],
      onEdit: (row) => abrirModalHistoricoEstado(row.idCambioEstadoVerraco)
    }

    const tableHGrupo = {
      id: 'hGrupoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      esEspecfica: false,
      mostrarModificar: true,
      columns: [
        {id: 'nombreGrupoVerraco', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.NOMBRE_GRUPO')},
        {id: 'fechaAsignacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.FECHA_INICIO'), type: 'fecha'},
        {id: 'fechaDesasignacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.FECHA_FIN'), type: 'fecha'},
        {id: 'descripcion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.DESCRIPCION')},
        {id: 'activo', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.ESTADO')}
      ],
      rows: listHistoricoGrupoVerraco,
      onEdit: (row) => abrirModalHistoricoGrupos(row.idGrupoVerraco)
    }

    const tableHextracciones = {
      id: 'extraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      esEspecfica: false,
      mostrarModificar: true,
      columns: this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5 ? ([
        {id: 'fechaExtraccion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.FECHA_EXTRACCION'), type: 'fecha'},
        {id: 'orden', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.ORDEN')},
        {id: 'dosisReales', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.NUM_DOSIS')},
        {id: 'volumen', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.VOL')},
        {id: 'porcentajeFa', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.POR_FORMAS_ANORMALES'), type: 'numero', numDecimales: 2},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'mp', name: this.props.t('INFORMES.EXTRACCIONES.TABLA.MP'), type: 'numero'},
        {id: 'aglutinacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.AGLUTINACION'), type: 'numero', numDecimales: 2},
        {id: 'concentracionUtil', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.CONCENTRACION_UTIL'), type: 'numero', numDecimales: 2},
        {id: 'nombreOperarioProcesado', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.OPERARIO_PROCESADO')},
        {id: 'nombreTipoDosis', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TIPO_DOSIS')},
        {id: 'eyaculadoEliminado', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.EYACULADO_ELIMINADO')},
        {id: 'nombre', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.POOL')},
        {id: 'ebv', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.INDEX')},
        {id: 'nivelgenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.NIVEL_GENETICO')},
        {id: 'observaciones', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.OBSERVACIONES')}
      ]) : ([
        {id: 'fechaExtraccion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.FECHA_EXTRACCION'), type: 'fecha'},
        {id: 'orden', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.ORDEN')},
        {id: 'dosisReales', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.NUM_DOSIS')},
        {id: 'volumen', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.VOL')},
        {id: 'porcentajeFa', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.POR_FORMAS_ANORMALES'), type: 'numero', numDecimales: 2},
        {id: this.state.idMotilidad, name: this.state.nameMotilidad, type: 'numero'},
        {id: 'aglutinacion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.AGLUTINACION'), type: 'numero', numDecimales: 2},
        {id: 'concentracionUtil', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.CONCENTRACION_UTIL'), type: 'numero', numDecimales: 2},
        {id: 'nombreOperarioProcesado', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.OPERARIO_PROCESADO')},
        {id: 'nombreTipoDosis', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TIPO_DOSIS')},
        {id: 'eyaculadoEliminado', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.EYACULADO_ELIMINADO')},
        {id: 'nombre', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.POOL')},
        {id: 'ebv', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.INDEX')},
        {id: 'nivelgenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.TABLAS.INDEX.NIVEL_GENETICO')},
        {id: 'observaciones', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.OBSERVACIONES')}
      ]),
      rows: listHistoricoExtracciones,
      onEdit: (row) => abrirModalHistoricoExtracciones(row.idAnalisis)
    }

    const tableHintervenciones = {
      id: 'intervencionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      esEspecfica: false,
      mostrarModificar: true,
      columns: [
        {id: 'numeroRegistroIntervencionSanitaria', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.CODIGO')},
        {id: 'tipoIntervencion', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.INTERVENCIONES')},
        {id: 'fechaInicio', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.FECHA_INICIO'), type: 'fecha'},
        {id: 'producto', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.PRODUCTO')},
        {id: 'lote', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.LOTE')},
        {id: 'intervalo', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.INTERVALO')},
        {id: 'numeroVerracos', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.NUM_VERRACOS')},
        {id: 'observaciones', name: t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.OBSERVACIONES')}
      ],
      rows: listHistoricoIntervenciones,
      onEdit: (row) => abrirModalHistoricoIntervenciones(row.idIntervencion)
    }
    return (
      <div>
        <SimpleModalPage/>
        <div style={{width: '91%', display: 'inline-block'}}>
          <h2 style={{display: 'inline-block'}} className="page-title">
            {t('FICHA_VERRACO.NUEVO_VERRACO.TITULO_EDITAR')}
          </h2>
          <h3 className="subtitule">
            {
              Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? t('FICHA_VERRACO.VERRACO') + ': ' + this.props.fichaVerraco.listVerraco.verraco.codVerraco : null
            }
          </h3>
        </div>
        {this.props.match.params.idVerraco && (
            <ButtonChangeRecord list={list && list.verracos ? list.verracos : []} idElemento="idVerraco" currentId={this.props.match.params.idVerraco} getNextRecord={(idVerraco) => {
              resetUbicacion()            
              fetchEditarVerraco({idVerraco})
              fetchHistoricoEstadosVerraco({idVerraco})
              fetchHistoricoGrupoVerraco({idVerraco})
              fetchHistoricoExtracciones({idVerraco})
              fetchHistoricoIntervenciones({idVerraco})
              window.location.href='/#/EditarVerraco/' + idVerraco
            }} />
          )}
        <span className="cierre-ventana-verraco"><Link to={{pathname: '/FichaVerraco', state: { from: this.props.location.pathname }}}><Close/></Link></span>
        <MensajeAlertaPage/>
        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) && (
          <ModalMovimientoVerracoPage
            mostrarComboVerraco={false}
            esInsertar={false}
            mostrarOrigenVerraco={false}
          />
        )}
        <form className="form-nuevo-verraco" onSubmit={handleSubmit(this.submitEditarVerraco.bind(this))}>
        {
        isLoading ? (          
      <div className="loading-screen">
        <CircularProgress />
      </div>    ): (
         <div>
          <Tabs defaultActiveKey={1} id="tab-ficha-verraco" className="tab-ficha-verraco">
            <Tab eventKey={1} title={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TITULO')}>
              <Row>
                <Field
                  colSm={2}
                  id="idRaza"
                  name="idRaza"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.RAZA') + '*'}
                  component={InputSelect}
                  options={comboRazaActiva}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-raza'
                  validate={required}
                  onInputChange={(value) => this.handleChangeCodVerraco(value)}
                />
                <Field
                  colSm={2}
                  id="idLinea"
                  name="idLinea"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.LINEA_GENETICA')}
                  component={InputSelect}
                  options={comboLineaGeneticaActiva}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-linea-genetica'
                />
                <Field
                  colSm={2}
                  id="codVerraco"
                  name="codVerraco"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.VERRACO') + '*'}
                  customClass='verraco'
                  validate={required}
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="crotal"
                  name="crotal"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CROTAL')}
                  customClass='crotal'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="tatuaje"
                  name="tatuaje"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TATUAJE')}
                  customClass='tatuaje'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="identificador"
                  name="identificador"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.IDENTIFICADOR')}
                  customClass='identificador'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="numero"
                  name="numero"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NUMERO')}
                  customClass='numero'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="registro"
                  name="registro"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.REGISTRO')}
                  customClass='registro'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="propiedad"
                  name="propiedad"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PROPIEDAD')}
                  component={InputSelect}
                  options={comboPropiedad}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-propiedad'
                  onInputChange={(value) => this.handleChangePropiedad(value)}
                />
                {this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
                  <Field
                    colSm={2}
                    id="idCliente"
                    name="idCliente"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CLIENTE')}
                    component={InputSelect}
                    options={comboCliente}
                    valueKey="value"
                    labelKey="label"
                    customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
                    onInputChange={(value) => this.handleChangeCliente(value)}
                  />
                )}
                {this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
                  <Field
                    colSm={2}
                    id="idDireccionCliente"
                    name="idDireccionCliente"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GRANJA')}
                    component={InputSelect}
                    options={comboDireccionesClienteSinPredeterminado}
                    valueKey="value"
                    labelKey="label"
                    customClass={this.state.campoCliente === true ? 'select-cliente ' : 'select-cliente campo-visible'}
                  />
                )}
                <Field
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_NACIMIENTO')}
                  onChange={(val, prevVal) => this.handleChangeEdad(val, prevVal)}
                />
                <Field
                  colSm={2}
                  id="edad"
                  name="edad"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EDAD')}
                  customClass='edad'
                  disabled={true}
                />
                <Field
                  colSm={2}
                  id="edadGenetica"
                  name="edadGenetica"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EDAD_GENETICA')}
                  customClass='edadGenetica'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  id="fechaCuarentena"
                  name="fechaCuarentena"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ENTRADA_CUARENTENA')}
                />
                <Field
                  id="fechaEntradaGranja"
                  name="fechaEntradaGranja"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ENTRADA_GRANJA')}
                  onChange={(val, prevVal) => this.handleChangeSemanasEstancia(val, prevVal)}
                />
                <Field
                  id="fecha1Extraccion"
                  name="fecha1Extraccion"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_PRIMERA_EXTRACCION')}
                  disabled={true}
                />
                {this.props.auth.modulos.includes(modulosConstant.BASICO) && (
                  <Col sm={2}>
                    <span
                      className={"boton-ubicacion-verraco " + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.MOVIMIENTO_VERRACOS] > 1) ? () => this.props.actions.abrirModalUbicacion() : undefined}
                    >
                      {t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.UBICACION')}
                      {
                        Object.keys(this.props.fichaVerraco.ubicacion).length > 0 ? (
                          <OverlayTrigger placement="left" overlay={<Tooltip id="ubicacionTooltip" bsStyle="link">{this.props.fichaVerraco.ubicacion.instalacion[0].label}</Tooltip>}>
                            <span>{': ' + this.props.fichaVerraco.ubicacion.instalacion[0].label}</span>
                          </OverlayTrigger>
                        ) : (
                          Object.keys(this.props.fichaVerraco.listVerraco).length > 0 ? (
                            this.props.fichaVerraco.listVerraco.ubicacion !== null ? (
                              this.props.fichaVerraco.listVerraco.ubicacion.nombreInstalacion !== null && this.props.fichaVerraco.listVerraco.ubicacion.nombreInstalacion !== undefined ? (
                                <OverlayTrigger placement="left" overlay={<Tooltip id="ubicacionTooltipo" bsStyle="link">{this.props.fichaVerraco.listVerraco.ubicacion.nombreInstalacion}</Tooltip>}>
                                  <span>{': ' + this.props.fichaVerraco.listVerraco.ubicacion.nombreInstalacion}</span>
                                </OverlayTrigger>
                              ) : null
                            ) : null
                          ) : null
                        )
                      }
                    </span>
                  </Col>
                )}
                <Col sm={2}>
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) && (
                    <ModalVerracoCambioEstadoPage
                      esInsertar={false}
                      mostrarComboVerraco={false}
                    />
                  )}
                  <span
                    id="cambioEstadoVerraco" className={"boton-cambio-estado-verraco" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) ? () => this.props.actions.abrirModalVerracoCambioEstado(this.state.idVerraco) : undefined}
                  >
                    {t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO') + '*'}
                    {
                      Object.keys(datosCambioEstado).length > 0 ? (
                        datosCambioEstado.nuevoEstado !== null && datosCambioEstado.nuevoEstado !== undefined ? (
                          ': ' + datosCambioEstado.nuevoEstado.label
                        ) : null
                      ) : this.fechaMayorCambioEstado()
                    }
                  </span>
                </Col>
                <Field
                  colSm={2}
                  id="semanasEstancia"
                  name="semanasEstancia"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.SEMANAS_ESTANCIA')}
                  customClass='semanasEstancia'
                  disabled={true}
                />
                <Field
                  id="fechaEliminado"
                  name="fechaEliminado"
                  component={InputDatePicker}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FECHA_ELIMINACION')}
                  disabled={true}
                />
                <Field
                  colSm={2}
                  id="granjaOrigen"
                  name="granjaOrigen"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GRANJA_ORIGEN')}
                  customClass='granjaOrigen'
                  maxLength={true}
                  valorMaxLength={50}
                />
                <Field
                  colSm={2}
                  id="padre"
                  name="padre"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.PADRE')}
                  customClass='padre'
                  maxLength={true}
                  valorMaxLength={50}
                />
                <Field
                  colSm={2}
                  id="madre"
                  name="madre"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.MADRE')}
                  customClass='madre'
                  maxLength={true}
                  valorMaxLength={50}
                />
                {(this.props.auth.customizations && this.props.auth.customizations.find(customizacion => customizacion ==='AbuelosBagmatic')) &&
                <Field
                  colSm={2}
                  id="abuelo"
                  name="abuelo"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ABUELO')}
                  customClass='abuelo'
                  maxLength={true}
                  valorMaxLength={50}
                />
                }
                <Field
                  colSm={2}
                  id="numMachosLote"
                  name="numMachosLote"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NUM_MACHOS_LOTE')}
                  customClass='numMachosLote'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="idLoteEntrada"
                  name="idLoteEntrada"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ID_LOTE_ENTRADA')}
                  customClass='idLoteEntrada'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="idGuiaEntrada"
                  name="idGuiaEntrada"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ID_GUIA_ENTRADA')}
                  customClass='idGuiaEntrada'
                  maxLength={true}
                  valorMaxLength={20}
                />
                <Field
                  colSm={2}
                  id="registroLote"
                  name="registroLote"
                  component={InputText}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.REGISTRO_LOTE')}
                  customClass='registroLote'
                  maxLength={true}
                  valorMaxLength={20}
                />
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Field
                    id="fechaFinIntervencionSanitaria"
                    name="fechaFinIntervencionSanitaria"
                    component={InputDatePicker}
                    colSm={2}
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.FIN_INTERVENCION_SANITARIA')}
                    disabled={true}
                  />
                )}
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Field
                    id="noMandarMatadero"
                    name="noMandarMatadero"
                    colSm={2}
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label no-matadero-check"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.NO_MANDAR_MATADERO')}
                    disabled={true}
                  />
                )}
                {/*
                <Field
                  colSm={2}
                  id="idOperarioColecta"
                  name="idOperarioColecta"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.OPERARIO_COLECTA_PREFERIDO')}
                  component={InputSelect}
                  options={comboOperarioPredeterminado}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-operario-colecta-preferido'
                />*/}
                <Field
                  colSm={2}
                  id="idTipoDosis"
                  name="idTipoDosis"
                  component={InputSelect}
                  options={comboTipoDosis}
                  valueKey="value"
                  labelKey="label"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TIPO_DOSIS_PREFERENTE')}
                  customClass='tipo-de-dosis-preferente'
                />
                <Field
                  colSm={2}
                  id="idSalaExtraccion"
                  name="idSalaExtraccion"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.SALA_EXTRACCION_PREFERENTE')}
                  component={InputSelect}
                  options={comboSalaExtraccionActivo}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-sala-extraccion-preferente'
                />
                <Field
                  colSm={2}
                  id="tiempoMonta"
                  name="tiempoMonta"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.TIEMPO_MONTA')}
                  component={InputSelect}
                  options={comboTiempoMonta}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-tiempo-monta'
                />
                <Field
                  colSm={2}
                  id="conservacion"
                  name="conservacion"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CONSERVACION')}
                  component={InputSelect}
                  options={comboConservacion}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-conservacion'
                />
                <Field
                  id="vigilado"
                  name="vigilado"
                  colSm={2}
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('FICHA_VERRACO.VIGILADO')}
                />
                <Field
                  id="planning"
                  name="planning"
                  colSm={2}
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label checkbox-inline-activo"
                  controlLabel={t('FICHA_VERRACO.PLANNING')}                
                />
                <div className="clearfix"></div>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    colSm={6}
                    id="descripcionVigilado"
                    name="descripcionVigilado"
                    component={InputTextArea}
                    componentClass="textarea"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.DESCRIPCION_VIGILADO')}
                    customClass='observaciones-verraco'
                  />
                  <Field
                    colSm={6}
                    id="observacionesVerraco"
                    name="observacionesVerraco"
                    component={InputTextArea}
                    componentClass="textarea"
                    controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.OBSERVACIONES')}
                    customClass='observaciones-verraco'
                  />
                </Col>
                <Col sm={6}>
                  <Col sm={3}>
                    <div className="imagenVerraco">
                    <div className="content-dropzone">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected}) }}
                        maxSize={2000000}
                        onDropRejected={this.onDropRejected}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}  className="dropzone">
                            <input {...getInputProps()} />
                            <CloudUpload/>
                          </div>
                        )}
                      </Dropzone>
                      {
                        (this.state.accepted !== '' && this.state.accepted !== undefined) ? (
                          <div className="eliminar-imagen-verraco" onClick={this.eliminaImagen.bind(this)}>
                            <Delete/>
                          </div>
                        ) : null
                      }
                      <div className="imagen-perfil-verraco">
                      {
                        this.state.accepted !== '' && this.state.accepted !== undefined ? (
                          <img src={this.state.accepted} />
                        ) : (
                          <img src={urlRecursos + 'imagen-perfl-cerdo.jpg'} />
                        )
                      }
                      </div>
                      <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>Imagen desamiado grande</p>
                    </div>
                  </div>
                  </Col>
                  <Col sm={6}>
                    <div className="archivosVerraco">
                      {t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ARCHIVOS_VERRACO')}
                      <Dropzone
                        // accept="image/jpeg, image/png"
                        onDrop={this.onDropArchivos.bind(this)}
                        uploadMultiple={true}
                        maxFiles={null}
                        // maxSize={200000}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}  className="custom-file-label">
                            <input {...getInputProps()} />
                            <label><span className="explorar-input-file">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EXPLORAR')}</span></label>
                          </div>
                        )}
                      </Dropzone>
                      <ul>
                        {
                          Object.keys(this.state.archivos).length > 0 && (
                            this.state.archivos.map((item, idx) =>{
                              return (
                                <li key={idx}>
                                  <span className="descargar-adjunto-verraco" onClick={() => this.obtenerArchivo(item)}>
                                    {
                                      this.getFileExtension(item.archivo) === 'jpg' || this.getFileExtension(item.archivo) === 'gif' || this.getFileExtension(item.archivo) === 'png' ||
                                      this.getFileExtension(item.archivo) === 'JPG' || this.getFileExtension(item.archivo) === 'GIF' || this.getFileExtension(item.archivo) === 'PNG' ? (
                                        <Photo/>
                                      ) : (
                                            this.getFileExtension(item.archivo) === 'pdf' ? (
                                              <PictureAsPdf/>
                                            ) : (<FileCopy/>)
                                          )
                                    }
                                    <p>{item.archivo}</p>
                                  </span>
                                  <span className="borrar" onClick={() => this.eliminaArchivo(item.archivo)}><Delete/></span>
                                </li>
                              )
                            })
                          )
                        }
                      </ul>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={2} title={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.TITULO')}>
              <Row className="parte-parametros">
                <Field
                  id="grasaDorsal"
                  name="grasaDorsal"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GRASA_DORSAL')}
                />
                <Field
                  id="indiceConversion"
                  name="indiceConversion"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDICE_CONVERSION')}
                />
                <Field
                  id="gmdVerraco"
                  name="gmdVerraco"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GMD')}
                  numDecimales={2}
                />
                <Field
                  id="porMagro"
                  name="porMagro"
                  component={InputNumerical}
                  colSm={1}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.POR_MAGRO')}
                />
                <Field
                  id="genHalotano"
                  name="genHalotano"
                  component={InputSelect}
                  options={comboGenHalotano}
                  colSm={1}
                  valueKey="value"
                  labelKey="label"
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.GEN_HALOTANO')}
                />
                <Field
                  id="indexActual"
                  name="indexActual"
                  component={InputText}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX_ACTUAL')}
                  disabled={true}
                />
                <Field
                  id="nivelGeneticoActual"
                  name="nivelGeneticoActual"
                  component={InputText}
                  colSm={2}
                  controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.NIVEL_GENETICO_ACTUAL')}
                  disabled={true}
                />
              </Row>
              <div className="clearfix"></div>
              <ModalIndexPage />
              <FormSubtitle>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.TITLE')}</FormSubtitle>
              <ListadoPage noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX." table={tableIndex}>
              </ListadoPage>
              <div className="clearfix"></div>
              {/*
              <ModalEvolucionPage />
              <FormSubtitle>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION.TITLE')}</FormSubtitle>
              <ListadoPage noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.EVOLUCION." table={tableEvolucion}>
              </ListadoPage>
              <div className="clearfix"></div>
              {
                this.state.listadoEvolucion.length > 0 ? (
                  <Line data={dataLine} height={50} />
                ) : null*/
              }
              {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && [
                <ModalAlimentacionPage key="ModalAlimentacionPage" />,
                <FormSubtitle key="FormSubtitle">{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.TITLE')}</FormSubtitle>,
                <ListadoPage key="ListadoPage" noTitle={true} t_key="FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION." table={tableAlimentacion}>
                </ListadoPage>
              ]}
              <div className="clearfix"></div>
            </Tab>
            <Tab eventKey={3} title={t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TITULO')}>
              <ListadoPage noTitle={true} table={tableHextracciones}>
              </ListadoPage>
              <ModalHistoricoExtraccionesPage/>
              <Row className="tabla-totales-hist-extracciones" style={{marginTop: '20px'}}>
                <Col sm={2} className="totales">{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TOTALES.TOTALES_EXTRACCIONES')}</Col>
                <Col sm={2} className="totales">{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TOTALES.TOTAL_DOSIS')}</Col>
                <Col sm={2} className="totales">{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TOTALES.MEDIA_DOSIS')}</Col>
                <Col sm={2} className="totales">{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TOTALES.MEDIA_FA') + '%'}</Col>
                <Col sm={2} className="totales">{'% ' + this.state.nameMotilidad}</Col>
                <Col sm={2} className="totales">{t('FICHA_VERRACO.NUEVO_VERRACO.H_EXTRACCIONES.TOTALES.MEDIA_CONCENTRACION')}</Col>
              </Row>
              <Row className="tabla-totales-hist-extracciones" style={{marginBottom: '20px'}}>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.totalExtracciones}</Col>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.totalDosis}</Col>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.mediaDosis !== undefined ? number_formatter(totalesHistoricoExtracciones.mediaDosis, 2, separadorDec, separadorMil) : null}</Col>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.mediaFA !== undefined ? number_formatter(totalesHistoricoExtracciones.mediaFA, 2, separadorDec, separadorMil) + '%' : null}</Col>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.mediaMotilidad !== undefined ? number_formatter(totalesHistoricoExtracciones.mediaMotilidad, 2, separadorDec, separadorMil) : null}</Col>
                <Col sm={2} className="totales-valores">{totalesHistoricoExtracciones.mediaConcentracion !== undefined ? number_formatter(totalesHistoricoExtracciones.mediaConcentracion, 2, separadorDec, separadorMil) : null}</Col>
              </Row>
            </Tab>
            <Tab eventKey={4} title={t('FICHA_VERRACO.NUEVO_VERRACO.H_ESTADOS.TITULO')}>
              <ListadoPage table={tableHEstado} noTitle={true}>
              </ListadoPage>
              <ModalHistoricoEstadoPage/>
            </Tab>
            <Tab eventKey={5} title={t('FICHA_VERRACO.NUEVO_VERRACO.H_GRUPOS.TITULO')}>
              <ListadoPage table={tableHGrupo} noTitle={true}>
              </ListadoPage>
              <ModalHistoricoGruposPage />
            </Tab>
            <Tab eventKey={6} title={t('FICHA_VERRACO.NUEVO_VERRACO.H_INTERVENCIONES.TITULO')}>
              <ListadoPage noTitle={true} table={tableHintervenciones}>
              </ListadoPage>
              <ModalHistoricoIntervencionesPage/>
            </Tab>
          </Tabs>
          <div className="clearfix"></div>
          <div className="botones-pie-form">
            <button type="button" className="btn btn-primary" onClick={handleSubmit(this.imprimirDetalleVerraco.bind(this))}>
              <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
            </button>
            <Button
              type="submit"
              className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] < 2) ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] < 2)}
            >{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white">
              <Link to={{pathname: '/FichaVerraco', state: { from: this.props.location.pathname }}} style={{color: '#888', textDecoration: 'none'}}>{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.CANCELAR')}</Link>
            </Button>
          </div>
          </div>
          )}
        </form>
       
       
      </div>
    )
  }
}

export default (reduxForm({
  form: 'editarVerraco',
  onSubmitFail
})(EditarVerraco))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosRegistroIntervencionSanitaria from '../../../components/planSanitario/registroIntervencionSanitaria/FiltrosRegistroIntervencionSanitaria'
import {openModal} from '../../../actions/common'
import {abrirModalVacioRegistroIntervencionSanitaria, seleccionarTodosRegistroIntervencionSanitaria, deSeleccionarTodosRegistroIntervencionSanitaria, cambiarEstadoSeleccionRegistroIntervencionSanitaria,
  deleteSeleccionRegistroIntervencionSanitaria, fetchFiltrarRegistroIntervencionSanitaria, crearCsvRegistroIntervencion, imprimirPdfRegistroIntervencion
} from '../../../actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'
import {comboVerraco, comboRaza, comboLineaGenetica, comboNombreGrupoVerraco, comboUbicacionPadre} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.registroIntervencionSanitaria,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioRegistroIntervencionSanitaria,
      seleccionarTodosRegistroIntervencionSanitaria,
      deSeleccionarTodosRegistroIntervencionSanitaria,
      cambiarEstadoSeleccionRegistroIntervencionSanitaria,
      deleteSeleccionRegistroIntervencionSanitaria,
      fetchFiltrarRegistroIntervencionSanitaria,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      comboNombreGrupoVerraco,
      comboUbicacionPadre,
      crearCsvRegistroIntervencion,
      imprimirPdfRegistroIntervencion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosRegistroIntervencionSanitaria))
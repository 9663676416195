import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ModalConductor from '../../components/transporte/ModalConductor'
import {closeModalConductor, submitNuevoConductor, editConductorAnadido, guardarYNuevoConductor} from '../../actions/transporte/transporte'

export function mapStateToProps (state) {
  return {
    transporte: {...state.transporte},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalConductor,
      submitNuevoConductor,
      editConductorAnadido,
      guardarYNuevoConductor
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor)

import React, { Component } from 'react'
import SimplePage from '../../containers/page/SimplePage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'

class Listado extends Component {
  render () {
    const { t_key, table, noTitle } = this.props
    return (
      <SimplePage t_key={t_key} noTitle={noTitle}>
        <SimpleTablePage {...table} ></SimpleTablePage>
        {this.props.children}
      </SimplePage>
    )
  }
}

export default Listado

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import RegistroAlimentacion from '../../../components/planSanitario/registroAlimentacion/RegistroAlimentacion'
import {fetchRegistroAlimentacion, recuperarDatosModalRegistroAlimentacion, duplicarDatosModalRegistroAlimentacion, cambiarEstadoRegistroAlimentacion, onDeleteRegistroAlimentacion, fetchFiltrarRegistroAlimentacion } from '../../../actions/planSanitario/registroAlimentacion/registroAlimentacion'

export function mapStateToProps (state) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    registroAlimentacion: state.registroAlimentacion,
    showModal: state.common.showModal,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRegistroAlimentacion,
      recuperarDatosModalRegistroAlimentacion,
      duplicarDatosModalRegistroAlimentacion,
      cambiarEstadoRegistroAlimentacion,
      onDeleteRegistroAlimentacion,
      fetchFiltrarRegistroAlimentacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistroAlimentacion))
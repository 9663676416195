import React, {Component} from 'react'
import {Col, FormControl, FormGroup, ControlLabel} from 'react-bootstrap'
// import { Error, Warning } from '@material-ui/icons'
import { Warning } from '@material-ui/icons'
import './InputText.scss'

/*
  Se ha comentando la funcionalidad de mostrar las advertencias.
 */
class InputText extends Component {
  constructor () {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange (event) {
    let value = event.target.value
    this.props.input.onChange(event)
    if (this.props.onInputChange) {
      setTimeout((() => {
        this.props.onInputChange(value)
      }).bind(this), 200)
    }
  }

  handleBlur (value) {
    this.props.input.onBlur(value)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(value)
    }
  }

  /*UNSAFE_componentWillMount () {
    if (!this.props.input.value) {
      this.props.input.onChange(null)
    }
  }*/

  render () {
    const {
      t,
      id,
      colSm,
      customClass,
      disabled,
      placeholder,
      componentClass,
      controlLabel,
      title,
      input,
      maxLength,
      valorMaxLength,
      subTextoMax,
      autoComplete = "on",
      meta: { touched, error/*, warning*/ }
    } = this.props
    const errorMessage = (touched && error) ? error : null
    // const warningMessage = (touched && warning) ? warning : null
    // const successMessage = (touched && !errorMessage && !warningMessage) ? true : null
    return (
    <Col sm={colSm} className={customClass}>
      {/*<FormGroup validationState={errorMessage ? 'error' : warningMessage ? 'warning' : successMessage ? 'success' : null}>*/}
      <FormGroup validationState={errorMessage ? 'error' : null}>
        {controlLabel && <ControlLabel>{controlLabel}{errorMessage && <span className='help-block'><Warning /></span>}</ControlLabel>}
        <FormControl
          id={id}
          disabled={disabled}
          type="text"
          placeholder={placeholder}
          title={controlLabel || title}
          componentClass={componentClass}
          value={input.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          maxLength={maxLength === true ? valorMaxLength : ''}
          autoComplete={autoComplete}
        />
        {
          /*
          errorMessage ?
            <span className='help-block'><Error /><span>{errorMessage}</span></span>
          : warningMessage &&
            <span className='help-block'><Warning /><span>{warningMessage}</span></span>
          */
          subTextoMax === true && (
            <span style={{color: '#c1c1c1', fontStyle: 'italic', fontSize: '10px'}}>{t('COMUN.LONGITUD_MAX')}</span>
          )
        }
      </FormGroup>
    </Col>
    )
  }
}

export default InputText

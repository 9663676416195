export default {
  FETCH_VISITA: 'FETCH_VISITA',
  FETCH_VISITA_SUCCESS: 'FETCH_VISITA_SUCCESS',
  ABRIR_MODAL_VACIO_VISITA: 'ABRIR_MODAL_VACIO_VISITA',
  SELECCIONAR_TODOS_VISITA: 'SELECCIONAR_TODOS_VISITA',
  DESELECCIONAR_TODOS_VISITA: 'DESELECCIONAR_TODOS_VISITA',
  SUBMIT_NUEVO_VISITA: 'SUBMIT_NUEVO_VISITA',
  SUBMIT_NUEVO_VISITA_SUCCESS: 'SUBMIT_NUEVO_VISITA_SUCCESS',
  RECUPERAR_DATOS_MODAL_VISITA: 'RECUPERAR_DATOS_MODAL_VISITA',
  RECUPERAR_DATOS_MODAL_VISITA_SUCCESS: 'RECUPERAR_DATOS_MODAL_VISITA_SUCCESS',
  EDITAR_VISITA: 'EDITAR_VISITA',
  DUPLICAR_DATOS_MODAL_VISITA: 'DUPLICAR_DATOS_MODAL_VISITA',
  DUPLICAR_DATOS_MODAL_VISITA_SUCCESS: 'DUPLICAR_DATOS_MODAL_VISITA_SUCCESS',
  CAMBIAR_ESTADO_VISITA: 'CAMBIAR_ESTADO_VISITA',
  ON_DELETE_VISITA: 'ON_DELETE_VISITA',
  CAMBIAR_ESTADO_SELECCION_VISITA: 'CAMBIAR_ESTADO_SELECCION_VISITA',
  DELETE_SELECCION_VISITA: 'DELETE_SELECCION_VISITA',
  GUARDAR_Y_NUEVO_VISITA: 'GUARDAR_Y_NUEVO_VISITA',
  FETCH_FILTRAR_VISITA: 'FETCH_FILTRAR_VISITA',
  FETCH_FILTRAR_VISITA_SUCCESS: 'FETCH_FILTRAR_VISITA_SUCCESS',
  IMPRIMIR_INFORME_VISITA: 'IMPRIMIR_INFORME_VISITA',
  IMPRIMIR_INFORME_VISITA_SUCCESS: 'IMPRIMIR_INFORME_VISITA_SUCCESS',
  CREATE_CSV_VISITA_SUCCESS: 'CREATE_CSV_VISITA_SUCCESS',
  IMPRIMIR_PDF_VISITA_SUCCESS: 'IMPRIMIR_PDF_VISITA_SUCCESS',
  CREATE_CSV_VISITA: 'CREATE_CSV_VISITA',
  IMPRIMIR_PDF_VISITA: 'IMPRIMIR_PDF_VISITA',
  RESTART_VISITAS: 'RESTART_VISITAS',
  CERRAR_MODAL_VISITAS: 'CERRAR_MODAL_VISITAS',
  ABRIR_MODAL_VISITAS: 'ABRIR_MODAL_VISITAS'
}
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'

class FiltrosAnalisisExtracciones extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: [],
      quitarFiltroFechas: false
    }
    this.fetchAnalisisExtracciones = this.fetchAnalisisExtracciones.bind(this)
    this.crearCsvAnalisisExtracciones = this.crearCsvAnalisisExtracciones.bind(this)
    this.imprimirPdfAnalisisExtracciones = this.imprimirPdfAnalisisExtracciones.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.fetchComboPool()
    this.props.actions.comboRaza()
    this.props.actions.comboClienteConVerracosEnPropiedad()
    this.props.actions.comboLineaGenetica() 
    this.props.actions.comboNivelGenetico()   
    if (this.props.auth.customizations && this.props.auth.customizations.includes("DataSwine")) {
      this.props.actions.comprobarEntornoLocal()
    }    
    
    let filtros = {};
    // si no hay ningun filtro, ponemos por defecto el de fecha
    if (!this.props.analisisExtracciones.filtros || Object.keys(this.props.analisisExtracciones.filtros).length === 0) {
      filtros = { desde: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora) }
    } else {
      filtros = this.props.analisisExtracciones.filtros;
    }    
    this.props.initialize(filtros);    
    this.fetchAnalisisExtracciones(filtros);
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosAnalisisExtracciones()
          }
        }
      }
    }
    if (this.props.analisisExtracciones.filtros !== prevProps.analisisExtracciones.filtros) {    
      this.setState({quitarFiltroFechas: false})
    }
  }

  fetchAnalisisExtracciones(values){
    this.props.actions.fetchAnalisisExtracciones(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosAnalisisExtracciones()
    } else {
      this.props.actions.deseleccionarTodosAnalisisExtracciones()
    }
  }

  cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones(){
    const itemsSeleccion = this.props.analisisExtracciones.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones(list)
  }

  cambiarVigiladoSeleccionAnalisisExtracciones(){
    const itemsSeleccion = this.props.analisisExtracciones.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          'idVerraco': row.idVerraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarVigiladoSeleccionAnalisisExtracciones(list)
  }

  deleteSeleccionAnalisisExtracciones(){
    const itemsSeleccion = this.props.analisisExtracciones.list.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionAnalisisExtracciones(list)
  }

  crearCsvAnalisisExtracciones(){
    this.props.actions.crearCsvAnalisisExtracciones(this.props.analisisExtracciones.filtros)
  }

  imprimirPdfAnalisisExtracciones(){
    this.props.actions.imprimirPdfAnalisisExtracciones(this.props.analisisExtracciones.filtros)
  }

  calcularDiasDescanso(){
    this.props.actions.calcularDiasDescanso()
  }

  sincronizarPendientesDataSwine(){
    this.props.actions.sincronizarPendientesDataSwine()
  }

  quitarFiltros (value) {    
    let values = this.props.analisisExtracciones.filtros    
    if (value == 'desde' || value == 'hasta') {        
      let ultimoMes = new Date()      
      ultimoMes.setMonth(ultimoMes.getMonth() - 1)
      values['desde'] = date_parser(ultimoMes, this.props.auth.formaFechaHora)
      values['hasta'] = null     
      this.props.actions.fetchAnalisisExtracciones(values)
      this.props.change('desde', ultimoMes)  
      this.props.change('hasta', null)     
      this.setState({quitarFiltroFechas: true})
    } else {
      values[value] = null
      this.props.actions.fetchAnalisisExtracciones(values)
      this.props.change(value, null)
    }     
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, auth: { permisos }, analisisExtracciones: {filtros = {}},
    combos: {
      comboVerraco, comboPool, comboNombreGrupoVerraco, comboRaza, comboClienteConVerracosEnPropiedad, comboLineaGenetica, comboNivelGenetico
    }
  } = this.props
  const tKey = 'ANALISIS_EXTRACCIONES.'

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t(tKey + 'FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Field
              colSm={2}
              id="pool"
              name="pool"
              controlLabel={t(tKey + 'FILTROS.POOL')}
              component={InputSelect}
              options={comboPool}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t(tKey + 'FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t(tKey + 'FILTROS.LINEA_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Field
                colSm={2}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('PLANING.FILTROS.NIVEL_GENETICO')}
                component={InputSelect}
                options={comboNivelGenetico}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
              />
            <Field
              colSm={2}
              id="propiedad"
              name="propiedad"
              controlLabel={t(tKey + 'FILTROS.PROPIEDAD')}
              component={InputSelect}
              options={comboClienteConVerracosEnPropiedad}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchAnalisisExtracciones)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.verraco && <span key="filtros.verraco" className="badge-dark">{t(tKey + 'FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>),
                    (filtros.pool && <span key="filtros.pool"  className="badge-dark">{t(tKey + 'FILTROS.POOL') + ': ' + filtros.pool.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('pool')}> x </button></span>),
                    (filtros.grupoVerraco && <span key="filtros.grupoVerraco"  className="badge-dark">{t(tKey + 'FILTROS.GRUPO_VERRACO') + ': ' + filtros.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>),
                    (filtros.raza && <span key="filtros.raza"  className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>),
                    (filtros.lineaGenetica && <span key="filtros.lineaGenetica"  className="badge-dark">{t(tKey + 'FILTROS.LINEA_GENETICA') + ': ' + filtros.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>),
                    (filtros.nivelGenetico && <span key="filtros.nivelGenetico"  className="badge-dark">{t(tKey + 'FILTROS.NIVEL_GENETICO') + ': ' + filtros.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>),
                    (filtros.propiedad && <span key="filtros.propiedad"  className="badge-dark">{t(tKey + 'FILTROS.PROPIEDAD') + ': ' + filtros.propiedad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('propiedad')}> x </button></span>),
                    (filtros.desde && filtros.hasta && filtros.desde !== null && filtros.hasta !== null) ? (
                      <span key="filtros.desdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtros.desde && filtros.desde !== null) ? (
                      <span key="filtros.desde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtros.hasta && filtros.hasta !== null) && (
                      <span key="filtros.hasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    )                    
                  ]
                }
                {(this.state.quitarFiltroFechas === true && <span> {t(tKey + 'FILTROS.AVISO_FILTROS_CERRADOS')}</span>)}
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ANALISIS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones()}>
                    <Refresh></Refresh> {t(tKey + 'FILTROS.CAMBIAR_EYACULADO_ELIMINADO')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.cambiarVigiladoSeleccionAnalisisExtracciones()}>
                    <Refresh></Refresh> {t(tKey + 'FILTROS.CAMBIAR_VIGILADO')}
                  </button>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionAnalisisExtracciones()}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvAnalisisExtracciones()}>
                  <Launch></Launch> {t(tKey + 'FILTROS.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfAnalisisExtracciones()}>
                  <Print></Print> {t(tKey + 'FILTROS.IMPRIMIR')}
                </button>
                {this.props.auth.customizations && this.props.auth.customizations.includes("Magapor") ? (
                  <button type="button" className="btn btn-icono" onClick={(e) => this.calcularDiasDescanso()}>
                    {t(tKey + 'FILTROS.DIAS_DESCANSO')}
                  </button>
                ) : null} 
                {this.props.auth.customizations && this.props.auth.customizations.includes("DataSwine") ? (
                  <button type="button" className="btn btn-icono" onClick={(e) => this.sincronizarPendientesDataSwine()} title={this.props.auth.isLocal ? "Prod - https://api-ssms-orders-bretanha-prd-dot-bretanha-ssms.rj.r.appspot.com" : "Test - https://api-ssms-orders-master-dot-bretanha-ssms.rj.r.appspot.com"}>
                    {t(tKey + 'FILTROS.SINCRONIZAR_PENDIENTES')}
                  </button>
                ) : null}
                {(permisos === '-1' || permisos[permisosConstant.ANALISIS] > 1) ? (
                  <Link to="/NuevoAnalisisExtraccion" className="btn btn-primary nuevo" style={{padding: '1px 6px', verticalAlign: 'inherit'}}>
                    <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary nuevo disabled"
                    disabled={true}
                  >{t(tKey + 'FILTROS.NUEVO')}</button>
                )}
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosAnalisisExtracciones',
})(FiltrosAnalisisExtracciones))

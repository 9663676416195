import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Col, ControlLabel, FormGroup } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Warning } from '@material-ui/icons'
import {date_formatter, date_parser, getMomentFormat, date_formatter_tabla, dateTimeZones} from '../../../util/formatFunctions'
import 'react-day-picker/lib/style.css'
import './InputRangeDatePicker.scss'
import { formatDate, parseDate } from "react-day-picker/moment";

class InputDatePicker extends Component {
  constructor(props) {
    super()
    let userFormatValue = props.input.value
    if (userFormatValue !== '') {
      //console.log(userFormatValue) //"2022-01-18"
      if (!(userFormatValue instanceof Date)) {
        userFormatValue = new Date(userFormatValue+'T10:00:00Z')
      }
      //console.log(userFormatValue)
      userFormatValue = date_formatter_tabla(userFormatValue, props.auth.formaFechaHora, false)
    }
    this.state = {
      valor: userFormatValue,
      error: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleChange(value) {
    if (value && !isNaN(value)){
      let userFormatValue = date_formatter_tabla(value, this.props.auth.formaFechaHora, false)
      this.setState({error: false, valor: userFormatValue})

      let standartFormatValue = date_parser(value, this.props.auth.formaFechaHora)
      this.props.input.onChange(standartFormatValue)
      if (this.props.onInputChange) {
        setTimeout((function() {
          this.props.onInputChange(standartFormatValue)
        }).bind(this), 200)
      }
    } else {
      this.setState({error: true, valor: ''}, () => {
        this.props.input.onChange('')
        if (this.props.onInputChange) {
          setTimeout((function() {
            this.props.onInputChange('')
          }).bind(this), 200)
        }
      })
    }
  }

  handleBlur () {
    // this.props.input.onBlur(event) // No llamar a input.onBlur de form-redux debido a que produce un comportamiento no controlado.
    if (this.props.onInputBlur) {
      this.props.onInputBlur(this.props.input.value)
    }
  }

  handleClick (e) {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.valor !== prevState.valor) {
      let standartFormatValue = this.state.valor
      if (standartFormatValue) {
        standartFormatValue = date_parser(this.state.valor, this.props.auth.formaFechaHora)
      }
      this.props.input.onChange(standartFormatValue)
    } else if (this.props.input.value !== prevProps.input.value) {
      let userFormatValue = this.props.input.value
      if (userFormatValue) {
        userFormatValue = date_formatter(this.props.input.value, this.props.auth.formaFechaHora, false)
      }
      this.setState({valor: userFormatValue, error: false})
    }
  }

  clearValorInput () {
    this.setState({valor: ''})
    this.handleChange('')
  }

  render() {
    const {
      id, colSm, colSmOffset, controlLabel, t, fechaPrincipio, fechaFin, customClass = '', esFiltro, inline, onClick, isClearable = true,
      meta: { touched, error }, disabled = false,
      auth: {formaFechaHora, lunesInicioSemana, idioma}
    } = this.props
    const errorMessage = (touched && error) ? error : null
    const weekdays_long = [
      t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_LONG.LUNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MARTES'),
      t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_LONG.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_LONG.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_LONG.SABADO'),
    ];
    const weekdays_short = [
      t('COMUN.DATE.WEEKDAYS_SHORT.DOMINGO'),
      t('COMUN.DATE.WEEKDAYS_SHORT.LUNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MARTES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.MIERCOLES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.JUEVES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.VIERNES'),
      t('COMUN.DATE.WEEKDAYS_SHORT.SABADO'),
    ];
    const months = [
      t('COMUN.DATE.MONTHS.ENERO'),
      t('COMUN.DATE.MONTHS.FEBRERO'),
      t('COMUN.DATE.MONTHS.MARZO'),
      t('COMUN.DATE.MONTHS.ABRIL'),
      t('COMUN.DATE.MONTHS.MAYO'),
      t('COMUN.DATE.MONTHS.JUNIO'),
      t('COMUN.DATE.MONTHS.JULIO'),
      t('COMUN.DATE.MONTHS.AGOSTO'),
      t('COMUN.DATE.MONTHS.SEPTIEMBRE'),
      t('COMUN.DATE.MONTHS.OCTUBRE'),
      t('COMUN.DATE.MONTHS.NOVIEMBRE'),
      t('COMUN.DATE.MONTHS.DICIEMBRE'),
    ];

    let formatoMoment = getMomentFormat(formaFechaHora)

    return (
      <Col sm={colSm} smOffset={colSmOffset} className={customClass + (inline ? ' inline' : '')}>
        <FormGroup validationState={errorMessage ? 'error' : null} className={inline ? ' inline' : ''}>
          <div className={'InputFromTo datepicker-indv' + (this.state.error === true && !esFiltro ? ' error-fecha' : '') + (inline ? ' datepicker-inline' : '')}>
            {controlLabel && <ControlLabel>
              {controlLabel}
              {errorMessage || (this.state.error === true && !esFiltro) ? <span className='help-block'><Warning /></span> : null}
              {
                // this.state.error === true && !esFiltro && <span className='help-block'><Warning style={{color: '#a94442'}} /></span>
              }
            </ControlLabel>}
            {!inline && <br/>}
            <div className="cont-input-date">
              <DayPickerInput
                id={id}
                format={formatoMoment}
                formatDate={formatDate}
                parseDate={parseDate}
                value={this.state.valor}
                placeholder={formatoMoment.toLowerCase()}
                inputProps={{
                  title: controlLabel,
                  disabled,
                  className: 'form-control',
                  onBlur: this.handleBlur,
                  onClick: this.handleClick
                }}
                dayPickerProps={{
                  months: months,
                  weekdaysLong: weekdays_long,
                  weekdaysShort: weekdays_short,
                  firstDayOfWeek: (lunesInicioSemana ? 1 : 0),
                  locale: idioma,
                  disabledDays: {before: new Date(fechaPrincipio), after: new Date(fechaFin)}
                }}
                onDayChange={this.handleChange}
              />
              {
                (disabled !== true && isClearable) ? (
                  <span className="clear-fecha" onClick={() => this.clearValorInput()}>x</span>
                ) : null
              }
            </div>
          </div>
        </FormGroup>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(withTranslation()(InputDatePicker))

import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePicker from '../comun/form/InputRangeDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosEntradaMercanciaProducto extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelectedEntradaMercanciaProducto: false
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelectedEntradaMercanciaProducto: value })
    if (value === true) {
      this.props.actions.seleccionarTodosEntradaMercanciaProducto()
    } else {
      this.props.actions.deSeleccionarTodosEntradaMercanciaProducto()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-producto', false)
            this.setState({ imputSelectedEntradaMercanciaProducto: false })
            this.props.actions.deSeleccionarTodosEntradaMercanciaProducto()
          }
        }
      }
    }
  }

  deleteSeleccionEntradaMercanciaProducto(){
    let listado = []
    const itemsSeleccion = this.props.entradaMercanciaProductos.map((row) => {
      return (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idEntradaAlmacenProducto': row.idEntradaAlmacenProducto,
          'idProducto': row.idProducto,
          'nombre': row.nombre,
          'cantidad': row.cantidad,
          'lote': row.lote,
          'fechaCaducidad': row.fechaCaducidad,
          'nombreProducto': row.nombreProducto,
          'idElemento': row.idElemento
        }
      )
    })
    if (Object.keys(itemsSeleccion).length > 0) {
      this.props.actions.deleteSeleccionEntradaMercanciaProductoAnadido(itemsSeleccion)
      this.props.actions.deSeleccionarTodosEntradaMercanciaProducto()
    }
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalEntradaMercanciaAnadirProducto
    }
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={12}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id={'seleccionar-todo-producto'}
                    name={'seleccionar-todo-producto'}
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id={'block-options1'} key={'block-options'} className={this.state.imputSelectedEntradaMercanciaProducto === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionEntradaMercanciaProducto()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) ? () => abrirModalEntradaMercanciaAnadirProducto() : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosEntradaMercanciaProducto',
})(FiltrosEntradaMercanciaProducto))

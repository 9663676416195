import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import { Delete, AddCircleOutline } from '@material-ui/icons'

class FiltrosFormPoolExtracciones extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
    }
    this.deleteSeleccionAsignacionAnalisisPool = this.deleteSeleccionAsignacionAnalisisPool.bind(this)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosAsignacionAnalisisPool()
    } else {
      this.props.actions.deseleccionarTodosAsignacionAnalisisPool()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.poolExtraccionesTable && Object.keys(this.props.poolExtraccionesTable).length > 0) {
      if (this.props.poolExtraccionesTable !== prevProps.poolExtraccionesTable) {
        let contActivos = 0
        if (this.props.poolExtraccionesTable.values) {
          Object.values(this.props.poolExtraccionesTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosAsignacionAnalisisPool()
          }
        }
      }
    }
  }

  deleteSeleccionAsignacionAnalisisPool(){
    const itemsSeleccion = this.props.pool.asignadosAnalisisList.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionAsignacionAnalisisPool(list)
    this.props.actions.deseleccionarTodosAsignacionAnalisisPool()
  }

  render () {
    const {
      t,
      actions: { abrirModalAsignacionAnalisisPool }
    } = this.props

    const tKey = 'POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.TABLE.'

    return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="custom-check-w">
                <Field
                  id="seleccionar-todo"
                  name="seleccionar-todo"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  onInputChange={(value) => this.intercambiarCheck(value)}
                />
              </div>
              <div id="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionAsignacionAnalisisPool()}>
                  <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                </button>
                <div className="barra-buttons-min"></div>
              </div>
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-primary nuevo"  onClick={abrirModalAsignacionAnalisisPool}>
                  <AddCircleOutline /> {t(tKey + 'FILTROS.ANADIR')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosFormPoolExtracciones',
})(FiltrosFormPoolExtracciones))

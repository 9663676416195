import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalDireccionesRuta from '../../components/cliente/FiltrosModalDireccionesRuta'
import {abrirModalDireccionesRuta, seleccionarTodosModalDireccionesRuta, deSeleccionarTodosModalDireccionesRuta, actualizarDatosRuta} from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalDireccionesRuta,
      seleccionarTodosModalDireccionesRuta,
      deSeleccionarTodosModalDireccionesRuta,
      actualizarDatosRuta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalDireccionesRuta))
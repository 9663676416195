import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import { onSubmitFail } from '../../../util/util'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import InputText from '../../comun/form/InputText'
import InputSelect from '../../comun/form/InputSelect'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputTextArea from '../../comun/form/InputTextArea'
import InputNumerical from '../../comun/form/InputNumerical'
import './Microbiologia.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {Print} from '@material-ui/icons'

class ModalMicrobiologia extends Component {

  componentDidMount () {    
    this.props.actions.comboVerraco()
    this.props.actions.comboOperario()
  }

  submitNuevoMicrobiologia (values) {       
    if (this.props.data){      
      this.props.actions.editarMicrobiologia(values)
    } else {      
      this.props.actions.submitNuevaMicrobiologia(values)
    }
  }  
  
  cerrarModal () {   
    this.props.actions.cerrarModalMicrobiologia()
  }
  render () {
    const {
      t, handleSubmit, list, showModalMicrobiologia, data, duplicar,
      actions: {recuperarDatosModalMicrobiologia, cerrarModalMicrobiologia },
      combos: {comboTipoMicrobiologia, comboVerraco, comboAntibioticoMicrobiologia, comboOperario}
    } = this.props  
    
    //Traduccion del combo
    let comboTipoMicrobiologiaT = comboTipoMicrobiologia
    comboTipoMicrobiologiaT = comboTipoMicrobiologiaT.map((combo) => {
      return {...combo, label: t(combo.label)}
    })

    let comboAntibioticoMicrobiologiaT = comboAntibioticoMicrobiologia
    comboAntibioticoMicrobiologiaT = comboAntibioticoMicrobiologiaT.map((combo) => {
      return {...combo, label: t(combo.label)}
    })

    return (
      <Modal show={showModalMicrobiologia} onHide={() => this.cerrarModal()} aria-labelledby="contained-modal-title-lg" className="modal-microbiologia" backdrop="static">
      <form className="form-Microbiologia" onSubmit={handleSubmit(this.submitNuevoMicrobiologia.bind(this))}>
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('MICROBIOLOGIA.MODAL.TITULO_EDITAR')
            ) : (
              t('MICROBIOLOGIA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>          
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="fecha"
              name="fecha"
              colSm={3}
              component={InputDatePicker}
              controlLabel={t('MICROBIOLOGIA.MODAL.FECHA') + '*'}
              claseActivo="fecha"
              validate={required}
            />
            <Field
              colSm={3}
              id="tipo"
              name="tipo"
              controlLabel={t('MICROBIOLOGIA.MODAL.TIPO') + '*'}
              component={InputSelect}
              options={comboTipoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo'
              validate={required}
            />
            <Field
              id="nombre_tipo"
              name="nombre_tipo"
              colSm={3}
              component={InputText}
              controlLabel={t('MICROBIOLOGIA.MODAL.NOMBRE_TIPO')}
              claseActivo="nombre"             
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              colSm={3}
              id="idVerraco"
              name="idVerraco"
              controlLabel={t('MICROBIOLOGIA.MODAL.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-verraco'              
            />
            <Field
              id="resultado"
              name="resultado"
              colSm={3}
              component={InputText}
              controlLabel={t('MICROBIOLOGIA.MODAL.RESULTADO')}
              claseActivo="resultado"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="laboratorio"
              name="laboratorio"
              colSm={3}
              component={InputText}
              controlLabel={t('MICROBIOLOGIA.MODAL.LABORATORIO')}
              claseActivo="laboratorio"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="bacterias"
              name="bacterias"
              colSm={3}
              component={InputText}
              controlLabel={t('MICROBIOLOGIA.MODAL.BACTERIAS')}
              claseActivo="laboratorio"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              colSm={3}
              id="idOperario"
              name="idOperario"
              controlLabel={t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.OPERARIO')}
              component={InputSelect}
              options={comboOperario}
              valueKey="value"
              labelKey="label"
              customClass='select-operario'              
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              controlLabel={t('MICROBIOLOGIA.MODAL.OBSERVACIONES')}
              component={InputTextArea}
            />    
            <Field
              colSm={3}
              id="amoxicilina"
              name="amoxicilina"
              controlLabel={t('MICROBIOLOGIA.MODAL.AMOXICILINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-amoxicilina'
            /> 
            <Field
              colSm={3}
              id="ampicilina"
              name="ampicilina"
              controlLabel={t('MICROBIOLOGIA.MODAL.AMPICILINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-ampicilina'
            />
            <Field
              colSm={3}
              id="apramicina"
              name="apramicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.APRAMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-apramicina'
            />
            {/* <Field
              colSm={3}
              id="cefalexina"
              name="cefalexina"
              controlLabel={t('MICROBIOLOGIA.MODAL.CEFALEXINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-cefalexina'
            /> */}
            {/* <Field
              colSm={3}
              id="cefoperazona"
              name="cefoperazona"
              controlLabel={t('MICROBIOLOGIA.MODAL.CEFOPERAZONA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-cefoperazona'
            /> */}
            {/* <Field
              colSm={3}
              id="ceftiofur"
              name="ceftiofur"
              controlLabel={t('MICROBIOLOGIA.MODAL.CEFTIOFUR')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-ceftiofur'
            /> */}
            {/* <Field
              colSm={3}
              id="ciprofloxacina"
              name="ciprofloxacina"
              controlLabel={t('MICROBIOLOGIA.MODAL.CIPROFLOXACINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-ciprofloxacina'
            /> */}
            <Field
              colSm={3}
              id="espectinomicina"
              name="espectinomicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.ESPECTINOMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-espectinomicina'
            />
            <Field
              colSm={3}
              id="estreptomicina"
              name="estreptomicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.ESTREPTOMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-estreptomicina'
            />
            <Field
              colSm={3}
              id="gentamicina"
              name="gentamicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.GENTAMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-gentamicina'
            />
            <Field
              colSm={3}
              id="lincomicina"
              name="lincomicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.LINCOMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-lincomicina'
            />
            <Field
              colSm={3}
              id="neomicina"
              name="neomicina"
              controlLabel={t('MICROBIOLOGIA.MODAL.NEOMICINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-neomicina'
            />
            <Field
              colSm={3}
              id="tilosina"
              name="tilosina"
              controlLabel={t('MICROBIOLOGIA.MODAL.TILOSINA')}
              component={InputSelect}
              options={comboAntibioticoMicrobiologiaT}
              valueKey="value"
              labelKey="label"
              customClass='select-tilosina'
            />       
          </Row>
        </Modal.Body>
        <Modal.Footer>          
          <Button
            type="submit"
            className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MICROBIOLOGIA] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.MICROBIOLOGIA] < 2)}
          >{t('PLAN_SANITARIO.VISITA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModal()}>{t('PLAN_SANITARIO.VISITA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalMicrobiologia',
  onSubmitFail
})(ModalMicrobiologia))
import actionTypes from '../../constants/actions/grupoUsuarios/grupoUsuarios'

export function initialState () {
  return {
    listGrupoUsuarios: [],
    filtros: {},
    checkGrupoUsuarios: false,
    showModalGrupoUsuarios: false,
    data: {},
  }
}

export function fetchGrupoUsuariosSuccess (state, {list, filtros}) {
  return {
    ...state,
    listGrupoUsuarios: list,
    filtros
  }
}

export function abrirModalGrupoUsuariosSuccess (state, {data}) {
  return {
    ...state,
    showModalGrupoUsuarios: true,
    data
  }
}

export function cerrarModalGrupoUsuariosSuccess (state) {
  return {
    ...state,
    showModalGrupoUsuarios: false,
    data: {}
  }
}

export function duplicarGrupoUsuariosSuccess (state, {data}) {
  return {
    ...state,
    showModalGrupoUsuarios: true,
    data
  }
}

export function seleccionarTodosGrupoUsuarios (state) {
  return {
    ...state,
    checkGrupoUsuarios: true
  }
}

export function deSeleccionarTodosGrupoUsuarios (state) {
  return {
    ...state,
    checkGrupoUsuarios: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_GRUPO_USUARIOS_SUCCESS:
      return fetchGrupoUsuariosSuccess(state, action)
    case actionTypes.ABRIR_MODAL_GRUPO_USUARIOS_SUCCESS:
      return abrirModalGrupoUsuariosSuccess(state, action)
    case actionTypes.CERRAR_MODAL_GRUPO_USUARIOS_SUCCESS:
      return cerrarModalGrupoUsuariosSuccess(state, action)
    case actionTypes.DUPLICAR_GRUPO_USUARIOS_SUCCESS:
      return duplicarGrupoUsuariosSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_GRUPO_USUARIOS:
      return seleccionarTodosGrupoUsuarios(state, action)
    case actionTypes.DESELECCIONAR_TODOS_GRUPO_USUARIOS:
      return deSeleccionarTodosGrupoUsuarios(state, action)
    default:
      return state
  }
}
// New V10
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import castellano from './translations/castellano.json'
import ingles from './translations/ingles.json'
import chino from './translations/chino.json'
import ruso from './translations/ruso.json'
import japones from './translations/japones.json'
import coreano from './translations/coreano.json'

const resources = {
  en: {
    common: ingles
  },
  es: {
    common: castellano
  },
  ch: {
    common: chino
  },
  ru: {
    common: ruso
  },
  ja: {
    common: japones
  },
  ko: {
    common: coreano
  }
}

// Lenguaje predeterminado, utilizado en el reducer auth.
export const defaultlanguage = 'es';

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: defaultlanguage,
    default: defaultlanguage,
    fallbackLng: 'es',
    defaultNS: 'common',
    fallbackNS: 'common',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      wait: false,
      withRef: false,
      nsMode: 'default',
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    }
  });


export default i18n;
import actionTypes from '../../constants/actions/nivelGenetico/nivelGenetico'

export function initialState () {
  return {
    list: []
  }
}

export function fetchNivelGeneticoSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalNivelGeneticoSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function onDeleteNivelGeneticoSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function submitNuevaNivelGeneticoSuccess (state, {insercionNivelGenetico}) {
  return {
    ...state,
    insercionNivelGenetico
  }
}

export function editarNivelGeneticoSuccess (state, {edicionNivelGenetico}) {
  return {
    ...state,
    edicionNivelGenetico
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_NIVEL_GENETICO_SUCCESS:
      return fetchNivelGeneticoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_NIVEL_GENETICO_SUCCESS:
      return recuperarDatosModalNivelGeneticoSuccess(state, action)
    case actionTypes.ON_DELETE_NIVEL_GENETICO_SUCCESS:
      return onDeleteNivelGeneticoSuccess(state, action)   
    case actionTypes.SUBMIT_NUEVA_NIVEL_GENETICO_SUCCESS:
      return submitNuevaNivelGeneticoSuccess(state, action)
    case actionTypes.EDITAR_NIVEL_GENETICO_SUCCESS:
      return editarNivelGeneticoSuccess(state, action)    
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import './NuevoTransportista.scss'
import {date_formatter} from '../../util/formatFunctions'
import { Delete, AddCircleOutline, Warning, Close } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import { onSubmitFail } from '../../util/util'
import FormSubtitle from '../comun/form/FormSubtitle'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalConductorPage from '../../containers/transporte/ModalConductorPage'
import ModalVehiculoPage from '../../containers/transporte/ModalVehiculoPage'
import ModalRutaPage from '../../containers/transporte/ModalRutaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class NuevoTransportista extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      imputConductorSelected: false,
      imputVehiculoSelected: false,
      imputRutasSelected: false,
      mensajeErrorClientes: '',
      classErrorClientes: false,
      mostrarFechaAsignacion: false,
      mostrarFechaDesasignacion: false,
      conductoresAnadidos: [],
      vehiculosAnadidos: [],
      rutasAnadidas: [],
      marcarFiltrosConductor: false,
      marcarFiltrosVehiculo: false,
      marcarFiltrosRuta: false,
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTE') + ' - ' + this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTISTAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.change('activo', true)
    this.setState({ imputVehiculoSelected: false })
    this.setState({ imputRutasSelected: false })
    this.setState({ imputConductorSelected: false })
  }

  close(){
    this.props.history.push({pathname: '/Transporte', state: { from: this.props.history.location.pathname }})
    this.props.actions.reiniciarConductoresVehiculosRutas()
  }

  componentDidUpdate (prevProps) {
    if (this.props.conductoresAnadidos !== prevProps.conductoresAnadidos){
      this.setState({conductoresAnadidos: this.props.conductoresAnadidos})
      this.props.change('seleccionar-todo-vehiculo', false)
      this.props.change('seleccionar-todo-conductores', false)
      this.props.change('seleccionar-todo-ruta', false)
      this.setState({ imputVehiculoSelected: false })
      this.setState({ imputRutasSelected: false })
      this.setState({ imputConductorSelected: false })
    }
    if (this.props.cambioPorDefecto !== prevProps.cambioPorDefecto){
      this.setState({conductoresAnadidos: this.props.conductoresAnadidos})
    }
    if (this.props.cambioEstadoTrasporte !== prevProps.cambioEstadoTrasporte){
      this.setState({conductoresAnadidos: this.props.conductoresAnadidos})
    }

    if (this.props.vehiculosAnadidos !== prevProps.vehiculosAnadidos){
      this.setState({vehiculosAnadidos: this.props.vehiculosAnadidos})
      this.props.change('seleccionar-todo-vehiculo', false)
      this.props.change('seleccionar-todo-conductores', false)
      this.props.change('seleccionar-todo-ruta', false)
      this.setState({ imputVehiculoSelected: false })
      this.setState({ imputRutasSelected: false })
      this.setState({ imputConductorSelected: false })
    }
    if (this.props.cambioPorDefectoVehiculo !== prevProps.cambioPorDefectoVehiculo){
      this.setState({vehiculosAnadidos: this.props.vehiculosAnadidos})
    }
    if (this.props.cambioEstadoVehiculo !== prevProps.cambioEstadoVehiculo){
      this.setState({vehiculosAnadidos: this.props.vehiculosAnadidos})
    }

    if (this.props.rutasAnadidas !== prevProps.rutasAnadidas){
      this.setState({rutasAnadidas: this.props.rutasAnadidas})
      this.props.change('seleccionar-todo-vehiculo', false)
      this.props.change('seleccionar-todo-conductores', false)
      this.props.change('seleccionar-todo-ruta', false)
      this.setState({ imputVehiculoSelected: false })
      this.setState({ imputRutasSelected: false })
      this.setState({ imputConductorSelected: false })
    }
    if (this.props.cambioPorDefectoRuta !== prevProps.cambioPorDefectoRuta){
      this.setState({rutasAnadidas: this.props.rutasAnadidas})
    }
    if (this.props.paginaActual !== prevProps.paginaActual) {
      console.log(this.props.paginaActual)
    }
  }

  submitNuevoTransportista (values) {
    values.conductores = this.props.conductoresAnadidos
    values.vehiculos = this.props.vehiculosAnadidos
    values.rutas = this.props.rutasAnadidas
    this.props.actions.submitNuevoTransportista(values, this.props.history)
  }

  guardarYnuevoTransporte(values){
    values.conductores = this.props.conductoresAnadidos
    values.vehiculos = this.props.vehiculosAnadidos
    values.rutas = this.props.rutasAnadidas
    this.props.actions.guardarYnuevoTransporte(values)
  }

  intercambiarCheckConductor (value) {
    this.setState({ imputConductorSelected: value })
    this.setState({ marcarFiltrosConductor: value })
    this.setState({ marcarFiltrosVehiculo: false })
    this.setState({ marcarFiltrosRuta: false })
    this.props.change('seleccionar-todo-vehiculo', false)
    this.props.change('seleccionar-todo-ruta', false)
    this.setState({ imputVehiculoSelected: false })
    this.setState({ imputRutasSelected: false })
    this.props.actions.deSeleccionarTodosVehiculos()
    this.props.actions.deSeleccionarTodosRutas()
    if (value === true) {
      this.props.actions.seleccionarTodosConductores()
    } else {
      this.props.actions.deSeleccionarTodosConductores()
    }
  }

  intercambiarCheckVehiculo (value) {
    this.setState({ imputVehiculoSelected: value })
    this.setState({ marcarFiltrosVehiculo: value })
    this.setState({ marcarFiltrosConductor: false })
    this.setState({ marcarFiltrosRuta: false })
    this.props.change('seleccionar-todo-conductores', false)
    this.props.change('seleccionar-todo-ruta', false)
    this.setState({ imputConductorSelected: false })
    this.setState({ imputRutasSelected: false })
    this.props.actions.deSeleccionarTodosConductores()
    this.props.actions.deSeleccionarTodosRutas()
    if (value === true) {
      this.props.actions.seleccionarTodosVehiculos()
    } else {
      this.props.actions.deSeleccionarTodosVehiculos()
    }
  }
  intercambiarCheckNuevoRuta (value) {
    this.setState({ imputRutasSelected: value })
    this.setState({ marcarFiltrosRuta: value })
    this.setState({ marcarFiltrosVehiculo: false })
    this.setState({ marcarFiltrosConductor: false })
    this.props.change('seleccionar-todo-vehiculo', false)
    this.props.change('seleccionar-todo-conductores', false)
    this.setState({ marcarFiltrosConductor: false })
    this.setState({ imputVehiculoSelected: false })
    this.setState({ imputConductorSelected: false })
    this.props.actions.deSeleccionarTodosVehiculos()
    this.props.actions.deSeleccionarTodosConductores()
    if (value === true) {
      this.props.actions.seleccionarTodosRutas()
    } else {
      this.props.actions.deSeleccionarTodosRutas()
    }
  }

  deleteSeleccionConductor () {
    const itemsSeleccion = this.props.conductoresAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'activo': row.activo,
          'predefinido': row.predefinido,
          'nombreConductor': row.nombreConductor,
          'dniConductor': row.dniConductor,
          'movilConductor': row.movilConductor

        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item !== false
    })
    this.props.actions.deleteSeleccionConductor(list)
    this.props.change('seleccionar-todo-conductores', false)
  }

  deleteSeleccionVehiculo () {
    const itemsSeleccion = this.props.vehiculosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'activo': row.activo,
          'predefinido': row.predefinido,
          'matricula': row.matricula,
          'modelo': row.modelo
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item !== false
    })
    this.props.actions.deleteSeleccionVehiculo(list)
    this.props.change('seleccionar-todo-vehiculo', false)
  }

  deleteSeleccionRuta () {
    const itemsSeleccion = this.props.rutasAnadidas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'activo': row.activo,
          'predefinido': row.predefinido,
          'idRuta': row.idRuta,
          'idCentro': row.idCentro,
          'nombreRuta': row.nombreRuta,
          'observaciones': row.observaciones,
          'numeroClientes': row.numeroClientes
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item !== false
    })
    this.props.actions.deleteSeleccionRutaTransportista(list)
    this.props.change('seleccionar-todo-ruta', false)
  }

  abrirModalRuta () {
    this.props.actions.abrirModalRuta(null)
  }

  render () {
    const {
      t, handleSubmit, showModal, data, duplicar, checkedVehiculo, numeroRegistrosPorPagina, conductoresAnadidos, checkedConductor, vehiculosAnadidos, checkedRutas, rutasAnadidas,
      paginaActual,
      actions: {closeModal, abrirModalConductor, recuperarModalConductor, cambiarEstadoConductor, onDeleteConductorAnadido, duplicarConductor, onDeleteRutaAnadida, rutaPorDefecto,
      conductorPorDefecto, abrirModalVehiculo, editVehiculoAnadido, recuperarModalVehiculo, cambiarEstadoVehiculo, onDeleteVehiculoAnadido, duplicarVehiculo, vehiculoPorDefecto},
      auth: {formaFechaHora}
    } = this.props

    // TABLA CONDUCTORES
    this.state.conductoresAnadidos.forEach(
      // (row, idRow) => row.idConductor ? row.idElemento = row.idConductor : row.idElemento = idRow + 1
      (row, idRow) => row.idElemento = idRow + 1 + 'conductor'
    )

    const tableConductoresAnadidos = {
      id: 'conductorAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      tablaModal: true,
      idBlockOptions: 1,
      // noBlockOptions: false,
      columns: [
        {id: 'nombreConductor', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.NOMBRE')},
        {id: 'dniConductor', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.DNI')},
        {id: 'movilConductor', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.TELEFONO')},
        {id: 'predefinido', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.POR_DEFECTO')}
      ],
      rows: this.state.conductoresAnadidos,
      data: data,
      onEdit: (row, idRow) => recuperarModalConductor(row.idElemento),
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      cambiarEstado: (row, idRow) => cambiarEstadoConductor(row.idElemento),
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      duplicar: (row, idRow) => duplicarConductor(row.idElemento),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      onDelete: (row) => onDeleteConductorAnadido(row.idElemento, row.idConductor),
      cambiarPorDefecto: (row, idRow) => conductorPorDefecto(row.idElemento),
      conductorPorDefecto: true,
      checkedTabla1: this.state.marcarFiltrosConductor,
      initialValues: {}
    }

    tableConductoresAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableConductoresAnadidos.initialValues['check' + row.idElemento] = checkedConductor
        } else {
          console.log('no')
        }
      }
    )

    // TABLA VEHÍCULOS
    this.state.vehiculosAnadidos.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'vehiculo'
    )

    const tableVehiculosAnadidos = {
      id: 'vehiculoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      tablaModal: true,
      idBlockOptions: 2,
      // noBlockOptions: false,
      columns: [
        {id: 'matricula', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.MATRICULA')},
        {id: 'modelo', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.MODELO')},
        {id: 'predefinido', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.POR_DEFECTO')}
      ],
      rows: this.state.vehiculosAnadidos,
      data: data,
      onEdit: (row, idRow) => recuperarModalVehiculo(row.idElemento),
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      cambiarEstado: (row, idRow) => cambiarEstadoVehiculo(row.idElemento),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      onDelete: (row) => onDeleteVehiculoAnadido(row.idElemento, row.idVehiculo),
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      duplicar: (row, idRow) => duplicarVehiculo(row.idElemento),
      cambiarPorDefecto: (row, idRow) => vehiculoPorDefecto(row.idElemento),
      vehiculoPorDefecto: true,
      checkedTabla2: this.state.marcarFiltrosVehiculo,
      initialValues: {}
    }

    tableVehiculosAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVehiculosAnadidos.initialValues['check' + row.idElemento] = checkedVehiculo
        }
      }
    )

    // TABLA RUTAS
    this.state.rutasAnadidas.forEach(
      (row) => row.idElemento = row.idRuta
    )

    const tableRutasAnadidos = {
      id: 'vehiculoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1),
      columns: [
        {id: 'nombreRuta', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.RUTA')},
        {id: 'observaciones', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.OBSERVACIONES')},
        {id: 'predefinido', name: t('TRANSPORTE.NUEVO_TRANSPORTE.TABLAS.POR_DEFECTO')}
      ],
      rows: this.state.rutasAnadidas,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      tablaModal: true,
      idBlockOptions: 3,
      // noBlockOptions: false,
      rutaPorDefecto: true,
      onDelete: (row) => onDeleteRutaAnadida(row.idElemento),
      cambiarPorDefecto: (row, idRow) => rutaPorDefecto(row.idElemento),
      checkedTabla3: this.state.marcarFiltrosRuta === true ? checkedRutas : this.state.marcarFiltrosRuta,
      initialValues: {}
    }

    tableRutasAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableRutasAnadidos.initialValues['check' + row.idElemento] = checkedRutas
        }
      }
    )

    return (
      <div>
        <h2 className="page-title">{t('TRANSPORTE.NUEVO_TRANSPORTE.TITULO')}
        <span className="cierre-ventana"><Link to={{pathname: '/Transporte', state: { from: this.props.location.pathname }}}><Close/></Link></span></h2>
        <Row>
          <SimpleModalPage/>
          <MensajeAlertaPage/>
          <form className="form-ruta">
            <Field
              colSm={2}
              id="nombreTransportista"
              name="nombreTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.EMPRESA') + '*'}
              customClass='nombre-empresa'
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={2}
              id="cif"
              name="cifTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.CIF')}
              customClass='cif'
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              colSm={2}
              id="direccion"
              name="direccionTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.DIRECCION')}
              customClass='direccion'
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              colSm={2}
              id="localidad"
              name="localidadTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.LOCALIDAD')}
              customClass='localidad'
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={2}
              id="provincia"
              name="provinciaTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.PROVINCIA')}
              customClass='provincia'
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={2}
              id="telefono"
              name="telefonoTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.TELEFONO_CONTACTO')}
              customClass='telefono'
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={2}
              id="mail"
              name="emailTransportista"
              component={InputText}
              controlLabel={t('TRANSPORTE.NUEVO_TRANSPORTE.EMAIL_CONTACTO')}
              customClass='mail'
              maxLength={true}
              valorMaxLength={100}
            />
            <div className="clearfix"></div>
            <div className="tabla-nuevo-transportista">
              <FormSubtitle>{t('TRANSPORTE.NUEVO_TRANSPORTE.TITLE_CONDUCTORES')}</FormSubtitle>
              <div id="block-options1" className={this.state.imputConductorSelected === true ? 'filtros-tabla-conductor' : 'filtros-tabla-conductor oculta-action'}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) && [
                    <Field
                      id="seleccionar-todo-conductores"
                      name="seleccionar-todo-conductores"
                      key="seleccionar-todo-conductores"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckConductor(value)}
                    />,
                    <button key="eliminar_key" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionConductor()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                  ]}
                  <ModalConductorPage
                    t={t}
                    duplicar={(row, idRow) => recuperarModalConductor(row.idElemento)}
                  />
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) ? abrirModalConductor : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
                </div>
              </div>
              <ListadoPage t_key="RUTA." noTitle={true} table={tableConductoresAnadidos}>
              </ListadoPage>
              <p className={this.state.classErrorClientes === true ? 'error-cliente' : 'noerror-cliente'}><Warning/>{this.state.mensajeErrorClientes}</p>
            </div>
            <div className="tabla-nuevo-transportista">
              <FormSubtitle>{t('TRANSPORTE.NUEVO_TRANSPORTE.TITLE_VEHICULOS')}</FormSubtitle>
              <div id="block-options2" className={this.state.imputVehiculoSelected === true ? 'filtros-tabla-nuevo-transportista' : 'filtros-tabla-nuevo-transportista oculta-action'}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) && [
                    <Field
                      id="seleccionar-todo-vehiculo"
                      name="seleccionar-todo-vehiculo"
                      key="seleccionar-todo-vehiculo"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckVehiculo(value)}
                    />,
                    <button key="eliminar_key" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionVehiculo()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                  ]}
                  <ModalVehiculoPage
                    t={t}
                  />
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) ? abrirModalVehiculo : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
                </div>
              </div>
              <ListadoPage t_key="RUTA." noTitle={true} table={tableVehiculosAnadidos}>
              </ListadoPage>
              <p className={this.state.classErrorClientes === true ? 'error-cliente' : 'noerror-cliente'}><Warning/>{this.state.mensajeErrorClientes}</p>
            </div>
            <div className="tabla-nuevo-transportista">
              <FormSubtitle>{t('TRANSPORTE.NUEVO_TRANSPORTE.TITLE_RUTAS')}</FormSubtitle>
              <div id="block-options3" className={this.state.imputRutasSelected === true ? 'filtros-tabla-nuevo-transportista' : 'filtros-tabla-nuevo-transportista oculta-action'}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) && [
                    <Field
                      id="seleccionar-todo-ruta"
                      name="seleccionar-todo-ruta"
                      key="seleccionar-todo-ruta"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckNuevoRuta(value)}
                    />,
                    <button key="eliminar_key" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionRuta()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                  ]}
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) ? () => this.abrirModalRuta() : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.ANADIR')}
                  </button>
                  <ModalRutaPage />
                </div>
              </div>
              <ListadoPage t_key="RUTA." noTitle={true} table={tableRutasAnadidos}>
              </ListadoPage>
              <p className={this.state.classErrorClientes === true ? 'error-cliente' : 'noerror-cliente'}><Warning/>{this.state.mensajeErrorClientes}</p>
            </div>
            <Field
              colSm={12}
              id="observacionesRuta"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('RUTA.MODAL.OBSERVACIONES')}
              customClass='descripcion-ruta'
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('RUTA.MODAL.ACTIVO')}
            />
            <div className="clearfix"></div>
            <div className="botones-pie-form">
              {
                Object.keys(data).length > 0 && (
                  <Button
                    type="button"
                    className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) ? handleSubmit(duplicar) : undefined}
                  >{t('RUTA.MODAL.DUPLICAR')}</Button>
                )
              }
              {
                Object.keys(data).length === 0 && (
                  <Button
                    type="button"
                    className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoTransporte.bind(this)) : null : undefined}
                  >{t('RUTA.MODAL.GUARDAR_Y_NUEVO')}</Button>
                )
              }
              <Button
                type="button"
                onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoTransportista.bind(this)) : null}
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2) ? ' disabled' : '')}
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TRANSPORTISTAS] < 2)}
              >{t('RUTA.MODAL.GUARDAR')}</Button>
              <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('RUTA.MODAL.CANCELAR')}</Button>
            </div>
          </form>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'NuevoTransportista',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail
})(NuevoTransportista))

import apiFetch from '../../apiFetch'

export default function (id, tipoAccion, token) {
  if (tipoAccion === 1) {
    return apiFetch({endPoint: `Bioseguridad/imprimirDetalleBioseguridadAnalitica?idBioSeguridadAnalitica=` + id, method: 'GET', body: null, token: token})
  }
  else if (tipoAccion === 2) {
    return apiFetch({endPoint: `Bioseguridad/imprimirDetalleBioseguridadLimpiezaSilosDepositos?idBioseguridadLimpiezaSilo=` + id, method: 'GET', body: null, token: token})
  }
  else if (tipoAccion === 3) {
    return apiFetch({endPoint: `Bioseguridad/imprimirDetalleBioseguridadLimpiezaCorrales?idBioseguridadLimpiezaCorral=` + id, method: 'GET', body: null, token: token})
  }
  else if (tipoAccion === 4) {
    return apiFetch({endPoint: `Bioseguridad/imprimirDetalleBioseguridadLimpiezaOtros?idBioseguridadOtro=` + id, method: 'GET', body: null, token: token})
  }
}
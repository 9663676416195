import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosComboObservacionesEyaculadoEliminado from '../../components/comboObservacionesEyaculadoEliminado/FiltrosComboObservacionesEyaculadoEliminado'
import { abrirModalVacioComboObservacionesEyaculadoEliminado } from '../../actions/comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'

export function mapStateToProps (state) {
  return {
    comboObservacionesEyaculadoEliminado: {...state.comboObservacionesEyaculadoEliminado},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVacioComboObservacionesEyaculadoEliminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosComboObservacionesEyaculadoEliminado))
import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import {Delete, Launch, Print, AddCircleOutline } from '@material-ui/icons'
import InputCheckBox from '../comun/form/InputCheckBox'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosEvolucion extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.fichaVerraco.checkedFiltrosEvolucion !== prevProps.fichaVerraco.checkedFiltrosEvolucion) {
      if (this.props.fichaVerraco.checkedFiltrosEvolucion === false) {
        this.props.change('seleccionar-todo-evolucion', false)
      }
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosEvolucion()
    } else {
      this.props.actions.deSeleccionarTodosEvolucion()
    }
  }

  deleteSeleccionEvolucion(){
    const itemsSeleccion = this.props.fichaVerraco.evolucionesAnadidas.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'fechaEvolucion': row.fechaEvolucion,
          'condicionCorporalVerraco': row.condicionCorporalVerraco,
          'pesoVerraco': row.pesoVerraco,
          'kgPiensoDiaVerraco': row.kgPiensoDiaVerraco,
          'gmdVerraco': row.gmdVerraco,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionEvolucion(list)
    this.props.change('seleccionar-todo-evolucion', false)
  }

  crearCsvEvolucion(){
    this.props.actions.crearCsvEvolucion(this.props.list)
  }

  render() {
  const {
    t,
    actions: { abrirModalVacioEvolucion, abrirModalEvolucion }
  } = this.props

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={6}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo-evolucion"
                    name="seleccionar-todo-evolucion"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionEvolucion()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) ? abrirModalVacioEvolucion : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosEvolucion',
})(FiltrosEvolucion))

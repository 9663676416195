import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {fetchLicencias, abrirModalLicencias, onDeleteLicencia} from '../../../actions/admin/licencias/licencias'
import Licencias from '../../../components/admin/licencias/Licencias'

const mapStateToProps = (state) => {
  return {
    ...state.licencias,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchLicencias,
      abrirModalLicencias,
      onDeleteLicencia
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Licencias))
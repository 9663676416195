export default {
  FETCH_FOSO_SUCCESS: 'FETCH_FOSO_SUCCESS',
  FETCH_FILTRAR_FOSO: 'FETCH_FILTRAR_FOSO',
  FETCH_FILTRAR_FOSO_SUCCESS: 'FETCH_FILTRAR_FOSO_SUCCESS',
  CERRAR_MODAL_FOSO: 'CERRAR_MODAL_FOSO',
  ABRIR_MODAL_FOSO: 'ABRIR_MODAL_FOSO',
  OBTENER_DATOS_VERRACO: 'OBTENER_DATOS_VERRACO',
  OBTENER_DATOS_VERRACO_SUCCESS: 'OBTENER_DATOS_VERRACO_SUCCESS',
  OBTENER_CODIGO_ANALISIS_SUCCESS: 'OBTENER_CODIGO_ANALISIS_SUCCESS',
  OBTENER_EXTRACCION: 'OBTENER_EXTRACCION',
  OBTENER_EXTRACCION_SUCCESS: 'OBTENER_EXTRACCION_SUCCESS',
  GUARDAR_FOSO: 'GUARDAR_FOSO',
  MODIFICAR_FOSO: 'MODIFICAR_FOSO',
  ELIMINAR_FOSO: 'ELIMINAR_FOSO',
  CREATE_CSV_FOSO: 'CREATE_CSV_FOSO',
  CREATE_CSV_FOSO_SUCCESS: 'CREATE_CSV_FOSO_SUCCESS',
  IMPRIMIR_PDF_FOSO: 'IMPRIMIR_PDF_FOSO',
  IMPRIMIR_PDF_FOSO_SUCCESS: 'IMPRIMIR_PDF_FOSO_SUCCESS',
  IMPRIMIR_ETIQUETA_FOSO: 'IMPRIMIR_ETIQUETA_FOSO',
}
import React, { Component } from 'react'
import { Image, Popover, OverlayTrigger } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import Notifications from '@material-ui/icons/Notifications'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {DesktopMac, LiveHelp} from '@material-ui/icons'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import InputSelect from '../comun/form/InputSelect'
import avatar from '../../assets/images/avatar.png'
import ModalPerfilPage from '../../containers/perfil/ModalPerfilPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import NotificacionesPage from '../../containers/notificaciones/NotificacionesPage'

const popoveruser = ({t, actions: {logout}, auth: {nombreUsuario, pertenece_grupo_admin}, actions: {abrirModalPerfil}}) => (
  <Popover id="popover-trigger-click-root-close" className="content-popover-user" onClick={() => document.body.click()}>
    <div className="user-popover">
      <div className="data-cargo">{pertenece_grupo_admin && t('HEADER.USUARIO')}</div>
      <div className="data-name">{nombreUsuario}</div>
    </div>
    <div className="mi-cuenta-popover">
      <ul>
        <li onClick={abrirModalPerfil}><AccountCircle /><span className="acciones-mi-cuenta-popover">{t('HEADER.MI_CUENTA')}</span></li>
        <hr style={{marginBottom: '0px', marginTop: '0px'}} />
        <li onClick={() => logout() }><PowerSettingsNew /><span className="acciones-mi-cuenta-popover">{t('HEADER.CERRAR_SESION')}</span></li>
      </ul>
    </div>
  </Popover>
)

class UserSubHeader extends Component {

  componentDidMount () {
    this.props.actions.comboObtenerCentroUsuario()
  }

  componentDidUpdate (prevProps) {
    if (this.props.auth.centro !== prevProps.auth.centro) {
      this.props.actions.obtenerNotificaconesUsuario()
    }
  }

  handleChangeCentro(idCentro){
    if (idCentro && idCentro.value) this.props.actions.cambiarCentro(idCentro.value, this.props.history)
  }

  render () {
    const { t, auth, showModalPerfil, combos:{comboObtenerCentroUsuario} } = this.props
    return (
      <div className="loged">
        {showModalPerfil && <ModalPerfilPage />}
        <form>
          <Field
            colSm={0}
            id="centro"
            name="centro"
            placeholder={t('HEADER.CENTRO_INSEMINACION')}
            component={InputSelect}
            options={comboObtenerCentroUsuario}
            valueKey="value"
            labelKey="label"
            customClass='select-centro-inseminacion'
            onInputChange={(value) => this.handleChangeCentro(value)}
            isClearable={false}
          />
        </form>
        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popoveruser(this.props)}>
          <div className="nav-link dropdown-toggle">
            {
              auth.imagenUsuario !== null ? (
                <Image src={auth.imagenUsuario} className='avatar' responsive />
              ) : (
                <Image src={avatar} className='avatar' responsive />
              )
            }
            <div className="user-data">
              {auth.pertenece_grupo_admin && (<div className="data-cargo">{t('HEADER.USUARIO')}: </div>)}
              <div className="data-name">{auth.nombreUsuario}</div>
            </div>
          </div>
        </OverlayTrigger>
        <NotificacionesPage />
      </div>
    )
  }
}

export default (reduxForm({
  form: 'centro',
})(UserSubHeader))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosAgenda from '../../components/agenda/FiltrosAgenda'
import {
  fetchAgenda, fetchTareasDia, abrirModalAgenda
} from '../../actions/agenda/agenda'
import {comboOperario} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    agenda: state.agenda,
    auth: state.auth,
    combos: {
      comboOperario: state.combos.comboOperario,
      comboTipoTareasAgenda: state.combos.comboTipoTareasAgenda,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAgenda,
      fetchTareasDia,
      abrirModalAgenda,
      comboOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosAgenda))
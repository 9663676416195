import apiFetch from '../apiFetch'

export default function ({analisisExtraccion, fechaExtraccion}) {
  console.log()
  const queryParamsArray = [
    analisisExtraccion ? `analisisExtraccion=${analisisExtraccion}` : null,
    fechaExtraccion ? `fechaExtraccion=${fechaExtraccion}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({endPoint: `Combos/getComboVerraco${queryParams}`, method: 'GET', body: null})
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalMicrobiologia from '../../../components/planSanitario/microbiologia/ModalMicrobiologia'
import {submitNuevaMicrobiologia, recuperarDatosModalMicrobiologia, editarMicrobiologia, cerrarModalMicrobiologia, abrirModalVacioMicrobiologia, abrirModalMicrobiologia} from '../../../actions/planSanitario/microbiologia/microbiologia'
import {comboVerraco, comboOperario} from '../../../actions/combos/combos'
import {closeModal} from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.microbiologia,
    showModal: state.common.showModal,
    auth: state.auth,    
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      submitNuevaMicrobiologia,
      recuperarDatosModalMicrobiologia,
      editarMicrobiologia,          
      cerrarModalMicrobiologia,
      abrirModalVacioMicrobiologia,
      abrirModalMicrobiologia,
      comboVerraco,
      comboOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalMicrobiologia))
import { call, put, race, take, takeEvery } from 'redux-saga/effects'
import { openSimpleModal, closeModal } from '../../actions/common.js'
import actionTypes from '../../constants/actions/common'

export function * yesNoModal ({modalType, okAction, cancelAction, message, subMessage}) {
  yield put(openSimpleModal(modalType, message, subMessage))
  const {ok} = yield race({
    ok: take(actionTypes.CUSTOM_MODAL_OK),
    nok: take(actionTypes.CUSTOM_MODAL_NOK)
  })

  if (ok && okAction) yield call(okAction)
  else if (cancelAction) yield call(cancelAction)

  yield put(closeModal())
  return !!ok
}

export function * watchYesNoModal () {
  yield takeEvery(actionTypes.YES_NO_MODAL, yesNoModal)
}

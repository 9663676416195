import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalInstalacion from '../../components/instalacion/ModalInstalacion'
import {recuperarDatosModalInstalacion, submitNuevaInstalacion, editarInstalacion, guardarYnuevoInstalacion} from '../../actions/instalacion/instalacion'
import {closeModal} from '../../actions/common'
import {comboTipoInstalacion, comboInstalacionPadre} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.instalacion,
    showModal: state.common.showModal,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalInstalacion,
      submitNuevaInstalacion,
      editarInstalacion,
      closeModal,
      guardarYnuevoInstalacion,
      comboTipoInstalacion,
      comboInstalacionPadre
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalInstalacion))
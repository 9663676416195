import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalBioseguridadHigiene.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'

class ModalGrupoVerraco extends Component {
  anadirGrupoVerraco () {
    const itemsSeleccion = this.props.listGrupoVerraco.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idGrupoVerraco': row.idGrupoVerraco,
          'nombreGrupoVerraco': row.nombreGrupoVerraco,
          'numeroverracos': row.numeroverracos,
          'activo': row.activo,
          'descripcion': row.descripcion,
          'fechaCreacion': row.fechaCreacion,
          'fechaDesactivacion': row.fechaDesactivacion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.anadirGrupoVerracoBioseguridad(list)
    this.props.change('seleccionar-todos-verraco', false)
  }

  intercambiarCheckModalGrupoVerraco (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalGrupoVerraco()
    } else {
      this.props.actions.deSeleccionarTodosModalGrupoVerraco()
    }
  }

  componentDidMount () {
    this.props.actions.fetchGrupoVerracoBioseguridad(this.props.grupoVerracoAnadidas)
  }

  render () {
    const {
      t, handleSubmit, listGrupoVerraco, showModalGrupoVerraco, checkedGrupoVerraco, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalGrupoVerraco }
    } = this.props

    listGrupoVerraco.forEach(
      (row) => row.idElemento = row.idGrupoVerraco
    )

    const tableGrupoVerraco = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'nombreGrupoVerraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.NOMBRE_GRUPO')},
        {id: 'numeroverracos', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.NUM_VERRACOS')},
        {id: 'fechaCreacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.FECHA_CREACION'), type: 'fecha', type: 'fecha'},
        {id: 'fechaDesactivacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.FECHA_DESACTIVACION'), type: 'fecha'},
      ],
      rows: listGrupoVerraco,
      initialValues: {}
    }

    tableGrupoVerraco.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableGrupoVerraco.initialValues['check' + row.idElemento] = checkedGrupoVerraco
        }
      }
    )

    return (
      <Modal show={showModalGrupoVerraco} onHide={cerrarModalGrupoVerraco} aria-labelledby="contained-modal-title-lg" size="lg" className="submodal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GRUPO_VERRACOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="tabla-submodal">
              <div className="filtros-tabla-modal-submodal">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-verraco"
                    name="seleccionar-todos-verraco"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalGrupoVerraco(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableGrupoVerraco}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={() => this.anadirGrupoVerraco()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalGrupoVerraco}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalGrupoVerraco',
})(ModalGrupoVerraco))
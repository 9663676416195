import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {comboLotesProductoEspecificoActivoTrazabilidad} from '../../actions/combos/combos'
import {guardarAjusteStock, closeModalAjusteStock} from '../../actions/ajusteStock/ajusteStock'
import ModalAjusteStock from '../../components/ajusteStock/ModalAjusteStock'

export function mapStateToProps (state) {
  return {
    ...state.ajusteStock,
    combos: {
      comboProductosActivosTrazabilidad: state.combos.comboProductosActivosTrazabilidad,
      comboLotesProductoEspecificoActivoTrazabilidad: state.combos.comboLotesProductoEspecificoActivoTrazabilidad
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboLotesProductoEspecificoActivoTrazabilidad,
      guardarAjusteStock,
      closeModalAjusteStock
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAjusteStock))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import ModalAnadirMuestrasPage from '../../containers/panelControl/ModalAnadirMuestrasPage'

class ModalAnadirMuestras extends Component {

  anadirMuestra (values) {
    if (Object.keys(this.props.muestrasRecuperar).length > 0){
      this.props.actions.editMuestrasAnadidas(values)
    } else {
      this.props.actions.anadirMuestra(values)
    }
  }

  guardarYnuevoMuestras (values) {
    this.props.actions.guardarYnuevoMuestras(values)
    this.props.initialize('ModalAnadirMuestras')
  }

  render () {
    const {
      t, handleSubmit, showModalAnadirMuestra,
      actions: {cerrarModalAnadirMuestras}
    } = this.props

    return (
      <Modal show={showModalAnadirMuestra} onHide={cerrarModalAnadirMuestras} aria-labelledby="contained-modal-title-lg" className="modal-anadir-muestras" backdrop="static">
      <form className="form-anadir-colorimetro">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(this.props.muestrasRecuperar).length > 0 ? (
              t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_EDITAR')
            ) : (
              Object.keys(this.props.muestrasDuplicar).length > 0 ? (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_DUPLICAR')
              ) : (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={6}
              id="absorbancia"
              name="absorbancia"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.ABSORBANCIA')}
              component={InputNumerical}
              numDecimales={3}
              customClass='absorbancia'
              validate={required}
            />
            <Field
              colSm={6}
              id="concentracionEyaculado"
              name="concentracionEyaculado"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.CONCENTRACION_EYACULADO')}
              component={InputNumerical}
              numDecimales={4}
              customClass='concentracion-eyaculado'
              validate={required}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.muestrasRecuperar).length === 0 ? (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoMuestras.bind(this))}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR_Y_NUEVO')}</Button>
            ) : null
          }
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirMuestra.bind(this))}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarModalAnadirMuestras}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirMuestras',
})(ModalAnadirMuestras))
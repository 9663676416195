import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Filtros from '../../components/operario/FiltrosOperario'
import {openModal} from '../../actions/common'
import {abrirModalVacioOperario, fetchFiltrarOperario, seleccionarTodosOperario, deSeleccionarTodosOperario, cambiarEstadoSeleccionOperario, deleteSeleccionOperario, imprimirPdfOperario,
crearCsvOperario} from '../../actions/operario/operario'
import {comboNombresOperarios, comboApellidosOperarios, comboLocalidadOperarios} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.operario,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioOperario,
      fetchFiltrarOperario,
      seleccionarTodosOperario,
      deSeleccionarTodosOperario,
      cambiarEstadoSeleccionOperario,
      deleteSeleccionOperario,
      crearCsvOperario,
      comboNombresOperarios,
      comboApellidosOperarios,
      comboLocalidadOperarios,
      imprimirPdfOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filtros))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { abrirMagavision } from '../../../actions/analisisExtracciones/analisisExtracciones'
import { abrirMagavisionControlMorfologico, openModalFormControlMorfologico, guardarModalFormControlMorfologico, closeModalFormControlMorfologico, obtenerYGuardarResultadoSistemaAnalisisControlMorfologico } from '../../../actions/planSanitario/controlCalidad/controlMorfologico'
import {openModalContajeMaquinas} from '../../../actions/analisisExtracciones/contajeMaquinas'
import ModalFormControlMorfologico from '../../../components/planSanitario/controlCalidad/ModalFormControlMorfologico'
import {comboVerraco, comboOperarioPredeterminado, fetchComboRectaCalibrado, comboTipoDosis, fetchComboTipoDiluyente, comboOperarioActivo} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.controlMorfologico.modalFormControlMorfologico,
    mainList: state.controlMorfologico.mainList,
    tipoAnalisisPredeterminado: state.controlMorfologico.modalFormControlMorfologico.data.idTipoAnalisis || state.panelControl.tipoAnalisisPredeterminado,
    auth: state.auth,
    combos: {
      comboTipoControl: state.combos.comboTipoControlSeguimiento,
      comboTipoAnalisisExtraccion: state.combos.comboTipoAnalisisExtraccion,
      comboOperarioActivo: state.combos.comboOperarioActivo
    },
    panelControl: state.panelControl,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModalFormControlMorfologico,
      guardarModalFormControlMorfologico,
      closeModalFormControlMorfologico,
      openModalContajeMaquinas,
      obtenerYGuardarResultadoSistemaAnalisisControlMorfologico,
      abrirMagavision,
      // abrirMagavisionControlMorfologico,
      comboOperarioActivo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalFormControlMorfologico)))
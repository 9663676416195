import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import {dateTimeZones} from '../../util/formatFunctions'

class ModalAlimentacion extends Component {

  componentDidMount () {
    this.props.actions.comboTipoAlimentacion()
    this.props.actions.comboOperarioActivo()
  }

  submitNuevaAlimentacion (values) {
    if (values.operario) { 
      values.idOperario = values.operario.value
      values.nombreOperario = values.operario.label
    }
    if (values.tipoAlimentacion) {
      values.idTipoAlimentacion = values.tipoAlimentacion.value
      values.nombreTipoAlimentacion = values.tipoAlimentacion.label
    }
    if (Object.keys(this.props.dataAlimentacion).length > 0){
      this.props.actions.editarAlimentacion(values)
    } else {
      this.props.actions.submitNuevaAlimentacion(values)
    }
  }

  guardarYnuevoAlimentacion(values){
    this.props.actions.guardarYnuevoAlimentacion(values)
  }

  calcularTerminado (value) {
    let diaHoy = new Date()
    if (new Date(value).getTime() <= diaHoy.getTime()) {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.SI'))
    } else {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.NO'))
    }
  }

  render () {
    const {
      t, handleSubmit, showModal, dataAlimentacion, dataAlimentacionDuplicar,
      // fichaVerraco: {nuevaAlimentacion},
      actions: {closeModalAlimentacion},
      combos: {comboTipoAlimentacion, comboOperarioActivo}
    } = this.props

    // const ultimaAlimentacion = this.props.fichaVerraco.nuevaAlimentacion.length -1
    return (
      <Modal show={showModal} onHide={closeModalAlimentacion} aria-labelledby="contained-modal-title-lg" backdrop="static" bsSize="large" dialogClassName="xl" >
      <form className="form-Alimentacion" onSubmit={handleSubmit(this.submitNuevaAlimentacion.bind(this))}>
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(dataAlimentacion).length > 0 ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.TITULO_EDITAR')
            ) : Object.keys(dataAlimentacionDuplicar).length > 0 ? (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.TITULO_DUPLICAR')
            ) : (
              t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={3}
              id="numeroRegistroAlimentacion"
              name="numeroRegistroAlimentacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CODIGO_ALIMENTACION')}
              component={InputText}
              disabled={true}
            />
            <Field
              colSm={3}
              id="tipoAlimentacion"
              name="tipoAlimentacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TIPO_ALIMENTACION') + '*'}
              component={InputSelect}
              options={comboTipoAlimentacion}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-alimentacion'
              validate={required}
              isClearable={false}
            />
            <Field
              colSm={3}
              id="producto"
              name="producto"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.PRODUCTO')}
              component={InputText}
            />
            <Field
              id="fechaCaducidad"
              name="fechaCaducidad"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_CADUCIDAD`)}
            />
            <Field
              id="lote"
              name="lote"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.LOTE')}
            />
            <Field
              id="cantidad"
              name="cantidad"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CANTIDAD')}
              claseActivo="nombre-cantidad"
            />
            <Field
              colSm={3}
              id="operario"
              name="operario"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OPERARIO')}
              component={InputSelect}
              options={comboOperarioActivo}
              valueKey="value"
              labelKey="label"
              customClass='select-operario'
            />
            <div className="clearfix"></div>
            <Field
              id="fechaInicio"
              name="fechaInicio"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_INICIO`) + '*'}
              validate={required}
            />
            <Field
              id="fechaFinal"
              name="fechaFinal"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_FINAL`) + '*'}
              onInputChange={(value) => this.calcularTerminado(value)}
              validate={required}
            />
            <Field
              colSm={3}
              id="terminado"
              name="terminado"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TERMINADO')}
              component={InputText}
              disabled={true}
            />
            <Field
              id="observaciones"
              name="observaciones"
              colSm={12}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OBSERVACIONES')}
              claseActivo="observaciones"
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.submitNuevaAlimentacion.bind(this))}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalAlimentacion}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlimentacion',
})(ModalAlimentacion))
import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import Button from '../comun/button/Button'
import ModalEnvasadoPage from '../../containers/envasado/ModalEnvasadoPage'
import FiltrosEnvasadoPage from '../../containers/envasado/FiltrosEnvasadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import { date_parser, dateTimeZones } from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'
import SimplePage from '../../containers/page/SimplePage'
import { Row, Col, Modal} from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'

class Envasado extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.ENVASADO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_ENVASADO.${this.props.combos.comboEnvasado[1].label}`)
    let estadoDescartado = this.props.t('COMUN.COMBOS.DESCARTADO.NO')
    //this.props.actions.fetchFiltrarEnvasado({estado: {...this.props.combos.comboEnvasado[1], label: estadoLabel}, fechaEntrada: fechaActual, desde: fechaActual})
    
    //Si queremos inicializar descartado a NO
    this.props.actions.fetchFiltrarEnvasado({fechaEntrada: fechaActual, desde: fechaActual, descartado: {value: false, label: estadoDescartado}})    
    this.props.actions.obtenerDatosPanelControl()
  }

  componentDidUpdate(prevProps) {
  }

  lecturaRFIDEnvasado(){
    this.props.actions.lecturaRFIDEnvasado()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      envasado: { list, checkedFiltrosEnvasado },
      actions: {abrirDetallesEnvasado, abrirModalEnvasadoMultiDosisDesdeListadoPrincipal}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idEnvasado + '-' + row.idEnvasadoMultiDosis
    )

    let listNoEnvasado = list.filter(d => d.envasado === false);
    let listEnvasado = list.filter(d => d.envasado === true);

    const idElemento = 'idEnvasadoMultiDosis'    
    const tableNoEnvasado = {
      id: 'envasadoTable',      
      multiHeader: false,
      hasCheckbox: true,
      idElemento,
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.ENVASADO.TABLA.COD_VERRACO')},
        //{id: 'codigo', name: t('EXTRACCIONES.ENVASADO.TABLA.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombrePool', name: t('EXTRACCIONES.ENVASADO.TABLA.POOL')},
        {id: 'fecha', name: t('EXTRACCIONES.ENVASADO.TABLA.FECHA'), type: 'fecha'},
        {id: 'numeroDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.NUMERO_DOSIS')},
        {id: 'nombreRaza', name: t('EXTRACCIONES.ENVASADO.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('EXTRACCIONES.ENVASADO.TABLA.LINEA_GENETICA')},
        {id: 'nombreTipoDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.TIPO_DOSIS')},
        {id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX'), type: 'numero', numDecimales: 0},
        {id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
      ],
      rows: listNoEnvasado,
      filtros: false,
      mostrarVerDetalle: false,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      onEdit: (row) => abrirDetallesEnvasado(row.idEnvasado),
      mostrarEnvasar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1),
      envasar: (row) => abrirModalEnvasadoMultiDosisDesdeListadoPrincipal(row.idEnvasado, row.idEnvasadoMultiDosis),
      leyenda: [
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO_PARCIALMENTE'), color: '#FFD700'},
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.DESCARTADO'), color: '#e27329'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        // if (row.envasadoEliminado){
        //   classRow = 'envasado-eliminado'
        // } else if (row.envasado) {
        //   classRow = 'envasado'
        // }        
        if (row.envasadoEliminado){
          classRow = 'descartado'
        } else if (row.dosisPendientes > 0){
          classRow = 'envasado-parcialmente'
        }
        return classRow
      }
    }
    tableNoEnvasado.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableNoEnvasado.initialValues['check' + row.idElemento] = checkedFiltrosEnvasado
        }
      }
    )
    
    const tableEnvasado = {
      id: 'envasadoTable',
      multiHeader: false,
      hasCheckbox: false,      
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.ENVASADO.TABLA.COD_VERRACO')},
        //{id: 'codigo', name: t('EXTRACCIONES.ENVASADO.TABLA.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombrePool', name: t('EXTRACCIONES.ENVASADO.TABLA.POOL')},
        {id: 'fecha', name: t('EXTRACCIONES.ENVASADO.TABLA.FECHA'), type: 'fecha'},
        {id: 'numeroDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.NUMERO_DOSIS')},
        {id: 'nombreRaza', name: t('EXTRACCIONES.ENVASADO.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('EXTRACCIONES.ENVASADO.TABLA.LINEA_GENETICA')},
        {id: 'nombreTipoDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.TIPO_DOSIS')},
        {id: 'index', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.INDEX'), type: 'numero', numDecimales: 0},
        {id: 'nivelGenetico', name: t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.INDEX.MODAL.NIVEL_GENETICO')},
      ],
      rows: listEnvasado,
      filtros: false,
      mostrarVerDetalle: false,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      onEdit: (row) => abrirDetallesEnvasado(row.idEnvasado),
      //mostrarEnvasar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1),
      //envasar: (row) => abrirModalEnvasadoMultiDosisDesdeListadoPrincipal(row.idEnvasado, row.idEnvasadoMultiDosis),
      leyenda: [
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO_ELIMINADO'), color: '#c4415d'},
        // {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO'), color: '#aacfdc'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.envasadoEliminado){
          classRow = 'envasado-eliminado'
        }
        // else if (row.envasado) {
        //   classRow = 'envasado'
        // }
        return classRow
      }
    }
    tableEnvasado.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEnvasado.initialValues['check' + row.idElemento] = checkedFiltrosEnvasado
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalEnvasadoPage/>
        <SimplePage t_key={"EXTRACCIONES.ENVASADO."}>
        <FiltrosEnvasadoPage idElemento={idElemento}/>
          <Row>
            <Col sm={6} style={{width: '50%', paddingRight: 0}}><h3 style={{height: '15px'}}>{t('COMUN.COMBOS.ESTADO_ENVASADO.NO_ENVASADO')}
              {this.props.panelControl.boxRfid && (
                <button type="button" className="btn btn-white" style={{marginLeft: '30px'}} onClick={()=>this.lecturaRFIDEnvasado()}>{t('RFID')}</button>  
              )}
            </h3></Col>
            <Col sm={6} style={{width: '50%', paddingLeft: 15}}><h3 style={{height: '15px'}}>{t('COMUN.COMBOS.ESTADO_ENVASADO.ENVASADO')}</h3></Col>
            <Col sm={6}>
              <div>
                <SimpleTablePage {...tableNoEnvasado}></SimpleTablePage>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <SimpleTablePage {...tableEnvasado}></SimpleTablePage>
              </div>
            </Col>
          </Row>
        </SimplePage>
      </div>
    )
  }
}

export default Envasado
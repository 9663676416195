import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosProducto from '../../components/producto/FiltrosProducto'
import { comboNombreProductos, comboReferenciaProductos } from '../../actions/combos/combos'
import {abrirModalProducto, filtrarProducto, seleccionarTodosProducto, deSeleccionarTodosProducto, cambiarEstadoSeleccionProducto, deleteSeleccionProducto, crearCsvProducto,
  imprimirPdfProducto} from '../../actions/producto/producto'

export function mapStateToProps (state) {
  return {
    ...state.producto,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    abrirModalProducto,
    filtrarProducto,
    comboNombreProductos,
    comboReferenciaProductos,
    seleccionarTodosProducto,
    deSeleccionarTodosProducto,
    cambiarEstadoSeleccionProducto,
    deleteSeleccionProducto,
    crearCsvProducto,
    imprimirPdfProducto
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosProducto))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import Dropzone from 'react-dropzone'
import { CloudUpload, Delete, Warning, AddCircleOutline } from '@material-ui/icons'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalGruposUsuarioPage from '../../containers/usuarios/ModalGruposUsuarioPage'
import ModalListadoCentroPage from '../../containers/usuarios/ModalListadoCentroPage'
import ModalCambiarTokenMagavisionPage from '../../containers/usuarios/ModalCambiarTokenMagavisionPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalUsuarios extends Component {
  constructor (props) {
    super()
    this.state = {
      errorTipoUsuarios: false,
      imagen: '',
      accepted: '',
      rejected: '',
      imputSelectedGrupoUsuario: false,
      imputSelectedCentro: false,
      classErrorGruposUsuario: false,
      classErrorCentro: false,
      dataNuevoUsuario: '',
      usuarioInsertado: '',
      separadorDec: '',
      separadorMil: ''
    }
  }

  UNSAFE_componentWillMount () {
    this.props.change('idioma', {value: 'es', label: this.props.t('COMUN.COMBOS.IDIOMA.ESPANOL')})
    this.props.change('formaFecha', {value: 'day/month/year', label: 'day/month/year'})
    this.props.change('formaHora', {value: '/24', label: '24h'})
    this.props.change('separadorDec', {value: ',', label: ','})
    this.props.change('separadorMil', {value: '.', label: '.'})
    this.setState({separadorDec: {value: ',', label: ','}})
        this.setState({separadorMil: {value: '.', label: '.'}})
  }

  componentDidUpdate (prevProps) {
    if (this.props.imagen !== prevProps.imagen){
      this.props.change('hidden', true)
      this.props.change('hidden', false)
    }

    if (Object.keys(this.props.listGruposUsuariosAnadidos).length > 0) {
      if (this.props.listGruposUsuariosAnadidos !== prevProps.listGruposUsuariosAnadidos) {
        this.setState({classErrorGruposUsuario: false})
      }
    }

    if (Object.keys(this.props.listCentrosAnadidos).length > 0) {
      if (this.props.listCentrosAnadidos !== prevProps.listCentrosAnadidos) {
        this.setState({classErrorCentro: false})
      }
    }

    if (this.props.dataUsuario !== prevProps.dataUsuario) {
      if (Object.keys(this.props.dataUsuario).length > 0) {
        this.setState({separadorDec: this.props.dataUsuario.separadorDec})
        this.setState({separadorMil: this.props.dataUsuario.separadorMil})
        if (this.props.dataUsuario.imagen && this.props.dataUsuario.imagen !== null && Object.keys(this.props.dataUsuario.imagen).length > 0) {
          if (this.props.dataUsuario.imagen.file !== null) {
            this.setState({accepted: this.props.dataUsuario.imagen.file.imagebase64})
            this.props.actions.uploadFileUsuarios({name: this.props.dataUsuario.imagen.name, imagebase64: this.props.dataUsuario.imagen.file.imagebase64})
          }
        }
      }
    }

    if (this.props.dataUsuarioDuplicar !== prevProps.dataUsuarioDuplicar) {
      if (Object.keys(this.props.dataUsuarioDuplicar).length > 0) {
        this.setState({separadorDec: this.props.dataUsuarioDuplicar.separadorDec})
        this.setState({separadorMil: this.props.dataUsuarioDuplicar.separadorMil})
        if (this.props.dataUsuarioDuplicar.imagen && this.props.dataUsuarioDuplicar.imagen !== null && Object.keys(this.props.dataUsuarioDuplicar.imagen).length > 0) {
          if (this.props.dataUsuarioDuplicar.imagen.file !== null) {
            this.setState({accepted: this.props.dataUsuarioDuplicar.imagen.file.imagebase64})
            this.props.actions.uploadFileUsuarios({name: this.props.dataUsuarioDuplicar.imagen.name, imagebase64: this.props.dataUsuarioDuplicar.imagen.file.imagebase64})
          }
        }
      }
    }

    if (this.props.dataNuevoUsuario !== prevProps.dataNuevoUsuario) {
      if (this.props.dataNuevoUsuario.insertado === false && this.props.dataNuevoUsuario.existeUsuario === true) {
        this.setState({usuarioInsertado: true})
      } else {
        this.setState({usuarioInsertado: false})
      }
      if (this.props.dataNuevoUsuario.actualizado === false && this.props.dataNuevoUsuario.existeUsuario === true) {
        this.setState({usuarioInsertado: true})
      } else {
        this.setState({usuarioInsertado: false})
      }
    }

    if (this.props.showModalUsuarios !== prevProps.showModalUsuarios && Object.keys(this.props.dataUsuario).length === 0 && Object.keys(this.props.dataUsuarioDuplicar).length === 0) {
      this.props.change('idioma', {value: 'es', label: this.props.t('COMUN.COMBOS.IDIOMA.ESPANOL')})
      this.props.change('formaFecha', {value: 'day/month/year', label: 'day/month/year'})
      this.props.change('formaHora', {value: '/24', label: '24h'})
      this.props.change('separadorDec', {value: ',', label: ','})
      this.props.change('separadorMil', {value: '.', label: '.'})
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && (this.state.classErrorGruposUsuario === true || this.state.classErrorCentro === true)) {
      this.props.dispatch(setSubmitFailed('ModalUsuarios'))
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-grupo-usuarios', false)
            this.props.change('seleccionar-todos-centros', false)
            this.setState({imputSelectedGrupoUsuario: false})
            this.setState({imputSelectedCentro: false})
            this.props.actions.deSeleccionarTodosTablaGruposUsuario()
            this.props.actions.deSeleccionarTodosTablaCentros()
          }
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.fetchCentrosUsuarios(this.props.listCentrosAnadidos)
    this.props.actions.fetchUsuarioGrupoUsuarios(this.props.listGruposUsuariosAnadidos)
    this.props.change('activo', true)
  }

  submitNuevoUsuario (values) {
    if (Object.keys(this.props.listGruposUsuariosAnadidos).length > 0 && Object.keys(this.props.listCentrosAnadidos).length > 0) {
      values.imagen = this.props.imagen
      values.centros = this.props.listCentrosAnadidos
      values.grupos = this.props.listGruposUsuariosAnadidos
      values.idioma = values.idioma.value
      values.separadorDec = values.separadorDec.label
      values.separadorMil = values.separadorMil.label
      values.formaFechaHora = values.formaFecha.value + values.formaHora.value
      values.zonaHoraria = values.zonaHoraria.value
      this.setState({classErrorCentro: false})
      this.setState({classErrorGruposUsuario: false})
      if (Object.keys(this.props.dataUsuario).length > 0) {
        this.props.actions.editarUsuarios(values)
      } else {
        values.idUsuario = null
        this.props.actions.submitNuevoUsuario(values)
      }
      this.setState({accepted: ''})
    } else {
      if (Object.keys(this.props.listCentrosAnadidos).length === 0) {
        this.setState({classErrorCentro: true})
      }
       if (Object.keys(this.props.listGruposUsuariosAnadidos).length === 0) {
        this.setState({classErrorGruposUsuario: true})
      }
    }
  }
  guardarYnuevoUsuarios(values){
    this.props.actions.guardarYnuevoUsuarios(values)
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFileUsuarios(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  eliminaImagen () {
    this.setState({accepted: ''})
    this.props.actions.uploadFileUsuarios(null)
  }

  cerrarModalUsuarios () {
    this.props.actions.cerrarModalUsuarios()
    this.setState({accepted: ''})
    this.setState({classErrorGruposUsuario: false})
    this.setState({classErrorCentro: false})
    this.props.initialize()
    this.setState({usuarioInsertado: ''})
  }

  intercambiarCheckGrupoUsuarios (value) {
    this.setState({imputSelectedGrupoUsuario: value})
    if (value === true) {
      this.props.actions.seleccionarTodosTablaGruposUsuario()
    } else {
      this.props.actions.deSeleccionarTodosTablaGruposUsuario()
    }
  }
  intercambiarCheckCentros (value) {
    this.setState({imputSelectedCentro: value})
    if (value === true) {
      this.props.actions.seleccionarTodosTablaCentros()
    } else {
      this.props.actions.deSeleccionarTodosTablaCentros()
    }
  }

  eliminarGrupoUsuariosAnadidos (id) {
    let itemsSeleccion = this.props.listGruposUsuariosAnadidos.filter((row) => {
      if (row.idGrupoUsuario !== id) {
         return (
          {
            'idGrupoUsuario': row.idGrupoUsuario,
            'idElemento': row.idGrupoUsuario,
            'nombreGrupo': row.nombreGrupo
          }
        )
      }
    })
    this.props.actions.eliminarGrupoUsuariosAnadidos(itemsSeleccion)
  }

  deleteSeleccionGrupoUsuarios () {
    const itemsSeleccion = this.props.listGruposUsuariosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idGrupoUsuario': row.idGrupoUsuario,
          'nombreGrupo': row.nombreGrupo
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === false;
    })
    this.props.actions.eliminarGrupoUsuariosAnadidos(list)
    this.props.actions.deSeleccionarTodosTablaGruposUsuario()
  }

  eliminarCentrosAnadidos (id) {
    let itemsSeleccion = this.props.listCentrosAnadidos.filter((row) => {
      if (row.idCentro !== id) {
         return (
          {
            'idCentro': row.idCentro,
            'idElemento': row.idCentro,
            'nombreCentro': row.nombreCentro
          }
        )
      }
    })
    this.props.actions.eliminarCentrosAnadidos(itemsSeleccion)
  }

  deleteSeleccionCentros () {
    const itemsSeleccion = this.props.listCentrosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCentro': row.idCentro,
          'nombreCentro': row.nombreCentro
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === false;
    })
    this.props.actions.eliminarCentrosAnadidos(list)
    this.props.actions.deSeleccionarTodosTablaCentros()
  }

  changeSeparadorDec (value) {
    this.setState({separadorDec: value})
    if (value.value === this.state.separadorMil.value && value.value === ',') {
      this.props.change('separadorMil', {value: '.', label: '.'})
    } else if (value.value === this.state.separadorMil.value && value.value === '.') {
      this.props.change('separadorMil', {value: ',', label: ','})
    }
  }
  changeSeparadorMil (value) {
    this.setState({separadorMil: value})
    if (value.value === this.state.separadorDec.value && value.value === ',') {
      this.props.change('separadorDec', {value: '.', label: '.'})
    } else if (value.value === this.state.separadorDec.value && value.value === '.') {
      this.props.change('separadorDec', {value: ',', label: ','})
    }
  }

  cambiarTokenMagavision (idElemento) {
    this.props.actions.cambiarTokenMagavision(idElemento)
    this.props.actions.abrirModalCambiarTokenMagavision()
  }

  render () {
    const {
      t, handleSubmit, listUsuarios, showModalUsuarios, dataUsuario, dataUsuarioDuplicar, urlRecursos, checkTablaGrupoUsuarios, checkTablaCentros, listGruposUsuariosAnadidos, listCentrosAnadidos, paginaActual,
      numeroRegistrosPorPagina,
      actions: {recuperarDatosUsuario, cerrarModalUsuarios, abrirModalListCentro, abrirModalGruposUsuarios, eliminarCentrosAnadidos},
      combos: {comboZonaHoraria}
    } = this.props

    const comboDecimalMiles = [
      {value: ',', label: ','},
      {value: '.', label: '.'}
    ]

    const comboIdioma = [
      {value: 'es', label: 'ES - ' + t('COMUN.COMBOS.IDIOMA.ESPANOL')},
      {value: 'en', label: 'EN - ' + t('COMUN.COMBOS.IDIOMA.INGLES')},
      {value: 'ch', label: 'CH - ' + t('COMUN.COMBOS.IDIOMA.CHINO')},
      {value: 'ru', label: 'RU - ' + t('COMUN.COMBOS.IDIOMA.RUSO')},
      {value: 'ja', label: 'JA - ' + t('COMUN.COMBOS.IDIOMA.JAPONES')},
      {value: 'ko', label: 'KO - ' + t('COMUN.COMBOS.IDIOMA.COREANO')}
    ]

    /*const comboFormatoFechaHora = [
      {value: 'day/month/year/12', label: 'day/month/year/12'},
      {value: 'day/month/year/24', label: 'day/month/year/24'},
      {value: 'month/day/year/12', label: 'month/day/year/12'},
      {value: 'month/day/year/24', label: 'month/day/year/24'},
      {value: 'year/month/day/12', label: 'year/month/day/12'},
      {value: 'year/month/day/24', label: 'year/month/day/24'},
      {value: 'year/day/month/12', label: 'year/day/month/12'},
      {value: 'year/day/month/24', label: 'year/day/month/24'}
    ]*/

    const comboFormatoFecha = [
      {value: 'day/month/year', label: 'day/month/year'},
      {value: 'month/day/year', label: 'month/day/year'}
    ]

    const comboFormatoHora = [
      {value: '/12', label: '12h'},
      {value: '/24', label: '24h'}
    ]

    // TABLA DE GRUPO DE USUARIOS
    listGruposUsuariosAnadidos.forEach(
      (row, idRow) => row.idElemento = 'g' + idRow
    )

    const tableGrupoUsuarios = {
      id: 'grupoUsuarioTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      tablaModal: true,
      idBlockOptions: 1,
      // noBlockOptions: true,
      columns: [
        {id: 'nombreGrupo', name: t('USUARIOS.MODAL.TABLA_GRUPOS_USUARIOS.NOMBRE')}
      ],
      rows: listGruposUsuariosAnadidos,
      mostrarModificar: false,
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1,
      onDelete: (row) => this.eliminarGrupoUsuariosAnadidos(row.idGrupoUsuario),
      initialValues: {},
    }
    tableGrupoUsuarios.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableGrupoUsuarios.initialValues['check' + row.idElemento] = checkTablaGrupoUsuarios
        }
      }
    )

    // TABLA DE CENTROS
    listCentrosAnadidos.forEach(
      (row, idRow) => row.idElemento = 'c' + idRow
    )

    const tableCentros = {
      id: 'centrosTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      tablaModal: true,
      idBlockOptions: 2,
      // noBlockOptions: true,
      columns: [
        {id: 'nombreCentro', name: t('USUARIOS.MODAL.TABLA_CENTROS.NOMBRE')},
        {id: 'tokenMagavision', name: t('USUARIOS.MODAL.TABLA_CENTROS.TOKEN_MAGAVISION')}
      ],
      rows: listCentrosAnadidos,
      mostrarModificar: false,
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1,
      onDelete: (row) => this.eliminarCentrosAnadidos(row.idCentro),
      cambiarTokenMagavision: (row) => this.cambiarTokenMagavision(row.idElemento),
      mostrarcambiarTokenMagavision: true,
      initialValues: {},
    }
    tableCentros.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableCentros.initialValues['check' + row.idElemento] = checkTablaCentros
        }
      }
    )

    return (
      <Modal show={showModalUsuarios} onHide={() => this.cerrarModalUsuarios()} backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-usuarios">
      <form className="form-usuarios">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(this.props.dataUsuario).length > 0 ? 
            t('USUARIOS.MODAL.TITULO_EDITAR')
            :
            t('USUARIOS.MODAL.TITULO_NUEVO')
          }
          </Modal.Title>
          {dataUsuario && dataUsuario.idUsuario && (
            <ButtonChangeRecord list={listUsuarios} idElemento="idUsuario" currentId={dataUsuario.idUsuario} getNextRecord={recuperarDatosUsuario} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              this.state.usuarioInsertado === true ? (
                <Col sm={12}>
                  <div role="alert" className="alert alert-danger">{t('USUARIOS.MODAL.ALERETA_USUARIO_REPETIDO')}</div>
                </Col>
              ) : null
            }
              <Field
                colSm={3}
                id="nombreUsuario"
                name="nombreUsuario"
                component={InputText}
                controlLabel={t('USUARIOS.MODAL.NOMBRE') + '*'}
                customClass='campo-modal-usuario'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={3}
                id="passwordUsuario"
                name="passwordUsuario"
                component={InputText}
                controlLabel={t('USUARIOS.MODAL.CONTRASENA') + '*'}
                customClass='campo-modal-usuario'
                maxLength={true}
                valorMaxLength={255}
              />
              <Field
                colSm={3}
                id="emailUsuario"
                name="emailUsuario"
                component={InputText}
                controlLabel={t('USUARIOS.MODAL.EMAIL') + '*'}
                customClass='campo-modal-usuario'
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                id="idioma"
                name="idioma"
                controlLabel={t('USUARIOS.MODAL.IDIOMA') + '*'}
                component={InputSelect}
                options={comboIdioma}
                valueKey="value"
                labelKey="label"
                customClass='select-usuarios'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={3}
                id="zonaHoraria"
                name="zonaHoraria"
                controlLabel={t('USUARIOS.MODAL.ZONA_HORARIA') + '*'}
                component={InputSelect}
                options={comboZonaHoraria}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={3}
                id="formaFecha"
                name="formaFecha"
                controlLabel={t('USUARIOS.MODAL.FORMATO_FECHA') + '*'}
                component={InputSelect}
                options={comboFormatoFecha}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={3}
                id="formaHora"
                name="formaHora"
                controlLabel={t('USUARIOS.MODAL.FORMATO_HORA') + '*'}
                component={InputSelect}
                options={comboFormatoHora}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={3}
                id="separadorDec"
                name="separadorDec"
                controlLabel={t('USUARIOS.MODAL.SEPARADOR_DECIMAL') + '*'}
                component={InputSelect}
                options={comboDecimalMiles}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                onInputChange={(val) => this.changeSeparadorDec(val)}
                isClearable={false}
              />
              <Field
                colSm={3}
                id="separadorMil"
                name="separadorMil"
                controlLabel={t('USUARIOS.MODAL.SEPARADOR_MILES') + '*'}
                component={InputSelect}
                options={comboDecimalMiles}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                onInputChange={(val) => this.changeSeparadorMil(val)}
                isClearable={false}
              />
              <div className="clearfix"></div>
              <Col sm={12}>
                <span className="validacion-miles">{t('PERFIL.VALIDACION_SEPARADOR_MILES_DEC')}</span>
              </Col>
              <div className="clearfix"></div>
              <Field
                id="hidden"
                name="hidden"
                colSm={0}
                inline
                component={InputCheckBox}
                claseActivo="hidden"
              />
              <div className="clearfix"></div>
              <Col sm={2}>
                <div className="imagenUsuarios">
                  <div className="content-dropzone">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected })}}
                      maxSize={2000000}
                      onDropRejected={this.onDropRejected}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}  className="dropzone">
                          <input {...getInputProps()} />
                          <CloudUpload/>
                        </div>
                      )}
                    </Dropzone>
                    {
                      (this.state.accepted !== '' && this.state.accepted !== undefined) ? (
                        <div className="eliminar-imagen-usuarios" onClick={this.eliminaImagen.bind(this)}>
                          <Delete/>
                        </div>
                      ) : null
                    }
                    <div className="imagen-perfil-usuarios">
                    {
                      this.state.accepted !== '' && this.state.accepted !== undefined ? (
                        <img src={this.state.accepted} />
                      ) : (
                        <img src={urlRecursos + 'imagen-firma.jpg'} />
                      )
                    }
                    </div>
                    <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>Imagen desamiado grande</p>
                  </div>
                </div>
              </Col>
              <Field
                id="activo"
                name="activo"
                colSm={8}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('USUARIOS.MODAL.ACTIVO')}
              />
              <div className="clearfix"></div>
              <Col sm={6}>
                <h3>{t('USUARIOS.MODAL.TABLA_GRUPOS_USUARIOS.TITLE')}</h3>
                <Row className="acciones-tabla-filtros">
                  <Col sm={10}>
                    <div className="buttons-flex">
                      {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) && [
                        <div className="custom-check-w" key="custom-check-w">
                          <Field
                            id="seleccionar-todos-grupo-usuarios"
                            name="seleccionar-todos-grupo-usuarios"
                            colSm={12}
                            inline
                            component={InputCheckBox}
                            onInputChange={(value) => this.intercambiarCheckGrupoUsuarios(value)}
                          />
                        </div>,
                        <div id="block-options1" key="block-options1" className={this.state.imputSelectedGrupoUsuario === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                          <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionGrupoUsuarios()}>
                            <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                          </button>
                          <div className="barra-buttons-min"></div>
                        </div>
                      ]}
                      <ModalGruposUsuarioPage />
                      <ModalListadoCentroPage />
                      <ModalCambiarTokenMagavisionPage />
                      <div className="button-group buttons-min">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#new-edit-modal"
                          className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2) ? ' disabled' : '')}
                          onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) ? abrirModalGruposUsuarios : undefined}
                        >
                          <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <ListadoPage noTitle={true} table={tableGrupoUsuarios}></ListadoPage>
                {
                  this.state.classErrorGruposUsuario === true ? (
                    <span className="mensaje-error-usuarios"><Warning />{t('USUARIOS.MODAL.ERROR_DEBE_HABER_GRUPOS_USUARIOS')}</span>
                  ) : null
                }
              </Col>
              <Col sm={6}>
                <h3>{t('USUARIOS.MODAL.TABLA_CENTROS.TITLE')}</h3>
                <Row className="acciones-tabla-filtros">
                  <Col sm={10}>
                    <div className="buttons-flex">
                      {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) && [
                        <div className="custom-check-w" key="custom-check-w">
                          <Field
                            id="seleccionar-todos-centros"
                            name="seleccionar-todos-centros"
                            colSm={12}
                            inline
                            component={InputCheckBox}
                            onInputChange={(value) => this.intercambiarCheckCentros(value)}
                          />
                        </div>,
                        <div id="block-options2" key="block-options2" className={this.state.imputSelectedCentro === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                          <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionCentros()}>
                            <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                          </button>
                          <div className="barra-buttons-min"></div>
                        </div>
                      ]}
                      <div className="button-group buttons-min">
                        <button
                        type="button"
                        data-toggle="modal"
                        data-target="#new-edit-modal"
                        className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1) ? abrirModalListCentro : undefined}
                        >
                          <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <ListadoPage noTitle={true} table={tableCentros}></ListadoPage>
                {
                  this.state.classErrorCentro === true ? (
                    <span className="mensaje-error-usuarios"><Warning />{t('USUARIOS.MODAL.ERROR_DEBE_HABER_CENTROS')}</span>
                  ) : null
                }
              </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            /*this.props.dataUsuario && (
              <Button type="button" className="btn btn-white" onClick={handleSubmit(duplicar)}>{t('USUARIOS.MODAL.DUPLICAR')}</Button>
            )*/
          }
          {
            /*!this.props.dataUsuario && (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoUsuarios.bind(this))}>{t('USUARIOS.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )*/
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoUsuario.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.USUARIOS] < 2)}
          >{t('USUARIOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalUsuarios()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalUsuarios',
})(ModalUsuarios))
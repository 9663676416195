import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalPedidosVenta from '../../components/pedidosVenta/ModalPedidosVenta'
import {recuperarDatosModalPedidosVenta, cerrarModalPedidosVenta, recuperarDatosModalDatosDosis, duplicarDatosModalDatosDosis, onDeleteDatosDosis, elegirClientePedidoVenta, elegirClienteSecundarioPedidoVenta, anadirPedidosVenta, editarPedidosVenta,
  crearAlbaranDesdePedido, duplicarPedidoVenta, obtenerNumeroPedido, elegirDireccionClientePedidoVenta, confirmarYnuevoPedidosVenta, vaciarCombosPedidosVenta, mostrarPasarABorradorModalPedidosVenta} from '../../actions/pedidosVenta/pedidosVenta'
import {comboClienteActivo, comboClienteSecundarioActivo, comboDireccionClienteActivo, comboOperarioPredeterminado} from '../../actions/combos/combos'
import {abrirDetallesAlbaran} from '../../actions/albaran/albaran'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    isCloud: state.auth.isCloud,
    combos: state.combos,
    formModalPedidosVenta: state.form.ModalPedidosVenta
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalPedidosVenta,
      cerrarModalPedidosVenta,
      recuperarDatosModalDatosDosis,
      duplicarDatosModalDatosDosis,
      onDeleteDatosDosis,
      comboClienteActivo,
      comboClienteSecundarioActivo,
      comboDireccionClienteActivo,
      elegirClientePedidoVenta,
      elegirClienteSecundarioPedidoVenta,
      anadirPedidosVenta,
      editarPedidosVenta,
      abrirDetallesAlbaran,
      crearAlbaranDesdePedido,
      comboOperarioPredeterminado,
      duplicarPedidoVenta,
      obtenerNumeroPedido,
      elegirDireccionClientePedidoVenta,
      confirmarYnuevoPedidosVenta,
      vaciarCombosPedidosVenta,
      mostrarPasarABorradorModalPedidosVenta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPedidosVenta))
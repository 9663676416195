import actionTypes from '../../constants/actions/panelControl/panelControl'

export function fetchPanelControl(){
  return {
    type: actionTypes.FETCH_PANEL_CONTROL
  }
}

export function fetchPanelControlSuccess(data){
  return{
    type: actionTypes.FETCH_PANEL_CONTROL_SUCCESS,
    data
  }
}

export function obtenerDatosPanelControl(value){
  return {
    type: actionTypes.OBTENER_DATOS_PANEL_CONTROL,
    value
  }
}

export function obtenerDatosPanelControlSuccess(panelControl){
  return{
    type: actionTypes.OBTENER_DATOS_PANEL_CONTROL_SUCCESS,
    panelControl
  }
}

export function guardarPanelControl(values){
  return{
    type: actionTypes.GUARDAR_PANEL_CONTROL,
    values
  }
}

export function abrirModalTeclasContaje(){
  return{
    type: actionTypes.ABRIR_MODAL_TECLAS_CONTAJE
  }
}

export function cerrarModalTeclasContaje(){
  return{
    type: actionTypes.CERRAR_MODAL_TECLAS_CONTAJE
  }
}

export function obtenerValoresTeclasContaje(){
  return{
    type: actionTypes.OBTENER_VALORES_TECLA_CONTAJE
  }
}

export function obtenerValoresTeclasContajeSuccess(teclasContaje){
  return{
    type: actionTypes.OBTENER_VALORES_TECLA_CONTAJE_SUCCESS,
    teclasContaje
  }
}

export function valoresPredefinidosTeclasContaje(){
  return{
    type: actionTypes.VALORES_PREDEFINIDOS_TECLAS_CONTAJE
  }
}

export function guardarTeclasContaje(values){
  return{
    type: actionTypes.GUARDAR_TECLAS_CONTAJE,
    values
  }
}

export function abrirModalTipoDiluyente(){
  return{
    type: actionTypes.ABRIR_MODAL_TIPO_DILUYENTE
  }
}

export function cerrarModalTipoDiluyente(){
  return{
    type: actionTypes.CERRAR_MODAL_TIPO_DILUYENTE
  }
}

export function anadirDiluyentePanelControl(values){
  return{
    type: actionTypes.ANADIR_DILUYENTE_PANEL_CONTROL,
    values
  }
}

export function obtenerTipoDiluyente(){
  return{
    type: actionTypes.OBTENER_TIPO_DILUYENTE
  }
}

export function obtenerTipoDiluyenteSuccess(tipoDiluyente){
  return{
    type: actionTypes.OBTENER_TIPO_DILUYENTE_SUCCESS,
    tipoDiluyente
  }
}

export function recuperarDatosTipoDiluyente(idTipoDiluyente){
  return{
    type: actionTypes.RECUPERAR_DATOS_TIPO_DILUYENTE,
    idTipoDiluyente
  }
}

export function recuperarDatosTipoDiluyenteSuccess(dataTipoDiluyente){
  return{
    type: actionTypes.RECUPERAR_DATOS_TIPO_DILUYENTE_SUCCESS,
    dataTipoDiluyente
  }
}

export function editarDiluyentePanelControl(values){
  return{
    type: actionTypes.EDITAR_DILUYENTE_PANEL_CONTROL,
    values
  }
}

export function duplicarDatosModalTipoDiluyente(idTipoDiluyente){
  return{
    type: actionTypes.DUPLICAR_DATOS_TIPO_DILUYENTE,
    idTipoDiluyente
  }
}

export function duplicarDatosModalTipoDiluyenteSuccess(dataDuplicarTipoDiluyente){
  return{
    type: actionTypes.DUPLICAR_DATOS_TIPO_DILUYENTE_SUCCESS,
    dataDuplicarTipoDiluyente
  }
}

export function cambiaPredeterminadoDiluyente(idTipoDiluyente){
  return{
    type: actionTypes.CAMBIA_PREDETERMINADO_DILUYENTE,
    idTipoDiluyente
  }
}

export function cambiaPredeterminadoDiluyenteSuccess(tipoDiluyentePredeterminado){
  return{
    type: actionTypes.CAMBIA_PREDETERMINADO_DILUYENTE_SUCCESS,
    tipoDiluyentePredeterminado
  }
}

export function onDeleteDiluyente(idTipoDiluyente){
  return{
    type: actionTypes.ON_DELETE_DILUYENTE,
    idTipoDiluyente
  }
}

export function onDeleteDiluyenteSuccess(tipoDiluyentePredeterminado){
  return{
    type: actionTypes.ON_DELETE_DILUYENTE_SUCCESS,
    tipoDiluyentePredeterminado
  }
}

export function seleccionarTodosTipoDiluyente(){
  return{
    type: actionTypes.SELECCIONAR_TODOS_TIPO_DILUYENTE
  }
}

export function deSeleccionarTodosTipoDiluyente(){
  return{
    type: actionTypes.DESELECCIONAR_TODOS_TIPO_DILUYENTE
  }
}

export function deleteSeleccionTipoDiluyente (listTipoDiluyente){
  return {
    type: actionTypes.DELETE_SELECCION_TIPO_DILUYENTE,
    listTipoDiluyente
  }
}

export function abrirModalTipoDosis(){
  return{
    type: actionTypes.ABRIR_MODAL_TIPO_DOSIS
  }
}

export function cerrarModalTipoDosis(){
  return{
    type: actionTypes.CERRAR_MODAL_TIPO_DOSIS
  }
}

export function anadirDosisPanelControl(values){
  return{
    type: actionTypes.ANADIR_DOSIS_PANEL_CONTROL,
    values
  }
}

export function obtenerTipoDosis(){
  return{
    type: actionTypes.OBTENER_TIPO_DOSIS
  }
}

export function obtenerTipoDosisSuccess(tipoDosis){
  return{
    type: actionTypes.OBTENER_TIPO_DOSIS_SUCCESS,
    tipoDosis
  }
}

export function recuperarDatosTipoDosis(idTipoDosis){
  return{
    type: actionTypes.RECUPERAR_DATOS_TIPO_DOSIS,
    idTipoDosis
  }
}

export function recuperarDatosTipoDosisSuccess(dataTipoDosis){
  return{
    type: actionTypes.RECUPERAR_DATOS_TIPO_DOSIS_SUCCESS,
    dataTipoDosis
  }
}

export function editarDosisPanelControl(values){
  return{
    type: actionTypes.EDITAR_DOSIS_PANEL_CONTROL,
    values
  }
}

export function duplicarDatosModalTipoDosis(idTipoDosis){
  return{
    type: actionTypes.DUPLICAR_DATOS_TIPO_DOSIS,
    idTipoDosis
  }
}

export function duplicarDatosModalTipoDosisSuccess(dataDuplicarTipoDosis){
  return{
    type: actionTypes.DUPLICAR_DATOS_TIPO_DOSIS_SUCCESS,
    dataDuplicarTipoDosis
  }
}

export function cambiaPredeterminadoDosis(idTipoDosis){
  return{
    type: actionTypes.CAMBIA_PREDETERMINADO_DOSIS,
    idTipoDosis
  }
}

export function cambiaPredeterminadoDosisSuccess(tipoDosisPredeterminado){
  return{
    type: actionTypes.CAMBIA_PREDETERMINADO_DOSIS_SUCCESS,
    tipoDosisPredeterminado
  }
}

export function onDeleteDosis(idTipoDosis){
  return{
    type: actionTypes.ON_DELETE_DOSIS,
    idTipoDosis
  }
}

export function onDeleteDosisSuccess(tipoDosisPredeterminado){
  return{
    type: actionTypes.ON_DELETE_DOSIS_SUCCESS,
    tipoDosisPredeterminado
  }
}

export function seleccionarTodosTipoDosis(){
  return{
    type: actionTypes.SELECCIONAR_TODOS_TIPO_DOSIS
  }
}

export function deSeleccionarTodosTipoDosis(){
  return{
    type: actionTypes.DESELECCIONAR_TODOS_TIPO_DOSIS
  }
}

export function deleteSeleccionTipoDosis (listTipoDosis){
  return {
    type: actionTypes.DELETE_SELECCION_TIPO_DOSIS,
    listTipoDosis
  }
}

export function abrirModalRectaColorimetro (){
  return {
    type: actionTypes.ABRIR_MODAL_RECTA_COLORIMETRO
  }
}

export function cerrarModalRectaColorimetro (){
  return {
    type: actionTypes.CERRAR_MODAL_RECTA_COLORIMETRO
  }
}

export function abrirModalAnadirColorimetro (){
  return {
    type: actionTypes.ABRIR_MODAL_ANADIR_COLORIMETRO
  }
}

export function cerrarModalAnadirColorimetro (){
  return {
    type: actionTypes.CERRAR_MODAL_ANADIR_COLORIMETRO
  }
}

export function submitAnadirColorimetro (values){
  return {
    type: actionTypes.SUBMIT_ANADIR_COLORIMETRO,
    values
  }
}

export function fetchRectaColorimetro (){
  return {
    type: actionTypes.FETCH_RECTA_COLORIMETRO
  }
}

export function fetchRectaColorimetroSuccess (listColorimetro){
  return {
    type: actionTypes.FETCH_RECTA_COLORIMETRO_SUCCESS,
    listColorimetro
  }
}

export function recuperarDatosRectaColorimetro (value){
  return {
    type: actionTypes.RECUPERAR_DATOS_RECTA_COLORIMETRO,
    value
  }
}

export function recuperarDatosRectaColorimetroSuccess (dataRectaColorimetro){
  return {
    type: actionTypes.RECUPERAR_DATOS_RECTA_COLORIMETRO_SUCCESS,
    dataRectaColorimetro
  }
}

export function editarColorimetro (values){
  return {
    type: actionTypes.EDITAR_COLORIMETRO,
    values
  }
}

export function editarColorimetroSuccess (dataRectaColorimetro){
  return {
    type: actionTypes.EDITAR_COLORIMETRO_SUCCESS,
    dataRectaColorimetro
  }
}

export function duplicarDatosModalRectaColorimetro (value){
  return {
    type: actionTypes.DUPLICAR_COLORIMETRO,
    value
  }
}

export function duplicarDatosModalRectaColorimetroSuccess (dataDuplicarRectaColorimetro){
  return {
    type: actionTypes.DUPLICAR_COLORIMETRO_SUCCESS,
    dataDuplicarRectaColorimetro
  }
}

export function cambiaPredeterminadoRectaColorimetro (value){
  return {
    type: actionTypes.CAMBIA_PREDETERMINADO_RECTA_COLORIMETRO,
    value
  }
}

export function onDeleteRectaColorimetro (value){
  return {
    type: actionTypes.ON_DELETE_RECTA_COLORIMETRO,
    value
  }
}

export function abrirModalAnadirMuestras (){
  return {
    type: actionTypes.ABRIR_MODAL_ANADIR_MUESTRAS
  }
}

export function cerrarModalAnadirMuestras (){
  return {
    type: actionTypes.CERRAR_MODAL_ANADIR_MUESTRAS
  }
}

export function anadirMuestra (muestrasAnadidas){
  return {
    type: actionTypes.ANADIR_MUESTRA,
    muestrasAnadidas
  }
}

export function recuperarDatosMuestras (idElemento) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MUESTRAS,
    idElemento
  }
}

export function recuperarDatosMuestrasSuccess (muestrasRecuperar) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MUESTRAS_SUCCESS,
    muestrasRecuperar
  }
}

export function editMuestrasAnadidas (values) {
  return {
    type: actionTypes.EDIT_MUESTAS_ANADIDAS,
    values
  }
}

export function editMuestrasAnadidasSuccess (muestrasAnadidas) {
  return {
    type: actionTypes.EDIT_MUESTAS_ANADIDAS_SUCCESS,
    muestrasAnadidas
  }
}

export function duplicarDatosModalMuestras (idElemento) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MUESTRAS,
    idElemento
  }
}

export function duplicarDatosModalMuestrasSuccess (muestrasDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MUESTRAS_SUCCESS,
    muestrasDuplicar
  }
}

export function onDeleteMuestras (idElemento) {
  return {
    type: actionTypes.ON_DELETE_MUESTRAS,
    idElemento
  }
}

export function reiniciarMuestraVerraco () {
  return {
    type: actionTypes.REINICIAR_MUESTRA_VERRACO
  }
}

export function seleccionarTodosColorimetro () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_COLORIMETRO
  }
}

export function deSeleccionarTodosColorimetro () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_COLORIMETRO
  }
}

export function deleteSeleccionRectaColorimetro (listColorimetro){
  return {
    type: actionTypes.DELETE_SELECCION_RECTA_COLORIMETRO,
    listColorimetro
  }
}

export function abrirModalAnadirVerracos () {
  return {
    type: actionTypes.ABRIR_MODAL_ANADIR_VERRACOS
  }
}

export function cerrarModalAnadirVerracos (listColorimetro){
  return {
    type: actionTypes.CERRAR_MODAL_ANADIR_VERRACOS,
    listColorimetro
  }
}

export function fetchListadoVerracos (verracosAnadidos){
  return {
    type: actionTypes.FETCH_LISTADO_VERRACOS,
    verracosAnadidos
  }
}

export function fetchListadoVerracosSuccess (listVerracos){
  return {
    type: actionTypes.FETCH_LISTADO_VERRACOS_SUCCESS,
    listVerracos
  }
}

export function seleccionarTodosVerracos () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACOS
  }
}

export function deSeleccionarTodosVerracos () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACOS
  }
}

export function anadirVerracos (verracosAnadidos) {
  return {
    type: actionTypes.ANADIR_VERRACOS,
    verracosAnadidos
  }
}

export function seleccionarTodosVerracosAnadidos () {
  return {
    type: actionTypes.SELECCIONAR_VERRACOS_ANADIDOS
  }
}

export function deSeleccionarTodosVerracosAnadidos () {
  return {
    type: actionTypes.DESELECCIONAR_VERRACOS_ANADIDOS
  }
}

export function seleccionarTodosMuestas () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MUESTRAS
  }
}

export function deSeleccionarTodosMuestas () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MUESTRAS
  }
}

export function editarVerracos (verracosAnadidos) {
  return {
    type: actionTypes.EDITAR_VERRACOS,
    verracosAnadidos
  }
}

export function valoresPredefinidosPanelControl(metodo){
  return {
    type: actionTypes.VALORES_PREDEFINIDOS_PANEL_CONTROL,
    metodo
  }
}

export function uploadFilePanelControl (file) {
  const imagen = {name: file.name, file}
  return {
    type: actionTypes.UPLOAD_FILE_PANEL_CONTROL,
    imagen
  }
}

export function uploadLogoPanelControl (file) {
  const logo = {name: file.name, file}
  return {
    type: actionTypes.UPLOAD_LOGO_PANEL_CONTROL,
    logo
  }
}

export function guardarYnuevoMuestras (muestrasAnadidas) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_MUESTRAS,
    muestrasAnadidas
  }
}

export function onDeleteVerracos (idElemento) {
  return {
    type: actionTypes.ON_DELETE_VERRACOS,
    idElemento
  }
}

export function onDeleteVerracosSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ON_DELETE_VERRACOS_SUCCESS,
    verracosAnadidos
  }
}

export function verFormula (formula) {
  return {
    type: actionTypes.VER_FORMULA,
    formula
  }
}

export function verFormulaR (formulaR) {
  return {
    type: actionTypes.VER_FORMULA_R,
    formulaR
  }
}

export function calculoRecta (formula) {
  return {
    type: actionTypes.CALCULO_RECTA,
    formula
  }
}

export function recuperarSecuenciaNumeraciones (idSecuencia) {
  return {
    type: actionTypes.RECUPERAR_SECUENCIA_NUMERACIONES,
    idSecuencia
  }
}

export function recuperarSecuenciaNumeracionesSuccess (dataSecuenciaNumeraciones) {
  return {
    type: actionTypes.RECUPERAR_SECUENCIA_NUMERACIONES_SUCCESS,
    dataSecuenciaNumeraciones
  }
}

export function abrirModalSecuenciasNumeracion () {
  return {
    type: actionTypes.ABRIR_MODAL_SECUENCIAS_NUMERACION
  }
}

export function cerrarModalSecuenciasNumeracion () {
  return {
    type: actionTypes.CERRAR_MODAL_SECUENCIAS_NUMERACION
  }
}

export function fecthSecuenciasNumeracion () {
  return {
    type: actionTypes.FETCH_SECUENCIAS_NUMERACION
  }
}

export function fecthSecuenciasNumeracionSuccess (listSecuenciaNumeraciones) {
  return {
    type: actionTypes.FETCH_SECUENCIAS_NUMERACION_SUCCESS,
    listSecuenciaNumeraciones
  }
}

export function editarSecuencia (values) {
  return {
    type: actionTypes.EDITAR_SECUENCIA,
    values
  }
}

export function resetPedido (value) {    
  return {
    type: actionTypes.RESET_PEDIDO,
    value
  }
}

export function abrirConfiguracionMagavision() {
  return{
    type: actionTypes.ABRIR_CONFIGURACION_MAGAVISION
  }
}

export default {
  fetchPanelControl,
  fetchPanelControlSuccess,
  obtenerDatosPanelControl,
  obtenerDatosPanelControlSuccess,
  guardarPanelControl,
  abrirModalTeclasContaje,
  cerrarModalTeclasContaje,
  obtenerValoresTeclasContaje,
  valoresPredefinidosTeclasContaje,
  guardarTeclasContaje,
  abrirModalTipoDiluyente,
  cerrarModalTipoDiluyente,
  anadirDiluyentePanelControl,
  obtenerTipoDiluyente,
  obtenerTipoDiluyenteSuccess,
  recuperarDatosTipoDiluyente,
  recuperarDatosTipoDiluyenteSuccess,
  editarDiluyentePanelControl,
  duplicarDatosModalTipoDiluyente,
  duplicarDatosModalTipoDiluyenteSuccess,
  cambiaPredeterminadoDiluyente,
  cambiaPredeterminadoDiluyenteSuccess,
  onDeleteDiluyente,
  onDeleteDiluyenteSuccess,
  seleccionarTodosTipoDiluyente,
  deSeleccionarTodosTipoDiluyente,
  abrirModalTipoDosis,
  cerrarModalTipoDosis,
  anadirDosisPanelControl,
  obtenerTipoDosis,
  obtenerTipoDosisSuccess,
  recuperarDatosTipoDosis,
  recuperarDatosTipoDosisSuccess,
  editarDosisPanelControl,
  duplicarDatosModalTipoDosis,
  duplicarDatosModalTipoDosisSuccess,
  cambiaPredeterminadoDosis,
  cambiaPredeterminadoDosisSuccess,
  onDeleteDosis,
  onDeleteDosisSuccess,
  seleccionarTodosTipoDosis,
  deSeleccionarTodosTipoDosis,
  deleteSeleccionTipoDosis,
  abrirModalRectaColorimetro,
  cerrarModalRectaColorimetro,
  abrirModalAnadirColorimetro,
  cerrarModalAnadirColorimetro,
  submitAnadirColorimetro,
  fetchRectaColorimetro,
  fetchRectaColorimetroSuccess,
  recuperarDatosRectaColorimetro,
  recuperarDatosRectaColorimetroSuccess,
  editarColorimetro,
  editarColorimetroSuccess,
  duplicarDatosModalRectaColorimetro,
  duplicarDatosModalRectaColorimetroSuccess,
  cambiaPredeterminadoRectaColorimetro,
  onDeleteRectaColorimetro,
  abrirModalAnadirMuestras,
  cerrarModalAnadirMuestras,
  anadirMuestra,
  recuperarDatosMuestras,
  recuperarDatosMuestrasSuccess,
  editMuestrasAnadidas,
  editMuestrasAnadidasSuccess,
  duplicarDatosModalMuestras,
  duplicarDatosModalMuestrasSuccess,
  onDeleteMuestras,
  reiniciarMuestraVerraco,
  seleccionarTodosColorimetro,
  deSeleccionarTodosColorimetro,
  deleteSeleccionRectaColorimetro,
  abrirModalAnadirVerracos,
  cerrarModalAnadirVerracos,
  fetchListadoVerracos,
  fetchListadoVerracosSuccess,
  seleccionarTodosVerracos,
  deSeleccionarTodosVerracos,
  anadirVerracos,
  seleccionarTodosVerracosAnadidos,
  deSeleccionarTodosVerracosAnadidos,
  seleccionarTodosMuestas,
  deSeleccionarTodosMuestas,
  editarVerracos,
  valoresPredefinidosPanelControl,
  uploadFilePanelControl,
  uploadLogoPanelControl,
  guardarYnuevoMuestras,
  onDeleteVerracos,
  onDeleteVerracosSuccess,
  verFormula,
  verFormulaR,
  calculoRecta,
  recuperarSecuenciaNumeraciones,
  recuperarSecuenciaNumeracionesSuccess,
  fecthSecuenciasNumeracion,
  fecthSecuenciasNumeracionSuccess,
  editarSecuencia,
  resetPedido,
  abrirConfiguracionMagavision
}

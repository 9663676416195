import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import Button from '../../comun/button/Button'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputText from '../../comun/form/InputText'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputTextArea from '../../comun/form/InputTextArea'
import InputSelect from '../../comun/form/InputSelect'
import InputNumerical from '../../comun/form/InputNumerical'
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants} from '../../../constants/analisis'
import {permisos as permisosConstant} from '../../../constants/permisos'

class ModalFormControlMorfologico extends Component {
  constructor () {
    super()
    this.state = {
      tipoAnalisisSeleccionado: null,
      archivoAnalisis: null,
      datosProcesar: null
    }
    this.handleChangeTipoAnalisis = this.handleChangeTipoAnalisis.bind(this)
    this.abrirMagavision = this.abrirMagavision.bind(this)
    this.obtenerYGuardarResultadoSistemaAnalisisControlMorfologico = this.obtenerYGuardarResultadoSistemaAnalisisControlMorfologico.bind(this)
    this.guardarControlMorfologico = this.guardarControlMorfologico.bind(this)
  }

  guardarControlMorfologico (values) {
    //console.log('guardarControlMorfologico',values)
    this.props.actions.guardarModalFormControlMorfologico(values)
  }

  handleChangeTipoAnalisis (tipoAnalisisSeleccionado) {
    this.setState({tipoAnalisisSeleccionado: tipoAnalisisSeleccionado ? tipoAnalisisSeleccionado.value : null})
  }

  abrirMagavision(tipoAnalisis) {
    //console.log('ModalFormControlMorfologico-abrirMagavision')
    this.props.actions.abrirMagavision(tipoAnalisis,false,false,true)
  }

  obtenerYGuardarResultadoSistemaAnalisisControlMorfologico() {
    this.props.actions.obtenerYGuardarResultadoSistemaAnalisisControlMorfologico(this.state.tipoAnalisisSeleccionado)
  }

  componentDidMount () {
    this.props.actions.comboOperarioActivo()
    if (this.props.tipoAnalisisPredeterminado) {
      let tipoAnalisisPredeterminado = this.props.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
        return tipoAnalisisExtraccionItem.value === this.props.tipoAnalisisPredeterminado
      })
      tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: this.props.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
      this.setState({tipoAnalisisSeleccionado: tipoAnalisisPredeterminado.value})
      this.props.change('tipoAnalisis', tipoAnalisisPredeterminado)
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.showModalFormControlMorfologico && this.props.showModalFormControlMorfologico){
      //abriendo modal, actualizar el estado del tipo analisis
      // Guardamos tambien el estado y hasta que no se produzca un cambio, deshabilitamos el boton de guardar
      let tipoAnalisisPredeterminado = this.props.tipoAnalisisPredeterminado
      if (this.props.tipoAnalisisPredeterminado) {
        tipoAnalisisPredeterminado = this.props.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
          return tipoAnalisisExtraccionItem.value === this.props.tipoAnalisisPredeterminado
        })
        if (tipoAnalisisPredeterminado) {
          tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: this.props.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
          this.setState({tipoAnalisisSeleccionado: tipoAnalisisPredeterminado.value})
          this.props.change('tipoAnalisis', tipoAnalisisPredeterminado)
        }
      }
    }
    if (this.props.tipoAnalisisPredeterminado !== prevProps.tipoAnalisisPredeterminado) {
      let tipoAnalisisPredeterminado = this.props.tipoAnalisisPredeterminado
      if (this.props.tipoAnalisisPredeterminado) {
        tipoAnalisisPredeterminado = this.props.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
          return tipoAnalisisExtraccionItem.value === this.props.tipoAnalisisPredeterminado
        })
        if (tipoAnalisisPredeterminado) {
          tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: this.props.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
          this.setState({tipoAnalisisSeleccionado: tipoAnalisisPredeterminado.value})
          this.props.change('tipoAnalisis', tipoAnalisisPredeterminado)
        }
      }
    }
  }

  render () {
    const {
      t, handleSubmit, showModalFormControlMorfologico, data, mainList,
      actions: {openModalFormControlMorfologico, openModalContajeMaquinas, closeModalFormControlMorfologico},
      combos: {comboOperarioActivo},
      auth: {versionesMagavision}
    } = this.props
    const tKey = 'SEGUIMIENTO.FORM.'
    let arrayVersionesMagavision = versionesMagavision !== null ? versionesMagavision.split(',') : []
    let comboTipoAnalisisExtraccion = []
    this.props.combos.comboTipoAnalisisExtraccion.forEach((item) => {
      if(item.value == 1){ //colorimetro
        comboTipoAnalisisExtraccion.push(item)
      }
      if(arrayVersionesMagavision.length > 0 && arrayVersionesMagavision.includes(String(item.value)) && item.value == 5){ 
        comboTipoAnalisisExtraccion.push(item)
      }
    })
    comboTipoAnalisisExtraccion = comboTipoAnalisisExtraccion.map((tipoAnalisisExtraccionItem) => {
      return {...tipoAnalisisExtraccionItem, label: t(`COMUN.COMBOS.${tipoAnalisisExtraccionItem.label}`)}
    })

    let comboTipoControl = this.props.combos.comboTipoControl
    comboTipoControl = comboTipoControl.map((item) => {
      return {...item, label: t(`COMUN.COMBOS.${item.label}`)}
    })

    let comboOperarioProcesado = comboOperarioActivo.filter(item => item.procesado===true)


    return (
      <Modal show={showModalFormControlMorfologico} onHide={closeModalFormControlMorfologico} bsSize="large" backdrop="static">
        <form onSubmit={handleSubmit(this.guardarControlMorfologico)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('PLAN_SANITARIO.CONTROL_MORFOLOGICO.FORM.TITLE_MODIFICAR')+' - '+this.props.data.nombreVerraco}</Modal.Title>
            {data && data.idSeguimiento && (
              <ButtonChangeRecord list={mainList} idElemento="idControlMorfologico" currentId={data.idControlMorfologico} getNextRecord={openModalFormControlMorfologico} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="idControlMorfologico"
                name="idControlMorfologico"
                controlLabel={t(tKey + 'FORM.CODIGO')}
                component={InputText}
                disabled={true}
              />
              <Field
                colSm={3}
                id="fechaControlMorfologico"
                name="fechaControlMorfologico"
                controlLabel={t(tKey + 'FORM.FECHA')}
                component={InputDatePicker}
              />
              <Field
                colSm={3}
                id="tipoAnalisis"
                name="tipoAnalisis"
                controlLabel={t(tKey + 'FORM.TIPO_ANALISIS')}
                component={InputSelect}
                options={comboTipoAnalisisExtraccion}
                valueKey="value"
                labelKey="label"
                onInputChange={(tipoAnalisisSeleccionado) => this.handleChangeTipoAnalisis(tipoAnalisisSeleccionado)}
                disabled={data && data.estado == 2}
              />
              {this.state.tipoAnalisisSeleccionado != null && data && data.estado == 1 &&
                <Col sm={3}>
                  {
                    this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ? (
                      <Button
                        type="button"
                        onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento)) && openModalContajeMaquinas(tipoAnalisisExtraccionConstants.COLORIMETRO, true) }
                        className={"btn btn-primary btn-input-style"}
                      >{t(tKey + 'BUTTONS.CONTAR')}</Button>
                    ) : this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER ? (
                      <Button
                        type="button"
                        onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento)) && openModalContajeMaquinas(tipoAnalisisExtraccionConstants.CAMARA_BURKER, true) }
                        className={"btn btn-primary btn-input-style"}
                      >{t(tKey + 'BUTTONS.CONCENTRACION_FA')}</Button>
                    ) : (
                      this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION
                    ) && (
                      <Button
                        type="button"
                        onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONTROL_MORFOLOGICO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento)) && this.abrirMagavision(this.state.tipoAnalisisSeleccionado) }
                        className={"btn btn-primary btn-input-style"}
                      >{t(tKey + 'BUTTONS.ABRIR_MAGAVISION')}</Button>
                    )
                  }
                </Col>
              }
            </Row>
            <Row>
              {
                (
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ) && (
                <Field
                  colSm={2}
                  id="totalAcrosomas"
                  name="totalAcrosomas"
                  numDecimales={0}
                  controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.ACROSOMAS')}
                  component={InputNumerical}
                  disabled={true}
                />
                )
              }
              {
                (
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ) && (
                <Field
                  colSm={2}
                  id="faCabezas"
                  name="faCabezas"
                  numDecimales={0}
                  controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_CABEZAS')}
                  component={InputNumerical}
                  disabled={true}
                />
                )
              }
              <Field
                colSm={2}
                id="faColas"
                name="faColas"
                numDecimales={0}
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_COLAS')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faGotaProximal"
                name="faGotaProximal"
                numDecimales={0}
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_PROXIMAL')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faGotaDistal"
                name="faGotaDistal"
                numDecimales={0}
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_DISTAL')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faTotal"
                name="faTotal"
                numDecimales={0}
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_TOTAL')}
                component={InputNumerical}
                disabled={true}
              />
            </Row>
            <Row>
              {
                (
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ) && (
                <Field
                  colSm={2}
                  id="totalAcrosomasPercent"
                  name="totalAcrosomasPercent"
                  controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                  component={InputNumerical}
                  disabled={true}
                />
                )
              }
              {
                (
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ) && (
                <Field
                  colSm={2}
                  id="faCabezasPercent"
                  name="faCabezasPercent"
                  controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                  component={InputNumerical}
                  disabled={true}
                />
                )
              }
              <Field
                colSm={2}
                id="faColasPercent"
                name="faColasPercent"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faGotaProximalPercent"
                name="faGotaProximalPercent"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faGotaDistalPercent"
                name="faGotaDistalPercent"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                component={InputNumerical}
                disabled={true}
              />
              <Field
                colSm={2}
                id="faTotalPercent"
                name="faTotalPercent"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.PERCENT')}
                component={InputNumerical}
                disabled={true}
              />
            </Row>
            <Row>
            <Field
                colSm={3}
                id="operarioProcesado"
                name="operarioProcesado"
                controlLabel={t('ANALISIS_EXTRACCIONES.FORM.FORM.OPERARIO_PROCESADO')}
                component={InputSelect}
                options={comboOperarioProcesado}
                valueKey="value"
                labelKey="label"
              />
              <Field
                colSm={3}
                id="tipoControl"
                name="tipoControl"
                controlLabel={t(tKey + 'FORM.TIPO_CONTROL')}
                component={InputSelect}
                options={comboTipoControl}
                valueKey="value"
                labelKey="label"
                isClearable={false}
              />
            </Row>
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className={'btn btn-primary' + (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2 ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2)}
            >{t(tKey + 'BUTTONS.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={closeModalFormControlMorfologico}>{t(tKey + 'BUTTONS.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalFormControlMorfologico'
})(ModalFormControlMorfologico))
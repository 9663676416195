import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePicker from '../comun/form/InputRangeDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosCliente extends Component {
  constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  componentDidMount () {
    this.props.actions.comboRazonSocialClientesActivos()
    this.props.actions.comboLocalidadClientes()
    this.props.actions.comboProvinciasClientes()
    this.props.actions.comboRuta()
    this.props.actions.filtrarCliente({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
    this.props.change('activo', {value: true, label: this.props.t('ESTADO.ACTIVO')})
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  fetchFiltrarCliente(values){
    this.props.actions.filtrarCliente(values)
  }

   componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deSeleccionarTodosCliente()
    }
    if (this.props.datosExcelCliente !== prevProps.datosExcelCliente) {
      if (this.props.datosExcelCliente.excel && this.props.datosExcelCliente.excel !== null){
        let pdf = this.props.datosExcelCliente.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PEDIDO.CLIENTES') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfCliente !== prevProps.datosPdfCliente) {
      if (this.props.datosPdfCliente.pdf && this.props.datosPdfCliente.pdf !== null){
        let pdf = this.props.datosPdfCliente.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PEDIDO.CLIENTES') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosCliente()
          }
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosCliente()
    } else {
      this.props.actions.deSeleccionarTodosCliente()
    }
  }

 cambiarEstadoSeleccionCliente(){
    const itemsSeleccion = this.props.listCliente.clientes.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCliente': row.idCliente
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarEstadoSeleccionCliente(list)
  }

  deleteSeleccionCliente(){
    const itemsSeleccion = this.props.listCliente.clientes.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCliente': row.idCliente,
          'razonSocialCliente': row.razonSocialCliente
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionCliente(list)
  }

  crearCsvCliente(){
    this.props.actions.crearCsvCliente(this.props.filtros)
  }

  imprimirPdfCliente () {
    this.props.actions.imprimirPdfCliente(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.filtrarCliente(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {
      abrirModalCliente
    },
    combos: {
      comboCifCliente, comboRazonSocialClientesActivos, comboAliasClientesActivos, comboLocalidadClientes, comboProvinciasClientes, comboRuta
    }
  } = this.props

  let comboEstado = this.props.combos.comboEstado
  comboEstado = comboEstado.map((estado) => {
    return {...estado, label: t(estado.label)}
  })

  const tKey = 'PEDIDOS.CLIENTE.'

  const comboTrazabilidad = [
    {value: true, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.TRAZABILIDAD.NO')}
  ]

  const comboValidacion = [
    {value: true, label: this.props.t('COMUN.COMBOS.VALIDACION.SI')},
    {value: false, label: this.props.t('COMUN.COMBOS.VALIDACION.NO')}
  ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="razonSocial"
              name="razonSocial"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.RAZON_SOCIAL')}
              component={InputSelect}
              options={comboRazonSocialClientesActivos}
              valueKey="value"
              labelKey="label"
              customClass='select-cliente'
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="cif"
              name="cif"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.CIF')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            {/*
            <Field
              colSm={2}
              id="alias"
              name="alias"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.ALIAS')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />*/}
            <Field
              colSm={2}
              id="localidad"
              name="localidad"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.LOCALIDAD')}
              component={InputSelect}
              options={comboLocalidadClientes}
              valueKey="value"
              labelKey="label"
              customClass='select-cliente'
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="provincia"
              name="provincia"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.PROVINCIA')}
              component={InputSelect}
              options={comboProvinciasClientes}
              valueKey="value"
              labelKey="label"
              customClass='select-cliente'
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="activo"
              name="activo"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.ACTIVO')}
              component={InputSelect}
              options={comboEstado}
              valueKey="value"
              labelKey="label"
              customClass='select-cliente'
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            <Field
              colSm={2}
              id="ruta"
              name="ruta"
              controlLabel={t('PEDIDOS.CLIENTE.FILTROS.RUTA')}
              component={InputSelect}
              options={comboRuta}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchFiltrarCliente.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.razonSocial && filtros.razonSocial !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.RAZON_SOCIAL') + ': ' + filtros.razonSocial.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('razonSocial')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.cif && filtros.cif !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.CIF') + ': ' + filtros.cif}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cif')}> x </button></span>
                    ) : null
                  )
                }
                {/*
                  Object.keys(filtros).length > 0 && (
                    filtros.alias && filtros.alias !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ALIAS') + ': ' + filtros.alias}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('alias')}> x </button></span>
                    ) : null
                  )*/
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.localidad && filtros.localidad !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.LOCALIDAD') + ': ' + filtros.localidad.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('localidad')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.provincia && filtros.provincia !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.PROVINCIA') + ': ' + filtros.provincia.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('provincia')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.activo && filtros.activo !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ACTIVO') + ': ' + filtros.activo.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('activo')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.ruta && filtros.ruta !== null ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ACTIVO') + ': ' + filtros.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarEstadoSeleccionCliente()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_ESTADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionCliente()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvCliente()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfCliente()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1) ? abrirModalCliente : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'filtrosCliente',
})(FiltrosCliente))

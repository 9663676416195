import React, { Component, forwardRef } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTime from '../comun/form/InputTime'
import './CopiaSeguridad.scss'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';

const componentDatePicker = ({input, placeholder}) => {
  let date = null
  if(input.value){
    date = moment(input.value, "HH:mm:ss").toDate();
  }

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => {
      return(
        <Button type="button" onClick={onClick} className="btn btn-primary margin-left">{value||placeholder}</Button>
      )
    }
  );

  return (
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        placeholderText={placeholder}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="Time"
        selected={date}
        onChange={date => input.onChange(date)}
        customInput={<ExampleCustomInput />}
      />
  )
}

class ModalCopiaSeguridadProgramada extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalCopiaSeguridadProgramada !== prevProps.showModalCopiaSeguridadProgramada){
      this.props.actions.obtenerProgramacionCopiaSeguridad ()
    }
  }

  submitNuevaCopiaSeguridadProgramada (values) {    
    const valoresEnviar = []
    const diasSemana = []
    if (values.lunes === true) {
      diasSemana.push(1)
    }
    if (values.martes === true) {
      diasSemana.push(2)
    }
    if (values.miercoles === true) {
      diasSemana.push(3)
    }
    if (values.jueves === true) {
      diasSemana.push(4)
    }
    if (values.viernes === true) {
      diasSemana.push(5)
    }
    if (values.sabado === true) {
      diasSemana.push(6)
    }
    if (values.domingo === true) {
      diasSemana.push(0)
    }
    valoresEnviar.hora = moment(values.hora).format("HH:mm")
    valoresEnviar.cadaHora = values.cadaHora
    valoresEnviar.diasSemana = diasSemana
    this.props.actions.submitNuevaCopiaSeguridadProgramada(valoresEnviar)
  }

  cerrarModalCopiaSeguridadProgramada () {
    this.props.actions.cerrarModalCopiaSeguridadProgramada()
  }

  render () {
    const {
      t, handleSubmit, showModalCopiaSeguridadProgramada,
      actions: {cerrarModalCopiaSeguridadProgramada},
      combos: {comboObtenerCentroUsuario}
      } = this.props

    return (
      <Modal show={showModalCopiaSeguridadProgramada}
      onHide={() => this.cerrarModalCopiaSeguridadProgramada()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-programar-copia">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('COPIA_SEGURIDAD.MODAL.TITULO_NUEVO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="clearfix"></div>
            <Field
              id="lunes"
              name="lunes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.LUNES')}
              colSm={2}
            />
            <Field
              id="martes"
              name="martes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MARTES')}
              colSm={2}
            />
            <Field
              id="miercoles"
              name="miercoles"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES')}
              colSm={2}
            />
            <Field
              id="jueves"
              name="jueves"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.JUEVES')}
              colSm={2}
            />
            <Field
              id="viernes"
              name="viernes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.VIERNES')}
              colSm={2}
            />
            <Field
              id="sabado"
              name="sabado"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.SABADO')}
              colSm={2}
            />
            <Field
              id="domingo"
              name="domingo"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO')}
              colSm={2}
            />
            <Col sm={8}>            
              <Field
                id="hora"
                name="hora"
                component={componentDatePicker}
                placeholder={t('COPIA_SEGURIDAD.HORA') + '*'}
                validate={required}
                colSm={12}
              />                                
            </Col>
            <Col sm={4}>
              <Field
                id="cadaHora"
                name="cadaHora"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('COPIA_SEGURIDAD.CADA_HORA')}  
                display="flex"            
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={handleSubmit(this.submitNuevaCopiaSeguridadProgramada.bind(this))} className="btn btn-primary">{t('COPIA_SEGURIDAD.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalCopiaSeguridadProgramada()}>{t('COPIA_SEGURIDAD.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCopiaSeguridadProgramada',
})(ModalCopiaSeguridadProgramada))

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import actionTypes from '../../../constants/actions/planSanitario/visita/visita'
import {fetchVisitaSuccess, recuperarDatosModalVisitaSuccess, duplicarDatosModalVisitaSuccess, deSeleccionarTodosVisita, fetchFiltrarVisitaSuccess, submitNuevoVisitaSuccess, cerrarModalVisitas, abrirModalVisitas,
  imprimirInformeVisitaSuccess, crearCsvVisitaSuccess, imprimirPdfVisitaSuccess} from '../../../actions/planSanitario/visita/visita'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import { yesNoModal } from '../../modal/yesNoModal'
import getVisitaList from '../../../services/planSanitario/visita/getVisitaList'
import addVisita from '../../../services/planSanitario/visita/addVisita'
import getVisita from '../../../services/planSanitario/visita/getVisita'
import editVisita from '../../../services/planSanitario/visita/editVisita'
import getVisitaParaDuplicar from '../../../services/planSanitario/visita/getVisitaParaDuplicar'
import deleteVisita from '../../../services/planSanitario/visita/deleteVisita'
import getDeleteSeleccionVisita from '../../../services/planSanitario/visita/getDeleteSeleccionVisita'
import getFiltrarVisita from '../../../services/planSanitario/visita/getFiltrarVisita'
import getImprimirInforme from '../../../services/planSanitario/visita/getImprimirInforme'
import getCrearCsvVisita from '../../../services/planSanitario/visita/getCrearCsvVisita'
import getImprimirPdfVisita from '../../../services/planSanitario/visita/getImprimirPdfVisita'
import {date_formatter, dateTimeZones} from '../../../util/formatFunctions'
import {comboEmpresaVisitas, comboContactoVistas} from '../../../actions/combos/combos'

export function * fetchVisita () {
  try {
    const list = yield call(getVisitaList, null)
    yield put(fetchVisitaSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchVisita () {
  yield takeLatest(actionTypes.FETCH_VISITA, fetchVisita)
}

export function * abrirModalVacioVisita () {
  try {
    // yield put(recuperarDatosModalVisitaSuccess(null))
    let auth = yield select(state => state.auth)
    const valores = {fechaVisita: dateTimeZones(auth.zonaHorariaString)}
    yield put(initialize('ModalVisita', valores))
    yield put(abrirModalVisitas())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioVisita () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_VISITA, abrirModalVacioVisita)
}

export function * submitNuevoVisita ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let ultimaVisita = []
    if (values.imprimir === false) {
      yield put(openSimpleModal('imprimirVisita'))
      const confirmed = yield call(yesNoModal, {modalType: 'imprimirVisita'})
      if (confirmed) {
        values.imprimir = true
        ultimaVisita = yield call(addVisita, values, auth.token)
        yield put(submitNuevoVisitaSuccess(ultimaVisita))
      } else {
        ultimaVisita = yield call(addVisita, values, auth.token)
        yield put(submitNuevoVisitaSuccess(ultimaVisita))
      }
    } else {
      ultimaVisita = yield call(addVisita, values, auth.token)
      yield put(submitNuevoVisitaSuccess(ultimaVisita))
    }
    yield put(comboEmpresaVisitas())
    yield put(comboContactoVistas())
    let filtros = yield select(state => state.visita.filtros)
    const lista = yield call(getFiltrarVisita, filtros, auth.token)
    yield put(fetchVisitaSuccess(lista))
    yield put(fetchFiltrarVisitaSuccess(filtros))
    yield put(cerrarModalVisitas())
    if (ultimaVisita.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchSubmitNuevoVisita () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_VISITA, submitNuevoVisita)
}

export function * recuperarDatosModalVisita ({idVisita}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getVisita, idVisita)
    registroModificar.diasVisita = registroModificar.diasUltimaVisita
    yield put(recuperarDatosModalVisitaSuccess(registroModificar))
    yield put(initialize('ModalVisita', registroModificar))
    yield put(abrirModalVisitas())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalVisita () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_VISITA, recuperarDatosModalVisita)
}

export function * editarVisita ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let edicionVisita = []
    if (values.imprimir === false) {
      yield put(openSimpleModal('imprimirVisita'))
      const confirmed = yield call(yesNoModal, {modalType: 'imprimirVisita'})
      if (confirmed) {
        values.imprimir = true
        edicionVisita = yield call(editVisita, values, auth.token)
        yield put(submitNuevoVisitaSuccess(edicionVisita))
      } else {
        edicionVisita = yield call(editVisita, values, auth.token)
        yield put(submitNuevoVisitaSuccess(edicionVisita))
      }
    } else {
      edicionVisita = yield call(editVisita, values, auth.token)
      yield put(submitNuevoVisitaSuccess(edicionVisita))
    }

    yield put(cerrarModalVisitas())
    yield put(comboEmpresaVisitas())
    yield put(comboContactoVistas())
    let filtros = yield select(state => state.visita.filtros)
    if (filtros.contacto && values.nombreVisita){
      filtros.contacto = {value: values.nombreVisita, label: values.nombreVisita}
    }
    if (filtros.empresa && values.empresaVisita){
      filtros.empresa = {value: values.empresaVisita, label: values.empresaVisita}
    }
    const lista = yield call(getFiltrarVisita, filtros, auth.token)
    yield put(fetchVisitaSuccess(lista))
    yield put(fetchFiltrarVisitaSuccess(filtros))
    if (edicionVisita.actualizado.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarVisita () {
  yield takeLatest(actionTypes.EDITAR_VISITA, editarVisita)
}

export function * duplicarDatosModalVisita ({idVisita}) {
  try {
    let auth = yield select(state => state.auth)
    const duplicar = yield call(getVisitaParaDuplicar, idVisita)
    duplicar.nombreVisita = duplicar.nombreVisita + ' - Copia'
    duplicar.diasVisita = duplicar.diasUltimaVisita
    yield put(duplicarDatosModalVisitaSuccess(duplicar))
    yield put(initialize('ModalVisita', duplicar))
    yield put(abrirModalVisitas())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalVisita () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_VISITA, duplicarDatosModalVisita)
}

export function * onDeleteVisita ({idVisita, contacto, empresa}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const dataDelete = yield call(deleteVisita , idVisita, null)
      yield put(comboEmpresaVisitas())
      yield put(comboContactoVistas())
      let filtros = yield select(state => state.visita.filtros)
      if (filtros.contacto && filtros.contacto.value === contacto){
        filtros.contacto = null
      }
      if (filtros.empresa && filtros.empresa.value === empresa){
        filtros.empresa = null
      }
      let auth = yield select(state => state.auth)
      const lista = yield call(getFiltrarVisita, filtros, auth.token)
      yield put(fetchVisitaSuccess(lista))
      yield put(fetchFiltrarVisitaSuccess(filtros))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteVisita () {
  yield takeLatest(actionTypes.ON_DELETE_VISITA, onDeleteVisita)
}

export function * deleteSeleccionVisita ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccionVisita, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosVisita())
      yield put(initialize('filtrosVisita'))
       yield put(comboEmpresaVisitas())
      yield put(comboContactoVistas())
      let filtros = yield select(state => state.visita.filtros)
      list.forEach((li) => {
        if (filtros.contacto && filtros.contacto.value === li.contacto){
          filtros.contacto = null
        }
        if (filtros.empresa && filtros.empresa.value === li.empresa){
          filtros.empresa = null
        }
      })
      const lista = yield call(getFiltrarVisita, filtros, auth.token)
      yield put(fetchVisitaSuccess(lista))
      yield put(fetchFiltrarVisitaSuccess(filtros))
      yield put(initialize('filtrosVisita', filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionVisita () {
  yield takeLatest(actionTypes.DELETE_SELECCION_VISITA, deleteSeleccionVisita)
}

export function * guardarYnuevoVisita ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const ultimaVisita = yield call(addVisita, values, auth.token)
    yield put(submitNuevoVisitaSuccess(ultimaVisita))
    yield put(initialize('ModalVisita'))
    yield call(fetchVisita)
    if (ultimaVisita.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(comboEmpresaVisitas())
    yield put(comboContactoVistas())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarYnuevoVisita () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_VISITA, guardarYnuevoVisita)
}

export function * fetchFiltrarVisita ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaVisitaDesde = values.fechaEntrada
    values.fechaVisitaHasta = values.fechaSalida
    const list = yield call(getFiltrarVisita, values, auth.token)
    yield put(fetchVisitaSuccess(list))
    yield put(fetchFiltrarVisitaSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarVisita () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_VISITA, fetchFiltrarVisita)
}

export function * imprimirInformeVisita ({values, idVisita}) {
  try {
    yield put(openSimpleModal('imprimirGuardar'))
    const confirmed = yield call(yesNoModal, {modalType: 'imprimirGuardar'})
    if (confirmed) {
      // guardar visita
      let auth = yield select(state => state.auth)
      values.diasUltimaVisita = values.diasVisita
      yield call(editVisita, values, auth.token)
      yield put(initialize('ModalVisita', values))

      // imprimir visita
      const ultimaVisita = yield call(getImprimirInforme , idVisita, null)
      yield put(imprimirInformeVisitaSuccess(ultimaVisita))
    } else {
      yield put(initialize('ModalVisita', values))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirInformeVisita () {
  yield takeLatest(actionTypes.IMPRIMIR_INFORME_VISITA, imprimirInformeVisita)
}

export function * crearCsvVisita ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelVisita = yield call(getCrearCsvVisita, list, auth.token)
    yield put(crearCsvVisitaSuccess(datosExcelVisita))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvVisita () {
  yield takeLatest(actionTypes.CREATE_CSV_VISITA, crearCsvVisita)
}
export function * imprimirPdfVisita ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfVisita = yield call(getImprimirPdfVisita, list, auth.token)
    yield put(imprimirPdfVisitaSuccess(datosPdfVisita))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfVisita () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_VISITA, imprimirPdfVisita)
}

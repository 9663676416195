import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import './Perfil.scss'
import Dropzone from 'react-dropzone'
import { CloudUpload, Delete, Warning, AddCircleOutline } from '@material-ui/icons'
import ModalCambiarPassPerfilPage from '../../containers/perfil/ModalCambiarPassPerfilPage'
import avatar from '../../assets/images/avatar.png'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'

class ModalPerfil extends Component {
  constructor (props) {
    super()
    this.state = {
      imagen: '',
      accepted: '',
      rejected: '',
      dataNuevoUsuario: '',
      usuarioInsertado: '',
      separadorDec: '',
      separadorMil: ''
    }
  }

  UNSAFE_componentWillMount () {
    this.setState({accepted: this.props.auth.imagenUsuario})
    this.props.actions.fetchPerfil()
    this.props.change('idioma', {value: 'es', label: this.props.t('COMUN.COMBOS.IDIOMA.ESPANOL')})
    this.props.change('formaFecha', {value: 'day/month/year', label: 'day/month/year'})
    this.props.change('formaHora', {value: '/24', label: '24h'})
    this.props.change('separadorDec', {value: ',', label: ','})
    this.props.change('separadorMil', {value: '.', label: '.'})
  }

  componentDidUpdate (prevProps) {
    if (this.props.imagen !== prevProps.imagen){
      this.props.change('hidden', true)
      this.props.change('hidden', false)
    }

    if (this.props.dataNuevoUsuario !== prevProps.dataNuevoUsuario) {
      if (this.props.dataNuevoUsuario.insertado === false && this.props.dataNuevoUsuario.existeUsuario === true) {
        this.setState({usuarioInsertado: true})
      } else {
        this.setState({usuarioInsertado: false})
      }
      if (this.props.dataNuevoUsuario.actualizado === false && this.props.dataNuevoUsuario.existeUsuario === true) {
        this.setState({usuarioInsertado: true})
      } else {
        this.setState({usuarioInsertado: false})
      }
    }

    if (this.props.auth !== prevProps.auth) {
      this.setState({accepted: this.props.auth.imagenUsuario})
      this.props.actions.uploadFileUsuarios(this.props.auth.imagenUsuario)
    }

    if (this.props.dataPerfil !== prevProps.dataPerfil) {
      if (this.props.dataPerfil.imagenUsuario !== null) {
        this.props.actions.uploadFileUsuarios({imagebase64: this.props.auth.imagenUsuario, name: this.props.dataPerfil.imagenUsuario.name})
      }
    }

    if (this.props.showModalPerfil !== prevProps.showModalPerfil) {
      this.props.actions.fetchPerfil()
      this.setState({accepted: this.props.auth.dataPerfil.imagenUsuario})
      this.setState({separadorDec: this.props.dataPerfil.separadorDec})
      this.setState({separadorMil: this.props.dataPerfil.separadorMil})
      if (this.props.dataPerfil.imagenUsuario !== null) {
        this.props.actions.uploadFileUsuarios({imagebase64: this.props.auth.imagenUsuario, name: this.props.dataPerfil.imagenUsuario.name})
      }
    }
  }

  submitNuevoUsuario (values) {
    values.imagenUsuario = this.props.usuarios.imagen
    values.idioma = values.idioma.value
    values.separadorDec = values.separadorDec.label
    values.separadorMil = values.separadorMil.label
    values.formaFechaHora = values.formaFecha.value + values.formaHora.value
    values.idUsuario = this.props.dataPerfil.idUsuario
    values.zonaHoraria = values.zonaHoraria.value
    this.props.actions.editarPerfil(values)
    this.setState({accepted: ''})
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFileUsuarios(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  eliminaImagen () {
    this.setState({accepted: ''})
    this.props.actions.uploadFileUsuarios(null)
  }

  cerrarModalPerfil () {
    this.props.actions.cerrarModalPerfil()
    this.setState({accepted: ''})
    this.props.initialize()
    this.setState({usuarioInsertado: ''})
  }

  cambiarPassPerfil () {
    this.props.actions.abrirCambiarPassPerfil()
  }

  changeSeparadorDec (value) {
    this.setState({separadorDec: value})
    if (value.value === this.state.separadorMil.value && value.value === ',') {
      this.props.change('separadorMil', {value: '.', label: '.'})
    } else if (value.value === this.state.separadorMil.value && value.value === '.') {
      this.props.change('separadorMil', {value: ',', label: ','})
    }
  }
  changeSeparadorMil (value) {
    this.setState({separadorMil: value})
    if (value.value === this.state.separadorDec.value && value.value === ',') {
      this.props.change('separadorDec', {value: '.', label: '.'})
    } else if (value.value === this.state.separadorDec.value && value.value === '.') {
      this.props.change('separadorDec', {value: ',', label: ','})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalPerfil, urlRecursos, checkTablaGrupoUsuarios, paginaActual, numeroRegistrosPorPagina,
      actions: {cerrarModalPerfil},
      auth: {nombreUsuario},
      combos: {comboZonaHoraria}
    } = this.props

    const comboDecimalMiles = [
      {value: ',', label: ','},
      {value: '.', label: '.'}
    ]

    const comboIdioma = [
      {value: 'es', label: 'ES - ' + t('COMUN.COMBOS.IDIOMA.ESPANOL')},
      {value: 'en', label: 'EN - ' + t('COMUN.COMBOS.IDIOMA.INGLES')},
      {value: 'ch', label: 'CH - ' + t('COMUN.COMBOS.IDIOMA.CHINO')},
      {value: 'ru', label: 'RU - ' + t('COMUN.COMBOS.IDIOMA.RUSO')},
      {value: 'ja', label: 'JA - ' + t('COMUN.COMBOS.IDIOMA.JAPONES')},
      {value: 'ko', label: 'KO - ' + t('COMUN.COMBOS.IDIOMA.COREANO')}
    ]

    /*const comboFormatoFechaHora = [
      {value: 'day/month/year/12', label: 'day/month/year/12'},
      {value: 'day/month/year/24', label: 'day/month/year/24'},
      {value: 'month/day/year/12', label: 'month/day/year/12'},
      {value: 'month/day/year/24', label: 'month/day/year/24'},
      {value: 'year/month/day/12', label: 'year/month/day/12'},
      {value: 'year/month/day/24', label: 'year/month/day/24'},
      {value: 'year/day/month/12', label: 'year/day/month/12'},
      {value: 'year/day/month/24', label: 'year/day/month/24'}
    ]*/

    const comboFormatoFecha = [
      {value: 'day/month/year', label: 'day/month/year'},
      {value: 'month/day/year', label: 'month/day/year'}
    ]

    const comboFormatoHora = [
      {value: '/12', label: '12h'},
      {value: '/24', label: '24h'}
    ]

    return (
      <Modal show={showModalPerfil} onHide={() => this.cerrarModalPerfil()} backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-perfil">
      <form className="form-perfil" onSubmit={handleSubmit(this.submitNuevoUsuario.bind(this))}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="cabecera-modal">
              <Col sm={4}>
                <div className="imagenPerfil">
                  <div className="content-dropzone">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected })}}
                      maxSize={2000000}
                      onDropRejected={this.onDropRejected}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}  className="dropzone">
                          <input {...getInputProps()} />
                          <CloudUpload/>
                        </div>
                      )}
                    </Dropzone>
                    {
                      (this.state.accepted !== '' && this.state.accepted !== undefined && this.state.accepted !== null) ? (
                        <div className="eliminar-imagen-perfil" onClick={this.eliminaImagen.bind(this)}>
                          <Delete/>
                        </div>
                      ) : null
                    }
                    <div className="imagen-perfil">
                    {
                      this.state.accepted !== '' && this.state.accepted !== undefined && this.state.accepted !== null ? (
                        <img src={this.state.accepted} />
                      ) : (
                        <img src={avatar} />
                      )
                    }
                    </div>
                    <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>Imagen desamiado grande</p>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="data-cargo-perfil">{t('PERFIL.TITULO')}</div>
                <div className="data-name-perfil">{nombreUsuario}</div>
              </Col>
            </Col>
            <div className="clearfix"></div>
            {
              this.state.usuarioInsertado === true ? (
                <Col sm={12}>
                  <div role="alert" className="alert alert-danger">{t('USUARIOS.MODAL.ALERETA_USUARIO_REPETIDO')}</div>
                </Col>
              ) : null
            }
              <Field
                colSm={6}
                id="nombreUsuario"
                name="nombreUsuario"
                component={InputText}
                controlLabel={t('USUARIOS.MODAL.NOMBRE') + '*'}
                customClass='campo-modal-usuario'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={6}
                id="emailUsuario"
                name="emailUsuario"
                component={InputText}
                controlLabel={t('USUARIOS.MODAL.EMAIL') + '*'}
                customClass='campo-modal-usuario'
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={6}
                id="idioma"
                name="idioma"
                controlLabel={t('USUARIOS.MODAL.IDIOMA') + '*'}
                component={InputSelect}
                options={comboIdioma}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={6}
                id="zonaHoraria"
                name="zonaHoraria"
                controlLabel={t('USUARIOS.MODAL.ZONA_HORARIA') + '*'}
                component={InputSelect}
                options={comboZonaHoraria}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={6}
                id="formaFecha"
                name="formaFecha"
                controlLabel={t('USUARIOS.MODAL.FORMATO_FECHA') + '*'}
                component={InputSelect}
                options={comboFormatoFecha}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={6}
                id="formaHora"
                name="formaHora"
                controlLabel={t('USUARIOS.MODAL.FORMATO_HORA') + '*'}
                component={InputSelect}
                options={comboFormatoHora}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                isClearable={false}
              />
              <Field
                colSm={6}
                id="separadorDec"
                name="separadorDec"
                controlLabel={t('USUARIOS.MODAL.SEPARADOR_DECIMAL') + '*'}
                component={InputSelect}
                options={comboDecimalMiles}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                onInputChange={(val) => this.changeSeparadorDec(val)}
                isClearable={false}
              />
              <Field
                colSm={6}
                id="separadorMil"
                name="separadorMil"
                controlLabel={t('USUARIOS.MODAL.SEPARADOR_MILES') + '*'}
                component={InputSelect}
                options={comboDecimalMiles}
                valueKey="value"
                labelKey="label"
                customClass='select-perfil'
                disabled={false}
                validate={required}
                onInputChange={(val) => this.changeSeparadorMil(val)}
                isClearable={false}
              />
              <div className="clearfix"></div>
              <Col sm={12}>
                <span className="validacion-miles">{t('PERFIL.VALIDACION_SEPARADOR_MILES_DEC')}</span>
              </Col>
              <div className="clearfix"></div>
              <ModalCambiarPassPerfilPage />
              <Button type="button" className="btn btn-white cambiar-pass-perfil" onClick={() => this.cambiarPassPerfil()}>{t('COMUN.CAMBIAR_PASSWORD')}</Button>
              <Field
                id="hidden"
                name="hidden"
                colSm={0}
                inline
                component={InputCheckBox}
                claseActivo="hidden"
              />
              <div className="clearfix"></div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <MensajeAlertaPage/>
          <Button type="submit" className="btn btn-primary">{t('USUARIOS.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalPerfil()}>{t('USUARIOS.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalPerfil',
})(ModalPerfil))
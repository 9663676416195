import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalBioseguridadHigiene from '../../../components/planSanitario/bioseguridadHigiene/ModalBioseguridadHigiene'
import {recuperarDatosModalBioseguridadHigiene, abrirModalLimpiezaSilos, onDeleteSiloAnadido, seleccionarTodosModalBioseguridad, deSeleccionarTodosModalBioseguridad, deleteSeleccionModalSilos, abrirModalLimpiezaCorral,
  onDeleteCorralAnadido, abrirModalUbicacion, onDeleteUbicacionAnadido, deleteSeleccionModalCorral, deleteSeleccionModalUbicacion, abrirModalVerraco, abrirModalGrupoVerraco,
  onDeleteVerracoAnadidoBioseguridad, onDeleteGrupoVerracoAnadido, deleteSeleccionModalVerraco, deleteSeleccionModalGrupoVerraco, submitAnaliticaAgua, submitLimpiezaSilos,
  submitLimpiezaCorrales, submitOtros, modificarAnaliticaAgua, modificarLimpiezaSilos, modificarLimpiezaCorral, modificarOtros, guardarNuevoAnaliticaAgua, guardarNuevoLimpiezaSilos,
  guardarNuevoLimpiezaCorrales, guardarNuevoOtros, abrirModalAgendaBioseguridad, closeModalBioseguridadHigiene, duplicarDatosModalBioseguridadHigiene, subirArchivosBioseguridad, eliminarArchvioBioseguridad, obtenerArchivoContentBioseguridad, imprimirInformeBioseguridad} from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {comboOperarioPredeterminado} from '../../../actions/combos/combos'
import {abrirModalAgenda} from '../../../actions/agenda/agenda'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    formBioseguridadHigiene: state.form.ModalBioseguridadHigiene,
    auth: state.auth,
    showModalAgenda: state.agenda.modalAgenda.showModalAgenda
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalBioseguridadHigiene,
      recuperarDatosModalBioseguridadHigiene,
      abrirModalLimpiezaSilos,
      onDeleteSiloAnadido,
      seleccionarTodosModalBioseguridad,
      deSeleccionarTodosModalBioseguridad,
      deleteSeleccionModalSilos,
      abrirModalLimpiezaCorral,
      onDeleteCorralAnadido,
      abrirModalUbicacion,
      onDeleteUbicacionAnadido,
      deleteSeleccionModalCorral,
      deleteSeleccionModalUbicacion,
      abrirModalVerraco,
      abrirModalGrupoVerraco,
      onDeleteVerracoAnadidoBioseguridad,
      onDeleteGrupoVerracoAnadido,
      deleteSeleccionModalVerraco,
      deleteSeleccionModalGrupoVerraco,
      comboOperarioPredeterminado,
      submitAnaliticaAgua,
      submitLimpiezaSilos,
      submitLimpiezaCorrales,
      submitOtros,
      modificarAnaliticaAgua,
      modificarLimpiezaSilos,
      modificarLimpiezaCorral,
      modificarOtros,
      guardarNuevoAnaliticaAgua,
      guardarNuevoLimpiezaSilos,
      guardarNuevoLimpiezaCorrales,
      guardarNuevoOtros,
      abrirModalAgendaBioseguridad,
      duplicarDatosModalBioseguridadHigiene,
      subirArchivosBioseguridad,
      eliminarArchvioBioseguridad,
      obtenerArchivoContentBioseguridad,
      imprimirInformeBioseguridad,
      abrirModalAgenda
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalBioseguridadHigiene))
import React, {Component} from 'react'
import { connect } from 'react-redux'
import {FormControl} from 'react-bootstrap'
import {number_formatter, number_parser, getClosestIfOutsideOfRange} from '../../../util/formatFunctions'
const defaultNumDecimales = 2

class Numerical extends Component {
  constructor (props) {
    super(props)
    let value = '' + props.value || ''
    let normalizedSeparadorMil = props.auth.separadorMil === '.' ? '*' : props.auth.separadorMil
    let realValue = this.numberParser(value, props, {normalizedSeparadorMil})

    this.state = {
      realValue: realValue,
      showedValue: this.numberFormatter(realValue, props),
      normalizedSeparadorMil: normalizedSeparadorMil,
      hasFocus: false,
      lastOnChangeTimeoutId: null
    }

    this.placeholder = this.placeholder.bind(this);
    this.numberFormatter = this.numberFormatter.bind(this);
    this.numberParser = this.numberParser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  numberParser (value, props, state) {
    const _props = props || this.props;
    const _state = state || this.state;
    let parsedNumber = number_parser(value, (!isNaN(_props.numDecimales) ? _props.numDecimales : defaultNumDecimales), _props.auth.separadorDec, _state.normalizedSeparadorMil)
    return getClosestIfOutsideOfRange(parsedNumber, _props)
  }

  numberFormatter (value, props) {
    const _props = props || this.props;
    let valueInRange = getClosestIfOutsideOfRange(value, _props)
    if(!isNaN(_props.numDecimales) && _props.numDecimales==0){
      return valueInRange
    }else{
      return number_formatter(valueInRange, (!isNaN(_props.numDecimales) ? _props.numDecimales : defaultNumDecimales), _props.auth.separadorDec, _props.auth.separadorMil)
    }
  }

  isValid (value) {
    return !isNaN(value)
  }

  hasFormat (value) {
    return /^[+-]?[,.\d]*$/.test(value)
  }

  placeholder () {
    let placeholder = '0'
    let numDecimales = !isNaN(this.props.numDecimales) ? this.props.numDecimales : defaultNumDecimales
    if (numDecimales > 0) {
      placeholder += this.props.auth.separadorDec + '0'.repeat(numDecimales)
    }
    return placeholder
  }

  handleChange (event) {
    if (this.isValid(event.target.value) || this.hasFormat(event.target.value)) {
      let realValue = this.numberParser(event.target.value);
      let showedValue = this.numberFormatter(realValue);

      this.setState({
        realValue: realValue,
        showedValue: showedValue
      })

      let aux = realValue
      if (aux) {
        if ((this.props.numDecimales || defaultNumDecimales) > 0) {
          aux = parseFloat(aux)
        } else {
          aux = parseInt(aux, 10)
        }
      }

      if (this.isValid(aux) && this.props.onChange) {
        // Cancelar el anterior timeout y establecer el actual.
        if (this.state.lastOnChangeTimeoutId) clearTimeout(this.state.lastOnChangeTimeoutId)
        let timeoutID = setTimeout(() => {this.props.onChange(event, aux)}, this.props.onInputChangeDelay)

        // Almacenar el timeoutId actual.
        this.setState({lastOnChangeTimeoutId: timeoutID})
      }
    }
  }

  handleFocus (event) {
    this.setState({hasFocus: true})
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  handleBlur (event) {
    this.setState({hasFocus: false})
    if (this.props.onBlur) {
      this.props.onBlur(event) // TODO formatear event.target.value aquí en vez de en el InputNumerical.
    }
  }

  handleClick (event) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      let newValue = ("" + this.props.value).replace('.', this.props.auth.separadorDec)
      if (("" + this.state.realValue) !== newValue) {

        this.setState({
          realValue: this.numberParser(newValue),
          showedValue: this.numberFormatter(newValue)
        })

        if (("" + this.props.value) !== ("" + newValue)) {
          if ((this.props.numDecimales || defaultNumDecimales) > 0) {
            newValue = parseFloat(this.props.value)
          } else {
            newValue = parseInt(this.props.value, 10)
          }
          this.props.onChange(null, newValue)
        }
      }
    }
  }

  render () {
    const {
      id, disabled, placeholder = null, bsClass, componentClass, className, controlLabel, autoComplete = "on", setMyRef
    } = this.props

    return (
      <FormControl
        id={id}
        disabled={disabled}
        type="text"
        placeholder={placeholder || (!disabled ? this.placeholder() : '')}
        title={controlLabel}
        bsClass={bsClass}
        componentClass={componentClass}
        className={className}
        value={this.state.hasFocus ? ("" + this.state.realValue).replace('.', this.props.auth.separadorDec) : this.state.showedValue}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        inputRef={ref => { setMyRef && setMyRef(ref) }}
      />
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: {...state.auth}
  }
}

export default connect(mapStateToProps, null)(Numerical)

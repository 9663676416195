import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import {fetchAlbaranes, abrirDetallesAlbaran, duplicarDatosModalAlbaran, onDeleteAlbaran, imprimirAlbaran} from '../../actions/albaran/albaran'
import Albaran from '../../components/albaran/Albaran'

export function mapStateToProps (state) {
  return {
    ...state.albaran,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAlbaranes,
      abrirDetallesAlbaran,
      duplicarDatosModalAlbaran,
      onDeleteAlbaran,
      imprimirAlbaran,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Albaran))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import { Delete, AddCircleOutline, Warning, Print } from '@material-ui/icons'
import './ModalRegistroAlimentacion.scss'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import FormSubtitle from '../../comun/form/FormSubtitle'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import InputSelect from '../../comun/form/InputSelect'
import ListadoPage from '../../../containers/page/ListadoPage'
import ModalVerracoRegistroAlimentacionPage from '../../../containers/planSanitario/registroAlimentacion/ModalVerracoRegistroAlimentacionPage'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'
import {date_formatter, dateTimeZones} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'

class ModalRegistroAlimentacion extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      mensajeErrorVerracos: '',
      classErrorVerracos: false,
      fechaPrincipio: '',
      fechaFin: ''
    }
  }

  close(){
    this.setState({fechaPrincipio: ''})
    this.setState({fechaFin: ''})
    this.setState({classErrorVerracos: false})
    this.setState({mensajeErrorVerracos: ''})
    this.props.actions.closeModalRegistroAlimentacion()
  }

  componentDidMount(){
    this.props.change('fechaInicio', date_formatter(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora, false))
    this.props.actions.comboTipoAlimentacion()
    this.props.actions.comboOperarioPredeterminado()
  }

  componentDidUpdate(prevProps, prevState){
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({classErrorVerracos: false})
        this.setState({mensajeErrorVerracos: ''})
      }
    } else {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        // this.setState({classErrorVerracos: true})
        this.setState({mensajeErrorVerracos: this.props.t('MENSAJE_ALERTA.FALTA_VERRACO_ASIGNADO')})
      }
    }

    if (Object.keys(this.props.data).length > 0){
      if (this.props.data !== prevProps.data){
        this.setState({fechaPrincipio: this.props.data.alimentacion.fechaInicio})
        this.setState({fechaFin: this.props.data.alimentacion.fechaFinal})
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.classErrorVerracos === true) {
      this.props.dispatch(setSubmitFailed('ModalRegistroAlimentacion'))
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-modal-registro-alimentacion', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalRegistroAlimentacion()
          }
        }
      }
    }
  }

  submitNuevoRegistroAlimentacion (values) {
    values.verraco = this.props.verracosAnadidos
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      this.setState({classErrorVerracos: false})
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarRegistroAlimentacion(values)
        this.close()
      } else {
        this.props.actions.submitNuevoRegistroAlimentacion(values)
        this.close()
      }
    } else {
      this.setState({classErrorVerracos: true})
      this.setState({mensajeErrorVerracos: this.props.t('MENSAJE_ALERTA.FALTA_VERRACO_ASIGNADO')})
    }
  }
  guardarYnuevoRegistroAlimentacion(values){
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      this.props.actions.guardarYnuevoRegistroAlimentacion(values)
      this.setState({classErrorVerracos: false})
    } else {
      this.setState({classErrorVerracos: true})
    }
  }
  intercambiarCheckModalRegistroAlimentacion (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalRegistroAlimentacion()
    } else {
      this.props.actions.deSeleccionarTodosModalRegistroAlimentacion()
    }
  }

  deleteSeleccionVerracosRegistroAlimentacion () {
    const itemsSeleccion = this.props.verracosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'idElemento': row.idElemento
        }
      )
    })
    let listado = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item;
      }
    })
    this.props.actions.deleteSeleccionVerracosRegistroAlimentacion(listado)
  }

  calcularTerminado (value) {
    this.setState({fechaFin: value})
    let diaHoy = dateTimeZones(this.props.auth.zonaHorariaString)
    if (new Date(value).getTime() <= diaHoy.getTime()) {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.SI'))
    } else {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.NO'))
    }
  }

  handleFechaInicio (value) {
    this.setState({fechaPrincipio: value})
  }

  imprimirInformeRegistroAlimentacion (values) {
    values.verraco = this.props.verracosAnadidos
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      this.setState({classErrorVerracos: false})
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.imprimirInformeRegistroAlimentacion(values, this.props.data.alimentacion.idAlimentacion)
      }
    } else {
      this.setState({classErrorVerracos: true})
      this.setState({mensajeErrorVerracos: this.props.t('MENSAJE_ALERTA.FALTA_VERRACO_ASIGNADO')})
    }
  }

  render () {
    const {
      t, handleSubmit, list, showModalRegistroAlimentacion, data, duplicar, verracosAnadidos, checkedModalRegistroAlimentacion, numeroRegistrosPorPagina, paginaActual,
      actions: {closeModal, recuperarDatosModalRegistroAlimentacion, abrirModalVerracoRegistroAlimentacion, onDeleteVerracoAnadidoRegistroAlimentacion},
      combos: {comboTipoAlimentacion, comboOperarioPredeterminado}
    } = this.props

    verracosAnadidos.forEach(
      (row) => row.idElemento = row.idVerraco + 'verracoAlimentacion'
    )

    const tableVerracosAnadidos = {
      id: 'verracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1),
      hasOptions: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1,
      columns: [
        {id: 'codVerraco', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.NOMBRE')},
        {id: 'nombreRaza', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.LINEA_GENETICA')},
        {id: 'estadoVerraco', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.ESTADO'), stilyzer: estadoVerracoStilyzer}
      ],
      rows: verracosAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracoAnadidoRegistroAlimentacion(row.idElemento),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      tablaModal: true,
      idBlockOptions: 1,
      showModal: this.props.showModalVerracoRegistroAlimentacion,
      initialValues: {},
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableVerracosAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracosAnadidos.initialValues['check' + row.idElemento] = checkedModalRegistroAlimentacion
        }
      }
    )

    return (
      <Modal show={showModalRegistroAlimentacion} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" className="modal-registro-alimentacion" backdrop="static">
      <form className="form-RegistroAlimentacion">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.alimentacion && data.alimentacion.idAlimentacion && (
            <ButtonChangeRecord list={list} idElemento="idAlimentacion" currentId={data.alimentacion.idAlimentacion} getNextRecord={recuperarDatosModalRegistroAlimentacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={3}
              id="numeroRegistroAlimentacion"
              name="numeroRegistroAlimentacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CODIGO_ALIMENTACION')}
              component={InputText}
              disabled={true}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="tipoAlimentacion"
              name="tipoAlimentacion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TIPO_ALIMENTACION') + '*'}
              component={InputSelect}
              options={comboTipoAlimentacion}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-alimentacion'
              validate={required}
              isClearable={false}
            />
            <Field
              colSm={3}
              id="producto"
              name="producto"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.PRODUCTO')}
              component={InputText}
              maxLength={true}
              valorMaxLength={120}
            />
            <Field
              id="fechaCaducidad"
              name="fechaCaducidad"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_CADUCIDAD`)}
            />
            <Field
              id="lote"
              name="lote"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.LOTE')}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="cantidad"
              name="cantidad"
              colSm={3}
              component={InputNumerical}
              numDecimales={2}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CANTIDAD')}
              claseActivo="nombre-cantidad"
            />
            <Field
              colSm={3}
              id="operario"
              name="operario"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OPERARIO')}
              component={InputSelect}
              options={comboOperarioPredeterminado}
              valueKey="value"
              labelKey="label"
              customClass='select-operario'
            />
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-grupo-verracos">
              <FormSubtitle>{t('MENU.VERRACOS.VERRACOS')}</FormSubtitle>
              <div id="block-options1" className={this.state.imputSelected === true ? 'filtros-tabla-modal-registro-alimetacion' : 'filtros-tabla-modal-registro-alimetacion oculta-action'}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1) && [
                    <Field
                      id="seleccionar-todo-modal-registro-alimentacion"
                      name="seleccionar-todo-modal-registro-alimentacion"
                      key="seleccionar-todo-modal-registro-alimentacion"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckModalRegistroAlimentacion(value)}
                    />,
                    <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionVerracosRegistroAlimentacion()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>,
                    <ModalVerracoRegistroAlimentacionPage key="ModalVerracoRegistroAlimentacionPage"/>
                  ]}
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2) ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1) ? abrirModalVerracoRegistroAlimentacion : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracosAnadidos}>
              </ListadoPage>
              <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
            </div>
            <div className="clearfix"></div>
            <Field
              id="fechaInicio"
              name="fechaInicio"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_INICIO`) + '*'}
              validate={required}
              onInputChange={(value) => this.handleFechaInicio(value)}
              fechaPrincipio={this.state.fechaFin === '' ? '' : null}
              fechaFin={this.state.fechaFin}
            />
            <Field
              id="fechaFinal"
              name="fechaFinal"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_FINAL`)}
              onInputChange={(value) => this.calcularTerminado(value)}
              fechaPrincipio={this.state.fechaPrincipio}
              fechaFin={this.state.fechaPrincipio === '' ? '' : null}
            />
            <Field
              colSm={3}
              id="terminado"
              name="terminado"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TERMINADO')}
              component={InputText}
              disabled={true}
            />
            <Field
              id="observaciones"
              name="observaciones"
              colSm={12}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OBSERVACIONES')}
              claseActivo="observaciones"
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(data).length > 0 && (
              <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeRegistroAlimentacion.bind(this))}><Print />{t('PLANING.MODAL.IMPRIMIR')}</Button>
            )
          }
          {
            Object.keys(data).length > 0 && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            Object.keys(data).length === 0 && (
              <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoRegistroAlimentacion.bind(this)) : null}>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoRegistroAlimentacion.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] < 2)}
          >{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRegistroAlimentacion',
})(ModalRegistroAlimentacion))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalLineaGenetica from '../../components/lineaGenetica/ModalLineaGenetica'
import {recuperarDatosModal, submitNuevaLineaGenetica, editarLineaGenetica, guardarYnuevo, closeModalLineaGenetica} from '../../actions/lineaGenetica/lineaGenetica'

export function mapStateToProps (state) {
  return {
    ...state.lineaGenetica,
    auth: state.auth,
    showModal: state.common.showModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModal,
      submitNuevaLineaGenetica,
      editarLineaGenetica,
      closeModalLineaGenetica,
      guardarYnuevo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalLineaGenetica))
import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'
import './ImportarPedidos.scss'

class ImportarPedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: '',
      archivoCargado: false
    }
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosImportarPedidos({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
        this.setState({archivoCargado: true})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitImportarPedidos () {
    this.props.actions.submitImportarPedidos(this.state.archivos)
    this.setState({archivoCargado: false})
  }

  cerrarModalImportarPedidos () {
    this.props.actions.cerrarModalImportarPedidos()
    this.setState({archivos: ''})
    this.setState({archivoCargado: false})
  }

  descargarPlantillaExcelPedidos () {
    this.props.actions.descargarPlantillaExcelPedidos()
  }

  render () {
    const {
      t, handleSubmit, showModalImportarPedidos, archivo, listImportarPedidos
      } = this.props

    if(!showModalImportarPedidos) return null
    return (
      <Modal show={showModalImportarPedidos} onHide={() => this.cerrarModalImportarPedidos()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-carga-index">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('IMPORTAR_PEDIDOS.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-carga-index">
              <Dropzone
                accept=".xls"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivo).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
              ) : null
            }
            {
              Object.keys(listImportarPedidos).length > 0 ? (
                <div className="resultado-importar-pedidos">
                {
                  listImportarPedidos.esValido === 0 ? (
                    <ul>
                      <li>{t('IMPORTAR_DATOS.DOCUMENTO_NO_VALIDO')}</li>
                    </ul>
                  ) : (
                    <ul>                                          
                      <li>{t('IMPORTAR_PEDIDOS.DOCUMENTO_SUBIDO')}</li>                      
                    </ul>
                  )
                }
              </div>
              ) : null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>          
          <Button type="button" onClick={() => this.descargarPlantillaExcelPedidos()} className="btn btn-primary">{t('IMPORTAR_PEDIDOS.DESCARGAR_EJEMPLO_EXCEL')}</Button>
          {
            Object.keys(listImportarPedidos).length === 0 ? (
              <Button type="button" onClick={() => this.submitImportarPedidos()} className="btn btn-primary">{t('CARGA_INDEX.ACEPTAR')}</Button>
            ) : null
          }
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalImportarPedidos()}>{t('CARGA_INDEX.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ImportarPedidos',
})(ImportarPedidos))
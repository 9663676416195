import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ImportarPedidos from '../../components/importarPedidos/ImportarPedidos'
import {submitImportarPedidos, cerrarModalImportarPedidos, subirArchivosImportarPedidos, abrirModalImportarPedidos, descargarPlantillaExcelPedidos} from '../../actions/importarPedidos/importarPedidos'

export function mapStateToProps (state) {
  return {
    ...state.importarPedidos,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitImportarPedidos,
      cerrarModalImportarPedidos,
      subirArchivosImportarPedidos,
      abrirModalImportarPedidos,
      descargarPlantillaExcelPedidos
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportarPedidos))
import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import actionTypes from '../../constants/actions/importarVerracos/importarVerracos'
import {submitImportarVerracosSuccess} from '../../actions/importarVerracos/importarVerracos'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getImportarVerracos from '../../services/importarVerracos/getImportarVerracos'
import getDescargarPlantillaExcelVerracos from '../../services/importarVerracos/getDescargarPlantillaExcelVerracos'
import getDescargarVerracos from '../../services/importarVerracos/getDescargarVerracos'

export function * submitImportarVerracos ({value}) {
  try {
    let auth = yield select(state => state.auth)
    const listCargaIndex = yield call(getImportarVerracos, value, auth.token)
    yield put(submitImportarVerracosSuccess(listCargaIndex))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitImportarVerracos () {
  yield takeLatest(actionTypes.SUBMIT_IMPORTAR_VERRACOS, submitImportarVerracos)
}

export function * descargarPlantillaExcelVerracos () {
  try {
    const archivoExcel = yield call(getDescargarPlantillaExcelVerracos, null)
    let href = 'data:application/csv;base64,' + encodeURIComponent(archivoExcel.xls)
    let name = i18n.t('IMPORTAR_VERRACOS.EJEMPLO_EXCEL_VERRACOS') + ".xls"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDescargarPlantillaExcelVerracos () {
  yield takeLatest(actionTypes.DESCARGAR_PLANTILLA_EXCEL_VERRACOS, descargarPlantillaExcelVerracos)
}

export function * descargarVerracos () {
  try {
    const archivoExcel = yield call(getDescargarVerracos, null)
    let href = 'data:application/csv;base64,' + encodeURIComponent(archivoExcel.xls)
    let name = i18n.t('IMPORTAR_VERRACOS.EXCEL_VERRACOS') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDescargarVerracos () {
  yield takeLatest(actionTypes.DESCARGAR_VERRACOS, descargarVerracos)
}

export default {
  ABRIR_MODAL_CONDUCTOR: 'ABRIR_MODAL_CONDUCTOR',
  CLOSE_MODAL_CONDUCTOR: 'CLOSE_MODAL_CONDUCTOR',
  SUBMIT_NUEVO_CONDUCTOR: 'SUBMIT_NUEVO_CONDUCTOR',
  EDIT_CONDUCTOR_ANADIDO: 'EDIT_CONDUCTOR_ANADIDO',
  EDIT_CONDUCTOR_ANADIDO_SUCCESS: 'EDIT_CONDUCTOR_ANADIDO_SUCCESS',
  RECUPERAR_MODAL_CONDUCTOR: 'RECUPERAR_MODAL_CONDUCTOR',
  RECUPERAR_MODAL_CONDUCTOR_SUCCESS: 'RECUPERAR_MODAL_CONDUCTOR_SUCCESS',
  CAMBIAR_ESTADO_CONDUCTOR: 'CAMBIAR_ESTADO_CONDUCTOR',
  CAMBIAR_ESTADO_CONDUCTOR_SUCCESS: 'CAMBIAR_ESTADO_CONDUCTOR_SUCCESS',
  ON_DELETE_CONDUCTOR_ANADADIDO: 'ON_DELETE_CONDUCTOR_ANADADIDO',
  FETCH_TRANSPORTISTA: 'FETCH_TRANSPORTISTA',
  FETCH_TRANSPORTISTA_SUCCESS: 'FETCH_TRANSPORTISTA_SUCCESS',
  FILTRAR_TRANSPORTE: 'FILTRAR_TRANSPORTE',
  FILTRAR_TRANSPORTE_SUCCESS: 'FILTRAR_TRANSPORTE_SUCCESS',
  DUPLICAR_CONDUCTOR: 'DUPLICAR_CONDUCTOR',
  DUPLICAR_CONDUCTOR_SUCCESS: 'DUPLICAR_CONDUCTOR_SUCCESS',
  CONDUCTOR_POR_DEFECTO: 'CONDUCTOR_POR_DEFECTO',
  CONDUCTOR_POR_DEFECTO_SUCCESS: 'CONDUCTOR_POR_DEFECTO_SUCCESS',
  REINICIAR_CONDUCTORES_VEHICULOS_RUTAS: 'REINICIAR_CONDUCTORES_VEHICULOS_RUTAS',
  ABRIR_MODAL_VEHICULO: 'ABRIR_MODAL_VEHICULO',
  CLOSE_MODAL_VEHICULO: 'CLOSE_MODAL_VEHICULO',
  SUBMIT_NUEVO_VEHICULO: 'SUBMIT_NUEVO_VEHICULO',
  RECUPERAR_MODAL_VEHICULO: 'RECUPERAR_MODAL_VEHICULO',
  RECUPERAR_MODAL_VEHICULO_SUCCESS: 'RECUPERAR_MODAL_VEHICULO_SUCCESS',
  EDIT_VEHICULO_ANADIDO: 'EDIT_VEHICULO_ANADIDO',
  EDIT_VEHICULO_ANADIDO_SUCCESS: 'EDIT_VEHICULO_ANADIDO_SUCCESS',
  CAMBIAR_ESTADO_VEHICULO: 'CAMBIAR_ESTADO_VEHICULO',
  CAMBIAR_ESTADO_VEHICULO_SUCCESS: 'CAMBIAR_ESTADO_VEHICULO_SUCCESS',
  ON_DELETE_VEHICULO_ANADADIDO: 'ON_DELETE_VEHICULO_ANADADIDO',
  DUPLICAR_VEHICULO: 'DUPLICAR_VEHICULO',
  DUPLICAR_VEHICULO_SUCCESS: 'DUPLICAR_VEHICULO_SUCCESS',
  VEHICULO_POR_DEFECTO: 'VEHICULO_POR_DEFECTO',
  VEHICULO_POR_DEFECTO_SUCCESS: 'VEHICULO_POR_DEFECTO_SUCCESS',
  ABRIR_MODAL_RUTA: 'ABRIR_MODAL_RUTA',
  CLOSE_MODAL_RUTA: 'CLOSE_MODAL_RUTA',
  FILTRAR_RUTAS_TRANSPORTE: 'FILTRAR_RUTAS_TRANSPORTE',
  FILTRAR_RUTAS_TRANSPORTE_SUCCESS: 'FILTRAR_RUTAS_TRANSPORTE_SUCCESS',
  SELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA: 'SELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA',
  DESELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA: 'DESELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA',
  ANADIR_RUTAS_TRANPORTISTA: 'ANADIR_RUTAS_TRANPORTISTA',
  ON_DELETE_RUTA_ANADADIDA: 'ON_DELETE_RUTA_ANADADIDA',
  ON_DELETE_RUTA_ANADADIDA_SUCCESS: 'ON_DELETE_RUTA_ANADADIDA_SUCCESS',
  RUTA_POR_DEFECTO: 'RUTA_POR_DEFECTO',
  RUTA_POR_DEFECTO_SUCCESS: 'RUTA_POR_DEFECTO_SUCCESS',
  FILTROS_APLICADOS: 'FILTROS_APLICADOS',
  SUBMIT_NUEVO_TRANSPORTISTA: 'SUBMIT_NUEVO_TRANSPORTISTA',
  GUARDAR_Y_NUEVO_TRANSPORTE: 'GUARDAR_Y_NUEVO_TRANSPORTE',
  GUARDAR_Y_NUEVO_CONDUCTOR: 'GUARDAR_Y_NUEVO_CONDUCTOR',
  GUARDAR_Y_NUEVO_VEHICULO: 'GUARDAR_Y_NUEVO_VEHICULO',
  SELECCIONAR_TODOS_CONDUCTORES: 'SELECCIONAR_TODOS_CONDUCTORES',
  DESELECCIONAR_TODOS_CONDUCTORES: 'DESELECCIONAR_TODOS_CONDUCTORES',
  SELECCIONAR_TODOS_VEHICULOS: 'SELECCIONAR_TODOS_VEHICULOS',
  DESELECCIONAR_TODOS_VEHICULOS: 'DESELECCIONAR_TODOS_VEHICULOS',
  SELECCIONAR_TODOS_RUTAS: 'SELECCIONAR_TODOS_RUTAS',
  DESELECCIONAR_TODOS_RUTAS: 'DESELECCIONAR_TODOS_RUTAS',
  DELETE_SELECCION_CONDUCTOR: 'DELETE_SELECCION_CONDUCTOR',
  DELETE_SELECCION_VEHICULO: 'DELETE_SELECCION_VEHICULO',
  DELETE_SELECCION_RUTA_TRANSPORTISTA: 'DELETE_SELECCION_RUTA_TRANSPORTISTA',
  FETCH_EDITAR_TRANSPORTISTA: 'FETCH_EDITAR_TRANSPORTISTA',
  SUBMIT_EDITAR_TRANSPORTISTA: 'SUBMIT_EDITAR_TRANSPORTISTA',
  CAMBIAR_ESTADO_TRANSPORTE: 'CAMBIAR_ESTADO_TRANSPORTE',
  CAMBIAR_ESTADO_TRANSPORTE_SUCCESS: 'CAMBIAR_ESTADO_TRANSPORTE_SUCCESS',
  ON_DELETE_TRANSPORTE: 'ON_DELETE_TRANSPORTE',
  ON_DELETE_TRANSPORTE_SUCCESS: 'ON_DELETE_TRANSPORTE_SUCCESS',
  SELECCIONAR_TODOS_TRANSPORTE: 'SELECCIONAR_TODOS_TRANSPORTE',
  DESELECCIONAR_TODOS_TRANSPORTE: 'DESELECCIONAR_TODOS_TRANSPORTE',
  CAMBIO_ESTADO_SELECCION_TRANSPORTE: 'CAMBIO_ESTADO_SELECCION_TRANSPORTE',
  DELETE_SELECCION_TRANSPORTE: 'DELETE_SELECCION_TRANSPORTE',
  CREATE_CSV_TRANSPORTE: 'CREATE_CSV_TRANSPORTE',
  CREATE_CSV_TRANSPORTE_SUCCESS: 'CREATE_CSV_TRANSPORTE_SUCCESS',
  IMPRIMIR_PDF_TRANSPORTE: 'IMPRIMIR_PDF_TRANSPORTE',
  IMPRIMIR_PDF_TRANSPORTE_SUCCESS: 'IMPRIMIR_PDF_TRANSPORTE_SUCCESS'
}

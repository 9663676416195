import actionTypes from '../../constants/actions/cargaIndex/cargaIndex'

export function initialState () {
  return {
    showModalCargaIndex: false,
    archivo: [],
    listCargaIndex: []
  }
}

export function abrirModalCargaIndex (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalCargaIndex: true
  }
}

export function cerrarModalCargaIndex (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalCargaIndex: false,
    archivo: [],
    listCargaIndex: []
  }
}

export function subirArchivosCargaIndex (state, {archivo}) {
  return {
    ...state,
    archivo: archivo
  }
}

export function submitCargaIndexSuccess (state, {listCargaIndex}) {
  return {
    ...state,
    listCargaIndex: listCargaIndex
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_CARGA_INDEX:
      return abrirModalCargaIndex(state, action)
     case actionTypes.CERRAR_MODAL_CARGA_INDEX:
      return cerrarModalCargaIndex(state, action)
     case actionTypes.SUBIR_ARCHIVOS_CARGA_INDEX:
      return subirArchivosCargaIndex(state, action)
     case actionTypes.SUBMIT_CARGA_INDEX_SUCCESS:
      return submitCargaIndexSuccess(state, action)
    default:
      return state
  }
}

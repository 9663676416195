import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosControlSangre from '../../../components/planSanitario/controlSangre/FiltrosControlSangre'
import {
  fetchControlSangre, seleccionarTodosControlSangre, deSeleccionarTodosControlSangre, deleteSeleccionControlSangre,
  crearCsvControlSangre, imprimirPdfControlSangre, abrirModalInicio, fetchFiltrarModalInicio, imprimirEtiquetaControlSangre
} from '../../../actions/planSanitario/controlSangre/controlSangre'
import {comboVerraco, comboRaza, comboLineaGenetica} from '../../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.controlSangre,
    auth: state.auth,
    controlSangreTable: state.form.controlSangreTable,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerraco: state.combos.comboVerraco,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
      comboTipoControlSangre: state.combos.comboTipoControlSangre
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchControlSangre,
      seleccionarTodosControlSangre,
      deSeleccionarTodosControlSangre,
      deleteSeleccionControlSangre,
      comboVerraco,
      comboRaza,
      comboLineaGenetica,
      crearCsvControlSangre,
      imprimirPdfControlSangre,
      abrirModalInicio,
      fetchFiltrarModalInicio,
      imprimirEtiquetaControlSangre
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosControlSangre))
import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, reset, change, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import {date_parser, round_decimal, dateTimeZones} from '../../util/formatFunctions'
import {downloadDocument} from '../../util/util'

// Constants
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants, translateTipoAnalisisExtraccion} from '../../constants/analisis'
import {translateTipoControlSeguimiento} from '../../constants/seguimiento'
import actionTypes from '../../constants/actions/seguimiento/seguimiento'

// Actions
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {
  fetchSeguimientosSuccess, openModalListadoSeguimientoSuccess, closeModalListadoSeguimientoSuccess,
  deSeleccionarTodosModalSeguimiento, openModalFormSeguimientoSuccess, guardarModalFormSeguimientoSuccess,
  closeModalFormSeguimientoSuccess, fetchFiltrarModalNuevoSeguimientoSuccess
} from '../../actions/seguimiento/seguimiento'
import {
  updateTokenDtvMainMagavisionSuccess
} from '../../actions/analisisExtracciones/analisisExtracciones'
import {
  updateTokenDtvMagavisionPoolSuccess
} from '../../actions/poolExtracciones/poolExtracciones'

// Services
import getSeguimientosListService from '../../services/seguimiento/getSeguimientosList'
import getFiltrarSeguimientosListService from '../../services/seguimiento/getFiltrarSeguimientosList'
import deleteSeguimientoService from '../../services/seguimiento/deleteSeguimiento'
import deleteSeleccionSeguimientoService from '../../services/seguimiento/deleteSeleccionSeguimiento'
import getCrearCsvSeguimientoService from '../../services/seguimiento/getCrearCsvSeguimiento'
import getImprimirPdfSeguimientoService from '../../services/seguimiento/getImprimirPdfSeguimiento'
import getSeguimientoService from '../../services/seguimiento/getSeguimiento'
import submitNuevoSeguimientoService from '../../services/seguimiento/submitNuevoSeguimiento'
import savePrimerSeguimientoDelPoolService from '../../services/seguimiento/savePrimerSeguimientoDelPool'
import submitEditarSeguimientoService from '../../services/seguimiento/submitEditarSeguimiento'
import iniciarServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/iniciarServidorLecturaOldMagavision'
import comprobarServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/comprobarServidorLecturaOldMagavision'
import leerDatosServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/leerDatosServidorLecturaOldMagavision'
import leerDatosServidorLecturaNewMagavisionService from '../../services/analisisExtracciones/leerDatosServidorLecturaNewMagavision'
import getFiltrarModalNuevoSeguimientoService from '../../services/seguimiento/getFiltrarModalNuevoSeguimiento'
import addMultiSeguimientoService from '../../services/seguimiento/addMultiSeguimiento'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {fetchTokenMagavision as fetchTokenMagavisionSaga, recuperarDatosFormAnalisisExtraccion as recuperarDatosFormAnalisisExtraccionSaga, cerrarNuevoMagavision as cerrarNuevoMagavisionSaga} from '../analisisExtracciones/analisisExtracciones'
import {fetchTokenMagavisionPool as fetchTokenMagavisionPoolSaga, recuperarDatosFormPoolExtraccion as recuperarDatosFormPoolExtraccionSaga} from '../poolExtracciones/poolExtracciones'

export function * fetchSeguimientos ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let mainList = []
    if (filtros && Object.keys(filtros).length > 1) {
      mainList = yield call(getFiltrarSeguimientosListService, filtros, auth.token)
    } else {
      mainList = yield call(getSeguimientosListService)
      mainList = mainList.list
    }

    mainList.length > 0 && mainList.forEach((seguimiento) => {
      seguimiento.tipoControl = (seguimiento.tipoControl ? i18n.t('COMUN.COMBOS.' + translateTipoControlSeguimiento[seguimiento.tipoControl]) : '')
      return seguimiento
    })

    yield put(fetchSeguimientosSuccess(mainList, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchSeguimientos () {
  yield takeLatest(actionTypes.FETCH_SEGUIMIENTOS, fetchSeguimientos)
}

export function * openModalListadoSeguimiento ({idAnalisis, idPool}) {
  try {
    let auth = yield select(state => state.auth)
    let {list, conservacion, codigo} = yield call(getSeguimientosListService, idAnalisis, idPool)
    list.length > 0 && list.forEach((seguimiento) => {
      seguimiento.tipoControl = (seguimiento.tipoControl ? i18n.t('COMUN.COMBOS.' + translateTipoControlSeguimiento[seguimiento.tipoControl]) : '')      
      return seguimiento
    })
    yield put(openModalListadoSeguimientoSuccess(list, conservacion, codigo, idAnalisis, idPool))
    
    if (idAnalisis && list.length > 0) {
      yield put(updateTokenDtvMainMagavisionSuccess(list[0].t_dtv_main, list[0].nombreVerracoPool))
    } else if (idPool && list.length > 0) {
      yield put(updateTokenDtvMagavisionPoolSuccess(list[0].t_dtv_main, list[0].nombreVerracoPool))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalListadoSeguimiento () {
  yield takeLatest(actionTypes.OPEN_MODAL_LISTADO_SEGUIMIENTO, openModalListadoSeguimiento)
}

export function * closeModalListadoSeguimiento () {
  try {
    let state = yield select(state => state)

    // Si se cierra el modal-listado desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
    // Si se cierra el modal-listado desde el modal de edición de analisis/pool, se actualizara el checkbox de seguimiento.
    if (state.seguimiento.esApartadoPrincipal) {
      yield call(fetchSeguimientos, {filtros: state.seguimiento.filtros})
    } else {
      let newSeguimiento = state.seguimiento.list.length > 0
      let oldSeguimiento
      let formName = ''
      if (state.seguimiento.idAnalisis) {
        formName = 'formAnalisisExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      } else if (state.seguimiento.idPool) {
        formName = 'formPoolExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      }

      if (newSeguimiento !== oldSeguimiento) {
        yield put(change(formName, 'seguimiento', newSeguimiento))
      }
    }
    yield put(closeModalListadoSeguimientoSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalListadoSeguimiento () {
  yield takeLatest(actionTypes.CLOSE_MODAL_LISTADO_SEGUIMIENTO, closeModalListadoSeguimiento)
}

export function * deleteSeguimiento ({row}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteSeguimientoService, row.idSeguimiento, null)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }

      // Si pinchado eliminar desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
      // Si pinchado eliminar desde el modal de edición de analisis/pool, se recargara el listado con los seguimientos del analisis/pool en uso.
      let seguimiento = yield select(state => state.seguimiento)
      if (seguimiento.showModalListadoSeguimiento) {
        yield call(openModalListadoSeguimiento, {idAnalisis: row.idAnalisis, idPool: row.idPool})
      } else if (seguimiento.showModalNuevoSeguimiento) {
        yield call(fetchFiltrarModalNuevoSeguimiento, {filtros: seguimiento.filtrosModalNuevoSeguimiento}) 
        yield call(fetchSeguimientos, {filtros: seguimiento.filtros})
      } else {
        yield call(fetchSeguimientos, {filtros: seguimiento.filtros})
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeguimiento () {
  yield takeLatest(actionTypes.DELETE_SEGUIMIENTO, deleteSeguimiento)
}

export function * deleteSeleccionSeguimiento ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const {eliminado} = yield call(deleteSeleccionSeguimientoService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosModalSeguimiento())
      yield put(initialize('listadoSeguimientoTable', {}))
      yield put(initialize('seguimientoTable', {}))

      // Si pinchado eliminar desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
      // Si pinchado eliminar desde el modal de edición de analisis/pool, se recargara el listado con los seguimientos del analisis/pool en uso.
      let seguimiento = yield select(state => state.seguimiento)
      if (seguimiento.showModalListadoSeguimiento) {
        yield call(openModalListadoSeguimiento, {idAnalisis: seguimiento.list[0].idAnalisis, idPool: seguimiento.list[0].idPool})
      } else {
        yield call(fetchSeguimientos, {filtros: seguimiento.filtros})
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionSeguimiento () {
  yield takeLatest(actionTypes.DELETE_SELECCION_SEGUIMIENTO, deleteSeleccionSeguimiento)
}

export function * crearCsvSeguimiento ({list}) {
  try {
    
    let auth = yield select(state => state.auth)
    // annadir filtros de seleccionados

    let filtrosSeleccionados = yield select(state => state.form.seguimientoTable.values);
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('checkseguimientoTable'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    list = {...list, idSeleccionados}  
    const datosExcelSeguimiento = yield call(getCrearCsvSeguimientoService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelSeguimiento.excel)
    let name = i18n.t('MENU.EXTRACCIONES.SEGUIMIENTO') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvSeguimiento () {
  yield takeLatest(actionTypes.CREATE_CSV_SEGUIMIENTO, crearCsvSeguimiento)
}

export function * imprimirPdfSeguimiento ({list}) {
  try {
    let auth = yield select(state => state.auth)
    let filtrosSeleccionados = yield select(state => state.form.seguimientoTable.values);    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('checkseguimientoTable'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    list = {...list, idSeleccionados}  
    const datosPdfSeguimiento = yield call(getImprimirPdfSeguimientoService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfSeguimiento.pdf)
    let name = i18n.t('MENU.EXTRACCIONES.SEGUIMIENTO') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfSeguimiento () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_SEGUIMIENTO, imprimirPdfSeguimiento)
}

export function * openModalFormSeguimiento ({idSeguimiento, duplicarSeguimiento, isPool, idAnalisis, idPool}) {
  try {
    if(idAnalisis != null) yield call(recuperarDatosFormAnalisisExtraccionSaga, {idAnalisisExtraccion: idAnalisis})
    if(idPool != null) yield call(recuperarDatosFormPoolExtraccionSaga, {idPoolExtraccion: idPool})


    let state = yield select(state => state)
    let initialValues = {
      codigo: state.seguimiento.codigo,
      tipoAnalisis: state.panelControl.tipoAnalisisPredeterminado,
      fecha: date_parser(new Date(), state.auth.formaFechaHora),
      contaminacion: false,
      conservacion: state.seguimiento.conservacion,
      apto: true
    }

    let data = {}
    data.isPool = isPool
    if (idSeguimiento) {      
      data = yield call(getSeguimientoService, idSeguimiento)
      initialValues = {...initialValues, ...data}
      
      if (duplicarSeguimiento) {
        initialValues.idSeguimiento = null
        data.duplicarSeguimiento = true
      }

      if (data.codigo) {
        initialValues.codigo = data.codigo
      }
      if (data.idTipoAnalisis) {
        initialValues.tipoAnalisis = data.idTipoAnalisis = parseInt(data.idTipoAnalisis, 10)
      }
      if (initialValues.tipoControl) {
        initialValues.tipoControl = state.combos.comboTipoControlSeguimiento.find((tipoControlItem) => {
          return tipoControlItem.value === initialValues.tipoControl
        })
        initialValues.tipoControl = {value: initialValues.tipoControl.value, label: i18n.t(`COMUN.COMBOS.${initialValues.tipoControl.label}`)}
      }
      if (data.idOperarioProcesado) {
          initialValues.operarioProcesado = state.combos.comboOperarioPredeterminado.find((procesado) => {
            return procesado.value == data.idOperarioProcesado
          })
          initialValues.operarioProcesado = {value: initialValues.operarioProcesado.value, label: initialValues.operarioProcesado.label}
      }
    }else{      
      data.idAnalisis = idAnalisis
      data.idPool = idPool
    }
    initialValues.tipoAnalisis = state.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
      return tipoAnalisisExtraccionItem.value === initialValues.tipoAnalisis
    })
    if (initialValues.tipoAnalisis) {
      initialValues.tipoAnalisis = {value: initialValues.tipoAnalisis.value, label: i18n.t(`COMUN.COMBOS.${initialValues.tipoAnalisis.label}`)}
    }
    if (initialValues.conservacion) {
      initialValues.conservacion = state.combos.comboConservacion.find((conservacionItem) => {
        return conservacionItem.value === initialValues.conservacion
      })
    }
    yield put(initialize('ModalFormSeguimiento', initialValues))
    yield put(openModalFormSeguimientoSuccess(data))
    if (isPool) {
      yield call(fetchTokenMagavisionPoolSaga, {idPoolExtraccion: data.idPool || state.poolExtracciones.pool.data.idPool || state.seguimiento.idPool})
    } else {
      yield call(fetchTokenMagavisionSaga)
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalFormSeguimiento () {
  yield takeLatest(actionTypes.OPEN_MODAL_FORM_SEGUIMIENTO, openModalFormSeguimiento)
}

export function * guardarModalFormSeguimiento ({values}) {
  try {
    let state = yield select(state => state)
    let existSeguimiento = !!(values && values.idSeguimiento)
    let estadoPeticion

    if (existSeguimiento) {
      estadoPeticion = yield call(submitEditarSeguimientoService, values, state.auth.token)
    } else {
      estadoPeticion = yield call(submitNuevoSeguimientoService, values, state.auth.token)
      if(values.tipoAnalisis.value == tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION && state.poolExtracciones.pool.isPrimerSeguimientoDelPool){
        yield call(savePrimerSeguimientoDelPoolService, {idPool: values.idPool, t_dtv_main: state.poolExtracciones.pool.t_dtv_main}, state.auth.token)
      }
    }
    yield put(guardarModalFormSeguimientoSuccess())

    // Si se ha abierto el modal de edición desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
    // Si se ha abierto desde el modal de edición de analisis/pool, se recargara el listado con los seguimientos del analisis/pool en uso.
    if (state.seguimiento.showModalListadoSeguimiento) {
      yield call(openModalListadoSeguimiento, {idAnalisis: values.idAnalisis, idPool: values.idPool})
    } else if (state.seguimiento.showModalNuevoSeguimiento) {
      yield call(fetchFiltrarModalNuevoSeguimiento, {filtros: state.seguimiento.filtrosModalNuevoSeguimiento}) 
      yield call(fetchSeguimientos, {filtros: state.seguimiento.filtros})
    } else {
      yield call(fetchSeguimientos, {filtros: state.seguimiento.filtros})
    }

    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)
    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(existSeguimiento ? 'modificacion' : 'insercion'))
    } else {
      yield put(openMensajeAlerta(existSeguimiento ? 'no-modificacion' : 'no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarModalFormSeguimiento () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_FORM_SEGUIMIENTO, guardarModalFormSeguimiento)
}

export function * closeModalFormSeguimiento () {
  try {
    yield put(closeModalFormSeguimientoSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalFormSeguimiento () {
  yield takeLatest(actionTypes.CLOSE_MODAL_FORM_SEGUIMIENTO, closeModalFormSeguimiento)
}

export function * obtenerYGuardarResultadoSistemaAnalisisSeguimiento ({tipoAnalisis}) {
  try {
    let auth = yield select(state => state.auth)
    let estaServicioPreparado = false
    let data = {}

    if (tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_M) {
      //yield call(iniciarServidorLecturaOldMagavisionService)

      let limitRequest = 10
      let countRequest = 0
      while(!estaServicioPreparado && countRequest < limitRequest) {
        try {
          estaServicioPreparado = yield call(comprobarServidorLecturaOldMagavisionService)
          estaServicioPreparado = estaServicioPreparado && estaServicioPreparado.Iniciado
        } catch (e) {
          console.log(e, 'servidor no iniciado ...')
          yield delay(300)
        } finally {
          countRequest++
        }
      }
      if (estaServicioPreparado) {
        let result = yield call(leerDatosServidorLecturaOldMagavisionService)

        if (tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_I) {
          data = {
            concentracion: result.Linea2 !== null ? result.Linea2 : 0,
            contados: result.Linea3 !== null ? result.Linea3 : 0,
            faTotal: result.Linea4 !== null ? result.Linea4 : 0,
            porMotilidadTotal: result.Linea5 !== null ? result.Linea5.slice(0, result.Linea5.length - 1) : 0,
            porMotilidadProgresiva: result.Linea6 !== null ? result.Linea6.slice(0, result.Linea6.length - 1) : 0
          }
        } else {
          data = {
            concentracion: result.Linea3 !== null ? result.Linea3 : 0,
            contados: result.Linea4 !== null ? result.Linea4 : 0,
            faTotal: result.Linea5 !== null ? result.Linea5 : 0,
          }
        }
        
        // Dividir concentracion entre 10 y redondearla, tal y como pide el cliente en el correo:
        // 'RV: Estado cálculos y recálculos pantalla análisis múltiples sistemas', lunes, 27 de abril de 2020 13:38.
        data.concentracion = parseFloat(data.concentracion) / 10
        data.concentracion = round_decimal(data.concentracion, 2, auth.separadorDec, auth.separadorMil)

        // Calcular el % de formas anormales.
        let faTotalPercent = parseFloat(data.faTotal) * 100 / parseInt(data.contados, 10)
        faTotalPercent = round_decimal(faTotalPercent, 2, auth.separadorDec, auth.separadorMil)
        faTotalPercent = isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent) ? 0 : faTotalPercent
        data.formasAnormales = faTotalPercent
      }
    } else if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
      let baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
      let {tokenUsuarioMagavision, t_anl} = yield select(state => state.analisisExtracciones.extraccion)
      let result = yield call(leerDatosServidorLecturaNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision, t_anl)
      estaServicioPreparado = result.code === 200
      if (estaServicioPreparado) {
        data = {
          porMotilidad: '',
          concentracion: result.concentracion,
          formasAnormales: result.anormales,
          porMotilidadTotal: result.MT,
          porMotilidadProgresiva: result.MP,
        }
      }
    }

    if (estaServicioPreparado) {
      yield put(change('ModalFormSeguimiento', 'porMotilidad', data.porMotilidad))
      yield put(change('ModalFormSeguimiento', 'concentracion', data.concentracion))
      yield put(change('ModalFormSeguimiento', 'formasAnormales', data.formasAnormales))
      yield put(change('ModalFormSeguimiento', 'porMotilidadTotal', data.porMotilidadTotal))
      yield put(change('ModalFormSeguimiento', 'porMotilidadProgresiva', data.porMotilidadProgresiva))
    } else {
      yield call(yesNoModalSaga, {
        modalType: 'servidorNoPreparado'
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchObtenerYGuardarResultadoSistemaAnalisisSeguimiento () {
  yield takeLatest(actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_SEGUIMIENTO, obtenerYGuardarResultadoSistemaAnalisisSeguimiento)
}



export function * fetchFiltrarModalNuevoSeguimiento ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let list = yield call(getFiltrarModalNuevoSeguimientoService, filtros, auth.token)

    yield put(fetchFiltrarModalNuevoSeguimientoSuccess(list, filtros))

  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchFiltrarModalNuevoSeguimiento () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_MODAL_NUEVO_SEGUIMIENTO, fetchFiltrarModalNuevoSeguimiento)
}



export function* obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento() {
  try {
    console.log('obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento')
    let auth = yield select(state => state.auth)

    let baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
    let tokenUsuarioMagavision = yield select(state => state.analisisExtracciones.tokenUsuarioMagavision)
    let mgv_follow_id = yield select(state => state.analisisExtracciones.extraccion.mgv_follow_id)
    let formValues = yield select(state => state.seguimiento.filtrosModalNuevoSeguimiento)

    //mgv_follow_id = 8 /////////////////////////////////////////
    console.log(mgv_follow_id)
    console.log(formValues)

    let seguimiento = yield select(state => state.seguimiento)
    console.log(seguimiento.listModalNuevoSeguimiento) 


    let result = yield call(leerDatosServidorLecturaNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision, null, mgv_follow_id)
    console.log(result) 
    let estaServicioPreparado = result.code === 200
    if (estaServicioPreparado) {
      let listSeguimientos = []
      result.multi.forEach(element => {
        const reference = seguimiento.listModalNuevoSeguimiento.filter(s => s.tokenAnalisisMagavision == element.t_dtv);
        console.log(reference)
        
        if(reference.length>0){
          let faTotalPercent = round_decimal(element.anormales * 100 / element.conteo)
          if (isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent)) faTotalPercent = 0

          let data = {
            conservacion: {value: 1, label: "*"},
            contaminacion: false,
            fecha: formValues.fechaSeguimiento,
            operarioProcesado: formValues.operarioProcesado,
            tipoAnalisis: {value: tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION, label: "Magavision"},
            tipoControl: {value: 1, label: "Interno", predeterminado: true},
            concentracion: element.concentracion,
            formasAnormales: faTotalPercent,
            porMotilidadProgresiva: element.MP,
            porMotilidadTotal: element.MT,
            idAnalisis: reference[0].idAnalisis,
            idPool: reference[0].idPool,
          }

          listSeguimientos.push(data)
        }

      });

 
      console.log(listSeguimientos) 
      yield call(addMultiSeguimientoService, listSeguimientos, auth.token)
      //cerramos session Magavision
      yield call(cerrarNuevoMagavisionSaga, false)
      //recargamos listado
      yield call(fetchFiltrarModalNuevoSeguimiento, {filtros: {...seguimiento.filtrosModalNuevoSeguimiento, timestamp: Date.now()}}) 


    } else if (result.code === 404) {
      yield call(yesNoModalSaga, {
        modalType: 'analisisInexistente'
      })
    } else if (result.code === 405) {
      yield call(yesNoModalSaga, {
        modalType: 'analisisPendienteDeCompletar'
      })
    } else {
      yield call(yesNoModalSaga, {
        modalType: 'servidorNoPreparado'
      })
    }
    



  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchObtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento() {
  yield takeLatest(actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_MULTISEGUIMIENTO, obtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento)
}
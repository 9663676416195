import actionTypes from '../../constants/actions/movimientoVerraco/movimientoVerraco'

export function initialState () {
  return {
    list: [],
    ubicacion: '',
    registroModificar: [],
    registroDuplicar: [],
    checkedFiltrosMovimientoVerraco: false,
    filtros: {},
    checkVerracosSinUbicacion: false,
    numVerracosSinUbicacion:'',
    origenMovimiento: '',
    datosExcelMovimientoVerraco: [],
    datosPdfMovimientoVerraco: []
  }
}

export function cerrarModalUbicacionMovimientoVerraco (state, {values}) {
  return {
    ...state,
    registroModificar: [],
    registroDuplicar: [],
    verracoMovidoHoy: {ubicacionVerracoExistente: false},
    origenMovimiento: ''
  }
}

export function submitNuevoMoviVerraco (state, {values}) {
  return {
    ...state,
    ubicacion: values
  }
}

export function enviarFechaMovimientoVerracoSuccess (state, {verracoMovidoHoy}) {
  return {
    ...state,
    verracoMovidoHoy
  }
}

export function fetchMovimientoVerracoSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalMovimientoVerracoSuccess (state, {registroModificar}) {
  return {
    ...state,
    registroModificar
  }
}

export function duplicarDatosModalMovimientoVerracoSuccess (state, {registroDuplicar}) {
  return {
    ...state,
    registroDuplicar
  }
}

export function seleccionarTodosMovimientoVerraco (state) {
  return {
    ...state,
    checkedFiltrosMovimientoVerraco: true
  }
}

export function deSeleccionarTodosMovimientoVerraco (state) {
  return {
    ...state,
    checkedFiltrosMovimientoVerraco: false
  }
}

export function fetchFiltrarMovimientoVerracoSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function checkVerracosSinUbicacion (state) {
  return {
    ...state,
    checkVerracosSinUbicacion: true
  }
}

export function quitarCheckVerracosSinUbicacion (state) {
  return {
    ...state,
    checkVerracosSinUbicacion: false,
    registroModificar: [],
    registroDuplicar: []
  }
}

export function numVerracosSinUbicacionSuccess (state, {numVerracosSinUbicacion}) {
  return {
    ...state,
    numVerracosSinUbicacion
  }
}

export function origenMovimientoSuccess (state,{origenMovimiento}) {
  return {
    ...state,
    origenMovimiento
  }
}

export function crearCsvMovimientoVerracoSuccess (state, {datosExcelMovimientoVerraco}) {
  return {
    ...state,
    datosExcelMovimientoVerraco
  }
}

export function imprimirPdfMovimientoVerracoSuccess (state, {datosPdfMovimientoVerraco}) {
  return {
    ...state,
    datosPdfMovimientoVerraco
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.CERRAR_MODAL_UBICACION:
      return cerrarModalUbicacionMovimientoVerraco(state, action)
    case actionTypes.SUBMIT_NUEVO_MOVI_VERRACO:
      return submitNuevoMoviVerraco(state, action)
    case actionTypes.RESTART_MOVIMIENTO_VERRACO:
      return initialState()
    case actionTypes.ENVIAR_FECHA_MOVIMIENTO_VERRACO_SUCCESS:
      return enviarFechaMovimientoVerracoSuccess(state, action)
    case actionTypes.FETCH_MOVIMIENTO_VERRACO_SUCCESS:
      return fetchMovimientoVerracoSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS:
      return recuperarDatosModalMovimientoVerracoSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO_SUCCESS:
      return duplicarDatosModalMovimientoVerracoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MOVIMIENTO_VERRACOS:
      return seleccionarTodosMovimientoVerraco(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MOVIMIENTO_VERRACOS:
      return deSeleccionarTodosMovimientoVerraco(state, action)
    case actionTypes.FETCH_FILTRAR_MOVIMIENTO_VERRACO_SUCCESS:
      return fetchFiltrarMovimientoVerracoSuccess(state, action)
    case actionTypes.CHECK_VERRACOS_SIN_UBICACION:
      return checkVerracosSinUbicacion(state, action)
    case actionTypes.QUITAR_CHECK_VERRACOS_SIN_UBICACION:
      return quitarCheckVerracosSinUbicacion(state, action)
    case actionTypes.NUM_VERRACOS_SIN_UBICACION:
      return numVerracosSinUbicacionSuccess(state, action)
    case actionTypes.ORIGEN_MOVIMIENTO_SUCCESS:
      return origenMovimientoSuccess(state, action)
    case actionTypes.CREATE_CSV_MOVIMIENTO_VERRACO_SUCCESS:
      return crearCsvMovimientoVerracoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_MOVIMIENTO_VERRACO_SUCCESS:
      return imprimirPdfMovimientoVerracoSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import iconoCerdo from '../../assets/images/iconoVerraco.png'
import { Dashboard, DesktopMac, Dns, Menu, Settings, LiveHelp, KeyboardTab, GridOn, Domain, LocalHospital, FileCopy, AddCircleOutline, RemoveCircleOutline, Timeline, Build, DateRange } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'
import CargaIndexPage from '../../containers/cargaIndex/CargaIndexPage'
import ImportarDatosPage from '../../containers/importarDatos/ImportarDatosPage'

/*
  Este componente se llama a si mismo recursivamente cuando tiene hijos.
*/
class SubItemMenu extends Component {
  render () {
    const {url = '#', label, children = [], onClick} = this.props
    return (
      <li>
        <Link to={url} onClick={onClick}>{label}</Link>
        {children.length > 0 && (
          <ul>
            {children.map((child, idx) => <SubItemMenu key={idx} {...child} />)}
          </ul>
        )}
      </li>
    )
  }
}

class ItemMenu extends Component {
  constructor (props) {
    super()
    this.state = {
      folded: (props.children && props.children.length > 0)
    }
  }
  render () {
    const {url = '#', icon, label, children = [], onClick} = this.props
    return (
      <li className='item-menu' onClick={onClick}>
        <Link to={url} onClick={() => this.setState({folded: !this.state.folded})}>
          {icon}
          <span className="titulo-item">{label}</span>
          {children.length > 0 && (
            <span className="arrow-down arrow">
              {this.state.folded ? <AddCircleOutline /> : <RemoveCircleOutline />}
            </span>
          )}
        </Link>
        {children.length > 0 && (
          <ul className={(this.state.folded ? '' : 'visible')}>
            {children.map((child, idx) => <SubItemMenu key={idx} {...child} />)}
          </ul>
        )}
      </li>
    )
  }
}

class ItemsMenu extends Component {

  abrirModalCargaIndex () {
    this.props.actions()
  }

  childrenFilter (children) {
    return children.filter((child) => {
      if (child.children) {
        child.children = this.childrenFilter(child.children)
      }
      return  (!child.modulos || child.modulos.some((modulo) => {
        return this.props.modulos.indexOf(modulo) !== -1
      })) 
      && (this.props.permisos === '-1' || this.props.permisos[child.permiso_key] > 0)
      && ( !child.customization  || (this.props.customizations && this.props.customizations.find((customization)=> customization === child.customization)!=undefined ));
    })
  }

  render () {
    const { t, permisos, modulos } = this.props
    const itemsMenu = [
      {
        url: '/',
        key: permisosConstant.DASHBOARD,
        permiso_key: permisosConstant.DASHBOARD,
        icon: <Dashboard/>,
        label: t('MENU.DASHBOARD'),
        classItem: 'item-dashboard'
      },
      {
        key: permisosConstant.EXTRACCIONES,
        permiso_key: permisosConstant.EXTRACCIONES,
        modulos: [modulosConstant.BASICO],
        icon: <KeyboardTab/>,
        label: t('MENU.EXTRACCIONES.EXTRACCIONES'),
        children: [
          {url: '/AnalisisExtracciones', label: t('MENU.EXTRACCIONES.ANALISIS'), permiso_key: permisosConstant.ANALISIS, modulos: [modulosConstant.BASICO]},
          {url: '/Dilucion', label: t('MENU.EXTRACCIONES.DILUCION'), permiso_key: permisosConstant.DILUCION, modulos: [modulosConstant.BASICO]},
          {url: '/PoolExtracciones', label: t('MENU.EXTRACCIONES.POOL'), permiso_key: permisosConstant.POOL, modulos: [modulosConstant.BASICO]},
          {url: '/AsignacionPoolVerraco', label: t('MENU.EXTRACCIONES.ASIGNACION_POOL'), permiso_key: permisosConstant.ASIGNACION_POOL, modulos: [modulosConstant.BASICO]},
          {url: '/Envasado', label: t('MENU.EXTRACCIONES.ENVASADO'), permiso_key: permisosConstant.ENVASADO, modulos: [modulosConstant.BASICO]},
          {url: '/Seguimiento', label: t('MENU.EXTRACCIONES.SEGUIMIENTO'), permiso_key: permisosConstant.SEGUIMIENTO, modulos: [modulosConstant.BASICO]},
          {url: '/Foso', label: t('MENU.EXTRACCIONES.FOSO'), permiso_key: permisosConstant.FOSO, modulos: [modulosConstant.BASICO]}
        ],
        classItem: 'item-extracciones'
      },
      {
        url: '/Planing',
        key: permisosConstant.PLANNING,
        permiso_key: permisosConstant.PLANNING,
        modulos: [modulosConstant.BASICO],
        icon: <Timeline/>,
        label: t('MENU.PLANING'),
        classItem: 'item-planing'
      },
      {
        key: permisosConstant.PEDIDOS,
        permiso_key: permisosConstant.PEDIDOS,
        modulos: [modulosConstant.PEDIDOS_CLIENTES],
        icon: <GridOn/>,
        label: t('MENU.PEDIDO.PEDIDOS'),
        children: [
          {url: '/Cliente', label: t('MENU.PEDIDO.CLIENTES'), permiso_key: permisosConstant.CLIENTES, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
          {url: '/PedidosVenta', label: t('MENU.PEDIDO.PEDIDOS_VENTA'), permiso_key: permisosConstant.PEDIDO, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
          {url: '/AsignacionPedidos', label: t('MENU.PEDIDO.ASIGNACION_PEDIDOS'), permiso_key: permisosConstant.ALBARANES, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
          {url: '/AlbaranVenta', label: t('MENU.PEDIDO.ALBARANES_VENTA'), permiso_key: permisosConstant.ALBARANES, modulos: [modulosConstant.PEDIDOS_CLIENTES]}
        ],
        classItem: 'item-pedidos'
      },
      {
        key: permisosConstant.VERRACOS,
        permiso_key: permisosConstant.VERRACOS,
        modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <div className="font-gesipor-cerdo"></div>,
        label: t('MENU.VERRACOS.VERRACOS'),
        url: '/FichaVerraco',
        classItem: 'item-verracos'
      },
      {
        key: permisosConstant.PLAN_SANITARIO,
        permiso_key: permisosConstant.PLAN_SANITARIO,
        modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <LocalHospital/>,
        label: t('MENU.PLAN_SANITARIO.PLAN_SANITARIO'),
        children: [
          {
            label: t('MENU.PLAN_SANITARIO.BIOSEGURIDAD'),
            permiso_key: permisosConstant.BIOSEGURIDAD_HIGIENE,
            modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/Visitas', label: t('MENU.PLAN_SANITARIO.VISITAS'), permiso_key: permisosConstant.VISITAS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/BioseguridadHigiene', label: t('MENU.PLAN_SANITARIO.HIGIENE'), permiso_key: permisosConstant.BIOSEGURIDAD_HIGIENE, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/ControlSangre', label: t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SANGRE'), permiso_key: permisosConstant.CONTROL_SANGRE, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/ControlSemen', label: t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SEMEN'), permiso_key: permisosConstant.CONTROL_SEMEN, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
            ]
          },          
          {
            label: t('MENU.PLAN_SANITARIO.ALIMENTACION.ALIMENTACION'),
            permiso_key: permisosConstant.PS_ALIMENTACION,
            modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/TipoAlimentacion', label: t('MENU.PLAN_SANITARIO.ALIMENTACION.TIPOS_ALIMENTACION'), permiso_key: permisosConstant.TIPOS_ALIMENTACION, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/RegistroAlimentacion', label: t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES'), permiso_key: permisosConstant.REGISTRO_ALIMENTACIONES, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]}
            ]
          },
          {
            label: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.INTERVENCIONES_SANITARIAS'),
            permiso_key: permisosConstant.INTERVENCIONES_SANITARIAS,
            modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/TipoIntervencionSanitaria', label: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.TIPOS_INTERVENCION'), permiso_key: permisosConstant.TIPOS_INTERVENCION_SANITARIA, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/RegistroIntervencionSanitaria', label: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.REGISTRO_INTERVENCION_SANITARIA'), permiso_key: permisosConstant.REGISTRO_INTERVENCION_SANITARIA, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]}
            ]
          },
          {
            label: t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_CALIDAD'),
            permiso_key: permisosConstant.CONTROL_CALIDAD,
            modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/ControlMorfologico', label: t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_MORFOLOGICO'), permiso_key: permisosConstant.CONTROL_MORFOLOGICO, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},            
            ]
          }
        ],
        classItem: 'item-plan-sanitario'
      },
      {
        key: permisosConstant.MANTENIMIENTO_DATOS,
        permiso_key: permisosConstant.MANTENIMIENTO_DATOS,
        modulos: [modulosConstant.BASICO, modulosConstant.PEDIDOS_CLIENTES, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <Dns/>,
        label: t('MENU.MANTENIMIENTO_DATOS.MANTENIMIENTO_DATOS'),
        children: [
          {url: '/Raza', label: t('MENU.MANTENIMIENTO_DATOS.RAZAS'), permiso_key: permisosConstant.RAZAS, modulos: [modulosConstant.BASICO]},
          {url: '/LineaGenetica', label: t('MENU.MANTENIMIENTO_DATOS.LINEAS_GENETICAS'), permiso_key: permisosConstant.LINEAS_GENETICAS, modulos: [modulosConstant.BASICO]},
          {url: '/NivelGenetico', label: t('MENU.MANTENIMIENTO_DATOS.NIVEL_GENETICO'), permiso_key: permisosConstant.NIVEL_GENETICO, modulos: [modulosConstant.BASICO]},
          {url: '/GrupoVerraco', label: t('MENU.MANTENIMIENTO_DATOS.GRUPOS_DE_VERRACOS'), permiso_key: permisosConstant.GRUPOS_VERRACOS, modulos: [modulosConstant.BASICO]},
          {url: '/Operario', label: t('MENU.MANTENIMIENTO_DATOS.OPERARIOS'), permiso_key: permisosConstant.OPERARIOS, modulos: [modulosConstant.BASICO]},
          {
            label: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTE'),
            permiso_key: permisosConstant.TRANSPORTE,
            modulos: [modulosConstant.PEDIDOS_CLIENTES],
            children: [
              {url: '/Ruta', label: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.RUTAS'), permiso_key: permisosConstant.RUTAS, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
              {url: '/Transporte', label: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTISTAS'), permiso_key: permisosConstant.TRANSPORTISTAS, modulos: [modulosConstant.PEDIDOS_CLIENTES]}
            ]
          },
          {
            label: t('MENU.MANTENIMIENTO_DATOS.PLANO.PLANO'),
            permiso_key: permisosConstant.PLANO,
            modulos: [modulosConstant.BASICO],
            children: [
              {url: '/TipoInstalacion', label: t('MENU.MANTENIMIENTO_DATOS.PLANO.TIPOS'), permiso_key: permisosConstant.TIPOS, modulos: [modulosConstant.BASICO]},
              {url: '/Instalacion', label: t('MENU.MANTENIMIENTO_DATOS.PLANO.INSTALACIONES'), permiso_key: permisosConstant.INSTALACIONES, modulos: [modulosConstant.BASICO]},
              {url: '/Plano', label: t('MENU.MANTENIMIENTO_DATOS.PLANO.VER_MAPA'), permiso_key: permisosConstant.VER_MAPA, modulos: [modulosConstant.BASICO]}
            ]
          },
          {
            label: t('MENU.VERRACOS.VERRACOS'),
            permiso_key: permisosConstant.VERRACOS,
            modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/MovimientoVerraco', label: t('MENU.VERRACOS.MOVIMIENTO_VERRACOS'), permiso_key: permisosConstant.MOVIMIENTO_VERRACOS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
              {url: '/CambioEstadoVerraco', label: t('MENU.VERRACOS.CAMBIOS_ESTADO_VERRACOS'), permiso_key: permisosConstant.CAMBIOS_ESTADO_VERRACOS, modulos: [modulosConstant.BASICO]}
            ]
          },
          {
            label: t('MENU.MANTENIMIENTO_DATOS.COMBOS.TITLE'),
            permiso_key: permisosConstant.VERRACOS,
            modulos: [modulosConstant.BASICO],
            children: [
              {url: '/ComboCausaEliminacion', label: t('MENU.MANTENIMIENTO_DATOS.COMBOS.COMBO_CAUSA_ELIMINACION_VERRACOS'), permiso_key: permisosConstant.VERRACOS, modulos: [modulosConstant.BASICO]},
              {url: '/ComboObservacionesEyaculadoEliminado', label: t('MENU.MANTENIMIENTO_DATOS.COMBOS.COMBO_CAUSA_ELIMINACION_EYACULADOS'), permiso_key: permisosConstant.VERRACOS, modulos: [modulosConstant.BASICO]}
            ]
          }
        ],
        classItem: 'item-mantenimiento-datos'
      },
      {
        key: permisosConstant.ALMACEN,
        permiso_key: permisosConstant.ALMACEN,
        modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <Domain/>,
        label: t('MENU.ALMACEN.ALMACEN'),
        children: [
          {url: '/Producto', label: t('MENU.ALMACEN.PRODUCTOS'), permiso_key: permisosConstant.PRODUCTOS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/Escandallo', label: t('MENU.ALMACEN.ESCANDALLOS'), permiso_key: permisosConstant.ESCANDALLOS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/EntradaMercancia', label: t('MENU.ALMACEN.ENTRADAS_MERCANCIAS'), permiso_key: permisosConstant.ENTRADAS_MERCANCIAS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/ValidacionMateriaPrima', label: t('MENU.ALMACEN.VALIDACION_MATERIAS_PRIMAS'), permiso_key: permisosConstant.VALIDACION_MATERIAS_PRIMAS, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/AjusteStock', label: t('MENU.ALMACEN.AJUSTE_STOCK'), permiso_key: permisosConstant.AJUSTE_STOCK, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]}
        ],
        classItem: 'item-almacen'
      },
      {
        key: permisosConstant.INFORMES,
        permiso_key: permisosConstant.INFORMES,
        modulos: [modulosConstant.INFORMES, modulosConstant.PEDIDOS_CLIENTES, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <FileCopy/>,
        label: t('MENU.INFORMES.INFORMES'),
        children: [
          {url: '/Informes/Extracciones', label: t('MENU.INFORMES.EXTRACCIONES'), permiso_key: permisosConstant.ANALISIS, modulos: [modulosConstant.INFORMES]},
          {url: '/Informes/Pool', label: t('MENU.INFORMES.POOL'), permiso_key:permisosConstant.POOL, modulos: [modulosConstant.INFORMES]},
          {url: '/Informes/ProductividadExtracciones', label: t('MENU.INFORMES.PRODUCTIVIDAD_MACHOS'), permiso_key: permisosConstant.ANALISIS, modulos: [modulosConstant.INFORMES]},
          {url: '/Informes/SeguimientoExtraccionesPool', label: t('MENU.INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL'), permiso_key: this.props.permisos[permisosConstant.ANALISIS] > 0 ? permisosConstant.ANALISIS : permisosConstant.POOL, modulos: [modulosConstant.INFORMES]},
          {url: '/Informes/ProductividadCentro', label: t('MENU.INFORMES.PRODUCTIVIDAD_CENTRO'), permiso_key: permisosConstant.ANALISIS, modulos: [modulosConstant.INFORMES]},
          {url: '/Informes/Trazabilidad', label: t('MENU.INFORMES.TRAZABILIDAD'), permiso_key: this.props.permisos[permisosConstant.ANALISIS] > 0 ? (permisosConstant.ANALISIS) : (this.props.permisos[permisosConstant.PEDIDO] > 0 ? (permisosConstant.PEDIDO) : (permisosConstant.ALBARANES)), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/Informes/Intervenciones', label: t('MENU.INFORMES.INTERVENCIONES'), permiso_key: permisosConstant.REGISTRO_INTERVENCION_SANITARIA, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
          {url: '/Informes/Clientes', label: t('MENU.INFORMES.CLIENTES'), permiso_key: permisosConstant.INFORMES && permisosConstant.CLIENTES, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
          {url: '/Informes/StockDosis', label: t('MENU.INFORMES.STOCK_DOSIS'), permiso_key: this.props.permisos[permisosConstant.ANALISIS] > 0 ? permisosConstant.ANALISIS : permisosConstant.ALBARANES, modulos: [modulosConstant.PEDIDOS_CLIENTES]},
          /*{url: '/Informes/EstadoCentro', label: t('MENU.INFORMES.ESTADO_CENTRO'), permiso_key: permisosConstant.INFORMES, modulos: [modulosConstant.INFORMES]},*/
          {url: '/Informes/Personalizado', label: t('MENU.INFORMES.PERSONALIZADO'), permiso_key: this.props.permisos[permisosConstant.ANALISIS] > 0 && this.props.permisos[permisosConstant.POOL] > 0 && this.props.permisos[permisosConstant.VERRACOS] > 0 && this.props.permisos[permisosConstant.PLANNING] > 0 && this.props.permisos[permisosConstant.CLIENTES] > 0 && this.props.permisos[permisosConstant.PEDIDO] > 0 && this.props.permisos[permisosConstant.ALBARANES] > 0 ? permisosConstant.INFORMES : null, modulos: [modulosConstant.INFORMES]}
        ],
        classItem: 'item-iformes'
      },
      {
        key: permisosConstant.HERRAMIENTAS,
        permiso_key: permisosConstant.HERRAMIENTAS,
        modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <Settings/>,
        label: t('MENU.HERRAMIENTAS.HERRAMIENTAS'),
        children: [
          {url: '/PanelControl', label: t('MENU.HERRAMIENTAS.PANEL_CONTROL'), permiso_key: permisosConstant.PANEL_CONTROL, modulos: [modulosConstant.BASICO]},
          {url: '/Conectividad', label: t('MENU.HERRAMIENTAS.CONECTIVIDAD'), permiso_key: permisosConstant.CONECTIVIDAD, modulos: [modulosConstant.BASICO]},
          {
            label: t('MENU.UTILIDADES.UTILIDADES'),
            permiso_key: permisosConstant.UTILIDADES,
            modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
            children: [
              {url: '/CopiaSeguridad', label: t('MENU.UTILIDADES.COPIA_SEGURIDAD'), permiso_key: permisosConstant.COPIA_SEGURIDAD, modulos: [modulosConstant.BASICO]},
              {onClick: () => this.abrirModalCargaIndex(), label: t('MENU.UTILIDADES.CARGA_INDEX'), permiso_key: permisosConstant.FICHA_VERRACOS, modulos: [modulosConstant.BASICO]},
              {onClick: () => this.abrirModalImportarDatos(), label: t('MENU.SISTEMA.IMPORTAR_DATOS_GESIPOR'), permiso_key: permisosConstant.IMPORTAR_DATOS_GESIPOR_2, modulos: [modulosConstant.BASICO]}
            ]
          },
          {url: '/PICTraq', label:'PICTraq', customization:'PICTraq', modulos: [modulosConstant.BASICO]},
          {
            permiso_key: permisosConstant.SISTEMA,
            modulos: [modulosConstant.BASICO],
            label: t('MENU.SISTEMA.SISTEMA'),
            children: [
              {url: '/Empresa', label: t('MENU.SISTEMA.EMPRESA_CENTROS'), permiso_key: permisosConstant.EMPRESA_CENTROS, modulos: [modulosConstant.BASICO]},
              {url: '/Usuarios', label: t('MENU.SISTEMA.USUARIOS'), permiso_key: permisosConstant.USUARIOS, modulos: [modulosConstant.BASICO]},
              {url: '/GrupoUsuarios', label: t('MENU.SISTEMA.GRUPOS_USUARIOS'), permiso_key: permisosConstant.GRUPOS_USUARIOS, modulos: [modulosConstant.BASICO]},
            ],
            classItem: 'item-sistema'
          },
          // {
          //   url: '/',
          //   key: permisosConstant.AYUDA,
          //   permiso_key: permisosConstant.AYUDA,
          //   label: t('MENU.AYUDA'),
          //   classItem: 'item-ayuda'
          // },
        ],
        classItem: 'item-herramientas'
      },
      {
        url: '/Agenda',
        key: permisosConstant.AGENDA,
        permiso_key: permisosConstant.AGENDA,
        modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD],
        icon: <DateRange/>,
        label: t('MENU.UTILIDADES.AGENDA'),
        classItem: 'item-agenda'
      }
    ]
    return (itemsMenu.filter((itemMenu) => {
      if (itemMenu.children) {
        itemMenu.children = this.childrenFilter(itemMenu.children)
      }
      return (!itemMenu.modulos || itemMenu.modulos.some((modulo) => {
        return modulos.indexOf(modulo) !== -1
      })) && (this.props.permisos === '-1' || permisos[itemMenu.permiso_key] > 0)
    }).map((itemMenu) => <ItemMenu {...itemMenu} />))
  }
}

class Sidebar extends Component {
  constructor () {
    super()
    this.state = {
      openMenu: false
    }
  }

  abrirMenu = (event) => {
    document.body.classList.add('open-m')
    this.setState({ openMenu: true })
  }

  cerrarMenu = (event) => {
    document.body.classList.remove('open-m');
    this.setState({ openMenu: false })
  }

  render () {
    const {
      t, sm, showModalCargaIndex, showModalImportarDatos,
      auth: { permisos, modulos },
      actions: {abrirModalCargaIndex, abrirModalImportarDatos}
    } = this.props
    return (
      <div>
        <Col sm={sm} className='sidebar-container'>
          <Col sm={sm} className='sidebar' >
            <div className='menu-lateral-izq'>
              <ul>
                <li key="menu-toogle" className='menu-toggle' onClick={(e) => !this.state.openMenu ? this.abrirMenu() : this.cerrarMenu()}><Menu></Menu></li>
                <ItemsMenu
                  key="items-menu"
                  t={t}
                  permisos={permisos}
                  modulos={modulos}
                  onClick={(e) => this.state.openMenu ? this.cerrarMenu() : null}
                  actions={{abrirModalCargaIndex: abrirModalCargaIndex, abrirModalImportarDatos: abrirModalImportarDatos}}
                />
              </ul>
            </div>
          </Col>
        </Col>
        {showModalCargaIndex && (
          <CargaIndexPage />
        )}
        {showModalImportarDatos && (
          <ImportarDatosPage />
        )}
      </div>
    )
  }
}

export default Sidebar

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEscandallo from '../../components/escandallo/FiltrosEscandallo'
import { comboTipoDosis, comboNombreEscandallo } from '../../actions/combos/combos'
import { abrirModalEscandallo, closeModalEscandallo, filtrarEscandallo, seleccionarTodosEscandallo, deSeleccionarTodosEscandallo, cambiarEstadoSeleccionEscandallo, deleteSeleccionEscandallo,
  crearCsvEscandallo, imprimirPdfEscandallo} from '../../actions/escandallo/escandallo'

export function mapStateToProps (state) {
  return {
    ...state.escandallo,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    abrirModalEscandallo,
    closeModalEscandallo,
    comboTipoDosis,
    filtrarEscandallo,
    comboNombreEscandallo,
    seleccionarTodosEscandallo,
    deSeleccionarTodosEscandallo,
    cambiarEstadoSeleccionEscandallo,
    deleteSeleccionEscandallo,
    crearCsvEscandallo,
    imprimirPdfEscandallo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEscandallo))
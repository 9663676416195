import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import { onSubmitFail } from '../../../util/util'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import InputText from '../../comun/form/InputText'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import './Visita.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {Print} from '@material-ui/icons'

class ModalVisita extends Component {
  submitNuevoVisita (values) {
    values.diasUltimaVisita = parseInt(values.diasVisita, 10)
    values.imprimir = false
    if (Object.keys(this.props.data).length > 0){
      this.props.actions.editarVisita(values)
    } else {
      if (this.props.submitSucceeded === false) {
        this.props.actions.submitNuevoVisita(values)
      }
    }
  }
  imprimirInformeVisita (values) {
    if (Object.keys(this.props.data).length > 0){
      this.props.actions.imprimirInformeVisita(values, this.props.data.idVisita)
    }
  }
  guardarYnuevoVisita(values){
    if (this.props.submitSucceeded === false) {
      this.props.actions.guardarYnuevoVisita(values)
    }
  }
  componentDidUpdate (prevProps) {
    if (this.props.ultimaVisita !== prevProps.ultimaVisita) {
      if (this.props.ultimaVisita.pdf && this.props.ultimaVisita.pdf !== null){
        let pdf = this.props.ultimaVisita.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.VISITAS')+".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }
  cerrarModal () {
    this.props.actions.restartVisitas()
    this.props.actions.cerrarModalVisitas()
  }
  render () {
    const {
      t, handleSubmit, list, showModalVisitas, data, duplicar,
      actions: {recuperarDatosModalVisita, cerrarModalVisitas}
    } = this.props

    return (
      <Modal show={showModalVisitas} onHide={() => this.cerrarModal()} aria-labelledby="contained-modal-title-lg" className="modal-visitas" backdrop="static">
      <form className="form-Visita" onSubmit={handleSubmit(this.submitNuevoVisita.bind(this))}>
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('PLAN_SANITARIO.VISITA.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.VISITA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idVisita && (
            <ButtonChangeRecord list={list} idElemento="idVisita" currentId={data.idVisita} getNextRecord={recuperarDatosModalVisita} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="fechaVisita"
              name="fechaVisita"
              colSm={3}
              component={InputDatePicker}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.FECHA_VISITA') + '*'}
              claseActivo="fecha-visita"
              validate={required}
            />
            <Field
              id="nombreVisita"
              name="nombreVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.NOMBRE_CONTACTO') + '*'}
              claseActivo="nombre-visita"
              validate={required}
              maxLength={true}
              valorMaxLength={70}
            />
            <Field
              id="dniVisita"
              name="dniVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.DNI')}
              claseActivo="dni-visita"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="matriculaVisita"
              name="matriculaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.MATRICULA')}
              claseActivo="matricula-visita"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="empresaVisita"
              name="empresaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.EMPRESA') + '*'}
              claseActivo="empresa-visita"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="diasVisita"
              name="diasVisita"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.DIAS_ULTIMA_VISITA') + '*'}
              claseActivo="dias-ultima-visita"
              validate={required}
            />
            <Field
              id="lugarUltimaVisita"
              name="lugarUltimaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.ULTIMO_LUGAR_VISITADO')}
              claseActivo="lugar-ultima-visita"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="motivoVisita"
              name="motivoVisita"
              colSm={12}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.MOTIVO_VISITA')}
              claseActivo="motivo-visita"
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.data).length > 0 && (
              <Button type="button" className="imprimir-visita-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeVisita.bind(this))}><Print />{t('PLANING.MODAL.IMPRIMIR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoVisita.bind(this))}>{t('PLAN_SANITARIO.VISITA.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="submit"
            className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 2)}
          >{t('PLAN_SANITARIO.VISITA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModal()}>{t('PLAN_SANITARIO.VISITA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVisita',
  onSubmitFail
})(ModalVisita))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Conectividad from '../../components/conectividad/Conectividad'
import {fetchConectividad, recuperarDatosModalConectividad, duplicarDatosModalConectividad, cambiarEstadoConectividad, onDeleteConectividad, fetchFiltrarConectividad,
  actualizarConectividadVpn, obternerConectividadVpn, actualizarConectividadVpnVacio, probarConexionVpn} from '../../actions/conectividad/conectividad'

export function mapStateToProps (state) {
  return {
    conectividad: {...state.conectividad},
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    conectividadForm: state.form.formConectividad,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchConectividad,
      recuperarDatosModalConectividad,
      duplicarDatosModalConectividad,
      cambiarEstadoConectividad,
      onDeleteConectividad,
      fetchFiltrarConectividad,
      actualizarConectividadVpn,
      obternerConectividadVpn,
      actualizarConectividadVpnVacio,
      probarConexionVpn
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Conectividad))
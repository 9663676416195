import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field, Fields, setSubmitFailed} from 'redux-form'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import { Delete, AddCircleOutline, Warning, Photo, FileCopy, PictureAsPdf, Print, Notifications } from '@material-ui/icons'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import FormSubtitle from '../../comun/form/FormSubtitle'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import InputSelect from '../../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../../comun/form/InputRangeDatePickerDynamicNames'
import ListadoPage from '../../../containers/page/ListadoPage'
import ModalBioseguridadHigienePage from '../../../containers/planSanitario/bioseguridadHigiene/ModalBioseguridadHigienePage'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'
import './ModalBioseguridadHigiene.scss'
import ModalLimpiezaSilosPage from '../../../containers/planSanitario/bioseguridadHigiene/ModalLimpiezaSilosPage'
import ModalLimpiezaCorralPage from '../../../containers/planSanitario/bioseguridadHigiene/ModalLimpiezaCorralPage'
import ModalUbicacionPage from '../../../containers/planSanitario/bioseguridadHigiene/ModalUbicacionPage'
import ModalVerracoPage from '../../../containers/planSanitario/bioseguridadHigiene/ModalVerracoPage'
import ModalGrupoVerracoPage from '../../../containers/planSanitario/bioseguridadHigiene/ModalGrupoVerracoPage'
import {date_formatter, dateTimeZones} from '../../../util/formatFunctions'
import Dropzone from 'react-dropzone'
import {permisos as permisosConstant} from '../../../constants/permisos'
import ModalAgendaPage from '../../../containers/agenda/ModalAgendaPage'

class ModalBioseguridadHigiene extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      mensajeErrorVerracos: '',
      classErrorVerracos: false,
      tipoAccion: 1,
      classErrorLimpiezaSilos: false,
      classErrorLimpiezaCorrales: false,
      classErrorOtrosVerracos: false,
      tipoOtros: 1,
      inputSelectedSilos: false,
      silo: [],
      corral: [],
      ubicacion: [],
      verraco: [],
      grupoVerraco: [],
      archivos: [],
      mensajeSubirArchivos: false
    }
    // this.abrirModalAgendaBioseguridad = this.abrirModalAgendaBioseguridad.bind(this)
  }

  /*abrirModalAgendaBioseguridad () {
    this.props.actions.abrirModalAgendaBioseguridad()
  }*/

  cambiarTipoAccion (value) {
    let textoInput = ''
    if (value !== null){
      if (value.value === 1) {
        this.setState({tipoAccion: 1})
        textoInput = this.props.t('COMUN.COMBOS.TIPO_ACCION.ANALITICA_AGUA')
      }
      else if (value.value === 2) {
        this.setState({tipoAccion: 2})
        textoInput = this.props.t('COMUN.COMBOS.TIPO_ACCION.LIMPIEZA_SILO_DEPOSITO')
      }

      else if (value.value === 3) {
        this.setState({tipoAccion: 3})
        textoInput = this.props.t('COMUN.COMBOS.TIPO_ACCION.LIMPIEZA_CORRALES')
      }
      else if (value.value === 4) {
        this.setState({tipoAccion: 4})
        textoInput = this.props.t('COMUN.COMBOS.TIPO_ACCION.OTROS')
        this.props.change('tipoOtros.tipo', { value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoOtros[0].label)})
      }
    }
    let initialValues = {}
    initialValues.fechaEntrada = dateTimeZones(this.props.auth.zonaHorariaString)
    initialValues.fechaSalida = dateTimeZones(this.props.auth.zonaHorariaString)
    initialValues.tipoAccion = {value: value.value, label: textoInput}
    initialValues.analitica = {
      fecha: dateTimeZones(this.props.auth.zonaHorariaString)
    }
    initialValues.limpiezaSilos = {
      fecha: dateTimeZones(this.props.auth.zonaHorariaString)
    }
    initialValues.limpiezaCorrales = {
      fecha: dateTimeZones(this.props.auth.zonaHorariaString),
      fechaCaducidad: dateTimeZones(this.props.auth.zonaHorariaString)
    }
    initialValues.tipoOtros = {
      tipo: {value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoOtros[0].label)},
      fechaCaducidad: dateTimeZones(this.props.auth.zonaHorariaString)
      
    }
    this.props.initialize(initialValues)
  }

  cambiarTipoOtros (value) {
    this.setState({classErrorOtrosVerracos: false})
    if (value !== null){
      if (value.value === 1) {
        this.setState({tipoOtros: 1})
      }
      else if (value.value === 2) {
        this.setState({tipoOtros: 2})
      }
      else if (value.value === 3) {
        this.setState({tipoOtros: 3})
      }
    }
  }

  close(){
    this.props.actions.closeModalBioseguridadHigiene()
    this.setState({classErrorVerracos: false})
    this.setState({mensajeErrorVerracos: ''})
    this.setState({tipoAccion: 1})
    this.setState({tipoOtros: 1})
    this.setState({classErrorLimpiezaSilos: false})
    this.setState({classErrorLimpiezaCorrales: false})
    this.setState({classErrorOtrosVerracos: false})
    this.setState({archivos: []})
  }

  submitAnaliticaAgua (values) {
    values.analitica.archivos = this.props.archivo
    if (Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false) {
      values.tipoAccion = this.state.tipoAccion
      this.props.actions.modificarAnaliticaAgua(values)
    } else {
      /*if (this.props.duplicarBioseguridad === true) {
        values.tipoAccion = {value: this.state.tipoAccion}
      } else{
        values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
      }*/
      if (this.props.duplicarBioseguridad === true) {
        values.tipoAccion = values.tipoAccion = {value: this.state.tipoAccion}
      }
      this.props.actions.submitAnaliticaAgua(values)
    }
    this.close()
  }

  submitLimpiezaSilos (values) {
    if (Object.keys(this.props.listSiloAnadidos).length > 0) {
      if (Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false) {
        values.silo = this.props.listSiloAnadidos
        values.tipoAccion = this.state.tipoAccion
        values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
        values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
        this.props.actions.modificarLimpiezaSilos(values)
      } else {
        values.silo = this.props.listSiloAnadidos
        if (this.props.duplicarBioseguridad === true) {
          values.tipoAccion = {value: this.state.tipoAccion}
        } else{
          values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
        }
        values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
        values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
        this.props.actions.submitLimpiezaSilos(values)
      }
      this.close()
    } else {
      this.setState({classErrorLimpiezaSilos: true})
    }
  }

  submitLimpiezaCorrales (values) {
    if (Object.keys(this.props.listCorralAnadidos).length > 0){
      if (Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false) {
        values.corral = this.props.listCorralAnadidos
        values.tipoAccion = this.state.tipoAccion
        this.props.actions.modificarLimpiezaCorral(values)
      } else {
        values.corral = this.props.listCorralAnadidos
        if (this.props.duplicarBioseguridad === true) {
          values.tipoAccion = {value: this.state.tipoAccion}
        } else{
          values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
        }
        this.props.actions.submitLimpiezaCorrales(values)
      }
      this.close()
    } else {
      this.setState({classErrorLimpiezaCorrales: true})
    }
  }

  submitOtros (values) {
    let errorOtros = false
    if (Object.keys(this.props.listUbicacionAnadidos).length > 0 && this.state.tipoOtros === 1){
      errorOtros = true
    }
    else if (Object.keys(this.props.listVerracoAnadidos).length > 0 && this.state.tipoOtros === 2){
      errorOtros = true
    }
    else if (Object.keys(this.props.listGrupoVerracoAnadidos).length > 0 && this.state.tipoOtros === 3){
      errorOtros = true
    } else {
      errorOtros = false
    }
    if (errorOtros === true) {
      if (Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false) {
        values.tipoAccion = this.state.tipoAccion
        values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
        values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
        if (this.state.tipoOtros === 1){
          values.otros = this.props.listUbicacionAnadidos
        }
        if (this.state.tipoOtros === 2){
          values.otros = this.props.listVerracoAnadidos
        }
        if (this.state.tipoOtros === 3){
          values.otros = this.props.listGrupoVerracoAnadidos
        }
        this.props.actions.modificarOtros(values)
      } else{
        if (this.props.duplicarBioseguridad === true) {
          values.tipoAccion = {value: this.state.tipoAccion}
        } else{
          values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
        }
        values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
        values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
        if (this.state.tipoOtros === 1){
          values.otros = this.props.listUbicacionAnadidos
        }
        if (this.state.tipoOtros === 2){
          values.otros = this.props.listVerracoAnadidos
        }
        if (this.state.tipoOtros === 3){
          values.otros = this.props.listGrupoVerracoAnadidos
        }
        this.props.actions.submitOtros(values)
      }
      this.close()
    } else {
      this.setState({classErrorOtrosVerracos: true})
    }
  }

  guardarNuevoAnaliticaAgua (values) {
    values.analitica.archivos = this.props.archivo
    if (this.props.duplicarBioseguridad === true) {
      values.tipoAccion = {value: this.state.tipoAccion}
    } else{
      values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
    }
    this.props.actions.guardarNuevoAnaliticaAgua(values)
  }

  guardarNuevoLimpiezaSilos (values) {
    if (this.state.classErrorLimpiezaSilos === false) {
      values.silo = this.props.listSiloAnadidos
      if (this.props.duplicarBioseguridad === true) {
        values.tipoAccion = {value: this.state.tipoAccion}
      } else{
        values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
      }
      values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
      values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
      this.props.actions.guardarNuevoLimpiezaSilos(values)
    } else {
      this.setState({classErrorLimpiezaSilos: true})
    }
  }

  guardarNuevoLimpiezaCorrales (values) {
    if (this.state.classErrorLimpiezaCorrales === false){
      values.corral = this.props.listCorralAnadidos
      if (this.props.duplicarBioseguridad === true) {
        values.tipoAccion = {value: this.state.tipoAccion}
      } else{
        values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
      }
      this.props.actions.guardarNuevoLimpiezaCorrales(values)
    } else {
      this.setState({classErrorLimpiezaCorrales: true})
    }
  }

  guardarNuevoOtros (values) {
    let errorOtros = false
    if (Object.keys(this.props.listUbicacionAnadidos).length > 0 && this.state.tipoOtros === 1){
      errorOtros = true
    }
    else if (Object.keys(this.props.listVerracoAnadidos).length > 0 && this.state.tipoOtros === 2){
      errorOtros = true
    }
    else if (Object.keys(this.props.listGrupoVerracoAnadidos).length > 0 && this.state.tipoOtros === 3){
      errorOtros = true
    } else {
      errorOtros = false
    }
    if (errorOtros === true) {
      if (this.props.duplicarBioseguridad === true) {
        values.tipoAccion = {value: this.state.tipoAccion}
      } else{
        values.tipoAccion = this.props.formBioseguridadHigiene.values.tipoAccion
      }
      values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
      values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
      if (this.state.tipoOtros === 1){
        values.otros = this.props.listUbicacionAnadidos
      }
      if (this.state.tipoOtros === 2){
        values.otros = this.props.listVerracoAnadidos
      }
      if (this.state.tipoOtros === 3){
        values.otros = this.props.listGrupoVerracoAnadidos
      }
      this.props.actions.guardarNuevoOtros(values)
    } else {
      this.setState({classErrorOtrosVerracos: true})
    }
  }

  intercambiarCheckModalBioseguridad (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalBioseguridad()
    } else {
      this.props.actions.deSeleccionarTodosModalBioseguridad()
    }
  }

  deleteSeleccionModalSilos () {
    const itemsSeleccion = this.props.listSiloAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idInstalacion': row.idInstalacion,
          'nombreInstalacion': row.nombreInstalacion,
          'codInstalacion': row.codInstalacion,
          'animales': row.animales,
          'activo': row.activo,
          'idBioseguridadLimpiezaSilo': row.idBioseguridadLimpiezaSilo
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionModalSilos(list)
  }

  deleteSeleccionModalCorral () {
    const itemsSeleccion = this.props.listCorralAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'Verraco': row.Verraco,
          'codInstalacion': row.codInstalacion,
          'idBioseguridadLimpiezaCorral': row.idBioseguridadLimpiezaCorral,
          'idInstalacion': row.idInstalacion,
          'idInstalacionPadre': row.idInstalacionPadre,
          'nombreInstalacion': row.nombreInstalacion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionModalCorral(list)
  }

  deleteSeleccionModalUbicacion () {
    const itemsSeleccion = this.props.listUbicacionAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'codInstalacion': row.codInstalacion,
          'idBioseguridadOtro': row.idBioseguridadOtro,
          'idInstalacion': row.idInstalacion,
          'idInstalacionPadre': row.idInstalacionPadre,
          'nombreInstalacion': row.nombreInstalacion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionModalUbicacion(list)
  }

  deleteSeleccionModalVerraco () {
    const itemsSeleccion = this.props.listVerracoAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'codVerraco': row.codVerraco,
          'estadoverraco': row.estadoverraco,
          'idRaza': row.idRaza,
          'nombreLinea': row.nombreLinea,
          'nombreRaza': row.nombreRaza,
          'ubicacionverraco': row.ubicacionverraco,
          'index': row.index,
          'idVerraco': row.idVerraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionModalVerraco(list)
  }

  deleteSeleccionModalGrupoVerraco () {
    const itemsSeleccion = this.props.listGrupoVerracoAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idGrupoVerraco': row.idGrupoVerraco,
          'nombreGrupoVerraco': row.nombreGrupoVerraco,
          'numeroverracos': row.numeroverracos,
          'activo': row.activo,
          'descripcion': row.descripcion,
          'fechaCreacion': row.fechaCreacion,
          'fechaDesactivacion': row.fechaDesactivacion
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionModalGrupoVerraco(list)
  }

  componentDidMount(){
    this.props.actions.comboOperarioPredeterminado()
    this.props.change('tipoOtros.tipo', { value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoOtros[0].label)})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.archivo !== prevProps.archivo){
      this.setState({archivos: this.props.archivo})
    }
    if (this.props.showModalBioseguridadHigiene === true && this.props.showModalBioseguridadHigiene !== prevProps.showModalBioseguridadHigiene && Object.keys(this.props.registroModificar).length === 0){
      this.props.change('tipoAccion', { value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoAccion[0].label)})
      this.props.change('tipoOtros.tipo', { value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoOtros[0].label)})
      this.props.change('analitica.fecha', dateTimeZones(this.props.auth.zonaHorariaString))
    }
    if (Object.keys(this.props.registroModificar).length > 0) {
      if (this.props.registroModificar.analitica) {
        if (this.props.registroModificar !== prevProps.registroModificar) {
          this.setState({tipoAccion: 1})
          this.props.change('tipoAccion', { value: 1, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoAccion[0].label)})
          const comboOpe = this.props.combos.comboOperarioPredeterminado.filter((id) => {
            if (id.value === this.props.registroModificar.analitica.idOperario){
              return id
            }
          })
          this.props.registroModificar.analitica.idOperario = comboOpe[0]
          const comboEstadoAcci = this.props.combos.comboEstadoAccion.filter((id) => {
            if (id.value === this.props.registroModificar.analitica.estadoAccion){
              return id
            }
          })
          this.props.registroModificar.analitica.estadoAccion = {value: comboEstadoAcci[0].value, label: this.props.t('COMUN.COMBOS.' + comboEstadoAcci[0].label)}
        }
      }
      if (this.props.registroModificar.limpiezaSilos) {
        if (this.props.registroModificar !== prevProps.registroModificar) {
          this.props.change('tipoAccion', { value: 2, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoAccion[0].label)})
          this.setState({tipoAccion: 2})
          const comboOpe = this.props.combos.comboOperarioPredeterminado.filter((id) => {
            if (id.value === this.props.registroModificar.limpiezaSilos.idOperario){
              return id
            }
          })
          this.props.registroModificar.limpiezaSilos.idOperario = comboOpe[0]
          const comboEstadoAcci = this.props.combos.comboEstadoAccion.filter((id) => {
            if (id.value === this.props.registroModificar.limpiezaSilos.estadoAccion){
              return id
            }
          })
          this.props.registroModificar.limpiezaSilos.estadoAccion = {value: comboEstadoAcci[0].value, label: this.props.t('COMUN.COMBOS.' + comboEstadoAcci[0].label)}
          this.props.registroModificar.fechaEntrada = this.props.registroModificar.limpiezaSilos.desde
          this.props.registroModificar.fechaSalida = this.props.registroModificar.limpiezaSilos.hasta
        }
      }
      if (this.props.registroModificar.limpiezaCorrales) {
        if (this.props.registroModificar !== prevProps.registroModificar) {
          this.props.change('tipoAccion', { value: 3, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoAccion[0].label)})
          this.setState({tipoAccion: 3})
          const comboOpe = this.props.combos.comboOperarioPredeterminado.filter((id) => {
            if (id.value === this.props.registroModificar.limpiezaCorrales.idOperario){
              return id
            }
          })
          this.props.registroModificar.limpiezaCorrales.idOperario = comboOpe[0]
          const comboEstadoAcci = this.props.combos.comboEstadoAccion.filter((id) => {
            if (id.value === this.props.registroModificar.limpiezaCorrales.estadoAccion){
              return id
            }
          })
          this.props.registroModificar.limpiezaCorrales.estadoAccion = {value: comboEstadoAcci[0].value, label: this.props.t('COMUN.COMBOS.' + comboEstadoAcci[0].label)}
        }
      }
      if (this.props.registroModificar.tipoOtros) {
        if (this.props.registroModificar !== prevProps.registroModificar) {
          this.props.change('tipoAccion', { value: 4, label: this.props.t('COMUN.COMBOS.' + this.props.combos.comboTipoAccion[0].label)})
          this.setState({tipoAccion: 4})
          const comboOpe = this.props.combos.comboOperarioPredeterminado.filter((id) => {
            if (id.value === this.props.registroModificar.tipoOtros.idOperario){
              return id
            }
          })
          this.props.registroModificar.tipoOtros.idOperario = comboOpe[0]
          const comboEstadoAcci = this.props.combos.comboEstadoAccion.filter((id) => {
            if (id.value === this.props.registroModificar.tipoOtros.estadoAccion){
              return id
            }
          })
          this.props.registroModificar.tipoOtros.estadoAccion = {value: comboEstadoAcci[0].value, label: this.props.t('COMUN.COMBOS.' + comboEstadoAcci[0].label)}
          const combotipo = this.props.combos.comboTipoOtros.filter((id) => {
            if (id.value === this.props.registroModificar.tipoOtros.tipo){
              return id
            }
          })
          this.props.registroModificar.tipoOtros.tipo = {value: combotipo[0].value, label: this.props.t('COMUN.COMBOS.' + combotipo[0].label)}
          this.props.registroModificar.fechaEntrada = this.props.registroModificar.tipoOtros.desde
          this.props.registroModificar.fechaSalida = this.props.registroModificar.tipoOtros.hasta
        }
      }
    }

    if (Object.keys(this.props.registroModificar).length > 0 ) {
      if (this.props.registroModificar !== prevProps.registroModificar){
        if (this.props.registroModificar.tipoOtros) {
          if (Object.keys(this.props.registroModificar.tipoOtros).length > 0) {
            if (this.props.registroModificar.tipoOtros.tipo.value === 1) {
              this.setState({tipoOtros: this.props.registroModificar.tipoOtros.tipo.value})
            }
            else if (this.props.registroModificar.tipoOtros.tipo.value === 2) {
              this.setState({tipoOtros: this.props.registroModificar.tipoOtros.tipo.value})
            }
            else if (this.props.registroModificar.tipoOtros.tipo.value === 3) {
              this.setState({tipoOtros: this.props.registroModificar.tipoOtros.tipo.value})
            }
          }
        }

      }
    }

    // Quitar mensaje de error si hay registro en la tabla
    if (Object.keys(this.props.listSiloAnadidos).length > 0) {
      if (this.props.listSiloAnadidos !== prevProps.listSiloAnadidos) {
        this.setState({classErrorLimpiezaSilos: false})
      }
    }
    if (this.props.listCorralAnadidos !== prevProps.listCorralAnadidos) {
      if (Object.keys(this.props.listCorralAnadidos).length > 0) {
        this.setState({classErrorLimpiezaCorrales: false})
      }
    }
    if (Object.keys(this.props.listUbicacionAnadidos).length > 0) {
      if (this.props.listUbicacionAnadidos !== prevProps.listUbicacionAnadidos) {
        this.setState({classErrorOtrosVerracos: false})
      }
    }
    if (Object.keys(this.props.listVerracoAnadidos).length > 0) {
      if (this.props.listVerracoAnadidos !== prevProps.listVerracoAnadidos) {
        this.setState({classErrorOtrosVerracos: false})
      }
    }
    if (Object.keys(this.props.listGrupoVerracoAnadidos).length > 0) {
      if (this.props.listGrupoVerracoAnadidos !== prevProps.listGrupoVerracoAnadidos) {
        this.setState({classErrorOtrosVerracos: false})
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.classErrorLimpiezaSilos === true) {
      this.props.dispatch(setSubmitFailed('ModalBioseguridadHigiene'))
    }
    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.classErrorLimpiezaCorrales === true) {
      this.props.dispatch(setSubmitFailed('ModalBioseguridadHigiene'))
    }
    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.classErrorOtrosVerracos === true) {
      this.props.dispatch(setSubmitFailed('ModalBioseguridadHigiene'))
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-modal-bioseguridad1', false)
            this.props.change('seleccionar-todo-modal-bioseguridad2', false)
            this.props.change('seleccionar-todo-modal-bioseguridad3', false)
            this.props.change('seleccionar-todo-modal-bioseguridad4', false)
            this.props.change('seleccionar-todo-modal-bioseguridad5', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalBioseguridad()
          }
        }
      }
    }
  }

  getFileExtension(filename) {
    return filename.split('.').pop()
  }

  onDropArchivos(archivos) {
    let extension = this.getFileExtension(archivos[0].name)
    if (extension === 'pdf') {
      this.setState({mensajeSubirArchivos: false})
      var numFiles=archivos.length
      archivos.map((archivo, i) => {
        var reader = new FileReader();
        reader.onloadend = () => {
          archivo.content=reader.result
          const archivoVerraco = {archivo: archivo.name, content: reader.result}
          this.setState({archivos: this.state.archivos.concat(archivoVerraco)})
          this.props.actions.subirArchivosBioseguridad(this.state.archivos)
        }
        reader.readAsDataURL(archivo)
      })
    } else {
      this.setState({mensajeSubirArchivos: true})
    }
  }

  eliminaArchivo(archivo){
    this.props.actions.eliminarArchvioBioseguridad(archivo)
  }

  obtenerArchivoContentBioseguridad (archivo) {
    if(archivo.content){
      let element = document.createElement('a')
      element.setAttribute('href', encodeURIComponent(archivo))
      element.setAttribute('href', archivo.content)
      element.setAttribute('download', archivo.archivo)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    else{
      this.props.actions.obtenerArchivoContentBioseguridad(archivo)
    }
  }

  imprimirInformeBioseguridad (id, tipoAccion, values) {
    if (tipoAccion === 1) {
      values.analitica.archivos = this.props.archivo
      values.tipoAccion = tipoAccion
      this.props.actions.imprimirInformeBioseguridad(values, id, tipoAccion)
    }

    if (tipoAccion === 2) {
      if (Object.keys(this.props.listSiloAnadidos).length > 0) {
        if (this.state.classErrorLimpiezaSilos === false) {
          values.silo = this.props.listSiloAnadidos
          values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
          values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
          values.tipoAccion = tipoAccion
          this.props.actions.imprimirInformeBioseguridad(values, id, tipoAccion)
        } else {
          this.setState({classErrorLimpiezaSilos: true})
        }
      } else {
        this.setState({classErrorLimpiezaSilos: true})
      }
    }

    if (tipoAccion === 3) {
      if (Object.keys(this.props.listCorralAnadidos).length > 0){
        if (this.state.classErrorLimpiezaCorrales === false){
          values.corral = this.props.listCorralAnadidos
          values.tipoAccion = tipoAccion
          this.props.actions.imprimirInformeBioseguridad(values, id, tipoAccion)
        } else {
          this.setState({classErrorLimpiezaCorrales: true})
        }
      }
    }

    if (tipoAccion === 4) {
      let errorOtros = false
      if (Object.keys(this.props.listUbicacionAnadidos).length > 0 && this.state.tipoOtros === 1){
        errorOtros = true
      }
      else if (Object.keys(this.props.listVerracoAnadidos).length > 0 && this.state.tipoOtros === 2){
        errorOtros = true
      }
      else if (Object.keys(this.props.listGrupoVerracoAnadidos).length > 0 && this.state.tipoOtros === 3){
        errorOtros = true
      } else {
        errorOtros = false
      }
      if (errorOtros === true) {
        values.tipoAccion = tipoAccion
        values.desde = this.props.formBioseguridadHigiene.values.fechaEntrada
        values.hasta = this.props.formBioseguridadHigiene.values.fechaSalida
        if (this.state.tipoOtros === 1){
          values.otros = this.props.listUbicacionAnadidos
        }
        if (this.state.tipoOtros === 2){
          values.otros = this.props.listVerracoAnadidos
        }
        if (this.state.tipoOtros === 3){
          values.otros = this.props.listGrupoVerracoAnadidos
        }
        this.props.actions.imprimirInformeBioseguridad(values, id, tipoAccion)
      } else {
        this.setState({classErrorOtrosVerracos: true})
      }
    }
  }

  abrirModalAgenda (idAgenda) {
    this.props.actions.abrirModalAgenda(idAgenda)
  }

  render () {
    const {
      t, handleSubmit, list, showModal, data, registroModificar, duplicar, numeroRegistrosPorPagina, formBioseguridadHigiene, listSiloAnadidos, checkedModalBioseguridad, listCorralAnadidos, showModalBioseguridadHigiene,
      listUbicacionAnadidos, listVerracoAnadidos, listGrupoVerracoAnadidos, paginaActual,
      actions: {recuperarDatosModalBioseguridadHigiene, abrirModalLimpiezaSilos, onDeleteSiloAnadido, abrirModalLimpiezaCorral, onDeleteCorralAnadido, abrirModalUbicacion, onDeleteUbicacionAnadido, abrirModalVerraco, duplicarDatosModalBioseguridadHigiene,
      abrirModalGrupoVerraco, onDeleteVerracoAnadidoBioseguridad, onDeleteGrupoVerracoAnadido, guardarNuevoAnaliticaAgua, guardarNuevoLimpiezaSilos, guardarNuevoLimpiezaCorrales, guardarNuevoOtros},
      combos: {comboOperarioPredeterminado}
    } = this.props

    let comboTipoAccion = this.props.combos.comboTipoAccion
    comboTipoAccion = comboTipoAccion.map((tipoAccionBioseguridadHigiene) => {
      return {...tipoAccionBioseguridadHigiene, label: t(`COMUN.COMBOS.${tipoAccionBioseguridadHigiene.label}`)}
    })

    let comboTipoOtros = this.props.combos.comboTipoOtros
    comboTipoOtros = comboTipoOtros.map((tipoOtrosBioseguridadHigiene) => {
      return {...tipoOtrosBioseguridadHigiene, label: t(`COMUN.COMBOS.${tipoOtrosBioseguridadHigiene.label}`)}
    })

    let comboEstadoAccion = this.props.combos.comboEstadoAccion
    comboEstadoAccion = comboEstadoAccion.map((estadoAccionBioseguridadHigiene) => {
      return {...estadoAccionBioseguridadHigiene, label: t(`COMUN.COMBOS.${estadoAccionBioseguridadHigiene.label}`)}
    })

    // TABLA LIMPIEZA SILOS Y DEPOSITOS

    listSiloAnadidos.forEach(
      (row) => row.idElemento = row.idInstalacion
    )
    const tableSiloDeposito = {
      id: 'siloAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.NOMBRE_UBICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.CODIGO_UBICACION')},
        {id: 'producto', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.PRODUCTO')},
        {id: 'lote', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.LOTE')},
        {id: 'cantidad', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.CANTIDAD')},
        {id: 'fechaCaducidad', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.FECHA_CADUCIDAD'), type: 'fecha'}
      ],
      rows: listSiloAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteSiloAnadido(row.idInstalacion),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      showModal: this.props.showModalLimpiezaSilos,
      initialValues: {},
      idBlockOptions: 1,
      tablaModal: true
    }

    tableSiloDeposito.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableSiloDeposito.initialValues['check' + row.idElemento] = checkedModalBioseguridad
        }
      }
    )

   // TABLA LIMPIEZA DE CORRALES
    listCorralAnadidos.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const tableCorral = {
      id: 'corralAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.TABLA.NOMBRE_UBICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.TABLA.CODIGO_UBICACION')},
        {id: 'Verraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.TABLA.VERRACO')}
      ],
      rows: listCorralAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteCorralAnadido(row.idInstalacion),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      showModal: this.props.showModalLimpiezaCorral,
      initialValues: {},
      idBlockOptions: 2,
      tablaModal: true
    }

    tableCorral.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableCorral.initialValues['check' + row.idElemento] = checkedModalBioseguridad
        }
      }
    )

    // TABLA OTROS -- UBICACIONES

    listUbicacionAnadidos.forEach(
      (row) => row.idElemento = row.idInstalacion
    )

    const tableOtrosUbicacion = {
      id: 'ubicacionAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      columns: [
        {id: 'nombreInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.TABLA.NOMBRE_UBICACION')},
        {id: 'codInstalacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.TABLA.CODIGO_UBICACION')}
      ],
      rows: listUbicacionAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteUbicacionAnadido(row.idInstalacion),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      showModal: this.props.showModalUbicacion,
      initialValues: {},
      idBlockOptions: 3,
      tablaModal: true
    }

    tableOtrosUbicacion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableOtrosUbicacion.initialValues['check' + row.idElemento] = checkedModalBioseguridad
        }
      }
    )

    // TABLA OTROS -- VERRACOS

    listVerracoAnadidos.forEach(
      (row) => row.idElemento = row.idVerraco
    )

    const tableOtrosVerracos = {
      id: 'verracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')},
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: listVerracoAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracoAnadidoBioseguridad(row.idVerraco),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      initialValues: {},
      idBlockOptions: 4,
      tablaModal: true,
      showModal: this.props.showModalVerraco,
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableOtrosVerracos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableOtrosVerracos.initialValues['check' + row.idElemento] = checkedModalBioseguridad
        }
      }
    )

    // TABLA OTROS -- GRUPO DE VERRACO

    listGrupoVerracoAnadidos.forEach(
      (row) => row.idElemento = row.idGrupoVerraco
    )

    const tableOtrosGruposVerraco = {
      id: 'grupoVerracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1),
      columns: [
        {id: 'nombreGrupoVerraco', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.NOMBRE_GRUPO')},
        {id: 'numeroverracos', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.NUM_VERRACOS')},
        {id: 'fechaCreacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.FECHA_CREACION'), type: 'fecha'},
        {id: 'fechaDesactivacion', name: t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TABLA.FECHA_DESACTIVACION'), type: 'fecha'}
      ],
      rows: listGrupoVerracoAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteGrupoVerracoAnadido(row.idGrupoVerraco),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      showModal: this.props.showModalGrupoVerraco,
      initialValues: {},
      idBlockOptions: 5,
      tablaModal: true
    }

    tableOtrosGruposVerraco.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableOtrosGruposVerraco.initialValues['check' + row.idElemento] = checkedModalBioseguridad
        }
      }
    )

    return (
      <Modal show={showModalBioseguridadHigiene} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" className="modal-bioseguridad-higiene" backdrop="static">
        <Modal.Header closeButton>
        {/*
          <ModalAgendaPage
            idBioSeguridadAnalitica={Object.keys(registroModificar).length > 0 && registroModificar.analitica ? registroModificar.analitica.idBioSeguridad : null}
            idBioSeguridadLimpiezaCorral={Object.keys(registroModificar).length > 0 && registroModificar.limpiezaCorrales ? registroModificar.limpiezaCorrales.idBioSeguridad : null}
            idBioSeguridadLimpiezaSilo={Object.keys(registroModificar).length > 0 && registroModificar.limpiezaSilos ? registroModificar.limpiezaSilos.idBioSeguridad : null}
            idBioSeguridadOtro={Object.keys(registroModificar).length > 0 && registroModificar.tipoOtros ? registroModificar.tipoOtros.idBioSeguridad : null}
            tipoTarea={2}
           />*/}
          <Modal.Title>
          {
            Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false ? (
              t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.TITULO_NUEVO')
            )
          }
          {(registroModificar && registroModificar.analitica && registroModificar.analitica.idBioSeguridad)
            && (
            <ButtonChangeRecord list={list} idElemento="idBioSeguridad" currentId={registroModificar.analitica.idBioSeguridad} getNextRecord={(idBioSeguridad) => {
              let bioseguridadRow = list.find((row) => {
                return row.idBioSeguridad === idBioSeguridad
              })
              recuperarDatosModalBioseguridadHigiene(idBioSeguridad, bioseguridadRow.tipoAccion)
            }} />
          )}
            {
            (registroModificar && registroModificar.limpiezaCorrales && registroModificar.limpiezaCorrales.idBioSeguridad)
            && (
            <ButtonChangeRecord list={list} idElemento="idBioSeguridad" currentId={registroModificar.limpiezaCorrales.idBioSeguridad} getNextRecord={(idBioSeguridad) => {
              let bioseguridadRow = list.find((row) => {
                return row.idBioSeguridad === idBioSeguridad
              })
              recuperarDatosModalBioseguridadHigiene(idBioSeguridad, bioseguridadRow.tipoAccion)
            }} />
          )}
            {
            (registroModificar && registroModificar.limpiezaSilos && registroModificar.limpiezaSilos.idBioSeguridad)
            && (
            <ButtonChangeRecord list={list} idElemento="idBioSeguridad" currentId={registroModificar.limpiezaSilos.idBioSeguridad} getNextRecord={(idBioSeguridad) => {
              let bioseguridadRow = list.find((row) => {
                return row.idBioSeguridad === idBioSeguridad
              })
              recuperarDatosModalBioseguridadHigiene(idBioSeguridad, bioseguridadRow.tipoAccion)
            }} />
          )}
            {
            (registroModificar && registroModificar.tipoOtros && registroModificar.tipoOtros.idBioSeguridad)
            && (
            <ButtonChangeRecord list={list} idElemento="idBioSeguridad" currentId={registroModificar.tipoOtros.idBioSeguridad} getNextRecord={(idBioSeguridad) => {
              let bioseguridadRow = list.find((row) => {
                return row.idBioSeguridad === idBioSeguridad
              })
              recuperarDatosModalBioseguridadHigiene(idBioSeguridad, bioseguridadRow.tipoAccion)
            }} />
          )}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          {
           Object.keys(this.props.registroModificar).length === 0 ? (
            <Field
              colSm={3}
              id="tipoAccion"
              name="tipoAccion"
              controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.TIPO_ACCION') + '*'}
              component={InputSelect}
              options={comboTipoAccion}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-accion'
              onInputChange={(value) => this.cambiarTipoAccion(value)}
              validate={required}
              isClearable={false}
            />
            ) : null
          }
            <div className="clearfix"></div>

            {
              this.state.tipoAccion === 1 && (
                <div>
                  <FormSubtitle style={{marginLeft: '14px'}}>{t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.ANALITICA_AGUA`)}</FormSubtitle>
                  <form id="form-analitica-agua" className="form-analitica-agua">
                    <Field
                      id="fecha"
                      name="analitica.fecha"
                      component={InputDatePicker}
                      colSm={2}
                      controlLabel={t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.FECHA`) + '*'}
                      validate={required}
                    />
                    <Field
                      colSm={2}
                      id="analitica.idOperario"
                      name="analitica.idOperario"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.OPERARIO') + '*'}
                      component={InputSelect}
                      options={comboOperarioPredeterminado}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-operario'
                      validate={required}
                    />
                    <Field
                      colSm={2}
                      id="estadoAccion"
                      name="analitica.estadoAccion"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.ESTADO') + '*'}
                      component={InputSelect}
                      options={comboEstadoAccion}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-estado-accion'
                      validate={required}
                    />
                    <Field
                      colSm={2}
                      id="lugarRecogida"
                      name="analitica.lugarRecogida"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.LUGAR_RECOGIDA')}
                      component={InputText}
                      maxLength={true}
                      valorMaxLength={100}
                    />
                    <Field
                      colSm={2}
                      id="laboratorio"
                      name="analitica.laboratorio"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.LABORATORIO')}
                      component={InputText}
                      maxLength={true}
                      valorMaxLength={100}
                    />
                    <div className="clearfix"></div>
                    <Field
                      colSm={12}
                      id="observaciones"
                      name="analitica.observaciones"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.OBSERVACIONES')}
                      component={InputText}
                      componentClass="textarea"
                      claseActivo="observaciones"
                    />
                    <Col sm={6}>
                      <div className="archivosIntervencion">
                        {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ADJUNTAR_ARCHIVO')}
                        <Dropzone
                          // accept="image/jpeg, image/png"
                          onDrop={this.onDropArchivos.bind(this)}
                          uploadMultiple={true}
                          maxFiles={null}
                          // maxSize={200000}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}  className="custom-file-label">
                              <input {...getInputProps()} />
                              <label><span className="explorar-input-file">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EXPLORAR')}</span></label>
                            </div>
                          )}
                        </Dropzone>
                        {
                          this.state.mensajeSubirArchivos === true ? (
                            <p className='error-verracos'><Warning/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.NO_PODER_ADJUNTAR')}</p>
                          ) : null
                        }
                        <ul>
                          {
                            Object.keys(this.state.archivos).length > 0 && (
                              this.state.archivos.map((item, idx) =>{
                                return (
                                  <li
                                    key={idx}
                                  >
                                    {
                                      this.getFileExtension(item.archivo) === 'jpg' || this.getFileExtension(item.archivo) === 'gif' || this.getFileExtension(item.archivo) === 'png' ||
                                      this.getFileExtension(item.archivo) === 'JPG' || this.getFileExtension(item.archivo) === 'GIF' || this.getFileExtension(item.archivo) === 'PNG' ? (
                                        <Photo/>
                                      ) : (
                                            this.getFileExtension(item.archivo) === 'pdf' ? (
                                              <PictureAsPdf/>
                                            ) : (<FileCopy/>)
                                          )
                                    }
                                    <p style={{cursor: 'pointer'}} onClick={() => this.obtenerArchivoContentBioseguridad(item)}>{item.archivo}</p>
                                    <span className="borrar" onClick={() => this.eliminaArchivo(item.archivo)}><Delete/></span>
                                  </li>
                                )
                              })
                            )
                          }
                        </ul>
                      </div>
                    </Col>
                    <div className="clearfix"></div>
                    <Col sm={12} className="botones-footer">
                      {
                        Object.keys(registroModificar).length > 0 ? (
                          registroModificar.analitica && registroModificar.analitica.idAgenda !== null ? (
                            <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda(registroModificar.analitica.idAgenda)}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.EDITAR_AVISO_AGENDA')}</Button>
                          ) : <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda()}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CREAR_AVISO_AGENDA')}</Button>
                        ) : null
                      }
                      {
                        Object.keys(this.props.registroModificar).length > 0 && (
                          registroModificar.analitica && (
                          <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeBioseguridad.bind(this, registroModificar.analitica.idBioSeguridad, 1))}><Print />{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.IMPRIMIR')}</Button>
                          )
                        )
                      }
                      {
                        Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false ? (
                          <Button
                            type="button"
                            onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? (
                              () => {duplicarDatosModalBioseguridadHigiene(registroModificar.analitica.idBioSeguridad, this.state.tipoAccion)}) : undefined}
                            className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                          >
                            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.DUPLICAR')}
                          </Button>
                        ) : (
                          <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarNuevoAnaliticaAgua.bind(this)) : null}>
                            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR_Y_NUEVO')}
                          </Button>
                        )
                      }
                      <Button
                        type="button"
                        className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? this.props.submitSucceeded !== true ? handleSubmit(this.submitAnaliticaAgua.bind(this)) : null : undefined}
                      >
                        {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR')}
                      </Button>
                      <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
                    </Col>
                  </form>
                </div>
              )
            }
            {
              this.state.tipoAccion === 2 && (
                <div>
                <FormSubtitle style={{marginLeft: '14px'}}>{t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.LIMPIEZA_SILOS_DEPOSITOS`)}</FormSubtitle>
                  <form id="form-limpieza-silos-depositos" className="form-limpieza-silos-depositos">
                    <Fields
                      names={['fechaEntrada', 'fechaSalida']}
                      component={InputRangeDatePickerDynamicNames}
                      colSm={4}
                      nameFrom="fechaEntrada"
                      nameTo="fechaSalida"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.DESDE_HASTA')}
                    />
                    <Field
                      colSm={2}
                      id="idOperario"
                      name="limpiezaSilos.idOperario"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.OPERARIO') + '*'}
                      component={InputSelect}
                      options={comboOperarioPredeterminado}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-operario'
                      validate={required}
                    />
                    <Field
                      colSm={2}
                      id="estadoAccions"
                      name="limpiezaSilos.estadoAccion"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.ESTADO') + '*'}
                      component={InputSelect}
                      options={comboEstadoAccion}
                      valueKey="value"
                      labelKey="label"
                      customClass='select-estado-accion'
                      validate={required}
                    />
                    <div className="clearfix"></div>
                    <div className="tabla-modal-limpieza-silos">
                      <div id="block-options1" className={this.state.imputSelected === true ? 'filtros-tabla-modal-limpieza-silos' : 'filtros-tabla-modal-limpieza-silos oculta-action'}>
                        <div className="custom-check-w">
                          {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                            <Field
                              id="seleccionar-todo-modal-bioseguridad1"
                              key="seleccionar-todo-modal-bioseguridad1"
                              name="seleccionar-todo-modal-bioseguridad1"
                              inline
                              component={InputCheckBox}
                              onInputChange={(value) => this.intercambiarCheckModalBioseguridad(value)}
                            />,
                            <button key="button" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionModalSilos()}>
                              <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                            </button>,
                            <ModalLimpiezaSilosPage key="ModalLimpiezaSilosPage" />
                          ]}
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#new-edit-modal"
                            className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                            onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? () => abrirModalLimpiezaSilos(listSiloAnadidos) : undefined}
                          >
                            <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                          </button>
                        </div>
                      </div>
                      <ListadoPage noTitle={true} table={tableSiloDeposito}>
                      </ListadoPage>
                      {
                        this.state.classErrorLimpiezaSilos === true ? (
                          <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.MENSAJE_ALERTA_UBICACION')}</span>
                        ) : null
                      }
                    </div>
                    <div className="clearfix"></div>
                    <Field
                      colSm={12}
                      id="observaciones"
                      name="limpiezaSilos.observaciones"
                      controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.OBSERVACIONES')}
                      component={InputText}
                      componentClass="textarea"
                      claseActivo="observaciones"
                    />
                    <Col sm={12} className="botones-footer">
                      {
                        Object.keys(registroModificar).length > 0 ? (
                          registroModificar.limpiezaSilos && registroModificar.limpiezaSilos.idAgenda !== null ? (
                            <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda(registroModificar.limpiezaSilos.idAgenda)}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.EDITAR_AVISO_AGENDA')}</Button>
                          ) : <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda()}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CREAR_AVISO_AGENDA')}</Button>
                        ) : null
                      }
                      {
                        Object.keys(this.props.registroModificar).length > 0 && (
                          registroModificar.limpiezaSilos && (
                            <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeBioseguridad.bind(this, registroModificar.limpiezaSilos.idBioSeguridad, 2))}><Print />{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.IMPRIMIR')}</Button>
                          )
                        )
                      }
                      {
                        Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false ? (
                          <Button
                            type="button"
                            onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? (
                              () => {duplicarDatosModalBioseguridadHigiene(registroModificar.limpiezaSilos.idBioSeguridad, this.state.tipoAccion)}) : undefined}
                            className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                          >
                            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.DUPLICAR')}
                          </Button>
                        ) : (
                          <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded !== true ? handleSubmit(this.guardarNuevoLimpiezaSilos.bind(this)) : null}>
                            {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR_Y_NUEVO')}
                          </Button>
                        )
                      }
                      <Button
                        type="button"
                        className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? this.props.submitSucceeded !== true ? handleSubmit(this.submitLimpiezaSilos.bind(this)) : null : undefined}
                      >
                        {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR')}
                      </Button>
                      <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
                    </Col>
                  </form>
                </div>
              )
            }
            {
              this.state.tipoAccion === 3 && (
                <div>
                  <FormSubtitle style={{marginLeft: '14px'}}>{t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.LIMPIEZA_CORRALES`)}</FormSubtitle>
                  <Field
                    id="fecha"
                    name="limpiezaCorrales.fecha"
                    component={InputDatePicker}
                    colSm={2}
                    controlLabel={t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.FECHA`) + '*'}
                    validate={required}
                  />
                  <Field
                    colSm={2}
                    id="idOperario"
                    name="limpiezaCorrales.idOperario"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.OPERARIO') + '*'}
                    component={InputSelect}
                    options={comboOperarioPredeterminado}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-operario'
                    validate={required}
                  />
                  <Field
                    colSm={2}
                    id="estadoAccion"
                    name="limpiezaCorrales.estadoAccion"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.ESTADO') + '*'}
                    component={InputSelect}
                    options={comboEstadoAccion}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-estado-accion'
                    validate={required}
                  />
                  <Field
                    colSm={2}
                    id="producto"
                    name="limpiezaCorrales.producto"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.PRODUCTO')}
                    component={InputText}
                    maxLength={true}
                    valorMaxLength={120}
                  />
                  <Field
                    id="fechaCaducidad"
                    name="limpiezaCorrales.fechaCaducidad"
                    component={InputDatePicker}
                    colSm={2}
                    controlLabel={t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.FECHA_CADUCIDAD`)}
                  />
                  <Field
                    id="lote"
                    name="limpiezaCorrales.lote"
                    colSm={2}
                    component={InputText}
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.LOTE')}
                    claseActivo="nombre-lote"
                    maxLength={true}
                    valorMaxLength={120}
                  />
                  <Field
                    id="cantidad"
                    name="limpiezaCorrales.cantidad"
                    colSm={2}
                    component={InputText}
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.CANTIDAD')}
                    claseActivo="nombre-cantidad"
                    maxLength={true}
                    valorMaxLength={100}
                  />
                  <div className="tabla-verracos-en-grupo-verracos">
                    <div id="block-options2" className={this.state.imputSelected === true ? 'filtros-tabla-modal-limpieza-corrales' : 'filtros-tabla-modal-limpieza-corrales oculta-action'}>
                      <div className="custom-check-w">
                        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                          <Field
                            id="seleccionar-todo-modal-bioseguridad2"
                            key="seleccionar-todo-modal-bioseguridad2"
                            name="seleccionar-todo-modal-bioseguridad2"
                            inline
                            component={InputCheckBox}
                            onInputChange={(value) => this.intercambiarCheckModalBioseguridad(value)}
                          />,
                          <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionModalCorral()}>
                            <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                          </button>,
                          <ModalLimpiezaCorralPage key="ModalLimpiezaCorralPage" />
                        ]}
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#new-edit-modal"
                          className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                          onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? () => abrirModalLimpiezaCorral(listCorralAnadidos) : undefined}
                        >
                          <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                        </button>
                      </div>
                    </div>
                    <ListadoPage noTitle={true} table={tableCorral}>
                    </ListadoPage>
                    {
                      this.state.classErrorLimpiezaSilos === true ? (
                        <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.MENSAJE_ALERTA_UBICACION')}</span>
                      ) : null
                    }
                    {
                      this.state.classErrorLimpiezaCorrales === true ? (
                        <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.MENSAJE_ALERTA_UBICACION')}</span>
                      ) : null
                    }
                  </div>
                  <div className="clearfix"></div>
                  <Field
                    colSm={12}
                    id="observaciones"
                    name="limpiezaCorrales.observaciones"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.OBSERVACIONES')}
                    component={InputText}
                    componentClass="textarea"
                    claseActivo="observaciones"
                  />
                  <Col sm={12} className="botones-footer">
                    {
                      Object.keys(registroModificar).length > 0 ? (
                        registroModificar.limpiezaCorrales && registroModificar.limpiezaCorrales.idAgenda !== null ? (
                          <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda(registroModificar.limpiezaCorrales.idAgenda)}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.EDITAR_AVISO_AGENDA')}</Button>
                        ) : <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda()}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CREAR_AVISO_AGENDA')}</Button>
                      ) : null
                    }
                    {
                      Object.keys(this.props.registroModificar).length > 0 && (
                        registroModificar.limpiezaCorrales && (
                          <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeBioseguridad.bind(this, registroModificar.limpiezaCorrales.idBioSeguridad, 3))}><Print />{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.IMPRIMIR')}</Button>
                        )
                      )
                    }
                    {
                      Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false ? (
                        <Button
                          type="button"
                          onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? (
                              () => {duplicarDatosModalBioseguridadHigiene(registroModificar.limpiezaCorrales.idBioSeguridad, this.state.tipoAccion)}) : undefined}
                          className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                        >
                          {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.DUPLICAR')}
                        </Button>
                      ) : (
                        <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded !== true ? handleSubmit(this.guardarNuevoLimpiezaCorrales.bind(this)) : null}>
                          {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR_Y_NUEVO')}
                        </Button>
                      )
                    }
                    <Button
                      type="button"
                      className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? this.props.submitSucceeded !== true ? handleSubmit(this.submitLimpiezaCorrales.bind(this)) : null : undefined}
                    >
                      {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR')}
                    </Button>
                    <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
                  </Col>
                </div>
              )
            }
            {
              this.state.tipoAccion === 4 && (
                <div>
                  <FormSubtitle style={{marginLeft: '14px'}}>{t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.OTROS`)}</FormSubtitle>
                  <Field
                    id="identificacion"
                    name="tipoOtros.identificacion"
                    colSm={2}
                    component={InputText}
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.IDENTIFICACION') + '*'}
                    claseActivo="nombre-identificacion"
                    validate={required}
                    maxLength={true}
                    valorMaxLength={60}
                  />
                  <Fields
                    names={['fechaEntrada', 'fechaSalida']}
                    component={InputRangeDatePickerDynamicNames}
                    colSm={4}
                    nameFrom="fechaEntrada"
                    nameTo="fechaSalida"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.DESDE_HASTA')}
                  />
                  <Field
                    colSm={2}
                    id="idOperario"
                    name="tipoOtros.idOperario"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.OPERARIO') + '*'}
                    component={InputSelect}
                    options={comboOperarioPredeterminado}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-operario'
                    validate={required}
                  />
                  <Field
                    colSm={2}
                    id="estadoAccion"
                    name="tipoOtros.estadoAccion"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.ESTADO') + '*'}
                    component={InputSelect}
                    options={comboEstadoAccion}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-estado-accion'
                    validate={required}
                  />
                  <Field
                    colSm={2}
                    id="producto"
                    name="tipoOtros.producto"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.PRODUCTO')}
                    component={InputText}
                    maxLength={true}
                    valorMaxLength={120}
                  />
                  <Field
                    id="fechaCaducidad"
                    name="tipoOtros.fechaCaducidad"
                    component={InputDatePicker}
                    colSm={2}
                    controlLabel={t(`PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.FECHA_CADUCIDAD`)}
                  />
                  <Field
                    id="lote"
                    name="tipoOtros.lote"
                    colSm={2}
                    component={InputText}
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.LOTE')}
                    claseActivo="nombre-lote"
                    maxLength={true}
                    valorMaxLength={120}
                  />
                  <Field
                    id="cantidad"
                    name="tipoOtros.cantidad"
                    colSm={2}
                    component={InputNumerical}
                    numDecimales={2}
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.CANTIDAD')}
                    claseActivo="nombre-cantidad"
                  />
                  <Field
                    colSm={2}
                    id="tipo"
                    name="tipoOtros.tipo"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.TIPO')}
                    component={InputSelect}
                    options={comboTipoOtros}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-tipo'
                    onInputChange={(value) => this.cambiarTipoOtros(value)}
                  />
                  <div className="clearfix"></div>
                    {
                      this.state.tipoOtros === 1 && (
                        <div className="tabla-verracos-en-grupo-verracos">
                          <div id="block-options3" className={this.state.imputSelected === true ? 'filtros-tabla-modal-otros' : 'filtros-tabla-modal-otros oculta-action'}>
                            <div className="custom-check-w">
                              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                                <Field
                                  id="seleccionar-todo-modal-bioseguridad3"
                                  key="seleccionar-todo-modal-bioseguridad3"
                                  name="seleccionar-todo-modal-bioseguridad3"
                                  inline
                                  component={InputCheckBox}
                                  onInputChange={(value) => this.intercambiarCheckModalBioseguridad(value)}
                                />,
                                <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionModalUbicacion()}>
                                  <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                                </button>,
                                <ModalUbicacionPage key="ModalUbicacionPage" />
                              ]}
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#new-edit-modal"
                                className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? () => abrirModalUbicacion(listUbicacionAnadidos) : undefined}
                              >
                                <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                              </button>
                            </div>
                          </div>
                          <ListadoPage noTitle={true} table={tableOtrosUbicacion}>
                          </ListadoPage>
                          {
                            this.state.classErrorOtrosVerracos === true ? (
                              <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.MENSAJE_ALERTA_OTROS')}</span>
                            ) : null
                          }
                        </div>
                      )
                    }
                    {
                      this.state.tipoOtros === 2 && (
                        <div className="tabla-verracos-en-grupo-verracos">
                          <div id="block-options4" className={this.state.imputSelected === true ? 'filtros-tabla-modal-otros' : 'filtros-tabla-modal-otros oculta-action'}>
                            <div className="custom-check-w">
                              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                                <Field
                                  id="seleccionar-todo-modal-bioseguridad4"
                                  key="seleccionar-todo-modal-bioseguridad4"
                                  name="seleccionar-todo-modal-bioseguridad4"
                                  inline
                                  component={InputCheckBox}
                                  onInputChange={(value) => this.intercambiarCheckModalBioseguridad(value)}
                                />,
                                <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionModalVerraco()}>
                                  <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                                </button>,
                                <ModalVerracoPage key="ModalVerracoPage" />
                              ]}
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#new-edit-modal"
                                className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? () => abrirModalVerraco(listVerracoAnadidos) : undefined}
                              >
                                <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                              </button>
                            </div>
                          </div>
                          <ListadoPage noTitle={true} table={tableOtrosVerracos}>
                          </ListadoPage>
                          {
                            this.state.classErrorOtrosVerracos === true ? (
                              <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.MENSAJE_ALERTA_OTROS')}</span>
                            ) : null
                          }
                        </div>
                      )
                    }
                    {
                      this.state.tipoOtros === 3 && (
                        <div className="tabla-verracos-en-grupo-verracos">
                          <div id="block-options5" className={this.state.imputSelected === true ? 'filtros-tabla-modal-otros' : 'filtros-tabla-modal-otros oculta-action'}>
                            <div className="custom-check-w">
                              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) && [
                                <Field
                                  id="seleccionar-todo-modal-bioseguridad5"
                                  key="otros.seleccionar-todo-modal-bioseguridad5"
                                  name="otros.seleccionar-todo-modal-bioseguridad5"
                                  inline
                                  component={InputCheckBox}
                                  onInputChange={(value) => this.intercambiarCheckModalBioseguridad(value)}
                                />,
                                <button key="button" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionModalGrupoVerraco()}>
                                  <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                                </button>,
                                <ModalGrupoVerracoPage key="ModalGrupoVerracoPage" />
                              ]}
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#new-edit-modal"
                                className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? () => abrirModalGrupoVerraco(listGrupoVerracoAnadidos) : undefined}
                              >
                                <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                              </button>
                            </div>
                          </div>
                          <ListadoPage noTitle={true} table={tableOtrosGruposVerraco}>
                          </ListadoPage>
                          {
                            this.state.classErrorOtrosVerracos === true ? (
                              <span className="error-limpieza-silos"><Warning /> {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.MENSAJE_ALERTA_OTROS')}</span>
                            ) : null
                          }
                        </div>
                      )
                    }
                  <div className="clearfix"></div>
                  <Field
                    colSm={12}
                    id="observaciones"
                    name="tipoOtros.observaciones"
                    controlLabel={t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.OBSERVACIONES')}
                    component={InputText}
                    componentClass="textarea"
                    claseActivo="observaciones"
                  />
                  <Col sm={12} className="botones-footer">
                    {
                      Object.keys(registroModificar).length > 0 ? (
                        registroModificar.tipoOtros && registroModificar.tipoOtros.idAgenda !== null ? (
                          <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda(registroModificar.tipoOtros.idAgenda)}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.EDITAR_AVISO_AGENDA')}</Button>
                        ) : <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={() => this.abrirModalAgenda()}><Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CREAR_AVISO_AGENDA')}</Button>
                      ) : null
                    }
                    {
                      Object.keys(this.props.registroModificar).length > 0 && (
                        registroModificar.tipoOtros && (
                          <Button type="button" className="imprimir-registro-alimentacion-modal btn btn-primary" onClick={handleSubmit(this.imprimirInformeBioseguridad.bind(this, registroModificar.tipoOtros.idBioSeguridad, 4))}><Print />{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.IMPRIMIR')}</Button>
                        )
                      )
                    }
                    {
                      Object.keys(this.props.registroModificar).length > 0 && this.props.duplicarBioseguridad === false ? (
                        <Button
                          type="button"
                          className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                          onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? (
                              () => {duplicarDatosModalBioseguridadHigiene(registroModificar.tipoOtros.idBioSeguridad, this.state.tipoAccion)}) : undefined}
                        >
                          {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.DUPLICAR')}
                        </Button>
                      ) : (
                        <Button type="button" className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}  onClick={this.props.submitSucceeded !== true ? handleSubmit(this.guardarNuevoOtros.bind(this)) : null}>
                          {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR_Y_NUEVO')}
                        </Button>
                      )
                    }
                    <Button
                      type="button"
                      className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] > 1) ? this.props.submitSucceeded !== true ? handleSubmit(this.submitOtros.bind(this)) : null : undefined}
                    >
                      {t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.GUARDAR')}
                    </Button>
                    <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.CANCELAR')}</Button>
                  </Col>
                </div>
              )
            }
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalBioseguridadHigiene',
})(ModalBioseguridadHigiene))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalVerracoRegistroAlimentacion from '../../../components/planSanitario/registroAlimentacion/ModalVerracoRegistroAlimentacion'
import { abrirModalSeleccionarVerraco, cerrarModalSeleccionarVerraco, fetchVerracosGrupoVerraco, seleccionarTodosModalVerracoGrupoVerraco, deSeleccionarTodosModalVerracoGrupoVerraco, anadirVerraco,
  fetchFiltrarVerracoModalVerraco} from '../../../actions/grupoVerraco/GrupoVerraco'
import {closeModal} from '../../../actions/common'
import {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboEstadoVerraco, comboVerraco, comboNombreGrupoVerracoActiva} from '../../../actions/combos/combos'

import {cerrarModalVerracoRegistroAlimentacion, fetchVerracosRegistroAlimentacion, seleccionarTodosModalVerracoRegistroAlimentacion, deSeleccionarTodosModalVerracoRegistroAlimentacion,
  anadirVerracoModalRegistroAlimentacion, fetchFiltrarVerracoModalRegistroAlimentacion} from '../../../actions/planSanitario/registroAlimentacion/registroAlimentacion'

export function mapStateToProps (state) {
  return {
    ...state.grupoVerraco,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos,
    registroAlimentacion: state.registroAlimentacion
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalSeleccionarVerraco,
      cerrarModalSeleccionarVerraco,
      fetchVerracosGrupoVerraco,
      seleccionarTodosModalVerracoGrupoVerraco,
      deSeleccionarTodosModalVerracoGrupoVerraco,
      anadirVerraco,
      fetchFiltrarVerracoModalVerraco,
      comboRaza,
      comboLineaGenetica,
      comboUbicacionPadre,
      comboVerraco,
      comboNombreGrupoVerracoActiva,
      cerrarModalVerracoRegistroAlimentacion,
      fetchVerracosRegistroAlimentacion,
      seleccionarTodosModalVerracoRegistroAlimentacion,
      deSeleccionarTodosModalVerracoRegistroAlimentacion,
      anadirVerracoModalRegistroAlimentacion,
      fetchFiltrarVerracoModalRegistroAlimentacion
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalVerracoRegistroAlimentacion))
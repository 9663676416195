export default {
  ABRIR_MODAL_PRODUCTO: 'ABRIR_MODAL_PRODUCTO',
  CLOSE_MODAL_PRODUCTO: 'CLOSE_MODAL_PRODUCTO',
  SUBMIT_NUEVO_PRODUCTO: 'SUBMIT_NUEVO_PRODUCTO',
  SUBMIT_NUEVO_PRODUCTO_SUCCESS: 'SUBMIT_NUEVO_PRODUCTO_SUCCESS',
  OBTENER_PRODUCTOS_ALMACEN: 'OBTENER_PRODUCTOS_ALMACEN',
  FILTRAR_PRODUCTO: 'FILTRAR_PRODUCTO',
  FILTRAR_PRODUCTO_SUCCESS: 'FILTRAR_PRODUCTO_SUCCESS',
  FILTROS_PRODUCTO_SUCCESS: 'FILTROS_PRODUCTO_SUCCESS',
  RECUPERAR_DATOS_MODAL_PRODUCTO: 'RECUPERAR_DATOS_MODAL_PRODUCTO',
  RECUPERAR_DATOS_MODAL_PRODUCTO_SUCCESS: 'RECUPERAR_DATOS_MODAL_PRODUCTO_SUCCESS',
  EDITAR_PRODUCTO: 'EDITAR_PRODUCTO',
  DUPLICAR_DATOS_MODAL_PRODUCTO: 'DUPLICAR_DATOS_MODAL_PRODUCTO',
  DUPLICAR_DATOS_MODAL_PRODUCTO_SUCCESS: 'DUPLICAR_DATOS_MODAL_PRODUCTO_SUCCESS',
  CAMBIAR_ESTADO_PRODUCTO: 'CAMBIAR_ESTADO_PRODUCTO',
  ON_DELETE_PRODUCTO: 'ON_DELETE_PRODUCTO',
  SELECCIONAR_TODOS_PRODUCTO: 'SELECCIONAR_TODOS_PRODUCTO',
  DESELECCIONAR_TODOS_PRODUCTO: 'DESELECCIONAR_TODOS_PRODUCTO',
  CAMBIAR_ESTADO_SELECCION_PRODUCTO: 'CAMBIAR_ESTADO_SELECCION_PRODUCTO',
  DELETE_SELECCION_PRODUCTO: 'DELETE_SELECCION_PRODUCTO',
  CREATE_CSV_PRODUCTO: 'CREATE_CSV_PRODUCTO',
  CREATE_CSV_PRODUCTO_SUCCESS: 'CREATE_CSV_PRODUCTO_SUCCESS',
  IMPRIMIR_PDF_PRODUCTO: 'IMPRIMIR_PDF_PRODUCTO',
  IMPRIMIR_PDF_PRODUCTO_SUCCESS: 'IMPRIMIR_PDF_PRODUCTO_SUCCESS'
}
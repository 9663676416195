import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalGruposUsuario from '../../components/usuarios/ModalGruposUsuario'
import { cerrarModalGruposUsuarios, seleccionarTodosModalGruposUsuario, deSeleccionarTodosModalGruposUsuario, anadirGruposUsuario, fetchUsuarioGrupoUsuarios
  } from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalGruposUsuarios,
      seleccionarTodosModalGruposUsuario,
      deSeleccionarTodosModalGruposUsuario,
      anadirGruposUsuario,
      fetchUsuarioGrupoUsuarios
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGruposUsuario))
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import './ModalRuta.scss'
import {date_formatter} from '../../util/formatFunctions'
import { Delete, AddCircleOutline, Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalClienteRutaPage from '../../containers/ruta/ModalClienteRutaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalRuta extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      mensajeErrorClientes: '',
      classErrorClientes: false,
      mostrarFechaAsignacion: false,
      mostrarFechaDesasignacion: false,
    }
  }

  close(){
    this.props.actions.closeModal()
    this.props.actions.limpiar()
    this.setState({classErrorClientes: false})
    this.setState({mensajeErrorClientes: ''})
    this.setState({mostrarFechaDesasignacion: false})
    this.setState({mostrarFechaAsignacion: false})
  }

  submitNuevoRuta (values) {
    values.cliente = this.props.clientesAnadidos
    //if (Object.keys(this.props.clientesAnadidos).length > 0) {
      this.setState({classErrorClientes: false})
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarRuta(values)
      } else {
        this.props.actions.submitNuevoRuta(values)
      }
    /*} else {
      this.setState({classErrorClientes: true})
      this.setState({mensajeErrorClientes: 'Necesitas tener almenos un cliente asigando.'})
    }*/
  }

  guardarYnuevoRuta(values){
    values.cliente = this.props.clientesAnadidos
    // if (Object.keys(this.props.clientesAnadidos).length > 0) {
      this.setState({classErrorClientes: false})
      this.props.actions.guardarYnuevoRuta(values)
    /*} else {
      this.setState({classErrorClientes: true})
      this.setState({mensajeErrorClientes: 'Necesitas tener almenos un cliente asigando.'})
    }*/
  }

  intercambiarCheckModalRuta (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalRuta()
    } else {
      this.props.actions.deSeleccionarTodosModalRuta()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-modal-ruta', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalRuta()
          }
        }
      }
    }
  }

  deleteSeleccionClientesRuta () {
    const itemsSeleccion = this.props.clientesAnadidos.map((row) => {
      if (this.props.formSimpleTable) {
        return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
          {
            'check': this.props.formSimpleTable.values['check' + row.idElemento],
            'direccion': row.direccion,
            'idDireccionCliente': row.idDireccionCliente,
            'razonSocialCliente': row.razonSocialCliente,
            'cifCliente': row.cifCliente,
            'idElemento': row.idElemento + 'cliente'
          }
        )
      }
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false) {
        return item
      }
    })
    this.props.actions.deleteSeleccionClientesRuta(list)
  }

  subirOrdenCliente (id) {
    const ordenCliente = this.props.clientesAnadidos.map((row) => {
      return{
        'direccion': row.direccion,
        'idDireccionCliente': row.idDireccionCliente,
        'razonSocialCliente': row.razonSocialCliente,
        'cifCliente': row.cifCliente,
        'orden': id > 1 && id === row.orden ? (row.orden - 1) : (id > 1 && id - 1 === row.orden ? (row.orden + 1) : row.orden),
        'idElemento': row.idElemento + 'cliente',
        'nombreDireccion': row.nombreDireccion,
        'localidad': row.localidad,
        'provincia': row.provincia
      }
    })
    ordenCliente.sort(function (a,b){
      if (a.orden > b.orden) {
        return 1;
      }
      if (a.orden < b.orden) {
        return -1;
      }
      return 0
    })
    this.props.actions.deleteSeleccionClientesRuta(ordenCliente)
  }

  bajarOrdenCliente (id) {
    const ordenCliente = this.props.clientesAnadidos.map((row) => {
      return{
        'direccion': row.direccion,
        'idDireccionCliente': row.idDireccionCliente,
        'razonSocialCliente': row.razonSocialCliente,
        'cifCliente': row.cifCliente,
        'orden': id < Object.keys(this.props.clientesAnadidos).length && id === row.orden ? (row.orden + 1) : (id < Object.keys(this.props.clientesAnadidos).length && id + 1 === row.orden ? (row.orden - 1) : row.orden),
        'idElemento': row.idElemento + 'cliente',
        'nombreDireccion': row.nombreDireccion,
        'localidad': row.localidad,
        'provincia': row.provincia
      }
    })
    ordenCliente.sort(function (a,b){
      if (a.orden > b.orden) {
        return 1;
      }
      if (a.orden < b.orden) {
        return -1;
      }
      return 0
    })
    this.props.actions.deleteSeleccionClientesRuta(ordenCliente)
  }

  render () {
    const {
      t, handleSubmit, showModalRuta, list, data, duplicar, checkedRuta, numeroRegistrosPorPagina, clientesAnadidos, paginaActual,
      actions: {recuperarDatosModalRuta, closeModal, abrirModalClienteRuta, onDeleteClienteAnadido}, auth: {formaFechaHora}
    } = this.props

    clientesAnadidos.forEach(
      (row) => row.idElemento = row.idDireccionCliente  + 'cliente'
    )
    clientesAnadidos.forEach(
      (row, idRow) => row.orden = idRow + 1
    )

    const tableClientesAnadidos = {
      id: 'verracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1),
      columns: [
        {id: 'orden', name: t('RUTA.MODAL.TABLA.ORDEN')},
        {id: 'razonSocialCliente', name: t('RUTA.MODAL.TABLA.CLIENTE')},
        {id: 'cifCliente', name: t('RUTA.MODAL.TABLA.CIF')},
        {id: 'nombreDireccion', name: t('RUTA.MODAL.TABLA.GRANJA')},
        {id: 'localidad', name: t('RUTA.MODAL.TABLA.LOCALIDAD')},
        {id: 'provincia', name: t('RUTA.MODAL.TABLA.PROVINCIA')}
      ],
      rows: clientesAnadidos,
      onDelete: (row) => onDeleteClienteAnadido(row.idDireccionCliente),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      mostrarSubirBajar: true,
      tablaModal: true,
      idBlockOptions: 1,
      noBlockOptions: true,
      subirOrden: (row) => this.subirOrdenCliente(row.orden),
      bajarOrden: (row) => this.bajarOrdenCliente(row.orden),
      showModal: this.props.showModalClienteRuta,
      initialValues: {}
    }

    tableClientesAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableClientesAnadidos.initialValues['check' + row.idElemento] = checkedRuta
        }
      }
    )

    return (
      <Modal show={showModalRuta} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-ruta" backdrop="static">
        <form className="form-ruta">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('RUTA.MODAL.TITULO_EDITAR')
            ) : (
              t('RUTA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idRuta && (
            <ButtonChangeRecord list={list} idElemento="idRuta" currentId={data.idRuta} getNextRecord={recuperarDatosModalRuta} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={4}
                id="nombre-ruta"
                name="nombreRuta"
                component={InputText}
                controlLabel={t('RUTA.MODAL.NOMBRE_RUTA') + '*'}
                customClass='nombre-ruta'
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />

              <Field
                colSm={8}
                id="observacionesRuta"
                name="observaciones"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('RUTA.MODAL.OBSERVACIONES')}
                customClass='descripcion-ruta'
              />
              <div className="clearfix"></div>
              <div className="tabla-cliente-en-ruta">
                <div id="block-options1" className={this.state.imputSelected === true ? 'filtros-tabla-modal-ruta' : 'filtros-tabla-modal-ruta oculta-action'}>
                  <div className="custom-check-w">
                    {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1) && [
                      <Field
                        id="seleccionar-todo-modal-ruta"
                        name="seleccionar-todo-modal-ruta"
                        key="seleccionar-todo-modal-ruta"
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheckModalRuta(value)}
                      />,
                      <button key="eliminar_key" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionClientesRuta()}>
                        <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                      </button>
                    ]}
                    <ModalClienteRutaPage />
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#new-edit-modal"
                      className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RUTAS] < 2) ? ' disabled' : '')}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1) ? abrirModalClienteRuta : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                  </div>
                </div>
                <ListadoPage t_key="RUTA." noTitle={true} table={tableClientesAnadidos}>
                </ListadoPage>
                {/*
                  <p className={this.state.classErrorClientes === true ? 'error-cliente' : 'noerror-cliente'}><Warning/>{this.state.mensajeErrorClientes}</p>
                */}
              </div>
              <Field
                id="activoEstado"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('RUTA.MODAL.ACTIVO')}
              />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(data).length > 0 && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RUTAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('RUTA.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            Object.keys(data).length === 0 && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RUTAS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoRuta.bind(this)) : null : undefined}
              >{t('RUTA.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoRuta.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RUTAS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RUTAS] < 2)}
          >{t('RUTA.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.close()}>{t('RUTA.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRuta',
  // enableReinitialize: true
})(ModalRuta))

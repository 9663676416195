export default {
  FETCH_ANALISIS_PARA_PIC: 'FETCH_ANALISIS_PARA_PIC',
  FETCH_ANALISIS_PARA_PIC_SUCCESS: 'FETCH_ANALISIS_PARA_PIC_SUCCESS',
  FETCH_PIC_TRAITS: 'FETCH_PIC_TRAITS',
  FETCH_PIC_TRAITS_SUCCESS: 'FETCH_PIC_TRAITS_SUCCESS',
  INSERT_ANALISIS_PIC: 'INSERT_ANALISIS_PIC',
  INSERT_ANALISIS_PIC_SUCCESS: 'INSERT_ANALISIS_PIC_SUCCESS',
  RESET_ANALISIS_PIC: 'RESET_ANALISIS_PIC',
  SAVE_DATE_PIC: 'SAVE_DATE_PIC',
  SAVE_DATE_PIC_SUCCESS: 'SAVE_DATE_PIC_SUCCESS',
  GET_URL_PIC: 'GET_URL_PIC',
  GET_URL_PIC_SUCCESS: 'GET_URL_PIC_SUCCESS',
  SINCRONIZAR_INDEX_PIC: 'SINCRONIZAR_INDEX_PIC',
  SINCRONIZAR_INDEX_PIC_SUCCESS: 'SINCRONIZAR_INDEX_PIC_SUCCESS',
  FETCH_POOL_SPLITS_PARA_PIC: 'FETCH_POOL_SPLITS_PARA_PIC',
  FETCH_POOL_PARA_PIC_SUCCESS: 'FETCH_POOL_PARA_PIC_SUCCESS',
  INSERT_POOL_PIC: 'INSERT_POOL_PIC',
  INSERT_POOL_PIC_SUCCESS: 'INSERT_POOL_PIC_SUCCESS',
  INSERT_SPLITS_PIC: 'INSERT_SPLITS_PIC',
  INSERT_SPLITS_PIC_SUCCESS: 'INSERT_SPLIT_PIC_SUCCESS',
  SAVE_DATA_SPLITS_PIC: 'SAVE_DATA_SPLITS_PIC',
  SAVE_DATA_POOL_PIC: 'SAVE_DATA_POOL_PIC',
  SAVE_DATA_POOL_PIC_SUCCESS: 'SAVE_DATA_POOL_PIC_SUCCESS',
  SAVE_DATA_SPLITS_PIC_SUCCESS: 'SAVE_DATA_SPLITS_PIC_SUCCESS',
  FETCH_SPLIT_PARA_PIC_SUCCESS : 'FETCH_SPLIT_PARA_PIC_SUCCESS'
}
import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputNumerical from '../comun/form/InputNumerical'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosFichaVerraco extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  componentDidMount () {
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboVerraco()
    this.props.actions.comboNivelGenetico()
    this.props.initialize(this.props.fichaVerraco.filtros);
  }

  componentDidUpdate (prevProps) {
    if (this.props.fichaVerraco.datosExcelFichaVerraco !== prevProps.fichaVerraco.datosExcelFichaVerraco) {
      if (this.props.fichaVerraco.datosExcelFichaVerraco.excel && this.props.fichaVerraco.datosExcelFichaVerraco.excel !== null){
        let pdf = this.props.fichaVerraco.datosExcelFichaVerraco.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.VERRACOS.FICHA_VERRACOS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.fichaVerraco.datosPdfFichaVerraco !== prevProps.fichaVerraco.datosPdfFichaVerraco) {
      if (this.props.fichaVerraco.datosPdfFichaVerraco.pdf && this.props.fichaVerraco.datosPdfFichaVerraco.pdf !== null){
        let pdf = this.props.fichaVerraco.datosPdfFichaVerraco.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.VERRACOS.FICHA_VERRACOS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosFichaVerraco()
          }
        }
      }
    }
  }

  fetchFiltrarFichaVerraco(values){
    this.props.actions.fetchFiltrarFichaVerraco(values)
  }

  fetchFiltrarFechaEntrada (val, prevVal) {
    if (Object.keys(this.props.formFiltrosFichaVerraco).length > 0){
      this.props.formFiltrosFichaVerraco.values.fechaEntrada = prevVal
      this.props.actions.fetchFiltrarFichaVerraco(this.props.formFiltrosFichaVerraco.values)
    }
  }

  fetchFiltrarFechaNacimiento (val, prevVal) {
    if (Object.keys(this.props.formFiltrosFichaVerraco).length > 0){
      this.props.formFiltrosFichaVerraco.values.fechaNacimiento = prevVal
      this.props.actions.fetchFiltrarFichaVerraco(this.props.formFiltrosFichaVerraco.values)
    }
  }
  fetchFiltrarFechaEliminacion (val, prevVal) {
    if (Object.keys(this.props.formFiltrosFichaVerraco).length > 0){
      this.props.formFiltrosFichaVerraco.values.fechaEliminacion = prevVal
      this.props.actions.fetchFiltrarFichaVerraco(this.props.formFiltrosFichaVerraco.values)
    }
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosFichaVerraco()
    } else {
      this.props.actions.deSeleccionarTodosFichaVerraco()
    }
  }

  cambiarVigiladoSeleccionFichaVerraco(){
    const itemsSeleccion = this.props.fichaVerraco.list.verracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.cambiarVigiladoSeleccionFichaVerraco(list)
  }

  deleteSeleccionFichaVerraco(){
    const itemsSeleccion = this.props.fichaVerraco.list.verracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionFichaVerraco(list)
  }

  crearCsvFichaVerraco(){
    this.props.actions.crearCsvFichaVerraco(this.props.fichaVerraco.filtros)
  }

  imprimirPdfFichaVerraco(){
    this.props.actions.imprimirPdfFichaVerraco(this.props.fichaVerraco.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.fichaVerraco.filtros
    values[value] = null
    this.props.actions.fetchFiltrarFichaVerraco(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit,
    auth: { permisos, formaFechaHora },
    combos: {comboVerraco, comboRaza, comboLineaGenetica, comboNivelGenetico},
    fichaVerraco: {filtros}
  } = this.props

  let comboTratamiento = this.props.combos.comboTratamiento
  comboTratamiento = comboTratamiento.map((tratamiento) => {
    return {...tratamiento, label: t(`COMUN.COMBOS.${tratamiento.label}`)}
  })

  let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

  return (
      <div>        
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('FICHA_VERRACO.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('FICHA_VERRACO.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='select-raza'
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('FICHA_VERRACO.FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='select-lin-genetica'
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Field
                colSm={2}
                id="nivelGenetico"
                name="nivelGenetico"
                controlLabel={t('PLANING.FILTROS.NIVEL_GENETICO')}
                component={InputSelect}
                options={comboNivelGenetico}
                valueKey="value"
                labelKey="label"
                customClass='nombre-operario'
                onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
              />
            <Field
              colSm={2}
              id="estadoVerraco"
              name="estadoVerraco"
              controlLabel={t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO')}
              component={InputSelect}
              options={comboEstadoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-verraco'
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="enTratamiento"
              name="enTratamiento"
              controlLabel={t('FICHA_VERRACO.FILTROS.EN_TRATAMIENTO')}
              component={InputSelect}
              options={comboTratamiento}
              valueKey="value"
              labelKey="label"
              customClass='select-en-tratamiento'
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Field
              colSm={2}
              id="ebv"
              name="ebv"
              controlLabel={t('FICHA_VERRACO.FILTROS.INDEX')}
              component={InputNumerical}
              numDecimales={0}
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Fields
              names={['fechaEntradaDesde', 'fechaEntradaHasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntradaDesde"
              nameTo="fechaEntradaHasta"
              controlLabel={t('FICHA_VERRACO.FILTROS.FECHA_ENTRADA')}
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Fields
              names={['fechaNacimientoDesde', 'fechaNacimientoHasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaNacimientoDesde"
              nameTo="fechaNacimientoHasta"
              controlLabel={t('FICHA_VERRACO.FILTROS.FECHA_NACIMIENTO')}
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <Fields
              names={['fechaEliminacionDesde', 'fechaEliminacionHasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEliminacionDesde"
              nameTo="fechaEliminacionHasta"
              controlLabel={t('FICHA_VERRACO.FILTROS.FECHA_ELIMINACION')}
              onInputChange={handleSubmit(this.fetchFiltrarFichaVerraco.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.verraco && filtros.verraco !== null && filtros.verraco !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.raza && filtros.raza !== null ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.lineaGenetica && filtros.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.LIN_GENETICA') + ': ' + filtros.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nivelGenetico && filtros.nivelGenetico !== null ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.NIVEL_GENETICO') + ': ' + filtros.nivelGenetico.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nivelGenetico')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.estadoVerraco && filtros.estadoVerraco !== null && filtros.estadoVerraco !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO') + ': ' + filtros.estadoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estadoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.enTratamiento && filtros.enTratamiento !== null && filtros.enTratamiento !== '') ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.EN_TRATAMIENTO') + ': ' + filtros.enTratamiento.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('enTratamiento')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.ebv && filtros.ebv !== null ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.INDEX') + ': ' + filtros.ebv}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntradaDesde && filtros.fechaEntradaHasta && filtros.fechaEntradaDesde !== null && filtros.fechaEntradaHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ENTRADA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntradaDesde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaEntradaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntradaHasta')}> x </button></span>
                    ) : (filtros.fechaEntradaDesde && filtros.fechaEntradaDesde !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ENTRADA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntradaDesde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntradaDesde')}> x </button></span>
                    ) : (filtros.fechaEntradaHasta && filtros.fechaEntradaHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ENTRADA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaEntradaHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntradaHasta')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaNacimientoDesde && filtros.fechaNacimientoHasta && filtros.fechaNacimientoDesde !== null && filtros.fechaNacimientoHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_NACIMIENTO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaNacimientoDesde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaNacimientoHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaNacimientoHasta')}> x </button></span>
                    ) : (filtros.fechaNacimientoDesde && filtros.fechaNacimientoDesde !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_NACIMIENTO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaNacimientoDesde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaNacimientoDesde')}> x </button></span>
                    ) : (filtros.fechaNacimientoHasta && filtros.fechaNacimientoHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_NACIMIENTO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaNacimientoHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaNacimientoHasta')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEliminacionDesde && filtros.fechaEliminacionHasta && filtros.fechaEliminacionDesde !== null && filtros.fechaEliminacionHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEliminacionDesde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaEliminacionHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEliminacionHasta')}> x </button></span>
                    ) : (filtros.fechaEliminacionDesde && filtros.fechaEliminacionDesde !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ELIMINACION') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEliminacionDesde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEliminacionDesde')}> x </button></span>
                    ) : (filtros.fechaEliminacionHasta && filtros.fechaEliminacionHasta !== null) ? (
                      <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.FECHA_ELIMINACION') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaEliminacionHasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEliminacionHasta')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FICHA_VERRACOS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambiarVigiladoSeleccionFichaVerraco()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.CAMBIAR_VIGILADO')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionFichaVerraco()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvFichaVerraco()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfFichaVerraco()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                {(permisos === '-1' || permisos[permisosConstant.FICHA_VERRACOS] > 1) ? (
                  <Link to="/NuevoVerraco" className="btn btn-primary nuevo" style={{verticalAlign: 'inherit'}}>
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary nuevo disabled"
                    disabled={true}
                  >{t('TITULOS_BOTONES.NUEVO')}</button>
                )}
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosFichaVerraco',
})(FiltrosFichaVerraco))

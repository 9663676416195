import actionTypes from '../../constants/actions/lineaGenetica/lineaGenetica'

export function initialState () {
  return {
    list: [],
    checkedFiltros: false,
    filtros:{},
    datosExcelLinea: [],
    datosPdfLinea: [],
    showModalLineaGenetica: false
  }
}

export function fetchLineaGeneticaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function recuperarDatosModalSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function cambiarEstadoSuccess (state, {dataEstado}) {
  return {
    ...state,
    dataEstado
  }
}

export function onDeleteSuccess (state, {dataDelete}) {
  return {
    ...state,
    dataDelete
  }
}

export function seleccionarTodosLineaGenetica (state) {
  return {
    ...state,
    checkedFiltros: true
  }
}

export function deSeleccionarTodosLineaGenetica (state) {
  return {
    ...state,
    checkedFiltros: false
  }
}

export function fetchFiltrarLineaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function submitNuevaLineaGeneticaSuccess (state, {insercionLinea}) {
  return {
    ...state,
    insercionLinea
  }
}

export function editarLineaGeneticaSuccess (state, {edicionLinea}) {
  return {
    ...state,
    edicionLinea
  }
}

export function crearCsvSuccess (state, {datosExcelLinea}) {
  return {
    ...state,
    datosExcelLinea
  }
}

export function imprimirPdfLineaSuccess (state, {datosPdfLinea}) {
  return {
    ...state,
    datosPdfLinea
  }
}

export function openModalLineaGenetica (state) {
  return {
    ...state,
    showModalLineaGenetica: true
  }
}

export function closeModalLineaGenetica (state) {
  return {
    ...state,
    showModalLineaGenetica: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_LINEA_GENETICA_SUCCESS:
      return fetchLineaGeneticaSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_SUCCESS:
      return recuperarDatosModalSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_SUCCESS:
      return duplicarDatosModalSuccess(state, action)
    case actionTypes.CAMBIAR_ESTADO_SUCCESS:
      return cambiarEstadoSuccess(state, action)
    case actionTypes.ON_DELETE_SUCCESS:
      return onDeleteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_LINEA_GENETICA:
      return seleccionarTodosLineaGenetica(state, action)
    case actionTypes.DESELECCIONAR_TODOS_LINEA_GENETICA:
      return deSeleccionarTodosLineaGenetica(state, action)
    case actionTypes.FETCH_FILTRAR_LINEA_SUCCESS:
      return fetchFiltrarLineaSuccess(state, action)
    case actionTypes.SUBMIT_NUEVA_LINEA_GENETICA_SUCCESS:
      return submitNuevaLineaGeneticaSuccess(state, action)
    case actionTypes.EDITAR_LINEA_GENETICA_SUCCESS:
      return editarLineaGeneticaSuccess(state, action)
    case actionTypes.CREATE_CSV_SUCCESS:
      return crearCsvSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_LINEA_SUCCESS:
      return imprimirPdfLineaSuccess(state, action)
    case actionTypes.OPEN_MODAL_LINEA_GENETICA:
      return openModalLineaGenetica(state, action)
    case actionTypes.CLOSE_MODAL_LINEA_GENETICA:
      return closeModalLineaGenetica(state, action)
    default:
      return state
  }
}

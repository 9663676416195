import React, { Component } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { PowerSettingsNew } from '@material-ui/icons'

const popoveruser = ({t, actions: {logout}, auth: {nombreUsuario}}) => (
  <Popover id="popover-trigger-click-root-close" className="content-popover-user" onClick={() => document.body.click()}>
    <div className="user-popover">
      <div className="data-name">{nombreUsuario}</div>
    </div>
    <div className="mi-cuenta-popover">
      <ul>
        <li onClick={() => logout() }><PowerSettingsNew /><span className="acciones-mi-cuenta-popover">{t('HEADER.CERRAR_SESION')}</span></li>
      </ul>
    </div>
  </Popover>
)

class AdminSubHeader extends Component {
  render () {
    const { auth } = this.props
    return (
      <div className="loged adminLogin">
        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popoveruser(this.props)}>
          <div className="nav-link dropdown-toggle">
            <div className="user-data">
              <div className="data-name">{auth.nombreUsuario}</div>
            </div>
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

export default AdminSubHeader

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalListadoCentros from '../../components/usuarios/ModalListadoCentros'
import { cerrarModalListCentro, seleccionarTodosModalListCentro, deSeleccionarTodosModalListCentro, anadirListCentro, fetchCentrosUsuarios
  } from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalListCentro,
      seleccionarTodosModalListCentro,
      deSeleccionarTodosModalListCentro,
      anadirListCentro,
      fetchCentrosUsuarios
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalListadoCentros))
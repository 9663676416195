import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalRutaPage from '../../containers/ruta/ModalRutaPage'
import FiltrosRutaPage from '../../containers/ruta/FiltrosRutaPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class Ruta extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTE') + ' - ' + this.props.t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.RUTAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarRuta({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      ruta: { list, checkedFiltrosRuta, data, dataDuplicar },
      actions: { recuperarDatosModalRuta, duplicarDatosModalRuta, cambiarEstadoRuta, onDeleteRuta }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idRuta
    )

    const table = {
      id: 'rutaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreRuta', name: t('RUTA.RUTA')},
        {id: 'numeroClientes', name: t('RUTA.NUM_CLIENTES')},
        {id: 'observaciones', name: t('RUTA.OBSERVACIONES')}
      ],
      rows: list,
      filtros: <FiltrosRutaPage />,
      onEdit: (row) => recuperarDatosModalRuta(row.idRuta),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1,
      duplicar: (row) =>duplicarDatosModalRuta(row.idRuta),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1,
      cambiarEstado: (row) => cambiarEstadoRuta(row.idRuta),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RUTAS] > 1,
      onDelete: (row) => onDeleteRuta(row.idRuta),
      showModal: this.props.showModal,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosRuta
        }
      }
    )
  return (
      <div>
      <SimpleModalPage/>
      <MensajeAlertaPage/>
        <ModalRutaPage
          duplicar={(row) =>duplicarDatosModalRuta(row.idRuta)}
        />
        <ListadoPage t_key="RUTA." table={table}>
        </ListadoPage>
      </div>
    )
  }
}
export default Ruta
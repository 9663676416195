import React, { Component } from 'react'
import {reduxForm} from 'redux-form'
import { Modal, Button } from 'react-bootstrap'
import './SimpleModal.scss'

class SimpleModal extends Component {
  
  render () {
    const { t, showSimpleModal, typeModal, dynamicMessage, subDynamicMessage, actions: { closeModal, okResponse, nOkResponse }, auth: {limiteUsuarios} } = this.props
    let onHide = closeModal
    let subMessage = ''
    let components = (<div/>)
    let message = ''
    let title = ''
    let buttons = ([<Button key="1" onClick={() => closeModal()}>Close</Button>])
    switch (typeModal) {
      case 'cambiarEstado':
        title = t('SIMPLE_MODAL.CAMBIAR_ESTADO')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_ESTADO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_ESTADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'cambiarEstadoVerracosVivos':
        title = t('SIMPLE_MODAL.CAMBIAR_ESTADO_VERRACOS_VIVOS')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_ESTADO_VERRACOS_VIVOS')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_ESTADO_VERRACOS_VIVOS')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'copiaSeguridad':
        title = t('SIMPLE_MODAL.COPIA_SEGURIDAD')
        message = t('SIMPLE_MODAL.MENSAJE_COPIA_SEGURIDAD')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_COPIA_SEGURIDAD')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'conductorPorDefecto':
        title = t('SIMPLE_MODAL.CONDUCTOR_POR_DEFECTO')
        message = t('SIMPLE_MODAL.MENSAJE_CONDUCTOR_POR_DEFECTO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CONDUCTOR_POR_DEFECTO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'vehiculoPorDefecto':
        title = t('SIMPLE_MODAL.VEHICULO_POR_DEFECTO')
        message = t('SIMPLE_MODAL.MENSAJE_VEHICULO_POR_DEFECTO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_VEHICULO_POR_DEFECTO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'rutaPorDefecto':
        title = t('SIMPLE_MODAL.RUTA_POR_DEFECTO')
        message = t('SIMPLE_MODAL.MENSAJE_RUTA_POR_DEFECTO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_RUTA_POR_DEFECTO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'onDelete':
        title = t('SIMPLE_MODAL.ELIMINAR_REGISTRO')
        message = t('SIMPLE_MODAL.MENSAJE_ELIMINAR_REGISTRO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        subMessage = t('SIMPLE_MODAL.PREGUNTA_ELIMINAR_REGISTRO')
        break
      case 'onDeleteDosis':
        title = t('SIMPLE_MODAL.DESCARTAR_REGISTRO')
        message = ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        subMessage = t('SIMPLE_MODAL.PREGUNTA_DESCARTAR_REGISTRO')
        break
      case 'onDeleteDosisEnvasadas':
        title = t('SIMPLE_MODAL.DESCARTAR_REGISTRO')
        message = ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        subMessage = t('SIMPLE_MODAL.PREGUNTA_DESCARTAR_REGISTRO')
        break
      case 'confirmarPedidos':
        title = t('SIMPLE_MODAL.CONFIRMAR_PEDIDOS')
        message = ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CONFIRMAR_PEDIDOS')
        break
      case 'cambiarVigilado':
        title = t('SIMPLE_MODAL.CAMBIAR_VIGILADO')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_VIGILADO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_VIGILADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'cambiarEyaculadoEliminado':
        title = t('SIMPLE_MODAL.CAMBIAR_EYACULADO_ELIMINADO')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_EYACULADO_ELIMINADO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_EYACULADO_ELIMINADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'cambiarOperarioProcesado':
        title = t('SIMPLE_MODAL.CAMBIAR_PROCESADO')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_PROCESADO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_PROCESADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'cambiarOperarioAlbaran':
        title = t('SIMPLE_MODAL.CAMBIAR_ALBARAN')
        message = t('SIMPLE_MODAL.MENSAJE_CAMBIAR_ALBARAN')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_CAMBIAR_ALBARAN')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'verracoEnVigilancia':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_EN_VIGILANCIA')
        subMessage = subDynamicMessage ? t('ANALISIS_EXTRACCIONES.FORM.FORM.OBSERVACIONES') + ': ' + subDynamicMessage : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'verracoControlSangre':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_CON_CONTROL_SANGRE')
        // subMessage = subDynamicMessage ? t('ANALISIS_EXTRACCIONES.FORM.FORM.OBSERVACIONES') + ': ' + subDynamicMessage : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'verracoControlSemen':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_CON_CONTROL_SEMEN')
        // subMessage = subDynamicMessage ? t('ANALISIS_EXTRACCIONES.FORM.FORM.OBSERVACIONES') + ': ' + subDynamicMessage : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      // Eliminamos el focus del boton ("e.currentTarget.blur()") al hacer click, para evitar que se arrastre el focus a la siguiente ventana
      case 'verracoAnalizadoHaceMenosDe24h':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_ANALIZADO_HACE_MENOS_DE_24H')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={(e) => {e.currentTarget.blur();okResponse()}}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'verracoPreguntaCambioEstadoActivo':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_PRIMER_ANALISIS_CAMBIAR_ESTADO_ACTIVO_PREGUNTA')
        subMessage = t('SIMPLE_MODAL.VERRACO_PRIMER_ANALISIS_CAMBIAR_ESTADO_ACTIVO_ACLARACION')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'fechaExtraccionRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.FECHA_EXTRACCION_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'verracoRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VERRACO_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'volumenRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VOLUMEN_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'volumenDiluyenteRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VOLUMEN_DILUYENTE_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'rectaRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.RECTA_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'tipoDosisRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.TIPO_DOSIS_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'urlMagavisionRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.URL_MAGAVISION_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'unoAnalisisExtraccionRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.UNO_ANALISIS_EXTRACCION_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'dosAnalisisExtraccionRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.DOS_ANALISIS_EXTRACCION_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'extraccionDiluida':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.EXTRACCION_DILUIDA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'envasadoMultiDosisRequerido':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.ENVASADO_MULTI_DOSIS_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'limiteCuadrosContadosAlcanzado':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.LIMITE_CUADROS_CONTADOS_ALCANZADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'stockInsuficiente':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.STOCK_INSUFICIENTE')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'fueraLimite':
        title = t('SIMPLE_MODAL.ATENCION')
        message = dynamicMessage
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'imprimirVisita':
        title = t('SIMPLE_MODAL.IMPRIMIR_VISITA_TITLE')
        message = t('SIMPLE_MODAL.IMPRIMIR_VISITA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'imprimirAlbaran':
        title = t('SIMPLE_MODAL.IMPRIMIR_DATOS_ALBARAN_TITLE')
        message = t('SIMPLE_MODAL.IMPRIMIR_DATOS_ALBARAN')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'clienteSinDirecciones':
        title = t('SIMPLE_MODAL.CLIENTE_SIN_DIRECCIONES_TITLE')
        message = t('SIMPLE_MODAL.CLIENTE_SIN_DIRECCIONES')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'dosisPedidasDifiereDosisEnviadas':
        title = t('SIMPLE_MODAL.DOSIS_PEDIDAS_DIFIERE_DOSIS_ENVIADAS_TITLE')
        message = t('SIMPLE_MODAL.DOSIS_PEDIDAS_DIFIERE_DOSIS_ENVIADAS')
        subMessage = t('SIMPLE_MODAL.DOSIS_PEDIDAS_DIFIERE_DOSIS_ENVIADAS_PREGUNTA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'dosisEnviadasExcedeStockDisponible':
        title = t('SIMPLE_MODAL.DOSIS_ENVIADAS_EXCEDE_STOCK_DISPONIBLE_TITLE')
        message = t('SIMPLE_MODAL.DOSIS_ENVIADAS_EXCEDE_STOCK_DISPONIBLE')
        // subMessage = t('SIMPLE_MODAL.DOSIS_ENVIADAS_EXCEDE_STOCK_DISPONIBLE_PREGUNTA')
        buttons = (<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>)
        break
      case 'dosisEnviadasNegativo':
        title = t('SIMPLE_MODAL.DOSIS_ENVIADAS_NEGATIVO_TITLE')
        message = t('SIMPLE_MODAL.DOSIS_ENVIADAS_NEGATIVO')
        // subMessage = t('SIMPLE_MODAL.DOSIS_ENVIADAS_NEGATIVO')
        buttons = (<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>)
        break
      case 'envasadoNoEliminable':
        title = t('SIMPLE_MODAL.ENVASADO_NO_ELIMINABLE_TITLE')
        message = t('SIMPLE_MODAL.ENVASADO_NO_ELIMINABLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'envasadosNoEliminables':
        title = t('SIMPLE_MODAL.ENVASADOS_NO_ELIMINABLES_TITLE')
        message = t('SIMPLE_MODAL.ENVASADOS_NO_ELIMINABLES')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'envasadosNoEliminables':
        title = t('SIMPLE_MODAL.ENVASADOS_NO_ELIMINABLES_TITLE')
        message = t('SIMPLE_MODAL.ENVASADOS_NO_ELIMINABLES')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'diluyenteRequerido':
        title = t('SIMPLE_MODAL.DILUYENTE_REQUERIDO_TITLE')
        message = t('SIMPLE_MODAL.DILUYENTE_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'maquinaRequerida':
        title = t('SIMPLE_MODAL.MAQUINA_REQUERIDA_TITLE')
        message = t('SIMPLE_MODAL.MAQUINA_REQUERIDA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'comprobarModoAguaDeshabilitado':
        title = t('SIMPLE_MODAL.COMPROBAR_MODO_AGUA_DESHABILITADO_TITLE')
        message = t('SIMPLE_MODAL.COMPROBAR_MODO_AGUA_DESHABILITADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'licenciaInvalida':
        title = t('SIMPLE_MODAL.LICENCIA_INVALIDA_TITLE')
        message = t('SIMPLE_MODAL.LICENCIA_INVALIDA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'analisisInexistente':
        title = t('SIMPLE_MODAL.ANALISIS_INEXISTENTE_TITLE')
        message = t('SIMPLE_MODAL.ANALISIS_INEXISTENTE')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'analisisPendienteDeCompletar':
        title = t('SIMPLE_MODAL.ANALISIS_PENDIENTE_DE_COMPLETAR_TITLE')
        message = t('SIMPLE_MODAL.ANALISIS_PENDIENTE_DE_COMPLETAR')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'servidorNoPreparado':
        title = t('SIMPLE_MODAL.SERVIDOR_NO_PREPARADO_TITLE')
        message = t('SIMPLE_MODAL.SERVIDOR_NO_PREPARADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'redireccionEdicionAnalisis':
        title = t('SIMPLE_MODAL.REDIRECCION_EDICION_ANALISIS_TITLE')
        message = t('SIMPLE_MODAL.REDIRECCION_EDICION_ANALISIS')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'crearAvisoAgenda':
        title = t('SIMPLE_MODAL.CREAR_AVISO_AGENDA')
        message = t('SIMPLE_MODAL.CREAR_AVISO_AGENDA')
        buttons = ([<Button id="btn_yes_modal"  className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'eliminarAvisoAgenda':
        title = t('SIMPLE_MODAL.ELIMINAR_AVISO_AGENDA')
        message = t('SIMPLE_MODAL.ELIMINAR_AVISO_AGENDA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'dynamicMessage':
        title = t('SIMPLE_MODAL.ATENCION')
        message = dynamicMessage
        subMessage = subDynamicMessage
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'cambioEstadoEliminado':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('MENSAJE_ALERTA.CAMBIO_ESTADO_ELIMINADO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'ubicacionLibre':
        title = t('SIMPLE_MODAL.UBICACION_LIBRE')
        message = ''
        subMessage = t('SIMPLE_MODAL.PREGUNTA_UBICACION_LIBRE')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'limite-usuarios':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('MENSAJE_ALERTA.LIMITE_USUARIOS') + limiteUsuarios
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'pedidoIncompleto':
        title = t('SIMPLE_MODAL.PEDIDO_INCOMPLETO')
        message = t('SIMPLE_MODAL.MENSAJE_PEDIDO_INCOMPLETO')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_PEDIDO_INCOMPLETO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'predefinidoPanelControl':
        title = t('SIMPLE_MODAL.PREDEFINIDO_PANEL_CONTROL')
        message = t('SIMPLE_MODAL.MENSAJE_PREDEFINIDO_PANEL_CONTROL')
        subMessage = t('SIMPLE_MODAL.PREGUNTA_PREDEFINIDO_PANEL_CONTROL')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'vpnNoDisponible':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.VPN_NO_DISPONIBLE')
        subMessage = subDynamicMessage
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'ipNoDisponible':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.IP_NO_DISPONIBLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'imprimirGuardar':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.IMPRIMIR_GUARDAR')
        subMessage = t('SIMPLE_MODAL.DESEA_CONTINUAR')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'basculaDesconectada':
        title = t('SIMPLE_MODAL.SERVIDOR_NO_PREPARADO_TITLE')
        message = t('SIMPLE_MODAL.BASCULA_DESCONECTADA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'basculaApagada':
        title = t('SIMPLE_MODAL.SERVIDOR_NO_PREPARADO_TITLE')
        message = t('SIMPLE_MODAL.BASCULA_APAGADA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'basculaError':
        title = t('SIMPLE_MODAL.SERVIDOR_NO_PREPARADO_TITLE')
        message = t('SIMPLE_MODAL.BASCULA_NO_PREPARADA')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'alertaPoolNumEyaculados':
        title = t('SIMPLE_MODAL.ALERTA_POOL_TITLE')
        message = t('SIMPLE_MODAL.ALERTA_POOL_NUM_EYACULADOS_MSG')
        subMessage = t('SIMPLE_MODAL.ALERTA_POOL_SUBMSG')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
        break
      case 'alertaPoolNumDosis':
          title = t('SIMPLE_MODAL.ALERTA_POOL_TITLE')
          message = t('SIMPLE_MODAL.ALERTA_POOL_NUM_DOSIS_MSG')
          subMessage = t('SIMPLE_MODAL.ALERTA_POOL_SUBMSG')
          buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
            <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
          break
      case 'alertaEvaluacion':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.EVALUACION_REQUERIDO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      case 'alertaOldMagavisionAbierto':
        title = t('SIMPLE_MODAL.ATENCION')
        message = t('SIMPLE_MODAL.MAGAVISIONOLD_ABIERTO')
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>])
        break
      default:
        message = ''
        buttons = ([<Button id="btn_yes_modal" className="btn-primary" key="1" onClick={() => okResponse()}>{t('SIMPLE_MODAL.ACEPTAR')}</Button>,
          <Button id="btn_no_modal" className="btn-white" key="2" onClick={() => nOkResponse()}>{t('SIMPLE_MODAL.CANCELAR')}</Button>])
    }

    return (
      <Modal show={showSimpleModal} onHide={onHide} className="custom-modal simple-modal" restoreFocus={false} backdrop="static">
      <Modal.Header><h3>{title}</h3></Modal.Header>
        <Modal.Body>
          <p>{message}</p>
          <p style={typeModal === "vpnNoDisponible" ? {whiteSpace: 'pre-line'} : null}>{subMessage}</p>
          {components}
        </Modal.Body>
        <Modal.Footer>
          <div className="buttons-wrapper">
            {buttons}
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}
// export default SimpleModal
export default (reduxForm({
  form: 'SimpleModal',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SimpleModal))

import React, { Component } from 'react'
import './FormSubtitle.scss'

class FormSubtitle extends Component {
  render () {
    const {style} = this.props
    return (
      <h3 className="form-subtitle" style={style}>
        {this.props.children}
      </h3>
    )
  }
}
export default FormSubtitle

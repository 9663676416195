import React, { Component } from 'react'
import { Modal, Button, Col, Tabs, Tab } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Warning, Delete, AddCircleOutline } from '@material-ui/icons'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import ModalDireccionesClientePage from '../../containers/cliente/ModalDireccionesClientePage'
import './Cliente.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalCliente extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false
    }
  }

  componentDidMount () {
    this.props.change('activo', true)
  }

  closeModalCliente () {
    this.props.actions.closeModalCliente()
  }

  abrirModalDireccionesCliente () {
    this.props.actions.abrirModalDireccionesCliente()
  }

  submitNuevoCliente (values) {
    values.direccionesCliente = this.props.listDireccionesCliente
    values.rutas = this.props.listRutas
    if (Object.keys(this.props.data).length > 0){
      this.props.actions.editarCliente(values)
    } else {
      this.props.actions.submitNuevoCliente(values)
    }
  }

  guardarYnuevoCliente (values) {
    values.direccionesCliente = this.props.listDireccionesCliente
    values.rutas = this.props.listRutas
    this.props.actions.guardarYnuevoCliente(values)
  }

  intercambiarCheckModalDireccionesCliente = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosDireccionesCliente()
    } else {
      this.props.actions.deSeleccionarTodosDireccionesCliente()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-modal-direcciones-cliente', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosDireccionesCliente()
          }
        }
      }
    }
  }

  deleteSeleccionDireccionesCliente () {
     const itemsSeleccion = this.props.listDireccionesCliente.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCliente': row.idCliente,
          'activo': row.activo,
          'tipo': row.tipo,
          'nombreDireccion': row.nombreDireccion,
          'funcion': row.funcion,
          'telefono_uno': row.telefono_uno,
          'contacto_telefono_uno': row.contacto_telefono_uno,
          'telefono_dos': row.telefono_dos,
          'contacto_telefono_dos': row.contacto_telefono_dos,
          'telefono_tres': row.telefono_tres,
          'contacto_telefono_tres': row.contacto_telefono_tres,
          'fax': row.fax,
          'direccion': row.direccion,
          'codigoPostal': row.codigoPostal,
          'dni': row.dni,
          'movil': row.movil,
          'email': row.email,
          'provincia': row.provincia,
          'localidad': row.localidad,
          'idTipo': row.idTipo,
          'idElemento': row.idElemento,
          'rutas': row.rutas,
          'idDireccionCliente': row.idDireccionCliente,
          'codigoRega': row.codigoRega
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item !== false;
    })
    this.props.actions.deleteSeleccionDireccionCliente(list)
    this.props.change('seleccionar-todo-modal-direcciones-cliente', false)
  }

  render () {
    const {
      t, handleSubmit, showModalCliente, listCliente, data, dataDuplicar, clienteAnalisis, numeroRegistrosPorPagina, paginaActual, checkedFiltrosClienteAnalisis, checkedFiltrosClienteDilucion,
      clienteDilucion, checkedFiltrosClienteEnvasado, clienteEnvasado, dataCliente, duplicar, listDireccionesCliente, checkedFiltrosClienteAnadidos, listPedidosCliente, listAlbaranCliente,
      listDosisEnviadasCliente,
      actions: {recuperarDatosModalCliente, abrirModalDireccionesCliente, recuperarDatosModalDireccionCliente, duplicarDatosModalDireccionCliente, onDeleteDireccionCliente}
    } = this.props

    // tabla direcciones
    listDireccionesCliente.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'direccion'
    )

    const tableDatosCliente = {
      id: 'clienteTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1),
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1),
      mostrarCambioEstado: false,
      columns: [
        {id: 'tipo', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.TIPO_DIRECCION')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.GRANJA')},
        {id: 'direccion', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.DIRECCION')},
        {id: 'localidad', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.LOCALIDAD')},
        {id: 'provincia', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.PROVINCIA')},
        {id: 'movil', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.MOVIL')},
        {id: 'transportista', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.TRANSPORTISTA')},
        {id: 'rutasDireccionCliente', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.RUTA')}
      ],
      rows: listDireccionesCliente,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1),
      onEdit: (row) => recuperarDatosModalDireccionCliente(row.idElemento),
      duplicar: (row) =>duplicarDatosModalDireccionCliente(row.idElemento),
      onDelete: (row) => onDeleteDireccionCliente(row.idElemento),
      showModal: this.props.showModalDireccionesCliente,
      tablaModal: true,
      idBlockOptions: 1,
      initialValues: {}
    }
    tableDatosCliente.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableDatosCliente.initialValues['check' + row.idElemento] = checkedFiltrosClienteAnadidos
        }
      }
    )

    // TABLA PEDIDOS
    const tablePedidos = {
      id: 'pedidosTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'fecha', name: t('PEDIDOS.CLIENTE.MODAL.PEDIDOS.FECHA'), type: 'fecha'},
        {id: 'numeroPedido', name: t('PEDIDOS.CLIENTE.MODAL.PEDIDOS.N_PEDIDO')},
        {id: 'numeroDosisPedidas', name: t('PEDIDOS.CLIENTE.MODAL.PEDIDOS.N_DOSIS')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')},
        {id: 'estado', name: t('PEDIDOS.CLIENTE.MODAL.PEDIDOS.ESTADO')}
      ],
      rows: listPedidosCliente,
      initialValues: {}
    }

    // TABLA ALBARANES
    const tableAlbaranes = {
      id: 'albaranesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'fechaCreacion', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.FECHA'), type: 'fecha'},
        {id: 'numeroAlbaran', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.N_ALBARAN')},
        {id: 'numeroDosis', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.N_DOSIS')},
        {id: 'tiposDosis', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.TIPO_DOSIS')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')},
        {id: 'nombreTransportista', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.TRANSPORTE')},
        {id: 'nombreRuta', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.RUTA')},
        {id: 'estado', name: t('PEDIDOS.CLIENTE.MODAL.ALBARANES.ESTADO')}
      ],
      rows: listAlbaranCliente,
      initialValues: {}
    }

    // TABLA DOSIS ENVIADAS
    const tableDosisEnviadas = {
      id: 'dosisEnviadasTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'fechaCreacion', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.FECHA'), type: 'fecha'},
        {id: 'nombreTipoDosis', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.N_DOSIS')},
        {id: 'nombreRaza', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.RAZA')},
        {id: 'nombreLinea', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.LIN_GENETICA')},
        {id: 'verracoPool', name: t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.EXTRACCIONES_POOL')},
        {id: 'nombreDireccion', name: t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
      ],
      rows: listDosisEnviadasCliente,
      initialValues: {}
    }

    return (
      <div>
        <Modal show={showModalCliente} onHide={() => this.closeModalCliente()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-cliente">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(this.props.data).length > 0 ? (
                t('PEDIDOS.CLIENTE.MODAL.TITLE_EDITAR')
              ) : (
                Object.keys(this.props.dataDuplicar).length > 0 ? (
                  t('PEDIDOS.CLIENTE.MODAL.TITLE_DUPLICAR')
                ) : (
                  t('PEDIDOS.CLIENTE.MODAL.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
            {data && data.cliente && data.cliente.idCliente && (
              <ButtonChangeRecord list={listCliente.clientes} idElemento="idCliente" currentId={data.cliente.idCliente} getNextRecord={recuperarDatosModalCliente} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="razonSocialCliente"
                name="razonSocialCliente"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.TITLE') + '*'}
                customClass="razon-social"
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={3}
                id="cifCliente"
                name="cifCliente"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL.CIF')}
                customClass="cif"
                maxLength={true}
                valorMaxLength={20}
              />
              <Field
                colSm={3}
                id="direccion"
                name="direccion"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.DIRECCION') + '*'}
                customClass="direccion"
                validate={required}
                maxLength={true}
                valorMaxLength={60}
              />
              <Field
                colSm={3}
                id="localidad"
                name="localidad"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.LOCALIDAD') + '*'}
                customClass="localidad"
                validate={required}
                maxLength={true}
                valorMaxLength={35}
              />
              <Field
                colSm={3}
                id="provincia"
                name="provincia"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PROVINCIA')}
                customClass="provincia"
                maxLength={true}
                valorMaxLength={35}
              />
              <Field
                colSm={3}
                id="email"
                name="email"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.EMAIL')}
                customClass="email"
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={3}
                id="telefono"
                name="telefono"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO')}
                customClass="telefono"
                maxLength={true}
                valorMaxLength={20}
              />
              {/*
              <Field
                colSm={3}
                id="aliasCliente"
                name="aliasCliente"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL.ALIAS')}
                customClass="alias"
                maxLength={true}
                valorMaxLength={20}
              />
              <Field
                colSm={3}
                id="codigoREGACliente"
                name="codigoREGACliente"
                component={InputText}
                controlLabel={t('PEDIDOS.CLIENTE.MODAL.CODIGO_REGA')}
                customClass="codigo-rega"
                maxLength={true}
                valorMaxLength={10}
              />
              */}
              <Field
                id="clienteActivo"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL.ACTIVO')}
              />
              <div className="clearfix"></div>
            </Row>
            <Tabs defaultActiveKey={1} id="tab-modal-cliente" className="tab-modal-cliente">
              <Tab eventKey={1} title={t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.TITLE')}>
                <div className="tabla-verracos-en-direcciones-cliente">
                  <div id="block-options1" className={this.state.imputSelected === true ? 'filtros-tabla-modal-direcciones-cliente' : 'filtros-tabla-modal-direcciones-cliente oculta-action'}>
                    <div className="custom-check-w">
                      {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1) && [
                        <Field
                          id="seleccionar-todo-modal-direcciones-cliente"
                          name="seleccionar-todo-modal-direcciones-cliente"
                          key="seleccionar-todo-modal-direcciones-cliente"
                          inline
                          component={InputCheckBox}
                          onInputChange={(value) => this.intercambiarCheckModalDireccionesCliente(value)}
                        />,
                        <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionDireccionesCliente()}>
                          <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                        </button>,
                        <ModalDireccionesClientePage
                          duplicar={(row) =>duplicarDatosModalDireccionCliente(row.idElemento)} key="ModalDireccionesClientePage"
                        />
                      ]}
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#new-edit-modal"
                        className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2) ? ' disabled' : '')}
                        onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1) ? () => this.abrirModalDireccionesCliente() : undefined}
                      >
                        <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                      </button>
                    </div>
                  </div>
                  <SimpleTablePage {...tableDatosCliente}></SimpleTablePage>
                  <div className="clearfix"></div>
                </div>
              </Tab>
              <Tab eventKey={2} title={t('PEDIDOS.CLIENTE.MODAL.PEDIDOS.TITLE')}>
                <SimpleTablePage {...tablePedidos}></SimpleTablePage>
                <div className="clearfix"></div>
              </Tab>
              <Tab eventKey={3} title={t('PEDIDOS.CLIENTE.MODAL.ALBARANES.TITLE')}>
                <SimpleTablePage {...tableAlbaranes}></SimpleTablePage>
                <div className="clearfix"></div>
              </Tab>
              <Tab eventKey={4} title={t('PEDIDOS.CLIENTE.MODAL.DOSIS_ENVIADAS.TITLE')}>
                <SimpleTablePage {...tableDosisEnviadas}></SimpleTablePage>
                <div className="clearfix"></div>
              </Tab>
            </Tabs>
            <Field
              colSm={12}
              id="ObservacionesCliente"
              name="ObservacionesCliente"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL.OBSERVACIONES')}
              customClass='observaciones-cliente'
            />
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(this.props.data).length > 0 ? (
                <Button
                  type="button"
                  className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CLIENTES] > 1) ? handleSubmit(duplicar) : undefined}
                >{t('PEDIDOS.CLIENTE.MODAL.DUPLICAR')}</Button>
              ) : (
                <Button type="button" className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2) ? ' disabled' : '')} onClick={this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoCliente.bind(this)) : null}>{t('PEDIDOS.CLIENTE.MODAL.GUARDAR_Y_NUEVO')}</Button>
              )
            }
            <Button
              type="button"
              onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevoCliente.bind(this)) : null}
              className={'btn btn-primary' + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2) ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CLIENTES] < 2)}
            >{t('PEDIDOS.CLIENTE.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalCliente()}>{t('PEDIDOS.CLIENTE.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalCliente',
})(ModalCliente))
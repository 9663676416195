export default {
  FETCH_AJUSTE_STOCK: 'FETCH_AJUSTE_STOCK',
  FETCH_AJUSTE_STOCK_SUCCESS: 'FETCH_AJUSTE_STOCK_SUCCESS',
  OPEN_MODAL_AJUSTE_STOCK: 'OPEN_MODAL_AJUSTE_STOCK',
  OPEN_MODAL_AJUSTE_STOCK_SUCCESS: 'OPEN_MODAL_AJUSTE_STOCK_SUCCESS',
  CLOSE_MODAL_AJUSTE_STOCK: 'CLOSE_MODAL_AJUSTE_STOCK',
  CLOSE_MODAL_AJUSTE_STOCK_SUCCESS: 'CLOSE_MODAL_AJUSTE_STOCK_SUCCESS',
  GUARDAR_AJUSTE_STOCK: 'GUARDAR_AJUSTE_STOCK',
  CREATE_CSV_AJUSTE_STOCK: 'CREATE_CSV_AJUSTE_STOCK',
  CREATE_CSV_AJUSTE_STOCK_SUCCESS: 'CREATE_CSV_AJUSTE_STOCK_SUCCESS',
  IMPRIMIR_PDF_AJUSTE_STOCK: 'IMPRIMIR_PDF_AJUSTE_STOCK',
  IMPRIMIR_PDF_AJUSTE_STOCK_SUCCESS: 'IMPRIMIR_PDF_AJUSTE_STOCK_SUCCESS'
}
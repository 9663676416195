import React, { Component } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import './Button.scss'
import './ButtonPrimary.scss'
import './ButtonWhite.scss'

class Button extends Component {
  render () {
    const {forwardRef} = this.props
    const cleanedProps = {...this.props}
    delete cleanedProps.forwardRef // Eliminada debido al aviso de propiedad no reconocida.
    return (
      <BootstrapButton {...cleanedProps} ref={ref => { forwardRef && forwardRef(ref) }} >
        {this.props.children}
      </BootstrapButton>
    )
  }
}
export default Button

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalCambiarPass from '../../components/usuarios/ModalCambiarPass'
import { cerrarModalCambiarPass, cambiarPasswordUsuario } from '../../actions/usuarios/usuarios'

export function mapStateToProps (state) {
  return {
    ...state.usuarios,
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    formSimpleTable: state.form.simpleTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalCambiarPass,
      cambiarPasswordUsuario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCambiarPass))
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosEnvasado from '../../components/envasado/FiltrosEnvasado'
import {openModal} from '../../actions/common'
import {fetchFiltrarEnvasado, crearCsvEnvasado, imprimirPdfEnvasado, deleteSeleccionDosis} from '../../actions/envasado/envasado'

export function mapStateToProps (state) {
  return {
    ...state.envasado,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      fetchFiltrarEnvasado,
      crearCsvEnvasado,
      imprimirPdfEnvasado,
      deleteSeleccionDosis
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosEnvasado))
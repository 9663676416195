import actionTypes from '../../../constants/actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'

export function initialState () {
  return {
    list: [],
    checkedFiltrosTipoIntervencionSanitaria: false,
    datosExcelTipoIntervencion: [],
    datosPdfTipoIntervencion: []
  }
}

export function fetchTipoIntervencionSanitariaSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function seleccionarTodosTipoIntervencionSanitaria (state) {
  return {
    ...state,
    checkedFiltrosTipoIntervencionSanitaria: true
  }
}

export function deSeleccionarTodosTipoIntervencionSanitaria (state) {
  return {
    ...state,
    checkedFiltrosTipoIntervencionSanitaria: false
  }
}

export function recuperarDatosModalTipoIntervencionSanitariaSuccess (state, {data}) {
  return {
    ...state,
    data
  }
}

export function duplicarDatosModalTipoIntervencionSanitariaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar
  }
}

export function crearCsvTipoIntervencionSuccess (state, {datosExcelTipoIntervencion}) {
  return {
    ...state,
    datosExcelTipoIntervencion
  }
}

export function imprimirPdfTipoIntervencionSuccess (state, {datosPdfTipoIntervencion}) {
  return {
    ...state,
    datosPdfTipoIntervencion
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_TIPO_INTERVENCION_SANITARIA_SUCCESS:
      return fetchTipoIntervencionSanitariaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA:
      return seleccionarTodosTipoIntervencionSanitaria(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA:
      return deSeleccionarTodosTipoIntervencionSanitaria(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS:
      return recuperarDatosModalTipoIntervencionSanitariaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS:
      return duplicarDatosModalTipoIntervencionSanitariaSuccess(state, action)
    case actionTypes.CREATE_CSV_TIPO_INTERVENCION_SUCCESS:
      return crearCsvTipoIntervencionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_TIPO_INTERVENCION_SUCCESS:
      return imprimirPdfTipoIntervencionSuccess(state, action)
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalUsuariosPage from '../../containers/usuarios/ModalUsuariosPage'
import FiltrosUsuariosPage from '../../containers/usuarios/FiltrosUsuariosPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Usuarios.scss'
import { date_parser } from '../../util/formatFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {Delete, AddCircleOutline, Refresh, Launch, Print} from '@material-ui/icons'
import ModalCambiarPassPage from '../../containers/usuarios/ModalCambiarPassPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class Usuarios extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      idUsuario: ''
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosUsuarios()
    } else {
      this.props.actions.deSeleccionarTodosUsuarios()
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.SISTEMA.USUARIOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchUsuarios({estado: {value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  editarUsuarios (values) {
    this.props.actions.editarUsuarios(values)
  }

  deleteSeleccionCentros () {
    const itemsSeleccion = this.props.listCentros.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idUsuario': row.idUsuario,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.deleteSeleccionCentros(list)
    this.props.change('seleccionar-todos-usuarios', false)
    this.props.actions.deSeleccionarTodosUsuarios()
  }

  cambioEstadoSeleccionCentros () {
    const itemsSeleccion = this.props.listCentros.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idUsuario': row.idUsuario,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.cambioEstadoSeleccionCentros(list)
    this.props.change('seleccionar-todos-usuarios', false)
    this.props.actions.deSeleccionarTodosUsuarios()
  }

  crearCsvUsuarios () {
    this.props.actions.crearCsvUsuarios()
  }

  imprimirPdfUsuarios () {
    this.props.actions.imprimirPdfUsuarios()
  }

  abrirModalCambiarPass (idUsuario) {
    this.setState({idUsuario: idUsuario})
    this.props.actions.abrirModalCambiarPass()
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,listUsuarios, listCentros, checkUsuarios, dataUsuario, dataUsuarioDuplicar,
      actions: {abrirModalCentro, recuperarDatosUsuario, duplicarUsuario, cambiarEstadoUsuario, eliminarUsuario, abrirModalCambiarPass, yesNoModal},
      auth: {limiteUsuarios}
    } = this.props

    listUsuarios.forEach(
      (row) => row.idElemento = row.idUsuario
    )

    const table = {
      id: 'usuariosTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreUsuario', name: t('USUARIOS.TABLA.NOMBRE')},
        {id: 'idioma', name: t('USUARIOS.TABLA.IDIOMA')},
        {id: 'ultimaConexion', name: t('USUARIOS.TABLA.ULTIMA_CONEXION'), type: 'fechaHoraTabla'}
      ],
      rows: listUsuarios,
      filtros: <FiltrosUsuariosPage />,
      onEdit: (row) => recuperarDatosUsuario(row.idUsuario),
      data: dataUsuario,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1),
      duplicar: (row) => listUsuarios.length < limiteUsuarios ? (duplicarUsuario(row.idUsuario)) : (limiteUsuarios === '0' || limiteUsuarios === null ? duplicarUsuario(row.idUsuario) : (yesNoModal('limite-usuarios'))),
      dataDuplicar: dataUsuarioDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1,
      cambiarEstado: (row) => cambiarEstadoUsuario(row.idUsuario),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1,
      onDelete: (row) => eliminarUsuario(row.idUsuario),
      cambiarPassword: (row) => this.abrirModalCambiarPass(row.idUsuario),
      mostrarCambiarPassword: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.USUARIOS] > 1,
      initialValues: {},
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkUsuarios
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalUsuariosPage/>
        <ModalCambiarPassPage idUsuario={this.state.idUsuario} />
        <h2 className="page-title">{t('USUARIOS.TITLE')}</h2>
        <ListadoPage t_key="USUARIOS." noTitle={true} table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default (Usuarios)
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Filtros from '../../components/instalacion/FiltrosInstalacion'
import {openModal} from '../../actions/common'
import {abrirModalVacioInstalacion, fetchFiltrarInstalacion, seleccionarTodosInstalacion, deSeleccionarTodosInstalacion, cambiarEstadoSeleccionInstalacion, deleteSeleccionInstalacion,
  crearCsvInstalacion, imprimirPdfInstalacion} from '../../actions/instalacion/instalacion'
import {comboTipoInstalacion, comboCodInstalacion, comboNombreInstalacion, comboInstalacionesPadre
  } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.instalacion,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      abrirModalVacioInstalacion,
      fetchFiltrarInstalacion,
      seleccionarTodosInstalacion,
      deSeleccionarTodosInstalacion,
      cambiarEstadoSeleccionInstalacion,
      deleteSeleccionInstalacion,
      comboTipoInstalacion,
      comboCodInstalacion,
      comboNombreInstalacion,
      comboInstalacionesPadre,
      crearCsvInstalacion,
      imprimirPdfInstalacion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filtros))
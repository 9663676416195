import actionTypes from '../../../constants/actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import actionTypesComun from '../../../constants/actions/common'

export function initialState () {
  return {
    list: [],
    showModalBioseguridadHigiene: false,
    listLimpiezaSilos: [],
    showModalLimpiezaSilos: false,
    //limpiezaSilosAnadidas: [],
    checkedLimpiezaSilos: false,
    listSiloAnadidos: [],
    checkedModalBioseguridad: false,
    listLimpiezaCorral: [],
    showModalLimpiezaCorral: false,
    checkedLimpiezaCorral: false,
    listCorralAnadidos: [],
    listUbicacion: [],
    showModalUbicacion: false,
    checkedUbicacion: false,
    listUbicacionAnadidos: [],
    listVerraco: [],
    showModalVerraco: false,
    checkedVerraco: false,
    listVerracoAnadidos: [],
    listGrupoVerraco: [],
    showModalGrupoVerraco: false,
    checkedGrupoVerraco: false,
    listGrupoVerracoAnadidos: [],
    registroModificar: [],
    duplicarBioseguridad: false,
    checkedFiltrosBioseguridadHigiene: false,
    filtros:{},
    datosExcelBioseguridad: [],
    datosPdfBioseguridad: [],
    showModalAgendaBioseguridad: false,
    tipoAccion: null,
    filtrosUbicacion: [],
    filtrosVerracos: [],
    archivo: []
  }
}

export function abrirModalVacioBioseguridadHigiene (state){
  return {
    ...state,
    showModalBioseguridadHigiene: true,
    listSiloAnadidos: [],
    listCorralAnadidos: [],
    listUbicacionAnadidos: [],
    listVerracoAnadidos: [],
    listGrupoVerracoAnadidos: [],
    archivo: []
  }
}

export function openModalBioseguridadHigiene (state){
  return {
    ...state,
    showModalBioseguridadHigiene: true
  }
}

export function closeModalBioseguridadHigiene (state) {
  return {
    ...state,
    showModalBioseguridadHigiene: false,
    showModalUbicacion: false,
    registroModificar: [],
    listSiloAnadidos: [],
    listCorralAnadidos: [],
    listUbicacionAnadidos: [],
    listVerracoAnadidos: [],
    listGrupoVerracoAnadidos: [],
    duplicarBioseguridad: false,
    archivo: []
  }
}

export function fetchBioseguridadHigieneSuccess (state, {list}){
  return {
    ...state,
    list
  }
}

export function abrirModalLimpiezaSilos (state){
  return {
    ...state,
    showModalLimpiezaSilos: true
  }
}

export function cerrarModalLimpiezaSilos (state){
  return {
    ...state,
    showModalLimpiezaSilos: false
  }
}

export function fetchLimpiezaSilosSuccess (state, {listLimpiezaSilos}){
  return {
    ...state,
    listLimpiezaSilos
  }
}

export function seleccionarTodosModalLimpiezaSilos (state){
  return {
    ...state,
    checkedLimpiezaSilos: true
  }
}

export function deSeleccionarTodosModalLimpiezaSilos (state){
  return {
    ...state,
    checkedLimpiezaSilos: false
  }
}

export function anadirSilo (state, {listSiloAnadidos}){
  return {
    ...state,
    listSiloAnadidos: state.listSiloAnadidos.concat(listSiloAnadidos),
    showModalLimpiezaSilos: false
  }
}

export function onDeleteSiloAnadidoSuccess (state, {listSiloAnadidos}){
  return {
    ...state,
    listSiloAnadidos: listSiloAnadidos,
    checkedModalBioseguridad: false,
    checkedLimpiezaSilos: false
  }
}

export function seleccionarTodosModalBioseguridad (state){
  return {
    ...state,
    checkedModalBioseguridad: true
  }
}

export function deSeleccionarTodosModalBioseguridad (state){
  return {
    ...state,
    checkedModalBioseguridad: false
  }
}

export function abrirModalLimpiezaCorral (state){
  return {
    ...state,
    showModalLimpiezaCorral: true
  }
}

export function cerrarModalLimpiezaCorral (state){
  return {
    ...state,
    showModalLimpiezaCorral: false
  }
}

export function fetchLimpiezaCorralSuccess (state, {listLimpiezaCorral}){
  return {
    ...state,
    listLimpiezaCorral
  }
}

export function seleccionarTodosModalLimpiezaCorral (state){
  return {
    ...state,
    checkedLimpiezaCorral: true
  }
}

export function deSeleccionarTodosModalLimpiezaCorral (state){
  return {
    ...state,
    checkedLimpiezaCorral: false
  }
}

export function anadirCorral (state, {listCorralAnadidos}){
  return {
    ...state,
    listCorralAnadidos: state.listCorralAnadidos.concat(listCorralAnadidos),
    showModalLimpiezaCorral: false
  }
}

export function onDeleteCorralAnadidoSuccess (state, {listCorralAnadidos}){
  return {
    ...state,
    listCorralAnadidos: listCorralAnadidos,
    checkedModalBioseguridad: false,
    checkedLimpiezaCorral: false
  }
}

export function abrirModalUbicacion (state){
  return {
    ...state,
    showModalUbicacion: true
  }
}

export function cerrarModalUbicacion (state){
  return {
    ...state,
    showModalUbicacion: false,
    filtrosUbicacion: []
  }
}

export function fetchUbicacionSuccess (state, {listUbicacion}){
  return {
    ...state,
    listUbicacion
  }
}

export function seleccionarTodosModalUbicacion (state){
  return {
    ...state,
    checkedUbicacion: true
  }
}

export function deSeleccionarTodosModalUbicacion (state){
  return {
    ...state,
    checkedUbicacion: false
  }
}

export function anadirUbicacion (state, {listUbicacionAnadidos}){
  return {
    ...state,
    listUbicacionAnadidos: state.listUbicacionAnadidos.concat(listUbicacionAnadidos),
    showModalUbicacion: false,
    filtrosUbicacion: []
  }
}

export function onDeleteUbicacionAnadidoSuccess (state, {listUbicacionAnadidos}){
  return {
    ...state,
    listUbicacionAnadidos: listUbicacionAnadidos,
    checkedModalBioseguridad: false,
    checkedUbicacion: false
  }
}

export function abrirModalVerraco (state){
  return {
    ...state,
    showModalVerraco: true
  }
}

export function cerrarModalVerraco (state){
  return {
    ...state,
    showModalVerraco: false,
    filtrosVerracos: []
  }
}

export function fetchVerracoSuccess (state, {listVerraco}){
  return {
    ...state,
    listVerraco
  }
}

export function seleccionarTodosModalVerraco (state){
  return {
    ...state,
    checkedVerraco: true
  }
}

export function deSeleccionarTodosModalVerraco (state){
  return {
    ...state,
    checkedVerraco: false
  }
}

export function anadirVerracoBioseguridad (state, {listVerracoAnadidos}){
  return {
    ...state,
    listVerracoAnadidos: state.listVerracoAnadidos.concat(listVerracoAnadidos),
    showModalVerraco: false,
    filtrosVerracos: []
  }
}

export function onDeleteVerracoAnadidoBioseguridadSuccess (state, {listVerracoAnadidos}){
  return {
    ...state,
    listVerracoAnadidos: listVerracoAnadidos,
    checkedModalBioseguridad: false,
    checkedVerraco: false
  }
}

export function abrirModalGrupoVerraco (state){
  return {
    ...state,
    showModalGrupoVerraco: true
  }
}

export function cerrarModalGrupoVerraco (state){
  return {
    ...state,
    showModalGrupoVerraco: false
  }
}

export function fetchGrupoVerracoBioseguridadSuccess (state, {listGrupoVerraco}){
  return {
    ...state,
    listGrupoVerraco
  }
}

export function seleccionarTodosModalGrupoVerraco (state){
  return {
    ...state,
    checkedGrupoVerraco: true
  }
}

export function deSeleccionarTodosModalGrupoVerraco (state){
  return {
    ...state,
    checkedGrupoVerraco: false
  }
}

export function anadirGrupoVerracoBioseguridad (state, {listGrupoVerracoAnadidos}){
  return {
    ...state,
    listGrupoVerracoAnadidos: state.listGrupoVerracoAnadidos.concat(listGrupoVerracoAnadidos),
    showModalGrupoVerraco: false
  }
}

export function onDeleteGrupoVerracoAnadidoSuccess (state, {listGrupoVerracoAnadidos}){
  return {
    ...state,
    listGrupoVerracoAnadidos: listGrupoVerracoAnadidos,
    checkedModalBioseguridad: false,
    checkedGrupoVerraco: false
  }
}

export function recuperarDatosModalBioseguridadHigieneSuccess (state, {registroModificar}){
  return {
    ...state,
    registroModificar,
    listSiloAnadidos: registroModificar.silo ? (registroModificar.silo) : [],
    listCorralAnadidos: registroModificar.corral ? (registroModificar.corral) : [],
    listUbicacionAnadidos: registroModificar.otros && registroModificar.tipoOtros.tipo === 1 ? (registroModificar.otros) : [],
    listVerracoAnadidos: registroModificar.otros && registroModificar.tipoOtros.tipo === 2 ? (registroModificar.otros) : [],
    listGrupoVerracoAnadidos: registroModificar.otros && registroModificar.tipoOtros.tipo === 3 ? (registroModificar.otros) : []
  }
}

export function duplicarDatosModalBioseguridadHigieneSuccess (state){
  return {
    ...state,
    duplicarBioseguridad: true
  }
}

export function seleccionarTodosBioseguridadHigiene (state){
  return {
    ...state,
    checkedFiltrosBioseguridadHigiene: true
  }
}

export function deSeleccionarTodosBioseguridadHigiene (state){
  return {
    ...state,
    checkedFiltrosBioseguridadHigiene: false
  }
}

export function vaciarTablas (state){
  return {
    ...state,
    listSiloAnadidos: [],
    listCorralAnadidos: [],
    listUbicacionAnadidos: [],
    listVerracoAnadidos: [],
    listGrupoVerracoAnadidos: [],
    checkedFiltrosBioseguridadHigiene: false
  }
}

export function fetchFiltrarBioseguridadHigieneSuccess (state, {values}){
  return {
    ...state,
    filtros: values
  }
}

export function submitAnaliticaAguaSuccess (state, {insercionBioseguridad}){
  return {
    ...state,
    insercionBioseguridad
  }
}

export function submitLimpiezaSilosSuccess (state, {insercionBioseguridad}){
  return {
    ...state,
    insercionBioseguridad
  }
}

export function submitLimpiezaCorralesSuccess (state, {insercionBioseguridad}){
  return {
    ...state,
    insercionBioseguridad
  }
}

export function submitOtrosSuccess (state, {insercionBioseguridad}){
  return {
    ...state,
    insercionBioseguridad
  }
}

export function crearCsvBioseguridadSuccess (state, {datosExcelBioseguridad}) {
  return {
    ...state,
    datosExcelBioseguridad
  }
}

export function imprimirPdfBioseguridadSuccess (state, {datosPdfBioseguridad}) {
  return {
    ...state,
    datosPdfBioseguridad
  }
}

export function abrirModalAgendaBioseguridadSuccess (state, {tipoAccion}) {
  return {
    ...state,
    showModalAgendaBioseguridad: true,
    tipoAccion
  }
}

export function guardarModalAgendaBioseguridadSuccess (state) {
  return {
    ...state,
    showModalAgendaBioseguridad: false,
    tipoAccion: null
  }
}

export function cerrarModalAgendaBioseguridadSuccess (state) {
  return {
    ...state,
    showModalAgendaBioseguridad: false,
    tipoAccion: null
  }
}

export function filtrosUbicacionSuccess (state, {filtrosUbicacion}) {
  return {
    ...state,
    filtrosUbicacion: filtrosUbicacion
  }
}

export function filtrosVerracosSuccess (state, {filtrosVerracos}) {
  return {
    ...state,
    filtrosVerracos: filtrosVerracos
  }
}

export function subirArchivosBioseguridad (state, {archivos}) {
  return {
    ...state,
    archivo: archivos
  }
}

export function eliminarArchvioBioseguridad (state, {archivo}) {
  console.log(archivo)
  const archivos = state.archivo
  const datos = archivos.filter(function(data) {
    if (data.archivo !== archivo) {
        return data
    }
  })
  return {
    ...state,
    archivo: datos
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_VACIO_BIOSEGURIDAD_HIGIENE:
      return abrirModalVacioBioseguridadHigiene(state, action)
    case actionTypes.OPEN_MODAL_BIOSEGURIDAD_HIGIENE:
      return openModalBioseguridadHigiene(state, action)
    case actionTypes.FETCH_BIOSEGURIDAD_HIGIENE_SUCCESS:
      return fetchBioseguridadHigieneSuccess(state, action)
    case actionTypes.ABRIR_MODAL_LIMPIEZA_SILOS:
      return abrirModalLimpiezaSilos(state, action)
    case actionTypes.CERRAR_MODAL_LIMPIEZA_SILOS:
      return cerrarModalLimpiezaSilos(state, action)
    case actionTypes.FETCH_LIMPIEZA_SILOS_SUCCESS:
      return fetchLimpiezaSilosSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS:
      return seleccionarTodosModalLimpiezaSilos(state, action)
    case actionTypes.DESELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS:
      return deSeleccionarTodosModalLimpiezaSilos(state, action)
    case actionTypes.ANADIR_SILO:
      return anadirSilo(state, action)
    case actionTypes.ON_DELETE_SILO_ANADIDO_SUCCESS:
      return onDeleteSiloAnadidoSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_BIOSEGURIDAD:
      return seleccionarTodosModalBioseguridad(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_BIOSEGURIDAD:
      return deSeleccionarTodosModalBioseguridad(state, action)
    case actionTypes.ABRIR_MODAL_LIMPIEZA_CORRAL:
      return abrirModalLimpiezaCorral(state, action)
    case actionTypes.CERRAR_MODAL_LIMPIEZA_CORRAL:
      return cerrarModalLimpiezaCorral(state, action)
    case actionTypes.FETCH_LIMPIEZA_CORRAL_SUCCESS:
      return fetchLimpiezaCorralSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL:
      return seleccionarTodosModalLimpiezaCorral(state, action)
    case actionTypes.DESELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL:
      return deSeleccionarTodosModalLimpiezaCorral(state, action)
    case actionTypes.ANADIR_CORRAL:
      return anadirCorral(state, action)
    case actionTypes.ON_DELETE_CORRAL_ANADIDO_SUCCESS:
      return onDeleteCorralAnadidoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_UBICACION:
      return abrirModalUbicacion(state, action)
    case actionTypes.CERRAR_MODAL_UBICACION:
      return cerrarModalUbicacion(state, action)
    case actionTypes.FETCH_UBICACION_SUCCESS:
      return fetchUbicacionSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_MODAL_UBICACION:
      return seleccionarTodosModalUbicacion(state, action)
    case actionTypes.DESELCCIONAR_TODOS_MODAL_UBICACION:
      return deSeleccionarTodosModalUbicacion(state, action)
    case actionTypes.ANADIR_UBICACION:
      return anadirUbicacion(state, action)
    case actionTypes.ON_DELETE_UBICACION_ANADIDO_SUCCESS:
      return onDeleteUbicacionAnadidoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VERRACO:
      return abrirModalVerraco(state, action)
    case actionTypes.CERRAR_MODAL_VERRACO:
      return cerrarModalVerraco(state, action)
    case actionTypes.FETCH_VERRACO_SUCCESS:
      return fetchVerracoSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_MODAL_VERRACO:
      return seleccionarTodosModalVerraco(state, action)
    case actionTypes.DESELCCIONAR_TODOS_MODAL_VERRACO:
      return deSeleccionarTodosModalVerraco(state, action)
    case actionTypes.ANADIR_VERRACO_BIOSEGURIDAD:
      return anadirVerracoBioseguridad(state, action)
    case actionTypes.ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS:
      return onDeleteVerracoAnadidoBioseguridadSuccess(state, action)
    case actionTypes.ABRIR_MODAL_GRUPO_VERRACO:
      return abrirModalGrupoVerraco(state, action)
    case actionTypes.CERRAR_MODAL_GRUPO_VERRACO:
      return cerrarModalGrupoVerraco(state, action)
    case actionTypes.FETCH_GRUPO_VERRACO_BIOSEGURIDAD_SUCCESS:
      return fetchGrupoVerracoBioseguridadSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_MODAL_GRUPO_VERRACO:
      return seleccionarTodosModalGrupoVerraco(state, action)
    case actionTypes.DESELCCIONAR_TODOS_MODAL_GRUPO_VERRACO:
      return deSeleccionarTodosModalGrupoVerraco(state, action)
    case actionTypes.ANADIR_GRUPO_VERRACO_BIOSEGURIDAD:
      return anadirGrupoVerracoBioseguridad(state, action)
    case actionTypes.ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS:
      return onDeleteGrupoVerracoAnadidoSuccess(state, action)
    case actionTypes.CLOSE_MODAL_BIOSEGURIDAD_HIGIENE:
      return closeModalBioseguridadHigiene(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS:
      return recuperarDatosModalBioseguridadHigieneSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS:
      return duplicarDatosModalBioseguridadHigieneSuccess(state, action)
    case actionTypes.SELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE:
      return seleccionarTodosBioseguridadHigiene(state, action)
    case actionTypes.DESELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE:
      return deSeleccionarTodosBioseguridadHigiene(state, action)
    case actionTypes.VACIAR_TABLAS:
      return vaciarTablas(state, action)
    case actionTypes.FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE_SUCCESS:
      return fetchFiltrarBioseguridadHigieneSuccess(state, action)
    case actionTypes.SUBMIT_ANALITICA_AGUA_SUCCESS:
      return submitAnaliticaAguaSuccess(state, action)
    case actionTypes.SUBMIT_LIMPIEZA_SILOS_SUCCESS:
      return submitLimpiezaSilosSuccess(state, action)
    case actionTypes.SUBMIT_LIMPIEZA_CORRALES_SUCCESS:
      return submitLimpiezaCorralesSuccess(state, action)
    case actionTypes.SUBMIT_OTROS_SUCCESS:
      return submitOtrosSuccess(state, action)
    case actionTypes.CREATE_CSV_BIOSEGURIDAD_SUCCESS:
      return crearCsvBioseguridadSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_BIOSEGURIDAD_SUCCESS:
      return imprimirPdfBioseguridadSuccess(state, action)
    case actionTypes.ABRIR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS:
      return abrirModalAgendaBioseguridadSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_AGENDA_BIOSEGURIDAD:
      return guardarModalAgendaBioseguridadSuccess(state, action)
    case actionTypes.CERRAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS:
      return cerrarModalAgendaBioseguridadSuccess(state, action)
    case actionTypes.FILTROS_UBICACION_SUCCESS:
      return filtrosUbicacionSuccess(state, action)
    case actionTypes.FILTROS_VERRACOS_SUCCESS:
      return filtrosVerracosSuccess(state, action)
    case actionTypes.SUBIR_ARCHIVOS_BIOSEGURIDAD:
      return subirArchivosBioseguridad(state, action)
    case actionTypes.ELIMINAR_ARCHIVOS_BIOSEGURIDAD:
      return eliminarArchvioBioseguridad(state, action)
    default:
      return state
  }
}
import actionTypes from '../../constants/actions/ajusteStock/ajusteStock'

export function fetchAjusteStock (filtros) {
  return {
    type: actionTypes.FETCH_AJUSTE_STOCK,
    filtros
  }
}

export function fetchAjusteStockSuccess (list, filtros) {
  return {
    type: actionTypes.FETCH_AJUSTE_STOCK_SUCCESS,
    list,
    filtros
  }
}

export function openModalAjusteStock(){
  return {
    type: actionTypes.OPEN_MODAL_AJUSTE_STOCK
  }
}

export function openModalAjusteStockSuccess(){
  return {
    type: actionTypes.OPEN_MODAL_AJUSTE_STOCK_SUCCESS
  }
}

export function closeModalAjusteStock(){
  return {
    type: actionTypes.CLOSE_MODAL_AJUSTE_STOCK
  }
}

export function closeModalAjusteStockSuccess(){
  return {
    type: actionTypes.CLOSE_MODAL_AJUSTE_STOCK_SUCCESS
  }
}

export function guardarAjusteStock (values, guardarYNuevo) {
  return {
    type: actionTypes.GUARDAR_AJUSTE_STOCK,
    values,
    guardarYNuevo
  }
}

export function crearCsvAjusteStock(list){
  return {
    type: actionTypes.CREATE_CSV_AJUSTE_STOCK,
    list
  }
}

export function crearCsvAjusteStockSuccess(datosExcelAjusteStock){
  return {
    type: actionTypes.CREATE_CSV_AJUSTE_STOCK_SUCCESS,
    datosExcelAjusteStock
  }
}

export function imprimirPdfAjusteStock(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_AJUSTE_STOCK,
    list
  }
}

export function imprimirPdfAjusteStockSuccess(datosPdfAjusteStock){
  return {
    type: actionTypes.IMPRIMIR_PDF_AJUSTE_STOCK_SUCCESS,
    datosPdfAjusteStock
  }
}

export default {
  fetchAjusteStock,
  fetchAjusteStockSuccess,
  guardarAjusteStock,
  crearCsvAjusteStock,
  crearCsvAjusteStockSuccess,
  imprimirPdfAjusteStock,
  imprimirPdfAjusteStockSuccess,
  openModalAjusteStock,
  openModalAjusteStockSuccess,
  closeModalAjusteStock,
  closeModalAjusteStockSuccess
}

import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import actionTypes from '../../constants/actions/importarPedidos/importarPedidos'
import {submitImportarPedidosSuccess} from '../../actions/importarPedidos/importarPedidos'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getImportarPedidos from '../../services/importarPedidos/getImportarPedidos'
import getDescargarPlantillaExcelPedidos from '../../services/importarPedidos/getDescargarPlantillaExcelPedidos'

export function * submitImportarPedidos ({value}) {
  try {
    let auth = yield select(state => state.auth)
    const listCargaIndex = yield call(getImportarPedidos, value, auth.token)    
    yield put(submitImportarPedidosSuccess(listCargaIndex))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitImportarPedidos () {
  yield takeLatest(actionTypes.SUBMIT_IMPORTAR_PEDIDOS, submitImportarPedidos)
}

export function * descargarPlantillaExcelPedidos () {
  try {
    const archivoExcel = yield call(getDescargarPlantillaExcelPedidos, null)
    let href = 'data:application/csv;base64,' + encodeURIComponent(archivoExcel.xls)
    let name = i18n.t('IMPORTAR_PEDIDOS.EJEMPLO_EXCEL_PEDIDOS') + ".xls"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDescargarPlantillaExcelPedidos () {
  yield takeLatest(actionTypes.DESCARGAR_PLANTILLA_EXCEL_PEDIDOS, descargarPlantillaExcelPedidos)
}
